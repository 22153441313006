import gql from 'graphql-tag';

export default gql`
    query searchdatamasterSlaTitles(  
        $params: paramsDatamaster_sla_level,
        $sort: sort,
        $page: Int,
    ){
    datamasterSlaTitles(
            params:$params
            sort: $sort
            page: $page
            showData: 100
        ) {
        slaTitles {
        _id
        datamaster_sla_level
        level
        title
        status_delete
        show_driver_app
        create_date
        last_update
        
        }
    }
    }
`;
