import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import cookie from 'react-cookies';

import { Button } from 'react-bootstrap';
import HeaderLinks from '../Header/HeaderLinks.jsx';

import imagine from 'assets/img/sidebar-2.jpg';
import logo from 'assets/img/logo_rw.svg';
import logoSmall from 'assets/img/logo_rw_small.svg';
import adminLogo from 'assets/img/adminLogoWithText.svg';
import adminLogoSmall from 'assets/img/adminLogo.svg';

import adminListQuery from '../../utils/AdminName/graphql/query/adminList';
import { graphql, compose } from 'react-apollo';
import { client } from '../../index';

import dashboardRoutesShopee from 'routes/dashboardShopee.jsx';
import dashboardRoutes from 'routes/dashboard.jsx';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      checkCollapseOnce: false,
      shipManGroup: false,
      projectGroup: false,
      thirdGroup: false,
      cusGroup: false,
      marketingGroup: false,
      settingGroup: false,
      isSidebar: true,
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  logout() {
    cookie.remove('token_key', { path: '/' });
    window.open('/login', '_self');
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  updateCollapse = group => {
    const value = this.state[group];
    this.setState({
      [group]: !value,
    });
  };

  toggleSidebar = () => {
    document.body.classList.toggle('sidebar-mini');
    this.setState({
      isSidebar: !this.state.isSidebar,
    });
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  render() {
    const sidebarBackground = {
      // backgroundImage: 'url(' + imagine + ')',
      background: '#232A32',
    };
    let adminName = '';
    if (
      this.props.adminListQuery &&
      this.props.adminListQuery.loading == false &&
      this.props.adminListQuery.adminList != undefined
    ) {
      adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
    }
    return (
      <div id="sidebar" className="sidebar" data-color="black" data-image={imagine}>
        <div className="sidebar-background" style={sidebarBackground} />
        <div
          className={`toggleSidebar ${this.state.isSidebar && 'active'}`}
          onClick={this.toggleSidebar}
        >
          <i className={`fas fa-chevron-${this.state.isSidebar ? 'left' : 'right'}`}></i>
        </div>
        <div className="logo">
          <a href="/" className="simple-text logo-mini">
            <div className="logo-img">
              <img src={adminLogo} alt="logo_image" id="logo_image" />
              <img
                src={adminLogoSmall}
                alt="logo_image"
                id="logo_image_small"
                style={{ height: 40 }}
              />
            </div>
          </a>
          <a href="/" className="simple-text logo-normal"></a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <HeaderLinks /> : null}
            {adminName === 'shopee'
              ? dashboardRoutesShopee.map((prop, key) => {
                  if (!prop.redirect && !prop.hide) {
                    // with sub menu
                    if (prop.subMenu && prop.subMenu.length > 0) {
                      return (
                        <>
                          <li
                            className={[
                              prop.upgrade ? 'active active-pro' : this.activeRoute(prop.path),
                              'with-sub-menu',
                            ].join(' ')}
                            onClick={() => this.updateCollapse(prop.enName)}
                            key={key}
                            style={{ textAlignLast: 'left' }}
                          >
                            <Button className={['nav-link', 'with-sub-menu']} title={prop.name}>
                              {prop.icon && (
                                <i
                                  style={{ float: 'left', color: '#ffffff' }}
                                  className={prop.icon}
                                />
                              )}
                              <p style={{ color: '#ffffff' }}>{prop.name}</p>
                              {this.state[prop.enName] ? (
                                <i
                                  style={{ color: '#ffffff', position: 'absolute', right: '-10px' }}
                                  class="pe-7s-angle-down"
                                />
                              ) : (
                                <i
                                  style={{ color: '#ffffff', position: 'absolute', right: '-10px' }}
                                  class="pe-7s-angle-up"
                                />
                              )}
                            </Button>
                          </li>
                          {prop.subMenu.map((propSub, key) => {
                            if (!propSub.redirect && !propSub.hide) {
                              if (propSub.path === this.props.location.pathname) {
                                if (!this.state[prop.enName])
                                  this.setState({ [prop.enName]: true });
                              }

                              return (
                                <li
                                  className={
                                    propSub.upgrade
                                      ? 'active active-pro'
                                      : this.activeRoute(propSub.path)
                                  }
                                  style={{
                                    marginLeft: 20,
                                    display: this.state[prop.enName] ? 'block' : 'none',
                                  }}
                                  key={key}
                                >
                                  <NavLink
                                    to={propSub.path}
                                    className="nav-link"
                                    activeClassName="active"
                                    title={propSub.name}
                                  >
                                    <i className={propSub.icon} />
                                    <p>{propSub.name}</p>
                                  </NavLink>
                                </li>
                              );
                            }
                          })}
                        </>
                      );
                    }
                    //
                    return (
                      <li
                        className={prop.upgrade ? 'active active-pro' : this.activeRoute(prop.path)}
                        key={key}
                        style={{ textAlignLast: 'left' }}
                      >
                        <NavLink
                          to={prop.path}
                          className="nav-link"
                          activeClassName="active"
                          title={prop.name}
                        >
                          <i className={prop.icon} />
                          <p>{prop.name}</p>
                        </NavLink>
                      </li>
                    );
                  }
                  return null;
                })
              : dashboardRoutes.map((prop, key) => {
                  if (!prop.redirect && !prop.hide) {
                    // with sub menu
                    if (prop.subMenu && prop.subMenu.length > 0) {
                      return (
                        <>
                          <li
                            className={[
                              prop.upgrade ? 'active active-pro' : this.activeRoute(prop.path),
                              'with-sub-menu',
                            ].join(' ')}
                            onClick={() => this.updateCollapse(prop.enName)}
                            key={key}
                            style={{ textAlignLast: 'left' }}
                          >
                            <Button className={['nav-link', 'with-sub-menu']} title={prop.name}>
                              {prop.icon && !prop.svg && (
                                <i
                                  style={{ float: 'left', color: '#ffffff' }}
                                  className={prop.icon}
                                />
                              )}
                              <p
                                className={`${!prop.icon && 'alwayShowText'}`}
                                style={{ color: '#ffffff' }}
                              >
                                {prop.name}
                              </p>
                              {this.state[prop.enName] ? (
                                <i
                                  style={{ color: '#ffffff', position: 'absolute', right: '-10px' }}
                                  class="pe-7s-angle-down"
                                />
                              ) : (
                                <i
                                  style={{ color: '#ffffff', position: 'absolute', right: '-10px' }}
                                  class="pe-7s-angle-up"
                                />
                              )}
                            </Button>
                          </li>
                          {prop.subMenu.map((propSub, key) => {
                            if (!propSub.redirect && !propSub.hide) {
                              if (this.props.location.pathname.includes(propSub.path)) {
                                if (!this.state[prop.enName] && !this.state.checkCollapseOnce)
                                  this.setState({ [prop.enName]: true, checkCollapseOnce: true });
                              }

                              return (
                                <li
                                  className={
                                    propSub.upgrade
                                      ? 'active active-pro'
                                      : this.activeRoute(propSub.path)
                                  }
                                  style={{
                                    marginLeft: 20,
                                    display: this.state[prop.enName] ? 'block' : 'none',
                                  }}
                                  key={key}
                                >
                                  <NavLink
                                    to={propSub.path}
                                    className="nav-link"
                                    activeClassName="active"
                                    title={propSub.name}
                                  >
                                    {propSub.icon && !propSub.svg && <i className={propSub.icon} />}
                                    {propSub.svg && (
                                      <img
                                        style={{
                                          float: 'left',
                                          height: '20px',
                                          marginRight: '15px',
                                          marginLeft: '10px',
                                        }}
                                        src={propSub.svg}
                                      />
                                    )}
                                    <p>{propSub.name}</p>
                                  </NavLink>
                                </li>
                              );
                            }
                          })}
                        </>
                      );
                    }
                    //
                    return (
                      <li
                        className={prop.upgrade ? 'active active-pro' : this.activeRoute(prop.path)}
                        key={key}
                        style={{ textAlignLast: 'left' }}
                      >
                        <NavLink
                          to={prop.path}
                          className="nav-link"
                          activeClassName="active"
                          title={prop.name}
                        >
                          {prop.icon && !prop.svg && (
                            <i style={{ float: 'left', color: '#ffffff' }} className={prop.icon} />
                          )}
                          <p
                            className={`${!prop.icon && 'alwayShowText'}`}
                            style={{ color: '#ffffff' }}
                          >
                            {prop.name}
                          </p>
                        </NavLink>
                      </li>
                    );
                  }
                  return null;
                })}
          </ul>

          <ul className="logout">
            <li className="active active-pro">
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                title="ออกจากระบบ"
                block
                className="nav-link"
                onClick={() => this.logout()}
              >
                <i style={{ float: 'left', color: '#ffffff' }} className="fas fa-sign-out-alt" />
                <p style={{ color: '#ffffff', marginLeft: 5, marginBottom: 2 }}>ออกจากระบบ</p>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default compose(graphql(adminListQuery, { name: 'adminListQuery' }))(Sidebar);
