import styled from 'styled-components';
import { Row, NavDropdown } from 'react-bootstrap';

export const Container = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    // position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

export const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

export const TabContentContainer = styled.div`
  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

export const TitleLeft = styled.h4`
  font-weight: bold;
  margin-top: 0;
  border-left: 2px solid #d90402;
  padding-left: 10px;
`;

export const TitleModal = styled.h5`
    font-weight: bold;
    margin-top: 0;
    border-left: 2px solid #d90402;
    padding-left: 10px;
    font
`;

export const BoxDashboard = styled(Row)`
  border: 1px solid #dddddd;
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 30px;

  & .col-md-4 {
    border-right: 1px solid #dddddd;
  }

  & .col-md-4:last-child {
    border-right: none;
  }

  & h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Line = styled.hr`
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 15px;
  float: left;
  width: 100%;
`;

export const Blankspace = styled.div`
  height: 10px;
  width: calc(100% + 30px);
  background-color: #f7f7f8;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RowItem = styled.div`
  color: #000000;
  padding: 0 15px 10px 0;
  font-size: 18px;
  line-height: 1;
  width: 100%;
  float: left;

  & .left {
    display: contents;
    float: left;
  }

  & .right {
    display: block;
    float: right;
    text-align: right;
  }

  & .total {
    font-size: 18px;
    color: #d90001;
    font-weight: bold;
  }
`;

export const AdvancePayment = styled.div`
  width: 100%;
  float: left;
  height: 500px;
  overflow-y: auto;

  & .item {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eee;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    & .icon-wrapper {
      width: 40px;
      float: left;
      & i {
        font-size: 20px;
        color: #ddd;
        cursor: pointer;
      }
    }
    & .detail-wrapper {
      width: calc(100% - 40px);
      float: left;
      & .titel {
        font-weight: bold;
      }
      & .content {
        & .left {
          float: left;
          width: 100px;
        }
        & .right {
          width: calc(100% - 100px);
          float: left;
        }
      }
    }
  }
  & .item.selected {
    & .icon-wrapper {
      & i {
        color: #19aa19;
      }
    }
  }
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

export const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

export const NavItemA = styled.li`
  & a {
    padding: 8px 16px;
    color: #333333;
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
  }
`;
