import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormControl,
  Label,
  FormGroup,
  Popover,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';
import _ from 'lodash';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import summaryInvoiceListQuery from './graphql/query/summaryInvoiceList';
import waitingInvoiceListQuery from './graphql/query/waitingMakeSummaryInvoiceList';
import debitNoteListQuery from './graphql/query/debitNoteList';
import receiptSummaryListQuery from './graphql/query/receiptSummaryList';
import transporterInfoListQuery from './graphql/query/accountInfoListSearchId';
import adminName from './graphql/query/adminName';

import sendEmailDocumentMutation from './graphql/mutation/sendEmailDocument';

import CardTable from 'components/CardTable/CardTable.jsx';
import styled from 'styled-components';
import { client } from '../..';
import baseUrl from './../../config/baseUrl';
import reportUrl from './../../config/reportUrl';
import s3 from './../../config/s3';

const Container = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    // position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

const TabContentContainer = styled.div`
  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

const TitleLeft = styled.h4`
  font-weight: bold;
  margin-top: 0;
  border-left: 2px solid #d90402;
  padding-left: 10px;
`;

const TitleModal = styled.h5`
    font-weight: bold;
    margin-top: 0;
    border-left: 2px solid #d90402;
    padding-left: 10px;
    font
`;

const BoxDashboard = styled(Row)`
  border: 1px solid #dddddd;
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 30px;

  & .col-md-4 {
    border-right: 1px solid #dddddd;
  }

  & .col-md-4:last-child {
    border-right: none;
  }

  & h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
`;

const Line = styled.hr`
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 15px;
  float: left;
  width: 100%;
`;

const Blankspace = styled.div`
  height: 10px;
  width: calc(100% + 30px);
  background-color: #f7f7f8;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const RowItem = styled.div`
  color: #000000;
  padding: 0 15px 10px 0;
  font-size: 18px;
  line-height: 1;
  width: 100%;
  float: left;

  & .left {
    display: contents;
    float: left;
  }

  & .right {
    display: block;
    float: right;
    text-align: right;
  }

  & .total {
    font-size: 18px;
    color: #d90001;
    font-weight: bold;
  }
`;

const AdvancePayment = styled.div`
  width: 100%;
  float: left;
  height: 500px;
  overflow-y: auto;

  & .item {
    width: 100%;
    float: left;
    border-bottom: 1px solid #eee;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }

    & .icon-wrapper {
      width: 40px;
      float: left;
      & i {
        font-size: 20px;
        color: #ddd;
        cursor: pointer;
      }
    }
    & .detail-wrapper {
      width: calc(100% - 40px);
      float: left;
      & .titel {
        font-weight: bold;
      }
      & .content {
        & .left {
          float: left;
          width: 100px;
        }
        & .right {
          width: calc(100% - 100px);
          float: left;
        }
      }
    }
  }
  & .item.selected {
    & .icon-wrapper {
      & i {
        color: #19aa19;
      }
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

class Payment extends Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(document.location.search.substring(1));
    let id = params.get('id');

    this.state = {
      admin_id: 0,
      account_info_id: id,
      search_account_info_id: null,
      show: false,
      total_page: 0,
      page_number: 1,
      order_by: null,
      order_type: null,

      total_page_debitNote: 0,
      page_number_debitNote: 1,
      order_by_debitNote: null,
      order_type_debitNote: null,

      total_page_receipt: 0,
      page_number_receipt: 1,
      order_by_receipt: null,
      order_type_receipt: null,

      showReportInvoice: false,
      reportMode: null,
      reportDateInvoice: '',
      show_reportDateInvoice: '',

      showReport: false,
      reportDate: '',
      show_reportDate: '',

      openCreateInvoice: false,
      waitingInvoiceSelected: [],
      waitingInvoiceList: [],
      GrandtotalWaitingInvoice: 0,
      issue_date: '',
      issue_date_show: '',
      due_date: '',
      due_date_show: '',

      selectTap: 1,

      summary_invoice_number: '',
      debit_note_number: '',
      receipt_number: '',
      summary_invoice_status: 0,
      debit_note_status: 0,
      receipt_status: 0,

      openSendMail: false,
      sendMailStatus: 0,
      selectInvoice: [],
      selectDebitNote: [],
      selectRecript: [],

      create_date: null,
      last_update: null,
      receipt_date: `${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`,
      show_receipt_date: `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
      transporterSuggestions: [],
      transporter: '',
    };
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    this.setState({ page_number: page + 1, total_page });
    // //console.log(this.state);
    refetch();
  }

  /* DebitNote */
  sortDebitNote(order_by, refetch) {
    if (order_by == this.state.order_by_debitNote) {
      this.setState({ order_type_debitNote: this.state.order_type_debitNote * -1 });
    } else {
      this.setState({ order_type_debitNote: 1, order_by_debitNote: order_by });
    }
    refetch();
  }

  renderSortingDebitNote(order_by) {
    if (order_by == this.state.order_by_debitNote) {
      if (this.state.order_type_debitNote == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPageDebitNote(page, total_page, refetch) {
    this.setState({ page_number_debitNote: page + 1, total_page });
    // //console.log(this.state);
    refetch();
  }

  /* receipt */
  sortReceipt(order_by, refetch) {
    if (order_by == this.state.order_by_receipt) {
      this.setState({ order_type_receipt: this.state.order_type_receipt * -1 });
    } else {
      this.setState({ order_type_receipt: 1, order_by_receipt: order_by });
    }
    refetch();
  }

  renderSortingReceipt(order_by) {
    if (order_by == this.state.order_by_receipt) {
      if (this.state.order_type_receipt == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPageReceipt(page, total_page, refetch) {
    this.setState({ page_number_receipt: page + 1, total_page });
    // //console.log(this.state);
    refetch();
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 2:
        return <Label bsStyle="warning">รอตรวจสอบ</Label>;
      case 3:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      case 4:
        return <Label bsStyle="danger">ค้างชำระ</Label>;
      case 5:
        return <Label>แบบร่าง</Label>;
      default:
        return '-';
    }
  }

  renderStatusReceipt(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="warning">รอตรวจสอบ</Label>;
      case 2:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      default:
        return '-';
    }
  }

  renderPaidStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 2:
        return <Label bsStyle="warning">รอตรวจสอบ</Label>;
      case 3:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      case 4:
        return <Label bsStyle="danger">ค้างชำระ</Label>;
      case 5:
        return <Label>แบบร่าง</Label>;
      default:
        return '-';
    }
  }

  renderPaidTypeShipment(type) {
    switch (type) {
      case 5:
        return 'บัตรเครดิต / เดบิต';
      case 6:
        return 'เงินสด';
      default:
        return 'ใบแจ้งหนี้';
    }
  }

  renderPaidType(type) {
    switch (type) {
      case 1:
        return 'บัตรเครดิต / เดบิต';
      case 2:
        return 'ออนไลน์แบงค์';
      case 3:
        return 'จ่ายบิล';
      case 4:
        return 'โอนเงิน';
      default:
        return '-';
    }
  }

  searchPickupDate(mode, refetch) {
    let date = '';
    let show = '';
    if (mode == 'today') {
      date = `${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`;
      show = `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
    } else {
      date = `${moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')}:${moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')}`;
      show = `${moment()
        .add(1, 'days')
        .format('DD/MM/YYYY')} - ${moment()
        .add(1, 'days')
        .format('DD/MM/YYYY')}`;
    }
    this.setState({ pick_up_date: date, show_pick_up_date: show }, () => {
      refetch();
    });
  }

  //WaitingInvoiceList
  getWaitingInvoiceList = async value => {
    try {
      const { data } = await client.query({
        query: waitingInvoiceListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_id: value,
        },
      });

      const waitingInvoiceSelectedData = data.waitingMakeSummaryInvoiceList.list.map(e => {
        return {
          shipment_id: e.shipment_id,
          grand_total_price: e.grand_total_price,
        };
      });

      this.setState(
        {
          openCreateInvoice: true,
          waitingInvoiceList: data.waitingMakeSummaryInvoiceList,
          issue_date: moment(data.waitingMakeSummaryInvoiceList.issue_date).format('YYYY-MM-DD'),
          issue_date_show: moment(data.waitingMakeSummaryInvoiceList.issue_date).format(
            'DD/MM/YYYY'
          ),
          due_date: moment(data.waitingMakeSummaryInvoiceList.due_date).format('YYYY-MM-DD'),
          due_date_show: moment(data.waitingMakeSummaryInvoiceList.due_date).format('DD/MM/YYYY'),
          waitingInvoiceSelected: waitingInvoiceSelectedData,
        },
        this.calcGrandtotal(waitingInvoiceSelectedData)
      );
    } catch (error) {
      //console.log(error)
    }
  };

  calcGrandtotal(item, mode) {
    let price = 0;
    if (mode == 'price') {
      price = item;
    } else {
      item.forEach(list => {
        price += list.grand_total_price;
      });
    }

    this.setState({
      GrandtotalWaitingInvoice: price,
    });
  }

  onWaitingInvoiceFetchRequested = shipperID => {
    this.getWaitingInvoiceList(shipperID);
  };

  onWaitingInvoiceClearRequested = () => {
    this.setState({
      openCreateInvoice: false,
      waitingInvoiceSelected: [],
      waitingInvoiceList: [],
    });
  };

  renderShipmentArray() {
    let id = [];
    if (this.state.waitingInvoiceSelected.length > 0) {
      this.state.waitingInvoiceSelected.forEach(list => {
        id.push(list.shipment_id);
      });
    }

    return id;
  }

  checkWaitinfItemSelected(id) {
    const list = this.state.waitingInvoiceSelected.filter(list => {
      return list.shipment_id == id;
    });
    if (list.length > 0) {
      return true;
    }
    return false;
  }

  onClickWaitinfItem(id) {
    const listSelected = this.state.waitingInvoiceSelected.filter(list => {
      return list.shipment_id == id;
    });

    let price = 0;
    listSelected.forEach(list => {
      price += list.grand_total_price;
    });

    const list = this.state.waitingInvoiceList.list.filter(list => {
      return list.shipment_id == id;
    });

    if (listSelected.length > 0) {
      const listSelected = this.state.waitingInvoiceSelected.filter(list => {
        return list.shipment_id != id;
      });
      this.setState({ waitingInvoiceSelected: listSelected }, this.calcGrandtotal(listSelected));
    } else {
      this.setState(
        {
          waitingInvoiceSelected: [
            ...this.state.waitingInvoiceSelected,
            {
              shipment_id: list[0].shipment_id,
              grand_total_price: list[0].grand_total_price,
            },
          ],
        },
        this.calcGrandtotal(price + list[0].grand_total_price, 'price')
      );
    }
  }

  //sendMail
  checkItemSelected(id, mode) {
    let list = [];
    if (mode == 'invoice') {
      list = this.state.selectInvoice.filter(_id => {
        return _id == id;
      });
    } else if (mode == 'debitnote') {
      list = this.state.selectDebitNote.filter(_id => {
        return _id == id;
      });
    } else if (mode == 'receipt') {
      list = this.state.selectRecript.filter(_id => {
        return _id == id;
      });
    }

    if (list.length > 0) {
      return true;
    }
    return false;
  }

  onClickItem(id, mode) {
    let listSelected = [];
    let list = [];
    if (mode == 'invoice') {
      listSelected = this.state.selectInvoice.filter(_id => {
        return _id == id;
      });

      if (listSelected.length > 0) {
        const listSelected = this.state.selectInvoice.filter(_id => {
          return _id != id;
        });
        this.setState({ selectInvoice: listSelected });
      } else {
        this.setState({
          selectInvoice: [...this.state.selectInvoice, id],
        });
      }
    } else if (mode == 'debitnote') {
      listSelected = this.state.selectDebitNote.filter(_id => {
        return _id == id;
      });

      if (listSelected.length > 0) {
        const listSelected = this.state.selectDebitNote.filter(_id => {
          return _id != id;
        });
        this.setState({ selectDebitNote: listSelected });
      } else {
        this.setState({
          selectDebitNote: [...this.state.selectDebitNote, id],
        });
      }
    } else if (mode == 'receipt') {
      listSelected = this.state.selectRecript.filter(_id => {
        return _id == id;
      });

      if (listSelected.length > 0) {
        const listSelected = this.state.selectRecript.filter(_id => {
          return _id != id;
        });
        this.setState({ selectRecript: listSelected });
      } else {
        this.setState({
          selectRecript: [...this.state.selectRecript, id],
        });
      }
    }
  }

  checkDisabledSendMail() {
    if (this.state.selectTap == 1) {
      return this.state.selectInvoice.length > 0;
    } else if (this.state.selectTap == 2) {
      return this.state.selectDebitNote.length > 0;
    } else if (this.state.selectTap == 3) {
      return this.state.selectRecript.length > 0;
    }
  }

  renderVariablesSendEmailDocument() {
    let obj = {};
    if (this.state.selectTap == 1) {
      obj = {
        mode: 'invoice',
        id_array: this.state.selectInvoice,
      };
    } else if (this.state.selectTap == 2) {
      obj = {
        mode: 'debitNote',
        id_array: this.state.selectDebitNote,
      };
    } else if (this.state.selectTap == 3) {
      obj = {
        mode: 'receipt',
        id_array: this.state.selectRecript,
      };
    }

    return obj;
  }

  //Autosuggest Transporter
  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: transporterInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        transporterSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      transporterSuggestions: [],
    });
  };

  shouldRenderSuggestions() {
    return true;
  }
  //End Autosuggest Transporter

  openReportShipment(mode) {
    this.setState({ showReport: true, modeReport: mode, reportDate: '', show_reportDate: '' });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  handleDateRangePicker(mode, event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode == 'create_date') {
      this.setState({ create_date: date, show_create_date: show }, () => {
        refetch();
      });
    } else if (mode == 'last_update') {
      this.setState({ last_update: date, show_last_update: show }, () => {
        refetch();
      });
    } else if (mode == 'receipt_date') {
      this.setState({ receipt_date: date, show_receipt_date: show }, () => {
        refetch();
      });
    }
  }

  openReportInvoice(mode, admin_id) {
    this.setState({
      showReportInvoice: true,
      reportMode: mode,
      reportDateInvoice: '',
      show_reportDateInvoice: '',
      admin_id,
    });
  }

  handleDateRangePickerReportInvoice(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDateInvoice: date, show_reportDateInvoice: show });
  }

  exportReportInvoice() {
    if (this.state.reportMode == 'ops') {
      const uri = `${reportUrl}/summary-invoice-shipment?admin_id=${this.state.admin_id}&date=${this.state.reportDateInvoice}`;
      window.location = uri;
    } else {
      const uri = `${reportUrl}/summary_invoice_report?admin_id=${this.state.admin_id}&date=${this.state.reportDateInvoice}`;
      window.location = uri;
    }

    this.setState({ showReportInvoice: false, reportDateInvoice: '', show_reportDateInvoice: '' });
  }

  checkNew(mode, item) {
    let check = false;
    if (mode == 'invoice') {
      if (item != null && item.length > 0) {
        item.forEach(list => {
          if (list.read_status == 1) {
            check = true;
          }
        });
      }
    } else if (mode == 'waiting') {
      if (item != null && item.length > 0) {
        check = true;
      }
    } else if (mode == 'debitnote') {
      console.log(item);
      if (item != null && item.length > 0) {
        item.forEach(list => {
          if (list.read_status == 1) {
            check = true;
          }
        });
      }
    } else if (mode == 'recript') {
      if (item != null && item.length > 0) {
        item.forEach(list => {
          if (list.read_status == 1) {
            check = true;
          }
        });
      }
    }

    return check;
  }

  checkIsValidDate(date, chk_date) {
    // // yesterday
    const yesterday = moment(chk_date).subtract(1, 'day');
    if (date.isBefore(yesterday)) {
      return false;
    }

    // if (date.isBefore(chk_date)) {
    // 	return false;
    // }

    return true;
  }

  handleDatePicker(event, mode) {
    //console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD')}`;
    const show = `${event.format('DD/MM/YYYY')}`;
    if (mode == 'issue_date') {
      this.setState({ issue_date: date, issue_date_show: show });
    } else if ((mode = 'due_date')) {
      this.setState({ due_date: date, due_date_show: show });
    }
  }

  openSendEmail() {
    this.setState({
      openSendMail: true,
      sendMailStatus: 0,
      selectInvoice: [],
      selectDebitNote: [],
      selectRecript: [],
      receipt_date: `${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`,
      show_receipt_date: `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
    });
  }

  render() {
    let admin_id = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }
    return (
      <Container>
        <Grid fluid style={{ paddingTop: 5, marginBottom: '40px' }}>
          <Row>
            <Col md={12}>
              <CardTable
                title="รายการรายรับ"
                category="ข้อมูลใบแจ้งหนี้ ใบเพิ่มหนี้ และ ใบเสร็จรับเงิน"
                ctTableFullWidth
                ctTableResponsive
                rightButton={
                  <div style={{ float: 'right', marginLeft: '15px' }}>
                    <Button bsStyle="info" onClick={() => this.openSendEmail()}>
                      <i className="fal fa-mail"></i> ส่งอีเมล{' '}
                    </Button>

                    {this.state.selectTap == 1 && (
                      <Button
                        style={{ marginLeft: '15px' }}
                        onClick={() => this.openReportInvoice('acc', admin_id)}
                      >
                        {' '}
                        <i className="fal fa-clipboard-list"></i> รายงานใบแจ้งหนี้(ACC){' '}
                      </Button>
                    )}
                    {this.state.selectTap == 1 && (
                      <Button
                        style={{ marginLeft: '15px' }}
                        onClick={() => this.openReportInvoice('ops', admin_id)}
                      >
                        {' '}
                        <i className="fal fa-clipboard-list"></i> รายงานใบแจ้งหนี้(Shipment){' '}
                      </Button>
                    )}
                  </div>
                }
                content={
                  <div>
                    <TabHeaderContainer>
                      <div className="sub-header-left">
                        <div className="menu-wrapper">
                          <ul>
                            <li className={this.state.selectTap == 1 && 'active'}>
                              <a
                                onClick={() =>
                                  this.setState({
                                    selectTap: 1,
                                    transporter: '',
                                    search_account_info_id: null,
                                  })
                                }
                              >
                                ใบแจ้งหนี้
                              </a>
                            </li>
                            <li className={this.state.selectTap == 2 && 'active'}>
                              <a
                                onClick={() =>
                                  this.setState({
                                    selectTap: 2,
                                    transporter: '',
                                    search_account_info_id: null,
                                  })
                                }
                              >
                                ใบเพิ่มหนี้
                              </a>
                            </li>
                            <li className={this.state.selectTap == 3 && 'active'}>
                              <a
                                onClick={() =>
                                  this.setState({
                                    selectTap: 3,
                                    transporter: '',
                                    search_account_info_id: null,
                                  })
                                }
                              >
                                ใบเสร็จรับเงิน
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </TabHeaderContainer>

                    <TabContentContainer>
                      {this.state.selectTap == 1 && (
                        <Query
                          query={summaryInvoiceListQuery}
                          variables={{
                            show_data: 30,
                            page_number: this.state.page_number,
                            status: this.state.summary_invoice_status,
                            order_by: this.state.order_by,
                            order_type: this.state.order_type,
                            summary_invoice_number: this.state.summary_invoice_number,
                            account_info_id: this.state.search_account_info_id,
                          }}
                        >
                          {({ loading, error, data, refetch, variables }) => {
                            let invoiceList = [];
                            let invoiceTotal = 0;
                            if (!loading && !error) {
                              invoiceList = data.summaryInvoiceList.summaryInvoice;
                              invoiceTotal = data.summaryInvoiceList.total_page;
                            }
                            // if(error) return null;
                            // //console.log(variables);
                            var itemLength = 'loading';
                            const itemIndicatorSize = (this.state.page_number - 1) * 30;
                            if (data && data.summaryInvoiceList) {
                              if (data.summaryInvoiceList.summaryInvoice.length > 0) {
                                itemLength = data.summaryInvoiceList.summaryInvoice.length;
                              }
                            }

                            return (
                              <div className="tab-item">
                                <PageWrapper style={{ textAlign: 'right' }}>
                                  <div className="text">
                                    {this.state.page_number === 1
                                      ? `1 - ${itemLength}`
                                      : `${itemIndicatorSize + 1} - ${itemLength +
                                          itemIndicatorSize}`}{' '}
                                    หน้า :
                                  </div>
                                  <FormControl
                                    type="number"
                                    min={1}
                                    value={this.state.page_number}
                                    onChange={event =>
                                      setInterval(
                                        this.nextPage(
                                          Number(event.target.value) - 1,
                                          loading || error ? '0' : invoiceTotal,
                                          refetch
                                        ),
                                        1000
                                      )
                                    }
                                    name="page_number"
                                  />
                                  <div className="text">
                                    {' '}
                                    {loading || error ? '' : `จาก ${invoiceTotal}`}
                                  </div>
                                </PageWrapper>
                                <div className="table-vertical">
                                  <Table striped hover>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '110px', minWidth: '110px' }}>
                                          เครื่องมือ
                                          <small>ชำระเงิน / เอกสาร</small>
                                        </th>
                                        <th
                                          style={{ width: '165px', minWidth: '165px' }}
                                          className={this.renderSorting('summary_invoice_number')}
                                          onClick={() =>
                                            this.sort('summary_invoice_number', refetch)
                                          }
                                        >
                                          เลขที่ใบแจ้งหนี้
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                        <th
                                          style={{ width: '165px', minWidth: '165px' }}
                                          className={this.renderSorting(
                                            'account_info_business_name'
                                          )}
                                          onClick={() =>
                                            this.sort('summary_invoice_number', refetch)
                                          }
                                        >
                                          ลูกค้า
                                          <small>-</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          สถานะ
                                          <small>วัน - เวลา ที่อัพเดท</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          credit used
                                          <small>-</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          จำนวนเงิน
                                          <small>ช่องทางชำระงานขนส่ง</small>
                                        </th>
                                        <th style={{ width: '160px', minWidth: '160px' }}>
                                          วันครบกำหนดชำระ
                                          <small>รอบบิล</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          ช่องทางชำระ
                                          <small>วัน - เวลา ที่ชำระ</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          ใบเสร็จรับเงิน
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                        <th style={{ width: '200px', minWidth: '200px' }}>
                                          ใบเพิ่มหนี้ <span>(กรณีมีค่าใช้จ่ายเพิ่มเติม)</span>
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                      </tr>
                                      <tr className="tableFilter">
                                        <th></th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            value={this.state.summary_invoice_number}
                                            placeholder="ค้นหา"
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  summary_invoice_number: event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="summary_invoice_number"
                                          />
                                        </th>
                                        <th>
                                          <Autosuggest
                                            suggestions={this.state.transporterSuggestions}
                                            onSuggestionsFetchRequested={
                                              this.onSuggestionsFetchRequested
                                            }
                                            onSuggestionsClearRequested={
                                              this.onSuggestionsClearRequested
                                            }
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            focusInputOnSuggestionClick={false}
                                            inputProps={{
                                              placeholder: 'ค้นหา',
                                              value: this.state.transporter,
                                              onChange: event =>
                                                this.setState({
                                                  transporter: event.target.value,
                                                  search_account_info_id: null,
                                                }),
                                              type: 'text',
                                              className: 'form-control',
                                            }}
                                            onSuggestionSelected={(event, { suggestion }) => {
                                              this.setState(
                                                {
                                                  search_account_info_id: suggestion.id,
                                                  transporter: suggestion.name,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              );
                                            }}
                                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                                          />
                                        </th>
                                        <th>
                                          <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.summary_invoice_status}
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  summary_invoice_status: event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="summary_invoice_status"
                                          >
                                            <option value={0}>ทั้งหมด</option>
                                            <option value={1}>รอชำระ</option>
                                            <option value={2}>รอตรวจสอบ</option>
                                            <option value={3}>ชำระแล้ว</option>
                                            <option value={4}>ค้างชำระ</option>
                                            <option value={5}>แบบร่าง</option>
                                          </FormControl>
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading || error ? (
                                        <tr>
                                          <td colSpan="9" style={{ textAlign: 'center' }}>
                                            loading...
                                          </td>
                                        </tr>
                                      ) : invoiceList.length === 0 ? (
                                        <tr>
                                          <td colSpan="9" style={{ textAlign: 'center' }}>
                                            ไม่พบข้อมูล
                                          </td>
                                        </tr>
                                      ) : (
                                        invoiceList.map((prop, key) => (
                                          <tr key={key} data-id={prop._id}>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={() =>
                                                  window.open(
                                                    `/paymentdetail?id=${prop._id}&type=invoice`,
                                                    '_blank'
                                                  )
                                                }
                                              >
                                                {' '}
                                                ดูรายละเอียด{' '}
                                              </button>
                                              {/* <button type="button" className="btn btn-default" onClick={() => window.open(`${s3}/${prop.invoice_document}`,"_blank")}> ดูรายละเอียด </button> */}
                                            </td>
                                            <td>
                                              <span style={{ float: 'left' }}>
                                                {prop.summary_invoice_number}
                                              </span>{' '}
                                              {prop.send_email == 1 && (
                                                <span
                                                  style={{ marginLeft: '7px' }}
                                                  class="label label-info"
                                                >
                                                  <i className="fas fa-envelope-open-text"></i>
                                                </span>
                                              )}{' '}
                                              {/*{prop.read_status == 1 && (<span className="new">NEW!</span>)} */}
                                              <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {prop.issue_date}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {prop.account_info_business_name}
                                              <small>
                                                <a
                                                  href={`/giztixbusinessdetail?id=${prop.account_info_id}`}
                                                  style={{ color: '#666666' }}
                                                  target="_blank"
                                                >
                                                  ดูข้อมูลลูกค้า
                                                </a>
                                              </small>
                                            </td>
                                            <td>
                                              {this.renderStatus(prop.status)}
                                              <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {prop.last_update}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {parseFloat(prop.credit_used).toLocaleString(
                                                'en-US',
                                                { maximumFractionDigits: 2 }
                                              )}{' '}
                                              บาท
                                            </td>
                                            <td>
                                              {parseFloat(prop.summary_total_price).toLocaleString(
                                                'en-US',
                                                {
                                                  maximumFractionDigits: 2,
                                                }
                                              )}{' '}
                                              บาท
                                              <small>
                                                {this.renderPaidTypeShipment(prop.paid_type)}
                                              </small>
                                            </td>
                                            <td>
                                              <Moment format="DD/MM/YYYY">{prop.due_date}</Moment>
                                              <small>
                                                <Moment format="DD/MM/YYYY">
                                                  {prop.bill_date_start}
                                                </Moment>{' '}
                                                -{' '}
                                                <Moment format="DD/MM/YYYY">
                                                  {prop.bill_date_end}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {prop.paid_type ? (
                                                this.renderPaidType(prop.paid_type)
                                              ) : (
                                                <small>รอชำระ</small>
                                              )}
                                              {prop.receipt_create_date && (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.receipt_create_date}
                                                  </Moment>
                                                </small>
                                              )}
                                            </td>
                                            <td>
                                              {prop.receipt_number
                                                ? prop.receipt_number
                                                : prop.paid_type == 4 && prop.status == 2
                                                ? 'รอตรวจสอบ'
                                                : '-'}
                                              {prop.receipt_date && (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.receipt_date}
                                                  </Moment>
                                                </small>
                                              )}
                                            </td>
                                            <td>
                                              {prop.debitNote != null &&
                                              prop.debitNote.length > 0 ? (
                                                prop.debitNote.length == 1 ? (
                                                  prop.debitNote[0].debit_note_number
                                                ) : (
                                                  `${prop.debitNote.length} รายการ`
                                                )
                                              ) : (
                                                <small>- ไม่มี -</small>
                                              )}
                                              {prop.debitNote != null &&
                                                prop.debitNote.length == 1 && (
                                                  <small>
                                                    <Moment format="DD/MM/YYYY HH:mm">
                                                      {prop.debitNote[0].create_date}
                                                    </Moment>
                                                  </small>
                                                )}
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                  {loading || error ? null : (
                                    <ReactPaginate
                                      pageCount={invoiceTotal}
                                      forcePage={this.state.page_number - 1}
                                      containerClassName="pagination"
                                      nextLabel=">"
                                      previousLabel="<"
                                      onPageChange={({ selected }) => {
                                        this.nextPage(selected, invoiceTotal, refetch);
                                      }}
                                    />
                                  )}
                                </div>

                                <Modal
                                  show={this.state.showReportInvoice}
                                  onHide={() => this.setState({ showReportInvoice: false })}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      {this.state.reportMode == 'ops'
                                        ? 'รายงานใบแจ้งหนี้ แยกตาม Shipment'
                                        : 'รายงาน  summary invoice สำหรับ Accounting'}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Row style={{ marginBottom: '10px' }}>
                                      <Col md={12}> ช่วงวันที่</Col>
                                    </Row>
                                    <FormGroup controlId="formValidationError1">
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePickerReportInvoice(event, picker)
                                        }
                                        onCancel={() =>
                                          this.setState({
                                            reportDateInvoice: '',
                                            show_reportDateInvoice: '',
                                          })
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.show_reportDateInvoice}
                                          placeholder="ค้นหาวันที่"
                                          name="reportDate"
                                        />
                                      </DateRangePicker>
                                    </FormGroup>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      bsStyle="primary"
                                      onClick={() => this.exportReportInvoice()}
                                    >
                                      ดาวน์โหลดรายงาน
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            );
                          }}
                        </Query>
                      )}

                      {this.state.selectTap == 2 && (
                        <Query
                          query={debitNoteListQuery}
                          variables={{
                            page_number: this.state.page_number_debitNote,
                            status: this.state.debit_note_status,
                            order_by: this.state.order_by_debitNote,
                            order_type: this.state.order_type_debitNote,
                            debit_note_number: this.state.debit_note_number,
                            show_data: 30,
                            account_info_id: this.state.search_account_info_id,
                          }}
                        >
                          {({ loading, error, data, refetch, variables }) => {
                            let debitNoteList = [];
                            let debitNoteTotal = 0;
                            if (!loading && !error) {
                              debitNoteList = data.debitNoteList.debitNote;
                              debitNoteTotal = data.debitNoteList.total_page;
                            }
                            //if(loading) return "loading...";
                            // if(error) return null;

                            var itemLengthDebitNote = 'loading';
                            const itemIndicatorSizeDebitNote =
                              (this.state.page_number_debitNote - 1) * 30;
                            if (data && data.debitNoteList) {
                              if (data.debitNoteList.debitNote.length > 0) {
                                itemLengthDebitNote = data.debitNoteList.debitNote.length;
                              }
                            }

                            return (
                              <div className="tab-item">
                                <PageWrapper style={{ textAlign: 'right' }}>
                                  <div className="text">
                                    {this.state.page_number_debitNote === 1
                                      ? `1 - ${itemLengthDebitNote}`
                                      : `${itemIndicatorSizeDebitNote + 1} - ${itemLengthDebitNote +
                                          itemIndicatorSizeDebitNote}`}{' '}
                                    หน้า :
                                  </div>
                                  <FormControl
                                    type="number"
                                    min={1}
                                    value={this.state.page_number_debitNote}
                                    onChange={event =>
                                      setInterval(
                                        this.nextPage(
                                          Number(event.target.value) - 1,
                                          loading || error ? '0' : debitNoteTotal,
                                          refetch
                                        ),
                                        1000
                                      )
                                    }
                                    name="page_number_debitNote"
                                  />
                                  <div className="text">
                                    {' '}
                                    {loading || error ? '' : `จาก ${debitNoteTotal}`}
                                  </div>
                                </PageWrapper>
                                <div className="table-vertical">
                                  <Table striped hover>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '110px', minWidth: '110px' }}>
                                          เครื่องมือ
                                          <small>ชำระเงิน / เอกสาร</small>
                                        </th>
                                        <th
                                          style={{ width: '165px', minWidth: '165px' }}
                                          className={this.renderSortingDebitNote(
                                            'debit_note_number'
                                          )}
                                          onClick={() =>
                                            this.sortDebitNote('debit_note_number', refetch)
                                          }
                                        >
                                          เลขที่ใบเพิ่มหนี้
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                        <th
                                          style={{ width: '165px', minWidth: '165px' }}
                                          className={this.renderSortingDebitNote(
                                            'account_info_business_name'
                                          )}
                                          onClick={() =>
                                            this.sortDebitNote(
                                              'account_info_business_name',
                                              refetch
                                            )
                                          }
                                        >
                                          ลูกค้า
                                          <small>-</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          สถานะ
                                          <small>วัน - เวลา ที่อัพเดท</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          จำนวนเงิน
                                          <small>-</small>
                                        </th>
                                        <th style={{ width: '160px', minWidth: '160px' }}>
                                          วันครบกำหนดชำระ
                                          <small>-</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          ช่องทางชำระ
                                          <small>วัน - เวลา ที่ชำระ</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          ใบเสร็จรับเงิน
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          ใบแจ้งหนี้
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                      </tr>

                                      <tr className="tableFilter">
                                        <th></th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            value={this.state.debit_note_number}
                                            placeholder="ค้นหา"
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  debit_note_number: event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="debit_note_number"
                                          />
                                        </th>
                                        <th>
                                          <Autosuggest
                                            suggestions={this.state.transporterSuggestions}
                                            onSuggestionsFetchRequested={
                                              this.onSuggestionsFetchRequested
                                            }
                                            onSuggestionsClearRequested={
                                              this.onSuggestionsClearRequested
                                            }
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            focusInputOnSuggestionClick={false}
                                            inputProps={{
                                              placeholder: 'ค้นหา',
                                              value: this.state.transporter,
                                              onChange: event =>
                                                this.setState({
                                                  transporter: event.target.value,
                                                  search_account_info_id: null,
                                                }),
                                              type: 'text',
                                              className: 'form-control',
                                            }}
                                            onSuggestionSelected={(event, { suggestion }) => {
                                              this.setState(
                                                {
                                                  search_account_info_id: suggestion.id,
                                                  transporter: suggestion.name,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              );
                                            }}
                                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                                          />
                                        </th>
                                        <th>
                                          <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.debit_note_status}
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  debit_note_status: event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="debit_note_status"
                                          >
                                            <option value={0}>ทั้งหมด</option>
                                            <option value={1}>รอชำระ</option>
                                            <option value={2}>รอตรวจสอบ</option>
                                            <option value={3}>ชำระแล้ว</option>
                                            <option value={4}>ค้างชำระ</option>
                                            <option value={5}>แบบร่าง</option>
                                          </FormControl>
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading || error ? (
                                        <tr>
                                          <td colSpan="9" style={{ textAlign: 'center' }}>
                                            loading...
                                          </td>
                                        </tr>
                                      ) : debitNoteList.length === 0 ? (
                                        <tr>
                                          <td colSpan="9" style={{ textAlign: 'center' }}>
                                            ไม่พบข้อมูล
                                          </td>
                                        </tr>
                                      ) : (
                                        debitNoteList.map((prop, key) => (
                                          <tr key={key} data-id={prop._id}>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={() =>
                                                  window.open(
                                                    `/paymentdetail?id=${prop.summary_invoice_id}&type=debitnote`,
                                                    '_blank'
                                                  )
                                                }
                                              >
                                                {' '}
                                                ดูรายละเอียด{' '}
                                              </button>
                                            </td>
                                            <td>
                                              {prop.debit_note_number}{' '}
                                              {prop.read_status == 1 && (
                                                <span className="new">NEW!</span>
                                              )}
                                              <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {prop.issue_date}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {prop.account_info_business_name}
                                              <small>
                                                <a
                                                  href={`/giztixbusinessdetail?id=${prop.account_info_id}`}
                                                  style={{ color: '#666666' }}
                                                  target="_blank"
                                                >
                                                  ดูข้อมูลลูกค้า
                                                </a>
                                              </small>
                                            </td>
                                            <td>
                                              {this.renderStatus(prop.status)}
                                              <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {prop.last_update}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {parseFloat(
                                                prop.debit_note_total_price
                                              ).toLocaleString('en-US', {
                                                maximumFractionDigits: 2,
                                              })}{' '}
                                              บาท
                                            </td>
                                            <td>
                                              <Moment format="DD/MM/YYYY">{prop.due_date}</Moment>
                                            </td>
                                            <td>
                                              {prop.paid_type ? (
                                                this.renderPaidType(prop.paid_type)
                                              ) : (
                                                <small>รอชำระ</small>
                                              )}
                                              {prop.receipt_create_date && (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.receipt_create_date}
                                                  </Moment>
                                                </small>
                                              )}
                                            </td>
                                            <td>
                                              {prop.receipt_number
                                                ? prop.receipt_number
                                                : prop.paid_type == 4 && prop.status == 2
                                                ? 'รอตรวจสอบ'
                                                : '-'}
                                              {prop.receipt_date && (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.receipt_date}
                                                  </Moment>
                                                </small>
                                              )}
                                            </td>
                                            <td>
                                              {prop.summary_invoice_number ? (
                                                prop.summary_invoice_number
                                              ) : (
                                                <small>- ไม่มี -</small>
                                              )}
                                              {prop.summary_invoice_create_date && (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.summary_invoice_create_date}
                                                  </Moment>
                                                </small>
                                              )}
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                  {loading || error ? null : (
                                    <ReactPaginate
                                      pageCount={debitNoteTotal}
                                      forcePage={this.state.page_number_debitNote - 1}
                                      containerClassName="pagination"
                                      nextLabel=">"
                                      previousLabel="<"
                                      onPageChange={({ selected }) => {
                                        this.nextPageDebitNote(selected, debitNoteTotal, refetch);
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }}
                        </Query>
                      )}

                      {this.state.selectTap == 3 && (
                        <Query
                          query={receiptSummaryListQuery}
                          variables={{
                            page_number: this.state.page_number_receipt,
                            receipt_number: this.state.receipt_number,
                            order_by: this.state.order_by_receipt,
                            order_type: this.state.order_type_receipt,
                            show_data: 30,
                            account_info_id: this.state.search_account_info_id,
                          }}
                        >
                          {({ loading, error, data, refetch, variables }) => {
                            let receipt = [];
                            let receiptTotal = 0;
                            if (!loading && !error) {
                              receipt = data.receiptSummaryList.receipt;
                              receiptTotal = data.receiptSummaryList.total_page;
                            }
                            // if(loading) return "loading...";
                            // if(error) return null;
                            // //console.log(variables);

                            var itemLengthReceipt = 'loading';
                            const itemIndicatorSizeDebitNote =
                              (this.state.page_number_receipt - 1) * 30;
                            if (data && data.receiptSummaryList) {
                              if (data.receiptSummaryList.receipt.length > 0) {
                                itemLengthReceipt = data.receiptSummaryList.receipt.length;
                              }
                            }

                            return (
                              <div className="tab-item">
                                <PageWrapper style={{ textAlign: 'right' }}>
                                  <div className="text">
                                    {this.state.page_number_receipt === 1
                                      ? `1 - ${itemLengthReceipt}`
                                      : `${itemIndicatorSizeDebitNote + 1} - ${itemLengthReceipt +
                                          itemIndicatorSizeDebitNote}`}{' '}
                                    หน้า :
                                  </div>
                                  <FormControl
                                    type="number"
                                    min={1}
                                    value={this.state.page_number_receipt}
                                    onChange={event =>
                                      setInterval(
                                        this.nextPage(
                                          Number(event.target.value) - 1,
                                          loading || error ? '0' : receiptTotal,
                                          refetch
                                        ),
                                        1000
                                      )
                                    }
                                    name="page_number_receipt"
                                  />
                                  <div className="text">
                                    {' '}
                                    {loading || error ? '' : `จาก ${receiptTotal}`}
                                  </div>
                                </PageWrapper>
                                <div className="table-vertical">
                                  <Table striped hover>
                                    <thead>
                                      <tr>
                                        <th style={{ width: '110px', minWidth: '110px' }}>
                                          เครื่องมือ
                                          <small>ชำระเงิน / เอกสาร</small>
                                        </th>
                                        <th
                                          style={{ width: '165px', minWidth: '165px' }}
                                          className={this.renderSortingReceipt('receipt_number')}
                                          onClick={() =>
                                            this.sortReceipt('receipt_number', refetch)
                                          }
                                        >
                                          เลขที่ใบเสร็จรับเงิน
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                        <th
                                          style={{ width: '165px', minWidth: '165px' }}
                                          className={this.renderSorting(
                                            'account_info_business_name'
                                          )}
                                          onClick={() =>
                                            this.sort('summary_invoice_number', refetch)
                                          }
                                        >
                                          ลูกค้า
                                          <small>-</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          สถานะ
                                          <small>วัน - เวลา ที่อัพเดท</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          จำนวนเงิน
                                          <small>ช่องทางชำระงานขนส่ง</small>
                                        </th>
                                        <th style={{ width: '160px', minWidth: '160px' }}>
                                          วันครบกำหนดชำระ
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          ช่องทางชำระ
                                          <small>วัน - เวลา ที่ชำระ</small>
                                        </th>
                                        <th style={{ width: '140px', minWidth: '140px' }}>
                                          รหัสอ้างอิง
                                          <small>วัน - เวลา ที่สร้าง</small>
                                        </th>
                                      </tr>
                                      <tr className="tableFilter">
                                        <th></th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            value={this.state.receipt_number}
                                            placeholder="ค้นหา"
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  receipt_number: event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="receipt_number"
                                          />
                                        </th>
                                        <th>
                                          <Autosuggest
                                            suggestions={this.state.transporterSuggestions}
                                            onSuggestionsFetchRequested={
                                              this.onSuggestionsFetchRequested
                                            }
                                            onSuggestionsClearRequested={
                                              this.onSuggestionsClearRequested
                                            }
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            focusInputOnSuggestionClick={false}
                                            inputProps={{
                                              placeholder: 'ค้นหา',
                                              value: this.state.transporter,
                                              onChange: event =>
                                                this.setState({
                                                  transporter: event.target.value,
                                                  search_account_info_id: null,
                                                }),
                                              type: 'text',
                                              className: 'form-control',
                                            }}
                                            onSuggestionSelected={(event, { suggestion }) => {
                                              this.setState(
                                                {
                                                  search_account_info_id: suggestion.id,
                                                  transporter: suggestion.name,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              );
                                            }}
                                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                                          />
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading || error ? (
                                        <tr>
                                          <td colSpan="9" style={{ textAlign: 'center' }}>
                                            loading...
                                          </td>
                                        </tr>
                                      ) : receipt.length === 0 ? (
                                        <tr>
                                          <td colSpan="9" style={{ textAlign: 'center' }}>
                                            ไม่พบข้อมูล
                                          </td>
                                        </tr>
                                      ) : (
                                        receipt.map((prop, key) => (
                                          <tr>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-default"
                                                onClick={() =>
                                                  window.open(
                                                    `/paymentdetail?id=${prop.summary_invoice_id}&type=receipt`,
                                                    '_blank'
                                                  )
                                                }
                                              >
                                                {' '}
                                                ดูรายละเอียด{' '}
                                              </button>
                                            </td>
                                            <td>
                                              {prop.receipt_number}{' '}
                                              {prop.read_status == 1 && (
                                                <span className="new">NEW!</span>
                                              )}
                                              <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {prop.receipt_date}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {prop.account_info_business_name}
                                              <small>
                                                <a
                                                  href={`/giztixbusinessdetail?id=${prop.account_info_id}`}
                                                  style={{ color: '#666666' }}
                                                  target="_blank"
                                                >
                                                  ดูข้อมูลลูกค้า
                                                </a>
                                              </small>
                                            </td>
                                            <td>
                                              {this.renderStatusReceipt(prop.status)}
                                              <small>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {prop.paid_type == 4
                                                    ? prop.paid_bank_transfer.transfer_date
                                                    : prop.receipt_date}
                                                </Moment>
                                              </small>
                                            </td>
                                            <td>
                                              {parseFloat(prop.receipt_total_price).toLocaleString(
                                                'en-US',
                                                {
                                                  maximumFractionDigits: 2,
                                                }
                                              )}{' '}
                                              บาท
                                              <small>
                                                {this.renderPaidTypeShipment(prop.paid_type)}
                                              </small>
                                            </td>
                                            <td>
                                              <Moment format="DD/MM/YYYY">{prop.due_date}</Moment>
                                            </td>
                                            <td>
                                              {prop.paid_type ? (
                                                this.renderPaidType(prop.paid_type)
                                              ) : (
                                                <small>รอชำระ</small>
                                              )}
                                              {prop.create_date && (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.issue_date}
                                                  </Moment>
                                                </small>
                                              )}
                                            </td>
                                            <td>
                                              {prop.summary_invoice_number
                                                ? prop.summary_invoice_number
                                                : prop.debit_note_number}
                                              {prop.summary_invoice_create_date ||
                                              prop.debit_note_create_date ? (
                                                <small>
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {prop.summary_invoice_create_date
                                                      ? prop.summary_invoice_create_date
                                                      : prop.debit_note_create_date}
                                                  </Moment>
                                                </small>
                                              ) : null}
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                                <div style={{ textAlign: 'right' }}>
                                  {loading || error ? null : (
                                    <ReactPaginate
                                      pageCount={receiptTotal}
                                      forcePage={this.state.page_number_receipt - 1}
                                      containerClassName="pagination"
                                      nextLabel=">"
                                      previousLabel="<"
                                      onPageChange={({ selected }) => {
                                        this.nextPageReceipt(selected, receiptTotal, refetch);
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }}
                        </Query>
                      )}

                      <Mutation
                        mutation={sendEmailDocumentMutation}
                        variables={this.renderVariablesSendEmailDocument()}
                        update={() => {
                          this.setState({ openSendMail: false });
                        }}
                      >
                        {sendEmailDocument => (
                          <Modal
                            show={this.state.openSendMail}
                            onHide={() => this.setState({ openSendMail: false })}
                          >
                            <Modal.Body>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col md={12}>
                                  <TitleModal>
                                    ส่งอีเมล
                                    {this.state.selectTap == 1
                                      ? 'ใบแจ้งหนี้'
                                      : this.state.selectTap == 2
                                      ? 'ใบเพิ่มหนี้'
                                      : 'ใบเสร็จรับเงิน'}
                                  </TitleModal>
                                  เลือกรายการที่ต้องการส่งอีเมล
                                </Col>
                              </Row>
                              <Blankspace />
                              <Row>
                                <Col md={12}>
                                  <AdvancePayment>
                                    {this.state.selectTap == 1 && (
                                      <Query
                                        query={summaryInvoiceListQuery}
                                        variables={{
                                          status: this.state.sendMailStatus,
                                          show_data: 10000,
                                          create_date: this.state.receipt_date,
                                        }}
                                      >
                                        {({ loading, error, data, refetch, variables }) => {
                                          if (loading) return 'loading...';
                                          // if(error) return null;
                                          console.log(data);
                                          const invoiceList =
                                            data.summaryInvoiceList.summaryInvoice;
                                          return (
                                            <div>
                                              <div style={{ marginBottom: '10px' }}>
                                                <Col md={6}>
                                                  <Row>
                                                    <Col
                                                      md={12}
                                                      style={{
                                                        paddingLeft: 0,
                                                        paddingBottom: '15px',
                                                      }}
                                                    >
                                                      เลือกสถานะ ใบแจ้งหนี้
                                                    </Col>
                                                    <Col md={12} style={{ paddingLeft: 0 }}>
                                                      <FormControl
                                                        componentClass="select"
                                                        placeholder="select"
                                                        defaultValue={this.state.sendMailStatus}
                                                        onChange={event =>
                                                          this.setState(
                                                            { sendMailStatus: event.target.value },
                                                            () => {
                                                              refetch();
                                                            }
                                                          )
                                                        }
                                                        name="summary_invoice_status"
                                                      >
                                                        <option value={0}>ทั้งหมด</option>
                                                        <option value={1}>รอชำระ</option>
                                                        <option value={2}>รอตรวจสอบ</option>
                                                        <option value={3}>ชำระแล้ว</option>
                                                        <option value={4}>ค้างชำระ</option>
                                                        <option value={5}>แบบร่าง</option>
                                                      </FormControl>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col md={6}>
                                                  <Row>
                                                    <Col
                                                      md={12}
                                                      style={{
                                                        paddingLeft: 0,
                                                        paddingBottom: '15px',
                                                      }}
                                                    >
                                                      เลือกวันที่สร้าง ใบแจ้งหนี้
                                                    </Col>
                                                    <Col md={12} style={{ paddingLeft: 0 }}>
                                                      <FormGroup controlId="formValidationError1">
                                                        <DateRangePicker
                                                          onApply={(event, picker) =>
                                                            this.handleDateRangePicker(
                                                              'receipt_date',
                                                              event,
                                                              picker,
                                                              refetch
                                                            )
                                                          }
                                                          onCancel={() =>
                                                            this.setState({
                                                              receipt_date: null,
                                                              show_receipt_date: '',
                                                            })
                                                          }
                                                          containerStyles={{ display: 'block' }}
                                                          opens="right"
                                                          format="DD/MM/YYYY"
                                                        >
                                                          <FormControl
                                                            type="text"
                                                            value={this.state.show_receipt_date}
                                                            placeholder="ค้นหาวันที่"
                                                            name="receipt_date"
                                                          />
                                                        </DateRangePicker>
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </div>
                                              {invoiceList.length > 0 ? (
                                                invoiceList.map((prop, key) => (
                                                  <div
                                                    className={`item ${
                                                      this.checkItemSelected(prop._id, 'invoice')
                                                        ? 'selected'
                                                        : ''
                                                    }`}
                                                    key={key}
                                                    data-id={prop._id}
                                                    onClick={() =>
                                                      this.onClickItem(prop._id, 'invoice')
                                                    }
                                                  >
                                                    <div className="icon-wrapper">
                                                      {this.checkItemSelected(
                                                        prop._id,
                                                        'invoice'
                                                      ) ? (
                                                        <i className="fas fa-check-square"></i>
                                                      ) : (
                                                        <i className="fal fa-square"></i>
                                                      )}
                                                    </div>
                                                    <div className="detail-wrapper">
                                                      <div className="titel">
                                                        {this.renderStatus(prop.status)}{' '}
                                                        {prop.summary_invoice_number}{' '}
                                                        {prop.send_email == 1 && (
                                                          <span class="label label-success">
                                                            ส่งเมลแล้ว
                                                          </span>
                                                        )}
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">วันที่สร้าง:</div>
                                                        <div className="right">
                                                          <Moment format="DD/MM/YYYY">
                                                            {prop.issue_date}
                                                          </Moment>
                                                        </div>
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">จำนวนเงิน:</div>
                                                        <div className="right">
                                                          {parseFloat(
                                                            prop.summary_total_price
                                                          ).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2,
                                                          })}{' '}
                                                          บาท{' '}
                                                        </div>
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">ลูกค้า:</div>
                                                        <div className="right">
                                                          {prop.account_info_business_name}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div>ไม่มีรายการ ที่ต้องส่งอีเมล</div>
                                              )}
                                            </div>
                                          );
                                        }}
                                      </Query>
                                    )}

                                    {this.state.selectTap == 2 && (
                                      <Query
                                        query={debitNoteListQuery}
                                        variables={{
                                          status: this.state.sendMailStatus,
                                          show_data: 10000,
                                          create_date: this.state.receipt_date,
                                        }}
                                      >
                                        {({ loading, error, data, refetch, variables }) => {
                                          if (loading) return 'loading...';
                                          // if(error) return null;
                                          // //console.log(variables);
                                          const debitNoteList = data.debitNoteList.debitNote;
                                          return (
                                            <div>
                                              <div style={{ marginBottom: '10px' }}>
                                                <Col md={6}>
                                                  <Row>
                                                    <Col
                                                      md={12}
                                                      style={{
                                                        paddingLeft: 0,
                                                        paddingBottom: '15px',
                                                      }}
                                                    >
                                                      เลือกสถานะ ใบเพิ่มหนี้
                                                    </Col>
                                                    <Col md={12} style={{ paddingLeft: 0 }}>
                                                      <FormControl
                                                        componentClass="select"
                                                        placeholder="select"
                                                        defaultValue={this.state.sendMailStatus}
                                                        onChange={event =>
                                                          this.setState(
                                                            { sendMailStatus: event.target.value },
                                                            () => {
                                                              refetch();
                                                            }
                                                          )
                                                        }
                                                        name="summary_invoice_status"
                                                      >
                                                        <option value={0}>ทั้งหมด</option>
                                                        <option value={1}>รอชำระ</option>
                                                        <option value={2}>รอตรวจสอบ</option>
                                                        <option value={3}>ชำระแล้ว</option>
                                                        <option value={4}>ค้างชำระ</option>
                                                        <option value={5}>แบบร่าง</option>
                                                      </FormControl>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                <Col md={6}>
                                                  <Row>
                                                    <Col
                                                      md={12}
                                                      style={{
                                                        paddingLeft: 0,
                                                        paddingBottom: '15px',
                                                      }}
                                                    >
                                                      เลือกวันที่สร้าง ใบเพิ่มหนี้
                                                    </Col>
                                                    <Col md={12} style={{ paddingLeft: 0 }}>
                                                      <FormGroup controlId="formValidationError1">
                                                        <DateRangePicker
                                                          onApply={(event, picker) =>
                                                            this.handleDateRangePicker(
                                                              'receipt_date',
                                                              event,
                                                              picker,
                                                              refetch
                                                            )
                                                          }
                                                          onCancel={() =>
                                                            this.setState({
                                                              receipt_date: null,
                                                              show_receipt_date: '',
                                                            })
                                                          }
                                                          containerStyles={{ display: 'block' }}
                                                          opens="right"
                                                          format="DD/MM/YYYY"
                                                        >
                                                          <FormControl
                                                            type="text"
                                                            value={this.state.show_receipt_date}
                                                            placeholder="ค้นหาวันที่"
                                                            name="receipt_date"
                                                          />
                                                        </DateRangePicker>
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </div>
                                              {debitNoteList.length > 0 ? (
                                                debitNoteList.map((prop, key) => (
                                                  <div
                                                    className={`item ${
                                                      this.checkItemSelected(prop._id, 'debitnote')
                                                        ? 'selected'
                                                        : ''
                                                    }`}
                                                    key={key}
                                                    data-id={prop._id}
                                                    onClick={() =>
                                                      this.onClickItem(prop._id, 'debitnote')
                                                    }
                                                  >
                                                    <div className="icon-wrapper">
                                                      {this.checkItemSelected(
                                                        prop._id,
                                                        'debitnote'
                                                      ) ? (
                                                        <i className="fas fa-check-square"></i>
                                                      ) : (
                                                        <i className="fal fa-square"></i>
                                                      )}
                                                    </div>
                                                    <div className="detail-wrapper">
                                                      <div className="titel">
                                                        {this.renderStatus(prop.status)}{' '}
                                                        {prop.debit_note_number}{' '}
                                                        {prop.send_email == 1 && (
                                                          <span class="label label-success">
                                                            ส่งเมลแล้ว
                                                          </span>
                                                        )}
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">วันที่สร้าง:</div>
                                                        <div className="right">
                                                          <Moment format="DD/MM/YYYY">
                                                            {prop.issue_date}
                                                          </Moment>
                                                        </div>
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">จำนวนเงิน:</div>
                                                        <div className="right">
                                                          {parseFloat(
                                                            prop.debit_note_total_price
                                                          ).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2,
                                                          })}{' '}
                                                          บาท{' '}
                                                        </div>
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">ลูกค้า:</div>
                                                        <div className="right">
                                                          {prop.account_info_business_name}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div>ไม่มีรายการ ที่ต้องส่งอีเมล</div>
                                              )}
                                            </div>
                                          );
                                        }}
                                      </Query>
                                    )}

                                    {this.state.selectTap == 3 && (
                                      <Query
                                        query={receiptSummaryListQuery}
                                        variables={{
                                          receipt_date: this.state.receipt_date,
                                          show_data: 10000,
                                        }}
                                      >
                                        {({ loading, error, data, refetch, variables }) => {
                                          if (loading) return 'loading...';
                                          // if(error) return null;
                                          // //console.log(variables);
                                          const receipt = data.receiptSummaryList.receipt;
                                          return (
                                            <div>
                                              <div style={{ marginBottom: '10px' }}>
                                                <Col
                                                  md={12}
                                                  style={{ paddingLeft: 0, paddingBottom: '15px' }}
                                                >
                                                  เลือกวันที่สร้าง ใบเสร็จรับเงิน
                                                </Col>
                                                <Col md={12} style={{ paddingLeft: 0 }}>
                                                  <FormGroup controlId="formValidationError1">
                                                    <DateRangePicker
                                                      onApply={(event, picker) =>
                                                        this.handleDateRangePicker(
                                                          'receipt_date',
                                                          event,
                                                          picker,
                                                          refetch
                                                        )
                                                      }
                                                      onCancel={() =>
                                                        this.setState({
                                                          receipt_date: null,
                                                          show_receipt_date: '',
                                                        })
                                                      }
                                                      containerStyles={{ display: 'block' }}
                                                      opens="right"
                                                      format="DD/MM/YYYY"
                                                    >
                                                      <FormControl
                                                        type="text"
                                                        value={this.state.show_receipt_date}
                                                        placeholder="ค้นหาวันที่"
                                                        name="receipt_date"
                                                      />
                                                    </DateRangePicker>
                                                  </FormGroup>
                                                </Col>
                                              </div>

                                              {receipt.length > 0 ? (
                                                receipt.map((prop, key) => (
                                                  <div
                                                    className={`item ${
                                                      this.checkItemSelected(prop._id, 'receipt')
                                                        ? 'selected'
                                                        : ''
                                                    }`}
                                                    key={key}
                                                    data-id={prop._id}
                                                    onClick={() =>
                                                      this.onClickItem(prop._id, 'receipt')
                                                    }
                                                  >
                                                    <div className="icon-wrapper">
                                                      {this.checkItemSelected(
                                                        prop._id,
                                                        'receipt'
                                                      ) ? (
                                                        <i className="fas fa-check-square"></i>
                                                      ) : (
                                                        <i className="fal fa-square"></i>
                                                      )}
                                                    </div>
                                                    <div className="detail-wrapper">
                                                      <div className="titel">
                                                        {' '}
                                                        {prop.receipt_number}{' '}
                                                        {prop.send_email == 1 && (
                                                          <span class="label label-success">
                                                            ส่งเมลแล้ว
                                                          </span>
                                                        )}
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">วันที่สร้าง:</div>
                                                        <div className="right">
                                                          <Moment format="DD/MM/YYYY">
                                                            {prop.receipt_date}
                                                          </Moment>
                                                        </div>
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">จำนวนเงิน:</div>
                                                        <div className="right">
                                                          {parseFloat(
                                                            prop.receipt_total_price
                                                          ).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2,
                                                          })}{' '}
                                                          บาท{' '}
                                                        </div>
                                                      </div>
                                                      <div className="content">
                                                        <div className="left">ลูกค้า:</div>
                                                        <div className="right">
                                                          {prop.account_info_business_name}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))
                                              ) : (
                                                <div>ไม่มีรายการ ที่ต้องส่งอีเมล</div>
                                              )}
                                            </div>
                                          );
                                        }}
                                      </Query>
                                    )}
                                  </AdvancePayment>
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                bsStyle="defalut"
                                style={{ width: '120px' }}
                                onClick={() => this.setState({ openSendMail: false })}
                              >
                                ปิด
                              </Button>
                              <Button
                                className="btn btn-red"
                                style={{ width: '120px' }}
                                disabled={this.checkDisabledSendMail() ? false : 'disabled'}
                                onClick={e => sendEmailDocument()}
                              >
                                ยืนยัน
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </Mutation>
                    </TabContentContainer>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </Container>
    );
  }
}

export default compose(graphql(adminName, { name: 'adminName' }))(Payment);
