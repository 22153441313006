import gql from 'graphql-tag';

export default gql`
query manage3plAssignView(
    $import_order_id: ID
    ){
        manage3plAssignView(
            import_order_id: $import_order_id
      ){	
        _id
        ka_id
        import_order_id
        head_thrid_party
        
        trip
        trip_truck{
          truck_id
          truck_type
          name
          total
          total_average
        }
        warehouse
        warehouse_lat
        warehouse_lng
        trip_name
        
        third_party_list{
          account_info_id
          ka_id
          _id
          trip_amount
          trip_previous
          percent
          active
          queue_update
          trip
          sub_thirdparty_id
          trip_truck{
            truck_id
            truck_type
            name
            total
            total_average
            total_assign
          }
        }
        
        total_page
      }
    }
`;
