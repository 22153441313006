import gql from "graphql-tag";

export default gql`
  query search($shipment_id: ID,$check_agent_accept: ID) {
    trackingList(shipment_id: $shipment_id, check_agent_accept: $check_agent_accept) {
      tracking {
        _id
        shipment_tracking_number
        shipment_type
        tracking_status
        driver_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_accept
        driver_accept_date
        user_setting_truck_license_plate
        working
        review_detail
        review_score
        review_reason {
          th
          en
        }
        tracking_detail {
          droppoint
          pick_up_address
          customer_name
          customer_phone
          customer_phone_code

          contact_customer_date
          front_picture
          back_picture
          side_picture
          other_picture {
            path
          }
          take_picture_date
          problem_picture {
            path
          }
          problem_solved
          problem_take_picture_detail
          signature_picture
          signature_date
          pod_slip_picture
          pod_from
          pod_track
          pod_remark
          pod_date
        }
        tracking_import_detail {
          _id
          droppoint
          track_driver {
            _id
            driver_name
            driver_phone_code
            driver_phone_number
            license_plate_front
            license_plate_back
          }
          track_driver_date
          accept_container {
            _id
            cont_number
            seal_number
            tare_weight
            picture
          }
          accept_container_date
          return_container_date
        }
        tracking_export_detail {
          _id
          droppoint
          track_driver {
            _id
            driver_name
            driver_phone_code
            driver_phone_number
            license_plate_front
            license_plate_back
          }
          track_driver_date
          accept_container {
            _id
            cont_number
            seal_number
            tare_weight
            picture
          }
          accept_container_date
          commodity_picture
          commodity_picture_date
          deliver_document
          driver_accept_document
          return_container_date
        }
        favorite_driver
        driver_display_pic
        transporter
        transporter_detail
        transporter_phone
        transporter_phone_code
        transporter_date
      }
      total_page
    }
  }
`;
