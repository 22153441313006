import gql from 'graphql-tag';

export default gql`
    mutation setUpdateDriverMonthlyCalendar(
        $params: updateDriverMonthlyCalendarParams
        $update: updateDriverMonthlyCalendar
    ) {
        updateDriverMonthlyCalendar(
            params: $params
            update: $update
        ){
            _id
        }
    }
`;