import React, { useState, useRef, useEffect } from 'react';
import Moment from 'react-moment';
import { Query, Mutation } from 'react-apollo';
import { Grid, Row, Col, Table, FormControl, Button } from 'react-bootstrap';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import parcelOrdersListQuery from './graphql/query/parcelOrdersList';
import PrintLabelPreview from './PrintLabelPreview';
// import createLabelPacelsMutation from './graphql/mutation/createLabelPacels';
import updatePacelOrderPackageSizeMutation from './graphql/mutation/updatePacelOrderPackageSize';
import mergePDFDocuments from './PrintLabelPreview/mergePDFDocuments';
import correct from '../../assets/img/correct.png';
import printJS from 'print-js';
import { client } from '../..';
import './style.css';

const RowStyle = styled(Row)`
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
`;

const ReLabel = props => {
  const [status, setStatus] = useState(1);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [size, setSize] = useState('');
  const [sizeActive, setSizeActive] = useState(!!localStorage.getItem('ACTIVATE'));
  const [isOpenModalPrintLabelPreview, setIsOpenModalPrintLabelPreview] = useState(false);
  const [linkDownloadLabelParcel, setLinkDownloadLabelParcel] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isError, setIsError] = useState(false);

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);

  const searchTracking = async updatePacelOrderPackageSize => {
    try {
      if (trackingNumber != '') {
        const { data } = await client.query({
          query: parcelOrdersListQuery,
          fetchPolicy: 'network-only',
          variables: {
            params: {
              parcel_order_status: status,
              trackingNumber: trackingNumber,
            },
            showData: 1,
            page: 1,
          },
        });

        const findTrackingNumber = data.parcelOrders.orders.filter(itemFilter =>
          itemFilter.trackingNumber.includes(trackingNumber)
        );

        const productsNumber = findTrackingNumber.map(productItem => productItem.trackingNumber);

        if (productsNumber.length > 0) {
          updatePacelOrderPackageSize({
            variables: {
              trackingNumber: productsNumber[0],
              packageSize: size,
            },
          });
          setTrackingNumber('');
          setSize('');
          setIsError(false);
          inputRef1.current.focus();
        }
        if (productsNumber.length === 0) {
          setIsError(true);
          setTrackingNumber('');
          setSize('');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="content">
      <Mutation
        mutation={updatePacelOrderPackageSizeMutation}
        update={async (_cache, { data: updatePacelOrderPackageSizeData }) => {
          if (updatePacelOrderPackageSizeData.updatePacelOrderPackageSize.linkDownLoad !== null) {
            setLinkDownloadLabelParcel(
              updatePacelOrderPackageSizeData.updatePacelOrderPackageSize.linkDownLoad
            );
            setIsOpenModalPrintLabelPreview(true);

            printJS({
              printable: updatePacelOrderPackageSizeData.updatePacelOrderPackageSize.linkDownLoad,
              type: 'pdf',
              showModal: true,
            });
          } else {
            setIsOpenModalPrintLabelPreview(false);
          }
        }}
        onError={err => {
          alert(err.message);
        }}
      >
        {(updatePacelOrderPackageSize, { loading: updatePacelOrderPackageSizeLoading }) => (
          <Grid fluid>
            <Row>
              <Col md={12}>
                <CardTable
                  title=""
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div>
                      <RowStyle>
                        <Col md={2} style={{ fontWeight: 'bold' }}>
                          สถานะ
                        </Col>
                        <Col md={4}>
                          <FormControl
                            componentClass="select"
                            placeholder="select"
                            defaultValue={status}
                            onChange={event => setStatus(event.target.value)}
                            name="status"
                            style={{ height: 40 }}
                          >
                            <option value={0}>ทั้งหมด</option>
                            <option value={1}>คงคลังลูกค้า</option>
                            <option value={3}>กำลังไปคลัง GSC</option>
                            <option value={5}>คงคลัง Giztix</option>
                            <option value={7}>ออกจัดส่งลูกค้า</option>
                            <option value={8}>ส่งสำเร็จ</option>
                            <option value={9}>คืนคลังลูกค้า</option>
                            <option value={6}>ยกเลิก</option>
                          </FormControl>
                        </Col>
                      </RowStyle>
                      <RowStyle>
                        <Col md={2} style={{ fontWeight: 'bold' }}>
                          Tracking Number
                        </Col>
                        <Col md={4}>
                          <FormControl
                            autoFocus
                            type="text"
                            value={trackingNumber}
                            placeholder=""
                            inputRef={inputRef1}
                            onChange={event => {
                              setTrackingNumber(event.target.value);
                              // if (event.target.value != '') {
                              //   setTimeout(() => searchTracking(updatePacelOrderPackageSize), 1500);
                              // }
                            }}
                            onKeyPress={event => {
                              if (event.key === 'Enter' && !sizeActive) {
                                searchTracking(updatePacelOrderPackageSize);
                              }
                              if (event.key === 'Enter' && sizeActive) {
                                inputRef2.current.focus();
                              }
                            }}
                            name="trackingNumber"
                            style={{ height: 40 }}
                          />
                        </Col>
                      </RowStyle>
                      <RowStyle>
                        <Col md={2} style={{ fontWeight: 'bold' }}>
                          Size
                        </Col>
                        <Col md={4}>
                          <FormControl
                            disabled={!sizeActive}
                            autoFocus
                            type="text"
                            value={size}
                            placeholder=""
                            inputRef={inputRef2}
                            onChange={event => {
                              setSize(event.target.value);
                            }}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                searchTracking(updatePacelOrderPackageSize);
                              }
                            }}
                            name="size"
                            style={{ height: 40 }}
                          />
                        </Col>
                        <Col md={4} style={{ marginTop: 5 }}>
                          <label for="toggle-3" class="toggle-3">
                            <input
                              type="checkbox"
                              name="toggle-3"
                              id="toggle-3"
                              class="toggle-3__input"
                              checked={sizeActive}
                              onChange={value => {
                                if (sizeActive) {
                                  localStorage.removeItem('ACTIVATE');
                                } else {
                                  localStorage.setItem('ACTIVATE', true);
                                }

                                setSizeActive(value.target.checked);

                                if (value.target.checked === false) {
                                  setSize('');
                                }
                              }}
                            />
                            <span class="toggle-3__button">
                              <img src={correct} alt="correct" class="toggle-3__button--correct" />
                            </span>
                          </label>
                        </Col>
                      </RowStyle>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          disabled={!trackingNumber || updatePacelOrderPackageSizeLoading}
                          style={{
                            background: '#87CB16',
                            color: '#fff',
                            width: '200px',
                            marginTop: 10,
                          }}
                          onClick={() => searchTracking(updatePacelOrderPackageSize)}
                        >
                          ค้นหา
                        </Button>
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        )}
      </Mutation>
      {isOpenModalPrintLabelPreview && !isError && (
        <PrintLabelPreview linkDownloadLabelParcel={linkDownloadLabelParcel} autoPrint={true} />
      )}
      {isError && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          ไม่พบ Tracking Number ในสถานะนี้
        </div>
      )}
    </div>
  );
};

export default ReLabel;
