import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const Container = styled.div``;
const ModalStyle = styled(Modal)`
  & .modal-dialog {
    margin-top: 100px;

    @media(min-width: 768px) {
      ${props => props.width && `
        width: 920px;
      `}
    }
  }

  & .modal-body {
    padding: 25px 20px 20px 20px;
  }
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
  
  ${props => props.subTitle && `
    margin-bottom: 0px;
  `}
`;

const Content = styled.div``;
const Footer = styled.div`
  margin-top: 18px;
  text-align: right;
`;

const ButtonStyle =styled(Button)`
  min-width: 100px;
  height: 38px;
  box-sizing: border-box;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const SubHeader = styled.div`
  font-size:18px;
  color: #888888;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const ModalConfirm = (props) => {
  return (
    <Container>
      <ModalStyle {...props} show={props.show} onHide={props.onHide}>
        <ModalStyle.Body>
          {
            !!props.titleHeader && (
              <Header subTitle={props.subTitle}>{props.titleHeader}</Header>
            )
          }

          {
            !!props.subTitle && (
              <SubHeader>{props.subTitle}</SubHeader>
            )
          }

          <Content>
            {
              props.children
            }
          </Content>

          {
            (props.labelButtonCancel || props.labelButtonConfirm) && (
              <Footer>
                {
                  props.onPressButtonCancel && (
                    <ButtonStyle disabled={props.disabledButtonCancel} bsStyle={props.styleColorButtonCancel} onClick={props.onPressButtonCancel}>{props.labelButtonCancel}</ButtonStyle>
                  )
                }

                {
                  props.onPressButtonConfirm && (
                    <ButtonStyle disabled={props.disabledButtonConfirm} bsStyle={props.styleColorButtonConfirm} onClick={props.onPressButtonConfirm}>{props.labelButtonConfirm}</ButtonStyle>
                  )
                }
              </Footer>
            )
          }
        </ModalStyle.Body>
      </ModalStyle>
    </Container>
  )
}

ModalConfirm.propTypes = {
  titleHeader: PropTypes.string,
  width: PropTypes.number,
  labelButtonCancel: PropTypes.string,
  labelButtonConfirm: PropTypes.string,
  styleColorButtonConfirm: PropTypes.string,
  styleColorButtonCancel: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onPressButtonCancel: PropTypes.func,
  onPressButtonConfirm: PropTypes.func,
  children: PropTypes.node,
  disabledButtonConfirm: PropTypes.bool,
  disabledButtonCancel: PropTypes.bool,
};

ModalConfirm.defaultProps = {
  titleHeader: '',
  show: false,
  labelButtonConfirm: '',
  labelButtonCancel: '',
  styleColorButtonConfirm: undefined,
  styleColorButtonCancel: undefined,
  disabledButtonConfirm: false,
  disabledButtonCancel: false,
  width: undefined,
};

export default ModalConfirm;