import React from 'react';
import { Grid, Row, Col, Tooltip, Button, Modal, Table } from 'react-bootstrap';
import styled from 'styled-components';
import {
  TEXT_CLOSE,
  TEXT_LIST,
  TEXT_REVENUE,
  TEXT_SPEND,
  TEXT_SUM_COST,
  TEXT_SUM_INCOME,
  TEXT_SUM_PROFIT,
  TEXT_TITLE_CUSTOMER,
  TEXT_TITLE_ITEM_NAME,
  TEXT_TITLE_SENT_DATE,
  TEXT_TITLE_SIZE,
  TEXT_TITLE_STATUS,
  TEXT_TITLE_WEIGHT,
  TEXT_TOTAL_COST,
} from '../utils/useConstants';

export const StyledTable = styled(Table)`
  &.custom-table {
    & thead tr th {
      color: #000000;
      font-weight: bold;
      font-size: 20px;
      text-transform: none;
    }

    & td {
      word-break: break-all;
      height: 50px;
    }
  }
`;

const Line = () => {
  return <Row style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }} />;
};

// const data = [
//   {
//     key: '1',
//     shipment_number: 'ค่า KA_FM_15082022_BKK2_6W_R1-01',
//     tracking_number: '20.00',
//     delivered_date: '0.00',
//     cod_price: '(20.00)',
//   },

//   {
//     key: '2',
//     shipment_number: 'ค่า KA_FM_15082022_BKK2_6W_R1-01',
//     tracking_number: '20.00',
//     delivered_date: '0.00',
//     cod_price: '(20.00)',
//   },
// ];

const columns = [
  {
    key: '1',
    title: 'รายการ',
    width: '200px',
  },
  {
    key: '2',
    title: 'ต้นทุน(บาท)',
    width: '130px',
  },
  {
    key: '3',
    title: 'รายได้(บาท)',
    width: '110px',
  },
  {
    key: '4',
    title: 'กำไร/ขาดทุน(บาท)',
    width: '100px',
  },
];

const TablePer = ({ data }) => {
  console.log('TablePer data : ', data);
  return (
    <StyledTable striped hover className="custom-table">
      <thead>
        <tr>
          {columns.map(item => (
            <th
              key={item.title}
              style={{
                minWidth: item.width,
                width: item.width,
                textAlign: item.textAlign,
              }}
            >
              {item.title}
            </th>
          ))}
        </tr>
      </thead>

      {data?.serviceItems?.map(parcel_order => {
        return (
          <tbody>
            <tr style={{ right: '0' }}>
              <td> {parcel_order?.list_name} </td>
              <td>{parcel_order?.price} </td>
              <td>{parcel_order?.grand_total_price} </td>
              <td>{Number(parcel_order?.grand_total_price - parcel_order?.price).toFixed(2)} </td>
            </tr>
          </tbody>
        );
      })}
    </StyledTable>
  );
};

const CostPerParcelModalData = ({ data }) => {
  // const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        onClick={() => {
          window.open(`/ka-shipment-detail?id=${data?.jobId}&tab=cost`, '_blank');
        }}
        className="btn btn-default"
        target="_blank"
        style={{
          float: 'left',
          marginLeft: '10px',
          marginTop: '5px',
        }}
      >
        <i style={{ marginTop: '3px' }} className="fas fa-search"></i>
      </div>
      {/* {open && (
        <Modal
          dialogClassName="modal-dialog-size"
          show={open}
          backdrop="static"
          onHide={() => setOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ marginBottom: '10px' }}>
              <Col md={12}></Col>
            </Row>

            <Row style={{ marginBottom: '10px ', lineHeight: '1.2' }}>
              <Col md={12}>
                {TEXT_TITLE_CUSTOMER}:
                <b
                  style={{
                    minWidth: '160px',
                    marginLeft: '50px',
                  }}
                >
                  {data?.vendor?.full_name}
                </b>
              </Col>

              <Col md={12}>
                {TEXT_TITLE_ITEM_NAME}:
                <b
                  style={{
                    minWidth: '160px',
                    marginLeft: '34px',
                  }}
                >
                  {data?.serviceItems[0]?.list_name}
                </b>
              </Col>
              <Col md={12}>
                {TEXT_TITLE_SIZE}:
                <b
                  style={{
                    minWidth: '160px',
                    marginLeft: '50px',
                  }}
                >
                  45 x 36 x 32 cm.
                </b>
              </Col>
              <Col md={12}>
                {TEXT_TITLE_WEIGHT}:
                <b
                  style={{
                    minWidth: '160px',
                    marginLeft: '40px',
                  }}
                >
                  6 kg.
                </b>
              </Col>
              <Col md={12}>
                {TEXT_TITLE_STATUS}:
                <b
                  className="label label-success"
                  style={{
                    minWidth: '160px',
                    backgroundColor: '#00b24e',
                    marginLeft: '42px',
                    fontSize: '14px',
                    // color: '#FFFF',

                    // borderRadius: '0.5rem',
                    // padding: '1px',
                  }}
                >
                  ส่งสำเร็จ
                </b>
              </Col>
              <Col md={12}>
                {TEXT_TITLE_SENT_DATE}:
                <b
                  style={{
                    minWidth: '160px',
                    marginLeft: '10px',
                  }}
                >
                  {data?.deliveredDate}
                </b>
              </Col>
            </Row>

            <Line />
            <Row>
              <TablePer data={data} />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: '1.0' }}>
              <div
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                }}
              >
                {TEXT_SUM_COST} <div style={{ marginLeft: '60px' }}> 60.00</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                }}
              >
                {TEXT_SUM_INCOME} <div style={{ marginLeft: '60px' }}> 100.00</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: 'auto',
                }}
              >
                {TEXT_SUM_PROFIT} <div style={{ marginLeft: '60px' }}> 40.00 (40%)</div>
              </div>
            </div>

            <Button onClick={() => setOpen(false)}>{TEXT_CLOSE}</Button>
          </Modal.Footer>
        </Modal>
      )} */}
    </React.Fragment>
  );
};

export default CostPerParcelModalData;
