import gql from 'graphql-tag';

export default gql`
    mutation shipmentsUpdate(
        $_id: ID!
        $check_status: Int
    ){
        shipmentsUpdate(
        _id: $_id
        check_status: $check_status
        ){
            _id
        }
    }
`;