import React, { Component } from 'react';
import styled from 'styled-components';
import urijs from 'urijs';
import {
  Grid,
  Row,
  Col,
  Label,
  Button,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Table,
  Modal,
  Image,
} from 'react-bootstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import s3 from 'config/s3';
import QRCode from 'qrcode.react';
import Lightbox from 'lightbox-react';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import singleUploadMutation from './graphql/mutation/singleUpload';
import subThirdPartySettingUpdateMutation from './graphql/mutation/subThirdPartySettingUpdate';
import classes from './ProofofTransfer.module.scss';

const styleddefault = {
  backgroundColor: '#B3B3B3',
  border: '1px solid #B3B3B3',
  color: '#ffffff',
  fontWeight: 'bold',
};
const styleddefaultoutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #B3B3B3',
  color: '#B3B3B3',
  fontWeight: 'bold',
};

const styledprimary = {
  backgroundColor: '#318DE8',
  border: '1px solid #318DE8',
  color: '#ffffff',
  fontWeight: 'bold',
};
const styledprimaryoutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #318DE8',
  color: '#318DE8',
  fontWeight: 'bold',
};

const styledsuccess = {
  backgroundColor: '#66C066',
  border: '1px solid #66C066',
  color: '#ffffff',
  fontWeight: 'bold',
};
const styledsuccessoutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #66C066',
  color: '#66C066',
  fontWeight: 'bold',
};
const styleddark = {
  backgroundColor: '#ffffff',
  border: '1px solid #000000',
  color: '#000000',
  fontWeight: 'bold',
};

const styleddangeroutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #FF0000',
  color: '#FF0000',
  fontWeight: 'bold',
};

const styledwarning = {
  backgroundColor: '#ffffff',
  border: '1px solid #FF9D00',
  color: '#FF9D00',
  fontWeight: 'bold',
};

const CustomPhotoAdd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-color: ${props => (props.check ? 'rgba(49, 141, 232, 0.1)' : 'rgba(255, 0, 0, 0.1)')};
  border: ${props => (props.check ? '2px dashed #318DE8' : '2px dashed red')};
  border-radius: 10px;
`;
const CustomPhoto = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  // border: 2px solid #E3E3E3;
  position: relative;

  & i {
    position: absolute;
    font-size: 18px;
    margin: 5px 5px;
    top: 5px;
    right: 5px;
  }

  & .fas {
    color: #ffff;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;
const styleddanger = {
  backgroundColor: ' #FF0000',
  border: '1px solid #FF0000',
  color: '#ffffff',
  fontWeight: 'bold',
};

let lastitem = null;
class ProofofTransferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipId: null,
      showtransferDate: '',
      transferDate: '',
      start_date: '',
      proof_of_transfer: null,
      // ใช้ แนบรูปภาพยืนยัน && รูปถ่ายสินค้า
      attachPhotos: [],
      attachPhotosFile: [],
      attachPhotosInvalid: false,
      animationUploadFirst: false,

      //viewImageSrc
      isOpenLightbox: false,
      viewImageSrc: '',

      //dataImage
      dataImage: null,
      photoIndex: 0,
      isOpenLightboxarray: false,
    };

    this._inputAttachFileFirstCase = React.createRef();
  }
  // เพิ่มรูปหลักฐาน
  deleteAttachPhotoFirst = id => {
    const newAttachPhotos = this.state.attachPhotos.filter(data => data !== id);
    this.setState({
      attachPhotos: newAttachPhotos,
    });
  };

  deleteAttachPhotoFirstitem = id => {
    const photos = lastitem.slip.filter(data => data !== id);
    lastitem.slip = photos;
    this.setState({
      attachPhotos: photos,
    });
  };
  renderLightboxarray(data) {
    const array = data.map((item, index) => {
      let obj = `${s3}/${item}`;
      return obj;
    });
    this.setState({
      isOpenLightboxarray: true,
      dataImage: array,
      photoIndex: 0,
    });
  }
  renderLightbox(data) {
    this.setState({
      isOpenLightbox: true,
      viewImageSrc: `${s3}/${data}`,
    });
  }
  checkFileTypeImage(file) {
    if (file) {
      return file.type === 'image/png' || file.type === 'image/jpeg';
    }
  }
  handleSingleUploadDocumentCase(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    const timestamp = new Date().getTime();
    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/3plLazDetail/FinalStatus/${timestamp}/${documentName}`,
        },
      });
    }
  }
  variablesagentManageCOD(proofTransfer, agentManageCOD) {
    let obj = {
      _id: this.props.shipment._id,
      agent_shipment_status: 6,
      proof_of_transfer: {
        start_date: this.props.shipment.pick_up_date,
        trip_name: this.props.shipment.trip_name,
        name_agent: this.props.shipment.name_agent,
        account_info_id: this.props.accountinfoId._id,
        total_price_cod: this.props.shipment.total_price_cod
          ? this.props.shipment.total_price_cod
          : 0,
        transfer_date: this.state.transferDate
          ? this.state.transferDate
          : proofTransfer
          ? moment(proofTransfer[0].transfer_date).format('YYYY-MM-DD HH:mm')
          : '',
        slip: this.state.attachPhotos.concat(proofTransfer ? proofTransfer[0].slip : []),
      },
    };
    if (proofTransfer) {
      if (proofTransfer[0]._id) {
        obj.proof_of_transfer['_id'] = proofTransfer[0]._id;
      }
    }
    agentManageCOD({
      variables: obj,
    });
  }
  getorderIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).orderid;
    return id;
  };
  getIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).shipmentid;
    return id;
  };
  formatMoney = number => {
    return parseInt(number).toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  };

  getIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).shipmentid;
    return id;
  };

  renderReportStatus(status) {
    switch (status) {
      case 1:
        return <Label style={styleddefault}>รอส่งสถานะ</Label>;
      case 2:
        return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
      case 3:
        return <Label style={styleddefault}>รอโอน COD</Label>;
      case 4:
        return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
      case 5:
        return <Label style={styleddefault}>รอโอน COD</Label>;
      case 6:
        return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
      case 7:
        return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
      case 8:
        return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
      default:
        return '';
    }
  }

  render() {
    let rowLen = this.props.proofTransfer.length;
    let payment = this.props.accountinfoId.payment_bank_account;
    let proofTransfer = null;
    let checkSlip = [];
    let checkDeta = '';
    if (this.props.proofTransfer.length > 0) {
      proofTransfer = this.props.proofTransfer;
      if (proofTransfer[0].slip.length > 0) {
        checkSlip = proofTransfer[0].slip;
      }
      if (proofTransfer[0].transfer_date) {
        checkDeta = proofTransfer[0].transfer_date;
      }
    }
    return (
      <div style={{ display: this.props.isDataCheckedModal ? 'block' : 'none' }}>
        <Mutation
          mutation={subThirdPartySettingUpdateMutation}
          update={() => {
            this.props.closeModal();
            this.props.refetchagentManageCODList();
          }}
          // update={this.props.refetchagentManageCODList(), this.props.closeModal}
          onError={error => {
            alert(error);
            // this.checkTextError(error.graphQLErrors[0].message);
          }}
        >
          {agentManageCOD => (
            <Modal
              show={
                (this.props.isDataCheckedModal && this.state.isOpenLightbox) ||
                this.state.isOpenLightboxarray
                  ? false
                  : true
              }
              onHide={this.props.closeModal}
            >
              <Modal.Header closeButton>
                <h5 style={{ marginBottom: '5px' }}>
                  <b>รายการชำระเงินค่า COD</b>
                </h5>
                <div style={{ marginBottom: '5px' }}>
                  <span>ตรวจสอบรายการโอนชำระ COD จาก 3PL</span>
                </div>
                <div>
                  <span style={{ marginRight: '20px' }}>
                    วันที่เริ่มงาน:&nbsp;&nbsp;
                    <b>{moment(this.props.shipment.pick_up_date).format('DD/MM/YYYY - HH:mm')}</b>
                  </span>
                  <span style={{ marginRight: '20px' }}>
                    รอบ:&nbsp;&nbsp;<b>{this.props.shipment.trip_name}</b>
                  </span>
                  <span style={{ marginRight: '20px' }}>
                    นายหน้า:&nbsp;&nbsp;<b>{this.props.shipment.name_agent}</b>
                  </span>
                </div>
              </Modal.Header>
              <Modal.Body style={{ paddingTop: '0px' }}>
                <div>
                  <div className={classes.wrapper}>
                    <div className={classes.content}>
                      <div style={{ width: '100%' }}>
                        <div>
                          <span className={classes.inline}>สถานะรายงาน:&nbsp;&nbsp;</span>
                          {this.renderReportStatus(this.props.shipment.agent_shipment_status)}
                        </div>
                        {this.props.shipment.agent_shipment_status == 8 && (
                          <div>
                            <span className={classes.inline}>เหตุผล:&nbsp;&nbsp;</span>
                            <b style={{ color: 'red', wordBreak: 'break-all' }}>
                              {this.props.cod_reject_remark}
                            </b>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.proofTransfer.length > 0 &&
                  this.props.proofTransfer.map((item, index) => {
                    if (rowLen === index + 1) {
                      lastitem = item;
                      return <>{item.status === 3 && null}</>;
                    } else {
                      return (
                        <>
                          <div>
                            <div className={classes.wrapper}>
                              <div className={classes.title}>การโอนรอบที่ผ่านมา</div>
                              <div className={classes.content}>
                                <div className={classes.amount}>
                                  จำนวนที่โอน: <b>{this.formatMoney(item.total_price_cod)} บาท</b>
                                </div>
                                <div className={classes.time}>
                                  วัน-เวลาที่โอน:{' '}
                                  <b>{moment(item.transfer_date).format('DD/MM/YYYY - HH:mm')}</b>
                                </div>
                                <div className={classes.btnright}>
                                  <Button
                                    onClick={() => this.renderLightboxarray(item.slip)}
                                    style={{
                                      backgroundColor: '#ffffff',
                                      color: '#318de8',
                                      border: '1px dashed #318de8',
                                    }}
                                  >
                                    หลักฐานการโอน
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}

                <Row style={{ paddingTop: '15px' }}>
                  <Col md={6} sm={6} xs={12}>
                    <div>
                      <div>
                        ยอดโอนทั้งหมด (บาท)<span style={{ color: 'red' }}>*</span>
                      </div>
                      <FormControl
                        type="number"
                        placeholder="ระบุ"
                        disabled={true}
                        value={
                          this.props.shipment.total_price_cod
                            ? this.props.shipment.total_price_cod
                            : 0
                        }
                      />
                    </div>
                    <div style={{ marginTop: '20px' }}>
                      <div style={{ marginBottom: '10px' }}>
                        <b>เลขบัญชีที่โอนเข้า </b>
                      </div>
                      <div>
                        <b>
                          ชื่อบัญชี :{' '}
                          {payment.account_name ? payment.account_name : 'Giztix CO.,LTD'}
                        </b>
                      </div>
                      <div>
                        <b>ธนาคาร : {payment.bank_name ? payment.bank_name : 'ซิตี้แบงก์'}</b>
                      </div>
                      <div>
                        <b>
                          เลขบัญชี :{' '}
                          {payment.account_number ? payment.account_number : '8898500000'}
                        </b>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} sm={6} xs={12}>
                    <div>
                      <div>
                        วัน-เวลาที่โอน<span style={{ color: 'red' }}>*</span>
                      </div>
                      <Datetime
                        inputProps={{
                          placeholder: 'เลือกวันที่',
                          disabled:
                            this.props.shipment.agent_shipment_status === 7 ||
                            this.props.shipment.agent_shipment_status === 6,
                        }}
                        value={
                          this.state.showtransferDate
                            ? this.state.showtransferDate
                            : checkDeta
                            ? moment(checkDeta).format('DD/MM/YYYY - HH:mm')
                            : ''
                        }
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        disabled={this.props.shipment.agent_shipment_status === 7}
                        onChange={event =>
                          this.setState({
                            showtransferDate: event,
                            transferDate: event.format('YYYY-MM-DD HH:mm'),
                          })
                        }
                      />
                    </div>
                    <div style={{ marginTop: '30px' }}>
                      <Row>
                        <Col md={12} style={{ marginBottom: '15px' }}>
                          แนบสลิป<span style={{ color: 'red' }}>*</span>
                        </Col>
                        <Col md={12}>
                          <Row>
                            <Col
                              md={6}
                              sm={6}
                              xs={12}
                              style={{
                                marginBottom: '30px',
                                cursor:
                                  this.props.shipment.agent_shipment_status === 7 ||
                                  this.props.shipment.agent_shipment_status === 6
                                    ? ' not-allowed'
                                    : 'unset',
                              }}
                            >
                              <CustomPhotoAdd
                                onClick={() => this._inputAttachFileFirstCase.current.click()}
                                check={this.state.attachPhotos.length > 0 || checkSlip.length > 0}
                                style={{
                                  pointerEvents:
                                    this.props.shipment.agent_shipment_status === 7 ||
                                    this.props.shipment.agent_shipment_status === 6
                                      ? ' none'
                                      : 'unset',
                                }}
                              >
                                <i
                                  style={{
                                    color:
                                      this.state.attachPhotos.length > 0 || checkSlip.length > 0
                                        ? '#318DE8'
                                        : 'red',
                                    fontSize: '23px',
                                    fontWeight: 100,
                                  }}
                                  class="far fa-plus-circle"
                                ></i>
                                <span
                                  style={{
                                    color:
                                      this.state.attachPhotos.length > 0 || checkSlip.length > 0
                                        ? '#318DE8'
                                        : 'red',
                                  }}
                                >
                                  เพิ่มรูปภาพ
                                </span>
                              </CustomPhotoAdd>
                            </Col>
                            {checkSlip &&
                              checkSlip.map(data => (
                                <Col
                                  key={data}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  style={{ marginBottom: '30px' }}
                                >
                                  <CustomPhoto>
                                    {this.props.shipment.agent_shipment_status !== 7 &&
                                      this.props.shipment.agent_shipment_status !== 6 && (
                                        <>
                                          <i class="fas fa-times-circle"></i>
                                          <i
                                            class="far fa-times-circle"
                                            onClick={() => this.deleteAttachPhotoFirstitem(data)}
                                          ></i>
                                        </>
                                      )}
                                    <Image
                                      onClick={() => this.renderLightbox(data)}
                                      style={{ width: '100%' }}
                                      src={s3 + '/' + data}
                                      rounded
                                    />
                                  </CustomPhoto>
                                </Col>
                              ))}
                            {this.state.attachPhotos.length > 0 &&
                              this.state.attachPhotos.map(data => (
                                <Col
                                  key={data}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  style={{ marginBottom: '30px' }}
                                >
                                  <CustomPhoto>
                                    <i class="fas fa-times-circle"></i>
                                    <i
                                      class="far fa-times-circle"
                                      onClick={() => this.deleteAttachPhotoFirst(data)}
                                    ></i>
                                    <Image
                                      onClick={() => this.renderLightbox(data)}
                                      style={{ width: '100%' }}
                                      src={s3 + '/' + data}
                                      rounded
                                    />
                                  </CustomPhoto>
                                </Col>
                              ))}
                            {this.state.animationUploadFirst && (
                              <Col md={6} sm={6} xs={12} style={{ marginBottom: '30px' }}>
                                <CustomPhoto
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      alignItems: 'center',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <i
                                      class="fad fa-spinner-third fa-spin"
                                      style={{ fontSize: '50px', top: 'unset', right: 'unset' }}
                                    ></i>
                                  </div>
                                </CustomPhoto>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Mutation
                          mutation={singleUploadMutation}
                          update={(cache, { data }) => {
                            this._inputAttachFileFirstCase.current.value = '';
                            this.setState(previousState => ({
                              attachPhotos: [...previousState.attachPhotos, data.singleUpload.path],
                              animationUploadFirst: false,
                            }));
                          }}
                          onError={error => {
                            console.log(error);
                          }}
                        >
                          {uploadFile => {
                            return (
                              <input
                                accept="image/jpeg,image/png"
                                onChange={event => {
                                  const files = event.target.files[0];
                                  const checkFileTypeImage = this.checkFileTypeImage(files);
                                  if (checkFileTypeImage === false) {
                                    this.addNotification(
                                      'error',
                                      'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
                                    );
                                    return;
                                  }
                                  files && this.setState({ animationUploadFirst: true });
                                  this._inputAttachFileFirstCase.current.files[0] &&
                                    this.handleSingleUploadDocumentCase(
                                      uploadFile,
                                      this._inputAttachFileFirstCase.current.files[0].name.split(
                                        '.'
                                      )[0],
                                      event
                                    );
                                }}
                                type="file"
                                id="file"
                                style={{ display: 'none' }}
                                ref={this._inputAttachFileFirstCase}
                                disabled={this.props.shipment.agent_shipment_status === 7}
                              />
                            );
                          }}
                        </Mutation>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button style={{ float: 'left' }} onClick={this.props.closeModal}>
                  ปิด
                </Button>
                {this.props.shipment.agent_shipment_status !== 7 &&
                  this.props.shipment.agent_shipment_status !== 6 && (
                    <Button
                      onClick={() => this.variablesagentManageCOD(proofTransfer, agentManageCOD)}
                      style={{ float: 'right', backgroundColor: '#5DBA5B', color: '#fff' }}
                      disabled={
                        (this.state.attachPhotos.length === 0 && checkSlip.length === 0) ||
                        (!this.state.transferDate && !checkDeta)
                      }
                    >
                      ยืนยันการโอนเงิน
                    </Button>
                  )}
              </Modal.Footer>
            </Modal>
          )}
        </Mutation>

        {this.state.isOpenLightbox && (
          <Lightbox
            style={{ zIndex: '9999' }}
            mainSrc={this.state.viewImageSrc}
            onCloseRequest={() => this.setState({ isOpenLightbox: false, viewImageSrc: '' })}
          />
        )}

        {this.state.isOpenLightboxarray && (
          <Lightbox
            style={{ zIndex: '9999' }}
            mainSrc={this.state.dataImage[this.state.photoIndex]}
            nextSrc={
              this.state.dataImage[(this.state.photoIndex + 1) % this.state.dataImage.length]
            }
            prevSrc={
              this.state.dataImage[
                (this.state.photoIndex + this.state.dataImage.length - 1) %
                  this.state.dataImage.length
              ]
            }
            onCloseRequest={() => this.setState({ isOpenLightboxarray: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + this.state.dataImage.length - 1) %
                  this.state.dataImage.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (this.state.photoIndex + 1) % this.state.dataImage.length,
              })
            }
          />
        )}
      </div>
    );
  }
}

export default ProofofTransferModal;
