import gql from 'graphql-tag';

export default gql`
  query summaryInvoiceList($_id: ID, $shipment_id: ID) {
    summaryInvoiceList(_id: $_id, shipment_id: $shipment_id) {
      summaryInvoice {
        _id
        summary_invoice_number
      }
    }
  }
`;
