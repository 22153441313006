import React from 'react';
import styled from 'styled-components';
import { Card } from '../components';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  width: 25%;
  color: ${props => props.textColor};
  border: 0.3vw solid ${props => props.borderColor};
  background-color: ${props => props.bgColor};
`;

const TextTitle = styled.div`
  font-size: 4vh;
  font-weight: bold;
`;

const TextNumber = styled.div`
  font-size: 19vh;
  font-weight: bold;
  line-height: 0.7;
`;

const TextUnit = styled.div`
  display: inline-block;
  font-size: 8vh;
  font-weight: bold;
  margin-left: 1vw;
`;

const defaultProps = {
  title: 'Title',
  bgColor: '#ffffff',
  textColor: '#000000',
  borderColor: '#000000',
  number: 0,
};

const CardLargeNumber = props => {
  const { bgColor, textColor, number, title, borderColor, ...otherProps } = props;

  return (
    <StyledCard bgColor={bgColor} textColor={textColor} borderColor={borderColor} {...otherProps}>
      <TextTitle>{title}</TextTitle>
      <TextNumber>
        {number}
        <TextUnit>คัน</TextUnit>
      </TextNumber>
    </StyledCard>
  );
};

CardLargeNumber.defaultProps = defaultProps;

export default CardLargeNumber;
