import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormControl,
  Label,
  FormGroup,
  Popover,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import transporterInfoListQuery from './graphql/query/transporterInfoList';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import agentListQuery from './graphql/query/agentList';
import userTruckList from './graphql/query/userTruckList';
import adminName from './graphql/query/adminName';
import shipmentListQuery from './graphql/query/shipmentList';
import assignDriver from './graphql/mutation/assignDriver';
import assignAdminShipment from './graphql/mutation/assignAdminShipment';
import settingTruckListQuery from './graphql/query/settingTruckList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import assignAgentMutation from './graphql/mutation/assignAgent';

import shipmentDashboardQuery from './graphql/query/shipmentDashboard';

import CardTable from 'components/CardTable/CardTable.jsx';
import styled from 'styled-components';
import { client } from '../..';
import baseUrl from './../../config/baseUrl';

const tooltip = <Tooltip id="tooltip">ดูข้อมูล</Tooltip>;

const tooltip1 = <Tooltip id="tooltip">เพิ่มคนขับ</Tooltip>;

const tooltip2 = <Tooltip id="tooltip">ยกเลิกงาน</Tooltip>;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

let driverList = [];
let truckList = [];
let truckItem = [];
let agentList = [];
let agentItem = [];

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  { value: 'ลูกค้าต้องการใช้บริการเสริม', label: 'ลูกค้าต้องการใช้บริการเสริม' },
];

const Container = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    // position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)`
  & a {
    & i {
    }
  }
`;

const TitleLeft = styled.h4`
  font-weight: bold;
  margin-top: 0;
  border-left: 2px solid #d90402;
  padding-left: 10px;
`;

const BoxDashboard = styled(Row)`
  border: 1px solid #dddddd;
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 30px;

  & .col-md-4 {
    border-right: 1px solid #dddddd;
  }

  & .col-md-4:last-child {
    border-right: none;
  }

  & h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
`;

const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

class BuShipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show_cancel: false,
      show_assign: false,
      total_page: 0,
      page_number: 1,
      order_by: null,
      order_type: null,
      shipment_status: null,
      admin_name: '',
      shipment_number: '',
      shipmentNumber: '',
      shipmentID: null,
      driverID: null,
      truckID: null,
      driverSelected: null,
      truckSelected: null,
      create_date: '',
      show_create_date: '',
      shipper_email: '',
      pick_up_date: '',
      show_pick_up_date: '',
      re_shipment: 0,
      transporterId: null,
      transporter: '',
      transporterSuggestions: [],
      showReport: false,
      reportDate: '',
      show_reportDate: '',
      driver: 0,
      setting_truck_type_id: null,
      shipment_type: 0,

      bu_number: '',
      bu_name: '',
      agentId: 0,
      agentName: '',
      agentSelected: null,
      show_transporter: false,
    };
  }

  componentWillReceiveProps(newProps) {
    const { loading, error } = newProps.accountInfoListQuery;
    if (!loading && !error) {
      const {
        accountInfoList: { account_info },
        accountInfoList: { account_business },
      } = newProps.accountInfoListQuery;
      const accountBusiness = account_business;

      if (accountBusiness === null) {
        window.location.href = '/giztixbusiness';
        return;
      }

      this.setState({
        bu_number: accountBusiness.business_req_number,
        bu_name: accountBusiness.account_info_business_name,
      });
    }
  }

  handlePickupDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ pick_up_date: date, show_pick_up_date: show }, () => {
      refetch();
    });
  }

  handleDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ create_date: date, show_create_date: show }, () => {
      refetch();
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShowAssignAdmin(shipmentNumber, shipmentID) {
    this.setState({
      show_assign: true,
      shipmentID: shipmentID,
      shipmentNumber: shipmentNumber,
    });
  }
  handleShowTransporter(shipment) {
    this.setState({
      show_transporter: true,
      shipmentID: shipment._id,
      shipmentNumber: shipment.shipment_number,
      agentId: shipment.transporter,
      agentName: shipment.transporter_name,
      agentSelected: { value: shipment.transporter, label: shipment.transporter_name },
    });
  }

  handleCloseAgent() {
    this.setState({
      show_transporter: false,
      shipmentID: '',
      shipmentNumber: '',
      agentId: '',
      agentName: '',
    });
  }

  handleShow(shipmentNumber, shipmentID, driverID, truckID, driverName) {
    const truck = truckItem.filter(item => item._id == truckID);

    if (driverID) {
      this.setState({
        show: true,
        shipmentID: shipmentID,
        shipmentNumber: shipmentNumber,
        driverID: driverID,
        truckID: truckID,
        driverSelected: { value: driverID, label: driverName },
        truckSelected: { value: truck[0]._id, label: truck[0].user_setting_truck_license_plate },
      });
    } else {
      this.setState({
        show: true,
        shipmentID: shipmentID,
        shipmentNumber: shipmentNumber,
        driverID: driverID,
        truckID: truckID,
        driverSelected: null,
        truckSelected: null,
      });
    }
  }

  renderTruck(data) {
    truckList = data.userSettingTruckList.user_setting_truck.map(truck => {
      return { value: truck._id, label: truck.user_setting_truck_license_plate };
    });
  }

  renderAgent(data) {
    console.log('renderAgent', data.accountInfoList.account_info);
    agentList = data.accountInfoList.account_info.map(agent => {
      console.log('renderAgent', agent);
      return {
        value: agent._id,
        label: agent.account_info_business_name,
      };
    });
  }

  handleChangeDriver(driver) {
    this.setState({
      driverSelected: driver,
      driverID: driver.value,
      truckSelected: {
        value: driver.user_setting_truck_id,
        label: driver.user_setting_truck_license_plate,
      },
      truckID: driver.user_setting_truck_id,
    });
  }

  handleChangeTruck(truck) {
    // console.log(`truckSelected:`, truck);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ truckSelected: truck, truckID: truck.value });
    // console.log(`truckSelected:`, this.state.truckID);
  }

  handleChangeAgent(agent) {
    console.log(`handleChangeAgent:`, agent);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ agentSelected: agent, agentId: agent.value });
    // console.log(`truckSelected:`, this.state.truckID);
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderViewTruck(id) {
    if (id == null) {
      return '-';
    }

    const truck = truckItem.filter(item => item._id == id);
    if (truck.length > 0) {
      return `${truck[0].user_setting_truck_license_plate}`;
    }
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      default:
        return 'งานทั้งหมด';
    }
  }

  searchPickupDate(mode, refetch) {
    let date = '';
    let show = '';
    if (mode == 'today') {
      date = `${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`;
      show = `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
    } else {
      date = `${moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')}:${moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')}`;
      show = `${moment()
        .add(1, 'days')
        .format('DD/MM/YYYY')} - ${moment()
        .add(1, 'days')
        .format('DD/MM/YYYY')}`;
    }
    this.setState({ pick_up_date: date, show_pick_up_date: show }, () => {
      refetch();
    });
  }

  //Autosuggest Transporter
  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: transporterInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        transporterSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      transporterSuggestions: [],
    });
  };

  shouldRenderSuggestions() {
    return true;
  }
  //End Autosuggest Transporter

  openReportShipment(mode) {
    this.setState({ showReport: true, modeReport: mode, reportDate: '', show_reportDate: '' });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  exportReportShipment() {
    const uri = `${baseUrl}/booking_data?date=${this.state.reportDate}`;
    window.location = uri;

    this.setState({ showReport: false, reportDate: '', show_reportDate: '', modeReport: '' });
  }

  render() {
    if (
      this.props.userTruckList.loading == false &&
      this.props.userTruckList.userSettingTruckList != undefined
    ) {
      truckItem = this.props.userTruckList.userSettingTruckList.user_setting_truck;
      this.renderTruck(this.props.userTruckList);
    }
    if (
      this.props.agentListQuery.loading == false &&
      this.props.agentListQuery.accountInfoList != undefined
    ) {
      agentItem = this.props.agentListQuery.accountInfoList.account_info;
      this.renderAgent(this.props.agentListQuery);
    }
    let admin = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin = this.props.adminName.adminList.admin[0].admin_username;
    }
    return (
      <Container>
        <HeaderContainer>
          <div className="sub-header-left">
            <a href="/giztixbusiness" className="btn-back">
              <i className="far fa-arrow-left"></i> กลับ
            </a>
            <div className="line"></div>
            <div className="menu-wrapper">
              <ul>
                <li>
                  <a href={`/giztixbusinessdetail?id=${window.location.search.split('?id=')[1]}`}>
                    {' '}
                    <i className="fal fa-address-card"></i> ข้อมูลการลงทะเบียน
                  </a>
                </li>
                <li className="active">
                  <a href={`/giztixbusinessshipment?id=${window.location.search.split('?id=')[1]}`}>
                    {' '}
                    <i className="fal fa-truck"></i> รายการขนส่งของบริษัท
                  </a>
                </li>
                <li>
                  <a href={`/giztixbusinesspayment?id=${window.location.search.split('?id=')[1]}`}>
                    {' '}
                    <i className="fal fa-money-check-alt"></i> ค่าใช้จ่ายของบริษัท
                  </a>
                </li>
                <li>
                  <a
                    href={`/giztixbusinesstrackingsetting?id=${
                      window.location.search.split('?id=')[1]
                    }`}
                  >
                    {' '}
                    <i className="fal fa-box-heart"></i> จัดการหน้าติดตามงานขนส่ง
                  </a>
                </li>
                <li>
                  <a href={`/giztixbusinessdriver?id=${window.location.search.split('?id=')[1]}`}>
                    {' '}
                    <i className="fal fa-user-hard-hat"></i> พนักงานขับรถคนโปรด
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </HeaderContainer>
        <SubHeaderContainer>
          <div className="sub-header-left">
            <strong>
              BU{this.state.bu_number} : {this.state.bu_name}
            </strong>
          </div>
        </SubHeaderContainer>

        <Grid fluid style={{ paddingTop: 5 }}>
          <Row>
            <Query
              query={shipmentListQuery}
              variables={{
                shipper: window.location.search.split('?id=')[1],
                page_number: this.state.page_number,
                order_by: this.state.order_by,
                order_type: this.state.order_type,
                shipment_status: this.state.shipment_status,
                shipment_number: this.state.shipment_number,
                create_date: this.state.create_date,
                shipper_email: this.state.shipper_email,
                pick_up_date: this.state.pick_up_date,
                re_shipment: this.state.re_shipment,
                transporter: this.state.transporterId,
                admin: this.state.admin_name,
                driver: this.state.driver,
                setting_truck_type_id: this.state.setting_truck_type_id,
                shipment_type: this.state.shipment_type,
              }}
            >
              {({ loading, error, data, refetch, variables }) => {
                // if(loading) return null;
                // if(error) return null;
                // console.log(variables);
                // console.log(data);
                return (
                  <Col md={12}>
                    <CardTable
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <div>
                          <TitleLeft>สรุปงานขนส่งของผู้ใช้งานรายนี้</TitleLeft>
                          <Query
                            query={shipmentDashboardQuery}
                            variables={{
                              account_info_id: window.location.search.split('?id=')[1],
                            }}
                          >
                            {({ loading, error, data, refetch, variables }) => {
                              if (loading) return null;
                              if (error) return null;
                              // //console.log(variables);
                              const summary = data.shipmentDashboard;
                              console.log(data);
                              return (
                                <Row>
                                  <Col md={6}>
                                    <BoxDashboard>
                                      <Col md={4}>
                                        <span>ภายในประเทศ: </span>
                                        <h4>{summary.domestic.toLocaleString('en-US')} รายการ</h4>
                                      </Col>
                                      <Col md={4}>
                                        <span>นำเข้าสินค้า: </span>
                                        <h4>{summary.import.toLocaleString('en-US')} รายการ</h4>
                                      </Col>
                                      <Col md={4}>
                                        <span>ส่งออกสินค้า: </span>
                                        <h4>{summary.export.toLocaleString('en-US')} รายการ</h4>
                                      </Col>
                                    </BoxDashboard>
                                  </Col>
                                </Row>
                              );
                            }}
                          </Query>

                          <TitleLeft>รายการขนส่งทั้งหมดของผู้ใช้งานรายนี้</TitleLeft>

                          <PageWrapper style={{ textAlign: 'right' }}>
                            <div className="text"> หน้า :</div>
                            <FormControl
                              type="number"
                              min={1}
                              value={this.state.page_number}
                              onChange={event =>
                                setInterval(
                                  this.nextPage(
                                    Number(event.target.value) - 1,
                                    loading || error ? '0' : data.shipmentList.total_page,
                                    refetch
                                  ),
                                  1000
                                )
                              }
                              name="page_number"
                            />
                            <div className="text">
                              {' '}
                              {loading || error ? '' : `จาก ${data.shipmentList.total_page}`}
                            </div>
                          </PageWrapper>

                          <div className="table-vertical">
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th style={{ width: '140px', minWidth: '140px' }}>เครื่องมือ</th>
                                  <th
                                    style={{ width: '140px', minWidth: '140px' }}
                                    className={this.renderSorting('shipment_number')}
                                    onClick={() => this.sort('shipment_number', refetch)}
                                  >
                                    หมายเลขงานขนส่ง
                                  </th>
                                  <th
                                    style={{ width: '120px', minWidth: '120px' }}
                                    className={this.renderSorting('shipment_status')}
                                    onClick={() => this.sort('shipment_status', refetch)}
                                  >
                                    สถานะ
                                  </th>
                                  <th
                                    style={{ width: '120px', minWidth: '120px' }}
                                    className={this.renderSorting('admin')}
                                    onClick={() => this.sort('admin', refetch)}
                                  >
                                    ผู้ดูแลงาน
                                  </th>
                                  <th style={{ width: '120px', minWidth: '120px' }}>
                                    ประเภทการขนส่ง
                                  </th>
                                  <th style={{ width: '100px', minWidth: '100px' }}>ประเภทรถ</th>
                                  <th style={{ width: '180px', minWidth: '180px' }}>
                                    ผู้ส่งสินค้า
                                  </th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    จุดรับสินค้า/ รับตู้
                                  </th>
                                  <th style={{ width: '30px', minWidth: '30px' }}></th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    จุดส่ง/ ขึ้น/ ลงสินค้า
                                  </th>
                                  <th style={{ width: '30px', minWidth: '30px' }}></th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    จุดคืนตู้เบา/ ตู้หนัก
                                  </th>
                                  <th
                                    style={{ width: '150px', minWidth: '150px' }}
                                    className={this.renderSorting('pick_up_date')}
                                    onClick={() => this.sort('pick_up_date', refetch)}
                                  >
                                    วันรับสินค้า
                                  </th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    พนักงานขับรถ
                                  </th>
                                  <th style={{ width: '100px', minWidth: '110px' }}>
                                    เลขทะเบียนรถ
                                  </th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>ชื่อนายหน้า</th>
                                  <th
                                    style={{ width: '150px', minWidth: '150px' }}
                                    className={this.renderSorting('create_date')}
                                    onClick={() => this.sort('create_date', refetch)}
                                  >
                                    วันที่จอง
                                  </th>
                                </tr>
                                <tr className="tableFilter">
                                  <th></th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.shipment_number}
                                      placeholder="ค้นหา"
                                      onChange={event =>
                                        this.setState(
                                          { shipment_number: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipment_number"
                                    />
                                  </th>
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.shipment_status}
                                      onChange={event =>
                                        this.setState(
                                          { shipment_status: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipment_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={2}>รอขนส่งรับงาน</option>
                                      <option value={10}>รอเริ่มงาน</option>
                                      <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                      <option value={3}>กำลังดำเนินการขนส่ง</option>
                                      <option value={4}>เสร็จสิ้นแล้ว</option>
                                      <option value={5}>ถูกยกเลิก</option>
                                      <option value={6}>งานเกิดปัญหา</option>
                                      <option value={7}>แบบร่าง</option>
                                    </FormControl>
                                  </th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.admin_name}
                                      placeholder="ค้นหา"
                                      onChange={event =>
                                        this.setState(
                                          { admin_name: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="admin_name"
                                    />
                                  </th>
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.shipment_type}
                                      onChange={event =>
                                        this.setState(
                                          { shipment_type: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipment_type"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={1}>ภายในประเทศ</option>
                                      <option value={2}>นำเข้า</option>
                                      <option value={3}>ส่งออก</option>
                                    </FormControl>
                                  </th>
                                  <th>
                                    <Query query={settingTruckListQuery} fetchPolicy="network-only">
                                      {({ loading, error, data, refetch }) => {
                                        return (
                                          <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.setting_truck_type_id}
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  setting_truck_type_id:
                                                    event.target.value == 0
                                                      ? null
                                                      : event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="setting_truck_type_id"
                                          >
                                            <option value={0}>ทั้งหมด</option>
                                            {!loading &&
                                              !error &&
                                              data.settingTruckList.map((truck, index) => (
                                                <optgroup
                                                  label={`รถ ${truck.setting_truck_name.th}`}
                                                >
                                                  {truck.setting_truck_type
                                                    .filter(item => item.truck_type_enable)
                                                    .map((item, key, arr) => (
                                                      <option value={item._id}>
                                                        {`${truck.setting_truck_name.th} - ${item.truck_type_name.th}` ||
                                                          undefined}
                                                      </option>
                                                    ))}
                                                </optgroup>
                                              ))}
                                          </FormControl>
                                        );
                                      }}
                                    </Query>
                                  </th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.shipper_email}
                                      placeholder="ค้นหาอีเมลผู้ส่งสินค้า"
                                      onChange={event =>
                                        this.setState(
                                          { shipper_email: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipper_email"
                                    />
                                  </th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handlePickupDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() =>
                                        this.setState(
                                          { pick_up_date: '', show_pick_up_date: '' },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.show_pick_up_date}
                                        placeholder="ค้นหาสร้าง"
                                        name="pick_up_date"
                                      />
                                    </DateRangePicker>
                                  </th>
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.driver}
                                      onChange={event =>
                                        this.setState(
                                          { driver: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipment_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={1}>มีคนขับรถ</option>
                                      <option value={2}>ไม่มีคนขับรถ</option>
                                    </FormControl>
                                  </th>
                                  <th></th>
                                  <th>
                                    <Autosuggest
                                      suggestions={this.state.transporterSuggestions}
                                      onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                      onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                      getSuggestionValue={this.getSuggestionValue}
                                      renderSuggestion={this.renderSuggestion}
                                      focusInputOnSuggestionClick={false}
                                      inputProps={{
                                        placeholder: 'ค้นหา',
                                        value: this.state.transporter,
                                        onChange: event =>
                                          this.setState({
                                            transporter: event.target.value,
                                            transporterId: null,
                                          }),
                                        type: 'text',
                                        className: 'form-control',
                                      }}
                                      onSuggestionSelected={(event, { suggestion }) => {
                                        this.setState({
                                          transporterId: suggestion.id,
                                          transporter: suggestion.name,
                                          page_number: 1,
                                        });
                                      }}
                                      shouldRenderSuggestions={this.shouldRenderSuggestions}
                                    />
                                  </th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() =>
                                        this.setState(
                                          { create_date: '', show_create_date: '' },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.show_create_date}
                                        placeholder="ค้นหาสร้าง"
                                        name="create_date"
                                      />
                                    </DateRangePicker>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading || error ? (
                                  <tr>
                                    <td colSpan="17" className="textCenter">
                                      loading...{loading} {error}
                                    </td>
                                  </tr>
                                ) : data.shipmentList.shipment.length === 0 ? (
                                  <tr>
                                    <td colSpan="17" className="textCenter">
                                      ไม่พบข้อมูล
                                    </td>
                                  </tr>
                                ) : (
                                  data.shipmentList.shipment.map((prop, key) => (
                                    <tr key={key} data-id={prop._id}>
                                      <td>
                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                          <a
                                            href={`/shipmentdetail?shipment=${prop._id}`}
                                            target="_blank"
                                            style={{ float: 'left' }}
                                            className="btn btn-default"
                                          >
                                            <i
                                              style={{ marginTop: '3px' }}
                                              className="fas fa-eye"
                                            ></i>
                                          </a>
                                        </OverlayTrigger>

                                        {prop.shipment_status != 5 &&
                                        prop.shipment_status != 4 &&
                                        prop.shipment_status != 7 &&
                                        prop.shipment_number ? (
                                          <NavDropdownContainer
                                            title={
                                              <Button>
                                                เครื่องมือ <i className="fal fa-angle-down"></i>
                                              </Button>
                                            }
                                          >
                                            <NavItemDropDownList
                                              onClick={() => this.handleShowTransporter(prop)}
                                            >
                                              <i className="fas fa-user-tie"></i> เปลี่ยนนายหน้า
                                            </NavItemDropDownList>
                                            <NavItemDropDownList
                                              onClick={() =>
                                                this.handleShow(
                                                  prop.shipment_number,
                                                  prop._id,
                                                  prop.driver_user_id,
                                                  prop.user_setting_truck_id,
                                                  prop.driver_name
                                                )
                                              }
                                            >
                                              <i className="fas fa-truck"></i> เพิ่มคนขับ
                                            </NavItemDropDownList>
                                          </NavDropdownContainer>
                                        ) : null}
                                      </td>
                                      <td>
                                        {prop.shipment_number
                                          ? `${prop.shipment_number}`
                                          : 'แบบร่าง'}{' '}
                                        {prop.re_shipment && <Label bsStyle="info">re book</Label>}
                                      </td>
                                      <td>
                                        {this.renderStatus(prop.shipment_status)}{' '}
                                        {prop.shipment_status == 5 && prop.cancel_comment ? (
                                          <div style={{ marginTop: 5 }}>
                                            <small>เหตุผล : {prop.cancel_comment}</small>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        {prop.admin ? (
                                          prop.admin
                                        ) : (
                                          <Button
                                            className="link hover-blue"
                                            onClick={() =>
                                              this.handleShowAssignAdmin(
                                                prop.shipment_number,
                                                prop._id
                                              )
                                            }
                                          >
                                            <i className="fal fa-smile"></i> ดูแลงานนี้
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        {prop.shipment_type == 1
                                          ? 'ภายในประเทศ'
                                          : prop.shipment_type == 2
                                          ? 'นำเข้า'
                                          : prop.shipment_type == 3
                                          ? 'ส่งออก'
                                          : ''}
                                      </td>
                                      <td>{`${prop.setting_truck_name.th} ${prop.truck_type_name.th}`}</td>
                                      <td>
                                        {' '}
                                        {prop.account_business_id ? (
                                          <a
                                            target="_blank"
                                            href={`/giztixbusinessdetail?id=${prop.shipper}`}
                                            class="label label-warning"
                                          >
                                            B
                                          </a>
                                        ) : null}{' '}
                                        <b>{prop.shipper_detail}</b> <br />
                                        {prop.shipper_info.account_info_email}
                                      </td>
                                      <td>
                                        <div>
                                          <b>
                                            {prop.shipment_address[0].subdictrict},
                                            {prop.shipment_address[0].province}
                                          </b>{' '}
                                          <br />{' '}
                                          {`${prop.shipment_address[0].contact_name}, ${prop.shipment_address[0].contact_tel}`}
                                        </div>
                                      </td>
                                      <td>
                                        {prop.round_trip == 1 ? (
                                          <i class="fal fa-exchange"></i>
                                        ) : (
                                          <i class="fal fa-arrow-right"></i>
                                        )}
                                      </td>
                                      <td>
                                        {prop.round_trip != 1 &&
                                        prop.shipment_address.length > 2 &&
                                        prop.shipment_type == 1 ? (
                                          <OverlayTrigger
                                            trigger="hover"
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                              <Popover
                                                id={`popover-positioned-${prop._id}`}
                                                title={`จุดส่งสินค้าทั้งหมด ${prop.shipment_address
                                                  .length - 1} จุด `}
                                              >
                                                {prop.shipment_address.map(
                                                  (address, key) =>
                                                    key != 0 && (
                                                      <p>
                                                        <strong>{`จุดที่ ${key + 1} `}</strong>
                                                        {`${address.subdictrict} ${address.province}`}{' '}
                                                        <br />{' '}
                                                        {`${address.contact_name}, ${address.contact_tel}`}{' '}
                                                      </p>
                                                    )
                                                )}
                                              </Popover>
                                            }
                                          >
                                            <b style={{ cursor: 'pointer' }}>
                                              {`จุดส่งสินค้าทั้งหมด ${prop.shipment_address.length -
                                                1} จุด`}
                                            </b>
                                          </OverlayTrigger>
                                        ) : (
                                          <div>
                                            {' '}
                                            <b>
                                              {prop.shipment_address[1].subdictrict},
                                              {prop.shipment_address[1].province}
                                            </b>{' '}
                                            <br />{' '}
                                            {`${prop.shipment_address[1].contact_name}, ${prop.shipment_address[1].contact_tel}`}{' '}
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {prop.shipment_type == 2 || prop.shipment_type == 3 ? (
                                          <i className="fal fa-arrow-right"></i>
                                        ) : null}
                                      </td>
                                      <td>
                                        {prop.shipment_type == 2 || prop.shipment_type == 3 ? (
                                          <div>
                                            <b>
                                              {prop.shipment_address[2].subdictrict},
                                              {prop.shipment_address[2].province}
                                            </b>{' '}
                                            <br />{' '}
                                            {`${prop.shipment_address[2].contact_name}, ${prop.shipment_address[2].contact_tel}`}
                                          </div>
                                        ) : null}
                                      </td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm">
                                          {prop.pick_up_date}
                                        </Moment>
                                      </td>
                                      <td>
                                        {prop.driver_name
                                          ? `${prop.driver_name} ,${prop.driver_phone_code} ${prop.driver_phone_number}`
                                          : '-'}
                                      </td>
                                      <td>{this.renderViewTruck(prop.user_setting_truck_id)}</td>
                                      <td>{prop.transporter_name}</td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {prop.create_date}
                                        </Moment>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>

                          <div style={{ textAlign: 'right' }}>
                            {loading || error ? null : (
                              <ReactPaginate
                                pageCount={data.shipmentList.total_page}
                                forcePage={this.state.page_number - 1}
                                containerClassName="pagination"
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={({ selected }) => {
                                  this.nextPage(selected, data.shipmentList.total_page, refetch);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      }
                    />

                    <Mutation
                      mutation={assignAdminShipment}
                      variables={{
                        shipmentID: this.state.shipmentID,
                        admin_name: admin,
                      }}
                      update={() => {
                        this.setState({ show_assign: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {assignAdminShipment => (
                        <Modal
                          show={this.state.show_assign}
                          onHide={() => this.setState({ show_assign: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>รับดูแลงาน {this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>กด "ตกลง" เพื่อรับดูแลงานนี้</Modal.Body>
                          <Modal.Footer>
                            <Button
                              style={{ float: 'left' }}
                              onClick={() => this.setState({ show_assign: false })}
                            >
                              กลับ
                            </Button>
                            <Button bsStyle="primary" onClick={() => assignAdminShipment()}>
                              ตกลง
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={assignDriver}
                      variables={{
                        shipmentID: this.state.shipmentID,
                        driverID: this.state.driverID,
                        truckID: this.state.truckID,
                      }}
                      update={() => {
                        this.setState({ show: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {assignDriver => (
                        <Modal show={this.state.show} onHide={() => this.handleClose()}>
                          <Modal.Header closeButton>
                            <Modal.Title>แก้ไขคนขับรถ {this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Row style={{ marginBottom: '10px' }}>
                              <Col md={12}>คนขับรถ</Col>
                            </Row>

                            <Select
                              name={`driver_select`}
                              value={this.state.driverSelected}
                              onChange={event => this.handleChangeDriver(event)}
                              options={driverList}
                              placeholder="เลือกคนขับรถ"
                            />

                            <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                              <Col md={12}>ทะเบียนรถ</Col>
                            </Row>

                            <Select
                              name={`truck_select`}
                              value={this.state.truckSelected}
                              onChange={event => this.handleChangeTruck(event)}
                              options={truckList}
                              placeholder="เลือกรถ"
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button bsStyle="primary" onClick={() => assignDriver()}>
                              บันทึก
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={assignAgentMutation}
                      variables={{
                        shipment_id: this.state.shipmentID,
                        transporter: this.state.agentId,
                      }}
                      update={() => {
                        this.setState({ show_transporter: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {assignAgent => (
                        <Modal
                          show={this.state.show_transporter}
                          onHide={() => this.handleCloseAgent()}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>แก้ไขนายหน้า {this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Row style={{ marginBottom: '10px' }}>
                              <Col md={12}>นายหน้า</Col>
                            </Row>

                            <Select
                              name={`agent_select`}
                              value={this.state.agentSelected}
                              onChange={event => this.handleChangeAgent(event)}
                              options={agentList}
                              placeholder="เลือกนายหน้า"
                            />
                          </Modal.Body>
                          <Modal.Footer>
                            <Button bsStyle="primary" onClick={() => assignAgent()}>
                              บันทึก
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Modal
                      show={this.state.showReport}
                      onHide={() => this.setState({ showReport: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>รายงานรายงานขนส่ง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row style={{ marginBottom: '10px' }}>
                          <Col md={12}> ช่วงวันที่</Col>
                        </Row>
                        <FormGroup controlId="formValidationError1">
                          <DateRangePicker
                            onApply={(event, picker) =>
                              this.handleDateRangePickerReport(event, picker)
                            }
                            onCancel={() => this.setState({ reportDate: '', show_reportDate: '' })}
                            containerStyles={{ display: 'block' }}
                            opens="right"
                            format="DD/MM/YYYY"
                          >
                            <FormControl
                              type="text"
                              value={this.state.show_reportDate}
                              placeholder="ค้นหาวันที่"
                              name="reportDate"
                            />
                          </DateRangePicker>
                        </FormGroup>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button bsStyle="primary" onClick={() => this.exportReportShipment()}>
                          ดาวน์โหลดรายงาน
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                );
              }}
            </Query>
          </Row>
        </Grid>
      </Container>
    );
  }
}

export default compose(
  graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        _id: props.location.search.split('?id=')[1],
      },
    }),
  }),
  graphql(agentListQuery, {
    name: 'agentListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        account_info_type: 3,
      },
    }),
  }),
  graphql(userTruckList, { name: 'userTruckList' }),
  graphql(adminName, { name: 'adminName' })
)(BuShipment);
