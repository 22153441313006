import gql from 'graphql-tag';

export default gql`
  query searchAccountAgent(
    $_id: ID
    $account_agent_number: String
    $account_info_business_name: String
    $account_info_status: Int
    $account_user_status: Int
    $page_number: Int
    $order_by: String
    $order_type: String
    $last_update: String
    $account_info_phone_number: String
    $show_data: Int
  ) {
    accountInfoList(
      _id: $_id
      account_agent_number: $account_agent_number
      account_info_business_name: $account_info_business_name
      account_info_status: $account_info_status
      account_user_status: $account_user_status
      page_number: $page_number
      order_by: $order_by
      order_type: $order_type
      account_info_type: 3
      show_data: $show_data
      account_info_phone_number: $account_info_phone_number
      last_update: $last_update
    ) {
      account_info {
        _id
        account_info_business_type {
          th
          en
        }
        account_info_business_name
      }
      total_page
    }
  }
`;
