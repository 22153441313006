import React, { Component } from 'react';
import Moment from 'react-moment';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment/moment.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Autosuggest from 'react-autosuggest';
import urijs from 'urijs';

// container
import CreateCostingOrderList from './container/CreateCostingOrderList';
import UpdateCosingOrderList from './container/UpdateCosingOrderList';
import EditPaymentOrderList from './container/EditPaymentOrderList';
import DetailCostingOrderList from './container/DetailCostingOrderList';

import CardTable from 'components/CardTable/CardTable.jsx';
// Component
import styled from 'styled-components';
import {
  GoogleMaps,
  TrackingItem,
  TrackingItemImport,
  TrackingItemExport,
  PDFPreview,
  StarRating,
  Input,
  SelectBox,
  ModalConfirm,
} from './components';
import Card from 'components/Card/Card.jsx';

// graphql
import dateNowQuery from './graphql/query/dateNow';
import adminName from './graphql/query/adminName';
import detailShipmentQuery from './graphql/query/detailShipment';
import invoiceBusinessListQuery from './graphql/query/invoiceBusinessList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import invoiceListQuery from './graphql/query/invoiceList';
import datamasterServiceCategoryListQuery from './graphql/query/datamasterServiceCategoryType';
import paymentOrderList from './graphql/query/paymentOrderList';
import summaryInvoiceList from './graphql/query/summaryInvoiceList';
import accountInfoListQuery from './graphql/query/accountInfo';

import costingQuery from './graphql/query/costing';
import costingDocumentsQuery from './graphql/query/costingDocuments';
import invoiceUpdateMutation from './graphql/mutation/invoiceUpdate';
import createCostingMutation from './graphql/mutation/createCosting';
import createCostingDocumentMutation from './graphql/mutation/createCostingDocument';
import deleteCostingDocumentsMutation from './graphql/mutation/deleteCostingDocuments';
import updateCostingMutation from './graphql/mutation/updateCosting';

import s3 from 'config/s3';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { client } from '../../../../index';

let diverWorking = false;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: -20px;
  width: 100%;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const WrapperForm = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  & .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 15px;
    padding-right: 15px;
    border-left: 1px solid #ddd;

    &:first-child {
      border-left: none;
      padding-left: 0;
      flex: 2;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const TitleLeft = styled.h4`
  font-weight: bold;
  margin-top: 0;
  border-left: 2px solid #d90402;
  padding-left: 10px;
  float: left;
`;

const TitleCard = styled.h4`
  font-weight: bold;
  margin-top: 0;
  float: left;
`;

const BoxDashboardCost = styled(Row)`
  background-color: ${props => props.color};
  border: 1px dashed ${props => props.borderColor};
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 8px;
  padding: 5px 15px;
  margin-bottom: 30px;

  & .col-md-4 {
    border-right: 1px solid #dddddd;
  }

  & .col-md-4:last-child {
    border-right: none;
  }

  & h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
`;

const BoxDashboard = styled(Row)`
  border: 1px solid #dddddd;
  margin-right: 0px !important;
  margin-left: 0px !important;
  border-radius: 8px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;

  & .col-md-4 {
    border-right: 1px solid #dddddd;
  }

  & .col-md-4:last-child {
    border-right: none;
  }

  & h4 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
`;

const TableEditCostDetail = styled(Table)`
  & td {
    border-top: none !important;
    text-align: right;
    padding: 5px 8px !important;
  }

  & td:first-child {
    text-align: left;
  }

  &.table > tbody > tr > td {
    padding: 5px 8px !important;
  }

  & .border-top td {
    border-top: 1px solid #dddddd !important;
  }
`;

const TableCostDetail = styled(Table)`
  margin-top: 15px;

  & thead td {
    background-color: #484848;
    text-align: right;
    color: #ffffff;
  }

  & tbody > tr > td {
    vertical-align: baseline !important;
    border-top: none !important;
    padding: 5px 8px !important;
    text-align: right;
  }

  & tbody > tr:last-child > td {
    padding-bottom: 30px !important;
  }

  & tbody > tr:last-child {
    background-color: #ffffff !important;
  }

  & td p {
    margin: 0;
    padding-left: 20px;
  }

  & td:first-child {
    text-align: left;
  }

  & .border-bottom td {
    border-bottom: 1px solid #dddddd !important;
  }

  & .border-top td {
    border-top: 1px solid #dddddd !important;
  }
`;

const TableDetail = styled(Table)`
  & td {
    border-top: none !important;
    padding: 5px 8px !important;
  }
  & .border-bottom td {
    border-bottom: 1px solid #dddddd !important;
  }

  & .border-top td {
    border-top: 1px solid #dddddd !important;
  }
`;

const TableCost = styled(Table)`
  margin-top: 5px;

  & td {
    border-top: none !important;
    text-align: right;
    padding: 5px 8px !important;
  }

  & td:first-child {
    text-align: left;
  }

  & td button,
  & td button:hover,
  & td button:active,
  & td button:focus {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 5px !important;
  }

  & td button:first-child,
  & td button:first-child:hover,
  & td button:first-child:active,
  & td button:first-child:focus {
    padding-left: 0;
  }

  & .border-bottom td {
    border-bottom: 1px solid #dddddd !important;
  }

  & .border-top td {
    border-top: 1px solid #dddddd !important;
  }
`;

const ModalStyle = styled(Modal)`
  & .modal-dialog {
    width: 80%;
    min-width: 600px;
    max-width: 1240px;
  }
`;

const ModalEditStyle = styled(Modal)`
  & .modal-dialog {
    width: 680px;
    max-width: 100%;
  }
`;

const ButtonBlue = styled(Button)`
  border-color: #019fd9 !important;
  background-color: #019fd9 !important;
  color: #ffffff !important;
`;

const BlankSpace = styled.div`
  height: 10px;
  width: calc(100% + 40px);
  background-color: #f7f7f8;
  margin-left: -25px;
  margin-right: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #e6e6e6;
`;

const DetailText = styled.span`
  padding-right: 5px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
`;

const Detail = styled.span`
  padding-right: 5px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
`;

const Text = styled.span`
  float: left;
`;

const vatList = [
  { value: '0', label: '0 %' },
  { value: '7', label: '7 %' },
];

const WhtList = [
  { value: '0', label: '0 %' },
  { value: '1', label: '1 %' },
  { value: '3', label: '3 %' },
  { value: '5', label: '5 %' },
];

const AutosuggestContainer = styled.div`
  min-height: 70px;
  padding: 15px;

  & .react-autosuggest__container {
    margin-top: 10;
  }

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);
    let params = new URLSearchParams(document.location.search.substring(1));
    let shipmentId = params.get('shipment');
    let lazadaImportOrderId = params.get('orderId');

    this.state = {
      shipmentId: shipmentId,
      lazadaImportOrderId: lazadaImportOrderId,
      showModalDetailCost: false,
      showModalNotiSave: false,
      showModalEdit: false,
      mainCategory: null,
      mainCategoryInvalid: false,
      subCategory: { value: '', label: '-- เลือก --' },
      subCategoryInvalid: false,

      invoice_status_update: 1 /* 1 = new , 2 = update */,
      category_id: '',
      sub_category_id: '',
      expense_id: '',
      cost_and_advance_cat_id: '',

      invoice_business_list_detail: '',
      invoice_business_list_qty: 0,
      invoice_business_list_price: 0,
      invoice_business_list_cost_price: 0,
      invoice_business_list_discount: 0,
      invoice_business_list_discount_cost: 0,
      invoice_business_list_vat: 0,
      invoice_business_list_cost_vat: 0,
      invoice_business_list_wht: 0,
      invoice_business_list_cost_wht: 0,

      //ส่วนรวม
      cal_price: 0,
      cal_cost_price: 0,
      cal_vat: 0,
      cal_vat_cost: 0,
      cal_wht: [],
      cal_wht_cost: [],
      cal_wht1: 0,
      cal_wht1_cost: 0,
      cal_wht3: 0,
      cal_wht3_cost: 0,
      cal_wht5: 0,
      cal_wht5_cost: 0,
      cal_total: 0,
      cal_total_cost: 0,

      selectOptionSubCategory: null,
      selectOptionCategory: null,

      showEditCostList: false,
      editInvoiceBusinessList: null,
      invoice_business_list: [],
      invoice_business_list_edit: [],

      //แก้ไข
      dataEditModal: null,
      agentSuggestions: [],
      agentId: 0,
      agentName: '',
      agentSelected: '',
      mode: 'new',
      edit_id: 0,
      qty: 0,
      unit: '',
      price: 0,
      cost_price: 0,
      discount: 0,
      discount_cost: 0,
      vat: 0,
      vat_cost: 0,
      wht: 0,
      wht_cost: 0,
      min_price: 0,
      modal_cal_sale: 0,
      modal_cal_cost: 0,
      modal_cal_total: 0,
      modal_cal_total_cost: 0,
      modal_cal_vat: 0,
      modal_cal_vat_cost: 0,
      modal_cal_wht: [],
      modal_cal_wht_cost: [],
      modal_cal_wht1: 0,
      modal_cal_wht1_cost: 0,
      modal_cal_wht3: 0,
      modal_cal_wht3_cost: 0,
      modal_cal_wht5: 0,
      modal_cal_wht5_cost: 0,
      modal_cal_grand_total: 0,
      modal_cal_total_grand_cost: 0,

      cost_priceInvalid: false,
      qtyInvalid: false,
      unitInvalid: false,
      cost_priceInvalid: false,
      discount_costInvalid: false,
      vat_costInvalid: false,
      wht_costInvalid: false,
      priceInvalid: false,
      discountInvalid: false,
      vatInvalid: false,
      whtInvalid: false,

      costingId: null,
      costing: null,
      billingCostingData: null,
      invoiceBusinessList: null,
      isOpenCreateCostingOrderList: false,
      isOpenUpdateCosingOrderList: false,
      isOpenDetailCostingOrderList: false,
      isOpenRequestEditCostList: false,
      requestStatus: null,
      costingStatus: null,
    };
  }

  renderCalWht(data) {
    let obj = [];
    console.log('renderCalWht', data);

    if (data) {
      data.map((value, key) => {
        const index = obj.findIndex(item => item.wht == value.invoice_business_list_wht);
        const indexCost = obj.findIndex(item => item.wht == value.invoice_business_list_cost_wht);

        if (value.invoice_business_list_wht !== 0 || value.invoice_business_list_cost_wht !== 0) {
          if (index == -1 && indexCost == -1) {
            if (value.invoice_business_list_wht === value.invoice_business_list_cost_wht) {
              obj.push({
                wht: value.invoice_business_list_wht,
                total: value.invoice_business_list_total_wht,
                totalCost:
                  value.invoice_business_list_cost_wht === value.invoice_business_list_wht
                    ? value.invoice_business_list_cost_total_wht
                    : 0,
              });
            } else {
              if (value.invoice_business_list_wht === 0) {
                obj.push({
                  wht: value.invoice_business_list_cost_wht,
                  total: value.invoice_business_list_total_wht,
                  totalCost: value.invoice_business_list_cost_total_wht,
                });
              } else if (value.invoice_business_list_cost_wht === 0) {
                obj.push({
                  wht: value.invoice_business_list_wht,
                  total: value.invoice_business_list_total_wht,
                  totalCost: value.invoice_business_list_cost_total_wht,
                });
              } else {
                obj.push(
                  {
                    wht: value.invoice_business_list_wht,
                    total: value.invoice_business_list_total_wht,
                    totalCost: 0,
                  },
                  {
                    wht: value.invoice_business_list_cost_wht,
                    total: 0,
                    totalCost: value.invoice_business_list_cost_total_wht,
                  }
                );
              }
            }
          } else if (index == indexCost) {
            obj[index] = {
              wht: value.invoice_business_list_wht,
              total: obj[index].total + value.invoice_business_list_total_wht,
              totalCost: obj[index].totalCost + value.invoice_business_list_cost_total_wht,
            };
          } else {
            if (index === -1) {
              obj.push({
                wht: value.invoice_business_list_wht,
                total: value.invoice_business_list_total_wht,
                totalCost: 0,
              });
              obj[indexCost] = {
                wht: value.invoice_business_list_cost_wht,
                total: obj[indexCost].total,
                totalCost: obj[indexCost].totalCost + value.invoice_business_list_cost_total_wht,
              };
            } else if (indexCost === -1) {
              obj[index] = {
                wht: value.invoice_business_list_wht,
                total: obj[index].total + value.invoice_business_list_total_wht,
                totalCost: obj[index].totalCost,
              };
              obj.push({
                wht: value.invoice_business_list_cost_wht,
                total: 0,
                totalCost: value.invoice_business_list_cost_total_wht,
              });
            } else {
              obj[index] = {
                wht: value.invoice_business_list_wht,
                total: obj[index].total + value.invoice_business_list_total_wht,
                totalCost: obj[index].totalCost,
              };
              obj[indexCost] = {
                wht: value.invoice_business_list_cost_wht,
                total: obj[indexCost].total,
                totalCost: obj[indexCost].totalCost + value.invoice_business_list_cost_total_wht,
              };
            }
          }
        }
      });

      obj.sort(function(a, b) {
        return a.wht - b.wht;
      });
    }
    return obj;
  }

  renderMainCategory(Category) {
    const list = Category.map(fee => {
      return {
        value: fee._id,
        label: `${fee.prefix}000 - ${fee.truck_type_prefix} / ${fee.service_category_name.th}`,
        prefix: fee.prefix,
        service_category_name: fee.service_category_name.th,
        truck_type_prefix: fee.truck_type_prefix,
        datamaster_job_type_id: fee.job_type_id,
        truck_type_id: fee.truck_type_id,
        truck_type_prefix_id: fee.truck_type_prefix_id,
        datamaster_service_category_id: fee._id,
        datamaster_service_category_prefix_id: fee.setting_prefix_id,
        datamaster_service_item: fee.datamaster_service_item,
      };
    });
    return list;
  }

  renderSubCategory() {
    let list = [{ value: '', label: '-- เลือก --' }];
    if (this.state.selectOptionSubCategory != null) {
      list = this.state.selectOptionSubCategory.map(fee => {
        return {
          value: fee._id,
          label: `${this.state.mainCategory.prefix}${fee.datamaster_service_item_number} - ${fee.service_item_name.th}`,
          service_item_name: fee.service_item_name.th,
          detail: fee,
        };
      });
    }

    return list;
  }

  changeSelectMainCategory(event) {
    this.setState(
      {
        mainCategory: {
          value: event.value,
          label: event.label,
          prefix: event.prefix,
          service_category_name: event.service_category_name,
          truck_type_prefix: event.truck_type_prefix,
          datamaster_job_type_id: event.datamaster_job_type_id,
          truck_type_id: event.truck_type_id,
          truck_type_prefix_id: event.truck_type_prefix_id,
          datamaster_service_category_id: event.datamaster_service_category_id,
          datamaster_service_category_prefix_id: event.datamaster_service_category_prefix_id,
          datamaster_service_item: event.datamaster_service_item,
        },
      },
      () => {
        this.setState({
          agentSelected: '',
          subCategory: { value: '', label: '-- เลือก --' },
          mainCategoryInvalid: false,
          selectOptionSubCategory: event.datamaster_service_item,
          qty: 0,
          unit: '',
          price: 0,
          cost_price: 0,
          discount: 0,
          discount_cost: 0,
          vat: 0,
          vat_cost: 0,
          wht: 0,
          wht_cost: 0,
          modal_cal_sale: 0,
          modal_cal_cost: 0,
          modal_cal_total: 0,
          modal_cal_total_cost: 0,
          modal_cal_vat: 0,
          modal_cal_vat_cost: 0,
          modal_cal_wht: [],
          modal_cal_wht_cost: [],
          modal_cal_wht1: 0,
          modal_cal_wht1_cost: 0,
          modal_cal_wht3: 0,
          modal_cal_wht3_cost: 0,
          modal_cal_wht5: 0,
          modal_cal_wht5_cost: 0,
          modal_cal_grand_total: 0,
          modal_cal_total_grand_cost: 0,
        });
      }
    );
  }

  changeSelectSubCategory(event) {
    this.setState(
      {
        subCategory: {
          value: event.value,
          label: event.label,
          service_item_name: event.service_item_name,
          detail: event.detail,
        },
      },
      () => {
        this.setState({
          agentSelected: '',
          subCategoryInvalid: false,
          subCategoryDetail: event.detail,
          qty: 1,
          unit: event.detail.unit,
          price: event.detail.sale,
          cost_price: event.detail.cost,
          discount: 0,
          discount_cost: 0,
          min_price: event.detail.min_price,
          vat: event.detail.vat,
          vat_cost: event.detail.vat_cost,
          wht: event.detail.wht,
          wht_cost: event.detail.wht_cost,
          modal_cal_sale: event.detail.sale,
          modal_cal_cost: event.detail.cost,
          modal_cal_total: event.detail.sale,
          modal_cal_total_cost: event.detail.cost,
          modal_cal_vat: (event.detail.sale * event.detail.vat) / 100,
          modal_cal_vat_cost: (event.detail.cost * event.detail.vat_cost) / 100,
          modal_cal_wht: [
            {
              wht: event.detail.wht,
              total: (event.detail.sale * event.detail.wht) / 100,
            },
          ],
          modal_cal_wht_cost: [
            {
              wht: event.detail.wht_cost,
              total: (event.detail.cost * event.detail.wht_cost) / 100,
            },
          ],
          modal_cal_wht1: event.detail.wht == 1 ? (event.detail.sale * event.detail.wht) / 100 : 0,
          modal_cal_wht1_cost:
            event.detail.wht_cost == 1 ? (event.detail.cost * event.detail.wht_cost) / 100 : 0,
          modal_cal_wht3: event.detail.wht == 3 ? (event.detail.sale * event.detail.wht) / 100 : 0,
          modal_cal_wht3_cost:
            event.detail.wht_cost == 3 ? (event.detail.cost * event.detail.wht_cost) / 100 : 0,
          modal_cal_wht5: event.detail.wht == 5 ? (event.detail.sale * event.detail.wht) / 100 : 0,
          modal_cal_wht5_cost:
            event.detail.wht_cost == 5 ? (event.detail.cost * event.detail.wht_cost) / 100 : 0,
          modal_cal_grand_total:
            event.detail.sale +
            (event.detail.sale * event.detail.vat) / 100 -
            (event.detail.sale * event.detail.wht) / 100,
          modal_cal_total_grand_cost:
            event.detail.cost +
            (event.detail.cost * event.detail.vat_cost) / 100 -
            (event.detail.cost * event.detail.wht_cost) / 100,
        });
      }
    );
  }

  calculatePrice() {
    const {
      qty,
      price,
      cost_price,
      discount,
      discount_cost,
      vat,
      vat_cost,
      wht,
      wht_cost,
      editInvoiceBusinessList,
    } = this.state;

    const sale = price * qty;
    const cost = cost_price * qty;

    const sale_discount = price - (discount ? discount : 0);
    const cost_discount = cost - (discount_cost ? discount_cost : 0);

    this.setState({
      modal_cal_sale: sale,
      modal_cal_cost: cost,
      modal_cal_total: sale_discount,
      modal_cal_total_cost: cost_discount,
      modal_cal_vat: (sale_discount * vat) / 100,
      modal_cal_vat_cost: (cost_discount * vat_cost) / 100,
      modal_cal_wht: [
        {
          wht: wht,
          total: (sale_discount * wht) / 100,
        },
      ],
      modal_cal_wht_cost: [
        {
          wht: wht_cost,
          total: (cost_discount * wht_cost) / 100,
        },
      ],
      modal_cal_wht1: wht == 1 ? (sale_discount * wht) / 100 : 0,
      modal_cal_wht1_cost: wht_cost == 1 ? (cost_discount * wht_cost) / 100 : 0,
      modal_cal_wht3: wht == 3 ? (sale_discount * wht) / 100 : 0,
      modal_cal_wht3_cost: wht_cost == 3 ? (cost_discount * wht_cost) / 100 : 0,
      modal_cal_wht5: wht == 5 ? (sale_discount * wht) / 100 : 0,
      modal_cal_wht5_cost: wht_cost == 5 ? (cost_discount * wht_cost) / 100 : 0,
      modal_cal_grand_total:
        sale_discount + (sale_discount * vat) / 100 - (sale_discount * wht) / 100,
      modal_cal_total_grand_cost:
        cost_discount + (cost_discount * vat_cost) / 100 - (cost_discount * wht_cost) / 100,
    });
  }

  changeSelectVatWht(event, mode) {
    switch (mode) {
      case 'vat_cost':
        this.setState({ vat_cost: { value: event.value, label: event.label } }, () =>
          this.checkValidate('vat_cost')
        );
        break;
      case 'wht':
        this.setState({ wht: { value: event.value, label: event.label } }, () =>
          this.checkValidate('wht')
        );
        break;
      case 'wht_cost':
        this.setState({ wht_cost: { value: event.value, label: event.label } }, () =>
          this.checkValidate('wht_cost')
        );
        break;
      default:
        this.setState({ vat: { value: event.value, label: event.label } }, () =>
          this.checkValidate('vat')
        );
    }
  }

  openEditCostPrice(list, costing) {
    const data = { ...list };

    if (!costing) {
      this.setState({
        requestStatus: 3,
        editInvoiceBusinessList: data,
        showEditCostList: true,
        cal_price: data.total_price,
        cal_cost_price: data.cost_total_price,
        cal_vat: data.total_vat7,
        cal_vat_cost: data.cost_total_vat7,
        cal_wht: this.renderCalWht(data.invoice_business_list),
        cal_wht1: data.total_wht1,
        cal_wht1_cost: data.cost_total_wht1,
        cal_wht3: data.total_wht3,
        cal_wht3_cost: data.cost_total_wht3,
        cal_wht5: data.total_wht5,
        cal_wht5_cost: data.cost_total_wht5,
        cal_total: data.invoice_business_grand_total_price,
        cal_total_cost: data.invoice_business_cost_grand_total_price,
      });
    } else {
      if (costing.costing_status === 3 || costing.costing_status === 4) {
        this.setState({
          costingStatus: costing.costing_status,
          requestStatus:
            costing.costing_status === 4
              ? costing.edit_request_status
                ? costing.edit_request_status
                : 3
              : 3,
          editInvoiceBusinessList: data,
          showEditCostList: true,
          cal_price: data.total_price,
          cal_cost_price: data.cost_total_price,
          cal_vat: data.total_vat7,
          cal_vat_cost: data.cost_total_vat7,
          cal_wht: this.renderCalWht(data.invoice_business_list),
          cal_wht1: data.total_wht1,
          cal_wht1_cost: data.cost_total_wht1,
          cal_wht3: data.total_wht3,
          cal_wht3_cost: data.cost_total_wht3,
          cal_wht5: data.total_wht5,
          cal_wht5_cost: data.cost_total_wht5,
          cal_total: data.invoice_business_grand_total_price,
          cal_total_cost: data.invoice_business_cost_grand_total_price,
        });
      } else if (costing.costing_status === 1) {
        this.setState({
          editInvoiceBusinessList: data,
          costing,
          isOpenRequestEditCostList: true,
          requestStatus: 3,
        });
      } else if (costing.costing_status === 2) {
        this.setState({
          editInvoiceBusinessList: data,
          costing,
          isOpenRequestEditCostList: true,
          requestStatus: 3,
        });
      }
    }
  }

  delInvoiceEdit(data, key) {
    console.log('delInvoiceEdit', key, data);
    let InvoiceBusiness = this.state.editInvoiceBusinessList; // make a separate copy of the array
    InvoiceBusiness.invoice_business_list = InvoiceBusiness.invoice_business_list.filter(
      (item, index) => index != key
    );
    console.log(InvoiceBusiness);

    const invoiceArray = InvoiceBusiness.invoice_business_list;
    const sumdiscount = InvoiceBusiness.invoice_discount
      ? InvoiceBusiness.invoice_discount.reduce(
          (previous, { price }) => previous + parseFloat(price),
          0
        )
      : 0;
    let dc_price = 0;
    let grand_total_price = 0;
    let wht_price = 0;
    let wht_price_total = 0;
    let vat_price = 0;
    let vat_price_total = 0;

    let invoiceArrayWithDiscount = [...invoiceArray];
    if (sumdiscount != 0) {
      invoiceArrayWithDiscount = invoiceArray.filter(
        (item, index) =>
          item.datamaster_service_category_prefix_id != '5e54cef8e99db3b888a2b73d' && index != 0
      );
      const truckPriceArray = invoiceArray.filter(
        (item, index) =>
          item.datamaster_service_category_prefix_id == '5e54cef8e99db3b888a2b73d' && index == 0
      );

      dc_price = truckPriceArray[0].invoice_business_list_price - sumdiscount;
      wht_price = truckPriceArray[0].invoice_business_list_wht;
      wht_price_total = (dc_price * truckPriceArray[0].invoice_business_list_wht) / 100;
      vat_price = truckPriceArray[0].invoice_business_list_vat;
      vat_price_total = (dc_price * truckPriceArray[0].invoice_business_list_total_vat) / 100;
      grand_total_price =
        truckPriceArray[0].invoice_business_list_price *
          truckPriceArray[0].invoice_business_list_qty -
        sumdiscount +
        vat_price_total -
        wht_price_total;
    }

    this.setState({
      editInvoiceBusinessList: InvoiceBusiness,
      cal_price:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_price }) =>
            previous + parseFloat(invoice_business_list_price),
          0
        ) + dc_price,
      cal_cost_price: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_price }) =>
          previous + parseFloat(invoice_business_list_cost_price),
        0
      ),
      cal_vat:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_total_vat }) =>
            previous + parseFloat(invoice_business_list_total_vat),
          0
        ) + vat_price_total,
      cal_vat_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_total_vat }) =>
          previous + parseFloat(invoice_business_list_cost_total_vat),
        0
      ),
      cal_wht: this.renderCalWht(invoiceArrayWithDiscount.invoice_business_list),
      cal_wht1:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_wht, invoice_business_list_total_wht }) =>
            previous +
            (invoice_business_list_wht == 1 ? parseFloat(invoice_business_list_total_wht) : 0),
          0
        ) + (wht_price == 1 ? wht_price_total : 0),
      cal_wht1_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_wht, invoice_business_list_cost_total_wht }) =>
          previous +
          (invoice_business_list_cost_wht == 1
            ? parseFloat(invoice_business_list_cost_total_wht)
            : 0),
        0
      ),
      cal_wht3:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_wht, invoice_business_list_total_wht }) =>
            previous +
            (invoice_business_list_wht == 3 ? parseFloat(invoice_business_list_total_wht) : 0),
          0
        ) + (wht_price == 3 ? wht_price_total : 0),
      cal_wht3_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_wht, invoice_business_list_cost_total_wht }) =>
          previous +
          (invoice_business_list_cost_wht == 3
            ? parseFloat(invoice_business_list_cost_total_wht)
            : 0),
        0
      ),
      cal_wht5:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_wht, invoice_business_list_total_wht }) =>
            previous +
            (invoice_business_list_wht == 5 ? parseFloat(invoice_business_list_total_wht) : 0),
          0
        ) + (wht_price == 5 ? wht_price_total : 0),
      cal_wht5_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_wht, invoice_business_list_cost_total_wht }) =>
          previous +
          (invoice_business_list_cost_wht == 5
            ? parseFloat(invoice_business_list_cost_total_wht)
            : 0),
        0
      ),
      cal_total:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_grand_total_price }) =>
            previous + parseFloat(invoice_business_list_grand_total_price),
          0
        ) + grand_total_price,
      cal_total_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_grand_total_price }) =>
          previous + parseFloat(invoice_business_list_cost_grand_total_price),
        0
      ),
    });
  }

  delInvoiceDiscountEdit(data, key) {
    let invoiceArray = this.state.editInvoiceBusinessList; // make a separate copy of the array
    invoiceArray.invoice_discount = invoiceArray.invoice_discount.filter(
      (item, index) => index != key
    );
    this.setState({ editInvoiceBusinessList: invoiceArray });
  }

  openEditCostPriceModal(mode, Category, data) {
    if (data) {
      const obj = { ...data };
      const list = Category.datamasterServiceCategoryList.map(fee => {
        return {
          value: fee._id,
          label: `${fee.prefix}000 - ${fee.truck_type_prefix} / ${fee.service_category_name.th}`,
          prefix: fee.prefix,
          service_category_name: fee.service_category_name.th,
          truck_type_prefix: fee.truck_type_prefix,
          datamaster_job_type_id: fee.job_type_id,
          truck_type_id: fee.truck_type_id,
          truck_type_prefix_id: fee.truck_type_prefix_id,
          datamaster_service_category_id: fee._id,
          datamaster_service_category_prefix_id: fee.setting_prefix_id,
          datamaster_service_item: fee.datamaster_service_item,
        };
      });
      const mainCategory = list.filter(item => item.value == obj.datamaster_service_category_id);
      const listItem = mainCategory[0].datamaster_service_item.map(fee => {
        return {
          value: fee._id,
          label: `${mainCategory[0].prefix}${fee.datamaster_service_item_number} - ${fee.service_item_name.th}`,
          service_item_name: fee.service_item_name.th,
          detail: fee,
        };
      });
      const subCategory = listItem.filter(item => item.value == obj.datamaster_service_item_id);
      if (obj._id == null) {
      }

      this.setState(
        {
          mode,
          showModalEdit: true,
          dataEditModal: obj,
          mainCategory: mainCategory[0],
          subCategory: subCategory[0],
          mainCategoryInvalid: false,
          subCategoryInvalid: false,
          selectOptionSubCategory: null,
          qty: obj.invoice_business_list_qty,
          unit: '',
          min_price: subCategory[0].detail.min_price ? subCategory[0].detail.min_price : 0,
          price: obj.invoice_business_list_price ? obj.invoice_business_list_price : 0,
          cost_price: obj.invoice_business_list_cost_price
            ? obj.invoice_business_list_cost_price
            : 0,
          discount: obj.invoice_business_list_discount ? obj.invoice_business_list_discount : 0,
          discount_cost: obj.invoice_business_list_cost_discount
            ? obj.invoice_business_list_cost_discount
            : 0,
          vat: obj.invoice_business_list_vat,
          vat_cost: obj.invoice_business_list_cost_vat,
          wht: obj.invoice_business_list_wht,
          wht_cost: obj.invoice_business_list_cost_wht,
          cost_priceInvalid: false,
          unitInvalid: false,
          cost_priceInvalid: false,
          discount_costInvalid: false,
          vat_costInvalid: false,
          wht_costInvalid: false,
          priceInvalid: false,
          discountInvalid: false,
          vatInvalid: false,
          whtInvalid: false,
        },
        () => this.calculatePrice()
      );
    } else {
      this.setState({
        mode,
        showModalEdit: true,
        dataEditModal: null,
        mainCategory: { value: '', label: '-- เลือก --' },
        subCategory: { value: '', label: '-- เลือก --' },
        mainCategoryInvalid: false,
        subCategoryInvalid: false,
        selectOptionSubCategory: null,
        qty: 0,
        unit: '',
        min_price: 0,
        price: 0,
        cost_price: 0,
        discount: 0,
        discount_cost: 0,
        vat: 0,
        vat_cost: 0,
        wht: 0,
        wht_cost: 0,
        modal_cal_total: 0,
        modal_cal_total_cost: 0,
        modal_cal_vat: 0,
        modal_cal_vat_cost: 0,
        modal_cal_wht: [],
        modal_cal_wht_cost: [],
        modal_cal_wht1: 0,
        modal_cal_wht1_cost: 0,
        modal_cal_wht3: 0,
        modal_cal_wht3_cost: 0,
        modal_cal_wht5: 0,
        modal_cal_wht5_cost: 0,
        modal_cal_grand_total: 0,
        modal_cal_total_grand_cost: 0,
        cost_priceInvalid: false,
        unitInvalid: false,
        cost_priceInvalid: false,
        discount_costInvalid: false,
        vat_costInvalid: false,
        wht_costInvalid: false,
        priceInvalid: false,
        discountInvalid: false,
        vatInvalid: false,
        whtInvalid: false,
      });
    }
  }

  renderPayType(status) {
    switch (status) {
      case 0:
        return 'ไม่มีค่าใช้จ่าย';
      case 1:
        return 'บัตรเดบิต/เครดิต';
      case 2:
        return 'เงินสด';
      case 3:
        return 'ออกใบแจ้งหนี้';
      default:
        return '';
    }
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 2:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      default:
        return '';
    }
  }

  renderStatusPayment(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 2:
        return <Label bsStyle="warning">รอตรวจสอบ</Label>;
      case 3:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      case 4:
        return <Label bsStyle="danger">ค้างชำระ</Label>;
      case 5:
        return <Label>แบบร่าง</Label>;
      case 6:
        return <Label bsStyle="default">รอสร้างใบเพิ่มหนี้</Label>;
      case 7:
        return <Label bsStyle="default">รอสร้างใบแจ้งหนี้</Label>;
      default:
        return '-';
    }
  }

  renderStatusCosting(status, edit_request_status) {
    switch (true) {
      case edit_request_status === 1:
        return <Label bsStyle="warning">ส่งคำขอแก้ไข</Label>;
      case edit_request_status === 3:
        return <Label bsStyle="danger">ปฎิเสธคำขอแก้ไข</Label>;
      case status === 1:
        return <Label bsStyle="primary">รอตรวจสอบ</Label>;
      case status === 2:
        return <Label bsStyle="success">ตรวจสอบแล้ว</Label>;
      case status === 3:
        return <Label bsStyle="danger">ปฎิเสธ</Label>;
      case status === 4:
        return <Label bsStyle="warning">แก้ไข</Label>;
      default:
        return '-';
    }
  }

  checkValidateSabmit() {
    const { qty, price, cost_price, min_price, discount, discount_cost } = this.state;
    var regex = /[.0-9]/;

    this.setState({
      discountInvalid: !regex.test(discount),
      discount_costInvalid: !regex.test(discount_cost),
      qtyInvalid: !regex.test(qty) || qty <= 0,
      priceInvalid: !regex.test(price) || price < min_price,
      cost_priceInvalid: !regex.test(cost_price),
    });

    return (
      regex.test(qty) &&
      qty > 0 &&
      regex.test(price) &&
      price >= min_price &&
      regex.test(cost_price) &&
      regex.test(discount) &&
      regex.test(discount_cost)
    );
  }

  checkValidate(name) {
    const {
      qty,
      price,
      cost_price,
      min_price,
      discount,
      discount_cost,
      vat,
      vat_cost,
      wht,
      wht_cost,
      unit,
      qtyInvalid,
      unitInvalid,
      priceInvalid,
      cost_priceInvalid,
      discountInvalid,
      discount_costInvalid,
      vat_costInvalid,
      wht_costInvalid,
      vatInvalid,
      whtInvalid,
    } = this.state;
    console.log('checkValidate', wht);
    var regex = /[.0-9]/g;

    if (name == 'unit') {
      if (unit) {
        this.setState({
          unitInvalid: false,
        });
      } else {
        this.setState({
          unitInvalid: true,
        });
      }
    } else if (name == 'discount') {
      if (discount) {
        if (regex.test(discount)) {
          this.setState(
            {
              discountInvalid: false,
            },
            () => this.calculatePrice()
          );
        } else {
          this.setState({
            discountInvalid: true,
          });
        }
      } else {
        this.setState(
          {
            discountInvalid: false,
          },
          () => this.calculatePrice()
        );
      }
    } else if (name == 'discount_cost') {
      if (discount_cost) {
        if (regex.test(discount_cost)) {
          this.setState(
            {
              discount_costInvalid: false,
            },
            () => this.calculatePrice()
          );
        } else {
          this.setState({
            discount_costInvalid: true,
          });
        }
      } else {
        this.setState(
          {
            discount_costInvalid: false,
          },
          () => this.calculatePrice()
        );
      }
    } else if (name == 'qty') {
      if (regex.test(qty) && qty > 0) {
        this.setState(
          {
            qtyInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          qtyInvalid: true,
        });
      }
    } else if (name == 'vat') {
      if (regex.test(vat) && vat >= 0) {
        this.setState(
          {
            vatInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          vatInvalid: true,
        });
      }
    } else if (name == 'vat_cost') {
      if (regex.test(vat_cost) && vat_cost >= 0) {
        this.setState(
          {
            vat_costInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          vat_costInvalid: true,
        });
      }
    } else if (name == 'wht') {
      if (regex.test(wht) && wht >= 0) {
        this.setState(
          {
            whtInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          whtInvalid: true,
        });
      }
    } else if (name == 'wht_cost') {
      if (regex.test(wht_cost) && wht_cost >= 0) {
        this.setState(
          {
            wht_costInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          wht_costInvalid: true,
        });
      }
    } else if (name == 'price') {
      if (regex.test(price) && price >= min_price) {
        this.setState(
          {
            priceInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          priceInvalid: true,
        });
      }
    } else if (name == 'cost_price') {
      if (regex.test(cost_price)) {
        this.setState(
          {
            cost_priceInvalid: false,
          },
          () => this.calculatePrice()
        );
      } else {
        this.setState({
          cost_priceInvalid: true,
        });
      }
    } else {
      this.calculatePrice();
    }
  }

  sumInvoiceDiscount(list) {
    let total = 0;
    if (list) {
      list.map(num => {
        total += parseFloat(num.price);
      });
    }

    return total;
  }

  submitInvoiceItem() {
    const {
      qty,
      price,
      cost_price,
      min_price,
      discount,
      discount_cost,
      vat,
      vat_cost,
      wht,
      wht_cost,
      unit,
      dataEditModal,
      modal_cal_sale,
      modal_cal_cost,
      modal_cal_total,
      modal_cal_total_cost,
      modal_cal_vat,
      modal_cal_vat_cost,
      modal_cal_wht_cost,
      modal_cal_wht,
      modal_cal_wht1,
      modal_cal_wht1_cost,
      modal_cal_wht3,
      modal_cal_wht3_cost,
      modal_cal_wht5,
      modal_cal_wht5_cost,
      modal_cal_grand_total,
      modal_cal_total_grand_cost,
      mainCategory,
      subCategory,
      edit_id,
      mode,
      agentId,
    } = this.state;

    let InvoiceBusiness = { ...this.state.editInvoiceBusinessList };
    let invoiceArray = [...InvoiceBusiness.invoice_business_list]; // make a separate copy of the array

    const obj = {
      _id: dataEditModal ? dataEditModal._id : null,
      edit_id: dataEditModal ? dataEditModal.edit_id : edit_id + 1,
      payment_order_id:
        dataEditModal && dataEditModal.payment_order_id ? dataEditModal.payment_order_id : [],
      datamaster_job_type_id: dataEditModal
        ? dataEditModal.datamaster_job_type_id
        : mainCategory.datamaster_job_type_id,
      datamaster_truck_type_id: dataEditModal
        ? dataEditModal.datamaster_truck_type_id
        : mainCategory.truck_type_id,
      datamaster_truck_type_prefix_id: dataEditModal
        ? dataEditModal.datamaster_truck_type_prefix_id
        : mainCategory.truck_type_prefix_id,
      datamaster_service_category_id: dataEditModal
        ? dataEditModal.datamaster_service_category_id
        : mainCategory.datamaster_service_category_id,
      datamaster_service_category_prefix_id: dataEditModal
        ? dataEditModal.datamaster_service_category_prefix_id
        : mainCategory.datamaster_service_category_prefix_id,
      datamaster_service_item_id: dataEditModal
        ? dataEditModal.datamaster_service_item_id
        : subCategory.value,
      invoice_list_id: dataEditModal ? dataEditModal.invoice_list_id : null,
      invoice_business_list_category: dataEditModal
        ? dataEditModal.invoice_business_list_category
        : mainCategory.service_category_name,
      invoice_business_list_detail: dataEditModal
        ? dataEditModal.invoice_business_list_detail
        : subCategory.service_item_name,
      invoice_business_list_price: price,
      invoice_business_list_type: dataEditModal ? dataEditModal.invoice_business_list_type : null,
      invoice_business_list_qty: qty,
      invoice_business_list_total_price: modal_cal_sale,
      invoice_business_list_discount: Number(discount),
      invoice_business_list_discount_cost: Number(discount_cost),
      invoice_business_list_tax_type: dataEditModal
        ? dataEditModal.invoice_business_list_tax_type
        : null,
      invoice_business_list_vat: vat,
      invoice_business_list_wht: wht,
      invoice_business_list_total_vat: (price * qty * vat) / 100,
      invoice_business_list_total_wht: (price * qty * wht) / 100,
      invoice_business_list_grand_total_price: modal_cal_grand_total,
      invoice_business_list_cost_price: cost_price,
      invoice_business_list_cost_total_price: modal_cal_cost,
      invoice_business_list_cost_discount: Number(discount_cost),
      invoice_business_list_cost_vat: vat_cost,
      invoice_business_list_cost_wht: wht_cost,
      invoice_business_list_cost_total_vat: (cost_price * qty * vat_cost) / 100,
      invoice_business_list_cost_total_wht: (cost_price * qty * wht_cost) / 100,
      invoice_business_list_cost_grand_total_price: modal_cal_total_grand_cost,
    };

    if (obj._id == null) {
      if (mode == 'new') {
        invoiceArray.push(obj);
      } else {
        const index = invoiceArray.findIndex(x => x.edit_id === obj.edit_id);
        invoiceArray[index] = obj;
      }
    } else {
      const index = invoiceArray.findIndex(x => x._id === obj._id);
      invoiceArray[index] = obj;
    }
    InvoiceBusiness.invoice_business_list = invoiceArray;

    const sumdiscount = InvoiceBusiness.invoice_discount
      ? InvoiceBusiness.invoice_discount.reduce(
          (previous, { price }) => previous + parseFloat(price),
          0
        )
      : 0;
    let dc_price = 0;
    let grand_total_price = 0;
    let wht_price = 0;
    let wht_price_total = 0;
    let vat_price = 0;
    let vat_price_total = 0;

    let invoiceArrayWithDiscount = [...invoiceArray];
    if (sumdiscount != 0) {
      invoiceArrayWithDiscount = invoiceArray.filter(
        (item, index) =>
          item.datamaster_service_category_prefix_id != '5e54cef8e99db3b888a2b73d' && index != 0
      );
      const truckPriceArray = invoiceArray.filter(
        (item, index) =>
          item.datamaster_service_category_prefix_id == '5e54cef8e99db3b888a2b73d' && index == 0
      );

      dc_price = truckPriceArray[0].invoice_business_list_price - sumdiscount;
      wht_price = truckPriceArray[0].invoice_business_list_wht;
      wht_price_total = (dc_price * truckPriceArray[0].invoice_business_list_wht) / 100;
      vat_price = truckPriceArray[0].invoice_business_list_vat;
      vat_price_total = (dc_price * truckPriceArray[0].invoice_business_list_total_vat) / 100;
      grand_total_price =
        truckPriceArray[0].invoice_business_list_price *
          truckPriceArray[0].invoice_business_list_qty -
        sumdiscount +
        vat_price_total -
        wht_price_total;
    }

    console.log('InvoiceBusiness ', InvoiceBusiness);

    this.setState({
      editInvoiceBusinessList: InvoiceBusiness,
      edit_id: edit_id + 1,
      showModalEdit: false,
      cal_price:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_price }) =>
            previous + parseFloat(invoice_business_list_price),
          0
        ) + dc_price,
      cal_cost_price: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_price }) =>
          previous + parseFloat(invoice_business_list_cost_price),
        0
      ),
      cal_vat:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_total_vat }) =>
            previous + parseFloat(invoice_business_list_total_vat),
          0
        ) + vat_price_total,
      cal_vat_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_total_vat }) =>
          previous + parseFloat(invoice_business_list_cost_total_vat),
        0
      ),
      cal_wht: this.renderCalWht(invoiceArrayWithDiscount),
      cal_wht1:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_wht, invoice_business_list_total_wht }) =>
            previous +
            (invoice_business_list_wht == 1 ? parseFloat(invoice_business_list_total_wht) : 0),
          0
        ) + (wht_price == 1 ? wht_price_total : 0),
      cal_wht1_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_wht, invoice_business_list_cost_total_wht }) =>
          previous +
          (invoice_business_list_cost_wht == 1
            ? parseFloat(invoice_business_list_cost_total_wht)
            : 0),
        0
      ),
      cal_wht3:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_wht, invoice_business_list_total_wht }) =>
            previous +
            (invoice_business_list_wht == 3 ? parseFloat(invoice_business_list_total_wht) : 0),
          0
        ) + (wht_price == 3 ? wht_price_total : 0),
      cal_wht3_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_wht, invoice_business_list_cost_total_wht }) =>
          previous +
          (invoice_business_list_cost_wht == 3
            ? parseFloat(invoice_business_list_cost_total_wht)
            : 0),
        0
      ),
      cal_wht5:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_wht, invoice_business_list_total_wht }) =>
            previous +
            (invoice_business_list_wht == 5 ? parseFloat(invoice_business_list_total_wht) : 0),
          0
        ) + (wht_price == 5 ? wht_price_total : 0),
      cal_wht5_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_wht, invoice_business_list_cost_total_wht }) =>
          previous +
          (invoice_business_list_cost_wht == 5
            ? parseFloat(invoice_business_list_cost_total_wht)
            : 0),
        0
      ),
      cal_total:
        invoiceArrayWithDiscount.reduce(
          (previous, { invoice_business_list_grand_total_price }) =>
            previous + parseFloat(invoice_business_list_grand_total_price),
          0
        ) + grand_total_price,
      cal_total_cost: invoiceArray.reduce(
        (previous, { invoice_business_list_cost_grand_total_price }) =>
          previous + parseFloat(invoice_business_list_cost_grand_total_price),
        0
      ),
    });
  }

  renderVariables() {
    let obj = [];
    if (this.state.editInvoiceBusinessList) {
      this.state.editInvoiceBusinessList.invoice_business_list.forEach((ele, key) => {
        if (ele._id) {
          const data = {
            _id: ele._id,
            create_costing: ele.create_costing || false,
            invoice_status_update: 2,
            payment_order_id: ele.payment_order_id ? ele.payment_order_id : [],
            datamaster_job_type_id: ele.datamaster_job_type_id,
            datamaster_truck_type_id: ele.datamaster_truck_type_id,
            datamaster_truck_type_prefix_id: ele.datamaster_truck_type_prefix_id,
            datamaster_service_category_id: ele.datamaster_service_category_id,
            datamaster_service_item_id: ele.datamaster_service_item_id,
            datamaster_service_category_prefix_id: ele.datamaster_service_category_prefix_id,
            invoice_business_list_tax_type: ele.invoice_business_list_tax_type
              ? ele.invoice_business_list_tax_type
              : null,
            invoice_business_list_type: ele.invoice_business_list_type,
            invoice_business_list_detail: ele.invoice_business_list_detail,
            invoice_business_list_price: ele.invoice_business_list_price,
            invoice_business_list_cost_price: ele.invoice_business_list_cost_price,
            invoice_business_list_qty: ele.invoice_business_list_qty,
            invoice_business_list_discount: ele.invoice_business_list_discount,
            invoice_business_list_cost_discount: Number(ele.invoice_business_list_discount_cost),
            invoice_business_list_vat: ele.invoice_business_list_vat,
            invoice_business_list_wht: ele.invoice_business_list_wht,
            invoice_business_list_cost_vat: ele.invoice_business_list_cost_vat,
            invoice_business_list_cost_wht: ele.invoice_business_list_cost_wht,
          };
          obj.push(data);
        } else {
          const data = {
            create_costing: ele.create_costing || false,
            invoice_status_update: 1,
            payment_order_id: ele.payment_order_id ? ele.payment_order_id : [],
            datamaster_job_type_id: ele.datamaster_job_type_id,
            datamaster_truck_type_id: ele.datamaster_truck_type_id,
            datamaster_truck_type_prefix_id: ele.datamaster_truck_type_prefix_id,
            datamaster_service_category_id: ele.datamaster_service_category_id,
            datamaster_service_item_id: ele.datamaster_service_item_id,
            datamaster_service_category_prefix_id: ele.datamaster_service_category_prefix_id,
            invoice_business_list_tax_type: ele.invoice_business_list_tax_type
              ? ele.invoice_business_list_tax_type
              : null,
            invoice_business_list_type: ele.invoice_business_list_type,
            invoice_business_list_detail: ele.invoice_business_list_detail,
            invoice_business_list_price: ele.invoice_business_list_price,
            invoice_business_list_cost_price: ele.invoice_business_list_cost_price,
            invoice_business_list_qty: ele.invoice_business_list_qty,
            invoice_business_list_discount: ele.invoice_business_list_discount,
            invoice_business_list_cost_discount: ele.invoice_business_list_discount_cost,
            invoice_business_list_vat: ele.invoice_business_list_vat,
            invoice_business_list_wht: ele.invoice_business_list_wht,
            invoice_business_list_cost_vat: ele.invoice_business_list_cost_vat,
            invoice_business_list_cost_wht: ele.invoice_business_list_cost_wht,
          };
          obj.push(data);
        }
      });
    }
    return obj;
  }

  //Autosuggest Agent
  getSuggestionsAgent = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_type: 3,
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        agentSuggestions:
          transporterData.length == 0
            ? [
                {
                  id: 'dd',
                  name: 'ddddd',
                },
                {
                  id: 'ee',
                  name: 'ee',
                },
              ]
            : transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueAgent = suggestion => suggestion.name;

  renderSuggestionAgent = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedAgent = ({ value }) => {
    this.getSuggestionsAgent(value);
  };

  onSuggestionsClearRequestedAgent = () => {
    this.setState({
      agentSuggestions: [],
    });
  };

  shouldRenderSuggestionsAgent() {
    return true;
  }
  //End Autosuggest Agent

  refetchCostingQuery = async billingCostingId => {
    try {
      const { data: costingList } = await client.query({
        query: costingQuery,
        fetchPolicy: 'network-only',
        variables: {
          _id: billingCostingId,
        },
      });
      return costingList ? costingList.costing : null;
    } catch (error) {
      console.log('errorRefetchCostingQuery', error);
      return null;
    }
  };

  render() {
    let billingCostingData = null;
    let adminUsername = '';
    let admin_id = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      adminUsername = this.props.adminName.adminList.admin[0].admin_username;
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }

    return (
      <div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Query
                query={detailShipmentQuery}
                variables={{
                  _id: this.state.shipmentId,
                  shipment_prefix: 'GLZ',
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  if (loading) return 'Loading...';
                  if (error) return { error };

                  const shipment = data.shipmentList.shipment;
                  const billingCostingId = shipment[0].billing_costing || null;

                  if (billingCostingId) {
                    (async () => {
                      billingCostingData = await this.refetchCostingQuery(billingCostingId);
                    })();
                  }

                  console.log('shipment', shipment, billingCostingData);

                  return (
                    <Query
                      query={datamasterServiceCategoryListQuery}
                      variables={{
                        truck_type_id: shipment[0].setting_truck_type_id,
                      }}
                    >
                      {({ loading, error, data: Category, refetch, variables }) => {
                        if (loading || error) return null;
                        return (
                          <Col md={12} className="card-shipment">
                            <div style={{ margin: '5px -15px 0' }}>
                              <HeaderContainer>
                                <div className="sub-header-left">
                                  <a
                                    href={`/lazada-shipment-list?id=${this.state.lazadaImportOrderId}`}
                                    className="btn-back"
                                  >
                                    <i className="far fa-arrow-left"></i> กลับ
                                  </a>
                                  <div className="line"></div>
                                  <div className="menu-wrapper">
                                    <ul>
                                      <li>
                                        <a
                                          href={`/lazada-shipment-detail?id=${this.state.shipmentId}`}
                                        >
                                          {' '}
                                          <i className="fal fa-truck"></i> ข้อมูลงานขนส่ง
                                        </a>
                                      </li>
                                      <li className="active">
                                        <a>
                                          {' '}
                                          <i className="fal fa-sack-dollar"></i> ต้นทุน - ราคาขาย
                                        </a>
                                      </li>
                                      {/* 
                                      <li>
                                        <a href={`/shipmentpvc?shipment=${this.state.shipmentId}`}>
                                          {' '}
                                          <i className="fal fa-money-check-alt"></i> รายการสั่งจ่าย
                                        </a>
                                      </li>
                                      <li>
                                          <a href={`/shipmentpayment?shipment=${this.state.shipmentId}`}> <i className="fal fa-file-invoice-dollar"></i> ใบแจ้งหนี้-เพิ่มหนี้</a>
                                      </li> */}
                                    </ul>
                                  </div>
                                </div>
                              </HeaderContainer>
                              <SubHeaderContainer>
                                <div className="sub-header-left">
                                  <strong>
                                    {shipment[0].shipment_number
                                      ? `รหัสการขนส่ง: ${shipment[0].shipment_number}`
                                      : 'แบบร่าง'}
                                  </strong>

                                  <strong style={{ marginLeft: 10 }}>
                                    {shipment[0].shipment_trip
                                      ? `Trip No: ${shipment[0].shipment_trip}`
                                      : `Trip Name: ${
                                          shipment[0].shipment_name ? shipment[0].shipment_name : ''
                                        }`}
                                  </strong>
                                  <Query
                                    query={summaryInvoiceList}
                                    variables={{
                                      shipment_id: this.state.shipmentId,
                                    }}
                                  >
                                    {({ loading, error, data, refetch, variables }) => {
                                      if (loading)
                                        return <div style={{ marginLeft: 20 }}>Loading...</div>;
                                      if (error) return { error };

                                      const summaryInvoice =
                                        data.summaryInvoiceList.summaryInvoice[0];

                                      return summaryInvoice ? (
                                        <div style={{ marginLeft: 20 }}>
                                          <a
                                            href={`/paymentdetail?id=${summaryInvoice._id}&type=invoice`}
                                            target="_blank"
                                          >
                                            <strong>
                                              {summaryInvoice &&
                                                `เลขที่ใบแจ้งหนี้: ${summaryInvoice.summary_invoice_number}`}
                                            </strong>
                                          </a>
                                        </div>
                                      ) : null;
                                    }}
                                  </Query>
                                </div>
                              </SubHeaderContainer>
                            </div>
                            <Card
                              ctTableFullWidth
                              ctTableResponsive
                              content={
                                <Query
                                  query={invoiceListQuery}
                                  variables={{
                                    shipment_id: this.state.shipmentId,
                                  }}
                                >
                                  {({ loading, error, data, refetch, variables }) => {
                                    if (loading) return 'Loading...';
                                    if (error) return { error };

                                    const invoiceBusinessList = data.invoiceList[0];
                                    const invoiceData = invoiceBusinessList.invoice_business_list;

                                    const dataWHT = this.renderCalWht(invoiceData);

                                    const OmiseCost =
                                      (invoiceBusinessList.invoice_business_grand_total_price /
                                        100) *
                                        3.25 +
                                      (((invoiceBusinessList.invoice_business_grand_total_price /
                                        100) *
                                        3.25) /
                                        100) *
                                        7;
                                    const costGrandTotal =
                                      invoiceBusinessList[
                                        'invoice_business_cost_grand_total_price'
                                      ];

                                    return (
                                      <div>
                                        <Row>
                                          <Col sm={4}>
                                            {invoiceBusinessList.billing_costing ? (
                                              <Query
                                                query={costingQuery}
                                                variables={{
                                                  _id: invoiceBusinessList.billing_costing,
                                                }}
                                              >
                                                {({ loading, error, data, refetch, variables }) => {
                                                  if (loading) return 'Loading...';
                                                  if (error)
                                                    return (
                                                      <BoxDashboard>
                                                        <Col
                                                          md={8}
                                                          style={{
                                                            textAlign: 'left',
                                                            paddingRight: 0,
                                                          }}
                                                        >
                                                          <div>รายการต้นทุน-ขาย: </div>
                                                          <h4>รายการที่ส่งให้บัญชีถูกลบ</h4>
                                                        </Col>
                                                      </BoxDashboard>
                                                    );

                                                  const costingData = data.costing;

                                                  if (costingData)
                                                    return (
                                                      <BoxDashboard>
                                                        <Col
                                                          sm={8}
                                                          style={{
                                                            textAlign: 'left',
                                                            paddingRight: 0,
                                                          }}
                                                        >
                                                          <div>รายการต้นทุน-ขาย: </div>
                                                          <h4>{costingData.costing_number}</h4>
                                                        </Col>
                                                        <Col
                                                          sm={4}
                                                          style={{
                                                            textAlign: 'right',
                                                            paddingLeft: 0,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              marginTop: -9,
                                                              marginBottom: 9,
                                                            }}
                                                          >
                                                            <Button
                                                              style={{ padding: '4px 6px' }}
                                                              className="btn btn-default btn-sm"
                                                              onClick={() => {
                                                                this.setState({
                                                                  costingId: costingData._id,
                                                                  costing: costingData,
                                                                  isOpenDetailCostingOrderList: true,
                                                                });
                                                              }}
                                                            >
                                                              <i
                                                                style={{ marginTop: '3px' }}
                                                                className="fas fa-eye"
                                                              ></i>
                                                            </Button>
                                                          </div>
                                                          {this.renderStatusCosting(
                                                            costingData.costing_status,
                                                            costingData.edit_request_status
                                                          )}
                                                        </Col>
                                                        {costingData.edit_request_status === 3 && (
                                                          <Col
                                                            sm={12}
                                                            style={{
                                                              textAlign: 'left',
                                                              paddingRight: 0,
                                                              color: '#d90201',
                                                            }}
                                                          >
                                                            <small>
                                                              เหตุผลปฎิเสธแก้ไข:{' '}
                                                              {costingData.edit_request_remark}
                                                            </small>
                                                          </Col>
                                                        )}
                                                      </BoxDashboard>
                                                    );
                                                }}
                                              </Query>
                                            ) : (
                                              <BoxDashboard>
                                                <Col
                                                  md={8}
                                                  style={{ textAlign: 'left', paddingRight: 0 }}
                                                >
                                                  <div>รายการต้นทุน-ขาย: </div>
                                                  <h4>ยังไม่ส่งรายการให้บัญชี</h4>
                                                </Col>
                                              </BoxDashboard>
                                            )}
                                          </Col>
                                          <Col sm={3} style={{ paddingLeft: 0 }}>
                                            <BoxDashboard>
                                              <Col sm={6}>
                                                <div>รวมราคาต้นทุน (รวมภาษี): </div>
                                                <h4 style={{ color: '#019FD9' }}>
                                                  {costGrandTotal.toLocaleString('en-US', {
                                                    maximumFractionDigits: 2,
                                                  })}{' '}
                                                  บาท
                                                </h4>
                                              </Col>
                                              <Col sm={6} style={{ textAlign: 'right' }}>
                                                <div style={{ marginBottom: 5 }}>
                                                  สถานะการจ่ายเงิน:
                                                </div>
                                                -
                                              </Col>
                                            </BoxDashboard>
                                          </Col>
                                          <Col sm={3} style={{ paddingLeft: 0 }}>
                                            <BoxDashboard>
                                              <Col sm={6}>
                                                <div>รวมราคาขาย (รวมภาษี): </div>
                                                <h4 style={{ color: '#D90101' }}>
                                                  {invoiceBusinessList[
                                                    'invoice_business_grand_total_price'
                                                  ].toLocaleString('en-US', {
                                                    maximumFractionDigits: 2,
                                                  })}{' '}
                                                  บาท
                                                </h4>
                                              </Col>
                                              <Col sm={6} style={{ textAlign: 'right' }}>
                                                <div style={{ marginBottom: 5 }}>
                                                  สถานะการรับเงิน:
                                                </div>
                                                {this.renderStatusPayment(shipment[0].paid_status)}
                                              </Col>
                                            </BoxDashboard>
                                          </Col>
                                          <Col sm={2} style={{ paddingLeft: 0 }}>
                                            <BoxDashboard>
                                              <Col md={12}>
                                                <span>ผลกำไร (รวมภาษี):</span>
                                                <h4
                                                  style={{
                                                    color: `${
                                                      costGrandTotal <
                                                      invoiceBusinessList[
                                                        'invoice_business_grand_total_price'
                                                      ]
                                                        ? '#19AA19'
                                                        : '#19AA19'
                                                    }`,
                                                  }}
                                                >
                                                  {(
                                                    invoiceBusinessList[
                                                      'invoice_business_grand_total_price'
                                                    ] - costGrandTotal
                                                  ).toLocaleString('en-US', {
                                                    maximumFractionDigits: 2,
                                                  })}{' '}
                                                  บาท
                                                </h4>
                                              </Col>
                                            </BoxDashboard>
                                          </Col>
                                        </Row>

                                        <Row style={{ marginBottom: '10px' }}>
                                          <Col md={6}>
                                            <Card
                                              ctTableFullWidth
                                              ctTableResponsive
                                              content={
                                                <>
                                                  <Row>
                                                    <Col md={12}>
                                                      <TitleCard>ต้นทุนและราคาขาย</TitleCard>
                                                      <div
                                                        style={{
                                                          float: 'right',
                                                          marginLeft: '15px',
                                                        }}
                                                      >
                                                        {this.state.showEditCostList ? (
                                                          <Button
                                                            bsStyle="link"
                                                            onClick={() =>
                                                              this.setState({
                                                                showEditCostList: false,
                                                              })
                                                            }
                                                          >
                                                            <i className="fal fa-chevron-left"></i>{' '}
                                                            ยกเลิกการแก้ไข
                                                          </Button>
                                                        ) : (
                                                          <div>
                                                            {invoiceBusinessList.billing_costing ? (
                                                              <Query
                                                                query={costingQuery}
                                                                variables={{
                                                                  _id:
                                                                    invoiceBusinessList.billing_costing,
                                                                }}
                                                              >
                                                                {({
                                                                  loading,
                                                                  error,
                                                                  data,
                                                                  refetch,
                                                                  variables,
                                                                }) => {
                                                                  if (loading) return 'Loading...';
                                                                  if (error) return null;
                                                                  const costingData = data.costing;
                                                                  if (costingData)
                                                                    return (
                                                                      <>
                                                                        {costingData.costing_status ==
                                                                          4 ||
                                                                        costingData.costing_status ==
                                                                          3 ? (
                                                                          <Button
                                                                            className="btn btn-primary"
                                                                            onClick={() => {
                                                                              this.setState({
                                                                                invoiceBusinessList: invoiceBusinessList,
                                                                                isOpenUpdateCosingOrderList: true,
                                                                                costingId:
                                                                                  costingData._id,
                                                                                costing: costingData,
                                                                              });
                                                                            }}
                                                                          >
                                                                            อัพเดตข้อมูล CT
                                                                          </Button>
                                                                        ) : null}
                                                                        <Button
                                                                          bsStyle="link"
                                                                          onClick={() =>
                                                                            this.openEditCostPrice(
                                                                              invoiceBusinessList,
                                                                              costingData
                                                                            )
                                                                          }
                                                                        >
                                                                          แก้ไขรายละเอียด{' '}
                                                                          <i className="fal fa-chevron-right"></i>
                                                                        </Button>
                                                                      </>
                                                                    );
                                                                }}
                                                              </Query>
                                                            ) : (
                                                              <>
                                                                {shipment[0].shipment_status ===
                                                                  4 ||
                                                                shipment[0].shipment_status ===
                                                                  5 ? (
                                                                  <Button
                                                                    className="btn btn-primary"
                                                                    onClick={() => {
                                                                      this.setState({
                                                                        invoiceBusinessList: invoiceBusinessList,
                                                                        isOpenCreateCostingOrderList: true,
                                                                      });
                                                                    }}
                                                                  >
                                                                    สร้างรายการ CT
                                                                  </Button>
                                                                ) : null}

                                                                <Button
                                                                  bsStyle="link"
                                                                  onClick={() =>
                                                                    this.openEditCostPrice(
                                                                      invoiceBusinessList,
                                                                      this.state.costing ||
                                                                        billingCostingData
                                                                    )
                                                                  }
                                                                >
                                                                  แก้ไขรายละเอียด{' '}
                                                                  <i className="fal fa-chevron-right"></i>
                                                                </Button>
                                                              </>
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                  <Row>
                                                    <Col md={12}>
                                                      <TableCost responsive>
                                                        <thead>
                                                          <tr className="border-top">
                                                            <td>
                                                              <b>รายการ</b>
                                                            </td>
                                                            <td>
                                                              <b>ราคาต้นทุน</b>
                                                            </td>
                                                            <td>
                                                              <b>ราคาขาย</b>
                                                            </td>
                                                          </tr>
                                                        </thead>

                                                        {
                                                          // '5e54cef8e99db3b888a2b73d'  'ค่าขนส่ง', prefix: "T1"
                                                          // '5e54cef8e99db3b888a2b749'  "ค่าบริการ", prefix: "S1"
                                                          // '5e54cef8e99db3b888a2b755' "ค่าสำรองจ่าย", prefix: "R1"
                                                          //console.log(this.sumInvoiceDiscount(invoiceBusinessList.invoice_discount))
                                                        }
                                                        <tbody>
                                                          <tr className="border-top">
                                                            <td colSpan={3}>
                                                              <b>ค่าขนส่ง</b>
                                                            </td>
                                                          </tr>
                                                          {invoiceData.map(
                                                            (list, key) =>
                                                              list.datamaster_service_category_prefix_id !=
                                                                '5e54cef8e99db3b888a2b749' &&
                                                              list.datamaster_service_category_prefix_id !=
                                                                '5e54cef8e99db3b888a2b755' && (
                                                                <tr key={key}>
                                                                  <td>
                                                                    {' '}
                                                                    <DetailText>
                                                                      {
                                                                        list.invoice_business_list_detail
                                                                      }
                                                                    </DetailText>{' '}
                                                                    [x
                                                                    {list.invoice_business_list_qty}
                                                                    ]{' '}
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_cost_total_price
                                                                      ? (
                                                                          list.invoice_business_list_cost_total_price -
                                                                            list.invoice_business_list_cost_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_total_price
                                                                      ? (
                                                                          list.invoice_business_list_total_price -
                                                                          list.invoice_business_list_discount +
                                                                          this.sumInvoiceDiscount(
                                                                            invoiceBusinessList.invoice_discount
                                                                          )
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              )
                                                          )}

                                                          {invoiceBusinessList.invoice_discount.map(
                                                            (discount, key) => (
                                                              <tr key={key}>
                                                                <td>
                                                                  {discount.detail}{' '}
                                                                  {discount.percent ? (
                                                                    <span>
                                                                      [{discount.percent}%]
                                                                    </span>
                                                                  ) : (
                                                                    ''
                                                                  )}
                                                                </td>
                                                                <td></td>
                                                                <td>
                                                                  -{' '}
                                                                  {discount.price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )}{' '}
                                                                  บาท
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}

                                                          {invoiceData.filter(
                                                            item =>
                                                              item.datamaster_service_category_prefix_id ==
                                                              '5e54cef8e99db3b888a2b749'
                                                          ).length > 0 && (
                                                            <tr className="border-top">
                                                              <td colSpan={3}>
                                                                <b>ค่าบริการเสริม</b>
                                                              </td>
                                                            </tr>
                                                          )}

                                                          {invoiceData.map(
                                                            (list, key) =>
                                                              list.datamaster_service_category_prefix_id ==
                                                                '5e54cef8e99db3b888a2b749' && (
                                                                <tr key={key}>
                                                                  <td>
                                                                    {
                                                                      list.invoice_business_list_detail
                                                                    }{' '}
                                                                    [x
                                                                    {list.invoice_business_list_qty}
                                                                    ]{' '}
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_cost_total_price
                                                                      ? (
                                                                          list.invoice_business_list_cost_total_price -
                                                                            list.invoice_business_list_cost_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_total_price
                                                                      ? (
                                                                          list.invoice_business_list_total_price -
                                                                            list.invoice_business_list_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              )
                                                          )}

                                                          {invoiceData.filter(
                                                            item =>
                                                              item.datamaster_service_category_prefix_id ==
                                                              '5e54cef8e99db3b888a2b755'
                                                          ).length > 0 && (
                                                            <tr className="border-top">
                                                              <td colSpan={3}>
                                                                <b>ค่าสำรองจ่าย</b>
                                                              </td>
                                                            </tr>
                                                          )}

                                                          {invoiceData.map(
                                                            (list, key) =>
                                                              list.datamaster_service_category_prefix_id ==
                                                                '5e54cef8e99db3b888a2b755' && (
                                                                <tr key={key}>
                                                                  <td>
                                                                    {
                                                                      list.invoice_business_list_detail
                                                                    }{' '}
                                                                    [x
                                                                    {list.invoice_business_list_qty}
                                                                    ]{' '}
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_cost_total_price
                                                                      ? (
                                                                          list.invoice_business_list_cost_total_price -
                                                                            list.invoice_business_list_cost_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_total_price
                                                                      ? (
                                                                          list.invoice_business_list_total_price -
                                                                            list.invoice_business_list_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              )
                                                          )}

                                                          {/*
                                                            discount_type : 1 - promotioncode / giztixbuisness | 2 - Cash voucher
                                                        */}

                                                          <tr className="border-bottom border-top">
                                                            <td>
                                                              (1) รวมค่าขนส่งและค่าบริการ
                                                              หลังหักส่วนลด{' '}
                                                            </td>
                                                            <td>
                                                              {invoiceBusinessList.cost_total_price
                                                                ? invoiceBusinessList.cost_total_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                            <td>
                                                              {invoiceBusinessList.total_price
                                                                ? invoiceBusinessList.total_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              (2) ภาษีมูลค่าเพิ่ม VAT (7% ค่าบริการ)
                                                            </td>
                                                            <td>
                                                              {invoiceBusinessList.cost_total_vat7
                                                                ? invoiceBusinessList.cost_total_vat7.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                            <td>
                                                              {invoiceBusinessList.total_vat7
                                                                ? invoiceBusinessList.total_vat7.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                          </tr>
                                                          {dataWHT.length > 0
                                                            ? dataWHT.map((listWht, key) => (
                                                                <tr>
                                                                  <td>
                                                                    ({key + 3}) ภาษีหัก ณ ที่จ่าย
                                                                    WHT(
                                                                    {listWht.wht}%){' '}
                                                                  </td>
                                                                  <td>
                                                                    {listWht.totalCost
                                                                      ? listWht.totalCost.toLocaleString(
                                                                          'en-US',
                                                                          {
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {listWht.total
                                                                      ? listWht.total.toLocaleString(
                                                                          'en-US',
                                                                          {
                                                                            maximumFractionDigits: 2,
                                                                          }
                                                                        )
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              ))
                                                            : ''}
                                                          <tr className="border-bottom border-top">
                                                            <td>ยอดรวมภาษี</td>
                                                            <td>
                                                              {costGrandTotal.toLocaleString(
                                                                'en-US',
                                                                { maximumFractionDigits: 2 }
                                                              )}{' '}
                                                              บาท
                                                            </td>
                                                            <td>
                                                              {invoiceBusinessList.invoice_business_grand_total_price
                                                                ? invoiceBusinessList.invoice_business_grand_total_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                          </tr>
                                                          <tr className="border-bottom border-top">
                                                            <td>
                                                              <b>ราคาที่ลูกค้าต้องชำระ</b>
                                                            </td>
                                                            <td>-</td>
                                                            <td style={{ color: '#19AA19' }}>
                                                              <b>
                                                                {invoiceBusinessList.invoice_business_grand_total_price
                                                                  ? invoiceBusinessList.invoice_business_grand_total_price.toLocaleString(
                                                                      'en-US'
                                                                    )
                                                                  : 0}{' '}
                                                                บาท
                                                              </b>
                                                            </td>
                                                          </tr>
                                                          {/* paid_type : 1 - บัตรเดบิต/เครดิต | 2 - เงินสด | 3 - ออกใบแจ้งหนี้ */}
                                                          {/* invoiceBusinessList.paid_type == 1 && (
                                                                                                                    <tr className="border-bottom">
                                                                                                                        <td>ค่าธรรมเนียม Omise</td>
                                                                                                                        <td>{OmiseCost.toLocaleString('en-US', { maximumFractionDigits: 2 })} บาท</td>
                                                                                                                        <td>-</td>
                                                                                                                    </tr>
                                                                                                                )*/}
                                                          <tr>
                                                            <td>
                                                              <b>ราคาต้นทุนรวม</b>
                                                            </td>
                                                            <td>
                                                              <b>
                                                                {costGrandTotal.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )}{' '}
                                                                บาท
                                                              </b>
                                                            </td>
                                                            <td>-</td>
                                                          </tr>
                                                        </tbody>
                                                      </TableCost>
                                                    </Col>
                                                  </Row>
                                                </>
                                              }
                                            />
                                          </Col>
                                          <Col md={6}>
                                            {this.state.showEditCostList && (
                                              <Card
                                                ctTableFullWidth
                                                ctTableResponsive
                                                content={
                                                  <Row>
                                                    <Col md={12}>
                                                      <TitleCard>แก้ไขต้นทุนและราคาขาย</TitleCard>
                                                      <div
                                                        style={{
                                                          float: 'right',
                                                          marginLeft: '15px',
                                                        }}
                                                      >
                                                        <Button
                                                          style={{
                                                            borderColor: '#e34942',
                                                            color: '#e34942',
                                                          }}
                                                          onClick={() =>
                                                            this.openEditCostPriceModal(
                                                              'new',
                                                              Category
                                                            )
                                                          }
                                                        >
                                                          {' '}
                                                          <i className="fal fa-plus"></i>{' '}
                                                          เพิ่มรายการ
                                                        </Button>
                                                        <Button
                                                          bsStyle="primary"
                                                          style={{ marginLeft: 15 }}
                                                          onClick={() =>
                                                            this.setState({
                                                              showModalNotiSave: true,
                                                            })
                                                          }
                                                        >
                                                          <i className="fal fa-save"></i> บันทึก
                                                        </Button>
                                                        <Button
                                                          style={{ marginLeft: 15 }}
                                                          onClick={() =>
                                                            this.setState({
                                                              showEditCostList: false,
                                                            })
                                                          }
                                                        >
                                                          {' '}
                                                          ปิด{' '}
                                                        </Button>
                                                      </div>
                                                    </Col>
                                                    <Col md={12}>
                                                      <TableCost responsive>
                                                        <thead>
                                                          <tr className="border-top">
                                                            <td>
                                                              <b>รายการ</b>
                                                            </td>
                                                            <td>
                                                              <b>ราคาต้นทุน</b>
                                                            </td>
                                                            <td>
                                                              <b>ราคาขาย</b>
                                                            </td>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr className="border-top">
                                                            <td colSpan={3}>
                                                              <b>ค่าขนส่ง</b>
                                                            </td>
                                                          </tr>
                                                          {this.state.editInvoiceBusinessList.invoice_business_list.map(
                                                            (list, key) =>
                                                              list.datamaster_service_category_prefix_id !=
                                                                '5e54cef8e99db3b888a2b749' &&
                                                              list.datamaster_service_category_prefix_id !=
                                                                '5e54cef8e99db3b888a2b755' && (
                                                                <tr key={key}>
                                                                  <td>
                                                                    <Button
                                                                      bsStyle="link"
                                                                      style={{ float: 'left' }}
                                                                      disabled={
                                                                        key == 0 ||
                                                                        list.invoice_business_list_detail.search(
                                                                          'ส่วนลด'
                                                                        ) != -1 ||
                                                                        this.state.requestStatus !=
                                                                          3
                                                                      }
                                                                      onClick={() =>
                                                                        this.delInvoiceEdit(
                                                                          list,
                                                                          key
                                                                        )
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      <i className="fal fa-trash-alt"></i>
                                                                    </Button>
                                                                    <Button
                                                                      disabled={
                                                                        list.invoice_business_list_detail.search(
                                                                          'ส่วนลด'
                                                                        ) != -1
                                                                      }
                                                                      bsStyle="link"
                                                                      style={{
                                                                        color: '#288BED',
                                                                        float: 'left',
                                                                        paddingLeft: 0,
                                                                      }}
                                                                      onClick={() =>
                                                                        this.openEditCostPriceModal(
                                                                          'edit',
                                                                          Category,
                                                                          list
                                                                        )
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      <i className="fal fa-edit"></i>
                                                                    </Button>
                                                                    <Detail>
                                                                      {
                                                                        list.invoice_business_list_detail
                                                                      }{' '}
                                                                    </Detail>
                                                                    <Text>
                                                                      [x
                                                                      {
                                                                        list.invoice_business_list_qty
                                                                      }
                                                                      ]
                                                                    </Text>
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_cost_total_price
                                                                      ? (
                                                                          list.invoice_business_list_cost_total_price -
                                                                            list.invoice_business_list_cost_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_total_price
                                                                      ? (
                                                                          list.invoice_business_list_total_price -
                                                                          list.invoice_business_list_discount +
                                                                          this.sumInvoiceDiscount(
                                                                            invoiceBusinessList.invoice_discount
                                                                          )
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              )
                                                          )}

                                                          {this.state.editInvoiceBusinessList.invoice_discount.map(
                                                            (discount, key) => (
                                                              <tr key={key}>
                                                                <td>
                                                                  <Button
                                                                    disabled
                                                                    bsStyle="link"
                                                                    style={{ float: 'left' }}
                                                                    onClick={() =>
                                                                      this.delInvoiceDiscountEdit(
                                                                        discount,
                                                                        key
                                                                      )
                                                                    }
                                                                  >
                                                                    {' '}
                                                                    <i className="fal fa-trash-alt"></i>
                                                                  </Button>
                                                                  <Button
                                                                    disabled
                                                                    bsStyle="link"
                                                                    style={{
                                                                      color: '#288BED',
                                                                      float: 'left',
                                                                      paddingLeft: 0,
                                                                    }}
                                                                    onClick={() =>
                                                                      this.openEditCostPriceModal(
                                                                        'edit',
                                                                        discount
                                                                      )
                                                                    }
                                                                  >
                                                                    {' '}
                                                                    <i className="fal fa-edit"></i>
                                                                  </Button>
                                                                  <Text>
                                                                    {discount.detail} [
                                                                    {discount.percent}%]
                                                                  </Text>
                                                                </td>
                                                                <td></td>
                                                                <td>
                                                                  -{' '}
                                                                  {discount.price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )}{' '}
                                                                  บาท
                                                                </td>
                                                              </tr>
                                                            )
                                                          )}

                                                          {this.state.editInvoiceBusinessList.invoice_business_list.filter(
                                                            item =>
                                                              item.datamaster_service_category_prefix_id ==
                                                              '5e54cef8e99db3b888a2b749'
                                                          ).length > 0 && (
                                                            <tr className="border-top">
                                                              <td colSpan={3}>
                                                                <b>ค่าบริการเสริม</b>
                                                              </td>
                                                            </tr>
                                                          )}

                                                          {this.state.editInvoiceBusinessList.invoice_business_list.map(
                                                            (list, key) =>
                                                              list.datamaster_service_category_prefix_id ==
                                                                '5e54cef8e99db3b888a2b749' && (
                                                                <tr key={key}>
                                                                  <td>
                                                                    <Button
                                                                      bsStyle="link"
                                                                      style={{ float: 'left' }}
                                                                      onClick={() =>
                                                                        this.delInvoiceEdit(
                                                                          list,
                                                                          key
                                                                        )
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      <i className="fal fa-trash-alt"></i>
                                                                    </Button>
                                                                    <Button
                                                                      bsStyle="link"
                                                                      style={{
                                                                        color: '#288BED',
                                                                        float: 'left',
                                                                        paddingLeft: 0,
                                                                      }}
                                                                      onClick={() =>
                                                                        this.openEditCostPriceModal(
                                                                          'edit',
                                                                          Category,
                                                                          list
                                                                        )
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      <i className="fal fa-edit"></i>
                                                                    </Button>
                                                                    <Detail>
                                                                      {
                                                                        list.invoice_business_list_detail
                                                                      }{' '}
                                                                    </Detail>{' '}
                                                                    <Text>
                                                                      [x
                                                                      {
                                                                        list.invoice_business_list_qty
                                                                      }
                                                                      ]
                                                                    </Text>
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_cost_total_price
                                                                      ? (
                                                                          list.invoice_business_list_cost_total_price -
                                                                            list.invoice_business_list_cost_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_total_price
                                                                      ? (
                                                                          list.invoice_business_list_total_price -
                                                                            list.invoice_business_list_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              )
                                                          )}

                                                          {this.state.editInvoiceBusinessList.invoice_business_list.filter(
                                                            item =>
                                                              item.datamaster_service_category_prefix_id ==
                                                              '5e54cef8e99db3b888a2b755'
                                                          ).length > 0 && (
                                                            <tr className="border-top">
                                                              <td colSpan={3}>
                                                                <b>ค่าสำรองจ่าย</b>
                                                              </td>
                                                            </tr>
                                                          )}

                                                          {this.state.editInvoiceBusinessList.invoice_business_list.map(
                                                            (list, key) =>
                                                              list.datamaster_service_category_prefix_id ==
                                                                '5e54cef8e99db3b888a2b755' && (
                                                                <tr key={key}>
                                                                  <td>
                                                                    <Button
                                                                      bsStyle="link"
                                                                      style={{ float: 'left' }}
                                                                      disabled={key == 0}
                                                                      onClick={() =>
                                                                        this.delInvoiceEdit(
                                                                          list,
                                                                          key
                                                                        )
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      <i className="fal fa-trash-alt"></i>
                                                                    </Button>
                                                                    <Button
                                                                      bsStyle="link"
                                                                      style={{
                                                                        color: '#288BED',
                                                                        float: 'left',
                                                                        paddingLeft: 0,
                                                                      }}
                                                                      onClick={() =>
                                                                        this.openEditCostPriceModal(
                                                                          'edit',
                                                                          Category,
                                                                          list
                                                                        )
                                                                      }
                                                                    >
                                                                      {' '}
                                                                      <i className="fal fa-edit"></i>
                                                                    </Button>
                                                                    <Detail>
                                                                      {
                                                                        list.invoice_business_list_detail
                                                                      }{' '}
                                                                    </Detail>
                                                                    <Text>
                                                                      [x
                                                                      {
                                                                        list.invoice_business_list_qty
                                                                      }
                                                                      ]
                                                                    </Text>
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_cost_total_price
                                                                      ? (
                                                                          list.invoice_business_list_cost_total_price -
                                                                            list.invoice_business_list_cost_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                  <td>
                                                                    {list.invoice_business_list_total_price
                                                                      ? (
                                                                          list.invoice_business_list_total_price -
                                                                            list.invoice_business_list_discount ||
                                                                          0
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })
                                                                      : 0}{' '}
                                                                    บาท
                                                                  </td>
                                                                </tr>
                                                              )
                                                          )}

                                                          <tr className="border-bottom border-top">
                                                            <td>
                                                              (1) รวมค่าขนส่งและค่าบริการ
                                                              หลังหักส่วนลด{' '}
                                                            </td>
                                                            <td>
                                                              {this.state.cal_cost_price
                                                                ? this.state.cal_cost_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                            <td>
                                                              {this.state.cal_price
                                                                ? this.state.cal_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              (2) ภาษีมูลค่าเพิ่ม VAT (7% ค่าบริการ)
                                                            </td>
                                                            <td>
                                                              {this.state.cal_vat_cost
                                                                ? this.state.cal_vat_cost.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                            <td>
                                                              {this.state.cal_vat
                                                                ? this.state.cal_vat.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                          </tr>
                                                          {this.state.cal_wht.length > 0
                                                            ? this.state.cal_wht.map(
                                                                (listWht, key) => (
                                                                  <tr>
                                                                    <td>
                                                                      ({key + 3}) ภาษีหัก ณ ที่จ่าย
                                                                      WHT({listWht.wht}%){' '}
                                                                    </td>
                                                                    {console.log(
                                                                      'listWht',
                                                                      listWht
                                                                    )}
                                                                    <td>
                                                                      {listWht.totalCost
                                                                        ? listWht.totalCost.toLocaleString(
                                                                            'en-US',
                                                                            {
                                                                              maximumFractionDigits: 2,
                                                                            }
                                                                          )
                                                                        : 0}{' '}
                                                                      บาท
                                                                    </td>
                                                                    <td>
                                                                      {listWht.total
                                                                        ? listWht.total.toLocaleString(
                                                                            'en-US',
                                                                            {
                                                                              maximumFractionDigits: 2,
                                                                            }
                                                                          )
                                                                        : 0}{' '}
                                                                      บาท
                                                                    </td>
                                                                  </tr>
                                                                )
                                                              )
                                                            : ''}
                                                          <tr className="border-bottom border-top">
                                                            <td>ยอดรวมภาษี</td>
                                                            <td>
                                                              {this.state.cal_total_cost.toLocaleString(
                                                                'en-US',
                                                                { maximumFractionDigits: 2 }
                                                              )}{' '}
                                                              บาท
                                                            </td>
                                                            <td>
                                                              {this.state.cal_total
                                                                ? this.state.cal_total.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : 0}{' '}
                                                              บาท
                                                            </td>
                                                          </tr>
                                                          <tr className="border-bottom border-top">
                                                            <td>
                                                              <b>ราคาที่ลูกค้าต้องชำระ</b>
                                                            </td>
                                                            <td>-</td>
                                                            <td style={{ color: '#19AA19' }}>
                                                              <b>
                                                                {this.state.cal_total
                                                                  ? this.state.cal_total.toLocaleString(
                                                                      'en-US'
                                                                    )
                                                                  : 0}{' '}
                                                                บาท
                                                              </b>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              <b>ราคาต้นทุนรวม</b>
                                                            </td>
                                                            <td>
                                                              <b>
                                                                {this.state.cal_total_cost.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )}{' '}
                                                                บาท
                                                              </b>
                                                            </td>
                                                            <td>-</td>
                                                          </tr>
                                                        </tbody>
                                                      </TableCost>
                                                    </Col>
                                                  </Row>
                                                }
                                              />
                                            )}
                                          </Col>
                                        </Row>

                                        <ModalStyle
                                          show={this.state.showModalDetailCost}
                                          onHide={() =>
                                            this.setState({ showModalDetailCost: false })
                                          }
                                        >
                                          <Modal.Body>
                                            <Row>
                                              <Col md={12}>
                                                <TableDetail>
                                                  <thead>
                                                    <tr>
                                                      <td colSpan={5}>
                                                        <b>
                                                          Shipment ID :{' '}
                                                          {shipment[0].shipment_number}
                                                        </b>
                                                      </td>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td style={{ width: 120 }}>Request ID:</td>
                                                      <td>
                                                        <b>
                                                          {shipment[0].shipment_tracking_number}
                                                        </b>
                                                      </td>
                                                      <td></td>
                                                      <td style={{ width: 120 }}>PCV ID:</td>
                                                      <td>
                                                        <b>{shipment[0].shipper_detail}</b>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>วันที่สั่งจ่าย:</td>
                                                      <td>
                                                        <b>
                                                          <Moment format="DD/MM/YYYY HH:mm">
                                                            {shipment[0].create_date}
                                                          </Moment>
                                                        </b>
                                                      </td>
                                                      <td></td>
                                                      <td>วันที่ทำจ่าย:</td>
                                                      <td>
                                                        <b>
                                                          <Moment format="DD/MM/YYYY HH:mm">
                                                            {shipment[0].create_date}
                                                          </Moment>
                                                        </b>
                                                      </td>
                                                    </tr>
                                                    <tr className="border-top">
                                                      <td>Shipper Type:</td>
                                                      <td>
                                                        <b>{shipment[0].transporter_name}</b>
                                                      </td>
                                                      <td></td>
                                                      <td>Agent:</td>
                                                      <td>
                                                        <b>{shipment[0].transporter_name}</b>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Shipper Name:</td>
                                                      <td>
                                                        <b>{shipment[0].shipper_detail}</b>
                                                      </td>
                                                      <td></td>
                                                      <td>Driver:</td>
                                                      <td>
                                                        <b>{shipment[0].shipper_detail}</b>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Pay By:</td>
                                                      <td>
                                                        <b>
                                                          {this.renderPayType(
                                                            shipment[0].paid_type
                                                          )}
                                                        </b>
                                                      </td>
                                                      <td></td>
                                                      <td>Truck Type:</td>
                                                      <td>
                                                        <b>
                                                          {shipment[0].setting_truck_name.th}
                                                          {shipment[0].truck_type_name.th}
                                                        </b>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>วันที่สั่งจ่าย:</td>
                                                      <td colSpan={4}>
                                                        <b>
                                                          <Moment format="DD/MM/YYYY HH:mm">
                                                            {shipment[0].create_date}
                                                          </Moment>
                                                        </b>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>ผู้สั่งจ่าย:</td>
                                                      <td colSpan={4}>
                                                        <b>
                                                          <Moment format="DD/MM/YYYY HH:mm">
                                                            {shipment[0].create_date}
                                                          </Moment>
                                                        </b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </TableDetail>
                                              </Col>
                                              <Col md={12}>
                                                <TableCostDetail striped>
                                                  <thead>
                                                    <tr>
                                                      <td>
                                                        <b>(1) รายการต้นทุน</b>
                                                      </td>
                                                      <td>
                                                        <b>ราคา (A)</b>
                                                      </td>
                                                      <td>
                                                        <b>ส่วนลด (B)</b>
                                                      </td>
                                                      <td>
                                                        <b>ยอดก่อนภาษี (C)</b>
                                                      </td>
                                                      <td>
                                                        <b>ภาษีมูลค่าเพิ่ม (D)</b>
                                                      </td>
                                                      <td>
                                                        <b>หัก ณ ที่จ่าย (E)</b>
                                                      </td>
                                                      <td>
                                                        <b>รวมราคา (F)</b>
                                                      </td>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุน - ค่ารถ</b>
                                                        <p>ค่าขนส่ง</p>
                                                        <p>ค่ายกเลิกงานขนส่ง</p>
                                                        <p>รถขนาดใหญ่พิเศษ</p>
                                                        <p>บริการไป-กลับ</p>
                                                        <p>ค่าบริการเพิ่มจุดส่งสินค้า</p>
                                                        <p>ค่าบริการเปลี่ยนเส้นทาง</p>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุน - ค่าแรง</b>
                                                        <p>คนขับช่วยยก</p>
                                                        <p>ผู้ช่วยเพิ่มเติม</p>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุน - ค่าขนส่งล่วงเวลา</b>
                                                        <p>ค่าบริการขนส่งล่วงเวลา</p>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุน - ค่าค้างคืน</b>
                                                        <p>ค่าบริการรอขึ้น-ลงสินค้าข้ามคืน</p>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุน - POD</b>
                                                        <p>ค่าบริการคืนใบส่งสินค้า (POD)</p>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุน - ค่าธรรมเนียมธนาคาร</b>
                                                        <p>ค่าบริการ Payment Gateway</p>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <b>- ต้นทุนอื่นๆ </b>
                                                      </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr className="border-top">
                                                      <td style={{ textAlign: 'right' }}>
                                                        <b>(1) รวมราคาต้นทุน</b>
                                                      </td>
                                                      <td>
                                                        <b>1,000.00</b>
                                                      </td>
                                                      <td>
                                                        <b>-</b>
                                                      </td>
                                                      <td>
                                                        <b>1,000.00</b>
                                                      </td>
                                                      <td>
                                                        <b>-</b>
                                                      </td>
                                                      <td>
                                                        <b>10.00</b>
                                                      </td>
                                                      <td>
                                                        <b>990.00</b>
                                                      </td>
                                                    </tr>
                                                  </tbody>

                                                  {/* (2) รายการค่าสำรองจ่าย */}
                                                  <thead>
                                                    <tr>
                                                      <td>
                                                        <b>(2) รายการค่าสำรองจ่าย</b>
                                                      </td>
                                                      <td>
                                                        <b>ราคา (A)</b>
                                                      </td>
                                                      <td>
                                                        <b>ส่วนลด (B)</b>
                                                      </td>
                                                      <td>
                                                        <b>ยอดก่อนภาษี (C)</b>
                                                      </td>
                                                      <td>
                                                        <b>ภาษีมูลค่าเพิ่ม (D)</b>
                                                      </td>
                                                      <td>
                                                        <b>หัก ณ ที่จ่าย (E)</b>
                                                      </td>
                                                      <td>
                                                        <b>รวมราคา (F)</b>
                                                      </td>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>- ค่าสำรองจ่าย - ค่าผ่านท่า</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ค่าสำรองจ่าย - Container Storage</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ค่าสำรองจ่าย - งานเอกสาร</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ค่าสำรองจ่าย - อื่นๆ </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr className="border-top">
                                                      <td style={{ textAlign: 'right' }}>
                                                        <b>(2) รวมค่าสำรองจ่าย</b>
                                                      </td>
                                                      <td>
                                                        <b>1,000.00</b>
                                                      </td>
                                                      <td>
                                                        <b>-</b>
                                                      </td>
                                                      <td>
                                                        <b>1,000.00</b>
                                                      </td>
                                                      <td>
                                                        <b>-</b>
                                                      </td>
                                                      <td>
                                                        <b>10.00</b>
                                                      </td>
                                                      <td>
                                                        <b>990.00</b>
                                                      </td>
                                                    </tr>
                                                  </tbody>

                                                  {/* (3) รายการราคาขาย */}
                                                  <thead>
                                                    <tr>
                                                      <td>
                                                        <b>(3) รายการราคาขาย</b>
                                                      </td>
                                                      <td>
                                                        <b>ราคา (A)</b>
                                                      </td>
                                                      <td>
                                                        <b>ส่วนลด (B)</b>
                                                      </td>
                                                      <td>
                                                        <b>ยอดก่อนภาษี (C)</b>
                                                      </td>
                                                      <td>
                                                        <b>ภาษีมูลค่าเพิ่ม (D)</b>
                                                      </td>
                                                      <td>
                                                        <b>หัก ณ ที่จ่าย (E)</b>
                                                      </td>
                                                      <td>
                                                        <b>รวมราคา (F)</b>
                                                      </td>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr>
                                                      <td>- ราคาขาย - ค่ารถ</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ราคาขาย - ค่าแรง</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ราคาขาย - ค่าค้างคืน</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ราคาขาย - ค่าขนส่งล่วงเวลา</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ราคาขาย - POD</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr>
                                                      <td>- ราคาขายอื่นๆ </td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>1,000.00</td>
                                                      <td>-</td>
                                                      <td>10.00</td>
                                                      <td>990.00</td>
                                                    </tr>
                                                    <tr className="border-top">
                                                      <td style={{ textAlign: 'right' }}>
                                                        <b>(3) รวมราคาขาย</b>
                                                      </td>
                                                      <td>
                                                        <b>1,000.00</b>
                                                      </td>
                                                      <td>
                                                        <b>-</b>
                                                      </td>
                                                      <td>
                                                        <b>1,000.00</b>
                                                      </td>
                                                      <td>
                                                        <b>-</b>
                                                      </td>
                                                      <td>
                                                        <b>10.00</b>
                                                      </td>
                                                      <td>
                                                        <b>990.00</b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </TableCostDetail>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col md={6}></Col>
                                              <Col md={6}>
                                                <Table bordered>
                                                  <tbody>
                                                    <tr>
                                                      <td style={{ textAlign: 'right' }}>
                                                        สรุปราคาขายทั้งหมดก่อนหักส่วนลด (GMV
                                                        Revenue): (3A)
                                                      </td>
                                                      <td>
                                                        {' '}
                                                        <b>1,900.00</b>{' '}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td style={{ textAlign: 'right' }}>
                                                        สรุปราคาขายทั้งหมดหลังหักส่วนลด (Actual
                                                        Sell): (3C)
                                                      </td>
                                                      <td>
                                                        {' '}
                                                        <b>1,900.00</b>{' '}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td style={{ textAlign: 'right' }}>
                                                        กำไร-ขาดทุน หลังหักส่วนลด (Actual GP):
                                                        (3C)-(1C+2C)
                                                      </td>
                                                      <td>
                                                        <b>1,900.00</b>{' '}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                                <BoxDashboardCost
                                                  color="#F8FFF8"
                                                  borderColor="#19AA19"
                                                >
                                                  <TableCost style={{ margin: 0 }}>
                                                    <tbody>
                                                      <tr>
                                                        <td style={{ textAlign: 'right' }}>
                                                          รวมค่าจ้างที่ต้องทำจ่าย Vendor (1F)
                                                        </td>
                                                        <td>
                                                          {' '}
                                                          <b>1,900.00</b>{' '}
                                                        </td>
                                                      </tr>
                                                      <tr className="border-top">
                                                        <td style={{ textAlign: 'right' }}>
                                                          Vendor ได้รับเงินแล้ว
                                                        </td>
                                                        <td>
                                                          {' '}
                                                          <b>1,900.00</b>{' '}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ textAlign: 'right' }}>
                                                          Vendor ต้องโอนคืน
                                                        </td>
                                                        <td>
                                                          <b style={{ color: '#19AA19' }}>
                                                            1,900.00
                                                          </b>{' '}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </TableCost>
                                                </BoxDashboardCost>
                                              </Col>
                                            </Row>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <ButtonBlue
                                              style={{}}
                                              onClick={() =>
                                                this.setState({ showModalDetailCost: false })
                                              }
                                            >
                                              ส่งตั้งเบิก >
                                            </ButtonBlue>
                                            <Button
                                              onClick={() =>
                                                this.setState({ showModalDetailCost: false })
                                              }
                                            >
                                              ปิด
                                            </Button>
                                          </Modal.Footer>
                                        </ModalStyle>

                                        <Mutation
                                          mutation={invoiceUpdateMutation}
                                          variables={{
                                            shipment_id: shipment[0]._id,
                                            invoice_business_list: this.renderVariables(),
                                          }}
                                          update={(cache, { data }) => {
                                            this.setState({
                                              showModalNotiSave: false,
                                              showEditCostList: false,
                                            });
                                          }}
                                          refetchQueries={() => ['seachInvoiceList', 'search']}
                                        >
                                          {invoiceUpdate => {
                                            return (
                                              <ModalConfirm
                                                show={this.state.showModalNotiSave}
                                                onPressButtonCancel={() =>
                                                  this.setState({ showModalNotiSave: false })
                                                }
                                                onPressButtonConfirm={() => invoiceUpdate()}
                                                labelButtonConfirm={`ยืนยัน`}
                                                labelButtonCancel={`กลับ`}
                                                styleColorButtonConfirm="primary"
                                                titleHeader={`คุณต้องการบันทึกรายการแก้ไข ใช่หรือไม่?`}
                                                subTitle={`กด ยืนยัน เพื่อบันทึก`}
                                                onHide={() =>
                                                  this.setState({ showModalNotiSave: false })
                                                }
                                              />
                                            );
                                          }}
                                        </Mutation>

                                        <ModalEditStyle show={this.state.showModalEdit}>
                                          <Modal.Header>
                                            <Modal.Title>
                                              <b>แก้ไขรายการ</b>
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <WrapperForm>
                                              <div className="column">
                                                <SelectBox
                                                  onChange={event =>
                                                    this.changeSelectMainCategory(event)
                                                  }
                                                  value={this.state.mainCategory}
                                                  style={{ marginTop: 5 }}
                                                  options={this.renderMainCategory(
                                                    Category.datamasterServiceCategoryList
                                                  )}
                                                  inputLabel="หมวดหมู่รายการ:"
                                                  required
                                                  messageError={
                                                    this.state.mainCategoryInvalid &&
                                                    'กรุณากรอกข้อมูล'
                                                  }
                                                />
                                                <SelectBox
                                                  onChange={event =>
                                                    this.changeSelectSubCategory(event)
                                                  }
                                                  value={this.state.subCategory}
                                                  style={{ marginTop: 5 }}
                                                  options={this.renderSubCategory()}
                                                  inputLabel="หมวดหมู่ย่อย:"
                                                  required
                                                  messageError={
                                                    this.state.subCategoryInvalid &&
                                                    'กรุณากรอกข้อมูล'
                                                  }
                                                />
                                                <Row>
                                                  <Col md={6}>
                                                    <Input
                                                      onChange={event =>
                                                        this.setState(
                                                          { qty: event.target.value },
                                                          () => this.checkValidate('qty')
                                                        )
                                                      }
                                                      value={this.state.qty}
                                                      style={{ marginTop: 5 }}
                                                      inputLabel="จำนวน:"
                                                      placeholder="จำนวน"
                                                      required
                                                      messageError={
                                                        this.state.qtyInvalid &&
                                                        'กรุณากรอกตัวเลขเท่านั้น'
                                                      }
                                                      disabled={this.state.subCategory.value == ''}
                                                    />
                                                  </Col>
                                                  <Col md={6}>
                                                    <Input
                                                      onChange={event =>
                                                        this.setState(
                                                          { unit: event.target.value },
                                                          () => this.checkValidate('unit')
                                                        )
                                                      }
                                                      value={this.state.unit}
                                                      style={{ marginTop: 5 }}
                                                      inputLabel="หน่วย"
                                                      placeholder="หน่วย"
                                                      disabled
                                                      required
                                                      messageError={
                                                        this.state.unitInvalid && 'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                                {/* {
                                                  this.state.subCategory && this.state.subCategory.service_item_name == "ค่าประกัน" && (
                                                  <Row>
                                                    <Col md={12}>นายหน้า</Col>
                                                    <AutosuggestContainer>
                                                        <Autosuggest
                                                            suggestions={this.state.agentSuggestions}
                                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedAgent}
                                                            onSuggestionsClearRequested={this.onSuggestionsClearRequestedAgent}
                                                            getSuggestionValue={this.getSuggestionValueAgent}
                                                            renderSuggestion={this.renderSuggestionAgent}
                                                            focusInputOnSuggestionClick={false}
                                                            inputProps={{
                                                                placeholder: 'ค้นหา',
                                                                value: this.state.agentSelected,
                                                                onChange: (event) => this.setState({ agentSelected: event.target.value, agentId: null }),
                                                                type: "text",
                                                                className: "form-control",
                                                            }}
                                                            onSuggestionSelected={(event, { suggestion }) => {
                                                                this.setState({ agentId: suggestion.id, agentSelected: suggestion.name, page_number: 1 })
                                                            }}
                                                            shouldRenderSuggestions={this.shouldRenderSuggestionsAgent}
                                                        />
                                                    </AutosuggestContainer>
                                                  </Row>
                                                  )
                                                } */}
                                              </div>
                                              <div className="column">
                                                <Input
                                                  onChange={event =>
                                                    this.setState(
                                                      { cost_price: event.target.value },
                                                      () => this.checkValidate('cost_price')
                                                    )
                                                  }
                                                  value={this.state.cost_price}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ราคาต้นทุน:"
                                                  placeholder="ราคาต้นทุน"
                                                  required
                                                  messageError={
                                                    this.state.cost_priceInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 2
                                                  }
                                                />

                                                <Input
                                                  onChange={event =>
                                                    this.setState(
                                                      { discount_cost: event.target.value },
                                                      () => this.checkValidate('discount_cost')
                                                    )
                                                  }
                                                  value={this.state.discount_cost}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ส่วนลดเพิ่มเติม:"
                                                  placeholder="ส่วนลดเพิ่มเติม"
                                                  messageError={
                                                    this.state.discount_costInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 2
                                                  }
                                                />

                                                <Input
                                                  onChange={event =>
                                                    this.setState(
                                                      { vat_cost: event.target.value },
                                                      () => this.checkValidate('vat_cost')
                                                    )
                                                  }
                                                  value={this.state.vat_cost}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ภาษีมูลค่าเพิ่ม:"
                                                  placeholder="ภาษีมูลค่าเพิ่ม"
                                                  messageError={
                                                    this.state.vat_costInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 2
                                                  }
                                                />

                                                <Input
                                                  onChange={event =>
                                                    this.setState(
                                                      { wht_cost: event.target.value },
                                                      () => this.checkValidate('wht_cost')
                                                    )
                                                  }
                                                  value={this.state.wht_cost}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ภาษีหัก ณ ที่จ่าย:"
                                                  placeholder="ภาษีหัก ณ ที่จ่าย"
                                                  messageError={
                                                    this.state.wht_costInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 2
                                                  }
                                                />
                                              </div>
                                              <div className="column">
                                                <Input
                                                  onChange={event =>
                                                    this.setState(
                                                      { price: event.target.value },
                                                      () => this.checkValidate('price')
                                                    )
                                                  }
                                                  value={this.state.price}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ราคาขาย:"
                                                  placeholder="ราคาขาย"
                                                  required
                                                  messageError={
                                                    this.state.priceInvalid &&
                                                    `ห้ามน้อยกว่า ${this.state.min_price}`
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 1
                                                  }
                                                />

                                                <Input
                                                  onChange={event =>
                                                    this.setState(
                                                      { discount: event.target.value },
                                                      () => this.checkValidate('discount')
                                                    )
                                                  }
                                                  value={this.state.discount}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ส่วนลดเพิ่มเติม:"
                                                  placeholder="ส่วนลดเพิ่มเติม"
                                                  messageError={
                                                    this.state.discountInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 1
                                                  }
                                                />

                                                <Input
                                                  onChange={event =>
                                                    this.setState({ vat: event.target.value }, () =>
                                                      this.checkValidate('vat')
                                                    )
                                                  }
                                                  value={this.state.vat}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ภาษีมูลค่าเพิ่ม:"
                                                  placeholder="ภาษีมูลค่าเพิ่ม"
                                                  messageError={
                                                    this.state.vatInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 1
                                                  }
                                                />

                                                <Input
                                                  onChange={event =>
                                                    this.setState({ wht: event.target.value }, () =>
                                                      this.checkValidate('wht')
                                                    )
                                                  }
                                                  value={this.state.wht}
                                                  style={{ marginTop: 5 }}
                                                  inputLabel="ภาษีหัก ณ ที่จ่าย:"
                                                  placeholder="ภาษีหัก ณ ที่จ่าย"
                                                  messageError={
                                                    this.state.whtInvalid &&
                                                    'กรุณากรอกตัวเลขเท่านั้น'
                                                  }
                                                  disabled={
                                                    this.state.subCategory.value == '' ||
                                                    this.state.requestStatus == 1
                                                  }
                                                />
                                              </div>
                                            </WrapperForm>
                                            <Line />
                                            <Row>
                                              <Col md={12}>
                                                <TableEditCostDetail>
                                                  <tbody>
                                                    <tr>
                                                      <td></td>
                                                      <td>
                                                        <b>ราคาต้นทุน</b>
                                                      </td>
                                                      <td>
                                                        <b>ราคาขาย</b>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>ยอดก่อนภาษี</td>
                                                      <td>
                                                        {this.state.modal_cal_total_cost.toLocaleString(
                                                          'en-US',
                                                          { maximumFractionDigits: 2 }
                                                        )}{' '}
                                                        บาท
                                                      </td>
                                                      <td>
                                                        {this.state.modal_cal_total.toLocaleString(
                                                          'en-US',
                                                          { maximumFractionDigits: 2 }
                                                        )}{' '}
                                                        บาท
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>ภาษีมูลค่าเพิ่ม VAT(7%)</td>
                                                      <td>
                                                        {this.state.modal_cal_vat_cost.toLocaleString(
                                                          'en-US',
                                                          { maximumFractionDigits: 2 }
                                                        )}{' '}
                                                        บาท
                                                      </td>
                                                      <td>
                                                        {this.state.modal_cal_vat.toLocaleString(
                                                          'en-US',
                                                          { maximumFractionDigits: 2 }
                                                        )}{' '}
                                                        บาท
                                                      </td>
                                                    </tr>

                                                    {this.state.modal_cal_wht_cost.length > 0
                                                      ? this.state.modal_cal_wht_cost.map(
                                                          (listWht, key) => (
                                                            <tr>
                                                              <td>ภาษีหัก ณ ที่จ่าย WHT </td>
                                                              <td>
                                                                {listWht.total
                                                                  ? listWht.total.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )
                                                                  : 0}{' '}
                                                                บาท
                                                              </td>
                                                              <td>
                                                                {this.state.modal_cal_wht[key].total
                                                                  ? this.state.modal_cal_wht[
                                                                      key
                                                                    ].total.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )
                                                                  : 0}{' '}
                                                                บาท
                                                              </td>
                                                            </tr>
                                                          )
                                                        )
                                                      : ''}
                                                    <tr>
                                                      <td>
                                                        <b>รวมทั้งหมด</b>
                                                      </td>
                                                      <td>
                                                        <b>
                                                          {this.state.modal_cal_total_grand_cost.toLocaleString(
                                                            'en-US',
                                                            { maximumFractionDigits: 2 }
                                                          )}{' '}
                                                          บาท
                                                        </b>
                                                      </td>
                                                      <td>
                                                        <b>
                                                          {this.state.modal_cal_grand_total.toLocaleString(
                                                            'en-US',
                                                            { maximumFractionDigits: 2 }
                                                          )}{' '}
                                                          บาท
                                                        </b>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </TableEditCostDetail>
                                              </Col>
                                            </Row>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button
                                              onClick={() =>
                                                this.setState({ showModalEdit: false })
                                              }
                                            >
                                              ปิด
                                            </Button>
                                            <Button
                                              bsStyle="primary"
                                              onClick={() => {
                                                if (this.checkValidateSabmit()) {
                                                  this.submitInvoiceItem();
                                                } else {
                                                  alert('กรอกข้อมูลให้ครบถ้วน');
                                                }
                                              }}
                                            >
                                              บันทึกรายการต้นทุนและราคาขาย
                                            </Button>
                                          </Modal.Footer>
                                        </ModalEditStyle>

                                        <DetailCostingOrderList // รายละเอียดใบสั่งจ่าย
                                          show={this.state.isOpenDetailCostingOrderList}
                                          costingId={this.state.costingId}
                                          costing={this.state.costing}
                                          shipmentId={this.state.shipmentId}
                                          invoiceList={this.state.invoiceBusinessList}
                                          admin={{
                                            id: admin_id,
                                            name: adminUsername,
                                          }}
                                          onHide={() => {
                                            this.setState({ isOpenDetailCostingOrderList: false });
                                          }}
                                        />

                                        <UpdateCosingOrderList // อัพเดตรายการ CT
                                          show={this.state.isOpenUpdateCosingOrderList}
                                          costingId={this.state.costingId}
                                          costing={this.state.costing}
                                          shipmentId={this.state.shipmentId}
                                          invoiceList={this.state.invoiceBusinessList}
                                          admin={{
                                            id: admin_id,
                                            name: adminUsername,
                                          }}
                                          onHide={() => {
                                            this.setState({ isOpenUpdateCosingOrderList: false });
                                          }}
                                        />
                                      </div>
                                    );
                                  }}
                                </Query>
                              }
                            />
                          </Col>
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>

              <CreateCostingOrderList // สร้างรายการ CT
                show={this.state.isOpenCreateCostingOrderList}
                shipmentId={this.state.shipmentId}
                invoiceList={this.state.invoiceBusinessList}
                admin={{
                  id: admin_id,
                  name: adminUsername,
                }}
                onHide={() => {
                  this.setState({ isOpenCreateCostingOrderList: false });
                }}
              />

              <EditPaymentOrderList // ขอแก้ไข CT
                show={this.state.isOpenRequestEditCostList}
                shipmentId={this.state.shipmentId}
                costingData={this.state.costing}
                requestStatus={this.state.requestStatus}
                onHide={() => {
                  this.setState({ isOpenRequestEditCostList: false });
                }}
              />
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(dateNowQuery, { name: 'dateNow' }),
  graphql(adminName, { name: 'adminName' }),
  graphql(settingTruckListQuery, { name: 'settingTruckList' })
)(ShipmentDetail);
