import gql from 'graphql-tag';

export default gql`
  query AccountInfoList($_id: ID, $account_info_type: Int) {
    accountInfoList(_id: $_id, account_info_type: $account_info_type) {
      account_business {
        _id
        invite_code
        recommend_company
        account_info_business_name
        business_req_number
        account_info_id
        business_status
        business_usage_information_1
        business_usage_information_2
        business_usage_information_3
        business_credit_req
        business_credit_approved
        business_credit_used
        business_edocument_receipt
        business_alway_wht
        business_admin_delivery_vat
        business_admin_delivery_wht
        business_admin_service_vat
        business_admin_service_wht
        business_admin_document_1
        business_admin_document_2
        business_admin_document_3
        business_register_from
        business_free_credit
        business_free_credit_used
        business_invite_benefit_discount_start {
          discount
          start
          end
        }
        business_invite_benefit_discount {
          discount
          start
          end
        }
        business_admin_benefit_discount {
          setting_truck_type_id
          discount
          discountend
        }
        business_register_from
        business_credit_approved
        business_credit_used
        business_invoice_type
        create_date
        approved_date
        last_update
        cancel_date
        business_invoice_date_range {
          start
          end
        }
        business_pay_round {
          round_date
          after_invoice
        }
        payment_bank_account {
          bank_name
          bank_branch
          account_name
          account_number
        }
        account_pricing_conditions_weight
        account_pricing_conditions_dimension
      }
      account_info {
        _id
        create_costing_auto
        account_info_number
        account_info_type
        account_info_customer_type
        customer_service_type
        old_user
        account_info_register_channel
        account_info_status

        account_info_business_type {
          th
          en
        }

        account_info_business_name
        account_info_tax_id
        account_info_postcode
        account_info_branch
        account_info_email
        account_info_address
        account_info_country
        account_info_province
        account_info_district
        account_info_subdistrict
        account_info_corporate_type
        account_info_corporate_phone_code
        account_info_corporate_phone_number
        account_info_corporate_co_phone_code
        account_info_corporate_co_phone_number
        account_info_postcode
        account_info_tel
        account_info_phone_code
        account_info_phone_number
        account_info_logo
        account_info_location_map
        account_info_tracking_url
        create_date
        last_update
        account_business_email {
          _id
          email
        }

        account_users {
          _id
          account_user_register_from
          account_user_name
          account_user_type
          account_user_password
          account_user_phone_code
          account_user_phone_number
          account_user_display_name
          account_user_display_picture
          account_user_status
          account_user_activate_code
          create_date
          last_update
        }

        account_documents {
          _id
          account_document_type
          account_document_name
          account_document_path
          create_date
          last_update
        }

        account_billing_addresses {
          _id
          account_billing_address_tax
          account_billing_address_name
          account_billing_address_address
          account_billing_address_country
          account_billing_address_province
          account_billing_address_district
          account_billing_address_postcode
          account_billing_address_tel
          account_billing_address_email
          create_date
          last_update
        }

        account_info_note
        shipping_from_main_address
        account_info_shipping_address {
          _id
          address
          country
          province
          district
          subdistrict
          postcode
        }
        cod_days
      }
    }
  }
`;
