import gql from 'graphql-tag';

export default gql`
query searchUserTruck(
  $_id: ID
  $user_setting_truck_license_plate : String
){
    userSettingTruckList(
      show_data : 10,
      _id : $_id
      user_setting_truck_license_plate : $user_setting_truck_license_plate
    ){
      user_setting_truck{
         _id
          user_setting_truck_license_plate
          driver_status
      }
      total_page
    }
  }
`;
