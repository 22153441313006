import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipmentID: ID!
    $cancel_comment: String
){
    shipmentsUpdate(
        _id:$shipmentID,
        cancel_comment: $cancel_comment,
        shipment_status: 5
    ){
        _id
    }
  }
`;
