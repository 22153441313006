import gql from 'graphql-tag';

export default gql`
mutation Update(
    $_id: ID!
    $status: Int
    $receipt_id : ID
    $receipt_date: String
    $invoice_document: String
    $credit_used: Float
    $summary_total_price: Float
  ){
    summaryInvoiceUpdate(
        _id: $_id
        status: $status
        receipt_id: $receipt_id
        receipt_date : $receipt_date
        invoice_document: $invoice_document
        credit_used: $credit_used
        summary_total_price: $summary_total_price
    ){
        _id
    }
}
`;
