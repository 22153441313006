import gql from 'graphql-tag';

export default gql`
query search(
  $_id: ID
  $name: String
  $page_number: Int
  $order_by: String
  $order_type: String
){
  challengeList(
      page_number :$page_number,
      show_data: 30,
      order_by :$order_by,
	  	order_type :$order_type,
      name : $name
      _id :$_id
  	) {
    challenge {
      _id
      name
      challenge_type
      start_date
      end_date
    }
    total_page
  }
}
`;


