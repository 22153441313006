import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Input } from '../../../../components';
import { Query, Mutation } from 'react-apollo';
import { connect } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
// import DocumentList from './DocumentList';

// utils
import formatCurrency from '../../../../../../utils/formatCurrency';

// graphql
import paymentOrderList from '../../../../graphql/query/paymentOrderList';
import singleUpload from '../../../../graphql/mutation/singleUpload';
import billingCreatePaymentDocument from '../../../../graphql/mutation/billingCreatePaymentDocument';

import s3Bucket from 'config/s3Bucket';

const ContainerStyled = styled.div`
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;
  padding-bottom: 20px;

  & .subTitle {
    color: #aaaaaa;
  }

  & .filepond--root {
    font-size: 16px !important;
  }

  & .filepond--root {
    height: 76px !important;
  }
`;

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

class PaymentInvoiceDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.myRef = React.createRef();
  }

  render() {
    return (
      <ContainerStyled>
        <div style={{ marginTop: 25, marginLeft: 15, marginBottom: 15 }}>ไม่มีข้อมูล</div>
      </ContainerStyled>
    );
  }
}

export default PaymentInvoiceDocument;
