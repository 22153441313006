import gql from 'graphql-tag';

export default gql`
  mutation AccountBusinessUpdate(
    $_id: ID
    $account_business_id: ID
    $account_info_business_name: String
    $account_info_tax_id: String
    $account_info_branch: String
    $account_info_postcode: String
    $account_info_address: String
    $account_info_country: String
    $account_info_province: String
    $account_info_district: String
    $account_info_subdistrict: String
    $account_info_corporate_type: String
    $account_info_corporate_phone_code: String
    $account_info_corporate_phone_number: String
    $account_info_corporate_co_phone_code: String
    $account_info_corporate_co_phone_number: String
    $account_business_email: [account_business_emailArg]
    $account_business_email_delete: [account_business_email_deleteArg]
    $business_usage_information_1: String
    $business_usage_information_2: [String]
    $business_usage_information_3: [String]
    $business_admin_document_1: String
    $business_admin_document_2: String
    $business_admin_document_3: String
    $business_credit_req: Float
    $business_credit_approved: Float
    $business_edocument_receipt: Int
    $business_alway_wht: Int
    $business_status: Int
    $business_admin_delivery_vat: String
    $business_admin_delivery_wht: String
    $business_admin_service_vat: String
    $business_admin_service_wht: String
    $business_admin_benefit_discount: [business_admin_benefit_discountArg]
    $account_info_note: String
    $reject_note: String
    $business_invoice_type: Int
    $invite_code: String
    $business_invoice_date_range: business_invoice_date_rangeArg
    $account_info_shipping_address: [accountInfoShippingSddressArg]
    $business_pay_round: business_pay_roundArg
    $shipping_from_main_address: Boolean
    $account_info_customer_type: Int
    $cod_days: Int
    $recommend_company: String
    $create_costing_auto: Boolean
    $customer_service_type: [String]
    $account_pricing_conditions_weight: Boolean
    $account_pricing_conditions_dimension: Boolean
  ) {
    accountBusinessUpdate(
      _id: $_id
      account_business_id: $account_business_id
      account_info_business_name: $account_info_business_name
      account_info_tax_id: $account_info_tax_id
      account_info_postcode: $account_info_postcode
      account_info_branch: $account_info_branch
      account_info_address: $account_info_address
      account_info_country: $account_info_country
      account_info_province: $account_info_province
      account_info_district: $account_info_district
      account_info_subdistrict: $account_info_subdistrict
      account_info_corporate_type: $account_info_corporate_type
      account_info_corporate_phone_code: $account_info_corporate_phone_code
      account_info_corporate_phone_number: $account_info_corporate_phone_number
      account_info_corporate_co_phone_code: $account_info_corporate_co_phone_code
      account_info_corporate_co_phone_number: $account_info_corporate_co_phone_number
      account_business_email: $account_business_email
      account_business_email_delete: $account_business_email_delete
      business_usage_information_1: $business_usage_information_1
      business_usage_information_2: $business_usage_information_2
      business_usage_information_3: $business_usage_information_3
      business_admin_document_1: $business_admin_document_1
      business_admin_document_2: $business_admin_document_2
      business_admin_document_3: $business_admin_document_3
      business_credit_req: $business_credit_req
      business_credit_approved: $business_credit_approved
      business_edocument_receipt: $business_edocument_receipt
      business_alway_wht: $business_alway_wht
      business_status: $business_status
      business_admin_delivery_vat: $business_admin_delivery_vat
      business_admin_delivery_wht: $business_admin_delivery_wht
      business_admin_service_vat: $business_admin_service_vat
      business_admin_service_wht: $business_admin_service_wht
      business_admin_benefit_discount: $business_admin_benefit_discount
      account_info_note: $account_info_note
      reject_note: $reject_note
      business_invoice_type: $business_invoice_type
      invite_code: $invite_code
      business_invoice_date_range: $business_invoice_date_range
      shipping_from_main_address: $shipping_from_main_address
      account_info_shipping_address: $account_info_shipping_address
      business_pay_round: $business_pay_round
      account_info_customer_type: $account_info_customer_type
      cod_days: $cod_days
      recommend_company: $recommend_company
      create_costing_auto: $create_costing_auto
      customer_service_type: $customer_service_type
      account_pricing_conditions_weight: $account_pricing_conditions_weight
      account_pricing_conditions_dimension: $account_pricing_conditions_dimension
    ) {
      business_status
      business_req_number
      last_update
    }
  }
`;
