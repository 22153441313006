import gql from 'graphql-tag';

export default gql`
  query($_id: ID!) {
    billingParcel(_id: $_id) {
      _id
      customer {
        full_name
      }
      parcelCost {
        _id
        jobId
        refNumber
        totalCostAvg
      }
      cost
      parcelRevenue {
        jobId
        totalRevenue
        refNumber
      }
      parcelStatus
      status
      trackingNumber
      orderNumber
      itemName
      goodsValue
      cod
      weight
      width
      length
      height
      proof
      deliveredDate
      pickupDate
      createdAt
      updatedAt
    }
  }
`;
