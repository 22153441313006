import React, { Component } from 'react';
import styled from 'styled-components';
import {
    Row,
    Col,
    Table,
    Button,
    Grid,
    FormControl,
} from 'react-bootstrap';

import { Query, compose, graphql, Mutation } from 'react-apollo';
import Select from 'react-select';
import CardTable from 'components/CardTable/CardTable.jsx';
import NotificationSystem from 'react-notification-system';
import customerId from 'config/customerId';
import thirPartySettingSet from './graphql/mutation/thirPartySettingSet';
import accountInfoListQuery from './graphql/query/accountInfoList';
import thirdPartyList from './graphql/query/thirdPartyList';
import datathirdPartyList from './graphql/query/thirdPartyList';
import { client } from '../../../../index';
import { Layout, ModalConfirm, Input } from '../../components';

const styleNotification = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px'
        },

        error: {
            color: '#FFFFFF',
            backgroundColor: '#FC727A',
            border: '1px solid #FC727A',
            boxShadow: 'none',
            borderRadius: '4px',
        },

        success: {
            color: '#FFFFFF',
            backgroundColor: '#318DE8',
            border: '1px solid #318DE8',
            boxShadow: 'none',
            borderRadius: '4px',
        }
    },
}
const TextRight = styled.div`
	height: 38px;
	top: 0;
	right: 0;
	background-color: #ffffff;
	border: 1px solid #e3e3e3;
	padding: 0px 12px;
	box-sizing: border-box;
	border-radius: 6px;
	line-height: 40px;
`;
const InputGroup = styled.div`
	display: flex;
	flex-direction: row;
`;
const TextMessageError = styled.span`
	font-size:18px;
	color: #FF0000;
    height: 22px;
    float: right;
`;

const colourStyles = {
    control: (styles, { data, isDisabled }) => ({ ...styles, backgroundColor: isDisabled ? '#F9F9F9' : 'white' }),
    singleValue: (styles, { data, isDisabled }) => ({ ...styles, color: isDisabled ? '#000' : '#000' }),
};
// let numcheck = 0;
let datacheckobj = null;
class MasterData3PL extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_complete: false,
            percent_3pl_Invalid: false,
            account_info_id_3pl_Invalid: false,
            masterdata_3PL_list: null,
            mode_btn: 'edit',
            show_delmaster: false,
            datamasterdelid: null,
            numcheck: 0,
        };
        this.notificationSystem = React.createRef();
    }
    // set option
    renderVariablesMasterdata3PL() {
        let arrayObj = [];
        if (this.state.masterdata_3PL_list) {
            this.state.masterdata_3PL_list.map((masterdata3PLList, masterdata3PLKey) => {
                let obj = {
                    account_info_id: masterdata3PLList.account_info_id,
                    ka_id: customerId.lazada,
                    percent: masterdata3PLList.percent,
                    active: true,
                };
                if (masterdata3PLList._id) {
                    obj._id = masterdata3PLList._id
                }
                arrayObj.push(obj);
            })
        }
        if (this.state.datamasterdelid) {
            this.state.datamasterdelid.map((list, key) => {
                let obj = {
                    _id: list,
                    active: false,
                    ka_id: customerId.lazada,
                };
                arrayObj.push(obj);
            })
        }
        if (datacheckobj && this.state.masterdata_3PL_list) {
            // var filtered = datacheckobj.filter((list) => !this.state.masterdata_3PL_list.indexOf(list));
            let filtered = datacheckobj.filter(i => !this.state.masterdata_3PL_list.find(ele => ele['account_info_id'] == i['account_info_id']));
            if (filtered.length > 0) {
                filtered.map((list, key) => {
                    let obj = {
                        _id: list._id,
                        active: false,
                        ka_id: customerId.lazada,
                    };
                    arrayObj.push(obj);
                })
            }

        }
        return arrayObj;
    }
    //ลบรายการ masterdata3pl
    handleDeletemasterdata3PLList(obj, key) {
        let arrayObj = this.state.masterdata_3PL_list;
        let arraydelObj = [];
        if (obj) {
            arraydelObj.push(obj);
        }
        arrayObj.splice(key, 1);
        this.setState({
            datamasterdelid: arraydelObj,
            masterdata_3PL_list: arrayObj,
        });
    }
    //เพิ่มรายการ masterdata3pl
    handleAddmasterdata3PLList() {
        let setobj = [];
        let arrayObj = this.state.masterdata_3PL_list;
        let obj = {
            _id: '',
            account_info_id: '',
            percent: '',
            shipment_improgress: 0,
            shipment_completed: 0,
            percent_Invalid: false,
            account_info_id_Invalid: false,
        }
        if (arrayObj) {
            arrayObj.push(obj);
        } else {
            arrayObj = [obj];
        }

        this.setState({
            mode_btn: 'save',
            masterdata_3PL_list: arrayObj,
        });
    }
    selectOption3PL(agentList) {
        let options = [];
        if (agentList.length > 0) {
            agentList.map((accountInfoList, accountInfoKey) => {
                let obj = {
                    value: accountInfoList._id,
                    label: accountInfoList.account_info_business_name
                }
                options.push(obj);
            });
        }
        return options;
    }

    //setState input อัตราส่วน
    setInputmasterdata3PLList(event, key) {
        let sum = 0;
        let value = parseInt(event.target.value);
        if (event.target.value > 100) {
            value = 100;
        }
        if(!value){
            value = 0;
        }
        const arrayObj = [...this.state.masterdata_3PL_list];
        let chkInvalid = false;
        arrayObj[key].percent = parseInt(value);
        arrayObj.map((masterdata3PLList, masterdata3PLKey) => {
            if (masterdata3PLList.percent) {
                sum += parseInt(masterdata3PLList.percent);
            }
            if (sum > 100) {
                arrayObj[key].percent_Invalid = true;
                chkInvalid = true;
            } else {
                arrayObj[masterdata3PLKey].percent_Invalid = false;
                chkInvalid = false;
            }
        });
        if (sum !== 100) {
            arrayObj[key].percent_Invalid = true;
            chkInvalid = true;
        }
        this.setState({
            masterdata_3PL_list: arrayObj,
            percent_3pl_Invalid: chkInvalid
        });
    }

    //setState select ชื่อ3PL
    async setSelectmasterdata3PLList(event, objitem, key) {
        try {
            const { data: datalist } = await client.query({
                query: datathirdPartyList,
                fetchPolicy: 'network-only',
                variables: {
                    ka_id: customerId.lazada,
                    account_info_id: event.value
                },
            });
            const arrayObj = [...this.state.masterdata_3PL_list];
            if (datalist.thirdPartyList.third_party_list.length > 0) {
                const list = datalist.thirdPartyList.third_party_list[0];
                let setObj = {}
                setObj = {
                    _id: list._id,
                    account_info_id: list.account_info_id,
                    ka_id: customerId.lazada,
                    shipment_completed: list.shipment_completed,
                    shipment_improgress: list.shipment_improgress,
                    percent: list.percent,
                }
                arrayObj[key] = setObj;
            } else {
                let setObj = {}
                setObj = {
                    _id: '',
                    account_info_id: event.value,
                    ka_id: customerId.lazada,
                    shipment_completed: 0,
                    shipment_improgress: 0,
                    percent: 0,
                }
                arrayObj[key] = setObj;
            }
            //หา account_info_id ที่ซ้ำกัน ใส่ในarray
            let uniq = arrayObj.map((name) => {
                return {
                    count: 1,
                    name: name.account_info_id
                }

            })
                .reduce((a, b) => {
                    a[b.name] = (a[b.name] || 0) + b.count
                    return a
                }, {})
            let duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);

            //เช็คerror input account_info_id ที่ซ้ำกัน
            let chkInvalid = false;
            arrayObj.map((masterdata3PLList, masterdata3PLKey) => {
                if (duplicates.length > 0) {
                    let check = duplicates.indexOf(masterdata3PLList.account_info_id);
                    if (check !== -1) {
                        arrayObj[masterdata3PLKey].account_info_id_Invalid = true;
                        chkInvalid = true;
                    } else {
                        arrayObj[masterdata3PLKey].account_info_id_Invalid = false;
                        chkInvalid = false;
                    }
                } else {
                    arrayObj[masterdata3PLKey].account_info_id_Invalid = false;
                    chkInvalid = false;
                }
            });
            this.setState({
                masterdata_3PL_list: arrayObj,
                account_info_id_3pl_Invalid: chkInvalid,
            });
        } catch (error) {
            console.log(error);
        }

    }

    checkUpdatemasterdata3PL = (postMutation) => {
        let sum = 0;
        let count = 0;
        let textsumerror = '';
        const arrayObj = [...this.state.masterdata_3PL_list];
        let chkInvalidpercent = false;
        let chkInvalidaccount = false;
        arrayObj.map((masterdata3PLList, masterdata3PLKey) => {
            if (masterdata3PLList.account_info_id_Invalid) {
                chkInvalidaccount = true;
            }
            if (!masterdata3PLList.account_info_id) {
                arrayObj[masterdata3PLKey].account_info_id_Invalid = true;
                chkInvalidaccount = true;
            }
            if (!masterdata3PLList.percent && masterdata3PLList.percent !== 0) {
                arrayObj[masterdata3PLKey].percent_Invalid = true;
                chkInvalidpercent = true;
            } else {
                sum += parseFloat(masterdata3PLList.percent);
                count++;
            }
        });
        if (sum !== 100 && count !== 0) {
            arrayObj.map((masterdata3PLList, masterdata3PLKey) => {
                arrayObj[masterdata3PLKey].percent_Invalid = true;
            });
            chkInvalidpercent = true;
            textsumerror = 'อัตราส่วนรวมกันต้อง 100% เท่านั้น';
        } else {
            textsumerror = 'กรุณาตรวจสอบข้อมูลให้ถูกต้อง';
        }
        if (chkInvalidaccount === true || chkInvalidpercent === true) {
            this.addNotification('error', textsumerror);
            this.setState({
                account_info_id_3pl_Invalid: chkInvalidaccount,
                percent_3pl_Invalid: chkInvalidpercent
            });
        } else {
            postMutation({
                variables: {
                    third_party_list: this.renderVariablesMasterdata3PL()
                }
            })
        }
    }
    addNotification = (typeStatus, message) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: (
                <span>
                    <strong>แจ้งเตือน</strong> {message}
                </span>
            ),
            level: typeStatus,
            position: 'tr',
        });
    };

    onClickEdit(dataList, dataDefault) {
        datacheckobj = [...dataDefault];
        this.setState({
            mode_btn: 'save',
            masterdata_3PL_list: [...dataList.thirdPartyList.third_party_list]
        });
    }

    render() {
        let agentList = [];
        let thirdPartyListitem = [];
        if (
            this.props.accountInfoListQuery.loading === false &&
            this.props.accountInfoListQuery.accountInfoList !== undefined
        ) {
            agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
        }
        if (
            this.props.thirdPartyList.loading === false &&
            this.props.thirdPartyList.thirdPartyList !== undefined
        ) {
            thirdPartyListitem = this.props.thirdPartyList.thirdPartyList.third_party_list;
        }
        return (
            <Layout>
                <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
                <Row>
                    <Col md={12}>
                        <div>
                            <Query
                                query={thirdPartyList}
                                variables={{
                                    ka_id: customerId.lazada,
                                    active: true,
                                }}
                                fetchPolicy="cache-and-network"
                            >
                                {({ data, loading, error, refetch: refetchdatathirdPartyList }) => {
                                    if (loading) return (<Grid fluid>loading...</Grid>);
                                    if (error) return null;
                                    return (
                                        <CardTable
                                            title="3PL ที่รับงานให้ Project นี้"
                                            category="ระบุชื่อ 3PL และแบ่งงานตามเปอร์เซ็นตามที่กำหนด"
                                            ctTableFullWidth
                                            ctTableResponsive
                                            rightButton=
                                            {data.thirdPartyList.third_party_list.length > 0 && this.state.mode_btn === 'edit' ? (
                                                <div style={{ float: 'right', marginLeft: '15px' }}>
                                                    <Button style={{ width: '100px', backgroundColor: '#FFDB46' }} onClick={() => this.onClickEdit(data, thirdPartyListitem)} >
                                                        <i className="fad fa-pencil-alt"></i> แก้ไข
                                                    </Button>
                                                </div>
                                            ) : (
                                                    <div style={{ float: 'right', marginLeft: '15px' }}>
                                                        <Button style={{ width: '100px' }} onClick={() => this.handleAddmasterdata3PLList()} >
                                                            + เพิ่มรายการ
                                                        </Button>
                                                        <Mutation
                                                            mutation={thirPartySettingSet}
                                                            update={() => {
                                                                this.setState({ show_complete: true, mode_btn: 'edit' }, () => {
                                                                    refetchdatathirdPartyList();
                                                                })
                                                            }}
                                                            onError={error => {
                                                                alert(error);
                                                            }}
                                                        >
                                                            {postMutation =>
                                                                <Button
                                                                    onClick={() => this.checkUpdatemasterdata3PL(postMutation)}
                                                                    disabled={(!this.state.masterdata_3PL_list && !this.state.datamasterdelid) ? 'disabled' : ''}
                                                                    style={{ background: '#87CB16', color: '#ffffff', marginLeft: '15px', width: '100px' }} >
                                                                    บันทึก
                                                                </Button>
                                                            }

                                                        </Mutation>
                                                    </div>
                                                )
                                            }

                                            content={
                                                <div>
                                                    <div className="table-vertical">
                                                        <Table striped hover>
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ minWidth: '50px', textAlign: 'center' }}>ลำดับ</th>
                                                                    <th style={{ minWidth: '250px' }}>ชื่อ3PL</th>
                                                                    <th style={{ minWidth: '200px' }}>อัตราส่วน</th>
                                                                    <th style={{ minWidth: '140px' }}>จำนวนขนส่งที่ทำงานอยู่</th>
                                                                    <th style={{ minWidth: '140px' }}>จำนวนขนส่งที่เสร็จแล้ว</th>
                                                                    <th style={{ minWidth: '140px' }}>จำนวงานขนส่งทั้งหมด</th>
                                                                    <th style={{ minWidth: '120px', textAlign: 'center' }}>ลบ</th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                this.state.masterdata_3PL_list || data.thirdPartyList.third_party_list.length > 0 ? (
                                                                    <tbody>
                                                                        {loading && (
                                                                            <tr>
                                                                                <td colSpan="7">Loading...</td>
                                                                            </tr>
                                                                        )}

                                                                        {error && (
                                                                            <tr>
                                                                                <td colSpan="7">Error</td>
                                                                            </tr>
                                                                        )}

                                                                        {!loading && !error && (!this.state.masterdata_3PL_list && data.thirdPartyList.third_party_list.length === 0) && (
                                                                            <tr>
                                                                                <td colSpan="7">ไม่มีข้อมูล</td>
                                                                            </tr>
                                                                        )}

                                                                        {
                                                                            (this.state.masterdata_3PL_list || data.thirdPartyList.third_party_list).map((masterdata3PLList, masterdata3PLKey) => {
                                                                                let account_info_id = '';
                                                                                if (masterdata3PLList.account_info_id) {
                                                                                    const list = agentList.filter((list) => {
                                                                                        return list._id == masterdata3PLList.account_info_id;
                                                                                    })
                                                                                    if (list.length > 0) {
                                                                                        account_info_id = { value: list[0]._id, label: list[0].account_info_business_name };

                                                                                    }
                                                                                    if (!this.state.masterdata_3PL_list) {
                                                                                        masterdata3PLList.percent_Invalid = false;
                                                                                    }
                                                                                }
                                                                                return (
                                                                                    <tr key={masterdata3PLKey}>
                                                                                        <td style={{ textAlign: 'center' }}>{masterdata3PLKey + 1}</td>
                                                                                        <td>
                                                                                            <div style={{ marginTop: `${masterdata3PLList.account_info_id_Invalid ? '10px' : '0'}`, border: `${masterdata3PLList.account_info_id_Invalid ? '1px solid #FF0000' : ''}` }}>
                                                                                                <Select
                                                                                                    value={account_info_id}
                                                                                                    defaultValue={account_info_id}
                                                                                                    onChange={event => this.setSelectmasterdata3PLList(event, masterdata3PLList, masterdata3PLKey)}
                                                                                                    options={this.selectOption3PL(agentList)}
                                                                                                    placeholder="ระบุ"
                                                                                                    isDisabled={this.state.mode_btn === 'edit' ? 'disabled' : ''}
                                                                                                    styles={colourStyles}
                                                                                                />
                                                                                            </div>

                                                                                            {masterdata3PLList.account_info_id_Invalid && <TextMessageError>{masterdata3PLList.account_info_id ? 'ไม่สามารถเลือก3PLซ้ำกันได้' : 'กรุณากรอกข้อมูล'}</TextMessageError>}
                                                                                        </td>
                                                                                        <td>
                                                                                            <InputGroup>
                                                                                                <Input
                                                                                                    pattern="^-?[0-9]\d*\.?\d*$"
                                                                                                    required
                                                                                                    type="text"
                                                                                                    min={0}
                                                                                                    max={100}
                                                                                                    value={masterdata3PLList.percent}
                                                                                                    onChange={(event) => this.setInputmasterdata3PLList(event, masterdata3PLKey)}
                                                                                                    style={{ width: '100%', height: '38px', marginBottom: `${masterdata3PLList.percent_Invalid ? '10px' : '0px'}` }}
                                                                                                    messageError={masterdata3PLList.percent_Invalid === true && (`${masterdata3PLList.percent >= 0 ? 'อัตราส่วนรวมกันต้อง 100% เท่านั้น' : 'กรุณากรอกข้อมูล'}`)}
                                                                                                    placeholder="ระบุ"
                                                                                                    disabled={this.state.mode_btn === 'edit'} />
                                                                                                <TextRight style={{ backgroundColor: `${this.state.mode_btn === 'edit' ? '#F9F9F9' : '#ffffff'}` }}>%</TextRight>
                                                                                            </InputGroup>
                                                                                        </td>
                                                                                        <td>{masterdata3PLList.shipment_improgress ? masterdata3PLList.shipment_improgress : 0} งาน</td>
                                                                                        <td>{masterdata3PLList.shipment_completed ? masterdata3PLList.shipment_completed : 0} งาน</td>
                                                                                        <td>{masterdata3PLList.shipment_sum ? masterdata3PLList.shipment_sum : 0} งาน</td>
                                                                                        <td style={{ textAlign: 'center' }}>
                                                                                            <a
                                                                                                onClick={() => this.state.mode_btn === 'edit' ? null : this.handleDeletemasterdata3PLList(masterdata3PLList._id, masterdata3PLKey)}
                                                                                                style={{ cursor: `${this.state.mode_btn === 'edit' ? 'default' : 'pointer'}`, opacity: `${this.state.mode_btn === 'edit' ? 0.5 : 1}` }}><i class="far fa-trash-alt" style={{ color: 'red' }}></i>
                                                                                            </a>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                ) : (
                                                                        <tbody>
                                                                            <tr >
                                                                                <td> ไม่มีรายการหัวข้อปัญหา</td>
                                                                                <td colSpan={7}></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                            }
                                                        </Table>
                                                    </div>
                                                </div>
                                            }
                                        />
                                    );
                                }}
                            </Query>
                        </div>
                        <ModalConfirm
                            show={this.state.show_complete}
                            onPressButtonCancel={() => {
                                this.setState({
                                    show_complete: false,
                                });

                            }}
                            labelButtonCancel={`ปิด`}
                            styleColorButtonConfirm="green"
                            titleHeader={`บันทึกรายการ3PLเสร็จสิ้น`}
                            onHide={() => {
                                this.setState({
                                    show_complete: false,
                                });
                            }}
                        >
                            <div> บันทึกรายการ3PL ที่รับงานให้ Project นี้เสร็จสิ้น</div>
                        </ModalConfirm>
                    </Col>
                </Row>
            </Layout>
        )
    }
}

export default compose(
    graphql(accountInfoListQuery, {
        name: 'accountInfoListQuery',
        options: () => ({
            fetchPolicy: 'network-only',
            variables: {
                account_info_type: 3,
            },
        }),
    }),
    graphql(thirdPartyList, {
        name: 'thirdPartyList',
        options: () => ({
            fetchPolicy: 'network-only',
            variables: {
                ka_id: customerId.lazada,
                active: true,
            },
        }),
    })
)(MasterData3PL);  