import React from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import { Row, Col, FormGroup, FormControl, Button, ControlLabel } from 'react-bootstrap';
import driverUserListQuery from './graphql/query/driverUserList';
import trackingUpdateImportMutation from './graphql/mutation/trackingUpdateImport';
import trackingUpdateExportMutation from './graphql/mutation/trackingUpdateExport';
import assignDriverMutation from './graphql/mutation/assignDriver';
import userTruckListQuery from './graphql/query/userTruckList';
import Autosuggest from 'react-autosuggest';
import Select from 'react-select';
import { client } from '../../../../index';

const TextControlLabel = styled(ControlLabel)`
  width: 100%;
  font-size: 18px !important;
  color: #000000 !important;

  & small {
    color: #888888;
    float: right;
    cursor: pointer;
  }
`;

const Required = styled.span`
  color: #d90101;
`;

const AutosuggestContainer = styled.div`
  min-height: 60px;

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

class AssignDriver extends React.Component {
  state = {
    openEditDriverMode: this.props.openEditDriverMode,
    trackingId: this.props.trackingId,
    shipmentId: this.props.shipmentId,
    driver_phone_code: 66,
    driver_phone_number: this.props.driverPhoneNumber,
    license_plate_back: this.props.licensePlateBack,
    tracking: this.props.tracking,
    type: this.props.type,
    transporter: this.props.transporter,

    //ค้นหา driver list
    driverListSuggestions: [],
    driverName: this.props.driverName,
    driverId: this.props.driverUserId,

    //ค้นหาทะเบียน
    userSettingTruckLicensePlateListSuggestions: [],
    userSettingTruckLicensePlate: this.props.licensePlateFront,
    userSettingTruckLicensePlateId: this.props.userSettingTruckId,
  };

  onSubmitAddDriver(trackingAddDriver, assignDriver) {
    const {
      driverName,
      driver_phone_number,
      driver_phone_code,
      userSettingTruckLicensePlate,
      license_plate_back,
      tracking,
      type,
    } = this.state;

    if (type === 'import') {
      if (this.state.openEditDriverMode === 'new') {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_add: [
              {
                driver_name: driverName,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: userSettingTruckLicensePlate,
                license_plate_back: license_plate_back,
              },
            ],
          },
        }).then(() => assignDriver());
      } else {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_update: [
              {
                _id: tracking[0].tracking_import_detail[0].track_driver[0]._id,
                position_driver: '0',
                driver_name: driverName,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: userSettingTruckLicensePlate,
                license_plate_back: license_plate_back,
              },
            ],
          },
        }).then(() => assignDriver());
      }
    } else if (type === 'export') {
      if (this.state.openEditDriverMode === 'new') {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_add: [
              {
                driver_name: driverName,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: userSettingTruckLicensePlate,
                license_plate_back: license_plate_back,
              },
            ],
          },
        }).then(() => assignDriver());
      } else {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_update: [
              {
                _id: tracking[0].tracking_export_detail[0].track_driver[0]._id,
                position_driver: '0',
                driver_name: driverName,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: userSettingTruckLicensePlate,
                license_plate_back: license_plate_back,
              },
            ],
          },
        }).then(() => assignDriver());
      }
    }
  }

  checkValidateAddDriver() {
    const { driverId, driver_phone_number, userSettingTruckLicensePlateId } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (
      validatePhoneNumber.test(driver_phone_number) &&
      driverId &&
      userSettingTruckLicensePlateId
    ) {
      return true;
    }

    return false;
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      shipmentListSuggestions: [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  getDriverListSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
          account_info_id: this.state.transporter,
          agent: this.state.transporter,
        },
      });

      const driverUserListData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: e.driver_name,
          licensePlate: e.user_setting_truck_license_plate,
          licensePlateId: e.user_setting_truck_id,
          account_info_id: e.account_info_id,
          driverPhoneNumber: '0' + e.driver_phone_number,
          licensePlateBack: e.user_setting_truck_license_plate_back,
        };
      });

      this.setState({
        driverListSuggestions: driverUserListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getLicensePlateListSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: userTruckListQuery,
        fetchPolicy: 'network-only',
        variables: {
          user_setting_truck_license_plate: inputValue,
        },
      });

      const userSettingTruckListData = data.userSettingTruckList.user_setting_truck.map(e => {
        return {
          id: e._id,
          name: e.user_setting_truck_license_plate,
          userSettingTruckLicensePlateBack: e.user_setting_truck_license_plate_back,
        };
      });

      this.setState({
        userSettingTruckLicensePlateListSuggestions: userSettingTruckListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getTypeSchemaMutation = () => {
    if (this.state.type === 'export') {
      return trackingUpdateExportMutation;
    }

    return trackingUpdateImportMutation;
  };

  render() {
    return (
      <Mutation
        mutation={this.getTypeSchemaMutation()}
        update={() => {
          this.props.callback();
        }}
        onError={error => {
          alert(error);
        }}
      >
        {trackingAddDriver => (
          <React.Fragment>
            <Row style={{ marginTop: 20 }}>
              <Col md={12}>
                <TextControlLabel>
                  ชื่อ-สกุล พนักงานขับรถ<Required>*</Required>
                </TextControlLabel>
                <AutosuggestContainer>
                  <Autosuggest
                    suggestions={this.state.driverListSuggestions}
                    onSuggestionsFetchRequested={({ value }) => {
                      this.getDriverListSuggestions(value);
                    }}
                    onSuggestionsClearRequested={() => {
                      this.setState({
                        driverListSuggestions: [],
                      });
                    }}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    focusInputOnSuggestionClick={false}
                    inputProps={{
                      placeholder: 'ชื่อ-สกุล พนักงานขับรถ',
                      value: this.state.driverName,
                      onChange: event =>
                        this.setState({
                          driverName: event.target.value,
                          driverId: '',
                        }),
                      type: 'text',
                      className: 'form-control',
                    }}
                    onSuggestionSelected={(event, { suggestion }) => {
                      this.setState({
                        driverId: suggestion.id,
                        driverName: suggestion.name,
                        userSettingTruckLicensePlate: suggestion.licensePlate,
                        userSettingTruckLicensePlateId: suggestion.licensePlateId,
                        driver_phone_number: suggestion.driverPhoneNumber,
                        license_plate_back: suggestion.licensePlateBack || '',
                      });
                    }}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                  />
                </AutosuggestContainer>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <TextControlLabel>
                    เบอร์โทรติดต่อ<Required>*</Required>
                  </TextControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.driver_phone_number}
                    placeholder="ตัวอย่าง: 0123456789"
                    onChange={e =>
                      this.setState({
                        driver_phone_number: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <TextControlLabel>
                  ทะเบียนหัว<Required>*</Required>
                </TextControlLabel>
                <AutosuggestContainer>
                  <Autosuggest
                    suggestions={this.state.userSettingTruckLicensePlateListSuggestions}
                    onSuggestionsFetchRequested={({ value }) => {
                      this.getLicensePlateListSuggestions(value);
                    }}
                    onSuggestionsClearRequested={() => {
                      this.setState({
                        userSettingTruckLicensePlateListSuggestions: [],
                      });
                    }}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    focusInputOnSuggestionClick={false}
                    inputProps={{
                      placeholder: 'ทะเบียนรถ',
                      value: this.state.userSettingTruckLicensePlate,
                      onChange: event =>
                        this.setState({
                          userSettingTruckLicensePlate: event.target.value,
                          userSettingTruckLicensePlateId: '',
                        }),
                      type: 'text',
                      className: 'form-control',
                    }}
                    onSuggestionSelected={(event, { suggestion }) => {
                      this.setState({
                        userSettingTruckLicensePlateId: suggestion.id,
                        userSettingTruckLicensePlate: suggestion.name,
                        license_plate_back: suggestion.userSettingTruckLicensePlateBack,
                      });
                    }}
                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                  />
                </AutosuggestContainer>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <TextControlLabel>ทะเบียนหาง</TextControlLabel>
                  <FormControl
                    type="text"
                    value={this.state.license_plate_back}
                    placeholder="ตัวอย่าง: กข0001"
                    onChange={e =>
                      this.setState({
                        license_plate_back: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Mutation
                  mutation={assignDriverMutation}
                  variables={{
                    shipmentID: this.state.shipmentId,
                    driverID: this.state.driverId,
                    truckID: this.state.userSettingTruckLicensePlateId,
                  }}
                  onError={error => {
                    alert('Assign Driver: ' + error);
                  }}
                  refetchQueries={() => ['search']}
                >
                  {(assignDriver, { loading: loadingAssignDriver }) => {
                    return (
                      <Button
                        onClick={e => this.onSubmitAddDriver(trackingAddDriver, assignDriver)}
                        disabled={!this.checkValidateAddDriver()}
                        className="btn btn-red"
                        block
                      >
                        ตกลง
                      </Button>
                    );
                  }}
                </Mutation>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Mutation>
    );
  }
}

export default AssignDriver;
