import React from 'react';
import { Layout, ModalConfirm } from '../../components';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
  Popover,
} from 'react-bootstrap';
import CardTable from 'components/CardTable/CardTable.jsx';
import classes from './ShipmentList.module.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import shipmentListQuery from './graphql/query/shipmentList';
import urijs from 'urijs';
import Moment from 'react-moment';
import assignAdminShipment from './graphql/mutation/assignAdminShipment';
import { client } from '../../../../index';
import adminListQuery from './graphql/query/adminList';
import driverUserListQuery from './graphql/query/driverUserList';
import userTruckListQuery from './graphql/query/userTruckList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import ReactPaginate from 'react-paginate';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import Select from 'react-select';
import assignDriverMutation from './graphql/mutation/assignDriver';
import Autosuggest from 'react-autosuggest';
import reportUrl from 'config/reportUrl';
import assignAgentMutation from './graphql/mutation/assignAgent';
import accountInfoListQuery from './graphql/query/accountInfoList';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import customerId from 'config/customerId';
import ClearShipmentDriverModal from './components/ClearShipmentDriverModal';

import SubHeader from '../../components/SubHeaderNew';
import ShipmentTable from './components/ShipmentTable';

//new
import shipmentKaList from './graphql/query/shipmentKAsList';
import shipmentKAStatus from './graphql/query/shipmentKAStatus';
import refetchQueries from 'utils/refetchQuery';

import HeaderLayoutWithBackUrl from 'views/KaCustomer/components/HeaderLayoutWithBackUrl';
import shipmentKaTotalCod from './graphql/query/shipmentKaTotalCod';
import TrackingTable from './components/TrackingTable';

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;
const Tag = styled.div`
  background-color: #e5ffe5;
  color: #5dba5b;
  font-weight: bold;
  padding: 2px 10px;
  // margin-left: 30px;
  border-radius: 4px;
`;
const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #e3e3e3;
`;
const MenuWrapper = styled.div`
  width: 100%;
  & ul {
    padding-left: 0;
    margin: 0;
    display: flex;

    & li {
      list-style: none;
      margin-right: 15px;
      padding: 22px 10px 15px;

      & a {
        font-size: 18px;
        cursor: pointer;
        color: #000000;
        & i {
          font-size: 18px;
          color: #000000;
        }
      }
    }
    & li.active {
      border-bottom: 2px solid #d90402;
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const NavItemDropDownList = styled(NavItem)``;
const AutosuggestContainer = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  {
    value: 'ลูกค้าต้องการใช้บริการเสริม',
    label: 'ลูกค้าต้องการใช้บริการเสริม',
  },
  { value: 'เลือกใช้บริการเจ้าอื่น', label: 'เลือกใช้บริการเจ้าอื่น' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

class ShipmentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order_by: null,
      order_type: null,
      lazada_order_type: 0,
      shipmentName: this.getBookingNumber() || '',
      tripNumber: '',
      shipment_number: '',
      tagName: '',
      commodityName: '',
      pickupDate: '',
      displayPickupDate: '',
      shipmentStatus: 0,
      shipmentRunningNumber: '',
      admin: '',
      createDate: '',
      displayCreateDate: '',
      pageNumber: 1,
      lazadaImportOrderId: null,
      lazadaImportOrderName: '',
      lazadaImportOrderNumber: '',
      lazadaImportOrderPickupDate: null,
      lazadaImportOrderIdUrl: this.getIdFromUrl() || '',

      //ค้นหา driver
      driverUserListSuggestions: [],
      driverName: '',
      driverId: '',
      setting_truck_type_id: '',

      //assign admin
      isOpenModalAssignAdmin: false,
      shipmentID: '',
      shipmentNumber: '',
      adminName: '',

      //cancel shipment
      rejectDetail: '',
      isShowRejectDetail: false,
      rejectSelected: null,
      rejectRemark: '',
      isOpenModalConfirmCancel: false,

      //assign driver
      driverUserId: '',
      userSettingTruckId: '',
      isOpenModalAssignDriver: false,
      driverSelected: '',
      truckSelected: '',
      DriverSuggestions: [],
      TruckSuggestions: [],
      driverUserSelected: null,

      fileNameBookingFile: '',
      isOpenModalConfirmDownloadReport: false,
      idBookingFile: null,

      //assign agent
      agentSelected: null,
      agentId: null,
      isOpenModalAssignAgent: false,

      OrderNumber: '',
      lazada_import_order_id: null,

      //clear shipment modal
      isShowClearShipmentModal: false,
      shipmentTripClearModal: '',
      shipmentIdClearModal: '',
      agentIdClearModal: '',

      //subheaader
      stage: this.getBookingNumber() ? '' : 'ALL',

      //ค้นหา transporter
      transporterId: '',

      //ค้นหา tracking number
      trackingNumber: '',
      selectedViewBy: 'shipment', //shipment or tracking
    };
  }

  // componentWillMount() {
  // console.log('A : ', this.state);
  // this.setState(prev => ({
  //   ...prev,
  //   driverUserId: 'mark20100601',
  // }));
  // }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    this.setState({
      stage: urlParams.get('stage') || this.state.stage,
      selectedViewBy: urlParams.get('tab') || this.state.selectedViewBy,
    });
  }

  fetchDataLazadaImportOrderList = async () => {
    try {
      const id = this.state.lazadaImportOrderId;

      if (!id) {
        return;
      }

      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          lazada_import_order_id: id,
          account_info_id: customerId.lazada,
        },
      });

      const { order } = data.lazadaImportOrderList;

      if (order.length === 1) {
        const { _id, pick_up_date, name, format_bookname } = order[0];
        this.setState({
          lazadaImportOrderName: name,
          lazadaImportOrderNumber: format_bookname,
          lazadaImportOrderPickupDate: pick_up_date,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).id;
    return id;
  };

  getBookingNumber = () => {
    const bookNumber = urijs(window.location.href).query(true).bookingNumber;
    return bookNumber;
  };

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      default:
        return '';
    }
  }

  renderLazadaSendFinalStatus(status, key) {
    switch (status) {
      case 1:
        return (
          <Label bsStyle="warning">
            <i className="fas fa-spinner"></i>
          </Label>
        );
      case 2:
        return (
          <Label bsStyle="success">
            <i className="fas fa-check"></i>
          </Label>
        );
      default:
        return (
          <Label bsStyle="danger">
            <i className="fas fa-times"></i>
          </Label>
        );
    }
  }

  handleDateRangePicker = (mode, picker) => {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({
        pickupDate: date,
        displayPickupDate: display,
      });
    }
  };

  handleShowAssignAdmin = async (shipmentID, shipmentNumber) => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      this.setState({
        adminName: admin[0].admin_username,
        isOpenModalAssignAdmin: true,
        shipmentID: shipmentID,
        shipmentNumber: shipmentNumber,
      });
    } catch (error) {
      alert(error);
    }
  };

  renderViewDriver(driverId, driverList) {
    if (driverId === null) {
      return '-';
    }

    const driver = driverList.filter(item => item._id === driverId);
    if (driver.length > 0) {
      return `${driver[0].driver_name}, ${driver[0].driver_phone_code} ${driver[0].driver_phone_number}`;
    }
  }

  renderViewTruck(userSettingTruckId, userTruckList) {
    if (userSettingTruckId === null) {
      return '-';
    }

    const truck = userTruckList.filter(item => item._id === userSettingTruckId);
    if (truck.length > 0) {
      return `${truck[0].user_setting_truck_license_plate}`;
    }
  }

  handleChangeReject(event) {
    this.setState({
      rejectSelected: event,
      rejectRemark: event.value,
    });

    if (event.value === 'อื่นๆ') {
      this.setState({ isShowRejectDetail: true });
    } else {
      this.setState({ isShowRejectDetail: false });
    }
  }

  handleCancelShipment = async (shipmentID, shipmentsUpdate) => {
    try {
      this.setState({ isLoading: true });
      const { shipmentID } = this.state;
      const { data } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: shipmentID,
        },
        fetchPolicy: 'network-only',
      });

      const { shipment } = data.shipmentList;
      if (
        shipment[0].shipment_status === 4 ||
        shipment[0].shipment_status === 5 ||
        shipment[0].shipment_status === 8
      ) {
        this.setState({ isLoading: false, isOpenModalConfirmCancel: false });
        alert('ไม่สามารถยกเลิกรายการได้');
        return;
      }

      shipmentsUpdate();
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmCancel: false,
      });

      alert(error);
    }
  };

  handleAssignDriver = shipment => {
    const { _id, shipment_number, driver_user_id, user_setting_truck_id } = shipment;
    const shipmentNumber = shipment_number;

    if (driver_user_id) {
      this.setState(
        {
          isOpenModalAssignDriver: true,
          shipmentID: _id,
          shipmentNumber: shipmentNumber,
          driverUserId: driver_user_id,
          userSettingTruckId: user_setting_truck_id,
          driverSelected: `${shipment.driver_name} (0${shipment.driver_phone_number})`,
          truckSelected: shipment.user_setting_truck_license_plate,
          agentSelected: {
            value: shipment.transporter,
            label: shipment.transporter_name,
          },
          agentId: shipment.transporter || null,
          lazadaImportOrderId: shipment.lazada_import_order_id,
        },
        () => this.fetchDataLazadaImportOrderList()
      );
    } else {
      this.setState(
        {
          isOpenModalAssignDriver: true,
          shipmentID: _id,
          shipmentNumber: shipmentNumber,
          driverUserId: driver_user_id,
          userSettingTruckId: user_setting_truck_id,
          driverSelected: '',
          truckSelected: '',
          agentSelected: {
            value: shipment.transporter,
            label: shipment.transporter_name,
          },
          agentId: shipment.transporter || null,
          lazadaImportOrderId: shipment.lazada_import_order_id,
        },
        () => this.fetchDataLazadaImportOrderList()
      );
    }
  };

  setAgentData = async transporterId => {
    if (!transporterId) {
      return;
    }

    try {
      const { data: accountInfoListData } = await client.query({
        query: accountInfoListQuery,
        variables: {
          _id: transporterId,
        },
      });
      if (accountInfoListData.accountInfoList.account_info.length > 0) {
        const {
          _id,
          account_info_business_name,
        } = accountInfoListData.accountInfoList.account_info[0];

        this.setState({
          agentId: _id,
          agentSelected: { value: _id, label: account_info_business_name },
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  handleAssignAgent = (shipment, agentList) => {
    const { _id, shipment_number, transporter } = shipment;
    const shipmentNumber = shipment_number;
    const agent = agentList.filter(item => item._id === transporter);
    this.clearDataSelectAgent();

    if (agent.length > 0) {
      this.setState({
        isOpenModalAssignAgent: true,
        shipmentID: _id,
        shipmentNumber: shipmentNumber,
        agentSelected: { value: agent[0]._id, label: agent[0].account_info_business_name },
      });
    } else {
      this.setState({
        isOpenModalAssignAgent: true,
        shipmentID: _id,
        shipmentNumber: shipmentNumber,
        agentId: transporter,
      });
    }
  };

  selectOptionDriverList = driverList => {
    return driverList.map(driver => {
      return {
        value: driver._id,
        label:
          driver.driver_name +
          ' (' +
          driver.driver_phone_code +
          '-' +
          driver.driver_phone_number +
          ')',
        user_setting_truck_id: driver.user_setting_truck_id,
        user_setting_truck_license_plate: driver.user_setting_truck_license_plate,
      };
    });
  };

  selectOptionTruckList = userTruckList => {
    return userTruckList.map(truck => {
      return {
        value: truck._id,
        label: truck.user_setting_truck_license_plate,
      };
    });
  };

  selectOptionAgentList = agentList => {
    return agentList.map(agent => {
      return {
        value: agent._id,
        label: agent.account_info_business_name,
      };
    });
  };

  handleChangeDriver(driver, driverList) {
    this.setState(
      {
        driverSelected: driver,
        driverUserId: driver.value,
        truckSelected: {
          value: driver.user_setting_truck_id,
          label: driver.user_setting_truck_license_plate,
        },
        userSettingTruckId: driver.user_setting_truck_id,
      },
      () => {
        this.clearDataSelectAgent();
        const driverFilter = driverList.filter(item => item._id === driver.value);
        this.setAgentData(driverFilter[0].account_info_id);
      }
    );
  }

  handleChangeAgent = agent => {
    console.log('handleChangeAgent', agent);
    this.setState({ agentSelected: agent, agentId: agent.value });
  };

  handleChangeTruck(truck) {
    this.setState({ truckSelected: truck, userSettingTruckId: truck.value });
  }

  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
        },
      });

      const driverUserListData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: e.driver_name,
        };
      });

      this.setState({
        driverUserListSuggestions: driverUserListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      driverUserListSuggestions: [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  downloadReportHandler = async () => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const { idBookingFile } = this.state;
      const adminId = admin[0]._id;
      const url = `${reportUrl}/lazada-summary?id=${idBookingFile}&admin_id=${adminId}`;
      window.open(url, '_blank');
      this.setState({
        isOpenModalConfirmDownloadReport: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  formatMoney = number => {
    return `฿ ${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;
  };

  formatNumber = number => {
    return `${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    })}`;
  };

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  clearDataSelectAgent = () => {
    this.setState({
      agentSelected: null,
      agentId: null,
    });
  };

  //Autosuggest Driver
  getSuggestionsDriver = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
        },
      });

      const driverData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: `${e.driver_name} (0${e.driver_phone_number})`,
          user_setting_truck_id: e.user_setting_truck_id ? e.user_setting_truck_id : '',
          user_setting_truck_license_plate: e.user_setting_truck_license_plate
            ? e.user_setting_truck_license_plate
            : '',
          driver_name: e.driver_name || '',
          driver_phone: e.driver_phone_number || '',
          driver_phone_code: e.driver_phone_code || '',
          setting_truck_id: e.setting_truck_service[0] || '',
          driver_agent_detail: {
            account_info_id: e.driver_agent_detail[0]?.account_info_id || '',
            agent_name: e.driver_agent_detail[0]?.agent_name || '',
          },
        };
      });

      this.setState({
        DriverSuggestions: driverData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueDriver = suggestion => suggestion.name;

  renderSuggestionDriver = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedDriver = ({ value }) => {
    this.getSuggestionsDriver(value);
  };

  onSuggestionsClearRequestedDriver = () => {
    this.setState({
      DriverSuggestions: [],
    });
  };

  shouldRenderSuggestionsDriver() {
    return true;
  }
  //End Autosuggest Driver

  //Autosuggest Truck
  getSuggestionsTruck = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: userTruckListQuery,
        fetchPolicy: 'network-only',
        variables: {
          user_setting_truck_license_plate: inputValue,
        },
      });

      console.log('userSettingTruckList', data.userSettingTruckList.user_setting_truck);

      const driverData = data.userSettingTruckList.user_setting_truck.map(e => {
        return {
          id: e._id,
          name: e.user_setting_truck_license_plate,
        };
      });

      this.setState({
        TruckSuggestions: driverData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTruck = suggestion => suggestion.name;

  renderSuggestionTruck = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTruck = ({ value }) => {
    this.getSuggestionsTruck(value);
  };

  onSuggestionsClearRequestedTruck = () => {
    this.setState({
      TruckSuggestions: [],
    });
  };

  shouldRenderSuggestionsTruck() {
    return true;
  }
  //End Autosuggest Truck

  getIdFromUrl = () => {
    return urijs(window.location.href).query(true).id;
  };

  renderStatusMode = status => {
    switch (status) {
      case 0:
        return <span class="label label-success">Trip LOY</span>;
      case 1:
        return <span class="label label-success">Last Mile (LM)</span>;
      case 2:
        return <span class="label label-danger">Return to Merchant (RM)</span>;
      case 3:
        return (
          <span class="label" style={{ backgroundColor: '#318DE8' }}>
            FIRST MILE (FM) (Import)
          </span>
        );
      case 4:
        return (
          <span class="label" style={{ backgroundColor: '#FFDB46', color: 'black' }}>
            SPECIAL (SP)
          </span>
        );
      case 5:
        return (
          <span class="label" style={{ backgroundColor: '#ff9800' }}>
            Return (RT)
          </span>
        );
      case 6:
        return (
          <span class="label" style={{ backgroundColor: '#82c5b9' }}>
            Return to WH (RTWH)
          </span>
        );
      case 7:
        return (
          <span class="label" style={{ backgroundColor: '#82c5f9' }}>
            Return to Customer (CR/RC)
          </span>
        );
      case 8:
        return (
          <span class="label" style={{ backgroundColor: '#FF0505' }}>
            Return to Merchant (CR/RM)
          </span>
        );
      case 9:
        return (
          <span class="label" style={{ backgroundColor: '#d6949e' }}>
            First Mile (FM)
          </span>
        );
      case 10:
        return (
          <span class="label" style={{ backgroundColor: '#bcddfd', color: '#318de8' }}>
            Shuttle Haul (SH)
          </span>
        );
      case 11:
        return (
          <span class="label" style={{ backgroundColor: '#ffe2bb', color: '#e28200' }}>
            Reject Return (RR)
          </span>
        );
      case 12:
        return (
          <span class="label" style={{ backgroundColor: '#d9e2ff', color: '#318DE8' }}>
            Direct Ship (DS)
          </span>
        );
      case 13:
        return (
          <span class="label" style={{ backgroundColor: '#FFDB46', color: 'black' }}>
            Line Haul (LH)
          </span>
        );
      case 14:
        return (
          <span class="label" style={{ backgroundColor: '#9933ff', color: '#FFFFFF' }}>
            BU First Mile (BUFM)
          </span>
        );
      default:
        return status;
    }
  };

  renderStatusStage = (status, confirm_cod_status, confirm_delivery_status) => {
    {
      // # stage: "WAITING" /** รอเริ่มงาน */
      // # stage: "INPROGRESS" /** กำลังดำเนินงาน */
      // # stage: "WAITINGCONFIRM" /** รอส่งสถานะ */
      // stage: "WAITINGCOD" /** รอส่ง cod */
      // # stage: "COMPLETED" /** เสร็จสิ้น */
      // # stage: "CANCLE" /** ยกเลิก */
      // # shipment_trip: "LZD_LH_28012021_1-01"
    }

    switch (this.state.stage) {
      case 'WAITING':
        // <Tag
        //   style={{
        //     backgroundColor: '#000',
        //     color: '#fff',
        //     width: 'max-content',
        //   }}
        // >
        //   รอเริ่มงาน
        // </Tag>
        switch (status) {
          case 2:
            return (
              <Tag
                style={{
                  backgroundColor: '#000',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                รอขนส่งรับงาน
              </Tag>
            );
          case 10:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                รอเริ่มงาน
              </Tag>
            );

          case 3:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                กำลังดำเนินการขนส่ง
              </Tag>
            );
          case 8:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                นัดหมายลูกค้าแล้ว
              </Tag>
            );
          case 9:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                กำลังคืนคลัง
              </Tag>
            );

          case 4:
            return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
          case 5:
            return <Label bsStyle="danger">ถูกยกเลิก</Label>;
          case 6:
            return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
          case 7:
            return <Label bsStyle="default">แบบร่าง</Label>;
          default:
            return '';
        }
      case 'INPROGRESS':
        // <Tag
        //   style={{
        //     backgroundColor: '#318DE8',
        //     color: '#fff',
        //     width: 'max-content',
        //   }}
        // >
        //   กำลังดำเนินงาน
        // </Tag>
        switch (status) {
          case 2:
            return (
              <Tag
                style={{
                  backgroundColor: '#000',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                รอขนส่งรับงาน
              </Tag>
            );
          case 10:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                รอเริ่มงาน
              </Tag>
            );

          case 3:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                กำลังดำเนินการขนส่ง
              </Tag>
            );
          case 8:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                นัดหมายลูกค้าแล้ว
              </Tag>
            );
          case 9:
            return (
              <Tag
                style={{
                  backgroundColor: '#318DE8',
                  color: '#fff',
                  width: 'max-content',
                }}
              >
                กำลังคืนคลัง
              </Tag>
            );

          case 4:
            return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
          case 5:
            return <Label bsStyle="danger">ถูกยกเลิก</Label>;
          case 6:
            return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
          case 7:
            return <Label bsStyle="default">แบบร่าง</Label>;
          default:
            return '';
        }

      case 'WAITINGCONFIRM':
        return (
          <Tag
            style={{
              backgroundColor: '#318DE8',
              color: '#fff',
              width: 'max-content',
            }}
          >
            รอยืนยันสถานะ
          </Tag>
        );
      case 'WAITINGCOD':
        return (
          <Tag
            style={{
              backgroundColor: '#E28200',
              color: '#fff',
              width: 'max-content',
            }}
          >
            รอจ่าย COD
          </Tag>
        );
      case 'COMPLETED':
        return (
          <Tag
            style={{
              backgroundColor: '#50B35C',
              color: '#fff',
              width: 'max-content',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              padding: '2px 5px',
            }}
          >
            <i style={{ fontSize: '14px' }} className="fas fa-check-circle"></i>
            เสร็จสิ้น
          </Tag>
        );
      case 'CANCLE':
        return (
          <Tag
            style={{
              backgroundColor: 'red',
              color: '#fff',
              width: 'max-content',
              display: 'flex',
              gap: '5px',
              alignItems: 'center',
              padding: '2px 5px',
            }}
          >
            <i style={{ fontSize: '14px' }} className="fas fa-ban"></i>
            ถูกยกเลิก
          </Tag>
        );
      default:
        if (confirm_delivery_status == 1 || !confirm_delivery_status) {
          switch (status) {
            case 2:
              return (
                <Tag
                  style={{
                    backgroundColor: '#000',
                    color: '#fff',
                    width: 'max-content',
                  }}
                >
                  รอขนส่งรับงาน
                </Tag>
              );
            case 10:
              return (
                <Tag
                  style={{
                    backgroundColor: '#318DE8',
                    color: '#fff',
                    width: 'max-content',
                  }}
                >
                  รอเริ่มงาน
                </Tag>
              );

            case 3:
              return (
                <Tag
                  style={{
                    backgroundColor: '#318DE8',
                    color: '#fff',
                    width: 'max-content',
                  }}
                >
                  กำลังดำเนินการขนส่ง
                </Tag>
              );
            case 8:
              return (
                <Tag
                  style={{
                    backgroundColor: '#318DE8',
                    color: '#fff',
                    width: 'max-content',
                  }}
                >
                  นัดหมายลูกค้าแล้ว
                </Tag>
              );
            case 9:
              return (
                <Tag
                  style={{
                    backgroundColor: '#318DE8',
                    color: '#fff',
                    width: 'max-content',
                  }}
                >
                  กำลังคืนคลัง
                </Tag>
              );

            case 4:
              return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
            case 5:
              return (
                <Tag
                  style={{
                    backgroundColor: 'red',
                    color: '#fff',
                    width: 'max-content',
                    display: 'flex',
                    gap: '5px',
                    alignItems: 'center',
                    padding: '2px 5px',
                  }}
                >
                  <i style={{ fontSize: '14px' }} className="fas fa-ban"></i>
                  ถูกยกเลิก
                </Tag>
              );
            case 6:
              return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
            case 7:
              return <Label bsStyle="default">แบบร่าง</Label>;
            default:
              return '';
          }
        }
        if (confirm_delivery_status == 2) {
          return (
            <Tag
              style={{
                backgroundColor: '#318DE8',
                color: '#fff',
                width: 'max-content',
              }}
            >
              รอยืนยันสถานะ
            </Tag>
          );
        } else if (confirm_cod_status == 2) {
          return (
            <Tag
              style={{
                backgroundColor: '#E28200',
                color: '#fff',
                width: 'max-content',
              }}
            >
              รอจ่าย COD
            </Tag>
          );
        } else if (confirm_cod_status == 3) {
          return (
            <Tag
              style={{
                backgroundColor: '#50B35C',
                color: '#fff',
                width: 'max-content',
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
                padding: '2px 5px',
              }}
            >
              <i style={{ fontSize: '14px' }} className="fas fa-check-circle"></i>
              เสร็จสิ้น
            </Tag>
          );
        } else return null;
    }
  };

  render() {
    let driverList = [];
    if (
      this.props.driverUserListQuery.loading === false &&
      this.props.driverUserListQuery.driverUserList !== undefined
    ) {
      driverList = this.props.driverUserListQuery.driverUserList.driver_user;
    }

    let userTruckList = [];
    if (
      this.props.userTruckListQuery.loading === false &&
      this.props.userTruckListQuery.userSettingTruckList !== undefined
    ) {
      userTruckList = this.props.userTruckListQuery.userSettingTruckList.user_setting_truck;
    }

    let agentList = [];
    if (
      this.props.accountInfoListQuery.loading === false &&
      this.props.accountInfoListQuery.accountInfoList !== undefined
    ) {
      agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
    }

    return (
      <div className="content">
        <Query
          query={shipmentKaList}
          variables={{
            params: {
              stage: this.state.stage,
              shipment_status:
                this.state.stage == 'WAITING' || this.state.stage == 'INPROGRESS'
                  ? this.state.shipmentStatus
                  : 0,
              transporter: this.state.transporterId,
              shipment_trip: this.state.tripNumber,
              format_bookname: this.state.shipmentName,
              pick_up_date: this.state.pickupDate,
              create_date: this.state.createDate,
              lazada_order_type: this.state.lazada_order_type,
              commodity_name: this.state.trackingNumber,
              lazada_import_order_id: this.state.lazadaImportOrderIdUrl,
            },
            sort: {
              order_by: this.state.order_by,
              order_type: this.state.order_type,
            },
            page: this.state.pageNumber,
            showData: 30,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error, refetch }) => {
            let shipmentList = [];
            let pickupDate = '';
            let totalPage = 0;

            if (!loading && !error) {
              shipmentList = [...data.shipmentKAs.shipments];
              totalPage = data.shipmentKAs.totalPage;
            }

            var itemLength = 'loading';
            const itemIndicatorSize = (this.state.pageNumber - 1) * 30;
            if (data.shipmentKAs) {
              if (data.shipmentKAs.shipments.length > 0) {
                itemLength = data.shipmentKAs.shipments.length;
              }
            }

            return (
              <>
                <Layout noMenuTab={this.getIdFromUrl()} />
                {
                  // # stage: "WAITING" /** รอเริ่มงาน */
                  // # stage: "INPROGRESS" /** กำลังดำเนินงาน */
                  // # stage: "WAITINGCONFIRM" /** รอส่งสถานะ */
                  // stage: "WAITINGCOD" /** รอส่ง cod */
                  // # stage: "COMPLETED" /** เสร็จสิ้น */
                  // # stage: "CANCLE" /** ยกเลิก */
                  // # shipment_trip: "LZD_LH_28012021_1-01"
                }
                {!this.getIdFromUrl() ? (
                  <Query query={shipmentKAStatus} fetchPolicy="cache-and-network">
                    {({ data, loading }) => {
                      return (
                        <SubHeader
                          menus={[
                            {
                              title: 'ทั้งหมด',
                              stage: 'ALL',
                              num: data.shipmentKAStatus ? data.shipmentKAStatus.ALL || 0 : 0,
                              icon: 'fas fa-shipping-fast',
                            },
                            {
                              title: 'รอเริ่มงาน',
                              stage: 'WAITING',
                              num: data.shipmentKAStatus ? data.shipmentKAStatus.WAITING || 0 : 0,
                              icon: 'fas fa-shipping-timed',
                            },
                            {
                              title: 'กำลังดำเนินงาน',
                              stage: 'INPROGRESS',
                              num: data.shipmentKAStatus
                                ? data.shipmentKAStatus.INPROGRESS || 0
                                : 0,
                              icon: 'fas fa-shipping-fast',
                            },
                            {
                              title: 'รอส่ง COD',
                              stage: 'WAITINGCOD',
                              num: data.shipmentKAStatus
                                ? data.shipmentKAStatus.WAITINGCOD || 0
                                : 0,
                              icon: 'fas fa-box-usd',
                            },
                            {
                              title: 'รอส่ง CT',
                              stage: 'WAITINGCREATECOST',
                              num: data.shipmentKAStatus
                                ? data.shipmentKAStatus.WAITINGCREATECOST || 0
                                : 0,
                              icon: 'fas fa-box-usd',
                            },
                            {
                              title: 'เสร็จสิ้น',
                              stage: 'COMPLETED',
                              num: data.shipmentKAStatus ? data.shipmentKAStatus.COMPLETED || 0 : 0,
                              icon: 'fas fa-check-circle',
                            },
                            {
                              title: 'ถูกยกเลิก',
                              stage: 'CANCLE',
                              num: data.shipmentKAStatus ? data.shipmentKAStatus.CANCLE || 0 : 0,
                              icon: 'fas fa-ban',
                            },
                          ]}
                          stage={this.state.stage}
                          selectedState={stage => {
                            refetchQueries(['shipmentKAStatus']);
                            this.setState({
                              stage: stage,
                              pageNumber: 1,
                              shipmentStatus: 0,
                              order_by: stage == 'COMPLETED' ? 'shipment_ka_compeleted_date' : null,
                              selectedViewBy: 'shipment',
                            });
                          }}
                        />
                      );
                    }}
                  </Query>
                ) : (
                  <HeaderLayoutWithBackUrl backUrl="/ka" />
                )}

                <Grid fluid>
                  <div>
                    <Row>
                      <Col md={12}>
                        <div>
                          <CardTable
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                              <div>
                                <div
                                  style={{
                                    display: this.state.stage !== 'WAITINGCOD' ? 'none' : 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: 20,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div>View By: </div>
                                    <Button
                                      style={{
                                        marginLeft: 10,
                                        borderRadius: 20,
                                        backgroundColor: '#F4F4F4',
                                        borderColor: 'transparent',
                                        color: '#808790',
                                        ...(this.state.selectedViewBy === 'shipment' && {
                                          backgroundColor: 'transparent',
                                          borderColor: '#3377B7',
                                          color: '#3377B7',
                                        }),
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          selectedViewBy: 'shipment',
                                        });
                                      }}
                                    >
                                      Shipment / Trip No.
                                    </Button>
                                    <Button
                                      style={{
                                        marginLeft: 10,
                                        borderRadius: 20,
                                        backgroundColor: '#F4F4F4',
                                        borderColor: 'transparent',
                                        color: '#808790',
                                        ...(this.state.selectedViewBy === 'tracking' && {
                                          backgroundColor: 'transparent',
                                          borderColor: '#3377B7',
                                          color: '#3377B7',
                                        }),
                                      }}
                                      onClick={() => {
                                        this.setState({
                                          selectedViewBy: 'tracking',
                                        });
                                      }}
                                    >
                                      Tracking No.
                                    </Button>
                                  </div>
                                </div>

                                {this.state.selectedViewBy === 'shipment' && (
                                  <>
                                    <PageWrapper style={{ textAlign: 'right' }}>
                                      <div className="text">
                                        {this.state.pageNumber === 1
                                          ? `1 - ${itemLength}`
                                          : `${itemIndicatorSize + 1} - ${itemLength +
                                              itemIndicatorSize}`}{' '}
                                        หน้า :
                                      </div>
                                      <FormControl
                                        type="number"
                                        min={1}
                                        value={this.state.pageNumber}
                                        onChange={event =>
                                          Number(event.target.value) >= 0
                                            ? setInterval(
                                                this.setState({
                                                  pageNumber: Number(event.target.value),
                                                }),
                                                1000
                                              )
                                            : setInterval(this.setState({ pageNumber: 1 }), 1000)
                                        }
                                        name="page_number"
                                      />
                                      <div className="text">
                                        {' '}
                                        {loading || error
                                          ? ''
                                          : `จาก ${data.shipmentKAs.totalPage}`}
                                      </div>
                                    </PageWrapper>
                                    {this.state.stage == 'WAITINGCOD' && (
                                      <Query
                                        query={shipmentKaTotalCod}
                                        variables={{
                                          params: {
                                            shipment_status:
                                              this.state.stage == 'WAITING' ||
                                              this.state.stage == 'INPROGRESS'
                                                ? this.state.shipmentStatus
                                                : 0,
                                            transporter: this.state.transporterId,
                                            shipment_trip: this.state.tripNumber,
                                            format_bookname: this.state.shipmentName,
                                            pick_up_date: this.state.pickupDate,
                                            create_date: this.state.createDate,
                                            lazada_order_type: this.state.lazada_order_type,
                                            commodity_name: this.state.trackingNumber,
                                          },
                                        }}
                                        fetchPolicy="cache-and-network"
                                      >
                                        {({ data, loading }) => {
                                          let totalCodKas = 0;
                                          if (
                                            data &&
                                            data.shipmentKaTotalCod &&
                                            data.shipmentKaTotalCod.cod_price
                                          ) {
                                            totalCodKas = data.shipmentKaTotalCod.cod_price;
                                          }
                                          return (
                                            <b style={{ fontSize: '24px' }}>
                                              ยอด COD ทั้งหมด :{' '}
                                              {loading ? 'loading' : this.formatNumber(totalCodKas)}
                                            </b>
                                          );
                                        }}
                                      </Query>
                                    )}
                                    <div className="table-vertical">
                                      <ShipmentTable
                                        loading={loading}
                                        error={error}
                                        data={shipmentList}
                                        //
                                        handleDateRangePicker={this.handleDateRangePicker}
                                        //
                                        renderStatus={this.renderStatusStage}
                                        //
                                        formatMoney={this.formatMoney}
                                        agentList={agentList}
                                        handleAssignAgent={this.handleAssignAgent}
                                        handleAssignDriver={this.handleAssignDriver}
                                        //
                                        shipmentStatus={this.state.shipmentStatus}
                                        //
                                        stage={this.state.stage}
                                        //
                                        searchShipmentStatus={event => {
                                          refetchQueries(['shipmentKAStatus']);
                                          this.setState({
                                            shipmentStatus: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        //
                                        shipmentType={this.state.lazada_order_type}
                                        //
                                        searchShipmentType={event =>
                                          this.setState({
                                            lazada_order_type: parseInt(event.target.value),
                                            pageNumber: 1,
                                          })
                                        }
                                        //
                                        shipmentNumber={this.state.tripNumber}
                                        //
                                        searchShipmentNumber={event => {
                                          this.setState({
                                            tripNumber: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        //
                                        bookingNumber={this.state.shipmentName}
                                        //
                                        searchBookingNumber={event => {
                                          this.setState({
                                            shipmentName: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        //
                                        displayPickupDate={this.state.displayPickupDate}
                                        //
                                        cancelDisplayPickupDate={() =>
                                          this.setState({
                                            pickupDate: '',
                                            displayPickupDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        displayCreateDate={this.state.displayCreateDate}
                                        cancelDisplayCreateDate={() =>
                                          this.setState({
                                            createDate: '',
                                            displayCreateDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        tplName={''}
                                        searchTplName={() => console.log('change')}
                                        //
                                        renderStatusMode={this.renderStatusMode}
                                        restoreShipment={item =>
                                          this.setState({
                                            isShowClearShipmentModal: true,
                                            shipmentTripClearModal: item.shipment_trip,
                                            shipmentIdClearModal: item._id,
                                            agentIdClearModal: item.transporter,
                                          })
                                        }
                                        cancelShipment={item => {
                                          this.setState({
                                            isOpenModalConfirmCancel: true,
                                            shipmentID: item._id,
                                            shipmentNumber: item.shipment_number,
                                            rejectSelected: null,
                                            rejectRemark: '',
                                            rejectDetail: '',
                                          });
                                        }}
                                        onChangeTransporter={id =>
                                          this.setState({
                                            transporterId: id,
                                          })
                                        }
                                        trackingNumber={this.state.trackingNumber}
                                        searchTrackingNumber={event =>
                                          this.setState({ trackingNumber: event.target.value })
                                        }
                                      />
                                    </div>
                                    <div style={{ textAlign: 'right', marginTop: 20 }}>
                                      {!loading && !error && (
                                        <ReactPaginate
                                          pageCount={totalPage}
                                          forcePage={this.state.pageNumber - 1}
                                          containerClassName="pagination"
                                          nextLabel=">"
                                          previousLabel="<"
                                          onPageChange={({ selected }) => {
                                            this.setState({
                                              pageNumber: selected + 1,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                )}

                                {this.state.selectedViewBy === 'tracking' && (
                                  <TrackingTable
                                    isHideCheckbox
                                    selectedTracking={this.state.selectedTracking}
                                  />
                                )}
                              </div>
                            }
                          />
                        </div>

                        <Mutation
                          mutation={assignAdminShipment}
                          variables={{
                            shipmentID: this.state.shipmentID,
                            admin_name: this.state.adminName,
                          }}
                          update={() => {
                            this.setState({ isOpenModalAssignAdmin: false });
                          }}
                          refetchQueries={() => ['shipmentKAs', 'shipmentKAStatus']}
                        >
                          {assignAdminShipment => (
                            <Modal
                              show={this.state.isOpenModalAssignAdmin}
                              onHide={() => this.setState({ isOpenModalAssignAdmin: false })}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>รับดูแลงาน {this.state.shipmentNumber}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>กด "ตกลง" เพื่อรับดูแลงานนี้</Modal.Body>
                              <Modal.Footer>
                                <Button
                                  style={{ float: 'left' }}
                                  onClick={() =>
                                    this.setState({
                                      isOpenModalAssignAdmin: false,
                                    })
                                  }
                                >
                                  กลับ
                                </Button>
                                <Button bsStyle="primary" onClick={() => assignAdminShipment()}>
                                  ตกลง
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>

                        <Mutation
                          mutation={shipmentsUpdateMutation}
                          variables={{
                            _id: this.state.shipmentID,
                            shipment_status: 5,
                            cancel_comment:
                              this.state.rejectRemark === 'อื่นๆ'
                                ? this.state.rejectDetail
                                : this.state.rejectRemark,
                          }}
                          update={() => {
                            this.setState({
                              isLoading: false,
                              isOpenModalConfirmCancel: false,
                            });
                          }}
                          refetchQueries={() => ['shipmentKAs', 'shipmentKAStatus']}
                        >
                          {shipmentsUpdate => (
                            <Modal
                              show={this.state.isOpenModalConfirmCancel}
                              onHide={() =>
                                this.setState({
                                  isOpenModalConfirmCancel: false,
                                })
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  ยกเลิกงานขนส่ง {this.state.shipmentNumber}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col md={12}>
                                    <b>
                                      เหตุผลที่ยกเลิกงาน{' '}
                                      <small style={{ color: '#D90101' }}>*</small>
                                    </b>
                                  </Col>
                                </Row>

                                <Select
                                  value={this.state.rejectSelected}
                                  onChange={event => this.handleChangeReject(event)}
                                  options={rejectList}
                                  placeholder="เลือกเหตุผลที่ยกเลิกงาน"
                                />

                                {this.state.isShowRejectDetail && (
                                  <FormControl
                                    style={{ marginTop: '10px' }}
                                    type="text"
                                    value={this.state.rejectDetail}
                                    placeholder="ระบุ"
                                    onChange={event =>
                                      this.setState({
                                        rejectDetail: event.target.value,
                                      })
                                    }
                                  />
                                )}

                                <Row style={{ marginTop: '10px' }}>
                                  <Col md={12}>คุณต้องการยกเลิกงานขนส่งนี้ให้กด "ตกลง"</Col>
                                </Row>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  style={{ float: 'left' }}
                                  onClick={() =>
                                    this.setState({
                                      isOpenModalConfirmCancel: false,
                                    })
                                  }
                                >
                                  กลับ
                                </Button>
                                <Button
                                  bsStyle="primary"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.rejectRemark === '' ||
                                    (this.state.rejectRemark === 'อื่นๆ' &&
                                      this.state.rejectDetail === '')
                                  }
                                  onClick={() =>
                                    this.handleCancelShipment(
                                      this.state.shipmentID,
                                      shipmentsUpdate
                                    )
                                  }
                                >
                                  ตกลง
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>

                        <Mutation
                          mutation={assignAgentMutation}
                          variables={{
                            shipment_id: this.state.shipmentID,
                            driver_user_id: this.state.driverUserId,
                            transporter: this.state.agentId,
                          }}
                          update={() => {
                            this.setState({ isOpenModalAssignDriver: false });
                          }}
                          refetchQueries={() => ['shipmentKAs', 'shipmentKAStatus']}
                        >
                          {assignAgent => (
                            <Mutation
                              mutation={assignDriverMutation}
                              variables={{
                                shipment_id: this.state.shipmentID,
                                driver_user_id: this.state.driverUserId,
                                user_setting_truck_id: this.state.userSettingTruckId,
                                transporter: this.state.agentId,
                                lazada_import_order_id: this.state.lazadaImportOrderId,
                                book_number: this.state.lazadaImportOrderNumber,
                                driver_name: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.driver_name
                                  : '',
                                driver_phone: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.driver_phone
                                  : '',
                                driver_phone_code: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.driver_phone_code
                                  : '',
                                name_agent: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.agent_name
                                  : this.state.agentId
                                  ? this.state.agentSelected.label
                                  : '',
                                truck_license: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.user_setting_truck_license_plate
                                  : '',
                                truck_type_id: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.setting_truck_id
                                  : '',
                              }}
                              update={() => {
                                assignAgent();
                              }}
                              refetchQueries={() => ['shipmentKAs', 'shipmentKAStatus']}
                            >
                              {assignDriver => (
                                <Modal
                                  show={this.state.isOpenModalAssignDriver}
                                  onHide={() =>
                                    this.setState({
                                      isOpenModalAssignDriver: false,
                                    })
                                  }
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      แก้ไขคนขับรถ {this.state.shipmentNumber}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Row style={{ marginBottom: '10px' }}>
                                      <Col md={12}>คนขับรถ</Col>
                                    </Row>

                                    <AutosuggestContainer>
                                      <Autosuggest
                                        suggestions={this.state.DriverSuggestions}
                                        onSuggestionsFetchRequested={
                                          this.onSuggestionsFetchRequestedDriver
                                        }
                                        onSuggestionsClearRequested={
                                          this.onSuggestionsClearRequestedDriver
                                        }
                                        getSuggestionValue={this.getSuggestionValueDriver}
                                        renderSuggestion={this.renderSuggestionDriver}
                                        focusInputOnSuggestionClick={false}
                                        inputProps={{
                                          placeholder: 'เลือกคนขับรถ',
                                          value: this.state.driverSelected,
                                          onChange: event =>
                                            this.setState({
                                              driverSelected: event.target.value,
                                              driverUserId: null,
                                            }),
                                          type: 'text',
                                          className: 'form-control',
                                        }}
                                        onSuggestionSelected={(event, { suggestion }) => {
                                          if (this.state.agentId != null) {
                                            this.setState({
                                              driverUserId: suggestion.id,
                                              driverSelected: suggestion.name,
                                              userSettingTruckId: suggestion.user_setting_truck_id,
                                              truckSelected:
                                                suggestion.user_setting_truck_license_plate,
                                              driverUserSelected: suggestion,
                                            });
                                          } else {
                                            this.setState({
                                              driverUserId: suggestion.id,
                                              driverSelected: suggestion.name,
                                              userSettingTruckId: suggestion.user_setting_truck_id,
                                              truckSelected:
                                                suggestion.user_setting_truck_license_plate,
                                              driverUserSelected: suggestion,
                                              agentSelected: {
                                                label: suggestion.driver_agent_detail.agent_name,
                                                value:
                                                  suggestion.driver_agent_detail.account_info_id,
                                              },
                                              agentId:
                                                suggestion.driver_agent_detail.account_info_id,
                                            });
                                          }
                                        }}
                                        shouldRenderSuggestions={this.shouldRenderSuggestionsDriver}
                                      />
                                    </AutosuggestContainer>

                                    <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                      <Col md={12}>ทะเบียนรถ</Col>
                                    </Row>
                                    <AutosuggestContainer>
                                      <Autosuggest
                                        suggestions={this.state.TruckSuggestions}
                                        onSuggestionsFetchRequested={
                                          this.onSuggestionsFetchRequestedTruck
                                        }
                                        onSuggestionsClearRequested={
                                          this.onSuggestionsClearRequestedTruck
                                        }
                                        getSuggestionValue={this.getSuggestionValueTruck}
                                        renderSuggestion={this.renderSuggestionTruck}
                                        focusInputOnSuggestionClick={false}
                                        inputProps={{
                                          placeholder: 'เลือกทะเบียนรถ',
                                          value: this.state.truckSelected,
                                          onChange: event =>
                                            this.setState({
                                              truckSelected: event.target.value,
                                              userSettingTruckId: null,
                                            }),
                                          type: 'text',
                                          className: 'form-control',
                                        }}
                                        onSuggestionSelected={(event, { suggestion }) => {
                                          this.setState({
                                            userSettingTruckId: suggestion.id,
                                            truckSelected: suggestion.name,
                                          });
                                        }}
                                        shouldRenderSuggestions={this.shouldRenderSuggestionsTruck()}
                                      />
                                    </AutosuggestContainer>

                                    <Row
                                      style={{
                                        marginBottom: '10px',
                                        marginTop: '20px',
                                      }}
                                    >
                                      <Col md={12}>นายหน้า</Col>
                                    </Row>

                                    <Select
                                      disabled={this.state.agentId != null}
                                      value={this.state.agentSelected}
                                      onChange={event => this.handleChangeAgent(event)}
                                      options={this.selectOptionAgentList(agentList)}
                                      placeholder="เลือกนายหน้า"
                                    />
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      bsStyle="primary"
                                      onClick={() => assignDriver()}
                                      disabled={
                                        !this.state.driverSelected ||
                                        !this.state.truckSelected ||
                                        !this.state.agentSelected
                                      }
                                    >
                                      บันทึก
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              )}
                            </Mutation>
                          )}
                        </Mutation>

                        <Mutation
                          mutation={assignAgentMutation}
                          variables={{
                            shipment_id: this.state.shipmentID,
                            driver_user_id: this.state.driverUserId,
                            transporter: this.state.agentId,
                          }}
                          update={() => {
                            this.setState({ isOpenModalAssignAgent: false });
                          }}
                          refetchQueries={() => ['shipmentKAs', 'shipmentKAStatus']}
                        >
                          {assignAgent => (
                            <Modal
                              show={this.state.isOpenModalAssignAgent}
                              onHide={() =>
                                this.setState({
                                  isOpenModalAssignAgent: false,
                                })
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>แก้ไขนายหน้า {this.state.shipmentNumber}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col md={12}>นายหน้า</Col>
                                </Row>

                                <Select
                                  value={this.state.agentSelected}
                                  onChange={event => this.handleChangeAgent(event)}
                                  options={this.selectOptionAgentList(agentList)}
                                  placeholder="เลือกนายหน้า"
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  disabled={!this.state.agentSelected}
                                  bsStyle="primary"
                                  onClick={() => assignAgent()}
                                >
                                  บันทึก
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>

                        <ModalConfirm
                          show={this.state.isOpenModalConfirmDownloadReport}
                          onPressButtonConfirm={this.downloadReportHandler}
                          styleColorButtonConfirm="primary"
                          labelButtonConfirm="ดาวน์โหลด"
                          labelButtonCancel="ยกเลิก"
                          onPressButtonCancel={() =>
                            this.setState({ isOpenModalConfirmDownloadReport: false })
                          }
                          titleHeader={`Download Report ใช่หรือไม่?`}
                          onHide={() => this.setState({ isOpenModalConfirmDownloadReport: false })}
                        >
                          <div style={{ lineHeight: 1 }}>
                            File Name: <strong>{this.state.fileNameBookingFile}</strong>
                          </div>
                        </ModalConfirm>

                        {this.state.isShowClearShipmentModal && (
                          <ClearShipmentDriverModal
                            showModal={this.state.isShowClearShipmentModal}
                            closeModal={() =>
                              this.setState({
                                isShowClearShipmentModal: false,
                                shipmentTripClearModal: '',
                                shipmentIdClearModal: '',
                                agentIdClearModal: '',
                              })
                            }
                            shipmentTrip={this.state.shipmentTripClearModal}
                            shipmentID={this.state.shipmentIdClearModal}
                            transporter={this.state.agentIdClearModal}
                            refetch={refetch}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Grid>
              </>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default compose(
  graphql(driverUserListQuery, { name: 'driverUserListQuery' }),
  graphql(userTruckListQuery, { name: 'userTruckListQuery' }),
  graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        account_info_type: 3,
      },
    }),
  })
)(ShipmentList);
