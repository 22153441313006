import gql from 'graphql-tag';

export default gql`
query searchCosting(
    $_id: ID!
  ){
    costing(
        _id :$_id
      ) {
        _id
        costing_number
        costing_status
        service_type
        system_request
        number_ref
        ref_id
        employee {
            _id
            department
            title
            first_name
            last_name
            full_name
            email
            phone
            create_date
            last_update
        }
        payee{
            _id
            number
            external_system_id
            title
            first_name
            last_name
            full_name
            email
            phone
            tax_id
            authorized_capital
            branch
            address
            sub_district
            district
            province
            postcode
            full_address
            address_location{
                lat
                lng
            }
            bank_account {
                bank_name
                bank_branch
                account_name
                account_number
            }
            create_date
            last_update
        }
        payer{
            _id
            from
            external_system_id
            number
            status
            business_invoice_type
            business_invoice_date_range{
                start
                end
            }
            payment_bank_account{
                bank_name
                bank_branch
                account_name
                account_number
            }
            business_pay_round {
                round_date
                after_invoice
            }
            first_name
            last_name
            full_name
            email
            phone
            tax_id
            authorized_capital
            branch
            address
            sub_district
            district
            province
            postcode
            full_address
            address_location{
                lat
                lng
            }
            create_date
            last_update
        }
        business_invoice_type
        service_date
        completed_date
        payment {
            _id
            payment_number
        }
        invoice {
            _id
            invoice_number
        }
        costing_list{
            datamaster_service_item_id
            service_category_name
            advance_type
            list_number
            list_name
            qty
            unit
            price {
                cost
                sale
            }
            discount {
                cost
                sale
            }
            vat {
                cost
                sale
            }
            total_vat {
                cost
                sale
            }
            wht {
                cost
                sale
            }
            total_wht {
                cost
                sale
            }
            total_before_discount {
                cost
                sale
            }
            total_price {
                cost
                sale
            }
            grand_total_price {
                cost
                sale
            }
        }
        re_total_before_discount {
                cost
                sale
            }
        non_total_before_discount {
                cost
                sale
            }
        inc_total_before_discount {
                cost
                sale
            }
        re_total_discount {
                cost
                sale
            }
        non_total_discount {
                cost
                sale
            }
        inc_total_discount {
                cost
                sale
            }
        re_total_after_discount {
                cost
                sale
            }
        non_total_after_discount {
                cost
                sale
            }
        inc_total_after_discount {
                cost
                sale
            }
        total_vat {
                cost
                sale
            }
        re_total {
                cost
                sale
            }
        non_total {
                cost
                sale
            }
        inc_total {
                cost
                sale
            }
        total_before_discount {
                cost
                sale
            }
        total_discount {
                cost
                sale
            }
        total_after_discount {
                cost
                sale
            }
        total_price {
                cost
                sale
            }
        profit
        net_profit
        all_wht {
            wht
            price {
                cost
                sale
            }
            total_wht {
                cost
                sale
            }
        }
        grand_total_price {
                cost
                sale
            }
        group_price {
            shipping_price {
                cost
                sale
            }
            service_price {
                cost
                sale
            }
            reserve_price {
                cost
                sale
            }
        }
        costing_remark
        reject_remark
        edit_request
        edit_request_status
        edit_request_remark
        send_history {
            timestamp
        }
        last_send
        checked_date
        create_date
        last_update
    }
  }
`;
