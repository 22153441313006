import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import { Input, Label, Tag, SelectBox, Tabs } from '../../components';
import { Formik } from 'formik';
import moment from 'moment';

import { client } from './../../../../';

// container
import CostingConfirm from '../CostingConfirm';
import DeletePaymentOrder from '../DeletePaymentOrder';

import PaymentList from './container/PaymentList';
import CostingDocument from './container/CostingDocument';

// graphql
import createCostingMutation from '../../graphql/mutation/createCosting';
import shipmentAdminListQuery from '../../graphql/query/shipmentAdminList';
import invoiceListQuery from '../../graphql/query/invoiceList';
import costingDocumentsQuery from '../../graphql/query/costingDocuments';
import costingQuery from '../../graphql/query/costing';

const ModalContainer = styled(Modal)`
  font-size: 20px;

  .modal-dialog {
    max-width: 1300px;
    width: 100%;
  }

  & .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .text-header {
      font-size: 24px;
      font-weight: bold;
    }

    & .button-header {
      display: flex;
      flex-direction: row;
    }
  }

  & .condition-payment-container {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;

    & .title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 50px;

      & .icon-title {
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        width: 36px;
        height: 26px;
        margin: 10px 10px 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .text-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
    }

    & .body-container {
      display: flex;
      flex-direction: row;
      font-size: 20px;

      & .condition-container {
        display: flex;
        flex-direction: row;

        & .column {
          width: 100%;
        }
        & .column1 {
          width: 35%;
        }
        & .column2 {
          width: 65%;
        }
      }
    }
  }

  & .detail-container {
    width: 100%;
    margin-top: 20px;
  }
`;

const TextHeader = styled.div`
  margin-bottom: 5;
  font-size: 20;
  margin-top: 0;
  float: left;
  width: 100%;
`;

const Title = styled.div`
  font-size: 20;
  font-weight: bold;
  margin-top: 0;
  float: left;
`;

const RejectRemark = styled.div`
    color: #e15051;
    margin-top: 10px;
    font-size: 18px;
`;

const IconContainer = styled.div`
  width: 40px;
  float: left;
  & i {
    font-size: 30px;
  }
`;

const TextContainer = styled.div`
  float: left;
  width: calc(100% - 40px);
  font-size: 16px;
`;

const ButtonRed = styled(Button)`
  background-color: #d91010 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bold !important;
`;

const ButtonBlue = styled(Button)`
  background-color: #3377b7 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bold !important;
`;

const ButtonDefault = styled(Button)`
  font-size: 20px !important;
  font-weight: bold !important;
`;

const LineVertical = styled.div`
  border-left: 1px solid #e3e3e3;
`;

const LineHorizontal = styled.div`
  border-top: 1px solid #e3e3e3;
`;

class DetailCostingOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipmentId: props.shipmentId,
      invoiceBusinessList: props.invoiceBusinessList,
      messageError: '',
      isOpenCostingConfirm: false,
      isOpenDeletePayment: false,
      selectTab: 1,
      documents: [],
    };
  }

  selectTab = indexTab => {
    this.setState({
      selectTab: indexTab,
    });
  };

  checkTabsActive = indexTab => {
    return this.state.selectTab === indexTab;
  };

  checkDisabled(paymentOrder) {
    const { payment_order_status } = paymentOrder;

    if (payment_order_status === 1) {
      return false;
    }
    return true;
  }

  transporterName = paymentOrder => {
    const { transporter_name, driver_name, driver_user_id, transporter } = paymentOrder;
    const id = transporter_name === 'GIZTIX' ? driver_user_id : transporter;
    const name = transporter_name === 'GIZTIX' ? driver_name : transporter_name;
    return { value: id, label: name };
  };

  paymentType = paymentOrder => {
    const { payment_type } = paymentOrder;
    const name = payment_type;
    switch (name) {
      case 1:
        return { value: 1, label: 'Advance Payment' };
      case 2:
        return { value: 1, label: 'COGs' };
      case 3:
        return { value: 1, label: 'Operating Cost' };
      case 4:
        return { value: 1, label: 'Petty Cash' };
      default:
        return name;
    }
  };

  paymentStatusTag(paymentStatus) {
    switch (paymentStatus) {
      case 1:
        return <Tag color="yellow">รอตรวจสอบ</Tag>;
      case 2:
        return <Tag color="blue--light">รอสั่งจ่าย</Tag>;
      case 3:
        return <Tag color="blue">สั่งจ่ายแล้ว</Tag>;
      case 4:
        return <Tag color="red">ปฏิเสธ</Tag>;
      case 5:
        return <Tag color="white">ยกเลิก</Tag>;
      default:
        return <Tag>{paymentStatus}</Tag>;
    }
  }

  renderStatusCosting(status ,edit_request_status) {
    switch (true) {
      case edit_request_status === 1:
        return <Tag color="yellow">ส่งคำขอแก้ไข</Tag>;
      case edit_request_status === 3:
        return <Tag color="red">ปฎิเสธคำขอแก้ไข</Tag>;
      case status == 1:
        return <Tag color="blue">รอตรวจสอบ</Tag>;
      case status == 2:
        return <Tag color="green">ตรวจสอบแล้ว</Tag>;
      case status == 3:
        return <Tag color="red">ปฎิเสธ</Tag>;
      case status == 4:
        return <Tag color="yellow">แก้ไข</Tag>;
      default:
        return '-';
    }
  }

  render() {
    const tabs = [
      {
        name: 'รายการจ่าย',
        active: this.checkTabsActive(1),
        clicked: () => this.selectTab(1),
      },
      {
        name: 'เอกสารทั่วไป',
        active: this.checkTabsActive(2),
        clicked: () => this.selectTab(2),
      },
    ];

    let billingCostingData = null;
    return (
      <Formik
        initialValues={{
          remark: ``,
        }}
        onSubmit={(values, actions) => {
          this.setState({
            isOpenCostingConfirm: true,
          });
        }}
      >
        {props => {
          return (
            <Query
              query={shipmentAdminListQuery}
              variables={{
                params: {
                  _id: this.state.shipmentId,
                },
                page: 1,
                showData: 30,
              }}
            >
              {({
                loading: loadingShipmentAdmin,
                error: errorShipmentAdmin,
                data: dateShipmentAdmin,
                refetch: refetchShipmentadmin,
                variables,
              }) => {
                if (loadingShipmentAdmin) return 'Loading...';
                if (errorShipmentAdmin) return { errorShipmentAdmin };
                let shipmentAdmin = null;
                dateShipmentAdmin.shipmentAdmins &&
                  dateShipmentAdmin.shipmentAdmins.shipments.length > 0 &&
                  (shipmentAdmin = dateShipmentAdmin.shipmentAdmins.shipments[0]);

                return (
                  <ModalContainer
                    show={this.props.show}
                    onHide={() => {
                      this.props.onHide();
                      this.setState({ messageError: '' });
                    }}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <div className="header-container">
                          <div className="text-header">
                            รายการต้นทุน/ขาย :{' '}
                            {this.props.costing ? this.props.costing.costing_number : '-'}
                          </div>
                          <div className="button-header">
                            <LineVertical style={{ marginRight: 10 }} />
                            <ButtonDefault onClick={this.props.onHide}>
                              <i className="far fa-times-circle" /> ปิด
                            </ButtonDefault>
                          </div>
                        </div>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {this.state.messageError && (
                        <div
                          style={{
                            color: '#d91010',
                            padding: '15px',
                            border: ' 1px solid',
                            borderLeftWidth: '5px',
                          }}
                        >
                          {this.state.messageError}
                        </div>
                      )}
                      <Query
                        query={invoiceListQuery}
                        variables={{
                          shipment_id: this.state.shipmentId,
                        }}
                      >
                        {({ loading, error, data, refetch, variables }) => {
                          if (loading) return 'Loading...';
                          if (error) return { error };

                          const invoiceBusinessList = data.invoiceList[0];

                          const costGrandTotal = invoiceBusinessList
                            ? invoiceBusinessList.cost_total_price
                            : 0;
                          const grandTotal = invoiceBusinessList
                            ? invoiceBusinessList.total_price
                            : 0;

                          return (
                            <Row style={{ padding: '0 30px' }}>
                              <Col sm={3} style={{ padding: '20px 0' }}>
                                <IconContainer>
                                  <i className="fal fa-file-chart-line"></i>
                                </IconContainer>
                                <TextContainer>
                                  <TextHeader>ต้นทุนก่อนภาษี</TextHeader>
                                  <div className="column">
                                    <Title>
                                      {costGrandTotal.toLocaleString('en-US', {
                                        maximumFractionDigits: 2,
                                      })}{' '}
                                      บาท
                                    </Title>
                                  </div>
                                </TextContainer>
                              </Col>
                              <LineVertical />
                              <Col sm={3} style={{ padding: '20px 0' }}>
                                <IconContainer>
                                  <i className="fal fa-hand-holding-usd"></i>
                                </IconContainer>
                                <TextContainer>
                                  <TextHeader>รายได้ก่อนภาษี</TextHeader>
                                  <div className="column">
                                    <Title>
                                      {grandTotal.toLocaleString('en-US', {
                                        maximumFractionDigits: 2,
                                      })}{' '}
                                      บาท
                                    </Title>
                                  </div>
                                </TextContainer>
                              </Col>
                              <LineVertical />
                              <Col sm={3} style={{ padding: '20px 0' }}>
                                <IconContainer>
                                  <i className="fal fa-chart-line"></i>
                                </IconContainer>
                                <TextContainer>
                                  <TextHeader>กำไรขั้นต้น</TextHeader>
                                  <div className="column">
                                    <Title style={{ color: '#5DBA5B' }}>
                                      <Title>
                                        {(grandTotal - costGrandTotal).toLocaleString('en-US', {
                                          maximumFractionDigits: 2,
                                        })}{' '}
                                        บาท
                                      </Title>
                                    </Title>
                                  </div>
                                </TextContainer>
                              </Col>
                              <LineVertical />
                              <Col sm={3} style={{ padding: '20px 0' }}>
                                <IconContainer>
                                  <i className="fal fa-hourglass-half"></i>
                                </IconContainer>
                                <TextContainer>
                                  <TextHeader>เหลือเวลาในการดำเนินงาน</TextHeader>
                                  <div className="column">
                                    <Title>-</Title>
                                  </div>
                                </TextContainer>
                              </Col>
                            </Row>
                          );
                        }}
                      </Query>
                      <div className="condition-payment-container">
                        <div className="body-container">
                          <Col sm={4} style={{ padding: '20px 30px' }}>
                            <Row>
                              <TextHeader>
                                ชื่อลูกค้า <span style={{ color: '#B3B3B3' }}>(ลูกหนี้)</span>
                              </TextHeader>
                              <div className="column">
                                <Title>{shipmentAdmin.shipper.account_info_business_name}</Title>
                              </div>
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                              <TextHeader>
                                ผู้รับเงิน <span style={{ color: '#B3B3B3' }}>(เจ้าหนี้)</span>
                              </TextHeader>
                              <div className="column">
                                <Title>
                                  {shipmentAdmin.transporter &&
                                  shipmentAdmin.transporter.account_info_business_name !== 'GIZTIX'
                                    ? shipmentAdmin.transporter.account_info_business_name
                                    : shipmentAdmin.driver_user_id.driver_name}
                                </Title>
                              </div>
                            </Row>
                          </Col>
                          <LineVertical />
                          <Col sm={4} style={{ padding: '20px 30px' }}>
                            <Row>
                              <TextHeader>ผู้ส่งราคา</TextHeader>
                              <div className="column">
                                <Title>
                                  {this.props.costing
                                    ? this.props.costing.employee.full_name
                                    : this.props.admin.name}
                                </Title>
                              </div>
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                              <TextHeader>หมายเหตุ</TextHeader>
                              <div className="column">
                                <Title>
                                  {this.props.costing ? this.props.costing.costing_remark : '-'}
                                </Title>
                              </div>
                            </Row>
                          </Col>
                          <LineVertical />
                          <Col sm={4} style={{ padding: '20px 30px' }}>
                            <Row>
                              <TextHeader>รหัสงานขนส่ง</TextHeader>
                              <div className="column">
                                <Title>{shipmentAdmin.shipment_number}</Title>
                              </div>
                            </Row>
                            <Row style={{ marginTop: 15 }}>
                              <TextHeader>สถานะ:</TextHeader>
                              <div className="column">
                                {this.renderStatusCosting(
                                  this.props.costing ? this.props.costing.costing_status : null,
                                  this.props.costing ? this.props.costing.edit_request_status : null
                                )}
                              </div>

                              {
                                this.props.costing && this.props.costing.reject_remark && this.props.costing.costing_status == 3 && (
                                  <RejectRemark>
                                    เหตุผล : {this.props.costing ? this.props.costing.reject_remark : null}
                                  </RejectRemark>
                                )
                              }
                            </Row>
                          </Col>
                        </div>
                      </div>
                      <div className="detail-container">
                        <Tabs menu={tabs} />
                        {this.state.selectTab === 1 && (
                          <PaymentList costingId={this.props.costingId} />
                        )}
                        {this.state.selectTab === 2 && (
                          <CostingDocument
                            shipmentId={this.props.shipmentId}
                            costingId={this.props.costingId}
                          />
                        )}
                      </div>
                    </Modal.Body>
                  </ModalContainer>
                );
              }}
            </Query>
          );
        }}
      </Formik>
    );
  }
}

export default DetailCostingOrderList;
