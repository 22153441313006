import React, { Component } from 'react'
import styled from 'styled-components';
import Moment from 'react-moment';
import {
    Grid,
    Row,
    Col,
    Table,
    Tooltip,
    OverlayTrigger,
    Button,
    FormControl,
    NavDropdown,
    NavItem,
    Label,
    Modal,
    Popover,
} from 'react-bootstrap';
import urijs from 'urijs';
import CardTable from 'components/CardTable/CardTable.jsx';
import { ModalConfirm } from '../../components';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import Autosuggest from 'react-autosuggest';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactPaginate from 'react-paginate';
import reportUrl from 'config/reportUrl';
import Select from 'react-select';
import assignAgentMutation from './graphql/mutation/assignAgent';
import assignDriverMutation from './graphql/mutation/assignDriver';
import { client } from '../../../../index';
import SubHeaderCOD from '../../components/SubHeaderCOD';
import shipmentListQuery from './graphql/query/shipmentList';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import adminListQuery from './graphql/query/adminList';
import driverUserListQuery from './graphql/query/driverUserList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import userTruckListQuery from './graphql/query/userTruckList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import shipmentAgentViewQuery from './graphql/query/shipmentAgentView';

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    background-color: #F4F4F4;
    height: 60px;
    border: 1px solid #E3E3E3;
    border-bottom: unset;
`;
const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #E3E3E3;
`;
const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;
const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;
const NavItemDropDownList = styled(NavItem)``;

const AutosuggestContainer = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const styleddefault = {
    backgroundColor: '#B3B3B3',
    border: '1px solid #B3B3B3',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styleddefaultoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #B3B3B3',
    color: '#B3B3B3',
    fontWeight: 'bold',
};

const styledprimary = {
    backgroundColor: '#318DE8',
    border: '1px solid #318DE8',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styledprimaryoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #318DE8',
    color: '#318DE8',
    fontWeight: 'bold',
};

const styledsuccess = {
    backgroundColor: '#66C066',
    border: '1px solid #66C066',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styledsuccessoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #66C066',
    color: '#66C066',
    fontWeight: 'bold',
};
const styleddark = {
    backgroundColor: '#ffffff',
    border: '1px solid #000000',
    color: '#000000',
    fontWeight: 'bold',
};
const styleddanger = {
    backgroundColor: ' #FF0000',
    border: '1px solid #FF0000',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styleddangeroutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #FF0000',
    color: '#FF0000',
    fontWeight: 'bold',
};
const styledwarning = {
    backgroundColor: '#ffffff',
    border: '1px solid #FF9D00',
    color: '#FF9D00',
    fontWeight: 'bold',
};

let datalist = [
    {
        date: '09/04/2020 - 10:10',
        money: 2500,
    },
    {
        date: '09/04/2020 - 11:11',
        money: 2400,
    }
]
const rejectList = [
    { value: 'ทดสอบ', label: 'ทดสอบ' },
    { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
    { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
    { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
    { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
    { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
    { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
    { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
    { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
    {
      value: 'ลูกค้าต้องการใช้บริการเสริม',
      label: 'ลูกค้าต้องการใช้บริการเสริม',
    },
    { value: 'เลือกใช้บริการเจ้าอื่น', label: 'เลือกใช้บริการเจ้าอื่น' },
    { value: 'อื่นๆ', label: 'อื่นๆ' },
  ];
class ShipmentSubAgent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            order_by: null,
            order_type: null,
            shipmentName: '',
            shipment_number: '',
            tagName: '',
            commodityName: '',
            pickupDate: '',
            displayPickupDate: '',
            shipmentStatus: 0,
            shipmentRunningNumber: '',
            admin: '',
            createDate: '',
            displayCreateDate: '',
            pageNumber: 1,
            lazadaImportOrderId: null,
            lazadaImportOrderName: '',
            lazadaImportOrderPickupDate: null,

            order_by: null,

            //ค้นหา driver
            driverUserListSuggestions: [],
            driverName: '',
            driverId: '',
            setting_truck_type_id: '',

            //cancel shipment
            rejectDetail: '',
            isShowRejectDetail: false,
            rejectSelected: null,
            rejectRemark: '',
            isOpenModalConfirmCancel: false,

            //assign driver
            driverUserId: '',
            userSettingTruckId: '',
            isOpenModalAssignDriver: false,
            driverSelected: '',
            truckSelected: '',

            DriverSuggestions: [],
            TruckSuggestions: [],

            fileNameBookingFile: '',
            isOpenModalConfirmDownloadReport: false,
            idBookingFile: null,

            //assign agent
            agentSelected: null,
            agentId: '',
            isOpenModalAssignAgent: false,
        };
    }
    renderStatusButton = (status) => {
        switch (status) {
            case 1:
                return <Button onClick={() => this.setState({ show_transfer: true })} style={{ backgroundColor: '#ffffff', color: '#318de8', border: '1px dashed #318de8' }}><i style={{ marginRight: '5px' }} class="fal fa-upload"></i>หลักฐานการโอน</Button>
            case 2:
                return <Button style={{ backgroundColor: '#D91010', color: '#fff' }}><i style={{ marginRight: '5px' }} class="far fa-times-circle"></i>ปฏิเสธข้อมูล</Button>
        }
    }

    renderStatus(status) {
        switch (status) {
            case 2:
                return <Label style={styleddefault}>รอขนส่งรับงาน</Label>;
            case 10:
                return <Label style={styleddefaultoutline} >รอเริ่มงาน</Label>;
            case 3:
                return <Label style={styledprimaryoutline}>กำลังดำเนินการขนส่ง</Label>;
            case 4:
                return <Label style={styledsuccessoutline}>ขนส่งเสร็จสิ้น</Label>;
            case 5:
                return <Label style={styleddark}>ถูกยกเลิก</Label>;
            case 6:
                return <Label style={styleddangeroutline}>งานเกิดปัญหา</Label>;
            case 7:
                return <Label style={styleddefaultoutline} >แบบร่าง</Label>;
            case 8:
                return <Label style={styledprimary} >นัดหมายลูกค้าแล้ว</Label>;
            case 9:
                return <Label style={styledwarning}>กำลังคืนคลัง</Label>;
            case 11:
                return <Label style={styleddefault}>รอส่งสถานะ</Label>;
            case 12:
                return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
            case 13:
                return <Label style={styledsuccess}>สถานะผ่าน</Label>;
            case 14:
                return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
            case 15:
                return <Label style={styleddefault}>รอโอน COD</Label>;
            case 16:
                return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
            case 17:
                return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
            case 18:
                return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
            default:
                return '';
        }
    }
    handleChangeAgent = agent => {
        this.setState({ agentSelected: agent, agentId: agent.value });
      };
    //Autosuggest Driver
    getSuggestionsDriver = async value => {
        const inputValue = value
        .trim()
        .toString()
        .toLowerCase();
        const inputLength = inputValue.length;

        try {
        const { data } = await client.query({
            query: driverUserListQuery,
            fetchPolicy: 'network-only',
            variables: {
            driver_name: inputValue,
            },
        });

        const driverData = data.driverUserList.driver_user.map(e => {
            return {
            id: e._id,
            name: `${e.driver_name} (0${e.driver_phone_number})`,
            user_setting_truck_id: e.user_setting_truck_id ? e.user_setting_truck_id : '',
            user_setting_truck_license_plate: e.user_setting_truck_license_plate
                ? e.user_setting_truck_license_plate
                : '',
            };
        });

        this.setState({
            DriverSuggestions: driverData,
        });
        } catch (error) {
        console.log(error);
        }
    };

    getSuggestionValueDriver = suggestion => suggestion.name;

    renderSuggestionDriver = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

    onSuggestionsFetchRequestedDriver = ({ value }) => {
        this.getSuggestionsDriver(value);
    };

    onSuggestionsClearRequestedDriver = () => {
        this.setState({
        DriverSuggestions: [],
        });
    };

    shouldRenderSuggestionsDriver() {
        return true;
    }
    //End Autosuggest Driver

    //Autosuggest Truck
    getSuggestionsTruck = async value => {
        const inputValue = value
        .trim()
        .toString()
        .toLowerCase();
        const inputLength = inputValue.length;

        try {
        const { data } = await client.query({
            query: userTruckListQuery,
            fetchPolicy: 'network-only',
            variables: {
            user_setting_truck_license_plate: inputValue,
            },
        });

        const driverData = data.userSettingTruckList.user_setting_truck.map(e => {
            return {
            id: e._id,
            name: e.user_setting_truck_license_plate,
            };
        });

        this.setState({
            TruckSuggestions: driverData,
        });
        } catch (error) {
        console.log(error);
        }
    };

    getSuggestionValueTruck = suggestion => suggestion.name;

    renderSuggestionTruck = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

    onSuggestionsFetchRequestedTruck = ({ value }) => {
        this.getSuggestionsTruck(value);
    };

    onSuggestionsClearRequestedTruck = () => {
        this.setState({
        TruckSuggestions: [],
        });
    };

    shouldRenderSuggestionsTruck() {
        return true;
    }
    //End Autosuggest Truck
    handleCancelShipment = async (shipmentID, shipmentsUpdate) => {
        try {
          this.setState({ isLoading: true });
          const { shipmentID } = this.state;
          const { data } = await client.query({
            query: shipmentListQuery,
            variables: {
              _id: shipmentID,
            },
            fetchPolicy: 'network-only',
          });
    
          const { shipment } = data.shipmentList;
          if (
            shipment[0].shipment_status === 4 ||
            shipment[0].shipment_status === 5 ||
            shipment[0].shipment_status === 8
          ) {
            this.setState({ isLoading: false, isOpenModalConfirmCancel: false });
            alert('ไม่สามารถยกเลิกรายการได้');
            return;
          }
    
          shipmentsUpdate();
        } catch (error) {
          this.setState({
            isLoading: false,
            isOpenModalConfirmCancel: false,
          });
    
          alert(error);
        }
    };
    handleAssignDriver = shipment => {
        const { _id, shipment_number, driver_user_id, user_setting_truck_id } = shipment;
        const shipmentNumber = shipment_number;
    
        if (driver_user_id) {
          this.setState({
            isOpenModalAssignDriver: true,
            shipmentID: _id,
            shipmentNumber: shipmentNumber,
            driverUserId: driver_user_id,
            userSettingTruckId: user_setting_truck_id,
            driverSelected: `${shipment.driver_name} (0${shipment.driver_phone_number})`,
            truckSelected: shipment.user_setting_truck_license_plate,
          });
        } else {
          this.setState({
            isOpenModalAssignDriver: true,
            shipmentID: _id,
            shipmentNumber: shipmentNumber,
            driverUserId: driver_user_id,
            userSettingTruckId: user_setting_truck_id,
            driverSelected: '',
            truckSelected: '',
          });
        }
    };
    handleDateRangePicker(mode, picker) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    
        const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
          'DD/MM/YYYY'
        )}`;
    
        if (mode === 'createDate') {
          this.setState({ createDate: date, displayCreateDate: display });
        } else if (mode === 'pickupDate') {
          this.setState({ pickupDate: date, displayPickupDate: display });
        }
      }
    downloadReportHandler = async () => {
        try {
          const { data } = await client.query({
            query: adminListQuery,
          });
    
          const { admin } = data.adminList;
    
          if (admin.length === 0) {
            alert('ไม่พบ Admin');
            return;
          }
    
          const { idBookingFile } = this.state;
          const adminId = admin[0]._id;
          const url = `${reportUrl}/lazada-summary?id=${idBookingFile}&admin_id=${adminId}`;
          window.open(url, '_blank');
          this.setState({
            isOpenModalConfirmDownloadReport: false,
          });
        } catch (error) {
          alert(error);
        }
    };
    selectOptionAgentList = agentList => {
        return agentList.map(agent => {
          return {
            value: agent._id,
            label: agent.account_info_business_name,
          };
        });
      };
    handleChangeReject(event) {
    this.setState({
        rejectSelected: event,
        rejectRemark: event.value,
    });

    if (event.value === 'อื่นๆ') {
        this.setState({ isShowRejectDetail: true });
    } else {
        this.setState({ isShowRejectDetail: false });
    }
    }
    handleAssignAgent = (shipment, agentList) => {
        const { _id, shipment_number, transporter } = shipment;
        const shipmentNumber = shipment_number;
        const agent = agentList.filter(item => item._id === transporter);
        this.clearDataSelectAgent();
    
        if (agent.length > 0) {
          this.setState({
            isOpenModalAssignAgent: true,
            shipmentID: _id,
            shipmentNumber: shipmentNumber,
            agentSelected: { value: agent[0]._id, label: agent[0].account_info_business_name },
          });
        } else {
          this.setState({
            isOpenModalAssignAgent: true,
            shipmentID: _id,
            shipmentNumber: shipmentNumber,
            agentId: transporter,
          });
        }
      };
    formatMoney = number => {
        return `฿ ${number.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        })}`;
    };
    renderDeliveryStatus = (status) => {
        switch (status) {
            case (1):
                return <span style={{ color: '#5DBA5B', fontWeight: 'bold' }} >Delivered</span>
            case (2):
                return <span style={{ color: '#FF0000', fontWeight: 'bold' }}>Fail Delivery</span>
        }
    }  
    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
          if (this.state.order_type == 1) {
            return 'sorting_asc';
          } else {
            return 'sorting_desc';
          }
        }
    
        return 'sorting';
    }
    clearDataSelectAgent = () => {
        this.setState({
          agentSelected: null,
          agentId: '',
        });
      };
    getorderIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).orderid;
        return id;
    };
    getIdFromUrl = () => {
      return urijs(window.location.href).query(true).id;
    };
    getaccountIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).accid;
        return id;
    };

    render() {
        let agentList = [];
        if (
        this.props.accountInfoListQuery.loading === false &&
        this.props.accountInfoListQuery.accountInfoList !== undefined
        ) {
        agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
        }
        return (
            <div>
                <Query
                    query={shipmentListQuery}
                    variables={{
                        page_number: this.state.pageNumber,
                        order_by: this.state.order_by,
                        order_type: this.state.order_type,
                        shipment_trip: this.state.shipmentName,
                        tag_name: this.state.tagName,
                        pick_up_date: this.state.pickupDate,
                        shipment_status: this.state.shipmentStatus,
                        shipment_running_number: this.state.shipmentRunningNumber,
                        admin: this.state.admin,
                        create_date: this.state.createDate,
                        shipment_number: this.state.shipment_number,
                        driver_user_id: this.state.driverId,
                        commodity_name: this.state.commodityName,
                        commodity_order_number: this.state.OrderNumber,
                        lazada_import_order_id: this.getorderIdFromUrl(),
                        setting_truck_type_id: this.state.setting_truck_type_id,
                    }}
                    fetchPolicy="cache-and-network"
                    >
                    {({ data, loading, error, refetch }) => {
                        let shipmentList = [];
                        let pickupDate = '';
                        let totalPage = 0;
            
                        if (!loading && !error) {
                        shipmentList = [...data.shipmentList.shipment];
                        totalPage = data.shipmentList.total_page;
                        }
            
                        var itemLength = 'loading';
                        const itemIndicatorSize = (this.state.pageNumber - 1) * 30;
                        if (data.shipmentList) {
                            if (data.shipmentList.shipment.length > 0) {
                                itemLength = data.shipmentList.shipment.length;
                            }
                        }
            
                        return (
                            <>
                                <Query
                                    query={shipmentAgentViewQuery}
                                    variables={{
                                        shipment_prefix: 'GLZ',
                                        lazada_import_order_id: this.getorderIdFromUrl(),
                                        account_info_id: this.getaccountIdFromUrl()
                                    }}
                                    fetchPolicy="cache-and-network"
                                >
                                    {({ data: datashipmentAgentView}) => {
                                        let codAvailable = false
                                        if(datashipmentAgentView && datashipmentAgentView.shipmentAgentView){
                                          codAvailable = datashipmentAgentView.shipmentAgentView.total_accept_cod ? (datashipmentAgentView.shipmentAgentView.total_accept_cod > 0) : false
                                        }
                                    return(
                                        <SubHeaderCOD headerName='ตารางงานขนส่ง' pathname={this.props.location.pathname} codAvailable={codAvailable} agent_status={datashipmentAgentView && datashipmentAgentView.shipmentAgentView && datashipmentAgentView.shipmentAgentView.agent_shipment_status} />
                                    )
                                    }}
                                </Query>
                                <Grid fluid>
                                    <Row>
                                        <Col md={12}>
                                            <div>
                                                <StyledHeader>
                                                    <Grid style={{ margin: 'unset', width: '100%' }} >
                                                        <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                            <Col md={2}>
                                                                <strong style={{ fontSize: '22px' }}>ตารางงานขนส่ง</strong>
                                                            </Col>
                                                            <Col md={10}><Line /></Col>
                                                                {this.getorderIdFromUrl() && (
                                                                    <Col md={1} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                                        <Button
                                                                            style={{backgroundColor: '#fff'}}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    isOpenModalConfirmDownloadReport: true,
                                                                                    fileNameBookingFile: this.state.lazadaImportOrderName,
                                                                                    idBookingFile: this.state.lazadaImportOrderId,
                                                                                });
                                                                            }}
                                                                        >
                                                                        <i className="fal fa-file-alt"></i> รายงานงานขนส่ง
                                                                        </Button>
                                                                    </Col>
                                                                )}
                                                        </Row>
                                                    </Grid>
                                                </StyledHeader>
                                                <CardTable
                                                    ctTableFullWidth
                                                    ctTableResponsive
                                                    content={
                                                    <div>
                                                        <PageWrapper style={{ textAlign: 'right' }}>
                                                        <div className="text">
                                                            {this.state.pageNumber === 1
                                                            ? `1 - ${itemLength}`
                                                            : `${itemIndicatorSize + 1} - ${itemLength +
                                                            itemIndicatorSize}`}{' '}
                                                            หน้า :
                                                        </div>
                                                        <FormControl
                                                            type="number"
                                                            min={1}
                                                            value={this.state.pageNumber}
                                                            onChange={event =>
                                                            Number(event.target.value) >= 0
                                                                ? setInterval(
                                                                this.setState({
                                                                    pageNumber: Number(event.target.value),
                                                                }),
                                                                1000
                                                                )
                                                                : setInterval(this.setState({ pageNumber: 1 }), 1000)
                                                            }
                                                            name="page_number"
                                                        />
                                                        <div className="text">
                                                            {' '}
                                                            {loading || error ? '' : `จาก ${data.shipmentList.total_page}`}
                                                        </div>
                                                        </PageWrapper>
                                                        <div className="table-vertical">
                                                        <Table striped hover>
                                                            <thead>
                                                            <tr>
                                                                <th style={{ minWidth: '200px' }}>เครื่องมือ</th>
                                                                <th
                                                                style={{ minWidth: '200px' }}
                                                                className={this.renderSorting('shipment_trip')}
                                                                onClick={() => this.sort('shipment_trip', refetch)}
                                                                >
                                                                Booking Number
                                                                </th>
                                                                <th
                                                                style={{ minWidth: '160px' }}
                                                                className={this.renderSorting('pick_up_date')}
                                                                onClick={() => this.sort('pick_up_date', refetch)}
                                                                >
                                                                วันที่รับสินค้า
                                                                </th>
                                                                <th style={{ minWidth: '160px' }}>
                                                                สถานะ 
                                                                </th>
                                                                <th style={{ minWidth: '160px' }}>พนักงานขับรถ</th>
                                                                <th style={{ minWidth: '100px' }}>เลขทะเบียน</th>
                                                                <th style={{ minWidth: '100px' }}>ชื่อนายหน้า</th>
                                                                <th style={{ minWidth: '100px' }}>จุดรับสินค้า</th>
                                                                <th style={{ minWidth: '100px' }}>จุดส่งสินค้า</th>
                                                                <th style={{ minWidth: '100px' }}>COD รับแล้ว</th>
                                                                <th style={{ minWidth: '130px' }}>COD ยังไม่ได้รับ</th>
                                                                <th style={{ minWidth: '100px' }}>COD ยกเลิก</th>
                                                                <th style={{ minWidth: '100px' }}>COD ทั้งหมด</th>
                                                                <th style={{ minWidth: '120px' }}>เลขรัน</th>
                                                                <th style={{ minWidth: '160px' }}>หมายเลขงานขนส่ง</th>
                                                                <th style={{ minWidth: '140px' }}>ประเภทรถขนส่ง</th>
                                                                <th style={{ minWidth: '160px' }}>วันที่จอง</th>
                                                            </tr>
                                                            <tr>
                                                                <th></th>
                                                                <th>
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder="ค้นหา Trip No."
                                                                    value={this.state.shipmentName}
                                                                    onChange={event => {
                                                                    this.setState({
                                                                        shipmentName: event.target.value,
                                                                        pageNumber: 1,
                                                                    });
                                                                    }}
                                                                />
                                                                </th>
                                                                <th>
                                                                <DateRangePicker
                                                                    onApply={(event, picker) =>
                                                                    this.handleDateRangePicker('pickupDate', picker)
                                                                    }
                                                                    onCancel={() =>
                                                                    this.setState({
                                                                        pickupDate: '',
                                                                        displayPickupDate: '',
                                                                        pageNumber: 1,
                                                                    })
                                                                    }
                                                                    containerStyles={{ display: 'block' }}
                                                                    opens="right"
                                                                    format="DD/MM/YYYY"
                                                                >
                                                                    <FormControl
                                                                    type="text"
                                                                    value={this.state.displayPickupDate}
                                                                    placeholder="วันที่รับสินค้า"
                                                                    />
                                                                </DateRangePicker>
                                                                </th>
                                                                <th>
                                                                <FormControl
                                                                    componentClass="select"
                                                                    placeholder="select"
                                                                    defaultValue={this.state.shipmentStatus}
                                                                    onChange={event =>
                                                                    this.setState({
                                                                        shipmentStatus: event.target.value,
                                                                        page_number: 1,
                                                                    })
                                                                    }
                                                                >
                                                                    <option value={0}>ทั้งหมด</option>
                                                                    <option value={1}>รอเพิ่มคนขับ</option>
                                                                    <option value={2}>รอขนส่งรับงาน</option>
                                                                    <option value={10}>รอเริ่มงาน</option>
                                                                    <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                                                    <option value={3}>กำลังดำเนินการขนส่ง</option>
                                                                    <option value={4}>เสร็จสิ้นแล้ว</option>
                                                                    <option value={5}>ถูกยกเลิก</option>
                                                                    <option value={6}>งานเกิดปัญหา</option>
                                                                    <option value={7}>แบบร่าง</option>
                                                                    <option value={9}>กำลังคืนคลัง</option>

                                                                    {/* new status */}
                                                                    <option value={11}>รอส่งสถานะ</option>
                                                                    <option value={12}>รอตรวจสอบสถานะ</option>
                                                                    <option value={13}>สถานะผ่าน</option> {/* สถานะผ่าน ถ้าไม่มี COD จะเป็น เสร็จสมบูรณ์ */}
                                                                    <option value={14}>สถานะไม่ผ่าน</option>
                                                                    <option value={15}>รอโอน COD</option>
                                                                    <option value={16}>รอตรวจสอบ COD</option>
                                                                    <option value={17}>เสร็จสมบูรณ์</option>
                                                                    <option value={18}>COD ไม่ผ่าน</option>
                                                                </FormControl>
                                                                </th>
                                                                <th>
                                                                <AutosuggestContainer>
                                                                    <Autosuggest
                                                                    suggestions={this.state.driverUserListSuggestions}
                                                                    onSuggestionsFetchRequested={
                                                                        this.onSuggestionsFetchRequested
                                                                    }
                                                                    onSuggestionsClearRequested={
                                                                        this.onSuggestionsClearRequested
                                                                    }
                                                                    getSuggestionValue={this.getSuggestionValue}
                                                                    renderSuggestion={this.renderSuggestion}
                                                                    focusInputOnSuggestionClick={false}
                                                                    inputProps={{
                                                                        placeholder: 'ค้นหา',
                                                                        value: this.state.driverName,
                                                                        onChange: event =>
                                                                        this.setState({
                                                                            driverName: event.target.value,
                                                                            driverId: '',
                                                                        }),
                                                                        type: 'text',
                                                                        className: 'form-control',
                                                                    }}
                                                                    onSuggestionSelected={(event, { suggestion }) => {
                                                                        this.setState({
                                                                        driverId: suggestion.id,
                                                                        driverName: suggestion.name,
                                                                        pageNumber: 1,
                                                                        });
                                                                    }}
                                                                    shouldRenderSuggestions={this.shouldRenderSuggestions}
                                                                    />
                                                                </AutosuggestContainer>
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder="ค้นหาจุดรับสินค้า"
                                                                    value={this.state.tagName}
                                                                    onChange={event => {
                                                                    this.setState({
                                                                        tagName: event.target.value,
                                                                        pageNumber: 1,
                                                                    });
                                                                    }}
                                                                />
                                                                </th>
                                                                <th>
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder="ค้นหาเลข tracking"
                                                                    value={this.state.commodityName}
                                                                    onChange={event => {
                                                                    this.setState({
                                                                        commodityName: event.target.value,
                                                                        pageNumber: 1,
                                                                    });
                                                                    }}
                                                                />
                                                                </th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th></th>
                                                                <th>
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder="ค้นหาเลขรัน"
                                                                    value={this.state.shipmentRunningNumber}
                                                                    onChange={event => {
                                                                    this.setState({
                                                                        shipmentRunningNumber: event.target.value,
                                                                        pageNumber: 1,
                                                                    });
                                                                    }}
                                                                />
                                                                </th>
                                                                <th>
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder="ค้นหาหมายเลขงานขนส่ง"
                                                                    value={this.state.shipment_number}
                                                                    onChange={event => {
                                                                    this.setState({
                                                                        shipment_number: event.target.value,
                                                                        pageNumber: 1,
                                                                    });
                                                                    }}
                                                                />
                                                                </th>
                                                                <th>
                                                                <Query
                                                                    query={settingTruckListQuery}
                                                                    fetchPolicy="network-only"
                                                                >
                                                                    {({ loading, error, data, refetch }) => {
                                                                    return (
                                                                        <FormControl
                                                                        componentClass="select"
                                                                        placeholder="select"
                                                                        defaultValue={this.state.setting_truck_type_id}
                                                                        onChange={event =>
                                                                            this.setState(
                                                                            {
                                                                                setting_truck_type_id:
                                                                                event.target.value == 0
                                                                                    ? null
                                                                                    : event.target.value,
                                                                                page_number: 1,
                                                                            },
                                                                            () => {
                                                                                refetch();
                                                                            }
                                                                            )
                                                                        }
                                                                        name="setting_truck_type_id"
                                                                        >
                                                                        <option value={0}>ทั้งหมด</option>
                                                                        {!loading && !error && data.settingTruckList.map((truck, index) => (
                                                                            <optgroup
                                                                                label={`รถ ${truck.setting_truck_name.th}`}
                                                                            >
                                                                                {truck.setting_truck_type
                                                                                .filter(item => item.truck_type_enable)
                                                                                .map((item, key, arr) => (
                                                                                    <option value={item._id}>
                                                                                    {`${truck.setting_truck_name.th} - ${item.truck_type_name.th}` ||
                                                                                        undefined}
                                                                                    </option>
                                                                                ))}
                                                                            </optgroup>
                                                                            ))}
                                                                        </FormControl>
                                                                    );
                                                                    }}
                                                                </Query>
                                                                </th>
                                                                <th>
                                                                <DateRangePicker
                                                                    onApply={(event, picker) =>
                                                                    this.handleDateRangePicker('createDate', picker)
                                                                    }
                                                                    onCancel={() =>
                                                                    this.setState({
                                                                        createDate: '',
                                                                        displayCreateDate: '',
                                                                        pageNumber: 1,
                                                                    })
                                                                    }
                                                                    containerStyles={{ display: 'block' }}
                                                                    opens="right"
                                                                    format="DD/MM/YYYY"
                                                                >
                                                                    <FormControl
                                                                    type="text"
                                                                    value={this.state.displayCreateDate}
                                                                    placeholder="วันที่จอง"
                                                                    />
                                                                </DateRangePicker>
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {loading && (
                                                                <tr>
                                                                    <td colSpan="17">Loading...</td>
                                                                </tr>
                                                            )}

                                                            {error && (
                                                                <tr>
                                                                    <td colSpan="17">Error</td>
                                                                </tr>
                                                            )}

                                                            {!loading && !error && shipmentList.length === 0 && (
                                                                <tr>
                                                                    <td colSpan="17">ไม่มีข้อมูล</td>
                                                                </tr>
                                                            )}

                                                            {shipmentList.map((item, index) => (
                                                                <tr>
                                                                <td>
                                                                    <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}
                                                                    >
                                                                    <a
                                                                        href={`/lazada-shipment-detail?id=${item._id}`}
                                                                        target="_blank"
                                                                        style={{ float: 'left' }}
                                                                        className="btn btn-default"
                                                                    >
                                                                        <i
                                                                        style={{ marginTop: '3px' }}
                                                                        className="fas fa-eye"
                                                                        ></i>
                                                                    </a>
                                                                    </OverlayTrigger>
                                                                    <NavDropdownContainer
                                                                    title={
                                                                        <Button>
                                                                        เครื่องมือ <i className="fal fa-angle-down"></i>
                                                                        </Button>
                                                                    }
                                                                    >
                                                                    <NavItemDropDownList
                                                                        onClick={() =>
                                                                        this.handleAssignAgent(item, agentList)
                                                                        }
                                                                    >
                                                                        <i className="fas fa-user-tie"></i> เปลี่ยนนายหน้า
                                                                    </NavItemDropDownList>
                                                                    <NavItemDropDownList
                                                                        onClick={() => this.handleAssignDriver(item)}
                                                                    >
                                                                        <i className="fas fa-truck"></i> เพิ่มคนขับ
                                                                    </NavItemDropDownList>
                                                                    {item.shipment_status !== 4 &&
                                                                        item.shipment_status !== 5 && (
                                                                        <NavItemDropDownList
                                                                            onClick={() => {
                                                                            this.setState({
                                                                                isOpenModalConfirmCancel: true,
                                                                                shipmentID: item._id,
                                                                                shipmentNumber: item.shipment_number,
                                                                                rejectSelected: null,
                                                                                rejectRemark: '',
                                                                                rejectDetail: '',
                                                                            });
                                                                            }}
                                                                        >
                                                                            <i className="fas fa-times"></i> ยกเลิก
                                                                        </NavItemDropDownList>
                                                                        )}
                                                                    </NavDropdownContainer>
                                                                </td>
                                                                <td>{item.shipment_trip}</td>
                                                                <td>
                                                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                                                    {item.pick_up_date}
                                                                    </Moment>
                                                                </td>
                                                                <td>
                                                                    {this.renderStatus(item.shipment_status)}
                                                                </td>
                                                                <td>
                                                                    {item.driver_name
                                                                    ? `
                                                                    ${item.driver_name}, ${item.driver_phone_code} ${item.driver_phone_number}`
                                                                    : '-'}
                                                                </td>
                                                                <td>{item.user_setting_truck_license_plate || '-'}</td>
                                                                <td>{item.transporter_name}</td>
                                                                <td>{item.shipment_address[0].tag_name}</td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                    trigger="hover"
                                                                    key="bottom"
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Popover
                                                                        id={`popover-positioned-${item._id}`}
                                                                        title={`จุดส่งสินค้าทั้งหมด ${
                                                                            item.shipment_address.filter(
                                                                            shipmentAddress =>
                                                                                shipmentAddress.mode === 'ส่งสินค้า'
                                                                            ).length
                                                                            } จุด `}
                                                                        >
                                                                        {item.shipment_address
                                                                            .filter(
                                                                            shipmentAddress =>
                                                                                shipmentAddress.mode === 'ส่งสินค้า'
                                                                            )
                                                                            .map((address, key) => (
                                                                            <p>
                                                                                <strong>{`จุดที่ ${key + 1} `}</strong>
                                                                                {`${address.commodity[0].name}`}{' '}
                                                                            </p>
                                                                            ))}
                                                                        </Popover>
                                                                    }
                                                                    >
                                                                    <b style={{ cursor: 'pointer' }}>
                                                                        {`${
                                                                        item.shipment_address.filter(
                                                                            shipmentAddress =>
                                                                            shipmentAddress.mode === 'ส่งสินค้า'
                                                                        ).length
                                                                        } จุดส่ง`}
                                                                    </b>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>{this.formatMoney(item.shipment_accept_cod)}</td>
                                                                <td>
                                                                    {this.formatMoney(
                                                                    item.shipment_total_cod -
                                                                    item.shipment_cancel_cod -
                                                                    item.shipment_accept_cod
                                                                    )}
                                                                </td>
                                                                <td>{this.formatMoney(item.shipment_cancel_cod)}</td>
                                                                <td>{this.formatMoney(item.shipment_total_cod)}</td>
                                                                <td>{item.shipment_running_number}</td>
                                                                <td>{item.shipment_number}</td>
                                                                <td>
                                                                    {`${item.setting_truck_name.th} ${item.truck_type_name.th}`}
                                                                </td>
                                                                <td>
                                                                    <Moment format="DD/MM/YYYY HH:mm:ss">
                                                                    {item.create_date}
                                                                    </Moment>
                                                                </td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </Table>
                                                        </div>
                                                        <div style={{ textAlign: 'right', marginTop: 20 }}>
                                                        {!loading && !error && (
                                                            <ReactPaginate
                                                            pageCount={totalPage}
                                                            forcePage={this.state.pageNumber - 1}
                                                            containerClassName="pagination"
                                                            nextLabel=">"
                                                            previousLabel="<"
                                                            onPageChange={({ selected }) => {
                                                                this.setState({
                                                                pageNumber: selected + 1,
                                                                });
                                                            }}
                                                            />
                                                        )}
                                                        </div>
                                                    </div>
                                                    }
                                                />
                                            </div>
                                            

                                            <Mutation
                                            mutation={shipmentsUpdateMutation}
                                            variables={{
                                                _id: this.state.shipmentID,
                                                shipment_status: 5,
                                                cancel_comment:
                                                this.state.rejectRemark === 'อื่นๆ'
                                                    ? this.state.rejectDetail
                                                    : this.state.rejectRemark,
                                            }}
                                            update={() => {
                                                this.setState({
                                                isLoading: false,
                                                isOpenModalConfirmCancel: false,
                                                });
                                            }}
                                            refetchQueries={() => ['LazadaShipmentList']}
                                            >
                                            {shipmentsUpdate => (
                                                <Modal
                                                show={this.state.isOpenModalConfirmCancel}
                                                onHide={() =>
                                                    this.setState({
                                                    isOpenModalConfirmCancel: false,
                                                    })
                                                }
                                                >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>
                                                    ยกเลิกงานขนส่ง {this.state.shipmentNumber}
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Row style={{ marginBottom: '10px' }}>
                                                    <Col md={12}>
                                                        <b>
                                                        เหตุผลที่ยกเลิกงาน{' '}
                                                        <small style={{ color: '#D90101' }}>*</small>
                                                        </b>
                                                    </Col>
                                                    </Row>

                                                    <Select
                                                    value={this.state.rejectSelected}
                                                    onChange={event => this.handleChangeReject(event)}
                                                    options={rejectList}
                                                    placeholder="เลือกเหตุผลที่ยกเลิกงาน"
                                                    />

                                                    {this.state.isShowRejectDetail && (
                                                    <FormControl
                                                        style={{ marginTop: '10px' }}
                                                        type="text"
                                                        value={this.state.rejectDetail}
                                                        placeholder="ระบุ"
                                                        onChange={event =>
                                                        this.setState({
                                                            rejectDetail: event.target.value,
                                                        })
                                                        }
                                                    />
                                                    )}

                                                    <Row style={{ marginTop: '10px' }}>
                                                    <Col md={12}>คุณต้องการยกเลิกงานขนส่งนี้ให้กด "ตกลง"</Col>
                                                    </Row>
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                    style={{ float: 'left' }}
                                                    onClick={() =>
                                                        this.setState({
                                                        isOpenModalConfirmCancel: false,
                                                        })
                                                    }
                                                    >
                                                    กลับ
                                                    </Button>
                                                    <Button
                                                    bsStyle="primary"
                                                    disabled={
                                                        this.state.isLoading ||
                                                        this.state.rejectRemark === '' ||
                                                        (this.state.rejectRemark === 'อื่นๆ' &&
                                                        this.state.rejectDetail === '')
                                                    }
                                                    onClick={() =>
                                                        this.handleCancelShipment(
                                                        this.state.shipmentID,
                                                        shipmentsUpdate
                                                        )
                                                    }
                                                    >
                                                    ตกลง
                                                    </Button>
                                                </Modal.Footer>
                                                </Modal>
                                            )}
                                            </Mutation>

                                            <Mutation
                                            mutation={assignAgentMutation}
                                            variables={{
                                                shipment_id: this.state.shipmentID,
                                                driver_user_id: this.state.driverUserId,
                                                transporter: this.state.agentId,
                                            }}
                                            update={() => {
                                                this.setState({ isOpenModalAssignDriver: false });
                                            }}
                                            refetchQueries={() => ['LazadaShipmentList']}
                                            >
                                            {assignAgent => (
                                                <Mutation
                                                mutation={assignDriverMutation}
                                                variables={{
                                                    shipment_id: this.state.shipmentID,
                                                    driver_user_id: this.state.driverUserId,
                                                    user_setting_truck_id: this.state.userSettingTruckId,
                                                    transporter: this.state.agentId,
                                                }}
                                                update={() => {
                                                    assignAgent();
                                                }}
                                                refetchQueries={() => ['LazadaShipmentList']}
                                                >
                                                {assignDriver => (
                                                    <Modal
                                                    show={this.state.isOpenModalAssignDriver}
                                                    onHide={() =>
                                                        this.setState({
                                                        isOpenModalAssignDriver: false,
                                                        })
                                                    }
                                                    >
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>
                                                        แก้ไขคนขับรถ {this.state.shipmentNumber}
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row style={{ marginBottom: '10px' }}>
                                                        <Col md={12}>คนขับรถ</Col>
                                                        </Row>

                                                        <AutosuggestContainer>
                                                        <Autosuggest
                                                            suggestions={this.state.DriverSuggestions}
                                                            onSuggestionsFetchRequested={
                                                            this.onSuggestionsFetchRequestedDriver
                                                            }
                                                            onSuggestionsClearRequested={
                                                            this.onSuggestionsClearRequestedDriver
                                                            }
                                                            getSuggestionValue={this.getSuggestionValueDriver}
                                                            renderSuggestion={this.renderSuggestionDriver}
                                                            focusInputOnSuggestionClick={false}
                                                            inputProps={{
                                                            placeholder: 'เลือกคนขับรถ',
                                                            value: this.state.driverSelected,
                                                            onChange: event =>
                                                                this.setState({
                                                                driverSelected: event.target.value,
                                                                driverUserId: null,
                                                                }),
                                                            type: 'text',
                                                            className: 'form-control',
                                                            }}
                                                            onSuggestionSelected={(event, { suggestion }) => {
                                                            this.setState({
                                                                driverUserId: suggestion.id,
                                                                driverSelected: suggestion.name,
                                                                userSettingTruckId: suggestion.user_setting_truck_id,
                                                                truckSelected:
                                                                suggestion.user_setting_truck_license_plate,
                                                            });
                                                            }}
                                                            shouldRenderSuggestions={this.shouldRenderSuggestionsDriver}
                                                        />
                                                        </AutosuggestContainer>

                                                        <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                                        <Col md={12}>ทะเบียนรถ</Col>
                                                        </Row>
                                                        <AutosuggestContainer>
                                                        <Autosuggest
                                                            suggestions={this.state.TruckSuggestions}
                                                            onSuggestionsFetchRequested={
                                                            this.onSuggestionsFetchRequestedTruck
                                                            }
                                                            onSuggestionsClearRequested={
                                                            this.onSuggestionsClearRequestedTruck
                                                            }
                                                            getSuggestionValue={this.getSuggestionValueTruck}
                                                            renderSuggestion={this.renderSuggestionTruck}
                                                            focusInputOnSuggestionClick={false}
                                                            inputProps={{
                                                            placeholder: 'เลือกทะเบียนรถ',
                                                            value: this.state.truckSelected,
                                                            onChange: event =>
                                                                this.setState({
                                                                truckSelected: event.target.value,
                                                                userSettingTruckId: null,
                                                                }),
                                                            type: 'text',
                                                            className: 'form-control',
                                                            }}
                                                            onSuggestionSelected={(event, { suggestion }) => {
                                                            this.setState({
                                                                userSettingTruckId: suggestion.id,
                                                                truckSelected: suggestion.name,
                                                            });
                                                            }}
                                                            shouldRenderSuggestions={this.shouldRenderSuggestionsTruck()}
                                                        />
                                                        </AutosuggestContainer>

                                                        <Row
                                                        style={{
                                                            marginBottom: '10px',
                                                            marginTop: '20px',
                                                        }}
                                                        >
                                                        <Col md={12}>นายหน้า</Col>
                                                        </Row>

                                                        <Select
                                                        value={this.state.agentSelected}
                                                        onChange={event => this.handleChangeAgent(event)}
                                                        options={this.selectOptionAgentList(agentList)}
                                                        placeholder="เลือกนายหน้า"
                                                        />
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                        bsStyle="primary"
                                                        onClick={() => assignDriver()}
                                                        disabled={
                                                            !this.state.driverSelected ||
                                                            !this.state.truckSelected ||
                                                            !this.state.agentSelected
                                                        }
                                                        >
                                                        บันทึก
                                                        </Button>
                                                    </Modal.Footer>
                                                    </Modal>
                                                )}
                                                </Mutation>
                                            )}
                                            </Mutation>

                                            <Mutation
                                            mutation={assignAgentMutation}
                                            variables={{
                                                shipment_id: this.state.shipmentID,
                                                driver_user_id: this.state.driverUserId,
                                                transporter: this.state.agentId,
                                            }}
                                            update={() => {
                                                this.setState({ isOpenModalAssignAgent: false });
                                            }}
                                            refetchQueries={() => ['LazadaShipmentList']}
                                            >
                                            {assignAgent => (
                                                <Modal
                                                show={this.state.isOpenModalAssignAgent}
                                                onHide={() =>
                                                    this.setState({
                                                    isOpenModalAssignAgent: false,
                                                    })
                                                }
                                                >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>แก้ไขนายหน้า {this.state.shipmentNumber}</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Row style={{ marginBottom: '10px' }}>
                                                    <Col md={12}>นายหน้า</Col>
                                                    </Row>

                                                    <Select
                                                    value={this.state.agentSelected}
                                                    onChange={event => this.handleChangeAgent(event)}
                                                    options={this.selectOptionAgentList(agentList)}
                                                    placeholder="เลือกนายหน้า"
                                                    />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button
                                                    disabled={!this.state.agentSelected}
                                                    bsStyle="primary"
                                                    onClick={() => assignAgent()}
                                                    >
                                                    บันทึก
                                                    </Button>
                                                </Modal.Footer>
                                                </Modal>
                                            )}
                                            </Mutation>

                                            <ModalConfirm
                                            show={this.state.isOpenModalConfirmDownloadReport}
                                            onPressButtonConfirm={this.downloadReportHandler}
                                            styleColorButtonConfirm="primary"
                                            labelButtonConfirm="ดาวน์โหลด"
                                            labelButtonCancel="ยกเลิก"
                                            onPressButtonCancel={() =>
                                                this.setState({ isOpenModalConfirmDownloadReport: false })
                                            }
                                            titleHeader={`Download Report ใช่หรือไม่?`}
                                            onHide={() => this.setState({ isOpenModalConfirmDownloadReport: false })}
                                            >
                                            <div style={{ lineHeight: 1 }}>
                                                File Name: <strong>{this.state.fileNameBookingFile}</strong>
                                            </div>
                                            </ModalConfirm>
                                        </Col>
                                    </Row>
                                </Grid>
                            </>
                        )
                    }}
                </Query>
            </div>
        )
    }
}
export default compose(
    graphql(driverUserListQuery, { name: 'driverUserListQuery' }),
    graphql(userTruckListQuery, { name: 'userTruckListQuery' }),
    graphql(accountInfoListQuery, {
      name: 'accountInfoListQuery',
      options: () => ({
        fetchPolicy: 'network-only',
        variables: {
          account_info_type: 3,
        },
      }),
    })
  )(ShipmentSubAgent);