import React, { Component} from "react";
import Moment from 'react-moment';
import { Query, graphql, compose, Mutation } from "react-apollo";
import ReactPaginate from 'react-paginate';
import { Grid, Row, Col,Button ,InputGroup,FormControl} from "react-bootstrap";

import { Formik} from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import _ from 'lodash';

import checkExistsTrackingURLQuery from './graphql/query/checkExistsTrackingURL';
import accountInfoListQuery from './graphql/query/accountInfoList';
import accountInfoUpdateMutation from './graphql/mutation/accountInfoUpdate';

import UploadFile from './container/UploadFile';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import CardTable from "components/CardTable/CardTable.jsx";
import styled from 'styled-components';
import { client } from "../../..";
import s3 from "../../../config/s3" 
import { Card , ModalConfirm } from "./../components" 

// const s3 = 'https://s3-ap-southeast-1.amazonaws.com/media.kaikin.co';

const Container = styled.div`
    & .react-autosuggest__suggestions-list {
        list-style: none;
        color: #000000;
        padding: 0;
        margin: 0;
        border: 1px solid #E3E3E3;
        margin-top: 0px;
        border-radius: 4px;

        position: absolute;
        background-color: white;
        z-index: 999;
        min-width: 150px;
        white-space: nowrap;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
    }

    & .react-autosuggest__suggestions-container {
        // position: relative;
    }

    & .react-autosuggest__suggestion {
        padding: 10px 10px;
        background-color: #FFFFFF;
        cursor: pointer;

        &:hover {
            background-color: #E3E3E3;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #E3E3E3;
        }
    }

    & .react-autosuggest__suggestion--highlighted {
        background-color: #E3E3E3;
    }
`;

const HeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	border-bottom: 1px solid #efefef;
	display: flex;
	flex-direction: row;
	padding: 10px;

	

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}

		
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
		background-color: #E3E3E3;
		width: 1px;
		margin-left: 20px;
		margin-right: 20px;
	}

	& .menu-wrapper{
		& ul{
			padding-left: 0;
			margin: 0;
			display: flex;

			& li{
				list-style: none;
				margin-right: 15px;
				padding: 22px 10px 15px;

				& a{
					font-size:18px;
					cursor: pointer;
					& i{
						font-size:18px;
						color: #000000;
					}
				}
			}
			& li.active{
				border-bottom: 2px solid #d90402;
			}
			
		}
	}
`;

const Line = styled.hr`
    margin-bottom: 10px;
    margin-top: 5px;
    margin-right: 15px;
    float: left;
    width: 100%;
`;

const SubHeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	padding: 10px 20px;

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
    background-color: #E3E3E3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
	}
`;

const TextHeader = styled.div`
	font-size: 26px;
	font-weight: 700;
	line-height: 1;
`;

const TextSubHeader = styled.div`
	font-size: 18px;
	margin-bottom: 20px
`;

const Required = styled.span`
	color: #e30301;
`;

const Label = styled.div`
	font-size:18px;
	display: inline-block;
	font-weight: bold;
`;

const Remark = styled.div`
	color: #939393;
	font-size:18px;
	margin-top: 5px;
`;

const MessageInput = styled.div`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding-left: 10px;
	font-size:18px;
	line-height: 34px;
	color: ${props => props.textColor || '#000000'};

	& i {
		margin-right: 5px;
		color: ${props => props.iconColor || '#000000'};
	}
`;

const FormUrlContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	.form-control {
		width: 300px;
	}
`;

const UploadFileContainer = styled.div`
	margin-top:10px;
	display: flex;
	flex-direction: column;

	& .remark {
		margin-top: 10px;
		font-size: 16px;
		color: #939393;
		line-height: 1;
	}
`;

const ButtonUploadFile = styled.button`
	width: 86px;
	height: 86px;
	background-color: white;
	border: 1px dashed #bcbcbc;
	font-size: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	outline: none;
	border-radius: 3px;
	line-height: 1.2;

	&:hover {
		background-color: #fafafa;
	}

	&:focus,
	&:active {
		background-color: #fafafa;
	}

	& i {
		font-size: 20px;
		color: #d90101;
	}
`;

const LineStyle = styled.div`
	height: 0;
	border-bottom: 1px solid #e3e3e3;
	margin: 40px 0px;
`;

const Footer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	& button {
		padding: 10px 20px;
		& i {
			padding-right: 5px;
		}

		&:not(:last-child) {
			margin-right: 20px;
		}
	}
`;

const ButtonStyle = styled(Button)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	min-width: 120px;

	&:disabled:hover {
		color:#000000;
	}
`;

const Loading = styled.div`
	display: flex;
	align-items: center;
	color: #939393;
	padding-left: 10px;
	height: 34px;
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	& .image {
		width: 286px !important;
		height: 86px!important;
		border: 1px solid #E3E3E3;
		overflow: hidden;
		border-radius: 4px;
		position: relative;

		& img {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: -9999px;
			bottom: -9999px;
			left: -9999px;
			right: -9999px;
			margin: auto;
		}
	}

	.image-hover {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #00000080;
		border-radius: 4px;
		z-index: 999;
		width: 100%;
		height: 100%;
	}

	.image:hover {
		.image-hover {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			padding: 10px 0px;
		}
	}

	.btn-hover-image {
		color: white;
		font-size: 16px;
		cursor: pointer;
		padding: 2px;
		display: flex;
		flex-direction: row;
		align-items: center;
		font-weight: normal;

		& i {
			margin-right: 5px;
		}
	}
`;

class BusinessTrackingSetting extends Component {
    constructor(props) {
        super(props)

        let params = new URLSearchParams(document.location.search.substring(1));
        let id = params.get("id");

        this.state = {
			account_info_id: id,
			isUrlNotExists: false,
			isLoadingFetchDataAccountInfo: false,
      		isLoadingCheckTrackingUrl: false,
			isOpenModalAlertImage: false,
			isOpenModalCreateConfirm: false,
			textAlertModalImage: '',
			accountInfoLogo: '',
			accountInfoTrackingUrl: '',
			isOpenLightBox: false,
			isOpenModalDeleteAccountInfoLogo: false,
			isRegister: false,

            bu_number:"",
            bu_name:"",
		};
		
		this._checkTrackingUrlDelay = null;
    	this._inputUploadFile = React.createRef();
    }

    componentWillReceiveProps(newProps) {
		this.setState({ isLoadingFetchDataAccountInfo: true });
		const { loading, error } = newProps.accountInfoListQuery;
		if (!loading && !error) {
			const {
				accountInfoList: { account_info },
				accountInfoList: { account_business },
			} = newProps.accountInfoListQuery;
			const accountBusiness = account_business;

			if (accountBusiness === null) {
				window.location.href = '/giztixbusiness';
				return;
			}

			console.log(accountBusiness)

			this.setState({
				bu_number: accountBusiness.business_req_number,
				bu_name: accountBusiness.account_info_business_name,
				accountInfoLogo: account_info[0].account_info_logo,
				accountInfoTrackingUrl: account_info[0].account_info_tracking_url,
				//isRegister: !!account_info[0].account_info_tracking_url,
				isLoadingFetchDataAccountInfo: false,
			});
		}
	}

	async checkExistsTrackingURL(url, props) {
		try {
			this.setState({ isLoadingCheckTrackingUrl: true });

			const { data } = await client.query({
				query: checkExistsTrackingURLQuery,
				variables: {
					account_info_tracking_url: url,
				},
				fetchPolicy: 'network-only',
			});

			const { succeed } = data.checkExistsTrackingURL;

			if (succeed) {
				props.setFieldError('url', 'ถูกใช้งานแล้ว');
			}

			this.setState({
				isUrlNotExists: !succeed,
				isLoadingCheckTrackingUrl: false,
			});
		} catch (error) {
			this.setState({ isLoadingCheckTrackingUrl: false });
			console.log(error);
		}
  	}
  
	checkFileTypeImage(file) {
		if(file) {
		return file.type === 'image/png' || file.type === 'image/jpeg';
		}
	}

	checkFileSizeImage(file) {
		if(file) {
		const size = parseFloat(file.size / 1024).toFixed(2);
		return size < 2048; //2Mb
		}
	}

  	checkFilePixel(file) {		
		if(file) {
			return new Promise((resolve, reject) => {
				var _URL = window.URL || window.webkitURL;
				const img = new Image();
				img.onload = () => resolve(img.width === 140 && img.height === 40);
				img.onerror = () => reject(false);
				img.src = _URL.createObjectURL(file);
			});
		}
	}
	
	onDeletePhoto() {
		this.setState({
			accountInfoLogo: "",
		});
	}

    render() {
		const validateSchema = Yup.object().shape({
			url: Yup.string()
				.matches(/^[a-zA-Z0-9]{1,20}$/, 'รูปแบบไม่ถูกต้อง')
				.required('กรุณากรอกข้อมูล'),
		});
        return (
            <Container >
				<HeaderContainer>
                    <div className="sub-header-left">
                        <a href="/giztixbusiness" className="btn-back"><i className="far fa-arrow-left"></i> กลับ</a>
                        <div className="line"></div>
                        <div className="menu-wrapper">
							<ul>
								<li>
									<a href={`/giztixbusinessdetail?id=${this.state.account_info_id}`}> <i className="fal fa-address-card"></i> ข้อมูลการลงทะเบียน</a>
								</li>
								<li>
									<a href={`/giztixbusinessshipment?id=${this.state.account_info_id}`}> <i className="fal fa-truck"></i> รายการขนส่งของบริษัท</a>
								</li>
								<li>
									<a href={`/giztixbusinesspayment?id=${this.state.account_info_id}`}> <i className="fal fa-money-check-alt"></i> ค่าใช้จ่ายของบริษัท</a>
								</li>
								<li className="active">
									<a href={`/giztixbusinesstrackingsetting?id=${this.state.account_info_id}`}> <i className="fal fa-box-heart"></i> จัดการหน้าติดตามงานขนส่ง</a>
								</li>
                                <li>
                                    <a href={`/giztixbusinessdriver?id=${this.state.account_info_id}`}> <i className="fal fa-user-hard-hat"></i> พนักงานขับรถคนโปรด</a>
                                </li>
							</ul>
                        </div>
                    </div>
                </HeaderContainer>
                <SubHeaderContainer>
                    <div className="sub-header-left">
                        <strong>BU{this.state.bu_number} : { this.state.bu_name }</strong>
                    </div>
                </SubHeaderContainer>
            
				
                <Grid fluid style={{paddingTop:5}}>
                    <Row>
                        <Col md={12}>
                            <CardTable
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div>
										<Formik
											initialValues={{
												url: this.state.accountInfoTrackingUrl,
											}}
											validationSchema={validateSchema}
											onSubmit={(values, props) => {
												this.checkExistsTrackingURL(values.url, props);
											}}
											enableReinitialize={true}
										>
											{props => {
												const {
													values,
													errors,
													touched,
													setFieldValue,
													setFieldTouched,
													handleSubmit,
												} = props;
												return (
													<React.Fragment>
														<TextHeader>จัดการหน้าติดตามงานขนส่ง</TextHeader>
														<TextSubHeader>สำหรับจัดการหน้าติดตามงานขนส่ง</TextSubHeader>
														
														{
															this.state.isLoadingFetchDataAccountInfo && (
																<Loading style={{display: 'flex', justifyContent: 'center' }}>
																	<i className="fal fa-spinner-third fa-spin" />
																</Loading>
															)
														}

														{
															!this.state.isLoadingFetchDataAccountInfo && (
																<React.Fragment>
																	<Label>
																		<strong>ตั้งชื่อ URL ของคุณ</strong>
																		<Required>*</Required>
																		{` `}
																	</Label><br/>

																	(ไม่เกิน 20 ตัวอักษร) ประกอบด้วยตัวอักษร A-Z หรือตัวเลข 0-9
																	และห้ามใช้เครื่องหมายหรืออักขระพิเศษ เช่น !@#$% :

																	<FormUrlContainer>
																		<InputGroup>
																			<InputGroup.Addon style={{ fontSize: 16 }}>
																				app.giztix.com/tracking/
																			</InputGroup.Addon>
																			<FormControl
																				type="text"
																				placeholder="ระบุ URL"
																				value={values.url}
																				onChange={event => {
																					setFieldValue('url', event.target.value);
																					setFieldTouched('url', true);
																					this.setState({ isUrlNotExists: false });
																					clearTimeout(this._checkTrackingUrlDelay);
																					this._checkTrackingUrlDelay = setTimeout(
																						() => {
																							handleSubmit();
																						},
																						500,
																					);
																				}}
																				maxLength={20}
																				minLength ={1}
																				disabled={this.state.isRegister}
																			/>
																		</InputGroup>

																		{errors.url && touched.url && (
																			<MessageInput
																				textColor="#d90402"
																				iconColor="#d90402"
																			>
																				<i className="fas fa-times-circle" />{' '}
																				<div>{errors.url}</div>
																			</MessageInput>
																		)}

																		{this.state.isUrlNotExists && (
																			<MessageInput
																				textColor="#E30301"
																				iconColor="#E70301"
																			>
																				<i className="fas fa-check-circle" />{' '}
																				<div>ใช้งานได้</div>
																			</MessageInput>
																		)}

																		{this.state.isLoadingCheckTrackingUrl && (
																			<Loading>
																				<i className="fal fa-spinner-third fa-spin" />
																			</Loading>
																		)}
																	</FormUrlContainer>
																	<Remark>
																		*หมายเหตุ การตั้งชื่อ URL
																		ของหน้าติดตามงานขนส่งของคุณจะตั้งได้เพียงครั้งเดียวเท่านั้น
																		กรุณาตรวจสอบข้อมูลให้ถูกต้อง{' '}
																	</Remark>
																	<Label style={{ marginTop: 20 }}>
																		รูปภาพโลโก้ของบริษัท:
																	</Label>

																	<Mutation
																		mutation={accountInfoUpdateMutation}
																	>
																		{
																			(accountInfoUpdateLogo) => {
																				return (
																					<UploadFileContainer>
																						<UploadFile
																							completed={(path) => {
																								accountInfoUpdateLogo({
																									variables: {
																										_id:this.state.account_info_id,
																										account_info_logo: path,
																									}
																								}).then(() => {
																									this.setState({
																										accountInfoLogo: path,
																									});
																								});
																							}}
																						>
																							{
																								(selectFile) => {
																									return (
																										<React.Fragment>
																											<input
																												accept="image/jpeg, image/png"
																												onChange={async (event) => {
																														event.persist();
																														const files = event.target.files[0];
																														
																														const checkFileTypeImage = this.checkFileTypeImage(files);
																														if(checkFileTypeImage === false) {
																															this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png, jpg เท่านั้น' });
																															return;
																														}

																														const checkFilePixel = await this.checkFilePixel(files);
																														if(checkFilePixel === false) {
																															this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาด (กว้าง x สูง) 140 x 40 พิกเซล' });
																															return;
																														}

																														const checkFileSize = this.checkFileSizeImage(files);
																														if(checkFileSize === false) {
																															this.setState({ isOpenModalAlertImage: true, textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 2MB' });
																															return;
																														}

																														selectFile(event, `upload/profile/trackingLogo_${new Date().getTime()}`);
																												}}
																												type="file" id="file"
																												ref={this._inputUploadFile}
																												style={{display: "none"}}
																											/>

																											{
																												this.state.accountInfoLogo && (
																													<ImageContainer>
																														<div className="image">
																															<div className="image-hover">
																																<div className="btn-hover-image" onClick={() => {
																																	this.setState({
																																		isOpenLightBox: true,
																																	});
																																}}>
																																	<i className="fal fa-search"></i><span>ดูรูปภาพ</span>
																																</div>
																																<div className="btn-hover-image" onClick={() => {
																																	this.setState({ isOpenModalDeleteAccountInfoLogo: true })
																																}}>
																																	<i className="fal fa-trash-alt"></i><span>ลบรูปภาพ</span>
																																</div>
																															</div>
																															<img src={`${s3}/${this.state.accountInfoLogo}`} alt="" />
																														</div>
																													</ImageContainer>
																												)
																											}

																											{ this.state.isOpenLightBox && (
																												<Lightbox
																													mainSrc={`${s3}/${this.state.accountInfoLogo}`}
																													onCloseRequest={() => this.setState({ isOpenLightBox: false })}
																												/>
																											)}

																										</React.Fragment>
																									)
																								}
																							}
																						</UploadFile>

																						{
																							!this.state.accountInfoLogo && (
																								<ButtonUploadFile
																									onClick={() => this._inputUploadFile.current.click()}
																								>
																									<i className="fal fa-plus-circle" />
																									เพิ่มรูปภาพ
																								</ButtonUploadFile>
																							)
																						}
																						
																						<div className="remark">
																							ขนาด 140 x 40 pixel
																							<br />
																							ไม่เกิน 2 Mb
																						</div>
																					</UploadFileContainer>
																				)
																			}
																		}
																	</Mutation>

																	<ModalConfirm
																		show={this.state.isOpenModalAlertImage}
																		onPressButtonConfirm={() => this.setState({ isOpenModalAlertImage: false })}
																		styleColorButtonConfirm="primary"
																		labelButtonConfirm={`ตกลง`}
																		titleHeader={`แจ้งเตือน`}
																		onHide={() => this.setState({ isOpenModalAlertImage: false })}
																	>
																		{ this.state.textAlertModalImage }
																	</ModalConfirm>

																	<Mutation
																		mutation={accountInfoUpdateMutation}
																		variables={{
																			_id:this.state.account_info_id,
																			account_info_tracking_url: values.url,
																			account_info_logo: this.state.accountInfoLogo,
																		}}
																		update={(cache, { data }) => {
																			if(data.accountInfoUpdate._id) {
																				this.setState({ isUrlNotExists: false, });
																				this.fetchAccountInfoList();
																			}
																		}}
																	>
																		{
																			(accountInfoUpdate, { loading }) => {
																				return (				
																					<ModalConfirm
																						show={this.state.isOpenModalCreateConfirm}
																						onPressButtonConfirm={async () => {
																							this.setState({ isUrlNotExists: false });
																							await this.checkExistsTrackingURL(values.url, props);
																							if(!this.state.isUrlNotExists || errors.url) {
																								this.setState({ isOpenModalCreateConfirm: false });
																							}

																							accountInfoUpdate().then(() => {
																								this.setState({ isOpenModalCreateConfirm: false });
																							});
																						}}
																						styleColorButtonConfirm="primary"
																						labelButtonConfirm={loading ? 'ใช้ URL นี้...' : 'ใช้ URL นี้'}
																						labelButtonCancel={`ยกเลิก`}
																						onPressButtonCancel={() => this.setState({ isOpenModalCreateConfirm: false })}
																						titleHeader={`ยืนยันการสร้างหน้าติดตามงานขนส่งหรือไม่?`}
																						onHide={() => this.setState({ isOpenModalCreateConfirm: false })}
																						disabledButtonConfirm={loading}
																					>
																						<div style={{lineHeight: 1}}>
																							<div>
																								*หมายเหตุ การตั้งชื่อ URL ของหน้าติดตามงานขนส่งของคุณ จะตั้งได้เพียงครั้งเดียวเท่านั้น
																								<br/>กรุณาตรวจสอบข้อมูลให้ถูกต้อง 
																							</div>
																							<div style={{marginTop: 20}}>
																								URL: <strong>{`app.giztix.com/tracking/${values.url}`}</strong>
																							</div>
																						</div>
																					</ModalConfirm>
																				)
																			}
																		}
																	</Mutation>
																	
																	<Mutation
																		mutation={accountInfoUpdateMutation}
																		variables={{
																			_id:this.state.account_info_id,
																			account_info_logo: '',
																		}}
																	>
																		{
																			(accountInfoUpdateLogo) => {
																				return (
																					<ModalConfirm
																						show={this.state.isOpenModalDeleteAccountInfoLogo}
																						onPressButtonConfirm={() => {
																							accountInfoUpdateLogo().then(() => {
																								this.onDeletePhoto();
																								this.setState({ isOpenModalDeleteAccountInfoLogo: false });
																							});
																						}}
																						styleColorButtonConfirm="primary"
																						labelButtonConfirm={`ลบรูปภาพ`}
																						onPressButtonCancel={() => this.setState({ isOpenModalDeleteAccountInfoLogo: false })}
																						labelButtonCancel={`ปิด`}
																						titleHeader={`แจ้งเตือน`}
																						onHide={() => this.setState({ isOpenModalDeleteAccountInfoLogo: false })}
																					>
																						<div>คุณต้องการลบรูปภาพโลโก้ของบริษัท ใช่หรือไม่?</div>
																					</ModalConfirm>
																				)
																			}
																		}
																	</Mutation>

																	{
																		!this.state.isRegister && (
																			<React.Fragment>
																				<LineStyle />
																				<Footer>
																					<ButtonStyle
																						bsStyle="primary"
																						disabled={!this.state.isUrlNotExists}
																						onClick={async () => {
																							this.setState({ isOpenModalCreateConfirm: true });
																						}}
																					>
																						สร้างหน้าติดตามงานขนส่ง
																					</ButtonStyle>
																				</Footer>
																			</React.Fragment>
																		)
																	}
																</React.Fragment>
															)
														}
													</React.Fragment>
												);
											}}
										</Formik>
									</div>
                                    }
                                />
                            </Col>
                    </Row>
                </Grid>
            </Container>
        );
    }
}

export default compose(graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: props => ({
        fetchPolicy: 'network-only',
        variables: {
            _id: props.location.search.split('?id=')[1],
        },
    }),
    }))(BusinessTrackingSetting);