import gql from 'graphql-tag';

export default gql`
  mutation paymentOrderUpdate(
    $_id: ID!
    $payment_order_status: Int
    $payment_order_remark: String
    $round_paid_date: String
  ) {
    paymentOrderUpdate(
      _id: $_id
      payment_order_status: $payment_order_status
      payment_order_remark: $payment_order_remark
      round_paid_date: $round_paid_date
    ) {
      _id
    }
  }
`;
