export const FunctionOpenInNewTab = url => {
  if (url) {
    return window?.open(url, '_blank');
  } else {
    return;
  }
};

export const FunctionRenameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
