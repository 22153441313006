import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import lambdaUrl from 'config/lambdaUrl';
import restBaseUrl from 'config/restBaseUrl';
import Form from './Form';
import { FunctionGetOrderTypeWhenTripLoy, orderType } from './constant/orderType';
import adminListQuery from '../../graphql/query/adminList';
import { client } from '../../../../../../index';

const ModalStyle = styled(Modal)`
  & .modal-dialog {
    width: 100%;
    max-width: 600px;
  }
`;

const ModalHeader = styled(Modal.Header)`
  & button.close {
    font-size: 30px;
  }
`;

const pickupAndDeliverySchema = Yup.object({
  tagName: Yup.string().required('กรุณากรอกข้อมูล'),
  address: Yup.string().required('กรุณากรอกข้อมูล'),
  province: Yup.string().nullable(),
  district: Yup.string().nullable(),
  subDistrict: Yup.string().nullable(),
  postCode: Yup.string().nullable(),
  lat: Yup.string().nullable(),
  lng: Yup.string().nullable(),
  contactName: Yup.string().nullable(),
  contactTel: Yup.string().nullable(),
  warehouseType: Yup.string().nullable(),
  wmsBranch: Yup.string().nullable(),
  totalOrder: Yup.number().nullable(),
  customerId: Yup.string().nullable(),
  active: Yup.boolean().required(),
});

const orderTypeCreateNotImport = [
  orderType.LAST_MILE,
  orderType.FIRST_MILE,
  orderType.SHUTTLE_HAUL,
  orderType.REJECT_RETURN,
  orderType.DIRECT_SHIP,
  orderType.LINE_HAUL,
  orderType.BU_FIRST_MILE,
];

const schema = Yup.object({
  orderType: Yup.string().required('กรุณาเลือกประเภทงาน'),
  tripName: Yup.string().required('กรุณาระบุรอบการจัดส่งให้ถูกต้อง'),
  planDate: Yup.string().required('กรุณาระบุวันที่'),
  referenceNo: Yup.mixed().when('orderType', {
    is: value => {
      // console.log('value : ', { value, boolean: value === 0 || value === '0', type: typeof value });
      return value === 0 || value === '0';
    },
    then: Yup.object({
      name: Yup.string().required('กรุณาระบุเลขที่เอกสาร'),
      id: Yup.string().required('กรุณาระบุเลขที่เอกสาร'),
    }),
  }),
  pickupDate: Yup.string().when('orderType', {
    is: value => orderTypeCreateNotImport.some(item => item === value),
    then: Yup.string().required('กรุณาระบุวันที่'),
  }),
  truckType: Yup.string().when('orderType', {
    is: value => orderTypeCreateNotImport.some(item => item === value),
    then: Yup.string().required('กรุณาเลือกประเภทรถ'),
  }),
  agentId: Yup.string().when('orderType', {
    is: value => orderTypeCreateNotImport.some(item => item === value),
    then: Yup.string().required('กรุณาเลือกนายหน้า'),
  }),
  shipments: Yup.array().when('orderType', {
    is: value => orderTypeCreateNotImport.some(item => item === value),
    then: Yup.array()
      .of(
        Yup.object({
          pickup: Yup.array()
            .of(pickupAndDeliverySchema)
            .min(1, 'กรุณาเพิ่มจุดรับสินค้า'),
          delivery: Yup.array().of(pickupAndDeliverySchema),
        })
      )
      .when('orderType', {
        is: value =>
          value === orderType.FIRST_MILE ||
          value === orderType.SHUTTLE_HAUL ||
          value === orderType.LINE_HAUL ||
          value === orderType.REJECT_RETURN,
        then: Yup.array().of(
          Yup.object({
            pickup: Yup.array()
              .of(pickupAndDeliverySchema)
              .min(1, 'กรุณาเพิ่มจุดรับสินค้า'),
            delivery: Yup.array()
              .of(pickupAndDeliverySchema)
              .min(1, 'กรุณาเพิ่มจุดส่งสินค้า'),
          })
        ),
      })
      .min(1, 'กรุณากรอกข้อมูล'),
    otherwise: Yup.array(),
  }),
  file: Yup.object().when('orderType', {
    is: value => value === orderType.RETURN || value === orderType.RETURN_TO_MERCHANT,
    then: Yup.object({
      path: Yup.string().required('กรุณาอัพโหลดไฟล์'),
      fileName: Yup.string().required('กรุณาอัพโหลดไฟล์'),
    }),
  }),
  amount: Yup.string().when('orderType', {
    is: value => value === orderType.LAST_MILE,
    then: Yup.string()
      .required('กรุณากรอกข้อมูล')
      .matches(/^[1-9]+[\d]*$/, 'รูปแบบไม่ถูกต้อง'),
  }),
});

const initialValue = {
  orderType: '',
  tripName: '',
  planDate: '',
  pickupDate: '',
  truckType: '',
  agentId: '',
  shipments: [
    {
      pickup: [],
      delivery: [],
    },
  ],
  path: '',
  amount: '1',
};

const ModalCreateBooking = props => {
  const { open, onClose, onCompleted } = props;
  const [isLoading, setLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues: {
      ...initialValue,
    },
  });

  const { reset, handleSubmit, watch } = methods;
  const watchOrderType = watch('orderType');
  const watchrefNumber = watch('referenceNo');

  useEffect(() => {
    reset({
      ...initialValue,
    });
  }, [open]);

  const getAdminId = async () => {
    const { data } = await client.query({
      query: adminListQuery,
    });

    return data?.adminList?.admin?.[0]?._id || null;
  };

  const onSubmitImportFile = handleSubmit(async values => {
    setLoading(true);

    console.log('ยืนยันการสร้างงานขนส่ง', values);
    try {
      const adminId = await getAdminId();
      if (!adminId) {
        alert('ไม่พบ Admin');
        return;
      }
      const url = `${lambdaUrl}/lazada-import-order?path=${values.file.path}&name=${
        values.file.fileName
      }&admin_id=${adminId}&order_type=${
        values.orderType === '0'
          ? FunctionGetOrderTypeWhenTripLoy(watchrefNumber)
          : +values.orderType
      }&plan_date=${values.planDate}&trip_name=${values.tripName}&shipment_ref_id=${
        watchrefNumber?.id ? watchrefNumber?.id : ''
      }&trip_rloy=${String(watchrefNumber?.id)?.length === 24 ? true : false}`;
      await axios.get(url).then(res => {
        console.log('ยืนยันการสร้างงานขนส่ง 500', res);
        if (res.data.statusCode === 500) {
          throw new Error(`${lambdaUrl}/lazada-import-order` + res.data?.messageError);
        }

        if (!res.data._id) {
          alert('มีรอบการจัดส่งซ้ำ');
          return;
        }

        onCompleted && onCompleted();
      });
    } catch (err) {
      alert(err.message);
    } finally {
      setLoading(false);
    }
  });

  const onSubmitShipments = handleSubmit(async values => {
    setLoading(true);

    try {
      const adminId = await getAdminId();
      if (!adminId) {
        alert('ไม่พบ Admin');
        return;
      }

      const data = JSON.stringify({
        admin_id: adminId,
        order_type:
          values.orderType === '0'
            ? FunctionGetOrderTypeWhenTripLoy(watchrefNumber)
            : +values.orderType,
        plan_date: values.planDate,
        trip_name: values.tripName,
        pickup_date: values.pickupDate,
        truck_type: values.truckType,
        agent: values.agentId,
        shipment_ref_id: values?.referenceNo?.id || '',
        trip_rloy: values?.referenceNo?.id?.length === 24 ? true : false,
        shipments: values.shipments.map(shipment => ({
          pickup: shipment.pickup.map(pickupItem => ({
            tag_name: pickupItem.tagName,
            address: pickupItem.address,
            province: pickupItem.province,
            dictrict: pickupItem.district,
            subdictrict: pickupItem.subDistrict,
            postcode: pickupItem.postCode,
            lat: pickupItem.lat,
            lng: pickupItem.lng,
            contact_name: pickupItem.contactName,
            contact_tel: pickupItem.contactTel,
            warehouse_type: pickupItem.warehouseType,
            active: pickupItem.active,
            wms_branch: pickupItem.wmsBranch,
          })),
          delivery: shipment.delivery.map(deliveryItem => ({
            tag_name: deliveryItem.tagName,
            address: deliveryItem.address,
            province: deliveryItem.province,
            dictrict: deliveryItem.district,
            subdictrict: deliveryItem.subDistrict,
            postcode: deliveryItem.postCode,
            lat: deliveryItem.lat,
            lng: deliveryItem.lng,
            contact_name: deliveryItem.contactName,
            contact_tel: deliveryItem.contactTel,
            warehouse_type: deliveryItem.warehouseType,
            active: deliveryItem.active,
            wms_branch: deliveryItem.wmsBranch,
          })),
        })),
      });

      await axios
        .post(`${lambdaUrl}/create-book-ka-ship`, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          if (res.data.statusCode === 500) {
            throw new Error(`${lambdaUrl}/create-book-ka-ship ` + res.data?.messageError);
          }

          if (res.data.statusCode === 200) {
            onCompleted && onCompleted();
          }
        });
    } catch (err) {
      alert(err.message);
    } finally {
      setLoading(false);
    }
  });

  return (
    <ModalStyle show={open} onHide={() => onClose && onClose()}>
      <ModalHeader closeButton>
        <Modal.Title>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 24,
            }}
          >
            Create Booking
          </div>
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <FormProvider {...methods}>
          <Form />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose && onClose()}>ยกเลิก</Button>
        <Button
          disabled={isLoading}
          bsStyle="primary"
          onClick={() => {
            let type = '';

            if (watchrefNumber) {
              type = watchrefNumber.name.split('_')[1];
            }
            if (
              watchOrderType === orderType.RETURN ||
              watchOrderType === orderType.RETURN_TO_MERCHANT ||
              type === 'RT' ||
              type === 'RM'
            ) {
              onSubmitImportFile();
              return;
            }

            onSubmitShipments();
          }}
        >
          ยืนยันการสร้างงานขนส่ง{isLoading ? '...' : ''}
        </Button>
      </Modal.Footer>
    </ModalStyle>
  );
};

export default ModalCreateBooking;
