import gql from 'graphql-tag';

export default gql`
	mutation accountUserUpdate(
        $account_info_id: ID
        $_id: ID
		$account_user_status: Int
		$account_user_password: String
		$account_user_display_picture : String
	) {
        accountUserUpdate(
            account_info_id : $account_info_id
            _id : $_id
            account_user_status: $account_user_status
			account_user_password: $account_user_password
			account_user_display_picture: $account_user_display_picture
		){
	    _id
	  }
	}
`;
