import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1;

  & thead > tr,
  & tbody > tr {
    border-bottom: 1px solid #e8e8e8;
  }

  & thead {
    & tr > th {
      color: #8d8d8d;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  & th,
  & td {
    padding: 8px;
  }

  & th:nth-child(1) {
    padding-right: 10px;
  }

  & th:nth-child(2) {
    padding-right: 10px;
  }

  & .text-right {
    text-align: right;
  }
`;

const Table = ({ children, ...otherProps }) => {
  return <StyledTable {...otherProps}>{children}</StyledTable>;
};

export default Table;
