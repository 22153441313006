import React, { Component } from 'react';
import Moment from 'react-moment';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import {
  Row,
  Col,
  Table,
  OverlayTrigger,
  Button,
  FormControl,
  NavDropdown,
  NavItem, 
  Checkbox,
  Popover,
} from 'react-bootstrap';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import { Layout } from '../../components';
import styled from 'styled-components';
import classesLazada from '../../Lazada.module.css';
import { client } from '../../../../index';
import DO_NOTHING_MUTATION from './graphql/mutation/doNothing';
import PlanFromDateModal from './container/PlanFromDateModal'
import PlanFromOrder from './container/PlanFromOrderModal'
import ReactPaginate from 'react-paginate';
import FMCRImportModal from './container/FMCRCreateImportModal'

//Query
import lazadaPackageOrdersListQuery from './graphql/query/lazadaPackageOrdersList';
import lazadaPackageOrderStatusQuery from './graphql/query/lazadaPackageOrderStatus'

const CustomCheckbox = styled(Checkbox)`
padding-left: 35px !important;
margin-top: 12px;

& input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
}

&:hover input ~ .checkmark {
  background-color: #f1f1f1;
}

& input:checked ~ .checkmark {
  border: 1px solid #ffffff;
  background-color: #19aa19;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

& input:checked ~ .checkmark:after {
  display: block;
}

& .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
`;

const AboveWrapper = styled.div`
  background-color: #fff;
  padding: 10px;
`

const BoxDashboard = styled.div`
  height: 90px;
  min-width: 175px;
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  :not(:first-child){
    margin-left: 10px;
  }

  h1 {
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
  }

`

const BoxWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &:hover::-webkit-scrollbar{
    display: block;
  }

  &:hover {
    -ms-overflow-style: block;  /* IE and Edge */
    scrollbar-width: block;  /* Firefox */
  }

  & ${BoxDashboard}.black {
    background: #000;
    border-color: #000;
    color: #fff;
  }

  & ${BoxDashboard}.yellow {
    background: #E89C37;
    border-color: #E89C37;
    color: #fff;
  }

  & ${BoxDashboard}.blue {
    background: #337AB7;
    border-color: #337AB7;
    color: #fff;
  }

  & ${BoxDashboard}.red {
    background: #C63B3C;
    border-color: #C63B3C;
    color: #fff;
  }

  & ${BoxDashboard}.emerald {
    background: #82c5b9;
    border-color: #82c5b9;
    color: #fff;
  }

  & ${BoxDashboard}.green {
    background: #81b214;
    border-color: #81b214;
    color: #fff;
  }

  & ${BoxDashboard}.velvet {
    background: #d54062;
    border-color: #d54062;
    color: #fff;
  }
`
const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  margin-bottom: 10px;

  strong {
    font-weight: bold;
  }
`

const FormatStatus = styled.div`
  background: #fff;
  color: #000;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 70%;
  font-weight: bold;

  &.none{
    border: 2px solid black;
  }

  &.black {
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
  }

  &.blue {
    background: #337AB7;
    color: rgb(255, 255, 255);
  }

  &.red {
    background: #C63B3C;
    color: rgb(255, 255, 255);
  }

  &.yellow {
    background: #E89C37;
    color: rgb(255, 255, 255);
  }

  &.green {
    background: #81b214;
    color: rgb(255, 255, 255);
  }

  &.emerald{
    background: #82c5b9;
    color: rgb(255, 255, 255);
  }

  &.velvet{
    background: #d54062;
    color: rgb(255, 255, 255);
  }
`

class BookingFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cutOffList: [],
      page: 1,
      totalDoc: 0,
      checked: [],

      //Filter
      trackingNumber: '',

      zoneCode: '',
      
      status: 0,
      
      packageCode: '',
      
      planDate: '',
      displayPlanDate: '',
      
      productItem: '',
      
      createDate: '',
      displayCreateDate: '',
      
      lastUpdate: '',
      displayLastUpdate: '',

      slaPickup: '',
      displaySlaPickup: '',

      slaDelivery: '',
      displaySlaDelivery: '',

      //modal
      isPlanFromDateModal: false,
      isPlanFromOrderModal: false,
      isOpenFMCRImportFileMOdal: false,

      //sort
      order_by: 'create_date',
      order_type: '-1',
    };
  }

  componentDidMount() {
    // this.fetchlazadaPackageOrders()
  }

  fetchlazadaPackageOrders = async () => {
    try {
      const { data } = await client.query({
        query: lazadaPackageOrdersListQuery,
        variables: {
          page: 1,
          showData: 15
        }
      });

      console.log('fetchlazadaPackageOrders', data)

      if(data && data.lazadaPackageOrders && data.lazadaPackageOrders.orders.length > 0){
        // console.log('fetchlazadaPackageOrders: ', data.lazadaImportOrderList.order)
        this.setState({
          cutOffList: [...data.lazadaPackageOrders.orders],
          totalDoc: data.lazadaPackageOrders.totalDocument
        })
      }

    } catch (error) {
      alert(error);
    }
  }

  formatMoney = number => {
    return `${
        number.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        })
        } บาท`;
  };

  formatNumber = number => {
    return `${
        number.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        })
        }`;
  };

  fetchMorelazadaPackageOrders = async () => {
    try {
      const { data: moreData } = await client.query({
        query: lazadaPackageOrdersListQuery,
        variables: {
          page: this.state.page + 1,
          showData: 15
        }
      });

      console.log('fetchMorelazadaPackageOrders', moreData)

      if(moreData && moreData.lazadaPackageOrders && moreData.lazadaPackageOrders.orders.length > 0){
        // console.log('fetchlazadaPackageOrders: ', moreData.lazadaImportOrderList.order)
        this.setState(prevState => ({
          cutOffList: [...prevState.cutOffList, ...moreData.lazadaPackageOrders.orders],
          page: prevState.page + 1
        }))
      }

    } catch (error) {
      alert(error);
    }
  }

  reFetchlazadaPackageOrders = async () => {
    try {
      const { data: moreData } = await client.query({
        query: lazadaPackageOrdersListQuery,
        variables: {
          page: 1,
          showData: this.state.cutOffList.length
        }
      });

      console.log('reFetchlazadaPackageOrders', moreData)

      if(moreData && moreData.lazadaPackageOrders && moreData.lazadaPackageOrders.orders.length > 0){
        // console.log('fetchlazadaPackageOrders: ', moreData.lazadaImportOrderList.order)
        this.setState(prevState => ({
          cutOffList: [...moreData.lazadaPackageOrders.orders],
          checked: []
        }))
      }

    } catch (error) {
      alert(error);
    }
  }


  refetchQueries = () => {
    setTimeout(() => {
      client.mutate({ mutation: DO_NOTHING_MUTATION, refetchQueries: () => ['LazadaImportOrderListBookingFile'] });
    }, 500);
  };

  handlerClickCheckBox = (packageData) => {
    if(this.state.checked.filter(data => data._id === packageData._id).length > 0 ){
      this.setState(prevState => {
        return ({
          checked: prevState.checked.filter(data => data._id !== packageData._id)
        })
      })
    }else{
      this.setState(prevState => ({
        checked: [...prevState.checked, packageData]
      }))
    }
  }

  handlerCheckAll = () => {
    if(this.state.checked.length > 0){
      this.setState({
        checked: []
      })
    }else{
      const newCheck = this.state.cutOffList.filter(data => data.giztix_package_status == 1)
      this.setState({
        checked: newCheck
      })
    }
  }

  renderCheckCheckBox = (packageData) => {
    return this.state.checked.filter(data => data._id === packageData._id).length > 0
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
        if (this.state.order_type == 1) {
            return "sorting_asc";
        }
        else {
            return "sorting_desc";
        }
    }

    return "sorting";
}

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
        this.setState({ order_type: this.state.order_type * -1 });
    }
    else {
        this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderStatus = (status) => {
    switch(status){
      case 1:
        return <FormatStatus className="black">คงคลัง Lazada</FormatStatus>
      case 2:
        return <FormatStatus className="none">แพลนไปรับสินค้า</FormatStatus>
      case 3:
        return <FormatStatus className="yellow" >กำลังไปคลัง GSC</FormatStatus>
      case 4:
        return <FormatStatus className="none">Failed นำสินค้าออกจาก Lazada</FormatStatus>
      case 5:
        return <FormatStatus className="blue">คงคลัง Giztix</FormatStatus>
      case 6:
        return <FormatStatus className="red">ยกเลิก</FormatStatus>
      case 7:
        return <FormatStatus className="emerald">ออกจัดส่งลูกค้า</FormatStatus>
      case 8:
        return <FormatStatus className="green">ส่งสำเร็จ</FormatStatus>
      case 9:
        return <FormatStatus className="velvet">คืนคลัง Lazada</FormatStatus>
      default:
        return <div> - </div>
    }
  }

  handleDateRangePicker(mode, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'lastUpdate') {
      this.setState({ lastUpdate : date, displayLastUpdate: display });
    }
    else if (mode === 'slaPickup') {
      this.setState({ slaPickup : date, displaySlaPickup: display });
    }
    else if (mode === 'slaDelivery') {
      this.setState({ slaDelivery : date, displaySlaDelivery: display });
    }
    else if (mode === 'planDate') {
      this.setState({ planDate : date, displayPlanDate: display });
    }
  }

  calTimeLeft = (date, mode) => {
    const timeTillDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    const timeFormat="YYYY-MM-DD HH:mm:ss"

    const then = moment(timeTillDate, timeFormat) ;
    const timeLeft = then.diff(moment(), 'second');

    if(timeLeft <= 0){
      if(mode == 0){
        return moment(timeTillDate).format('DD/MM/YYYY HH:mm:ss')
      }else{
        return <b style={{color: 'red'}}>{moment(timeTillDate).format('DD/MM/YYYY HH:mm:ss')}</b>
      }
    }

    const formatTime = moment.duration(timeLeft, 'second').format(`DD [วัน] HH [ชั่วโมง] mm [นาที]`)

    return formatTime
  }

  calTimeStatus = (date) => {
    const timeTillDate = moment(date).format('YYYY-MM-DD HH:mm:ss')
    const timeFormat="YYYY-MM-DD HH:mm:ss"

    const then = moment(timeTillDate, timeFormat) ;
    const timeLeft = then.diff(moment(), 'second');

    console.log('timeLeft: ',timeLeft)

    if(timeLeft <= 10800){
      return  <span style={{color: 'red'}}>{moment(timeTillDate).format('DD/MM/YYYY HH:mm:ss')}</span>
    }
  }

  render() {
    console.log(this.state)
    return (
      <Layout>
        <Row>
          <Col md={12}>
            <div>
              <div>
              <CardTable
                style={{borderTop: 'none'}}
                title=""
                category={``}
                ctTableFullWidth
                ctTableResponsive
                rightButton={
                  <div style={{ float: 'right', marginLeft: '15px' }}>

                    <Button style={{background:"#87b792",color:"#fff",marginRight:"15px", display: 'none'}} onClick={() => this.setState({isPlanFromDateModal: true})}>
                      <i className="fas fa-cloud-upload"></i> แพลนรับสินค้าจากวันที่สร้าง
                    </Button>

                    <Button style={{background:"#c19970",color:"#fff", display: 'none' }} onClick={() => this.setState({ isPlanFromOrderModal : true})} disabled={this.state.checked.length === 0} >
                      <i className="fas fa-cloud-upload"></i> แพลนรับสินค้า
                    </Button>
                  </div>
                }
                content={
                  <Query
                    query={lazadaPackageOrdersListQuery}
                    variables={{
                      params:{
                        trackingNumber: this.state.trackingNumber,
                        packageCode: this.state.packageCode,
                        create_date: this.state.createDate,
                        last_update: this.state.lastUpdate,
                        plan_date: this.state.planDate,
                        pickup_sla_date: this.state.slaPickup,
                        delivery_sla_date: this.state.slaDelivery,
                        giztix_package_status: this.state.status,
                        delivery_zone: this.state.zoneCode
                      },
                      showData: 30,
                      page: this.state.page,
                      sort: {
                        order_by: this.state.order_by,
                        order_type: this.state.order_type
                      },
                    }}
                    fetchPolicy="cache-and-network"
                  >
                  {({ data, loading, error, refetch }) => {
                    return (
                      <div>

                        <AboveWrapper>
                          <FilterWrapper>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
                              <strong style={{marginRight: '10px'}}>สถานะรายการสินค้า:</strong> วันที่
                              <DateRangePicker
                                  onApply={(event, picker) =>
                                    this.handleDateRangePicker('createDate', picker)
                                  }
                                  onCancel={() =>
                                    this.setState({
                                      createDate: '',
                                      displayCreateDate: '',
                                      page: 1,
                                    })
                                  }
                                  containerStyles={{ display: 'block', marginLeft: '10px' }}
                                  opens="right"
                                  format="DD/MM/YYYY"
                                >
                                  <FormControl
                                    type="text"
                                    value={this.state.displayCreateDate}
                                    placeholder="วันที่รับข้อมูล"
                                  />
                                </DateRangePicker>
                              </div>  
                              
                              <div style={{display: 'flex'}} >
                                <Button style={{background:"#000",color:"#fff", display: 'flex',marginRight:"10px", alignItems: 'center'}} onClick={() => this.setState({isOpenFMCRImportFileMOdal: true})} >
                                  <i style={{marginRight: '5px'}} className="far fa-plus-circle"></i>LH-Request
                                </Button>
                                <Button style={{background:"#c19970",color:"#fff",marginRight:"15px", display: 'flex', alignItems: 'center'}} onClick={() => window.open('/lazada-parcel-flight')} >
                                  <i style={{marginRight: '5px'}} className="fas fa-truck-moving"></i>Parcel Dashboard
                                </Button>
                              </div>
                          </FilterWrapper>
          
                          <Query
                            query={lazadaPackageOrderStatusQuery}
                            variables={{
                                create_date: this.state.createDate,
                            }}
                            fetchPolicy="cache-and-network"
                          >
                          {({ data: orderStatusData, loading: loadingStatusData, error: errorStatusData, refetch: refetchStatusData }) => {
                            console.log('orderStatusData', orderStatusData)
                            return (
                              <BoxWrapper>
                                <BoxDashboard >
                                  <h3>ทั้งหมด</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.total ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.total) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>
              
                                <BoxDashboard className="black">
                                  <h3>คงคลัง Lazada</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.new ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.new) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>
              
                                <BoxDashboard className="yellow">
                                  <h3>กำลังไปคลัง GSC</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.outbound_lazada ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.outbound_lazada) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>
              
                                <BoxDashboard className="blue">
                                  <h3>คงคลัง Giztix</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.inbound_giztix ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.inbound_giztix) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>

                                <BoxDashboard className="emerald">
                                  <h3>ออกจัดส่งลูกค้า</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.out_delivery ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.out_delivery) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>

                                <BoxDashboard className="green">
                                  <h3>ส่งสำเร็จ</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.delivered ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.delivered) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>

                                <BoxDashboard className="velvet" >
                                  <h3>คืนคลัง Lazada</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.return_lazada ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.return_lazada) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>
              
                                <BoxDashboard className="red" >
                                  <h3>ยกเลิก</h3>
                                  <h1>
                                    {
                                      orderStatusData && orderStatusData.lazadaPackageOrderStatus && orderStatusData.lazadaPackageOrderStatus.cancel ? this.formatNumber(orderStatusData.lazadaPackageOrderStatus.cancel) : 0
                                    } 
                                    {` `}รายการ
                                  </h1>
                                </BoxDashboard>
                              </BoxWrapper>
                            )
                          }}
                          </Query>
                          
                        </AboveWrapper>
                        <span style={{fontWeight: 'bold', fontSize: '20px', margin: '10px 0'}} ><strong>ตารางรายการสินค้า</strong></span>
                        <div className="table-vertical" style={{minHeight: 'calc(100vh - 400px)', maxHeight: 'calc(100vh - 400px)'}} >
                          <Table striped hover style={{ marginBottom: '60px' }}>
                            <thead>
                              <tr>
                                {
                                  // check box column //
                                  // <th style={{ minWidth: '100px' }}></th>
                                }
                                {
                                  // <th style={{ minWidth: '100px' }}>No</th>
                                }
                                <th style={{ minWidth: '100px' }}>Tracking Number</th>
                                <th style={{ minWidth: '170px' }}>สถานะ</th>
                                {
                                  // <th style={{ minWidth: '200px' }}>Package Code</th>
                                }
                                {
                                // <th style={{ minWidth: '200px' }}>Plan Date</th>
                                }
                                {
                                  // <th style={{ minWidth: '100px' }}>Paid By/Price</th>
                                }
                                <th style={{ minWidth: '400px' }}>สินค้า</th>
                                <th style={{ minWidth: '125px' }}>Zone</th>
                                <th style={{ minWidth: '150px' }} className={this.renderSorting("pickup_sla_date")} onClick={() => this.sort("pickup_sla_date", refetch)}>SLA ที่ต้องไปรับ</th>
                                <th style={{ minWidth: '150px' }} className={this.renderSorting("delivery_sla_date")} onClick={() => this.sort("delivery_sla_date", refetch)}>SLA ที่ต้องจัดส่ง</th>
                                <th style={{ minWidth: '200px' }} className={this.renderSorting("create_date")} onClick={() => this.sort("create_date", refetch)}>วันที่รับข้อมูล</th>
                                {
                                  // <th style={{ minWidth: '200px' }}>อัพเดทล่าสุด</th>
                                }
                              </tr>
                              <tr>
                              {
                                    // <th>
                                    // Check Box All //
                                    // <CustomCheckbox
                                    //   checked={this.state.cutOffList.filter(data => data.giztix_package_status == 1).length === this.state.checked.length}
                                    //   onClick={this.handlerCheckAll}
                                    // >
                                    //   <span className="checkmark"></span>
                                    // </CustomCheckbox>  
                                    // </th>
                                }
                                {
                                  // <th></th>
                                }
                                <th>
                                  <FormControl
                                    type="text"
                                    placeholder="ค้นหา Tracking Number"
                                    name="trackingNumber"
                                    value={this.state.trackingNumber}
                                    onChange={event => {
                                      this.setState({
                                        trackingNumber: event.target.value,
                                        page: 1,
                                      });
                                    }}
                                  />
                                </th>
                                <th>
                                  <FormControl
                                    componentClass="select"
                                    placeholder="select"
                                    defaultValue={this.state.status}
                                    onChange={event =>
                                      this.setState({
                                        status: event.target.value,
                                        page: 1,
                                      })
                                    }
                                    name="status"
                                  >
                                    <option value={0}>ทั้งหมด</option>
                                    <option value={1}>คงคลัง Lazada</option>
                                    {
                                    // <option value={2}>แพลนไปรับสินค้า</option>
                                    // <option value={4}>Failed นำสินค้าออกจาก Lazada</option>
                                    }
                                    <option value={3}>กำลังไปคลัง GSC</option>
                                    <option value={5}>คงคลัง Giztix</option>
                                    <option value={7}>ออกจัดส่งลูกค้า</option>
                                    <option value={8}>ส่งสำเร็จ</option>
                                    <option value={9}>คืนคลัง Lazada</option>
                                    <option value={6}>ยกเลิก</option>
                                  </FormControl>
                                </th>
                                {
                                  // Package Code
                                  // <th>
                                  //   <FormControl
                                  //     type="text"
                                  //     placeholder="ค้นหา Package Code"
                                  //     name="packageCode"
                                  //     value={this.state.packageCode}
                                  //     onChange={event => {
                                  //       this.setState({
                                  //         packageCode: event.target.value,
                                  //         page: 1,
                                  //       });
                                  //     }}
                                  //   />
                                  // </th>
                                }
                                {
                                // Plan Date
                                // <th>
                                //   <DateRangePicker
                                //     onApply={(event, picker) =>
                                //       this.handleDateRangePicker('planDate', picker)
                                //     }
                                //     onCancel={() =>
                                //       this.setState({
                                //         planDate: '',
                                //         displayPlanDate: '',
                                //         page: 1,
                                //       })
                                //     }
                                //     containerStyles={{ display: 'block' }}
                                //     opens="right"
                                //     format="DD/MM/YYYY"
                                //   >
                                //     <FormControl
                                //       type="text"
                                //       value={this.state.displayPlanDate}
                                //       placeholder="Plan Date"
                                //     />
                                //   </DateRangePicker>
                                // </th>
                                }
                                {
                                  // 
                                  // <th></th>
                                }
                                <th></th>
                                <th>
                                  <FormControl
                                    type="text"
                                    placeholder="ค้นหา"
                                    name="zoneCode"
                                    value={this.state.zoneCode}
                                    onChange={event => {
                                      this.setState({
                                        zoneCode: event.target.value,
                                        page: 1,
                                      });
                                    }}
                                  />
                                </th>
                                <th>
                                  <DateRangePicker
                                    onApply={(event, picker) =>
                                      this.handleDateRangePicker('slaPickup', picker)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        slaPickup: '',
                                        displaySlaPickup: '',
                                        page: 1,
                                      })
                                    }
                                    containerStyles={{ display: 'block' }}
                                    opens="right"
                                    format="DD/MM/YYYY"
                                  >
                                    <FormControl
                                      type="text"
                                      value={this.state.displaySlaPickup}
                                      placeholder="ค้นหา"
                                    />
                                  </DateRangePicker>
                                </th>
                                <th>
                                  <DateRangePicker
                                    onApply={(event, picker) =>
                                      this.handleDateRangePicker('slaDelivery', picker)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        slaDelivery: '',
                                        displaySlaDelivery: '',
                                        page: 1,
                                      })
                                    }
                                    containerStyles={{ display: 'block' }}
                                    opens="right"
                                    format="DD/MM/YYYY"
                                  >
                                    <FormControl
                                      type="text"
                                      value={this.state.displaySlaDelivery}
                                      placeholder="ค้นหา"
                                    />
                                  </DateRangePicker>
                                </th>
                                <th>
                                  <DateRangePicker
                                    onApply={(event, picker) =>
                                      this.handleDateRangePicker('createDate', picker)
                                    }
                                    onCancel={() =>
                                      this.setState({
                                        createDate: '',
                                        displayCreateDate: '',
                                        page: 1,
                                      })
                                    }
                                    containerStyles={{ display: 'block' }}
                                    opens="right"
                                    format="DD/MM/YYYY"
                                  >
                                    <FormControl
                                      type="text"
                                      value={this.state.displayCreateDate}
                                      placeholder="วันที่รับข้อมูล"
                                    />
                                  </DateRangePicker>
                                </th>
                                {
                                    // Last Update
                                    // <th>
                                    //   <DateRangePicker
                                    //     onApply={(event, picker) =>
                                    //       this.handleDateRangePicker('lastUpdate', picker)
                                    //     }
                                    //     onCancel={() =>
                                    //       this.setState({
                                    //         lastUpdate: '',
                                    //         displayLastUpdate: '',
                                    //         page: 1,
                                    //       })
                                    //     }
                                    //     containerStyles={{ display: 'block' }}
                                    //     opens="right"
                                    //     format="DD/MM/YYYY"
                                    //   >
                                    //     <FormControl
                                    //       type="text"
                                    //       value={this.state.displayLastUpdate}
                                    //       placeholder="วันที่อัพเดทล่าสุด"
                                    //     />
                                    //   </DateRangePicker>
                                    // </th>
                                }    
                              </tr>
                            </thead>
                            <tbody>
                          {
                                // ( this.state.trackingNumber || this.state.packageCode || this.state.displayCreateDate || this.state.lastUpdate || this.state.status > 0 || this.state.planDate ) 
                                true ? 
                                  (
                                    !loading &&
                                    !error &&
                                    data.lazadaPackageOrders.orders.length > 0 ?
                                    data.lazadaPackageOrders.orders.map((itemN, indexN) => (
                                      <tr key={itemN._id}>
                                      {
                                            // <td>
                                            // Check Box //

                                            // <CustomCheckbox
                                            //   style={{display: itemN.giztix_package_status == 1 ? 'block' : 'none'}}
                                            //   value={this.renderCheckCheckBox(itemN)}
                                            //   checked={this.renderCheckCheckBox(itemN)}
                                            //   onClick={() => this.handlerClickCheckBox(itemN)}
                                            // >
                                            //   <span className="checkmark"></span>
                                            // </CustomCheckbox> 
                                            // </td>
                                          }  
                                          {
                                            // <td>{indexN + 1}</td>
                                          }
                                        <td style={{width: '50px'}} >{itemN.trackingNumber ? itemN.trackingNumber : null}</td>
                                        <td>{this.renderStatus(itemN.giztix_package_status)}</td>
                                        {
                                          // Package Code
                                          // <td>{itemN.packageCode ? itemN.packageCode : null}</td>
                                        }
                                        {
                                        // Plan Date
                                        // <td>
                                        //     {itemN.plan_date ? (
                                        //       <Moment format="DD/MM/YYYY HH:mm:ss">
                                        //         {itemN.plan_date}
                                        //       </Moment>
                                        //     ) : (
                                        //       '-'
                                        //     )}
                                        // </td>
                                        }
                                        {
                                          // <td>{itemN.payment.paymentType ? `${itemN.payment.paymentType} / ${this.formatMoney(itemN.payment.paidAmount)}` : '-'}</td>
                                        }
                                        <td style={{width: '300px', wordWrap: 'break-word'}} >
                                          <OverlayTrigger
                                            trigger="hover"
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                              <Popover
                                                id={`popover-positioned-${itemN._id}`}
                                                title={`สินค้าทั้งหมด ${itemN.items.length} ชิ้น `}
                                              >
                                                {itemN.items
                                                  .map((product, key) => (
                                                    <p>
                                                      {`${product.name}`}{' '}
                                                    </p>
                                                  ))}

                                              </Popover>
                                            }
                                          >
                                            <b style={{ cursor: 'pointer' }}>
                                              {itemN.items.length > 1 ? `สินค้า ${itemN.items.length} ชิ้น` : (itemN.items[0] ? itemN.items[0].name : ' - ')}
                                            </b>
                                          </OverlayTrigger>
                                        </td>
                                        <td>
                                            {
                                              itemN.delivery_zone ? itemN.delivery_zone : ' - '
                                            }
                                        </td>
                                        <td>{ itemN.pickup_sla_date ? (itemN.giztix_package_status > 1 ? moment(itemN.pickup_sla_date).format('DD/MM/YYYY HH:mm:ss') : this.calTimeLeft(itemN.pickup_sla_date, 0) ) : ' - ' }</td>
                                        <td>{ itemN.delivery_sla_date ? this.calTimeLeft(itemN.delivery_sla_date, 0) : ' - ' }</td>
                                        <td>
                                          {itemN.create_date ? (
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {itemN.create_date}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        {
                                        // Last Update
                                        // <td>
                                        //   {itemN.last_update ? (
                                        //     <Moment format="DD/MM/YYYY HH:mm:ss">
                                        //       {itemN.last_update}
                                        //     </Moment>
                                        //   ) : (
                                        //     '-'
                                        //   )}
                                        // </td>
                                        }
                                      </tr>
                                    ))
                                    :
                                    <tr>
                                    <td colSpan="7">No Data</td>
                                    </tr>
                                  )
                                :
                                (
                                  this.state.cutOffList.length > 0 && 
                                    this.state.cutOffList.map((itemN, indexN) => (
                                      <tr key={itemN._id}>
                                      {
                                        // <td>
                                            // Check Box //

                                          // <CustomCheckbox
                                          //   style={{display: itemN.giztix_package_status == 1 ? 'block' : 'none'}}
                                          //   value={this.renderCheckCheckBox(itemN)}
                                          //   checked={this.renderCheckCheckBox(itemN)}
                                          //   onClick={() => this.handlerClickCheckBox(itemN)}
                                          // >
                                          //   <span className="checkmark"></span>
                                          // </CustomCheckbox>
                                          // </td>
                                        }  
                                        <td>{indexN + 1}</td>
                                        <td>{itemN.trackingNumber ? itemN.trackingNumber : null}</td>
                                        <td>{this.renderStatus(itemN.giztix_package_status)}</td>
                                        <td>{itemN.packageCode ? itemN.packageCode : null}</td>
                                        <td>
                                            {itemN.plan_date ? (
                                              <Moment format="DD/MM/YYYY HH:mm:ss">
                                                {itemN.plan_date}
                                              </Moment>
                                            ) : (
                                              '-'
                                            )}
                                        </td>
                                        <td>{itemN.payment.paymentType ? `${itemN.payment.paymentType}/${itemN.payment.paidAmount}` : '-'}</td>
                                        <td>
                                          <OverlayTrigger
                                            trigger="hover"
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                              <Popover
                                                id={`popover-positioned-${itemN._id}`}
                                                title={`สินค้าทั้งหมด ${itemN.items.length} ชิ้น `}
                                              >
                                                {itemN.items
                                                  .map((product, key) => (
                                                    <p>
                                                      {`${product.name}`}{' '}
                                                    </p>
                                                  ))}

                                              </Popover>
                                            }
                                          >
                                            <b style={{ cursor: 'pointer' }}>
                                              {`สินค้า ${itemN.items.length} ชิ้น`}
                                            </b>
                                          </OverlayTrigger>
                                        </td>
                                        <td>
                                          {itemN.create_date ? (
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {itemN.create_date}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {itemN.last_update ? (
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {itemN.last_update}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                      </tr>
                                      ))
                                )
                              }
                              {!(this.state.trackingNumber || this.state.packageCode || this.state.displayCreateDate || this.state.lastUpdate || this.state.status > 0 || this.state.planDate) &&
                                <tr style={{display: this.state.cutOffList.length == this.state.totalDoc && 'none'  }} >
                                  <td colSpan="8"><Button onClick={() => this.fetchMorelazadaPackageOrders()}>loading more</Button></td>
                                </tr>
                              }
                              </tbody>
                            </Table>
                        </div>
                        <div style={{ textAlign: 'right', marginTop: 20 }}>
                            {!loading && !error && data.lazadaPackageOrders && (
                              <ReactPaginate
                                pageCount={data.lazadaPackageOrders.totalPage}
                                forcePage={this.state.page - 1}
                                containerClassName="pagination"
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={({ selected }) => {
                                  this.setState({ page: selected + 1 });
                                }}
                              />
                            )}
                          </div>
                      </div>
                    )
                  }}
                  </Query>
                }
              />
              </div>
            </div>
          </Col>
        </Row>
        { this.state.isPlanFromDateModal &&
          <PlanFromDateModal open={this.state.isPlanFromDateModal} close={() => this.setState({isPlanFromDateModal: false})} refresh={this.reFetchlazadaPackageOrders} />
        }
        { this.state.isPlanFromOrderModal &&
          <PlanFromOrder checked={this.state.checked} open={this.state.isPlanFromOrderModal} close={() => this.setState({isPlanFromOrderModal: false})} refresh={this.reFetchlazadaPackageOrders} />
        }
        {
          this.state.isOpenFMCRImportFileMOdal && 
          <FMCRImportModal open={this.state.isOpenFMCRImportFileMOdal} close={() => this.setState({isOpenFMCRImportFileMOdal: false})}/>
        }
      </Layout>
    );
  }
}

export default BookingFiles;
