import gql from 'graphql-tag';

export default gql`
query search(
  $page_number : Int
  $order_by : String
  $order_type : String
  $challenge_distance : Boolean
  $business_req_number: String
  $account_info_business_name: String
){
    accountBusinessList(
        page_number :$page_number,
        show_data: 30,
        order_by :$order_by,
	  	order_type :$order_type,
        challenge_distance : $challenge_distance,
        business_req_number :$business_req_number,
        account_info_business_name : $account_info_business_name
  	) {
    account_business {
        _id
        account_info_id
        business_req_number
        account_info_business_name
        challenge_distance{
            distance
            rank
            rank_old
            shipment
        }
    }
    total_page
  }
}
`;


