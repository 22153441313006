import gql from 'graphql-tag';

export default gql`
mutation UpdatedatamasterServiceItem(
    $datamaster_service_detail: [datamasterServiceUpdateItemArg]
){
    datamasterServiceItemAdd(
        datamaster_service_detail : $datamaster_service_detail
    ){
      succeed
    }
  }
`;
