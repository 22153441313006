import gql from 'graphql-tag';

export default gql`
    query shipmentSla(
        $_id: ID!
    ) {
        shipmentSla(
        _id: $_id
        ) {
        _id
        sla_status
        sla_case_number
        
            # หัวข้อปัญหา & ระดับปัญหา
        datamaster_sla_title{
            _id
            level
            title
        }
        
        # Service Time
        datamaster_sla_level{
            _id
            level
            service_time
            respond_time{
            time
            unit
            }
            
        }

        service_time

        completed_date

        title
        level

        respond_time{
            time
            unit
          }
        
        # 1. รายละเอียดปัญหา* ขาดเวลา
        sla_description
        
        sla_file
        sla_issue
        sla_exp
        admin{
            _id
            admin_username
            admin_email
        }
        sla_channel
        customer_phone
        customer_email
        
        # 2. รายละเอียดการแก้ไขปัญหา*
        respond_description
        respond_date
        respond_file
        respond_admin{
                admin_username      
        }
        respond_channel
        
        }
    }
`;
