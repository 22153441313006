const FunctuonChangePrefixToName = input => {
  if (input === 'T1') {
    return 'ค่าขนส่ง';
  } else if (input === 'S1') {
    return 'ค่าบริการ';
  } else if (input === 'R1') {
    return 'ค่าสำรองจ่าย';
  } else {
    return 'ค่าขนส่ง';
  }
};

export default FunctuonChangePrefixToName;
