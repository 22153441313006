import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';

import s3Bucket from 'config/s3Bucket';

const ContainerStyled = styled.div`
  padding: 15px;
`;

const FileContainer = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents:this.props.document,
    };
  }

  render() {
    return (
      <ContainerStyled>
      {console.log(this.state.documents)}
        {
          this.state.documents.length > 0 && (
            <div>
                <Row>
                  <Col>
                    {this.state.documents.map((item, index) => {
                      return (
                        <FileContainer>
                          <Button
                            style={{ color: 'red', marginRight: 10 }}
                            onClick={() => {
                              const result = [...this.state.documents].filter((ele, j) => item !== ele);
                              this.setState({
                                documents : result
                              });
                              this.props.updateDocument(result)
                            }}
                          >
                            <i className="far fa-trash-alt" />
                          </Button>
                          <a href={s3Bucket+item} target="_blank" rel="noopener noreferrer">
                            {s3Bucket+item}
                          </a>
                        </FileContainer>
                      );
                    })}
                  </Col>
                </Row>
              </div>
          )
        }
      </ContainerStyled>
    );
  }
}

export default DocumentList;
