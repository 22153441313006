import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import CustomButton from 'components/CustomButton/CustomButton.jsx';

import accountBusinessList from './graphql/query/accountBusinessList';

import baseUrl from 'config/baseUrl';
import reportUrl from './../../config/reportUrl.js';

const tooltip = <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const TableVertiacal = styled.div`
  overflow: auto;
  max-height: 70vh;
  width: 100%;
`;

const UserType = styled.span`
  width: 5px;
  height: 5px;
  margin-right: 5px;
`;

const StatusFilter = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  padding: unset;
  padding-top: 5;
  margin-right: 4;
`;

class GiztixBusiness extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_page: 0,
      page_number: 1,
      order_by: 'create_date',
      order_type: -1,
      account_info_status: null,
      account_info_business_name: '',
      business_req_number: '',
      create_date: '',
      show_create_date: '',
      last_update: '',
      show_last_update: '',
      business_name: '',
      showReport: false,
      reportDate: '',
      show_reportDate: '',
      shipperid: 0,
      account_info_email: '',
      account_info_customer_type: {
        value: 0,
        name: 'ทั้งหมด',
      },
      customer_service_type: '',
    };
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    // console.log(page+1);
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  handleDateRangePicker(mode, event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode == 'create_date') {
      this.setState({ create_date: date, show_create_date: show }, () => {
        refetch();
      });
    }
    if (mode == 'last_update') {
      this.setState({ last_update: date, show_last_update: show }, () => {
        refetch();
      });
    }
  }

  openReportInvoice(id, name) {
    this.setState({
      showReport: true,
      reportDate: '',
      show_reportDate: '',
      shipperid: id,
      business_name: name,
    });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  exportReportInvoice() {
    const uri = `${reportUrl}/invoiceData_report?date=${this.state.reportDate}&shipper=${this.state.shipperid}`;
    window.location = uri;

    this.setState({ showReport: false, reportDate: '', show_reportDate: '' });
  }

  renderUser(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="default">ลูกค้าเก่า</Label>;
      default:
        return '';
    }
  }

  renderChannel(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="primary">WEB</Label>;
      case 1:
        return <Label bsStyle="danger">APP</Label>;
      default:
        return '';
    }
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="warning">รอการอนุมัติ</Label>;
      case 2:
        return <Label bsStyle="primary">กำลังใช้งาน</Label>;
      case 3:
        return <Label bsStyle="danger">ระงับการใช้งาน</Label>;
      case 4:
        return <Label bsStyle="info">ปฏิเสธ</Label>;
      case 5:
        return <Label bsStyle="success">รับเรื่องแล้ว</Label>;
      default:
        return '';
    }
  }

  renderRegisterBusinessform(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="primary">WEB</Label>;
      case 1:
        return <Label bsStyle="danger">APP</Label>;
      default:
        return '';
    }
  }

  renderCustomerType = status => {
    switch (status) {
      case 0:
        return <span>ทั้งหมด</span>;
      case 1:
        return (
          <span>
            <UserType
              style={{ padding: '2px 6px' }}
              className="label"
              style={{ backgroundColor: '#FED249' }}
            >
              <i className="fal fa-user" />
            </UserType>
            B2C
          </span>
        );
      case 2:
        return (
          <span>
            <UserType style={{ padding: '2px 6px' }} className="label label-warning">
              <i className="fal fa-users" />
            </UserType>
            B2B-N
          </span>
        );
      case 3:
        return (
          <span>
            <UserType style={{ padding: '2px 6px' }} className="label label-success">
              <i class="fal fa-donate" />
            </UserType>
            GTX BU
          </span>
        );
      case 4:
        return (
          <span>
            <UserType style={{ padding: '2px 6px' }} className="label label-primary">
              <i class="fal fa-gem" />
            </UserType>
            Key Account
          </span>
        );
      default:
        return '';
    }
  };

  renderCustomerTypeOnlyIcon = status => {
    switch (status) {
      case 0:
        return <span>ทั้งหมด</span>;
      case 1:
        return (
          <span>
            <UserType
              style={{ padding: '2px 6px' }}
              className="label"
              style={{ backgroundColor: '#FED249' }}
            >
              <i className="fal fa-user" />
            </UserType>
          </span>
        );
      case 2:
        return (
          <span>
            <UserType style={{ padding: '2px 6px' }} className="label label-warning">
              <i className="fal fa-users" />
            </UserType>
          </span>
        );
      case 3:
        return (
          <span>
            <UserType style={{ padding: '2px 6px' }} className="label label-success">
              <i class="fal fa-donate" />
            </UserType>
          </span>
        );
      case 4:
        return (
          <span>
            <UserType style={{ padding: '2px 6px' }} className="label label-primary">
              <i class="fal fa-gem" />
            </UserType>
          </span>
        );
      default:
        return '';
    }
  };

  renderCustomerTypeFilter = status => {
    switch (status) {
      case 0:
        return <span style={{ marginRight: '70px' }}>ทั้งหมด</span>;
      case 3:
        return (
          <span style={{ marginRight: '50px' }}>
            <StatusFilter className="label label-success">
              <i style={{ fontSize: 9 }} class="fal fa-donate" />
            </StatusFilter>{' '}
            GTX BU
          </span>
        );
      case 4:
        return (
          <span style={{ marginRight: '50px' }}>
            <StatusFilter className="label label-primary">
              <i style={{ fontSize: 9 }} class="fal fa-gem" />
            </StatusFilter>{' '}
            Key Account
          </span>
        );
      default:
        return '';
    }
  };

  render() {
    // console.log(this.state);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Query
                query={accountBusinessList}
                variables={{
                  page_number: this.state.page_number,
                  order_by: this.state.order_by,
                  order_type: this.state.order_type,
                  account_info_business_name: this.state.account_info_business_name,
                  business_req_number: this.state.business_req_number,
                  business_status: Number(this.state.business_status),
                  create_date: this.state.create_date,
                  last_update: this.state.last_update,
                  business_register_from: this.state.business_register_from,
                  invite_code: this.state.invite_code,
                  account_info_email: this.state.account_info_email,
                  account_info_customer_type: this.state.account_info_customer_type.value,
                  customer_service_type: this.state.customer_service_type,
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  console.log(data);

                  var itemLength = 'loading';
                  const itemIndicatorSize = (this.state.page_number - 1) * 30;
                  if (data.accountBusinessList) {
                    if (data.accountBusinessList.account_business.length > 0) {
                      itemLength = data.accountBusinessList.account_business.length;
                    }
                  }
                  return (
                    <div>
                      <CardTable
                        title="รายชื่อองค์กร/บริษัทที่ใช้งาน Giztix business"
                        category="ข้อมูลงานขนส่งจากระบบออนไลน์Marketplace Marketplace"
                        ctTableFullWidth
                        ctTableResponsive
                        // rightButton="รายงานรายชื่อลูกค้า"
                        // onPressRight={() => this.openReportShipper()}
                        content={
                          <div>
                            <PageWrapper style={{ textAlign: 'right' }}>
                              <div className="text">
                                {this.state.page_number === 1
                                  ? `1 - ${itemLength}`
                                  : `${itemIndicatorSize + 1} - ${itemLength +
                                      itemIndicatorSize}`}{' '}
                                หน้า :
                              </div>
                              <FormControl
                                type="number"
                                min={1}
                                value={this.state.page_number}
                                onChange={event =>
                                  setInterval(
                                    this.nextPage(
                                      Number(event.target.value) - 1,
                                      loading || error ? '0' : data.accountBusinessList.total_page,
                                      refetch
                                    ),
                                    1000
                                  )
                                }
                                name="page_number"
                              />
                              <div className="text">
                                {' '}
                                {loading || error
                                  ? ''
                                  : `จาก ${data.accountBusinessList.total_page}`}
                              </div>
                            </PageWrapper>
                            <div className="table-vertical">
                              <Table striped hover>
                                <thead>
                                  <tr>
                                    <th style={{ minWidth: '140px' }}>เครื่องมือ</th>
                                    <th
                                      style={{ minWidth: '50px' }}
                                      className={this.renderSorting('business_admin_document_1')}
                                      onClick={() =>
                                        this.sort('business_admin_document_1', refetch)
                                      }
                                    >
                                      Doc
                                    </th>
                                    <th
                                      style={{ minWidth: '120px' }}
                                      className={this.renderSorting('business_req_number')}
                                      onClick={() => this.sort('business_req_number', refetch)}
                                    >
                                      รหัสคำร้อง
                                    </th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('business_status')}
                                      onClick={() => this.sort('business_status', refetch)}
                                    >
                                      สถานะ
                                    </th>
                                    <th
                                      style={{ minWidth: '150px' }}
                                      className={this.renderSorting('account_info_business_name')}
                                      onClick={() =>
                                        this.sort('account_info_business_name', refetch)
                                      }
                                    >
                                      ชื่อบริษัท
                                    </th>
                                    <th
                                      style={{ minWidth: '150px' }}
                                      className={this.renderSorting('account_info_email')}
                                      onClick={() => this.sort('account_info_email', refetch)}
                                    >
                                      ผู้ส่งสินค้า
                                    </th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('account_info_customer_type')}
                                      onClick={() =>
                                        this.sort('account_info_customer_type', refetch)
                                      }
                                    >
                                      ประเภทลูกค้า
                                    </th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('customer_service_type')}
                                      onClick={() => this.sort('customer_service_type', refetch)}
                                    >
                                      ประเภทบริการ
                                    </th>
                                    <th
                                      style={{ minWidth: '100px' }}
                                      className={this.renderSorting('invite_code')}
                                      onClick={() => this.sort('invite_code', refetch)}
                                    >
                                      Invite code
                                    </th>
                                    <th style={{ minWidth: '140px' }}>เครดิต</th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('business_register_from')}
                                      onClick={() => this.sort('business_register_from', refetch)}
                                    >
                                      สมัครทาง
                                    </th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('create_date')}
                                      onClick={() => this.sort('create_date', refetch)}
                                    >
                                      วันที่ยืนคำร้อง
                                    </th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('last_update')}
                                      onClick={() => this.sort('last_update', refetch)}
                                    >
                                      แก้ไขล่าสุด
                                    </th>
                                  </tr>
                                  <tr className="tableFilter">
                                    <th></th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.business_req_number}
                                        placeholder="ค้นหารหัสคำร้อง"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              business_req_number: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="business_req_number"
                                      />
                                    </th>

                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.business_status}
                                        onChange={event =>
                                          this.setState(
                                            { business_status: event.target.value, page_number: 1 },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="business_status"
                                      >
                                        <option value={0}>ทั้งหมด</option>
                                        <option value={5}>รับเรื่องแล้ว</option>
                                        <option value={1}>รอการอนุมัติ</option>
                                        <option value={2}>กำลังใช้งาน</option>
                                        <option value={3}>ระงับการใช้งาน</option>
                                        <option value={4}>ปฏิเสธ</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_business_name}
                                        placeholder="ค้นหาชื่อบริษัท"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_business_name: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_business_name"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_email}
                                        placeholder="ค้นหาอีเมล"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_email: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_email"
                                      />
                                    </th>
                                    <th>
                                      <DropdownButton
                                        style={{ minWidth: '120px', textAlign: 'left' }}
                                        title={this.renderCustomerTypeFilter(
                                          this.state.account_info_customer_type.value
                                        )}
                                        id="dropdown"
                                        onSelect={(eventKey, key) => {
                                          console.log(eventKey);
                                          this.setState({ account_info_customer_type: eventKey });
                                        }}
                                      >
                                        <MenuItem eventKey={{ value: 0, name: 'ทั้งหมด' }}>
                                          ทั้งหมด
                                        </MenuItem>
                                        <MenuItem eventKey={{ value: 3, name: 'GTX BU' }}>
                                          <span className="label label-success">
                                            <i class="fal fa-donate" />
                                          </span>{' '}
                                          GTX BU
                                        </MenuItem>
                                        <MenuItem eventKey={{ value: 4, name: 'Key Account' }}>
                                          <span className="label label-primary">
                                            <i class="fal fa-gem" />
                                          </span>{' '}
                                          Key Account
                                        </MenuItem>
                                      </DropdownButton>
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.customer_service_type}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              customer_service_type: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="customer_service_type"
                                      >
                                        <option value="">ทั้งหมด</option>
                                        <option value="FTL">FTL</option>
                                        <option value="PARCEL">PARCEL</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.invite_code}
                                        placeholder="ค้นหา invite code"
                                        onChange={event =>
                                          this.setState(
                                            { invite_code: event.target.value, page_number: 1 },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="invite_code"
                                      />
                                    </th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.business_register_from}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              business_register_from: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="business_register_from"
                                      >
                                        <option value={0}>ทั้งหมด</option>
                                        <option value={1}>APP</option>
                                        <option value={2}>WEB</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker(
                                            'create_date',
                                            event,
                                            picker,
                                            refetch
                                          )
                                        }
                                        onCancel={() =>
                                          this.setState(
                                            { create_date: '', show_create_date: '' },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.show_create_date}
                                          placeholder="ค้นหาวันที่ยืนคำร้อง"
                                          name="create_date"
                                        />
                                      </DateRangePicker>
                                    </th>
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker(
                                            'last_update',
                                            event,
                                            picker,
                                            refetch
                                          )
                                        }
                                        onCancel={() =>
                                          this.setState(
                                            { last_update: '', show_last_update: '' },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.show_last_update}
                                          placeholder="ค้นหาแก้ไขล่าสุด"
                                          name="last_update"
                                        />
                                      </DateRangePicker>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading || error || data.accountBusinessList == undefined ? (
                                    <tr>
                                      <td colSpan="4" className="textCenter">
                                        {loading ? 'Loading...' : error.message}
                                      </td>
                                    </tr>
                                  ) : (
                                    data.accountBusinessList.account_business.map((prop, key) => (
                                      <tr key={key}>
                                        <td>
                                          <a
                                            style={{ marginRight: '10px', float: 'left' }}
                                            href={`/giztixbusinessdetail?id=${prop.account_info_id}`}
                                            className="btn btn-default"
                                          >
                                            <i className="fal fa-search"></i>
                                          </a>
                                          <Button
                                            style={{ fontWeight: 'normal', float: 'left' }}
                                            onClick={() =>
                                              this.openReportInvoice(
                                                prop.account_info_id,
                                                prop.account_info_business_name
                                              )
                                            }
                                          >
                                            <i className="fal fa-file-invoice-dollar"></i> Invoice
                                          </Button>
                                        </td>
                                        <td>
                                          {prop.business_admin_document_1 ? (
                                            <i
                                              style={{ color: '#9E9E9E' }}
                                              className="fas fa-file-pdf"
                                            ></i>
                                          ) : null}
                                        </td>
                                        <td>BU{prop.business_req_number}</td>
                                        <td>{this.renderStatus(prop.business_status)}</td>
                                        <td>{prop.account_info_business_name}</td>
                                        <td>{prop.account_info_email}</td>
                                        <td>
                                          {this.renderCustomerType(prop.account_info_customer_type)}
                                        </td>
                                        <td>{prop.customer_service_type.join(', ')}</td>
                                        <td>{prop.invite_code}</td>
                                        <td>
                                          {prop.business_credit_used != null &&
                                            parseFloat(prop.business_credit_used.toFixed(2))
                                              .toString()
                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                                          /{' '}
                                          {prop.business_credit_approved != null &&
                                            parseFloat(prop.business_credit_approved.toFixed(2))
                                              .toString()
                                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        </td>
                                        <td>
                                          {this.renderRegisterBusinessform(
                                            prop.business_register_from
                                          )}
                                        </td>
                                        <td>
                                          <Moment format="DD/MM/YYYY HH:mm">
                                            {prop.create_date}
                                          </Moment>
                                        </td>
                                        <td>
                                          <Moment format="DD/MM/YYYY HH:mm">
                                            {prop.last_update}
                                          </Moment>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        }
                      />

                      <Modal
                        show={this.state.showReport}
                        onHide={() => this.setState({ showReport: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            รายงาน invoice ที่ยังไม่ชำระของ {this.state.business_name}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row style={{ marginBottom: '10px' }}>
                            <Col md={12}> ช่วงวันที่</Col>
                          </Row>
                          <FormGroup controlId="formValidationError1">
                            <DateRangePicker
                              onApply={(event, picker) =>
                                this.handleDateRangePickerReport(event, picker)
                              }
                              onCancel={() =>
                                this.setState({ reportDate: '', show_reportDate: '' })
                              }
                              containerStyles={{ display: 'block' }}
                              opens="right"
                              format="DD/MM/YYYY"
                            >
                              <FormControl
                                type="text"
                                value={this.state.show_reportDate}
                                placeholder="ค้นหาวันที่"
                                name="reportDate"
                              />
                            </DateRangePicker>
                          </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button bsStyle="primary" onClick={() => this.exportReportInvoice()}>
                            ดาวน์โหลดรายงาน
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <div style={{ textAlign: 'right' }}>
                        {loading ? null : (
                          <ReactPaginate
                            pageCount={data.accountBusinessList.total_page}
                            forcePage={this.state.page_number - 1}
                            containerClassName="pagination"
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={({ selected }) => {
                              this.nextPage(selected, data.accountBusinessList.total_page, refetch);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default GiztixBusiness;
