import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import printJS from 'print-js';
import PdfViewer from './components/PdfViewer';
import mergePDFDocuments from './mergePDFDocuments';

const ButtonDownload = styled(Button)`
  padding: 0px !important;

  & a {
    padding: 6px;
    display: flex;
    color: #ffffff;
    justify-content: center;
    background-color: #d90101;
    border-radius: 3px;

    &:hover,
    &:focus {
      color: #ffffff;
    }

    &:focus {
      outline: 1px solid;
    }
  }
`;

class PrintLabelPreview extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { linkDownloadLabelParcel } = this.props;
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          {linkDownloadLabelParcel !== null && (
            <div
              style={{
                padding: 8,
              }}
            >
              <PdfViewer
                pdfUrl={linkDownloadLabelParcel}
                onClick={() => printJS(linkDownloadLabelParcel)}
              />
              <ButtonDownload block style={{ marginTop: 8 }}>
                <a download target="_blank" href={linkDownloadLabelParcel}>
                  Download
                </a>
              </ButtonDownload>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default PrintLabelPreview;
