import React from 'react';

class HideMenu extends React.Component {
  componentDidMount() {
    localStorage.clear('giztixmenu');
    localStorage.clear('jdmenu');
    localStorage.setItem('lazadamenu', true);
  }

  render() {
    return (window.location = '/');
  }
}

export default HideMenu;
