import gql from 'graphql-tag';

export default gql`
  query DriverUserList($_id: ID, $driver_name: String) {
    driverUserList(_id: $_id, driver_name: $driver_name, show_data: 5) {
      driver_user {
        _id
        account_info_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_enable
        user_setting_truck_license_plate
        user_setting_truck_id
      }
      total_page
    }
  }
`;
