export const TEXT_REPORT_DOWNLOAD = 'ดาวน์โหลดรายงาน';
export const TEXT_BUTTON_DOWNLOAD = 'ดาวน์โหลด';
export const TEXT_REPORT_UPLOAD = ' อัพโหลดรายงาน';
export const TEXT_TOOLS = 'เครื่องมือ';
export const TEXT_REPORT_NAME = 'ชื่อรายงาน';
export const TEXT_REPORT_DATE = 'วันที่อัพโหลด';
export const TEXT_REPORT_DETAIL = 'รายละเอียด';
export const TEXT_UPLOADTER = 'รายงานอัพโหลดโดย';
export const TEXT_REPORT_TITLE = 'รายงานรายชื่อลูกค้า GIZTIX Business';
export const TEXT_REPORT_TABLE_TITLE = `สามารถ ${TEXT_REPORT_DOWNLOAD} ทั้งหมดได้จากหน้านี้`;
export const TEXT_UPLOAD_FILE = 'อัพโหลดไฟล์';
export const TEXT_ERROR_DETAIL = 'รายละเอียดของรายงานที่อัพโหลดผิดพลาด';
export const TEXT_ERROR = 'Error';
export const TEXT_SUCCESS = 'Success';
export const TEXT_COMPLETE = 'Complete';
export const TEXT_PROCESS = 'Process';
export const TEXT_PLACEHOLDER_SEARCH_STATUS = 'Search Status';
export const TEXT_PLACEHOLDER_SEARCH_CREATE_BY = 'Search Create By';
export const TITLE_JDBU = 'JDBU Rate Card';
export const TITLE_JDSM = 'JDSM Rate Card';
export const TITLE_LAZADA = 'Lazada Rate Card';
export const TITLE_COMMON = 'Common Rate Card';
export const TEXT_ALL = 'All';
export const TEXT_RECOMMEND_TITLE = 'รายงานราคาค่าขนส่งของ {NAME}';
export const TEXT_LOADING = 'Loading...';
export const TEXT_DOWNLOAD_TEMPLATE = 'ดาวน์โหลดเทมเพลต';
