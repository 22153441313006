import gql from 'graphql-tag';

export default gql`
	query searchAccountAgent (
		$_id: ID
		$account_agent_number: String
		$account_info_business_name: String
		$account_info_status: Int
		$account_user_status: Int
		$page_number: Int
		$order_by: String
		$order_type: String
		$last_update: String
		$account_info_phone_number: String
	) {
		accountInfoList (
			_id: $_id
			account_agent_number: $account_agent_number
			account_info_business_name: $account_info_business_name
			account_info_status: $account_info_status
			account_user_status: $account_user_status
			page_number: $page_number
			order_by: $order_by
			order_type: $order_type
			account_info_type : 3
			show_data : 30
			account_info_phone_number: $account_info_phone_number
			last_update : $last_update
		) {
			account_info {
				_id
				account_info_type
				account_info_number
				old_user
				account_info_register_channel
				account_info_status
				last_update
				account_info_business_type{
					th
					en
				}
				account_info_business_name
				account_info_tax_id
				account_info_email
				account_info_address
				account_info_country
				account_info_province
				account_info_district
				account_info_subdistrict
				account_info_postcode
				account_info_branch
				account_info_tel
				account_info_phone_code
				account_info_phone_number
				account_info_logo
				account_info_location_map
				create_date
				last_update
				account_users {
					_id
					account_user_register_from
					account_user_name
					account_user_type
					account_user_password
					account_user_phone_code
					account_user_phone_number
					account_user_display_name
					account_user_display_picture
					account_user_status
					account_user_activate_code
					create_date
					last_update
				}

				account_documents {
					_id
					account_document_type
					account_document_name
					account_document_path
					create_date
					last_update
				}
				account_billing_addresses {
					_id
					account_billing_address_tax
					account_billing_address_name
					account_billing_address_address
					account_billing_address_country
					account_billing_address_province
					account_billing_address_district
					account_billing_address_postcode
					account_billing_address_tel
					account_billing_address_email
					create_date
					last_update
				}
				account_business_email {
					_id
					email
				}
				account_info_corporate_phone_code
				account_info_corporate_phone_number
				account_info_corporate_co_phone_code
				account_info_corporate_co_phone_number
				account_info_corporate_type
				account_info_note
				account_agent_number
				line_id
				reserve_phone_code
				reserve_phone_number
				criminal_history
				id_card_number
				id_card_pic {
					path
				}
				driver_card_pic {
					path
				}
				car_pic {
					path
				}
				license_plate_pic {
					path
				}
				act_pic{
					path
				}
				car_insurance_pic {
					path
				}
				book_bank_pic {
					path
				}
				setting_truck_service
				account_info_delivery_vat
				account_info_delivery_wht
				account_info_service_vat
				account_info_service_wht
				bank_account{
					_id
					bank_name
					bank_branch
					account_name
					account_number
				}
				payment_bank_account{
					bank_name
					bank_branch
					account_name
					account_number
				}
			}
			total_page
		}
	}
`;