import gql from "graphql-tag";

export default gql`
  query LazadaImportOrderListBookingFile (
    $_id: ID
    $create_shipment: Int
    $page_number: Int
    $name: String
    $pick_up_date: String
    $create_date: String
    $status: Int
    $account_info_id: ID
    $format_bookname: String
  ) {
    lazadaImportOrderList(
      _id: $_id
      create_shipment: $create_shipment
      show_data: 30
      page_number: $page_number
      name: $name
      pick_up_date: $pick_up_date
      create_date: $create_date
      status: $status
      account_info_id: $account_info_id
      format_bookname: $format_bookname
    ) {
      order {
        _id
        name
        admin_username
        create_shipment
        status
        shipment_completed
        total_shipment
        create_date
        pick_up_date
        error_result
        order_type
        format_bookname
        confirm_cod
        confirm_cod_total
      }

      total_page
    }
  }
`;
