import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 16.6666666667%;
  color: #000000;
  background-color: #ffffff;
  padding-right: 1.5vw;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  padding-left: 0.9vw;
`;

const TextTitle = styled.div`
  font-size: 3vh;
`;

const TextNumber = styled.div`
  font-size: 8vh;
  font-weight: bold;
  line-height: 0.6;
`;

const LeftContainer = styled.div`
  margin-right: 1vw;
`;

const Icon = styled.i`
  font-size: 6vh;
  line-height: 1.5;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextUnit = styled.span`
  font-size: 5vh;
  font-weight: bold;
`;

const defaultProps = {
  title: 'Title',
  icon: 'fa-truck',
  number: 0,
};

const CardIcon = props => {
  const { title, icon, text, unit, ...otherProps } = props;

  return (
    <Container {...otherProps}>
      <LeftContainer>
        <Icon className={`far ${icon}`} />
      </LeftContainer>
      <RightContainer>
        <TextTitle>{title}</TextTitle>
        <TextNumber>
          {text} {unit && <TextUnit>{unit}</TextUnit>}
        </TextNumber>
      </RightContainer>
    </Container>
  );
};

CardIcon.defaultProps = defaultProps;

export default CardIcon;
