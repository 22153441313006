import React, { useState, useEffect } from 'react';
import AutoSuggest from 'react-autosuggest';
import { AutoSuggestContainer } from './styles';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { client } from '../../../../../../../index';

const SelectAgent = ({ defaultValue = '', onChange }) => {
  const [agentListSuggestion, setAgentListSuggestion] = useState([]);
  const [agentSelected, setAgentSelected] = useState({
    id: '',
    name: '',
  });

  const getAgentDefaultValue = async agentId => {
    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        variables: {
          _id: agentId,
          show_data: 1,
        },
        fetchPolicy: 'network-only',
      });

      if (data.accountInfoList?.account_info.length === 1) {
        setAgentSelected({
          id: data.accountInfoList.account_info[0]._id,
          name: data.accountInfoList.account_info[0].account_info_business_name,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!agentSelected.id && defaultValue) {
      getAgentDefaultValue(defaultValue);
    }
  }, [defaultValue]);

  const getSuggestions = async inputValue => {
    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        variables: {
          account_info_business_name: inputValue
            .trim()
            .toString()
            .toLowerCase(),
          show_data: 10,
        },
        fetchPolicy: 'network-only',
      });

      const agentListData = data.accountInfoList.account_info.map(e => ({
        id: e._id,
        name: e.account_info_business_name,
      }));

      setAgentListSuggestion([
        {
          id: '',
          name: 'ทั้งหมด',
        },
        ...agentListData,
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  const onSuggestionsFetchRequested = value => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setAgentListSuggestion([]);
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => (
    <div key={suggestion.id}>{suggestion.name ? suggestion.name : 'No Agent'}</div>
  );

  const shouldRenderSuggestions = () => {
    return true;
  };

  return (
    <AutoSuggestContainer>
      <AutoSuggest
        suggestions={agentListSuggestion}
        onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={false}
        inputProps={{
          type: 'text',
          placeholder: 'ค้นหาชื่อ 3PL',
          value: agentSelected.name,
          className: 'form-control',
          onChange: event => {
            setAgentSelected({
              id: '',
              name: event.target.value || '',
            });

            onChange && onChange(null);
          },
        }}
        onSuggestionSelected={(_event, { suggestion }) => {
          setAgentSelected({
            id: suggestion.id,
            name: suggestion.id ? suggestion.name : '',
          });

          onChange &&
            onChange({
              id: suggestion.id,
              name: suggestion.name,
            });
        }}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </AutoSuggestContainer>
  );
};

export default SelectAgent;
