import React, { Component } from 'react'
import styled from 'styled-components';
import ModalConfirm from '../../../../components/ModalConfirm'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FormControl } from 'react-bootstrap';
import Datetime from "react-datetime";
import moment from 'moment';
import { Mutation } from "react-apollo";

//Mutation
import lazadaPackageOrderPlaning from './graphql/mutation/lazadaPackageOrderPlaning'

const Wrapper = styled.div`
    // background: #F3D8FF;
    display: flex;
`

const LeftSide = styled.div`
    flex: 1;
`

const RightSide = styled.div`
    flex: 1;
`

class PlanFromDateModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            planDate: '',
            displayPlanDate: '',
            planDateInvalid: false,

            dateValid: true,

            createDate: '',
            displayCreateDate: '',
            createDateInvalid: false,
        }
    }

    handleSubmit = (lazadaPackageOrderPlaningData) => {
        if(this.checkInvalid()){
        }else{
            lazadaPackageOrderPlaningData({
                variables:{
                    params:{
                        create_date: this.state.createDate
                    },
                    update:{
                        plan_date: this.state.planDate
                    }
                }
            })

        }
    }

    handleDateRangePicker = (mode, picker) => {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    
        const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
          'DD/MM/YYYY'
        )}`;
    
        if (mode === 'createDate') {
          this.setState({ createDate: date, displayCreateDate: display, createDateInvalid: false });
        } else if (mode === 'lastUpdate') {
          this.setState({ lastUpdate : date, displayLastUpdate: display });
        }
        else if (mode === 'planDate') {
          this.setState({ planDate : date, displayPlanDate: display });
        }
      }

    checkInvalid = () => {
        this.setState({
            planDateInvalid: this.state.dateValid ? (moment(this.state.planDate, 'YYYY-MM-DD', true).isValid()  ? false : true) : true,
            createDateInvalid: this.state.createDate ? false : true
        })

        return !(this.state.planDate && moment(this.state.planDate, 'YYYY-MM-DD', true).isValid() && this.state.dateValid && this.state.createDate)
    }

    handleDatePicker(event) {
        if (event) {
            try {
                const date = `${event.format('YYYY-MM-DD')}`;
                const show = `${event.format('DD/MM/YYYY')}`;
                this.setState({ planDate: date, planDateShow: show, planDateInvalid: false, dateValid: true});
            } catch (error) {
                this.setState({ planDateInvalid: true, dateValid: false});
                console.log(error)
            }
        } else {
            this.setState({ planDate: '', planDateShow: '', planDateInvalid: false, dateValid: true});
            this.checkInvalid()
        }
    }


    render(){
        return (
            <Mutation
                mutation={lazadaPackageOrderPlaning}
                update={(cache, { data }) => {
                    this.props.close()
                    this.props.refresh()
                }}
                onError={error => {
                    console.log(error);
                }}
                // refetchQueries={() => ['lazadaPackageOrders']}
                >
                {lazadaPackageOrderPlaningData => {
                    return (
                        <ModalConfirm
                            show={this.props.open}
                            onPressButtonConfirm={() => this.handleSubmit(lazadaPackageOrderPlaningData)}
                            styleColorButtonConfirm="primary"
                            labelButtonConfirm={'ยืนยัน'}
                            labelButtonCancel={`ยกเลิก`}
                            onPressButtonCancel={this.props.close}
                            titleHeader={`แพลนรับสินค้าจากวันที่สร้าง`}
                            onHide={this.props.close}
                            disabledButtonConfirm={false}
                            >
                            <Wrapper>
                                <LeftSide>
                                    <strong>Plan Date</strong>
                                    <div style={{ border: this.state.planDateInvalid ? '1px solid #FF0000' : '0px solid #e3e3e3', borderRadius: '5px', width: '90%' }}>
                                        <Datetime
                                            closeOnSelect
                                            value={this.state.displayPlanDate}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            inputProps={{ placeholder: "DD/MM/YYYY" }}
                                            onChange={event => {
                                                this.handleDatePicker(event)
                                            }}
                                        />
                                    </div>
                                </LeftSide>
                                <RightSide>
                                    <strong>วันที่สร้าง</strong>
                                    <div style={{ width: '90%', border: this.state.createDateInvalid ? '1px solid #FF0000' : '0px solid #e3e3e3', borderRadius: '5px'}} >
                                        <DateRangePicker
                                            onApply={(event, picker) => this.handleDateRangePicker('createDate', picker)}
                                            onCancel={() =>
                                                this.setState({
                                                    createDate: '',
                                                    displayCreateDate: '',
                                                    createDateInvalid: false
                                                })
                                            }
                                            containerStyles={{ display: 'block' }}
                                            opens="right"
                                            format="DD/MM/YYYY"
                                        >
                                            <FormControl
                                                type="text"
                                                value={this.state.displayCreateDate}
                                                placeholder="DD/MM/YYYY - DD/MM/YYYY"
                                            />
                                        </DateRangePicker>
                                    </div>
                                </RightSide>
                            </Wrapper>
                        </ModalConfirm>
            )}}
            </Mutation>
        )
    }
}

export default PlanFromDateModal