import React from 'react'
import styled from 'styled-components';
import classes from './SubHeader3PL.module.css';
import Moment from 'react-moment';
import SubHeader from './components/SubHeader';
import urijs from 'urijs';

const MenuWrapper = styled.div`
width: 100%;
& ul{
  padding-left: 0;
  margin: 0;
  display: flex;

  & li{
    list-style: none;
    margin-right: 15px;
    padding: 15px 10px 15px;

    // &:not(:last-child)::after {
    //     content: '';
    //     display: inline-block;
    //     width: 1.7em;
    //     height: 1.7em;
    //     border-right: 0.1em solid black;
    //     border-top: 0.1em solid black;
    //     transform: rotate(45deg);
    // }

    & a{
      font-size:18px;
      cursor: pointer;
      color: #000000;
      & i{
        font-size:18px;
        color: #000000;
      }
    }

    & span {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-color: #B3B3B3;
        border-radius: 50%;
        text-align: center;
        font-weight: bold;
        padding-bottom: 5px;
        line-height: 1.3;
        margin-right: 6px;
        color: #FFFFFF;
    }

    &.active span {
        background-color: #000000;
        color: #FFFFFF;
    }
  }
  & li.active{
    border-bottom: 2px solid #d90402;
  }
  
}
`;

const StepContainer = styled.div`
	position: relative;
	margin-top: 25px;

	& ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;
		display: inline-block;

		& li {
            display: inline-block;
            
			&:not(:last-child)::after {
				content: '';
				background-color: #B3B3B3;
				width: 77px;
				height: 1px;
				display: inline-block;
				position: relative;
				margin: 4px 10px;
			}

			& span {
				display: inline-block;
				width: 24px;
				height: 24px;
				background-color: #B3B3B3;
				border-radius: 50%;
				text-align: center;
				font-weight: bold;
				padding-bottom: 5px;
				line-height: 1.3;
				margin-right: 6px;
			}

			&.active span {
				background-color: #D90101;
				color: #FFFFFF;
			}
		}
	}
`;

const Tag = styled.div`
  background-color: #E5FFE5;
  color: #5DBA5B;
  font-weight: bold;
  padding: 2px 5px;
  margin-left: 30px;
  border-radius: 4px;
`;

const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #E3E3E3;
`;
const getorderIdFromUrl = () => {
  const id = urijs(window.location.href).query(true).orderid;
  return id;
};
const getIdFromUrl = () => {
  const id = urijs(window.location.href).query(true).id;
  return id;
};
const getaccountIdFromUrl = () => {
  const id = urijs(window.location.href).query(true).accid;
  return id;
};

const SubHeader3PL = props => (
  <SubHeader backUrl={`/lazada-shipment-agent?id=${getorderIdFromUrl()}`}>
    <div className={classes.header}>
      <div className={classes.title}>
        <div style={{ width: 'max-content' }}>
          <strong>ตารางรายการงานขนส่ง</strong>
          {
            //     {this.getIdFromUrl() && this.state.lazadaImportOrderPickupDate && (
            //         <span>
            //             ประจำวันที่{' '}
            //             <Moment format="DD/MM/YYYY HH:mm:ss">
            //             </Moment>
            //         </span>
            //     )}
            // </div>
            // {this.getIdFromUrl() && (
            //     <div className={classes.small}>{this.state.lazadaImportOrderName}</div>
            // )}
          }
        </div>
      </div>
      <Line />
      <MenuWrapper>
        <ul>
          <li>
              <a href={`/lazada-shipment-subagent?id=${getIdFromUrl()}&orderid=${getorderIdFromUrl()}&accid=${getaccountIdFromUrl()}`}><span>1</span>งานขนส่ง</a>
          </li>
          <li className="active">
              <a href={`/lazada-3pl-detail?id=${getIdFromUrl()}&orderid=${getorderIdFromUrl()}&accid=${getaccountIdFromUrl()}`}><span>2</span>รายงานงานขนส่ง</a>
          </li>
          <li>
              <a href={`/lazada-cod-detail?id=${getIdFromUrl()}&orderid=${getorderIdFromUrl()}&accid=${getaccountIdFromUrl()}`}><span>3</span>รายงานเงิน COD</a>
          </li>
        </ul>
      </MenuWrapper>
    </div>
  </SubHeader>
)

export default SubHeader3PL