import gql from 'graphql-tag';

export default gql`
query accountInfoListSearchAgent (
    $account_info_type:Int
		$account_info_business_name: String
) {
    accountInfoList (
        show_data : 200,
        account_info_type: $account_info_type
        account_info_business_name: $account_info_business_name
    ) {
        account_info {
      _id
    account_info_business_name
  }
    }
}
`;
