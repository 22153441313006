import React from 'react';
import Autosuggest from 'react-autosuggest';
import TRANSPORTER_INFO_LIST_QUERY from '../../../graphql/query/transporterInfoList';
import styled from 'styled-components';
import { client } from '../../../../../index';

const Container = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

class SelectCustomerField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transporterSuggestions: [],
      transporter: '',
    };
  }

  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: TRANSPORTER_INFO_LIST_QUERY,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        transporterSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      transporterSuggestions: [],
    });
  };

  shouldRenderSuggestions() {
    return true;
  }

  render() {
    return (
      <Container>
        <Autosuggest
          suggestions={this.state.transporterSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          focusInputOnSuggestionClick={false}
          inputProps={{
            placeholder: 'ค้นหา',
            value: this.state.transporter,
            onChange: event =>
              this.setState(
                {
                  transporter: event.target.value,
                },
                () => {
                  if (this.props.onSearch) {
                    this.props.onSearch();
                  }
                }
              ),
            type: 'text',
            className: 'form-control',
          }}
          onSuggestionSelected={(event, { suggestion }) => {
            this.setState(
              {
                transporter: suggestion.name,
              },
              () => {
                if (this.props.onSelected) {
                  this.props.onSelected(suggestion.id);
                }
              }
            );
          }}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
        />
      </Container>
    );
  }
}

export default SelectCustomerField;
