import gql from 'graphql-tag';

export default gql`
  query search(
    $page_number: Int
    $shipment_status: Int
    $shipment_number: String
    $create_date: String
    $order_by: String
    $order_type: String
    $shipper_email: String
    $pick_up_date: String
    $re_shipment: Int
    $transporter: ID
    $admin: String
    $driver: Int
    $setting_truck_type_id: ID
    $shipment_type: Int
    $shipper: ID
  ) {
    shipmentList(
      shipper: $shipper
      page_number: $page_number
      show_data: 30
      order_by: $order_by
      order_type: $order_type
      shipment_status: $shipment_status
      shipment_number: $shipment_number
      create_date: $create_date
      shipper_email: $shipper_email
      pick_up_date: $pick_up_date
      re_shipment: $re_shipment
      transporter: $transporter
      admin: $admin
      driver: $driver
      setting_truck_type_id: $setting_truck_type_id
      shipment_type: $shipment_type
    ) {
      shipment {
        _id
        shipment_type
        driver_name_under_mama
        driver_tel_under_mama
        driver_name
        driver_phone_code
        driver_phone_number
        round_trip
        admin
        account_business_id
        re_shipment
        shipment_status
        shipment_number
        shipper
        shipper_detail
        shipper_info {
          account_info_email
        }
        user_setting_truck_id
        driver_user_id
        shipment_tracking_number
        distance
        paid_type
        paid_status
        create_date
        truck_type_name {
          th
        }
        setting_truck_name {
          th
        }
        pick_up_date
        shipment_address {
          mode
          address
          subdictrict
          province
          contact_name
          contact_tel
          paid_by_cod
        }
        shipment_other_detail {
          remark
        }
        cancel_comment
        transporter
        transporter_detail
        transporter_name
      }
      total_page
    }
  }
`;
