import React from 'react';
import { Query } from 'react-apollo';
import { FormControl } from 'react-bootstrap';
import kaWarehousesQuery from '../../graphql/query/kaWarehouses';

const SelectKaWarehouses = ({ value, onChange, selectLabel }) => {
  const label = selectLabel || 'เลือกจุดส่งสินค้า';

  return (
    <Query query={kaWarehousesQuery} fetchPolicy="cache-and-network">
      {({ data, loading, error }) => {
        return (
          <FormControl
            componentClass="select"
            placeholder="select"
            value={value}
            onChange={event => {
              const extraData = data.kaWarehouses.find(item => item._id === event.target.value);
              onChange(event, extraData);
            }}
            disabled={error || loading}
          >
            <option value="">{loading ? 'กำลังโหลด...' : label}</option>
            {!loading &&
              !error &&
              data.kaWarehouses.map(item => (
                <option key={item._id} value={item._id}>
                  {item.tag_name}
                </option>
              ))}
          </FormControl>
        );
      }}
    </Query>
  );
};

export default SelectKaWarehouses;
