import gql from 'graphql-tag';

export default gql`
query search(
    $account_info_id: ID
  ){
      summaryDashboard(
         account_info_id 	: $account_info_id
      ){
        credit_used
        credit_approved
        overdue_price
        overdue_grand_price
        overdue_items
      }
}
`;
