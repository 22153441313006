import gql from 'graphql-tag';

export default gql`
mutation UpdateSendShipmentToMama(
    $shipment_id: ID!
){
    sendShipmentToMama(
        shipment_id:$shipment_id
    ){
        succeed
    }
  }
`;
