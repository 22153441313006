import gql from 'graphql-tag';

export default gql`
mutation datamasterSlaLevelUpdate(
        $_id: ID!,
		$datamasterSlaLevelUpdate: datamasterSlaLevelUpdateArg
    ){
    datamasterSlaLevelUpdate(
        _id: $_id
        datamasterSlaLevelUpdate: $datamasterSlaLevelUpdate
    ){
      _id
    }
  }
`;
