import gql from 'graphql-tag';

export default gql`
	query SettingTruckList {
		settingTruckList {
			_id
			setting_truck_name {
				th
			}
			setting_truck_type {
				_id
				truck_type_name {
					th
				}
				truck_type_detail {
					th
				}
				truck_type_size
				truck_type_weight
				truck_type_enable
				truck_type_pic
			}
		}
	}
`;
