import gql from 'graphql-tag';

export default gql`
  mutation AgentGenQrCodeShipmentParcel($shipment: [ID]!, $delivery: [ID]!) {
    agentGenQrcodeShipmentParcel(shipment: $shipment, delivery: $delivery) {
      qr_id
      qr_ref1
      qr_ref2
      qr_gen_date
      qr_paid_amount
      qr_image
      billing_cod
    }
  }
`;
