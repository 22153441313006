import React from "react";
import Tabs from "../Tabs";
import urijs from "urijs";
import { Grid } from "react-bootstrap";

class Layout extends React.Component {
  getParamUrl = () => {
    const uriRoute = urijs(window.location.href);
    const uriFragment = uriRoute.fragment(true);
    const pathname = uriFragment.pathname();

    return pathname;
  };

  setActive = path => {
    const paramUrl = this.getParamUrl();
    return path === paramUrl;
  };

  openUrl = url => {
    window.location = url;
  };

  render() {
    const menuTabs = [
      {
        icon: "fal fa-file-excel",
        title: "Import file",
        active: this.setActive("/jd-import-file"),
        toUrl: "/jd-import-file"
      },
      {
        icon: "fal fa-file-alt",
        title: "Booking file",
        active: this.setActive("/jd"),
        toUrl: "/jd"
      },
      {
        icon: "fal fa-truck",
        title: "Shipment list",
        active: this.setActive("/jd-shipment-list"),
        toUrl: "/jd-shipment-list"
      },
      {
        icon: "fal fa-user",
        title: "แจงงานให้คนขับรถ",
        active: this.setActive("/jd-adddriver"),
        toUrl: "/jd-adddriver"
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
          <div {...this.props}>
            <Tabs menu={menuTabs} />
            <div>{this.props.children}</div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default Layout;
