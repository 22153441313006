/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import urijs from 'urijs';
import styled from 'styled-components';
import Moment from 'react-moment';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import HeaderLayoutWithBackUrl from '../../components/HeaderLayoutWithBackUrl';
import { Grid, Row, Col, NavDropdown, NavItem } from 'react-bootstrap';
import { ShipmentDetailTab, ConfirmStatusTab, CodTab } from './containers';
import shipmentListQuery from './graphql/query/shipmentList';
import { ShipmentDetailContext } from './contexts/ShipmentDetailContext';
import ShipmentDetailContextProvider from './contexts/ShipmentDetailContext';
import ShipmentKa from './graphql/query/shipmentKA';
import CostPerParcel from './containers/CostPerParcel';
import ButtonClickSentToAdmin from './components/ButtonClickSentToAdmin';
import parcelCostByShipment from './containers/CostPerParcel/graphql/parcelCostByShipment';

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)``;
const Message = styled.div`
  margin: 10px;
`;

class ShipmentList extends React.Component {
  getIdFromUrl = () => {
    return urijs(window.location.href).query(true).id;
  };

  constructor(props) {
    super(props);
    this.state = {
      stage: urijs(window.location.href).query(true).tab || 'detail',
    };
  }

  render() {
    return (
      <ShipmentDetailContextProvider>
        <ShipmentDetailContext.Consumer>
          {context => {
            return (
              <div className="content" style={{ paddingTop: 0 }}>
                <Query
                  query={ShipmentKa}
                  variables={{
                    _id: this.getIdFromUrl(),
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ data, loading, error }) => {
                    let shipmentName = '';
                    let shipmentTrip = '';
                    let dataShipmentKA = null;
                    if (!loading && !error && data.shipmentKA) {
                      const { shipment_name, shipment_trip } = data.shipmentKA;
                      shipmentName = shipment_name;
                      shipmentTrip = shipment_trip;
                      dataShipmentKA = data.shipmentKA;
                    }

                    if (data?.shipmentKA?._id) {
                      return (
                        <Query
                          query={parcelCostByShipment}
                          fetchPolicy="network-only"
                          variables={{
                            shipment_id: data?.shipmentKA?._id,
                          }}
                        >
                          {({
                            data: dataParcelCostByShipment,
                            loading: loadingParcelCostByShipment,
                          }) => {
                            if (loadingParcelCostByShipment) {
                              return 'Loading...';
                            } else {
                              return (
                                <React.Fragment>
                                  <HeaderLayoutWithBackUrl
                                    backUrl={`/ka-shipment-list-new`}
                                    menus={[
                                      {
                                        title: 'ข้อมูลงานขนส่ง',
                                        stage: 'detail',
                                        icon: 'fas fa-file-alt',
                                      },
                                      {
                                        title: 'ข้อมูลสถานะการขนส่ง',
                                        stage: 'confirm',
                                        icon: 'fas fa-truck',
                                        disabled: dataShipmentKA
                                          ? dataShipmentKA.confirm_delivery_status
                                            ? dataShipmentKA.confirm_delivery_status === 1
                                            : true
                                          : true,
                                      },
                                      {
                                        title: 'ข้อมูล COD',
                                        stage: 'cod',
                                        icon: 'fas fa-box-usd',
                                        disabled: dataShipmentKA
                                          ? dataShipmentKA.confirm_cod_status
                                            ? dataShipmentKA.confirm_cod_status === 1
                                            : true
                                          : true,
                                      },
                                      {
                                        title: 'ต้นทุน / ขาย',
                                        stage: 'cost',
                                        icon: 'far fa-sack-dollar',
                                        hide: dataShipmentKA.trip_rloy,
                                        disabled: dataShipmentKA
                                          ? dataShipmentKA.ka_create_cost_status >= 2
                                            ? false
                                            : true
                                          : true,
                                      },
                                    ]}
                                    stage={this.state.stage}
                                    selectedState={stage => {
                                      window.history.pushState(
                                        'data',
                                        null,
                                        `/ka-shipment-detail?${new URLSearchParams({
                                          id: this.getIdFromUrl(),
                                          tab: stage,
                                        }).toString()}`
                                      );
                                      this.setState({ stage: stage });
                                    }}
                                  />
                                  <div
                                    style={{
                                      backgroundColor: '#fff',
                                      padding: '20px 20px 20px 20px',
                                      marginBottom: '10px',
                                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                                    }}
                                  >
                                    {this.state.stage === 'cost' ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginTop: 'auto',
                                            marginBottom: 'auto',
                                          }}
                                        >
                                          Trip No: {shipmentTrip}
                                          {dataShipmentKA.trip_rloy && (
                                            <span
                                              class="label label-info"
                                              style={{ marginLeft: 10 }}
                                            >
                                              Rloy
                                            </span>
                                          )}
                                        </div>
                                        {(dataParcelCostByShipment?.parcelCostByShipment?._id ===
                                          null ||
                                          dataParcelCostByShipment?.parcelCostByShipment
                                            ?.costing_status === 4) && (
                                          <ButtonClickSentToAdmin
                                            data={data}
                                            shipmentId={data?.shipmentKA?._id}
                                            prepairData={dataParcelCostByShipment}
                                          />
                                        )}
                                      </div>
                                    ) : (
                                      <div>
                                        {shipmentTrip ? (
                                          <div>
                                            Trip No: <strong>{shipmentTrip}</strong>
                                            {dataShipmentKA.trip_rloy && (
                                              <span
                                                class="label label-info"
                                                style={{ marginLeft: 10 }}
                                              >
                                                Rloy
                                              </span>
                                            )}
                                          </div>
                                        ) : (
                                          <div>
                                            Trip Name:{' '}
                                            <strong>{shipmentName ? shipmentName : ''}</strong>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>

                                  <Grid fluid>
                                    <div>
                                      <Row>
                                        <Col md={12}>
                                          {loading && <Message>Loading...</Message>}
                                          {error && <Message>{error}</Message>}
                                          {!loading && !error && this.state.stage === 'detail' ? (
                                            <ShipmentDetailTab _id={data.shipmentKA._id} />
                                          ) : this.state.stage === 'confirm' ? (
                                            <ConfirmStatusTab
                                              loading={loading}
                                              driverName={
                                                data.shipmentKA && data.shipmentKA.driver_name
                                              }
                                              user_setting_truck_license_plate={
                                                data.shipmentKA &&
                                                data.shipmentKA.user_setting_truck_license_plate
                                              }
                                              trackingList={
                                                data.shipmentKA && data.shipmentKA.tracking
                                                  ? data.shipmentKA.tracking.tracking_detail_lazada
                                                      .all_delivery
                                                  : []
                                              }
                                            />
                                          ) : this.state.stage === 'cod' ? (
                                            <CodTab
                                              driverName={
                                                data.shipmentKA && data.shipmentKA.driver_name
                                              }
                                              user_setting_truck_license_plate={
                                                data.shipmentKA &&
                                                data.shipmentKA.user_setting_truck_license_plate
                                              }
                                              trackingList={
                                                data.shipmentKA && data.shipmentKA.tracking
                                                  ? data.shipmentKA.tracking.tracking_detail_lazada
                                                      .all_delivery
                                                  : []
                                              }
                                              shipment_accept_cod={
                                                data.shipmentKA &&
                                                data.shipmentKA.shipment_accept_cod
                                              }
                                              confirm_cod_status={
                                                data.shipmentKA &&
                                                data.shipmentKA.confirm_cod_status
                                              }
                                              cod_status={
                                                data.shipmentKA &&
                                                data.shipmentKA.billing_cod.cod_status
                                              }
                                              cod_in_number={
                                                data.shipmentKA &&
                                                data.shipmentKA.billing_cod.cod_in_number
                                              }
                                              cod_in_date={
                                                data.shipmentKA &&
                                                data.shipmentKA.billing_cod.cod_in_date
                                              }
                                              document={
                                                data.shipmentKA &&
                                                data.shipmentKA.billing_cod.document
                                              }
                                              reject_remark={
                                                data.shipmentKA &&
                                                data.shipmentKA.billing_cod.reject_remark
                                              }
                                            />
                                          ) : this.state.stage === 'cost' ? (
                                            <CostPerParcel
                                              truckId={data.shipmentKA.setting_truck_type_id}
                                              data={dataParcelCostByShipment}
                                              productCount={
                                                data.shipmentKA?.tracking?.tracking_detail_lazada?.all_delivery?.filter(
                                                  row => {
                                                    return row?.delivery_status === 3;
                                                  }
                                                )?.length || 0
                                              }
                                              productTarget={
                                                data.shipmentKA?.tracking?.tracking_detail_lazada
                                                  ?.all_delivery?.length || 0
                                              }
                                            />
                                          ) : null}
                                        </Col>
                                      </Row>
                                    </div>
                                  </Grid>
                                </React.Fragment>
                              );
                            }
                          }}
                        </Query>
                      );
                    } else {
                      return <div>Not found</div>;
                    }
                  }}
                </Query>
              </div>
            );
          }}
        </ShipmentDetailContext.Consumer>
      </ShipmentDetailContextProvider>
    );
  }
}

export default ShipmentList;
