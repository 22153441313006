import React, { Component} from "react";
import Moment from 'react-moment';
import { Query, graphql, compose, Mutation } from "react-apollo";
import ReactPaginate from 'react-paginate';
import { Grid, Row, Col, Table, FormGroup, FormControl} from "react-bootstrap";

import moment from 'moment';
import _ from 'lodash';

import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import favoriteAndBlacklistDriverListQuery from "./graphql/query/favoriteAndBlacklistDriverList"
import accountInfoListQuery from './graphql/query/accountInfoList';

import CardTable from "components/CardTable/CardTable.jsx";
import styled from 'styled-components';

// const s3 = 'https://s3-ap-southeast-1.amazonaws.com/media.kaikin.co';

const Container = styled.div`
    & .react-autosuggest__suggestions-list {
        list-style: none;
        color: #000000;
        padding: 0;
        margin: 0;
        border: 1px solid #E3E3E3;
        margin-top: 0px;
        border-radius: 4px;

        position: absolute;
        background-color: white;
        z-index: 999;
        min-width: 150px;
        white-space: nowrap;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.16);
    }

    & .react-autosuggest__suggestions-container {
        // position: relative;
    }

    & .react-autosuggest__suggestion {
        padding: 10px 10px;
        background-color: #FFFFFF;
        cursor: pointer;

        &:hover {
            background-color: #E3E3E3;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #E3E3E3;
        }
    }

    & .react-autosuggest__suggestion--highlighted {
        background-color: #E3E3E3;
    }
`;

const HeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	border-bottom: 1px solid #efefef;
	display: flex;
	flex-direction: row;
	padding: 10px;

	

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}

		
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
		background-color: #E3E3E3;
		width: 1px;
		margin-left: 20px;
		margin-right: 20px;
	}

	& .menu-wrapper{
		& ul{
			padding-left: 0;
			margin: 0;
			display: flex;

			& li{
				list-style: none;
				margin-right: 15px;
				padding: 22px 10px 15px;

				& a{
					font-size:18px;
					cursor: pointer;
					& i{
						font-size:18px;
						color: #000000;
                    }
                    
				}
			}
			& li.active{
				border-bottom: 2px solid #d90402;
			}
			
		}
	}
`;

const TabHeaderContainer = styled.div`
    background-color: #FFFFFF;
    height: 42px;
    border-bottom: 1px solid #efefef;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    
    & .sub-header {
        &-left {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        &-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            & button:not(:last-child) {
                margin-right: 10px;
            }
        }        
    }

    & a, a:hover, a:active, a:focus, a:visited {
        color: #000000;
        font-size: 18px;

        & i {
            color: #D90101;
            font-size: 6px;
            position: absolute;
            top: 8px;
            right: 6px;
        }

        & i.left {
            float: left;
            position: relative;
            font-size: 18px;
            top: 3px;
            color: #000000;
        }
    }

    & .line {
        height: 100%;
        background-color: #E3E3E3;
        width: 1px;
        margin-left: 20px;
        margin-right: 20px;
    }

    & .menu-wrapper{
        & ul{
            padding-left: 0;
            margin: 0;
            display: flex;

            & li{
                list-style: none;
                padding: 10px 15px;
                position: relative;
                margin-right:10px;
                & a{
                    font-size:18px;
                    cursor: pointer;
                    color:#808080;
                }
            }
            & li.active{
                border-bottom: 2px solid #d90402;
                & a{
                    color:#000000;
                }
            }
            
        }
    }
`;

const TabContentContainer = styled.div`
    & .tab-item{

    }

    & table thead small{
        width: 100%;
        float: left;
        font-weight: normal;
    }
    & table thead span{
        font-size: 85%;
        font-weight: normal;   
    }

    & table tbody td{
        font-weight: bold;
    }
    & table tbody small{
        width: 100%;
        float: left;
        font-weight: normal;
    }
    & table tbody span.new{
        font-size: 85%;
        font-weight: normal; 
        color: #D90101;
    }
    & table tbody span.label{
        font-size: 85%;
        font-weight: normal; 
        float: left;
    }
`;

const SubHeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
	padding: 10px 20px;

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
    background-color: #E3E3E3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
	}
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;
class ShipperDriver extends Component {
    constructor(props) {
        super(props)

        let params = new URLSearchParams(document.location.search.substring(1));
        let id = params.get("id");

        this.state = {
            account_info_id: id,
            show: false,
            total_page: 0,
            page_number: 1,
            order_by: null,
            order_type: null,

            ShipperNumber: '',
			ShipperName: '',
			accountInfoId: '',
			account_info_business_name: '',
			account_info_business_type: '',
			account_info_email: '',
			account_info_phone_number: '',
            account_users :[],
            selectTap : 1,
		};
    }

    componentWillReceiveProps(newProps) {
		const { loading, error } = newProps.accountInfoListQuery;
		if (!loading && !error) {
			const {
				accountInfoList: { account_info },
				accountInfoList: { account_business },
			} = newProps.accountInfoListQuery;
			const accountInfo = account_info;
			const accountBusiness = account_business;

			if (accountInfo === null) {
				window.location.href = '/shipper';
				return;
			}

			this.setState({
                ShipperNumber: account_info[0].account_info_number,
                ShipperName: account_info[0].account_info_business_name,
				accountInfoId: account_info[0]._id,
				account_info_business_name: account_info[0].account_info_business_name,
				account_info_business_type: account_info[0].account_info_business_type.th,
				account_info_email: account_info[0].account_info_email,
				account_info_phone_number: `(${account_info[0].account_info_phone_code}) ${account_info[0].account_info_phone_number}`,
				account_users : account_info[0].account_users,
            })
		}
	}

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }

    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    nextPage(page,total_page,refetch) {
        this.setState({ page_number: page+1,total_page });
        // //console.log(this.state);
        refetch();
    }

    render() {
        return (
            <Container >
				<HeaderContainer>
                    <div className="sub-header-left">
                        <a href="/shipper" className="btn-back"><i className="far fa-arrow-left"></i> กลับ</a>
                        <div className="line"></div>
                        <div className="menu-wrapper">
                            <ul>
                                <li>
                                    <a href={`/shipperdetail?id=${window.location.search.split('?id=')[1]}`}> <i className="fal fa-address-card"></i> ข้อมูลการลงทะเบียน</a>
                                </li>
                                <li>
                                    <a href={`/shippershipment?id=${window.location.search.split('?id=')[1]}`}> <i className="fal fa-truck"></i> รายการขนส่ง</a>
                                </li>
                                <li className="active">
                                    <a href={`/shipperdriver?id=${window.location.search.split('?id=')[1]}`}> <i className="fal fa-user-hard-hat"></i> พนักงานขับรถคนโปรด</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </HeaderContainer>
                <SubHeaderContainer>
                    <div className="sub-header-left">
                        <strong>CU{this.state.ShipperNumber} : { this.state.ShipperName }</strong>
                    </div>
                </SubHeaderContainer>
            
				
                <Grid fluid style={{paddingTop:15}}>
                    <Row>
                        <Col md={12}>
                            <CardTable
                                title="พนักงานขับรถคนโปรด / พนักงานขับรถที่แบน"
                                category="สำหรับการจัดการพนักงานขับรถคนโปรด / พนักงานขับรถที่แบน"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                    <div>
                                        <TabHeaderContainer>
                                            <div className="sub-header-left">
                                                <div className="menu-wrapper">
                                                    <ul>
                                                        <li className={this.state.selectTap == 1 && "active"}>
                                                            <a onClick={() => this.setState({selectTap:1})}> <i className="left fal fa-smile"></i> พนักงานขับรถคนโปรด</a>
                                                        </li>
                                                        <li className={this.state.selectTap == 2 && "active"}>
                                                            <a onClick={() => this.setState({selectTap:2})}> <i className="left fal fa-frown"></i>  พนักงานขับรถที่แบน</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </TabHeaderContainer>    

                                        <TabContentContainer>
                                                <Query
                                                    query={favoriteAndBlacklistDriverListQuery}
                                                    variables={{
                                                        shipper: this.state.account_info_id,
                                                        mode: this.state.selectTap == 1 ? 'favorite' : 'blacklist',
                                                        page_number: this.state.page_number,
                                                        order_by: this.state.order_by,
                                                        order_type: this.state.order_type,
                                                        show_data:30
                                                    }}
                                                >
                                                {
                                                ({ loading, error, data, refetch ,variables}) => {
                                                    if(loading) return "loading...";
                                                    // if(error) return null;
                                                    const driverUser = data.favoriteAndBlacklistDriverList.driver_user;
                                                    return (                    
                                                        <div className="tab-item">
                                                            <PageWrapper style={{ textAlign: "right" }}>
                                                                <div className="text"> หน้า :</div>
                                                                    <FormControl
                                                                        type="number"
                                                                        min={1}
                                                                        value={this.state.page_number}
                                                                        onChange={(event) => 
                                                                        setInterval(this.nextPage(Number(event.target.value) -1, loading || error ? '0' : data.favoriteAndBlacklistDriverList.total_page,refetch), 1000)
                                                                        }
                                                                        name="page_number"
                                                                    />
                                                                <div className="text"> {loading || error ? '' : `จาก ${data.favoriteAndBlacklistDriverList.total_page}`}</div>
                                                            </PageWrapper>

                                                            <div className="table-vertical">
                                                                <Table striped hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ width: "110px",minWidth:"110px" }}>
                                                                                เครื่องมือ
                                                                            </th>
                                                                            <th style={{ width: "165px",minWidth:"165px" }}  className={this.renderSorting("summary_invoice_number")} onClick={() => this.sort("summary_invoice_number", refetch)}>
                                                                                ชื่อพนักงานขับรถ
                                                                            </th>
                                                                            <th style={{ width: "140px",minWidth:"140px" }}>
                                                                                ประเภทรถ
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {(loading || error) ?
                                                                        (
                                                                            <tr>
                                                                                <td colSpan="3" style={{textAlign:"center"}}>loading...</td>
                                                                            </tr>
                                                                        ) : driverUser.length === 0 ? (
                                                                            <tr>
                                                                                <td colSpan="3" style={{textAlign:"center"}}>ไม่พบข้อมูล</td>
                                                                            </tr>
                                                                        ) :
                                                                        driverUser.map((prop, key) => (
                                                                        <tr key={key} data-id={prop._id}>
                                                                            <td>
                                                                                <button onClick={() => window.open(`/driverdetail?id=${prop._id}`, '_blank')} type="button" className="btn btn-default" > ดูรายละเอียด </button>
                                                                            </td>
                                                                            <td>
                                                                                {prop.driver_name}
                                                                            </td>
                                                                            <td>
                                                                                {prop.setting_truck_name.th} {prop.truck_type_name.th}
                                                                            </td>
                                                                        </tr>
                                                                        ))
                                                                    }
                                                                    </tbody>
                                                                </Table>
                                                            </div>

                                                            <div style={{ textAlign: "right" }}>
                                                                {(loading || error) ? null :
                                                                    (<ReactPaginate pageCount={data.favoriteAndBlacklistDriverList.total_page} forcePage={this.state.page_number -1}  containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected, data.favoriteAndBlacklistDriverList.total_page,refetch); }} />)
                                                                }
                                                            </div>  
                                                        </div> 
                                                        )
                                                    }}  
                                                </Query>
                                            
                                        </TabContentContainer>    
                                    </div>
                                    }
                                />
                            </Col>
                    </Row>
                </Grid>
            </Container>
        );
    }
}

export default compose(graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: props => ({
        fetchPolicy: 'network-only',
        variables: {
            _id: props.location.search.split('?id=')[1],
        },
    }),
}))(ShipperDriver);