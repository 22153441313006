import gql from 'graphql-tag';

export default gql`
  query AgentManageCOD(
    $_id: ID!
    $lazada_import_order_id: ID
    $shipment_prefix: String
    $shipment_trip: String
    $driver_name: String
    $user_setting_truck_license_plate: String
    $shipment_running_number: String
    $shipment_number: String
    $agent_shipment_status: Int
    $showData: Int
    $page: Int
  ){
    AgentManageCOD(
      _id: $_id
      lazada_import_order_id: $lazada_import_order_id
      shipment_prefix: $shipment_prefix
      shipment_trip: $shipment_trip
      driver_name: $driver_name
      user_setting_truck_license_plate: $user_setting_truck_license_plate
      shipment_running_number: $shipment_running_number
      shipment_number: $shipment_number
      agent_shipment_status: $agent_shipment_status
      showData: $showData
      page: $page
    ){
      shipment {
        _id
        lazada_import_order_id
        name
        agent_shipment_status
        pick_up_date
        trip_name
        total_price_cod
        outstanding_balance_cod
        name_agent
        plan_date
        proof_of_transfer{
          _id
          start_date
          trip_name
          name_agent
          account_info_id
          total_price_cod
          remark
          status
          slip
          transfer_date
        }
        shipmentList{
          _id
          lazada_import_order_id
          shipment_status
          driver_name
          driver_phone_code
          driver_phone_number
          user_setting_truck_license_plate
          shipment_accept_cod
          shipment_cancel_cod
          shipment_driver_accept_cod
          shipment_total_cod
          shipment_running_number
          shipment_number
          shipment_trip
        }
      }
      total_page
    }
  }	
`;
