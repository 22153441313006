import React, { useState, useEffect } from 'react';
import { Table, Tooltip, OverlayTrigger, FormControl, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import Moment from 'react-moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CustomCheckBox from 'views/KaCustomer/components/CustomCheckBox';
import shipmentWaitCodInByTrackingQuery from '../../graphql/query/shipmentWaitCodInByTracking';
import { Query } from 'react-apollo';
import moment from 'moment';
import SelectAgent from './SelectAgent';
import { withRouter } from 'react-router-dom';

const Tag = styled.div`
  background-color: #e5ffe5;
  color: #5dba5b;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 4px;
  width: max-content;
`;

const TrackingTable = ({ selectedTracking = [], onSelected, isHideCheckbox, ...props }) => {
  const [trackingList, setTrackingList] = useState([]);
  const [search, setSearch] = useState({
    transporterId: '',
    shipmentTrip: '',
    trackingNumber: '',
    tranferCodCashDate: '',
    trackingDeliveredDate: '',
    pageNumber: 1,
  });

  const updateSearch = value => {
    setSearch(prevState => ({
      ...prevState,
      ...value,
    }));
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const transferCodCashDate = urlParams.get('transferCodCashDate') || search.transferCodCashDate;
    const transporterId = urlParams.get('agent') || search.transporterId;
    const tab = urlParams.get('tab');

    if (tab === 'tracking') {
      updateSearch({
        transporterId,
        tranferCodCashDate: transferCodCashDate
          ? `${transferCodCashDate}:${transferCodCashDate}`
          : '',
      });
    }

    return () => {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete('agent');
      queryParams.delete('transferCodCashDate');
      props.history.replace({
        search: queryParams.toString(),
      });
    };
  }, []);

  const formatDateRange = dateRange => {
    if (!dateRange) return '';
    const dateSplit = dateRange.split(':');
    return `${moment(dateSplit[0]).format('DD/MM/YYYY')} - ${moment(dateSplit[1]).format(
      'DD/MM/YYYY'
    )}`;
  };

  const columns = [
    {
      key: 'tools',
      title: 'เครื่องมือ',
      width: '100px',
      search: (
        <>
          {!isHideCheckbox && (
            <CustomCheckBox
              checked={
                selectedTracking.length > 0 &&
                trackingList.length > 0 &&
                trackingList
                  .map(listItem => listItem.delivery_id)
                  .every(listId => selectedTracking.map(item => item.delivery_id).includes(listId))
              }
              onChange={event => {
                if (event.target.checked && trackingList.length > 0) {
                  onSelected(trackingList);
                } else {
                  onSelected([]);
                }
              }}
            >
              <span className="checkmark" />
            </CustomCheckBox>
          )}
        </>
      ),
    },
    {
      key: 'lazada_order_type',
      title: 'ประเภทงาน',
      width: '160px',
    },
    {
      key: 'trackingNumber',
      title: 'Tracking No.',
      width: '220px',
      search: (
        <FormControl
          type="text"
          placeholder="ค้นหาเลข Tracking Number"
          value={search.trackingNumber}
          onChange={event => {
            updateSearch({ trackingNumber: event.target.value });
          }}
        />
      ),
    },
    {
      key: 'trackingDeliveredDate',
      title: 'วันที่ส่งสำเร็จ',
      width: '200px',
      search: (
        <DateRangePicker
          onApply={(_event, picker) => {
            updateSearch({
              trackingDeliveredDate: handleDateRangePicker(picker),
            });
          }}
          onCancel={() =>
            updateSearch({
              trackingDeliveredDate: '',
            })
          }
          opens="right"
          containerStyles={{ display: 'block' }}
          format="DD/MM/YYYY"
        >
          <FormControl
            type="text"
            value={formatDateRange(search.trackingDeliveredDate)}
            placeholder="วันที่ส่งสำเร็จ"
          />
        </DateRangePicker>
      ),
    },
    {
      key: 'shipment_trip',
      title: 'Shipment / Trip No.',
      width: '250px',
      search: (
        <FormControl
          type="text"
          placeholder="ค้นหา Shipment / Trip No."
          value={search.shipmentTrip}
          onChange={event => {
            updateSearch({ shipmentTrip: event.target.value });
          }}
        />
      ),
    },
    {
      key: 'shipment_status',
      title: 'Status',
      width: '160px',
    },
    {
      key: 'cod_price',
      title: 'มูลค่า COD',
      width: '160px',
    },
    {
      key: 'tranfer_cod_cash_date',
      title: 'วันที่ต้องโอน',
      width: '200px',
      search: (
        <DateRangePicker
          onApply={(_event, picker) => {
            updateSearch({
              tranferCodCashDate: handleDateRangePicker(picker),
            });
          }}
          onCancel={() =>
            updateSearch({
              tranferCodCashDate: '',
            })
          }
          opens="right"
          containerStyles={{ display: 'block' }}
          format="DD/MM/YYYY"
        >
          <FormControl
            type="text"
            value={formatDateRange(search.tranferCodCashDate)}
            placeholder="วันที่ต้องโอน"
          />
        </DateRangePicker>
      ),
    },
    {
      key: 'transporter',
      title: 'Driver ID / ชื่อ 3PL',
      width: '160px',
      search: (
        <div style={{ position: 'relative' }}>
          <SelectAgent
            defaultValue={search.transporterId}
            onChange={valueChange =>
              updateSearch({
                transporterId: valueChange?.id || '',
              })
            }
          />
        </div>
      ),
    },
    {
      key: 'last_update',
      title: 'อัพเดทล่าสุด',
      width: '160px',
    },
  ];

  const formatDateTime = date => {
    return date ? <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> : '-';
  };

  const formatDate = date => {
    return date ? <Moment format="DD/MM/YYYY">{date}</Moment> : '-';
  };

  const formatCurrency = number => {
    return `฿ ${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;
  };

  const renderOrderType = status => {
    switch (status) {
      case 1:
        return (
          <Tag
            style={{
              backgroundColor: '#E5FFE5',
              color: '#5DBA5B',
            }}
          >
            Last Mile (LM)
          </Tag>
        );
      case 2:
        return (
          <Tag
            style={{
              backgroundColor: '#ffb7b0',
              color: '#ff1100',
            }}
          >
            Return to Merchant (RM)
          </Tag>
        );
      case 5:
        return (
          <Tag
            style={{
              backgroundColor: '#ffefd9',
              color: '#ff9500',
            }}
          >
            Return (RT)
          </Tag>
        );
      case 9:
        return (
          <Tag
            style={{
              backgroundColor: '#d6949e',
              color: '#fff',
            }}
          >
            First Mile (FM)
          </Tag>
        );
      case 10:
        return (
          <Tag
            style={{
              backgroundColor: '#bcddfd',
              color: '#318de8',
            }}
          >
            Shuttle Haul (SH)
          </Tag>
        );
      case 11:
        return (
          <Tag
            style={{
              backgroundColor: '#ffe2bb',
              color: '#e28200',
            }}
          >
            Reject Return (RR)
          </Tag>
        );
      case 12:
        return (
          <Tag
            style={{
              backgroundColor: '#d9e2ff',
              color: '#318DE8',
            }}
          >
            Direct Ship (DS)
          </Tag>
        );
      case 13:
        return (
          <Tag
            style={{
              backgroundColor: '#FFDB46',
              color: 'black',
            }}
          >
            Line Haul (LH)
          </Tag>
        );
      default:
        return status;
    }
  };

  const handleDateRangePicker = picker => {
    return `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
  };

  return (
    <Query
      query={shipmentWaitCodInByTrackingQuery}
      variables={{
        transporter: search.transporterId,
        shipment_trip: search.shipmentTrip,
        trackingNumber: search.trackingNumber,
        tranfer_cod_cash_date: search.tranferCodCashDate,
        trackingDeliveredDate: search.trackingDeliveredDate,
        page: search.pageNumber,
      }}
      onCompleted={dataComplete => {
        if (dataComplete?.shipmentWaitCodInByTracking?.trackings) {
          setTrackingList(dataComplete?.shipmentWaitCodInByTracking?.trackings);
        }
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data: data, loading, error }) => {
        let dataTable = [];
        if (data.shipmentWaitCodInByTracking) {
          const { trackings } = data.shipmentWaitCodInByTracking;
          dataTable = trackings
            .map(item => ({
              tools: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {!isHideCheckbox && (
                    <CustomCheckBox
                      style={{ display: 'inline-block' }}
                      checked={
                        !!selectedTracking.find(
                          dataAgent => dataAgent.delivery_id === item.delivery_id
                        )
                      }
                      onChange={event => {
                        if (event.target.checked) {
                          onSelected([...selectedTracking, item]);
                        } else {
                          const filter = selectedTracking.filter(
                            tracking => tracking.delivery_id !== item.delivery_id
                          );
                          onSelected(filter);
                        }
                      }}
                    />
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}
                  >
                    <a
                      href={`/ka-shipment-detail?id=${item._id}`}
                      target="_blank"
                      className="btn btn-default"
                    >
                      <i style={{ marginTop: '3px' }} className="fas fa-search"></i>
                    </a>
                  </OverlayTrigger>
                </div>
              ),
              lazada_order_type: renderOrderType(item.lazada_order_type),
              trackingNumber: item.trackingNumber,
              trackingDeliveredDate: formatDateTime(item.trackingDeliveredDate),
              shipment_trip: item.shipment_trip,
              shipment_status: (
                <Tag
                  style={{
                    backgroundColor: '#E28200',
                    color: '#fff',
                    gap: '5px',
                  }}
                >
                  รอจ่าย COD
                </Tag>
              ),
              cod_price: (
                <span style={{ color: '#5DBA5B', fontWeight: 'bold' }}>
                  {formatCurrency(item.cod_price)}
                </span>
              ),
              tranfer_cod_cash_date: formatDate(item.tranfer_cod_cash_date),
              transporter: (
                <OverlayTrigger
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-positioned-${item.delivery_id}`}>
                      <div style={{ fontSize: 18, color: '#000000', lineHeight: 1 }}>
                        <div>#{item.driver_number}</div>
                        <div>{item.driver_name}</div>
                        <div>{item.user_setting_truck_license_plate}</div>
                      </div>
                    </Popover>
                  }
                >
                  <div style={{ cursor: 'pointer' }}>{item.agent_name}</div>
                </OverlayTrigger>
              ),
              last_update: formatDateTime(item.last_update),
            }))
            .map((item, index) => (
              <tr key={index}>
                {columns.map(column => (
                  <td key={column.key}>{item[column.key]}</td>
                ))}
              </tr>
            ));
        }

        return (
          <div style={{ overflowX: 'scroll' }}>
            <Table striped hover>
              <thead>
                <tr>
                  {columns.map(item => (
                    <th key={item.title} style={{ minWidth: item.width }}>
                      {item.title}
                    </th>
                  ))}
                </tr>
                <tr>
                  {columns.map((item, index) => (
                    <th key={index}>{item.search}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={columns.length}>Loading...</td>
                  </tr>
                )}

                {error && (
                  <tr>
                    <td colSpan={columns.length}>Error</td>
                  </tr>
                )}

                {!loading && !error && (
                  <>
                    {dataTable.length === 0 && (
                      <tr>
                        <td colSpan={columns.length}>ไม่มีข้อมูล</td>
                      </tr>
                    )}

                    {dataTable.length > 0 && dataTable}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        );
      }}
    </Query>
  );
};

export default withRouter(TrackingTable);
