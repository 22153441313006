import gql from 'graphql-tag';

export default gql`
query  searchdatamasterJobTypeList{
    datamasterJobTypeList{
        _id
        jon_type_name
        position
        datamaster_truck_type
        {
            _id
            prefix
            setting_prefix_id
            setting_truck_type_id
            truck_size_name{
                th
                en
            }
            truck_type_name{
                th
                en
            }
            datamaster_service_category{
                _id
                job_type_id
                truck_type_id
                setting_prefix_id
                position
                prefix
                service_category_name {
                    th
                    en
                }
                datamaster_service_item{
                    _id
                    truck_type_id
                    datamaster_service_item_number
                    service_item_name{
                        th
                        en
                    }
                    cost
                    vat_cost
                    wht_cost
                    sale
                    vat
                    wht
                    unit
                    min_price
                    enable
                }
                create_date
            }
            create_date
        }
        create_date
    }
}
`;
