import React, { Component } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import { Query } from "react-apollo";
import ReactPaginate from 'react-paginate';
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger, Button, Modal, FormGroup, FormControl,Label } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from "components/CardTable/CardTable.jsx";
import CustomButton from "components/CustomButton/CustomButton.jsx";

import challengeListQuery from "./graphql/query/challengeList"

import baseUrl from "./../../config/baseUrl" 
import reportUrl from "./../../config/reportUrl" 

const tooltip = (
    <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>
);

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;

class Challenge extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total_page: 0,
            page_number: 1,
            order_by: "name",
            order_type: -1,
            name: "",
        }
    }

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }

    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    nextPage(page, total_page, refetch) {
        // console.log(page+1);
        this.setState({ page_number: page + 1, total_page });
        // console.log(this.state);
        refetch();
    }

    handleDateRangePicker(event, picker,refetch) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState({ create_date: date , show_create_date: show},() => { refetch() });
    }

    renderStatus(date) {
        if(moment() > moment(date)){
            return <Label bsStyle="danger">สิ้นสุด</Label>;
        }
        return <Label bsStyle="primary">ออนไลน์</Label>;
    }

    render() {
        // console.log(this.state);
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Query
                                query={challengeListQuery}
                                variables={{
                                    page_number: this.state.page_number,
                                    order_by: this.state.order_by,
                                    order_type: this.state.order_type,
                                    name: this.state.name,
                                }}
                            >
                                {
                                    ({ loading, error, data, refetch, variables }) => {
                                        console.log(data ,variables)
                                        var itemLength = 'loading';
                                        const itemIndicatorSize = (this.state.page_number - 1)*30;
                                        if(data.challengeList){
                                            if(data.challengeList.challenge.length > 0){
                                                itemLength = data.challengeList.challenge.length;
                                            }
                                        }
                                        return (
                                            <div>
                                                <CardTable
                                                    title="ตารางรายการ Challenge"
                                                    category="ข้อมูลงาน GIZTIX Challenge"
                                                    ctTableFullWidth
                                                    ctTableResponsive
                                                    content={
                                                        <div>
                                                            <PageWrapper style={{ textAlign: "right" }}>
                                                                <div className="text">{this.state.page_number === 1 ? `1 - ${itemLength}` : `${itemIndicatorSize + 1} - ${itemLength + itemIndicatorSize}`} หน้า :</div>
                                                                    <FormControl
                                                                        type="number"
                                                                        min={1}
                                                                        value={this.state.page_number}
                                                                        onChange={(event) => 
                                                                        setInterval(this.nextPage(Number(event.target.value) -1, loading || error ? '0' : data.challengeList.total_page,refetch), 1000)
                                                                        }
                                                                        name="page_number"
                                                                    />
                                                                <div className="text"> {loading || error ? '' : `จาก ${data.challengeList.total_page}`}</div>
                                                            </PageWrapper>
                                                            <div className="table-vertical">            
                                                                <Table striped hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{minWidth:"100px"}}>เครื่องมือ</th>
                                                                            <th style={{minWidth:"120px"}} className={this.renderSorting("name")} onClick={() => this.sort("name", refetch)}>ชื่อแคมเปญ</th>
                                                                            <th style={{minWidth:"140px"}}>สถานะ</th>
                                                                            <th style={{minWidth:"150px"}}>วันที่เริ่ม</th>
                                                                            <th style={{minWidth:"110px"}}>วันที่สิ้นสุด</th>
                                                                        </tr>
                                                                        <tr className="tableFilter">
                                                                            <th></th>
                                                                            <th>
                                                                                <FormControl
                                                                                    type="text"
                                                                                    value={this.state.name}
                                                                                    placeholder="ค้นหารหัสลูกค้า"
                                                                                    onChange={(event) => this.setState({ name: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                    name="name"
                                                                                />
                                                                            </th>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(loading || error) ?
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan="4" className="textCenter">{(loading) ? "Loading..." : error.message}</td>
                                                                                </tr>
                                                                            ) : 
                                                                            data.challengeList.challenge.map((prop, key) => 
                                                                            (
                                                                                <tr key={key}>
                                                                                    <td style={{ width: "100px" }}>
                                                                                        <Button onClick={() => window.location = `/giztixchallengedetail?id=${prop._id}`}><i style={{ color: "#6666666" }} className="fas fa-search"></i> ดูข้อมูล</Button>
                                                                                    </td>
                                                                                    <td>{prop.name}</td>
                                                                                    <td style={{ width: "130px" }}>{this.renderStatus(prop.end_date)}</td>
                                                                                    <td style={{ width: "130px" }}><Moment format="DD/MM/YYYY HH:mm">{prop.start_date}</Moment></td>
                                                                                    <td style={{ width: "130px" }}><Moment format="DD/MM/YYYY HH:mm">{prop.end_date}</Moment></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>        
                                                        </div>
                                                    }
                                                />

                                                <div style={{ textAlign: "right" }}>

                                                    {(loading) ? null :
                                                        (<ReactPaginate pageCount={data.challengeList.total_page} forcePage={this.state.page_number - 1} containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected, data.challengeList.total_page, refetch); }} />)
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            </Query>

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Challenge;