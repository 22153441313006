import gql from 'graphql-tag';

export default gql`
query searchCostingDocuments(
    $costing_id: ID!
    $delete: Boolean!
  ){
    costingDocuments(
        costing_id: $costing_id,
        delete: $delete
      ) {
    documents {
        _id
        costing_id
        document_file
        delete
        create_date
        last_update
    }
    totalDocument
    }
  }
`;
