import React from 'react';
import { SubHeader, ModalConfirm } from '../../components';
import {
    Grid,
    Row,
    Col,
    Table,
    Tooltip,
    OverlayTrigger,
    Button,
    Modal,
    FormControl,
    Label,
    NavItem,
} from 'react-bootstrap';
import CardTable from 'components/CardTable/CardTable.jsx';
import classes from './ShipmentAgent.module.scss';
import styled from 'styled-components';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import shipmentAdminListQuery from './graphql/query/shipmentAdminList';
import urijs from 'urijs';
import Moment from 'react-moment';
import driverUserListQuery from './graphql/query/driverUserList';
import userTruckListQuery from './graphql/query/userTruckList';
import ReactPaginate from 'react-paginate';
import accountInfoListQuery from './graphql/query/accountInfoList';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import customerId from 'config/customerId';
import CreateShipmentModal from './components/CreateShipmentModal';

const Bold = styled.div`
    font-weight: bold;
`;
const Tag = styled.div`
    background-color: #E5FFE5;
    color: #5DBA5B;
    font-weight: bold;
    padding: 2px 5px;
    margin-left: 30px;
    border-radius: 4px;
`;
const Line = styled.div`
    height: 36px;
    width: 0px;
    margin: 0px 20px;
    border-left: 1px solid #E3E3E3;
`;
const MenuWrapper = styled.div`
  width: 100%;
  & ul{
    padding-left: 0;
    margin: 0;
    display: flex;

    & li{
      list-style: none;
      margin-right: 15px;
      padding: 22px 10px 15px;

      & a{
        font-size:18px;
        cursor: pointer;
        color: #000000;
        & i{
          font-size:18px;
          color: #000000;
        }
      }
    }
    & li.active{
      border-bottom: 2px solid #d90402;
    }
    
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;
const AutosuggestContainer = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const styleddefault = {
    backgroundColor: '#B3B3B3',
    border: '1px solid #B3B3B3',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styleddefaultoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #B3B3B3',
    color: '#B3B3B3',
    fontWeight: 'bold',
};

const styledprimary = {
    backgroundColor: '#318DE8',
    border: '1px solid #318DE8',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styledprimaryoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #318DE8',
    color: '#318DE8',
    fontWeight: 'bold',
};

const styledsuccess = {
    backgroundColor: '#66C066',
    border: '1px solid #66C066',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styledsuccessoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #66C066',
    color: '#66C066',
    fontWeight: 'bold',
};
const styleddark = {
    backgroundColor: '#ffffff',
    border: '1px solid #000000',
    color: '#000000',
    fontWeight: 'bold',
};
const styleddanger = {
    backgroundColor: ' #FF0000',
    border: '1px solid #FF0000',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styleddangeroutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #FF0000',
    color: '#FF0000',
    fontWeight: 'bold',
};
const styledwarning = {
    backgroundColor: '#ffffff',
    border: '1px solid #FF9D00',
    color: '#FF9D00',
    fontWeight: 'bold',
};


class ShipmentAgent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shipmentName: '',
            pickupDate: '',
            displayPickupDate: '',
            shipmentStatus: 0,
            shipmentRunningNumber: '',
            admin: '',
            createDate: '',
            displayCreateDate: '',
            pageNumber: 1,
            lazadaImportOrderId: null,
            lazadaImportOrderName: '',
            lazadaImportOrderPickupDate: null,
            isOpenModalEdit3PL: false,
            isOpenModalNotEdit3PL: false,
            showData: 30,
            tripNo: '',

            //createShipmentModal
            idBookingFile: '',
            pickupDateForCreateShipment: '',
            bookingNumberForCreateShipment: '',
        };
    }

    renderStatus(status) {
        switch (status) {
            case 1:
                return <Label style={styleddefaultoutline} >รอเพิ่มคนรถ</Label>;
            case 2:
                return <Label style={styleddefault}>รอขนส่งรับงาน</Label>;
            case 10:
                return <Label style={styleddefaultoutline} >รอเริ่มงาน</Label>;
            case 3:
                return <Label style={styledprimaryoutline}>กำลังดำเนินการขนส่ง</Label>;
            case 4:
                return <Label style={styledsuccessoutline}>ขนส่งเสร็จสิ้น</Label>;
            case 5:
                return <Label style={styleddark}>ถูกยกเลิก</Label>;
            case 6:
                return <Label style={styleddangeroutline}>งานเกิดปัญหา</Label>;
            case 7:
                return <Label style={styleddefaultoutline} >แบบร่าง</Label>;
            case 8:
                return <Label style={styledprimary} >นัดหมายลูกค้าแล้ว</Label>;
            case 9:
                return <Label style={styledwarning}>กำลังคืนคลัง</Label>;
            case 11:
                return <Label style={styleddefault}>รอส่งสถานะ</Label>;
            case 12:
                return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
            case 13:
                return <Label style={styledsuccess}>สถานะผ่าน</Label>;
            case 14:
                return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
            case 15:
                return <Label style={styleddefault}>รอโอน COD</Label>;
            case 16:
                return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
            case 17:
                return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
            case 18:
                return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
            default:
                return '';
        }
    }

    renderStatusNew = (status, agentStatus) => {
        switch (status) {
            case 4:
                return <Label bsStyle="primary">รอเริ่มงาน</Label>;
            case 5:
                return (
                    <Label
                        bsStyle="info"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#337ab7',
                            border: '1px solid #337ab7',
                        }}
                    >
                        กำลังดำเนินงาน...
                    </Label>
                );
            case 6:
                switch (agentStatus) {
                    case 1:
                        return <Label style={styleddefault}>รอส่งสถานะ</Label>;
                    case 2:
                        return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
                    case 3:
                        return <Label style={styleddefault}>รอโอน COD</Label>;
                    case 4:
                        return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
                    case 5:
                        return <Label style={styleddefault}>รอโอน COD</Label>;
                    case 6:
                        return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
                    case 7:
                        return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
                    case 8:
                        return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
                    default:
                        return ''
                }
            case 7:
                return (
                    <Label
                        bsStyle="success"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#5cb85c',
                            border: '1px solid #5cb85c',
                        }}
                    >
                        ส่ง Report แล้ว
                    </Label>
                );
            case 8:
                return <Label bsStyle="success">โอนเงิน Lazada แล้ว</Label>;
            case 9:
                return <Label bsStyle="danger">ยกเลิก</Label>;
            default:
                return <Label>{status}</Label>;
        }
    };

    renderAgentShipmentStatus(status) {
        /*
           1=create shipment Waiting to start work,2=shipment In progress,3=pending,4=await admin check,
           5=pending cod,6=await cod check,7=completed,8=cod not pass,9=admin reject  proof
        */
        switch (status) {
            case 1:
                return <Label bsStyle="primary">รอเริ่มงาน</Label>;
            case 2:
                return (
                    <Label
                        bsStyle="info"
                        style={{
                            backgroundColor: 'transparent',
                            color: '#337ab7',
                            border: '1px solid #337ab7',
                        }}
                    >
                        กำลังดำเนินงาน...
                    </Label>
                );
            case 3:
                return <Label style={styleddefault}>รอส่งสถานะ</Label>;
            case 4:
                return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
            case 5:
                return <Label style={styleddefault}>รอโอน COD</Label>;
            case 6:
                return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
            case 7:
                return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
            case 8:
                return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
            case 9:
                return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
            default:
                return <Label>{status}</Label>;
        }
    }

    renderStatusMode = (status) => {
        switch (status) {
            case 1:
                return <Tag
                    style={{
                        backgroundColor: '#E5FFE5',
                        color: '#5DBA5B',
                        width: 'max-content'
                    }}
                >
                Last Mile (NM)
                    </Tag>
            case 2:
                return <Tag
                    style={{
                        backgroundColor: '#ffb7b0',
                        color: '#ff1100',
                        width: 'max-content'
                    }}
                >
                Return to Merchant (RM)
                    </Tag>
            case 3:
                return <Tag
                    style={{
                        backgroundColor: '#d9e2ff',
                        color: '#318DE8',
                        width: 'max-content'
                    }}>
                    FIRST MILE (FM)
                    </Tag>
            case 4:
                return <Tag
                    style={{
                        backgroundColor: '#FFDB46',
                        color: 'black',
                        width: 'max-content'
                    }}
                >
                SPECIAL (SP)
                    </Tag>
            case 5:
                return <Tag
                    style={{
                        backgroundColor: '#ffefd9',
                        color: '#ff9500',
                        width: 'max-content'
                    }}
                >
                Customer Return (CR)
                    </Tag>
            case 6:
                return <Tag
                    style={{
                        backgroundColor: '#82c5b9',
                        color: '#fff',
                        width: 'max-content'
                    }}
                >
                Return to WH (RTWH)
                    </Tag>
            case 7:
                return <Tag
                    style={{
                        backgroundColor: '#d9e2ff',
                        color: '#82c5f9',
                        width: 'max-content'
                    }}
                >
                Return to Customer (CR/RC)
                    </Tag>
            case 8:
                return <Tag
                    style={{
                        backgroundColor: '#ffb7b0',
                        color: '#FF0505',
                        width: 'max-content'
                    }}
                >
                Return to Merchant (CR/RM)
                    </Tag>
            case 9:
                return <Tag
                    style={{
                        backgroundColor: '#d6949e',
                        color: '#fff',
                        width: 'max-content'
                    }}
                >
                Line Haul (LH)
                    </Tag>
            default:
                return null
        }
    }

    closeCreateShipmentModal = () => {
        this.setState({
            isOpenModalEdit3PL: false,
            pickupDateForCreateShipment: '',
            bookingNumberForCreateShipment: ''
        })
    }

    formatMoney = number => {
        return `฿ ${
            number.toLocaleString('en-US', {
                maximumFractionDigits: 2,
            })
            } `;
    };

    renderTotalaverage(totalassign, truck, item) {
        // const total = truck.reduce((prev, cur) => prev + cur.total_average, 0)

        // return <Bold>{totalassign}/{total}</Bold>

        return (
            <div style={{ padding: '0 5px' }} >
                <span style={{ padding: '0 20px' }} >{item.trip}</span> <span style={{ color: '#318DE8' }}>|</span>
                <span style={{ padding: '0 20px', color: '#5DBA5B' }} >{item.accept_trip}</span> <span style={{ color: '#318DE8' }}>|</span>
                <span style={{ padding: '0 20px', color: 'red' }} >{item.no_accept_trip}</span> <span style={{ color: '#318DE8' }}>|</span>
                <span style={{ padding: '0 20px', color: 'rgb(51, 122, 183)' }} >{item.checkin}</span> <span style={{ color: '#318DE8' }}>|</span>
                <span style={{ padding: '0 20px', color: 'rgb(255, 152, 0)' }} >{item.wait_assign_work}</span>
            </div>
        )
    }

    renderTotalaverageCheck(totalassign, truck) {
        const total = truck.reduce((prev, cur) => prev + cur.total_average, 0)

        return totalassign !== total
    }

    getIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).id;
        return id;
    };
    render() {
        return (
            <div className="content">
                <Query
                    query={shipmentAdminListQuery}
                    variables={
                        {
                            params: {
                                import_order_id: this.getIdFromUrl(),
                                name_head: this.state.admin,
                                agent_shipment_status: this.state.shipmentStatus,
                                name_agent: this.state.shipmentName,
                                name: this.state.tripNo
                            },
                            page: this.state.pageNumber,
                            showData: this.state.showData
                        }
                    }
                    fetchPolicy="cache-and-network"
                >
                    {({ data, loading, error, refetch }) => {

                        let shipmentList = [];
                        let totalPage = 0;

                        if (!loading && !error) {
                            shipmentList = [...data.shipmentAdminSubThirdpartys.sub_thirdparty];
                            totalPage = data.shipmentAdminSubThirdpartys.totalPage;

                        }

                        var itemLength = 'loading';
                        let shipmentModify = false;
                        const itemIndicatorSize = (this.state.pageNumber - 1) * 30;
                        if (data.shipmentAdminSubThirdpartys) {
                            if (shipmentList.length > 0) {
                                itemLength = data.shipmentAdminSubThirdpartys.sub_thirdparty.length;
                                shipmentModify = data.shipmentAdminSubThirdpartys.sub_thirdparty.filter(data => this.renderTotalaverageCheck(data.total_assign_driver, data.trip_truck)).length > 0
                            }
                        }
                        return (
                            <>
                                <SubHeader style={{ marginTop: '-15px' }} backUrl={`/ka`}>
                                    <div className={classes.header}>
                                        <div className={classes.title}>
                                            <div style={{ width: 'max-content' }}>
                                                {
                                                    this.getIdFromUrl() ? (shipmentList.length > 0 ? <strong>{shipmentList[0].name}</strong> : <strong>รายการงานขนส่งตาม Agent</strong>)
                                                    :
                                                    (<strong>รายการงานขนส่งตาม Agent</strong>)
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.getIdFromUrl() && (shipmentList.length > 0 ? <div>{this.renderStatusMode(shipmentList[0].order_type)}</div> : null)
                                        }
                                        <Line />
                                        <MenuWrapper>
                                            <ul>
                                                <li className="active">
                                                    {this.getIdFromUrl() ?
                                                        (<a href={`/ka-shipment-agent?id=${this.getIdFromUrl()}`}>ดูตาม Agent</a>)
                                                        :
                                                        (<a href={`/ka-shipment-agent`}>ดูตาม Agent</a>)
                                                    }
                                                </li>
                                                <li > {this.getIdFromUrl() ?
                                                    (<a href={`/ka-shipment-list?id=${this.getIdFromUrl()}`}>ดูตามงานขนส่ง</a>)
                                                    :
                                                    (<a href={`/ka-shipment-list`}>ดูตามงานขนส่ง</a>)
                                                }

                                                </li>
                                            </ul>
                                        </MenuWrapper>
                                        <div className={classes.btnContainer}>
                                            {this.getIdFromUrl() && (
                                                <Button
                                                    onClick={() => {
                                                        if (shipmentModify) {
                                                            this.setState({
                                                                isOpenModalEdit3PL: true,
                                                                idBookingFile: this.getIdFromUrl(),
                                                                bookingNumberForCreateShipment: shipmentList.length > 0 ? shipmentList[0].name : '',
                                                            });
                                                        } else {
                                                            this.setState({
                                                                isOpenModalNotEdit3PL: true,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    แก้ไขจำนวนรถ ของ 3PL
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </SubHeader>
                                <Grid fluid>
                                    <div>
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    <CardTable
                                                        ctTableFullWidth
                                                        ctTableResponsive
                                                        content={
                                                            <div>
                                                                <PageWrapper style={{ textAlign: 'right' }}>
                                                                    <div className="text">
                                                                        {this.state.pageNumber === 1
                                                                            ? `1 - ${itemLength} `
                                                                            : `${itemIndicatorSize + 1} - ${
                                                                            itemLength +
                                                                            itemIndicatorSize
                                                                            } `}{' '}
                                                                        หน้า :
                                                                    </div>
                                                                    <FormControl
                                                                        type="number"
                                                                        min={1}
                                                                        value={this.state.pageNumber}
                                                                        onChange={event =>
                                                                            Number(event.target.value) >= 0
                                                                                ? setInterval(
                                                                                    this.setState({
                                                                                        pageNumber: Number(event.target.value),
                                                                                    }),
                                                                                    1000
                                                                                )
                                                                                : setInterval(this.setState({ pageNumber: 1 }), 1000)
                                                                        }
                                                                        name="page_number"
                                                                    />
                                                                    <div className="text">
                                                                        {' '}
                                                                        {loading || error ? '' : `จาก ${data.shipmentAdminSubThirdpartys.totalPage} `}
                                                                    </div>
                                                                </PageWrapper>
                                                                <div className="table-vertical">
                                                                    <Table striped hover responsive>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ minWidth: '80px' }}>เครื่องมือ</th>
                                                                                {
                                                                                   !this.getIdFromUrl() && <th style={{ minWidth: '140px' }}>Booking Number</th>
                                                                                }
                                                                                <th style={{ minWidth: '140px' }}>นายหน้า</th>
                                                                                <th style={{ minWidth: '160px' }}>สถานะ</th>
                                                                                <th style={{ minWidth: '300px' }}>
                                                                                    <span>ทั้งหมด</span> | <span style={{ color: '#5DBA5B' }}  >รับงาน</span> | <span style={{ color: 'red' }} > ไม่รับงาน</span> | <span style={{ color: 'rgb(51, 122, 183)' }} >check in</span> | <span style={{ color: 'rgb(255, 152, 0)' }} >รอจ่ายงาน </span>
                                                                                </th>
                                                                                <th style={{ minWidth: '100px' }}>COD รับแล้ว</th>
                                                                                <th style={{ minWidth: '130px' }}>COD ยังไม่ได้รับ</th>
                                                                                <th style={{ minWidth: '100px' }}>COD ยกเลิก</th>
                                                                                <th style={{ minWidth: '100px' }}>COD ทั้งหมด</th>
                                                                                <th style={{ minWidth: '140px' }}>ผู้ดูแลงาน</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <th></th>
                                                                                {!this.getIdFromUrl() && 
                                                                                <th>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหา Booking No."
                                                                                        value={this.state.tripNo}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                                tripNo: event.target.value,
                                                                                                pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </th>}
                                                                                <th>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหา นายหน้า"
                                                                                        value={this.state.shipmentName}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                                shipmentName: event.target.value,
                                                                                                pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </th>
                                                                                <th>
                                                                                    <FormControl
                                                                                        componentClass="select"
                                                                                        placeholder="select"
                                                                                        defaultValue={this.state.shipmentStatus}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                                shipmentStatus: event.target.value,
                                                                                                pageNumber: 1,
                                                                                            })
                                                                                        }
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            // <option value={0}>ทั้งหมด</option>
                                                                                            // <option value={1}>รอเพิ่มคนขับ</option>
                                                                                            // <option value={2}>รอขนส่งรับงาน</option>
                                                                                            // <option value={10}>รอเริ่มงาน</option>
                                                                                            // <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                                                                            // <option value={3}>กำลังดำเนินการขนส่ง</option>
                                                                                            // <option value={4}>เสร็จสิ้นแล้ว</option>
                                                                                            // <option value={5}>ถูกยกเลิก</option>
                                                                                            // <option value={6}>งานเกิดปัญหา</option>
                                                                                            // <option value={7}>แบบร่าง</option>
                                                                                            // <option value={9}>กำลังคืนคลัง</option>

                                                                                            // {/* new status */}
                                                                                            // <option value={11}>รอส่งสถานะ</option>
                                                                                            // <option value={12}>รอตรวจสอบสถานะ</option>
                                                                                            // <option value={13}>สถานะผ่าน</option> {/* สถานะผ่าน ถ้าไม่มี COD จะเป็น เสร็จสมบูรณ์ */}
                                                                                            // <option value={14}>สถานะไม่ผ่าน</option>
                                                                                            // <option value={15}>รอโอน COD</option>
                                                                                            // <option value={16}>รอตรวจสอบ COD</option>
                                                                                            // <option value={17}>เสร็จสมบูรณ์</option>
                                                                                            // <option value={18}>COD ไม่ผ่าน</option>
                                                                                            /*
                                                                                                1=create shipment Waiting to start work,2=shipment In progress,3=pending,4=await admin check,
                                                                                                5=pending cod,6=await cod check,7=completed,8=cod not pass,9=admin reject  proof
                                                                                            */
                                                                                        }
                                                                                        {/* new status */}
                                                                                        <option value={0}>ทั้งหมด</option>
                                                                                        <option value={1}>รอเริ่มงาน</option>
                                                                                        <option value={2}>กำลังดำเนินงาน...</option>

                                                                                        <option value={3}>รอส่งสถานะ</option>
                                                                                        <option value={4}>รอตรวจสอบสถานะ</option>
                                                                                        <option value={5}>รอโอน COD</option>
                                                                                        <option value={6}>รอตรวจสอบ COD</option>
                                                                                        <option value={7}>เสร็จสมบูรณ์</option>
                                                                                        <option value={8}>COD ไม่ผ่าน</option>
                                                                                        <option value={9}>สถานะไม่ผ่าน</option> {/* สถานะผ่าน ถ้าไม่มี COD จะเป็น เสร็จสมบูรณ์ */}
                                                                                    </FormControl>
                                                                                </th>
                                                                                <th></th>
                                                                                <th></th>
                                                                                <th></th>
                                                                                <th></th>
                                                                                <th></th>
                                                                                <th>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหาหมายผู้ดูแลงาน"
                                                                                        value={this.state.admin}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                                admin: event.target.value,
                                                                                                pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {loading && (
                                                                                <tr>
                                                                                    <td colSpan="9">Loading...</td>
                                                                                </tr>
                                                                            )}

                                                                            {error && (
                                                                                <tr>
                                                                                    <td colSpan="9">Error</td>
                                                                                </tr>
                                                                            )}

                                                                            {!loading && !error && shipmentList.length === 0 && (
                                                                                <tr>
                                                                                    <td colSpan="9">ไม่มีข้อมูล</td>
                                                                                </tr>
                                                                            )}

                                                                            {shipmentList.map((item, index) => (
                                                                                <tr>
                                                                                    <td>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}
                                                                                        >
                                                                                            <a
                                                                                                href={`/ka-3pl-detail?id=${item._id}&orderid=${item.import_order_id}&accid=${item.account_info_id}`}
                                                                                                target="_blank"
                                                                                                style={{ float: 'left' }}
                                                                                                className="btn btn-default"
                                                                                            >
                                                                                                <i
                                                                                                    style={{ marginTop: '3px' }}
                                                                                                    className="fas fa-eye"
                                                                                                ></i>
                                                                                            </a>
                                                                                        </OverlayTrigger>
                                                                                    </td>
                                                                                    {
                                                                                        !this.getIdFromUrl() && 
                                                                                        <td>{item.name}</td>
                                                                                    }
                                                                                    <td>{item.name_agent}</td>
                                                                                    <td>
                                                                                        {
                                                                                            // this.renderStatus(item.agent_shipment_status)
                                                                                        }
                                                                                        {
                                                                                            // this.renderStatusNew(6, item.agent_shipment_status)
                                                                                        }
                                                                                        {
                                                                                            this.renderAgentShipmentStatus(item.agent_shipment_status)
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.renderTotalaverage(item.total_assign_driver, item.trip_truck, item)}
                                                                                    </td>
                                                                                    <td>{this.formatMoney(item.accept_cod)}</td>
                                                                                    <td>
                                                                                        {this.formatMoney(item.wait_accept_cod)}
                                                                                    </td>
                                                                                    <td>{this.formatMoney(item.cancel_cod)}</td>
                                                                                    <td>{this.formatMoney(item.total_cod)}</td>
                                                                                    <td>{item.name_head}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                                <div style={{ textAlign: 'right', marginTop: 20 }}>
                                                                    {!loading && !error && (
                                                                        <ReactPaginate
                                                                            pageCount={totalPage}
                                                                            forcePage={this.state.pageNumber - 1}
                                                                            containerClassName="pagination"
                                                                            nextLabel=">"
                                                                            previousLabel="<"
                                                                            onPageChange={({ selected }) => {
                                                                                this.setState({
                                                                                    pageNumber: selected + 1,
                                                                                });
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </Grid>
                            </>
                        );
                    }}
                </Query>
                {
                    this.state.isOpenModalEdit3PL &&
                    <CreateShipmentModal
                        showModal={this.state.isOpenModalEdit3PL}
                        closeModal={this.closeCreateShipmentModal}
                        customerId={customerId.lazada}
                        idBookingFile={this.state.idBookingFile}
                        // pickupDateForCreateShipment={this.state.pickupDateForCreateShipment} 
                        bookingNumberForCreateShipment={this.state.bookingNumberForCreateShipment}
                    // modeForCreateShipment={this.state.modeForCreateShipment}
                    // refetchLazadaImportOrderList={() => refetchLazadaImportOrderList()}
                    />
                }
                {
                    this.state.isOpenModalNotEdit3PL &&
                    <ModalConfirm
                        show={this.state.isOpenModalNotEdit3PL}
                        onPressButtonConfirm={() => this.setState({ isOpenModalNotEdit3PL: false })}
                        styleColorButtonConfirm="primary"
                        labelButtonConfirm={this.state.isLoading ? 'ยืนยัน...' : 'ยืนยัน'}
                        titleHeader={`ไม่สามารถแก้ไขจำนานรถของ 3pl ได้แล้ว`}
                        onHide={() => this.setState({ isOpenModalNotEdit3PL: false })}
                    >

                    </ModalConfirm>
                }
            </div>
        );
    }
}

export default compose(
    graphql(driverUserListQuery, { name: 'driverUserListQuery' }),
    graphql(userTruckListQuery, { name: 'userTruckListQuery' }),
    graphql(accountInfoListQuery, {
        name: 'accountInfoListQuery',
        options: () => ({
            fetchPolicy: 'network-only',
            variables: {
                account_info_type: 3,
            },
        }),
    })
)(ShipmentAgent);
