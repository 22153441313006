import gql from 'graphql-tag';

export default gql`
    query lazadaImportOrderList(
        $_id: ID
        $account_info_id: ID
    ){
        lazadaImportOrderList(
        _id: $_id
        account_info_id: $account_info_id
        )
        {
            order{
                _id
                name
                order_type
                pick_up_date
            }
        }
    }
  
`;
