import gql from 'graphql-tag';

export default gql`
query search(
  $create_date :String
  $page_number : Int
  $order_by : String
	$order_type : String
  $account_info_email : String
  $log_status : Int
  $shipment_status : Int
  $call_from : Int
  $promotion_name : String
){
  getPriceLogList(
    	show_data : 30,
      page_number: $page_number,
      order_by: $order_by,
      order_type: $order_type,
      create_date: $create_date,
    	account_info_email : $account_info_email
      log_status : $log_status
      shipment_status: $shipment_status
      call_from: $call_from
      promotion_name:$promotion_name
  	) {
    log {
      origin_province
      destination_province
      distance
      lat_origin
      lng_origin
      lat_destination
      lng_destination
      account_info_email
      call_from
      promotion_name
      setting_truck_name{
        th
      }
      truck_type_name{
        th
      }
      shipment_id
      shipment_number
      shipment_status
      create_date
      total_price
      business_total_price
      discount {
        detail
        price
        discount_type
        percent
      }
    }
    total_page
  }
}
`;


