import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { AutosuggestContainer } from './styled';
import accountInfoListQuery from './query/accountInfoList';
import { client } from 'index.js';

export class AutoSuggestTransporter extends Component {
  state = {
    listSuggestions: [],
    nameSearch: '',
    idSearch: '',
  };

  //Function ที่ต้องแก้ตอนนำไปใช้
  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      //เปลีี่ยน Query
      const { data } = await client.query({
        query: accountInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
          page_number: this.state.page_number_agent,
          show_data: 10,
          page_number: 1,
        },
      });

      //เปลีี่ยน data ที่รับ
      const listData = data.accountInfoList.account_info
        .filter(dt => dt.account_info_business_name)
        .map(e => {
          return {
            id: e._id,
            name: e.account_info_business_name,
          };
        });

      const NewlistData = [{ id: '', name: 'ทั้งหมด' }, ...listData];

      this.setState({
        listSuggestions: NewlistData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      listSuggestions: [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => (
    <div key={suggestion.id}>{suggestion.name ? suggestion.name : 'No Data'}</div>
  );

  shouldRenderSuggestions() {
    return true;
  }

  render() {
    return (
      <AutosuggestContainer>
        <Autosuggest
          suggestions={this.state.listSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          focusInputOnSuggestionClick={false}
          inputProps={{
            placeholder: 'ค้นหาชื่อ 3PL',
            value: this.state.nameSearch,
            onChange: event =>
              this.setState({
                nameSearch: event.target.value,
                idSearch: '',
              }),
            type: 'text',
            className: 'form-control',
          }}
          onSuggestionSelected={(event, { suggestion }) => {
            this.setState({
              idSearch: suggestion.id,
              nameSearch: suggestion.name == 'ทั้งหมด' ? '' : suggestion.name,
            });
            this.props.setIdSearch(suggestion.id, suggestion.name);
          }}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
        />
      </AutosuggestContainer>
    );
  }
}

export default AutoSuggestTransporter;
