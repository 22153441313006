import gql from 'graphql-tag';

export default gql`
  query CustomerImportProduct($customer_service_type: String) {
    accountInfoList(customer_service_type: $customer_service_type) {
      account_info {
        _id
        account_info_business_name
      }
    }
  }
`;
