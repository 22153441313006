import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../graphql/query/shipmentList';

export default gql`
  query JDShipmentList(
    $_id: ID
    $lazada_import_order_id: ID
    $shipment_name: String
    $tag_name: String
    $pick_up_date: String
    $shipment_status: Int
    $shipment_running_number: String
    $admin: String
    $create_date: String
    $shipment_number: String
    $driver_user_id: ID
    $order_by: String
    $order_type: String
    $page_number: Int
    $commodity_name: String
    $commodity_order_number: String
		$setting_truck_type_id: ID,
  ) {
    shipmentList(
      commodity_name: $commodity_name
      commodity_order_number: $commodity_order_number
      shipment_prefix: "GSP"
      show_data: 30
      page_number: $page_number
      order_by: $order_by
      order_type: $order_type
      _id: $_id
      lazada_import_order_id: $lazada_import_order_id
      shipment_name: $shipment_name
      tag_name: $tag_name
      pick_up_date: $pick_up_date
      shipment_status: $shipment_status
      shipment_running_number: $shipment_running_number
      admin: $admin
      create_date: $create_date
      shipment_number: $shipment_number
      driver_user_id: $driver_user_id
			setting_truck_type_id: $setting_truck_type_id
    ) {
      shipment {
        ...shipmentFields
      }

      total_page
    }
  }

  ${shipmentListFragment}
`;
