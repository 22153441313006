import gql from 'graphql-tag';

export default gql`
    mutation subThirdPartySettingUpdate(
        $_id: ID!
        $agent_shipment_status: Int
        $admin_reject_detail: String
    ){
        subThirdPartySettingUpdate(
            _id: $_id
            agent_shipment_status: $agent_shipment_status
            admin_reject_detail: $admin_reject_detail
        ){
            succeed
        }
    }
`;