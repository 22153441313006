import React, { Component } from "react";

export class CardLogin extends Component {
  render() {
    return (
      <div className={"cardLogin" + (this.props.plain ? " card-plain" : "")}>
        <div className="inner">   
            <div className={"header" + (this.props.hCenter ? " text-center" : "")}>
                {(this.props.logo ? (<img src={this.props.logo} style={{height: "65px"}} />) : "")}
                {(this.props.title ? ( <h6 className="title">{this.props.title}</h6>) : "")}
            </div>

            {this.props.content}
        </div>
      </div>
    );
  }
}

export default CardLogin;
