import React from 'react';
import { Map, Marker, Popup, TileLayer, Polyline, LayersControl } from 'react-leaflet';
import styled from 'styled-components';
import hereMapKey from 'config/hereMapKey';
import 'react-leaflet-fullscreen-control';
import MarkerCustom from './components/MarkerCustom';
import MarkerPopup from './components/MarkerPopup';
import Leaflet from 'leaflet';
import color from 'config/color';

const { BaseLayer } = LayersControl;
const defaultPosition = [13.664273, 100.653512];
const hereStyle = 'normal.day';
const hereTileUrl = `https://2.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/${hereStyle}/{z}/{x}/{y}/256/png8?apiKey=${hereMapKey}&lg=tha&ppi=72&pview=DEF`;
const Container = styled.div`
  .leaflet-container {
    height: 100%;
    width: 100%;
    z-index: 0;
  }
`;

class LeafletMap extends React.Component {
  renderMarkerShipment = markerShipment => {
    if (markerShipment && markerShipment.length > 0) {
      return markerShipment.map(item => {
        return <MarkerCustom position={[item.lat, item.lng]} text={item.text} type={item.mode} />;
      });
    }

    return null;
  };

  renderLayers = () => {
    const assignLayers = [
      {
        name: 'Here Map',
        url: hereTileUrl,
        attribution: '&copy; HERE MAP',
        checked: true,
      },
      {
        name: 'OpenStreet Map',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        checked: false,
      },
    ];

    return (
      <LayersControl position="topright">
        {assignLayers.map((item, index) => (
          <BaseLayer key={index} checked={item.checked} name={item.name}>
            <TileLayer url={item.url} attribution={item.attribution} />
          </BaseLayer>
        ))}
      </LayersControl>
    );
  };

  setBound = boundsLatLng => {
    if (boundsLatLng && boundsLatLng.length > 0) {
      return Leaflet.latLngBounds(boundsLatLng);
    }

    return null;
  };

  renderPolyline = polyline => {
    if (polyline && polyline.length > 0) {
      return polyline.map((item, index) => (
        <Polyline key={index} positions={item} color={color.Polyline} weight={'6'} />
      ));
    }

    return null;
  };

  renderPopup = markerPopup => {
    if (markerPopup && markerPopup.length > 0) {
      return markerPopup.map(item => (
        <MarkerPopup
          position={[item.lat, item.lng]}
          autoClose={false}
          closeButton={false}
          title={item.title}
          subTitle={item.subTitle}
          autoPan={false}
          maxWidth={100}
        />
      ));
    }

    return null;
  };

  renderMarkerDriver = markerDriver => {
    if (markerDriver) {
      const { lat, lng, popup } = markerDriver;

      return (
        <MarkerCustom position={[lat, lng]} type="driver" isOpenPopup={true}>
          {popup && (
            <Popup className="marker-custom" autoPan={false}>
              <div className="marker-custom__title">{popup.title}</div>
              <div className="marker-custom__sub-title">{popup.subTitle}</div>
              <div className="marker-custom__sub-title">{popup.speed}</div>
              <div className="marker-custom__sub-title">{popup.activity}</div>
              <div className="marker-custom__sub-title">{popup.updateAt}</div>
            </Popup>
          )}
        </MarkerCustom>
      );
    }

    return null;
  };

  render() {
    return (
      <Container>
        <Map
          center={defaultPosition}
          zoom={15}
          maxZoom={20}
          fullscreenControl
          bounds={this.setBound(this.props.boundsLatLng)}
          closePopupOnClick={false}
        >
          {this.renderLayers()}
          {this.renderMarkerShipment(this.props.markerShipment)}
          {this.renderPolyline(this.props.polyline)}
          {this.renderPopup(this.props.markerPopup)}
          {this.renderMarkerDriver(this.props.markerDriver)}
        </Map>
      </Container>
    );
  }
}

export default LeafletMap;
