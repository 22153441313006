import gql from 'graphql-tag';

export default gql`
  query search($_id: ID, $shipment_prefix: String) {
    shipmentList(_id: $_id, see_detail: true, shipment_prefix: $shipment_prefix) {
      shipment {
        _id
        flash_deals_id
        create_date
        matching_time
        shipment_trip
        shipment_name
        shipment_type
        driver_name_under_mama
        driver_tel_under_mama
        round_trip
        shipment_status
        shipment_number
        shipper
        shipper_detail
        account_user_name
        account_user_display_name
        transporter
        transporter_detail
        transporter_name
        transporter_phone_code
        transporter_phone_number
        user_setting_truck_id
        setting_truck_id
        setting_truck_name {
          th
        }
        setting_truck_type_id
        truck_type_name {
          th
        }
        driver_user_id
        shipment_tracking_number
        distance
        paid_type
        paid_status
        cancel_comment
        assign_driver_date
        last_update
        completed_date
        cancel_date
        setting_truck_name {
          th
        }
        truck_type_name {
          th
        }
        truck_type_qty
        pick_up_date
        shipment_address {
          _id
          mode
          address
          subdictrict
          province
          lat
          lng
          contact_name
          contact_tel
          remark
          use_contact_point
          paid_by_cod
          pick_up_date
          delivery_date
        }
        shipment_other_detail {
          remark
          user_ref
          document {
            path
          }
        }
        shipment_additional {
          additional_id
          additional
          qty
          price
          address
        }
        shipment_confirm_document
        shipment_confirm_detail {
          receipt_doc {
            path
          }
        }
        shipment_refund {
          amount
          status
          detail {
            th
            en
          }
        }
        shipment_fines {
          amount
          status
          detail {
            th
            en
          }
          detail_complete {
            th
            en
          }
        }
        multi_point {
          distance_point
          distance_point_detail
          total_point
          price_per_point
        }
        shipment_accessory {
          _id
          accessory_id
          accessory
          name_lang {
            th
            en
          }
        }
        detail_from_tms {
          inv_number
          rec_number
        }
        billing_costing
      }
      total_page
    }
  }
`;
