import React from 'react';
import cn from './TrackingList.module.scss';
import { Label } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TrackingList = props => {
  let cnTrackingList = [cn.TrackingList];
  let cnDetailTitle = [cn.DetailTitle];
  let cnDetailSubTitle = [cn.DetailSubTitle];

  if (props.arrow) {
    cnTrackingList.push(cn.TrackingListArrow);
  }

  if (props.active) {
    cnDetailTitle.push(cn.Active);
    cnDetailSubTitle.push(cn.Active);
  }

  return (
    <div
      className={`${cnTrackingList.join(' ')}`}
      onClick={() => (props.onPress ? props.onPress() : null)}
    >
      <div className={cn.Icon}>
        <i className="fas fa-circle" style={{color: props.colorIcon}}></i>
      </div>
      <div className={cn.Detail}>
        <div className={cn.DetailHeader}>
          <div className={cn.Left}>
            <div className={cnDetailTitle.join(' ')}>{props.title}</div>
            <div className={cnDetailSubTitle.join(' ')}>{props.subTitle}</div>
          </div>
          <div className={cn.Right}>
            <i className="far fa-angle-right"></i>
          </div>
        </div>
        {props.children && <div className={cn.SubDetail}>{props.children}</div>}
      </div>
    </div>
  );
};

TrackingList.defaultProps = {
  colorIcon: '#5cb85c',
};

export default TrackingList;
