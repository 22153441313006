import React from 'react';
import { Modal as ModalBootstrap, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import agentGenQrcodeShipmentParcelMutation from './graphql/mutation/agentGenQrcodeShipmentParcel';

const Modal = styled(ModalBootstrap)`
  & .custom-modal {
    width: 700px;
    top: 10%;
  }

  & h4.modal-title {
    font-size: 20px;
    font-weight: bold;
  }
`;

const ModalConfirmCreateQr = ({
  isOpen = false,
  onCancel,
  onCompleted,
  codList = [],
}) => {
  return (
    <Mutation
      mutation={agentGenQrcodeShipmentParcelMutation}
      update={(_cache, { data }) => {
        if (data?.agentGenQrcodeShipmentParcel?.qr_id) {
          const {
            qr_id,
            qr_ref1,
            qr_ref2,
            qr_image,
            qr_gen_date,
            billing_cod,
            qr_paid_amount,
          } = data.agentGenQrcodeShipmentParcel;

          onCompleted &&
            onCompleted({
              id: qr_id,
              ref1: qr_ref1,
              ref2: qr_ref2,
              image: qr_image,
              date: qr_gen_date,
              paidAmount: qr_paid_amount,
              billingCod: billing_cod,
            });
        }
      }}
      onError={(error) => {
        alert(error.graphQLErrors[0].message);
      }}
      refetchQueries={() => [
        'newCod',
        'shipmentKA',
        'shipmentKAs',
        'shipmentKAStatus',
      ]}
    >
      {(agentGenQrcodeShipmentParcel, { loading }) => (
        <Modal
          show={isOpen}
          onHide={() => onCancel()}
          dialogClassName="custom-modal"
          backdropStyle={{
            zIndex: 1050,
          }}
        >
          <Modal.Header>
            <Modal.Title>
              <div>ยืนยันการสร้าง QR-Code สำหรับชำระค่า COD</div>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <span style={{ color: 'red' }}>
              หลังจากสร้าง QR-Code จะไม่สามารถแก้ไขหรือปิดได้
              กรุณาตรวจสอบก่อนสร้าง QR-Code
            </span>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button
              style={{
                color: '#ffffff',
                backgroundColor: '#50B55C',
              }}
              disabled={loading}
              onClick={() => {
                agentGenQrcodeShipmentParcel({
                  variables: {
                    shipment: codList.map((item) => item.id),
                    delivery: codList.map((item) => item.deliveryId),
                  },
                });
              }}
            >
              ยืนยันการสร้าง
            </Button> */}
            <Button onClick={() => onCancel && onCancel()}>
              <i className="fas fa-times"></i> ยกเลิก
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Mutation>
  );
};

export default ModalConfirmCreateQr;
