import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;
const TextareaBox = styled.textarea`
  font-size:18px;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  padding: 12px;
  line-height: 1;
  height: 80px;
  background-color: #FFFFFF;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    ${props => props.disabled && `
      background-color: #F9F9F9;
    `}
  }

  ${props => props.isBorderValidated && `
    border: 1px solid #FF0000;
  `}

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B3B3B3;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B3B3B3;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #B3B3B3;
  }
`;

const InputBox = styled.input`
  font-size:18px;
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  padding: 0 12px;
  line-height: 1;
  height: 40px;
  background-color: #FFFFFF;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    ${props => props.disabled && `
      background-color: #F9F9F9;
    `}
  }

  ${props => props.isBorderValidated && `
    border: 1px solid #FF0000;
  `}

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B3B3B3;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B3B3B3;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #B3B3B3;
  }
`;

const MessageError = styled.div`
  font-size:18px;
  color: #FF0000;
  height: 22px;
  text-align: right;
`;

const Label = styled.div`
  font-size:18px;
  font-weight: bold;

  ${props => props.messageError && `
    color: #FF0000;
  `}
`;

const Required = styled.span`
  color: #D90101;
`;

const ViewPass = styled.div`
  position: absolute;
  right: 30px;
  top: 44px;
  cursor: pointer;
  color: #999999;
`;

class Input extends React.Component {
  render() {
    return (
      <Container style={this.props.style}>
        { this.props.inputLabel ? (<Label messageError={this.props.messageError}>{this.props.inputLabel}{ this.props.required && (<Required>*</Required>) }</Label>) : null }
        {
          this.props.type != "textarea" ? (
            <InputBox ref={this.props.inputRef} onBlur={this.props.onBlur} onKeyPress={this.props.onKeyPress} name={this.props.name} style={this.props.styleInput} onChange={this.props.onChange} value={this.props.value} isBorderValidated={this.props.messageError} placeholder={this.props.placeholder} type={this.props.type} disabled={this.props.disabled} />
          ) : (
            <TextareaBox ref={this.props.inputRef} onBlur={this.props.onBlur} onKeyPress={this.props.onKeyPress} name={this.props.name} style={this.props.styleInput} onChange={this.props.onChange} value={this.props.value} isBorderValidated={this.props.messageError} placeholder={this.props.placeholder} disabled={this.props.disabled} />
          )
        }
        {
          this.props.viewPass && (
            <ViewPass onClick={this.props.onClickViewPass}>
              {
                this.props.type == "password" ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )
              }
              
            </ViewPass>
            
          )
        }
        
        <MessageError>{this.props.messageError}</MessageError>
      </Container>
    )
  }
}

export default Input;