import React from 'react'
import styled from 'styled-components';
import classes from './SubHeaderCOD.module.css';
import Moment from 'react-moment';
import SubHeader from './components/SubHeader'
import urijs from 'urijs';

const MenuWrapper = styled.div`
  width: 100%;

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;
  }

  & ul {
    padding-left: 0;
    margin: 0 0 5px 0;
    display: flex;

    & li {
      list-style: none;
      margin-right: 15px;
      padding: 22px 10px 15px;

      & a {
        font-size: 18px;
        cursor: pointer;
        & i {
          font-size: 18px;
          color: #000000;
        }
      }
    }
    & li.active {
      border-bottom: 2px solid #d90402;
    }
  }
`;

const Tag = styled.div`
  background-color: #E5FFE5;
  color: #5DBA5B;
  font-weight: bold;
  padding: 2px 5px;
  margin-left: 30px;
  border-radius: 4px;
`;

const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #E3E3E3;
`;
const activeRoute = (prop, url) => {
  return prop.pathname.indexOf(url) > -1 ? "active" : "";
}
const getorderIdFromUrl = () => {
  const id = urijs(window.location.href).query(true).orderid;
  return id;
};
const getIdFromUrl = () => {
  const id = urijs(window.location.href).query(true).id;
  return id;
};
const getaccountIdFromUrl = () => {
  const id = urijs(window.location.href).query(true).accid;
  return id;
};
const renderStatus = (status) => {
  switch (status) {
    case 1:
      return <i class="fal fa-check-circle" style={{ color: '#E3E3E3' }}></i>;
    case 2:
      return <i class="fal fa-check-circle" style={{ color: '#FF9300' }}></i>;
    case 3:
      return <i class="fal fa-check-circle" style={{ color: '#5DBA5B' }}></i>;
    case 4:
      return <i class="fal fa-times-circle" style={{ color: '#FF0000' }}></i>;
    default:
      return '';
  }
};
const SubHeaderCOD = props => (
  <SubHeader backUrl={props.backUrl ? props.backUrl : `/`} style={{ margin: '0', borderBottom: '1px solid #efefef' }}>
    <div className={classes.header}>
      <div className={classes.title}>
        <div style={{ width: 'max-content' }}>
          <strong style={{ fontSize: '20px' }} >{props.headerName ? props.headerName : ''}</strong>
        </div>
      </div>
      <MenuWrapper>
        <ul>
          <li className={activeRoute(props, '/lazada-shipment-detail')}>
            <a>{' '}<i className="fal fa-truck"></i> ข้อมูลงานขนส่ง</a>
          </li>
          <li className={activeRoute(props, `/lazada-shipment-costing`)} style={{ display: 'none' }}>
            <a href={`/lazada-shipment-costing?shipment=${props.idUrl}&orderId=${props.lazadaImportOrderId}`}>
              {' '}<i className="fal fa-sack-dollar"></i> ต้นทุน - ราคาขาย
            </a>
          </li>
        </ul>
      </MenuWrapper>
    </div>
  </SubHeader>
)

export default SubHeaderCOD