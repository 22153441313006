import gql from 'graphql-tag';

export default gql`
  mutation AssignDriver(
    $shipment_id: ID!
    $driver_user_id: ID!
    $user_setting_truck_id: ID!
    $transporter: ID
    $lazada_import_order_id: ID
    $book_number: String
    $driver_name: String
    $driver_phone: String
    $driver_phone_code: String
    $name_agent: String
    $truck_license: String
    $truck_type_id: ID
  ) {
    assignDriver(
      shipment_id: $shipment_id
      driver_user_id: $driver_user_id
      user_setting_truck_id: $user_setting_truck_id
      transporter: $transporter
      lazada_import_order_id: $lazada_import_order_id
      book_number: $book_number
      driver_name: $driver_name
      driver_phone: $driver_phone
      driver_phone_code: $driver_phone_code
      name_agent: $name_agent
      truck_license: $truck_license
      truck_type_id: $truck_type_id
    ) {
      succeed
    }
  }
`;
