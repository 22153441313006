import React from 'react';
import classes from './ShipmentTracking.module.scss';
import cn from '../../ShipmentDetail.module.scss';
import Moment from 'react-moment';
import { Row, Col, Button } from 'react-bootstrap';
import { useFormik, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { TrackingList } from '../../../../components';
import { Label } from 'react-bootstrap';
import shipmentListQuery from './graphql/query/shipmentList';
import { compose, Query, graphql, Mutation } from 'react-apollo';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';
import { ModalConfirm } from 'views/JD/components';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';

class ShipmentTracking extends React.Component {
  static contextType = ShipmentDetailContext;
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      isOpenModalJDFailStatus: false,
      commodity: null,
      deliveryObj: null,
      selectedObj: [],
    };
  }

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      default:
        return '';
    }
  }

  renderJDSendFinalStatus(status) {
    switch (status) {
      case 1:
        return (
          <Label bsStyle="warning" style={{ fontSize: 10, padding: '3px 5px' }}>
            <i className="fas fa-spinner"></i>
          </Label>
        );
      case 2:
        return (
          <Label bsStyle="success" style={{ fontSize: 10, padding: '3px 5px' }}>
            <i className="fas fa-check"></i>
          </Label>
        );
      default:
        return '';
    }
  }

  getTitleDelivery = item => {
    console.log(item);
    const { dictrict, delivery_status, province, jd_send_fail_status } = item;
    if (delivery_status === 3) {
      return (
        <div>
          ส่งสินค้า - {dictrict || province} เรียบร้อยแล้ว{' '}
          {this.renderJDSendFinalStatus(jd_send_fail_status)}
        </div>
      );
    }

    return <div>ส่งสินค้า - {dictrict || province}</div>;
  };

  getSubTitleDelivery = item => {
    const { delivery_status, signature_date, failure_detail, paid_by_cod } = item;
    let status = '';
    if (delivery_status === 2) {
      status = 'รอดำเนินการ';
    } else if (delivery_status === 3) {
      status = <Moment format="DD/MM/YYYY - HH:mm">{signature_date}</Moment>;
    } else if (delivery_status === 4) {
      const failureDetail = failure_detail ? `(${failure_detail})` : '';
      status = `รอคืนสินค้า ${failureDetail}`;
    }

    if (item.commodity.length > 0) {
      return (
        <div>
          {item.commodity[0].name} : <span className={`${cn.mr5}`}>{status}</span>
          {paid_by_cod === 1 && <span className={`${cn.textRed}`}>[COD]</span>}
        </div>
      );
    }
    return null;
  };

  getColorStatusDelivery = status => {
    if (status === 2) {
      return '#49ACF1';
    } else if (status === 3) {
      return '#5cb85c';
    } else if (status === 4) {
      return '#E70301';
    }

    return null;
  };

  getTitleReturn = item => {
    const { signature_date_return, tag_name } = item;
    if (signature_date_return) {
      return `คืนสินค้า - ${tag_name} เรียบร้อยแล้ว`;
    }

    return `คืนสินค้า - ${tag_name}`;
  };

  getSubTitleReturn = item => {
    const { signature_date_return } = item;
    let status = '';
    if (!signature_date_return) {
      status = 'รอดำเนินการ';
    } else {
      status = <Moment format="DD/MM/YYYY - HH:mm">{signature_date_return}</Moment>;
    }

    return <div>{status}</div>;
  };

  getColorStatusReturn = signature_date_return => {
    if (!signature_date_return) {
      return '#49ACF1';
    }

    return null;
  };

  renderVariables() {
    const { commodity, deliveryObj } = this.state;

    const obj = [];
    const data = [...this.state.selectedObj];
    // const data = deliveryObj ? deliveryObj.filter((element, index, array) => element.commodity[0].name  == commodity.name) : [];

    if (data.length > 0) {
      data.forEach((currentValue, index, arr) => {
        obj.push({
          _id: currentValue,
          jd_send_fail_status: 2,
        });
      });
    }
    return obj;
  }

  selectedFailId(value) {
    const array = [...this.state.selectedObj];
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
      this.setState({ selectedObj: array });
    } else {
      array.push(value);
      this.setState({ selectedObj: array });
    }
  }

  renderDeliveryObj(obj) {
    if (obj.return) {
      return obj.return[0].delivery_point;
    }
    return obj.delivery;
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>การติดตามงาน</div>
        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            if (loading) return 'loading...';
            if (error) return error;

            const { shipmentList, trackingList } = data;
            const shipment = shipmentList.shipment[0];
            const tracking = trackingList.tracking[0];
            const trackingJD = shipment.tracking.tracking_detail_lazada;

            return (
              <Row>
                <Col md={12}>
                  <div className={`${cn.mb10}`}>{this.renderStatus(shipment.shipment_status)}</div>
                  <div className={`${cn.textGray} ${cn.textSmall} ${cn.mb10}`}>
                    อัพเดทล่าสุด: <Moment format="DD/MM/YYYY HH:mm">{shipment.last_update}</Moment>
                  </div>

                  {shipment.shipment_status == 4 && shipment.jd_send_fail_status == 1 ? (
                    <div className={`${cn.RowItem} ${cn.mt10}`}>
                      <div className={cn.Left}>ส่งสถานะ "fail" ให้ JD</div> <br />
                      <div className={cn.Left}>
                        <Button
                          style={{ background: '#d90104', color: '#ffffff', opacity: 1 }}
                          onClick={() =>
                            this.setState({
                              isOpenModalJDFailStatus: true,
                              deliveryObj: this.renderDeliveryObj(trackingJD),
                            })
                          }
                        >
                          {' '}
                          <i className="fal fa-reload"></i> ส่งสถานะ "fail" ให้ JD{' '}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    shipment.jd_send_fail_status == 2 && (
                      <div className={`${cn.RowItem} ${cn.mt10}`}>
                        <div className={cn.Left}>ส่งสถานะให้ JD</div> <br />
                        <div className={cn.Left}>
                          <b className={cn.textGreen}>ส่งสถานะให้ JD เรียบร้อย</b>
                        </div>
                      </div>
                    )
                  )}
                  <Formik
                    initialValues={{ failId: [] }}
                    onSubmit={fields => {
                      alert('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
                    }}
                  >
                    {({ values, errors, status, touched }) => (
                      <Mutation
                        mutation={lazTrackingUpdateMutation}
                        update={(cache, { data }) => {
                          this.setState({
                            isOpenModalJDFailStatus: false,
                            commodity: null,
                            deliveryObj: null,
                          });
                        }}
                        refetchQueries={() => [
                          'JDShipmentListDetailSubTracking',
                          'JDShipmentDetailTracking',
                        ]}
                      >
                        {lazTrackingUpdate => {
                          return (
                            <ModalConfirm
                              show={this.state.isOpenModalJDFailStatus}
                              onPressButtonCancel={() =>
                                this.setState({ isOpenModalJDFailStatus: false })
                              }
                              onPressButtonConfirm={() => {
                                console.log(this.state.selectedObj);
                                this.state.selectedObj.length > 0
                                  ? lazTrackingUpdate({
                                      variables: {
                                        shipment_id: shipment._id,
                                        _id: shipment.tracking._id,
                                        tracking_detail_lazada: {
                                          delivery: this.renderVariables(),
                                        },
                                      },
                                    })
                                  : alert('กรุณาเลือกจุดที่จะส่งสถานะทั้งหมด');
                              }}
                              labelButtonConfirm={`ตกลง`}
                              labelButtonCancel={`กลับ`}
                              styleColorButtonConfirm="primary"
                              titleHeader={`ส่งสถานะให้ JD`}
                              onHide={() => this.setState({ isOpenModalJDFailStatus: false })}
                            >
                              <div>
                                คุณต้องการส่งสถานะให้ JD ใช่หรือไม่ ถ้าใช่ ให้เลือกงานที่ต้องส่ง
                                แล้ว กด <b>"ตกลง"</b>
                              </div>
                              {this.state.deliveryObj ? (
                                <div style={{ marginTop: 15 }}>
                                  {this.state.deliveryObj.map((item, index) => {
                                    if (item.jd_send_fail_status === 1)
                                      return (
                                        <div>
                                          <input
                                            name="failId"
                                            type="checkbox"
                                            value={item._id}
                                            id={item._id}
                                            onChange={e => this.selectedFailId(e.target.value)}
                                          />
                                          <label
                                            class="custom-control-label"
                                            for={item._id}
                                            style={{ paddingLeft: 5 }}
                                          >
                                            {item.commodity[0].name} :{' '}
                                            {item.commodity[0].name_detail}
                                          </label>
                                        </div>
                                      );
                                  })}
                                </div>
                              ) : (
                                <div>ไม่มีข้อมูล</div>
                              )}
                            </ModalConfirm>
                          );
                        }}
                      </Mutation>
                    )}
                  </Formik>

                  <div className={`${cn.LineHorizontal} ${cn.mt10} ${cn.mb10}`}></div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ผู้ดูแลงาน:{' '}
                      <span className={cn.textBold}>{shipment.transporter_name || '-'}</span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      โทร:{' '}
                      <span className={cn.textBold}>{`${shipment.transporter_phone_code ||
                        '-'} ${shipment.transporter_phone_number || '-'}`}</span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      พนักงานขับรถ:{' '}
                      <span className={cn.textBold}>
                        {shipment.driver_name_under_mama
                          ? shipment.driver_name_under_mama || '-'
                          : tracking.driver_name || '-'}
                      </span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ทะเบียนรถ:{' '}
                      <span className={cn.textBold}>
                        {tracking.user_setting_truck_license_plate || '-'}
                      </span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      โทร:{' '}
                      <span className={cn.textBold}>
                        {shipment.driver_tel_under_mama
                          ? `${tracking.driver_phone_code ||
                              '-'} ${shipment.driver_tel_under_mama || '-'}`
                          : `${tracking.driver_phone_code || '-'} ${tracking.driver_phone_number ||
                              '-'}`}
                      </span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>

                  <div className={cn.TrackingWrapper}>
                    {shipment.shipment_status === 4 && shipment.completed_date && (
                      <TrackingList
                        title={`ดำเนินการขนส่งเรียบร้อยแล้ว`}
                        subTitle={
                          <Moment format="DD/MM/YYYY - HH:mm">{shipment.completed_date}</Moment>
                        }
                      />
                    )}

                    {shipment.tracking.tracking_detail_lazada.return.map((item, index) => {
                      return (
                        <TrackingList
                          key={index}
                          arrow
                          title={this.getTitleReturn(item)}
                          subTitle={this.getSubTitleReturn(item)}
                          onPress={() =>
                            this.context.onOpenSubShipmentTracking(
                              this.getTitleReturn(item),
                              index,
                              'return'
                            )
                          }
                          colorIcon={this.getColorStatusReturn(item.signature_date_return)}
                          active={
                            this.context.state.subShipmentTrackingIndex === index &&
                            this.context.state.subShipmentTrackingMode === 'return'
                          }
                        />
                      );
                    })}

                    {shipment.tracking.tracking_detail_lazada.delivery.map((item, index) => {
                      return (
                        <TrackingList
                          key={index}
                          item={item}
                          arrow
                          title={this.getTitleDelivery(item)}
                          subTitle={this.getSubTitleDelivery(item)}
                          onPress={() =>
                            this.context.onOpenSubShipmentTracking(
                              this.getTitleDelivery(item),
                              index,
                              'delivery'
                            )
                          }
                          colorIcon={this.getColorStatusDelivery(item.delivery_status)}
                          active={
                            this.context.state.subShipmentTrackingIndex === index &&
                            this.context.state.subShipmentTrackingMode === 'delivery'
                          }
                        />
                      );
                    })}

                    {shipment.tracking.tracking_detail_lazada.pick_up.map((item, index) => {
                      return (
                        item.signature_date && (
                          <TrackingList
                            key={index}
                            arrow
                            title={`รับสินค้า - ${item.tag_name} เรียบร้อยแล้ว`}
                            subTitle={
                              <Moment format="DD/MM/YYYY - HH:mm">{item.signature_date}</Moment>
                            }
                            onPress={() =>
                              this.context.onOpenSubShipmentTracking(
                                `รับสินค้า - ${item.tag_name} เรียบร้อยแล้ว`,
                                index,
                                'pickup'
                              )
                            }
                            active={
                              this.context.state.subShipmentTrackingIndex === index &&
                              this.context.state.subShipmentTrackingMode === 'pickup'
                            }
                          />
                        )
                      );
                    })}

                    {shipment.tracking.driver_accept === 1 && (
                      <TrackingList
                        title="ขนส่งยืนยันงาน เรียบร้อยแล้ว"
                        subTitle={
                          <Moment format="DD/MM/YYYY - HH:mm">
                            {shipment.tracking.driver_accept_date}
                          </Moment>
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ShipmentTracking;
