import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const MessageError = styled.span`
  display: block;
  color: #ff0000;
  min-height: 20px;
`;

export const Divider = styled.div`
  width: 105%;
  height: 1px;
  background-color: #e8e8e8;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: -14px;
`;

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1;

  & thead > tr,
  & tbody > tr {
    border-bottom: 1px solid #e8e8e8;
  }

  & thead {
    & tr > th {
      color: #8d8d8d;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  & th,
  & td {
    padding: 8px;
  }

  & th:nth-child(1) {
    padding-right: 10px;
  }

  & th:nth-child(2) {
    padding-right: 10px;
  }

  & .text-right {
    text-align: right;
  }
`;

export const ButtonRemove = styled(Button)`
  & {
    color: #d90101 !important;
    padding: 0 !important;
    border: 0 !important;
  }
`;

export const ButtonLink = styled(Button)`
  & {
    color: #1c73e8 !important;
    padding: 0 !important;
  }
`;
