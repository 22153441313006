import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';

// graphql
import paymentOrderAdd from '../../graphql/mutation/paymentOrderAdd';
import paymentOrderList from '../../graphql/query/paymentOrderList';

const ModalContainer = styled(Modal)`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

class CreatePaymentOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = { messageError: '' };
  }

  render() {
    return (
      <ModalContainer
        show={this.props.show}
        onHide={() => {
          this.props.onHide();
          this.setState({ messageError: '' });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>สร้างรายการตั้งเบิกใช่หรือไม่?</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ระบบจะนำข้อมูลจากที่คุณได้บันทึกไว้ใน แถบ ต้นทุน-ราคาขาย
          มาสร้างเป็นรายการตั้งเบิกให้อัตโนมัติ <br />
          และตรวจสอบผู้รับเงินให้ถูกต้อง หากคุณมั่นใจแล้ว กด “ใช่” หากต้องการกลับไปแก้ไข กด “ไม่ใช่”{' '}
          <br />
          {this.state.messageError && (
            <div style={{ color: 'red', paddingTop: 15 }}>{this.state.messageError}</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ float: 'left', width: 60 }}
            onClick={() => {
              this.props.onHide();
              this.setState({ messageError: '' });
            }}
          >
            ไม่ใช่
          </Button>
          <Mutation
            mutation={paymentOrderAdd}
            variables={{
              shipment_id: this.props.shipmentId,
            }}
            update={() => {
              this.props.onHide();
              window.open(`/shipmentpvc?shipment=${this.props.shipmentId}`);
            }}
            refetchQueries={() => ['paymentOrderList']}
            onError={error => {
              console.log(error.message);
              let textError =
                error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message;
              if (textError === 'Item awaiting create payment order not found.') {
                textError = 'ไม่พบรายการที่ต้องตั้งเบิกใหม่';
              } else if (textError === 'Billing system user not found.') {
                textError = 'ผู้ใช้งานไม่ถูกต้อง';
              }
              this.setState({ messageError: textError });
            }}
          >
            {paymentOrderAdd => {
              return (
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    paymentOrderAdd();
                    this.setState({ messageError: '' });
                  }}
                  style={{ width: 60 }}
                >
                  ใช่
                </Button>
              );
            }}
          </Mutation>
        </Modal.Footer>
      </ModalContainer>
    );
  }
}

export default CreatePaymentOrderList;
