const initialValue = {
  isCreateQR: false,
  dateTime: '',
  document: [],
  codList: [],
  qrCode: {
    id: '',
    date: '',
    ref1: '',
    ref2: '',
    image: '',
    paidAmount: 0,
    billingCod: '',
  },
};

export default initialValue;
