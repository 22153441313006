import React from "react";
export const ShipmentDetailContext = React.createContext();

class ShipmentDetailContextProvider extends React.Component {
  state = {
    // manage popup
    subShipmentTracking: false,
    subShipmentTrackingMode: '',
    subShipmentTrackingIndex: null,
    subShipmentTrackingTitle: '',

    subShipmentTrackingOrder: false,
    subShipmentTrackingOrderIndex: 0,
    subShipmentTrackingOrderTitle: '',
    subShipmentTrackingOrderMode: '',
  };

  render() {
    return (
      <ShipmentDetailContext.Provider
        value={{
          state: this.state,
          setState: (data) => {
            this.setState({
              ...this.state,
              ...data,
            });
          },
          onCloseAll: () => {
            this.setState({
              subShipmentTracking: false
            });
          },
          onCloseAllAndOpen: (popupName = []) => {
            this.setState(
              {
                subShipmentTracking: false
              },
              () => {
                if (popupName.length > 0) {
                  popupName.forEach(element => {
                    this.setState({ [`${element}`]: true });
                  });
                }
              }
            );
          },
          onClose: (popupName = []) => {
            popupName.forEach(element => {
              this.setState({ [`${element}`]: false });
            });
          },
          onOpen: (popupName = []) => {
            popupName.forEach(element => {
              this.setState({
                [`${element}`]: true
              });
            });
          },
          onOpenSubShipmentTracking: (title, index, mode) => {
            this.setState({
              subShipmentTracking: true,
              subShipmentTrackingOrder: false,
              subShipmentTrackingTitle: title,
              subShipmentTrackingIndex: index,
              subShipmentTrackingMode: mode,
            });
          },
          onOpenSubShipmentTrackingOrder: (title, index, mode) => {
            this.setState({
              subShipmentTrackingOrder: true,
              subShipmentTrackingOrderTitle: title,
              subShipmentTrackingOrderIndex: index,
              subShipmentTrackingOrderMode: mode,
            });
          }
        }}
      >
        {this.props.children}
      </ShipmentDetailContext.Provider>
    );
  }
}

export default ShipmentDetailContextProvider;
