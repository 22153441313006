import gql from 'graphql-tag';

export default gql`
query waitingMakeDebitNoteListSearch(
    $account_info_id: ID
    $summary_invoice_id: ID!
  ){
    waitingMakeDebitNoteList(
        account_info_id : $account_info_id
        summary_invoice_id : $summary_invoice_id
    ){
    	list{
        _id
        shipment_id
        shipment_number
        summary_invoice_id
        invoice_business_list{
          _id
          invoice_business_list_category
          invoice_business_list_detail
          invoice_business_list_price
          invoice_business_list_type
          invoice_business_list_qty
          invoice_business_list_total_price
          invoice_business_list_discount
          invoice_business_list_vat
          invoice_business_list_wht
          invoice_business_list_total_vat
          invoice_business_list_total_wht
          invoice_business_list_grand_total_price
        }
        total_price
        total_vat
        total_wht1
        total_wht3
        total_wht5
        invoice_business_grand_total_price
      }
      total_price
      grand_total_vat
      grand_total_wht1
      grand_total_wht3
      grand_total_wht5
      grand_total_price
    }
  }
`;
