import React, { Component } from 'react'
import ModalConfirm from './components/ModalConfirm';
import NotificationSystem from 'react-notification-system';
import { Query, graphql, compose, Mutation } from "react-apollo";
import styled from 'styled-components';
//Mutation
import subThirdPartySettingUpdateMutation from './graphql/mutation/subThirdPartySettingUpdate'
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';

const styleNotification = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px',
        },

        error: {
            color: '#FFFFFF',
            backgroundColor: '#FC727A',
            border: '1px solid #FC727A',
            boxShadow: 'none',
            borderRadius: '4px',
        },

        success: {
            color: '#FFFFFF',
            backgroundColor: '#318DE8',
            border: '1px solid #318DE8',
            boxShadow: 'none',
            borderRadius: '4px',
        },
    },
};

class DataConfirmModal extends Component {
    constructor(props){
        super(props)
        this.notificationSystem = React.createRef();
    }

    addNotification = (typeStatus, message) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: (
                <span>
                    <strong>แจ้งเตือน</strong> {message}
                </span>
            ),
            level: typeStatus,
            position: 'tr',
        });
    };

    handlerSubmit = (lazTrackingUpdate) => {
        const delivery = this.props.agentDetailList.map((data, index) => ({
            _id: data._id,
            admin_reject_status: 4
        }))

        let finalData = {}

        if(delivery){
            finalData = {
                _id: this.props.trackingId,
                tracking_detail_lazada: {
                    delivery: delivery
                }
            }
            console.log(finalData)

            if(finalData){
                lazTrackingUpdate({
                    variables: finalData
                })
            }
        }

        // let subThirdPartyData = {
        //     _id: this.props.subThirdPartyId,
        //     agent_shipment_status: 3,
        //     admin_reject_detail: ''
        // }

        // console.log(subThirdPartyData)
    }

    handlerSubThirdPartySettingUpdate = (subThirdPartySettingUpdate) => {
        subThirdPartySettingUpdate({
            variables: {
                _id: this.props.subThirdPartyId,
                // เปลี่ยนเป็นสถานะผ่าน
                agent_shipment_status: this.props.codAvailable ? 5 : 7,
                admin_reject_detail: ''
            }
        })

        
    }
    render() {
        return(
            <Mutation
                mutation={subThirdPartySettingUpdateMutation}
                update={(cache, { data }) => {
                    this.props.closeModal()
                }}
                onError={error => {
                    console.log(error);
                    this.addNotification('error', error.message.replace("GraphQL error: ", ""));
                }}
                refetchQueries={() => ["shipmentAgentView"]}
            >
                {(subThirdPartySettingUpdate) => {
                    return (
                        <Mutation
                            mutation={ lazTrackingUpdateMutation }
                            update={(cache, { data }) => {
                                this.handlerSubThirdPartySettingUpdate(subThirdPartySettingUpdate)
                                // this.props.closeModal()
                            }}
                            onError={error => {
                                console.log(error);
                                this.addNotification('error', error.message.replace("Network error: ",""));
                            }}
                        >
                            {(lazTrackingUpdate) => {
                                return (
                                    <ModalConfirm
                                        show={this.props.isDataCheckedModal}
                                        onPressButtonCancel={this.props.closeModal}
                                        onPressButtonConfirm={() => this.handlerSubThirdPartySettingUpdate(subThirdPartySettingUpdate)}
                                        labelButtonConfirm={`ใช่, ฉันยืนยัน`}
                                        labelButtonCancel={`ไม่ใช่`}
                                        // disabledButtonConfirm={this.state.yearholidays == ''}
                                        styleColorButtonConfirm="green"
                                        titleHeader={`แจ้งเตือน`}
                                        onHide={this.props.closeModal}
                                    >
                                    <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
                                        <div style={{ lineHeight: 1 }}>
                                            ยืนยันการตรวจสอบใช่หรือไม่?<br />
                                                    หากยืนยันไปแล้ว<br />
                                                    1. Admin และ Agent จะไม่สามารถแก้ไขได้อีก<br />
                                                    2. ระบบจะทำการ อัพเดท “สถานะ (Final)” ไปยังรายละเอียดงานขนส่ง (TRIP No. ที่คุณตรวจสอบ)<br />
                                            <span style={{ color: '#318DE8' }} >“การกระทำนี้อาจใช้เวลาสักครู่ กรุณารอ จนกว่าจะมีข้อความตอบรับ”</span>
                                        </div>
                                    </ModalConfirm>
                                    )
                                }}
                            </Mutation>
                        )
                }}
            </Mutation>
        )

    }
}

export default DataConfirmModal;