export const TEXT_UPLOAD_FILE = 'อัพโหลดไฟล์';
export const TEXT_UOLOAD_FILE_TITLE = 'อัพโหลดไฟล์ / แก้ไข';
export const TEXT_UPLOAD_FILE_EXAMPLE = 'ดาวน์โหลดตัวอย่างไฟล์';
export const TEXT_CLOSE = 'ปิด';

export const TEXT_TITLE_CUSTOMER = 'ลูกค้า';
export const TEXT_TITLE_ITEM_NAME = 'ชื่อสินค้า';
export const TEXT_TITLE_SIZE = 'ขนาด';
export const TEXT_TITLE_WEIGHT = 'น้ำหนัก';
export const TEXT_TITLE_STATUS = 'สถานะ';
export const TEXT_TITLE_SENT_DATE = 'วันที่ส่งสำเร็จ';

export const TEXT_SPEND = 'ต้นทุนค่าขนส่งรวม (บาท)';
export const TEXT_REVENUE = 'รายได้รวม (บาท)';
export const TEXT_TOTAL_COST = 'กำไร/ขาดทุน (บาท)';
export const TEXT_LIST = 'รายการ';
export const TEXT_SUM_COST = 'ต้นทุนค่าขนส่งรวม (บาท):';
export const TEXT_SUM_INCOME = 'รายได้รวม (บาท):';
export const TEXT_SUM_PROFIT = 'กำไร/ขาดทุนรวม (บาท):';

export const TEXT_TOP_NUM = 'จำนวนสินค้าทั้งหมด (ชิ้น)';
export const TEXT_TOP_SUM = 'ค่าเฉลี่ยต้นทุนรายชิ้น (เกิดขึ้นจริง     /เป้าหมาย)';
export const TEXT_TOP_COST = 'ราคาต้นทุนค่าขนส่งรวม';
export const TEXT_TOP_INCOME = 'รายได้รวม';
