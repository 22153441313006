import gql from 'graphql-tag';

export default gql`
mutation  Update(
    $license_plate: String!
){
  userSettingTruckAdd(
    user_setting_truck_license_plate: $license_plate,
      driver_status : true
    ){
      _id
    }
  }
`;