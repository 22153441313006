import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipment_id: ID!
    $category: String
    $detail: String
    $price: Float
    $qty: Float
    $vat: Int
    $wht: Int
    $tax_type: Int!
){
    invoiceMoreAdd(
        shipment_id: $shipment_id
        invoice_business_list_category: $category
        invoice_business_list_detail: $detail
        invoice_business_list_price: $price
        invoice_business_list_qty: $qty
        invoice_business_list_vat: $vat
        invoice_business_list_wht: $wht
        invoice_business_list_tax_type: $tax_type
    ){
        _id
    }
  }
`;
