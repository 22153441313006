import gql from 'graphql-tag';

export default gql`
  query shipmentKAStatus {
    shipmentKAStatus {
      WAITING
      INPROGRESS
      WAITINGCONFIRM
      WAITINGCOD
      WAITINGCREATECOST
      COMPLETED
      CANCLE
      ALL
    }
  }
`;
