import styled from 'styled-components';
import { NavDropdown } from 'react-bootstrap';

export const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

export const PopUpItemWrapper = styled.div`
  color: #000;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  gap: 10px;
`;

export const TablePopover = styled.table`
  & th,
  & td {
    padding: 4px;
  }
`;
