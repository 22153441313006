import React from 'react';
import { Grid, Row, Col, Tooltip, Button, Modal } from 'react-bootstrap';
import FieldUploadFile from '../components/FieldUploadFile';
import {
  TEXT_CLOSE,
  TEXT_UOLOAD_FILE_TITLE,
  TEXT_UPLOAD_FILE,
  TEXT_UPLOAD_FILE_EXAMPLE,
} from '../utils/useConstants';
import adminListQuery from '../../Report/graphql/query/adminList';
import { client } from 'index';
import importUpdateDimweight from '../graphql/importUpdateDimweight';
import { gql } from 'apollo-boost';
import { FunctionOpenInNewTab } from 'views/ReportCalcurate/utils/useFunctions';

const upload = gql`
  mutation SingleUpload($file: Upload, $path: String) {
    singleUpload(file: $file, path: $path) {
      path
    }
  }
`;

const CostPerParcelModalFile = ({ open, setOpen }) => {
  const [file, setFile] = React.useState(null);

  const FunctuionUploadFile = async () => {
    // const { data } = await client.query({
    //   query: adminListQuery,
    // });
    // const { admin } = data.adminList;
    // if (admin.length === 0) {
    //   alert('ไม่พบ Admin');
    //   return;
    // }
    // const adminId = admin[0]._id;
    if (file) {
      const {
        data: { singleUpload },
      } = await client.mutate({
        mutation: upload,
        variables: {
          file: file.file,
          path: `upload/codPerParcel/${file.file.name}`,
        },
      });
      if (singleUpload) {
        await client.mutate({
          mutation: importUpdateDimweight,
          variables: {
            path: singleUpload.path,
            // adminId,
          },
        });
      }
      setOpen(false);
    }
  };

  return (
    <Modal
      dialogClassName="modal-dialog-size"
      show={open}
      backdrop="static"
      onHide={() => setOpen(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{TEXT_UOLOAD_FILE_TITLE}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row style={{ marginBottom: '10px' }}>
          <Col md={12}>
            <b>{TEXT_UPLOAD_FILE}</b>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <FieldUploadFile name={`file-${new Date().getTime()}`} setValue={setFile} />
        </Row>
        <Row>
          <div
            onClick={() => {
              FunctionOpenInNewTab(
                `https://s3.ap-southeast-1.amazonaws.com/media.kaikin.co/upload/lazadaProject/template_updateWeightAndDim_v1.xlsx`
              );
            }}
            style={{
              color: 'blue',
              marginLeft: '34px',
              cursor: 'pointer',
            }}
          >
            {TEXT_UPLOAD_FILE_EXAMPLE}
          </div>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)}>{TEXT_CLOSE}</Button>
        <Button
          bsStyle="primary"
          onClick={() => {
            FunctuionUploadFile();
          }}
        >
          {TEXT_UPLOAD_FILE}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CostPerParcelModalFile;
