import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Table from '../components/Table';
import Checkbox from '../components/Checkbox';
import pickupInventoryCusQuery from '../graphql/query/pickupInventoryCus';
import kaWarehousesQuery from '../graphql/query/kaWarehouses';
import SelectCustomerBU from '../components/SelectCustomerBU';
import { ErrorMessage } from '@hookform/error-message';
import { componentsToColor } from 'pdf-lib';

const ModalStyle = styled(Modal)`
  & .modal-dialog {
    width: 100%;
    max-width: 600px;
  }
`;

const ModalHeader = styled(Modal.Header)`
  & button.close {
    font-size: 30px;
  }
`;

export const MessageError = styled.span`
  display: block;
  color: #ff0000;
  min-height: 20px;
`;

const schema = Yup.object({
  customerId: Yup.string(),
  isCustomerKA: Yup.boolean(),
  warehouses: Yup.array()
    .of(
      Yup.object({
        tagName: Yup.string().required('กรุณากรอกข้อมูล'),
        address: Yup.string().required('กรุณากรอกข้อมูล'),
        province: Yup.string().nullable(),
        district: Yup.string().nullable(),
        subDistrict: Yup.string().nullable(),
        postCode: Yup.string().nullable(),
        lat: Yup.string().nullable(),
        lng: Yup.string().nullable(),
        contactName: Yup.string().nullable(),
        contactTel: Yup.string().nullable(),
        warehouseType: Yup.number().nullable(),
        active: Yup.boolean().required(),
        wmsBranch: Yup.string().nullable(),
        totalOrder: Yup.number().nullable(),
        customerId: Yup.string().required(),
      })
    )
    .min(1, 'กรุณาเลือกคลังสินค้า'),
});

const initialValue = {
  customerId: '',
  isCustomerKA: false,
  warehouses: [],
};

const ModalWarehouseCustomer = props => {
  const { open, onClose, shipmentIndex, onComplete, dropPoint } = props;
  const methods = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues: {
      ...initialValue,
    },
  });

  const { reset, watch, errors, handleSubmit, control, setValue, trigger } = methods;
  const watchCustomerId = watch('customerId');
  const watchWarehouses = watch('warehouses');
  const watchIsCustomerKA = watch('isCustomerKA');

  useEffect(() => {
    reset({
      ...initialValue,
      warehouses: [...dropPoint],
    });
  }, [open]);

  const onSubmit = handleSubmit(values => {
    onComplete({
      customerId: values.customerId,
      warehouses: values.warehouses,
    });
  });

  // const onSubmit = () => {
  //   console.log('handleSubmit ยืนยันการเลือกที่อยู่รับสินค้า', handleSubmit, errors);
  //   handleSubmit(values => {
  //     console.log('handleSubmit ยืนยันการเลือกที่อยู่รับสินค้า', values);
  //   });
  //   // console.log('onSubmit ยืนยันการเลือกที่อยู่รับสินค้า', watchCustomerId, watchWarehouses);
  //   // onComplete({
  //   //   customerId: watchCustomerId,
  //   //   warehouses: watchWarehouses,
  //   // });
  // };

  const onSelect = (event, values) => {
    console.log('onSelect warehouses', values);
    if (event.target.checked) {
      setValue('warehouses', [...watchWarehouses, { ...values }]);
    } else {
      setValue(
        'warehouses',
        watchWarehouses.filter(
          warehouseItem =>
            warehouseItem.address !== values.address && warehouseItem.customerId === watchCustomerId
        )
      );
    }
  };

  return (
    <ModalStyle
      show={open}
      onHide={() => onClose && onClose()}
      backdropClassName="custom-backdrop"
      backdropStyle={{
        zIndex: 1050,
      }}
      style={{
        zIndex: 1050,
      }}
    >
      <ModalHeader closeButton>
        <Modal.Title>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 24,
            }}
          >
            เลือกจุดรับสินค้าให้รถคันที่ {shipmentIndex + 1}
          </div>
        </Modal.Title>
      </ModalHeader>

      <Modal.Body>
        <Row>
          <Col md={6}>
            <strong>เลือกลูกค้า:</strong>
            <Controller
              name="customerId"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <SelectCustomerBU
                  value={value}
                  onChange={(event, extraData) => {
                    onChange(event.target.value);
                    const isKA = extraData?.account_info_customer_type === 4;
                    setValue('isCustomerKA', isKA || false);
                  }}
                />
              )}
            />
            <MessageError>{errors?.customerId?.message}</MessageError>
          </Col>
        </Row>

        <ErrorMessage
          errors={errors}
          name="warehouses"
          render={({ message }) => (
            <div style={{ marginTop: 10 }}>
              <MessageError>{message}</MessageError>
            </div>
          )}
        />

        <div style={{ overflowY: 'scroll', maxHeight: '60vh', position: 'relative' }}>
          <Table>
            <thead>
              <tr
                style={{
                  top: 0,
                  zIndex: 1,
                  width: '100%',
                  position: 'sticky',
                  backgroundColor: 'white',
                }}
              >
                <th style={{ width: 40 }}>เลือก</th>
                <th>ที่อยู่รับสินค้า</th>
                <th className="text-right">จำนวนสินค้าที่ต้องรับ</th>
              </tr>
            </thead>

            <tbody style={{ fontSize: 18 }}>
              {!watchCustomerId && (
                <tr style={{ border: 0 }}>
                  <td colspan={3}>กรุณาเลือกลูกค้า</td>
                </tr>
              )}

              {watchCustomerId && (
                <Query
                  query={pickupInventoryCusQuery}
                  fetchPolicy="cache-and-network"
                  variables={{
                    account_info: watchCustomerId,
                  }}
                >
                  {({ data, loading, error }) => {
                    return (
                      <>
                        {loading && (
                          <tr style={{ border: 0 }}>
                            <td colspan="3">กำลังโหลด...</td>
                          </tr>
                        )}

                        {error && (
                          <tr>
                            <td colspan="3">{error.message}</td>
                          </tr>
                        )}

                        {!loading && !error && data?.pickupInventoryCus.length === 0 && (
                          <tr style={{ border: 0 }}>
                            <td colspan="3">ไม่มีคลังสินค้า</td>
                          </tr>
                        )}

                        {!loading &&
                          !error &&
                          data.pickupInventoryCus.map((item, index) => (
                            <tr key={index} style={{ border: 0 }}>
                              <td>
                                <Checkbox
                                  inline
                                  checked={
                                    !!watchWarehouses.find(
                                      warehouse =>
                                        warehouse.customerId === watchCustomerId &&
                                        warehouse.address === item.address
                                    )
                                  }
                                  disabled={!item.address}
                                  onChange={event => {
                                    const pickup = item.orders?.[0]?.origin || null;
                                    onSelect(event, {
                                      tagName: item.address,
                                      address: item.address,
                                      province: pickup.address.province,
                                      district: pickup.address.city,
                                      subDistrict: null,
                                      postCode: pickup.address.zipCode,
                                      lat: pickup.geocoding?.latitude,
                                      lng: pickup.geocoding?.longitude,
                                      contactName: pickup.name,
                                      contactTel: pickup.phone,
                                      warehouseType: null,
                                      active: true,
                                      wmsBranch: null,
                                      totalOrder: item.totalOrder,
                                      customerId: watchCustomerId,
                                    });

                                    trigger(['warehouses']);
                                  }}
                                >
                                  <span className="checkmark" />
                                </Checkbox>
                              </td>
                              <td>
                                <div>{item.address || '(ไม่มีที่อยู่)'}</div>
                              </td>
                              <td className="text-right">{item.totalOrder} ชิ้น</td>
                            </tr>
                          ))}
                      </>
                    );
                  }}
                </Query>
              )}

              {watchCustomerId && watchIsCustomerKA && (
                <Query
                  query={kaWarehousesQuery}
                  fetchPolicy="cache-and-network"
                  variables={{
                    account_info: watchCustomerId,
                  }}
                >
                  {({ data, loading, error }) => {
                    return (
                      <>
                        {loading && (
                          <tr style={{ border: 0 }}>
                            <td colspan="2">กำลังโหลด...</td>
                          </tr>
                        )}

                        {error && (
                          <tr>
                            <td colspan="2">{error.message}</td>
                          </tr>
                        )}

                        {!loading && !error && data?.kaWarehouses.length === 0 && (
                          <tr style={{ border: 0 }}>
                            <td colspan="2">ไม่มีคลังสินค้า</td>
                          </tr>
                        )}

                        {!loading &&
                          !error &&
                          data.kaWarehouses.map((item, index) => (
                            <tr key={index} style={{ border: 0 }}>
                              <td>
                                <Checkbox
                                  inline
                                  checked={
                                    !!watchWarehouses.find(
                                      warehouse =>
                                        warehouse.customerId === watchCustomerId &&
                                        warehouse.address === item.address
                                    )
                                  }
                                  disabled={!item.address}
                                  onChange={event => {
                                    onSelect(event, {
                                      tagName: item.tag_name,
                                      address: item.address,
                                      province: item.province,
                                      district: item.dictrict,
                                      subDistrict: item.subdictrict,
                                      postCode: item.postcode,
                                      lat: item.lat,
                                      lng: item.lng,
                                      contactName: item.contact_name,
                                      contactTel: item.contact_tel,
                                      warehouseType: item.warehouse_type,
                                      active: item.active,
                                      wmsBranch: item.wms_branch,
                                      totalOrder: null,
                                      customerId: watchCustomerId,
                                    });

                                    trigger(['warehouses']);
                                  }}
                                >
                                  <span className="checkmark" />
                                </Checkbox>
                              </td>
                              <td>
                                <div>
                                  <strong>{item.tag_name}</strong>
                                </div>
                                <div>{item.address || '(ไม่มีที่อยู่)'}</div>
                              </td>
                              <td className="text-right">-</td>
                            </tr>
                          ))}
                      </>
                    );
                  }}
                </Query>
              )}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose && onClose()}>ยกเลิก</Button>
        <Button bsStyle="primary" onClick={() => onSubmit()}>
          ยืนยันการเลือกที่อยู่รับสินค้า({watchWarehouses.length})
        </Button>
      </Modal.Footer>
    </ModalStyle>
  );
};

export default ModalWarehouseCustomer;
