import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Modal,
  Table,
  FormControl,
} from 'react-bootstrap';
import styled from 'styled-components';
import s3 from 'config/s3';

import { Card, Input, SelectBox, Select, Label } from './components';
import provinceData from 'config/province/changwats/th.json';
import districtData from 'config/province/amphoes/th.json';
import subDistrictData from 'config/province/tambons/th.json';
import accountBusinessUpdateMutation from './graphql/mutation/accountBusinessUpdate';
import uploadFileMutation from './graphql/mutation/uploadFile';
import singleUploadMutation from './graphql/mutation/singleUpload';
import accountInfoListQuery from './graphql/query/accountInfoList';
import { Mutation, graphql } from 'react-apollo';
import Moment from 'react-moment';
import { compose } from 'redux';
import NotificationSystem from 'react-notification-system';
import adminListQuery from './graphql/query/adminName';

//react tag input
const KeyCodes = {
  comma: 188,
  enter: 13,
};

//styled component
const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const Container = styled.div`
	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}

	& .text-green {
		color: #19AA19;
	}

	& .text-blue {
		color: #318DE8;
	}

	& .text-red {
		color: #FF0000;
	}

	& .text-grey {
		color: #F2F2F2;
	}

	& .text-primary {
		color: #D90101 !important;
	}
`;

const TextHeader = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
`;

const TextSubHeader = styled.div`
  font-size: 18px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
`;

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  padding-left: 35px !important;
  margin-top: 12px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  &:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }

  & input:checked ~ .checkmark {
    border: 1px solid #ffffff;
    background-color: #19aa19;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const TextRight = styled.div`
  height: 40px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const TextContent = styled.div`
  font-size: 18px;
  line-height: 1;
  margin-top: 10px;
`;

const TextMessageError = styled.span`
  font-size: 18px;
  color: #ff0000;
`;

const selectOptionTypeBusiness = [
  { value: '', label: '-- เลือก --' },
  { value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
  { value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
  { value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
  { value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
  { value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
  { value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
  { value: 'การบริการ', label: 'การบริการ' },
  { value: 'เครื่องจักร', label: 'เครื่องจักร' },
  { value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
  { value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
  { value: 'บ้านและสวน', label: 'บ้านและสวน' },
  { value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
  { value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
  { value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
  { value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const selectOptionCountry = [
  { value: '', label: '-- เลือก --' },
  { value: 'ไทย', label: 'ไทย' },
];

const StatusContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
	padding: 20px;
	
	& .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		&-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 44px
			height: 44px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.1);

			& i {
				margin: auto;
				font-size: 20px;
			}

			&-danger {
				background-color: rgba(217, 1, 1, 0.1);

				& i {
					color: #D90101;
				}
			}

			&-success {
				background-color: rgba(25, 170, 25, 0.1);

				& i {
					color: #19AA19;
				}
			}
		}

		&-text {
			flex: 1;
			margin-left: 8px;
			line-height: 1;
			font-size: 18px;
			font-weight: bold;
		}

		& .nomargin {
			margin: 0;
		}
	}

	& .detail {
		font-size:18px;
		line-height: 1.4;
		margin-top: 10px;

		&-list {
			display: flex;
			flex-direction: row;

			&-left {
				align-self: flex-start;
				flex: 1;
			}

			&-right {
				align-self: flex-end;
			}
		}
	}

	& .text-danger {
		color: #D90101;
		font-weight: bold;
	}
`;

const CreditContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  line-height: 1;
  align-items: flex-end;

  & .text-header {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  & .text-bold {
    font-weight: bold;
  }

  & .text-medium {
    font-size: 24px;
  }

  & .text-large {
    font-size: 28px;
  }

  & .line {
    background-color: #e3e3e3;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;
const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const FileContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  align-items: center;
  padding: 5px 0px 5px 10px;
  height: 40px;
  box-sizing: border-box;

  & .file-left {
    flex: 1;
    display: flex;
    flex-direction: row;

    &-icon {
      font-size: 20px;
      margin-right: 10px;
    }

    &-text {
      font-size: 18px;
    }
  }

  & .file-right {
    display: flex;
    flex-direction: row;

    &-filename {
      margin-right: 10px;
    }

    &-delete {
      margin-right: 10px;
      cursor: pointer;
    }

    & button {
      border-radius: 0px;
      border-left-width: 1px;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
    }
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const TagsEmail = styled.div`
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  padding: 6px 11px;
  height: 38px;
  line-height: 1;
  background-color: #f9f9f9;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;

  & span.delete {
    font-size: 18px;
    color: #cacaca;
    cursor: pointer;
    margin-left: 5px;
  }
`;

let adminName = '';

class BusinessDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      businessId: '',
      accountInfoId: '',
      businessReqNumber: '',
      businessStatus: '',
      isEditData: false, //สำหรับแก้ไขข้อมูลเมื่อ businessStatus อนุมัติแล้ว
      create_date: '',
      approved_date: '',
      cancel_date: '',
      business_credit_approved: '',
      business_credit_used: '',
      business_free_credit: '0',
      business_free_credit_used: '0',
      isOpenModalApprove: false,
      isOpenModalNotApprove: false,
      isOpenModalSuspend: false,
      isOpenModalCancelSuspend: false,
      isOpenModalAlreadyReceived: false,

      //data step 1
      businessName: '',
      businessNameInvalid: false,
      taxId: '',
      taxIdInvalid: false,
      branch: '',
      branchInvalid: false,
      postcode: '',
      postcodeInvalid: false,
      businessAddress: '',
      businessAddressInvalid: false,
      country: { value: '', label: '-- เลือก --' },
      countryInvalid: false,
      province: { value: '', label: '-- เลือก --', pid: '' },
      provinceInvalid: false,
      district: { value: '', label: '-- เลือก --', pid: '' },
      districtInvalid: false,
      subdistrict: { value: '', label: '-- เลือก --' },
      subdistrictInvalid: false,
      businessType: { value: '', label: '-- เลือก --' },
      businessTypeInvalid: false,
      phoneCode: '66',
      phoneNumber: '',
      phoneNumberInvalid: '',
      phoneCodeCoordinator: '66',
      phoneNumberCoordinator: '',
      email: '',
      emailInvalid: false,
      emailTags: [],
      emailTagsInvalid: '',
      emailTagsDelete: [],
      note: '',
      invite_code: '',
      recommend_company: '',
      account_info_customer_type: false,
      account_info_customer_service_type: [],
      account_pricing_conditions_weight: false,
      account_pricing_conditions_dimension: false,
      payment_bank_account: null,
      cod_days: null,
      create_costing_auto: false,

      // shippingAddress
      shippingAddressChecked: false,
      shippingAddresspostcode: '',
      shippingAddresspostcodeInvalid: false,
      shippingAddress: '',
      shippingAddressInvalid: false,
      shippingAddresscountry: { value: '', label: '-- เลือก --' },
      shippingAddresscountryInvalid: false,
      shippingAddressprovince: { value: '', label: '-- เลือก --', pid: '' },
      shippingAddressprovinceInvalid: false,
      shippingAddressdistrict: { value: '', label: '-- เลือก --', pid: '' },
      shippingAddressdistrictInvalid: false,
      shippingAddresssubdistrict: { value: '', label: '-- เลือก --' },
      shippingAddresssubdistrictInvalid: false,

      roundDate: '',
      afterInvoice: '',
      businessPayRound: '',
      businessPayRoundInvalid: false,
      roundDateInvalid: false,
      afterInvoiceInvalid: false,

      //data step 2
      companyWillUse: '', //คาดว่าบริษัทของท่านจะใช้บริการขนส่ง
      companyWillUseInvalid: '',
      productType: [
        //ประเภทสินค้าที่ต้องการจัดส่ง
        { id: 'สินค้าทั่วไป', value: 'สินค้าทั่วไป', checked: false },
        { id: 'รักษาอุณหภูมิ', value: 'รักษาอุณหภูมิ', checked: false },
        {
          id: 'กลุ่มสินค้าอันตราย',
          value: 'กลุ่มสินค้าอันตราย',
          checked: false,
        },
        { id: 'อื่นๆ', value: 'อื่นๆ', checked: false },
      ],
      productTypeInvalid: '',
      productTypeOther: '',
      productTypeOtherInvalid: '',
      truckType: [
        //ประเภทรถที่ต้องการจัดส่ง
        { id: '4 ล้อ', value: '4 ล้อ', checked: false },
        { id: '6 ล้อ', value: '6 ล้อ', checked: false },
        { id: '10 ล้อ', value: '10 ล้อ', checked: false },
        {
          id: '4 ล้อ (ควบคุมอุณหภูมิ)',
          value: '4 ล้อ (ควบคุมอุณหภูมิ)',
          checked: false,
        },
        {
          id: '6 ล้อ (ควบคุมอุณหภูมิ)',
          value: '6 ล้อ (ควบคุมอุณหภูมิ)',
          checked: false,
        },
        {
          id: '10 ล้อ (ควบคุมอุณหภูมิ)',
          value: '10 ล้อ (ควบคุมอุณหภูมิ)',
          checked: false,
        },
        {
          id: 'เทรลเลอร์',
          value: 'เทรลเลอร์',
          checked: false,
        },
      ],
      truckTypeInvalid: '',

      //data step 3
      creditLimit: '', //วงเงินเครดิตที่ต้องการ
      creditLimitInvalid: '',
      creditApproved: '',
      creditApprovedInvalid: '',
      receiveInvoiceAndReceipt: false, //ช่องทางการรับเอกสารใบแจ้งหนี้และใบเสร็จรับเงิน
      transportationFee: '', //คุณต้องการทำหัก ณ ที่จ่ายค่าขนส่งทุกครั้งที่ใช้บริการหรือไม่
      transportationFeeInvalid: '',
      businessInvoiceType: '', //คุณต้องการรับเอกสารใบแจ้งหนี้เมื่อใด
      business_invoice_date_range_start: '',
      businessInvoiceDateRangeStartInvalid: '',
      business_invoice_date_range_end: '',
      businessInvoiceDateRangeEndInvalid: '',
      business_invoice_date_range_start5: '',
      businessInvoiceDateRangeStart5Invalid: '',
      business_invoice_date_range_end5: '',
      businessInvoiceDateRangeEnd5Invalid: '',
      businessInvoiceTypeInvalid: '',
      acceptTerms: false, //ในการลงทะเบียน จะถือว่าคุณยอมรับ...

      //แก้ไขข้อมูล กรณ๊สถานะอนุมัติ
      phoneCodeOld: '66',
      phoneNumberOld: '',
      phoneCodeOldCoordinator: '66',
      phoneNumberOldCoordinator: '',
      emailTagsOld: [],
      emailTagsOldDelete: [],

      //การคิดภาษี
      deliveryVAT: '',
      deliveryVATInvalid: '',
      deliveryWHT: '',
      deliveryWHTInvalid: '',
      serviceVAT: '',
      serviceVATInvalid: '',
      serviceWHT: '',
      serviceWHTInvalid: '',

      //เอกสารเพิ่มเติม
      document1: '',
      document2: '',
      document3: '',

      //ส่วนลดรถแต่ละประเภท
      discountPickupStall: '',
      discountPickupCabinet: '',
      discount6WheelsStall: '',
      discount6WheelsCabinet: '',
      discount6WheelsLift: '',
      discount6WheelsCrane: '',
      discount10WheelsStall: '',
      discount10WheelsCabinet: '',
      discount10WheelsLift: '',
      discountTrailer: '',
      discountTrailer20: '',
      discountTrailer40: '',
      discountTrailer40HQ: '',

      discountPickupStallEnd: '',
      discountPickupCabinetEnd: '',
      discount6WheelsStallEnd: '',
      discount6WheelsCabinetEnd: '',
      discount6WheelsLiftEnd: '',
      discount6WheelsCraneEnd: '',
      discount10WheelsStallEnd: '',
      discount10WheelsCabinetEnd: '',
      discount10WheelsLiftEnd: '',
      discountTrailerEnd: '',
      discountTrailer20End: '',
      discountTrailer40End: '',
      discountTrailer40HQEnd: '',

      reject_note: '',
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);

    this._inputDocument1 = React.createRef();
    this._inputDocument2 = React.createRef();
    this._inputDocument3 = React.createRef();

    this.notificationSystem = React.createRef();

    this._inputCredit = React.createRef();
    this._inputCreditApproved = React.createRef();
  }

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  componentWillReceiveProps(newProps) {
    const { loading, error } = newProps.accountInfoListQuery;
    if (!loading && !error) {
      const {
        accountInfoList: { account_info },
        accountInfoList: { account_business },
      } = newProps.accountInfoListQuery;
      const accountInfo = account_info[0];
      const accountBusiness = account_business;

      if (accountBusiness === null) {
        window.location.href = '/giztixbusiness';
        return;
      }

      this.setState({
        businessId: accountBusiness._id,
        accountInfoId: accountBusiness.account_info_id,
        businessReqNumber: accountBusiness.business_req_number,
        businessStatus: accountBusiness.business_status,

        //step 1
        businessName: accountBusiness.account_info_business_name,
        taxId: accountInfo.account_info_tax_id,
        branch: accountInfo.account_info_branch,
        postcode: accountInfo.account_info_postcode,
        businessAddress: accountInfo.account_info_address,
        country: {
          value: accountInfo.account_info_country,
          label: accountInfo.account_info_country,
        },
        province: this.renderProvinceData(accountInfo.account_info_province),
        district: this.renderDistrictData(accountInfo.account_info_district),
        subdistrict: {
          value: accountInfo.account_info_subdistrict,
          label: accountInfo.account_info_subdistrict,
        },
        businessType: {
          value: accountInfo.account_info_corporate_type,
          label: accountInfo.account_info_corporate_type,
        },
        phoneCode: accountInfo.account_info_corporate_phone_code,
        phoneNumber: accountInfo.account_info_corporate_phone_number,
        phoneCodeCoordinator: accountInfo.account_info_corporate_co_phone_code,
        phoneNumberCoordinator: accountInfo.account_info_corporate_co_phone_number,
        emailTags: accountInfo.account_business_email.map(e => {
          return { id: e._id, text: e.email };
        }),
        note: accountInfo.account_info_note,
        cod_days: accountInfo.cod_days,
        invite_code: accountBusiness.invite_code,
        recommend_company: accountBusiness.recommend_company,
        account_info_customer_type: accountInfo.account_info_customer_type === 4 ? true : false,
        account_info_customer_service_type: [...accountInfo.customer_service_type],
        account_info_customer_type_status: accountInfo.account_info_customer_type,
        account_pricing_conditions_dimension: accountBusiness.account_pricing_conditions_dimension,
        account_pricing_conditions_weight: accountBusiness.account_pricing_conditions_weight,

        payment_bank_account: accountBusiness.payment_bank_account,
        create_costing_auto: accountInfo.create_costing_auto,
        //step 2
        companyWillUse: accountBusiness.business_usage_information_1,
        productType: this.state.productType.map((e, index, arr) => {
          let data = accountBusiness.business_usage_information_2.filter(item => item === e.id);
          if (data.length) {
            e.checked = true;
          } else if (index === arr.length - 1) {
            //เมื่อไม่เจอให้นำค่าไว้ที่ช่องระบุ
            let other = accountBusiness.business_usage_information_2.filter(
              item =>
                item !== 'สินค้าทั่วไป' && item !== 'รักษาอุณหภูมิ' && item !== 'กลุ่มสินค้าอันตราย'
            );
            if (other.length) {
              e.checked = true;
              this.setState({ productTypeOther: other });
            }
          }

          return e;
        }),
        truckType: this.state.truckType.map((e, index, arr) => {
          let data = accountBusiness.business_usage_information_3.filter(item => item === e.id);
          if (data.length) {
            e.checked = true;
          }
          return e;
        }),
        creditLimit: accountBusiness.business_credit_req
          ? this.formatNumber(accountBusiness.business_credit_req.toString(), false)
          : accountBusiness.business_credit_req,
        creditApproved: accountBusiness.business_credit_approved
          ? this.formatNumber(accountBusiness.business_credit_approved.toString(), false)
          : accountBusiness.business_credit_approved,

        shippingAddressChecked: accountInfo.shipping_from_main_address,
        shippingAddresspostcode: accountInfo.account_info_shipping_address[0]
          ? accountInfo.account_info_shipping_address[0].postcode
          : '',
        shippingAddress: accountInfo.account_info_shipping_address[0]
          ? accountInfo.account_info_shipping_address[0].address
          : '',
        shippingAddresscountry: {
          value: accountInfo.account_info_shipping_address[0]
            ? accountInfo.account_info_shipping_address[0].country
            : '',
          label: accountInfo.account_info_shipping_address[0]
            ? accountInfo.account_info_shipping_address[0].country
            : '',
        },
        shippingAddressprovince: this.renderProvinceData(
          accountInfo.account_info_shipping_address[0]
            ? accountInfo.account_info_shipping_address[0].province
            : ''
        ),
        shippingAddressdistrict: this.renderDistrictData(
          accountInfo.account_info_shipping_address[0]
            ? accountInfo.account_info_shipping_address[0].district
            : ''
        ),
        shippingAddresssubdistrict: {
          value: accountInfo.account_info_shipping_address[0]
            ? accountInfo.account_info_shipping_address[0].subdistrict
            : '',
          label: accountInfo.account_info_shipping_address[0]
            ? accountInfo.account_info_shipping_address[0].subdistrict
            : '',
        },

        roundDate: accountBusiness.business_pay_round.round_date,
        afterInvoice: accountBusiness.business_pay_round.after_invoice,
        businessPayRound: Number(accountBusiness.business_pay_round.round_date) > 0 ? 1 : 2,

        receiveInvoiceAndReceipt: accountBusiness.business_edocument_receipt === true ? 1 : 2,
        transportationFee: accountBusiness.business_alway_wht + '',
        businessInvoiceType: accountBusiness.business_invoice_type.toString(),
        business_invoice_date_range_start:
          accountBusiness.business_invoice_type === 3
            ? accountBusiness.business_invoice_date_range.start
            : '',
        business_invoice_date_range_end:
          accountBusiness.business_invoice_type === 3
            ? accountBusiness.business_invoice_date_range.end
            : '',
        business_invoice_date_range_start5:
          accountBusiness.business_invoice_type === 5
            ? accountBusiness.business_invoice_date_range.start
            : '',
        business_invoice_date_range_end5:
          accountBusiness.business_invoice_type === 5
            ? accountBusiness.business_invoice_date_range.end
            : '',
        create_date: accountBusiness.create_date,
        cancel_date: accountBusiness.cancel_date,
        approved_date: accountBusiness.approved_date,
        business_credit_approved: accountBusiness.business_credit_approved,
        business_credit_used: accountBusiness.business_credit_used,
        business_free_credit: accountBusiness.business_free_credit,
        business_free_credit_used: accountBusiness.business_free_credit_used,
        acceptTerms: true,

        //ข้อมูลเพิ่มเติม (สำหรับ Admin)
        deliveryVAT: accountBusiness.business_admin_delivery_vat,
        deliveryWHT: accountBusiness.business_admin_delivery_wht,
        serviceVAT: accountBusiness.business_admin_service_vat,
        serviceWHT: accountBusiness.business_admin_service_wht,

        document1: accountBusiness.business_admin_document_1,
        document2: accountBusiness.business_admin_document_2,
        document3: accountBusiness.business_admin_document_3,

        discountPickupStall:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5bed280b2a9d8d18e851d7bb'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5bed280b2a9d8d18e851d7bb'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5bed280b2a9d8d18e851d7bb'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discountPickupCabinet:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5be5057787415b16d8cf48f7'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5be5057787415b16d8cf48f7'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5be5057787415b16d8cf48f7'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount6WheelsStall:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b30'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b30'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b30'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount6WheelsCabinet:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b31'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b31'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b31'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount6WheelsLift:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b32'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b32'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b32'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount6WheelsCrane:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount10WheelsStall:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da1'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da1'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da1'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount10WheelsCabinet:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da2'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da2'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da2'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discount10WheelsLift:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da3'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da3'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da3'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discountTrailer:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6411'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6411'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6411'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discountTrailer20:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6412'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6412'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6412'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discountTrailer40:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6413'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6413'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6413'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',
        discountTrailer40HQ:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6414'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6414'
          )[0].discount != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6414'
              )[0].discount
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount_start
            ? accountBusiness.business_invite_benefit_discount_start.discount
            : '',

        discountPickupStallEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5bed280b2a9d8d18e851d7bb'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5bed280b2a9d8d18e851d7bb'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5bed280b2a9d8d18e851d7bb'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discountPickupCabinetEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5be5057787415b16d8cf48f7'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5be5057787415b16d8cf48f7'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5be5057787415b16d8cf48f7'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount6WheelsStallEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b30'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b30'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b30'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount6WheelsCabinetEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b31'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b31'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b31'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount6WheelsLiftEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b32'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b32'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b32'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount6WheelsCraneEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5c4ac1eb9d52c92174bf0b33'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount10WheelsStallEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da1'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da1'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da1'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount10WheelsCabinetEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da2'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da2'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da2'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discount10WheelsLiftEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da3'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da3'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cad5a065e962c4d15eb2da3'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discountTrailerEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6411'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6411'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6411'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discountTrailer20End:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6412'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6412'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6412'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discountTrailer40End:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6413'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6413'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6413'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',
        discountTrailer40HQEnd:
          accountBusiness.business_admin_benefit_discount &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6414'
          ).length &&
          accountBusiness.business_admin_benefit_discount.filter(
            e => e.setting_truck_type_id === '5cbe8678723da822892a6414'
          )[0].discountend != null
            ? accountBusiness.business_admin_benefit_discount.filter(
                e => e.setting_truck_type_id === '5cbe8678723da822892a6414'
              )[0].discountend
            : accountBusiness.invite_code && accountBusiness.business_invite_benefit_discount
            ? accountBusiness.business_invite_benefit_discount.discount
            : '',

        //ข้อมูลผู้ใช้งาน
        accountNumber: accountInfo.account_info_number,
        customerEmail: accountInfo.account_info_email,
        customerPhoneCode: accountInfo.account_info_phone_code,
        customerPhoneNumber: accountInfo.account_info_phone_number,
        inviteCode: accountBusiness.invite_code,
      });
    }
  }

  renderProvinceData(data) {
    let dataFilter = provinceData.th.changwats.filter(e => e.name === data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data, pid: dataFilter[0].pid };
    }
    return { value: '', label: '-- เลือก --', pid: '' };
  }

  renderDistrictData(data) {
    let dataFilter = districtData.th.amphoes.filter(e => e.name === data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data, pid: dataFilter[0].pid };
    }
    return { value: '', label: '-- เลือก --', pid: '' };
  }

  selectOptionProvinceData(mode) {
    let options = [{ value: '', label: '-- เลือก --', pid: '' }];

    provinceData.th.changwats.map((item, index) => {
      const province = { value: item.name, label: item.name, pid: item.pid };
      options.push(province);
      return province;
    });

    return options;
  }

  selectOptionDistrictData(mode) {
    let options = [{ value: '', label: '-- เลือก --', pid: '' }];

    let dataFilter = districtData.th.amphoes.filter(
      e => e.changwat_pid === this.state.province.pid
    );
    if (mode === 'shippingAddress') {
      dataFilter = districtData.th.amphoes.filter(
        e => e.changwat_pid === this.state.shippingAddressprovince.pid
      );
    }
    dataFilter.map((item, index) => {
      const district = { value: item.name, label: item.name, pid: item.pid };
      options.push(district);
      return district;
    });

    return options;
  }

  selectOptionSubDistrictData(mode) {
    let options = [{ value: '', label: '-- เลือก --' }];

    let dataFilter = subDistrictData.th.tambons.filter(
      e => e.amphoe_pid === this.state.district.pid
    );
    if (mode === 'shippingAddress') {
      dataFilter = subDistrictData.th.tambons.filter(
        e => e.amphoe_pid === this.state.shippingAddressdistrict.pid
      );
    }
    dataFilter.map((item, index) => {
      const subdistrict = { value: item.name, label: item.name };
      options.push(subdistrict);
      return subdistrict;
    });

    return options;
  }

  changeCountry(event, mode) {
    if (mode === 'shippingAddress') {
      this.setState({ shippingAddresscountry: { value: event.value, label: event.label } }, () => {
        this.setState({
          shippingAddressprovince: { value: '', label: '-- เลือก --', pid: '' },
          shippingAddressdistrict: { value: '', label: '-- เลือก --' },
          shippingAddresscountryInvalid: false,
        });
      });
    } else {
      this.setState({ country: { value: event.value, label: event.label } }, () => {
        this.setState({
          province: { value: '', label: '-- เลือก --', pid: '' },
          district: { value: '', label: '-- เลือก --' },
          countryInvalid: false,
        });
      });
    }
  }

  changeProvince(event, mode) {
    if (mode === 'shippingAddress') {
      this.setState(
        { shippingAddressprovince: { value: event.value, label: event.label, pid: event.pid } },
        () => {
          this.setState({
            shippingAddressdistrict: { value: '', label: '-- เลือก --' },
            shippingAddressprovinceInvalid: false,
          });
          this.selectOptionDistrictData('shippingAddress');
        }
      );
    } else {
      this.setState(
        { province: { value: event.value, label: event.label, pid: event.pid } },
        () => {
          this.setState({
            district: { value: '', label: '-- เลือก --' },
            provinceInvalid: false,
          });
          this.selectOptionDistrictData();
        }
      );
    }
  }

  changeDistrict(event, mode) {
    if (mode === 'shippingAddress') {
      this.setState(
        { shippingAddressdistrict: { value: event.value, label: event.label, pid: event.pid } },
        () => {
          this.setState({
            shippingAddresssubdistrict: { value: '', label: '-- เลือก --' },
            shippingAddressdistrictInvalid: false,
          });
          this.selectOptionSubDistrictData('shippingAddress');
        }
      );
    } else {
      this.setState(
        { district: { value: event.value, label: event.label, pid: event.pid } },
        () => {
          this.setState({
            subdistrict: { value: '', label: '-- เลือก --' },
            districtInvalid: false,
          });
          this.selectOptionSubDistrictData();
        }
      );
    }
  }

  changeSubDistrict(event, mode) {
    if (mode === 'shippingAddress') {
      this.setState({
        shippingAddresssubdistrict: { value: event.value, label: event.label },
        shippingAddresssubDistrictInvalid: false,
      });
    } else {
      this.setState({
        subdistrict: { value: event.value, label: event.label },
        subDistrictInvalid: false,
      });
    }
  }
  changeBusinessType(event) {
    this.setState({
      businessType: { value: event.value, label: event.label },
      businessTypeInvalid: false,
    });
  }

  handleSelectShippingAddress(event) {
    const {
      postcode,
      postcodeInvalid,
      businessAddress,
      businessAddressInvalid,
      country,
      countryInvalid,
      province,
      provinceInvalid,
      district,
      districtInvalid,
      subdistrict,
      subdistrictInvalid,
    } = this.state;
    if (event.target.checked) {
      this.setState({
        shippingAddressChecked: event.target.checked,
        shippingAddresspostcode: postcode,
        shippingAddresspostcodeInvalid: postcodeInvalid,
        shippingAddress: businessAddress,
        shippingAddressInvalid: businessAddressInvalid,
        shippingAddresscountry: country,
        shippingAddresscountryInvalid: countryInvalid,
        shippingAddressprovince: province,
        shippingAddressprovinceInvalid: provinceInvalid,
        shippingAddressdistrict: district,
        shippingAddressdistrictInvalid: districtInvalid,
        shippingAddresssubdistrict: subdistrict,
        shippingAddresssubdistrictInvalid: subdistrictInvalid,
      });
    } else {
      this.setState({
        shippingAddressChecked: event.target.checked,
      });
    }
  }

  handleSelectProductType(event) {
    let productType = this.state.productType;

    let data = productType.map(e => {
      if (e.value === event.target.value) {
        e.checked = event.target.checked;
      }

      return e;
    });

    this.setState({ productType: data });
  }

  handleSelectTruckType(event) {
    let truckType = this.state.truckType;

    let data = truckType.map(e => {
      if (e.value === event.target.value) {
        e.checked = event.target.checked;
      }

      return e;
    });

    this.setState({ truckType: data });
  }

  handleDelete(i) {
    const { emailTags } = this.state;
    this.setState(
      {
        emailTagsInvalid: '',
        emailTags: emailTags.filter((e, index) => index !== i),
      },
      () => {
        if (emailTags.length) {
          this.setState({
            emailTagsDelete: [
              ...this.state.emailTagsDelete,
              { id: emailTags[i].id, text: emailTags[i].text },
            ],
          });
        }
      }
    );
  }

  handleAddition(event) {
    if (this.checkEmail(event.target.value)) {
      //ป้อนกันอีเมลซ้ำ
      const checkEmail = this.state.emailTags.filter(e => e.text === event.target.value);
      if (checkEmail.length === 0) {
        this.setState({
          email: '',
          emailTags: [...this.state.emailTags, { id: '', text: event.target.value }],
          emailTagsInvalid: '',
        });
      } else {
        this.setState({ emailTagsInvalid: 'อีเมลนี้ถูกเพิ่มแล้ว' });
      }
    } else {
      this.setState({ emailTagsInvalid: 'รูปแบบอีเมลไม่ถูกต้อง' });
    }
  }

  checkEmail = email => {
    const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validateEmail.test(email)) {
      return true;
    }

    return false;
  };

  checkPhoneNumber = phoneNumber => {
    const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
    if (validatePhoneNumber.test(phoneNumber)) {
      return true;
    }

    return false;
  };

  checkEditForm() {
    if (true) {
      return true;
    }

    return false;
  }

  //แก้ไขข้อมูล เมื่อสถานะอนุมัติ
  handleEditData(accountBusinessUpdate) {
    this.setState({
      phoneNumberInvalid: this.state.phoneNumber ? '' : 'กรุณากรอกข้อมูล',
    });
    this.setState({
      emailTagsInvalid: this.state.emailTags.length ? '' : 'กรุณากรอกข้อมูล',
    });

    if (this.state.phoneNumber && this.state.emailTags.length) {
      accountBusinessUpdate({
        variables: {
          account_business_id: this.state.businessId,
          account_info_corporate_phone_code: this.state.phoneCode,
          account_info_corporate_phone_number: this.state.phoneNumber,
          account_info_corporate_co_phone_code: this.state.phoneCodeCoordinator,
          account_info_corporate_co_phone_number: this.state.phoneNumberCoordinator,
          account_business_email: this.state.emailTags.map(e => {
            if (e.id) {
              //ถ้ามีค่า id ให้ส่ง id ไปด้วยเพราะจะเป็นการแก้ไข
              return { _id: e.id, email: e.text };
            }
            //ถ้าไม่มี id ให้เพิ่ม email เข้าไปใหม่
            return { email: e.text };
          }),
          account_business_email_delete: this.state.emailTagsDelete.map(e => {
            return { _id: e.id };
          }),
        },
      });
    }
  }

  setFormatNumber(event) {
    let value = parseInt(event.target.value);
    console.log(value);
    if (!value) {
      value = 0;
    }
    if (value !== '.') {
      this.setState({
        cod_days: value,
      });
    }
  }
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      cb(event.target.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  }

  handleUploadDocument(uploadFile, documentName, event) {
    const file = event.target.files[0];

    if (file) {
      this.getBase64(file, result => {
        const timestamp = new Date().getTime();
        uploadFile({
          variables: {
            file: result,
            path: `upload/account_business/${documentName}_${timestamp}`,
          },
        });
      });
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/account_business/${documentName}_${timestamp}`,
        },
      });
    }
  }

  handleSave(accountBusinessUpdate) {
    //ข้อมูลบริษัท
    this.setState({ businessNameInvalid: this.state.businessName ? false : true });
    this.setState({ taxIdInvalid: this.state.taxId ? false : true });
    this.setState({ branchInvalid: this.state.branch ? false : true });
    this.setState({ postcodeInvalid: this.state.postcode ? false : true });
    this.setState({ businessAddressInvalid: this.state.businessAddress ? false : true });
    this.setState({ countryInvalid: this.state.country.value ? false : true });
    this.setState({ provinceInvalid: this.state.province.value ? false : true });
    this.setState({ districtInvalid: this.state.district.value ? false : true });
    this.setState({ subDistrictInvalid: this.state.subdistrict.value ? false : true });
    this.setState({ businessTypeInvalid: this.state.businessType.value ? false : true });
    this.setState({ phoneNumberInvalid: this.state.phoneNumber ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({
      emailTagsInvalid: this.state.email
        ? this.checkEmail(this.state.email)
          ? this.state.emailTags.filter(e => e.text === this.state.email).length
            ? 'อีเมลนี้ถูกเพิ่มแล้ว'
            : ''
          : 'รูปแบบอีเมลไม่ถูกต้อง'
        : this.state.emailTags.length
        ? ''
        : 'กรุณากรอกข้อมูล',
    });
    //ข้อมูลการใช้งาน
    this.setState({ companyWillUseInvalid: this.state.companyWillUse ? '' : 'กรุณาเลือกรายการ' });
    this.setState({
      productTypeInvalid: this.state.productType.filter(e => e.checked === true).length
        ? ''
        : 'กรุณาเลือกรายการ',
    });
    this.setState({
      truckTypeInvalid: this.state.truckType.filter(e => e.checked === true).length
        ? ''
        : 'กรุณาเลือกรายการ',
    });
    this.setState({
      productTypeOtherInvalid: this.state.productType.filter(
        e => e.value === 'อื่นๆ' && e.checked === true
      ).length
        ? this.state.productTypeOther
          ? ''
          : 'กรุณากรอกข้อมูล'
        : '',
    });

    let checkProductTypeOther = true;
    if (this.state.productType.filter(e => e.value === 'อื่นๆ' && e.checked === true).length) {
      if (this.state.productTypeOther === '') {
        checkProductTypeOther = false;
      }
    }
    //ข้อมูลการขอเครดิต
    this.setState({ creditLimitInvalid: this.state.creditLimit ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({
      transportationFeeInvalid: this.state.transportationFee ? '' : 'กรุณาเลือกรายการ',
    });
    this.setState({
      businessInvoiceTypeInvalid: this.state.businessInvoiceType ? '' : 'กรุณาเลือกรายการ',
    });

    const roundDateInvalid =
      this.state.businessPayRound == 1 ? (Number(this.state.roundDate) > 0 ? false : true) : false;
    const afterInvoiceInvalid =
      this.state.businessPayRound == 2
        ? Number(this.state.afterInvoice) > 0
          ? false
          : true
        : false;
    const checkBusinessInvoiceType =
      Number(this.state.businessInvoiceType) === 3
        ? this.state.business_invoice_date_range_start && this.state.business_invoice_date_range_end
          ? true
          : false
        : true;

    const checkBusinessInvoiceType5 =
      Number(this.state.businessInvoiceType) === 5
        ? this.state.business_invoice_date_range_start5 &&
          this.state.business_invoice_date_range_end5
          ? true
          : false
        : true;

    this.setState({
      shippingAddresspostcodeInvalid: this.state.shippingAddresspostcode ? false : true,
    });
    this.setState({ shippingAddressInvalid: this.state.shippingAddress ? false : true });
    this.setState({
      shippingAddresscountryInvalid: this.state.shippingAddresscountry.value ? false : true,
    });
    this.setState({
      shippingAddressprovinceInvalid: this.state.shippingAddressprovince.value ? false : true,
    });
    this.setState({
      shippingAddressdistrictInvalid: this.state.shippingAddressdistrict.value ? false : true,
    });
    this.setState({
      shippingAddresssubDistrictInvalid: this.state.shippingAddresssubdistrict.value ? false : true,
    });
    this.setState({ businessPayRoundInvalid: this.state.businessPayRound ? false : true });

    this.setState({ roundDateInvalid });
    this.setState({ afterInvoiceInvalid });

    if (Number(this.state.businessInvoiceType) === 3) {
      this.setState({
        businessInvoiceDateRangeStartInvalid: this.state.business_invoice_date_range_start
          ? ''
          : 'กรุณาเลือกวันที่',
      });
      this.setState({
        businessInvoiceDateRangeEndInvalid: this.state.business_invoice_date_range_end
          ? ''
          : 'กรุณาเลือกวันที่',
      });
    }

    if (Number(this.state.businessInvoiceType) === 5) {
      this.setState({
        businessInvoiceDateRangeStart5Invalid: this.state.business_invoice_date_range_start5
          ? ''
          : 'กรุณาเลือกวันที่',
      });
      this.setState({
        businessInvoiceDateRangeEnd5Invalid: this.state.business_invoice_date_range_end5
          ? ''
          : 'กรุณาเลือกวันที่',
      });
    }

    if (
      //ข้อมูลบริษัท
      this.state.businessName &&
      this.state.taxId &&
      this.state.branch &&
      this.state.postcode &&
      this.state.businessAddress &&
      this.state.country.value &&
      this.state.province.value &&
      this.state.district.value &&
      this.state.subdistrict.value &&
      this.state.businessType.value &&
      this.state.phoneNumber &&
      this.state.emailTags.length &&
      !this.state.email &&
      //ข้อมูลการใช้งาน
      this.state.companyWillUse &&
      this.state.productType.filter(e => e.checked === true).length &&
      this.state.truckType.filter(e => e.checked === true).length &&
      checkProductTypeOther &&
      this.state.shippingAddresspostcode &&
      this.state.shippingAddress &&
      this.state.shippingAddresscountry.value &&
      this.state.shippingAddressprovince.value &&
      this.state.shippingAddressdistrict.value &&
      this.state.shippingAddresssubdistrict.value &&
      this.state.businessPayRound &&
      !roundDateInvalid &&
      !afterInvoiceInvalid &&
      //ข้อมูลการขอเครดิต
      this.state.creditLimit &&
      this.state.transportationFee &&
      this.state.businessInvoiceType &&
      checkBusinessInvoiceType
    ) {
      accountBusinessUpdate({
        variables: {
          _id: this.state.accountInfoId,
          account_business_id: this.state.businessId,

          //การลงทะเบียนใช้งาน Giztix Business
          account_info_business_name: this.state.businessName,
          account_info_tax_id: this.state.taxId,
          account_info_branch: this.state.branch,
          account_info_postcode: this.state.postcode,
          account_info_address: this.state.businessAddress,
          account_info_country: this.state.country.value,
          account_info_province: this.state.province.value,
          account_info_district: this.state.district.value,
          account_info_subdistrict: this.state.subdistrict.value,
          account_info_corporate_type: this.state.businessType.value,
          account_info_corporate_phone_code: this.state.phoneCode,
          account_info_corporate_phone_number: this.state.phoneNumber,
          account_info_corporate_co_phone_code: this.state.phoneCodeCoordinator,
          account_info_corporate_co_phone_number: this.state.phoneNumberCoordinator,
          account_business_email: this.state.emailTags.map(e => {
            if (e.id) {
              //ถ้ามีค่า id ให้ส่ง id ไปด้วยเพราะจะเป็นการแก้ไข
              return { _id: e.id, email: e.text };
            }
            //ถ้าไม่มี id ให้เพิ่ม email เข้าไปใหม่
            return { email: e.text };
          }),
          account_business_email_delete: this.state.emailTagsDelete.map(e => {
            return { _id: e.id };
          }),
          account_info_note: this.state.note,
          cod_days: this.state.cod_days,
          invite_code: this.state.invite_code,
          recommend_company: this.state.recommend_company,
          account_info_customer_type: this.state.account_info_customer_type
            ? 4
            : this.state.account_info_customer_type_status === 1
            ? 1
            : this.state.account_info_customer_type_status === 2
            ? 2
            : 3,
          customer_service_type: this.state.account_info_customer_service_type,
          create_costing_auto: this.state.create_costing_auto,
          //Price Condition
          account_pricing_conditions_weight: this.state.account_pricing_conditions_weight,
          account_pricing_conditions_dimension: this.state.account_pricing_conditions_dimension,

          //ข้อมูลการใช้งาน
          business_usage_information_1: this.state.companyWillUse,
          business_usage_information_2: this.state.productType
            .filter(e => e.checked === true)
            .map(e => {
              if (e.value === 'อื่นๆ') {
                return this.state.productTypeOther.toString();
              }

              return e.value;
            }),
          business_usage_information_3: this.state.truckType
            .filter(e => e.checked === true)
            .map(e => e.value),

          //ข้อมูลการขอเครดิต
          business_credit_req: this.formatNumber(this.state.creditLimit, true),
          business_credit_approved:
            this.state.creditApproved !== null || this.state.creditApproved !== ''
              ? this.formatNumber(this.state.creditApproved, true)
              : parseInt(this.state.creditApproved),
          business_edocument_receipt: this.state.receiveInvoiceAndReceipt ? 1 : 2,
          business_alway_wht: this.state.transportationFee,
          business_invoice_type: this.state.businessInvoiceType,
          business_invoice_date_range:
            Number(this.state.businessInvoiceType) === 3 ||
            Number(this.state.businessInvoiceType) === 5
              ? {
                  start:
                    Number(this.state.businessInvoiceType) === 3
                      ? this.state.business_invoice_date_range_start
                      : this.state.business_invoice_date_range_start5,
                  end:
                    Number(this.state.businessInvoiceType) === 3
                      ? this.state.business_invoice_date_range_end
                      : this.state.business_invoice_date_range_end5,
                }
              : undefined,
          //### ข้อมูลเพิ่มเติม (สำหรับ Admin)
          //การคิดภาษีค่าขนส่ง
          business_admin_delivery_vat: this.state.deliveryVAT,
          business_admin_delivery_wht: this.state.deliveryWHT,
          business_admin_service_vat: this.state.serviceVAT,
          business_admin_service_wht: this.state.serviceWHT,
          //ส่วนลดตามประเภทรถ
          business_admin_benefit_discount: [
            {
              setting_truck_type_id: '5bed280b2a9d8d18e851d7bb',
              discount: this.state.discountPickupStall
                ? Number(this.state.discountPickupStall)
                : null,
              discountend: this.state.discountPickupStallEnd
                ? Number(this.state.discountPickupStallEnd)
                : null,
            },
            {
              setting_truck_type_id: '5be5057787415b16d8cf48f7',
              discount: this.state.discountPickupCabinet
                ? Number(this.state.discountPickupCabinet)
                : null,
              discountend: this.state.discountPickupCabinetEnd
                ? Number(this.state.discountPickupCabinetEnd)
                : null,
            },
            {
              setting_truck_type_id: '5c4ac1eb9d52c92174bf0b30',
              discount: this.state.discount6WheelsStall
                ? Number(this.state.discount6WheelsStall)
                : null,
              discountend: this.state.discount6WheelsStallEnd
                ? Number(this.state.discount6WheelsStallEnd)
                : null,
            },
            {
              setting_truck_type_id: '5c4ac1eb9d52c92174bf0b31',
              discount: this.state.discount6WheelsCabinet
                ? Number(this.state.discount6WheelsCabinet)
                : null,
              discountend: this.state.discount6WheelsCabinetEnd
                ? Number(this.state.discount6WheelsCabinetEnd)
                : null,
            },
            {
              setting_truck_type_id: '5c4ac1eb9d52c92174bf0b32',
              discount: this.state.discount6WheelsLift
                ? Number(this.state.discount6WheelsLift)
                : null,
              discountend: this.state.discount6WheelsLiftEnd
                ? Number(this.state.discount6WheelsLiftEnd)
                : null,
            },
            {
              setting_truck_type_id: '5c4ac1eb9d52c92174bf0b33',
              discount: this.state.discount6WheelsCrane
                ? Number(this.state.discount6WheelsCrane)
                : null,
              discountend: this.state.discount6WheelsCraneEnd
                ? Number(this.state.discount6WheelsCraneEnd)
                : null,
            },
            {
              setting_truck_type_id: '5cad5a065e962c4d15eb2da1',
              discount: this.state.discount10WheelsStall
                ? Number(this.state.discount10WheelsStall)
                : null,
              discountend: this.state.discount10WheelsStallEnd
                ? Number(this.state.discount10WheelsStallEnd)
                : null,
            },
            {
              setting_truck_type_id: '5cad5a065e962c4d15eb2da2',
              discount: this.state.discount10WheelsCabinet
                ? Number(this.state.discount10WheelsCabinet)
                : null,
              discountend: this.state.discount10WheelsCabinetEnd
                ? Number(this.state.discount10WheelsCabinetEnd)
                : null,
            },
            {
              setting_truck_type_id: '5cad5a065e962c4d15eb2da3',
              discount: this.state.discount10WheelsLift
                ? Number(this.state.discount10WheelsLift)
                : null,
              discountend: this.state.discount10WheelsLiftEnd
                ? Number(this.state.discount10WheelsLiftEnd)
                : null,
            },
            {
              setting_truck_type_id: '5cbe8678723da822892a6411',
              discount: this.state.discountTrailer ? Number(this.state.discountTrailer) : null,
              discountend: this.state.discountTrailerEnd
                ? Number(this.state.discountTrailerEnd)
                : null,
            },
            {
              setting_truck_type_id: '5cbe8678723da822892a6412',
              discount: this.state.discountTrailer20 ? Number(this.state.discountTrailer20) : null,
              discountend: this.state.discountTrailer20End
                ? Number(this.state.discountTrailer20End)
                : null,
            },
            {
              setting_truck_type_id: '5cbe8678723da822892a6413',
              discount: this.state.discountTrailer40 ? Number(this.state.discountTrailer40) : null,
              discountend: this.state.discountTrailer40End
                ? Number(this.state.discountTrailer40End)
                : null,
            },
            {
              setting_truck_type_id: '5cbe8678723da822892a6414',
              discount: this.state.discountTrailer40HQ
                ? Number(this.state.discountTrailer40HQ)
                : null,
              discountend: this.state.discountTrailer40HQEnd
                ? Number(this.state.discountTrailer40HQEnd)
                : null,
            },
          ],
          //upload document
          business_admin_document_1: this.state.document1,
          business_admin_document_2: this.state.document2,
          business_admin_document_3: this.state.document3,

          shipping_from_main_address: this.state.shippingAddressChecked,
          account_info_shipping_address: [
            {
              address: this.state.shippingAddress,
              country: this.state.shippingAddresscountry.value,
              province: this.state.shippingAddressprovince.value,
              district: this.state.shippingAddressdistrict.value,
              subdistrict: this.state.shippingAddresssubdistrict.value,
              postcode: this.state.shippingAddresspostcode,
            },
          ],
          business_pay_round: {
            round_date: this.state.businessPayRound == 1 ? this.state.roundDate : 0,
            after_invoice: this.state.businessPayRound == 2 ? this.state.afterInvoice : 0,
          },
        },
      });
    } else {
      this.addNotification('error', 'กรุณากรอกข้อมูลที่สำคัญให้ครบก่อน');
    }
  }

  handleOpenModalSaveAndApproved() {
    //ข้อมูลบริษัท
    this.setState({ businessNameInvalid: this.state.businessName ? false : true });
    this.setState({ taxIdInvalid: this.state.taxId ? false : true });
    this.setState({ branchInvalid: this.state.branch ? false : true });
    this.setState({ postcodeInvalid: this.state.postcode ? false : true });
    this.setState({ businessAddressInvalid: this.state.businessAddress ? false : true });
    this.setState({ countryInvalid: this.state.country.value ? false : true });
    this.setState({ provinceInvalid: this.state.province.value ? false : true });
    this.setState({ districtInvalid: this.state.district.value ? false : true });
    this.setState({ subDistrictInvalid: this.state.subdistrict.value ? false : true });
    this.setState({ businessTypeInvalid: this.state.businessType.value ? false : true });
    this.setState({ phoneNumberInvalid: this.state.phoneNumber ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({
      emailTagsInvalid: this.state.email
        ? this.checkEmail(this.state.email)
          ? this.state.emailTags.filter(e => e.text === this.state.email).length
            ? 'อีเมลนี้ถูกเพิ่มแล้ว'
            : ''
          : 'รูปแบบอีเมลไม่ถูกต้อง'
        : this.state.emailTags.length
        ? ''
        : 'กรุณากรอกข้อมูล',
    });
    //ข้อมูลการใช้งาน
    this.setState({ companyWillUseInvalid: this.state.companyWillUse ? '' : 'กรุณาเลือกรายการ' });
    this.setState({
      productTypeInvalid: this.state.productType.filter(e => e.checked === true).length
        ? ''
        : 'กรุณาเลือกรายการ',
    });
    this.setState({
      truckTypeInvalid: this.state.truckType.filter(e => e.checked === true).length
        ? ''
        : 'กรุณาเลือกรายการ',
    });
    this.setState({
      productTypeOtherInvalid: this.state.productType.filter(
        e => e.value === 'อื่นๆ' && e.checked === true
      ).length
        ? this.state.productTypeOther
          ? ''
          : 'กรุณากรอกข้อมูล'
        : '',
    });
    let checkProductTypeOther = true;
    if (this.state.productType.filter(e => e.value === 'อื่นๆ' && e.checked === true).length) {
      if (this.state.productTypeOther === '') {
        checkProductTypeOther = false;
      }
    }
    //ข้อมูลการขอเครดิต
    this.setState({ creditLimitInvalid: this.state.creditLimit ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({ creditApprovedInvalid: this.state.creditApproved ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({
      transportationFeeInvalid: this.state.transportationFee ? '' : 'กรุณาเลือกรายการ',
    });
    this.setState({
      businessInvoiceTypeInvalid: this.state.businessInvoiceType ? '' : 'กรุณาเลือกรายการ',
    });
    //การคิดภาษีค่าขนส่ง
    this.setState({ deliveryVATInvalid: this.state.deliveryVAT ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({ deliveryWHTInvalid: this.state.deliveryWHT ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({ serviceVATInvalid: this.state.serviceVAT ? '' : 'กรุณากรอกข้อมูล' });
    this.setState({ serviceWHTInvalid: this.state.serviceWHT ? '' : 'กรุณากรอกข้อมูล' });

    if (
      //ข้อมูลบริษัท
      this.state.businessName &&
      this.state.taxId &&
      this.state.branch &&
      this.state.postcode &&
      this.state.businessAddress &&
      this.state.country.value &&
      this.state.province.value &&
      this.state.district.value &&
      this.state.subdistrict.value &&
      this.state.businessType.value &&
      this.state.phoneNumber &&
      this.state.emailTags.length &&
      !this.state.email &&
      //ข้อมูลการใช้งาน
      this.state.companyWillUse &&
      this.state.productType.filter(e => e.checked === true).length &&
      this.state.truckType.filter(e => e.checked === true).length &&
      checkProductTypeOther &&
      //ข้อมูลการขอเครดิต
      this.state.creditLimit &&
      this.state.creditApproved &&
      this.state.transportationFee &&
      this.state.businessInvoiceType &&
      //การคิดภาษีค่าขนส่ง
      this.state.deliveryVAT &&
      this.state.deliveryWHT &&
      this.state.serviceVAT &&
      this.state.serviceWHT
    ) {
      this.setState({ isOpenModalApprove: true });
    } else {
      this.addNotification('error', 'กรุณากรอกข้อมูลที่สำคัญให้ครบก่อน');
    }
  }

  handleSaveAndApproved(accountBusinessUpdate) {
    accountBusinessUpdate({
      variables: {
        _id: this.state.accountInfoId,
        account_business_id: this.state.businessId,

        //การลงทะเบียนใช้งาน Giztix Business
        account_info_business_name: this.state.businessName,
        account_info_tax_id: this.state.taxId,
        account_info_branch: this.state.branch,
        account_info_postcode: this.state.postcode,
        account_info_address: this.state.businessAddress,
        account_info_country: this.state.country.value,
        account_info_province: this.state.province.value,
        account_info_district: this.state.district.value,
        account_info_subdistrict: this.state.subdistrict.value,
        account_info_corporate_type: this.state.businessType.value,
        account_info_corporate_phone_code: this.state.phoneCode,
        account_info_corporate_phone_number: this.state.phoneNumber,
        account_info_corporate_co_phone_code: this.state.phoneCodeCoordinator,
        account_info_corporate_co_phone_number: this.state.phoneNumberCoordinator,
        account_business_email: this.state.emailTags.map(e => {
          if (e.id) {
            //ถ้ามีค่า id ให้ส่ง id ไปด้วยเพราะจะเป็นการแก้ไข
            return { _id: e.id, email: e.text };
          }
          //ถ้าไม่มี id ให้เพิ่ม email เข้าไปใหม่
          return { email: e.text };
        }),
        account_business_email_delete: this.state.emailTagsDelete.map(e => {
          return { _id: e.id };
        }),
        account_info_note: this.state.note,
        cod_days: this.state.cod_days,
        invite_code: this.state.invite_code,
        recommend_company: this.state.recommend_company,
        account_info_customer_type: this.state.account_info_customer_type ? 4 : 3,
        create_costing_auto: this.state.create_costing_auto,

        //ข้อมูลการใช้งาน
        business_usage_information_1: this.state.companyWillUse,
        business_usage_information_2: this.state.productType
          .filter(e => e.checked === true)
          .map(e => {
            if (e.value === 'อื่นๆ') {
              return this.state.productTypeOther.toString();
            }

            return e.value;
          }),
        business_usage_information_3: this.state.truckType
          .filter(e => e.checked === true)
          .map(e => e.value),

        //ข้อมูลการขอเครดิต
        business_credit_req: this.formatNumber(this.state.creditLimit, true),
        business_credit_approved:
          this.state.creditApproved !== null || this.state.creditApproved !== ''
            ? this.formatNumber(this.state.creditApproved, true)
            : parseInt(this.state.creditApproved),
        business_edocument_receipt: this.state.receiveInvoiceAndReceipt ? 1 : 2,
        business_alway_wht: this.state.transportationFee,
        business_invoice_type: this.state.businessInvoiceType,

        //### ข้อมูลเพิ่มเติม (สำหรับ Admin)
        //การคิดภาษีค่าขนส่ง
        business_admin_delivery_vat: this.state.deliveryVAT,
        business_admin_delivery_wht: this.state.deliveryWHT,
        business_admin_service_vat: this.state.serviceVAT,
        business_admin_service_wht: this.state.serviceWHT,
        //ส่วนลดตามประเภทรถ
        business_admin_benefit_discount: [
          {
            setting_truck_type_id: '5bed280b2a9d8d18e851d7bb',
            discount: this.state.discountPickupStall
              ? Number(this.state.discountPickupStall)
              : null,
            discountend: this.state.discountPickupStallEnd
              ? Number(this.state.discountPickupStallEnd)
              : null,
          },
          {
            setting_truck_type_id: '5be5057787415b16d8cf48f7',
            discount: this.state.discountPickupCabinet
              ? Number(this.state.discountPickupCabinet)
              : null,
            discountend: this.state.discountPickupCabinetEnd
              ? Number(this.state.discountPickupCabinetEnd)
              : null,
          },
          {
            setting_truck_type_id: '5c4ac1eb9d52c92174bf0b30',
            discount: this.state.discount6WheelsStall
              ? Number(this.state.discount6WheelsStall)
              : null,
            discountend: this.state.discount6WheelsStallEnd
              ? Number(this.state.discount6WheelsStallEnd)
              : null,
          },
          {
            setting_truck_type_id: '5c4ac1eb9d52c92174bf0b31',
            discount: this.state.discount6WheelsCabinet
              ? Number(this.state.discount6WheelsCabinet)
              : null,
            discountend: this.state.discount6WheelsCabinetEnd
              ? Number(this.state.discount6WheelsCabinetEnd)
              : null,
          },
          {
            setting_truck_type_id: '5c4ac1eb9d52c92174bf0b32',
            discount: this.state.discount6WheelsLift
              ? Number(this.state.discount6WheelsLift)
              : null,
            discountend: this.state.discount6WheelsLiftEnd
              ? Number(this.state.discount6WheelsLiftEnd)
              : null,
          },
          {
            setting_truck_type_id: '5c4ac1eb9d52c92174bf0b33',
            discount: this.state.discount6WheelsCrane
              ? Number(this.state.discount6WheelsCrane)
              : null,
            discountend: this.state.discount6WheelsCraneEnd
              ? Number(this.state.discount6WheelsCraneEnd)
              : null,
          },
          {
            setting_truck_type_id: '5cad5a065e962c4d15eb2da1',
            discount: this.state.discount10WheelsStall
              ? Number(this.state.discount10WheelsStall)
              : null,
            discountend: this.state.discount10WheelsStallEnd
              ? Number(this.state.discount10WheelsStallEnd)
              : null,
          },
          {
            setting_truck_type_id: '5cad5a065e962c4d15eb2da2',
            discount: this.state.discount10WheelsCabinet
              ? Number(this.state.discount10WheelsCabinet)
              : null,
            discountend: this.state.discount10WheelsCabinetEnd
              ? Number(this.state.discount10WheelsCabinetEnd)
              : null,
          },
          {
            setting_truck_type_id: '5cad5a065e962c4d15eb2da3',
            discount: this.state.discount10WheelsLift
              ? Number(this.state.discount10WheelsLift)
              : null,
            discountend: this.state.discount10WheelsLiftEnd
              ? Number(this.state.discount10WheelsLiftEnd)
              : null,
          },
          {
            setting_truck_type_id: '5cbe8678723da822892a6411',
            discount: this.state.discountTrailer ? Number(this.state.discountTrailer) : null,
            discountend: this.state.discountTrailerEnd
              ? Number(this.state.discountTrailerEnd)
              : null,
          },
          {
            setting_truck_type_id: '5cbe8678723da822892a6412',
            discount: this.state.discountTrailer20 ? Number(this.state.discountTrailer20) : null,
            discountend: this.state.discountTrailer20End
              ? Number(this.state.discountTrailer20End)
              : null,
          },
          {
            setting_truck_type_id: '5cbe8678723da822892a6413',
            discount: this.state.discountTrailer40 ? Number(this.state.discountTrailer40) : null,
            discountend: this.state.discountTrailer40End
              ? Number(this.state.discountTrailer40End)
              : null,
          },
          {
            setting_truck_type_id: '5cbe8678723da822892a6414',
            discount: this.state.discountTrailer40HQ
              ? Number(this.state.discountTrailer40HQ)
              : null,
            discountend: this.state.discountTrailer40HQEnd
              ? Number(this.state.discountTrailer40HQEnd)
              : null,
          },
        ],
        //upload document
        business_admin_document_1: this.state.document1,
        business_admin_document_2: this.state.document2,
        business_admin_document_3: this.state.document3,
        business_status: 2,
      },
    });
  }

  handleNotApproved(accountBusinessUpdate) {
    this.setState({ rejectNoteInvalid: this.state.rejectNote ? '' : 'กรุณากรอกข้อมูล' });

    if (this.state.rejectNote) {
      this.setState({ rejectNote: '' }, () => {
        accountBusinessUpdate({
          variables: {
            _id: this.state.accountInfoId,
            account_business_id: this.state.businessId,

            reject_note: this.state.rejectNote,
            business_status: 4,
          },
        });
      });
    }
  }

  handleSuspend(accountBusinessUpdate) {
    accountBusinessUpdate({
      variables: {
        _id: this.state.accountInfoId,
        account_business_id: this.state.businessId,

        business_status: 3,
      },
    });
  }

  handleCancelSuspend(accountBusinessUpdate) {
    accountBusinessUpdate({
      variables: {
        _id: this.state.accountInfoId,
        account_business_id: this.state.businessId,

        business_status: 2,
      },
    });
  }

  handleAlreadyReceived(accountBusinessUpdate) {
    accountBusinessUpdate({
      variables: {
        _id: this.state.accountInfoId,
        account_business_id: this.state.businessId,

        business_status: 5,
      },
    });
  }

  //credit input format
  formatNumber(number, revers) {
    //ป้องกันข้อมูลว่างแล้วค่าเป็น 0
    if (number === '' || number === null) {
      return number;
    }

    if (revers === true) {
      // format number 1,234,567 to 1000000
      return Number(number.replace(/\D/g, ''));
    }

    // format number 1000000 to 1,234,567
    return Number(number.replace(/\D/g, ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formatCurrency(event, inputRef) {
    // get input value
    var input_val = event.target.value;

    // original length
    var original_len = input_val.length;

    // initial caret position
    var caret_pos = event.target.selectionStart;

    input_val = this.formatNumber(input_val);

    setTimeout(() => {
      // put caret back in the right position
      let updated_len = input_val.length;
      caret_pos = updated_len - original_len + caret_pos;
      inputRef.current.setSelectionRange(caret_pos, caret_pos);
    }, 1);

    return input_val;
  }

  checkPricingConditions = () => {
    // let valueConditon = false
    const dataParcel = !!this.state.account_info_customer_service_type.find(
      serviceType => serviceType === 'PARCEL'
    )
    const editFrom = this.checkEditForm()
    const valueConditon = dataParcel && editFrom ? true : false
    return valueConditon
  }

  handleClickCheckbox = (e) => {
    console.log(e.target.checked);

    this.setState({ [e.target.id]: e.target.checked });
  }

  handleChangeParcel = (e) => {

    const valueCustomerService = e.target
    .checked
    ? [
        ...this.state
          .account_info_customer_service_type,
        'PARCEL',
      ]
    : this.state.account_info_customer_service_type.filter(
        serviceType => serviceType !== 'PARCEL'
      )
    
      const valueWeight = e.target.checked ? this.state.account_pricing_conditions_weight : false
      const valueDimension = e.target.checked ? this.state.account_pricing_conditions_dimension : false

      this.setState({
      account_info_customer_service_type: valueCustomerService,
      account_pricing_conditions_weight: valueWeight,
      account_pricing_conditions_dimension: valueDimension,

    })
  }

  render() {
    if (!this.props.adminListQuery.loading && !this.props.adminListQuery.error) {
      adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
    }
    return (
      <Container>
        <Mutation
          mutation={accountBusinessUpdateMutation}
          update={(cache, { data }) => {
            this.addNotification('success', 'บันทึกสำเร็จแล้ว');
            this.setState({
              isOpenModalApprove: false,
              isOpenModalNotApprove: false,
              isOpenModalSuspend: false,
              isOpenModalCancelSuspend: false,
              isOpenModalAlreadyReceived: false,
            });
          }}
          onError={error => {
            this.addNotification('error', error.graphQLErrors[0].message);
            this.setState({
              isOpenModalApprove: false,
              isOpenModalNotApprove: false,
              isOpenModalSuspend: false,
              isOpenModalCancelSuspend: false,
              isOpenModalAlreadyReceived: false,
            });
          }}
          refetchQueries={['AccountInfoList']}
        >
          {(accountBusinessUpdate, { data, error, loading }) => {
            return (
              <div className="content">
                <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
                <Modal bsSize="lg" show={this.state.isOpenModalApprove}>
                  <Modal.Body>
                    <ModalContent>
                      <div className="header">แจ้งเตือน</div>
                      <div className="content">
                        <div>
                          คุณต้องการ <strong>“อนุมัติ”</strong> คำร้องขอใช้งาน Giztix Business
                          นี้หรือไม่?
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Row>
                            <Col md={4}>ชื่อบริษัท:</Col>
                            <Col md={8}>{this.state.businessName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>หมายเลขคำร้อง:</Col>
                            <Col md={8}>BU{this.state.businessReqNumber}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>วันที่ยื่นคำร้อง:</Col>
                            <Col md={8}>
                              <Moment format="DD/MM/YYYY HH:mm">{this.state.create_date}</Moment>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>วงเงินเครดิตที่อนุมัติ:</Col>
                            <Col md={8}>
                              <strong>
                                {this.state.creditApproved
                                  ? this.formatNumber(this.state.creditApproved, true)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  : '0'}{' '}
                                บาท
                              </strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>การคิดภาษีค่าขนส่ง:</Col>
                            <Col md={8}>
                              <strong>
                                VAT {this.state.deliveryVAT ? this.state.deliveryVAT + '%' : '-'},
                                WHT {this.state.deliveryWHT ? this.state.deliveryWHT + '%' : '-'}
                              </strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>การคิดภาษีค่าบริการ:</Col>
                            <Col md={8}>
                              <strong>
                                VAT {this.state.serviceVAT ? this.state.serviceVAT + '%' : '-'}, WHT{' '}
                                {this.state.serviceWHT ? this.state.serviceWHT + '%' : '-'}
                              </strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>ส่วนลดตามประเภทรถ:</Col>
                            <Col md={8}>
                              <strong>
                                4 ล้อตู้คอก{' '}
                                {this.state.discountPickupStall
                                  ? this.state.discountPickupStall + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discountPickupStallEnd
                                  ? this.state.discountPickupStallEnd + '%'
                                  : '-'}
                                , 4 ล้อตู้ทึบ{' '}
                                {this.state.discountPickupCabinet
                                  ? this.state.discountPickupCabinet + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discountPickupCabinetEnd
                                  ? this.state.discountPickupCabinetEnd + '%'
                                  : '-'}
                                , <br />6 ล้อตู้คอก{' '}
                                {this.state.discount6WheelsStall
                                  ? this.state.discount6WheelsStall + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount6WheelsStallEnd
                                  ? this.state.discount6WheelsStallEnd + '%'
                                  : '-'}
                                , 6 ล้อตู้ทึบ{' '}
                                {this.state.discount6WheelsCabinet
                                  ? this.state.discount6WheelsCabinet + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount6WheelsCabinetEnd
                                  ? this.state.discount6WheelsCabinetEnd + '%'
                                  : '-'}
                                , 6 ล้อตู้ทึบมีลิฟท์{' '}
                                {this.state.discount6WheelsLift
                                  ? this.state.discount6WheelsLift + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount6WheelsLiftEnd
                                  ? this.state.discount6WheelsLiftEnd + '%'
                                  : '-'}
                                , 6 ล้อติดเครน{' '}
                                {this.state.discount6WheelsCrane
                                  ? this.state.discount6WheelsCrane + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount6WheelsCraneEnd
                                  ? this.state.discount6WheelsCraneEnd + '%'
                                  : '-'}
                                , <br />
                                10 ล้อตู้คอก{' '}
                                {this.state.discount10WheelsStall
                                  ? this.state.discount10WheelsStall + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount10WheelsStallEnd
                                  ? this.state.discount10WheelsStallEnd + '%'
                                  : '-'}
                                , 10 ล้อตู้ทึบ{' '}
                                {this.state.discount10WheelsCabinet
                                  ? this.state.discount10WheelsCabinet + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount10WheelsCabinetEnd
                                  ? this.state.discount10WheelsCabinetEnd + '%'
                                  : '-'}
                                , 10 ล้อตู้ทึบมีลิฟท์{' '}
                                {this.state.discount10WheelsLift
                                  ? this.state.discount10WheelsLift + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discount10WheelsLiftEnd
                                  ? this.state.discount10WheelsLiftEnd + '%'
                                  : '-'}
                                , <br />
                                รถหัวลาก พื้นเรียบ{' '}
                                {this.state.discountTrailer
                                  ? this.state.discountTrailer + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discountTrailerEnd
                                  ? this.state.discountTrailerEnd + '%'
                                  : '-'}{' '}
                                ,<br />
                                รถหัวลาก ตู้ 20"GP{' '}
                                {this.state.discountTrailer20
                                  ? this.state.discountTrailer20 + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discountTrailer20End
                                  ? this.state.discountTrailer20End + '%'
                                  : '-'}{' '}
                                ,<br />
                                รถหัวลาก ตู้ 40"GP{' '}
                                {this.state.discountTrailer40
                                  ? this.state.discountTrailer40 + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discountTrailer40End
                                  ? this.state.discountTrailer40End + '%'
                                  : '-'}{' '}
                                ,<br />
                                รถหัวลาก ตู้ 40"HQ{' '}
                                {this.state.discountTrailer40HQ
                                  ? this.state.discountTrailer40HQ + '%'
                                  : '-'}{' '}
                                |{' '}
                                {this.state.discountTrailer40HQEnd
                                  ? this.state.discountTrailer40HQEnd + '%'
                                  : '-'}
                              </strong>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="footer">
                        <button
                          className="btn btn-grey"
                          onClick={() => this.setState({ isOpenModalApprove: false })}
                        >
                          ยกเลิก
                        </button>{' '}
                        <button
                          className="btn btn-green"
                          onClick={() => this.handleSaveAndApproved(accountBusinessUpdate)}
                        >
                          อนุมัติ
                        </button>
                      </div>
                    </ModalContent>
                  </Modal.Body>
                </Modal>

                <Modal bsSize="lg" show={this.state.isOpenModalNotApprove}>
                  <Modal.Body>
                    <ModalContent>
                      <div className="header">แจ้งเตือน</div>
                      <div className="content">
                        <div>
                          คุณต้องการ <strong>“ปฏิเสธ”</strong> คำร้องขอใช้งาน Giztix Business
                          นี้หรือไม่?
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Row>
                            <Col md={4}>ชื่อบริษัท:</Col>
                            <Col md={8}>{this.state.businessName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>หมายเลขคำร้อง:</Col>
                            <Col md={8}>BU{this.state.businessReqNumber}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>วงเงินเครดิตที่อนุมัติ:</Col>
                            <Col md={8}>
                              <strong>
                                {this.state.creditApproved
                                  ? this.formatNumber(this.state.creditApproved, true)
                                      .toFixed(2)
                                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                  : '0'}{' '}
                                บาท
                              </strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    rejectNote: event.target.value,
                                    rejectNoteInvalid: '',
                                  })
                                }
                                value={this.state.rejectNote}
                                style={{ marginTop: 10 }}
                                inputLabel="เหตุผลในการปฎิเสธ"
                                placeholder="ระบุ"
                                required
                                messageError={this.state.rejectNoteInvalid}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="footer">
                        <button
                          className="btn btn-grey"
                          onClick={() =>
                            this.setState({
                              isOpenModalNotApprove: false,
                              rejectNote: '',
                              rejectNoteInvalid: '',
                            })
                          }
                        >
                          ยกเลิก
                        </button>{' '}
                        <button
                          className="btn btn-red"
                          onClick={() => this.handleNotApproved(accountBusinessUpdate)}
                        >
                          ไม่อนุมัติ
                        </button>
                      </div>
                    </ModalContent>
                  </Modal.Body>
                </Modal>

                <Modal bsSize="lg" show={this.state.isOpenModalSuspend}>
                  <Modal.Body>
                    <ModalContent>
                      <div className="header">แจ้งเตือน</div>
                      <div className="content">
                        <div>
                          คุณต้องการ <strong>“ระงับการใช้งาน”</strong> Giztix Business
                          หมายเลขคำร้องนี้หรือไม่?
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Row>
                            <Col md={4}>ชื่อบริษัท:</Col>
                            <Col md={8}>{this.state.businessName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>หมายเลขคำร้อง:</Col>
                            <Col md={8}>BU{this.state.businessReqNumber}</Col>
                          </Row>
                        </div>
                      </div>

                      <div className="footer">
                        <button
                          className="btn btn-grey"
                          onClick={() => this.setState({ isOpenModalSuspend: false })}
                        >
                          ยกเลิก
                        </button>{' '}
                        <button
                          className="btn btn-grey"
                          onClick={() => this.handleSuspend(accountBusinessUpdate)}
                        >
                          ระงับการใช้งาน
                        </button>
                      </div>
                    </ModalContent>
                  </Modal.Body>
                </Modal>

                <Modal bsSize="lg" show={this.state.isOpenModalCancelSuspend}>
                  <Modal.Body>
                    <ModalContent>
                      <div className="header">แจ้งเตือน</div>
                      <div className="content">
                        <div>
                          คุณต้องการ <strong>“ยกเลิกการระงับ”</strong> Giztix Business
                          หมายเลขคำร้องนี้หรือไม่?
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Row>
                            <Col md={4}>ชื่อบริษัท:</Col>
                            <Col md={8}>{this.state.businessName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>หมายเลขคำร้อง:</Col>
                            <Col md={8}>BU{this.state.businessReqNumber}</Col>
                          </Row>
                        </div>
                      </div>

                      <div className="footer">
                        <button
                          className="btn btn-grey"
                          onClick={() => this.setState({ isOpenModalCancelSuspend: false })}
                        >
                          ยกเลิก
                        </button>{' '}
                        <button
                          className="btn btn-grey"
                          onClick={() => this.handleCancelSuspend(accountBusinessUpdate)}
                        >
                          ตกลง
                        </button>
                      </div>
                    </ModalContent>
                  </Modal.Body>
                </Modal>

                <Modal bsSize="lg" show={this.state.isOpenModalAlreadyReceived}>
                  <Modal.Body>
                    <ModalContent>
                      <div className="header">แจ้งเตือน</div>
                      <div className="content">
                        <div>
                          คุณต้องการ <strong>“รับเรื่องแล้ว”</strong> Giztix Business
                          หมายเลขคำร้องนี้หรือไม่?
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Row>
                            <Col md={4}>ชื่อบริษัท:</Col>
                            <Col md={8}>{this.state.businessName}</Col>
                          </Row>
                          <Row>
                            <Col md={4}>หมายเลขคำร้อง:</Col>
                            <Col md={8}>BU{this.state.businessReqNumber}</Col>
                          </Row>
                        </div>
                      </div>

                      <div className="footer">
                        <button
                          className="btn btn-grey"
                          onClick={() => this.setState({ isOpenModalAlreadyReceived: false })}
                        >
                          ยกเลิก
                        </button>{' '}
                        <button
                          className="btn btn-grey"
                          onClick={() => this.handleAlreadyReceived(accountBusinessUpdate)}
                        >
                          ตกลง
                        </button>
                      </div>
                    </ModalContent>
                  </Modal.Body>
                </Modal>

                <HeaderContainer>
                  <div className="sub-header-left">
                    <a href="/giztixbusiness" className="btn-back">
                      <i className="far fa-arrow-left"></i> กลับ
                    </a>
                    <div className="line"></div>
                    <div className="menu-wrapper">
                      <ul>
                        <li className="active">
                          <a
                            href={`/giztixbusinessdetail?id=${
                              window.location.search.split('?id=')[1]
                            }`}
                          >
                            {' '}
                            <i className="fal fa-address-card"></i> ข้อมูลการลงทะเบียน
                          </a>
                        </li>
                        <li>
                          <a
                            href={`/giztixbusinessshipment?id=${
                              window.location.search.split('?id=')[1]
                            }`}
                          >
                            {' '}
                            <i className="fal fa-truck"></i> รายการขนส่งของบริษัท
                          </a>
                        </li>
                        <li>
                          <a
                            href={`/giztixbusinesspayment?id=${
                              window.location.search.split('?id=')[1]
                            }`}
                          >
                            {' '}
                            <i className="fal fa-money-check-alt"></i> ค่าใช้จ่ายของบริษัท
                          </a>
                        </li>
                        <li>
                          <a
                            href={`/giztixbusinesstrackingsetting?id=${
                              window.location.search.split('?id=')[1]
                            }`}
                          >
                            {' '}
                            <i className="fal fa-box-heart"></i> จัดการหน้าติดตามงานขนส่ง
                          </a>
                        </li>
                        <li>
                          <a
                            href={`/giztixbusinessdriver?id=${
                              window.location.search.split('?id=')[1]
                            }`}
                          >
                            {' '}
                            <i className="fal fa-user-hard-hat"></i> พนักงานขับรถคนโปรด
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </HeaderContainer>
                <SubHeaderContainer>
                  <div className="sub-header-left">
                    <strong>
                      BU{this.state.businessReqNumber} : {this.state.businessName}
                    </strong>
                  </div>
                  <div className="sub-header-right">
                    {this.state.businessStatus === 1 ? (
                      <button
                        className="btn btn-grey"
                        onClick={() => this.setState({ isOpenModalAlreadyReceived: true })}
                      >
                        รับเรื่องแล้ว
                      </button>
                    ) : null}

                    {this.state.businessStatus === 3 ? (
                      <button
                        className="btn btn-grey"
                        onClick={() => this.setState({ isOpenModalCancelSuspend: true })}
                      >
                        ยกเลิกการระงับ
                      </button>
                    ) : null}

                    {this.state.businessStatus === 2 ? (
                      <button
                        className="btn btn-grey"
                        onClick={() => this.setState({ isOpenModalSuspend: true })}
                      >
                        ระงับการใช้งานชั่วคราว
                      </button>
                    ) : null}

                    {this.state.businessStatus !== 4 ? (
                      <button
                        className="btn btn-red"
                        onClick={() => this.setState({ isOpenModalNotApprove: true })}
                      >
                        ไม่อนุมัติ
                      </button>
                    ) : null}

                    <button
                      className="btn btn-blue"
                      onClick={() => this.handleSave(accountBusinessUpdate)}
                    >
                      บันทึก
                    </button>

                    {this.state.businessStatus === 1 ||
                    this.state.businessStatus === 4 ||
                    this.state.businessStatus === 5 ? (
                      <button
                        className="btn btn-green"
                        onClick={() => this.handleOpenModalSaveAndApproved()}
                      >
                        บันทึกและอนุมัติ
                      </button>
                    ) : null}
                  </div>
                </SubHeaderContainer>
                <Grid fluid>
                  <Row>
                    <Col md={9}>
                      {!this.props.accountInfoListQuery.loading ? (
                        <div style={{ marginBottom: 30 }}>
                          <Card>
                            <div>
                              <TextHeader>การลงทะเบียนใช้งาน Giztix Business</TextHeader>
                              <TextSubHeader>
                                ระบุข้อมูลของบริษัทเพื่อการจองขนส่งสำหรับองค์กร/ธุรกิจ
                              </TextSubHeader>

                              <div style={{ marginTop: 20 }}>
                                <div>
                                  <Row>
                                    <Col md={6} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            businessName: event.target.value,
                                            businessNameInvalid: false,
                                          })
                                        }
                                        value={this.state.businessName}
                                        style={{ marginTop: 10 }}
                                        inputLabel="ชื่อบริษัท"
                                        placeholder="ชื่อบริษัทของคุณ"
                                        required
                                        messageError={
                                          this.state.businessNameInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>

                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        disabled={!this.checkEditForm()}
                                        onChange={event => this.changeBusinessType(event)}
                                        value={this.state.businessType}
                                        style={{ marginTop: 10 }}
                                        options={selectOptionTypeBusiness}
                                        inputLabel="ประเภทธุรกิจ"
                                        required
                                        messageError={
                                          this.state.businessTypeInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            taxId: event.target.value,
                                            taxIdInvalid: false,
                                          })
                                        }
                                        value={this.state.taxId}
                                        style={{ marginTop: 10 }}
                                        inputLabel="เลขประจำตัวผู้เสียภาษีอากร"
                                        placeholder="0-0000-00000-0000"
                                        required
                                        messageError={this.state.taxIdInvalid && 'กรุณากรอกข้อมูล'}
                                      />
                                    </Col>
                                    <Col md={6} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            businessAddress: event.target.value,
                                            businessAddressInvalid: false,
                                          })
                                        }
                                        value={this.state.businessAddress}
                                        style={{ marginTop: 10 }}
                                        inputLabel="ที่อยู่บริษัท"
                                        placeholder="123/3"
                                        required
                                        messageError={
                                          this.state.businessAddressInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            branch: event.target.value,
                                            branchInvalid: false,
                                          })
                                        }
                                        value={this.state.branch}
                                        style={{ marginTop: 10 }}
                                        inputLabel="สาขา"
                                        placeholder="สำนักงานใหญ่"
                                        required
                                        messageError={this.state.branchnvalid && 'กรุณากรอกข้อมูล'}
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        disabled={!this.checkEditForm()}
                                        onChange={event => this.changeCountry(event)}
                                        value={this.state.country}
                                        style={{ marginTop: 10 }}
                                        options={selectOptionCountry}
                                        inputLabel="ประเทศ"
                                        required
                                        messageError={
                                          this.state.countryInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        onChange={event => this.changeProvince(event)}
                                        value={this.state.province}
                                        style={{ marginTop: 10 }}
                                        options={this.selectOptionProvinceData()}
                                        inputLabel="จังหวัด"
                                        required
                                        disabled={
                                          !this.state.country.value || !this.checkEditForm()
                                        }
                                        messageError={
                                          this.state.provinceInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        onChange={event => this.changeDistrict(event)}
                                        value={this.state.district}
                                        style={{ marginTop: 10 }}
                                        options={this.selectOptionDistrictData()}
                                        inputLabel="เขต/อำเภอ"
                                        required
                                        disabled={
                                          !this.state.province.value || !this.checkEditForm()
                                        }
                                        messageError={
                                          this.state.districtInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        onChange={event => this.changeSubDistrict(event)}
                                        value={this.state.subdistrict}
                                        style={{ marginTop: 10 }}
                                        options={this.selectOptionSubDistrictData()}
                                        inputLabel="แขวง/ตำบล"
                                        required
                                        disabled={
                                          !this.state.district.value || !this.checkEditForm()
                                        }
                                        messageError={
                                          this.state.subdistrictInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            postcode: event.target.value,
                                            postcodeInvalid: false,
                                          })
                                        }
                                        value={this.state.postcode}
                                        style={{ marginTop: 7 }}
                                        inputLabel="รหัสไปรษณีย์"
                                        placeholder="10260"
                                        required
                                        messageError={
                                          this.state.postcodeInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6} sm={12}>
                                      <Label
                                        style={{
                                          marginTop: 10,
                                          color: `${
                                            this.state.phoneNumberInvalid ? '#FF0000' : ''
                                          }`,
                                        }}
                                        required
                                      >
                                        เบอร์โทรศัพท์
                                      </Label>
                                      <InputGroup>
                                        <Select
                                          disabled={!this.checkEditForm()}
                                          style={{
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderRightWidth: 0,
                                            border: `${
                                              this.state.phoneNumberInvalid
                                                ? '1px solid #FF0000'
                                                : ''
                                            }`,
                                          }}
                                          value={this.state.phoneCode}
                                          onChange={event =>
                                            this.setState({ phoneCode: event.target.value })
                                          }
                                        >
                                          <option value="66">+66</option>
                                        </Select>
                                        <Input
                                          disabled={!this.checkEditForm()}
                                          onChange={event =>
                                            this.setState({
                                              phoneNumber: event.target.value,
                                              phoneNumberInvalid: '',
                                            })
                                          }
                                          value={this.state.phoneNumber}
                                          placeholder="0123456789"
                                          style={{ width: '100%' }}
                                          styleInput={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderLeftWidth: 0,
                                          }}
                                          messageError={
                                            this.state.phoneNumberInvalid
                                              ? this.state.phoneNumberInvalid
                                              : ''
                                          }
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col md={6} sm={12}>
                                      <Label style={{ marginTop: 10 }}>
                                        เบอร์เจ้าหน้าที่ประสานงาน
                                      </Label>
                                      <InputGroup>
                                        <Select
                                          disabled={!this.checkEditForm()}
                                          style={{
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderRightWidth: 0,
                                          }}
                                          value={this.state.phoneCodeCoordinator}
                                          onChange={event =>
                                            this.setState({
                                              phoneCodeCoordinator: event.target.value,
                                            })
                                          }
                                        >
                                          <option value="66">+66</option>
                                        </Select>
                                        <Input
                                          disabled={!this.checkEditForm()}
                                          onChange={event =>
                                            this.setState({
                                              phoneNumberCoordinator: event.target.value,
                                            })
                                          }
                                          value={this.state.phoneNumberCoordinator}
                                          placeholder="0123456789"
                                          style={{ width: '100%' }}
                                          styleInput={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                          }}
                                        />
                                      </InputGroup>
                                    </Col>

                                    <Col md={12} sm={12}>
                                      <Label
                                        inline
                                        style={{
                                          marginTop: 10,
                                          marginBottom: 5,
                                          color: `${this.state.emailTagsInvalid ? '#FF0000' : ''}`,
                                        }}
                                        required
                                      >
                                        อีเมล์ เพื่อใช้ในการรับ เอกสารสำคัญ (กด enter
                                        เพื่อเพิ่มอีเมล)
                                      </Label>
                                    </Col>

                                    {this.checkEditForm() === true ? (
                                      <Col sm={12}>
                                        <TagsContainer>
                                          {this.state.emailTags.length
                                            ? this.state.emailTags.map((e, i) => (
                                                <TagsEmail key={i}>
                                                  {e.text}
                                                  <span
                                                    className="delete"
                                                    onClick={() => this.handleDelete(i)}
                                                  >
                                                    {' '}
                                                    <i class="far fa-times"></i>
                                                  </span>
                                                </TagsEmail>
                                              ))
                                            : ''}
                                          <Input
                                            onChange={event => {
                                              this.setState({
                                                email: event.target.value,
                                                emailTagsInvalid: '',
                                              });
                                            }}
                                            onKeyPress={event => {
                                              if (
                                                event.charCode === KeyCodes.enter ||
                                                event.charCode === KeyCodes.comma
                                              ) {
                                                this.handleAddition(event);
                                              }
                                            }}
                                            onBlur={event => {
                                              if (event.target.value) {
                                                this.handleAddition(event);
                                              }
                                            }}
                                            value={this.state.email}
                                            style={{ display: 'inline-block' }}
                                            styleInput={{ width: 250 }}
                                            placeholder="user@email.com"
                                            messageError={
                                              this.state.emailTagsInvalid &&
                                              this.state.emailTagsInvalid
                                            }
                                          />
                                        </TagsContainer>
                                      </Col>
                                    ) : (
                                      <Col sm={12}>
                                        <TagsContainer>
                                          {this.state.emailTags.length
                                            ? this.state.emailTags.map(e => (
                                                <TagsEmail key={e.text}>{e.text}</TagsEmail>
                                              ))
                                            : null}
                                        </TagsContainer>
                                      </Col>
                                    )}

                                    <Col md={3} sm={12}>
                                      <div style={{ marginTop: 10 }}>
                                        <Input
                                          styleInput={{ marginTop: 6 }}
                                          disabled={
                                            !(
                                              adminName == 'palmolive' ||
                                              adminName == 'sarawut@giztix.com' ||
                                              adminName == 'thames' ||
                                              adminName == 'CEO' ||
                                              adminName == 'soranut@giztix.com' ||
                                              adminName == 'werawat@giztix.com' ||
                                              adminName == 'pang' ||
                                              adminName == 'ann'
                                            )
                                          }
                                          onChange={event =>
                                            this.setState({ invite_code: event.target.value })
                                          }
                                          value={this.state.invite_code}
                                          style={{ marginTop: 10 }}
                                          inputLabel="INVITE CODE"
                                          placeholder="ระบุ"
                                        />
                                      </div>
                                    </Col>

                                    <Col sm={9}>
                                      <div style={{ marginTop: 10 }}>
                                        <Input
                                          styleInput={{ marginTop: 6 }}
                                          disabled={!this.checkEditForm()}
                                          onChange={event =>
                                            this.setState({ note: event.target.value })
                                          }
                                          value={this.state.note}
                                          style={{ marginTop: 10 }}
                                          inputLabel="หมายเหตุ"
                                          placeholder="ระบุ"
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={3}>
                                      <div style={{ marginTop: 10 }}>
                                        <Label>บริษัทที่แนะนำ</Label>
                                        <InputGroup style={{ marginTop: 6 }}>
                                          <Input
                                            type="text"
                                            disabled={
                                              !(
                                                adminName == 'palmolive' ||
                                                adminName == 'sarawut@giztix.com' ||
                                                adminName == 'thames' ||
                                                adminName == 'CEO' ||
                                                adminName == 'soranut@giztix.com' ||
                                                adminName == 'werawat@giztix.com'
                                              )
                                            }
                                            value={this.state.recommend_company}
                                            onChange={event =>
                                              this.setState({
                                                recommend_company: event.target.value,
                                              })
                                            }
                                            placeholder="บริษัทที่แนะนำ"
                                          />
                                        </InputGroup>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={3}>
                                      <div style={{ marginTop: 10 }}>
                                        <Label>จำนวนวันโอน Cod หลังงานเสร็จ</Label>
                                        <InputGroup style={{ marginTop: 6 }}>
                                          <Input
                                            pattern="^-?[0-9]\d*\.?\d*$"
                                            disabled={!this.checkEditForm()}
                                            type="text"
                                            value={this.state.cod_days}
                                            onChange={event => this.setFormatNumber(event)}
                                            style={{ width: '100%' }}
                                            placeholder="0"
                                          />
                                          <TextRight>วัน</TextRight>
                                        </InputGroup>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col md={3}>
                                      <div style={{ marginTop: 10 }}>
                                        <Label>Parcel Customer</Label>
                                        <Row>
                                          <Col md={12}>
                                            <CustomCheckbox
                                              disabled={!this.checkEditForm()}
                                              checked={
                                                !!this.state.account_info_customer_service_type.find(
                                                  serviceType => serviceType === 'PARCEL'
                                                )
                                              }
                                              // onChange={event =>
                                              //   this.setState({
                                              //     account_info_customer_service_type: event.target
                                              //       .checked
                                              //       ? [
                                              //           ...this.state
                                              //             .account_info_customer_service_type,
                                              //           'PARCEL',
                                              //         ]
                                              //       : this.state.account_info_customer_service_type.filter(
                                              //           serviceType => serviceType !== 'PARCEL'
                                              //         ),
                                              //         account_pricing_conditions_weight:event.target.checked ? true:false,
                                              //         account_pricing_conditions_dimension:event.target.checked? this.state.account_pricing_conditions_dimension:false
                                              //   })
                                              // }
                                              onClick={this.handleChangeParcel}
                                              inline
                                            >
                                              <span className="checkmark"></span>{' '}
                                              <span
                                                className="label label-primary"
                                                style={{ backgroundColor: '#D8554F' }}
                                              >
                                                <i class="fal fa-box" />
                                              </span>{' '}
                                              Parcel customer
                                            </CustomCheckbox>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div style={{ marginTop: 10 }}>
                                        <Label>Key Account</Label>
                                        <Row>
                                          <Col md={12}>
                                            <CustomCheckbox
                                              disabled={!this.checkEditForm()}
                                              checked={this.state.account_info_customer_type}
                                              onChange={event =>
                                                this.setState({
                                                  account_info_customer_type: event.target.checked,
                                                })
                                              }
                                              inline
                                            >
                                              <span className="checkmark"></span>{' '}
                                              <span className="label label-primary">
                                                <i class="fal fa-gem" />
                                              </span>{' '}
                                              Key Account
                                            </CustomCheckbox>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div style={{ marginTop: 10 }}>
                                        <Label>Auto Costing</Label>
                                        <Row>
                                          <Col md={12}>
                                            <CustomCheckbox
                                              disabled={!this.checkEditForm()}
                                              checked={this.state.create_costing_auto}
                                              onChange={event =>
                                                this.setState({
                                                  create_costing_auto: event.target.checked,
                                                })
                                              }
                                              inline
                                            >
                                              <span className="checkmark"></span> On Auto Costing
                                            </CustomCheckbox>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <div style={{ marginTop: 20 }}>
                                        <Label>เงื่อนไขการคิดราคา</Label>
                                        <Row>
                                          <Col md={6}>
                                            <CustomCheckbox
                                              id='account_pricing_conditions_weight'
                                              disabled={!this.checkPricingConditions()}
                                              checked={this.state.account_pricing_conditions_weight
                                              }
                                              onClick={this.handleClickCheckbox}
                                              inline
                                            >
                                              <span className="checkmark"></span>{' '}
                                              {/* <span
                                                className="label label-primary"
                                                style={{ backgroundColor: '#D8554F' }}
                                              >
                                                <i class="fal fa-box" />
                                              </span>{' '} */}
                                              น้ำหนัก
                                            </CustomCheckbox>
                                          </Col>
                                          <Col md={6}>
                                            <CustomCheckbox
                                              id='account_pricing_conditions_dimension'
                                              disabled={!this.checkPricingConditions()}
                                              checked={this.state.account_pricing_conditions_dimension
                                              }
                                              onClick={this.handleClickCheckbox}
                                              inline
                                            >
                                              <span className="checkmark"></span>
                                              {/* <span
                                                className="label label-primary"
                                                style={{ backgroundColor: '#D8554F' }}
                                              >
                                                <i class="fal fa-box" />
                                              </span>{' '} */}
                                              Dimension
                                            </CustomCheckbox>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>

                                <LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />
                                {this.state.payment_bank_account && (
                                  <div>
                                    <Row>
                                      <Col md={3} sm={12}>
                                        <Label>ธนาคาร</Label>
                                        <div>
                                          {this.state.payment_bank_account.bank_name
                                            ? this.state.payment_bank_account.bank_name
                                            : '-'}
                                        </div>
                                      </Col>
                                      <Col md={3} sm={12}>
                                        <Label>ชื่อบัญชีธนาคาร</Label>
                                        <div>
                                          {this.state.payment_bank_account.account_name
                                            ? this.state.payment_bank_account.account_name
                                            : '-'}
                                        </div>
                                      </Col>
                                      <Col md={3} sm={12}>
                                        <Label>เลขที่บัญชีที่ต้องโอน</Label>
                                        <div>
                                          {this.state.payment_bank_account.account_number
                                            ? this.state.payment_bank_account.account_number
                                            : '-'}
                                        </div>
                                      </Col>
                                      <Col md={3} sm={12}>
                                        <Label>สาขาธนาคาร</Label>
                                        <div>
                                          {this.state.payment_bank_account.bank_branch
                                            ? this.state.payment_bank_account.bank_branch
                                            : '-'}
                                        </div>
                                      </Col>
                                    </Row>
                                    <LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />
                                  </div>
                                )}

                                <TextHeader>ที่อยู่สำหรับจัดส่งเอกสาร</TextHeader>

                                <div>
                                  <Row>
                                    <Col md={12}>
                                      <CustomCheckbox
                                        inline
                                        value="shippingAddress"
                                        checked={this.state.shippingAddressChecked}
                                        onClick={event => this.handleSelectShippingAddress(event)}
                                      >
                                        <span className="checkmark"></span>{' '}
                                        ใช้ที่อยู่เดียวกันกับที่อยู่บริษัท
                                      </CustomCheckbox>
                                    </Col>
                                  </Row>
                                  <Row style={{ marginTop: 10 }}>
                                    <Col md={9} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            shippingAddress: event.target.value,
                                            shippingAddressAddressInvalid: false,
                                          })
                                        }
                                        value={this.state.shippingAddress}
                                        style={{ marginTop: 10 }}
                                        inputLabel="ที่อยู่บริษัท"
                                        placeholder="123/3"
                                        required
                                        messageError={
                                          this.state.shippingAddressInvalid && 'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.changeCountry(event, 'shippingAddress')
                                        }
                                        value={this.state.shippingAddresscountry}
                                        style={{ marginTop: 10 }}
                                        options={selectOptionCountry}
                                        inputLabel="ประเทศ"
                                        required
                                        messageError={
                                          this.state.shippingAddresscountryInvalid &&
                                          'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        onChange={event =>
                                          this.changeProvince(event, 'shippingAddress')
                                        }
                                        value={this.state.shippingAddressprovince}
                                        style={{ marginTop: 10 }}
                                        options={this.selectOptionProvinceData('shippingAddress')}
                                        inputLabel="จังหวัด"
                                        required
                                        disabled={
                                          !this.state.shippingAddresscountry.value ||
                                          !this.checkEditForm()
                                        }
                                        messageError={
                                          this.state.shippingAddressprovinceInvalid &&
                                          'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        onChange={event =>
                                          this.changeDistrict(event, 'shippingAddress')
                                        }
                                        value={this.state.shippingAddressdistrict}
                                        style={{ marginTop: 10 }}
                                        options={this.selectOptionDistrictData('shippingAddress')}
                                        inputLabel="เขต/อำเภอ"
                                        required
                                        disabled={
                                          !this.state.shippingAddressprovince.value ||
                                          !this.checkEditForm()
                                        }
                                        messageError={
                                          this.state.shippingAddressdistrictInvalid &&
                                          'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <SelectBox
                                        onChange={event =>
                                          this.changeSubDistrict(event, 'shippingAddress')
                                        }
                                        value={this.state.shippingAddresssubdistrict}
                                        style={{ marginTop: 10 }}
                                        options={this.selectOptionSubDistrictData(
                                          'shippingAddress'
                                        )}
                                        inputLabel="แขวง/ตำบล"
                                        required
                                        disabled={
                                          !this.state.shippingAddressdistrict.value ||
                                          !this.checkEditForm()
                                        }
                                        messageError={
                                          this.state.shippingAddresssubdistrictInvalid &&
                                          'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                    <Col md={3} sm={12}>
                                      <Input
                                        disabled={!this.checkEditForm()}
                                        onChange={event =>
                                          this.setState({
                                            shippingAddresspostcode: event.target.value,
                                            shippingAddresspostcodeInvalid: false,
                                          })
                                        }
                                        value={this.state.shippingAddresspostcode}
                                        style={{ marginTop: 7 }}
                                        inputLabel="รหัสไปรษณีย์"
                                        placeholder="10260"
                                        required
                                        messageError={
                                          this.state.shippingAddresspostcodeInvalid &&
                                          'กรุณากรอกข้อมูล'
                                        }
                                      />
                                    </Col>
                                  </Row>
                                </div>

                                <LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />

                                <TextHeader>ข้อมูลการใช้งาน</TextHeader>
                                <div>
                                  <div style={{ marginTop: 40 }}>
                                    <Label inline required>
                                      1. คาดว่าบริษัทของท่านจะใช้บริการขนส่ง
                                    </Label>{' '}
                                    {/* <small style={{ display: 'block' }}>
                                      {this.state.companyWillUse}
                                    </small>{' '} */}
                                    {this.state.companyWillUseInvalid ? (
                                      <TextMessageError>
                                        {this.state.companyWillUseInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <Row>
                                      <Col md={3}>
                                        <CustomRadio
                                          disabled={!this.checkEditForm()}
                                          value="1 ครั้ง ต่อวัน"
                                          checked={this.state.companyWillUse === '1 ครั้ง ต่อวัน'}
                                          onChange={event =>
                                            this.setState({ companyWillUse: event.target.value })
                                          }
                                          name="radio1"
                                          inline
                                        >
                                          <span className="checkmark"></span> 1 ครั้ง ต่อวัน
                                        </CustomRadio>
                                      </Col>
                                      <Col md={3}>
                                        <CustomRadio
                                          disabled={!this.checkEditForm()}
                                          value="มากกว่า 1 ครั้งต่อวัน"
                                          checked={
                                            this.state.companyWillUse === 'มากกว่า 1 ครั้งต่อวัน'
                                          }
                                          onChange={event =>
                                            this.setState({ companyWillUse: event.target.value })
                                          }
                                          name="radio1"
                                          inline
                                        >
                                          <span className="checkmark"></span> มากกว่า 1 ครั้งต่อวัน
                                        </CustomRadio>
                                      </Col>
                                      <Col md={3}>
                                        <CustomRadio
                                          disabled={!this.checkEditForm()}
                                          value="1 - 5 ครั้ง/สัปดาห์"
                                          checked={
                                            this.state.companyWillUse === '1 - 5 ครั้ง/สัปดาห์' ||
                                            this.state.companyWillUse === '1-5 ครั้ง/สัปดาห์'
                                          }
                                          onChange={event =>
                                            this.setState({ companyWillUse: event.target.value })
                                          }
                                          name="radio1"
                                          inline
                                        >
                                          <span className="checkmark"></span> 1 - 5 ครั้ง/สัปดาห์
                                        </CustomRadio>
                                      </Col>
                                    </Row>
                                  </div>

                                  <div style={{ marginTop: 60 }}>
                                    <Label inline required>
                                      2. ประเภทสินค้าที่ต้องการจัดส่ง (เลือกได้มากกว่า 1)
                                    </Label>{' '}
                                    {this.state.productTypeInvalid ? (
                                      <TextMessageError>
                                        {this.state.productTypeInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <Row>
                                      <Col md={3}>
                                        <CustomCheckbox
                                          disabled={!this.checkEditForm()}
                                          inline
                                          value={this.state.productType[0].value}
                                          checked={this.state.productType[0].checked}
                                          onClick={event => this.handleSelectProductType(event)}
                                        >
                                          <span className="checkmark"></span>{' '}
                                          {this.state.productType[0].value}
                                        </CustomCheckbox>
                                      </Col>
                                      <Col md={3}>
                                        <CustomCheckbox
                                          disabled={!this.checkEditForm()}
                                          inline
                                          value={this.state.productType[1].value}
                                          checked={this.state.productType[1].checked}
                                          onClick={event => this.handleSelectProductType(event)}
                                        >
                                          <span className="checkmark"></span>{' '}
                                          {this.state.productType[1].value}
                                        </CustomCheckbox>
                                      </Col>
                                      <Col md={3}>
                                        <CustomCheckbox
                                          disabled={!this.checkEditForm()}
                                          inline
                                          value={this.state.productType[2].value}
                                          checked={this.state.productType[2].checked}
                                          onClick={event => this.handleSelectProductType(event)}
                                        >
                                          <span className="checkmark"></span>{' '}
                                          {this.state.productType[2].value}
                                        </CustomCheckbox>
                                      </Col>
                                      <Col md={6}>
                                        <CustomCheckbox
                                          disabled={!this.checkEditForm()}
                                          inline
                                          value={this.state.productType[3].value}
                                          checked={this.state.productType[3].checked}
                                          onClick={event => this.handleSelectProductType(event)}
                                        >
                                          <span className="checkmark"></span>{' '}
                                          {this.state.productType[3].value}
                                        </CustomCheckbox>
                                        <Input
                                          disabled={!this.checkEditForm()}
                                          styleInput={{
                                            border: `${
                                              this.state.productTypeOtherInvalid
                                                ? '1px solid #FF0000'
                                                : ''
                                            }`,
                                          }}
                                          style={{
                                            marginLeft: 30,
                                            marginTop: 20,
                                            width: 135,
                                            display: 'inline-block',
                                          }}
                                          value={this.state.productTypeOther}
                                          onChange={event =>
                                            this.setState({ productTypeOther: event.target.value })
                                          }
                                          placeholder="ระบุ"
                                        />
                                      </Col>
                                    </Row>
                                  </div>

                                  <div style={{ marginTop: 60 }}>
                                    <Label inline required>
                                      3. ประเภทรถที่ต้องการจัดส่ง (เลือกได้มากกว่า 1)
                                    </Label>{' '}
                                    {this.state.truckTypeInvalid ? (
                                      <TextMessageError>
                                        {this.state.truckTypeInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <Row>
                                      {this.state.truckType.map(e => {
                                        return (
                                          <Col md={3} key={e.value}>
                                            <CustomCheckbox
                                              disabled={!this.checkEditForm()}
                                              inline
                                              value={e.value}
                                              checked={e.checked}
                                              onClick={event => this.handleSelectTruckType(event)}
                                            >
                                              <span className="checkmark"></span>
                                              {e.value}
                                            </CustomCheckbox>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </div>

                                  <LineHorizontal style={{ marginTop: 60, marginBottom: 40 }} />

                                  <TextHeader>ข้อมูลการขอเครดิต </TextHeader>
                                  <div>
                                    <div style={{ marginTop: 40 }}>
                                      <Row>
                                        <Col md={6}>
                                          <Label inline required>
                                            1. วงเงินเครดิตที่ต้องการ
                                          </Label>{' '}
                                          {this.state.creditLimitInvalid ? (
                                            <TextMessageError>
                                              {this.state.creditLimitInvalid}
                                            </TextMessageError>
                                          ) : null}
                                          <InputGroup style={{ marginTop: 6 }}>
                                            <Input
                                              disabled={!this.checkEditForm()}
                                              type="text"
                                              value={this.state.creditLimit}
                                              onChange={event =>
                                                this.setState({
                                                  creditLimit: this.formatCurrency(
                                                    event,
                                                    this._inputCredit
                                                  ),
                                                  creditLimitInvalid: '',
                                                })
                                              }
                                              style={{ width: '100%' }}
                                              styleInput={{
                                                borderBottomRightRadius: 0,
                                                borderTopRightRadius: 0,
                                                border: `${
                                                  this.state.creditLimitInvalid
                                                    ? '1px solid #FF0000'
                                                    : ''
                                                }`,
                                              }}
                                              placeholder="จำนวนเงิน"
                                              inputRef={this._inputCredit}
                                            />
                                            <TextRight
                                              style={{
                                                borderBottomLeftRadius: 0,
                                                borderTopLeftRadius: 0,
                                                borderLeftWidth: 0,
                                                border: `${
                                                  this.state.creditLimitInvalid
                                                    ? '1px solid #FF0000'
                                                    : ''
                                                }`,
                                              }}
                                            >
                                              บาท
                                            </TextRight>
                                          </InputGroup>
                                          <TextContent style={{ color: '#B3B3B3' }}>
                                            *จำนวนวงเงินเครดิตที่คุณระบุไว้อาจไม่ใช่จำนวนจริงที่คุณจะได้รับ
                                            ทั้งนี้จำนวนที่ได้รับเป็นไปตามการพิจารณาจากทาง Giztix
                                            เท่านั้น
                                          </TextContent>
                                        </Col>

                                        <Col md={6}>
                                          <Label inline required>
                                            วงเงินเครดิตที่อนุมัติ
                                          </Label>{' '}
                                          {this.state.creditApprovedInvalid ? (
                                            <TextMessageError>
                                              {this.state.creditApprovedInvalid}
                                            </TextMessageError>
                                          ) : null}
                                          <InputGroup style={{ marginTop: 6 }}>
                                            <Input
                                              disabled={!this.checkEditForm()}
                                              type="text"
                                              value={this.state.creditApproved}
                                              onChange={event =>
                                                this.setState({
                                                  creditApproved: this.formatCurrency(
                                                    event,
                                                    this._inputCreditApproved
                                                  ),
                                                  creditApprovedInvalid: '',
                                                })
                                              }
                                              style={{ width: '100%' }}
                                              styleInput={{
                                                borderBottomRightRadius: 0,
                                                borderTopRightRadius: 0,
                                                border: `${
                                                  this.state.creditApprovedInvalid
                                                    ? '1px solid #FF0000'
                                                    : ''
                                                }`,
                                              }}
                                              placeholder="จำนวนเงิน"
                                              inputRef={this._inputCreditApproved}
                                            />
                                            <TextRight
                                              style={{
                                                borderBottomLeftRadius: 0,
                                                borderTopLeftRadius: 0,
                                                borderLeftWidth: 0,
                                                border: `${
                                                  this.state.creditApprovedInvalid
                                                    ? '1px solid #FF0000'
                                                    : ''
                                                }`,
                                              }}
                                            >
                                              บาท
                                            </TextRight>
                                          </InputGroup>
                                        </Col>
                                      </Row>
                                    </div>

                                    <div style={{ marginTop: 40 }}>
                                      <Label>
                                        2. ช่องทางการรับเอกสารใบแจ้งหนี้และใบเสร็จรับเงิน
                                      </Label>
                                      <Row>
                                        <Col md={12}>
                                          <CustomCheckbox
                                            disabled={!this.checkEditForm()}
                                            checked={this.state.receiveInvoiceAndReceipt}
                                            onChange={event =>
                                              this.setState({
                                                receiveInvoiceAndReceipt: event.target.checked,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span>{' '}
                                            ท่านยอมรับการรับเอกสารผ่านช่องทาง E-Invoice/E-Receipt
                                          </CustomCheckbox>
                                        </Col>
                                      </Row>
                                    </div>

                                    <div style={{ marginTop: 40 }}>
                                      <Label inline required>
                                        3. คุณต้องการทำหัก ณ
                                        ที่จ่ายค่าขนส่งทุกครั้งที่ใช้บริการหรือไม่
                                      </Label>{' '}
                                      {this.state.transportationFeeInvalid ? (
                                        <TextMessageError>
                                          {this.state.transportationFeeInvalid}
                                        </TextMessageError>
                                      ) : null}
                                      <Row>
                                        <Col lg={3} md={4} sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radio2"
                                            value="1"
                                            checked={this.state.transportationFee === '1'}
                                            onClick={event =>
                                              this.setState({
                                                transportationFee: event.target.value,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> ต้องการ
                                          </CustomRadio>
                                        </Col>
                                        <Col lg={3} md={4} sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radio2"
                                            value="2"
                                            checked={this.state.transportationFee === '2'}
                                            onClick={event =>
                                              this.setState({
                                                transportationFee: event.target.value,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> ไม่ต้องการ
                                          </CustomRadio>
                                        </Col>
                                      </Row>
                                    </div>

                                    <div style={{ marginTop: 40 }}>
                                      <Label inline required>
                                        4.  คุณต้องการรับเอกสารใบแจ้งหนี้เมื่อใด
                                      </Label>{' '}
                                      {this.state.businessInvoiceTypeInvalid ? (
                                        <TextMessageError>
                                          {this.state.businessInvoiceTypeInvalid}
                                        </TextMessageError>
                                      ) : null}
                                      <Row>
                                        <Col sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioInvoiceType"
                                            value="1"
                                            checked={this.state.businessInvoiceType === '1'}
                                            onChange={event =>
                                              this.setState({
                                                businessInvoiceType: event.target.value,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1
                                            งาน และรวบวางบิลทุกสิ้นเดือน
                                          </CustomRadio>
                                        </Col>
                                        <Col sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioInvoiceType"
                                            value="2"
                                            checked={this.state.businessInvoiceType === '2'}
                                            onChange={event =>
                                              this.setState({
                                                businessInvoiceType: event.target.value,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1
                                            งาน และวางบิลทุกวันหลังจบงาน
                                          </CustomRadio>
                                        </Col>
                                        <Col sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioInvoiceType"
                                            value="3"
                                            checked={this.state.businessInvoiceType === '3'}
                                            onChange={event =>
                                              this.setState({
                                                businessInvoiceType: event.target.value,
                                                business_invoice_date_range_start5: '',
                                                businessInvoiceDateRangeStart5Invalid: '',
                                                business_invoice_date_range_end5: '',
                                                businessInvoiceDateRangeEnd5Invalid: '',
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> 1 ใบแจ้งหนี้ ต่อ 1
                                            งาน และรวบวางบิลตามวันที่กำหนด (โปรดระบุวัน)
                                            <div style={{ marginTop: 10 }}>
                                              <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                style={{
                                                  display: 'inline-block',
                                                  width: 80,
                                                  marginRight: 5,
                                                  height: 34,
                                                  border: this.state
                                                    .businessInvoiceDateRangeStartInvalid
                                                    ? '1px solid #FF0000'
                                                    : '',
                                                }}
                                                value={this.state.business_invoice_date_range_start}
                                                onChange={event => {
                                                  this.setState({
                                                    business_invoice_date_range_start:
                                                      event.target.value,
                                                    businessInvoiceDateRangeStartInvalid: '',
                                                  });
                                                  if (
                                                    Number(event.target.value) >
                                                    Number(
                                                      this.state.business_invoice_date_range_end
                                                    )
                                                  ) {
                                                    this.setState({
                                                      business_invoice_date_range_end: '',
                                                    });
                                                  }
                                                }}
                                              >
                                                <option value="">วันที่</option>
                                                {Array.from(Array(31), (e, i) => (
                                                  <option key={i} value={i + 1}>
                                                    {i + 1}
                                                  </option>
                                                ))}
                                              </FormControl>
                                              {` ถึง `}
                                              <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                style={{
                                                  display: 'inline-block',
                                                  width: 80,
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                  height: 34,
                                                  border: this.state
                                                    .businessInvoiceDateRangeEndInvalid
                                                    ? '1px solid #FF0000'
                                                    : '',
                                                }}
                                                value={this.state.business_invoice_date_range_end}
                                                onChange={event => {
                                                  this.setState({
                                                    business_invoice_date_range_end:
                                                      event.target.value,
                                                    businessInvoiceDateRangeEndInvalid: '',
                                                  });
                                                  if (
                                                    Number(event.target.value) <
                                                    Number(
                                                      this.state.business_invoice_date_range_start
                                                    )
                                                  ) {
                                                    this.setState({
                                                      business_invoice_date_range_start: '',
                                                    });
                                                  }
                                                }}
                                              >
                                                <option value="">วันที่</option>
                                                {Array.from(Array(31), (e, i) => (
                                                  <option key={i} value={i + 1}>
                                                    {i + 1}
                                                  </option>
                                                ))}
                                              </FormControl>
                                              {` ของทุกเดือน`}
                                            </div>
                                          </CustomRadio>
                                        </Col>
                                        <Col sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioInvoiceType"
                                            value="4"
                                            checked={this.state.businessInvoiceType === '4'}
                                            onChange={event =>
                                              this.setState({
                                                businessInvoiceType: event.target.value,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> 1 ใบแจ้งหนี้
                                            สำหรับทุกงานภายในเดือน และวางบิลทุกสิ้นเดือน
                                          </CustomRadio>
                                        </Col>
                                        <Col sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioInvoiceType"
                                            value="5"
                                            checked={this.state.businessInvoiceType === '5'}
                                            onChange={event =>
                                              this.setState({
                                                businessInvoiceType: event.target.value,
                                                business_invoice_date_range_start: '',
                                                businessInvoiceDateRangeStartInvalid: '',
                                                business_invoice_date_range_end: '',
                                                businessInvoiceDateRangeEndInvalid: '',
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> 1 ใบแจ้งหนี้
                                            สำหรับทุกงานภายในเดือน และวางบิลตามวันที่กำหนด
                                            (โปรดระบุวัน)
                                            <div style={{ marginTop: 10 }}>
                                              <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                style={{
                                                  display: 'inline-block',
                                                  width: 80,
                                                  marginRight: 5,
                                                  height: 34,
                                                  border: this.state
                                                    .businessInvoiceDateRangeStartInvalid
                                                    ? '1px solid #FF0000'
                                                    : '',
                                                }}
                                                value={
                                                  this.state.business_invoice_date_range_start5
                                                }
                                                onChange={event => {
                                                  this.setState({
                                                    business_invoice_date_range_start5:
                                                      event.target.value,
                                                    businessInvoiceDateRangeStart5Invalid: '',
                                                  });
                                                  if (
                                                    Number(event.target.value) >
                                                    Number(
                                                      this.state.business_invoice_date_range_end5
                                                    )
                                                  ) {
                                                    this.setState({
                                                      business_invoice_date_range_end5: '',
                                                    });
                                                  }
                                                }}
                                              >
                                                <option value="">วันที่</option>
                                                {Array.from(Array(28), (e, i) => (
                                                  <option key={i} value={i + 4}>
                                                    {i + 4}
                                                  </option>
                                                ))}
                                              </FormControl>
                                              {` ถึง `}
                                              <FormControl
                                                componentClass="select"
                                                placeholder="select"
                                                style={{
                                                  display: 'inline-block',
                                                  width: 80,
                                                  marginLeft: 5,
                                                  marginRight: 5,
                                                  height: 34,
                                                  border: this.state
                                                    .businessInvoiceDateRangeEnd5Invalid
                                                    ? '1px solid #FF0000'
                                                    : '',
                                                }}
                                                value={this.state.business_invoice_date_range_end5}
                                                onChange={event => {
                                                  this.setState({
                                                    business_invoice_date_range_end5:
                                                      event.target.value,
                                                    businessInvoiceDateRangeEnd5Invalid: '',
                                                  });
                                                  if (
                                                    Number(event.target.value) <
                                                    Number(
                                                      this.state.business_invoice_date_range_start5
                                                    )
                                                  ) {
                                                    this.setState({
                                                      business_invoice_date_range_start5: '',
                                                    });
                                                  }
                                                }}
                                              >
                                                <option value="">วันที่</option>
                                                {Array.from(Array(28), (e, i) => (
                                                  <option key={i} value={i + 4}>
                                                    {i + 4}
                                                  </option>
                                                ))}
                                              </FormControl>
                                              {` ของทุกเดือน`}
                                            </div>
                                          </CustomRadio>
                                        </Col>
                                      </Row>
                                    </div>

                                    <div style={{ marginTop: 40 }}>
                                      <Label inline required>
                                        5.  คุณต้องการชำระเงินค่าบริการเมื่อใด
                                      </Label>{' '}
                                      {this.state.businessPayRoundInvalid ? (
                                        <TextMessageError>
                                          {this.state.businessPayRoundInvalid}
                                        </TextMessageError>
                                      ) : null}
                                      <Row>
                                        <Col lg={3} md={4} sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioBusinessPayRound"
                                            value="1"
                                            checked={this.state.businessPayRound == '1'}
                                            onChange={event =>
                                              this.setState({
                                                businessPayRound: event.target.value,
                                                roundDate: 0,
                                                afterInvoice: 0,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> ทุกวันที่
                                          </CustomRadio>
                                          <div style={{ marginTop: 10, marginLeft: 35 }}>
                                            <FormControl
                                              as="input"
                                              type="number"
                                              min="0"
                                              max="31"
                                              style={{
                                                display: 'inline-block',
                                                width: 80,
                                                marginRight: 5,
                                                height: 34,
                                                border: this.state.roundDateInvalid
                                                  ? '1px solid #FF0000'
                                                  : '',
                                              }}
                                              value={this.state.roundDate}
                                              onChange={event => {
                                                if (event.target.value > 31) {
                                                  this.setState({
                                                    roundDate: 31,
                                                    roundDateInvalid: '',
                                                  });
                                                } else if (event.target.value < 0) {
                                                  this.setState({
                                                    roundDate: 0,
                                                    roundDateInvalid: '',
                                                  });
                                                } else {
                                                  this.setState({
                                                    roundDate: event.target.value,
                                                    roundDateInvalid: '',
                                                  });
                                                }
                                              }}
                                            />
                                            {` ของเดือน `}
                                          </div>
                                        </Col>
                                        <Col lg={3} md={4} sm={12}>
                                          <CustomRadio
                                            disabled={!this.checkEditForm()}
                                            name="radioBusinessPayRound"
                                            value="2"
                                            checked={this.state.businessPayRound == '2'}
                                            onChange={event =>
                                              this.setState({
                                                businessPayRound: event.target.value,
                                                roundDate: 0,
                                                afterInvoice: 0,
                                              })
                                            }
                                            inline
                                          >
                                            <span className="checkmark"></span> หลังจากวันแจ้งหนี้
                                          </CustomRadio>
                                          <div style={{ marginTop: 10, marginLeft: 35 }}>
                                            <FormControl
                                              as="input"
                                              type="number"
                                              min="0"
                                              style={{
                                                display: 'inline-block',
                                                width: 80,
                                                marginRight: 5,
                                                height: 34,
                                                border: this.state.afterInvoiceInvalid
                                                  ? '1px solid #FF0000'
                                                  : '',
                                              }}
                                              value={this.state.afterInvoice}
                                              onChange={event => {
                                                if (event.target.value < 0) {
                                                  this.setState({
                                                    afterInvoic: 0,
                                                    afterInvoiceInvalid: '',
                                                  });
                                                } else {
                                                  this.setState({
                                                    afterInvoice: event.target.value,
                                                    afterInvoiceInvalid: '',
                                                  });
                                                }
                                              }}
                                            />
                                            {` วัน `}
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>

                                  <LineHorizontal style={{ marginTop: 60, marginBottom: 40 }} />

                                  <TextHeader>ข้อมูลเพิ่มเติม (สำหรับ Admin) </TextHeader>
                                  <div style={{ marginTop: 40 }}>
                                    <Row>
                                      <Col md={6}>
                                        <Label required>การคิดภาษีค่าขนส่ง</Label>
                                        <Row style={{ marginTop: 10 }}>
                                          <Col md={6}>
                                            <Label inline style={{ fontWeight: 'normal' }} required>
                                              ภาษีมูลค่าเพิ่ม (VAT)
                                            </Label>{' '}
                                            {this.state.deliveryVATInvalid ? (
                                              <TextMessageError>
                                                {this.state.deliveryVATInvalid}
                                              </TextMessageError>
                                            ) : null}
                                            <InputGroup style={{ marginTop: 6 }}>
                                              <Input
                                                required
                                                disabled={!this.checkEditForm()}
                                                type="number"
                                                value={this.state.deliveryVAT}
                                                onChange={event =>
                                                  this.setState({
                                                    deliveryVAT: event.target.value,
                                                    deliveryVATInvalid: '',
                                                  })
                                                }
                                                style={{ width: '100%' }}
                                                styleInput={{
                                                  borderBottomRightRadius: 0,
                                                  borderTopRightRadius: 0,
                                                  border: `${
                                                    this.state.deliveryVATInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                                placeholder="ระบุ"
                                              />
                                              <TextRight
                                                style={{
                                                  borderBottomLeftRadius: 0,
                                                  borderTopLeftRadius: 0,
                                                  borderLeftWidth: 0,
                                                  border: `${
                                                    this.state.deliveryVATInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                              >
                                                %
                                              </TextRight>
                                            </InputGroup>
                                          </Col>
                                          <Col md={6}>
                                            <Label inline style={{ fontWeight: 'normal' }} required>
                                              หัก ณ ที่ จ่าย (WHT)
                                            </Label>{' '}
                                            {this.state.deliveryWHTInvalid ? (
                                              <TextMessageError>
                                                {this.state.deliveryWHTInvalid}
                                              </TextMessageError>
                                            ) : null}
                                            <InputGroup style={{ marginTop: 6 }}>
                                              <Input
                                                required
                                                disabled={!this.checkEditForm()}
                                                type="number"
                                                value={this.state.deliveryWHT}
                                                onChange={event =>
                                                  this.setState({
                                                    deliveryWHT: event.target.value,
                                                    deliveryWHTInvalid: '',
                                                  })
                                                }
                                                style={{ width: '100%' }}
                                                styleInput={{
                                                  borderBottomRightRadius: 0,
                                                  borderTopRightRadius: 0,
                                                  border: `${
                                                    this.state.deliveryWHTInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                                placeholder="ระบุ"
                                              />
                                              <TextRight
                                                style={{
                                                  borderBottomLeftRadius: 0,
                                                  borderTopLeftRadius: 0,
                                                  borderLeftWidth: 0,
                                                  border: `${
                                                    this.state.deliveryWHTInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                              >
                                                %
                                              </TextRight>
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col md={6}>
                                        <Label required>การคิดภาษีค่าบริการ</Label>
                                        <Row style={{ marginTop: 10 }}>
                                          <Col md={6}>
                                            <Label inline style={{ fontWeight: 'normal' }} required>
                                              ภาษีมูลค่าเพิ่ม (VAT)
                                            </Label>{' '}
                                            {this.state.serviceVATInvalid ? (
                                              <TextMessageError>
                                                {this.state.serviceVATInvalid}
                                              </TextMessageError>
                                            ) : null}
                                            <InputGroup style={{ marginTop: 6 }}>
                                              <Input
                                                required
                                                disabled={!this.checkEditForm()}
                                                type="number"
                                                value={this.state.serviceVAT}
                                                onChange={event =>
                                                  this.setState({
                                                    serviceVAT: event.target.value,
                                                    serviceVATInvalid: '',
                                                  })
                                                }
                                                style={{ width: '100%' }}
                                                styleInput={{
                                                  borderBottomRightRadius: 0,
                                                  borderTopRightRadius: 0,
                                                  border: `${
                                                    this.state.serviceVATInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                                placeholder="ระบุ"
                                              />
                                              <TextRight
                                                style={{
                                                  borderBottomLeftRadius: 0,
                                                  borderTopLeftRadius: 0,
                                                  borderLeftWidth: 0,
                                                  border: `${
                                                    this.state.serviceVATInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                              >
                                                %
                                              </TextRight>
                                            </InputGroup>
                                          </Col>
                                          <Col md={6}>
                                            <Label inline style={{ fontWeight: 'normal' }} required>
                                              หัก ณ ที่ จ่าย (WHT)
                                            </Label>{' '}
                                            {this.state.serviceWHTInvalid ? (
                                              <TextMessageError>
                                                {this.state.serviceWHTInvalid}
                                              </TextMessageError>
                                            ) : null}
                                            <InputGroup style={{ marginTop: 6 }}>
                                              <Input
                                                required
                                                disabled={!this.checkEditForm()}
                                                type="number"
                                                value={this.state.serviceWHT}
                                                onChange={event =>
                                                  this.setState({
                                                    serviceWHT: event.target.value,
                                                    serviceWHTInvalid: '',
                                                  })
                                                }
                                                style={{ width: '100%' }}
                                                styleInput={{
                                                  borderBottomRightRadius: 0,
                                                  borderTopRightRadius: 0,
                                                  border: `${
                                                    this.state.serviceWHTInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                                placeholder="ระบุ"
                                              />
                                              <TextRight
                                                style={{
                                                  borderBottomLeftRadius: 0,
                                                  borderTopLeftRadius: 0,
                                                  borderLeftWidth: 0,
                                                  border: `${
                                                    this.state.serviceWHTInvalid
                                                      ? '1px solid #FF0000'
                                                      : ''
                                                  }`,
                                                }}
                                              >
                                                %
                                              </TextRight>
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col sm={12}>
                                        <Label>เอกสารเพิ่มเติม</Label>
                                        <Row style={{ marginTop: 10 }}>
                                          <Col sm={12}>
                                            <FileContainer>
                                              <div className="file-left">
                                                <div className="file-left-icon">
                                                  {this.state.document1 ? (
                                                    <i className="fas fa-check-circle text-green"></i>
                                                  ) : (
                                                    <i className="fas fa-circle text-grey"></i>
                                                  )}
                                                </div>
                                                <div className="file-left-text">
                                                  (1) หนังสือรับรองบริษัท{' '}
                                                  <span className="text-primary">*</span>
                                                </div>
                                              </div>
                                              {this.state.document1 ? (
                                                <div className="file-right">
                                                  <div className="file-right-filename text-blue">
                                                    <a
                                                      href={s3 + '/' + this.state.document1}
                                                      target="_blank"
                                                    >
                                                      {this.state.document1.split('/')[2]}
                                                    </a>
                                                  </div>
                                                  <div
                                                    className="file-right-delete text-red"
                                                    onClick={() => this.setState({ document1: '' })}
                                                  >
                                                    <i className="far fa-times"></i> ลบ
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="file-right">
                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      this.setState({
                                                        document1: data.singleUpload.path,
                                                      });
                                                    }}
                                                    onError={error => {
                                                      console.log(error);
                                                    }}
                                                  >
                                                    {uploadFile => {
                                                      return (
                                                        <input
                                                          onChange={event =>
                                                            this.handleSingleUploadDocument(
                                                              uploadFile,
                                                              'CertificateOfCompany',
                                                              event
                                                            )
                                                          }
                                                          type="file"
                                                          id="file"
                                                          ref={this._inputDocument1}
                                                          style={{ display: 'none' }}
                                                        />
                                                      );
                                                    }}
                                                  </Mutation>
                                                  <Button
                                                    onClick={() =>
                                                      this._inputDocument1.current.click()
                                                    }
                                                  >
                                                    + อัพโหลด
                                                  </Button>
                                                </div>
                                              )}
                                            </FileContainer>

                                            <FileContainer>
                                              <div className="file-left">
                                                <div className="file-left-icon">
                                                  {this.state.document2 ? (
                                                    <i className="fas fa-check-circle text-green"></i>
                                                  ) : (
                                                    <i className="fas fa-circle text-grey"></i>
                                                  )}
                                                </div>
                                                <div className="file-left-text">
                                                  (2) ภ.พ. 20<span className="text-primary">*</span>
                                                </div>
                                              </div>
                                              {this.state.document2 ? (
                                                <div className="file-right">
                                                  <div className="file-right-filename text-blue">
                                                    <a
                                                      href={s3 + '/' + this.state.document2}
                                                      target="_blank"
                                                    >
                                                      {this.state.document2.split('/')[2]}
                                                    </a>
                                                  </div>
                                                  <div
                                                    className="file-right-delete text-red"
                                                    onClick={() => this.setState({ document2: '' })}
                                                  >
                                                    <i className="far fa-times"></i> ลบ
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="file-right">
                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      this.setState({
                                                        document2: data.singleUpload.path,
                                                      });
                                                    }}
                                                    onError={error => {
                                                      console.log(error);
                                                    }}
                                                  >
                                                    {uploadFile => {
                                                      return (
                                                        <input
                                                          onChange={event =>
                                                            this.handleSingleUploadDocument(
                                                              uploadFile,
                                                              'VAT20',
                                                              event
                                                            )
                                                          }
                                                          type="file"
                                                          id="file"
                                                          ref={this._inputDocument2}
                                                          style={{ display: 'none' }}
                                                        />
                                                      );
                                                    }}
                                                  </Mutation>
                                                  <Button
                                                    onClick={() =>
                                                      this._inputDocument2.current.click()
                                                    }
                                                  >
                                                    + อัพโหลด
                                                  </Button>
                                                </div>
                                              )}
                                            </FileContainer>

                                            <FileContainer>
                                              <div className="file-left">
                                                <div className="file-left-icon">
                                                  {this.state.document3 ? (
                                                    <i className="fas fa-check-circle text-green"></i>
                                                  ) : (
                                                    <i className="fas fa-circle text-grey"></i>
                                                  )}
                                                </div>
                                                <div className="file-left-text">
                                                  (3) บัตรประชาชนกรรมการ
                                                </div>
                                              </div>
                                              {this.state.document3 ? (
                                                <div className="file-right">
                                                  <div className="file-right-filename text-blue">
                                                    <a
                                                      href={s3 + '/' + this.state.document3}
                                                      target="_blank"
                                                    >
                                                      {this.state.document3.split('/')[2]}
                                                    </a>
                                                  </div>
                                                  <div
                                                    className="file-right-delete text-red"
                                                    onClick={() => this.setState({ document3: '' })}
                                                  >
                                                    <i className="far fa-times"></i> ลบ
                                                  </div>
                                                </div>
                                              ) : (
                                                <div className="file-right">
                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      this.setState({
                                                        document3: data.singleUpload.path,
                                                      });
                                                    }}
                                                    onError={error => {
                                                      console.log(error);
                                                    }}
                                                  >
                                                    {uploadFile => {
                                                      return (
                                                        <input
                                                          onChange={event =>
                                                            this.handleSingleUploadDocument(
                                                              uploadFile,
                                                              'IDCard',
                                                              event
                                                            )
                                                          }
                                                          type="file"
                                                          id="file"
                                                          ref={this._inputDocument3}
                                                          style={{ display: 'none' }}
                                                        />
                                                      );
                                                    }}
                                                  </Mutation>
                                                  <Button
                                                    onClick={() =>
                                                      this._inputDocument3.current.click()
                                                    }
                                                  >
                                                    + อัพโหลด
                                                  </Button>
                                                </div>
                                              )}
                                            </FileContainer>
                                          </Col>
                                        </Row>
                                      </Col>

                                      <Col sm={12}>
                                        <div style={{ marginTop: 20 }}>
                                          <Label>ส่วนลดตามประเภทรถ</Label>
                                          <Row style={{ marginTop: 10 }}>
                                            <Col md="12">
                                              <Table>
                                                <thead>
                                                  <tr>
                                                    <th style={{ width: '200px' }}>ประเภทรถ</th>
                                                    <th style={{ width: '150px' }}>
                                                      ส่วนลด 3 เดือนแรก (%)
                                                    </th>
                                                    <th style={{ width: '150px' }}>
                                                      ส่วนลดหลังจาก 3 เดือนแรก(%)
                                                    </th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td colSpan={3} style={{ fontWeight: 'bold' }}>
                                                      รถ 4 ล้อ
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>4 ล้อตู้คอก</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountPickupStall}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountPickupStall:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountPickupStallEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountPickupStallEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>4 ล้อตู้ทึบ</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountPickupCabinet}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountPickupCabinet:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={
                                                            this.state.discountPickupCabinetEnd
                                                          }
                                                          onChange={event =>
                                                            this.setState({
                                                              discountPickupCabinetEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={3} style={{ fontWeight: 'bold' }}>
                                                      รถ 6 ล้อ
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>6 ล้อตู้คอก</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsStall}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsStall:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsStallEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsStallEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>6 ล้อตู้ทึบ</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsCabinet}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsCabinet:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={
                                                            this.state.discount6WheelsCabinetEnd
                                                          }
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsCabinetEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>6 ล้อตู้ทึบมีลิฟท์</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsLift}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsLift:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsLiftEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsLiftEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>6 ล้อติดเครน</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsCrane}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsCrane:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount6WheelsCraneEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount6WheelsCraneEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={3} style={{ fontWeight: 'bold' }}>
                                                      รถ 10 ล้อ
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>10 ล้อตู้คอก</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount10WheelsStall}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount10WheelsStall:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={
                                                            this.state.discount10WheelsStallEnd
                                                          }
                                                          onChange={event =>
                                                            this.setState({
                                                              discount10WheelsStallEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>10 ล้อตู้ทึบ</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount10WheelsCabinet}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount10WheelsCabinet:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={
                                                            this.state.discount10WheelsCabinetEnd
                                                          }
                                                          onChange={event =>
                                                            this.setState({
                                                              discount10WheelsCabinetEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>10 ล้อตู้ทึบมีลิฟท์</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount10WheelsLift}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount10WheelsLift:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discount10WheelsLiftEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discount10WheelsLiftEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={3} style={{ fontWeight: 'bold' }}>
                                                      รถหัวลาก
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>รถหัวลาก พื้นเรียบ</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountTrailer}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer: event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountTrailerEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailerEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>รถหัวลาก 20" GP</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountTrailer20}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer20: event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountTrailer20End}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer20End:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>รถหัวลาก 40" GP</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountTrailer40}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer40: event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          min="0"
                                                          value={this.state.discountTrailer40End}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer40End:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>รถหัวลาก 40" HQ</td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          value={this.state.discountTrailer40HQ}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer40HQ:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                    <td>
                                                      <InputGroup style={{ marginTop: 6 }}>
                                                        <Input
                                                          disabled={!this.checkEditForm()}
                                                          type="number"
                                                          value={this.state.discountTrailer40HQEnd}
                                                          onChange={event =>
                                                            this.setState({
                                                              discountTrailer40HQEnd:
                                                                event.target.value,
                                                            })
                                                          }
                                                          style={{ width: '100%' }}
                                                          styleInput={{
                                                            borderBottomRightRadius: 0,
                                                            borderTopRightRadius: 0,
                                                          }}
                                                          placeholder="ระบุ"
                                                        />
                                                        <TextRight
                                                          style={{
                                                            borderBottomLeftRadius: 0,
                                                            borderTopLeftRadius: 0,
                                                            borderLeftWidth: 0,
                                                          }}
                                                        >
                                                          %
                                                        </TextRight>
                                                      </InputGroup>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </Table>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ) : null}
                    </Col>
                    <Col md={3}>
                      {this.state.businessStatus === 1 || this.state.businessStatus === 5 ? (
                        <StatusContainer>
                          <div className="header">
                            <div className="header-icon">
                              <i className="far fa-ellipsis-h"></i>
                            </div>
                            <div className="header-text">
                              กำลังรอการอนุมัติจากทาง{' '}
                              <span style={{ whiteSpace: 'nowrap' }}>Giztix Business</span>
                            </div>
                          </div>
                          <div className="detail">
                            <div className="detail-list">
                              <div className="detail-list-left">หมายเลขคำร้อง</div>
                              <div className="detail-list-right">
                                BU{this.state.businessReqNumber}
                              </div>
                            </div>
                            <div className="detail-list">
                              <div className="detail-list-left">วันที่ยื่นคำร้อง</div>
                              <div className="detail-list-right">
                                <Moment format="DD/MM/YYYY HH:mm">{this.state.create_date}</Moment>
                              </div>
                            </div>
                            <div className="detail-list">
                              <div className="detail-list-left">สถานะคำร้อง</div>
                              <div className="detail-list-right">รอตรวจสอบ</div>
                            </div>
                          </div>
                        </StatusContainer>
                      ) : null}

                      {this.state.businessStatus === 4 ? (
                        <StatusContainer>
                          <div className="header">
                            <div className="header-icon header-icon-danger">
                              <i className="fal fa-exclamation-triangle"></i>
                            </div>
                            <div className="header-text">
                              <span style={{ whiteSpace: 'nowrap' }}>Giztix Business</span>{' '}
                              ปฏิเสธคำร้องของคุณ
                            </div>
                          </div>
                          <div className="detail">
                            <div className="detail-list">
                              <div className="detail-list-left">หมายเลขคำร้อง</div>
                              <div className="detail-list-right">
                                BU{this.state.businessReqNumber}
                              </div>
                            </div>
                            <div className="detail-list">
                              <div className="detail-list-left">วันที่ยื่นคำร้อง</div>
                              <div className="detail-list-right">
                                <Moment format="DD/MM/YYYY HH:mm">{this.state.create_date}</Moment>
                              </div>
                            </div>
                            <div className="detail-list">
                              <div className="detail-list-left">สถานะคำร้อง</div>
                              <div className="detail-list-right text-danger">ไม่อนุมัติ</div>
                            </div>
                            <div className="detail-list">
                              <div className="detail-list-left">วันที่ปฎิเสธคำร้อง</div>
                              <div className="detail-list-right">
                                <Moment format="DD/MM/YYYY HH:mm">{this.state.cancel_date}</Moment>
                              </div>
                            </div>
                          </div>
                        </StatusContainer>
                      ) : null}

                      {this.state.businessStatus === 2 || this.state.businessStatus === 3 ? (
                        <div>
                          <CreditContainer>
                            <div className="text-medium text-bold text-header">
                              การใช้วงเงินเครดิต
                            </div>
                            <div>ยอดที่ใช้ไป (บาท)</div>
                            <div
                              className={`text-large  text-bold ${
                                this.state.business_credit_approved -
                                  this.state.business_credit_used <=
                                this.state.business_credit_approved / 10
                                  ? 'text-red'
                                  : 'text-blue'
                              }`}
                            >
                              {parseFloat(this.state.business_credit_used)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                            <div className="line"></div>
                            <div>คงเหลือ (บาท)</div>
                            <div
                              className={`text-medium text-bold ${this.state
                                .business_credit_approved -
                                this.state.business_credit_used <=
                                this.state.business_credit_approved / 10 && 'text-red'}`}
                            >
                              {parseFloat(
                                this.state.business_credit_approved -
                                  this.state.business_credit_used
                              )
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                            <div className="line"></div>
                            <div>วงเงินเครดิตทั้งหมด (บาท)</div>
                            <div className="text-medium">
                              {parseFloat(this.state.business_credit_approved)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </CreditContainer>
                          <CreditContainer>
                            <div className="text-medium text-bold text-header">
                              การใช้ cash voucher
                            </div>
                            <div>ยอดที่ใช้ไป (บาท)</div>
                            <div
                              className={`text-large  text-bold ${
                                this.state.business_free_credit -
                                  this.state.business_free_credit_used <=
                                this.state.business_free_credit / 10
                                  ? 'text-red'
                                  : 'text-blue'
                              }`}
                            >
                              {parseFloat(this.state.business_free_credit_used)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                            <div className="line"></div>
                            <div>คงเหลือ (บาท)</div>
                            <div
                              className={`text-medium text-bold ${this.state.business_free_credit -
                                this.state.business_free_credit_used <=
                                this.state.business_free_credit / 10 && 'text-red'}`}
                            >
                              {parseFloat(
                                this.state.business_free_credit -
                                  this.state.business_free_credit_used
                              )
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                            <div className="line"></div>
                            <div>วงเงิน cash voucher ทั้งหมด (บาท)</div>
                            <div className="text-medium">
                              {parseFloat(this.state.business_free_credit)
                                .toFixed(2)
                                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                            </div>
                          </CreditContainer>

                          <StatusContainer>
                            <div className="header">
                              <div className="header-icon header-icon-success">
                                <i className="fas fa-check"></i>
                              </div>
                              <div className="header-text">
                                ลงทะเบียนใช้งาน{' '}
                                <span style={{ whiteSpace: 'nowrap' }}>Giztix Business</span>{' '}
                                สำเร็จแล้ว
                              </div>
                            </div>
                            <div className="detail">
                              <div className="detail-list">
                                <div className="detail-list-left">หมายเลขคำร้อง</div>
                                <div className="detail-list-right">
                                  BU{this.state.businessReqNumber}
                                </div>
                              </div>
                              <div className="detail-list">
                                <div className="detail-list-left">วันที่ยื่นคำร้อง</div>
                                <div className="detail-list-right">
                                  <Moment format="DD/MM/YYYY HH:mm">
                                    {this.state.create_date}
                                  </Moment>
                                </div>
                              </div>
                              <div className="detail-list">
                                <div className="detail-list-left">สถานะคำร้อง</div>
                                {this.state.businessStatus === 2 ? (
                                  <div className="detail-list-right">อนุมัติแล้ว</div>
                                ) : null}
                                {this.state.businessStatus === 3 ? (
                                  <div className="detail-list-right text-danger">
                                    ถูกระงับการใช้งาน
                                  </div>
                                ) : null}
                              </div>
                              <div className="detail-list">
                                <div className="detail-list-left">วันที่อนุมัติ</div>
                                <div className="detail-list-right">
                                  <Moment format="DD/MM/YYYY HH:mm">
                                    {this.state.approved_date}
                                  </Moment>
                                </div>
                              </div>
                            </div>
                          </StatusContainer>
                        </div>
                      ) : null}

                      <StatusContainer style={{ marginTop: 10 }}>
                        <div className="header">
                          <div className="header-text nomargin">ข้อมูลบัญชีผู้ใช้</div>
                        </div>
                        <div className="detail">
                          <div className="detail-list">
                            <div className="detail-list-left"></div>
                            <div className="detail-list-right">
                              {this.state.account_info_customer_type ? (
                                <span>
                                  <span className="label label-primary">
                                    <i class="fal fa-gem" />
                                  </span>{' '}
                                  Key Account{' '}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="detail-list">
                            <div className="detail-list-left">รหัสลูกค้า</div>
                            <div className="detail-list-right">CU{this.state.accountNumber}</div>
                          </div>
                          <div className="detail-list">
                            <div className="detail-list-left">อีเมล</div>
                            <div className="detail-list-right">{this.state.customerEmail}</div>
                          </div>
                          <div className="detail-list">
                            <div className="detail-list-left">เบอร์โทรศัพท์</div>
                            <div className="detail-list-right">
                              ({this.state.customerPhoneCode}) {this.state.customerPhoneNumber}
                            </div>
                          </div>
                          {this.state.inviteCode && (
                            <div className="detail-list">
                              <div className="detail-list-left">INVITE CODE</div>
                              <div className="detail-list-right">{this.state.inviteCode}</div>
                            </div>
                          )}
                        </div>
                      </StatusContainer>
                    </Col>
                  </Row>
                </Grid>
              </div>
            );
          }}
        </Mutation>
      </Container>
    );
  }
}

export default compose(
  graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        _id: props.location.search.split('?id=')[1],
      },
    }),
  }),
  graphql(adminListQuery, {
    name: 'adminListQuery',
  })
)(BusinessDetail);
