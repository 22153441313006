/** @constant
    @type {{LAST_MILE: '1', RETURN_TO_MERCHANT: '2', RETURN: '5', FIRST_MILE: '9', SHUTTLE_HAUL: '10', REJECT_RETURN: '11', DIRECT_SHIP: '12', LINE_HAUL: '13' , TRIP_LOY : '0'}}
*/
export const orderType = {
  LAST_MILE: '1', //LM
  RETURN_TO_MERCHANT: '2', //RM
  RETURN: '5', //RT
  FIRST_MILE: '9', //FM
  SHUTTLE_HAUL: '10', //SH
  REJECT_RETURN: '11', //RR
  DIRECT_SHIP: '12', //DS
  LINE_HAUL: '13', //LH
  BU_FIRST_MILE: '14', // BUFM
  TRIP_LOY: '0',
};

export const FunctionGetOrderTypeWhenTripLoy = input => {
  const res = input?.name?.split('_')[1];
  console.log('TRIP LOY WITH TYPE : ', res);
  if (res === 'LM') {
    return 1;
  } else if (res === 'RM') {
    return 2;
  } else if (res === 'RT') {
    return 5;
  } else if (res === 'FM') {
    return 9;
  } else if (res === 'SH') {
    return 10;
  } else if (res === 'RR') {
    return 11;
  } else if (res === 'DS') {
    return 12;
  } else if (res === 'LH') {
    return 13;
  } else if (res === 'BUFM') {
    return 14;
  } else {
    return 0;
  }
};
