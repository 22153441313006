import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Input } from './../../../../components';
import { Query, Mutation } from 'react-apollo';
import { connect } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import DocumentList from './DocumentList';

// utils
import formatCurrency from './../../../../../../utils/formatCurrency';

// graphql
import paymentOrderList from './../../../../graphql/query/paymentOrderList';
import singleUpload from './../../../../graphql/mutation/singleUpload';
import billingCreatePaymentDocument from './../../../../graphql/mutation/billingCreatePaymentDocument';

import s3Bucket from 'config/s3Bucket';

const ContainerStyled = styled.div`
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;
  padding-bottom: 20px;

  & .subTitle {
    color: #aaaaaa;
  }

  & .filepond--root {
    font-size: 16px !important;
  }

  & .filepond--root {
    height: 76px !important;
  }
`;

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

class PaymentDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.myRef = React.createRef();
  }

  render() {
    return (
      <ContainerStyled>
        <Row>
          {this.props.paymentOrderStatus === 1 && (
            <Col md={12}>
              <div>อัพโหลดเอกสารทั่วไป</div>
              <div className="subTitle">
                (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ marginTop: 10 }}>
              {this.props.paymentOrderStatus === 1 && (
                <Mutation
                  mutation={billingCreatePaymentDocument}
                  refetchQueries={() => ['billingPaymentDocuments']}
                  onError={error => {
                    console.log(error);
                  }}
                >
                  {billingCreatePaymentDocument => {
                    return (
                      <Mutation mutation={singleUpload}>
                        {singleUpload => {
                          return (
                            <>
                              <FilePond
                                ref={this.myRef}
                                maxFiles={1}
                                server={{
                                  process: (fieldName, file, metadata, load, error) => {
                                    singleUpload({
                                      variables: {
                                        file,
                                        bucket: s3Bucket,
                                      },
                                      update: (cache, { data }) => {
                                        const { path } = data.singleUpload;
                                        billingCreatePaymentDocument({
                                          variables: {
                                            payment_id: this.props.paymentOrderId,
                                            document_type: 2, // เอกสารอื่นๆ
                                            document_file: path,
                                          },
                                        });
                                      },
                                    })
                                      .then(() => {
                                        this.myRef.current.removeFile();
                                      })
                                      .catch(err => {
                                        // message.error(err.message);
                                        error.message(err);
                                      });
                                  },
                                }}
                                acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
                                maxFileSize="2MB"
                              />
                            </>
                          );
                        }}
                      </Mutation>
                    );
                  }}
                </Mutation>
              )}
              <DocumentList
                paymentOrderId={this.props.paymentOrderId}
                paymentOrderStatus={this.props.paymentOrderStatus}
              />
            </div>
          </Col>
        </Row>
      </ContainerStyled>
    );
  }
}

export default PaymentDocument;
