import React from "react";
import { InfoWindow } from "react-google-maps";
import styled from "styled-components";

const InfoContainer = styled.div`
  font-family: "db_heavent";

  & .title {
    font-weight: bold;
    font-size:18px;
  }

  & .sub-title {
    color: #808080;
    font-size: 13px;
  }
`;

const InfoWindowTime = props => {
  return (
    <InfoWindow options={{ disableAutoPan: true }}>
      <InfoContainer>
        <div className="title">{props.title}</div>
        <div className="sub-title">{props.subTitle}</div>
        <div className="sub-title">{props.trackingSpeed}</div>
        <div className="sub-title">{props.trackingActivity}</div>
      </InfoContainer>
    </InfoWindow>
  );
};

export default InfoWindowTime;
