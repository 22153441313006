import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const LightBoxPreview = ({
  images = [],
  imageIndex = 0,
  isOpen = false,
  onClose,
  onNext,
  onPrev,
}) => {
  return (
    <>
      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 2000,
            },
          }}
          mainSrc={images[imageIndex]}
          nextSrc={
            images.length > 1
              ? images[(imageIndex + 1) % images.length]
              : undefined
          }
          prevSrc={
            images.length > 1
              ? images[(imageIndex + images.length - 1) % images.length]
              : undefined
          }
          onCloseRequest={() => onClose && onClose()}
          onMoveNextRequest={() =>
            onNext && onNext((imageIndex + 1) % images.length)
          }
          onMovePrevRequest={() =>
            onPrev && onPrev((imageIndex + images.length - 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default LightBoxPreview;
