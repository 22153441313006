import React from "react";
import classes from "./Tabs.module.scss";
import { Link } from "react-router-dom";

const Tabs = props => {
  let menuList = null;

  if (props.menu.length > 0) {
    menuList = (
      <ul>
        {props.menu.map((item, index) => (
          <Link to={item.toUrl}>
            <li
              key={index}
              className={item.active ? classes.active : ""}
              onClick={item.clicked}
            >
              {item.icon && <i className={item.icon}></i>}
              {item.title}
            </li>
          </Link>
        ))}
      </ul>
    );
  }

  return (
    <div className={classes.Tabs}>
      <ul>{menuList}</ul>
    </div>
  );
};

export default Tabs;
