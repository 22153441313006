import React from 'react';
import formatCurrency from 'utils/formatCurrency';
import {
  TEXT_EVENT_REMAINING,
  TEXT_EVENT_TITLE,
  TEXT_EVENT_UNIT,
  TEXT_EVENT_EMPTY,
} from '../../utils/Special.Constant';

const RenderEventContent = eventInfo => {
  const Props = eventInfo.event.extendedProps;
  return (
    <>
      <div
        style={{
          fontSize: '16px',
        }}
      >
        {TEXT_EVENT_TITLE}
      </div>
      <div
        style={{
          backgroundColor: Props.total_tracking
            ? Props.wait_tranfer_price > 0
              ? 'rgb(254 226 226)'
              : 'rgb(220 252 231)'
            : 'rgb(209 213 219)',
          fontSize: '20px',
          width: 'auto',
          maxWidth: '100%',
          padding: '10px 5px',
          borderRadius: 5,
          textAlign: 'end',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        {Props.total_tracking ? (
          <div>
            {Props.total_tracking_wait_tranfer} / {Props.total_tracking} {TEXT_EVENT_UNIT}
          </div>
        ) : (
          <div>{TEXT_EVENT_EMPTY}</div>
        )}
      </div>
      <div
        style={{
          color: Props.wait_tranfer_price > 0 ? 'red' : 'black',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >
        {TEXT_EVENT_REMAINING.replace(
          '[count]',
          `${formatCurrency(Props.wait_tranfer_price || 0)}`
        )}
      </div>
    </>
  );
};

export default RenderEventContent;
