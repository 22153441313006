import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import { Grid, Row, Col, Table, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import otpListQuery from './graphql/query/otpList';

class ChallengeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone: '',
    };
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Query
                query={otpListQuery}
                variables={{
                  phone: this.state.phone,
                }}
              >
                {({ loading, error, data }) => {
                  return (
                    <div>
                      <CardTable
                        title="Get OTP"
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <Table striped hover>
                            <thead>
                              <tr>
                                <th style={{ minWidth: '100px' }}>เบอร์โทรศัพท์</th>
                                <th style={{ minWidth: '100px' }}>OTP</th>
                                <th style={{ minWidth: '100px' }}>Ref</th>
                                <th style={{ minWidth: '100px' }}>Time out</th>
                                <th style={{ minWidth: '100px' }}>Create Date</th>
                              </tr>
                              <tr className="tableFilter">
                                <th>
                                  <FormControl
                                    type="text"
                                    value={this.state.phone}
                                    placeholder="088xxxxxxx"
                                    onChange={event => this.setState({ phone: event.target.value })}
                                    name="phone"
                                  />
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            {this.state.phone && (
                              <tbody>
                                {(loading || error) && (
                                  <tr>
                                    <td colSpan="5" className="textCenter">
                                      {loading ? 'Loading...' : error.message}
                                    </td>
                                  </tr>
                                )}

                                {!loading && !error && data.otpList.length === 0 && (
                                  <tr>
                                    <td colSpan="5" className="textCenter">
                                      ไม่มีข้อมูล
                                    </td>
                                  </tr>
                                )}

                                {!loading &&
                                  !error &&
                                  data.otpList.length > 0 &&
                                  data.otpList.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.phone}</td>
                                      <td>{item.otp}</td>
                                      <td>{item.ref}</td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {item.time_out}
                                        </Moment>
                                      </td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {item.create_date}
                                        </Moment>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            )}
                          </Table>
                        }
                      />
                    </div>
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ChallengeDetail;
