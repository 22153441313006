import gql from 'graphql-tag';

export default gql`
mutation UpdateClearShipmentDriverAccept(
    $shipment_id: ID!
){
    clearShipmentDriverAccept(
        _id:$shipment_id
    ){
        _id
    }
  }
`;
