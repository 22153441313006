import styled from 'styled-components';

export const MenuWrapper = styled.div`
  width: 100%;

  & a,
  a:hover,
  a:focus,
  a:visited {
    color: unset;
    font-size: 18px;
  }

  & ul {
    padding-left: 0;
    margin: 0 0 5px 0;
    display: flex;

    & li {
      list-style: none;
      margin-right: 15px;
      padding: 22px 10px 15px;
      cursor: pointer;

      & a {
        font-size: 18px;
        cursor: pointer;
        & i {
          font-size: 18px;
          color: #000000;
        }
      }
    }
    & li.active {
      border-bottom: 2px solid #d90402;
      color: #d90402;
    }

    & li.disabled {
      pointer-events: none; //This makes it not clickable
      opacity: 0.6; //This grays it out to look disabled
    }
  }
`;
