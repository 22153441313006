import React, { Component } from 'react';
import Moment from 'react-moment';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment/moment.js';

// Component
import styled from 'styled-components';
import {
  GoogleMaps,
  TrackingItem,
  TrackingItemImport,
  TrackingItemExport,
  PDFPreview,
  StarRating,
  ModalConfirm,
  Avatar,
  ShipmentMap,
} from './components';
import Card from 'components/Card/Card.jsx';
import CountDownTimer from './components/CountdownTimer/countdownTimer';
import Input from './components/InputModal';
import TextArea from './components/TextArea';
import SelectBox from './components/SelectBox';

// graphql
import dateNowQuery from './graphql/query/dateNow';
import detailShipment from './graphql/query/detailShipment';
import invoiceListQuery from './graphql/query/invoiceList';
import trackingList from './graphql/query/trackingList';
import accountInfo from './graphql/query/accountInfo';
import shipmentCostList from './graphql/query/shipmentCostList';
import editShipment from './graphql/mutation/editShipment';
import editpickupDate from './graphql/mutation/editpickupDate';
import shipmentUpdate from './graphql/mutation/shipmentUpdate';
import cancelShipment from './graphql/mutation/cancelShipment';
import detailRating from './graphql/query/detailRating';
import summaryInvoiceList from './graphql/query/summaryInvoiceList';

import shipmentSlasList from './graphql/query/shipmentSlasList';
import shipmentSlaList from './graphql/query/shipmentSlaList';
import createShipmentSlaMutation from './graphql/mutation/createShipmentSla';
import updateShipmentSla from './graphql/mutation/updateShipmentSla';
import adminName from './graphql/query/adminName';
import datamasterSlaTitlesList from './graphql/query/datamasterSlaTitlesList';
import datamasterSlaLevelsList from './graphql/query/datamasterSlaLevels';
import shipmentAdminListQuery from './graphql/query/shipmentAdminList';

import NotificationSystem from 'react-notification-system';

import s3 from 'config/s3';

/* Import & Export */
import singleUploadMutation from './graphql/mutation/singleUpload';
import trackingAddTransporterMutation from './graphql/mutation/trackingAddTransporter';
import trackingUpdateImportMutation from './graphql/mutation/trackingUpdateImport';
import trackingUpdateExportMutation from './graphql/mutation/trackingUpdateExport';

/* Aditional paymnet */
import invoiceBusinessListQuery from './graphql/query/invoiceBusinessList';
import expensesCategoryListQuery from './graphql/query/expensesCategoryList';
import invoiceMoreAddMutation from './graphql/mutation/invoiceMoreAdd';
import invoiceMoreUpdateMutation from './graphql/mutation/invoiceMoreUpdate';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { client } from '../..';
import UploadFile from './container/UploadFile';
import TransporterTrackingEdit from './container/TransporterTrackingEdit';
import AssignDriver from './container/AssignDriver';

let diverWorking = false;

let caseTitlelist = [];
let caseLevelList = [];

const selectOptionCaseChanel = [
  { value: 'อีเมล์', label: 'อีเมล์' },
  { value: 'โทรศัพท์', label: 'โทรศัพท์' },
  { value: 'ข้อความทางไลน์', label: 'ข้อความทางไลน์' },
  { value: 'ข้อความทางเฟสบุ๊ค', label: 'ข้อความทางเฟสบุ๊ค' },
  { value: 'Driver App', label: 'Driver App' },
];

const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const ButtonEdit = styled.div`
  display: inline;
  font-size: 19px;
  cursor: pointer;
  margin-left: 15px;
  float: right;
`;

const BlankSpace = styled.div`
  height: 10px;
  width: calc(100% + 40px);
  background-color: #f7f7f8;
  margin-left: -25px;
  margin-right: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #e6e6e6;
`;

const BoxWrapper = styled.div`
  background-color: #f7f7f8;
  margin-right: -20px;
  margin-left: -20px;
  padding: 15px 20px;

  & .inner-wrapper {
    background-color: white;
    width: 100%;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #f3f3f3;
  }
`;

const SubList = styled.div`
  line-height: 1.2;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  padding: 0 0 10px;

  & .title {
    color: #808080 !important;
    flex: 1;
    margin-right: 10px;
  }

  & .detail {
    color: #808080 !important;
    text-align: right;
  }
`;

const RowItem = styled.div`
  color: #000000;
  padding: 0 0 10px;
  font-size: 18px;
  line-height: 1;
  min-height: 30px;

  & small {
    color: #999999;
    cursor: pointer;
  }
  & small.selected {
    color: #d90101;
  }

  &.selected {
    color: #d90101;

    & small {
      color: #d90101;
    }
  }

  & .left {
    display: contents;
    float: left;
  }

  & .ellipsis {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
  }

  & .right {
    display: block;
    float: right;
    text-align: right;
  }

  & .total {
    font-size: 18px;
    color: #d90001;
    font-weight: bold;
  }

  & .total.green {
    color: #19aa19;
  }

  & .total.black {
    color: #000000;
  }
`;

const RowItemList = styled.div`
  padding: 0 15px 10px 0;
  font-size: 18px;
  line-height: 1;
  display: block;
  width: 100%;
  float: left;
  border-bottom: 1px solid #ececec;
  margin-bottom: 10px;

  & .icon-left {
    display: block;
    float: left;
    width: 25px;
    padding: 10px 0;

    & i {
      font-size: 18px;
      color: #000000;
      cursor: pointer;
    }
    &:hover i {
      color: #d90101;
    }
  }

  & .left {
    display: block;
    float: left;
    width: calc(100% - 50px);
    color: #000000;

    & b {
      width: 100%;
      float: left;
      margin-bottom: 5px;
    }
    & small {
      width: 100%;
      float: left;
    }
  }

  & .icon-right {
    width: 25px;
    display: block;
    float: right;
    text-align: right;
    padding: 10px 0;

    & i {
      font-size: 18px;
      color: #bcbcbc;
      cursor: pointer;
    }
  }

  &.selected {
    & .left {
      color: #d90101;
    }
    & .icon-right i {
      color: #d90101;
    }
  }
`;

const AdditionalCostscontainer = styled.div`
  border-bottom: 1px solid #000000;
  padding: 10px 0 5px;

  &:last-child {
    border-bottom: none;
  }
`;

const ShipmentItem = styled.div`
  color: #000000;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  float: left;
  width: 100%;

  & .icon {
    float: left;
    width: 40px;
    padding-top: 5px;
    text-align: center;
  }
  & .icon.address {
    float: left;
    width: 40px;
    padding-top: 5px;
    text-align: center;
  }
  & .icon i {
    font-size: 18px;
  }
  & .detail {
    float: left;
    width: calc(100% - 40px);
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 10px;
  }
  & .detail span {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  & .detail-address {
    border-left: 2px dotted #e3e3e3;
    float: left;
    width: calc(100% - 40px);
    padding-bottom: 10px;
    padding-left: 20px;
    margin-left: 20px;
  }
  & .detail-address span {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  & ::last-child .detail-address {
    border-left: 2px dotted #e3e3e3;
    float: left;
    width: calc(100% - 40px);
    padding-bottom: 10px;
    padding-left: 20px;
    margin-left: 20px;
  }

  & .detail__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &__edit {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      color: #888888;
      cursor: pointer;

      & i {
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }
`;

const ImageProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  & .image-product {
    width: 86px;
    height: 86px;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;
    cursor: pointer;

    & img {
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    margin-right: 10px;
    margin-bottom: 10px;
  }

  & .image-product-hover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000030;
    border-radius: 4px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  & .image-product:hover {
    .image-product-hover {
      display: block;
    }
  }
`;

const TextControlLabel = styled(ControlLabel)`
    width: 100%;
    font-size:18px; !important;
    color: #000000 !important;

    & small {
        color: #888888;
        float: right;
        cursor: pointer;
    }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  & .image {
    width: 86px !important;
    height: 86px !important;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;

    & img {
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    margin-right: 10px;
    margin-bottom: 10px;
  }

  .image-hover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000080;
    border-radius: 4px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  .image:hover {
    .image-hover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
    }
  }

  .btn-hover-image {
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;

    & span {
      margin-bottom: 4px;
    }

    & i {
      margin-right: 5px;
    }
  }
`;

const ReasonReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ReasonReviewList = styled.div`
  padding: 6px 10px;
  border: 1px solid #e3e3e3;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #000000;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #e3e3e3;
  }

  ${props =>
    props.selected &&
    `
		color: #d90101;
		border: 1px solid #d90101;
	`}

  ${props =>
    props.disabled &&
    `
		&:hover {
			background-color: transparent;
			cursor: default;
		}
	`}
`;

const ModalContent = styled.div`

	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
    }

    & .content{
        width: 100%;
        float: left;
        display: block;
        padding-bottom: 25px;
    }
    
    & .detail {
        width : 100%;
        float:left;
        
        & .left {
            width : auto;
            float:left;
        }
        & .right {
            float:left;
        }
	}

	& .footer {
		text-align: right;
		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: -20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const RowItemIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px;

  & .icon {
    font-size: 30px;
    padding-right: 10px;
  }

  & .left {
    flex: 1;
  }

  & .right {
    color: #888888;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & i {
      font-size: 20px;
      margin-left: 5px;
    }
  }

  & .text-bold {
    font-weight: bold;
  }
`;

const ButtonAddImage = styled.div`
  width: 86px;
  height: 86px;
  border: 1px dashed #a5a5a5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  & i {
    color: #d90101;
    font-size: 24px;
    margin-bottom: 6px;
  }
`;

const ButtonEditDetail = styled.button`
  font-size: 13px;
  color: #888888;
  border: none;
  background-color: transparent;
  padding: 0px;
`;

const TableCost = styled(Table)`
  margin-top: 5px;

  & td {
    border-top: none !important;
    text-align: right;
    padding: 5px 8px !important;
  }

  & td:first-child {
    text-align: left;
  }

  & td button,
  & td button:hover,
  & td button:active,
  & td button:focus {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 5px !important;
  }

  & td button:first-child,
  & td button:first-child:hover,
  & td button:first-child:active,
  & td button:first-child:focus {
    padding-left: 0;
  }

  & .border-bottom td {
    border-bottom: 1px solid #dddddd !important;
  }

  & .border-top td {
    border-top: 1px solid #dddddd !important;
  }
`;

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  { value: 'ลูกค้าต้องการใช้บริการเสริม', label: 'ลูกค้าต้องการใช้บริการเสริม' },
  { value: 'เลือกใช้บริการเจ้าอื่น', label: 'เลือกใช้บริการเจ้าอื่น' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const vatList = [
  { value: '0', label: '0' },
  { value: '7', label: '7' },
];

const WhtList = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '3', label: '3' },
  { value: '5', label: '5' },
];

const DriverDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AvatarContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
`;

const FavoriteIcon = styled.div`
  color: #ffffff;
  font-size: 10px;
  background-color: #de0201;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
`;

const ColLeft = styled.div`
  width: 300px;
  border-right: 0px solid #dbdbdb;
  background-color: #ffff;
  // overflow: auto;
`;
const ColRight = styled.div`
    padding 14px;
    width: 100%;
    overflow: auto;
`;

const ColRightOne = styled.div`
    // width :calc(100% - 300px);
    // height: calc(100vh - 320px);
    // margin: 10px;
    padding 14px;
    background-color: #ffff;
    // border-radius: 5px;
`;

const UlTruck = styled.ul`
  padding: 0;
  max-height: calc(100vh - 200px);
  overflow: auto;

  & li {
    position: relative;
    list-style: none;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    color: #c4c4c4;

    &:hover {
      background-color: #fafafa;
    }

    &.active {
      color: unset;
    }
  }

  & i {
    position: absolute;
    right: 5px;
    top: 40px;
    font-size: 12px;
  }
`;

const BoxDashboard = styled(Row)`
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 10px
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #F7F7F8;


    & h4{
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 15px 30px 15px;
  margin-bottom: 5px;
  margin-left: 5px;
  // float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  & p {
    text-align: center;
    top: 10px;
    left: -8px;
    position: relative;
    width: 15px;
    height: 15px;
    margin: 0px;
    color: #fff;
    font-weight: bold;
  }
  & span {
    position: relative;
    top: -5px;
    left: 20px;
  }
`;

const ColRightPopup = styled(Col)`
  padding-left: unset;
  padding-right: 5px;
  text-align: right;
  color: black;
`;

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(document.location.search.substring(1));
    let paramsShipmentId = params.get('shipment');

    this.state = {
      paramsShipmentId: paramsShipmentId,
      viewImageSrc: [],
      photoIndex: 0,
      isOpenLightbox: false,
      pick_up_date: '',
      pick_up_date_show: '',
      openEditPickupdate: false,
      openEditContactModal: false,
      pickup_id: '',
      pickup_contact_name: '',
      pickup_contact_tel: '',
      pickup_remark: '',
      delivery_id: '',
      delivery_contact_name: '',
      delivery_contact_tel: '',
      delivery_remark: '',
      delivery_id2: '',
      delivery_contact_name2: '',
      delivery_contact_tel2: '',
      delivery_remark2: '',
      addressObj: [],
      photoProductIndexLightBox: 0,
      isOpenPhotoProductLightBox: false,
      openEditTransporter: '',
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      license_plate_front: '',
      license_plate_back: '',

      openEditDriver: '',
      openEditDriverMode: 'new',
      openEditContainer: '',
      openEditContainerMode: 'new',
      openReturnContainer: false,
      cont_number: '',
      seal_number: '',
      tare_weight: '',
      cont_picture: [],

      isOpenModalAlertImage: false,
      textAlertModalImage: '',

      openEditDriverExport: '',
      openEditDriverModeExport: 'new',
      openEditContainerExport: '',
      openReturnContainerExport: false,
      OpenEditUploadImageExport: '',
      openDriverAcceptDocumentExport: false,

      show_cancel: false,
      shipmentID: '',
      shipmentNumber: '',
      rejectSelected: null,
      rejectRemark: '',
      rejectNumber: '',
      refundPrice: '',
      rejectDetail: '',
      showRejectDetail: false,
      shipmentGrandTotal: 0,
      Today: '',
      createDate: '',
      pickupDate: '',
      openApprovePaid: false,

      openAdditionalPaymentList: false,
      openAdditionalPayment: false,
      invoiceMoreMode: 'new',
      additionnalCostCategory: { value: null, label: null },
      additionnalCostSubCategory: { value: null, label: null },
      additionnalCostPriceVat: { value: '0', label: '0' },
      additionnalCostPriceWht: { value: '0', label: '0' },
      nvoiceMoreUpdateMode: 'update',
      invoiceMore_itemId: null,
      invoice_id: null,
      invoice_more_id: null,
      additionnalCostQty: 0,
      additionnalCostPrice: 0,
      tax_type: 0,
      disableTpyeDiscount: false,

      isOpenDetailReview: true,
      selectedAdditionalItem: [],
      selectedAdditionalItemMode: null,
      selectedAdditionalItemKey: null,
      showDeleteModal: false,
      DeleteItemName: '',
      DeleteItemPrice: '',

      //shipment edit
      isOpenModalEditPayment: false,
      selectEditPayment: null,
      isOpenModalEditShipmentOtherDetailUserRef: false,
      inputEditShipmentOtherDetailUserRef: '',
      isOpenModalEditShipmentOtherDetailRemark: false,
      inputEditShipmentOtherDetailRemark: '',
      //delete product image (document)
      isOpenModalConfirmDeleteImageDocument: false,
      selectImageDelete: '',
      isOpenLightboxDriverAvatar: false,

      //invoices/receipts
      show_invoicesandreceipts: false,
      inputshipmentInvoices: '',
      inputshipmentReceipts: '',

      // data management //////////////////////////////
      expireDateCheck: null,
      completedDateChecK: null,
      saveCaseCheck: '',
      caseTitleChange: false,
      serviceTimeCase: '',
      animationUpload: false,
      animationUploadSecond: false,
      showProblem: false,
      showConfirmModalCase: false,
      caseLevelDetail: [],
      caseId: '',
      caseIndex: -1,
      caseStatus: 0,
      editable: false,
      attachFile: [],
      attachSecondFile: [],
      secondEditable: false,
      expireDate: '',
      levelUnit: '',
      levelTime: '',
      newCase: true,
      //หัวข้อปัญหา
      caseTitle: { value: '', label: '-- เลือก --' },
      caseTitleId: '',
      caseTitleInvalid: false,

      //ระดับปัญหา
      caseLevel: { value: '', label: '-- เลือก --' },
      caseLevelInvalid: false,
      caseLevelId: '',

      //1.รายละเอียดปัญหา
      caseDetail: '',
      caseDetailInvalid: false,
      //วัน-เวลาในการรับแจ้งปัญหา
      InformRecieveDateTime: '',
      InformRecieveDateTimeShow: '',
      InformRecieveDateTimeInvalid: false,

      //ผู้รับปัญหา
      caseOwner: '',
      caseOwnerInvalid: false,
      //ช่องทางรับปัญหา
      caseRecieveChanel: { value: '', label: '-- เลือก --' },
      caseRecieveChanelInvalid: false,
      //เบอร์โทรศัพท์ผู้แจ้งปัญหา
      phoneInformer: '',
      phoneInformerInvalid: false,
      //อีเมล (ผู้แจ้งปัญหา)
      mailInformer: '',
      mailInformerInvalid: false,

      //2. รายละเอียดการแก้ไขปัญหา
      caseResolveDetail: '',
      caseResolveDetailInvalid: false,
      //วัน-เวลาตอบกลับลูกค้า
      customerReplyDateTime: '',
      customerReplyDateTimeShow: '',
      customerReplyDateTimeInvalid: false,

      //ผู้ตอบกลับปัญหา
      caseAnswer: '',
      caseAnswerInvalid: false,
      //ช่องทางการตอบกลับปัญหา
      caseAnswerChanel: { value: '', label: '-- เลือก --' },
      caseAnswerChanelInvalid: false,

      //Query ShipmentSlas
      shipmentSlas: [],

      //case_status
      sla_max_level: 0,
      level_status: 0,
      sla_completed: null,
      ///////////////////////////////////////////////////

      pickUpDatecase: '-',
      shipperName: '-',
      truckType: '-',
      driverName: '-',
      driverPhone: '-',
      pickUpPoint: '-',
      dropPoint: '-',
      transporterName: '-',
      additionService: [],
    };

    this._inputUploadImage = React.createRef();
    this._inputUploadFile = React.createRef();

    this._inputAttachFileFirstCase = React.createRef();
    this._inputAttachFileSecondCase = React.createRef();
    this.notificationSystem = React.createRef();
  }

  handleDatePickerEdit(event) {
    //console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD HH:mm')}`;
    const show = `${event.format('DD/MM/YYYY HH:mm')}`;
    this.setState({ pick_up_date: show, pick_up_date_show: date });
  }

  handleDatePickerAddress(index, event) {
    const date = `${event.format('YYYY-MM-DD HH:mm')}`;
    const show = `${event.format('DD/MM/YYYY HH:mm')}`;
    this.state.addressObj[index].delivery_date = show;
    this.state.addressObj[index].delivery_date_show = date;
    this.forceUpdate();
  }

  renderPayType(status) {
    switch (status) {
      case 0:
        return 'ไม่มีค่าใช้จ่าย';
      case 1:
        return 'บัตรเดบิต/เครดิต';
      case 2:
        return 'เงินสด';
      case 3:
        return 'ออกใบแจ้งหนี้';
      default:
        return '';
    }
  }

  renderPayTypeIcon(status) {
    switch (status) {
      case 0:
        return '';
      case 1:
        return 'fal fa-credit-card';
      case 2:
        return 'fal fa-hand-holding-usd';
      case 3:
        return 'fal fa-file-alt';
      default:
        return '';
    }
  }

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      default:
        return '';
    }
  }

  renderStatusPayment(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 2:
        return <Label bsStyle="warning">รอตรวจสอบ</Label>;
      case 3:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      case 4:
        return <Label bsStyle="danger">ค้างชำระ</Label>;
      case 5:
        return <Label>แบบร่าง</Label>;
      case 6:
        return <Label bsStyle="default">รอสร้างใบเพิ่มหนี้</Label>;
      case 7:
        return <Label bsStyle="default">รอสร้างใบแจ้งหนี้</Label>;
      default:
        return '-';
    }
  }

  renderLightboxContainner(data, index) {
    let image = [];
    for (var i = 0; i < data.length; i++) {
      const item = data[i];
      image.push(`${s3}/${item}`);
    }

    this.setState({
      isOpenLightbox: true,
      photoIndex: index,
      viewImageSrc: image,
    });
  }

  renderLightbox(mode, data, index) {
    if (mode === 'signature') {
      this.setState({
        isOpenLightbox: true,
        photoIndex: 0,
        viewImageSrc: [`${s3}/${data.signature_picture}`],
      });
    } else if (mode === 'pod') {
      this.setState({
        isOpenLightbox: true,
        photoIndex: 0,
        viewImageSrc: [`${s3}/${data}`],
      });
    } else {
      let image = [];
      if (index == 1) {
        image.push(`${s3}/${data.back_picture}`);
        if (data.side_picture) {
          image.push(`${s3}/${data.side_picture}`);
        }
        if (data.front_picture) {
          image.push(`${s3}/${data.front_picture}`);
        }
      } else if (index == 2) {
        image.push(`${s3}/${data.side_picture}`);
        if (data.front_picture) {
          image.push(`${s3}/${data.front_picture}`);
        }
        if (data.back_picture) {
          image.push(`${s3}/${data.back_picture}`);
        }
      } else {
        image.push(`${s3}/${data.front_picture}`);

        if (data.back_picture) {
          image.push(`${s3}/${data.back_picture}`);
        }
        if (data.side_picture) {
          image.push(`${s3}/${data.side_picture}`);
        }
      }

      this.setState({
        isOpenLightbox: true,
        photoIndex: 0,
        viewImageSrc: image,
      });
    }
  }

  renderPercentage(shipmentList) {
    const discountPrice = shipmentList.promotion_code.price;
    let distancePrice = 0;
    shipmentList.invoice_list.map((Pricing, key) => {
      if (Pricing.list_type == 1) {
        distancePrice += Pricing.list_total_price;
      }
    });
    return Math.round((discountPrice / distancePrice) * 100);
  }

  openEditContact(shipment) {
    //console.log("edit contact", shipment.shipment_address);
    let addressObj = [];
    for (var i = 0; i < shipment.shipment_address.length; i++) {
      const item = shipment.shipment_address[i];
      const obj = {
        _id: item._id,
        contact_name: item.contact_name,
        contact_tel: item.contact_tel,
        remark: item.remark,
        mode: item.mode,
        delivery_date: item.delivery_date
          ? moment(item.delivery_date).format('DD/MM/YYYY HH:mm')
          : '',
        delivery_date_show: item.delivery_date
          ? moment(item.delivery_date).format('YYYY-MM-DD HH:mm')
          : '',
      };
      addressObj.push(obj);
    }

    console.log('openEditContact', addressObj);

    this.setState({
      addressObj: addressObj,
      remark: shipment.shipment_other_detail.remark,
      openEditContactModal: true,
    });
  }

  setValueContactName(index, value) {
    this.state.addressObj[index].contact_name = value;
    this.forceUpdate();
  }

  setValueContactTel(index, value) {
    this.state.addressObj[index].contact_tel = value;
    this.forceUpdate();
  }

  setValueContactRemark(index, value) {
    this.state.addressObj[index].remark = value;
    this.forceUpdate();
  }

  chkEditShipment() {
    let num = 0;
    let error = false;
    for (var i = 0; i < this.state.addressObj.length; i++) {
      const item = this.state.addressObj[i];

      if (item.contact_name == '' || item.contact_tel == '') {
        num++;
      }
    }

    if (num > 0) {
      error = true;
    }

    return error;
  }

  chkAdditionalPod(shipment) {
    const additional = shipment.shipment_additional.slice();
    const additionalFilter = additional.filter(
      item => item.additional_id === '5ca46d2d910efac4f7704577'
    );
    if (additionalFilter.length === 0) {
      return false;
    }
    return true;
  }

  renderAdditional(item) {
    let text = '';
    for (let i = 0; i < item.length; i++) {
      if (
        item[i].additional == 'รถใหญ่พิเศษ' ||
        item[i].additional == 'รถขนาดใหญ่พิเศษ' ||
        item[i].additional == 'บริการคืนใบส่งสินค้า (POD)'
      ) {
        text += `${item[i].additional}`;
      } else {
        text += `${item[i].additional} x ${item[i].qty}`;
      }

      if (i < item.length - 1) {
        text += ', ';
      }
    }
    return text;
  }

  renderAccessory(accessoryList) {
    const accessory = accessoryList.map(item => item.name_lang.th);
    const textAccessory = accessory.join(', ');
    return textAccessory;
  }

  chkAdditionalPod(shipment) {
    const additional = shipment.shipment_additional.slice();
    const additionalFilter = additional.filter(
      item => item.additional_id == '5ca46d2d910efac4f7704577'
    );
    if (additionalFilter.length === 0) {
      return false;
    }
    return true;
  }

  renderAdditionalPod(shipment) {
    const additional = shipment.shipment_additional.slice();
    const additionalFilter = additional.filter(
      item => item.additional_id == '5ca46d2d910efac4f7704577'
    );
    if (additionalFilter.length === 0) {
      return '';
    }
    return additionalFilter[0].address;
  }

  renderAddressMode(mode, index) {
    if (mode == 'roundtrip') {
      return 'กลับจุดที่ 1 :';
    }
    return `จุดที่ ${index + 1} - ${mode}`;
  }

  renderVariablesShipment(shipment) {
    let addressObj = [];
    this.state.addressObj.forEach((ele, key) => {
      const data = {
        _id: ele._id,
        contact_name: ele.contact_name,
        contact_tel: ele.contact_tel,
        remark: ele.remark,
        delivery_date: ele.delivery_date_show,
      };
      addressObj.push(data);
    });

    let obj = {
      shipmentID: shipment._id,
      shipment_address_update: addressObj,
      remark: this.state.remark,
    };
    //console.log("renderVariablesShipment",obj);
    return obj;
  }

  renderVariablesCancelShipment(shipment) {
    const bookingTime = moment(shipment.pick_up_date).diff(moment(shipment.create_date), 'minutes');
    const timeLeft = moment(shipment.pick_up_date).diff(moment(this.state.today), 'minutes');
    const paidType = shipment.paid_type;

    let rejectDetail = '';
    let obj = {};
    let cancelComment = this.state.rejectRemark;
    if (cancelComment == 'อื่นๆ') {
      cancelComment = this.state.rejectDetail;
    }

    if (paidType == 1) {
      //Credit
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการจะได้รับเงินคืนเต็มจำนวน';
        } else if (timeLeft < 300 && timeLeft >= 180) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการจะได้รับเงินคืนเป็นจำนวน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 3 ชั่วโมง ผู้ใช้บริการจะไม่ได้รับเงินคืน';
        }
      } else {
        if (timeLeft >= 100) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการจะได้รับเงินคืนเต็มจำนวน';
        } else if (timeLeft < 100 && timeLeft >= 50) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการจะได้รับเงินคืนเป็นจำนวน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการจะไม่ได้รับเงินคืน';
        }
      }

      obj = {
        shipmentID: shipment._id,
        cancel_comment: cancelComment,
        shipment_status: 5,
        shipment_refund: {
          amount: this.state.refundPrice,
          status: 1,
          detail: { th: rejectDetail, en: '' },
        },
      };
    } else if (paidType == 2) {
      //COD
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 300 && timeLeft >= 180) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      } else {
        if (timeLeft >= 100) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 100 && timeLeft >= 50) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      }

      obj = {
        shipmentID: shipment._id,
        cancel_comment: cancelComment,
        shipment_status: 5,
        shipment_fines: {
          amount: this.state.refundPrice,
          status: 1,
          detail: { th: rejectDetail, en: '' },
          detail_complete: { th: rejectDetail, en: '' },
        },
      };
    } else if (paidType == 3) {
      //Invoice
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 300 && timeLeft >= 180) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      } else {
        if (timeLeft >= 100) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 100 && timeLeft >= 50) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      }

      obj = {
        shipmentID: shipment._id,
        cancel_comment: cancelComment,
        shipment_status: 5,
        shipment_fines: {
          amount: this.state.refundPrice,
          status: 1,
          detail: { th: rejectDetail, en: '' },
          detail_complete: { th: rejectDetail, en: '' },
        },
      };
    }

    return obj;
  }
  renderVariablesInvoicesandReceipts(shipment) {
    let obj = {
      _id: shipment._id,
      detail_from_tms: {
        inv_number: this.state.inputshipmentInvoices,
        rec_number: this.state.inputshipmentReceipts,
      },
    };
    //console.log("renderVariablesShipment",obj);
    return obj;
  }

  async handlecheckcancel(shipment, cancelShipmentMutation) {
    const shipmentID = shipment._id;
    try {
      const {
        data: {
          dateNow: { now: today },
        },
      } = await client.query({
        query: dateNowQuery,
      });

      const {
        data: { shipmentList },
      } = await client.query({
        query: detailShipment,
        variables: {
          _id: shipmentID,
        },
      });

      // console.log(shipmentList);
      if (shipmentList.shipment[0].shipment_status == 4) {
        alert('ไม่สามารถยกเลิกงานนี้ได้เนื้อจากงานเสร็จสิ้นแล้ว');
        window.location.reload();
      } else if (shipmentList.shipment[0].shipment_status == 5) {
        alert('ไม่สามารถยกเลิกงานนี้ได้เนื้อจากงานถูกยกเลิกแล้ว');
        window.location.reload();
      } else {
        this.setState({
          Today: today,
        });

        cancelShipmentMutation();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async handleShowInvoicesandReceipts(shipment) {
    let inv = '';
    let rec = '';
    if (shipment.detail_from_tms.inv_number) {
      inv = shipment.detail_from_tms.inv_number;
    }
    if (shipment.detail_from_tms.inv_number) {
      rec = shipment.detail_from_tms.rec_number;
    }

    try {
      this.setState({
        show_invoicesandreceipts: true,
        inputshipmentInvoices: inv,
        inputshipmentReceipts: rec,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async handleShowcancel(shipment) {
    const shipmentNumber = shipment.shipment_number;
    const shipmentID = shipment._id;
    const paidType = shipment.paid_type;

    let today = '';
    let invoiceBusinessList = null;
    let invoiceList = null;
    try {
      const {
        data: {
          dateNow: { now: today },
        },
      } = await client.query({
        query: dateNowQuery,
      });

      const {
        data: { invoiceBusinessList },
      } = await client.query({
        query: invoiceBusinessListQuery,
        variables: {
          shipment_id: shipmentID,
        },
      });

      const {
        data: { invoiceList },
      } = await client.query({
        query: invoiceListQuery,
        variables: {
          shipment_id: shipmentID,
        },
      });

      let grandTotal = 0;
      if (invoiceBusinessList) {
        grandTotal = invoiceBusinessList.grand_total_price;
      } else {
        grandTotal = invoiceList[0].total_price;
      }

      this.setState({
        show_cancel: true,
        shipmentID: shipmentID,
        shipmentNumber: shipmentNumber,
        rejectSelected: null,
        showRejectDetail: false,
        rejectNumber: '',
        rejectRemark: '',
        refundPrice: '',
        rejectDetail: '',
        shipmentGrandTotal: grandTotal,
        Today: today,
        createDate: shipment.create_date,
        pickupDate: shipment.pick_up_date,
      });
    } catch (error) {
      today = moment();
      console.log(error);
    }
  }

  checkMaxPrice(value) {
    if (value > this.state.shipmentGrandTotal) {
      alert('จำนวนเงินที่คืน หรือ เรียกเก็บจากลูกค้าไม่ควรเกิน ยอดรวมทั้งหมดของงาน');
      this.setState({ refundPrice: this.state.shipmentGrandTotal });
    } else {
      this.setState({ refundPrice: value });
    }
  }

  renderShipmentCancelError(shipment) {
    const bookingTime = moment(shipment.pick_up_date).diff(moment(shipment.create_date), 'minutes');
    const timeLeft = moment(shipment.pick_up_date).diff(moment(this.state.today), 'minutes');
    const paidType = shipment.paid_type;

    if (paidType == 1) {
      //Credit
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า น้อยกว่า 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 300 && timeLeft >= 180) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า น้อยกว่า 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง{' '}
                <b> ไม่สามารถยื่นคำร้องขอคืนเงินได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า น้อยกว่า 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า : <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        }
      } else {
        if (timeLeft >= 100) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที{' '}
                <b> สามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 100 && timeLeft >= 50) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที{' '}
                <b> สามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {(this.state.shipmentGrandTotal / 2).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที{' '}
                <b> ไม่สามารถยื่นคำร้องขอคืนเงินได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า : <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        }
      }
    } else if (paidType == 2) {
      //COD
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 300 && timeLeft >= 180) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      } else {
        if (timeLeft >= 100) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที
                <b> สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำ��ะ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 100 && timeLeft >= 50) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {(this.state.shipmentGrandTotal / 2).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      }
    } else if (paidType == 3) {
      //Invoice
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 300 && timeLeft >= 180) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      } else {
        if (timeLeft >= 100) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที{' '}
                <b> สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 100 && timeLeft >= 50) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {(this.state.shipmentGrandTotal / 2).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      }
    }
  }

  handleChangeReject(reject) {
    // console.log(`truckSelected:`, truck);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ rejectSelected: reject, rejectRemark: reject.value });
    if (reject.value == 'อื่นๆ') {
      this.setState({ showRejectDetail: true });
    } else {
      this.setState({ showRejectDetail: false });
    }
    // console.log(`truckSelected:`, this.state.truckID);
  }

  checkDisableCancel() {
    if (
      this.state.rejectNumber === this.state.shipmentNumber &&
      this.rejectSelected !== null &&
      this.state.refundPrice !== ''
    ) {
      return false;
    }
    return true;
  }

  handleChangeAdditionnalCostCategory(CategoryList, event) {
    this.setState({ additionnalCostCategory: event });

    if (event.value == '5d4009ec9799b1f96549d21e') {
      this.setState({
        additionnalCostSubCategory: { value: 'ส่วนลด', label: 'ส่วนลด' },
        disableTpyeDiscount: true,
        tax_type: 3,
        additionnalCostPriceVat: { value: '0', label: '0' },
        additionnalCostPriceWht: { value: '0', label: '0' },
      });
    } else {
      this.setState({
        additionnalCostSubCategory: { value: null, label: null },
        disableTpyeDiscount: false,
        tax_type: 0,
        additionnalCostPriceVat: { value: '0', label: '0' },
        additionnalCostPriceWht: { value: '0', label: '0' },
      });
    }
  }

  handleChangeAdditionnalCostSubCategory(CategoryList, event) {
    this.setState({ additionnalCostSubCategory: event });

    const CategoryListelected = CategoryList.filter(list => {
      return list._id == this.state.additionnalCostCategory.value;
    });

    const expenses = CategoryListelected[0].expenses_item.filter(list => {
      return list._id == event.value;
    });

    this.setState({
      disableTpyeDiscount: false,
      tax_type: expenses[0].tax_type,
      additionnalCostPriceVat: { value: expenses[0].default_vat, label: expenses[0].default_vat },
      additionnalCostPriceWht: { value: expenses[0].default_wht, label: expenses[0].default_wht },
    });

    // console.log(expenses );
  }

  handleChangeadditionnalCostPriceVat(event) {
    this.setState({ additionnalCostPriceVat: event });
  }

  handleChangeadditionnalCostPriceWht(event) {
    this.setState({ additionnalCostPriceWht: event });
  }

  renderAdditionnalCostCategory(CategoryList) {
    const CategoryListData = CategoryList.map(e => {
      return {
        value: e._id,
        label: e.name,
      };
    });

    return CategoryListData;
  }

  renderAdditionnalCostSubCategory(CategoryList) {
    const CategoryListelected = CategoryList.filter(list => {
      return list._id == this.state.additionnalCostCategory.value;
    });
    if (CategoryListelected.length > 0) {
      const CategoryListData = CategoryListelected[0].expenses_item.map(e => {
        return {
          value: e._id,
          label: e.name,
        };
      });
      return CategoryListData;
    } else {
      return [
        {
          value: null,
          label: null,
        },
      ];
    }
  }

  checkDisableAddCost() {
    if (
      this.state.additionnalCostCategory.value == null ||
      this.state.additionnalCostSubCategory.value == null ||
      this.state.additionnalCostQty <= 0 ||
      this.state.additionnalCostPrice <= 0
    ) {
      return 'disable';
    } else {
      return '';
    }
  }

  onClickAddInvoiceMore(mode) {
    this.setState({
      openAdditionalPayment: true,
      isOpenDetailReview: false,
      invoiceMoreMode: mode,
      additionnalCostCategory: { value: null, label: null },
      additionnalCostSubCategory: { value: null, label: null },
      additionnalCostPriceVat: { value: '0', label: '0' },
      additionnalCostPriceWht: { value: '0', label: '0' },
      invoiceMore_itemId: null,
      invoice_id: null,
      invoice_more_id: null,
      additionnalCostQty: 0,
      additionnalCostPrice: 0,
    });
  }

  renderAdditionnalCostCategorySelected(CategoryList, id) {}

  renderAdditionnalCostSubCategorySelected(CategoryList, id) {
    // const CategoryListelected = CategoryList.filter((list) => {
    //     return list._id == this.state.additionnalCostCategory.value;
    // })
  }

  onClickUpdateInvoiceMore(item, mode, _id) {
    if (mode == 'edit') {
      this.setState({
        openAdditionalPayment: true,
        showDeleteModal: false,
        invoiceMoreMode: mode,
        additionnalCostCategory: { value: null, label: null },
        additionnalCostSubCategory: { value: null, label: null },
        additionnalCostPriceVat: {
          value: item.invoice_business_list_vat,
          label: item.invoice_business_list_vat,
        },
        additionnalCostPriceWht: {
          value: item.invoice_business_list_wht,
          label: item.invoice_business_list_wht,
        },
        invoiceMore_itemId: item._id,
        additionnalCostQty: item.invoice_business_list_qty,
        additionnalCostPrice: item.invoice_business_list_total_price,
      });
    } else if (mode == 'delete') {
      this.setState({
        openAdditionalPayment: false,
        showDeleteModal: true,
        invoiceMoreMode: mode,
        DeleteItemName: item.invoice_business_list_detail,
        DeleteItemPrice: item.invoice_business_list_total_price,
        invoiceMore_itemId: item._id,
        invoice_more_id: this.state.selectedAdditionalItemMode != 'mainInvoice' ? _id : null,
        invoice_id: this.state.selectedAdditionalItemMode == 'mainInvoice' ? _id : null,
      });
    }
  }

  renderVariablesInvoiceMoreUpdate() {
    let obj = {};
    if (this.state.invoiceMoreMode == 'edit') {
      obj = {
        invoice_id: this.state.invoice_id,
        invoice_more_id: this.state.invoice_more_id,
        item_id: this.state.invoiceMore_itemId,
        category: this.state.additionnalCostCategory.label,
        detail: this.state.additionnalCostSubCategory.label,
        price: this.state.additionnalCostPrice,
        qty: this.state.additionnalCostQty,
        vat: this.state.additionnalCostPriceVat,
        wht: this.state.additionnalCostPriceWht,
        mode: this.state.invoiceMoreMode,
      };
    } else if (this.state.invoiceMoreMode == 'delete') {
      obj = {
        invoice_id: this.state.invoice_id,
        invoice_more_id: this.state.invoice_more_id,
        item_id: this.state.invoiceMore_itemId,
        mode: this.state.invoiceMoreMode,
      };
    }

    return obj;
  }

  onClickOpenAdditionalPayment(item, key, mode) {
    this.setState({
      openAdditionalPaymentList: true,
      selectedAdditionalItemKey: key,
      selectedAdditionalItem: item,
      selectedAdditionalItemMode: mode,
      isOpenDetailReview: false,
    });
  }

  chkOpenAdditionalItem(key) {
    if (key == this.state.selectedAdditionalItemKey) {
      return true;
    }
    return false;
  }

  /*Import */

  openEditTransporter(tracking) {
    this.setState({
      openEditTransporter: 'open',
      transporter: tracking[0].transporter ? tracking[0].transporter : null,
      driver_name: tracking[0].transporter_detail ? tracking[0].transporter_detail : '',
      driver_phone_code: tracking[0].transporter_phone_code
        ? tracking[0].transporter_phone_code
        : '66',
      driver_phone_number: tracking[0].transporter_phone ? '0' + tracking[0].transporter_phone : '',
    });
  }

  checkValidateAddDriver() {
    const {
      driver_name,
      driver_phone_code,
      driver_phone_number,
      license_plate_front,
      license_plate_back,
    } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (driver_name && license_plate_front && validatePhoneNumber.test(driver_phone_number)) {
      return true;
    }

    return false;
  }

  checkValidateAddContainer() {
    const { cont_number, seal_number, tare_weight } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (cont_number && seal_number && tare_weight) {
      return true;
    }

    return false;
  }

  openEditDriver(tracking, mode) {
    // console.log("openEditDriver" , tracking)
    this.setState({
      openEditDriver: 'open',
      openEditDriverMode: mode,
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      license_plate_front: '',
      license_plate_back: '',
      transporter: tracking[0].transporter ? tracking[0].transporter : null,
    });

    if (tracking[0].tracking_import_detail[0].track_driver.length > 0) {
      this.setState({
        openEditDriver: 'open',
        openEditDriverMode: mode,
        transporter: tracking[0].transporter ? tracking[0].transporter : null,
        driver_name: tracking[0].tracking_import_detail[0].track_driver[0].driver_name,
        driver_phone_code: tracking[0].tracking_import_detail[0].track_driver[0].driver_phone_code,
        driver_phone_number:
          '0' + tracking[0].tracking_import_detail[0].track_driver[0].driver_phone_number,
        license_plate_front:
          tracking[0].tracking_import_detail[0].track_driver[0].license_plate_front,
        license_plate_back:
          tracking[0].tracking_import_detail[0].track_driver[0].license_plate_back,
      });
    }
  }

  openEditContainer(tracking, mode) {
    this.setState({
      openEditContainer: 'open',
      openEditContainerMode: mode,
      cont_number: '',
      seal_number: '',
      tare_weight: '',
      cont_picture: [],
    });

    if (tracking[0].tracking_import_detail[0].accept_container.length > 0) {
      this.setState({
        openEditContainer: 'open',
        openEditContainerMode: mode,
        cont_number: tracking[0].tracking_import_detail[0].accept_container[0].cont_number,
        seal_number: tracking[0].tracking_import_detail[0].accept_container[0].seal_number,
        tare_weight: tracking[0].tracking_import_detail[0].accept_container[0].tare_weight,
        cont_picture: tracking[0].tracking_import_detail[0].accept_container[0].picture,
      });
    }
  }

  onSubmitAddContainer(e, tracking, trackingAddContainer) {
    const chkError = this.checkValidateAddContainer();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const { cont_number, seal_number, tare_weight, cont_picture } = this.state;
      //  //console.log("cont_picture",cont_picture.length)
      if (this.state.openEditContainerMode == 'new') {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_add: [
              {
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      } else {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_update: [
              {
                position_accept_container: '0',
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      }
    }
  }

  onOpenReturnContainer() {
    this.setState({
      openReturnContainer: true,
    });
  }

  onSubmitReturnContainer(e, tracking, trackingReturnContainer) {
    trackingReturnContainer({
      variables: {
        _id: tracking[0]._id,
        _id_detail: tracking[0].tracking_import_detail[0]._id,
        position: '0',
        droppoint: tracking[0].tracking_import_detail[0].droppoint,
        return_container_date: '1',
      },
    });
  }
  /*end Import */

  /* Export */

  openEditTransporterExport(tracking) {
    this.setState({
      openEditTransporterExport: 'open',
      transporter: tracking[0].transporter ? tracking[0].transporter : null,
      driver_name: tracking[0].transporter_detail ? tracking[0].transporter_detail : '',
      driver_phone_code: tracking[0].transporter_phone_code
        ? tracking[0].transporter_phone_code
        : '66',
      driver_phone_number: tracking[0].transporter_phone ? '0' + tracking[0].transporter_phone : '',
    });
  }

  openEditDriverExport(tracking, mode) {
    this.setState({
      openEditDriverExport: 'open',
      openEditDriverMode: mode,
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      license_plate_front: '',
      license_plate_back: '',
      transporter: tracking[0].transporter ? tracking[0].transporter : null,
    });

    if (tracking[0].tracking_export_detail[0].track_driver.length > 0) {
      this.setState({
        openEditDriverExport: 'open',
        openEditDriverMode: mode,
        transporter: tracking[0].transporter ? tracking[0].transporter : null,
        driver_name: tracking[0].tracking_export_detail[0].track_driver[0].driver_name,
        driver_phone_code: tracking[0].tracking_export_detail[0].track_driver[0].driver_phone_code,
        driver_phone_number:
          '0' + tracking[0].tracking_export_detail[0].track_driver[0].driver_phone_number,
        license_plate_front:
          tracking[0].tracking_export_detail[0].track_driver[0].license_plate_front,
        license_plate_back:
          tracking[0].tracking_export_detail[0].track_driver[0].license_plate_back,
      });
    }
  }

  openEditContainerExport(tracking, mode) {
    this.setState({
      openEditContainerExport: 'open',
      openEditContainerMode: mode,
      cont_number: '',
      seal_number: '',
      tare_weight: '',
      cont_picture: [],
    });

    if (tracking[0].tracking_export_detail[0].accept_container.length > 0) {
      this.setState({
        openEditContainerExport: 'open',
        openEditContainerMode: mode,
        cont_number: tracking[0].tracking_export_detail[0].accept_container[0].cont_number,
        seal_number: tracking[0].tracking_export_detail[0].accept_container[0].seal_number,
        tare_weight: tracking[0].tracking_export_detail[0].accept_container[0].tare_weight,
        cont_picture: tracking[0].tracking_export_detail[0].accept_container[0].picture,
      });
    }
  }

  onSubmitAddContainerExport(e, tracking, trackingAddContainer) {
    const chkError = this.checkValidateAddContainer();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const { cont_number, seal_number, tare_weight, cont_picture } = this.state;
      //console.log("cont_picture",cont_picture)
      if (this.state.openEditContainerMode == 'new') {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_add: [
              {
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      } else {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_update: [
              {
                position_accept_container: '0',
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      }
    }
  }

  onOpenDriverAcceptDocument() {
    this.setState({
      openDriverAcceptDocumentExport: true,
    });
  }

  onSubmitDriverAcceptDocument(e, tracking, trackingReturnContainer) {
    trackingReturnContainer({
      variables: {
        _id: tracking[0]._id,
        _id_detail: tracking[0].tracking_export_detail[0]._id,
        position: '0',
        droppoint: tracking[0].tracking_export_detail[0].droppoint,
        driver_accept_document: '1',
      },
    });
  }

  onOpenReturnContainerExport() {
    this.setState({
      openReturnContainerExport: true,
    });
  }

  onSubmitReturnContainerExport(e, tracking, trackingReturnContainer) {
    trackingReturnContainer({
      variables: {
        _id: tracking[0]._id,
        _id_detail: tracking[0].tracking_export_detail[0]._id,
        position: '0',
        droppoint: tracking[0].tracking_export_detail[0].droppoint,
        return_container_date: '1',
      },
    });
  }

  OpenUploadImageExport(tracking) {
    this.setState({
      OpenEditUploadImageExport: 'open',
      cont_picture: [],
    });

    if (tracking[0].tracking_export_detail[0].commodity_picture.length > 0) {
      this.setState({
        OpenEditUploadImageExport: 'open',
        cont_picture: tracking[0].tracking_export_detail[0].commodity_picture,
      });
    }
  }

  onSubmitUploadImageExport(e, tracking, trackingUploadImageCommodity) {
    const chkError = this.state.cont_picture.length > 0;
    if (chkError) {
      const { cont_picture } = this.state;
      trackingUploadImageCommodity({
        variables: {
          _id: tracking[0]._id,
          _id_detail: tracking[0].tracking_export_detail[0]._id,
          position: '0',
          droppoint: tracking[0].tracking_export_detail[0].droppoint,
          commodity_picture_date: '1',
          commodity_picture: cont_picture,
        },
      });
    }
  }
  /* Image */

  checkFileTypeImage(file) {
    if (file) {
      if (
        file.type === 'image/png' ||
        file.type === 'image/jpeg' ||
        file.type === 'application/pdf'
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkFileSizeImage(file) {
    if (file) {
      const size = parseFloat(file.size / 1024).toFixed(2);
      if (size < 10240) {
        //ขนาดไฟล์ 10240KB or 10Mb
        return true;
      } else {
        return false;
      }
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
    if (checkFileTypeImage === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png, jpg เท่านั้น',
      });
      return;
    }

    const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
    if (checkFileSize === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 10MB',
      });
      return;
    }

    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/shipments/${documentName}_${timestamp}`,
        },
      });
    }
  }

  onDeletePhoto(file) {
    const document = this.state.cont_picture.filter(item => `${item}` !== file);

    this.setState({
      cont_picture: document,
    });
  }

  rendeReceiptImage(shipment, mode) {
    const receipt_doc = shipment.shipment_confirm_detail.receipt_doc.map(item => {
      const patternFileType = /\.[0-9a-z]{1,5}$/;
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      const thumbnail = patternCheckFileType.test(item.path)
        ? `${require('./../../assets/img/file-type-pdf.png')}`
        : `${s3}/${item.path}`;
      const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

      return {
        path: `${s3}/${item.path}`, //path ไฟล์
        fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
        type: type, //ประเภทไฟล์
        thumbnail: thumbnail, //รูปตัวอย่าง
      };
    });
    const receiptImage = receipt_doc.map(item => {
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      if (patternCheckFileType.test(item.path)) {
        return <PDFPreview file={item.path} />;
      }

      return item.thumbnail;
    });

    if (mode == 'view') {
      return receipt_doc;
    }

    return receiptImage;
  }

  renderConfirmImage(shipment, mode) {
    let shipmentConfirmImage = '';
    if (shipment.shipment_confirm_document) {
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      const shipment_confirm = {
        path: `${s3}/${shipment.shipment_confirm_document}`, //path ไฟล์
        fileType: shipment.shipment_confirm_document.match(/\.[0-9a-z]{1,5}$/)[0], //นามสกุลไฟล์
        type: patternCheckFileType.test(shipment.shipment_confirm_document) ? 'pdf' : 'image', //ประเภทไฟล์
        thumbnail: patternCheckFileType.test(shipment.shipment_confirm_document)
          ? `${require('./../../assets/img/file-type-pdf.png')}`
          : `${s3}/${shipment.shipment_confirm_document}`, //รูปตัวอย่าง
      };

      if (patternCheckFileType.test(shipment_confirm.path)) {
        shipmentConfirmImage = <PDFPreview file={shipment_confirm.path} />;
      } else {
        shipmentConfirmImage = shipment_confirm.thumbnail;
      }

      if (mode == 'view') {
        return shipment_confirm;
      }
    }

    return shipmentConfirmImage;
  }
  /* end image */

  checkTransporterHideDriver(transporterId) {
    // console.log("checkTransporterHideDriver",diverWorking)
    if (!diverWorking) {
      return true;
    }
    return false;

    // const transporterList = [
    //     { transporter: '5c9afba08c8cf32b81d62a40', transporter_name: 'K.ณัฐพล 4+6+10' },
    //     { transporter: '5c9afbc08c8cf32b81d62a44', transporter_name: 'K.ธนูรัตน์ 4 + 6' },
    //     { transporter: '5c9afbe58c8cf32b81d62a48', transporter_name: 'K.บูรพา 4+6+10' },
    //     { transporter: '5c9afbff8c8cf32b81d62a4c', transporter_name: 'K.องอาจ 4' },
    //     { transporter: '5cc190734ad2050dfd0f4631', transporter_name: 'K.อุดม 6+10' },
    //     { transporter: '5cc18f774ad2050dfd0f462a', transporter_name: 'K.วราลักษณ์ 4+6+10' },
    //     { transporter: '5cd142a2092a935384fa2c71', transporter_name: 'k.วีระพนธ์ 4' },
    //     { transporter: '5d2fe9807ddc225bc8cf99db', transporter_name: 'K.สุรินทร์' },
    //     { transporter: '5d353a91088ba84277201784', transporter_name: 'บ. K2C logistics 6 + 10 พื้นเรียบ' },
    // ];

    // return transporterList.filter(item => item.transporter === transporterId).length > 0 ? true : false;
  }

  checkMatchingTime(matching_time) {
    //const timeLeft = moment(shipment.assign_driver_date).diff(moment(shipment.create_date), 'seconds');
    return `${this.getTimeFromMins(matching_time)}`;
  }

  getTimeFromMins(times) {
    var day = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;

    if (times >= 86400) {
      day = (times / 86400) | 0;
      times -= day * 86400;
    }
    if (times >= 3600) {
      hours = (times / 3600) | 0;
      times -= hours * 3600;
    }
    if (times >= 60) {
      minutes = (times / 60) | 0;
      times -= minutes * 60;
    }
    if (times > 0 && times < 60) {
      seconds = times % 60 | 0;
    }

    var showTime = '';

    if (day > 0) {
      showTime = `${day} วัน `;
    }
    if (hours > 0) {
      showTime += `${hours} ชั่วโมง `;
    }
    if (minutes > 0) {
      showTime += `${minutes} นาที `;
    }
    if (seconds > 0) {
      showTime += `${seconds} วินาที`;
    }
    return showTime;
  }

  /* Rating */
  getRatingToWord(rating) {
    if (rating === 5) {
      return {
        en: 'Excellent',
        th: 'ดีมาก',
      };
    } else if (rating === 4) {
      return {
        en: 'Good',
        th: 'ดี',
      };
    } else if (rating === 3) {
      return {
        en: 'Average',
        th: 'พอใช้',
      };
    } else if (rating === 2) {
      return {
        en: 'Bad',
        th: 'แย่',
      };
    } else if (rating === 1) {
      return {
        en: 'Worst',
        th: 'แย่มาก',
      };
    }

    return {
      en: 'Rate driver',
      th: 'ให้คะแนนพนักงานขับรถ',
    };
  }

  getReasonFromRating() {
    const { rating } = this.state;
    if (rating === 5 || rating === 4) {
      return [
        {
          id: 1,
          en: 'Good manner',
          th: 'มารยาทดี',
        },
        {
          id: 2,
          en: 'Rapidly',
          th: 'ขนส่งรวดเร็ว',
        },
        {
          id: 3,
          en: 'On time delivery',
          th: 'ตรงต่อเวลา',
        },
        {
          id: 4,
          en: 'Formal dressed',
          th: 'แต่งกายสุภาพ',
        },
        {
          id: 5,
          en: 'Good condition goods',
          th: 'สินค้าอยู่ในสภาพดี',
        },
      ];
    } else if (rating === 3) {
      return [
        {
          id: 1,
          en: 'Delay',
          th: 'ขนส่งล่าช้า',
        },
        {
          id: 2,
          en: 'Rude',
          th: 'พูดจาไม่ค่อยดี',
        },
        {
          id: 3,
          en: 'Bad navigation',
          th: 'ไม่ชำนาญเส้นทาง',
        },
        {
          id: 4,
          en: 'Unsatisfied',
          th: 'ไม่ค่อยประทับใจ',
        },
        {
          id: 5,
          en: 'Damaged during delivery',
          th: 'เกิดปัญหาระหว่างการขนส่ง',
        },
      ];
    } else if (rating === 2 || rating === 1) {
      return [
        {
          id: 1,
          en: 'Rude',
          th: 'พูดจาไม่ดี',
        },
        {
          id: 2,
          en: 'Delay',
          th: 'ขนส่งล่าช้า',
        },
        {
          id: 3,
          en: 'Bad navigation',
          th: 'ไม่ชำนาญเส้นทาง',
        },
        {
          id: 4,
          en: 'Informal dressed',
          th: 'แต่งกายไม่เรียบร้อย',
        },
        {
          id: 5,
          en: 'Damaged goods',
          th: 'สินค้าเกิดชำรุด',
        },
      ];
    }

    return [];
  }

  checkPODProcess(shipment, tracking) {
    const sortTrackingDetail = tracking.tracking_detail.sort((a, b) => b.droppoint - a.droppoint);
    return (
      this.chkAdditionalPod(shipment) &&
      shipment.shipment_type === 1 &&
      tracking.driver_accept === 1 &&
      sortTrackingDetail[0].signature_date !== null
    );
  }

  handlerShowCase = (shipment, admin) => {
    var additionl = [];
    if (shipment.shipment_additional.length > 0) {
      additionl = [...shipment.shipment_additional];
    }
    // console.log(additionl)
    // console.log(shipment)

    this.setState({
      shipmentID: shipment._id,
      shipmentNumber: shipment.shipment_number,
      InformRecieveDateTime: moment().format('YYYY-MM-DD HH:mm'),
      InformRecieveDateTimeShow: moment().format('DD/MM/YYYY HH:mm'),
      InformRecieveDateTimeInvalid: false,

      pickUpDatecase: shipment.pick_up_date ? shipment.pick_up_date : '-',
      shipperName: shipment.shipper ? shipment.shipper.account_info_business_name : '-',
      truckType:
        shipment.setting_truck_name && shipment.truck_type_name
          ? `${shipment.setting_truck_name.th} ${shipment.truck_type_name.th}`
          : '-',
      driverName: shipment.driver_user_id
        ? shipment.driver_user_id.driver_name
          ? shipment.driver_user_id.driver_name
          : '-'
        : '-',
      driverPhone: shipment.driver_user_id
        ? shipment.driver_user_id.driver_phone_number
          ? `${shipment.driver_user_id.driver_phone_code}  ${shipment.driver_user_id.driver_phone_number}`
          : '-'
        : '-',
      pickUpPoint:
        shipment.shipment_address.length > 0 ? shipment.shipment_address[0].address : '-',
      dropPoint: shipment.shipment_address.length > 1 ? shipment.shipment_address[1].address : '-',
      transporterName: shipment.transporter ? shipment.transporter.account_info_business_name : '-',
      additionService:
        shipment.shipment_additional.length > 0 ? [...shipment.shipment_additional] : [],

      showProblem: true,
      caseOwner: admin,
      caseAnswer: admin,
    });

    this.refetchCaseLevelAndTitle();
    this.getShipmentCase(shipment._id);
  };

  resetFieldCase = () => {
    this.setState({
      shipmentSlas: [],
      caseLevelDetail: [],
      newCase: true,
      caseIndex: -1,
      caseId: '',
      caseStatus: -1,
      caseTitle: { value: '', label: '-- เลือก --' },
      caseTitleId: '',
      caseTitleInvalid: false,
      expireDateCheck: null,
      completedDateChecK: null,

      caseLevel: { value: '', label: '-- เลือก --' },
      caseLevelId: '',
      caseLevelInvalid: false,
      attachFile: [],
      attachSecondFile: [],

      serviceTimeCase: '',
      levelTime: '',
      levelUnit: '',
      caseTitleChange: false,

      //1.รายละเอียดปัญหา
      caseDetail: '',
      caseDetailInvalid: false,

      InformRecieveDateTime: '',
      InformRecieveDateTimeShow: '',
      InformRecieveDateTimeInvalid: false,
      //ผู้รับปัญหา
      caseOwner: '',
      caseOwnerInvalid: false,

      caseRecieveChanel: { value: '', label: '-- เลือก --' },
      caseRecieveChanelInvalid: false,

      //เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)
      phoneInformer: '',
      phoneInformerInvalid: false,

      mailInformer: '',
      mailInformerInvalid: false,

      //2. รายละเอียดการแก้ไขปัญหา
      caseResolveDetail: '',
      caseResolveDetailInvalid: false,

      customerReplyDateTime: '',
      customerReplyDateTimeShow: '',
      customerReplyDateTimeInvalid: false,

      //ผู้ตอบกลับปัญหา
      caseAnswer: '',
      caseAnswerInvalid: false,

      caseAnswerChanel: { value: '', label: '-- เลือก --' },
      caseAnswerChanelInvalid: false,

      //Props
      shipmentID: '',
      shipmentNumber: '',
      pickUpDatecase: '',
      shipperName: '',
      truckType: '',
      driverName: '',
      driverPhone: '',
      pickUpPoint: '',
      dropPoint: '',
      additionService: [],
    });
  };

  handlerCreateNewCase = adminName => {
    this.refetchCaseLevelAndTitle();

    this.setState({
      newCase: true,
      caseLevelDetail: [],
      caseStatus: -1,
      caseId: '',
      caseIndex: -1,
      caseTitle: { value: '', label: '-- เลือก --' },
      caseTitleId: '',
      caseTitleInvalid: false,
      attachFile: [],
      attachSecondFile: [],
      serviceTimeCase: '',
      levelTime: '',
      levelUnit: '',
      caseTitleChange: false,
      expireDateCheck: null,
      completedDateChecK: null,

      caseLevel: { value: '', label: '-- เลือก --' },
      caseLevelId: '',
      caseLevelInvalid: false,

      //1.รายละเอียดปัญหา
      caseDetail: '',
      caseDetailInvalid: false,

      InformRecieveDateTime: moment().format('YYYY-MM-DD HH:mm'),
      InformRecieveDateTimeShow: moment().format('DD/MM/YYYY HH:mm'),
      InformRecieveDateTimeInvalid: false,
      //ผู้รับปัญหา
      caseOwner: adminName,
      caseOwnerInvalid: false,

      caseRecieveChanel: { value: '', label: '-- เลือก --' },
      caseRecieveChanelInvalid: false,

      //เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)
      phoneInformer: '',
      phoneInformerInvalid: false,

      mailInformer: '',
      mailInformerInvalid: false,

      //2. รายละเอียดการแก้ไขปัญหา
      caseResolveDetail: '',
      caseResolveDetailInvalid: false,

      customerReplyDateTime: '',
      customerReplyDateTimeShow: '',
      customerReplyDateTimeInvalid: false,

      //ผู้ตอบกลับปัญหา
      caseAnswer: adminName,
      caseAnswerInvalid: false,

      caseAnswerChanel: { value: '', label: '-- เลือก --' },
      caseAnswerChanelInvalid: false,
    });
  };

  handlerCreateShipmentSla = createShipmentSlaMutation => {
    if (!this.handlerCheckFirstCaseInvalid()) {
      createShipmentSlaMutation({
        variables: {
          createShipmentSla: {
            shipment: this.state.shipmentID,
            datamaster_sla_title: this.state.caseTitleId,
            datamaster_sla_level: this.state.caseLevelId,
            sla_issue: this.state.InformRecieveDateTime,
            sla_description: this.state.caseDetail,
            sla_file: this.state.attachFile,
            sla_channel: this.state.caseRecieveChanel.value,
            customer_email: this.state.mailInformer,
            customer_phone: this.state.phoneInformer,
          },
        },
      });
    }
  };

  handlerCheckFirstCaseInvalid = () => {
    // console.log(this.state.InformRecieveDateTimeInvalid);
    let phoneInvalid = this.state.phoneInformer
      ? this.state.phoneInformer.match('^[0][0-9]{8,9}$')
        ? false
        : true
      : false;
    let dateInvalid = this.state.InformRecieveDateTime
      ? moment(this.state.InformRecieveDateTime) > moment()
        ? true
        : false
      : true;
    let mailInvalid = this.state.mailInformer ? !this.checkEmail(this.state.mailInformer) : false;
    this.setState({
      caseTitleInvalid: this.state.caseTitle.value ? false : true,
      caseLevelInvalid: this.state.caseLevel.value ? false : true,
      caseDetailInvalid: this.state.caseDetail ? false : true,
      InformRecieveDateTimeInvalid: this.state.InformRecieveDateTime
        ? moment(this.state.InformRecieveDateTime) > moment()
          ? true
          : false
        : true,
      caseOwnerInvalid: this.state.caseOwner ? false : true,
      caseRecieveChanelInvalid: this.state.caseRecieveChanel.value ? false : true,
      phoneInformerInvalid: this.state.phoneInformer
        ? this.state.phoneInformer.match('^[0][0-9]{8,9}$')
          ? false
          : true
        : false,
      mailInformerInvalid: this.state.mailInformer
        ? !this.checkEmail(this.state.mailInformer)
        : false,
    });
    return !(
      this.state.caseTitle.value &&
      this.state.caseTitleId &&
      this.state.caseLevel.value &&
      this.state.caseLevelId &&
      this.state.caseDetail &&
      this.state.InformRecieveDateTime &&
      this.state.InformRecieveDateTimeShow &&
      this.state.caseOwner &&
      this.state.caseRecieveChanel.value &&
      !phoneInvalid &&
      !dateInvalid &&
      !mailInvalid
    );
  };

  handlerSaveFirstCase = (updateShipment, caseId) => {
    if (!this.handlerCheckFirstCaseInvalid()) {
      this.setState({ saveCaseCheck: 1 });
      if (false) {
        if (!this.handlerCheckSecondCaseInvalid()) {
          this.state.caseTitleChange
            ? updateShipment({
                variables: {
                  params: {
                    _id: caseId,
                  },
                  updateShipmentSla: {
                    datamaster_sla_title: this.state.caseTitleId,
                    datamaster_sla_level: this.state.caseLevelId,
                    sla_issue: this.state.InformRecieveDateTime,
                    sla_description: this.state.caseDetail,
                    sla_file: this.state.attachFile,
                    sla_channel: this.state.caseRecieveChanel.value,
                    customer_email: this.state.mailInformer,
                    customer_phone: this.state.phoneInformer,
                  },
                },
              })
            : updateShipment({
                variables: {
                  params: {
                    _id: caseId,
                  },
                  updateShipmentSla: {
                    sla_issue: this.state.InformRecieveDateTime,
                    sla_description: this.state.caseDetail,
                    sla_file: this.state.attachFile,
                    sla_channel: this.state.caseRecieveChanel.value,
                    customer_email: this.state.mailInformer,
                    customer_phone: this.state.phoneInformer,
                  },
                },
              });
        }
      } else {
        this.state.caseTitleChange
          ? updateShipment({
              variables: {
                params: {
                  _id: caseId,
                },
                updateShipmentSla: {
                  datamaster_sla_title: this.state.caseTitleId,
                  datamaster_sla_level: this.state.caseLevelId,
                  sla_issue: this.state.InformRecieveDateTime,
                  sla_description: this.state.caseDetail,
                  sla_file: this.state.attachFile,
                  sla_channel: this.state.caseRecieveChanel.value,
                  customer_email: this.state.mailInformer,
                  customer_phone: this.state.phoneInformer,
                },
              },
            })
          : updateShipment({
              variables: {
                params: {
                  _id: caseId,
                },
                updateShipmentSla: {
                  sla_issue: this.state.InformRecieveDateTime,
                  sla_description: this.state.caseDetail,
                  sla_file: this.state.attachFile,
                  sla_channel: this.state.caseRecieveChanel.value,
                  customer_email: this.state.mailInformer,
                  customer_phone: this.state.phoneInformer,
                },
              },
            });
      }
    } else {
      console.log('Invalid');
    }
  };

  handlerCheckSecondCaseInvalid = () => {
    const dateInvalid = this.state.customerReplyDateTime
      ? moment(this.state.customerReplyDateTime) > moment()
        ? true
        : false
      : true;
    this.setState({
      caseResolveDetailInvalid: this.state.caseResolveDetail ? false : true,
      customerReplyDateTimeInvalid: this.state.customerReplyDateTime
        ? moment(this.state.customerReplyDateTime) > moment()
          ? true
          : false
        : true,
    });

    return !(this.state.caseResolveDetail && this.state.customerReplyDateTime && !dateInvalid);
  };

  handlerSaveSecondCase = (updateShipment, caseId) => {
    if (!this.handlerCheckSecondCaseInvalid()) {
      this.setState({ saveCaseCheck: 2 });
      updateShipment({
        variables: {
          params: {
            _id: caseId,
          },
          updateShipmentSla: {
            respond_description: this.state.caseResolveDetail,
            respond_date: this.state.customerReplyDateTime,
            respond_file: this.state.attachSecondFile,
            respond_channel: this.state.caseAnswerChanel.value,
          },
        },
      });
    } else {
      console.log('second Invalid');
    }
  };

  handleDatePicker(event, mode) {
    if (event) {
      try {
        const date = `${event.format('YYYY-MM-DD HH:mm')}`;
        const show = `${event.format('DD/MM/YYYY HH:mm')}`;
        if (mode == 'receive') {
          this.setState({
            InformRecieveDateTime: date,
            InformRecieveDateTimeShow: show,
            InformRecieveDateTimeInvalid: false,
          });
        } else if ((mode = 'answer')) {
          this.setState({
            customerReplyDateTime: date,
            customerReplyDateTimeShow: show,
            customerReplyDateTimeInvalid: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (mode == 'receive') {
        this.setState({
          InformRecieveDateTime: '',
          InformRecieveDateTimeShow: '',
          InformRecieveDateTimeInvalid: false,
        });
      } else if ((mode = 'answer')) {
        this.setState({
          customerReplyDateTime: '',
          customerReplyDateTimeShow: '',
          customerReplyDateTimeInvalid: false,
        });
      }
    }
  }

  handlerChangeShipmentStatus = (status, refetch) => {
    this.setState(
      {
        stage:
          status == 100
            ? ''
            : status == 2
            ? 'pending'
            : status == 3 || status == 8 || status == 10
            ? 'inprogress'
            : status == 4
            ? 'completed'
            : status == 5
            ? 'cancel'
            : status == 6
            ? 'problem'
            : status == 0
            ? this.state.stage
            : '',
        shipment_status: status == 100 || status == 6 ? 0 : status,
        page_number: 1,
      },
      () => {
        refetch();
      }
    );
  };

  handlerChangeCaseStatus = (status, refetch) => {
    this.setState(
      {
        sla_max_level: status != 6 ? status : 0,
        level_status: status,
        sla_completed: status == 6 ? true : status == 0 ? null : false,
        page_number: 1,
      },
      () => {
        refetch();
      }
    );
  };

  handlerCaseStatus = (status, expire, complete) => {
    switch (status) {
      case 1:
        return <span style={{ color: '#FF2A2D', fontWeight: 'bold' }}>กำลังดำเนินการ</span>;
      case 2:
        if (moment(complete) > moment(expire)) {
          return (
            <span style={{ color: '#6DD634', fontWeight: 'bold' }}>
              แก้ไขแล้ว<span style={{ color: '#FF2A2D', fontWeight: 'bold' }}> (เกินเวลา)</span>{' '}
            </span>
          );
        }
        return <span style={{ color: '#6DD634', fontWeight: 'bold' }}>แก้ไขแล้ว</span>;
      default:
        return '';
    }
  };

  handleSingleUploadDocumentCase(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    // console.log(documentName)
    const timestamp = new Date().getTime();
    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/shipment/caseFile/${timestamp}/${documentName}`,
        },
      });
    }
  }

  getShipmentCase = async shipmentId => {
    try {
      const { data } = await client.query({
        query: shipmentSlasList,
        fetchPolicy: 'network-only',
        variables: {
          params: {
            shipment: shipmentId,
          },
          showData: 30,
        },
      });

      // console.log(data)

      data.shipmentSlas.totalDocument > 0 &&
        this.setState({
          shipmentSlas: data.shipmentSlas.shipmentSlas,
        });
    } catch (error) {
      console.log(error);
    }
  };

  getShipmentCaseDetail = async (caseId, admin) => {
    // console.log(caseId)

    try {
      const { data } = await client.query({
        query: shipmentSlaList,
        fetchPolicy: 'network-only',
        variables: {
          _id: caseId,
        },
      });

      // console.log(data)

      this.setState({
        caseIndex: data.shipmentSla.sla_case_number,
        caseStatus: data.shipmentSla.sla_status,
        newCase: false,
        editable: false,
        secondEditable: false,
        caseId: caseId,
        caseLevelDetail: data.shipmentSla.datamaster_sla_level
          ? caseLevelList.filter(item => item.level == data.shipmentSla.datamaster_sla_level.level)
          : '',
        expireDate: data.shipmentSla.sla_exp
          ? moment(data.shipmentSla.sla_exp).format('YYYY-MM-DD HH:mm:ss')
          : '',
        levelUnit: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.unit : '',
        levelTime: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.time : '',
        serviceTimeCase: data.shipmentSla.service_time ? data.shipmentSla.service_time : '',
        caseTitleChange: false,
        expireDateCheck: data.shipmentSla.sla_exp ? data.shipmentSla.sla_exp : null,
        completedDateChecK: data.shipmentSla.completed_date
          ? data.shipmentSla.completed_date
          : null,
        //card 1
        caseTitle: data.shipmentSla.title
          ? { value: data.shipmentSla.title, label: data.shipmentSla.title }
          : '',
        caseTitleId: data.shipmentSla.datamaster_sla_title
          ? data.shipmentSla.datamaster_sla_title._id
          : '',
        caseLevel: data.shipmentSla.level
          ? { value: data.shipmentSla.level, label: `Level ${data.shipmentSla.level}` }
          : '',
        caseLevelId: data.shipmentSla.datamaster_sla_level
          ? data.shipmentSla.datamaster_sla_level._id
          : '',

        caseDetail: data.shipmentSla.sla_description ? data.shipmentSla.sla_description : '',
        InformRecieveDateTime: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('YYYY-MM-DD HH:mm')
          : '',
        InformRecieveDateTimeShow: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('DD/MM/YYYY HH:mm')
          : '',
        attachFile: data.shipmentSla.sla_file ? data.shipmentSla.sla_file : [],
        caseOwner: data.shipmentSla.admin ? data.shipmentSla.admin.admin_username : admin,
        caseRecieveChanel: data.shipmentSla.sla_channel
          ? { value: data.shipmentSla.sla_channel, label: data.shipmentSla.sla_channel }
          : '',
        phoneInformer: data.shipmentSla.customer_phone ? data.shipmentSla.customer_phone : '',
        mailInformer: data.shipmentSla.customer_email ? data.shipmentSla.customer_email : '',

        // //card 2
        caseResolveDetail: data.shipmentSla.respond_description
          ? data.shipmentSla.respond_description
          : '',
        customerReplyDateTime: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('YYYY-MM-DD HH:mm')
          : '',
        customerReplyDateTimeShow: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('DD/MM/YYYY HH:mm')
          : '',
        attachSecondFile:
          data.shipmentSla.respond_file.length > 0 ? data.shipmentSla.respond_file : [],
        caseAnswer: data.shipmentSla.respond_admin
          ? data.shipmentSla.respond_admin.admin_username
          : admin,
        caseAnswerChanel: data.shipmentSla.respond_channel
          ? { value: data.shipmentSla.respond_channel, label: data.shipmentSla.respond_channel }
          : '',

        // //reset invalid
        caseTitleInvalid: false,
        caseLevelInvalid: false,
        caseDetailInvalid: false,
        caseRecieveChanelInvalid: false,
        caseOwnerInvalid: false,
        InformRecieveDateTimeInvalid: false,
        phoneInformerInvalid: false,
        mailInformerInvalid: false,

        caseResolveDetailInvalid: false,
        customerReplyDateTimeInvalid: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getShipmentCaseDetailCancelFirst = async (caseId, admin) => {
    // console.log(caseId)

    try {
      const { data } = await client.query({
        query: shipmentSlaList,
        fetchPolicy: 'network-only',
        variables: {
          _id: caseId,
        },
      });

      // console.log(data)

      this.setState({
        caseIndex: data.shipmentSla.sla_case_number,
        caseStatus: data.shipmentSla.sla_status,
        newCase: false,
        editable: false,
        caseId: caseId,
        caseLevelDetail: data.shipmentSla.datamaster_sla_level
          ? caseLevelList.filter(item => item.level == data.shipmentSla.datamaster_sla_level.level)
          : '',
        expireDate: data.shipmentSla.sla_exp
          ? moment(data.shipmentSla.sla_exp).format('YYYY-MM-DD HH:mm:ss')
          : '',
        levelUnit: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.unit : '',
        levelTime: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.time : '',
        serviceTimeCase: data.shipmentSla.service_time ? data.shipmentSla.service_time : '',
        caseTitleChange: false,
        expireDateCheck: data.shipmentSla.sla_exp ? data.shipmentSla.sla_exp : null,
        completedDateChecK: data.shipmentSla.completed_date
          ? data.shipmentSla.completed_date
          : null,
        //card 1
        caseTitle: data.shipmentSla.title
          ? { value: data.shipmentSla.title, label: data.shipmentSla.title }
          : '',
        caseTitleId: data.shipmentSla.datamaster_sla_title
          ? data.shipmentSla.datamaster_sla_title._id
          : '',
        caseLevel: data.shipmentSla.level
          ? { value: data.shipmentSla.level, label: `Level ${data.shipmentSla.level}` }
          : '',
        caseLevelId: data.shipmentSla.datamaster_sla_level
          ? data.shipmentSla.datamaster_sla_level._id
          : '',

        caseDetail: data.shipmentSla.sla_description ? data.shipmentSla.sla_description : '',
        InformRecieveDateTime: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('YYYY-MM-DD HH:mm')
          : '',
        InformRecieveDateTimeShow: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('DD/MM/YYYY HH:mm')
          : '',
        attachFile: data.shipmentSla.sla_file ? data.shipmentSla.sla_file : [],
        caseOwner: data.shipmentSla.admin ? data.shipmentSla.admin.admin_username : admin,
        caseRecieveChanel: data.shipmentSla.sla_channel
          ? { value: data.shipmentSla.sla_channel, label: data.shipmentSla.sla_channel }
          : '',
        phoneInformer: data.shipmentSla.customer_phone ? data.shipmentSla.customer_phone : '',
        mailInformer: data.shipmentSla.customer_email ? data.shipmentSla.customer_email : '',

        // //reset invalid
        caseTitleInvalid: false,
        caseLevelInvalid: false,
        caseDetailInvalid: false,
        caseRecieveChanelInvalid: false,
        caseOwnerInvalid: false,
        InformRecieveDateTimeInvalid: false,
        phoneInformerInvalid: false,
        mailInformerInvalid: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getShipmentCaseDetailCancelSecond = async (caseId, admin) => {
    // console.log(caseId)

    try {
      const { data } = await client.query({
        query: shipmentSlaList,
        fetchPolicy: 'network-only',
        variables: {
          _id: caseId,
        },
      });

      // console.log(data)

      this.setState({
        secondEditable: false,
        // card 1
        caseStatus: data.shipmentSla.sla_status,
        // //card 2
        caseResolveDetail: data.shipmentSla.respond_description
          ? data.shipmentSla.respond_description
          : '',
        customerReplyDateTime: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('YYYY-MM-DD HH:mm')
          : '',
        customerReplyDateTimeShow: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('DD/MM/YYYY HH:mm')
          : '',
        attachSecondFile:
          data.shipmentSla.respond_file.length > 0 ? data.shipmentSla.respond_file : [],
        caseAnswer: data.shipmentSla.respond_admin
          ? data.shipmentSla.respond_admin.admin_username
          : admin,
        caseAnswerChanel: data.shipmentSla.respond_channel
          ? { value: data.shipmentSla.respond_channel, label: data.shipmentSla.respond_channel }
          : '',

        // //reset invalid

        caseResolveDetailInvalid: false,
        customerReplyDateTimeInvalid: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderCaseTitleList(data) {
    caseTitlelist = data.slaTitles.map(item => {
      return {
        value: item.title,
        label: item.title,
        level: item.level,
        levelId: item.datamaster_sla_level,
        titleId: item._id,
      };
    });
  }

  renderCaseLevelList(data) {
    caseLevelList = data.slaLevels;
  }

  renderServiceTime = status => {
    switch (status) {
      case 1:
        return <span>ทุกวัน ( 7 วัน 24 ชั่วโมง )</span>;
      case 2:
        return <span>จันทร์ - ศุกร์ ( 09:00 - 18:00 น. )</span>;
      default:
        return '';
    }
  };

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  renderProblemStatus = (completed, level, total) => {
    // console.log(completed, level, total);
    if (level === 1 && completed === false) {
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          ปัญหา:{' '}
          <Triangle style={{ borderColor: 'transparent transparent #A6ACAF transparent' }}>
            <p>{total}</p>
            <span>Lv.1</span>
          </Triangle>
        </span>
      );
    } else if (level === 2 && completed === false) {
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          ปัญหา:{' '}
          <Triangle style={{ borderColor: 'transparent transparent #F39C12 transparent' }}>
            <p>{total}</p>
            <span>Lv.2</span>
          </Triangle>
        </span>
      );
    } else if (level === 3 && completed === false) {
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          ปัญหา:{' '}
          <Triangle style={{ borderColor: 'transparent transparent #6E2C00 transparent' }}>
            <p>{total}</p>
            <span>Lv.3</span>
          </Triangle>
        </span>
      );
    } else if (level === 4 && completed === false) {
      return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          ปัญหา:{' '}
          <span>
            {' '}
            <Triangle style={{ borderColor: 'transparent transparent #7F4099 transparent' }}>
              <p>{total}</p>
              <span>Lv.4</span>
            </Triangle>
          </span>
        </div>
      );
    } else if (level === 5 && completed === false) {
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          ปัญหา:{' '}
          <Triangle style={{ borderColor: 'transparent transparent #FF3333 transparent' }}>
            <p>{total}</p>
            <span> Lv.5</span>{' '}
          </Triangle>
        </span>
      );
    } else if (completed === true) {
      return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
          ปัญหา:{' '}
          <i
            style={{ color: '#76D233', marginLeft: '5px', marginRight: '5px', marginBottom: '5px' }}
            class="fas fa-thumbs-up"
          ></i>{' '}
          แก้ไขปัญหาแล้ว
        </span>
      );
    } else {
      return '';
    }
  };

  checkEmail = email => {
    const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validateEmail.test(email)) {
      return true;
    }

    return false;
  };

  exceedCountdownDate = async (caseId, shipmentId) => {
    try {
      if (caseId !== null) {
        const { data } = await client.query({
          query: shipmentSlaList,
          fetchPolicy: 'network-only',
          variables: {
            _id: caseId,
          },
        });

        this.setState({
          caseStatus: data.shipmentSla.sla_status,
        });
      }

      if (shipmentId !== null) {
        const { data } = await client.query({
          query: shipmentSlasList,
          fetchPolicy: 'network-only',
          variables: {
            params: {
              shipment: shipmentId,
            },
            showData: 30,
          },
        });

        data.shipmentSlas.totalDocument > 0 &&
          this.setState({
            shipmentSlas: data.shipmentSlas.shipmentSlas,
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  refetchCaseLevelAndTitle = async () => {
    try {
      const { data: titles } = await client.query({
        query: datamasterSlaTitlesList,
        fetchPolicy: 'network-only',
        variables: {
          params: {
            status_delete: false,
          },
        },
      });

      this.renderCaseTitleList(titles.datamasterSlaTitles);

      const { data: levels } = await client.query({
        query: datamasterSlaLevelsList,
        fetchPolicy: 'network-only',
      });

      this.renderCaseLevelList(levels.datamasterSlaLevels);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { viewImageSrc, isOpenLightbox, photoIndex } = this.state;
    const shipmentId = this.props.location.search.split('?shipment=')[1];

    if (
      this.props.datamasterSlaTitlesList.loading == false &&
      this.props.datamasterSlaTitlesList.datamasterSlaTitles != undefined
    ) {
      this.renderCaseTitleList(this.props.datamasterSlaTitlesList.datamasterSlaTitles);
    }

    if (
      this.props.datamasterSlaLevelsList.loading == false &&
      this.props.datamasterSlaLevelsList.datamasterSlaLevels != undefined
    ) {
      this.renderCaseLevelList(this.props.datamasterSlaLevelsList.datamasterSlaLevels);
    }

    let admin = '';
    let admin_id = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin = this.props.adminName.adminList.admin[0].admin_username;
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }

    //console.log(shipmentId);
    return (
      <div>
        {isOpenLightbox ? (
          viewImageSrc.length > 1 ? (
            <Lightbox
              mainSrc={viewImageSrc[photoIndex]}
              nextSrc={viewImageSrc[(photoIndex + 1) % viewImageSrc.length]}
              prevSrc={viewImageSrc[(photoIndex + viewImageSrc.length - 1) % viewImageSrc.length]}
              onCloseRequest={() => this.setState({ isOpenLightbox: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + viewImageSrc.length - 1) % viewImageSrc.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % viewImageSrc.length,
                })
              }
            />
          ) : (
            <Lightbox
              mainSrc={viewImageSrc[photoIndex]}
              onCloseRequest={() => this.setState({ isOpenLightbox: false })}
            />
          )
        ) : null}
        <div className="content">
          <Grid fluid>
            <Row>
              <Query
                query={shipmentAdminListQuery}
                variables={{
                  params: {
                    _id: shipmentId,
                  },
                  page: 1,
                  showData: 30,
                }}
              >
                {({
                  loading: loadingShipmentAdmin,
                  error: errorShipmentAdmin,
                  data: dateShipmentAdmin,
                  refetch: refetchShipmentadmin,
                  variables,
                }) => {
                  let shipmentAdmin = null;
                  dateShipmentAdmin.shipmentAdmins &&
                    dateShipmentAdmin.shipmentAdmins.shipments.length > 0 &&
                    (shipmentAdmin = dateShipmentAdmin.shipmentAdmins.shipments[0]);
                  return (
                    <Query
                      query={detailShipment}
                      variables={{
                        _id: shipmentId,
                      }}
                    >
                      {({ loading, error, data, refetch: refetchdetailShipment, variables }) => {
                        if (loading) return 'Loading...';
                        if (error) return { error };
                        const shipment = data.shipmentList.shipment;

                        const documents = shipment[0].shipment_other_detail.document.map(item => {
                          const patternFileType = /\.[0-9a-z]{1,5}$/;
                          const patternCheckFileType = /^.*\.(pdf|PDF)$/;
                          const thumbnail = patternCheckFileType.test(item.path)
                            ? `${require('./../../assets/img/file-type-pdf.png')}`
                            : `${s3}/${item.path}`;
                          const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

                          return {
                            originalPath: item.path,
                            path: `${s3}/${item.path}`, //path ไฟล์
                            fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
                            type: type, //ประเภทไฟล์
                            thumbnail: thumbnail, //รูปตัวอย่าง
                          };
                        });

                        const documentsImage = documents.map(item => {
                          const patternCheckFileType = /^.*\.(pdf|PDF)$/;
                          if (patternCheckFileType.test(item.path)) {
                            return <PDFPreview file={item.path} />;
                          }

                          return item.thumbnail;
                        });

                        let multidropPrice = 0;
                        if (
                          shipment[0].shipment_address.length > 2 &&
                          shipment[0].round_trip !== 1 &&
                          shipment[0].shipment_type == 1
                        ) {
                          if (shipment[0].multi_point.length > 0) {
                            multidropPrice = shipment[0].multi_point.reduce((sum, item) => {
                              return sum + item.price_per_point * item.total_point;
                            }, 0);
                          }
                        }

                        //console.log(shipment[0]);

                        return (
                          <Col md={12} className="card-shipment">
                            <div style={{ margin: '5px -15px 0' }}>
                              <HeaderContainer>
                                <div className="sub-header-left">
                                  <a href="/shipment" className="btn-back">
                                    <i className="far fa-arrow-left"></i> กลับ
                                  </a>
                                  <div className="line"></div>
                                  <div className="menu-wrapper">
                                    <ul>
                                      <li className="active">
                                        <a
                                          href={`/shipmentdetail?shipment=${this.state.paramsShipmentId}`}
                                        >
                                          {' '}
                                          <i className="fal fa-truck"></i> ข้อมูลงานขนส่ง
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          href={`/shipmentcost?shipment=${this.state.paramsShipmentId}`}
                                        >
                                          {' '}
                                          <i className="fal fa-sack-dollar"></i> ต้นทุน - ราคาขาย
                                        </a>
                                      </li>
                                      {/*     <li>
                                        <a href={`/shipmentpvc?shipment=${this.state.paramsShipmentId}`}>
                                          {' '}
                                          <i className="fal fa-money-check-alt"></i> รายการสั่งจ่าย
                                  </a>
                                      </li>
                                      
                                  <li>
                                      <a href={`/shipmentpayment?shipment=${this.state.paramsShipmentId}`}> <i className="fal fa-file-invoice-dollar"></i> ใบแจ้งหนี้-เพิ่มหนี้</a>
                                  </li>
                                  */}
                                    </ul>
                                  </div>
                                </div>
                              </HeaderContainer>
                              <SubHeaderContainer>
                                <div className="sub-header-left">
                                  <strong>
                                    {shipment[0].shipment_number
                                      ? `รหัสการขนส่ง: ${shipment[0].shipment_number}`
                                      : 'แบบร่าง'}
                                  </strong>
                                  {shipment[0].detail_from_tms.inv_number && (
                                    <strong style={{ marginLeft: '20px' }}>
                                      เลขที่ใบแจ้งหนี้: {shipment[0].detail_from_tms.inv_number}
                                    </strong>
                                  )}
                                  {shipment[0].detail_from_tms.rec_number && (
                                    <strong style={{ marginLeft: '20px' }}>
                                      เลขที่ใบเสร็จ: {shipment[0].detail_from_tms.rec_number}
                                    </strong>
                                  )}

                                  <Query
                                    query={summaryInvoiceList}
                                    variables={{
                                      shipment_id: this.state.paramsShipmentId,
                                    }}
                                  >
                                    {({ loading, error, data, refetch, variables }) => {
                                      if (loading)
                                        return <div style={{ marginLeft: 20 }}>Loading...</div>;
                                      if (error) return { error };

                                      const summaryInvoice =
                                        data.summaryInvoiceList.summaryInvoice[0];

                                      return summaryInvoice ? (
                                        <div style={{ marginLeft: 20 }}>
                                          <a
                                            href={`/paymentdetail?id=${summaryInvoice._id}&type=invoice`}
                                            target="_blank"
                                          >
                                            <strong>
                                              {summaryInvoice &&
                                                `เลขที่ใบแจ้งหนี้: ${summaryInvoice.summary_invoice_number}`}
                                            </strong>
                                          </a>
                                        </div>
                                      ) : null;
                                    }}
                                  </Query>
                                </div>
                                <div className="sub-header-right">
                                  <div style={{ float: 'right', marginLeft: '15px' }}>
                                    {(shipment[0].paid_type == 1 || shipment[0].paid_type == 2) && (
                                      <Button
                                        onClick={() =>
                                          this.handleShowInvoicesandReceipts(shipment[0])
                                        }
                                      >
                                        รหัสใบแจ้งหนี้ / ใบเสร็จ TMS
                                      </Button>
                                    )}

                                    {shipment[0].shipment_status != 4 &&
                                      shipment[0].shipment_status != 5 && (
                                        <Button onClick={() => this.handleShowcancel(shipment[0])}>
                                          {' '}
                                          <i className="fal fa-times"></i> ยกเลิกงานขนส่ง{' '}
                                        </Button>
                                      )}
                                  </div>
                                </div>
                              </SubHeaderContainer>
                            </div>
                            <Card
                              ctTableFullWidth
                              ctTableResponsive
                              content={
                                <Query
                                  query={invoiceBusinessListQuery}
                                  variables={{
                                    shipment_id: shipment[0]._id,
                                  }}
                                >
                                  {({
                                    loading,
                                    error,
                                    data,
                                    refetch: refetchInvoiceBusinessListQuery,
                                  }) => {
                                    if (loading) return null;
                                    if (error) return null;
                                    // console.log(variables);refetchInvoiceBusinessListQuery
                                    const invoiceBusinessLoading = loading;
                                    const invoiceBusinessError = error;
                                    const invoiceBusinessList = data.invoiceBusinessList;

                                    return (
                                      <div className="Row">
                                        <Query
                                          query={trackingList}
                                          variables={{
                                            shipment_id: shipmentId,
                                            check_agent_accept: shipment[0].transporter,
                                          }}
                                        >
                                          {({
                                            loading,
                                            error,
                                            data,
                                            refetch: refetchTrackingList,
                                            variables,
                                          }) => {
                                            if (loading) return 'Loading...';
                                            if (error) return 'แบบร่าง';
                                            const tracking = data.trackingList.tracking;
                                            diverWorking =
                                              tracking.length > 0
                                                ? tracking[0].working &&
                                                  !this.checkPODProcess(shipment[0], tracking[0])
                                                : false;
                                            return (
                                              <div className="box-content map">
                                                <ShipmentMap shipmentId={shipmentId} />
                                              </div>
                                            );
                                          }}
                                        </Query>

                                        {/* รายละเอียดค่าขนส่ง */}
                                        <div className="box-content shipment">
                                          <div className="inner">
                                            <h5>
                                              <b>รายละเอียดค่าขนส่ง</b>
                                            </h5>
                                            <hr style={{ margin: '0 0 10px' }} />
                                            <Query
                                              query={invoiceListQuery}
                                              variables={{
                                                shipment_id: shipmentId,
                                              }}
                                            >
                                              {({
                                                loading,
                                                error,
                                                data,
                                                refetch: refetchInvoiceListQuery,
                                                variables,
                                              }) => {
                                                if (loading) return 'Loading...';
                                                const invoice = data.invoiceList;
                                                const invoiceData =
                                                  invoice[0].invoice_business_list;
                                                //console.log(invoice);
                                                if (invoice.length > 0) {
                                                  return (
                                                    <div>
                                                      <RowItemIcon>
                                                        {!!this.renderPayTypeIcon(
                                                          shipment[0].paid_type
                                                        ) && (
                                                          <div className="icon">
                                                            <i
                                                              class={this.renderPayTypeIcon(
                                                                shipment[0].paid_type
                                                              )}
                                                            ></i>
                                                          </div>
                                                        )}
                                                        <div className="left">
                                                          <div className="">ชำระเงินโดย:</div>
                                                          <div className="text-bold">
                                                            {this.renderPayType(
                                                              shipment[0].paid_type
                                                            )}
                                                          </div>
                                                        </div>
                                                        {/* <div className="right">
                                                                                                                <div onClick={() => this.setState({isOpenModalEditPayment: true})}>แก้ไขรายละเอียด</div>
                                                                                                                <i className="fal fa-angle-right"></i>
                                                                                                            </div> */}
                                                      </RowItemIcon>

                                                      <ModalConfirm
                                                        show={this.state.isOpenModalEditPayment}
                                                        onPressButtonCancel={() =>
                                                          this.setState({
                                                            isOpenModalEditPayment: false,
                                                          })
                                                        }
                                                        onPressButtonConfirm={() =>
                                                          this.setState({
                                                            isOpenModalEditPayment: false,
                                                          })
                                                        }
                                                        labelButtonConfirm={`ตกลง`}
                                                        labelButtonCancel={`กลับ`}
                                                        styleColorButtonConfirm="primary"
                                                        titleHeader={`แก้ไขช่องทางการชำระเงิน`}
                                                        subTitle={`คุณต้องการช่องทางชำระเงินให้กด "ตกลง"`}
                                                        onHide={() =>
                                                          this.setState({
                                                            isOpenModalEditPayment: false,
                                                          })
                                                        }
                                                      >
                                                        <div style={{ lineHeight: 1 }}>
                                                          <div style={{ marginBottom: 10 }}>
                                                            ชำระเงินโดย:
                                                          </div>
                                                          <FormControl
                                                            componentClass="select"
                                                            placeholder="select"
                                                            defaultValue={
                                                              this.state.selectEditPayment
                                                            }
                                                            onChange={event =>
                                                              this.setState({
                                                                selectEditPayment:
                                                                  event.target.value,
                                                              })
                                                            }
                                                            name="selectEditPayment"
                                                          >
                                                            <option value={null}>
                                                              -- เลือกช่องทางชำระเงิน --
                                                            </option>
                                                            <option value={0}>เงินสด</option>
                                                            <option value={1}>
                                                              บัตรเดบิต/เครดิต
                                                            </option>
                                                            <option value={2}>ใบแจ้งหนี้</option>
                                                          </FormControl>
                                                        </div>
                                                      </ModalConfirm>

                                                      {/* {
                                                                                                            shipment[0].paid_type == 3 && (
                                                                                                                <RowItem>
                                                                                                                    <div className="left">เลขที่ใบแจ้งหนี้:</div>
                                                                                                                    <div className="right">INVG2019050003</div>
                                                                                                                </RowItem>
                                                                                                            )
                                                                                                        }
                                                                                                    
                                                                                                        <RowItem>
                                                                                                            <div className="left">สถานะ</div>
                                                                                                            <div className="right">{this.renderStatusPayment(shipment[0].paid_status)}</div>
                                                                                                        </RowItem> */}
                                                      <Line />

                                                      <RowItem>
                                                        <div className="left">
                                                          <b>ค่าขนส่ง</b>
                                                        </div>
                                                      </RowItem>
                                                      {invoiceData.map(
                                                        (list, key) =>
                                                          list.datamaster_service_category_prefix_id !=
                                                            '5e54cef8e99db3b888a2b749' &&
                                                          list.datamaster_service_category_prefix_id !=
                                                            '5e54cef8e99db3b888a2b755' &&
                                                          key == 0 && (
                                                            <RowItem key={key}>
                                                              <div className="left ">
                                                                <span className="ellipsis">
                                                                  {
                                                                    list.invoice_business_list_detail
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="right">
                                                                {list.invoice_business_list_total_price
                                                                  ? (
                                                                      list.invoice_business_list_total_price -
                                                                      multidropPrice
                                                                    ).toLocaleString('en-US', {
                                                                      maximumFractionDigits: 2,
                                                                    })
                                                                  : 0}{' '}
                                                                บาท
                                                              </div>
                                                            </RowItem>
                                                          )
                                                      )}
                                                      {shipment[0].shipment_address.length > 2 &&
                                                      shipment[0].round_trip !== 1 &&
                                                      shipment[0].shipment_type == 1 &&
                                                      shipment[0].multi_point.length > 0 ? (
                                                        <div>
                                                          <RowItem>
                                                            <div className="left">
                                                              ค่าบริการส่งสินค้าหลายจุด
                                                            </div>
                                                          </RowItem>
                                                          <SubList>
                                                            <div className="title">
                                                              จากจุดรับสินค้า - จุดส่งสินค้าที่ 2
                                                            </div>
                                                            <div className="detail">ฟรี</div>
                                                          </SubList>
                                                          {shipment[0].multi_point.length > 0 &&
                                                            shipment[0].multi_point.filter(
                                                              item => item.distance_point === 1
                                                            )[0].total_point > 0 &&
                                                            shipment[0].multi_point
                                                              .filter(
                                                                item => item.distance_point === 1
                                                              )
                                                              .map(item => (
                                                                <SubList>
                                                                  <div className="title">
                                                                    ระยะทางไม่เกิน 50 กม. [
                                                                    {item.price_per_point} x{' '}
                                                                    {item.total_point}]
                                                                  </div>
                                                                  <div className="detail">
                                                                    {`${(
                                                                      item.price_per_point *
                                                                      item.total_point
                                                                    ).toLocaleString('en-US', {
                                                                      maximumFractionDigits: 2,
                                                                    })}`}{' '}
                                                                    บาท
                                                                  </div>
                                                                </SubList>
                                                              ))}

                                                          {shipment[0].multi_point.length > 0 &&
                                                            shipment[0].multi_point.filter(
                                                              item => item.distance_point === 2
                                                            )[0].total_point > 0 &&
                                                            shipment[0].multi_point
                                                              .filter(
                                                                item => item.distance_point === 2
                                                              )
                                                              .map(item => (
                                                                <SubList>
                                                                  <div className="title">
                                                                    ระยะทางมากกว่า 50 กม. [
                                                                    {item.price_per_point} x{' '}
                                                                    {item.total_point}]
                                                                  </div>
                                                                  <div className="detail">
                                                                    {`${(
                                                                      item.price_per_point *
                                                                      item.total_point
                                                                    ).toLocaleString('en-US', {
                                                                      maximumFractionDigits: 2,
                                                                    })}`}{' '}
                                                                    บาท
                                                                  </div>
                                                                </SubList>
                                                              ))}

                                                          <RowItem>
                                                            <div className="left">
                                                              ค่าบริการส่งสินค้าหลายจุด (รวม)
                                                            </div>
                                                            <div className="right">
                                                              {multidropPrice.toLocaleString(
                                                                'en-US',
                                                                {
                                                                  maximumFractionDigits: 2,
                                                                }
                                                              )}{' '}
                                                              บาท
                                                            </div>
                                                          </RowItem>
                                                        </div>
                                                      ) : null}

                                                      {invoiceData.map(
                                                        (list, key) =>
                                                          list.datamaster_service_category_prefix_id !=
                                                            '5e54cef8e99db3b888a2b749' &&
                                                          list.datamaster_service_category_prefix_id !=
                                                            '5e54cef8e99db3b888a2b755' &&
                                                          key != 0 && (
                                                            <RowItem key={key}>
                                                              <div className="left ">
                                                                <span className="ellipsis">
                                                                  {
                                                                    list.invoice_business_list_detail
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="right">
                                                                {list.invoice_business_list_total_price
                                                                  ? (
                                                                      list.invoice_business_list_total_price -
                                                                      multidropPrice
                                                                    ).toLocaleString('en-US', {
                                                                      maximumFractionDigits: 2,
                                                                    })
                                                                  : 0}{' '}
                                                                บาท
                                                              </div>
                                                            </RowItem>
                                                          )
                                                      )}

                                                      {invoice[0].invoice_discount === null ||
                                                      invoice[0].invoice_discount.length === 0 ? (
                                                        invoice[0].promotion_code &&
                                                        invoice[0].promotion_code.name != null ? (
                                                          <RowItem>
                                                            <div className="left">
                                                              {shipment[0].flash_deals_id && (
                                                                <span className="label label-danger">
                                                                  <i className="fas fa-bolt"></i>
                                                                </span>
                                                              )}{' '}
                                                              ส่วนลด{' '}
                                                              {invoice[0].promotion_code.name} [
                                                              {this.renderPercentage(invoice[0])}%]
                                                            </div>
                                                            <div className="right">{`-${invoice[0].promotion_code.price.toLocaleString(
                                                              'en-US',
                                                              { maximumFractionDigits: 2 }
                                                            )}`}</div>
                                                          </RowItem>
                                                        ) : (
                                                          <RowItem>
                                                            <div className="left">ส่วนลด</div>
                                                            <div className="right">ไม่มี</div>
                                                          </RowItem>
                                                        )
                                                      ) : (
                                                        invoice[0].invoice_discount.map(
                                                          (Discount, key) =>
                                                            Discount.discount_type == 1 && (
                                                              <RowItem>
                                                                <div className="left">
                                                                  {shipment[0].flash_deals_id && (
                                                                    <span className="label label-danger">
                                                                      <i className="fas fa-bolt"></i>
                                                                    </span>
                                                                  )}{' '}
                                                                  {`${Discount.detail} [${Discount.percent}%]`}
                                                                </div>
                                                                <div className="right">{`- ${Discount.price.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )} บาท`}</div>
                                                              </RowItem>
                                                            )
                                                        )
                                                      )}

                                                      {invoice[0].invoice_discount === null ||
                                                      invoice[0].invoice_discount.length === 0
                                                        ? null
                                                        : invoice[0].invoice_discount.map(
                                                            (Discount, key) =>
                                                              Discount.discount_type == 2 && (
                                                                <RowItem key={key}>
                                                                  <div className="left">
                                                                    {Discount.detail}
                                                                  </div>
                                                                  <div className="right">{`- ${Discount.price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )} บาท`}</div>
                                                                </RowItem>
                                                              )
                                                          )}

                                                      <Line />

                                                      {invoiceData.filter(
                                                        item =>
                                                          item.datamaster_service_category_prefix_id ==
                                                          '5e54cef8e99db3b888a2b749'
                                                      ).length > 0 && (
                                                        <RowItem>
                                                          <div className="left">
                                                            <b>ค่าบริการเสริม</b>
                                                          </div>
                                                        </RowItem>
                                                      )}
                                                      {invoiceData.map(
                                                        (list, key) =>
                                                          list.datamaster_service_category_prefix_id ==
                                                            '5e54cef8e99db3b888a2b749' && (
                                                            <RowItem key={key}>
                                                              <div className="left ">
                                                                <span className="ellipsis">
                                                                  {
                                                                    list.invoice_business_list_detail
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="right">
                                                                {list.invoice_business_list_total_price
                                                                  ? list.invoice_business_list_total_price.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )
                                                                  : 0}{' '}
                                                                บาท
                                                              </div>
                                                            </RowItem>
                                                          )
                                                      )}

                                                      {invoiceData.filter(
                                                        item =>
                                                          item.datamaster_service_category_prefix_id ==
                                                          '5e54cef8e99db3b888a2b749'
                                                      ).length > 0 && <Line />}

                                                      {invoiceData.filter(
                                                        item =>
                                                          item.datamaster_service_category_prefix_id ==
                                                          '5e54cef8e99db3b888a2b755'
                                                      ).length > 0 && (
                                                        <RowItem>
                                                          <div className="left">
                                                            <b>ค่าสำรองจ่าย</b>
                                                          </div>
                                                        </RowItem>
                                                      )}

                                                      {invoiceData.map(
                                                        (list, key) =>
                                                          list.datamaster_service_category_prefix_id ==
                                                            '5e54cef8e99db3b888a2b755' && (
                                                            <RowItem key={key}>
                                                              <div className="left ">
                                                                <span className="ellipsis">
                                                                  {
                                                                    list.invoice_business_list_detail
                                                                  }
                                                                </span>
                                                              </div>
                                                              <div className="right">
                                                                {list.invoice_business_list_total_price
                                                                  ? list.invoice_business_list_total_price.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )
                                                                  : 0}{' '}
                                                                บาท
                                                              </div>
                                                            </RowItem>
                                                          )
                                                      )}

                                                      {invoiceData.filter(
                                                        item =>
                                                          item.datamaster_service_category_prefix_id ==
                                                          '5e54cef8e99db3b888a2b755'
                                                      ).length > 0 && <Line />}

                                                      <RowItem>
                                                        <div className="left">
                                                          (1) รวมค่าขนส่งและค่าบริการ หลังหักส่วนลด
                                                        </div>
                                                        <div className="right">
                                                          {invoice[0].total_price
                                                            ? invoice[0].total_price % 1 === 0
                                                              ? invoice[0].total_price.toLocaleString(
                                                                  'en-US'
                                                                )
                                                              : invoice[0].total_price.toLocaleString(
                                                                  'en-US',
                                                                  { minimumFractionDigits: 2 }
                                                                )
                                                            : 0}{' '}
                                                          บาท
                                                        </div>
                                                      </RowItem>

                                                      <Line />

                                                      <RowItem>
                                                        <div className="left">
                                                          (2) ภาษีมูลค่าเพิ่ม VAT (7% ค่าบริการ)
                                                        </div>
                                                        <div className="right">
                                                          {invoice[0].total_vat7
                                                            ? invoice[0].total_vat7 % 1 === 0
                                                              ? invoice[0].total_vat7.toLocaleString(
                                                                  'en-US'
                                                                )
                                                              : invoice[0].total_vat7.toLocaleString(
                                                                  'en-US',
                                                                  { minimumFractionDigits: 2 }
                                                                )
                                                            : 0}{' '}
                                                          บาท
                                                        </div>
                                                      </RowItem>

                                                      <RowItem>
                                                        <div className="left">
                                                          (3) ภาษีหัก ณ ที่จ่าย WHT (1% ค่าขนส่ง)
                                                        </div>
                                                        <div className="right">
                                                          {invoice[0].total_wht1
                                                            ? invoice[0].total_wht1 % 1 === 0
                                                              ? invoice[0].total_wht1.toLocaleString(
                                                                  'en-US'
                                                                )
                                                              : invoice[0].total_wht1.toLocaleString(
                                                                  'en-US',
                                                                  { minimumFractionDigits: 2 }
                                                                )
                                                            : 0}{' '}
                                                          บาท
                                                        </div>
                                                      </RowItem>

                                                      <RowItem>
                                                        <div className="left">
                                                          (4) ภาษีหัก ณ ที่จ่าย WHT (3% ค่าบริการ)
                                                        </div>
                                                        <div className="right">
                                                          {invoice[0].total_wht3
                                                            ? invoice[0].total_wht3 % 1 === 0
                                                              ? invoice[0].total_wht3.toLocaleString(
                                                                  'en-US'
                                                                )
                                                              : invoice[0].total_wht3.toLocaleString(
                                                                  'en-US',
                                                                  { minimumFractionDigits: 2 }
                                                                )
                                                            : 0}{' '}
                                                          บาท
                                                        </div>
                                                      </RowItem>
                                                      {invoice[0].total_wht5 > 0 && (
                                                        <RowItem>
                                                          <div className="left">
                                                            (5) ภาษีหัก ณ ที่จ่าย WHT (5% )
                                                          </div>
                                                          <div className="right">
                                                            {invoiceBusinessList.total_wht5
                                                              ? invoiceBusinessList.total_wht5 %
                                                                  1 ===
                                                                0
                                                                ? invoiceBusinessList.total_wht5.toLocaleString(
                                                                    'en-US'
                                                                  )
                                                                : invoiceBusinessList.total_wht5.toLocaleString(
                                                                    'en-US',
                                                                    { minimumFractionDigits: 2 }
                                                                  )
                                                              : 0}{' '}
                                                            บาท
                                                          </div>
                                                        </RowItem>
                                                      )}

                                                      <Line />

                                                      <RowItem>
                                                        <div className="left">
                                                          ยอดรวมภาษี (1)+(2)-(3)-(4)
                                                          {invoice[0].total_wht5 > 0 && '-(5)'}
                                                        </div>
                                                        <div className="right">
                                                          <b>
                                                            {invoice[0]
                                                              .invoice_business_grand_total_price
                                                              ? invoice[0]
                                                                  .invoice_business_grand_total_price %
                                                                  1 ===
                                                                0
                                                                ? invoice[0].invoice_business_grand_total_price.toLocaleString(
                                                                    'en-US'
                                                                  )
                                                                : invoice[0].invoice_business_grand_total_price.toLocaleString(
                                                                    'en-US',
                                                                    { minimumFractionDigits: 2 }
                                                                  )
                                                              : 0}{' '}
                                                            บาท
                                                          </b>
                                                        </div>
                                                      </RowItem>

                                                      {shipment[0].paid_type == 1 && <Line />}

                                                      {shipment[0].paid_type == 1 ? (
                                                        <RowItem>
                                                          <div className="left">
                                                            ค่าบริการชำระผ่านบัตรเครดิต
                                                          </div>
                                                          <div className="right">
                                                            {' '}
                                                            -{' '}
                                                            {(
                                                              (invoice[0]
                                                                .invoice_business_grand_total_price /
                                                                100) *
                                                                3.25 +
                                                              (((invoice[0]
                                                                .invoice_business_grand_total_price /
                                                                100) *
                                                                3.25) /
                                                                100) *
                                                                7
                                                            ).toLocaleString('en-US', {
                                                              maximumFractionDigits: 2,
                                                            })}
                                                          </div>
                                                        </RowItem>
                                                      ) : null}

                                                      <Line />
                                                      {shipment[0].paid_type == 1 ? (
                                                        <RowItem>
                                                          <div className="left">
                                                            {' '}
                                                            <b>รวมทั้งหมด</b>
                                                          </div>
                                                          <div className="right total">
                                                            {(
                                                              invoice[0]
                                                                .invoice_business_grand_total_price -
                                                              ((invoice[0]
                                                                .invoice_business_grand_total_price /
                                                                100) *
                                                                3.25 +
                                                                (((invoice[0]
                                                                  .invoice_business_grand_total_price /
                                                                  100) *
                                                                  3.25) /
                                                                  100) *
                                                                  7)
                                                            ).toLocaleString('en-US', {
                                                              minimumFractionDigits: 2,
                                                              maximumFractionDigits: 2,
                                                            })}{' '}
                                                            บาท
                                                          </div>
                                                        </RowItem>
                                                      ) : (
                                                        <RowItem>
                                                          <div className="left">
                                                            <b>รวมทั้งหมด</b>
                                                          </div>
                                                          <div className="right total">
                                                            {`${
                                                              invoice[0]
                                                                .invoice_business_grand_total_price %
                                                                1 ===
                                                              0
                                                                ? invoice[0].invoice_business_grand_total_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )
                                                                : invoice[0].invoice_business_grand_total_price.toLocaleString(
                                                                    'en-US',
                                                                    {
                                                                      maximumFractionDigits: 2,
                                                                      minimumFractionDigits: 2,
                                                                    }
                                                                  )
                                                            } บาท`}
                                                          </div>
                                                        </RowItem>
                                                      )}

                                                      {/* <Query
                                                                                                            query={shipmentCostList}
                                                                                                            variables={{
                                                                                                                shipment_id: shipmentId
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                ({ loading, error, data, refetch, variables }) => {
                                                                                                                    if (loading) return null;
                                                                                                                    if (error) return error;
                                                                                                                    const shipmentCostList = data.shipmentCostList;
                                                                                                                    
                                                                                                                    if(shipmentCostList != null)  { return (
                                                                                                                        <div>
                                                                                                                            <h5 style={{marginTop:0,marginBottom:10,paddingBottom:10,borderBottom:"1px solid #ddd"}}><b>ราคาต้นทุนขาย</b></h5>
                                                                                                                            
                                                                                                                            <RowItem>
                                                                                                                                <div className="left">ค่าบริการรถ</div>
                                                                                                                                <div className="right">{shipmentCostList.shipment_cost.toLocaleString('en-US', { maximumFractionDigits: 2 })} บาท</div>
                                                                                                                            </RowItem> 
                                                                                                                            <RowItem>
                                                                                                                                <div className="left">ค่าบริการเสริม</div>
                                                                                                                                <div className="right">{shipmentCostList.additional_cost.toLocaleString('en-US', { maximumFractionDigits: 2 })} บาท</div>
                                                                                                                            </RowItem> 

                                                                                                                            {shipmentCostList.backhaul_cost && (
                                                                                                                                <RowItem>
                                                                                                                                    <div className="left">ต้นทุน Backhaul</div>
                                                                                                                                    <div className="right">{shipmentCostList.backhaul_cost.toLocaleString('en-US', { maximumFractionDigits: 2 })} บาท</div>
                                                                                                                                </RowItem> 
                                                                                                                            )}
                                                                                                                            <BlankSpace />
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    return null;
                                                                                                                }
                                                                                                            }
                                                                                                        </Query>  */}
                                                    </div>
                                                  );
                                                } else {
                                                  if (shipment[0].paid_type === 3) {
                                                    return (
                                                      <RowItem>
                                                        <div className="left">ออกใบแจ้งหนี้</div>
                                                      </RowItem>
                                                    );
                                                  }
                                                  return (
                                                    <RowItem>
                                                      <div className="left">
                                                        ค่าบริการขนส่งไม่มีเนื่องจากเป็นแบบร่าง
                                                      </div>
                                                    </RowItem>
                                                  );
                                                }
                                              }}
                                            </Query>

                                            <BlankSpace />

                                            <h5
                                              style={{
                                                marginTop: 0,
                                                marginBottom: 10,
                                                paddingBottom: 10,
                                                borderBottom: '1px solid #ddd',
                                              }}
                                            >
                                              <b>ข้อมูลงานขนส่ง</b>
                                            </h5>
                                            {/* ข้อมูลบริการ*/}
                                            <Row>
                                              <Col md={12}>
                                                <RowItem>
                                                  <div className="left">รหัสงานขนส่ง:</div>
                                                  <div className="right">จองเมื่อ:</div>
                                                </RowItem>
                                                <RowItem>
                                                  <div className="left">
                                                    <b>{shipment[0].shipment_number}</b>
                                                  </div>
                                                  <div className="right">
                                                    <b>
                                                      <Moment format="DD/MM/YYYY HH:mm">
                                                        {shipment[0].create_date}
                                                      </Moment>
                                                    </b>
                                                  </div>
                                                </RowItem>

                                                <BoxWrapper>
                                                  <div className="inner-wrapper">
                                                    <RowItem>
                                                      <div className="left">ผู้ให้บริการขนส่ง:</div>
                                                    </RowItem>
                                                    <RowItem style={{ padding: '0px' }}>
                                                      <div className="left">
                                                        <b>บริษัท จิซทิกซ์ จำกัด</b>
                                                      </div>
                                                    </RowItem>
                                                  </div>
                                                </BoxWrapper>

                                                <RowItem style={{ marginTop: '10px' }}>
                                                  <div className="left">ลูกค้า:</div>
                                                  <div className="right">
                                                    {shipment[0].shipper_detail}
                                                  </div>
                                                </RowItem>
                                                <Query
                                                  query={accountInfo}
                                                  variables={{
                                                    _id: shipment[0].shipper,
                                                  }}
                                                >
                                                  {({ loading, error, data, variables }) => {
                                                    if ((loading, error)) return null;
                                                    if (data.accountInfoList != undefined) {
                                                      return (
                                                        <RowItem>
                                                          <div className="left">
                                                            เบอร์โทรติดต่อลูกค้า:
                                                          </div>
                                                          <div className="right">
                                                            (
                                                            {
                                                              data.accountInfoList.account_info[0]
                                                                .account_info_phone_code
                                                            }
                                                            ){' '}
                                                            {
                                                              data.accountInfoList.account_info[0]
                                                                .account_info_phone_number
                                                            }
                                                          </div>
                                                        </RowItem>
                                                      );
                                                    }
                                                    return null;
                                                  }}
                                                </Query>
                                                <RowItem>
                                                  <div className="left">ชื่อผู้จอง:</div>
                                                  <div className="right">
                                                    {shipment[0].account_user_display_name}
                                                  </div>
                                                </RowItem>
                                                <Line />

                                                <ShipmentItem>
                                                  <div className="icon">
                                                    <i className="fal fa-pallet"></i>
                                                  </div>
                                                  <div className="detail">
                                                    <span>รูปแบบการขนส่ง:</span>
                                                    <b>
                                                      {shipment[0].shipment_type == 1
                                                        ? 'ภายในประเทศ'
                                                        : shipment[0].shipment_type == 2
                                                        ? 'นำเข้า'
                                                        : shipment[0].shipment_type == 3
                                                        ? 'ส่งออก'
                                                        : 'ภายในประเทศ'}
                                                    </b>
                                                  </div>
                                                </ShipmentItem>

                                                <ShipmentItem>
                                                  <div className="icon">
                                                    <i className="fal fa-truck"></i>
                                                  </div>
                                                  <div className="detail">
                                                    <span>ประเภทรถขนส่ง</span>
                                                    <b>
                                                      {shipment[0].setting_truck_name.th} -{' '}
                                                      {shipment[0].truck_type_name.th} จำนวน{' '}
                                                      {shipment[0].truck_type_qty} คัน
                                                    </b>
                                                  </div>
                                                </ShipmentItem>
                                                {shipment[0].shipment_additional.length > 0 && (
                                                  <ShipmentItem>
                                                    <div className="icon">
                                                      <i className="fal fa-box"></i>
                                                    </div>
                                                    <div className="detail">
                                                      <span>บริการเสริม</span>
                                                      <b>
                                                        {this.renderAdditional(
                                                          shipment[0].shipment_additional
                                                        )}
                                                      </b>
                                                    </div>
                                                  </ShipmentItem>
                                                )}
                                                {shipment[0].shipment_accessory.length > 0 && (
                                                  <ShipmentItem>
                                                    <div className="icon"></div>
                                                    <div className="detail">
                                                      <span>อุปกรณ์นิรภัย</span>
                                                      <b>
                                                        {this.renderAccessory(
                                                          shipment[0].shipment_accessory
                                                        )}
                                                      </b>
                                                    </div>
                                                  </ShipmentItem>
                                                )}
                                                {this.chkAdditionalPod(shipment[0]) && (
                                                  <ShipmentItem>
                                                    <div className="icon"></div>
                                                    <div className="detail">
                                                      <span>ที่อยู่ในการจัดส่ง สำหรับ POD:</span>
                                                      <b>{this.renderAdditionalPod(shipment[0])}</b>
                                                    </div>
                                                  </ShipmentItem>
                                                )}

                                                <ShipmentItem>
                                                  <div className="icon">
                                                    <i className="fal fa-map-marked"></i>
                                                  </div>
                                                  <div className="detail">
                                                    <span>ระยะทาง</span>
                                                    <b>
                                                      {shipment[0].round_trip === 1
                                                        ? (shipment[0].distance * 2).toLocaleString(
                                                            'en-US',
                                                            {
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )
                                                        : shipment[0].distance.toLocaleString(
                                                            'en-US',
                                                            {
                                                              maximumFractionDigits: 2,
                                                            }
                                                          )}{' '}
                                                      กิโลเมตร
                                                    </b>
                                                  </div>
                                                </ShipmentItem>

                                                <ShipmentItem>
                                                  <div className="icon">
                                                    <i className="fal fa-clock"></i>
                                                  </div>
                                                  <div className="detail">
                                                    <span>วันที่เริ่มงานขนส่ง</span>
                                                    <b>
                                                      <Moment format="DD/MM/YYYY HH:mm">
                                                        {shipment[0].pick_up_date}
                                                      </Moment>
                                                    </b>
                                                    {shipment[0].shipment_status != 4 &&
                                                    shipment[0].shipment_status != 5 ? (
                                                      <ButtonEdit
                                                        bsStyle="link"
                                                        onClick={() =>
                                                          this.setState({
                                                            openEditPickupdate: true,
                                                            pick_up_date: moment(
                                                              shipment[0].pick_up_date
                                                            ).format('DD/MM/YYYY HH:mm'),
                                                          })
                                                        }
                                                      >
                                                        <i className="fal fa-calendar-edit"></i>
                                                      </ButtonEdit>
                                                    ) : null}
                                                  </div>
                                                </ShipmentItem>
                                              </Col>
                                            </Row>

                                            <BlankSpace />

                                            <Row>
                                              <Col md={12}>
                                                {/* จุดรับส่งสินค้า*/}
                                                <ShipmentItem>
                                                  <div className="detail" style={{ width: '100%' }}>
                                                    <b>จุดรับส่งสินค้า</b>
                                                    {shipment[0].shipment_status != 4 &&
                                                    shipment[0].shipment_status != 5 ? (
                                                      <ButtonEdit
                                                        bsStyle="link"
                                                        onClick={() =>
                                                          this.openEditContact(shipment[0])
                                                        }
                                                      >
                                                        <i className="fal fa-user-edit"></i>
                                                      </ButtonEdit>
                                                    ) : null}
                                                  </div>
                                                </ShipmentItem>

                                                {shipment[0].shipment_address.map((item, key) => (
                                                  <ShipmentItem key={key}>
                                                    {/* {console.log(item)} */}
                                                    <div className="icon address">
                                                      {item.mode == 'รับสินค้า' ? (
                                                        <i
                                                          className="fas fa-dot-circle"
                                                          style={{ color: '#000000' }}
                                                        ></i>
                                                      ) : (
                                                        <i
                                                          className="fas fa-map-marker-alt"
                                                          style={{ color: '#ED1C24' }}
                                                        ></i>
                                                      )}
                                                    </div>
                                                    <div
                                                      className={
                                                        key ==
                                                        shipment[0].shipment_address.length - 1
                                                          ? 'detail'
                                                          : 'detail-address'
                                                      }
                                                    >
                                                      <span>
                                                        {this.renderAddressMode(item.mode, key)}{' '}
                                                        {item.paid_by_cod == 1 && (
                                                          <b style={{ color: '#D90101' }}>
                                                            **รับชำระเงินสดที่จุดนี้{' '}
                                                          </b>
                                                        )}
                                                      </span>
                                                      <b>{item.address} </b>
                                                      {item.pick_up_date && (
                                                        <span style={{ paddingTop: '15px' }}>
                                                          {item.mode == 'รับตู้หนัก' ||
                                                          item.mode == 'รับตู้เบา'
                                                            ? `วันที่${item.mode}`
                                                            : `วัน/เวลา ที่${item.mode}`}
                                                          :{' '}
                                                          <b>
                                                            {item.mode == 'รับตู้หนัก' ||
                                                            item.mode == 'รับตู้เบา' ? (
                                                              <Moment format="DD/MM/YYYY">
                                                                {item.pick_up_date}
                                                              </Moment>
                                                            ) : (
                                                              <Moment format="DD/MM/YYYY HH:mm">
                                                                {item.pick_up_date}
                                                              </Moment>
                                                            )}
                                                          </b>
                                                        </span>
                                                      )}
                                                      {item.delivery_date && (
                                                        <span style={{ paddingTop: '15px' }}>
                                                          {`วัน/เวลา ที่${item.mode}`}:{' '}
                                                          <b>
                                                            <Moment format="DD/MM/YYYY HH:mm">
                                                              {item.delivery_date}
                                                            </Moment>
                                                          </b>
                                                        </span>
                                                      )}
                                                      <span style={{ paddingTop: '10px' }}>
                                                        ติดต่อ{' '}
                                                        <b>
                                                          {item.contact_name} , {item.contact_tel}
                                                        </b>
                                                      </span>

                                                      {item.remark ? (
                                                        <p style={{ paddingTop: '10px' }}>
                                                          หมายเหตุ <br />
                                                          <span
                                                            style={{
                                                              fontWeight: 600,
                                                              whiteSpace: 'pre-line',
                                                              wordBreak: 'break-all',
                                                            }}
                                                          >
                                                            {item.remark}
                                                          </span>
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                  </ShipmentItem>
                                                ))}
                                              </Col>
                                            </Row>

                                            {shipment[0].shipment_type === 2 ||
                                            shipment[0].shipment_type === 3 ? (
                                              <BlankSpace />
                                            ) : null}
                                            {shipment[0].shipment_type === 2 ||
                                            shipment[0].shipment_type === 3 ? (
                                              <Row>
                                                <Col md={12}>
                                                  <h5
                                                    style={{
                                                      marginTop: 10,
                                                      marginBottom: 20,
                                                      paddingBottom: 10,
                                                      borderBottom: '1px solid #ddd',
                                                    }}
                                                  >
                                                    <b>เอกสารสำคัญ</b>
                                                  </h5>

                                                  {/* เอกสาร Booking confirmation */}
                                                  {this.renderConfirmImage(
                                                    shipment[0],
                                                    'rightbox'
                                                  ) ? (
                                                    <ShipmentItem>
                                                      <div className="icon">
                                                        <i className="fal fa-clipboard-list"></i>
                                                      </div>
                                                      <div className="detail">
                                                        <span>เอกสาร Booking confirmation</span>
                                                        <p
                                                          style={{
                                                            whiteSpace: 'pre-line',
                                                            wordBreak: 'break-all',
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              color: '#4199F0',
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={() =>
                                                              this.setState({
                                                                isOpenConfirmImageLightBox: true,
                                                              })
                                                            }
                                                          >
                                                            Booking confirmation.pdf
                                                          </div>
                                                        </p>
                                                      </div>
                                                    </ShipmentItem>
                                                  ) : null}

                                                  {this.rendeReceiptImage(shipment[0], 'view')
                                                    .length ? (
                                                    <ShipmentItem>
                                                      <div className="icon">
                                                        <i className="fal fa-camera"></i>
                                                      </div>
                                                      <div className="detail">
                                                        <span>ข้อมูลการออกใบเสร็จค่าแอดวานซ์</span>
                                                        <Row>
                                                          <Col md={12}>
                                                            <ImageProductContainer>
                                                              {this.rendeReceiptImage(
                                                                shipment[0],
                                                                'view'
                                                              ).map((item, index) => (
                                                                <div
                                                                  className="image-product"
                                                                  onClick={() =>
                                                                    this.setState({
                                                                      otherImageIndexLightBox: index,
                                                                      isOpenPhotoOtherLightBox: true,
                                                                    })
                                                                  }
                                                                >
                                                                  <div className="image-product-hover"></div>
                                                                  <img
                                                                    src={`${item.thumbnail}`}
                                                                    alt="productImage"
                                                                  />
                                                                </div>
                                                              ))}
                                                            </ImageProductContainer>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </ShipmentItem>
                                                  ) : null}

                                                  {this.state.isOpenConfirmImageLightBox && (
                                                    <Lightbox
                                                      mainSrc={this.renderConfirmImage(
                                                        shipment[0],
                                                        'rightbox'
                                                      )}
                                                      onCloseRequest={() =>
                                                        this.setState({
                                                          isOpenConfirmImageLightBox: false,
                                                        })
                                                      }
                                                    />
                                                  )}
                                                  {this.state.isOpenPhotoOtherLightBox && (
                                                    <Lightbox
                                                      mainSrc={
                                                        this.rendeReceiptImage(
                                                          shipment[0],
                                                          'rightbox'
                                                        )[this.state.otherImageIndexLightBox]
                                                      }
                                                      nextSrc={
                                                        this.rendeReceiptImage(
                                                          shipment[0],
                                                          'rightbox'
                                                        ).length > 1
                                                          ? this.rendeReceiptImage(
                                                              shipment[0],
                                                              'rightbox'
                                                            )[
                                                              (this.state.otherImageIndexLightBox +
                                                                1) %
                                                                this.rendeReceiptImage(
                                                                  shipment[0],
                                                                  'rightbox'
                                                                ).length
                                                            ]
                                                          : undefined
                                                      }
                                                      prevSrc={
                                                        this.rendeReceiptImage(
                                                          shipment[0],
                                                          'rightbox'
                                                        ).length > 1
                                                          ? this.rendeReceiptImage(
                                                              shipment[0],
                                                              'rightbox'
                                                            )[
                                                              (this.state.otherImageIndexLightBox +
                                                                this.rendeReceiptImage(
                                                                  shipment[0],
                                                                  'rightbox'
                                                                ).length -
                                                                1) %
                                                                this.rendeReceiptImage(
                                                                  shipment[0],
                                                                  'rightbox'
                                                                ).length
                                                            ]
                                                          : undefined
                                                      }
                                                      onCloseRequest={() =>
                                                        this.setState({
                                                          isOpenPhotoOtherLightBox: false,
                                                        })
                                                      }
                                                      onMovePrevRequest={() =>
                                                        this.setState(prev => ({
                                                          otherImageIndexLightBox:
                                                            (prev.otherImageIndexLightBox +
                                                              this.rendeReceiptImage(
                                                                shipment[0],
                                                                'rightbox'
                                                              ).length -
                                                              1) %
                                                            this.rendeReceiptImage(
                                                              shipment[0],
                                                              'rightbox'
                                                            ).length,
                                                        }))
                                                      }
                                                      onMoveNextRequest={() =>
                                                        this.setState(prev => ({
                                                          otherImageIndexLightBox:
                                                            (prev.otherImageIndexLightBox + 1) %
                                                            this.rendeReceiptImage(
                                                              shipment[0],
                                                              'rightbox'
                                                            ).length,
                                                        }))
                                                      }
                                                    />
                                                  )}
                                                </Col>
                                              </Row>
                                            ) : null}

                                            <BlankSpace />
                                            <Row>
                                              <Col md={12}>
                                                <h5
                                                  style={{
                                                    marginTop: 10,
                                                    marginBottom: 20,
                                                    paddingBottom: 10,
                                                    borderBottom: '1px solid #ddd',
                                                  }}
                                                >
                                                  <b>เพิ่มเติม</b>
                                                </h5>

                                                {documents.length ? (
                                                  <ShipmentItem>
                                                    <div className="icon">
                                                      <i className="fal fa-camera"></i>
                                                    </div>
                                                    <div className="detail">
                                                      <span>{`รูปภาพสินค้า/เอกสาร (${
                                                        documents.length
                                                      }/${6}) :`}</span>
                                                      <Row>
                                                        <Col md={12}>
                                                          <ImageContainer>
                                                            {documents.map((item, index) => (
                                                              <div className="image">
                                                                <div className="image-hover">
                                                                  <div
                                                                    className="btn-hover-image"
                                                                    onClick={() => {
                                                                      this.setState({
                                                                        photoProductIndexLightBox: index,
                                                                        isOpenPhotoProductLightBox: true,
                                                                      });
                                                                    }}
                                                                  >
                                                                    <i className="fal fa-search"></i>
                                                                    <span>
                                                                      {item.type === 'image'
                                                                        ? 'ดูรูปภาพ'
                                                                        : 'ดูเอกสาร'}
                                                                    </span>
                                                                  </div>

                                                                  <div
                                                                    className="btn-hover-image"
                                                                    onClick={() => {
                                                                      this.setState({
                                                                        isOpenModalConfirmDeleteImageDocument: true,
                                                                        selectImageDelete:
                                                                          item.originalPath,
                                                                      });
                                                                    }}
                                                                  >
                                                                    <i className="fal fa-trash-alt"></i>
                                                                    <span>
                                                                      {item.type === 'image'
                                                                        ? 'ลบรูปภาพ'
                                                                        : 'ลบเอกสาร'}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                                <img
                                                                  src={`${item.thumbnail}`}
                                                                  alt=""
                                                                />
                                                              </div>
                                                            ))}

                                                            <Mutation
                                                              mutation={editShipment}
                                                              variables={{
                                                                shipmentID: shipment[0]._id,
                                                                user_ref: this.state
                                                                  .inputEditShipmentOtherDetailUserRef,
                                                                document: undefined,
                                                                remark: undefined,
                                                              }}
                                                              update={(cache, { data }) => {
                                                                if (data.shipmentsUpdate._id) {
                                                                  this.setState({
                                                                    isOpenModalEditShipmentOtherDetailUserRef: false,
                                                                  });
                                                                }
                                                              }}
                                                              refetchQueries={() => ['search']}
                                                            >
                                                              {editShipment => {
                                                                return (
                                                                  <ModalConfirm
                                                                    show={
                                                                      this.state
                                                                        .isOpenModalEditShipmentOtherDetailUserRef
                                                                    }
                                                                    onPressButtonCancel={() =>
                                                                      this.setState({
                                                                        isOpenModalEditShipmentOtherDetailUserRef: false,
                                                                      })
                                                                    }
                                                                    onPressButtonConfirm={() =>
                                                                      editShipment()
                                                                    }
                                                                    labelButtonConfirm={`ตกลง`}
                                                                    labelButtonCancel={`กลับ`}
                                                                    styleColorButtonConfirm="primary"
                                                                    titleHeader={`แก้รหัสอ้างอิงงานของคุณ`}
                                                                    subTitle={`คุณต้องการแก้ไขรหัสอ้างอิงงานของคุณให้กด "ตกลง"`}
                                                                    onHide={() =>
                                                                      this.setState({
                                                                        isOpenModalEditShipmentOtherDetailUserRef: false,
                                                                      })
                                                                    }
                                                                  >
                                                                    <div style={{ lineHeight: 1 }}>
                                                                      <div
                                                                        style={{ marginBottom: 10 }}
                                                                      >
                                                                        รหัสอ้างอิงงานของคุณ
                                                                      </div>
                                                                      <FormControl
                                                                        type="text"
                                                                        value={
                                                                          this.state
                                                                            .inputEditShipmentOtherDetailUserRef
                                                                        }
                                                                        placeholder="ระบุ"
                                                                        onChange={event =>
                                                                          this.setState({
                                                                            inputEditShipmentOtherDetailUserRef:
                                                                              event.target.value,
                                                                          })
                                                                        }
                                                                        name="shipmentOtherDetailUserRef"
                                                                      />
                                                                    </div>
                                                                  </ModalConfirm>
                                                                );
                                                              }}
                                                            </Mutation>

                                                            <Mutation
                                                              mutation={editShipment}
                                                              update={(cache, { data }) => {
                                                                if (data.shipmentsUpdate._id) {
                                                                  this.setState({
                                                                    isOpenModalEditShipmentOtherDetailUserRef: false,
                                                                  });
                                                                }
                                                              }}
                                                              refetchQueries={() => ['search']}
                                                            >
                                                              {editShipment => {
                                                                return (
                                                                  <UploadFile
                                                                    completed={path => {
                                                                      const filterDataDocument = shipment[0].shipment_other_detail.document.map(
                                                                        item => {
                                                                          return {
                                                                            path: item.path,
                                                                          };
                                                                        }
                                                                      );

                                                                      editShipment({
                                                                        variables: {
                                                                          shipmentID:
                                                                            shipment[0]._id,
                                                                          document: filterDataDocument.concat(
                                                                            { path: path }
                                                                          ),
                                                                          user_ref: undefined,
                                                                          remark: undefined,
                                                                        },
                                                                      });
                                                                    }}
                                                                  >
                                                                    {selectFile => {
                                                                      return (
                                                                        <React.Fragment>
                                                                          <input
                                                                            accept="image/jpeg, image/png, application/pdf"
                                                                            onChange={event => {
                                                                              const checkFileTypeImage = this.checkFileTypeImage(
                                                                                event.target
                                                                                  .files[0]
                                                                              );
                                                                              if (
                                                                                checkFileTypeImage ===
                                                                                false
                                                                              ) {
                                                                                this.setState({
                                                                                  isOpenModalAlertImage: true,
                                                                                  textAlertModalImage:
                                                                                    'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png, jpg และ pdf เท่านั้น',
                                                                                });
                                                                                return;
                                                                              }

                                                                              const checkFileSize = this.checkFileSizeImage(
                                                                                event.target
                                                                                  .files[0]
                                                                              );
                                                                              if (
                                                                                checkFileSize ===
                                                                                false
                                                                              ) {
                                                                                this.setState({
                                                                                  isOpenModalAlertImage: true,
                                                                                  textAlertModalImage:
                                                                                    'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 10MB',
                                                                                });
                                                                                return;
                                                                              }

                                                                              selectFile(
                                                                                event,
                                                                                `upload/shipments/document_${new Date().getTime()}`
                                                                              );
                                                                            }}
                                                                            type="file"
                                                                            id="file"
                                                                            ref={
                                                                              this._inputUploadFile
                                                                            }
                                                                            style={{
                                                                              display: 'none',
                                                                            }}
                                                                          />
                                                                          {shipment[0]
                                                                            .shipment_other_detail
                                                                            .document.length <
                                                                            6 && (
                                                                            <ButtonAddImage
                                                                              onClick={() =>
                                                                                this._inputUploadFile.current.click()
                                                                              }
                                                                            >
                                                                              <i className="fal fa-plus-circle"></i>
                                                                              <div>เพิ่มรูปภาพ</div>
                                                                            </ButtonAddImage>
                                                                          )}
                                                                        </React.Fragment>
                                                                      );
                                                                    }}
                                                                  </UploadFile>
                                                                );
                                                              }}
                                                            </Mutation>

                                                            <ModalConfirm
                                                              show={
                                                                this.state.isOpenModalAlertImage
                                                              }
                                                              onPressButtonConfirm={() =>
                                                                this.setState({
                                                                  isOpenModalAlertImage: false,
                                                                })
                                                              }
                                                              labelButtonConfirm={'ตกลง'}
                                                              titleHeader={'แจ้งเตือน'}
                                                              styleColorButtonConfirm="primary"
                                                              onHide={() =>
                                                                this.setState({
                                                                  isOpenModalAlertImage: false,
                                                                })
                                                              }
                                                            >
                                                              {this.state.textAlertModalImage}
                                                            </ModalConfirm>

                                                            <Mutation
                                                              mutation={editShipment}
                                                              update={() => {
                                                                this.setState({
                                                                  isOpenModalConfirmDeleteImageDocument: false,
                                                                });
                                                              }}
                                                              refetchQueries={() => ['search']}
                                                            >
                                                              {editShipmentDeleteDocument => {
                                                                return (
                                                                  <ModalConfirm
                                                                    show={
                                                                      this.state
                                                                        .isOpenModalConfirmDeleteImageDocument
                                                                    }
                                                                    onPressButtonConfirm={() => {
                                                                      const filterDataDocument = shipment[0].shipment_other_detail.document
                                                                        .map(item => {
                                                                          return {
                                                                            path: item.path,
                                                                          };
                                                                        })
                                                                        .filter(
                                                                          (item, i) =>
                                                                            item.path !==
                                                                            this.state
                                                                              .selectImageDelete
                                                                        );

                                                                      editShipmentDeleteDocument({
                                                                        variables: {
                                                                          shipmentID:
                                                                            shipment[0]._id,
                                                                          document: filterDataDocument,
                                                                          user_ref: undefined,
                                                                          remark: undefined,
                                                                        },
                                                                      });
                                                                    }}
                                                                    labelButtonConfirm={'ลบ'}
                                                                    styleColorButtonConfirm="primary"
                                                                    labelButtonCancel={'ยกเลิก'}
                                                                    onPressButtonCancel={() =>
                                                                      this.setState({
                                                                        isOpenModalConfirmDeleteImageDocument: false,
                                                                      })
                                                                    }
                                                                    titleHeader={'แจ้งเตือน'}
                                                                    styleColorButtonConfirm="primary"
                                                                    onHide={() =>
                                                                      this.setState({
                                                                        isOpenModalConfirmDeleteImageDocument: false,
                                                                      })
                                                                    }
                                                                  >
                                                                    ต้องการลบ รูปภาพสินค้า/เอกสาร
                                                                    ใช่หรือไม่?
                                                                  </ModalConfirm>
                                                                );
                                                              }}
                                                            </Mutation>
                                                          </ImageContainer>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  </ShipmentItem>
                                                ) : null}

                                                {this.state.isOpenPhotoProductLightBox && (
                                                  <Lightbox
                                                    mainSrc={
                                                      documentsImage[
                                                        this.state.photoProductIndexLightBox
                                                      ]
                                                    }
                                                    nextSrc={
                                                      documentsImage.length > 1
                                                        ? documentsImage[
                                                            (this.state.photoProductIndexLightBox +
                                                              1) %
                                                              documentsImage.length
                                                          ]
                                                        : undefined
                                                    }
                                                    prevSrc={
                                                      documentsImage.length > 1
                                                        ? documentsImage[
                                                            (this.state.photoProductIndexLightBox +
                                                              documentsImage.length -
                                                              1) %
                                                              documentsImage.length
                                                          ]
                                                        : undefined
                                                    }
                                                    onCloseRequest={() =>
                                                      this.setState({
                                                        isOpenPhotoProductLightBox: false,
                                                      })
                                                    }
                                                    onMovePrevRequest={() =>
                                                      this.setState(prev => ({
                                                        photoProductIndexLightBox:
                                                          (prev.photoProductIndexLightBox +
                                                            documentsImage.length -
                                                            1) %
                                                          documentsImage.length,
                                                      }))
                                                    }
                                                    onMoveNextRequest={() =>
                                                      this.setState(prev => ({
                                                        photoProductIndexLightBox:
                                                          (prev.photoProductIndexLightBox + 1) %
                                                          documentsImage.length,
                                                      }))
                                                    }
                                                  />
                                                )}

                                                {/* รหัสอ้างอิงงานของคุณ */}
                                                <ShipmentItem>
                                                  <div className="icon">
                                                    <i className="fal fa-file"></i>
                                                  </div>
                                                  <div className="detail">
                                                    <div className="detail__header">
                                                      <div className="detail__header__title">
                                                        รหัสอ้างอิงงานของคุณ
                                                      </div>
                                                      <div
                                                        className="detail__header__edit"
                                                        onClick={() =>
                                                          this.setState({
                                                            isOpenModalEditShipmentOtherDetailUserRef: true,
                                                            inputEditShipmentOtherDetailUserRef:
                                                              shipment[0].shipment_other_detail
                                                                .user_ref,
                                                          })
                                                        }
                                                      >
                                                        แก้ไขรายละเอียด{' '}
                                                        <i className="fal fa-angle-right"></i>
                                                      </div>
                                                    </div>
                                                    <p
                                                      style={{
                                                        whiteSpace: 'pre-line',
                                                        wordBreak: 'break-all',
                                                      }}
                                                    >
                                                      {shipment[0].shipment_other_detail.user_ref ||
                                                        '-'}
                                                    </p>
                                                  </div>
                                                </ShipmentItem>

                                                <Mutation
                                                  mutation={editShipment}
                                                  variables={{
                                                    shipmentID: shipment[0]._id,
                                                    user_ref: this.state
                                                      .inputEditShipmentOtherDetailUserRef,
                                                    document: undefined,
                                                    remark: undefined,
                                                  }}
                                                  update={(cache, { data }) => {
                                                    if (data.shipmentsUpdate._id) {
                                                      this.setState({
                                                        isOpenModalEditShipmentOtherDetailUserRef: false,
                                                      });
                                                    }
                                                  }}
                                                  refetchQueries={() => ['search']}
                                                >
                                                  {editShipment => {
                                                    return (
                                                      <ModalConfirm
                                                        show={
                                                          this.state
                                                            .isOpenModalEditShipmentOtherDetailUserRef
                                                        }
                                                        onPressButtonCancel={() =>
                                                          this.setState({
                                                            isOpenModalEditShipmentOtherDetailUserRef: false,
                                                          })
                                                        }
                                                        onPressButtonConfirm={() => editShipment()}
                                                        labelButtonConfirm={`ตกลง`}
                                                        labelButtonCancel={`กลับ`}
                                                        styleColorButtonConfirm="primary"
                                                        titleHeader={`แก้รหัสอ้างอิงงานของคุณ`}
                                                        subTitle={`คุณต้องการแก้ไขรหัสอ้างอิงงานของคุณให้กด "ตกลง"`}
                                                        onHide={() =>
                                                          this.setState({
                                                            isOpenModalEditShipmentOtherDetailUserRef: false,
                                                          })
                                                        }
                                                      >
                                                        <div style={{ lineHeight: 1 }}>
                                                          <div style={{ marginBottom: 10 }}>
                                                            รหัสอ้างอิงงานของคุณ
                                                          </div>
                                                          <FormControl
                                                            type="text"
                                                            value={
                                                              this.state
                                                                .inputEditShipmentOtherDetailUserRef
                                                            }
                                                            placeholder="ระบุ"
                                                            onChange={event =>
                                                              this.setState({
                                                                inputEditShipmentOtherDetailUserRef:
                                                                  event.target.value,
                                                              })
                                                            }
                                                            name="shipmentOtherDetailUserRef"
                                                          />
                                                        </div>
                                                      </ModalConfirm>
                                                    );
                                                  }}
                                                </Mutation>

                                                {/* รายละเอียดสินค้า*/}
                                                {!!shipment[0].shipment_other_detail.remark && (
                                                  <ShipmentItem>
                                                    <div className="icon">
                                                      <i className="fal fa-file"></i>
                                                    </div>
                                                    <div className="detail">
                                                      <div className="detail__header">
                                                        <div className="detail__header__title">
                                                          รายละเอียดเพิ่มเติม
                                                        </div>
                                                        <div
                                                          className="detail__header__edit"
                                                          onClick={() =>
                                                            this.setState({
                                                              isOpenModalEditShipmentOtherDetailRemark: true,
                                                              inputEditShipmentOtherDetailRemark:
                                                                shipment[0].shipment_other_detail
                                                                  .remark,
                                                            })
                                                          }
                                                        >
                                                          แก้ไขรายละเอียด{' '}
                                                          <i className="fal fa-angle-right"></i>
                                                        </div>
                                                      </div>
                                                      <p
                                                        style={{
                                                          whiteSpace: 'pre-line',
                                                          wordBreak: 'break-all',
                                                        }}
                                                      >
                                                        {shipment[0].shipment_other_detail.remark}
                                                      </p>
                                                    </div>
                                                  </ShipmentItem>
                                                )}

                                                <Mutation
                                                  mutation={editShipment}
                                                  variables={{
                                                    shipmentID: shipment[0]._id,
                                                    user_ref: undefined,
                                                    document: undefined,
                                                    remark: this.state
                                                      .inputEditShipmentOtherDetailRemark,
                                                  }}
                                                  update={(cache, { data }) => {
                                                    if (data.shipmentsUpdate._id) {
                                                      this.setState({
                                                        isOpenModalEditShipmentOtherDetailRemark: false,
                                                      });
                                                    }
                                                  }}
                                                  refetchQueries={() => ['search']}
                                                >
                                                  {editShipment => {
                                                    return (
                                                      <ModalConfirm
                                                        show={
                                                          this.state
                                                            .isOpenModalEditShipmentOtherDetailRemark
                                                        }
                                                        onPressButtonCancel={() =>
                                                          this.setState({
                                                            isOpenModalEditShipmentOtherDetailRemark: false,
                                                          })
                                                        }
                                                        onPressButtonConfirm={() => editShipment()}
                                                        labelButtonConfirm={`ตกลง`}
                                                        labelButtonCancel={`กลับ`}
                                                        styleColorButtonConfirm="primary"
                                                        titleHeader={`แก้ไขหมายเหตุ/รายละเอียดสินค้า:`}
                                                        subTitle={`คุณต้องการแก้ไขหมายเหตุ/รายละเอียดสินค้า ให้กด "ตกลง"`}
                                                        onHide={() =>
                                                          this.setState({
                                                            isOpenModalEditShipmentOtherDetailRemark: false,
                                                          })
                                                        }
                                                      >
                                                        <div style={{ lineHeight: 1 }}>
                                                          <div style={{ marginBottom: 10 }}>
                                                            หมายเหตุ/รายละเอียดสินค้า:
                                                          </div>
                                                          <FormControl
                                                            componentClass="textarea"
                                                            value={
                                                              this.state
                                                                .inputEditShipmentOtherDetailRemark
                                                            }
                                                            placeholder="ระบุ"
                                                            onChange={event =>
                                                              this.setState({
                                                                inputEditShipmentOtherDetailRemark:
                                                                  event.target.value,
                                                              })
                                                            }
                                                            name="shipmentOtherDetailRemark"
                                                          />
                                                        </div>
                                                      </ModalConfirm>
                                                    );
                                                  }}
                                                </Mutation>
                                              </Col>
                                            </Row>
                                          </div>
                                        </div>

                                        <Mutation
                                          mutation={invoiceMoreAddMutation}
                                          variables={{
                                            shipment_id: shipment[0]._id,
                                            category: this.state.additionnalCostCategory.label,
                                            detail: this.state.additionnalCostSubCategory.label,
                                            price:
                                              this.state.additionnalCostCategory.value ==
                                              '5d4009ec9799b1f96549d21e'
                                                ? parseFloat(this.state.additionnalCostPrice) * -1
                                                : parseFloat(this.state.additionnalCostPrice),
                                            qty: parseFloat(this.state.additionnalCostQty),
                                            vat: parseInt(this.state.additionnalCostPriceVat.label),
                                            wht: parseInt(this.state.additionnalCostPriceWht.label),
                                            tax_type: this.state.tax_type,
                                          }}
                                          update={() => {
                                            this.setState(
                                              {
                                                selectedAdditionalItemKey: null,
                                                openAdditionalPayment: false,
                                                showDeleteModal: false,
                                                openAdditionalPaymentList: false,
                                              },
                                              () => refetchInvoiceBusinessListQuery()
                                            );
                                          }}
                                        >
                                          {invoiceMoreAdd => (
                                            <Mutation
                                              mutation={invoiceMoreUpdateMutation}
                                              variables={this.renderVariablesInvoiceMoreUpdate()}
                                              update={() => {
                                                this.setState(
                                                  {
                                                    openAdditionalPayment: false,
                                                    showDeleteModal: false,
                                                    openAdditionalPaymentList: false,
                                                    selectedAdditionalItemKey: null,
                                                  },
                                                  () => refetchInvoiceBusinessListQuery()
                                                );
                                              }}
                                            >
                                              {invoiceMoreUpdate => (
                                                <div>
                                                  {/* รายการค่าใช้จ่ายเพิ่มเติม */}
                                                  {this.state.openAdditionalPaymentList ||
                                                  this.state.openAdditionalPayment ? (
                                                    <div className="wraper-additionalpayment">
                                                      {this.state.openAdditionalPaymentList && (
                                                        <div className="inner-left">
                                                          <div className="header">
                                                            <h5>
                                                              <b>รายการค่าใช้จ่ายเพิ่มเติม</b>{' '}
                                                              <button
                                                                className="btn btn-link"
                                                                style={{
                                                                  float: 'right',
                                                                  marginTop: '-5px',
                                                                }}
                                                                onClick={() =>
                                                                  this.setState({
                                                                    openAdditionalPaymentList: false,
                                                                    selectedAdditionalItemKey: null,
                                                                  })
                                                                }
                                                                disabled={
                                                                  this.state.openAdditionalPayment
                                                                    ? 'disabled'
                                                                    : false
                                                                }
                                                              >
                                                                <i className="fal fa-times"></i>
                                                              </button>
                                                            </h5>
                                                            <hr style={{ margin: '0 0 10px' }} />
                                                          </div>

                                                          <div className="body-main">
                                                            <button
                                                              type="button"
                                                              className="btn btn-default"
                                                              style={{
                                                                width: '100%',
                                                                marginBottom: '15px',
                                                              }}
                                                              onClick={() =>
                                                                this.onClickAddInvoiceMore('newsub')
                                                              }
                                                            >
                                                              {' '}
                                                              <i className="fal fa-plus-circle"></i>{' '}
                                                              เพิ่มรายการ
                                                            </button>
                                                            <div className="box-item">
                                                              {this.state.selectedAdditionalItem
                                                                .invoice_business_list.length > 0
                                                                ? this.state.selectedAdditionalItem.invoice_business_list.map(
                                                                    (prop, key) =>
                                                                      prop.invoice_business_list_type ==
                                                                        5 && (
                                                                        <RowItemList className="">
                                                                          <div
                                                                            className="icon-left"
                                                                            onClick={() =>
                                                                              this.onClickUpdateInvoiceMore(
                                                                                prop,
                                                                                'delete',
                                                                                this.state
                                                                                  .selectedAdditionalItem
                                                                                  ._id
                                                                              )
                                                                            }
                                                                          >
                                                                            <i className="far fa-trash-alt"></i>
                                                                          </div>
                                                                          <div className="left">
                                                                            <b>
                                                                              {
                                                                                prop.invoice_business_list_detail
                                                                              }
                                                                            </b>
                                                                            <small>
                                                                              {parseFloat(
                                                                                prop.invoice_business_list_total_price
                                                                              ).toLocaleString(
                                                                                'en-US',
                                                                                {
                                                                                  maximumFractionDigits: 2,
                                                                                }
                                                                              )}{' '}
                                                                              บาท
                                                                            </small>
                                                                          </div>
                                                                          {/* <div className="icon-right" onClick={() => this.onClickUpdateInvoiceMore(prop , "edit")}> 
                                                                                                                                    <i className="fal fa-chevron-right"></i>
                                                                                                                                </div> */}
                                                                        </RowItemList>
                                                                      )
                                                                  )
                                                                : null}
                                                              <Modal
                                                                show={this.state.showDeleteModal}
                                                              >
                                                                <Modal.Body>
                                                                  <ModalContent>
                                                                    <div className="content">
                                                                      <h5
                                                                        style={{
                                                                          marginBottom: '0',
                                                                        }}
                                                                      >
                                                                        <b>
                                                                          คุณต้องการลบรายการค่าใช้จ่ายใช่หรือไม่
                                                                        </b>
                                                                      </h5>
                                                                      <p>
                                                                        กรุณาตรวจสอบความถูกต้องก่อนกดยืนยัน
                                                                      </p>
                                                                      <p style={{ marginTop: 10 }}>
                                                                        <div className="detail">
                                                                          <div className="left">
                                                                            {
                                                                              this.state
                                                                                .DeleteItemName
                                                                            }
                                                                          </div>
                                                                          <div className="right">
                                                                            {parseFloat(
                                                                              this.state
                                                                                .DeleteItemPrice
                                                                            ).toLocaleString(
                                                                              'en-US',
                                                                              {
                                                                                maximumFractionDigits: 2,
                                                                              }
                                                                            )}{' '}
                                                                            บาท
                                                                          </div>
                                                                        </div>
                                                                      </p>
                                                                    </div>

                                                                    <div className="footer">
                                                                      <button
                                                                        className="btn btn-grey"
                                                                        style={{ width: '100px' }}
                                                                        onClick={() =>
                                                                          this.setState({
                                                                            showDeleteModal: false,
                                                                          })
                                                                        }
                                                                      >
                                                                        ปิด
                                                                      </button>
                                                                      <button
                                                                        className="btn btn-red"
                                                                        style={{ width: '100px' }}
                                                                        onClick={() =>
                                                                          invoiceMoreUpdate()
                                                                        }
                                                                      >
                                                                        ยืนยัน
                                                                      </button>
                                                                    </div>
                                                                  </ModalContent>
                                                                </Modal.Body>
                                                              </Modal>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}

                                                      {this.state.openAdditionalPayment && (
                                                        <div
                                                          className={
                                                            this.state.invoiceMoreMode == 'new'
                                                              ? 'inner-left'
                                                              : 'inner-right'
                                                          }
                                                        >
                                                          <div className="header">
                                                            <h5>
                                                              <b>รายละเอียด</b>{' '}
                                                              <button
                                                                className="btn btn-link"
                                                                style={{
                                                                  float: 'right',
                                                                  marginTop: '-5px',
                                                                }}
                                                                onClick={() =>
                                                                  this.setState({
                                                                    openAdditionalPayment: false,
                                                                  })
                                                                }
                                                              >
                                                                <i className="fal fa-times"></i>
                                                              </button>
                                                            </h5>
                                                            <hr style={{ margin: '0 0 10px' }} />
                                                          </div>

                                                          <div className="body">
                                                            <Query
                                                              query={expensesCategoryListQuery}
                                                            >
                                                              {({ loading, error, data }) => {
                                                                if (loading) return 'loading...';
                                                                // if(error) return null;
                                                                // //console.log(variables);
                                                                const CategoryList =
                                                                  data.expensesCategoryList;
                                                                // console.log(CategoryList);
                                                                return (
                                                                  <div className="box-item">
                                                                    <FormGroup controlId="formBasicText">
                                                                      <TextControlLabel>
                                                                        หมวดค่าใช้จ่าย*
                                                                      </TextControlLabel>
                                                                      <Select
                                                                        name={`additionnalCostCategory`}
                                                                        value={
                                                                          this.state
                                                                            .additionnalCostCategory
                                                                        }
                                                                        onChange={event =>
                                                                          this.handleChangeAdditionnalCostCategory(
                                                                            CategoryList,
                                                                            event
                                                                          )
                                                                        }
                                                                        options={this.renderAdditionnalCostCategory(
                                                                          CategoryList
                                                                        )}
                                                                      />
                                                                    </FormGroup>
                                                                    {this.state
                                                                      .additionnalCostCategory
                                                                      .value ==
                                                                    '5d4009ec9799b1f96549d21e' ? (
                                                                      <FormGroup controlId="formBasicText">
                                                                        <TextControlLabel>
                                                                          รายการ*
                                                                        </TextControlLabel>
                                                                        <FormControl
                                                                          type="text"
                                                                          value={
                                                                            this.state
                                                                              .additionnalCostSubCategory
                                                                              .label
                                                                          }
                                                                          placeholder="ระบุ"
                                                                          onChange={e =>
                                                                            this.setState({
                                                                              additionnalCostSubCategory: {
                                                                                value:
                                                                                  e.target.value,
                                                                                label:
                                                                                  e.target.value,
                                                                              },
                                                                            })
                                                                          }
                                                                        />
                                                                      </FormGroup>
                                                                    ) : (
                                                                      <FormGroup controlId="formBasicText">
                                                                        <TextControlLabel>
                                                                          รายการ*
                                                                        </TextControlLabel>
                                                                        <Select
                                                                          name={`additionnalCostSubCategory`}
                                                                          value={
                                                                            this.state
                                                                              .additionnalCostSubCategory
                                                                          }
                                                                          onChange={event =>
                                                                            this.handleChangeAdditionnalCostSubCategory(
                                                                              CategoryList,
                                                                              event
                                                                            )
                                                                          }
                                                                          options={this.renderAdditionnalCostSubCategory(
                                                                            CategoryList
                                                                          )}
                                                                        />
                                                                      </FormGroup>
                                                                    )}
                                                                    <FormGroup controlId="formBasicText">
                                                                      <TextControlLabel>
                                                                        จำนวน*
                                                                      </TextControlLabel>
                                                                      <FormControl
                                                                        type="number"
                                                                        min="0"
                                                                        value={
                                                                          this.state
                                                                            .additionnalCostQty
                                                                        }
                                                                        placeholder="ระบุ"
                                                                        onChange={e =>
                                                                          this.setState({
                                                                            additionnalCostQty:
                                                                              e.target.value,
                                                                          })
                                                                        }
                                                                      />
                                                                    </FormGroup>
                                                                    <FormGroup controlId="formBasicText">
                                                                      <TextControlLabel>
                                                                        ราคาต่อหน่วย*
                                                                      </TextControlLabel>
                                                                      <FormControl
                                                                        type="number"
                                                                        min="0"
                                                                        value={
                                                                          this.state
                                                                            .additionnalCostPrice
                                                                        }
                                                                        placeholder="ระบุ"
                                                                        onChange={e =>
                                                                          this.setState({
                                                                            additionnalCostPrice:
                                                                              e.target.value,
                                                                          })
                                                                        }
                                                                      />
                                                                    </FormGroup>
                                                                    <FormGroup controlId="formBasicText">
                                                                      <TextControlLabel>
                                                                        ภาษีมูลค่าเพิ่ม (บาท)*
                                                                      </TextControlLabel>
                                                                      <Select
                                                                        name={`additionnalCostPriceVat`}
                                                                        value={
                                                                          this.state
                                                                            .additionnalCostPriceVat
                                                                        }
                                                                        onChange={event =>
                                                                          this.handleChangeadditionnalCostPriceVat(
                                                                            event
                                                                          )
                                                                        }
                                                                        options={vatList}
                                                                        isDisabled={
                                                                          this.state
                                                                            .disableTpyeDiscount
                                                                        }
                                                                      />
                                                                    </FormGroup>
                                                                    <FormGroup controlId="formBasicText">
                                                                      <TextControlLabel>
                                                                        ภาษีหัก ณ ที่จ่าย (บาท)*
                                                                      </TextControlLabel>
                                                                      <Select
                                                                        name={`additionnalCostPriceWht`}
                                                                        value={
                                                                          this.state
                                                                            .additionnalCostPriceWht
                                                                        }
                                                                        onChange={event =>
                                                                          this.handleChangeadditionnalCostPriceWht(
                                                                            event
                                                                          )
                                                                        }
                                                                        options={WhtList}
                                                                        isDisabled={
                                                                          this.state
                                                                            .disableTpyeDiscount
                                                                        }
                                                                      />
                                                                    </FormGroup>
                                                                  </div>
                                                                );
                                                              }}
                                                            </Query>
                                                          </div>

                                                          <div className="footer">
                                                            <button
                                                              type="button"
                                                              className="btn btn-default"
                                                              onClick={() =>
                                                                this.setState({
                                                                  openAdditionalPayment: false,
                                                                })
                                                              }
                                                            >
                                                              {' '}
                                                              ปิด{' '}
                                                            </button>
                                                            <button
                                                              type="button"
                                                              className="btn btn-primary"
                                                              style={{ marginLeft: '20px' }}
                                                              disabled={this.checkDisableAddCost()}
                                                              onClick={() =>
                                                                this.state.invoiceMoreMode ==
                                                                  'new' ||
                                                                this.state.invoiceMoreMode ==
                                                                  'newsub'
                                                                  ? invoiceMoreAdd()
                                                                  : invoiceMoreUpdate()
                                                              }
                                                            >
                                                              {' '}
                                                              บันทึก{' '}
                                                            </button>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  ) : null}
                                                </div>
                                              )}
                                            </Mutation>
                                          )}
                                        </Mutation>

                                        {/* การติดตามงาน  */}
                                        <div className="box-content shipment right">
                                          <div className="inner">
                                            <h5>
                                              <b>การติดตามงาน</b>
                                            </h5>
                                            <hr style={{ margin: '0 0 10px' }} />
                                            <div>
                                              {this.renderStatus(shipment[0].shipment_status)}
                                              {shipment[0].shipment_status == 5 &&
                                              shipment[0].cancel_comment ? (
                                                <div style={{ marginTop: 5 }}>
                                                  <b>เหตุผลการยกเลิกงาน:</b> <br />
                                                  {shipment[0].cancel_comment}
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                              {shipment[0].shipment_status == 5 &&
                                              shipment[0].cancel_comment ? (
                                                <small style={{ color: '#808080' }}>
                                                  วันที่ยกเลิก :{' '}
                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                    {shipment[0].cancel_date
                                                      ? shipment[0].cancel_date
                                                      : shipment[0].last_update}
                                                  </Moment>
                                                </small>
                                              ) : null}
                                            </div>

                                            {shipment[0].shipment_status == 5 &&
                                            (shipment[0].shipment_refund ||
                                              shipment[0].shipment_fines) ? (
                                              <div style={{ margin: '10px 0 5px' }}>
                                                {shipment[0].shipment_refund &&
                                                  shipment[0].shipment_refund.detail.th && (
                                                    <div style={{ marginTop: 5 }}>
                                                      <b style={{ color: '#D90101' }}>
                                                        คืนเงินจำนวน
                                                      </b>{' '}
                                                      {parseFloat(
                                                        shipment[0].shipment_refund.amount
                                                      ).toLocaleString('en-US', {
                                                        maximumFractionDigits: 2,
                                                      })}{' '}
                                                      บาท
                                                      <br />{' '}
                                                      <span>
                                                        {shipment[0].shipment_refund.detail.th}
                                                      </span>
                                                    </div>
                                                  )}
                                                {shipment[0].shipment_fines &&
                                                  shipment[0].shipment_fines.detail.th && (
                                                    <div style={{ marginTop: 5 }}>
                                                      {shipment[0].paid_type == 3 ? (
                                                        <span style={{ color: '#D90101' }}>
                                                          <b>
                                                            มีค่าใช้จ่ายเพิ่มเติม/ค่าเสียหาย จำนวน
                                                          </b>{' '}
                                                          {parseFloat(
                                                            shipment[0].shipment_fines.amount
                                                          ).toLocaleString('en-US', {
                                                            maximumFractionDigits: 2,
                                                          })}{' '}
                                                          บาท
                                                        </span>
                                                      ) : shipment[0].paid_type == 2 ? (
                                                        shipment[0].shipment_fines.status == 1 ? (
                                                          <span style={{ color: '#D90101' }}>
                                                            <b>กรุณาชำระค่ายกเลิกบริการ จำนวน</b>{' '}
                                                            {parseFloat(
                                                              shipment[0].shipment_fines.amount
                                                            ).toLocaleString('en-US', {
                                                              maximumFractionDigits: 2,
                                                            })}{' '}
                                                            บาท
                                                          </span>
                                                        ) : (
                                                          <span style={{ color: '#D90101' }}>
                                                            <b>ชำระค่ายกเลิกบริการเรียบร้อยแล้ว</b>
                                                          </span>
                                                        )
                                                      ) : null}
                                                      <br />{' '}
                                                      <span>
                                                        {shipment[0].shipment_fines.detail.th}
                                                      </span>
                                                    </div>
                                                  )}

                                                {shipment[0].paid_type == 2 &&
                                                  shipment[0].shipment_fines.status == 1 && (
                                                    <div style={{ marginTop: 5 }}>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        style={{ width: '100%' }}
                                                        onClick={() =>
                                                          this.setState({ openApprovePaid: true })
                                                        }
                                                      >
                                                        รับค่ายกเลิกบริการแล้ว
                                                      </button>

                                                      <Mutation
                                                        mutation={cancelShipment}
                                                        variables={{
                                                          shipmentID: shipment[0]._id,
                                                          shipment_fines: {
                                                            amount:
                                                              shipment[0].shipment_fines.amount,
                                                            detail: {
                                                              th:
                                                                shipment[0].shipment_fines.detail
                                                                  .th,
                                                              en:
                                                                shipment[0].shipment_fines.detail
                                                                  .en,
                                                            },
                                                            detail_complete: {
                                                              th:
                                                                shipment[0].shipment_fines
                                                                  .detail_complete.th,
                                                              en:
                                                                shipment[0].shipment_fines
                                                                  .detail_complete.en,
                                                            },
                                                            status: 2,
                                                          },
                                                        }}
                                                        update={() =>
                                                          this.setState(
                                                            { openApprovePaid: false },
                                                            window.location.reload()
                                                          )
                                                        }
                                                        onError={() => {
                                                          alert('อัพเดตงานขนส่ง ไม่สำเร็จ');
                                                        }}
                                                      >
                                                        {updatePaidShipment => (
                                                          <Modal
                                                            show={this.state.openApprovePaid}
                                                            onHide={() =>
                                                              this.setState({
                                                                openApprovePaid: false,
                                                              })
                                                            }
                                                          >
                                                            <Modal.Body>
                                                              <Row style={{ marginBottom: '10px' }}>
                                                                <Col md={12}>
                                                                  <h3>
                                                                    ยืนยันชำระเงินค่ายกเลิกงาน
                                                                  </h3>
                                                                  <p>
                                                                    คุณแน่ใจว่าผู้ใช้บริการชำระเงินค่ายกเลิกงานเรียบร้อยแล้ว
                                                                    ?
                                                                  </p>
                                                                </Col>
                                                              </Row>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                              <Button
                                                                bsStyle="defalut"
                                                                style={{ width: '120px' }}
                                                                onClick={() =>
                                                                  this.setState({
                                                                    openApprovePaid: false,
                                                                  })
                                                                }
                                                              >
                                                                ยกเลิก
                                                              </Button>
                                                              <Button
                                                                className="btn btn-red"
                                                                style={{ width: '120px' }}
                                                                onClick={e => updatePaidShipment()}
                                                              >
                                                                ตกลง
                                                              </Button>
                                                            </Modal.Footer>
                                                          </Modal>
                                                        )}
                                                      </Mutation>
                                                    </div>
                                                  )}
                                              </div>
                                            ) : null}

                                            <div
                                              style={{
                                                padding: '5px 0',
                                                borderBottom: '1px solid #E3E3E3',
                                                marginBottom: 20,
                                              }}
                                            >
                                              <small style={{ color: '#808080' }}>
                                                อัพเดทล่าสุด:{' '}
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                  {shipment[0].last_update}
                                                </Moment>
                                              </small>{' '}
                                              <br />
                                              {shipment[0].matching_time && (
                                                <small style={{ color: '#808080' }}>
                                                  Matching time :{' '}
                                                  {this.getTimeFromMins(shipment[0].matching_time)}
                                                </small>
                                              )}
                                            </div>
                                            <Query
                                              query={trackingList}
                                              variables={{
                                                shipment_id: shipmentId,
                                                check_agent_accept: shipment[0].transporter,
                                              }}
                                            >
                                              {({
                                                loading,
                                                error,
                                                data,
                                                refetch: refetchTrackingList,
                                                variables,
                                              }) => {
                                                if (loading) return 'Loading...';
                                                if (error) return 'แบบร่าง';
                                                const tracking = data.trackingList.tracking;
                                                // console.log("tracking",tracking)
                                                if (tracking.length > 0) {
                                                  return (
                                                    <div>
                                                      {/* Driver Detail */}
                                                      {/* {console.log("Driver Detail",shipment[0].transporter,tracking[0].driver_name)} */}
                                                      {shipment[0].transporter ||
                                                      tracking[0].driver_name ? (
                                                        <div style={{ margin: '15px 0' }}>
                                                          {shipment[0].transporter &&
                                                            (!shipment[0].driver_user_id ||
                                                              shipment[0].transporter !==
                                                                '5d0083ebc88a512bb2f5f7a6') && (
                                                              <div>
                                                                <b>
                                                                  ผู้ดูแลงาน :{' '}
                                                                  {shipment[0].transporter_name}
                                                                </b>
                                                                <br />
                                                                <span>
                                                                  โทร :{' '}
                                                                  <b>{`${shipment[0].transporter_phone_code} ${shipment[0].transporter_phone_number}`}</b>
                                                                </span>
                                                                <hr
                                                                  style={{
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px',
                                                                  }}
                                                                />
                                                              </div>
                                                            )}
                                                          {shipment[0].shipment_type == 1 ? (
                                                            tracking[0].driver_name ? (
                                                              <div>
                                                                <DriverDetailContainer>
                                                                  <div>
                                                                    <b>
                                                                      พนักงานขับรถ :{' '}
                                                                      {shipment[0]
                                                                        .driver_name_under_mama
                                                                        ? shipment[0]
                                                                            .driver_name_under_mama
                                                                        : tracking[0].driver_name}
                                                                    </b>
                                                                    <br />
                                                                    <span>
                                                                      ทะเบียนรถ :{' '}
                                                                      {
                                                                        tracking[0]
                                                                          .user_setting_truck_license_plate
                                                                      }
                                                                      <br />
                                                                      โทร :{' '}
                                                                      <b>
                                                                        {shipment[0]
                                                                          .driver_tel_under_mama
                                                                          ? `${tracking[0].driver_phone_code} ${shipment[0].driver_tel_under_mama}`
                                                                          : `${tracking[0].driver_phone_code} ${tracking[0].driver_phone_number}`}
                                                                      </b>
                                                                    </span>
                                                                  </div>
                                                                  <AvatarContainer>
                                                                    <Avatar
                                                                      src={
                                                                        tracking[0]
                                                                          .driver_display_pic
                                                                          ? `${s3}/${tracking[0].driver_display_pic}`
                                                                          : ''
                                                                      }
                                                                      clicked={() =>
                                                                        this.setState({
                                                                          isOpenLightboxDriverAvatar: true,
                                                                        })
                                                                      }
                                                                    />

                                                                    {tracking[0].favorite_driver ===
                                                                      1 && (
                                                                      <FavoriteIcon>
                                                                        <i className="fas fa-heart"></i>
                                                                      </FavoriteIcon>
                                                                    )}
                                                                  </AvatarContainer>
                                                                  {this.state
                                                                    .isOpenLightboxDriverAvatar && (
                                                                    <Lightbox
                                                                      mainSrc={`${s3}/${tracking[0].driver_display_pic}`}
                                                                      onCloseRequest={() =>
                                                                        this.setState({
                                                                          isOpenLightboxDriverAvatar: false,
                                                                        })
                                                                      }
                                                                    />
                                                                  )}
                                                                </DriverDetailContainer>
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <b>พนักงานขับรถ : กำลังมอบหมาย </b>
                                                              </div>
                                                            )
                                                          ) : null}

                                                          {shipment[0].shipment_status != 4 ? (
                                                            tracking[0].working ? (
                                                              <div>
                                                                <b>"กำลังดำเนินการขนส่ง"</b>
                                                                <hr
                                                                  style={{
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px',
                                                                  }}
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                <b>"พักงานขนส่ง"</b>
                                                                <hr
                                                                  style={{
                                                                    marginBottom: '10px',
                                                                    marginTop: '10px',
                                                                  }}
                                                                />
                                                              </div>
                                                            )
                                                          ) : null}
                                                        </div>
                                                      ) : null}

                                                      {shipmentAdmin ? (
                                                        shipmentAdmin.sla_total > 0 ? (
                                                          <div>
                                                            <div
                                                              style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                              }}
                                                            >
                                                              {this.renderProblemStatus(
                                                                shipmentAdmin.sla_completed,
                                                                shipmentAdmin.sla_max_level,
                                                                shipmentAdmin.sla_total
                                                              )}
                                                              <span
                                                                style={{
                                                                  float: 'right',
                                                                  cursor: 'pointer',
                                                                }}
                                                                onClick={() =>
                                                                  this.handlerShowCase(
                                                                    shipmentAdmin,
                                                                    admin
                                                                  )
                                                                }
                                                              >
                                                                ดูรายละเอียดปัญหา{' '}
                                                                <i
                                                                  className="fal fa-chevron-right"
                                                                  style={{ fontSize: '11px' }}
                                                                ></i>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        ) : null
                                                      ) : null}

                                                      {/* customer review */}
                                                      {shipment[0].shipment_status === 4 &&
                                                        tracking.length > 0 &&
                                                        tracking[0].review_score && (
                                                          <button
                                                            type="button"
                                                            className="outline btn btn-sm btn-default"
                                                            style={{
                                                              fontWeight: 'normal',
                                                              width: '100%',
                                                              padding: '5px 0px',
                                                              marginBottom: '10px',
                                                            }}
                                                            onClick={() =>
                                                              this.setState({
                                                                isOpenDetailReview: true,
                                                              })
                                                            }
                                                          >
                                                            คะแนนพนักงานขับรถ
                                                          </button>
                                                        )}

                                                      <BlankSpace />

                                                      <div className="boxPosition tracking">
                                                        {shipment[0].shipment_status == 4 ? (
                                                          <div className="boxItem inProgress">
                                                            <i className="fas fa-circle"></i>
                                                            <div className="content">
                                                              <div className="title">
                                                                <b>ดำเนินการส่งเรียบร้อยแล้ว</b>
                                                              </div>
                                                              <div className="status">
                                                                <Moment format="DD/MM/YYYY HH:mm">
                                                                  {shipment[0].completed_date}
                                                                </Moment>
                                                              </div>
                                                              <div className="detail"></div>
                                                            </div>
                                                          </div>
                                                        ) : null}

                                                        {this.checkPODProcess(
                                                          shipment[0],
                                                          tracking[0]
                                                        ) ? (
                                                          tracking[0].tracking_detail[0]
                                                            .pod_date !== null ? (
                                                            <div className="boxItem">
                                                              <i className="fas fa-check-circle"></i>
                                                              <div className="content">
                                                                <div className="title">
                                                                  <b>ส่งเอกสาร POD เรียบร้อยแล้ว</b>
                                                                </div>
                                                                <div
                                                                  className="detail"
                                                                  style={{ margin: '10px 0' }}
                                                                >
                                                                  <Row>
                                                                    <Col sm={12}>
                                                                      จัดส่งโดย:{' '}
                                                                      {
                                                                        tracking[0]
                                                                          .tracking_detail[0]
                                                                          .pod_from
                                                                      }
                                                                    </Col>
                                                                  </Row>
                                                                  <Row>
                                                                    <Col sm={12}>
                                                                      หมายเลขพัสดุ:{' '}
                                                                      <b
                                                                        style={{
                                                                          fontWeight: 'bold',
                                                                        }}
                                                                      >
                                                                        {
                                                                          tracking[0]
                                                                            .tracking_detail[0]
                                                                            .pod_track
                                                                        }
                                                                      </b>
                                                                    </Col>
                                                                  </Row>
                                                                  {tracking[0].tracking_detail[0]
                                                                    .pod_remark && (
                                                                    <Row>
                                                                      <Col sm={12}>
                                                                        หมายเหตุ:
                                                                        <b
                                                                          style={{
                                                                            fontWeight: 'bold',
                                                                          }}
                                                                        >
                                                                          {
                                                                            tracking[0]
                                                                              .tracking_detail[0]
                                                                              .pod_remark
                                                                          }
                                                                        </b>
                                                                      </Col>
                                                                    </Row>
                                                                  )}
                                                                  <Row>
                                                                    <Col
                                                                      sm={12}
                                                                      style={{ marginTop: 15 }}
                                                                    >
                                                                      {tracking[0]
                                                                        .tracking_detail[0]
                                                                        .pod_slip_picture &&
                                                                        tracking[0].tracking_detail[0].pod_slip_picture.map(
                                                                          (
                                                                            slip_picture,
                                                                            slip_picture_index
                                                                          ) => {
                                                                            return (
                                                                              <div className="img_wrapper">
                                                                                <div
                                                                                  className="img"
                                                                                  onClick={() =>
                                                                                    this.renderLightbox(
                                                                                      'pod',
                                                                                      slip_picture,
                                                                                      0
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <Image
                                                                                    src={`${s3}/${slip_picture}`}
                                                                                    rounded
                                                                                  />
                                                                                </div>
                                                                              </div>
                                                                            );
                                                                          }
                                                                        )}
                                                                    </Col>
                                                                  </Row>
                                                                </div>
                                                                <div className="status">
                                                                  <Moment format="DD/MM/YYYY HH:mm">
                                                                    {
                                                                      tracking[0].tracking_detail[0]
                                                                        .pod_date
                                                                    }
                                                                  </Moment>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            <div className="boxItem inProgress">
                                                              <i className="fas fa-circle"></i>
                                                              <div className="content">
                                                                <div className="title">
                                                                  <b>ส่งเอกสาร POD</b>{' '}
                                                                </div>
                                                                <div className="status">
                                                                  รอดำเนินการ...
                                                                </div>
                                                                <div className="detail"></div>
                                                              </div>
                                                            </div>
                                                          )
                                                        ) : null}

                                                        {shipment[0].shipment_type == 1 && (
                                                          <Row>
                                                            <Col md={12}>
                                                              {/* วนที่อยู่ tracking */}
                                                              {tracking[0].tracking_detail
                                                                .sort(function(a, b) {
                                                                  return b.droppoint - a.droppoint;
                                                                })
                                                                .map((item, index) => (
                                                                  <TrackingItem
                                                                    step={[1, 2, 3]}
                                                                    tracking={tracking[0]}
                                                                    detail={
                                                                      tracking[0].tracking_detail
                                                                    }
                                                                    index={index}
                                                                    renderLightbox={(
                                                                      mode,
                                                                      data,
                                                                      index
                                                                    ) =>
                                                                      this.renderLightbox(
                                                                        mode,
                                                                        data,
                                                                        index
                                                                      )
                                                                    }
                                                                  />
                                                                ))}

                                                              {/* ขนส่งยืนยันงาน */}
                                                              {tracking[0].driver_accept_date ==
                                                              null ? (
                                                                <div className="boxItem inProgress">
                                                                  <i className="fas fa-circle"></i>
                                                                  <div className="content">
                                                                    <div className="title">
                                                                      <b>รอขนส่งยืนยันงาน</b>{' '}
                                                                    </div>
                                                                    <div className="status">
                                                                      ขนส่งยังไม่ได้ยืนยันรับงาน
                                                                    </div>
                                                                    <div className="detail"></div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <div className="boxItem">
                                                                  <i className="fas fa-check-circle"></i>
                                                                  <div className="content">
                                                                    <div className="title">
                                                                      ขนส่งยืนยันงาน เรียบร้อยแล้ว
                                                                    </div>
                                                                    <div className="status">
                                                                      <Moment format="DD/MM/YYYY HH:mm">
                                                                        {
                                                                          tracking[0]
                                                                            .driver_accept_date
                                                                        }
                                                                      </Moment>
                                                                    </div>
                                                                    <div className="detail"></div>
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </Col>
                                                          </Row>
                                                        )}

                                                        {shipment[0].shipment_type == 2 && ( //import
                                                          <Row>
                                                            <Col md={12}>
                                                              <TrackingItemImport
                                                                tracking={tracking}
                                                                onClickContainner={(data, index) =>
                                                                  this.renderLightboxContainner(
                                                                    data,
                                                                    index
                                                                  )
                                                                }
                                                                onClickOpenEditTransporter={tracking =>
                                                                  this.openEditTransporter(tracking)
                                                                }
                                                                onClickOpenEditDriver={(
                                                                  tracking,
                                                                  mode
                                                                ) =>
                                                                  this.openEditDriver(
                                                                    tracking,
                                                                    mode
                                                                  )
                                                                }
                                                                onClickOpenEditContainer={(
                                                                  tracking,
                                                                  mode
                                                                ) =>
                                                                  this.openEditContainer(
                                                                    tracking,
                                                                    mode
                                                                  )
                                                                }
                                                                onClickOpenReturnContainer={() =>
                                                                  this.onOpenReturnContainer()
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        )}

                                                        {shipment[0].shipment_type == 3 && ( //export
                                                          <Row>
                                                            <Col md={12}>
                                                              <TrackingItemExport
                                                                tracking={tracking}
                                                                onClickContainner={(data, index) =>
                                                                  this.renderLightboxContainner(
                                                                    data,
                                                                    index
                                                                  )
                                                                }
                                                                onClickOpenEditTransporter={tracking =>
                                                                  this.openEditTransporterExport(
                                                                    tracking
                                                                  )
                                                                }
                                                                onClickOpenEditDriver={(
                                                                  tracking,
                                                                  mode
                                                                ) =>
                                                                  this.openEditDriverExport(
                                                                    tracking,
                                                                    mode
                                                                  )
                                                                }
                                                                onClickOpenEditContainer={(
                                                                  tracking,
                                                                  mode
                                                                ) =>
                                                                  this.openEditContainerExport(
                                                                    tracking,
                                                                    mode
                                                                  )
                                                                }
                                                                onClickOpenReturnContainer={() =>
                                                                  this.onOpenReturnContainerExport()
                                                                }
                                                                onClickOpenDriverAcceptDocument={() =>
                                                                  this.onOpenDriverAcceptDocument()
                                                                }
                                                                onClickOpenUploadImage={tracking =>
                                                                  this.OpenUploadImageExport(
                                                                    tracking
                                                                  )
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                                return (
                                                  <div className="boxPosition tracking">
                                                    <div className="boxItem">
                                                      <i className="fas fa-check-circle"></i>
                                                      <div className="content">
                                                        <div className="title">งานแบบร่าง</div>
                                                        <div className="status">
                                                          <Moment format="DD/MM/YYYY HH:mm">
                                                            {shipment[0].pick_up_date}
                                                          </Moment>
                                                        </div>
                                                        <div className="detail"></div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }}
                                            </Query>
                                          </div>
                                        </div>

                                        {/* อัพเดตสถานะ tracking  */}
                                        <Query
                                          query={trackingList}
                                          variables={{
                                            shipment_id: shipmentId,
                                            check_agent_accept: shipment[0].transporter,
                                          }}
                                        >
                                          {({
                                            loading,
                                            error,
                                            data,
                                            refetch: refetchTrackingList,
                                            variables,
                                          }) => {
                                            if (loading) return 'Loading...';
                                            if (error) return 'แบบร่าง';
                                            const tracking = data.trackingList.tracking;
                                            //console.log(tracking)
                                            return (
                                              <div>
                                                <Mutation
                                                  mutation={singleUploadMutation}
                                                  update={(cache, { data }) => {
                                                    this.setState(state => {
                                                      const cont_picture = [
                                                        ...state.cont_picture,
                                                        data.singleUpload.path,
                                                      ];
                                                      return {
                                                        cont_picture,
                                                      };
                                                    });
                                                  }}
                                                  onError={error => {
                                                    console.log(error);
                                                  }}
                                                >
                                                  {uploadFile => {
                                                    return (
                                                      <input
                                                        accept="image/jpeg, image/png, application/pdf"
                                                        onChange={event =>
                                                          this.handleSingleUploadDocument(
                                                            uploadFile,
                                                            'document',
                                                            event
                                                          )
                                                        }
                                                        type="file"
                                                        id="file"
                                                        ref={this._inputUploadImage}
                                                        style={{ display: 'none' }}
                                                      />
                                                    );
                                                  }}
                                                </Mutation>
                                                {this.state.openEditTransporter == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.openEditTransporter}`}
                                                  >
                                                    <div className="inner">
                                                      <h5>
                                                        <b>กรอกข้อมูลบริษัทขนส่ง</b>{' '}
                                                        <button
                                                          className="btn btn-link"
                                                          style={{ float: 'right' }}
                                                          onClick={() =>
                                                            this.setState({
                                                              openEditTransporter: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                              transporter: null,
                                                            })
                                                          }
                                                        >
                                                          <i className="fal fa-times"></i>
                                                        </button>
                                                      </h5>
                                                      <hr style={{ margin: '0 0 10px' }} />
                                                      <TransporterTrackingEdit
                                                        trackingId={tracking[0]._id}
                                                        shipmentId={shipment[0]._id}
                                                        transporter={this.state.transporter}
                                                        driverName={this.state.driver_name}
                                                        driverPhoneNumber={
                                                          this.state.driver_phone_number
                                                        }
                                                        callback={() => {
                                                          this.setState(
                                                            {
                                                              openEditTransporter: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                            },
                                                            () => {
                                                              refetchTrackingList();
                                                              refetchdetailShipment();
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                )}

                                                {this.state.openEditDriver == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.openEditDriver}`}
                                                  >
                                                    <div className="inner">
                                                      <h5>
                                                        <b>กรอกข้อมูลพนักงานขับรถ</b>{' '}
                                                        <button
                                                          className="btn btn-link"
                                                          style={{ float: 'right' }}
                                                          onClick={() =>
                                                            this.setState({
                                                              openEditDriver: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                              license_plate_front: '',
                                                              license_plate_back: '',
                                                            })
                                                          }
                                                        >
                                                          <i className="fal fa-times"></i>
                                                        </button>
                                                      </h5>
                                                      <hr style={{ margin: '0 0 10px' }} />

                                                      <AssignDriver
                                                        trackingId={tracking[0]._id}
                                                        shipmentId={shipment[0]._id}
                                                        transporter={this.state.transporter}
                                                        driverName={this.state.driver_name}
                                                        driverPhoneNumber={
                                                          this.state.driver_phone_number
                                                        }
                                                        licensePlateFront={
                                                          this.state.license_plate_front
                                                        }
                                                        licensePlateBack={
                                                          this.state.license_plate_back
                                                        }
                                                        tracking={tracking}
                                                        driverUserId={shipment[0].driver_user_id}
                                                        userSettingTruckId={
                                                          shipment[0].user_setting_truck_id
                                                        }
                                                        openEditDriverMode={
                                                          this.state.openEditDriverMode
                                                        }
                                                        type="import"
                                                        callback={() => {
                                                          this.setState(
                                                            {
                                                              openEditDriver: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                              license_plate_front: '',
                                                              license_plate_back: '',
                                                            },
                                                            () => {
                                                              refetchTrackingList();
                                                              refetchdetailShipment();
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                )}

                                                {this.state.openEditContainer == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.openEditContainer}`}
                                                  >
                                                    <Mutation
                                                      mutation={trackingUpdateImportMutation}
                                                      update={() => {
                                                        this.setState(
                                                          {
                                                            openEditContainer: '',
                                                            cont_number: '',
                                                            seal_number: '',
                                                            tare_weight: '',
                                                            cont_picture: [],
                                                          },
                                                          () => {
                                                            refetchTrackingList();
                                                            refetchdetailShipment();
                                                          }
                                                        );
                                                      }}
                                                      onError={() => {
                                                        alert('กรอกข้อมูลให้ครบถ้วน และ ถูกต้อง');
                                                      }}
                                                    >
                                                      {trackingAddContainer => (
                                                        <div className="inner">
                                                          <h5>
                                                            <b>ข้อมูลคอนเทนเนอร์</b>{' '}
                                                            <button
                                                              className="btn btn-link"
                                                              style={{ float: 'right' }}
                                                              onClick={() =>
                                                                this.setState({
                                                                  openEditContainer: '',
                                                                  cont_number: '',
                                                                  seal_number: '',
                                                                  tare_weight: '',
                                                                  cont_picture: [],
                                                                })
                                                              }
                                                            >
                                                              <i className="fal fa-times"></i>
                                                            </button>
                                                          </h5>
                                                          <hr style={{ margin: '0 0 10px' }} />

                                                          <Row>
                                                            <Col md={12}>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  Cont No *
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="text"
                                                                  value={this.state.cont_number}
                                                                  placeholder="ระบุ"
                                                                  onKeyPress={e =>
                                                                    this.onSubmitAddContainer(
                                                                      e,
                                                                      tracking,
                                                                      trackingAddContainer
                                                                    )
                                                                  }
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      cont_number: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  Seal No*
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="text"
                                                                  value={this.state.seal_number}
                                                                  placeholder="ตัวอย่าง: กข0001"
                                                                  onKeyPress={e =>
                                                                    this.onSubmitAddContainer(
                                                                      e,
                                                                      tracking,
                                                                      trackingAddContainer
                                                                    )
                                                                  }
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      seal_number: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  Tare Weight*
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="text"
                                                                  value={this.state.tare_weight}
                                                                  placeholder="ตัวอย่าง: กข0001"
                                                                  onKeyPress={e =>
                                                                    this.onSubmitAddContainer(
                                                                      e,
                                                                      tracking,
                                                                      trackingAddContainer
                                                                    )
                                                                  }
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      tare_weight: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>

                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  รูปภาพเบอร์ตู้/เบอร์ซีลคอนเทนเนอร์*{' '}
                                                                  <small
                                                                    onClick={() => {
                                                                      if (documents.length < 6) {
                                                                        //อัพโหลดได้สูงสุด 3 ไฟล์
                                                                        this._inputUploadImage.current.click();
                                                                      } else {
                                                                        this.setState({
                                                                          isOpenModalAlertImage: true,
                                                                          textAlertModalImage:
                                                                            'สามารถเพิ่มรูปภาพสินค้าได้สูงสุด 6 ไฟล์',
                                                                        });
                                                                      }
                                                                    }}
                                                                  >
                                                                    เพิ่มรูปภาพ >{' '}
                                                                  </small>
                                                                </TextControlLabel>
                                                                <Row>
                                                                  <Col md={12}>
                                                                    {this.state.cont_picture
                                                                      .length > 0 && (
                                                                      <ImageContainer>
                                                                        {this.state.cont_picture.map(
                                                                          (item, index) => (
                                                                            <div className="image">
                                                                              <div className="image-hover">
                                                                                <div
                                                                                  className="btn-hover-image"
                                                                                  onClick={() => {
                                                                                    this.onDeletePhoto(
                                                                                      item
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  <i className="fal fa-trash-alt"></i>
                                                                                  <span>
                                                                                    ลบรูปภาพ
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                              <img
                                                                                src={`${s3}/${item}`}
                                                                                alt=""
                                                                              />
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </ImageContainer>
                                                                    )}
                                                                  </Col>
                                                                </Row>
                                                              </FormGroup>
                                                              <Button
                                                                onClick={e =>
                                                                  this.onSubmitAddContainer(
                                                                    e,
                                                                    tracking,
                                                                    trackingAddContainer
                                                                  )
                                                                }
                                                                disabled={
                                                                  !this.checkValidateAddContainer()
                                                                }
                                                                className="btn btn-red"
                                                                block
                                                              >
                                                                ตกลง
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      )}
                                                    </Mutation>
                                                  </div>
                                                )}

                                                {/* ยืนยันพนักงานขับรถคืนตู้ */}
                                                {this.state.openReturnContainer && (
                                                  <Mutation
                                                    mutation={editShipment}
                                                    variables={{
                                                      shipmentID: shipment[0]._id,
                                                      shipment_status: 4,
                                                    }}
                                                    update={() => {
                                                      this.setState(
                                                        { openReturnContainer: false },
                                                        () => {
                                                          refetchTrackingList();
                                                          refetchdetailShipment();
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {Updatestatus => (
                                                      <Mutation
                                                        mutation={trackingUpdateImportMutation}
                                                        update={() => Updatestatus()}
                                                        onError={() => {
                                                          alert(
                                                            'ยืนยันพนักงานขับรถคืนตู้ ไม่สำเร็จ'
                                                          );
                                                        }}
                                                      >
                                                        {trackingReturnContainer => (
                                                          <Modal
                                                            show={this.state.openReturnContainer}
                                                            onHide={() =>
                                                              this.setState({
                                                                openReturnContainer: false,
                                                              })
                                                            }
                                                          >
                                                            <Modal.Body>
                                                              <Row style={{ marginBottom: '10px' }}>
                                                                <Col md={12}>
                                                                  <h3>ยืนยันพนักงานขับรถคืนตู้</h3>
                                                                  <p>
                                                                    กดตกลงเพื่อยืนยันว่าคนขับรถได้คืนตู้เรียบร้อยแล้ว
                                                                  </p>
                                                                </Col>
                                                              </Row>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                              <Button
                                                                bsStyle="defalut"
                                                                style={{ width: '120px' }}
                                                                onClick={() =>
                                                                  this.setState({
                                                                    openReturnContainer: false,
                                                                  })
                                                                }
                                                              >
                                                                ยกเลิก
                                                              </Button>
                                                              <Button
                                                                className="btn btn-red"
                                                                style={{ width: '120px' }}
                                                                onClick={e =>
                                                                  this.onSubmitReturnContainer(
                                                                    e,
                                                                    tracking,
                                                                    trackingReturnContainer
                                                                  )
                                                                }
                                                              >
                                                                ตกลง
                                                              </Button>
                                                            </Modal.Footer>
                                                          </Modal>
                                                        )}
                                                      </Mutation>
                                                    )}
                                                  </Mutation>
                                                )}

                                                {/* Export */}
                                                {this.state.openEditTransporterExport == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.openEditTransporterExport}`}
                                                  >
                                                    <div className="inner">
                                                      <h5>
                                                        <b>กรอกข้อมูลบริษัทขนส่ง</b>{' '}
                                                        <button
                                                          className="btn btn-link"
                                                          style={{ float: 'right' }}
                                                          onClick={() =>
                                                            this.setState({
                                                              openEditTransporterExport: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                            })
                                                          }
                                                        >
                                                          <i className="fal fa-times"></i>
                                                        </button>
                                                      </h5>
                                                      <hr style={{ margin: '0 0 10px' }} />
                                                      <TransporterTrackingEdit
                                                        trackingId={tracking[0]._id}
                                                        shipmentId={shipment[0]._id}
                                                        transporter={this.state.transporter}
                                                        driverName={this.state.driver_name}
                                                        driverPhoneNumber={
                                                          this.state.driver_phone_number
                                                        }
                                                        callback={() => {
                                                          this.setState(
                                                            {
                                                              openEditTransporterExport: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                            },
                                                            () => {
                                                              refetchTrackingList();
                                                              refetchdetailShipment();
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                )}

                                                {this.state.openEditDriverExport == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.openEditDriverExport}`}
                                                  >
                                                    <div className="inner">
                                                      <h5>
                                                        <b>กรอกข้อมูลพนักงานขับรถ</b>{' '}
                                                        <button
                                                          className="btn btn-link"
                                                          style={{ float: 'right' }}
                                                          onClick={() =>
                                                            this.setState({
                                                              openEditDriverExport: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                              license_plate_front: '',
                                                              license_plate_back: '',
                                                            })
                                                          }
                                                        >
                                                          <i className="fal fa-times"></i>
                                                        </button>
                                                      </h5>
                                                      <hr style={{ margin: '0 0 10px' }} />

                                                      <AssignDriver
                                                        trackingId={tracking[0]._id}
                                                        shipmentId={shipment[0]._id}
                                                        driverName={this.state.driver_name}
                                                        transporter={this.state.transporter}
                                                        driverPhoneNumber={
                                                          this.state.driver_phone_number
                                                        }
                                                        licensePlateFront={
                                                          this.state.license_plate_front
                                                        }
                                                        licensePlateBack={
                                                          this.state.license_plate_back
                                                        }
                                                        tracking={tracking}
                                                        driverUserId={shipment[0].driver_user_id}
                                                        userSettingTruckId={
                                                          shipment[0].user_setting_truck_id
                                                        }
                                                        openEditDriverMode={
                                                          this.state.openEditDriverMode
                                                        }
                                                        type="export"
                                                        callback={() => {
                                                          this.setState(
                                                            {
                                                              openEditDriverExport: '',
                                                              driver_name: '',
                                                              driver_phone_code: '66',
                                                              driver_phone_number: '',
                                                              license_plate_front: '',
                                                              license_plate_back: '',
                                                            },
                                                            () => {
                                                              refetchTrackingList();
                                                              refetchdetailShipment();
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                )}

                                                {this.state.openEditContainerExport == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.openEditContainerExport}`}
                                                  >
                                                    <Mutation
                                                      mutation={trackingUpdateExportMutation}
                                                      update={() => {
                                                        this.setState(
                                                          {
                                                            openEditContainerExport: '',
                                                            cont_number: '',
                                                            seal_number: '',
                                                            tare_weight: '',
                                                            cont_picture: [],
                                                          },
                                                          () => {
                                                            refetchTrackingList();
                                                            refetchdetailShipment();
                                                          }
                                                        );
                                                      }}
                                                      onError={() => {
                                                        alert('กรอกข้อมูลให้ครบถ้วน และ ถูกต้อง');
                                                      }}
                                                    >
                                                      {trackingAddContainer => (
                                                        <div className="inner">
                                                          <h5>
                                                            <b>ข้อมูลคอนเทนเนอร์</b>{' '}
                                                            <button
                                                              className="btn btn-link"
                                                              style={{ float: 'right' }}
                                                              onClick={() =>
                                                                this.setState({
                                                                  openEditContainerExport: '',
                                                                  cont_number: '',
                                                                  seal_number: '',
                                                                  tare_weight: '',
                                                                  cont_picture: [],
                                                                })
                                                              }
                                                            >
                                                              <i className="fal fa-times"></i>
                                                            </button>
                                                          </h5>
                                                          <hr style={{ margin: '0 0 10px' }} />

                                                          <Row>
                                                            <Col md={12}>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  Cont No *
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="text"
                                                                  value={this.state.cont_number}
                                                                  placeholder="ระบุ"
                                                                  onKeyPress={e =>
                                                                    this.onSubmitAddContainerExport(
                                                                      e,
                                                                      tracking,
                                                                      trackingAddContainer
                                                                    )
                                                                  }
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      cont_number: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  Seal No*
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="text"
                                                                  value={this.state.seal_number}
                                                                  placeholder="ตัวอย่าง: กข0001"
                                                                  onKeyPress={e =>
                                                                    this.onSubmitAddContainerExport(
                                                                      e,
                                                                      tracking,
                                                                      trackingAddContainer
                                                                    )
                                                                  }
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      seal_number: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  Tare Weight*
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="text"
                                                                  value={this.state.tare_weight}
                                                                  placeholder="ตัวอย่าง: กข0001"
                                                                  onKeyPress={e =>
                                                                    this.onSubmitAddContainerExport(
                                                                      e,
                                                                      tracking,
                                                                      trackingAddContainer
                                                                    )
                                                                  }
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      tare_weight: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>

                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  รูปภาพเบอร์ตู้/เบอร์ซีลคอนเทนเนอร์*{' '}
                                                                  <small
                                                                    onClick={() => {
                                                                      if (documents.length < 6) {
                                                                        //อัพโหลดได้สูงสุด 3 ไฟล์
                                                                        this._inputUploadImage.current.click();
                                                                      } else {
                                                                        this.setState({
                                                                          isOpenModalAlertImage: true,
                                                                          textAlertModalImage:
                                                                            'สามารถเพิ่มรูปภาพสินค้าได้สูงสุด 6 ไฟล์',
                                                                        });
                                                                      }
                                                                    }}
                                                                  >
                                                                    เพิ่มรูปภาพ >{' '}
                                                                  </small>
                                                                </TextControlLabel>
                                                                <Row>
                                                                  <Col md={12}>
                                                                    {this.state.cont_picture
                                                                      .length > 0 && (
                                                                      <ImageContainer>
                                                                        {this.state.cont_picture.map(
                                                                          (item, index) => (
                                                                            <div className="image">
                                                                              <div className="image-hover">
                                                                                <div
                                                                                  className="btn-hover-image"
                                                                                  onClick={() => {
                                                                                    this.onDeletePhoto(
                                                                                      item
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  <i className="fal fa-trash-alt"></i>
                                                                                  <span>
                                                                                    ลบรูปภาพ
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                              <img
                                                                                src={`${s3}/${item}`}
                                                                                alt=""
                                                                              />
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </ImageContainer>
                                                                    )}
                                                                  </Col>
                                                                </Row>
                                                              </FormGroup>
                                                              <Button
                                                                onClick={e =>
                                                                  this.onSubmitAddContainerExport(
                                                                    e,
                                                                    tracking,
                                                                    trackingAddContainer
                                                                  )
                                                                }
                                                                disabled={
                                                                  !this.checkValidateAddContainer()
                                                                }
                                                                className="btn btn-red"
                                                                block
                                                              >
                                                                ตกลง
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      )}
                                                    </Mutation>
                                                  </div>
                                                )}

                                                {this.state.OpenEditUploadImageExport == 'open' && (
                                                  <div
                                                    className={`box-content shipment edit ${this.state.OpenEditUploadImageExport}`}
                                                  >
                                                    <Mutation
                                                      mutation={trackingUpdateExportMutation}
                                                      update={() => {
                                                        this.setState(
                                                          {
                                                            OpenEditUploadImageExport: '',
                                                            cont_picture: [],
                                                          },
                                                          () => {
                                                            refetchTrackingList();
                                                            refetchdetailShipment();
                                                          }
                                                        );
                                                      }}
                                                      onError={() => {
                                                        alert('ไม่สามารถอัพโหลดรูปภาพสินค้าได้');
                                                      }}
                                                    >
                                                      {trackingUploadImage => (
                                                        <div className="inner">
                                                          <h5>
                                                            <b>อัพโหลดรูปภาพสินค้า</b>{' '}
                                                            <button
                                                              className="btn btn-link"
                                                              style={{ float: 'right' }}
                                                              onClick={() =>
                                                                this.setState({
                                                                  OpenEditUploadImageExport: '',
                                                                  cont_picture: [],
                                                                })
                                                              }
                                                            >
                                                              <i className="fal fa-times"></i>
                                                            </button>
                                                          </h5>
                                                          <hr style={{ margin: '0 0 10px' }} />

                                                          <Row>
                                                            <Col md={12}>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  รูปภาพสินค้า*{' '}
                                                                  <small
                                                                    onClick={() => {
                                                                      if (documents.length < 6) {
                                                                        //อัพโหลดได้สูงสุด 6 ไฟล์
                                                                        this._inputUploadImage.current.click();
                                                                      } else {
                                                                        this.setState({
                                                                          isOpenModalAlertImage: true,
                                                                          textAlertModalImage:
                                                                            'สามารถเพิ่มรูปภาพสินค้าได้สูงสุด 6 ไฟล์',
                                                                        });
                                                                      }
                                                                    }}
                                                                  >
                                                                    เพิ่มรูปภาพ >{' '}
                                                                  </small>
                                                                </TextControlLabel>
                                                                <Row>
                                                                  <Col md={12}>
                                                                    {this.state.cont_picture
                                                                      .length > 0 && (
                                                                      <ImageContainer>
                                                                        {this.state.cont_picture.map(
                                                                          (item, index) => (
                                                                            <div className="image">
                                                                              <div className="image-hover">
                                                                                <div
                                                                                  className="btn-hover-image"
                                                                                  onClick={() => {
                                                                                    this.onDeletePhoto(
                                                                                      item
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  <i className="fal fa-trash-alt"></i>
                                                                                  <span>
                                                                                    ลบรูปภาพ
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                              <img
                                                                                src={`${s3}/${item}`}
                                                                                alt=""
                                                                              />
                                                                            </div>
                                                                          )
                                                                        )}
                                                                      </ImageContainer>
                                                                    )}
                                                                  </Col>
                                                                </Row>
                                                              </FormGroup>
                                                              <Button
                                                                onClick={e =>
                                                                  this.onSubmitUploadImageExport(
                                                                    e,
                                                                    tracking,
                                                                    trackingUploadImage
                                                                  )
                                                                }
                                                                disabled={
                                                                  !this.state.cont_picture.length >
                                                                  0
                                                                }
                                                                className="btn btn-red"
                                                                block
                                                              >
                                                                ตกลง
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                        </div>
                                                      )}
                                                    </Mutation>
                                                  </div>
                                                )}

                                                {/* ยืนยันพนักงานขับรถได้ใบกำกับ */}
                                                {this.state.openDriverAcceptDocumentExport && (
                                                  <Mutation
                                                    mutation={trackingUpdateExportMutation}
                                                    update={() => {
                                                      this.setState(
                                                        { openDriverAcceptDocumentExport: false },
                                                        () => {
                                                          refetchTrackingList();
                                                          refetchdetailShipment();
                                                        }
                                                      );
                                                    }}
                                                    onError={() => {
                                                      alert(
                                                        'ยืนยันพนักงานขับรถได้ใบกำกับ ไม่สำเร็จ'
                                                      );
                                                    }}
                                                  >
                                                    {trackingReturnContainer => (
                                                      <Modal
                                                        show={
                                                          this.state.openDriverAcceptDocumentExport
                                                        }
                                                        onHide={() =>
                                                          this.setState({
                                                            openDriverAcceptDocumentExport: false,
                                                          })
                                                        }
                                                      >
                                                        <Modal.Body>
                                                          <Row style={{ marginBottom: '10px' }}>
                                                            <Col md={12}>
                                                              <h3>
                                                                ตรวจสอบเอกสารการส่งใบกำกับให้คนขับรถ{' '}
                                                                <br /> ที่ service@giztix.com
                                                              </h3>
                                                              <p>
                                                                กดตกลงเพื่อยืนยันว่าคนขับรถได้รับใบกำกับเรียบร้อยแล้ว
                                                              </p>
                                                            </Col>
                                                          </Row>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                          <Button
                                                            bsStyle="defalut"
                                                            style={{ width: '120px' }}
                                                            onClick={() =>
                                                              this.setState({
                                                                openDriverAcceptDocumentExport: false,
                                                              })
                                                            }
                                                          >
                                                            ยกเลิก
                                                          </Button>
                                                          <Button
                                                            className="btn btn-red"
                                                            style={{ width: '120px' }}
                                                            onClick={e =>
                                                              this.onSubmitDriverAcceptDocument(
                                                                e,
                                                                tracking,
                                                                trackingReturnContainer
                                                              )
                                                            }
                                                          >
                                                            ตกลง
                                                          </Button>
                                                        </Modal.Footer>
                                                      </Modal>
                                                    )}
                                                  </Mutation>
                                                )}

                                                {/* ยืนยันพนักงานขับรถคืนตู้ */}
                                                {this.state.openReturnContainerExport && (
                                                  <Mutation
                                                    mutation={editShipment}
                                                    variables={{
                                                      shipmentID: shipment[0]._id,
                                                      shipment_status: 4,
                                                    }}
                                                    update={() => {
                                                      this.setState(
                                                        { openReturnContainerExport: false },
                                                        () => {
                                                          refetchTrackingList();
                                                          refetchdetailShipment();
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    {Updatestatus => (
                                                      <Mutation
                                                        mutation={trackingUpdateExportMutation}
                                                        update={() => {
                                                          Updatestatus();
                                                        }}
                                                        onError={() => {
                                                          alert(
                                                            'ยืนยันพนักงานขับรถคืนตู้ ไม่สำเร็จ'
                                                          );
                                                        }}
                                                      >
                                                        {trackingReturnContainer => (
                                                          <Modal
                                                            show={
                                                              this.state.openReturnContainerExport
                                                            }
                                                            onHide={() =>
                                                              this.setState({
                                                                openReturnContainerExport: false,
                                                              })
                                                            }
                                                          >
                                                            <Modal.Body>
                                                              <Row style={{ marginBottom: '10px' }}>
                                                                <Col md={12}>
                                                                  <h3>ยืนยันพนักงานขับรถคืนตู้</h3>
                                                                  <p>
                                                                    กดตกลงเพื่อยืนยันว่าคนขับรถได้คืนตู้เรียบร้อยแล้ว
                                                                  </p>
                                                                </Col>
                                                              </Row>
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                              <Button
                                                                bsStyle="defalut"
                                                                style={{ width: '120px' }}
                                                                onClick={() =>
                                                                  this.setState({
                                                                    openReturnContainerExport: false,
                                                                  })
                                                                }
                                                              >
                                                                ยกเลิก
                                                              </Button>
                                                              <Button
                                                                className="btn btn-red"
                                                                style={{ width: '120px' }}
                                                                onClick={e =>
                                                                  this.onSubmitReturnContainerExport(
                                                                    e,
                                                                    tracking,
                                                                    trackingReturnContainer
                                                                  )
                                                                }
                                                              >
                                                                ตกลง
                                                              </Button>
                                                            </Modal.Footer>
                                                          </Modal>
                                                        )}
                                                      </Mutation>
                                                    )}
                                                  </Mutation>
                                                )}
                                              </div>
                                            );
                                          }}
                                        </Query>

                                        {/* คะแนนพนักงานขับรถ */}
                                        <Query
                                          query={trackingList}
                                          variables={{
                                            shipment_id: shipmentId,
                                            check_agent_accept: shipment[0].transporter,
                                          }}
                                          fetchPolicy="network-only"
                                        >
                                          {({ loading, error, data, refetch, variables }) => {
                                            if (loading || error) return <div></div>;
                                            const tracking = data.trackingList.tracking;
                                            // console.log(shipment)
                                            if (
                                              tracking.length > 0 &&
                                              tracking[0].review_score &&
                                              this.state.isOpenDetailReview
                                            ) {
                                              // console.log(tracking[0].review_score)
                                              return (
                                                <div className={`box-content shipment review open`}>
                                                  <div className="inner">
                                                    <h5>
                                                      <b>คะแนนพนักงานขับรถ</b>
                                                      <button
                                                        className="btn btn-link"
                                                        style={{ float: 'right' }}
                                                        onClick={() =>
                                                          this.setState({
                                                            isOpenDetailReview: false,
                                                          })
                                                        }
                                                      >
                                                        <i className="fal fa-times"></i>
                                                      </button>
                                                    </h5>
                                                    <hr style={{ margin: '0 0 10px' }} />
                                                    <Row>
                                                      <Col md={12}>
                                                        <div
                                                          style={{
                                                            paddingTop: '50px',
                                                            lineHeight: 1.2,
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              textAlign: 'center',
                                                              fontWeight: 'bold',
                                                            }}
                                                          >
                                                            <div>
                                                              {`${
                                                                shipment[0].driver_name_under_mama
                                                                  ? shipment[0]
                                                                      .driver_name_under_mama
                                                                  : tracking[0].driver_name
                                                              }`}
                                                            </div>
                                                            <div>
                                                              {`${
                                                                shipment[0].shipment_address[0]
                                                                  .subdictrict
                                                              } - ${
                                                                shipment[0].shipment_address[
                                                                  shipment[0].shipment_address
                                                                    .length - 1
                                                                ].subdictrict
                                                              }`}
                                                            </div>
                                                            <div>{`${shipment[0].shipment_number}`}</div>
                                                          </div>

                                                          <div
                                                            style={{
                                                              marginTop: 50,
                                                              textAlign: 'center',
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                fontWeight: 'bold',
                                                              }}
                                                            >
                                                              {
                                                                this.getRatingToWord(
                                                                  tracking[0].review_score
                                                                )['th']
                                                              }
                                                            </div>
                                                            <div
                                                              style={{
                                                                marginTop: 20,
                                                              }}
                                                            >
                                                              <StarRating
                                                                disabled="disabled"
                                                                size={28}
                                                                rating={tracking[0].review_score}
                                                              />
                                                            </div>
                                                          </div>

                                                          {tracking[0].review_reason.length > 0 && (
                                                            <div>
                                                              <div
                                                                style={{
                                                                  marginTop: 30,
                                                                  fontWeight: 'bold',
                                                                }}
                                                              >
                                                                เหตุผล
                                                              </div>
                                                              <div
                                                                style={{
                                                                  marginTop: 10,
                                                                }}
                                                              >
                                                                <ReasonReviewContainer>
                                                                  {tracking[0].review_reason.map(
                                                                    (item, index, arr) => (
                                                                      <ReasonReviewList
                                                                        selected={true}
                                                                        key={index}
                                                                        disabled
                                                                      >
                                                                        {item['th']}
                                                                      </ReasonReviewList>
                                                                    )
                                                                  )}
                                                                </ReasonReviewContainer>
                                                              </div>
                                                            </div>
                                                          )}

                                                          {tracking[0].review_detail && (
                                                            <div>
                                                              <div
                                                                style={{
                                                                  marginTop: 30,
                                                                  fontWeight: 'bold',
                                                                }}
                                                              >
                                                                แสดงความคิดเห็น
                                                              </div>
                                                              <div
                                                                style={{
                                                                  marginTop: 10,
                                                                }}
                                                              >
                                                                {tracking[0].review_detail}
                                                              </div>
                                                            </div>
                                                          )}
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              );
                                            }
                                            return null;
                                          }}
                                        </Query>
                                      </div>
                                    );
                                  }}
                                </Query>
                              }
                            />
                            <Mutation
                              mutation={shipmentUpdate}
                              variables={this.renderVariablesInvoicesandReceipts(shipment[0])}
                              update={() => {
                                this.setState({ show_invoicesandreceipts: false }, () => {
                                  refetchdetailShipment();
                                });
                              }}
                            >
                              {shipmentUpdate => (
                                <ModalConfirm
                                  show={this.state.show_invoicesandreceipts}
                                  onPressButtonCancel={() =>
                                    this.setState({
                                      show_invoicesandreceipts: false,
                                    })
                                  }
                                  onPressButtonConfirm={() => shipmentUpdate()}
                                  labelButtonConfirm={`ตกลง`}
                                  labelButtonCancel={`กลับ`}
                                  disabledButtonConfirm={
                                    this.state.inputshipmentInvoices == '' &&
                                    this.state.inputshipmentReceipts == ''
                                  }
                                  styleColorButtonConfirm="green"
                                  titleHeader={`รหัสใบแจ้งหนี้ / ใบเสร็จ`}
                                  onHide={() =>
                                    this.setState({
                                      show_invoicesandreceipts: false,
                                    })
                                  }
                                >
                                  <div style={{ lineHeight: 1 }}>
                                    <div style={{ marginBottom: 10 }}>รหัสใบแจ้งหนี้</div>
                                    <FormControl
                                      style={{ marginBottom: '20px' }}
                                      type="text"
                                      value={this.state.inputshipmentInvoices}
                                      placeholder="ระบุ"
                                      onChange={event =>
                                        this.setState({
                                          inputshipmentInvoices: event.target.value,
                                        })
                                      }
                                      name="inputshipmentInvoices"
                                    />
                                    <div style={{ marginBottom: 10 }}>รหัสใบเสร็จ</div>
                                    <FormControl
                                      type="text"
                                      value={this.state.inputshipmentReceipts}
                                      placeholder="ระบุ"
                                      onChange={event =>
                                        this.setState({
                                          inputshipmentReceipts: event.target.value,
                                        })
                                      }
                                      name="inputshipmentReceipts"
                                    />
                                  </div>
                                </ModalConfirm>
                              )}
                            </Mutation>

                            <Mutation
                              mutation={cancelShipment}
                              variables={this.renderVariablesCancelShipment(shipment[0])}
                              update={() => {
                                this.setState({ show_cancel: false }, () => {
                                  refetchdetailShipment();
                                });
                              }}
                            >
                              {cancelShipment => (
                                <Modal
                                  show={this.state.show_cancel}
                                  onHide={() => this.setState({ show_cancel: false })}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      ยกเลิกงานขนส่ง {shipment[0].shipment_number}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Row
                                      style={{
                                        marginBottom: '10px',
                                        borderBottom: '1px dashed #ddd',
                                      }}
                                    >
                                      {this.renderShipmentCancelError(shipment[0])}
                                    </Row>

                                    <Row style={{ marginBottom: '10px' }}>
                                      <Col md={12}>
                                        <b>
                                          เหตุผลที่ยกเลิกงาน{' '}
                                          <small style={{ color: '#D90101' }}>*</small>
                                        </b>
                                      </Col>
                                    </Row>

                                    <Select
                                      name={`driver_select`}
                                      value={this.state.rejectSelected}
                                      onChange={event => this.handleChangeReject(event)}
                                      options={rejectList}
                                      placeholder="เลือกเหตุผลที่ยกเลิกงาน"
                                    />

                                    {this.state.showRejectDetail && (
                                      <FormControl
                                        style={{ marginTop: '10px' }}
                                        type="text"
                                        value={this.state.rejectDetail}
                                        placeholder="ระบุ"
                                        onChange={event =>
                                          this.setState({ rejectDetail: event.target.value })
                                        }
                                        name="rejectDetail"
                                      />
                                    )}

                                    <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
                                      <Col md={12}>
                                        <b>
                                          จำนวนเงินที่ต้องการคืน (บาท)
                                          <small style={{ color: '#D90101' }}>*</small>
                                        </b>
                                      </Col>
                                    </Row>

                                    <FormControl
                                      type="number"
                                      value={this.state.refundPrice}
                                      max={this.state.shipmentGrandTotal}
                                      placeholder="0.00"
                                      onChange={event => this.checkMaxPrice(event.target.value)}
                                      name="refundPrice"
                                    />

                                    <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
                                      <Col md={12}>
                                        <b>
                                          กรอกหมายเลขงานขนส่งที่ยกเลิกงาน
                                          <small style={{ color: '#D90101' }}>*</small>
                                        </b>
                                      </Col>
                                    </Row>

                                    <FormControl
                                      type="text"
                                      value={this.state.rejectNumber}
                                      placeholder="19040079"
                                      onChange={event =>
                                        this.setState({ rejectNumber: event.target.value })
                                      }
                                      name="rejectNumber"
                                    />

                                    <Row style={{ marginTop: '10px' }}>
                                      <Col md={12}>คุณต้องการยกเลิกงานขนส่งนี้ให้กด "ตกลง"</Col>
                                    </Row>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      style={{ float: 'left' }}
                                      onClick={() => this.setState({ show_cancel: false })}
                                    >
                                      กลับ
                                    </Button>
                                    <Button
                                      bsStyle="primary"
                                      disabled={this.checkDisableCancel()}
                                      onClick={() =>
                                        this.handlecheckcancel(shipment[0], cancelShipment())
                                      }
                                    >
                                      ตกลง
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              )}
                            </Mutation>

                            <Mutation
                              mutation={editpickupDate}
                              variables={{
                                shipmentID: shipmentId,
                                pick_up_date: this.state.pick_up_date_show,
                              }}
                              update={() => {
                                this.setState({ openEditPickupdate: false }, () => {
                                  refetchdetailShipment();
                                });
                              }}
                            >
                              {editpickupDate => (
                                <Modal
                                  show={this.state.openEditPickupdate}
                                  onHide={() => this.setState({ openEditPickupdate: false })}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      แก้ไขวันเริ่มงาน {shipment[0].shipment_number}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Row style={{ marginBottom: '10px' }}>
                                      <Col md={12}>
                                        <b>เลือกวัน-เวลา ที่เริ่มต้นงาน</b>
                                      </Col>
                                    </Row>
                                    <Datetime
                                      value={this.state.pick_up_date}
                                      dateFormat="DD/MM/YYYY"
                                      timeFormat="HH:mm"
                                      timeConstraints={{ minutes: { step: 5 } }}
                                      onChange={event => this.handleDatePickerEdit(event)}
                                    />

                                    <Row style={{ marginTop: '10px' }}>
                                      <Col md={12}>
                                        คุณต้องการแก้ไขวันเริ่มงาน งานขนส่งนี้ให้กด "ตกลง"
                                      </Col>
                                    </Row>
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      style={{ float: 'left' }}
                                      onClick={() => this.setState({ openEditPickupdate: false })}
                                    >
                                      กลับ
                                    </Button>
                                    <Button
                                      bsStyle="primary"
                                      disabled={this.state.pick_up_date == ''}
                                      onClick={() => editpickupDate()}
                                    >
                                      ตกลง
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              )}
                            </Mutation>

                            {this.state.openEditContactModal && (
                              <Mutation
                                mutation={editShipment}
                                variables={this.renderVariablesShipment(shipment[0])}
                                update={() => {
                                  this.setState({ openEditContactModal: false }, () => {
                                    refetchdetailShipment();
                                  });
                                }}
                              >
                                {editShipment => (
                                  <Modal
                                    show={this.state.openEditContactModal}
                                    onHide={() => this.setState({ openEditContactModal: false })}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>
                                        แก้ไขข้อมูลติดต่อ {shipment[0].shipment_number}
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      {shipment[0].shipment_address.map((item, index) => (
                                        <Row
                                          style={{
                                            marginBottom: '20px',
                                            paddingBottom: '20px',
                                            borderBottom: '1px solid #dddddd',
                                          }}
                                        >
                                          <strong style={{ marginLeft: '15px' }}>
                                            {this.renderAddressMode(item.mode, index)}
                                          </strong>
                                          <Col style={{ margin: '10px 0' }} md={12}>
                                            ชื่อผู้ติดต่อ :{' '}
                                          </Col>
                                          <Col md={12}>
                                            <FormControl
                                              type="text"
                                              value={this.state.addressObj[index].contact_name}
                                              placeholder="ชื่อผู้ติดต่อ"
                                              onChange={event =>
                                                this.setValueContactName(index, event.target.value)
                                              }
                                              name="pickup_contact_name"
                                            />
                                          </Col>
                                          <Col style={{ margin: '10px 0' }} md={12}>
                                            เบอร์โทรติดต่อ :{' '}
                                          </Col>
                                          <Col md={12}>
                                            <FormControl
                                              type="text"
                                              value={this.state.addressObj[index].contact_tel}
                                              placeholder="เบอร์โทรติดต่อ"
                                              onChange={event =>
                                                this.setValueContactTel(index, event.target.value)
                                              }
                                              name="pickup_contact_tel"
                                            />
                                          </Col>

                                          <Col style={{ margin: '10px 0' }} md={12}>
                                            วัน/เวลา ที่{this.state.addressObj[index].mode} :{' '}
                                          </Col>
                                          <Col md={12}>
                                            <Datetime
                                              value={this.state.addressObj[index].delivery_date}
                                              dateFormat="DD/MM/YYYY"
                                              timeFormat="HH:mm"
                                              timeConstraints={{ minutes: { step: 5 } }}
                                              onChange={event =>
                                                this.handleDatePickerAddress(index, event)
                                              }
                                            />
                                          </Col>

                                          <Col style={{ margin: '10px 0' }} md={12}>
                                            หมายเหตุ :{' '}
                                          </Col>
                                          <Col md={12}>
                                            <FormControl
                                              componentclassName="textarea"
                                              type="text"
                                              value={this.state.addressObj[index].remark}
                                              placeholder="หมายเหตุ "
                                              onChange={event =>
                                                this.setValueContactRemark(
                                                  index,
                                                  event.target.value
                                                )
                                              }
                                              name="pickup_remark"
                                            />
                                          </Col>
                                        </Row>
                                      ))}
                                      <Row>
                                        <Col style={{ margin: ' 0 0 10px' }} md={12}>
                                          <b>หมายเหตุ</b>
                                        </Col>
                                        <Col md={12}>
                                          <FormControl
                                            componentclassName="textarea"
                                            type="text"
                                            value={this.state.remark}
                                            placeholder="หมายเหตุ "
                                            onChange={event =>
                                              this.setState({ remark: event.target.value })
                                            }
                                            name="remark"
                                          />
                                        </Col>
                                      </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        style={{ float: 'left' }}
                                        onClick={() =>
                                          this.setState({ openEditContactModal: false })
                                        }
                                      >
                                        กลับ
                                      </Button>
                                      <Button
                                        bsStyle="primary"
                                        disabled={this.chkEditShipment()}
                                        onClick={() => editShipment()}
                                      >
                                        ตกลง
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>
                                )}
                              </Mutation>
                            )}

                            <Mutation
                              mutation={createShipmentSlaMutation}
                              update={(cache, { data }) => {
                                this.addNotification('success', 'บันทึกสำเร็จแล้ว');
                                this.getShipmentCase(data.createShipmentSla.shipment);
                                this.setState({
                                  newCase: false,
                                  caseIndex: data.createShipmentSla.sla_case_number,
                                });
                                this.getShipmentCaseDetail(data.createShipmentSla._id, admin);
                                // this.handlerCreateNewCase(admin)
                                // console.log('mutation success')
                              }}
                              onError={error => {
                                this.addNotification('error', error.graphQLErrors[0].message);
                              }}
                              refetchQueries={() => ['shipmentAdmins']}
                            >
                              {(createShipment, { loading, refetch }) => (
                                <Mutation
                                  mutation={updateShipmentSla}
                                  update={(cache, { data }) => {
                                    // this.addNotification('success', 'บันทึกสำเร็จแล้ว');
                                    this.setState({ showConfirmModalCase: true });
                                    // console.log(data)
                                    this.getShipmentCase(data.updateShipmentSla.shipment);
                                    this.state.saveCaseCheck == 1
                                      ? this.getShipmentCaseDetailCancelFirst(
                                          data.updateShipmentSla._id,
                                          admin
                                        )
                                      : this.getShipmentCaseDetailCancelSecond(
                                          data.updateShipmentSla._id,
                                          admin
                                        );
                                    // this.getShipmentCaseDetail(data.updateShipmentSla._id, admin)
                                  }}
                                  onError={error => {
                                    this.addNotification('error', error.graphQLErrors[0].message);
                                  }}
                                  refetchQueries={() => ['shipmentAdmins']}
                                >
                                  {(updateShipment, { loading }) => (
                                    <div>
                                      <Modal
                                        show={this.state.showProblem}
                                        onHide={() => {
                                          this.resetFieldCase();
                                          this.setState({ showProblem: false });
                                        }}
                                        dialogClassName="modal90x90"
                                      >
                                        <Modal.Header>
                                          <Modal.Title>
                                            <div>
                                              <b>
                                                รายงานปัญหา รหัสงานขนส่ง:{' '}
                                                {this.state.shipmentNumber}
                                              </b>
                                              <Button
                                                onClick={() => {
                                                  this.resetFieldCase();
                                                  this.setState({ showProblem: false });
                                                }}
                                                style={{ float: 'right', marginRight: '10px' }}
                                              >
                                                X ปิด
                                              </Button>
                                              <OverlayTrigger
                                                trigger={['hover']}
                                                key="bottom"
                                                placement="bottom"
                                                rootClose={true}
                                                overlay={
                                                  <Popover
                                                    style={{
                                                      width: '300px',
                                                      padding: '5px 0px',
                                                      overflow: 'hidden',
                                                      zIndex: 1070,
                                                    }}
                                                    id={`popover-positioned-${this.state.shipmentID}`}
                                                    title={`รายละเอียดงานขนส่ง`}
                                                  >
                                                    <Grid fluid style={{ paddingLeft: '0px' }}>
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>วันรับสินค้า:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            <Moment format="DD/MM/YYYY HH:mm">
                                                              {this.state.pickUpDate}
                                                            </Moment>
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>ผู้ส่งสินค้า:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.shipperName}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <hr style={{ margin: '5px' }} />
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>ประเภทรถ:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.truckType}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>

                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>พนักงานขับรถ:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.driverName}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>เบอร์โทรศัพท์:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.driverPhone}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <hr style={{ margin: '5px' }} />
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={7}>
                                                          <div>จุดรับสินค้า/ รับตู้ :</div>
                                                        </Col>
                                                        <ColRightPopup md={5}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.pickUpPoint}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={7}>
                                                          <div>จุดส่ง/ ขึ้น/ ลงสินค้า:</div>
                                                        </Col>
                                                        <ColRightPopup md={5}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.dropPoint}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>นายหน้า:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          <div
                                                            style={{
                                                              overflow: 'hidden',
                                                              whiteSpace: 'nowrap',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {this.state.transporterName}
                                                          </div>
                                                        </ColRightPopup>
                                                      </Row>
                                                      <hr style={{ margin: '5px' }} />
                                                      <Row style={{ fontSize: '18px' }}>
                                                        <Col md={6}>
                                                          <div>บริการเสริม:</div>
                                                        </Col>
                                                        <ColRightPopup md={6}>
                                                          {
                                                            // console.log(this.state.additionService)
                                                          }
                                                          {this.state.additionService.length > 0
                                                            ? this.state.additionService.map(item =>
                                                                item.qty ? (
                                                                  <div>
                                                                    {`${item.additional}X${item.qty}`}
                                                                  </div>
                                                                ) : (
                                                                  <div>{`${item.additional}`}</div>
                                                                )
                                                              )
                                                            : '-'}
                                                        </ColRightPopup>
                                                      </Row>
                                                    </Grid>
                                                  </Popover>
                                                }
                                              >
                                                <i
                                                  style={{
                                                    float: 'right',
                                                    color: '#0B9CFF',
                                                    marginRight: '15px',
                                                    marginTop: '7px',
                                                  }}
                                                  class="fas fa-info-circle"
                                                ></i>
                                              </OverlayTrigger>
                                            </div>
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                          style={{
                                            height: 'calc(85vh)',
                                            padding: 'unset',
                                            backgroundColor: '#F7F7F8',
                                          }}
                                        >
                                          <NotificationSystem
                                            ref={this.notificationSystem}
                                            style={styleNotification}
                                          />
                                          <div style={{ display: 'flex', height: 'inherit' }}>
                                            <ColLeft>
                                              <div>
                                                <UlTruck style={{ marginBottom: '0px' }}>
                                                  <li
                                                    onClick={() => this.handlerCreateNewCase(admin)}
                                                    style={{
                                                      color: 'unset',
                                                      backgroundColor: '#F7F7F8',
                                                      opacity: this.state.newCase ? '1' : '0.5',
                                                    }}
                                                  >
                                                    <b>
                                                      สร้างรายการปัญหา
                                                      <i
                                                        style={{
                                                          position: 'unset',
                                                          float: 'right',
                                                        }}
                                                        className="fal fa-chevron-right"
                                                      ></i>
                                                    </b>
                                                  </li>
                                                </UlTruck>
                                                <UlTruck
                                                  style={{
                                                    marginBottom: 0,
                                                    marginTop: 0,
                                                  }}
                                                >
                                                  {
                                                    // console.log(this.state.shipmentSlas)
                                                  }
                                                  {this.state.shipmentSlas.length > 0 &&
                                                    this.state.shipmentSlas.map((item, index) => (
                                                      <li
                                                        style={{
                                                          borderLeft:
                                                            item.sla_status == 1
                                                              ? '5px solid #FF9700'
                                                              : '5px solid #6DD634',
                                                        }}
                                                        className={
                                                          item.sla_case_number ==
                                                          this.state.caseIndex
                                                            ? 'active'
                                                            : ''
                                                        }
                                                        onClick={() =>
                                                          this.getShipmentCaseDetail(
                                                            item._id,
                                                            admin
                                                          )
                                                        }
                                                      >
                                                        <div>
                                                          <b>
                                                            {index + 1}.{' '}
                                                            {item.title ? item.title : '-'}
                                                          </b>
                                                        </div>
                                                        <div>ID: #{item.sla_case_number}</div>
                                                        {item.sla_status == 1 ? (
                                                          moment() > moment(item.sla_exp) ? null : (
                                                            <CountDownTimer
                                                              exceed={() =>
                                                                this.exceedCountdownDate(
                                                                  null,
                                                                  item.shipment
                                                                )
                                                              }
                                                              timeTillDate={moment(
                                                                item.sla_exp
                                                              ).format('YYYY-MM-DD HH:mm:ss')}
                                                              timeFormat="YYYY-MM-DD HH:mm:ss"
                                                              type="once"
                                                            />
                                                          )
                                                        ) : null}
                                                        <div>
                                                          <b>
                                                            {item.sla_status == 1 ? (
                                                              moment() > moment(item.sla_exp) ? (
                                                                <span
                                                                  style={{
                                                                    color: 'red',
                                                                    fontWeight: 'bold',
                                                                  }}
                                                                >
                                                                  เกินเวลา
                                                                </span>
                                                              ) : (
                                                                this.handlerCaseStatus(
                                                                  item.sla_status,
                                                                  item.sla_exp,
                                                                  item.completed_date
                                                                )
                                                              )
                                                            ) : (
                                                              this.handlerCaseStatus(
                                                                item.sla_status,
                                                                item.sla_exp,
                                                                item.completed_date
                                                              )
                                                            )}{' '}
                                                          </b>
                                                        </div>
                                                        <i className="fal fa-chevron-right"></i>
                                                      </li>
                                                    ))}
                                                </UlTruck>
                                                {
                                                  // <Button
                                                  //     onClick={() => this.handlerCreateNewCase(admin)}
                                                  //     style={{marginLeft: '10px', fontSize: '16px', fontWeight: 'bold' }}
                                                  //     disabled={this.state.newCase}
                                                  // >
                                                  //     + เพิ่มรายการ
                                                  // </Button>
                                                }
                                              </div>
                                            </ColLeft>

                                            <ColRight>
                                              {
                                                /////////////////////////
                                                //       CARD 1       //
                                                ////////////////////////
                                              }
                                              <div
                                                style={{
                                                  backgroundColor: '#fff',
                                                  padding: '10px',
                                                  borderRadius: '5px',
                                                }}
                                              >
                                                <Row>
                                                  <Col md={4}>
                                                    <b>สร้างรายการปัญหา</b>
                                                  </Col>
                                                  <Col md={4}>
                                                    สถานะ:{' '}
                                                    {this.state.newCase ? (
                                                      <b>กำลังสร้าง...</b>
                                                    ) : this.state.caseStatus == 1 ? (
                                                      moment() > moment(this.state.expireDate) ? (
                                                        <span
                                                          style={{
                                                            color: 'red',
                                                            fontWeight: 'bold',
                                                          }}
                                                        >
                                                          เกินเวลา
                                                        </span>
                                                      ) : (
                                                        this.handlerCaseStatus(
                                                          this.state.caseStatus,
                                                          this.state.expireDateCheck,
                                                          this.state.completedDateCheck
                                                        )
                                                      )
                                                    ) : (
                                                      this.handlerCaseStatus(
                                                        this.state.caseStatus,
                                                        this.state.expireDateCheck,
                                                        this.state.completedDateCheck
                                                      )
                                                    )}
                                                  </Col>
                                                  <Col md={4}>
                                                    Case ID:{' '}
                                                    <b>
                                                      {this.state.newCase
                                                        ? '-'
                                                        : this.state.caseIndex}
                                                    </b>
                                                  </Col>
                                                </Row>
                                                <hr style={{ margin: '5px' }} />
                                                {
                                                  //  หัวข้อปัญหา
                                                }
                                                <Row>
                                                  <Col md={7}>
                                                    <SelectBox
                                                      disabled={
                                                        this.state.newCase
                                                          ? false
                                                          : !this.state.editable
                                                      }
                                                      onChange={event => {
                                                        this.setState({
                                                          caseTitle: {
                                                            value: event.value,
                                                            label: event.label,
                                                          },
                                                          caseLevel: {
                                                            value: event.level,
                                                            label: `Level ${event.level}`,
                                                          },
                                                          caseTitleChange: true,
                                                          caseTitleId: event.titleId,
                                                          caseLevelId: event.levelId,
                                                          caseLevelDetail: caseLevelList.filter(
                                                            item => item.level == event.level
                                                          ),
                                                          caseTitleInvalid: false,
                                                          caseLevelInvalid: false,
                                                        });
                                                      }}
                                                      value={this.state.caseTitle}
                                                      options={caseTitlelist}
                                                      style={{ marginTop: '10px' }}
                                                      inputLabel="หัวข้อปัญหา"
                                                      placeholder="- เลือก -"
                                                      required
                                                      messageError={
                                                        this.state.caseTitleInvalid &&
                                                        'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                  <Col md={5}>
                                                    <SelectBox
                                                      disabled
                                                      value={this.state.caseLevel}
                                                      options={caseLevelList}
                                                      style={{ marginTop: '10px' }}
                                                      inputLabel="ระดับปัญหา"
                                                      placeholder="- เลือก -"
                                                      required
                                                      messageError={
                                                        this.state.caseLevelInvalid &&
                                                        'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md={12}>
                                                    <BoxDashboard>
                                                      <Col md={4}>
                                                        <span>Service Time </span>
                                                        {this.state.caseTitleChange ? (
                                                          <div>
                                                            <b>
                                                              {this.state.caseLevelDetail[0]
                                                                ? this.renderServiceTime(
                                                                    this.state.caseLevelDetail[0]
                                                                      .service_time
                                                                  )
                                                                : '-'}
                                                            </b>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <b>
                                                              {' '}
                                                              {this.state.serviceTimeCase
                                                                ? this.renderServiceTime(
                                                                    this.state.serviceTimeCase
                                                                  )
                                                                : '-'}{' '}
                                                            </b>
                                                          </div>
                                                        )}
                                                      </Col>
                                                      <Col md={4}>
                                                        <span>ระยะเวลาทั้งหมด </span>
                                                        {this.state.caseTitleChange ? (
                                                          <div>
                                                            <b>
                                                              {this.state.caseLevelDetail[0]
                                                                ? `${this.state.caseLevelDetail[0].respond_time.time} ${this.state.caseLevelDetail[0].respond_time.unit}`
                                                                : '-'}
                                                            </b>
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            <b>
                                                              {' '}
                                                              {this.state.levelTime
                                                                ? `${this.state.levelTime} ${this.state.levelUnit}`
                                                                : '-'}{' '}
                                                            </b>
                                                          </div>
                                                        )}
                                                      </Col>
                                                      <Col md={4}>
                                                        <span>เหลือเวลาในการแก้ไข </span>
                                                        {this.state.newCase ? (
                                                          <div>
                                                            <b>-</b>
                                                          </div>
                                                        ) : this.state.caseStatus === 1 ? (
                                                          moment() >
                                                          moment(this.state.expireDate) ? (
                                                            <div>
                                                              <b>-</b>
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <b>
                                                                <CountDownTimer
                                                                  exceed={() =>
                                                                    this.exceedCountdownDate(
                                                                      this.state.caseId,
                                                                      null
                                                                    )
                                                                  }
                                                                  timeTillDate={
                                                                    this.state.expireDate
                                                                  }
                                                                  timeFormat="YYYY-MM-DD HH:mm:ss"
                                                                />{' '}
                                                              </b>
                                                            </div>
                                                          )
                                                        ) : (
                                                          <div>
                                                            <b>-</b>
                                                          </div>
                                                        )}
                                                      </Col>
                                                    </BoxDashboard>
                                                  </Col>
                                                </Row>

                                                <hr style={{ margin: '5px' }} />
                                                {
                                                  //รายละเอียดปัญหา
                                                }
                                                <Row>
                                                  <Col md={8}>
                                                    <TextArea
                                                      disabled={
                                                        this.state.newCase
                                                          ? false
                                                          : !this.state.editable
                                                      }
                                                      onChange={event =>
                                                        this.setState({
                                                          caseDetail: event.target.value,
                                                          caseDetailInvalid: false,
                                                        })
                                                      }
                                                      value={this.state.caseDetail}
                                                      inputLabel="รายละเอียดปัญหา"
                                                      style={{ marginTop: '10px' }}
                                                      placeholder="ระบุ"
                                                      required
                                                      messageError={
                                                        this.state.caseDetailInvalid &&
                                                        'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                  {
                                                    //วัน-เวลาในการรับแจ้งปัญหา
                                                  }
                                                  <Col md={4}>
                                                    <div
                                                      style={{
                                                        marginTop: '10px',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color:
                                                          this.state.InformRecieveDateTimeInvalid &&
                                                          '#FF0000',
                                                      }}
                                                    >
                                                      วัน-เวลาที่เกิดปัญหา
                                                      <span style={{ color: '#FF0000' }}>*</span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        border:
                                                          this.state.InformRecieveDateTimeInvalid &&
                                                          '1px solid #FF0000',
                                                        borderRadius: '5px',
                                                      }}
                                                    >
                                                      <Datetime
                                                        value={this.state.InformRecieveDateTimeShow}
                                                        dateFormat="DD/MM/YYYY"
                                                        timeFormat="HH:mm"
                                                        inputProps={{
                                                          disabled: this.state.newCase
                                                            ? false
                                                            : !this.state.editable,
                                                          placeholder: '00/00/00 - 00:00น',
                                                        }}
                                                        className="caseModal"
                                                        input={true}
                                                        onChange={event =>
                                                          this.handleDatePicker(event, 'receive')
                                                        }
                                                      />
                                                    </div>
                                                    {this.state.InformRecieveDateTimeInvalid && (
                                                      <div
                                                        style={{
                                                          fontSize: '16px',
                                                          color: '#FF0000',
                                                          height: '22px',
                                                          textAlign: 'right',
                                                        }}
                                                      >
                                                        {this.state.InformRecieveDateTimeShow
                                                          ? 'เวลาต้องไม่เกินกว่าเวลาปัจจุบัน'
                                                          : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                                      </div>
                                                    )}
                                                  </Col>
                                                  {
                                                    //แนบไฟล์
                                                  }
                                                  <Col md={12}>
                                                    <Mutation
                                                      mutation={singleUploadMutation}
                                                      update={(cache, { data }) => {
                                                        // console.log(data.singleUpload.path)
                                                        // console.log(cache)
                                                        this.setState({ animationUpload: false });
                                                        this.setState(previousState => ({
                                                          attachFile: [
                                                            ...previousState.attachFile,
                                                            data.singleUpload.path,
                                                          ],
                                                        }));
                                                      }}
                                                      onError={error => {
                                                        console.log(error);
                                                      }}
                                                    >
                                                      {uploadFile => {
                                                        return (
                                                          <input
                                                            accept="image/jpeg,image/png,application/pdf"
                                                            onChange={event => {
                                                              const files = event.target.files[0];
                                                              const checkFileTypeImage = this.checkFileTypeImage(
                                                                files
                                                              );
                                                              if (checkFileTypeImage === false) {
                                                                this.addNotification(
                                                                  'error',
                                                                  'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png, pdf เท่านั้น'
                                                                );
                                                                return;
                                                              }
                                                              files &&
                                                                this.setState({
                                                                  animationUpload: true,
                                                                });
                                                              this._inputAttachFileFirstCase.current
                                                                .files[0] &&
                                                                this.handleSingleUploadDocumentCase(
                                                                  uploadFile,
                                                                  this._inputAttachFileFirstCase.current.files[0].name.split(
                                                                    '.'
                                                                  )[0],
                                                                  event
                                                                );
                                                            }}
                                                            type="file"
                                                            id="file"
                                                            style={{ display: 'none' }}
                                                            ref={this._inputAttachFileFirstCase}
                                                          />
                                                        );
                                                      }}
                                                    </Mutation>

                                                    <div style={{ marginTop: '10px' }}>
                                                      <Button
                                                        disabled={
                                                          this.state.newCase
                                                            ? false
                                                            : !this.state.editable
                                                        }
                                                        onClick={() =>
                                                          this._inputAttachFileFirstCase.current.click()
                                                        }
                                                        style={{
                                                          border: '1px dashed #0B9CFF',
                                                          padding: '2.5px unset',
                                                          color: '#0B9CFF',
                                                        }}
                                                      >
                                                        + แนบไฟล์
                                                      </Button>
                                                      <span
                                                        style={{ color: 'grey', marginLeft: '5px' }}
                                                      >
                                                        รูปแบบไฟล์แนบรับ .jpg, .jpeg, .png , .pdf
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  {// this.state.attachFile.length > 0 && console.log(this.state.attachFile)
                                                  this.state.animationUpload ? (
                                                    <Col md={12}>
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            'rgba(10, 156, 255, 0.2)',
                                                          color: 'black',
                                                          borderRadius: '5px',
                                                          border: '0.1px solid #E7E7E7',
                                                          marginTop: '5px',
                                                          padding: '5px 5px',
                                                          position: 'relative',
                                                        }}
                                                      >
                                                        <i class="fad fa-spinner-third fa-spin"></i>
                                                      </div>
                                                    </Col>
                                                  ) : null}

                                                  {this.state.attachFile &&
                                                    this.state.attachFile.length > 0 &&
                                                    this.state.attachFile.map(
                                                      (item, index) =>
                                                        item && (
                                                          <Col md={12}>
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  'rgba(10, 156, 255, 0.2)',
                                                                borderRadius: '5px',
                                                                border: '0.1px solid #E7E7E7',
                                                                marginTop: '5px',
                                                                padding: '5px 5px',
                                                                position: 'relative',
                                                              }}
                                                            >
                                                              {
                                                                <a
                                                                  href={s3 + '/' + item}
                                                                  target="_blank"
                                                                  style={{ color: 'black' }}
                                                                >
                                                                  {item.split('/').length > 2
                                                                    ? item.split('/')[4]
                                                                    : item}
                                                                </a>
                                                              }
                                                              {
                                                                <Button
                                                                  disabled={
                                                                    this.state.newCase
                                                                      ? false
                                                                      : !this.state.editable
                                                                  }
                                                                  style={{
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    padding: 'unset',
                                                                    border: 'unset',
                                                                  }}
                                                                  onClick={() =>
                                                                    this.setState(prevState => ({
                                                                      attachFile: prevState.attachFile.filter(
                                                                        (itemPre, indexPre) =>
                                                                          indexPre !== index
                                                                      ),
                                                                    }))
                                                                  }
                                                                >
                                                                  <i
                                                                    style={{ color: 'red' }}
                                                                    class="fas fa-trash-alt"
                                                                  ></i>
                                                                </Button>
                                                              }
                                                            </div>
                                                          </Col>
                                                        )
                                                    )}
                                                  {
                                                    //ผู้รับปัญหา
                                                  }
                                                  <Col md={6}>
                                                    <Input
                                                      disabled
                                                      onChange={event =>
                                                        this.setState({
                                                          caseOwner: event.target.value,
                                                          caseOwnerInvalid: false,
                                                        })
                                                      }
                                                      value={this.state.caseOwner}
                                                      inputLabel="ผู้รับปัญหา"
                                                      style={{ marginTop: '10px' }}
                                                      placeholder="ระบุ"
                                                      required
                                                      messageError={this.state.caseOwnerInvalid}
                                                    />
                                                  </Col>
                                                  {
                                                    //ช่องทางรับปัญหา
                                                  }
                                                  <Col md={6}>
                                                    <SelectBox
                                                      disabled={
                                                        this.state.newCase
                                                          ? false
                                                          : !this.state.editable
                                                      }
                                                      onChange={event =>
                                                        this.setState({
                                                          caseRecieveChanel: {
                                                            value: event.value,
                                                            label: event.label,
                                                          },
                                                          caseRecieveChanelInvalid: false,
                                                        })
                                                      }
                                                      value={this.state.caseRecieveChanel}
                                                      style={{ marginTop: '10px' }}
                                                      options={selectOptionCaseChanel}
                                                      inputLabel="ช่องทางรับปัญหา"
                                                      placeholder="- เลือก -"
                                                      required
                                                      messageError={
                                                        this.state.caseRecieveChanelInvalid &&
                                                        'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                  {
                                                    //เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)
                                                  }
                                                  <Col md={6}>
                                                    <Input
                                                      disabled={
                                                        this.state.newCase
                                                          ? false
                                                          : !this.state.editable
                                                      }
                                                      onChange={event =>
                                                        this.setState({
                                                          phoneInformer: event.target.value,
                                                          phoneInformerInvalid: false,
                                                        })
                                                      }
                                                      value={this.state.phoneInformer}
                                                      inputLabel="เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)"
                                                      style={{ marginTop: '10px' }}
                                                      placeholder="ระบุ"
                                                      messageError={
                                                        this.state.phoneInformerInvalid &&
                                                        'กรุณากรอกข้อมูลให้ถูกต้อง'
                                                      }
                                                    />
                                                  </Col>
                                                  {
                                                    //อีเมล (ผู้แจ้งปัญหา)
                                                  }
                                                  <Col md={6}>
                                                    <Input
                                                      disabled={
                                                        this.state.newCase
                                                          ? false
                                                          : !this.state.editable
                                                      }
                                                      onChange={event =>
                                                        this.setState({
                                                          mailInformer: event.target.value,
                                                          mailInformerInvalid: false,
                                                        })
                                                      }
                                                      value={this.state.mailInformer}
                                                      inputLabel="อีเมล (ผู้แจ้งปัญหา)"
                                                      style={{ marginTop: '10px' }}
                                                      placeholder="ระบุ"
                                                      messageError={
                                                        this.state.mailInformerInvalid &&
                                                        'กรุณากรอกข้อมูลให้ถูกต้อง'
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                                <hr style={{ margin: '10px' }} />
                                                {
                                                  //Footer
                                                }
                                                <Row>
                                                  <Col>
                                                    {this.state.editable || this.state.newCase ? (
                                                      <div
                                                        style={{
                                                          float: 'right',
                                                          marginRight: '15px',
                                                        }}
                                                      >
                                                        <Button
                                                          style={{
                                                            backgroundColor: '#94DA57',
                                                            color: 'white',
                                                            opacity: '1',
                                                          }}
                                                          onClick={() => {
                                                            this.state.newCase
                                                              ? this.handlerCreateShipmentSla(
                                                                  createShipment
                                                                )
                                                              : this.state.editable &&
                                                                this.handlerSaveFirstCase(
                                                                  updateShipment,
                                                                  this.state.caseId
                                                                );
                                                          }}
                                                        >
                                                          บันทึกปัญหา
                                                        </Button>
                                                        <Button
                                                          style={{
                                                            marginLeft: '10px',
                                                            display: this.state.newCase
                                                              ? 'none'
                                                              : 'inline',
                                                          }}
                                                          onClick={() =>
                                                            this.getShipmentCaseDetailCancelFirst(
                                                              this.state.caseId,
                                                              admin
                                                            )
                                                          }
                                                        >
                                                          ยกเลิก
                                                        </Button>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        style={{
                                                          float: 'right',
                                                          marginRight: '15px',
                                                        }}
                                                      >
                                                        <Button
                                                          onClick={() => {
                                                            this.refetchCaseLevelAndTitle();
                                                            this.setState({ editable: true });
                                                          }}
                                                          style={{ marginLeft: '10px' }}
                                                        >
                                                          แก้ไข
                                                        </Button>
                                                      </div>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </div>
                                              {
                                                /////////////////////////
                                                //       CARD 2        //
                                                /////////////////////////
                                              }
                                              <div
                                                style={{
                                                  backgroundColor: '#fff',
                                                  padding: '10px',
                                                  borderRadius: '5px',
                                                  marginTop: '10px',
                                                  display: this.state.newCase ? 'none' : 'block',
                                                }}
                                              >
                                                <Row>
                                                  {
                                                    //2. รายละเอียดการแก้ไขปัญหา
                                                  }
                                                  <Col md={8}>
                                                    <TextArea
                                                      disabled={
                                                        this.state.newCase
                                                          ? true
                                                          : this.state.caseAnswer
                                                          ? !this.state.secondEditable
                                                          : false
                                                      }
                                                      onChange={event =>
                                                        this.setState({
                                                          caseResolveDetail: event.target.value,
                                                          caseResolveDetailInvalid: '',
                                                        })
                                                      }
                                                      style={{ marginTop: '5px' }}
                                                      value={this.state.caseResolveDetail}
                                                      inputLabel="รายละเอียดการแก้ไขปัญหา"
                                                      placeholder="ระบุ"
                                                      required={this.state.newCase ? false : true}
                                                      messageError={
                                                        this.state.caseResolveDetailInvalid &&
                                                        'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                  {
                                                    //วัน-เวลาตอบกลับลูกค้า
                                                  }
                                                  <Col md={4}>
                                                    <div
                                                      style={{
                                                        marginTop: '5px',
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color:
                                                          this.state.customerReplyDateTimeInvalid &&
                                                          '#FF0000',
                                                      }}
                                                    >
                                                      วัน-เวลาตอบกลับลูกค้า
                                                      <span style={{ color: '#FF0000' }}>*</span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        border:
                                                          this.state.customerReplyDateTimeInvalid &&
                                                          '1px solid #FF0000',
                                                        borderRadius: '5px',
                                                      }}
                                                    >
                                                      <Datetime
                                                        value={this.state.customerReplyDateTimeShow}
                                                        dateFormat="DD/MM/YYYY"
                                                        timeFormat="HH:mm"
                                                        inputProps={{
                                                          disabled: this.state.newCase
                                                            ? false
                                                            : this.state.caseAnswer
                                                            ? !this.state.secondEditable
                                                            : false,
                                                          placeholder: '00/00/00 - 00:00น',
                                                        }}
                                                        className="caseModal"
                                                        onChange={event =>
                                                          this.handleDatePicker(event, 'answer')
                                                        }
                                                      />
                                                    </div>
                                                    {this.state.customerReplyDateTimeInvalid && (
                                                      <div
                                                        style={{
                                                          fontSize: '16px',
                                                          color: '#FF0000',
                                                          height: '22px',
                                                          textAlign: 'right',
                                                        }}
                                                      >
                                                        {this.state.customerReplyDateTimeShow
                                                          ? 'เวลาต้องไม่เกินกว่าเวลาปัจจุบัน'
                                                          : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                                      </div>
                                                    )}
                                                  </Col>
                                                  {
                                                    //แนบไฟล์
                                                  }
                                                  <Col md={12}>
                                                    <Mutation
                                                      mutation={singleUploadMutation}
                                                      update={(cache, { data }) => {
                                                        // console.log(data.singleUpload.path)
                                                        this.setState({
                                                          animationUploadSecond: false,
                                                        });
                                                        this.setState(prevState => ({
                                                          attachSecondFile: [
                                                            ...prevState.attachSecondFile,
                                                            data.singleUpload.path,
                                                          ],
                                                        }));
                                                      }}
                                                      onError={error => {
                                                        console.log(error);
                                                      }}
                                                    >
                                                      {uploadFile => {
                                                        return (
                                                          <input
                                                            accept="image/jpeg,image/png,application/pdf"
                                                            onChange={event => {
                                                              const files = event.target.files[0];

                                                              const checkFileTypeImage = this.checkFileTypeImage(
                                                                files
                                                              );
                                                              if (checkFileTypeImage === false) {
                                                                this.addNotification(
                                                                  'error',
                                                                  'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png, pdf เท่านั้น'
                                                                );
                                                                return;
                                                              }
                                                              files &&
                                                                this.setState({
                                                                  animationUploadSecond: true,
                                                                });
                                                              this._inputAttachFileSecondCase
                                                                .current.files[0] &&
                                                                this.handleSingleUploadDocumentCase(
                                                                  uploadFile,
                                                                  this._inputAttachFileSecondCase.current.files[0].name.split(
                                                                    '.'
                                                                  )[0],
                                                                  event
                                                                );
                                                            }}
                                                            type="file"
                                                            id="file"
                                                            style={{ display: 'none' }}
                                                            ref={this._inputAttachFileSecondCase}
                                                          />
                                                        );
                                                      }}
                                                    </Mutation>

                                                    <div style={{ marginTop: '10px' }}>
                                                      <Button
                                                        disabled={
                                                          this.state.newCase
                                                            ? false
                                                            : this.state.caseAnswer
                                                            ? !this.state.secondEditable
                                                            : false
                                                        }
                                                        onClick={() =>
                                                          this._inputAttachFileSecondCase.current.click()
                                                        }
                                                        style={{
                                                          border: '1px dashed #0B9CFF',
                                                          padding: '2.5px unset',
                                                          color: '#0B9CFF',
                                                        }}
                                                      >
                                                        + แนบไฟล์
                                                      </Button>
                                                      <span
                                                        style={{ color: 'grey', marginLeft: '5px' }}
                                                      >
                                                        รูปแบบไฟล์แนบรับ .jpg, .jpeg, .png , .pdf
                                                      </span>
                                                    </div>
                                                  </Col>
                                                  {// this.state.attachFile.length > 0 && console.log(this.state.attachFile)
                                                  this.state.animationUploadSecond ? (
                                                    <Col md={12}>
                                                      <div
                                                        style={{
                                                          backgroundColor:
                                                            'rgba(10, 156, 255, 0.2)',
                                                          color: 'black',
                                                          borderRadius: '5px',
                                                          border: '0.1px solid #E7E7E7',
                                                          marginTop: '5px',
                                                          padding: '5px 5px',
                                                          position: 'relative',
                                                        }}
                                                      >
                                                        <i class="fad fa-spinner-third fa-spin"></i>
                                                      </div>
                                                    </Col>
                                                  ) : null}
                                                  {this.state.attachSecondFile &&
                                                    this.state.attachSecondFile.length > 0 &&
                                                    this.state.attachSecondFile.map(
                                                      (item, index) =>
                                                        item && (
                                                          <Col md={12}>
                                                            <div
                                                              style={{
                                                                backgroundColor:
                                                                  'rgba(10, 156, 255, 0.2)',
                                                                borderRadius: '5px',
                                                                border: '0.1px solid #E7E7E7',
                                                                marginTop: '5px',
                                                                padding: '5px 5px',
                                                                position: 'relative',
                                                              }}
                                                            >
                                                              {
                                                                <a
                                                                  href={s3 + '/' + item}
                                                                  target="_blank"
                                                                  style={{ color: 'black' }}
                                                                >
                                                                  {item.split('/').length > 2
                                                                    ? item.split('/')[4]
                                                                    : item}
                                                                </a>
                                                              }
                                                              <Button
                                                                disabled={
                                                                  this.state.newCase
                                                                    ? false
                                                                    : this.state.caseAnswer
                                                                    ? !this.state.secondEditable
                                                                    : false
                                                                }
                                                                style={{
                                                                  position: 'absolute',
                                                                  right: '10px',
                                                                  padding: 'unset',
                                                                  border: 'unset',
                                                                }}
                                                                onClick={() =>
                                                                  this.setState(prevState => ({
                                                                    attachSecondFile: prevState.attachSecondFile.filter(
                                                                      (preItem, preIndex) =>
                                                                        preIndex !== index
                                                                    ),
                                                                  }))
                                                                }
                                                              >
                                                                <i
                                                                  style={{ color: 'red' }}
                                                                  class="fas fa-trash-alt"
                                                                ></i>
                                                              </Button>
                                                            </div>
                                                          </Col>
                                                        )
                                                    )}
                                                  {
                                                    //ผู้ตอบกลับปัญหา
                                                  }
                                                  <Col md={6}>
                                                    <Input
                                                      style={{ marginTop: '10px' }}
                                                      disabled
                                                      onChange={event =>
                                                        this.setState({
                                                          caseAnswer: event.target.value,
                                                          caseAnswerInvalid: false,
                                                        })
                                                      }
                                                      value={this.state.caseAnswer}
                                                      inputLabel="ผู้ตอบกลับปัญหา"
                                                      placeholder="ระบุ"
                                                      messageError={this.state.caseAnswerInvalid}
                                                    />
                                                  </Col>
                                                  {
                                                    //ช่องทางตอบกลับปัญหา
                                                  }
                                                  <Col md={6}>
                                                    <SelectBox
                                                      style={{ marginTop: '10px' }}
                                                      disabled={
                                                        this.state.newCase
                                                          ? false
                                                          : this.state.caseAnswer
                                                          ? !this.state.secondEditable
                                                          : false
                                                      }
                                                      onChange={event =>
                                                        this.setState({
                                                          caseAnswerChanel: {
                                                            value: event.value,
                                                            label: event.label,
                                                          },
                                                        })
                                                      }
                                                      value={this.state.caseAnswerChanel}
                                                      options={selectOptionCaseChanel}
                                                      inputLabel="ช่องทางตอบกลับปัญหา"
                                                      placeholder="- เลือก -"
                                                      messageError={
                                                        this.state.caseAnswerChanelInvalid &&
                                                        'กรุณากรอกข้อมูล'
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                                <hr style={{ margin: '10px' }} />
                                                {
                                                  //Footer
                                                }
                                                <Row>
                                                  <Col>
                                                    <div
                                                      style={{
                                                        float: 'right',
                                                        marginRight: '15px',
                                                      }}
                                                    >
                                                      {this.state.secondEditable ||
                                                      this.state.newCase ||
                                                      !this.state.caseAnswer ? (
                                                        <div>
                                                          <Button
                                                            disabled={this.state.newCase}
                                                            style={{
                                                              backgroundColor: '#94DA57',
                                                              color: 'white',
                                                              opacity: '1',
                                                            }}
                                                            onClick={() =>
                                                              this.handlerSaveSecondCase(
                                                                updateShipment,
                                                                this.state.caseId
                                                              )
                                                            }
                                                          >
                                                            บันทึก
                                                          </Button>
                                                          <Button
                                                            style={{
                                                              marginLeft: '10px',
                                                              display: this.state.newCase
                                                                ? 'none'
                                                                : 'inline',
                                                            }}
                                                            onClick={() =>
                                                              this.getShipmentCaseDetailCancelSecond(
                                                                this.state.caseId,
                                                                admin
                                                              )
                                                            }
                                                          >
                                                            ยกเลิก
                                                          </Button>
                                                        </div>
                                                      ) : (
                                                        <Button
                                                          onClick={() =>
                                                            !this.state.caseResolveDetail ||
                                                            this.state.newCase
                                                              ? this.setState({
                                                                  secondEditable: true,
                                                                  caseAnswer: admin,
                                                                  customerReplyDateTime: moment().format(
                                                                    'YYYY-MM-DD HH:mm'
                                                                  ),
                                                                  customerReplyDateTimeShow: moment().format(
                                                                    'DD/MM/YYYY HH:mm'
                                                                  ),
                                                                })
                                                              : this.setState({
                                                                  secondEditable: true,
                                                                  caseAnswer: admin,
                                                                })
                                                          }
                                                        >
                                                          แก้ไข
                                                        </Button>
                                                      )}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </ColRight>
                                          </div>
                                        </Modal.Body>
                                      </Modal>
                                      <Modal
                                        show={this.state.showConfirmModalCase}
                                        style={{ marginTop: '30vh' }}
                                        onHide={() => {
                                          this.setState({ showConfirmModalCase: false });
                                        }}
                                      >
                                        <Modal.Body
                                          style={{
                                            height: 'calc(20vh)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <span style={{ fontSize: '20px' }}>
                                            <b>แก้ไขรายงานปัญหา Case Id : {this.state.caseIndex}</b>
                                          </span>
                                          <br />
                                          <span style={{ fontSize: '20px' }}>
                                            บันทึกการแก้ไขเรียบร้อย
                                          </span>
                                          <Button
                                            style={{
                                              position: 'absolute',
                                              right: '20px',
                                              bottom: '20px',
                                            }}
                                            onClick={() =>
                                              this.setState({ showConfirmModalCase: false })
                                            }
                                          >
                                            ปิด
                                          </Button>
                                        </Modal.Body>
                                      </Modal>
                                    </div>
                                  )}
                                </Mutation>
                              )}
                            </Mutation>
                          </Col>
                        );
                      }}
                    </Query>
                  );
                }}
              </Query>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(dateNowQuery, { name: 'dateNow' }),
  graphql(adminName, { name: 'adminName' }),
  graphql(datamasterSlaTitlesList, {
    name: 'datamasterSlaTitlesList',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        params: {
          status_delete: false,
        },
      },
    }),
  }),
  graphql(datamasterSlaLevelsList, { name: 'datamasterSlaLevelsList' })
)(ShipmentDetail);
