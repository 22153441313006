import React from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import otpGeneratePhoneMutation from './graphql/mutation/otpGeneratePhone';
import getOTPMutation from './graphql/mutation/getOTP';
import { Mutation } from 'react-apollo';
import color from 'config/color';


const ModalContainer = styled(Modal)`
	.modal-dialog {
		margin-top: 120px;
		width: 460px;
  }
  
  .modal-body {
    padding: 40px;
  }
`;

const TextHeader = styled.div`
  font-size: 26px;
  font-weight: 700;
  color: #000000;
  text-align: center;
`;

const TextSubHeader = styled.div`
  padding-top: 8px;
  font-size: 20px;
  color: #000000;
  text-align: center;
  line-height: 1;
`;

const OTPRefContainer = styled.div`
	text-align: center;
  color: #808080;
`;

const InputOTPContainer = styled.div`
	margin-top: 20px;
	text-align: center;
	margin-bottom: 20px;
`;

const InputOTP = styled.input`
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	text-align: center;
	width: 48px;
	height: 56px;
	margin-right: 18px;
	font-size: 30px;
`;

const ButtonLink = styled.span`
  display: inline-block;
  padding: 8px 10px;
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  
  & i {
    font-size: 18px;
    padding-right: 8px;
  }
`;

const MessageContainer = styled.div`
  text-align: center;
  font-size: 18px;
  color: #000000;
  line-height: 1;
`;

const OTPMessageContainer = styled.div`
	text-align: center;
	color: ${props => props.color || '#b3b3b3'};
	margin-bottom: 5px;
	line-height: 1;

	& i {
		font-size: 18px;
	}
`;

class ModalPhoneOTP extends React.Component {
  constructor(props) {
		super(props);

		this._inputOTP1 = React.createRef();
		this._inputOTP2 = React.createRef();
		this._inputOTP3 = React.createRef();
    this._inputOTP4 = React.createRef();

		this.state = {
			ref: this.props.refOTP,
			otp1: '',
			otp2: '',
			otp3: '',
			otp4: '',
			otpValid: '',
			otpInvalid: '',
      time: '05:00',
      loading: false,
		}
	}

  componentDidMount() {
    this.durationTime();
  }

  //รีเซ็ตเวลา OTP
	durationTime = () => {
		clearTimeout(this.countDown);
		const eventTime = moment()
			.add(5, 'minutes')
			.unix();
		const currentTime = moment().unix();
		const diffTime = eventTime - currentTime;
		let duration = moment.duration(diffTime * 1000);
		const interval = 1000;

		this.countDown = setInterval(() => {
			duration = moment.duration(duration - interval);
			const time = `${('0' + duration.minutes()).slice(-2)}:${(
				'0' + duration.seconds()
			).slice(-2)}`;

			this.setState({
				time,
			});

			if (duration.as('milliseconds') === 0) {
				clearTimeout(this.countDown);
			}
		}, interval);
  };
  
  //ป้อนหมายเลข OTP
	handleChangeOTP = (index, event, getOTP) => {
		const value = event.target.value;
		this.setState(
			{
				[`otp${index}`]: value,
			},
			() => {
				if (
					this.state.otp1 &&
					this.state.otp2 &&
					this.state.otp3 &&
					this.state.otp4
				) {
					getOTP();
				} else {
					if (value) {
						if (index === 1) {
							this._inputOTP2.current.focus();
						} else if (index === 2) {
							this._inputOTP3.current.focus();
						} else if (index === 3) {
							this._inputOTP4.current.focus();
						}
					} else {
						if (index === 2) {
							this._inputOTP1.current.focus();
						} else if (index === 3) {
							this._inputOTP2.current.focus();
						} else if (index === 4) {
							this._inputOTP3.current.focus();
						}
					}
				}
			},
		);
  };
  
  handleClose() {
    return this.props.onHide;
  }

  render() {
    return (
      <ModalContainer
        show={this.props.show}
        onHide={this.props.onHide}
      >
        <Modal.Body>
          <TextHeader>ยืนยันเบอร์โทรศัพท์มือถือของคุณ</TextHeader>
          <TextSubHeader>เราส่งรหัส (OTP) ไปที่หมายเลขโทรศัพท์ของคุณแล้ว</TextSubHeader>
          <OTPRefContainer>
            OTP Ref. {this.state.ref} (Time {this.state.time})
          </OTPRefContainer>

          <Mutation
            mutation={getOTPMutation}
            variables={{
              ref: this.state.ref,
              otp: `${this.state.otp1}${this.state.otp2}${
                this.state.otp3
                }${this.state.otp4}`,
            }}
            update={(
              cache,
              {
                data: {
                  getOtp: { available },
                },
              },
            ) => {
              if (available) {
                this.setState(
                  {
                    otpValid: true,
                    otpInvalid: false,
                    error_message: '',
                  },
                  () => {
                    this.props.callback(true);
                  },
                );
              } else {
                this.setState(
                  {
                    otpValid: false,
                    otpInvalid: true,
                    otp1: '',
                    otp2: '',
                    otp3: '',
                    otp4: '',
                  },
                  () => {
                    this._inputOTP1.current.focus();
                  },
                );
              }
            }}
          >
            { getOTP => {
              return (
                <InputOTPContainer>
                  <InputOTP
                    ref={this._inputOTP1}
                    maxLength="1"
                    autocorrect="off"
                    value={this.state.otp1}
                    onChange={event => {
                      this.handleChangeOTP(1, event, getOTP);
                    }}
                    onFocus={() => {
                      this._inputOTP1.current.select();
                    }}
                  />
                  <InputOTP
                    ref={this._inputOTP2}
                    maxLength="1"
                    autocorrect="off"
                    value={this.state.otp2}
                    onChange={event => {
                      this.handleChangeOTP(2, event, getOTP);
                    }}
                    onFocus={() => {
                      this._inputOTP2.current.select();
                    }}
                  />
                  <InputOTP
                    ref={this._inputOTP3}
                    maxLength="1"
                    autocorrect="off"
                    value={this.state.otp3}
                    onChange={event => {
                      this.handleChangeOTP(3, event, getOTP);
                    }}
                    onFocus={() => {
                      this._inputOTP3.current.select();
                    }}
                  />
                  <InputOTP
                    ref={this._inputOTP4}
                    maxLength="1"
                    autocorrect="off"
                    value={this.state.otp4}
                    onChange={event => {
                      this.handleChangeOTP(4, event, getOTP);
                    }}
                    onFocus={() => {
                      this._inputOTP4.current.select();
                    }}
                    style={{ marginRight: 0 }}
                  />
                </InputOTPContainer>
              );
            }}
          </Mutation>

          <div style={{minHeight: 70}}>
            {this.state.otpValid ? (
              <OTPMessageContainer color={color.Primary}>
                <i className="fas fa-check-circle" /> รหัส OTP ถูกต้อง
              </OTPMessageContainer>
            ) : null}

            {this.state.otpInvalid ? (
              <div>
                <OTPMessageContainer color={color.SoftGray}>
                  <i className="fas fa-times-circle" /> รหัส OTP
                  ไม่ถูกต้อง
                </OTPMessageContainer>
                <OTPMessageContainer color={color.SoftGray}>
                  กรอกรหัสใหม่ หรือ ส่งรหัส OTP ทาง SMS อีกครั้ง
                </OTPMessageContainer>
              </div>
            ) : null}
          </div>

          <MessageContainer style={{marginTop: 40}}>คำเตือน: กรุณาอย่าออกจากหน้านี้</MessageContainer>
          <div style={{ textAlign: 'center', marginTop: 70 }}>
            <Mutation
              mutation={otpGeneratePhoneMutation}
              variables={{
                phone:
                  this.props.phoneCode +
                  this.props.phoneNumber.replace(
                    /\b0+/g,
                    '',)
              }}
              update={(cache, { data }) => {
                const ref = data.otpGeneratePhone.ref;
                this.setState(
                  {
                    ref: ref,
                    otp1: '',
                    otp2: '',
                    otp3: '',
                    otp4: '',
                    otpValid: '',
                    otpInvalid: '',
                  },
                  () => {
                    this._inputOTP1.current.focus();
                    this.durationTime();
                  },
                );
              }}
            >
              {otpGenerate => {
                return (
                  <ButtonLink onClick={() => otpGenerate()}>
                    <i className="fas fa-envelope"></i> ส่ง SMS อีกครั้ง
                  </ButtonLink>
                );
              }}
            </Mutation>
          </div>
        </Modal.Body>
      </ModalContainer>
    )
  }
}

export default ModalPhoneOTP;