import gql from 'graphql-tag';

export default gql`
	query searchDriverMonthlyCalendars(
        $month: String
        $account_info: String
    ){
        driverMonthlyCalendars(
            month:$month 
            account_info: $account_info
        ) {
            calendars{
                day
                month
                date
                value
            }
        }
    }
`;
