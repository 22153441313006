import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../graphql/query/shipmentList';

export default gql`
  query ShopeeShipmentDetailMain($_id: ID) {
    shipmentList(_id: $_id, shipment_prefix: "GSP", see_detail: true) {
      shipment {
        ...shipmentFields
      }

      total_page
    }
  }

  ${shipmentListFragment}
`;
