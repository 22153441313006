import React from 'react';

export const CostPerParcelEachBox = ({
  title = 'title',
  icon = 'icon',
  value = 'value',
  colorValue = false,
  status = false,
  valueStatus,
  iconColor = '',
  colorBgStatus = '#80808020',
  colorTextStatus,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        borderRadius: '5px',
        border: '1px solid #e9ecef',
        padding: '5px',
        width: '100%',
        marginInline: '10px',
        backgroundColor: '#fff',
      }}
    >
      <div
        style={{
          width: '20%',
          margin: 'auto',
          marginRight: 'auto',
          marginLeft: '10px',
          color: iconColor,
        }}
      >
        <i class={icon}></i>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
        }}
      >
        <div
          style={{
            fontSize: '18px',
          }}
        >
          {title}
        </div>
        {colorValue ? (
          <div
            style={{
              fontSize: '26px',
              fontWeight: 'bold',
              color: '#00b24e',
            }}
          >
            {value}
          </div>
        ) : (
          <div
            style={{
              fontSize: '26px',
              fontWeight: 'bold',
            }}
          >
            {value}
          </div>
        )}
      </div>
      {status && (
        <div
          style={{
            fontSize: '14px',
            width: '100px',
            left: '10px',
            fontWeight: '3px',
          }}
        >
          {valueStatus && (
            <React.Fragment>
              <div
                style={{
                  // padding: '0.3rem',
                  marginBottom: '3px',
                }}
              >
                สถานะ :
              </div>

              <div
                style={{
                  borderRadius: '0.5rem',
                  padding: '5px 5px 5px 5px',
                  backgroundColor: colorBgStatus,
                  color: colorTextStatus,
                }}
              >
                {valueStatus}
              </div>
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export const CostPerParcelBoxHeader = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
      }}
    >
      <CostPerParcelEachBox />
      <CostPerParcelEachBox />
      <CostPerParcelEachBox />
      <CostPerParcelEachBox />
    </div>
  );
};
