import React from 'react';
import { Grid, Row, Col, Button, FormControl, Modal } from 'react-bootstrap';
import { Query } from 'react-apollo';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import {
  TEXT_CLOSE,
  TEXT_MODAL_SUBTITLE,
  TEXT_MODAL_TITLE,
  TEXT_TITLE,
  MONTH_TH,
} from './utils/Special.Constant';
import CardTable from './components/dashboard/COD.RenderCardTable';
import { Container } from './utils/Special.Style';
import {
  FunctionGetYearList,
  FunctionGoToSetDate,
  FunctionOnClickEvnet,
} from './utils/Special.Function';
import CODQueryTrackingCodByCashCalendar from './graphql/CODQueryTrackingCodByCashCalendar';
import CODQueryDataPopUp from './graphql/CODQueryDataPopUp';
import RenderEventContent from './components/dashboard/COD.RenderEventContent';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import dayjs from 'dayjs';
import { Layout } from 'views/KaCustomer/components';
import RenderModalBody from './components/dashboard/COD.RenderModalBody';
import './CodDashboard.css';

const KaCodDashnoard = () => {
  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [open, setOpen] = React.useState(false);
  const [calendarDetail, setCalendarDetail] = React.useState(null);

  const CDOReferenceCalenda = React.createRef();

  return (
    <Layout>
      <Grid fluid>
        <Container>
          <Col md={12}>
            <CardTable
              ctTableFullWidth
              ctTableResponsive
              leftButton={
                <div
                  style={{
                    float: 'left',
                    marginRight: '15px',
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: '15px',
                  }}
                >
                  <span>{TEXT_TITLE}</span>
                </div>
              }
              rightButton={
                <div
                  style={{
                    float: 'right',
                    marginLeft: '15px',
                    flexDirection: 'row',
                    display: 'flex',
                    gap: '12px',
                  }}
                >
                  <FormControl
                    style={{ width: '120px' }}
                    componentClass="select"
                    placeholder="select"
                    defaultValue={month}
                    onChange={event => {
                      const value = event.target.value;
                      setMonth(value);
                      FunctionGoToSetDate(value, year, CDOReferenceCalenda);
                    }}
                    name="month"
                  >
                    {MONTH_TH?.filter((_, i) => {
                      const thisYear = new Date().getFullYear();
                      const thisMonth = new Date().getMonth();
                      if (year < thisYear) {
                        return _;
                      } else {
                        return i <= thisMonth;
                      }
                    })?.map((item, key) => (
                      <option value={key + 1} key={item}>
                        {item}
                      </option>
                    ))}
                  </FormControl>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    defaultValue={year}
                    onChange={event => {
                      const value = event.target.value;
                      setYear(value);
                      FunctionGoToSetDate(month, value, CDOReferenceCalenda);
                    }}
                    name="search_year"
                  >
                    {FunctionGetYearList()?.map(item => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </FormControl>
                </div>
              }
              content={
                <div>
                  <Query
                    query={CODQueryTrackingCodByCashCalendar}
                    variables={{
                      month: `${year}-${month}`,
                    }}
                  >
                    {({ loading, error, data, refetch }) => {
                      // {
                      //   if (!loading && !error) {
                      //     console.log(
                      //       'CODQueryTrackingCodByCashCalendar',
                      //       month,
                      //       year,
                      //       data.trackingCodByCashCalendar
                      //     );
                      //   }
                      // }

                      if (loading || error) {
                        return (
                          <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" />
                        );
                      }

                      return (
                        <FullCalendar
                          plugins={[dayGridPlugin, interactionPlugin]}
                          headerToolbar={{
                            left: '',
                            center: '',
                            right: '',
                          }}
                          initialView="dayGridMonth"
                          events={data.trackingCodByCashCalendar.map((calendar, key) => {
                            return {
                              id: key,
                              date: calendar.date,
                              total_price: calendar.total_price,
                              total_tracking: calendar.total_tracking,
                              total_tracking_wait_tranfer: calendar.total_tracking_wait_tranfer,
                              wait_tranfer_price: calendar.wait_tranfer_price,
                            };
                          })}
                          eventContent={RenderEventContent}
                          eventClick={event =>
                            FunctionOnClickEvnet(event, setCalendarDetail, setOpen)
                          }
                          ref={CDOReferenceCalenda}
                        />
                      );
                    }}
                  </Query>
                </div>
              }
            />
          </Col>
          {open && (
            <Modal
              dialogClassName="modal-dialog-size"
              show={open}
              backdrop="static"
              onHide={() => setOpen(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {TEXT_MODAL_TITLE} {dayjs(calendarDetail).format('DD/MM/YYYY')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={12}>{/* <b>{TEXT_MODAL_SUBTITLE}</b> */}</Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={12}>
                    <Query
                      query={CODQueryDataPopUp}
                      variables={{
                        date: dayjs(calendarDetail).format('YYYY-MM-DD'),
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        return (
                          <RenderModalBody
                            total={data?.trackingCodByCashPopup?.total_tracking_wait_tranfer}
                            date={data?.trackingCodByCashPopup?.date}
                            count={data?.trackingCodByCashPopup?.wait_tranfer_price}
                            tableData={data?.trackingCodByCashPopup?.detail_by_agent}
                          />
                        );
                      }}
                    </Query>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setOpen(false)}>{TEXT_CLOSE}</Button>
              </Modal.Footer>
            </Modal>
          )}
        </Container>
      </Grid>
    </Layout>
  );
};

export default KaCodDashnoard;
