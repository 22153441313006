import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  Image,
  Grid,
  Label,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import s3 from 'config/s3';

import Moment from 'react-moment';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './../../style.css';

const TrackingItem = props =>
  props.step.map((value, index) =>
    value == 1 ? (
      props.index < props.detail.length - 1 ? (
        props.tracking.driver_accept == 1 &&
        props.detail[props.index + 1].signature_date &&
        props.detail[props.index].contact_customer_date &&
        props.detail[props.index].take_picture_date ? (
          props.detail[props.index].signature_date == null ? (
            <div className="boxItem inProgress">
              <i className="fas fa-circle"></i>
              <div className="content">
                <div className="title">
                  <b>
                    จุดที่ {props.detail[props.index].droppoint} - ยืนยันการ
                    {props.index > 0 ? 'รับ' : 'ส่ง'}สินค้า
                  </b>{' '}
                </div>
                <div className="status">กำลังดำเนินการ</div>
                <div className="detail"></div>
              </div>
            </div>
          ) : (
            <div className="boxItem">
              <i className="fas fa-check-circle"></i>
              <div className="content">
                <div className="title">
                  <b>จุดที่ {props.detail[props.index].droppoint}</b> - ยืนยันการส่งสินค้า
                  เรียบร้อยแล้ว
                </div>
                <div className="status">
                  <Moment format="DD/MM/YYYY HH:mm">
                    {props.detail[props.index].signature_date}
                  </Moment>
                </div>
                <div className="detail">
                  <Row>
                    {props.detail[props.index].signature_picture ? (
                      <Col sm={3}>
                        <div
                          className="img"
                          onClick={() =>
                            props.renderLightbox('signature', props.detail[props.index])
                          }
                        >
                          <Image
                            src={`${s3}/${props.detail[props.index].signature_picture}`}
                            rounded
                          />
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </div>
            </div>
          )
        ) : null
      ) : props.tracking.driver_accept == 1 &&
        props.detail[props.index].contact_customer_date &&
        props.detail[props.index].take_picture_date ? (
        props.detail[props.index].signature_date == null ? (
          <div className="boxItem inProgress">
            <i className="fas fa-circle"></i>
            <div className="content">
              <div className="title">
                <b>
                  จุดที่ {props.detail[props.index].droppoint} - ยืนยันการ
                  {props.index > 0 ? 'รับ' : 'ส่ง'}สินค้า
                </b>{' '}
              </div>
              <div className="status">กำลังดำเนินการ</div>
              <div className="detail"></div>
            </div>
          </div>
        ) : (
          <div className="boxItem">
            <i className="fas fa-check-circle"></i>
            <div className="content">
              <div className="title">
                <b>จุดที่ {props.detail[props.index].droppoint}</b> - ยืนยันการ
                {props.index > 0 ? 'รับ' : 'ส่ง'}สินค้า เรียบร้อยแล้ว
              </div>
              <div className="status">
                <Moment format="DD/MM/YYYY HH:mm">
                  {props.detail[props.index].signature_date}
                </Moment>
              </div>
              <div className="detail">
                <Row>
                  {props.detail[props.index].signature_picture ? (
                    <Col sm={3}>
                      <div
                        className="img"
                        onClick={() => props.renderLightbox('signature', props.detail[props.index])}
                      >
                        <Image
                          src={`${s3}/${props.detail[props.index].signature_picture}`}
                          rounded
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>
          </div>
        )
      ) : null
    ) : value == 2 ? (
      props.index < props.detail.length - 1 ? (
        props.tracking.driver_accept == 1 &&
        props.detail[props.index + 1].signature_date &&
        props.detail[props.index].contact_customer_date ? (
          props.detail[props.index].take_picture_date == null ? (
            <div className="boxItem inProgress">
              <i className="fas fa-circle"></i>
              <div className="content">
                <div className="title">
                  <b>จุดที่ {props.detail[props.index].droppoint} - ถ่ายรูปสินค้า</b>{' '}
                </div>
                <div className="status">กำลังดำเนินการ</div>
                <div className="detail"></div>
              </div>
            </div>
          ) : (
            <div className="boxItem">
              <i className="fas fa-check-circle"></i>
              <div className="content">
                <div className="title">
                  <b>จุดที่ {props.detail[props.index].droppoint}</b> - ถ่ายรูปสินค้า เรียบร้อยแล้ว
                </div>
                <div className="status">
                  <Moment format="DD/MM/YYYY HH:mm">
                    {props.detail[props.index].take_picture_date}
                  </Moment>
                </div>
                <div className="detail">
                  <Row>
                    <Col md={12}>
                      {props.detail[props.index].front_picture ? (
                        <div className="img_wrapper">
                          <div
                            className="img"
                            onClick={() =>
                              props.renderLightbox('image', props.detail[props.index], 0)
                            }
                          >
                            <Image
                              src={`${s3}/${props.detail[props.index].front_picture}`}
                              rounded
                            />
                          </div>
                          <p>รูปด้านหน้า</p>
                        </div>
                      ) : null}
                      {props.detail[props.index].back_picture ? (
                        <div className="img_wrapper">
                          <div
                            className="img"
                            onClick={() =>
                              props.renderLightbox('image', props.detail[props.index], 1)
                            }
                          >
                            <Image
                              src={`${s3}/${props.detail[props.index].back_picture}`}
                              rounded
                            />
                          </div>
                          <p>รูปด้านหลัง</p>
                        </div>
                      ) : null}
                      {props.detail[props.index].side_picture ? (
                        <div className="img_wrapper">
                          <div
                            className="img"
                            onClick={() =>
                              props.renderLightbox('image', props.detail[props.index], 2)
                            }
                          >
                            <Image
                              src={`${s3}/${props.detail[props.index].side_picture}`}
                              rounded
                            />
                          </div>
                          <p>รูปด้านข้าง</p>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )
        ) : null
      ) : props.tracking.driver_accept == 1 && props.detail[props.index].contact_customer_date ? (
        props.detail[props.index].take_picture_date == null ? (
          <div className="boxItem inProgress">
            <i className="fas fa-circle"></i>
            <div className="content">
              <div className="title">
                <b>จุดที่ {props.detail[props.index].droppoint} - ถ่ายรูปสินค้า</b>{' '}
              </div>
              <div className="status">กำลังดำเนินการ</div>
              <div className="detail"></div>
            </div>
          </div>
        ) : (
          <div className="boxItem">
            <i className="fas fa-check-circle"></i>
            <div className="content">
              <div className="title">
                <b>จุดที่ {props.detail[props.index].droppoint}</b> - ถ่ายรูปสินค้า เรียบร้อยแล้ว
              </div>
              <div className="status">
                <Moment format="DD/MM/YYYY HH:mm">
                  {props.detail[props.index].take_picture_date}
                </Moment>
              </div>
              <div className="detail">
                <Row>
                  <Col md={12}>
                    {props.detail[props.index].front_picture ? (
                      <div className="img_wrapper">
                        <div
                          className="img"
                          onClick={() =>
                            props.renderLightbox('image', props.detail[props.index], 0)
                          }
                        >
                          <Image src={`${s3}/${props.detail[props.index].front_picture}`} rounded />
                        </div>
                        <p>รูปด้านหน้า</p>
                      </div>
                    ) : null}
                    {props.detail[props.index].back_picture ? (
                      <div className="img_wrapper">
                        <div
                          className="img"
                          onClick={() =>
                            props.renderLightbox('image', props.detail[props.index], 1)
                          }
                        >
                          <Image src={`${s3}/${props.detail[props.index].back_picture}`} rounded />
                        </div>
                        <p>รูปด้านหลัง</p>
                      </div>
                    ) : null}
                    {props.detail[props.index].side_picture ? (
                      <div className="img_wrapper">
                        <div
                          className="img"
                          onClick={() =>
                            props.renderLightbox('image', props.detail[props.index], 2)
                          }
                        >
                          <Image src={`${s3}/${props.detail[props.index].side_picture}`} rounded />
                        </div>
                        <p>รูปด้านข้าง</p>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )
      ) : null
    ) : props.index < props.detail.length - 1 ? (
      props.tracking.driver_accept == 1 && props.detail[props.index + 1].signature_date ? (
        props.detail[props.index].contact_customer_date == null ? (
          <div className="boxItem inProgress">
            <i className="fas fa-circle"></i>
            <div className="content">
              <div className="title">
                <b>
                  จุดที่ {props.detail[props.index].droppoint} - ติดต่อจุด
                  {props.index > 0 ? 'รับ' : 'ส่ง'}สินค้า
                </b>{' '}
              </div>
              <div className="status">กำลังดำเนินการ</div>
              <div className="detail"></div>
            </div>
          </div>
        ) : (
          <div className="boxItem">
            <i className="fas fa-check-circle"></i>
            <div className="content">
              <div className="title">
                <b>จุดที่ {props.detail[props.index].droppoint}</b> - ติดต่อจุดส่งสินค้า
                เรียบร้อยแล้ว
              </div>
              <div className="status">
                <Moment format="DD/MM/YYYY HH:mm">
                  {props.detail[props.index].contact_customer_date}
                </Moment>
              </div>
              <div className="detail"></div>
            </div>
          </div>
        )
      ) : null
    ) : props.tracking.driver_accept == 1 ? (
      props.detail[props.index].contact_customer_date == null ? (
        <div className="boxItem inProgress">
          <i className="fas fa-circle"></i>
          <div className="content">
            <div className="title">
              <b>
                จุดที่ {props.detail[props.index].droppoint} - ติดต่อจุด
                {props.index > 0 ? 'รับ' : 'ส่ง'}สินค้า{' '}
              </b>{' '}
            </div>
            <div className="status">กำลังดำเนินการ</div>
            <div className="detail"></div>
          </div>
        </div>
      ) : (
        <div className="boxItem">
          <i className="fas fa-check-circle"></i>
          <div className="content">
            <div className="title">
              <b>จุดที่ {props.detail[props.index].droppoint}</b> - ติดต่อจุด
              {props.index > 0 ? 'รับ' : 'ส่ง'}สินค้า เรียบร้อยแล้ว
            </div>
            <div className="status">
              <Moment format="DD/MM/YYYY HH:mm">
                {props.detail[props.index].contact_customer_date}
              </Moment>
            </div>
            <div className="detail"></div>
          </div>
        </div>
      )
    ) : null
  );

TrackingItem.propTypes = {
  detail: PropTypes.node,
  index: PropTypes.string,
};

TrackingItem.defaultProps = {};

export default TrackingItem;
