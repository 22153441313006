import gql from 'graphql-tag';

export default gql`
    mutation sendDeliveredToCoustomer(
        $tracking_id: ID!
        $delivery_id: ID!
    ){
        sendDeliveredToCoustomer(
        tracking_id: $tracking_id
        delivery_id: $delivery_id
    ){
        succeed
    }
    }
`;
