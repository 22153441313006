import gql from 'graphql-tag';

export default gql`
	mutation AccountInfoUpdateTrackingUrl(
		$_id: ID
		$account_info_tracking_url: String
		$account_info_logo: String
	) {
		accountInfoUpdate(
			_id : $_id
			account_info_tracking_url: $account_info_tracking_url
			account_info_logo: $account_info_logo
		) {
			_id
		}
	}
`;
