import React from 'react';
import styled from 'styled-components';
import { Card } from '../components';
import QRCode from 'qrcode.react';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 25%;
`;

const BottomTitle = styled.span`
  font-weight: bold;
  font-size: 1.5vw;
  margin-top: 5px;
`

const WrapperCanvas = styled.div`
  width: 100%
  height: 100%;
  text-align: center;

  .canvasId {
    height: 100% !important;
    width: auto !important;
  }
`

const defaultProps = {
  title: 'Title',
  bgColor: '#ffffff',
  textColor: '#000000',
  borderColor: '#000000',
  number: 0,
};

const CardLargeNumber = props => {
  const { round ,bgColor, textColor, number, title, borderColor, ...otherProps } = props;

  return (
    <StyledCard bgColor={bgColor} textColor={textColor} borderColor={borderColor} {...otherProps}>
      <WrapperCanvas>
        <QRCode
            id={round}
            className="canvasId"
            value={round}
            level={"H"}
            size={500}
            includeMargin={false}
        />
      </WrapperCanvas>
      <BottomTitle>DRIVER CHECK IN </BottomTitle>
    </StyledCard>
  );
};

CardLargeNumber.defaultProps = defaultProps;

export default CardLargeNumber;
