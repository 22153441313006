import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import MediaQuery from 'react-responsive';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  width: 100%;
  min-height: 100px;
  margin: auto;
  background-color: #FFFFFF;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Paginate = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  line-height: 1;

  & i {
    padding-top: 4px;
  }

  & div:not(:first-child), & div:not(:last-child) {
    margin: 0px 10px;
  }
`;

class PDFPreview extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  onNextPage() {
    this.setState({ pageNumber: this.state.pageNumber < this.state.numPages ? (this.state.pageNumber + 1) : this.state.pageNumber });
  }

  onPreviousPage() {
    this.setState({ pageNumber: this.state.pageNumber > 1 ? (this.state.pageNumber - 1) : this.state.pageNumber })
  }

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <Container>
        <object data={this.props.file} type="application/pdf" style={{width:"100%",height:"calc(100vh - 180px)"}}>
            <iframe src={this.props.file} type="application/pdf">
                <p>This browser does not support PDFs. Please download the PDF to view it: <a href={this.props.file} download >Download PDF</a>.</p>
            </iframe>
        </object>
      </Container>
    )
  }
}

PDFPreview.propTypes = {
  file: PropTypes.string,
};

PDFPreview.default = {
  file: '',
};

export default PDFPreview;