import gql from 'graphql-tag';

export default gql`
mutation settingHolidayDelete($_id: ID! )
{
  settingHolidayDelete(_id: $_id) {
    succeed
  }
}
`;
