import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Modal,
  Table,
  FormControl,
} from 'react-bootstrap';
import { Mutation, graphql, Query, compose } from 'react-apollo';
import Moment from 'react-moment';
import NotificationSystem from 'react-notification-system';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { client } from '../../../../../../../';

import datamasterJobTypeListQuery from 'views/Setting/graphql/query/datamasterJobTypeList';
import datamasterServiceCategoryListQuery from 'views/Setting/graphql/query/datamasterServiceCategoryType';
import datamasterServiceItemAddMutation from 'views/Setting/graphql/mutation/datamasterServiceItemAdd';

//in Module
import { Card, Input, SelectBox, Select, Label } from 'views/Setting/components';

const selectOptionCategory = [
  { value: '', label: 'กรุณาเลือกหมวดหมู่', prefix: '' },
  { value: '5e54cef8e99db3b888a2b73d', label: 'ค่าขนส่ง', prefix: 'T1' },
  { value: '5e54cef8e99db3b888a2b749', label: 'ค่าบริการ', prefix: 'S1' },
  { value: '5e54cef8e99db3b888a2b755', label: 'ค่าสำรองจ่าย', prefix: 'R1' },
];

const selectOptionVAT = [
  { value: '0', label: '0' },
  { value: '7', label: '7' },
];

const selectOptionWHT = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '1.5', label: '1.5' },
  { value: '3', label: '3' },
  { value: '5', label: '5' },
];

const ModalContainer = styled(Modal)`
  & .modal-dialog {
    width: 100%;
    max-width: 1024px;
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const IconStyled = styled.i`
  color: #0b8d10;
  font-size: 24px;
  cursor: pointer;

  &.off {
    transform: rotate(180deg);
    color: #b3b3b3;
  }
`;

const FormDataMaster = props => {
  const { show_modal, mode: MasterMode, dataModalService } = props;

  const {
    control,
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    clearErrors,
    getValues,
  } = useForm();
  const [values, setReactSelect] = useState({
    selectedOption: null,
  });

  const [prefixId, setPrefixId] = useState(
    dataModalService && dataModalService.categoryList
      ? dataModalService.categoryList.setting_prefix_id
      : null
  );
  const [prefix, setPrefix] = useState(
    dataModalService && dataModalService.categoryList ? dataModalService.categoryList.prefix : null
  );
  const [prefixNumber, setPrefixNumber] = useState('');
  const [dataTruckTypeList, setTruckTypeList] = useState(
    dataModalService && dataModalService.categoryList
      ? dataModalService.categoryList.datamaster_service_item
      : []
  );

  const onSubmit = (dataForm, datamasterServiceItemAdd) => {
    let serviceItem = [];
    dataForm.serviceItem.forEach((ele, key) => {
      if (MasterMode == 'new') {
        const data = {
          service_category_id: ele.service_category_id,
          serviceItem: [
            {
              datamaster_service_item_number: dataForm.datamaster_service_item_number,
              truck_type_id: ele.truck_type_id,
              service_item_name: {
                th: dataForm.service_item_name_th,
                en: dataForm.service_item_name_en,
              },
              cost: ele.cost,
              vat_cost: ele.vat_cost,
              wht_cost: ele.wht_cost,
              sale: ele.sale,
              vat: ele.vat,
              wht: ele.wht,
              unit: ele.unit,
              min_price: ele.min_price,
              enable: ele.enable,
            },
          ],
        };
        serviceItem.push(data);
      } else {
        const data = {
          service_category_id: ele.service_category_id,
          serviceItem: [
            {
              _id: ele._id,
              datamaster_service_item_number: dataForm.datamaster_service_item_number,
              truck_type_id: ele.truck_type_id,
              service_item_name: {
                th: dataForm.service_item_name_th,
                en: dataForm.service_item_name_en,
              },
              cost: ele.cost,
              vat_cost: ele.vat_cost,
              wht_cost: ele.wht_cost,
              sale: ele.sale,
              vat: ele.vat,
              wht: ele.wht,
              unit: ele.unit,
              min_price: ele.min_price,
              enable: ele.enable,
            },
          ],
        };
        serviceItem.push(data);
      }
    });

    datamasterServiceItemAdd({
      variables: {
        datamaster_service_detail: serviceItem,
      },
      refetchQueries: ['searchdatamasterJobTypeList'],
    });
  };

  const handleCategoryChange = selected => {
    console.log('handleCategoryChange', selected);
    getDataServiceCategory(selected.value);
    setReactSelect({ selected });
    setValue('reactSelect', selected);
    setPrefixId(selected.value);
    setPrefix(selected.prefix);
  };

  const getDataServiceCategory = async prefix => {
    try {
      const { loading, error, data } = await client.query({
        query: datamasterServiceCategoryListQuery,
        variables: {
          setting_prefix_id: prefix,
        },
      });
      console.log('getDataServiceCategory', data);
      if (data) {
        setTruckTypeList(data.datamasterServiceCategoryList);
        renderprefixNumber(data.datamasterServiceCategoryList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderprefixNumber = data => {
    const Category = data[0].datamaster_service_item;
    let number = Category.length + 1;
    if (Category.length.toString().length == 1) {
      number = `00${Category.length + 1}`;
    } else if (Category.length.toString().length == 2) {
      number = `0${Category.length + 1}`;
    }
    setPrefixNumber(number);
    setValue('datamaster_service_item_number', number);
  };

  const handleMultiChange = (selectedOption, CategoryList) => {
    const Category = CategoryList.datamasterServiceCategoryList
      ? CategoryList.datamasterServiceCategoryList[0].datamaster_service_item
      : [];
    console.log(Category, Category.length);
    let number = Category.length + 1;
    if (Category.length.toString().length == 1) {
      number = `00${Category.length + 1}`;
    } else if (Category.length.toString().length == 2) {
      number = `0${Category.length + 1}`;
    }
    console.log(number);
    setValue('datamaster_service_item_number', number);
  };

  const renderPrefixCategory = (mode, dataProps) => {
    if (values.selectedOption == null) {
      if (mode == 'new') {
        return 'T1';
      } else {
        return dataProps.categoryList.prefix;
      }
    }
    return values.selectedOption.prefix;
  };

  const renderValueMain = (name, mode, dataProps) => {
    if (mode == 'new') {
      return '';
    } else {
      if (name == 'reactSelect') {
        return {
          value: dataProps.categoryList._id,
          label: dataProps.categoryList.service_category_name.th,
          prefix: dataProps.categoryList.prefix,
        };
      } else if (name == 'datamaster_service_item_number') {
        return dataProps.itemList.datamaster_service_item_number;
      } else if (name == 'service_item_name_th') {
        return dataProps.itemList.service_item_name.th;
      } else if (name == 'service_item_name_en') {
        return dataProps.itemList.service_item_name.en;
      }
      return '';
    }
  };

  const checkEnable = (key, mode, truckItem, dataModalService) => {
    if (watch(`serviceItem[${key}]["enable"]`) == undefined) {
      if (mode == 'new') {
        return true;
      } else {
        return truckItem.datamaster_service_item.filter(
          item =>
            item.datamaster_service_item_number ==
            dataModalService.itemList.datamaster_service_item_number
        )[0].enable;
      }
    }
    return watch(`serviceItem[${key}]["enable"]`);
  };

  return (
    <ModalContainer
      show={show_modal}
      onHide={() => {
        clearErrors();
        props.onHideModal(false);
      }}
    >
      <Mutation
        mutation={datamasterServiceItemAddMutation}
        update={() => {
          clearErrors();
          props.onHideModal(false);
          window.reload();
        }}
      >
        {datamasterServiceItemAdd => (
          <form onSubmit={handleSubmit(data => onSubmit(data, datamasterServiceItemAdd))}>
            <Modal.Header closeButton>
              <Modal.Title>{MasterMode == 'new' ? 'เพิ่มรายการ' : 'แก้ไขรายการ'} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalContent>
                <FormDataMaster />
                <Row>
                  {console.log('selectOptionCategory', selectOptionCategory)}
                  <Col md={6} sm={12}>
                    <SelectBox
                      style={{ marginTop: 10 }}
                      inputLabel="หมวดหมู่:"
                      options={selectOptionCategory}
                      placeholder="หมวดหมู่"
                      disabled={MasterMode != 'new'}
                      messageError={errors['reactSelect'] && errors['reactSelect'].message}
                      name={`reactSelect`}
                      onChange={e => handleCategoryChange(e)}
                      required
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Controller
                      as={
                        <Input
                          style={{ marginTop: 10 }}
                          inputLabel="รหัสบริการ:"
                          placeholder="รหัสบริการ"
                          readOnly
                          prefix={prefix}
                          messageError={
                            errors['datamaster_service_item_number'] &&
                            errors['datamaster_service_item_number'].message
                          }
                          required
                        />
                      }
                      rules={{
                        required: 'กรุณากรอกข้อมูล',
                        maxLength: { value: 3, message: 'จำนวน 3 ตัวอักษรเท่านั้น' },
                        minLength: { value: 3, message: 'จำนวน 3 ตัวอักษรเท่านั้น' },
                        pattern: { value: /^[0-9]+$/i, message: 'กรอกเฉพาะตัวเลขเท่านั้น' },
                      }}
                      name={`datamaster_service_item_number`}
                      control={control}
                      defaultValue={renderValueMain(
                        'datamaster_service_item_number',
                        MasterMode,
                        dataModalService
                      )}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Controller
                      as={
                        <Input
                          style={{ marginTop: 10 }}
                          inputLabel="ชื่อบริการ (ไทย):"
                          placeholder="ชื่อบริการ (ไทย):"
                          messageError={
                            errors['service_item_name_th'] && errors['service_item_name_th'].message
                          }
                          required
                        />
                      }
                      rules={{ required: 'กรุณากรอกข้อมูล' }}
                      name={`service_item_name_th`}
                      control={control}
                      defaultValue={renderValueMain(
                        'service_item_name_th',
                        MasterMode,
                        dataModalService
                      )}
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <Controller
                      as={
                        <Input
                          style={{ marginTop: 10 }}
                          inputLabel="ชื่อบริการ (อังกฤษ):"
                          placeholder="ชื่อบริการ (อังกฤษ):"
                          messageError={
                            errors['service_item_name_en'] && errors['service_item_name_en'].message
                          }
                          required
                        />
                      }
                      rules={{ required: 'กรุณากรอกข้อมูล' }}
                      name={`service_item_name_en`}
                      control={control}
                      defaultValue={renderValueMain(
                        'service_item_name_en',
                        MasterMode,
                        dataModalService
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <div style={{ padding: '15px' }}>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <td style={{ textAlign: 'center', fontWeight: 700, width: 45 }}>
                            เปิดใช้
                          </td>
                          <td style={{ textAlign: 'center', fontWeight: 700, width: 85 }}>รหัส</td>
                          <td style={{ textAlign: 'center', fontWeight: 700, width: 180 }}>
                            ประเภทรถ
                          </td>
                          <td style={{ textAlign: 'center', fontWeight: 700 }}>หน่วยนับ</td>
                          <td colSpan={3} style={{ textAlign: 'center', fontWeight: 700 }}>
                            ราคาต้นทุน
                          </td>
                          <td colSpan={3} style={{ textAlign: 'center', fontWeight: 700 }}>
                            ราคาขาย
                          </td>
                          <td style={{ textAlign: 'center', fontWeight: 700 }}>
                            ราคาขั้นต่ำ (บาท)
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>ราคา (บาท)</td>
                          <td>VAT (%)</td>
                          <td>WHT (%)</td>
                          <td>ราคา (บาท)</td>
                          <td>VAT (%)</td>
                          <td>WHT (%)</td>
                          <td></td>
                        </tr>
                      </thead>
                      <tbody>
                        {dataTruckTypeList.length == 0 ? (
                          <tr>
                            <td colSpan={11}>กรุณาเลือกหมวดหมู่</td>
                          </tr>
                        ) : (
                          dataTruckTypeList.map((truckItem, key) => (
                            <tr key={key}>
                              <td style={{ textAlign: 'center' }}>
                                <Controller
                                  as={<FormControl style={{ display: 'none' }} />}
                                  name={`serviceItem[${key}]["service_category_id"]`}
                                  control={control}
                                  defaultValue={truckItem._id}
                                />
                                <Controller
                                  as={<FormControl style={{ display: 'none' }} />}
                                  name={`serviceItem[${key}]["_id"]`}
                                  control={control}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0]._id
                                      : null
                                  }
                                />
                                <Controller
                                  as={<FormControl style={{ display: 'none' }} />}
                                  name={`serviceItem[${key}]["truck_type_id"]`}
                                  control={control}
                                  defaultValue={truckItem.truck_type_id}
                                />
                                <Controller
                                  as={<FormControl type="checkbox" style={{ display: 'none' }} />}
                                  name={`serviceItem[${key}]["enable"]`}
                                  control={control}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].enable
                                      : true
                                  }
                                />
                                {checkEnable(key, MasterMode, truckItem, dataModalService) ? (
                                  <IconStyled
                                    onClick={() => setValue(`serviceItem[${key}]["enable"]`, false)}
                                    name="enable-on"
                                    className="fas fa-toggle-on"
                                  />
                                ) : (
                                  <IconStyled
                                    onClick={() => setValue(`serviceItem[${key}]["enable"]`, true)}
                                    name="enable-off"
                                    className="fas fa-toggle-on off"
                                  />
                                )}
                              </td>
                              <td>
                                {`${prefix}${prefixNumber}`}-{truckItem.truck_type_prefix}
                              </td>
                              <td>
                                {truckItem.truck_size_name.th}
                                {truckItem.truck_type_name.th}
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="text"
                                      placeholder="หน่วยนับ"
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['unit']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["unit"]`}
                                  control={control}
                                  rules={{ required: 'กรุณากรอกข้อมูล' }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].unit
                                      : ''
                                  }
                                />
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="ราคา"
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['cost']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["cost"]`}
                                  control={control}
                                  rules={{
                                    required: 'กรุณากรอกข้อมูล',
                                    min: 0,
                                    pattern: {
                                      value: /^[0-9]+$/i,
                                      message: 'กรอกเฉพาะตัวเลขเท่านั้น',
                                    },
                                  }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].cost
                                      : 0
                                  }
                                />
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="VAT"
                                      min={0}
                                      step={0.1}
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['vat_cost']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["vat_cost"]`}
                                  control={control}
                                  rules={{ required: 'กรุณากรอกข้อมูล' }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].vat_cost
                                      : 0
                                  }
                                />
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="WHT"
                                      min={0}
                                      step={0.1}
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['wht_cost']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["wht_cost"]`}
                                  control={control}
                                  rules={{ required: 'กรุณากรอกข้อมูล' }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].wht_cost
                                      : 0
                                  }
                                />
                              </td>

                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="ราคา"
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['sale']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["sale"]`}
                                  control={control}
                                  rules={{
                                    required: 'กรุณากรอกข้อมูล',
                                    min: 0,
                                    pattern: {
                                      value: /^[0-9]+$/i,
                                      message: 'กรอกเฉพาะตัวเลขเท่านั้น',
                                    },
                                  }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].sale
                                      : 0
                                  }
                                />
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="VAT"
                                      min={0}
                                      step={0.1}
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['vat']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["vat"]`}
                                  control={control}
                                  rules={{ required: 'กรุณากรอกข้อมูล' }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].vat
                                      : 0
                                  }
                                />
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="WHT"
                                      min={0}
                                      step={0.1}
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['wht']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["wht"]`}
                                  control={control}
                                  rules={{ required: 'กรุณากรอกข้อมูล' }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].wht
                                      : 0
                                  }
                                />
                              </td>
                              <td>
                                <Controller
                                  as={
                                    <FormControl
                                      type="number"
                                      placeholder="ราคา"
                                      style={{
                                        minWidth: 70,
                                        borderColor:
                                          errors['serviceItem'] &&
                                          errors['serviceItem'][key] &&
                                          errors['serviceItem'][key]['min_price']
                                            ? '#FF0000'
                                            : '#E3E3E3',
                                      }}
                                    />
                                  }
                                  name={`serviceItem[${key}]["min_price"]`}
                                  control={control}
                                  rules={{
                                    required: 'กรุณากรอกข้อมูล',
                                    min: 0,
                                    pattern: {
                                      value: /^[0-9]+$/i,
                                      message: 'กรอกเฉพาะตัวเลขเท่านั้น',
                                    },
                                  }}
                                  defaultValue={
                                    MasterMode == 'edit'
                                      ? truckItem.datamaster_service_item.filter(
                                          item =>
                                            item.datamaster_service_item_number ==
                                            dataModalService.itemList.datamaster_service_item_number
                                        )[0].min_price
                                      : 0
                                  }
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Row>
              </ModalContent>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ float: 'left' }}
                onClick={() => {
                  clearErrors();
                  props.onHideModal(false);
                }}
              >
                กลับ
              </Button>
              {/* <Button type="button" bsStyle="primary" onClick={handleSubmit((data) => saveDataMaster(data,errors,datamasterServiceItemAdd))}>ตกลง</Button> */}
              <Button type="submit" bsStyle="primary">
                ตกลง
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Mutation>
    </ModalContainer>
  );
};

export default React.memo(FormDataMaster);
