import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxRB } from 'react-bootstrap';

const StyledCheckbox = styled(CheckboxRB)`
  margin-top: -8px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  &:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }

  & input:checked ~ .checkmark {
    border: 1px solid #ffffff;
    background-color: #19aa19;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkbox = ({ children, ...otherProps }) => {
  return <StyledCheckbox {...otherProps}>{children}</StyledCheckbox>;
};

export default Checkbox;
