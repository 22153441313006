import React from 'react';
import styled from 'styled-components';
import { Table, Button } from 'react-bootstrap';
import Moment from 'react-moment';

export const StyledTable = styled(Table)`
  &.custom-table {
    & thead tr th {
      color: #000000;
      font-weight: bold;
      font-size: 13px;
      text-transform: none;
    }

    & td {
      word-break: break-all;
      height: 50px;
    }
  }
`;

const Tag = styled.div`
  background-color: #e5ffe5;
  color: #5dba5b;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 4px;
  width: max-content;
`;

const ButtonDelete = styled(Button)`
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  font-size: 16px;

  & > i {
    color: red;
  }
`;

const formatDate = (date) => {
  return date ? <Moment format="DD/MM/YYYY HH:mm:ss">{date}</Moment> : '-';
};

const formatCurrency = (number) => {
  return `฿ ${number.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })}`;
};

const renderOrderType = (status) => {
  switch (status) {
    case 1:
      return (
        <Tag
          style={{
            backgroundColor: '#E5FFE5',
            color: '#5DBA5B',
          }}
        >
          Last Mile (LM)
        </Tag>
      );
    case 2:
      return (
        <Tag
          style={{
            backgroundColor: '#ffb7b0',
            color: '#ff1100',
          }}
        >
          Return to Merchant (RM)
        </Tag>
      );
    case 5:
      return (
        <Tag
          style={{
            backgroundColor: '#ffefd9',
            color: '#ff9500',
          }}
        >
          Return (RT)
        </Tag>
      );
    case 9:
      return (
        <Tag
          style={{
            backgroundColor: '#d6949e',
            color: '#fff',
          }}
        >
          First Mile (FM)
        </Tag>
      );
    case 10:
      return (
        <Tag
          style={{
            backgroundColor: '#bcddfd',
            color: '#318de8',
          }}
        >
          Shuttle Haul (SH)
        </Tag>
      );
    case 11:
      return (
        <Tag
          style={{
            backgroundColor: '#ffe2bb',
            color: '#e28200',
          }}
        >
          Reject Return (RR)
        </Tag>
      );
    case 12:
      return (
        <Tag
          style={{
            backgroundColor: '#d9e2ff',
            color: '#318DE8',
          }}
        >
          Direct Ship (DS)
        </Tag>
      );
    case 13:
      return (
        <Tag
          style={{
            backgroundColor: '#FFDB46',
            color: 'black',
          }}
        >
          Line Haul (LH)
        </Tag>
      );
    default:
      return status;
  }
};

const CodListTable = ({ codList, onDelete, isCanDelete, data }) => {

  const columns = [
    // {
    //   key: 'tools',
    //   title: 'เครื่องมือ',
    //   width: '80px',
    // },
    // {
    //   key: 'lazadaOrderType',
    //   title: 'ประเภทงาน',
    //   width: '130px',
    // },
    {
      key: 'shipmentTrip',
      title: 'Shipment / Trip No.',
      width: '250px',
    },
    {
      key: 'trackingNumber',
      title: 'Tracking No.',
      width: '180px',
    },
    {
      key: 'trackingDeliveredDate',
      title: 'Delivered Date',
      width: '160px',
    },
    {
      key: 'codPrice',
      title: 'มูลค่า COD',
      width: '120px',

    },
  ];

  // const dataTable = codList
  //   .map((item) => ({
  //     // tools: (
  //     //   <ButtonDelete
  //     //     onClick={() => onDelete && onDelete(item.id)}
  //     //     disabled={!isCanDelete}
  //     //   >
  //     //     <i className="far fa-trash-alt"></i>
  //     //   </ButtonDelete>
  //     // ),
  //     // lazadaOrderType: renderOrderType(item.lazadaOrderType),
  //     trackingNumber: item?.trackingNumber,
  //     trackingDeliveredDate: formatDate(item?.trackingDeliveredDate),
  //     shipmentTrip: item?.shipmentTrip,
  //     codPrice: formatCurrency(item?.codPrice),
  //   }))
  //   .map((item, index) => (
  //     <tr key={index}>
  //       {columns.map((column) => (
  //         <td
  //           key={column.key}
  //           style={{
  //             textAlign: column.textAlign,
  //           }}
  //         >
  //           {item[column.key]}
  //         </td>
  //       ))}
  //     </tr>
  //   ));

  // console.log("data?.parcel_orders.shipment_number", data?.parcel_orders?.map(parcel_order => {
  //   return {
  //     shipment_number: parcel_order.shipment_number
  //   }
  // }))
  // const parcelorders = data?.parcel_orders
  return (
    <StyledTable striped hover className="custom-table">
      <thead>
        <tr>
          {columns.map((item) => (
            <th
              key={item.title}
              style={{
                minWidth: item.width,
                width: item.width,
                textAlign: item.textAlign,
              }}
            >
              {item.title}
            </th>
          ))}
        </tr>
      </thead>



      {data?.parcel_orders?.map(parcel_order => {
        return <tbody> <tr>   <td>{parcel_order?.shipment_number} </td>

          <td>{parcel_order?.tracking_number} </td>
          <td>{parcel_order?.delivered_date} </td>
          <td>{parcel_order?.cod_price} </td>

        </tr>    </tbody>

      })
      }






      {/* {props.dataList.map((data) => (
          <tr key={data._id}>
            {!props.hideTools && (
              <td className="tools">
                <i
                  className={`far fa-trash-alt ${
                    !props.isCreateQR ? 'clickable red' : ''
                  }`}
                  onClick={() =>
                    !props.isCreateQR && props.removeList(data._id)
                  }
                ></i>
              </td>
            )}
            <td>
              {props.newCodCase ? data.booking_number : data.format_bookname}
            </td>
            <td>
              {props.newCodCase ? data.shipment_number : data.shipment_trip}
            </td>
            <td className="cod-total">
              {props.newCodCase
                ? formatMoney(data.cod_price || 0)
                : formatMoney(data.shipment_current_total_cod || 0)}
            </td>
            <td className="cod-cash">
              {props.newCodCase
                ? formatMoney(data.paid_by_cash || 0)
                : formatMoney(data.shipment_accept_cod || 0)}
            </td>
            <td className="cod-qr">
              {props.newCodCase
                ? formatMoney(data.paid_by_qr || 0)
                : formatMoney(data.shipment_accept_cod_qr || 0)}
            </td>
          </tr>
        ))} */}

    </StyledTable >
  );
};

export default CodListTable;
