import React, { useEffect, useState } from 'react';
import { Row, Col, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { useFormContext, Controller } from 'react-hook-form';
import moment from 'moment';
import Datetime from 'react-datetime';
import UploadImage from './UploadImage';
import PhotoPreview from './PhotoPreview';
import LightBoxPreview from './LightBoxPreview';
import GenerateQrCode from './GenerateQrCode';
import CodListTable from './CodListTable';
import formatCurrency from 'utils/formatCurrency';

const StyledCol = styled(Col)`
  margin-bottom: 10px;
`;

const MessageRed = styled.span`
  color: red;
`;

const Form = ({ data = {} }) => {
  const { control, errors, watch, setValue, trigger } = useFormContext();
  const watchAttachPhotos = watch('document') || [];
  const watchCodList = watch('codList') || [];
  const watchQrCode = watch('qrCode');
  const [isOpenLightBox, setOpenLightBox] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const paymentBankAccount = {
    bankName: 'ธนาคารกรุงเทพ จำกัด',
    accountNumber: '010555812476709',
  };

  const totalCod = data?.total_cod || 0.0;

  const formatMoney = number => {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>รายละเอียด COD</div>
      <Row>
        <StyledCol xs={6}>
          <div>
            <span>
              ยอดโอนทั้งหมด (บาท)<MessageRed>**</MessageRed>
            </span>
            <FormControl
              type="text"
              placeholder="0.00"
              disabled={true}
              value={formatCurrency(totalCod)}
            />

            {/* <input
              style={{
                border: "solid 1px #28282828",
                borderRadius: "5px",
                // height: " 42px",
                // padding: "12px",
                width: "100%"
              }}
              disabled={true}
              value={formatCurrency(totalCod)}

            /> */}
          </div>
        </StyledCol>

        <StyledCol xs={6}>
          <span>
            วัน - เวลาที่โอน
            <MessageRed>* {errors?.dateTime?.message} </MessageRed>
          </span>

          <Controller
            control={control}
            name="dateTime"
            render={({ onChange, value }) => {
              return (
                <Datetime
                  timeFormat="HH:mm"
                  dateFormat="DD/MM/YYYY"
                  inputProps={{ placeholder: '01/01/2022 00:00' }}
                  // value={data?.cod_in_date}
                  value={value ? moment(value) : data?.cod_in_date ? data?.cod_in_date : ''}
                  onChange={momentDate => {
                    try {
                      onChange(momentDate.format('YYYY-MM-DD HH:mm'));
                    } catch {
                      onChange('');
                    }
                  }}
                />
              );
            }}
          />
        </StyledCol>

        <StyledCol xs={6}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 20 }}>
              {!data?.paid_qr_detail && (
                <GenerateQrCode
                  codList={watchCodList}
                  onCompleted={values => {
                    setValue('qrCode', {
                      id: values.id,
                      date: values.date,
                      ref1: values.ref1,
                      ref2: values.ref2,
                      image: values.image,
                      paidAmount: values.paidAmount,
                      billingCod: values.billingCod,
                    });
                  }}
                />
              )}

              {data?.paid_qr_detail && (
                <React.Fragment>
                  {data?.cod_status === 1 || data?.cod_status === 4 ? (
                    <img
                      src={data?.paid_qr_detail?.qr_image}
                      style={{
                        width: 130,
                        height: 130,
                        border: '1px solid #fafafa',
                      }}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </React.Fragment>
              )}
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ marginBottom: 6 }}>
                <strong>กรุณาแสกน QR-Code เพื่อชำระเงิน</strong>
              </div>
              <div>
                ชื่อบัญชี : <strong>GIZTIX</strong>
              </div>
              <div>
                ธนาคาร : <strong>{paymentBankAccount.bankName}</strong>
              </div>
              <div>
                Biller ID : <strong>{paymentBankAccount.accountNumber}</strong>
              </div>
              {data?.paid_qr_detail && (
                <>
                  <div>
                    Ref. 1 : <strong>{data?.paid_qr_detail.qr_ref1 || 'ว่าง'}</strong>
                  </div>
                  <div>
                    Ref. 2 : <strong>{data?.paid_qr_detail.qr_ref2 || 'ว่าง'}</strong>
                  </div>
                </>
              )}

              {data?.cod_status === 4 && (
                <div>เหตุผลที่ถูกปฏิเสธ : {data?.reject_remark || 'ว่าง'} </div>
              )}
            </div>
          </div>
        </StyledCol>

        <StyledCol xs={6}>
          <span>{data?.cod_status != 1 && <span>หลักฐานการโอน</span>}</span>
          <div>
            <MessageRed>{errors?.document?.message}</MessageRed>
          </div>

          <div
            style={{
              gap: 10,
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {/* <UploadImage
              onCompleted={(imageUrl) => {
                setValue('document', [
                  ...watchAttachPhotos,
                  { path: imageUrl },
                ]);

                trigger(['document']);
              }}
            /> */}

            {data?.document.map((image, imageItemIndex) => {
              if (image) {
                return (
                  // <img
                  //   style={{
                  //     width: "100px",
                  //     height: "100px"
                  //   }}
                  //   alt=""
                  //   src={image}
                  // />

                  <PhotoPreview
                    key={image}
                    pathUrl={image}
                    // onDelete={() => {
                    //   const filterImage = data?.document.filter(
                    //     (_, index) => index !== imageItemIndex
                    //   );
                    //   setValue('document', [...filterImage]);
                    // }}
                    onClick={() => {
                      setImageIndex(imageItemIndex);
                      setOpenLightBox(true);
                    }}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </StyledCol>
      </Row>

      <LightBoxPreview
        isOpen={isOpenLightBox}
        images={data?.document.map(item => {
          return item;
        })}
        imageIndex={imageIndex}
        onClose={() => setOpenLightBox(false)}
        onNext={index => setImageIndex(index)}
        onPrev={index => setImageIndex(index)}
      />

      <div style={{ overflowX: 'auto', marginTop: 10 }}>
        <CodListTable
          data={data}
          isCanDelete={!watchQrCode.id && watchCodList.length > 1}
          codList={watchCodList}
          onDelete={id => {
            const filter = watchCodList.filter(item => item.id !== id);
            setValue('codList', filter);
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: -10,
          }}
        >
          <div style={{ marginRight: 50 }}>มูลค่า COD (บาท)</div>
          <strong>{formatMoney(totalCod)}</strong>
        </div>
      </div>
    </div>
  );
};

export default Form;
