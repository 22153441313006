import gql from 'graphql-tag';

export default gql`
query seachInvoiceList(
    $shipment_id :ID
) { 
    invoiceList( 
        shipment_id :$shipment_id 
    ){
        _id
        shipment_id
        shipper
        invoice_status
        summary_invoice_id
        summary_invoice_status
        paid_type
        paid_credit_card_detail{
            bank
            last_digits
            brand
            name
       }
        promotion_code{
            name
            price
            for_you
            title_lang{
                th
                en
            }
        }
        invoice_discount {
            detail
            price
            discount_type
            percent
        }
        payment_date
        invoice_list{
            list_type
            list_name
            list_qty
            list_price
            list_total_price
            list_cost_price
            list_cost_total_price
        }
        invoice_business_list{
            _id
            create_costing
            payment_order_id
            invoice_status_update
            datamaster_job_type_id
            datamaster_truck_type_id
            datamaster_truck_type_prefix_id
            datamaster_service_category_id
            datamaster_service_item_id
            datamaster_service_category_prefix_id
            invoice_list_id
            invoice_business_list_category
            invoice_business_list_detail
            invoice_business_list_price
            invoice_business_list_type
            invoice_business_list_qty
            invoice_business_list_total_price
            invoice_business_list_discount
            invoice_business_list_discount_cost
            invoice_business_list_tax_type
            invoice_business_list_vat
            invoice_business_list_wht
            invoice_business_list_total_vat
            invoice_business_list_total_wht
            invoice_business_list_grand_total_price
            invoice_business_list_cost_price
            invoice_business_list_cost_total_price
            invoice_business_list_cost_discount
            invoice_business_list_cost_vat
            invoice_business_list_cost_wht
            invoice_business_list_cost_total_vat
            invoice_business_list_cost_total_wht
            invoice_business_list_cost_grand_total_price
        }
        billing_costing
        total_price
        total_vat7
        total_wht1
        total_wht3
        total_wht5
        invoice_business_grand_total_price
        cost_total_vat7
        cost_total_wht1
        cost_total_wht3
        cost_total_wht5
        cost_total_price
        invoice_business_cost_grand_total_price
        invoice_date
        shipment_completed_date
        create_date
        last_update
    }
} 
`;
