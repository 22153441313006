import gql from 'graphql-tag';

export default gql`
mutation Update(
    $account_info_id: ID
    $id_array: [String]
  
  ){
    makeDebitNoteManual(
    account_info_id: $account_info_id
    id_array: $id_array
    ){
        _id
    }
}
`;
