import gql from 'graphql-tag';

export default gql`
query search(
    $account_info_id: ID
    $summary_invoice_id: ID
    $status: Int
    $page_number: Int
    $order_by: String
    $order_type: String
  ){
    summaryInvoiceList(
        account_info_id : $account_info_id
        _id : $summary_invoice_id
        status : $status
        show_data : 30
        page_number : $page_number
        order_by : $order_by
        order_type : $order_type
        
    ){
    summaryInvoice{
        _id
        account_info_id
        account_info_business_name
        summary_invoice_number
        invoice_id
        status
        bill_date_start
        bill_date_end
        issue_date
        due_date
        credit_used
        summary_total_price
        invoice_document
        paid_type
        receipt_id
        receipt_number
        receipt_date
        receipt_create_date
        receipt_document
        paid_bank_transfer{
            transfer_price
            transfer_pic
            transfer_date
        }
        paid_credit_card_detail{
            bank
            last_digits
            brand
            name
        }
        create_date
        read_status
        last_update
        debitNote{
            _id
            debit_note_number
            paid_type
            receipt_id
            receipt_number
            receipt_date
            receipt_create_date
            receipt_document
            read_status
            create_date
        }
        credit_used
    }
    total_page
    }
}
`;
