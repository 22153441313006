import gql from 'graphql-tag';

export default gql`
  query OtpList($phone: String!) {
    otpList(phone: $phone) {
      _id
      type
      otp
      ref
      phone
      email
      create_date
      time_out
    }
  }
`;
