import gql from 'graphql-tag';

export default gql`
	query searchDriverMonthlyCalendarDetail(
    $date: String
    $account_info: String
  ){
    driverMonthlyCalendarDetail(
      date: $date
      account_info: $account_info
    ) {
      detail{
        _id
        date
        driver{
          _id
          driver_name
        }
        account_info{
          _id
          account_info_business_name
        }
        setting_truck_name{
          en
        }
        truck_type_name{
          en
        }
        driver_route_service{
          _id
          provine
        }
      }
    }
}
`;
