import React from 'react';
import { Query, Mutation } from 'react-apollo';
import classes from './SubShipmentTracking.module.scss';
import cn from '../../ShipmentDetail.module.scss';
import Moment from 'react-moment';
import { Row, Col, Button } from 'react-bootstrap';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';
import { TrackingList, LightBoxPreview, OrderList } from '../../../../components';
import { ModalConfirm } from '../../../../../Lazada/components';
import shipmentListQuery from './graphql/query/shipmentList';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';

import s3 from 'config/s3';
import classnames from 'classnames';

class SubShipmentTracking extends React.Component {
  static contextType = ShipmentDetailContext;
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      isOpenModalLazadaStatus: false,
      commodity: null,
      deliveryObj: null,
    };
  }

  renderTrackingListPickup = pick_up => {
    const index = this.context.state.subShipmentTrackingIndex;
    const pickUp = pick_up[index];
    let trackingList = [];
    if (pickUp.signature_picture !== null) {
      trackingList.push({
        title: 'เซ็นรับมอบ เรียบร้อยแล้ว',
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{pickUp.signature_date}</Moment>,
        content: (
          <LightBoxPreview
            images={[`${s3}/${pickUp.signature_picture}`]}
            position={`${pickUp.lat_signature_picture},${pickUp.lng_signature_picture}`}
          />
        ),
      });
    }

    const commodityPicture = pickUp.commodity_picture.map(item => `${s3}/${item}`);
    if (commodityPicture.length > 0) {
      trackingList.push({
        title: `${
          pickUp.pickup_failure_detail && pickUp.pickup_failure_detail.length > 0
            ? 'รูปภาพแนป'
            : 'รูปท้ายรถ'
        }`,
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{pickUp.signature_date}</Moment>,
        content: (
          <LightBoxPreview
            images={commodityPicture}
            position={`${pickUp.check_commodity_lat},${pickUp.check_commodity_lng}`}
          />
        ),
      });
    }

    if (pickUp.check_commodity === 1) {
      const orderList = pickUp.commodity.map((item, index) => (
        <OrderList
          key={index}
          item={item}
          title={`${item.failure_pre_call ? '(Pre Call)' : ''} ${
            item.accept === 1 ? `${item.damage ? '[นำส่งแต่ชำรุด]' : ''}` : '[ไม่นำส่ง]'
          } ${item.name || '-'}`}
          subTitle={`Order number: ${item.order_number || '-'}`}
          active={item.accept === 1}
          onPress={() =>
            item.accept === 1
              ? this.context.onOpenSubShipmentTrackingOrder(item.name, index, 'pickup')
              : null
          }
        />
      ));
      let pickup_failure_detail = '';
      if (pickUp.pickup_failure_detail && pickUp.pickup_failure_detail.length > 0) {
        pickup_failure_detail = pickUp.pickup_failure_detail.map((item, index) => (
          <span> {item.th}</span>
        ));
      }
      trackingList.push({
        title: 'ตรวจสอบสินค้า เรียบร้อยแล้ว',
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{pickUp.check_commodity_date}</Moment>,
        content: (
          <div>
            {pickup_failure_detail !== '' && (
              <div>
                <b>เหตุผลที่ไม่ได้รับสินค้า : </b>
                {pickup_failure_detail}
              </div>
            )}
            {orderList}
          </div>
        ),
      });
    }

    if (pickUp.contact_status) {
      trackingList.push({
        title: 'ติดต่อจุดรับสินค้า เรียบร้อยแล้ว',
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{pickUp.contact_customer_date}</Moment>,
        content: null,
      });
    }

    return trackingList.map((item, index) => (
      <TrackingList key={index} title={item.title} subTitle={item.subTitle}>
        {item.content}
      </TrackingList>
    ));
  };

  renderFailureStatusName = (status, failure_detail) => {
    if (failure_detail) {
      return failure_detail;
    } else {
      switch (parseInt(status)) {
        case 1:
          return 'ที่อยู่ไม่ถูกต้อง';
        case 2:
          return 'ติดต่อลูกค้าไม่ได้';
        case 3:
          return 'ลูกค้าปฎิเสธรับสินค้า';
        case 4:
          return 'ส่งช้าเกินคำมั่นสัญาต่อลูกค้า';
        case 5:
          return 'สินค้าราคาซ้ำ';
        case 6:
          return 'สินค้าสูญหาย';
        case 7:
          return 'สินค้าชำรุด';
        case 8:
          return 'ลูกค้าเลื่อนส่ง';
        case 9:
          return 'อื่นๆ';
      }
    }
  };

  renderTrackingListDelivery = delivery => {
    const index = this.context.state.subShipmentTrackingIndex;
    const deliveryData = delivery[index];

    let trackingList = [];
    if (deliveryData.delivery_status === 4) {
      if (deliveryData.failure_status == 2) {
        trackingList.push({
          title: 'ติดต่อลูกค้าไม่ได้',
          subTitle: <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.failure_date}</Moment>,
          content: (
            <Row>
              {deliveryData.failure_picture.map(picPath => (
                <Col md={6}>
                  <LightBoxPreview images={[`${s3}/${picPath}`]} />
                </Col>
              ))}
            </Row>
          ),
          colorIcon: '#E30301',
        });
      } else if (deliveryData.failure_status == 8) {
        trackingList.push({
          title: 'เลื่อนส่ง',
          subTitle: <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.failure_date}</Moment>,
          content: (
            <div>
              <span style={{ fontWeight: 'bold' }}>วันที่ลูกค้าเลื่อนส่ง: </span>
              <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.postpone_delivery_date}</Moment>
            </div>
          ),
          colorIcon: '#E30301',
        });
      } else {
        trackingList.push({
          title: this.renderFailureStatusName(
            deliveryData.failure_status,
            deliveryData.failure_detail
          ),
          subTitle: <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.failure_date}</Moment>,
          content: deliveryData.failure_detail,
          colorIcon: '#E30301',
        });
      }
    }

    if (deliveryData.signature_picture !== null) {
      trackingList.push({
        title: 'เซ็นรับมอบ เรียบร้อยแล้ว',
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.signature_date}</Moment>,
        content: (
          <LightBoxPreview
            images={[`${s3}/${deliveryData.signature_picture}`]}
            position={`${deliveryData.lat_signature_picture},${deliveryData.lng_signature_picture}`}
          />
        ),
        colorIcon: '#5cb85c',
      });
    } else {
      if (deliveryData.delivery_status !== 4) {
        trackingList.push({
          title: 'เซ็นรับมอบ',
          subTitle: 'รอดำเนินการ',
          content: null,
          colorIcon: '#E3E3E3',
        });
      }
    }

    if (
      deliveryData.cod_tranfer_date !== null ||
      (deliveryData.signature_date !== null && deliveryData.paid_by_cod === 1)
    ) {
      const cnCOD = classnames({
        [cn.textBold]: deliveryData.cod_tranfer === null,
        [cn.textRed]: deliveryData.cod_tranfer === null,
      });

      const cnQRCode = classnames({
        [cn.textBold]: deliveryData.cod_tranfer === 1,
        [cn.textRed]: deliveryData.cod_tranfer === 1,
      });

      const priceCOD = deliveryData.cod_tranfer === null ? deliveryData.cod_price : 0;
      const priceQRCode = deliveryData.cod_tranfer === 1 ? deliveryData.cod_price : 0;

      trackingList.push({
        title: 'เก็บเงินปลายทาง เรียบร้อยแล้ว',
        subTitle: (
          <Moment format="DD/MM/YYYY - HH:mm">
            {deliveryData.cod_tranfer_date || deliveryData.signature_date}
          </Moment>
        ),
        content: (
          <div>
            <div className={`${cn.borderBox} ${cn.mb10}`}>
              <div className={`${cn.RowItem} ${cn.textBold}`}>
                <div className={cn.Left}>เก็บเงินปลายทาง (COD)</div>
                <div className={cn.Right}>{this.formatMoney(deliveryData.cod_price)} บาท</div>
              </div>
              <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>
              <div className={`${cn.RowItem} ${cnQRCode}`}>
                <div className={cn.Left}>รับเงินผ่าน QR Code</div>
                <div className={cn.Right}>{this.formatMoney(priceQRCode)} บาท</div>
              </div>
              <div className={`${cn.RowItem} ${cn['no-pb']} ${cnCOD}`}>
                <div className={cn.Left}>รับเงินสด</div>
                <div className={cn.Right}>{this.formatMoney(priceCOD)} บาท</div>
              </div>
            </div>
            {deliveryData.cod_tranfer_slip && (
              <LightBoxPreview images={[`${s3}/${deliveryData.cod_tranfer_slip}`]} />
            )}
          </div>
        ),
        colorIcon: '#5cb85c',
      });
    } else {
      if (deliveryData.delivery_status !== 4 && deliveryData.paid_by_cod === 1) {
        trackingList.push({
          title: 'เก็บเงินปลายทาง',
          subTitle: 'รอดำเนินการ',
          content: null,
          colorIcon: '#E3E3E3',
        });
      }
    }

    if (deliveryData.check_commodity_date !== null) {
      const pictures =
        deliveryData.commodity_picture.length > 0
          ? deliveryData.commodity_picture.map(item => `${s3}/${item}`)
          : deliveryData.commodity_picture_end.map(item => `${s3}/${item}`);
      trackingList.push({
        title: `ตรวจสอบ ${deliveryData.commodity[0].name} เรียบร้อยแล้ว`,
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.check_commodity_date}</Moment>,
        content: (
          <LightBoxPreview
            images={pictures}
            mode="commodity"
            position={`${deliveryData.check_commodity_lat},${deliveryData.check_commodity_lng}`}
          />
        ),
        colorIcon: '#5cb85c',
      });
    } else {
      if (deliveryData.delivery_status !== 4) {
        trackingList.push({
          title: `ตรวจสอบ ${deliveryData.commodity[0].name}`,
          subTitle: 'รอดำเนินการ',
          content: null,
          colorIcon: '#E3E3E3',
        });
      }
    }

    if (deliveryData.contact_status === 1) {
      trackingList.push({
        title: 'ติดต่อจุดส่งสินค้า เรียบร้อยแล้ว',
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{deliveryData.contact_customer_date}</Moment>,
        content: null,
        colorIcon: '#5cb85c',
      });
    } else {
      if (deliveryData.delivery_status !== 4) {
        trackingList.push({
          title: 'ติดต่อจุดส่งสินค้า',
          subTitle: 'รอดำเนินการ',
          content: null,
          colorIcon: '#E3E3E3',
        });
      }
    }

    return trackingList.map((item, index) => (
      <TrackingList
        key={index}
        title={item.title}
        subTitle={item.subTitle}
        colorIcon={item.colorIcon}
      >
        {item.content}
      </TrackingList>
    ));
  };

  renderTrackingListReturn = returnList => {
    const index = this.context.state.subShipmentTrackingIndex;
    const returnData = returnList[index];

    let trackingList = [];
    if (returnData.signature_picture_return !== null) {
      trackingList.push({
        title: 'เซ็นรับมอบ เรียบร้อยแล้ว',
        subTitle: <Moment format="DD/MM/YYYY - HH:mm">{returnData.signature_date_return}</Moment>,
        content: (
          <LightBoxPreview
            images={[`${s3}/${returnData.signature_picture_return}`]}
            position={`${returnData.lat_signature_picture_return},${returnData.lng_signature_picture_return}`}
          />
        ),
      });
    } else {
      trackingList.push({
        title: 'เซ็นรับมอบ',
        subTitle: 'รอดำเนินการ',
        content: null,
        colorIcon: '#E3E3E3',
      });
    }

    const orderList = returnData.delivery_point.map((item, index) => {
      let status = '';
      let preCAll = '';
      if (item.delivery_status === 6) {
        status = `[ไม่ทำการคืน]`;
      } else if (item.delivery_status !== 5) {
        status = `[ยังไม่คืน]`;
      }

      preCAll = item.failure_pre_call ? '(Pre Call)' : ''

      return (
        <OrderList
          key={index}
          item={item}
          title={`${preCAll} ${status} ${item.commodity[0].name || '-'}`}
          subTitle={`Order number: ${item.commodity[0].order_number || '-'}`}
          active={item.delivery_status === 5}
          onPress={() =>
            item.delivery_status === 5
              ? this.context.onOpenSubShipmentTrackingOrder(item.commodity[0].name, index, 'return')
              : null
          }
        />
      );
    });

    if (returnData.check_commodity_return === 1) {
      trackingList.push({
        title: 'ตรวจสอบสินค้า เรียบร้อยแล้ว',
        subTitle: (
          <Moment format="DD/MM/YYYY - HH:mm">{returnData.check_commodity_return_date}</Moment>
        ),
        content: <div>{orderList}</div>,
      });
    } else {
      trackingList.push({
        title: 'ตรวจสอบสินค้า',
        subTitle: 'รอดำเนินการ',
        content: orderList,
        colorIcon: '#E3E3E3',
      });
    }

    if (returnData.contact_return_status === 1) {
      trackingList.push({
        title: 'ติดต่อจุดคืนสินค้า เรียบร้อยแล้ว',
        subTitle: (
          <Moment format="DD/MM/YYYY - HH:mm">{returnData.contact_return_customer_date}</Moment>
        ),
        content: null,
      });
    } else {
      trackingList.push({
        title: 'ติดต่อจุดคืนสินค้า',
        subTitle: 'รอดำเนินการ',
        content: null,
        colorIcon: '#E3E3E3',
      });
    }

    return trackingList.map((item, index) => (
      <TrackingList
        key={index}
        title={item.title}
        subTitle={item.subTitle}
        colorIcon={item.colorIcon}
      >
        {item.content}
      </TrackingList>
    ));
  };

  formatMoney = number => {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  };

  renderDetailDelivery = delivery => {
    const index = this.context.state.subShipmentTrackingIndex;
    const deliveryData = delivery[index];
    const commodity = deliveryData.commodity[0];

    return (
      <div>
        <div className={`${cn.RowItem} ${cn.textBold}`}>
          <div className={cn.Left}>{commodity.name}</div>
        </div>
        {
          // {deliveryData.send_failed_status !== 1 && deliveryData.lazada_order_api ? (
          //   <div className={`${cn.RowItem} ${cn.mt10}`}>
          //     <div className={cn.Left}>ยกเลิกรับสินค้า</div> <br />
          //     <div className={cn.Left}>
          //       <Button
          //         style={{ background: '#49acf1', color: '#ffffff', opacity: 1 }}
          //         onClick={() =>
          //           this.setState({
          //             mode: 'cancel',
          //             isOpenModalLazadaStatus: true,
          //             commodity,
          //             deliveryObj: delivery,
          //           })
          //         }
          //       >
          //         {' '}
          //         <i className="fal fa-reload"></i> ยกเลิกรับสินค้า{' '}
          //       </Button>
          //     </div>
          //   </div>
          // ) : (
          //   deliveryData.send_failed_status === 1 && (
          //     <div className={`${cn.RowItem} ${cn.mt10}`}>
          //       <div className={cn.Left}>ยกเลิกรับสินค้า</div> <br />
          //       <div className={cn.Left}>
          //         <b className={cn.textRed}>ยกเลิกรับสินค้าเรียบร้อย</b>
          //       </div>
          //     </div>
          //   )
          // )}
          // {console.log(
          //   deliveryData.lazada_order_api,
          //   deliveryData.send_final_status,
          //   deliveryData.send_failed_status
          // )}
          // {deliveryData.delivery_status === 3 &&
          // deliveryData.send_failed_status !== 1 &&
          // deliveryData.lazada_order_api ? (
          //   <div className={`${cn.RowItem} ${cn.mt10}`}>
          //     <div className={cn.Left}>ส่งสถานะให้ลาซาด้า</div> <br />
          //     <div className={cn.Left}>
          //       <Button
          //         style={{ background: '#5cb85c', color: '#ffffff', opacity: 1 }}
          //         onClick={() =>
          //           this.setState({
          //             mode: 'final',
          //             isOpenModalLazadaStatus: true,
          //             commodity,
          //             deliveryObj: delivery,
          //           })
          //         }
          //       >
          //         {' '}
          //         <i className="fal fa-reload"></i> ส่งสำเร็จ{' '}
          //       </Button>
          //     </div>
          //   </div>
          // ) : (
          //   deliveryData.send_final_status == 1 && (
          //     <div className={`${cn.RowItem} ${cn.mt10}`}>
          //       <div className={cn.Left}>ส่งสถานะให้ลาซาด้า</div> <br />
          //       <div className={cn.Left}>
          //         <b className={cn.textGreen}>ส่งสถานะให้ลาซาด้าเรียบร้อย</b>
          //       </div>
          //     </div>
          //   )
          // )}
        }
        <div className={`${cn.RowItem} ${cn.mt10}`}>
          <div className={cn.Left}>Order number:</div>
        </div>
        <div className={`${cn.RowItem} ${cn.textBold}`}>
          <div className={cn.Left}>{commodity.order_number || '-'}</div>
        </div>
        <div className={`${cn.RowItem} ${cn.mt10}`}>
          <div className={cn.Left}>สินค้า:</div>
        </div>
        <div className={`${cn.RowItem} ${cn.textBold}`}>
          <div className={cn.Left} style={{ lineHeight: 1.5 }}>
            {commodity.name_detail || '-'}
          </div>
        </div>
        <div className={`${cn.RowItem} ${cn.mt10}`}>
          <div className={cn.Left}>ชื่อผู้รับสินค้า:</div>
        </div>
        <div className={`${cn.RowItem} ${cn.textBold}`}>
          <div className={cn.Left}>
            {commodity.contact_name ? `${commodity.contact_name}, ${commodity.contact_tel}` : '-'}
          </div>
        </div>
        <div className={`${cn.RowItem} ${cn.mt10}`}>
          <div className={cn.Left}>ที่อยู่:</div>
        </div>
        <div className={`${cn.RowItem} ${cn.textBold} ${cn['no-pb']}`}>
          <div className={cn.Left} style={{ lineHeight: 1.5 }}>
            {deliveryData.address || '-'}
          </div>
        </div>
        <div className={`${cn.LineHorizontal} ${cn.mt10}`} />
      </div>
    );
  };

  renderVariables() {
    const { commodity, deliveryObj } = this.state;

    const obj = [];
    const data = deliveryObj
      ? deliveryObj.filter((element, index, array) => element.commodity[0].name == commodity.name)
      : [];

    if (data.length > 0) {
      data.forEach((currentValue, index, arr) => {
        if (this.state.mode === 'final') {
          obj.push({
            _id: currentValue._id,
            send_final_status: 1,
          });
        } else {
          obj.push({
            _id: currentValue._id,
            send_failed_status: 1,
          });
        }
      });
    }
    return obj;
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>
          {this.context.state.subShipmentTrackingTitle}
          <button
            className={cn.BtnClose}
            onClick={() => {
              this.context.setState({
                subShipmentTrackingIndex: null,
              });

              this.context.onClose(['subShipmentTracking']);
            }}
          >
            <i className="fal fa-times"></i>
          </button>
        </div>

        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId,
          }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) return 'loading...';
            if (error) return error;

            const { shipmentList } = data;
            const shipment = shipmentList.shipment[0];
            const {
              pick_up,
              delivery,
              return: returnData,
            } = shipment.tracking.tracking_detail_lazada;
            console.log('pick_up', pick_up);
            return (
              <div>
                {this.context.state.subShipmentTrackingMode === 'delivery' &&
                  this.renderDetailDelivery(delivery)}
                <div className={cn.TrackingWrapper}>
                  {this.context.state.subShipmentTrackingMode === 'pickup' &&
                    this.renderTrackingListPickup(pick_up)}
                  {this.context.state.subShipmentTrackingMode === 'delivery' &&
                    this.renderTrackingListDelivery(delivery)}
                  {this.context.state.subShipmentTrackingMode === 'return' &&
                    this.renderTrackingListReturn(returnData)}
                </div>

                <Mutation
                  mutation={lazTrackingUpdateMutation}
                  variables={{
                    shipment_id: shipment._id,
                    _id: shipment.tracking._id,
                    tracking_detail_lazada: {
                      delivery: this.renderVariables(),
                    },
                  }}
                  update={(cache, { data }) => {
                    this.setState({
                      isOpenModalLazadaStatus: false,
                      commodity: null,
                      deliveryObj: null,
                    });
                  }}
                  refetchQueries={() => ['LazadaShipmentListDetailSubTracking']}
                >
                  {lazTrackingUpdate => {
                    return (
                      <ModalConfirm
                        show={this.state.isOpenModalLazadaStatus}
                        onPressButtonCancel={() =>
                          this.setState({ isOpenModalLazadaStatus: false })
                        }
                        onPressButtonConfirm={() => lazTrackingUpdate()}
                        labelButtonConfirm={`ตกลง`}
                        labelButtonCancel={`กลับ`}
                        styleColorButtonConfirm="primary"
                        titleHeader={`ส่งสถานะให้ลาซาด้า`}
                        onHide={() => this.setState({ isOpenModalLazadaStatus: false })}
                      >
                        {this.state.mode === 'final' ? (
                          <div>
                            คุณต้องการส่งสถานะให้ลาซาด้า งาน{' '}
                            <b>{this.state.commodity ? this.state.commodity.name : ''}</b>{' '}
                            ใช่หรือไม่ ถ้าใช่ กด <b>"ตกลง"</b>
                          </div>
                        ) : (
                          <div>
                            คุณต้องการยกเลิกการส่งสินค้า งาน{' '}
                            <b>{this.state.commodity ? this.state.commodity.name : ''}</b>{' '}
                            ใช่หรือไม่ ถ้าใช่ กด <b>"ตกลง"</b>
                          </div>
                        )}
                      </ModalConfirm>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default SubShipmentTracking;
