import gql from 'graphql-tag';

export default gql`
mutation Update(
    $_id : ID!
    $exp: String
    $delete_fake: Int
){
  flashDealsUpdate(
        _id : $_id,
        exp: $exp,
        delete_fake: $delete_fake
    ){
      _id
    }
  }
`;