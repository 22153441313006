import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import Moment from 'react-moment';
import SubHeader3PL from './components/SubHeader3PL';
import Input from './components/Input';
import moment from 'moment';
import urijs from 'urijs';
import {
  Grid,
  Row,
  Col,
  Table,
  Button,
  FormControl,
  Label,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import ModalConfirm from 'views/Setting/components/ModalConfirm';
import DataDeniedModal from './components/DataDeniedModal';
import ConfirmStatusDeniedModal from './components/ConfirmStatusDeniedModal';
import ConfirmStatusModal from './components/ConfirmStatusModal';
import DataConfirmModal from './components/DataConfirmModal';
import { client } from '../../../../index';
import SubHeaderCOD from '../../components/SubHeaderCOD';
import CheckFinalStatusModal from './components/CheckFinalStatusModal';
import TripNumberList from './components/TripNumberList'
import AddSuffixShipment from './components/AddSuffixShipment'

//Query
import shipmentAgentViewQuery from './graphql/query/shipmentAgentView';
import accountInfoListQuery from './graphql/query/accountInfoList';

import {
  SubHeaderContainer, 
  StyledHeader, 
  CardContainer, 
  ColRight, 
  Line, 
  CenterVerticalSpan, 
  CustomCheckbox, 
  styleddefault, 
  styledprimary, 
  styledsuccess, 
  styleddanger,
  TableWrapper
} from './styled'

let subThirdPartyId = '';

class ThirdpartyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripId: '',
      agentDetail: '',
      agentDetailList: [],
      agentTotal: 0,
      transporterName: '',
      checkOnce: true,

      shipmentId: '',

      isConfirmStatusDeniedModal: false,
      isConfirmStatusModal: false,
      isDataCheckedModal: false,
      isDataDeniedModal: false,

      trackingId: '',
      user_setting_truck_license_plate: '',
      driverName: '',

      //Search
      shipment_trip_search: '',
      delivery_tracking_number_search: '',
      delivery_status_search: 0,
      final_status_search: 0,

      //check final
      isCheckFinalStatusModal: false,
      deliveryData: [],

      //checkedTracking
      checkedTrackingList: []
    };
  }

  componentDidMount = () => {
    this.getFirstShipment();
  };

  getIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).orderid;
    return id;
  };

  getaccountIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).accid;
    return id;
  };

  getFirstShipment = async () => {
    try {
        const { data } = await client.query({
          query: shipmentAgentViewQuery,
          fetchPolicy: 'network-only',
          variables: {
            shipment_prefix: 'GLZ',
            lazada_import_order_id: this.getIdFromUrl(),
            account_info_id: this.getaccountIdFromUrl(),
          },
        });

        // console.log(data)
        if (data) {
          subThirdPartyId = data.shipmentAgentView.shipment_sub_thirdparty_id;
        }

        if (data.shipmentAgentView.shipment.length > 0) {
          // console.log('newCheck ',data.shipmentAgentView.shipment[0]._id)
          this.setState({
            tripId: data.shipmentAgentView.shipment[0]._id,
            agentTotal: data.shipmentAgentView.shipment.length,
          });
        }
    } catch (error) {
      console.log(error);
    }
  };

  searchTransporter = async id => {
    if (id) {
      try {
        const { data: accountInfoListData } = await client.query({
          query: accountInfoListQuery,
          fetchPolicy: 'network-only',
          variables: {
            _id: id,
          },
        });

        if (accountInfoListData.accountInfoList.account_info.length > 0) {
          // console.log(accountInfoListData.accountInfoList.account_info[0].account_info_business_name)
          this.setState({
            transporterName:
              accountInfoListData.accountInfoList.account_info[0].account_info_business_name,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      this.setState({ transporterName: '-' });
    }
  };

  renderStatusButton = (status, done, total, agent_shipment_status) => {
    switch (status) {
      case 1:
        return (
          <Button
            disabled={done !== total}
            style={{
              backgroundColor: '#5DBA5B',
              opacity: done == total && 1,
              color: '#fff',
              display:
                agent_shipment_status == 9 ||
                agent_shipment_status == 1 ||
                agent_shipment_status == 2 ||
                agent_shipment_status == 3 ||
                agent_shipment_status == 7
                  ? 'none'
                  : 'flex',
            }}
            onClick={() => this.setState({ isDataCheckedModal: true })}
          >
            <i style={{ marginRight: '5px' }} class="far fa-check-circle"></i>ตรวจสอบข้อมูลแล้ว
          </Button>
        );
      case 2:
        return (
          <Button
            disabled={done !== total}
            style={{
              backgroundColor: '#D91010',
              color: '#fff',
              display:
                agent_shipment_status == 9 ||
                agent_shipment_status == 1 ||
                agent_shipment_status == 2 ||
                agent_shipment_status == 3
                  ? 'none'
                  : 'flex',
            }}
            onClick={() => this.setState({ isDataDeniedModal: true })}
          >
            <i style={{ marginRight: '5px' }} class="far fa-times-circle"></i>ปฏิเสธข้อมูล
          </Button>
        );
    }
  };

  renderReportStatus(status) {
    /*
           1=create shipment Waiting to start work,2=shipment In progress,3=pending,4=await admin check,
           5=pending cod,6=await cod check,7=completed,8=cod not pass,9=admin reject  proof
        */
    switch (parseInt(status)) {
      case 1:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 2:
        return (
          <Label
            bsStyle="info"
            style={{
              backgroundColor: 'transparent',
              color: '#337ab7',
              border: '1px solid #337ab7',
            }}
          >
            กำลังดำเนินงาน...
          </Label>
        );
      case 3:
        return <Label style={styleddefault}>รอส่งสถานะ</Label>;
      case 4:
        return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
      case 5:
        return <Label style={styledsuccess}>รอโอน COD</Label>;
      case 6:
        return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
      case 7:
        return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
      case 8:
        return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
      case 9:
        return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
      default:
        return <Label>{status}</Label>;
    }
  }

  renderDeliveryStatus = status => {
    //1 wait, 2 inprogress, 3 complete = delivered , 4 wait return = fail  5 return complete 6 no return 7 cancel pick up = fail delivery
    switch (status) {
      case 2:
        return <span style={{ color: '#318DE8', fontWeight: 'bold' }}>Inprogress</span>;
      case 3:
        return <span style={{ color: '#5DBA5B', fontWeight: 'bold' }}>Delivered</span>;
      case 4:
        return <span style={{ color: '#FF0000', fontWeight: 'bold' }}>Fail Delivery</span>;
      case 5:
        return <span style={{ color: '#FF0000', fontWeight: 'bold' }}>Fail Delivery</span>;
      case 6:
        return <span style={{ color: '#FF0000', fontWeight: 'bold' }}>Fail Delivery</span>;
      case 9:
        return <span style={{ color: '#FF0000', fontWeight: 'bold' }}>Fail Delivery</span>;
      case 7:
        return <span style={{ fontWeight: 'bold' }}>No Pickup</span>;
      default:
        return <span style={{ fontWeight: 'bold' }}>Pending</span>;
    }
  };

  renderIconStatus = (status, trip) => {
    if(trip){
      switch (status) {
        case 1:
          return <span style={{ color: '#000', marginRight: '5px', display: 'flex', alignItems: 'center' }}><i style={{ marginRight: '5px' }} class="far fa-check-circle"></i>{trip}</span>;
        case 2:
          return <span style={{ color: '#5DBA5B', marginRight: '5px', display: 'flex', alignItems: 'center'}} ><i style={{ marginRight: '5px' }} class="far fa-check-circle"></i> {trip}</span>;
        case 3:
          return <span style={{ color: '#FF0000', marginRight: '5px', display: 'flex', alignItems: 'center'}}><i style={{ marginRight: '5px' }} class="far fa-check-circle"></i> {trip}</span>;
        default:
          return <span style={{ color: '#000', marginRight: '5px', display: 'flex', alignItems: 'center'}}><i style={{ marginRight: '5px' }} class="far fa-check-circle"></i> {trip}</span>;
      }
    }else{
      switch (status) {
        case 1:
          return <i style={{ color: '#E3E3E3', marginRight: '5px' }} class="far fa-check-circle"></i>;
        case 2:
          return <i style={{ color: '#5DBA5B', marginRight: '5px' }} class="far fa-check-circle"></i>;
        case 3:
          return <i style={{ color: '#FF0000', marginRight: '5px' }} class="far fa-times-circle"></i>;
        default:
          return <i style={{ color: '#E3E3E3', marginRight: '5px' }} class="far fa-check-circle"></i>;
      }
    }
  };

  getAgentDetail = async id => {
    this.setState(prevState => {
      if (prevState.tripId !== id) {
        return {
          tripId: id,
          //reset data
          checkOnce: true,
          agentDetailLength: 0,
          checkedTrackingList: []
        };
      }
    });
  };

  renderCheckTrack = (id, tracking) => {
    const CopyCheckedTrackingList = [...this.state.checkedTrackingList]
    let newCheckedTrackingList = []
    if(CopyCheckedTrackingList.filter(tk => tk._id === tracking._id).length > 0){
      newCheckedTrackingList = CopyCheckedTrackingList.filter(tk => tk._id !== tracking._id)
    }else newCheckedTrackingList = [...CopyCheckedTrackingList, tracking]
    
    this.setState({
      checkedTrackingList: newCheckedTrackingList
    });
  };

  confirmDeliveryStatus = shipmentId => {
    if (this.state.checkedTrackingList.length > 0) {
      this.setState({
        isConfirmStatusDeniedModal: true,
        shipmentId: shipmentId,
      });
    } else {
      this.setState({
        isConfirmStatusModal: true,
        shipmentId: shipmentId,
      });
    }
  };

  formatMoney = number => {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    });
  };

  renderRejectStatus = status => {
    switch (status) {
      case 1:
        return (
          <i style={{ color: 'red', marginLeft: '5px' }} class="far fa-exclamation-triangle"></i>
        );
      default:
        return null;
    }
  };

  renderLogStatus = log => {
    if (log.length > 0) {
      return (
        <i style={{ color: '#318DE8', marginLeft: '5px' }} class="far fa-exclamation-circle"></i>
      );
    }
    return null;
  };

  renderIconArrow = (shipment) => {
    switch (shipment.check_status) {
      case 1:
        return <i style={{ color: '#000', marginRight: '5px' }} className={`fal fa-chevron-${shipment._id == this.state.tripId ? 'right' : 'left'}`}></i>;
      case 2:
        return <i style={{ color: '#5DBA5B', marginRight: '5px' }} className={`fal fa-chevron-${shipment._id == this.state.tripId ? 'right' : 'left'}`}></i>;
      case 3:
        return <i style={{ color: '#FF0000', marginRight: '5px' }} className={`fal fa-chevron-${shipment._id == this.state.tripId ? 'right' : 'left'}`}></i>;
      default:
        return <i style={{ color: '#000', marginRight: '5px' }} className={`fal fa-chevron-${shipment._id == this.state.tripId ? 'right' : 'left'}`}></i>;
    }
};


  clearChecked = () => {
    this.setState({
      checkedTrackingList: []
    })
  }

  checkBoxValue = (data) => {
    return (this.state.checkedTrackingList.filter(tk => tk._id === data._id).length > 0)
  }

  render() {
    return (
      <Query
        query={shipmentAgentViewQuery}
        variables={{
          shipment_prefix: 'GLZ',
          lazada_import_order_id: this.getIdFromUrl(),
          shipment_trip: this.state.shipment_trip_search,
          account_info_id: this.getaccountIdFromUrl(),
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, error, refetch: refetchShipmentAgentView }) => {
          let shipmentAgentView = '';
          let doneTrip = '';
          let header = '';
          let codAvailable = false;

          if (data && data.shipmentAgentView) {
            shipmentAgentView = data.shipmentAgentView;
            doneTrip =
              shipmentAgentView.shipment &&
              shipmentAgentView.shipment.filter(
                data => data.check_status !== null && data.check_status !== 1
              ).length;
            header = (
              <div>
                {moment(shipmentAgentView.plan_date).format('DD/MM/YYYY - HH:mm')}
                <br />
                {' รอบ ' + shipmentAgentView.trip_name}
              </div>
            );
            codAvailable = data.shipmentAgentView.total_accept_cod
              ? data.shipmentAgentView.total_accept_cod > 0
              : false;
          }
          return (
            <div>
              <SubHeaderCOD
                headerName={header}
                pathname={this.props.location.pathname}
                codAvailable={codAvailable}
                agent_status={
                  data && data.shipmentAgentView && data.shipmentAgentView.agent_shipment_status
                }
              />
              <Grid fluid>
                <Row>
                  <Col md={12}>
                    <div>
                      <StyledHeader>
                        <CenterVerticalSpan>
                          <strong style={{ fontSize: '22px' }}>ตรวจสอบงานขนส่ง</strong>
                          <Line left={105} />
                        </CenterVerticalSpan>
                        <CenterVerticalSpan>
                          {this.state.agentTotal ? (doneTrip === this.state.agentTotal ? this.renderIconStatus(2) : this.renderIconStatus(1)) : this.renderIconStatus(1)}
                          งานที่ตรวจแล้ว:&nbsp;
                          <b>
                            {this.state.agentTotal ? `${doneTrip}/${this.state.agentTotal}` : 'รอดำเนินการ'}
                          </b>
                        </CenterVerticalSpan>
                        <Line/>
                        <div style={{ overflow: 'auto', height: 52 }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                            }}
                          >
                            <CenterVerticalSpan style={{ marginTop: 15 }}>
                              สถานะรายงาน:&nbsp;&nbsp;{' '}
                              <b>
                                {shipmentAgentView
                                  ? this.renderReportStatus(
                                      shipmentAgentView.agent_shipment_status
                                    )
                                  : null}
                              </b>
                            </CenterVerticalSpan>
                            {shipmentAgentView.agent_shipment_status == 4 ||
                            shipmentAgentView.agent_shipment_status == 9 ? (
                              shipmentAgentView.admin_reject_detail ? (
                                <div style={{ color: 'red', wordBreak: 'break-all' }}>
                                  {shipmentAgentView.admin_reject_detail}
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                        <Line/>
                        <CenterVerticalSpan>
                          นายหน้า:&nbsp;{' '}
                          <b>
                            {this.state.transporterName ? this.state.transporterName : '-'}
                          </b>
                        </CenterVerticalSpan>
                        <div></div>
                        {
                          shipmentAgentView && shipmentAgentView.shipment && 
                          shipmentAgentView.shipment.filter(ship => ship.check_status === 3).length > 0 ? 
                          this.renderStatusButton(2,doneTrip,this.state.agentTotal,shipmentAgentView.agent_shipment_status) 
                          : 
                          this.renderStatusButton(1,doneTrip,this.state.agentTotal,shipmentAgentView.agent_shipment_status)
                        }
                      </StyledHeader>
                      <CardContainer>
                        <div style={{ display: 'flex', height: '100%' }}>
                        <TripNumberList 
                          getAgentDetail={this.getAgentDetail} 
                          shipmentAgentView={shipmentAgentView} 
                          renderIconStatus={this.renderIconStatus} 
                          renderIconArrow={this.renderIconArrow} 
                          tripId={this.state.tripId} 
                          setShipmentSearch={value => this.setState({ shipment_trip_search: value })}
                        />
                          {
                            <Query
                              query={shipmentAgentViewQuery}
                              variables={{
                                shipment_prefix: 'GLZ',
                                lazada_import_order_id: this.getIdFromUrl(),
                                _id: this.state.tripId,
                                delivery_tracking_number_search: this.state.delivery_tracking_number_search,
                                temp_delivery_status_search: this.state.delivery_status_search,
                                final_status_search: this.state.final_status_search,
                                account_info_id: this.getaccountIdFromUrl(),
                              }}
                              fetchPolicy="cache-and-network"
                            >
                              {({
                                data: dataShipmentDetail,
                                loading: loadingShipmentDetail,
                                error: errorShipmentDetail,
                                refetch: refetchShipmentDetail,
                              }) => {
                                let dataDetail = '';
                                let shipment_trip = '';
                                let shipmentId = '';

                                if (errorShipmentDetail) {
                                  return <div>Error</div>;
                                }
                                if (
                                  dataShipmentDetail &&
                                  dataShipmentDetail.shipmentAgentView &&
                                  dataShipmentDetail.shipmentAgentView.shipment &&
                                  dataShipmentDetail.shipmentAgentView.shipment.length > 0
                                ) {
                                  dataDetail = dataShipmentDetail.shipmentAgentView.shipment[0];
                                  shipment_trip = dataDetail.shipment_trip;
                                  shipmentId = dataDetail._id;

                                  //tracking
                                  if (
                                    dataDetail.tracking &&
                                    dataDetail.tracking.tracking_detail_lazada
                                  ) {
                                    this.setState(prevstate => {
                                      if (
                                        prevstate.agentDetailList !==
                                        dataDetail.tracking.tracking_detail_lazada.delivery
                                      ) {

                                        if (this.state.checkOnce) {
                                          const newDeliveryCheck = dataDetail.tracking.tracking_detail_lazada.delivery.map(
                                            dataDelivery => {
                                              const newDataDelivery = { ...dataDelivery };
                                              newDataDelivery.check = false;
                                              return newDataDelivery;
                                            }
                                          );

                                          // console.log('newDeliveryCheck', newDeliveryCheck)
                                          this.searchTransporter(dataDetail.transporter);
                                          this.setState({
                                            checkOnce: false,
                                          });
                                        }

                                        // console.log('check enter new trip')
                                        return {
                                          agentDetailList: dataDetail.tracking.tracking_detail_lazada.delivery,
                                          user_setting_truck_license_plate: dataDetail.user_setting_truck_license_plate,
                                          driverName: dataDetail.driver_name ? `${dataDetail.driver_name}, ${dataDetail.driver_phone_code} ${dataDetail.driver_phone_number}` : '-',
                                          trackingId: dataDetail.tracking._id,
                                        };
                                      }
                                    });
                                  }
                                }
                                return (
                                    <ColRight>
                                      <SubHeaderContainer>
                                        <div className="sub-header-left">
                                          <Link
                                            to={`/ka-shipment-detail?id=${shipmentId}`}
                                            style={{ float: 'left', padding: '5px 5px 5px 9px' }}
                                            className="btn "
                                          >
                                            <i style={{ color: '#000' }} className="fas fa-eye"></i>
                                          </Link>
                                          <CenterVerticalSpan style={{ marginLeft: '20px' }}>{this.renderIconStatus(dataDetail.check_status)}<b>{shipment_trip}</b></CenterVerticalSpan>
                                          <Line />
                                          <CenterVerticalSpan>พนักงานขับรถ : &nbsp;<b>{this.state.driverName}</b></CenterVerticalSpan>
                                          <CenterVerticalSpan style={{ marginLeft: '40px' }}>ทะเบียนรถ : &nbsp;<b>{this.state.user_setting_truck_license_plate ? this.state.user_setting_truck_license_plate : '-'}</b></CenterVerticalSpan>
                                        </div>
                                        <div className="sub-header-right">
                                          <Button
                                            style={{
                                              display:
                                                shipmentAgentView.agent_shipment_status == 9 ||
                                                shipmentAgentView.agent_shipment_status == 1 ||
                                                shipmentAgentView.agent_shipment_status == 2 ||
                                                shipmentAgentView.agent_shipment_status == 3
                                                  ? 'none'
                                                  : 'flex',
                                              alignItems: 'center',
                                            }}
                                            onClick={() => this.confirmDeliveryStatus(shipmentId)}
                                          >
                                            <i
                                              style={{
                                                color: '#5DBA5B',
                                                marginRight: '5px',
                                                fontSize: '18px',
                                              }}
                                              class="far fa-save"
                                            ></i>
                                            ยืนยันสถานะขนส่ง
                                          </Button>
                                        </div>
                                      </SubHeaderContainer>
                                      <TableWrapper className="table-vertical">
                                        <Table style={{ marginBottom: '0px' }} striped hover>
                                          <thead>
                                            <tr style={{ height: '50px' }}>
                                              <td style={{ minWidth: '50px' }}></td>
                                              <td style={{ minWidth: '100px', fontSize: '18px' }}>
                                                <b>Tracking Number</b>
                                              </td>
                                              <td style={{ minWidth: '100px', fontSize: '18px' }}>
                                                <b>สถานะ</b> (Driver)
                                              </td>
                                              <td style={{ minWidth: '100px', fontSize: '18px' }}>
                                                <b>สถานะ</b> (Agent)
                                              </td>
                                              <td style={{ minWidth: '100px', fontSize: '18px' }}>
                                                <b>COD</b> (บาท)
                                              </td>
                                              <td style={{ minWidth: '100px', fontSize: '18px' }}>
                                                <b>บันทึกช่วยจำ</b>
                                              </td>
                                            </tr>
                                            <tr style={{ height: '50px' }}>
                                              <td></td>
                                              <td>
                                                <Input
                                                  placeholder="ค้นหา"
                                                  onChange={e =>
                                                    this.setState({
                                                      delivery_tracking_number_search:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="select"
                                                  defaultValue={0}
                                                  value={this.state.delivery_status_search}
                                                  onChange={event =>
                                                    this.setState({
                                                      delivery_status_search: event.target.value,
                                                    })
                                                  }
                                                  name="Driver_Delivery_status"
                                                >
                                                  <option value={0}>ทั้งหมด</option>
                                                  <option value={1}>Delivered</option>
                                                  <option value={2}>Fail Delivery</option>
                                                  <option value={3}>No Pickup</option>
                                                </FormControl>
                                              </td>
                                              <td>
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="select"
                                                  defaultValue={0}
                                                  value={this.state.final_status_search}
                                                  onChange={event =>
                                                    this.setState({
                                                      final_status_search: event.target.value,
                                                    })
                                                  }
                                                  name="Agent_Delivery_status"
                                                >
                                                  <option value={0}>ทั้งหมด</option>
                                                  <option value={1}>Delivered</option>
                                                  <option value={2}>Fail Delivery</option>
                                                  <option value={3}>No Pickup</option>
                                                </FormControl>
                                              </td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </thead>
                                          
                                          {
                                            loadingShipmentDetail ?
                                            <tbody>
                                              <tr>
                                                <td></td>
                                                <td> Loading</td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                              </tr>
                                            </tbody>
                                            :
                                            (
                                              this.state.agentDetailList.length == 0 ? (
                                                <tbody>
                                                  <tr>
                                                    <td></td>
                                                    <td> ไม่มีรายการ</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  {this.state.agentDetailList.map((data, index) => (
                                                    <tr key={data._id}>
                                                      <td>
                                                        <CustomCheckbox
                                                          value={this.checkBoxValue(data)}
                                                          checked={this.checkBoxValue(data)}
                                                          onClick={() => this.renderCheckTrack(data._id, data)}
                                                          style={{
                                                            display:
                                                              shipmentAgentView.agent_shipment_status == 9 ||
                                                              shipmentAgentView.agent_shipment_status == 1 ||
                                                              shipmentAgentView.agent_shipment_status == 2 ||
                                                              shipmentAgentView.agent_shipment_status == 3 
                                                              ? 'none' : 'block',
                                                          }}
                                                        >
                                                          <span className="checkmark"></span>
                                                        </CustomCheckbox>
                                                      </td>
                                                      <td>
                                                        {' '}
                                                        <b>{AddSuffixShipment(data.account_info)}</b>
                                                        {data.commodity[0].name}
                                                        {this.renderRejectStatus(data.admin_reject_status)}
                                                        {this.renderLogStatus(data.log)}
                                                      </td>
                                                      <td>
                                                        {this.renderDeliveryStatus(data.temp_delivery_status)}
                                                      </td>
                                                      <td>
                                                        {this.renderDeliveryStatus(data.final_status)}
                                                        {
                                                          <Button
                                                            onClick={() =>
                                                              this.setState({
                                                                isCheckFinalStatusModal: true,
                                                                // trackingId: trackingId,
                                                                deliveryData: data,
                                                              })
                                                            }
                                                            style={{
                                                              marginLeft: '10px',
                                                              display: shipmentAgentView.agent_shipment_status == 2 || shipmentAgentView.agent_shipment_status == 1 ? 'none' : 'inline',
                                                            }}
                                                          >
                                                            ตรวจสอบ
                                                          </Button>
                                                        }
                                                      </td>
                                                      <td>{data.cod_price ? this.formatMoney(data.cod_price) : 0}</td>
                                                      <td>{data.remind ? data.remind : '-'}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              )
                                            )
                                          //     )
                                          }
                                        </Table>
                                      </TableWrapper>
                                    </ColRight>
                                );
                              }}
                            </Query>
                          }
                        </div>
                      </CardContainer>
                    </div>
                  </Col>
                </Row>
              </Grid>

              {
                //ตรวจสอบข้อมูลแล้ว
              }
              {this.state.isDataCheckedModal && (
                <DataConfirmModal
                  isDataCheckedModal={this.state.isDataCheckedModal}
                  closeModal={() => this.setState({ isDataCheckedModal: false })}
                  subThirdPartyId={subThirdPartyId}
                  agentDetailList={this.state.agentDetailList}
                  trackingId={this.state.trackingId}
                  codAvailable={codAvailable}
                />
              )}

              {
                //ยืนยันสถานะการขนส่ง ไม่มีปฏิเสธ
              }
              {this.state.isConfirmStatusModal && (
                <ConfirmStatusModal
                  isConfirmStatusModal={this.state.isConfirmStatusModal}
                  closeModal={() => this.setState({ isConfirmStatusModal: false })}
                  shipmentId={this.state.shipmentId}
                  agentDetailList={this.state.agentDetailList}
                  trackingId={this.state.trackingId}
                />
              )}

              {
                //ยืนยันสถานะการขนส่ง มีปฏิเสธ
              }
              {this.state.isConfirmStatusDeniedModal && (
                <ConfirmStatusDeniedModal
                  isConfirmStatusDeniedModal={this.state.isConfirmStatusDeniedModal}
                  closeModal={() => this.setState({ isConfirmStatusDeniedModal: false })}
                  selectedAgentList={this.state.checkedTrackingList}
                  agentDetailList={this.state.agentDetailList}
                  shipmentId={this.state.shipmentId}
                  trackingId={this.state.trackingId}
                  clearChecked={this.clearChecked}
                />
              )}

              {
                //ปฏิเสธข้อมูล
              }
              {this.state.isDataDeniedModal && (
                <DataDeniedModal
                  selectedAgentList={this.state.checkedTrackingList}
                  isDataDeniedModal={this.state.isDataDeniedModal}
                  closeModal={() => this.setState({ isDataDeniedModal: false })}
                  subThirdPartyId={subThirdPartyId}
                />
              )}

              {
                //check final status
              }
              {this.state.isCheckFinalStatusModal && (
                <CheckFinalStatusModal
                  openModal={this.state.isCheckFinalStatusModal}
                  closeModal={() => {
                    this.setState({
                      isCheckFinalStatusModal: false,
                      // trackingId: '',
                      deliveryData: [],
                    });
                  }}
                  trackingId={this.state.trackingId}
                  deliveryData={this.state.deliveryData}
                />
              )}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default ThirdpartyDetail;
