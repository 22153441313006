import gql from 'graphql-tag';

export default gql`
  query($shipment_id: ID!) {
    parcelCostByShipment(shipment_id: $shipment_id) {
      _id
      vendor {
        _id
      }
      jobId
      refNumber
      costModel
      jobType
      pickupDate
      deliveredDate
      serviceItems {
        _id
        datamaster_service_item_id
        service_category_name
        advance_type
        list_number
        list_name
        qty
        unit
        price
        discount
        vat
        wht
        total_before_discount
        total_price
        grand_total_price
      }
      totalCost
      remark
      reject_remark
      costing_number
      costing_status
      edit_request_status
      re_total_before_discount
      non_total_before_discount
      inc_total_before_discount
      re_total_discount
      totalCostAvg
      non_total_discount
      inc_total_discount
      re_total_after_discount
      non_total_after_discount
      inc_total_after_discount
      total_vat
      parcelTarget
      re_total
      non_total
      inc_total
      total_price
      all_wht {
        wht
        price
        total_wht
      }
      grand_total_price
      cancel_remark
      edit_remark
      employee {
        _id
      }
      approve_edit_request
      createdAt
      updatedAt
    }
  }
`;
