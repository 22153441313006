import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import { Grid, Row, Col, Table, Tooltip, NavDropdown, NavItem , Button, FormControl, Modal ,Label } from "react-bootstrap";
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Moment from 'react-moment';

import styled from 'styled-components';

import CardTable from "components/CardTable/CardTable.jsx";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import { DriverArray } from "variables/Variables.jsx";

import flashDealsListQuery from "./graphql/query/flashDealsList";
import flashDealsAddMutation from "./graphql/mutation/flashDealsAdd";
import flashDealsUpdateMutation from "./graphql/mutation/flashDealsUpdate";
import settingTruckListQuery from './graphql/query/settingTruckList';

import provinceData from 'config/province/changwats/th.json';
import provinceDataEn from 'config/province/changwats/en.json';

const tooltip = (
    <Tooltip id="tooltip">
        แก้ไข
  </Tooltip>
);
const tooltip1 = (
    <Tooltip id="tooltip">
        บล๊อค / ยกเลิกการบล็อค
  </Tooltip>
);

const TableVertiacal = styled.div`
    overflow: auto;
    max-height: 70vh;
    width: 100%;
`;

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;

const NavDropdownContainer = styled(NavDropdown)`
    list-style: none;
    float: left;
    margin-left: 5px;
    
    & a {
        & .caret {
            display:none;
        }

        & button.btn.btn-default{
            float: left;
            padding: 5px 8px;
        }
    }
    
    & .dropdown-menu{
        min-width: 80px;
    }
`;

const NavItemDropDownList = styled(NavItem)`

	& a {

		& i {
		}
	}
`;


class Driver extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total_page: 0,
            page_number: 1,
            order_by: null,
            order_type: null,
            showAdd: false,
            showEdit: false,
            limit_count:0, 
            price:0, 
            price_cost:0, 
            price_sale:0, 
            name_lang: "",
            pick_up_date: "",
            show_pick_up_date: "",
            start_date: "",
            show_start_date: "",
            _id: null,
            provincePickup:null,
            provinceDelivery:null,
            provincePickupEN:null,
            provinceDeliveryEN:null,
            setting_truck_type_id :null,
            setting_truck_id :null,
            location_start_lang:"",
            location_end_lang:"",
            provincePickupPosition:{
                lat :"",
                lng :""
            },
            provinceDeliveryPosition:{
                lat :"",
                lng :""
            }
        }
    }

    addFlashdeals() {
        this.setState({ 
            showAdd: true, 
            limit_count:0, 
            price:0, 
            price_cost:0, 
            price_sale:0, 
            name_lang: "",
            pick_up_date: "",
            show_pick_up_date: "",
            start_date: "",
            show_start_date: "",
            _id: null,
            provincePickup:null,
            provinceDelivery:null,
            provincePickupEN:null,
            provinceDeliveryEN:null,
            provincePickupPosition:{
                lat :"",
                lng :""
            },
            provinceDeliveryPosition:{
                lat :"",
                lng :""
            },
            setting_truck_type_id :null,
            setting_truck_id :null,
            update_mode: "delete",
            name_update: "คุณยืนยันที่จะลบรายการ ลดกระหน่ำ50% ใช่หรือไม่"
        });
    }

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }

    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    nextPage(page,total_page,refetch) {
        this.setState({ page_number: page+1,total_page });
        // console.log(this.state);
        refetch();
    }

    checkDisabled(){     
        if(this.state.limit_count == 0|| 
            this.state.price == 0|| 
            this.state.price_cost == 0||
            this.state.price_sale == 0|| 
            this.state.name_lang == ""||
            this.state.pick_up_date == ""||
            this.state.start_date == ""||
            this.state.provincePickup == null||
            this.state.provinceDelivery == null||
            this.state.setting_truck_type_id == null){
            return true
        }
        return false;
    }

    renderStatus(status){
        switch (status) {
            case 1:
                return <Label bsStyle="warning">รอดำเนินการ</Label>;
            case 2:
                return <Label bsStyle="success">ออนไลน์</Label>;
            case 3:
                return <Label bsStyle="default">สิ้นสุด</Label>;
            default:
                return '-';
        }
    }

    clickEdit(update_mode,name,_id){
        if(update_mode == "delete"){
            this.setState({ showEdit: true , _id , update_mode , name_update: `คุณยืนยันที่จะลบรายการ ${name} ใช่หรือไม่`});
        }
        else{
            this.setState({ showEdit: true , _id , update_mode , name_update: `คุณยืนยันที่จะสิ้นสุดรายการ ${name} ใช่หรือไม่`});
        }
    }

    handlePickupDateRangePicker(event, picker,mode) {
        const date = `${picker.startDate.format('YYYY-MM-DD HH:mm')} : ${picker.endDate.format('YYYY-MM-DD HH:mm')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY HH:mm')} - ${picker.endDate.format('DD/MM/YYYY HH:mm')}`;

        if(mode == "pickup"){
            this.setState({ pick_up_date: date , show_pick_up_date: show});
        }
        else{
            this.setState({ start_date: date , show_start_date: show});
        }
        
    }
  
    checkAddress(){
        if(this.state.setting_truck_type_id.value == "" || this.state.provincePickup.value == "" ||this.state.provinceDelivery.value == "" ){
        return false;
        }
        return true;
    }

    renderVariablesUpdate(){
        if(this.state.update_mode == "delete"){
            return {
                _id: this.state._id,
                delete_fake: 1
            };
        }
        return {
            _id: this.state._id,
            exp: moment().format('YYYY-MM-DD HH:mm')
        };
    }

    handelchange(event,mode){
        if(mode == "Delivery"){
            this.setState({ 
                provinceDelivery: event.target[event.target.selectedIndex].text , 
                provinceDeliveryEN: event.target.value,
                provinceDeliveryPosition:{
                    lat : event.target[event.target.selectedIndex].dataset.lat,
                    lng : event.target[event.target.selectedIndex].dataset.lng
                } });
        }
        else if(mode == "truck"){
            this.setState({ 
                setting_truck_id: event.target[event.target.selectedIndex].dataset.truckid ,
                setting_truck_type_id: event.target.value});
        }else{
            this.setState({ 
                provincePickup: event.target[event.target.selectedIndex].text , 
                provincePickupEN: event.target.value ,
                provincePickupPosition:{
                    lat : event.target[event.target.selectedIndex].dataset.lat,
                    lng : event.target[event.target.selectedIndex].dataset.lng
                }
            });
        }
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Query
                            query={flashDealsListQuery}
                            variables={{
                                page_number: this.state.page_number,
                                order_by: this.state.order_by,
                                order_type: this.state.order_type,
                                location_start_lang: this.state.location_start_lang,
                                location_end_lang: this.state.location_end_lang
                            }}
                        >
                            {
                                ({ loading, error, data, refetch }) => {
                                    var itemLength = 'loading';
                                        const itemIndicatorSize = (this.state.page_number - 1)*30;
                                        if(data.flashDealsList){
                                            if(data.flashDealsList.flash_deals.length > 0){
                                                itemLength = data.flashDealsList.flash_deals.length;
                                            }
                                        }

                                    return (
                                        <Col md={12}>
                                            <CardTable
                                                title={`ตารางรายการ FLASH  DEALS`}
                                                category="ข้อมูลงาน FLASH  DEALS"
                                                ctTableFullWidth
                                                ctTableResponsive
                                                rightButton={
                                                    <div style={{ float: "right", marginLeft: "15px" }}>
                                                        <Button  bsStyle="primary" onClick={() => this.addFlashdeals()}><i className="fal fa-users-medical"></i> เพิ่มรายการ </Button>
                                                    </div>
                                                }
                                                content={
                                                    <div>
                                                        <PageWrapper style={{ textAlign: "right" }}>
                                                            <div className="text">{this.state.page_number === 1 ? `1 - ${itemLength}` : `${itemIndicatorSize + 1} - ${itemLength + itemIndicatorSize}`} หน้า :</div>
                                                                <FormControl
                                                                    type="number"
                                                                    min={1}
                                                                    value={this.state.page_number}
                                                                    onChange={(event) => 
                                                                    setInterval(this.nextPage(Number(event.target.value) -1, loading || error ? '0' : data.flashDealsList.total_page,refetch), 1000)
                                                                    }
                                                                    name="page_number"
                                                                />
                                                            <div className="text"> {loading || error ? '' : `จาก ${data.flashDealsList.total_page}`}</div>
                                                        </PageWrapper>
                                                        <div className="table-vertical">
                                                            <Table striped hover style={{marginBottom:"55px"}}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>เครื่องมือ</th>
                                                                        <th style={{ width: "200px",minWidth:"200px" }}>ชื่อ</th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>จำนวนสิทธิ์ผู้ใช้</th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>สถานะ</th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>ประเภทรถ</th>
                                                                        <th style={{ width: "150px",minWidth:"150px" }}>ต้นทาง</th>
                                                                        <th style={{ width: "150px",minWidth:"150px" }}>ปลายทาง</th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>ราคาต้นทุน</th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>ราคาขาย</th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>ราคาลด</th>
                                                                        <th style={{ width: "200px",minWidth:"200px" }}>วันที่เริ่ม</th>
                                                                        <th style={{ width: "200px",minWidth:"200px" }}>วันที่สิ้นสุด</th>
                                                                        <th style={{ width: "200px",minWidth:"200px" }}>วันที่รับของ</th>
                                                                    </tr>
                                                                    <tr className="tableFilter">
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th>
                                                                            <FormControl
                                                                                type="text"
                                                                                value={this.state.location_start_lang}
                                                                                onChange={(event) => this.setState({ location_start_lang: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                placeholder="ค้นหาต้นทาง"
                                                                            name="location_start_lang"
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <FormControl
                                                                                type="text"
                                                                                value={this.state.location_end_lang}
                                                                                onChange={(event) => this.setState({ location_end_lang: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                placeholder="ค้นหาปลายทาง"
                                                                                name="location_end_lang"
                                                                            />
                                                                        </th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(loading || error) ?
                                                                        (   <tr>
                                                                                <td colSpan="10" className="textCenter">{(loading) ? "Loading..." : error.message}</td>
                                                                            </tr>
                                                                        ) :
                                                                        data.flashDealsList.flash_deals.map((prop, key) => (
                                                                            <tr key={key} data-id={prop._id}>
                                                                                <td style={{ width: "50px" }}>
                                                                                    <NavDropdownContainer title={
                                                                                        <Button  >
                                                                                            เครื่องมือ <i className="fal fa-angle-down"></i>
                                                                                        </Button>
                                                                                    }>
                                                                                        <NavItemDropDownList onClick={() => this.clickEdit("timeout",prop.name_lang.th,prop._id)} > สิ้นสุด </NavItemDropDownList>
                                                                                        <NavItemDropDownList onClick={() => this.clickEdit("delete",prop.name_lang.th,prop._id)} > ลบ </NavItemDropDownList>
                                                                                    </NavDropdownContainer>
                                                                                </td>
                                                                                <td>{prop.name_lang.th}</td>
                                                                                <td>{`${prop.count_used} / ${prop.limit_count}`}</td>
                                                                                <td style={{ width: "130px" }}>
                                                                                    {this.renderStatus(prop.status)}
                                                                                </td>
                                                                                <td>{prop.setting_truck_name.th}{prop.truck_type_name.th}</td>
                                                                                <td>{prop.location_start_lang.th}</td>
                                                                                <td>{prop.location_end_lang.th}</td>
                                                                                <td>{prop.price_cost.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                <td>{prop.price_sale.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                <td>{prop.discount.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                <td><Moment format="DD/MM/YYYY HH:mm">{prop.start}</Moment></td>
                                                                                <td><Moment format="DD/MM/YYYY HH:mm">{prop.exp}</Moment></td>
                                                                                <td><Moment format="DD/MM/YYYY HH:mm">{prop.pickup_start}</Moment> - <Moment format="DD/MM/YYYY HH:mm">{prop.pickup_end}</Moment></td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                }

                                            />

                                            <Mutation
                                                mutation={flashDealsAddMutation}
                                                variables={{
                                                    name_lang: {
                                                        th : this.state.name_lang,
                                                        en :this.state.name_lang
                                                    },
                                                    limit_count: this.state.limit_count,
                                                    setting_truck_id: this.state.setting_truck_id,
                                                    setting_truck_type_id: this.state.setting_truck_type_id,
                                                    location_start_lang: {
                                                        th : this.state.provincePickup,
                                                        en :this.state.provincePickupEN
                                                    },
                                                    location_end_lang: {
                                                        th : this.state.provinceDelivery,
                                                        en : this.state.provinceDeliveryEN
                                                    },
                                                    position_end:{
                                                        lat : this.state.provinceDeliveryPosition.lat,
                                                        lng : this.state.provinceDeliveryPosition.lng
                                                    },
                                                    position_start:{
                                                        lat : this.state.provincePickupPosition.lat,
                                                        lng : this.state.provincePickupPosition.lng
                                                    },
                                                    start: this.state.start_date.split(" : ")[0],
                                                    exp: this.state.start_date.split(" : ")[1],
                                                    pickup_start: this.state.pick_up_date.split(" : ")[0],
                                                    pickup_end: this.state.pick_up_date.split(" : ")[1],
                                                    price: Number(this.state.price),
                                                    price_cost: Number(this.state.price_cost),
                                                    price_sale: Number(this.state.price_sale),
                                                }}
                                                update={() => {
                                                    this.setState({ showAdd: false }, () => { refetch() })
                                                }}
                                            >
                                                {flashDealsAdd => (
                                                    <Modal show={this.state.showAdd} backdrop='static' onHide={() => this.setState({showAdd : false})}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>FLASH  DEALS <br/> <small>กรุณากรอกรายละเอียดข้อมูลให้ถูกต้อง</small></Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Row style={{ marginBottom: "15px"}}>
                                                                <Col md={6}> 
                                                                    <label>ชื่อที่แสดง FLASH  DEALS ( 20 ตัวอักษร ) <span>*</span></label>
                                                                    <FormControl
                                                                        type="text"
                                                                        value={this.state.name_lang}
                                                                        maxLength={20}
                                                                        placeholder="ชื่อที่แสดง FLASH DEALS ( 20 ตัวอักษร )"
                                                                        onChange={(e) => this.setState({ name_lang: e.target.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginBottom: "15px"}}>
                                                                <Col md={6}> 
                                                                    <label>ช่วงวัน-เวลา ที่รับของ<span>*</span></label>
                                                                    <DateRangePicker 
                                                                        onApply={(event,picker) => this.handlePickupDateRangePicker(event,picker,"pickup")} 
                                                                        onCancel={() => this.setState({pick_up_date : "",show_pick_up_date : ""},() => { refetch() })} 
                                                                        containerStyles={{display: "block"}}
                                                                        ช่วงวัน-เวลา ที่รับของ='top'
                                                                        format='DD/MM/YYYY HH:mm'
                                                                        timePicker
                                                                        timePicker24Hour
                                                                    >
                                                                        <FormControl
                                                                            type="text"
                                                                            value={this.state.show_pick_up_date}
                                                                            placeholder="ค้นหาสร้าง"
                                                                            name="pick_up_date"
                                                                        />
                                                                    </DateRangePicker>
                                                                </Col>
                                                                <Col md={6}> 
                                                                    <label>ช่วงวัน-เวลา แสดง FLASH  DEALS <span>*</span></label>
                                                                    <DateRangePicker 
                                                                        onApply={(event,picker) => this.handlePickupDateRangePicker(event,picker,"startDate")} 
                                                                        onCancel={() => this.setState({start_date : "",show_start_date : ""},() => { refetch() })} 
                                                                        containerStyles={{display: "block"}}
                                                                        opens='right'
                                                                        format='DD/MM/YYYY HH:mm'
                                                                        timePicker
                                                                        timePicker24Hour
                                                                    >
                                                                        <FormControl
                                                                            type="text"
                                                                            value={this.state.show_start_date}
                                                                            placeholder="ค้นหาสร้าง"
                                                                            name="start_date"
                                                                        />
                                                                    </DateRangePicker>
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginBottom: "15px"}}>
                                                                <Col md={6}> 
                                                                    <label>เลือกจังหวัด - ต้นทาง <span>*</span></label>
                                                                    <FormControl
                                                                        componentClass="select"
                                                                        placeholder="select"
                                                                        defaultValue={this.state.provincePickupEN}
                                                                        onChange={(event) => this.handelchange(event,"Pickup")}
                                                                        name="provincePickup">
                                                                        <option value=""></option>
                                                                        {
                                                                            provinceData.th.changwats.map((item, index) => (
                                                                                <option key={index} value={provinceDataEn.en.changwats[index].name} data-lng={item.longitude}  data-lat={item.latitude}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                        }
                                                                    </FormControl> 
                                                                </Col>
                                                                <Col md={6}> 
                                                                    <label>เลือกจังหวัด - ปลายทาง <span>*</span></label>
                                                                    <FormControl
                                                                        componentClass="select"
                                                                        placeholder="select"
                                                                        defaultValue={this.state.provinceDeliveryEN}
                                                                        onChange={(event) => this.handelchange(event,"Delivery")}
                                                                        name="provinceDelivery">
                                                                        <option value=""></option>
                                                                        {
                                                                            provinceData.th.changwats.map((item, index) => (
                                                                                <option key={index} value={provinceDataEn.en.changwats[index].name} data-lng={item.longitude}  data-lat={item.latitude}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                        }
                                                                    </FormControl> 
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginBottom: "15px"}}>
                                                                <Col md={6}> 
                                                                    <label>ประเภทการขนส่ง <span>*</span></label>
                                                                    <Query
                                                                            query={settingTruckListQuery}
                                                                            variables={{
                                                                                shipmentType : 1
                                                                            }}
                                                                            fetchPolicy="network-only"
                                                                        >
                                                                        {({ loading, error, data, refetch }) => {
                                                                            return (
                                                                                <FormControl
                                                                                    componentClass="select"
                                                                                    placeholder="select"
                                                                                    defaultValue={this.state.setting_truck_type_id}
                                                                                    onChange={(event) => this.handelchange(event,"truck")}
                                                                                    name="setting_truck_type_id">
                                                                                    <option value=""></option>
                                                                                    {
                                                                                        (!loading && !error) && 
                                                                                        data.settingTruckList.map((truck, index) => (
                                                                                            <optgroup key={index} label={`รถ ${truck.setting_truck_name.th}`}>
                                                                                                { truck.setting_truck_type.filter(item => item.truck_type_enable).map((item, key, arr) => (
                                                                                                    <option key={key} value={item._id} data-truckId={truck._id}>{
                                                                                                        `${
                                                                                                            truck.setting_truck_name.th
                                                                                                        } - ${
                                                                                                            item.truck_type_name.th
                                                                                                        }` || undefined
                                                                                                    }</option>
                                                                                                ))
                                                                                                }
                                                                                            </optgroup>
                                                                                        ))
                                                                                    }
                                                                                    }
                                                                                </FormControl> 
                                                                            )}
                                                                        }
                                                                        </Query>
                                                                </Col>
                                                                <Col md={6}> 
                                                                    <label>ราคาต้นทุน <span>*</span></label>
                                                                    <FormControl
                                                                        type="number"
                                                                        value={this.state.price_cost}
                                                                        placeholder="ราคาต้นทุน"
                                                                        onChange={(e) => this.setState({ price_cost: e.target.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginBottom: "15px"}}>
                                                                <Col md={6}> 
                                                                    <label>ราคาเดิม <span>*</span></label>
                                                                    <FormControl
                                                                        type="number"
                                                                        value={this.state.price}
                                                                        placeholder="ราคาเดิม"
                                                                        onChange={(e) => this.setState({ price: e.target.value })}
                                                                    />
                                                                </Col>
                                                                <Col md={6}> 
                                                                    <label>ราคาขาย <span>*</span></label>
                                                                    <FormControl
                                                                        type="number"
                                                                        value={this.state.price_sale}
                                                                        placeholder="ราคาขาย"
                                                                        onChange={(e) => this.setState({ price_sale: e.target.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row style={{ marginBottom: "15px"}}>
                                                                <Col md={6}> 
                                                                    <label>จำนวนสิทธิ์ <span>*</span></label>
                                                                    <FormControl
                                                                        type="number"
                                                                        value={this.state.limit_count}
                                                                        placeholder="จำนวนสิทธิ์*"
                                                                        onChange={(e) => this.setState({ limit_count: e.target.value })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                                    
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button bsStyle="primary" disabled={this.checkDisabled()} onClick={() => flashDealsAdd()}>บันทึก</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                )

                                                }
                                            </Mutation>

                                            <Mutation
                                                mutation={flashDealsUpdateMutation}
                                                variables={this.renderVariablesUpdate()}
                                                update={() => {
                                                    this.setState({ showEdit: false }, () => { refetch() })
                                                }}
                                            >
                                                {flashDealsUpdate => (
                                                    <Modal show={this.state.showEdit} backdrop='static' onHide={() => this.setState({showEdit : false})}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>{this.state.name_update}</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Footer>
                                                            <Button onClick={() => this.setState({showEdit : false})}>ปิด</Button>
                                                            <Button bsStyle="primary" onClick={() => flashDealsUpdate()}>บันทึก</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                )

                                                }
                                            </Mutation>

                                            <div style={{ textAlign: "right" }}>
                                                {(loading) ? null :
                                                    (<ReactPaginate pageCount={data.flashDealsList.total_page} forcePage={this.state.page_number -1} containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected,data.flashDealsList.total_page, refetch); }} />)
                                                }
                                            </div>
                                        </Col>
                                    )
                                }
                            }
                        </Query>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Driver;