import React from 'react';
import { CustomPhoto } from './styled';
import s3 from 'config/s3';
import { Image } from 'react-bootstrap';

const PhotoBox = props => {
  return (
    <CustomPhoto>
      <i class="fas fa-times-circle"></i>
      {!props.noDelete && (
        <i class="far fa-times-circle" onClick={() => props.deleteAttachPhoto(props.pathUrl)}></i>
      )}
      <Image onClick={props.renderLightbox} style={{ width: '100%' }} src={props.pathUrl} rounded />
    </CustomPhoto>
  );
};

export default PhotoBox;
