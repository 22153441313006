import gql from 'graphql-tag';

export default gql`
  query search($_id: ID) {
    shipmentList(_id: $_id, see_detail: true) {
      shipment {
        _id
        flash_deals_id
        create_date
        matching_time
        shipment_type
        driver_name_under_mama
        driver_tel_under_mama
        round_trip
        shipment_status
        shipment_number
        shipper
        shipper_detail
        account_user_name
        account_user_display_name
        transporter
        transporter_detail
        transporter_name
        transporter_phone_code
        transporter_phone_number
        user_setting_truck_id
        driver_user_id
        shipment_tracking_number
        distance
        paid_type
        paid_status
        cancel_comment
        assign_driver_date
        last_update
        completed_date
        cancel_date
        setting_truck_name {
          th
        }
        truck_type_name {
          th
        }
        truck_type_qty
        pick_up_date
        shipment_address {
          _id
          mode
          address
          subdictrict
          province
          lat
          lng
          contact_name
          contact_tel
          remark
          use_contact_point
          paid_by_cod
          pick_up_date
          delivery_date
        }
        shipment_other_detail {
          remark
          user_ref
          document {
            path
          }
        }
        shipment_additional {
          additional_id
          additional
          qty
          price
          address
        }
        shipment_confirm_document
        shipment_confirm_detail {
          receipt_doc {
            path
          }
        }
        shipment_refund {
          amount
          status
          detail {
            th
            en
          }
        }
        shipment_fines {
          amount
          status
          detail {
            th
            en
          }
          detail_complete {
            th
            en
          }
        }
        multi_point {
          distance_point
          distance_point_detail
          total_point
          price_per_point
        }
        polyline_list {
          distance
          duration
          polyline
        }
        tracking {
          working
        }
      }
      total_page
    }

    trackingList(shipment_id: $_id) {
      tracking {
        _id
        shipment_tracking_number
        shipment_type
        tracking_status
        driver_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_accept
        driver_accept_date
        user_setting_truck_license_plate
        working
        review_detail
        review_score
        review_reason {
          th
          en
        }
        tracking_detail {
          droppoint
          pick_up_address
          customer_name
          customer_phone
          customer_phone_code

          contact_customer_date
          front_picture
          back_picture
          side_picture
          other_picture {
            path
          }
          take_picture_date
          problem_picture {
            path
          }
          problem_solved
          problem_take_picture_detail
          signature_picture
          signature_date
          pod_from
          pod_track
          pod_remark
          pod_date
        }
        tracking_import_detail {
          _id
          droppoint
          track_driver {
            _id
            driver_name
            driver_phone_code
            driver_phone_number
            license_plate_front
            license_plate_back
          }
          track_driver_date
          accept_container {
            _id
            cont_number
            seal_number
            tare_weight
            picture
          }
          accept_container_date
          return_container_date
        }
        tracking_export_detail {
          _id
          droppoint
          track_driver {
            _id
            driver_name
            driver_phone_code
            driver_phone_number
            license_plate_front
            license_plate_back
          }
          track_driver_date
          accept_container {
            _id
            cont_number
            seal_number
            tare_weight
            picture
          }
          accept_container_date
          commodity_picture
          commodity_picture_date
          deliver_document
          driver_accept_document
          return_container_date
        }
        favorite_driver
        driver_display_pic
      }
      total_page
    }
  }
`;
