import gql from 'graphql-tag';

export default gql`
  query AccountInfoList($_id: ID) {
    accountInfoList(_id: $_id, account_info_type: 3, account_info_status: 1) {
      account_info {
        _id
        account_info_business_name
      }
    }
  }
`;
