import React, { Component } from 'react';
import ModalConfirm from './components/ModalConfirm'
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';
import NotificationSystem from 'react-notification-system';
import { Query, graphql, compose, Mutation } from "react-apollo";
import styled from 'styled-components';

const styleNotification = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px',
        },

        error: {
            color: '#FFFFFF',
            backgroundColor: '#FC727A',
            border: '1px solid #FC727A',
            boxShadow: 'none',
            borderRadius: '4px',
        },

        success: {
            color: '#FFFFFF',
            backgroundColor: '#318DE8',
            border: '1px solid #318DE8',
            boxShadow: 'none',
            borderRadius: '4px',
        },
    },
};
class ConfirmStatusDeniedModal extends Component {
    constructor(props){
        super(props)
        this.notificationSystem = React.createRef();
    }

    addNotification = (typeStatus, message) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: (
                <span>
                    <strong>แจ้งเตือน</strong> {message}
                </span>
            ),
            level: typeStatus,
            position: 'tr',
        });
    };

    handlerSubmit = (lazTrackingUpdate) => {
        // console.log(this.props.selectedAgentList)
        // console.log(this.props.agentDetailList)

        const delivery = this.props.agentDetailList.map((data, index) => 
        {
            if(this.props.selectedAgentList.filter(slec => slec._id == data._id).length > 0){
                return {
                    _id: data._id, 
                    failure_detail: data.failure_detail,
                    failure_date: data.failure_date,
                    postpone_delivery_date: data.postpone_delivery_date,
                    failure_picture: data.failure_picture,
                    remind: data.remind,
                    admin_reject_status: 1
                }
            }else{
                return {
                    _id: data._id, 
                    failure_detail: data.failure_detail,
                    failure_date: data.failure_date,
                    postpone_delivery_date: data.postpone_delivery_date,
                    failure_picture: data.failure_picture,
                    remind: data.remind,
                    admin_reject_status: 2
                }
            }
        }
        )

        let finalData = {
            _id: this.props.trackingId,
            tracking_detail_lazada: {
                delivery: delivery
            }
        }

        // console.log('finalData', finalData)

        if(finalData){
            lazTrackingUpdate({
                variables: finalData
            })
        }

    }

    updateCheckStatus = (shipmentsUpdate) =>{
        shipmentsUpdate({
            variables: {
                _id: this.props.shipmentId,
                check_status: 3
            }
        })
    }

    

    render(){
        {
            console.log(this.props.selectedAgentList)
        }
        return(
            <Mutation
                mutation={ shipmentsUpdateMutation }
                update={(cache, { data }) => {
                    this.props.clearChecked()
                    this.props.closeModal()
                }}
                onError={error => {
                    this.addNotification('error', error.message.replace("Network error: ",""));
                }}
                refetchQueries={() => ["shipmentAgentView"]}
            >
                {(shipmentsUpdate) => {
                    return (
                        <Mutation
                            mutation={ lazTrackingUpdateMutation }
                            update={(cache, { data }) => {
                                this.updateCheckStatus(shipmentsUpdate)
                                // this.props.closeModal()
                            }}
                            onError={error => {
                                console.log(error);
                                this.addNotification('error', error.message.replace("Network error: ",""));
                            }}
                        >
                            {(lazTrackingUpdate) => {
                                return (
                                    <ModalConfirm
                                            show={this.props.isConfirmStatusDeniedModal}
                                            onPressButtonCancel={this.props.closeModal}
                                            onPressButtonConfirm={() => this.handlerSubmit(lazTrackingUpdate)}
                                            labelButtonConfirm={`ใช่, ฉันยืนยัน`}
                                            labelButtonCancel={`ไม่ใช่`}
                                            // disabledButtonConfirm={this.state.yearholidays == ''}
                                            styleColorButtonConfirm="green"
                                            titleHeader={`แจ้งเตือน`}
                                            onHide={this.props.closeModal}
                                        >
                                        <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
                                        <div style={{ lineHeight: 1 }}>
                                            ต้องการปฎิเสธข้อมูลนี้ใช้หรือไม่?<br/>
                                            สินค้าที่ปฏิเสธข้อมูล :<br/>
                                            {this.props.selectedAgentList && this.props.selectedAgentList.map((data, index) => <div key={data._id}><b>{`${index + 1}. ${data.commodity[0].name}`} </b></div>)}
                                        </div>
                                    </ModalConfirm>
                                    )
                                }}
                            </Mutation>
                            )
                        }}
            </Mutation>
        );
    }
}

export default ConfirmStatusDeniedModal;