import gql from 'graphql-tag';

export default gql`
  mutation billingDeletePaymentDocuments($_id: [ID], $payment_id: ID!) {
    billingDeletePaymentDocuments(_id: $_id, payment_id: $payment_id) {
      _id
      payment_id
      document_type
      document_file
      list_ref
      delete
      create_date
      last_update
    }
  }
`;
