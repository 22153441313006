import * as yup from 'yup';

export const validateSchema = yup.object({
  isCreateQR: yup.boolean().required(),
  document: yup
    .array()
    .of(
      yup.object({
        path: yup.string().required(),
      })
    )
    .min(1, 'กรุณาแนบสลิป'),
  dateTime: yup.string().required('กรุณาเลือกวันเวลาที่โอน'),
  codList: yup
    .array()
    .of(
      yup.object({
        id: yup.string().required(),
        lazadaOrderType: yup.string().required(),
        shipmentTrip: yup.string().required(),
        trackingNumber: yup.string().required(),
        trackingDeliveredDate: yup.string().required(),
        codPrice: yup.string().required(),
        deliveryId: yup.string().required(),
      })
    )
    .min(1, 'กรุณาเพิ่มรายการ'),
  qrCode: yup.object({
    id: yup.string().required(),
    date: yup.string().required(),
    ref1: yup.string().required(),
    ref2: yup.string().required(),
    image: yup.string().required(),
    paidAmount: yup.number().required(),
    billingCod: yup.string().required(),
  }),
});
