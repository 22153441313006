import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Modal, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Table from '../components/Table';
import Checkbox from '../components/Checkbox';
import kaWarehousesQuery from '../graphql/query/kaWarehouses';
import { ErrorMessage } from '@hookform/error-message';

const ModalStyle = styled(Modal)`
  & .modal-dialog {
    width: 100%;
    max-width: 600px;
  }
`;

const ModalHeader = styled(Modal.Header)`
  & button.close {
    font-size: 30px;
  }
`;

export const MessageError = styled.span`
  display: block;
  color: #ff0000;
  min-height: 20px;
`;

const schema = Yup.object({
  warehouses: Yup.array()
    .of(
      Yup.object({
        tagName: Yup.string().required('กรุณากรอกข้อมูล'),
        address: Yup.string().required('กรุณากรอกข้อมูล'),
        province: Yup.string().nullable(),
        district: Yup.string().nullable(),
        subDistrict: Yup.string().nullable(),
        postCode: Yup.string().nullable(),
        lat: Yup.string().nullable(),
        lng: Yup.string().nullable(),
        contactName: Yup.string().required('กรุณากรอกข้อมูล'),
        contactTel: Yup.string().required('กรุณากรอกข้อมูล'),
        warehouseType: Yup.number().nullable(),
        active: Yup.boolean().required(),
        wmsBranch: Yup.string().nullable(),
        totalOrder: Yup.number().nullable(),
      })
    )
    .min(1, 'กรุณาเลือกคลังสินค้า'),
});

const initialValue = {
  warehouses: [],
};

const ModalWarehouse = props => {
  const { open, onClose, shipmentIndex, onComplete, dropPoint, mode } = props;
  const methods = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
    defaultValues: {
      ...initialValue,
    },
  });

  const { reset, watch, errors, handleSubmit, setValue, trigger } = methods;
  const watchWarehouses = watch('warehouses');

  useEffect(() => {
    reset({
      ...initialValue,
      warehouses: [...dropPoint],
    });
  }, [open]);

  const onSubmit = handleSubmit(values => {
    onComplete({
      warehouses: values.warehouses,
    });
  });

  const onSelect = (event, values) => {
    if (event.target.checked) {
      setValue('warehouses', [...watchWarehouses, { ...values }]);
    } else {
      setValue(
        'warehouses',
        watchWarehouses.filter(
          warehouseItem =>
            !(warehouseItem.address === values.address && warehouseItem.tagName === values.tagName)
        )
      );
    }
  };

  return (
    <ModalStyle
      show={open}
      onHide={() => onClose && onClose()}
      backdropClassName="custom-backdrop"
      backdropStyle={{
        zIndex: 1050,
      }}
      style={{
        zIndex: 1050,
      }}
    >
      <ModalHeader closeButton>
        <Modal.Title>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 24,
            }}
          >
            {`เลือกจุด${mode === 'pickup' ? 'รับ' : 'ส่ง'}สินค้าให้รถคันที่`} {shipmentIndex + 1}
          </div>
        </Modal.Title>
      </ModalHeader>

      <Modal.Body>
        <ErrorMessage
          errors={errors}
          name="warehouses"
          render={({ message }) => (
            <div style={{ marginTop: 10 }}>
              <MessageError>{message}</MessageError>
            </div>
          )}
        />

        <div style={{ overflowY: 'scroll', maxHeight: '60vh', position: 'relative' }}>
          <Table>
            <thead>
              <tr
                style={{
                  top: 0,
                  zIndex: 1,
                  width: '100%',
                  position: 'sticky',
                  backgroundColor: 'white',
                }}
              >
                <th style={{ width: 40 }}>เลือก</th>
                <th>{`ที่อยู่${mode === 'pickup' ? 'รับ' : 'ส่ง'}สินค้า`}</th>
              </tr>
            </thead>

            <tbody style={{ fontSize: 18 }}>
              <Query query={kaWarehousesQuery} fetchPolicy="cache-and-network">
                {({ data, loading, error }) => {
                  return (
                    <>
                      {loading && (
                        <tr style={{ border: 0 }}>
                          <td colspan="2">กำลังโหลด...</td>
                        </tr>
                      )}

                      {error && (
                        <tr>
                          <td colspan="2">{error.message}</td>
                        </tr>
                      )}

                      {!loading && !error && data?.kaWarehouses.length === 0 && (
                        <tr style={{ border: 0 }}>
                          <td colspan="2">ไม่มีคลังสินค้า</td>
                        </tr>
                      )}

                      {!loading &&
                        !error &&
                        data.kaWarehouses.map((item, index) => (
                          <tr key={index} style={{ border: 0 }}>
                            <td>
                              <Checkbox
                                inline
                                checked={
                                  !!watchWarehouses.find(
                                    warehouse =>
                                      warehouse.address === item.address &&
                                      warehouse.tagName === item.tag_name
                                  )
                                }
                                disabled={!item.address}
                                onChange={event => {
                                  onSelect(event, {
                                    tagName: item.tag_name,
                                    address: item.address,
                                    province: item.province,
                                    district: item.dictrict,
                                    subDistrict: item.subdictrict,
                                    postCode: item.postcode,
                                    lat: item.lat,
                                    lng: item.lng,
                                    contactName: item.contact_name,
                                    contactTel: item.contact_tel,
                                    warehouseType: item.warehouse_type,
                                    active: item.active,
                                    wmsBranch: item.wms_branch,
                                    totalOrder: null,
                                  });

                                  trigger(['warehouses']);
                                }}
                              >
                                <span className="checkmark" />
                              </Checkbox>
                            </td>
                            <td>
                              <div>
                                <strong>{item.tag_name}</strong>
                              </div>
                              <div>{item.address || '(ไม่มีที่อยู่)'}</div>
                            </td>
                          </tr>
                        ))}
                    </>
                  );
                }}
              </Query>
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose && onClose()}>ยกเลิก</Button>
        <Button bsStyle="primary" onClick={() => onSubmit()}>
          {`ยืนยันการเลือกที่อยู่${mode === 'pickup' ? 'รับ' : 'ส่ง'}สินค้า`} (
          {watchWarehouses.length})
        </Button>
      </Modal.Footer>
    </ModalStyle>
  );
};

export default ModalWarehouse;
