import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { graphql, compose, Query } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';

import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';

import adminListQuery from './graphql/query/adminList';
import accountInfoListQuery from './graphql/query/accountInfoList';

import customerId from 'config/customerId';
import baseUrl from 'config/baseUrl';
import reportUrl from 'config/reportUrl';
import lambdaUrl from 'config/lambdaUrl';
import { UploadFile, UploadFileWithSingleUpload } from './container';
import restBaseUrl from 'config/restBaseUrl';
import { client } from 'index';
import { faKaaba, fal } from '@fortawesome/pro-light-svg-icons';
import SelectCustomerParcel from './container/SelectCustomerParcel';
import RepoerPD from './render/ReportPD';

const AutosuggestContainer = styled.div`
  min-height: 70px;

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;
    position: absolute;
    background-color: white;
    z-index: 999;
    width: calc(100% - 30px);
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportName: '',
      reportNo: '',
      reportDate: '',
      show_reportDate: '',
      buSuggestions: '',
      agentSuggestions: [],
      accountBu: '',
      accountBuId: '',
      reportTransporter: '',
      reportTransporterId: '',
      lazadaReportOption: null,
      customerParcelId: '',
    };
    this._inputImportFile = React.createRef();
    this._inputImportFile1 = React.createRef();
  }

  uploadFileHandler = async (file, refetch) => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const adminId = admin[0]._id;
      const url = `${restBaseUrl}/import-file-get-price?path=${file.path}&admin_id=${adminId}`;
      await axios(url).then(res => {
        console.log('res', res);
        //   if (!res.data._id) {
        //     // alert('Error');
        //     return;
        //   }

        window.location = '/downloadreport';
      });
    } catch (error) {
      console.log(error);
    }
  };

  uploadFileTrackingHandler = (file, refetch) => {
    const uri = `${restBaseUrl}/v1/customer-tracking-report?path=${file.path}`;
    window.location = uri;
  };

  openReportModal(reportName, reportNo) {
    this.setState({
      showReport: true,
      reportName,
      reportNo,
      reportDate: '',
      show_reportDate: '',
      accountBu: '',
      accountBuId: '',
      reportTransporter: '',
      reportTransporterId: '',
    });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  exportReport(adminId) {
    let uri = '';
    switch (this.state.reportNo) {
      case 1:
        this.callExportReport(
          JSON.stringify({
            create_date: this.state.reportDate,
            admin_id: adminId,
          }),
          '/bookingdata-original-report'
        );
        break;
      case 2:
        uri = `${reportUrl}/summary-invoice-shipment?admin_id=${adminId}&date=${this.state.reportDate}`;
        window.location = uri;
        break;
      case 3:
        uri = `${reportUrl}/summary_invoice_report?admin_id=${adminId}&date=${this.state.reportDate}`;
        window.location = uri;
        break;
      case 4:
        uri = `${reportUrl}/invoiceData_report?admin_id=${adminId}&date=${this.state.reportDate}&shipper=${this.state.accountBuId}`;
        window.location = uri;
        break;
      case 5:
        uri = `${reportUrl}/allLogSearchReport?admin_id=${adminId}&date=${this.state.reportDate}&key=NW4nA8F9mVfUSdy3`;
        window.location = uri;
        break;
      case 6:
        uri = `${reportUrl}/driver-user-report?create_date=${this.state.reportDate}&admin_id=${adminId}`;
        window.location = uri;
        break;
      case 7:
        if (this.state.lazadaReportOption) {
          this.callExportReport(
            JSON.stringify({
              pick_up_date: this.state.reportDate,
              admin_id: adminId,
              shipper: customerId.lazada,
              lazada_order_type: this.state.lazadaReportOption,
            }),
            '/ka-summary-report'
          );
        } else {
          this.callExportReport(
            JSON.stringify({
              pick_up_date: this.state.reportDate,
              admin_id: adminId,
              shipper: customerId.lazada,
            }),
            '/ka-summary-report'
          );
        }
        break;
      case 8:
        uri = `${reportUrl}/commission-report?create_date=${this.state.reportDate}&admin_id=${adminId}`;
        window.location = uri;
        break;
      case 9: // giztixBusiness
        uri = `${reportUrl}/account-business-report?create_date=${this.state.reportDate}&admin_id=${adminId}`;
        window.location = uri;
        break;
      case 10:
        if (this.state.lazadaReportOption) {
          this.callExportReport(
            JSON.stringify({
              pick_up_date: this.state.reportDate,
              admin_id: adminId,
              shipper: customerId.jd,
              lazada_order_type: this.state.lazadaReportOption,
            }),
            '/ka-summary-report'
          );
        } else {
          this.callExportReport(
            JSON.stringify({
              pick_up_date: this.state.reportDate,
              admin_id: adminId,
              shipper: customerId.jd,
            }),
            '/ka-summary-report'
          );
        }
        break;
      case 11:
        this.callExportReport(
          JSON.stringify({
            pickup_date: this.state.reportDate,
            admin_id: adminId,
          }),
          '/new-bookingdata-report'
        );
        break;
      case 12:
        this.callExportReport(
          JSON.stringify({
            deliveredDate: this.state.reportDate,
            admin_id: adminId,
          }),
          '/ka-order-delivered-report'
        );
        break;
      case 13:
        this.callExportReport(
          JSON.stringify({
            create_date: this.state.reportDate,
            admin_id: adminId,
          }),
          '/shipment-driver-invite-report'
        );
        break;
      case 14:
        // uri = `${lambdaUrl}/case-report/${adminId}/${this.state.reportDate}`;
        // window.location = uri;
        this.callExportReport(
          JSON.stringify({
            sla_issue: this.state.reportDate,
            admin_id: adminId,
          }),
          '/case-report-ui'
        );
        break;
      case 15:
        this.callExportReport(
          JSON.stringify({
            transporter: this.state.reportTransporterId,
            pick_up_date: this.state.reportDate,
            createByAgent: false /*true = agent ดึงไม่ต้องส่ง admin_id*/,
            admin_id: adminId,
          }),
          '/shipment-ka-monthrate-report'
        );
        break;
      case 16:
        if (!(adminId && this.state.reportDate && this.state.customerParcelId)) {
          alert('กรุณากรอกข้อมูล');
          return;
        }

        this.callExportReport(
          JSON.stringify({
            admin_id: adminId,
            create_date: this.state.reportDate,
            account_info: this.state.customerParcelId,
          }),
          '/paecel-order-for-cus'
        );
        break;
      default:
        break;
    }
    this.setState({
      showReport: false,
      reportDate: '',
      show_reportDate: '',
      reportNo: '',
      reportName: '',
      accountBu: '',
      accountBuId: '',
      reportTransporter: '',
      reportTransporterId: '',
    });
  }

  callExportReport = (content, url) => {
    try {
      let headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const result = axios
        .post(lambdaUrl + url, content, headers)
        .then(results => {
          console.log(results);
        })
        .catch(err => {
          console.log(err);
        });
      setTimeout(() => {
        window.location = '/downloadreport';
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  //Autosuggest BU
  getSuggestionsBu = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const accountInfoData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        buSuggestions: accountInfoData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueBu = suggestion => suggestion.name;

  renderSuggestionBu = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedBu = ({ value }) => {
    this.getSuggestionsBu(value);
  };

  onSuggestionsClearRequestedBu = () => {
    this.setState({
      buSuggestions: [],
    });
  };

  shouldRenderSuggestionsBu() {
    return true;
  }
  //End Autosuggest BU

  //Autosuggest Transporter
  getSuggestionsTransporter = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
          account_info_type: 3,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        agentSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTransporter = suggestion => suggestion.name;

  renderSuggestionTransporter = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTransporter = ({ value }) => {
    this.getSuggestionsTransporter(value);
  };

  onSuggestionsClearRequestedTransporter = () => {
    this.setState({
      agentSuggestions: [],
    });
  };

  shouldRenderSuggestionsTransporter() {
    return true;
  }
  //End Autosuggest Transporter

  //template from tracking lazada
  downloadTemplate(reportUrl) {
    const url =
      'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/template_file/template-export-tracking-status.xlsx';
    window.open(url, '_blank');
  }

  checkFileTypeExcel(file) {
    if (file) {
      return (
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      );
    }
  }

  render() {
    let admin_id = '';
    let admin = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin = this.props.adminName.adminList.admin[0].admin_username;
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }
    // console.log(this.state);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <CardTable
                title="ตารางรายงาน"
                category="สามารถดาวน์โหลดรายงานทั้งหมดได้จากหน้านี้"
                ctTableFullWidth
                ctTableResponsive
                rightButton={
                  <div style={{ float: 'right', marginLeft: '15px' }}>
                    <Button onClick={() => (window.location = '/downloadreport')}>
                      {' '}
                      <i className="fal fa-clipboard-list"></i> รายงานรอดาวน์โหลด{' '}
                    </Button>
                  </div>
                }
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th style={{ minWidth: '100px' }}>เครื่องมือ</th>
                        <th style={{ width: '250px' }}>ชื่อรายงาน</th>
                        <th>รายละเอียด</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() =>
                              this.openReportModal('รายงานรายชื่อลูกค้า GIZTIX Business', 9)
                            }
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          {' '}
                          <b>รายงานรายชื่อลูกค้า GIZTIX Business</b>
                        </td>
                        <td>แสดงรายละเอียดรายงานรายชื่อลูกค้า GIZTIX Businessทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานงานขนส่ง (Booking Data)', 1)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานงานขนส่ง (Booking Data)</b>
                        </td>
                        <td>แสดงรายละเอียดงานขนส่งทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() =>
                              this.openReportModal('รายงานราคางานขนส่ง (Truck Costing Data)', 11)
                            }
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานราคางานขนส่ง (Truck Costing Data)</b>
                        </td>
                        <td>แสดงรายละเอียดงานขนส่งทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button onClick={() => this.openReportModal('รายงานใบแจ้งหนี้ (Acc)', 2)}>
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานใบแจ้งหนี้ (Acc)</b>
                        </td>
                        <td>แสดงรายละเอียดใบแจ้งหนี้สำหรับบัญชี</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานใบแจ้งหนี้ที่ยังไม่ชำระ', 4)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานใบแจ้งหนี้ที่ยังไม่ชำระ</b>
                        </td>
                        <td>แสดงรายละเอียดใบแจ้งหนี้ที่ยังไม่ชำระ</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานใบแจ้งหนี้ (Operation)', 3)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานใบแจ้งหนี้ (Operation)</b>
                        </td>
                        <td>แสดงรายละเอียดใบแจ้งหนี้สำหรับ Operation</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานการค้นหาราคาทั้งหมด', 5)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานการค้นหาราคาทั้งหมด</b>
                        </td>
                        <td>แสดงรายละเอียดรายงานการค้นหาราคาทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <UploadFileWithSingleUpload
                            completed={file => {
                              this._inputImportFile.current.value = null;
                              this.uploadFileHandler(file);
                            }}
                          >
                            {selectFile => {
                              return (
                                <React.Fragment>
                                  <input
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={event => {
                                      selectFile(
                                        event,
                                        `upload/getPrice/document_${new Date().getTime()}`
                                      );
                                    }}
                                    type="file"
                                    id="file"
                                    ref={this._inputImportFile}
                                    style={{ display: 'none' }}
                                  />
                                  <Button onClick={() => this._inputImportFile.current.click()}>
                                    <i className="fas fa-cloud-upload"></i> ดึงราคาทั่วประเทศ
                                  </Button>
                                </React.Fragment>
                              );
                            }}
                          </UploadFileWithSingleUpload>
                        </td>
                        <td>
                          <b>รายงานราคาค่าขนส่งทั่วประเทศ</b>
                        </td>
                        <td>
                          แสดงรายละเอียดราคาค่าขนส่งทั่วประเทศ
                          <a
                            style={{ marginLeft: '20px' }}
                            href="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/template_Import_get_price.xlsx"
                            download="ไฟล์ตัวอย่าง"
                          >
                            <Button>
                              <i className="fas fa-cloud-download"></i> Downlond ไฟล์ตัวอย่าง
                            </Button>
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานรายชื่อพนักงานขับรถ', 6)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานรายชื่อพนักงานขับรถ</b>
                        </td>
                        <td>แสดงรายละเอียดรายงานรายชื่อพนักงานขับรถทั้งหมดตามช่วงเวลาที่สมัคร</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() =>
                              this.openReportModal('รายงานงานขนส่ง (Booking Lazada)', 7)
                            }
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานงานขนส่ง (Booking Lazada)</b>
                        </td>
                        <td>แสดงรายละเอียดงานขนส่ง Lazada ทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานงานขนส่ง (Booking JD)', 10)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานงานขนส่ง (Booking JD)</b>
                        </td>
                        <td>แสดงรายละเอียดงานขนส่ง JD ทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button onClick={() => this.openReportModal('รายงานค่าคอม', 8)}>
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>

                          {/* <Button onClick={() => this.callExportReport(admin_id)}>
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            callExportReport
                          </Button> */}
                        </td>
                        <td>
                          <b>รายงานค่าคอม</b>
                        </td>
                        <td>แสดงรายละเอียดค่าคอมงานขนส่งทั้งหมด</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <UploadFile
                            completed={file => {
                              this._inputImportFile1.current.value = null;
                              this.uploadFileTrackingHandler(file);
                            }}
                          >
                            {selectFile => {
                              return (
                                <React.Fragment>
                                  <input
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={event => {
                                      const files = event.target.files[0];
                                      const checkFileTypeExcel = this.checkFileTypeExcel(files);
                                      if (checkFileTypeExcel === false) {
                                        alert(
                                          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท xls และ xlsx เท่านั้น'
                                        );
                                        return;
                                      }
                                      selectFile(
                                        event,
                                        `upload/lazadaProject/document_${new Date().getTime()}`
                                      );
                                    }}
                                    type="file"
                                    id="file"
                                    ref={this._inputImportFile1}
                                    style={{ display: 'none' }}
                                  />
                                  <Button
                                    style={{ marginRight: '15px' }}
                                    onClick={() => this._inputImportFile1.current.click()}
                                  >
                                    <i className="fas fa-cloud-upload"></i> ดาวน์โหลดรายงาน
                                  </Button>
                                </React.Fragment>
                              );
                            }}
                          </UploadFile>
                        </td>

                        <td>
                          <b>รายงานสถานะ Tracking Lazada</b>
                        </td>

                        <td>
                          แสดงรายละเอียดสถานะ Tracking Lazada
                          <Button
                            style={{ marginLeft: '20px' }}
                            onClick={() => this.downloadTemplate()}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-cloud-download"></i>{' '}
                            Download Template
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงานสินค้าส่งสำเร็จ  (KA)', 12)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานสินค้าส่งสำเร็จ</b>
                        </td>
                        <td>แสดงรายงานสินค้าส่งสำเร็จ</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() =>
                              this.openReportModal('รายงาน Campaign คนขับรถช่วยขาย', 13)
                            }
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงาน Campaign คนขับรถช่วยขาย</b>
                        </td>
                        <td>แสดงรายงาน งานขนส่งที่มาจาก Campaign คนขับรถช่วยขาย</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() => this.openReportModal('รายงาน Truck Case Report', 14)}
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงาน Truck Case Report</b>
                        </td>
                        <td>แสดงรายงาน Truck Case Report</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button
                            onClick={() =>
                              this.openReportModal(
                                'รายงานสรุปข้อมูล Shipment ประเภทการจัดจ้างแบบรายเดือน',
                                15
                              )
                            }
                          >
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานสรุปข้อมูล Shipment ประเภทการจัดจ้างแบบรายเดือน</b>
                        </td>
                        <td>แสดงรายงานสรุปข้อมูล Shipment ประเภทการจัดจ้างแบบรายเดือน</td>
                      </tr>
                      <tr>
                        <td style={{ width: '100px' }}>
                          <Button onClick={() => this.openReportModal('รายงานการส่งของสินค้า', 16)}>
                            <i style={{ color: '#6666666' }} className="fas fa-search"></i>{' '}
                            ดาวน์โหลดรายงาน
                          </Button>
                        </td>
                        <td>
                          <b>รายงานการส่งของสินค้า</b>
                        </td>
                        <td>แสดงรายละเอียดสถานะขนส่งสินค้าทั้งหมด</td>
                      </tr>
                      <RepoerPD adminId={admin_id} callExportReport={this.callExportReport} />
                    </tbody>
                  </Table>
                }
              />

              <Modal
                show={this.state.showReport}
                onHide={() => this.setState({ showReport: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title>{this.state.reportName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {(this.state.reportNo == 7 || this.state.reportNo == 10) && (
                    <div>
                      <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}> Mode</Col>
                      </Row>
                      <FormControl
                        componentClass="select"
                        placeholder="select"
                        defaultValue={null}
                        value={this.state.lazadaReportOption}
                        style={{ marginBottom: '10px' }}
                        onChange={event => {
                          this.setState({
                            lazadaReportOption: Number(event.target.value),
                          });
                        }}
                        name="lazadaReportOption"
                      >
                        <option value={null}>ทั้งหมด</option>
                        <option value={1}>Last Mile(LM)</option>
                        <option value={2}>Return to Merchant (RM)</option>
                        <option value={5}>Return (RT)</option>
                        <option value={9}>First Mile (FM)</option>
                        <option value={10}>Shuttle Haul (SH)</option>
                        <option value={11}>Reject Return (RR)</option>
                        <option value={12}>Direct Ship (DS)</option>
                        <option value={13}>Line Haul (LH)</option>
                        <option value={14}>BU First Mile (BUFM)</option>
                      </FormControl>
                    </div>
                  )}
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}> ช่วงวันที่{this.state.reportNo == 12 && 'ส่งสำเร็จ'}</Col>
                  </Row>
                  <FormGroup controlId="formValidationError1">
                    <DateRangePicker
                      onApply={(event, picker) => this.handleDateRangePickerReport(event, picker)}
                      onCancel={() => this.setState({ reportDate: '', show_reportDate: '' })}
                      containerStyles={{ display: 'block' }}
                      opens="right"
                      format="DD/MM/YYYY"
                    >
                      <FormControl
                        type="text"
                        value={this.state.show_reportDate}
                        placeholder="ค้นหาวันที่"
                        name="reportDate"
                      />
                    </DateRangePicker>
                  </FormGroup>
                  {this.state.reportNo === 4 && (
                    <AutosuggestContainer>
                      <Autosuggest
                        suggestions={this.state.buSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedBu}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedBu}
                        getSuggestionValue={this.getSuggestionValueBu}
                        renderSuggestion={this.renderSuggestionBu}
                        focusInputOnSuggestionClick={false}
                        inputProps={{
                          placeholder: 'ค้นหา',
                          value: this.state.accountBu,
                          onChange: event =>
                            this.setState({ accountBu: event.target.value, accountBuId: null }),
                          type: 'text',
                          className: 'form-control',
                        }}
                        onSuggestionSelected={(event, { suggestion }) => {
                          this.setState({ accountBuId: suggestion.id, accountBu: suggestion.name });
                        }}
                        shouldRenderSuggestions={this.buSuggestions}
                      />
                    </AutosuggestContainer>
                  )}
                  {this.state.reportNo === 15 && (
                    <AutosuggestContainer>
                      <Autosuggest
                        suggestions={this.state.agentSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTransporter}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedTransporter}
                        getSuggestionValue={this.getSuggestionValueTransporter}
                        renderSuggestion={this.renderSuggestionTransporter}
                        focusInputOnSuggestionClick={false}
                        inputProps={{
                          placeholder: 'ค้นหานายหน้า',
                          value: this.state.reportTransporter,
                          onChange: event =>
                            this.setState({
                              reportTransporter: event.target.value,
                              reportTransporterId: null,
                            }),
                          type: 'text',
                          className: 'form-control',
                        }}
                        onSuggestionSelected={(event, { suggestion }) => {
                          this.setState({
                            reportTransporterId: suggestion.id,
                            reportTransporter: suggestion.name,
                          });
                        }}
                        shouldRenderSuggestions={this.agentSuggestions}
                      />
                    </AutosuggestContainer>
                  )}
                  {this.state.reportNo === 16 && (
                    <SelectCustomerParcel
                      onChange={valueChange =>
                        this.setState({
                          customerParcelId: valueChange,
                        })
                      }
                    />
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button bsStyle="primary" onClick={() => this.exportReport(admin_id)}>
                    ดาวน์โหลดรายงาน
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(graphql(adminListQuery, { name: 'adminName' }))(Report);
