import React from 'react';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';

import indexRoutes from 'routes/index.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/sass/light-bootstrap-dashboard.scss?v=1.2.0';
import './assets/css/demo.css';
import './assets/css/pe-icon-7-stroke.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import 'filepond/dist/filepond.min.css';

import baseUrl from 'config/baseUrl';

// import ShipmentDetail from "views/ShipmentDetail/ShipmentDetail";
import Login from 'views/Login/Login';
import { graphql, Query } from 'react-apollo';
import currentUser from 'views/Login/graphql/query/currentUser';
import PDPA from './components/PDPA';

/*custom style*/
import './assets/fontsV2.css';
import './assets/css/style.css';

// const client = new ApolloClient({
// 	uri: `${baseUrl}/graphql`,
// 	request: async operation => {
// 		const token = await cookie.load('token_key');
// 		operation.setContext({
// 			headers: {
// 				authorization: `admin ${token}`,
// 			},
// 		});
// 	},
// });

// console.warn = function filterWarnings() {};
// console.error = function filterErrors() {};

const httpLink = createUploadLink({
  // uri: `http://192.168.101.24:3000/graphql`,
  uri: `${baseUrl}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await cookie.load('token_key');
  //console.log(token)
  return {
    headers: {
      ...headers,
      authorization: `admin ${token}`,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: object => null,
  }),
  resolvers: {
    Mutation: {
      doNothing: () => {
        return null;
      }, // hack to be able to use refetchQueries
    },
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <PDPA />
    <Router>
      <Query query={currentUser}>
        {({ loading, error, data, refetch, variables }) => {
          if (loading) return null;
          if (data && data.currentUser.result)
            return (
              <Switch>
                {indexRoutes.map((prop, key) => {
                  return <Route to={prop.path} component={prop.component} key={key} />;
                })}
              </Switch>
            );
          return (
            <Switch>
              <Route to={'/login'} component={Login} />
              <Redirect to="/login" />
            </Switch>
          );
        }}
      </Query>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);
