import React, { Component } from 'react';
import ModalConfirm from './components/ModalConfirm'
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import NotificationSystem from 'react-notification-system';
import { Query, graphql, compose, Mutation } from "react-apollo";
import styled from 'styled-components';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';

const styleNotification = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px',
        },

        error: {
            color: '#FFFFFF',
            backgroundColor: '#FC727A',
            border: '1px solid #FC727A',
            boxShadow: 'none',
            borderRadius: '4px',
        },

        success: {
            color: '#FFFFFF',
            backgroundColor: '#318DE8',
            border: '1px solid #318DE8',
            boxShadow: 'none',
            borderRadius: '4px',
        },
    },
};

class ConfirmStatusModal extends Component {
    constructor(props){
        super(props)
        this.notificationSystem = React.createRef();
    }

    updateCheckStatus = (shipmentsUpdate) =>{
        shipmentsUpdate({
            variables: {
                _id: this.props.shipmentId,
                check_status: 2
            }
        })
    }

    addNotification = (typeStatus, message) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: (
                <span>
                    <strong>แจ้งเตือน</strong> {message}
                </span>
            ),
            level: typeStatus,
            position: 'tr',
        });
    };

    handlerSubmit = (lazTrackingUpdate) => {
        const delivery = this.props.agentDetailList.map((data, index) => ({
            _id: data._id,
            failure_detail: data.failure_detail,
            failure_date: data.failure_date,
            postpone_delivery_date: data.postpone_delivery_date,
            failure_picture: data.failure_picture,
            remind: data.remind,
            admin_reject_status: 2
        }))

        let finalData = {}

        // console.log('agentDetailList', this.props.agentDetailList)

        if(delivery){
            finalData = {
                _id: this.props.trackingId,
                tracking_detail_lazada: {
                    delivery: delivery
                }
            }
            // console.log(finalData)

            if(finalData){
                lazTrackingUpdate({
                    variables: finalData
                })
            }
        }

        // let subThirdPartyData = {
        //     _id: this.props.subThirdPartyId,
        //     agent_shipment_status: 3,
        //     admin_reject_detail: ''
        // }

        // console.log(subThirdPartyData)
    }

    render(){
        return(
            <Mutation
                mutation={ shipmentsUpdateMutation }
                update={(cache, { data }) => {
                    this.props.closeModal()
                }}
                onError={error => {
                    console.log(error);
                    this.addNotification('error', error.message.replace("Network error: ",""));
                }}
                refetchQueries={() => ["shipmentAgentView"]}
            >
                {(shipmentsUpdate) => {
                    return (
                        <Mutation
                            mutation={ lazTrackingUpdateMutation }
                            update={(cache, { data }) => {
                                this.updateCheckStatus(shipmentsUpdate)
                            }}
                            onError={error => {
                                console.log(error);
                                this.addNotification('error', error.message.replace("Network error: ",""));
                            }}
                        >
                            {(lazTrackingUpdate) => {
                                return (
                                    <ModalConfirm
                                            show={this.props.isConfirmStatusModal}
                                            onPressButtonCancel={this.props.closeModal}
                                            onPressButtonConfirm={() => this.handlerSubmit(lazTrackingUpdate)}
                                            labelButtonConfirm={`ใช่, ฉันยืนยัน`}
                                            labelButtonCancel={`ไม่ใช่`}
                                            // disabledButtonConfirm={this.state.yearholidays == ''}
                                            styleColorButtonConfirm="green"
                                            titleHeader={`แจ้งเตือน`}
                                            onHide={this.props.closeModal}
                                        >
                                            <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
                                            <div style={{ lineHeight: 1 }}>
                                                ต้องการยืนยันข้อมูลนี้ใช้หรือไม่?<br/>
                                            </div>
                                    </ModalConfirm>
                                )
                                }}
                            </Mutation>
                        )
                        }}
            </Mutation>
        );
    }
}

export default ConfirmStatusModal;