import gql from 'graphql-tag';

export default gql`
mutation UpdateJdResendComplete(
    $order_id: String!
){
    jdResendComplete(
        order_id: $order_id
    ){
       result
       description
    }
  }
`;
