import gql from 'graphql-tag';

export default gql`
  query codParcels($params: paramsCodParcels, $page: Int, $showData: Int!, $sort: sort) {
    codParcels(params: $params, page: $page, showData: $showData, sort: $sort) {
      codParcels {
        _id
        cod_in_number
        cod_in_date
        cod_status
        vendor {
          _id
          full_name
          last_name
          email
          phone
        }
        due_date
        delivered_date
        paid_channel
        parcel_orders {
          _id
          ref_id
          delivered_date
          cod_in_date
          pick_up_date
          tracking_number
          cod_price
          cod_in_number
          shipment_number
          booking_number
          package_number
          order_number
          contact_name
          cod_status
        }
        total_cod
        paid_qr_detail {
          qr_id
          qr_image
          qr_emvco
          qr_ref1
          qr_ref2
          qr_gen_date
          qr_complete
          qr_paid_amount
          qr_paid_date
          qr_from_bank
          qr_from_name
          qr_trans_date_time
          qr_bank_ref
        }
        document
        cod_remark
        create_date
        last_update
      }
      totalPage
      totalDocument
    }
  }
`;
