import gql from 'graphql-tag';

export default gql`
  mutation subThirdPartySettingUpdate($_id: ID!,$agent_shipment_status: Int,$proof_of_transfer: [typeProofOfTransferArg], $cod_reject_remark: String) {
    subThirdPartySettingUpdate(
      _id: $_id
      agent_shipment_status: $agent_shipment_status
      proof_of_transfer: $proof_of_transfer
      cod_reject_remark: $cod_reject_remark
    ) {
      succeed
    }
  }
`;