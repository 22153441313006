import gql from 'graphql-tag';

export default gql`
  mutation(
    $shipment_id: ID!
    $totalCost: Float!
    $serviceItems: [createParcelCostServiceItemArg]
    $costModel: String!
  ) {
    createParcelCost(
      shipment_id: $shipment_id
      totalCost: $totalCost
      serviceItems: $serviceItems
      costModel: $costModel
    ) {
      _id
    }
  }
`;
