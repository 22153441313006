import React, { Component } from "react";
import styled from 'styled-components';
import Moment from 'react-moment';
import { Query } from "react-apollo";
import ReactPaginate from 'react-paginate';
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger, Button, Pagination, FormGroup, FormControl ,Modal } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from "components/CardTable/CardTable.jsx";
import CustomButton from "components/CustomButton/CustomButton.jsx";

import getPriceLogList from "./graphql/query/getPriceLogList"
import baseUrl from "./../../config/baseUrl" 

const tooltip = (
    <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>
);


const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;

class Shipper extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total_page: 0,
            page_number: 1,
            order_by: "create_date",
            order_type: -1,
            account_info_email: "",
            create_date: "",
            show_create_date: "",
            log_status: 0,
            shipment_status: 0,
            show_shipment_status: 0,
            call_from : 0,
            promotion_name : "",
            showReport: false,
            reportDate: "",
            show_reportDate: ""
        }
    }

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }

    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    nextPage(page, total_page, refetch) {
        // console.log(page+1);
        this.setState({ page_number: page + 1, total_page });
        // console.log(this.state);
        refetch();
    }

    handleDateRangePicker(event, picker,refetch) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        console.log(date);
        this.setState({ create_date: date , show_create_date: show},() => { refetch() });
    }

    handleSeachStatus(status,refetch){
        if(status == 9){
            this.setState({ show_shipment_status: status ,log_status: 1 , shipment_status: 0 , page_number:1});
        }
        else{
            this.setState({ show_shipment_status:status ,log_status: 0, shipment_status: parseInt(status),page_number:1});
        }
        refetch();
    }

    openReportPriceLog() {
        this.setState({ showReport: true, reportDate: "" ,show_reportDate : ""});
    }

    handleDateRangePickerReport(event, picker) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState({ reportDate: date , show_reportDate: show});
    }

    exportReportPriceLog(){
        const  uri =`${baseUrl}/allLogSearchReport?date=${this.state.reportDate}&key=NW4nA8F9mVfUSdy3`;
        window.location = uri;

        this.setState({ showReport: false, reportDate: "" ,show_reportDate : ""});
    }

    render() {
        // console.log(this.state);
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                    <Query
                            query={getPriceLogList}
                            variables={{
                                page_number: this.state.page_number,
                                order_by: this.state.order_by,
                                order_type: this.state.order_type,
                                account_info_email: this.state.account_info_email,
                                create_date: this.state.create_date,
                                shipment_status : this.state.shipment_status,
                                log_status : this.state.log_status,
                                call_from: this.state.call_from,
                                promotion_name: this.state.promotion_name,
                            }}
                        >
                            {
                                ({ loading, error, data, refetch ,variables}) => {
                                    console.log(variables ,data);

                                    var itemLength = 'loading';
                                    const itemIndicatorSize = (this.state.page_number - 1)*30;
                                    if(data && data.getPriceLogList){
                                        if(data.getPriceLogList.log.length > 0){
                                            itemLength = data.getPriceLogList.log.length;
                                        }
                                    }
                                    return (
                                        <div>
                                                <CardTable
                                                    title="ตารางรายการค้นหาราคาขนส่ง"
                                                    category="ข้อมูลรายการค้นหาราคาขนส่งจากระบบออนไลน์ Marketplace"
                                                    ctTableFullWidth
                                                    ctTableResponsive
                                                    rightButton={
                                                        <div style={{ float: "right", marginLeft: "15px" }}>
                                                            <Button  bsStyle="primary" onClick={() => this.openReportPriceLog()}><i className="fal fa-map-signs"></i> รายงานการค้นหา </Button>
                                                        </div>
                                                    }
                                                    content={<div>
                                                    <PageWrapper style={{ textAlign: "right" }}>
                                                        <div className="text">{this.state.page_number === 1 ? `1 - ${itemLength}` : `${itemIndicatorSize + 1} - ${itemLength + itemIndicatorSize}`} หน้า :</div>
                                                            <FormControl
                                                                type="number"
                                                                min={1}
                                                                value={this.state.page_number}
                                                                onChange={(event) => 
                                                                    Number(event.target.value) >= 0 ?
                                                                    setInterval(
                                                                        this.setState({
                                                                        page_number: Number(event.target.value),
                                                                        }), 1000) :
                                                                    setInterval(this.setState({ page_number: 1}), 1000)
                                                                }
                                                                name="page_number"
                                                            />
                                                        <div className="text"> {!data || loading || error ? '' : `จาก ${data.getPriceLogList.total_page}`}</div>
                                                        </PageWrapper>
                                                        <div className="table-vertical">        
                                                            <Table striped hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th style={{minWidth:"140px"}} className={this.renderSorting("account_info_email")} onClick={() => this.sort("account_info_email", refetch)}>อีเมล</th>
                                                                        <th style={{minWidth:"100px"}}>สถานะ</th>
                                                                        <th style={{minWidth:"120px"}}>ช่องทางการค้นหา</th>
                                                                        <th style={{minWidth:"100px"}}>ประเภทรถ</th>
                                                                        <th style={{minWidth:"100px"}}>ราคา</th>
                                                                        <th style={{minWidth:"120px"}}>โปรโมชั่น</th>
                                                                        <th style={{minWidth:"150px"}}>จังหวัดต้นทาง</th>
                                                                        <th style={{minWidth:"150px"}}>จังหวัดปลายทาง</th>
                                                                        <th style={{minWidth:"150px"}} className={this.renderSorting("create_date")} onClick={() => this.sort("create_date", refetch)}>วันที่ค้นหา</th>
                                                                        <th style={{minWidth:"100px"}}>เครื่องมือ</th>
                                                                    </tr>
                                                                    <tr className="tableFilter">
                                                                        <th>
                                                                            <FormControl
                                                                                type="text"
                                                                                value={this.state.account_info_email}
                                                                                placeholder="ค้นหาอีเมล"
                                                                                onChange={(event) => this.setState({ account_info_email: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                name="account_info_email"
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                            <FormControl
                                                                                componentClass="select"
                                                                                placeholder="select"
                                                                                defaultValue={this.state.show_shipment_status}
                                                                                onChange={(event) => this.handleSeachStatus(event.target.value,refetch)}
                                                                                name="shipment_status">
                                                                                <option value={0}>ทั้งหมด</option>
                                                                                <option value={2}>Booked</option>
                                                                                <option value={7}>จ่ายเงินไม่ผ่าน</option>
                                                                                <option value={9}>Not Book</option>
                                                                            </FormControl>
                                                                        </th>
                                                                        <th>
                                                                        <th>
                                                                            <FormControl
                                                                                componentClass="select"
                                                                                placeholder="select"
                                                                                defaultValue={this.state.call_from}
                                                                                onChange={(event) => this.setState({ call_from: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                
                                                                                name="shipment_status">
                                                                                <option value={0}>ทั้งหมด</option>
                                                                                <option value={1}>App</option>
                                                                                <option value={2}>
                                                                                Web</option>
                                                                            </FormControl>
                                                                        </th>
                                                                        </th>
                                                                        <th>
                                                                        </th>
                                                                        <th>
                                                                        </th>
                                                                        <th>
                                                                            <FormControl
                                                                                type="text"
                                                                                value={this.state.promotion_name}
                                                                                placeholder="ค้นหาโปรโมชั่น"
                                                                                onChange={(event) => this.setState({ promotion_name: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                name="promotion_name"
                                                                            />
                                                                        </th>
                                                                        <th>
                                                                        </th>
                                                                        <th>
                                                                        </th>
                                                                        <th>
                                                                            <DateRangePicker 
                                                                                onApply={(event,picker) => this.handleDateRangePicker(event,picker,refetch)} 
                                                                                onCancel={() => this.setState({create_date : "",show_create_date : ""},() => { refetch() })} 
                                                                                containerStyles={{display: "block"}}
                                                                                opens='right'
                                                                                format='DD/MM/YYYY'
                                                                            >
                                                                                <FormControl
                                                                                    type="text"
                                                                                    value={this.state.show_create_date}
                                                                                    placeholder="ค้นหาวันที่ค้นหา"
                                                                                    name="create_date"
                                                                                />
                                                                            </DateRangePicker>
                                                                        </th>
                                                                        <th>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(loading || error) ?
                                                                        (
                                                                            <tr>
                                                                                <td colSpan="10" className="textCenter">{(loading) ? "Loading..." : error.message}</td>
                                                                            </tr>
                                                                        ) : 
                                                                        data.getPriceLogList.log.map((prop, key) => (
                                                                            <tr key={key}>
                                                                                <td>{prop.account_info_email}</td>
                                                                                <td style={{ width: "130px" }}>
                                                                                    {(prop.shipment_status == 2 ? <Button bsStyle="link" style={{ color: "#18C805" }} onClick={() => window.open(`/shipmentdetail?shipment=${prop.shipment_id}`,'_blank')}> Booked <i style={{ color: "#18C805" }} className="fas fa-check-circle"></i></Button> : 
                                                                                        prop.shipment_status == 7 ? <Button bsStyle="link" style={{ color: "#ED1C24" }} onClick={() => window.open(`/shipmentdetail?shipment=${prop.shipment_id}`,'_blank')}> จ่ายเงินไม่ผ่าน <i className="fas fa-times-circle"></i></Button> : <Button bsStyle="link"> Not Book </Button>)}
                                                                                </td>
                                                                                <td style={{ width: "130px" }}>
                                                                                    {(prop.call_from == 1 ? <Button bsStyle="link" style={{ color: "#fd6d00" }}><i className="fas fa-mobile-android-alt"></i> App </Button> : <Button bsStyle="link" style={{ color: "#00bffd" }}><i className="fas fa-desktop"></i> Web </Button>)}
                                                                                </td>
                                                                                <td>{prop.setting_truck_name.th} {prop.truck_type_name.th}</td>
                                                                                <td>{prop.total_price ? `${prop.total_price.toLocaleString('en-US', { maximumFractionDigits: 2})} บาท` : "-"} </td>
                                                                                <td>{prop.promotion_name ? prop.promotion_name : "-"} {prop.discount[0] ? (<div>[{prop.discount[0].percent}%] { prop.discount[0].price.toLocaleString('en-US', { maximumFractionDigits: 2})} บาท </div>) : null } </td>
                                                                                <td>{prop.origin_province}</td>
                                                                                <td>{prop.destination_province}</td>
                                                                                <td style={{ width: "130px" }}><Moment format="DD/MM/YYYY HH:mm:ss">{prop.create_date}</Moment></td>
                                                                                <td>
                                                                                    <a href={`https://www.google.co.th/maps/dir/${prop.lat_origin},${prop.lng_origin}/${prop.lat_destination},${prop.lng_destination}`} target="_blank" className="btn btn-default"> ดูบนแผนที่ </a>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>        
                                                        </div>
                                                    }
                                                />

                                                <div style={{ textAlign: "right" }}>

                                                    {(loading || error) ? null :
                                                        (<ReactPaginate pageCount={data.getPriceLogList.total_page} forcePage={this.state.page_number - 1} containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected, data.getPriceLogList.total_page, refetch); }} />)
                                                    }
                                                </div>

                                                <Modal show={this.state.showReport} onHide={() => this.setState({ showReport: false })}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>รายงานการค้นหาราคาขนส่ง</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row style={{ marginBottom: "10px" }}>
                                                            <Col md={12}> ช่วงวันที่</Col>
                                                        </Row>
                                                        <FormGroup controlId="formValidationError1">
                                                            <DateRangePicker 
                                                                onApply={(event,picker) => this.handleDateRangePickerReport(event,picker)} 
                                                                onCancel={() => this.setState({reportDate : "",show_reportDate : ""})} 
                                                                containerStyles={{display: "block"}}
                                                                opens='right'
                                                                format='DD/MM/YYYY'
                                                            >
                                                                <FormControl
                                                                    type="text"
                                                                    value={this.state.show_reportDate}
                                                                    placeholder="ค้นหาวันที่ค้นหา"
                                                                    name="reportDate"
                                                                />
                                                            </DateRangePicker>
                                                        </FormGroup>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" onClick={() => this.exportReportPriceLog()}>ดาวน์โหลดรายงาน</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            
                                            </div>
                                        )
                                }
                            }
                        </Query>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Shipper;