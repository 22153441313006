import React, { Component } from 'react';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment/moment.js';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { MDBDataTable } from 'mdbreact';

// Utils
import { formatDateTime } from '../../utils/dateTime';
import formatCurrency from '../../utils/formatCurrency';

// Component
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import styled from 'styled-components';
import { Tag } from './components';
import Card from 'components/Card/Card.jsx';
import Autosuggest from 'react-autosuggest';
import PaymentOrderDetail from './container/PaymentOrderDetail';
import PaymentOrderConfirm from './container/PaymentOrderConfirm';
import DeletePaymentOrder from './container/DeletePaymentOrder';
import CreatePaymentOrderList from './container/CreatePaymentOrderList';
import UpdatePaymentOrder from './container/UpdatePaymentOrder';

// graphql
import dateNowQuery from './graphql/query/dateNow';
import detailShipment from './graphql/query/detailShipment';
import paymentOrderList from './graphql/query/paymentOrderList';
import summaryInvoiceList from './graphql/query/summaryInvoiceList';

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: -20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const RowItemIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px;

  & .icon {
    font-size: 30px;
    padding-right: 10px;
  }

  & .left {
    flex: 1;
  }

  & .right {
    color: #888888;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & i {
      font-size: 20px;
      margin-left: 5px;
    }
  }

  & .text-bold {
    font-weight: bold;
  }
`;

const TableShipmentPVC = styled(Table)`
  & .amount {
    color: #3378b7;
    font-weight: bold;
  }

  & tbody {
    & tr:nth-of-type(odd) {
      background-color: transparent !important;
    }
  }
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ButtonBlue = styled(Button)`
  background-color: #3377b7 !important;
  color: #ffffff !important;
`;

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
  & ul {
    z-index: 200;
  }
`;

const NavItemDropDownList = styled(NavItem)`
  & a {
    & i {
    }
  }
`;

const DataTable = styled(MDBDataTable)`
  & .dataTables_length {
    label {
      font-size: 18px;
    }
  }

  & .dataTables_filter {
    label {
      font-size: 18px;
      float: right;
    }
  }

  & .dataTables_paginate {
    float: right;
  }

  & .dataTable_scrollBody {
    padding-bottom: 100px;
  }
`;

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(document.location.search.substring(1));
    let paramsShipmentId = params.get('shipment');

    this.state = {
      paramsShipmentId: paramsShipmentId,

      //shipment edit payment
      isOpenModalEditPayment: false,
      selectEditPayment: null,

      //modal
      isOpenModalCreateList: false,
      isOpenModalPaymentOrder: false,
      isOpenPaymentOrderDetail: false,
      isOpenDeletePayment: false,
      isOpenCreatePaymentOrderList: false,
      isOpenUpdatePaymentOrder: false,

      //paymentOrder
      paymentOrderId: '',
    };

    this._inputUploadImage = React.createRef();
  }

  paymentStatusTag(paymentStatus) {
    switch (paymentStatus) {
      case 1:
        return <Tag color="yellow">รอตรวจสอบ</Tag>;
      case 2:
        return <Tag color="blue--light">รอสั่งจ่าย</Tag>;
      case 3:
        return <Tag color="blue">สั่งจ่ายแล้ว</Tag>;
      case 4:
        return <Tag color="red">ปฏิเสธ</Tag>;
      case 5:
        return <Tag color="white">ยกเลิก</Tag>;
      default:
        return <Tag>{paymentStatus}</Tag>;
    }
  }

  render() {
    return (
      <div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Query
                query={detailShipment}
                variables={{
                  _id: this.state.paramsShipmentId,
                }}
              >
                {({ loading, error, data, refetch: refetchDetailShipment, variables }) => {
                  if (loading) return 'Loading...';
                  if (error) return { error };
                  const shipment = data.shipmentList.shipment;

                  return (
                    <Col md={12} className="card-shipment">
                      <div style={{ margin: '5px -15px 0' }}>
                        <HeaderContainer>
                          <div className="sub-header-left">
                            <a href="/shipment" className="btn-back">
                              <i className="far fa-arrow-left"></i> กลับ
                            </a>
                            <div className="line"></div>
                            <div className="menu-wrapper">
                              <ul>
                                <li>
                                  <a
                                    href={`/shipmentdetail?shipment=${this.state.paramsShipmentId}`}
                                  >
                                    {' '}
                                    <i className="fal fa-truck"></i> ข้อมูลงานขนส่ง
                                  </a>
                                </li>
                                <li>
                                  <a href={`/shipmentcost?shipment=${this.state.paramsShipmentId}`}>
                                    {' '}
                                    <i className="fal fa-sack-dollar"></i> ต้นทุน - ราคาขาย
                                  </a>
                                </li>
                                <li className="active">
                                  <a href={`/shipmentpvc?shipment=${this.state.paramsShipmentId}`}>
                                    {' '}
                                    <i className="fal fa-money-check-alt"></i> รายการสั่งจ่าย
                                  </a>
                                </li>
                                {/* <li>
                                        <a
                                          href={`/shipmentpayment?shipment=${this.state.paramsShipmentId}`}
                                        >
                                          {' '}
                                          <i className="fal fa-file-invoice-dollar"></i>{' '}
                                          ใบแจ้งหนี้-เพิ่มหนี้
                                        </a>
                                      </li> */}
                              </ul>
                            </div>
                          </div>
                        </HeaderContainer>
                        <SubHeaderContainer>
                          <div className="sub-header-left">
                            <strong>
                              {shipment[0].shipment_number
                                ? `รหัสการขนส่ง: ${shipment[0].shipment_number}`
                                : 'แบบร่าง'}
                            </strong>
                            <Query
                              query={summaryInvoiceList}
                              variables={{
                                shipment_id: this.state.paramsShipmentId,
                              }}
                            >
                              {({ loading, error, data, refetch, variables }) => {
                                if (loading)
                                  return <div style={{ marginLeft: 20 }}>Loading...</div>;
                                if (error) return { error };

                                const summaryInvoice = data.summaryInvoiceList.summaryInvoice[0];

                                return summaryInvoice ? (
                                  <div style={{ marginLeft: 20 }}>
                                    <a
                                      href={`/paymentdetail?id=${summaryInvoice._id}&type=invoice`}
                                      target="_blank"
                                    >
                                      <strong>
                                        {summaryInvoice &&
                                          `เลขที่ใบแจ้งหนี้: ${summaryInvoice.summary_invoice_number}`}
                                      </strong>
                                    </a>
                                  </div>
                                ) : null;
                              }}
                            </Query>
                          </div>
                        </SubHeaderContainer>
                        <Query
                          query={paymentOrderList}
                          variables={{
                            shipment_id: this.state.paramsShipmentId,
                          }}
                        >
                          {({ loading, error, data, refetch, variables }) => {
                            if (loading) return `loading`;
                            if (error) return `Error! ${error.message}`;
                            const dataTable = {
                              columns: [
                                {
                                  label: 'เครื่องมือ',
                                  field: 'tool',
                                  width: 160,
                                },
                                {
                                  label: 'วันที่ใบสั่งจ่าย',
                                  field: 'paymentCreateDate',
                                  width: 140,
                                },
                                {
                                  label: 'รหัสใบสั่งจ่าย',
                                  field: 'paymentNumber',
                                  sort: 'desc',
                                  width: 140,
                                },
                                {
                                  label: 'จำนวนตั้งเบิก (บาท)',
                                  field: 'totalPrice',
                                  width: 140,
                                },
                                {
                                  label: 'สถานะ',
                                  field: 'paymentOrderStatus',
                                  width: 120,
                                },
                                {
                                  label: 'รอบจ่าย',
                                  field: 'roundPaidDate',
                                  width: 150,
                                },
                                {
                                  label: 'ผู้รับเงิน (เจ้าหนี้)',
                                  field: 'transporterName',
                                  width: 120,
                                },
                                {
                                  label: 'พนักงานขับรถ',
                                  field: 'driverName',
                                  width: 150,
                                },
                                {
                                  label: 'ผู้ตั้งเบิก',
                                  field: 'adminUsername',
                                  width: 180,
                                },
                                {
                                  label: 'วันที่ใบสำคัญจ่าย',
                                  field: 'voucherCreateDate',
                                  width: 150,
                                },
                                {
                                  label: 'รหัสใบสำคัญจ่าย',
                                  field: 'voucherNumber',
                                  width: 150,
                                },
                              ],
                              rows: data.paymentOrderList.payment_order.map((item, index) => {
                                return {
                                  key: index,
                                  tool: (
                                    <ToolContainer>
                                      <a
                                        className="btn btn-default"
                                        onClick={() => {
                                          this.setState({
                                            paymentOrderId: item._id,
                                            isOpenPaymentOrderDetail: true,
                                          });
                                        }}
                                      >
                                        <i className="fal fa-search" style={{ paddingTop: 3 }}></i>
                                      </a>
                                      {item.payment_order_status === 1 ||
                                      (item.payments && item.payments.payment_status === 4) ? (
                                        <NavDropdownContainer
                                          title={
                                            <Button>
                                              เครื่องมือ
                                              <i className="fal fa-angle-down"></i>
                                            </Button>
                                          }
                                        >
                                          {item.payment_order_status === 1 ||
                                          (item.payments && item.payments.payment_status === 4) ? (
                                            <>
                                              <NavItemDropDownList
                                                onClick={() => {
                                                  this.setState({
                                                    paymentOrderId: item._id,
                                                    isOpenUpdatePaymentOrder: true,
                                                  });
                                                }}
                                              >
                                                อัพเดท
                                              </NavItemDropDownList>
                                            </>
                                          ) : null}
                                          {(item.payment_order_status === 1 &&
                                            item.payments === null) ||
                                          (item.payment_order_status === 1 &&
                                            item.payments.payment_status === 4) ? (
                                            <NavItemDropDownList
                                              onClick={() => {
                                                this.setState({
                                                  paymentOrderId: item._id,
                                                  isOpenPaymentOrderConfirm: true,
                                                });
                                              }}
                                            >
                                              ส่งตั้งเบิก
                                            </NavItemDropDownList>
                                          ) : null}
                                          {item.payments === null &&
                                          item.payment_order_status === 1 ? (
                                            <NavItemDropDownList
                                              onClick={() => {
                                                this.setState({
                                                  paymentOrderId: item._id,
                                                  isOpenDeletePayment: true,
                                                });
                                              }}
                                            >
                                              ลบ
                                            </NavItemDropDownList>
                                          ) : null}
                                        </NavDropdownContainer>
                                      ) : null}
                                    </ToolContainer>
                                  ),
                                  paymentCreateDate:
                                    item.payments && item.payments.create_date
                                      ? item.payments.create_date
                                      : '-',
                                  paymentNumber:
                                    item.payments && item.payments.payment_number
                                      ? item.payments.payment_number
                                      : '-',
                                  totalPrice: item.total_price
                                    ? formatCurrency(item.total_price)
                                    : '-',
                                  // paymentOrderStatus:
                                  //   item.payment_voucher &&
                                  //   item.payment_voucher.voucher_status === '4' ? (
                                  //     <Tag color="green">ทำจ่ายแล้ว</Tag>
                                  //   ) : item.payment_voucher &&
                                  //     item.payment_voucher.voucher_status === '5' ? (
                                  //     <Tag color="red">ปฏิเสธ</Tag>
                                  //   ) : item.payment_voucher &&
                                  //     item.payment_voucher.voucher_status === '6' ? (
                                  //     <Tag color="white">ยกเลิก</Tag>
                                  //   ) : (item.payment_voucher &&
                                  //       item.payment_voucher.voucher_status !== '4') ||
                                  //     (item.payment_voucher &&
                                  //       item.payment_voucher.voucher_status !== '5') ||
                                  //     (item.payment_voucher &&
                                  //       item.payment_voucher.voucher_status !== '6') ||
                                  //     item.payments ? (
                                  //     this.paymentStatusTag(item.payments.payment_status)
                                  //   ) : (
                                  //     <Tag color="blue--light">รอตั้งเบิก</Tag>
                                  //   ),
                                  paymentOrderStatus: item.payments ? (
                                    this.paymentStatusTag(item.payments.payment_status)
                                  ) : (
                                    <Tag color="blue--light">รอตั้งเบิก</Tag>
                                  ),
                                  roundPaidDate: formatDateTime(item.round_paid_date),
                                  transporterName:
                                    item.transporter_name === 'GIZTIX'
                                      ? item.driver_name
                                      : item.transporter_name,
                                  driverName: item.driver_name,
                                  adminUsername: item.admin_username,
                                  voucherCreateDate:
                                    item.payments && item.payment_voucher.create_date
                                      ? item.payment_voucher.create_date
                                      : '-',
                                  voucherNumber:
                                    item.payments && item.payment_voucher.voucher_number
                                      ? item.payment_voucher.voucher_number
                                      : '-',
                                };
                              }),
                            };

                            return (
                              <Col md={12}>
                                <CardTable
                                  ctTableFullWidth
                                  ctTableResponsive
                                  title="ตารางแสดงรายการสั่งจ่าย"
                                  category="ข้อมูลงานขนส่งจากระบบออนไลน์ Marketplace Marketplace"
                                  // rightButton={
                                  //   <div style={{ float: 'right', marginLeft: '15px' }}>
                                  //     <Button
                                  //       className="btn btn-primary"
                                  //       onClick={() => {
                                  //         this.setState({
                                  //           isOpenCreatePaymentOrderList: true,
                                  //           shipmentId: this.state.paramsShipmentId,
                                  //         });
                                  //       }}
                                  //     >
                                  //       สร้างรายการ
                                  //     </Button>
                                  //   </div>
                                  // }
                                  content={
                                    <DataTable
                                      hover
                                      noBottomColumns
                                      entriesLabel="รายการแสดงผล"
                                      searchLabel="ค้นหา"
                                      noRecordsFoundLabel="ไม่พบข้อมูล"
                                      paginationLabel={['<', '>']}
                                      data={dataTable}
                                      scrollX
                                    />
                                  }
                                />
                              </Col>
                            );
                          }}
                        </Query>
                      </div>
                    </Col>
                  );
                }}
              </Query>
              {this.state.paymentOrderId && (
                <>
                  <DeletePaymentOrder // ลบตั้งเบิก
                    show={this.state.isOpenDeletePayment}
                    paymentOrderId={this.state.paymentOrderId}
                    closePaymentOrderDetail={() => {
                      this.setState({ isOpenDeletePayment: false });
                      this.setState({ isOpenPaymentOrderDetail: false });
                    }}
                    onHide={() => {
                      this.setState({ isOpenDeletePayment: false });
                    }}
                  />
                  <PaymentOrderDetail // รายละเอียดใบสั่งจ่าย
                    show={this.state.isOpenPaymentOrderDetail}
                    paymentOrderId={this.state.paymentOrderId}
                    onHide={() => {
                      this.setState({ isOpenPaymentOrderDetail: false });
                    }}
                  />
                  <PaymentOrderConfirm // ส่งตั้งเบิก
                    show={this.state.isOpenPaymentOrderConfirm}
                    paymentOrderId={this.state.paymentOrderId}
                    closePaymentOrderDetail={() => {
                      this.setState({ isOpenPaymentOrderConfirm: false });
                      this.setState({ isOpenPaymentOrderDetail: false });
                    }}
                    onHide={() => {
                      this.setState({ isOpenPaymentOrderConfirm: false });
                    }}
                  />
                  <UpdatePaymentOrder // อัพเดท
                    show={this.state.isOpenUpdatePaymentOrder}
                    paymentOrderId={this.state.paymentOrderId}
                    onHide={() => {
                      this.setState({ isOpenUpdatePaymentOrder: false });
                    }}
                  />
                </>
              )}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(graphql(dateNowQuery, { name: 'dateNow' }))(ShipmentDetail);
