import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Modal,
  Table,
  FormControl,
  Label,
} from 'react-bootstrap';
import styled from 'styled-components';
import s3 from 'config/s3';

import { Card, Input, SelectBox, Select } from './components';
import accountInfoListQuery from './graphql/query/accountInfoList';
import accountUserUpdateMutation from './graphql/mutation/accountUserUpdate';
import accountInfoUpdateMutation from './graphql/mutation/accountInfoUpdate';
import { Mutation, graphql } from 'react-apollo';
import Moment from 'react-moment';
import { compose } from 'redux';
import NotificationSystem from 'react-notification-system';
import EditUser from './components/EditUser';

//react tag input
const KeyCodes = {
  comma: 188,
  enter: 13,
};

//styled component
const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const Container = styled.div`
	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}

	& .text-green {
		color: #19AA19;
	}

	& .text-blue {
		color: #318DE8;
	}

	& .text-red {
		color: #FF0000;
	}

	& .text-grey {
		color: #F2F2F2;
	}

	& .text-primary {
		color: #D90101 !important;
	}
`;

const TextHeader = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
`;

const TextSubHeader = styled.div`
  font-size: 18px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
`;

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  padding-left: 35px !important;
  margin-top: 12px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  &:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }

  & input:checked ~ .checkmark {
    border: 1px solid #ffffff;
    background-color: #19aa19;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const TextRight = styled.div`
  height: 40px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const TextContent = styled.div`
  font-size: 18px;
  line-height: 1;
  margin-top: 10px;
`;

const TextMessageError = styled.span`
  font-size: 18px;
  color: #ff0000;
`;

const selectOptionTypeBusiness = [
  { value: '', label: '-- เลือก --' },
  { value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
  { value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
  { value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
  { value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
  { value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
  { value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
  { value: 'การบริการ', label: 'การบริการ' },
  { value: 'เครื่องจักร', label: 'เครื่องจักร' },
  { value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
  { value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
  { value: 'บ้านและสวน', label: 'บ้านและสวน' },
  { value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
  { value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
  { value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
  { value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const selectOptionCountry = [
  { value: '', label: '-- เลือก --' },
  { value: 'ไทย', label: 'ไทย' },
];

const StatusContainer = styled.div`
  	margin-bottom: 15px;
  	border-radius: 6px;
 	border: 1px solid #E3E3E3;
  	background-color: #FFFFFF;
	padding: 20px;
	
	& .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		& .header-text .lable{
			padding: 3px 10px;
		}

		&-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 44px
			height: 44px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.1);

			& i {
				margin: auto;
				font-size: 20px;
			}

			&-danger {
				background-color: rgba(217, 1, 1, 0.1);

				& i {
					color: #D90101;
				}
			}

			&-success {
				background-color: rgba(25, 170, 25, 0.1);

				& i {
					color: #19AA19;
				}
			}
		}

		&-text {
			flex: 1;
			margin-left: 8px;
			line-height: 1;
			font-size: 18px;
			font-weight: bold;
		}

		& .nomargin {
			margin: 0;
		}
	}

	& .detail {
		font-size:18px;
		line-height: 1.4;
		margin-top: 10px;

		&-list {
			display: flex;
			flex-direction: row;

			&-left {
				align-self: flex-start;
				flex: 1;
			}

			&-right {
				align-self: flex-end;
			}
		}
	}

	& .text-danger {
		color: #D90101;
		font-weight: bold;
	}
`;

const CreditContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  line-height: 1;
  align-items: flex-end;

  & .text-header {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  & .text-bold {
    font-weight: bold;
  }

  & .text-medium {
    font-size: 24px;
  }

  & .text-large {
    font-size: 28px;
  }

  & .line {
    background-color: #e3e3e3;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;
const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const FileContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  align-items: center;
  padding: 5px 0px 5px 10px;
  height: 40px;
  box-sizing: border-box;

  & .file-left {
    flex: 1;
    display: flex;
    flex-direction: row;

    &-icon {
      font-size: 20px;
      margin-right: 10px;
    }

    &-text {
      font-size: 18px;
    }
  }

  & .file-right {
    display: flex;
    flex-direction: row;

    &-filename {
      margin-right: 10px;
    }

    &-delete {
      margin-right: 10px;
      cursor: pointer;
    }

    & button {
      border-radius: 0px;
      border-left-width: 1px;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
    }
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const TagsEmail = styled.div`
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  padding: 11px;
  line-height: 1;
  background-color: #f9f9f9;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;

  & span.delete {
    font-size: 18px;
    color: #cacaca;
    cursor: pointer;
    margin-left: 5px;
  }
`;

class ShipperDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShipperNumber: '',
      ShipperName: '',
      accountInfoId: '',
      account_info_business_name: '',
      account_info_business_type: '',
      account_info_email: '',
      account_info_phone_number: '',
      account_users: [],
      blockModal: false,
      allowModal: false,
      account_info_status: '',
      account_info_number: '',
      account_info_customer_type: '',
    };

    this.notificationSystem = React.createRef();
  }

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  componentWillReceiveProps(newProps) {
    const { loading, error } = newProps.accountInfoListQuery;
    if (!loading && !error) {
      const {
        accountInfoList: { account_info },
        accountInfoList: { account_business },
      } = newProps.accountInfoListQuery;

      const accountInfo = account_info[0];
      const accountBusiness = account_business;

      if (accountInfo === null) {
        window.location.href = '/shipper';
        return;
      }

      console.log('accountInfo', account_info[0]);
      this.setState({
        ShipperNumber: account_info[0].account_info_number,
        ShipperName: account_info[0].account_info_business_name,
        accountInfoId: account_info[0]._id,
        account_info_business_name: account_info[0].account_info_business_name,
        account_info_business_type: account_info[0].account_info_business_type.th,
        account_info_email: account_info[0].account_info_email,
        account_info_phone_number: `(${account_info[0].account_info_phone_code}) ${account_info[0].account_info_phone_number}`,
        account_users: account_info[0].account_users,
        account_info_status: account_info[0].account_info_status,
        account_info_number: account_info[0].account_info_number,
        account_info_customer_type: account_info[0].account_info_customer_type,
      });
    }
  }

  renderUserType(type) {
    switch (type) {
      case 1:
        return <Label bsStyle="primary">Main User</Label>;
      case 2:
        return <Label bsStyle="default">Sub User</Label>;
      default:
        return '';
    }
  }
  renderUserstatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="success">เปิดใช้งาน</Label>;
      case 2:
        return <Label bsStyle="danger">บล๊อค</Label>;
      default:
        return '';
    }
  }

  renderUserPermission(status) {
    switch (status) {
      case 1:
        return (
          <button
            className="btn btn-red"
            style={{ padding: '8.5px 20px' }}
            onClick={() => this.setState({ blockModal: true })}
          >
            บล็อค
          </button>
        );
      case 2:
        return (
          <button className="btn btn-green" onClick={() => this.setState({ allowModal: true })}>
            เปิดใช้งาน
          </button>
        );
      default:
        return null;
    }
  }

  handleUserUpdate(accountInfoId, userId, status, accountUserUpdate) {
    if (accountInfoId && userId && status && accountUserUpdate) {
      accountUserUpdate({
        variables: {
          account_info_id: accountInfoId,
          _id: userId,
          account_user_status: status,
        },
      });
    }
  }

  renderCustomerType = status => {
    switch (status) {
      case 0:
        return <span>ทั้งหมด</span>;
      case 1:
        return (
          <span>
            <span className="label" style={{ backgroundColor: '#FED249' }}>
              <i className="fal fa-user" />
            </span>{' '}
            B2C{' '}
          </span>
        );
      case 2:
        return (
          <span>
            <span className="label label-warning">
              <i className="fal fa-users" />
            </span>{' '}
            B2B-N
          </span>
        );
      case 3:
        return (
          <span>
            <span className="label label-success">
              <i class="fal fa-donate" />
            </span>{' '}
            GTX BU
          </span>
        );
      case 4:
        return (
          <span>
            <span className="label label-primary">
              <i class="fal fa-gem" />
            </span>{' '}
            Key Account
          </span>
        );
      default:
        return '';
    }
  };

  render() {
    return (
      <Container>
        <div className="content">
          <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
          <HeaderContainer>
            <div className="sub-header-left">
              <a href="/shipper" className="btn-back">
                <i className="far fa-arrow-left"></i> กลับ
              </a>
              <div className="line"></div>
              <div className="menu-wrapper">
                <ul>
                  <li className="active">
                    <a href={`/shipperdetail?id=${window.location.search.split('?id=')[1]}`}>
                      {' '}
                      <i className="fal fa-address-card"></i> ข้อมูลการลงทะเบียน
                    </a>
                  </li>
                  <li>
                    <a href={`/shippershipment?id=${window.location.search.split('?id=')[1]}`}>
                      {' '}
                      <i className="fal fa-truck"></i> รายการขนส่ง
                    </a>
                  </li>
                  <li>
                    <a href={`/shipperdriver?id=${window.location.search.split('?id=')[1]}`}>
                      {' '}
                      <i className="fal fa-user-hard-hat"></i> พนักงานขับรถคนโปรด
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </HeaderContainer>
          <SubHeaderContainer>
            <div className="sub-header-left">
              <strong>
                CU{this.state.ShipperNumber} : {this.state.ShipperName}
              </strong>
            </div>
            <div className="sub-header-right">
              {this.renderUserPermission(this.state.account_info_status)}
            </div>
          </SubHeaderContainer>
          <Grid fluid>
            <Row>
              <Col md={9} style={{ paddingBottom: 20 }}>
                <Card>
                  <TextHeader>ข้อมูลโปรไฟล์</TextHeader>
                  <TextSubHeader>รายละเอียดที่แสดงเป็นข้อมูลจริงที่ใช้บนระบบ Giztix</TextSubHeader>
                  <Row style={{ marginTop: 20 }}>
                    <Col md={6} sm={12}>
                      <div style={{ marginTop: 10 }}>
                        <Input
                          styleInput={{ marginTop: 6 }}
                          disabled={true}
                          value={this.state.account_info_business_name}
                          style={{ marginTop: 10 }}
                          inputLabel="ชื่อบริษัท"
                          placeholder="ระบุ"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <div style={{ marginTop: 10 }}>
                        <Input
                          styleInput={{ marginTop: 6 }}
                          disabled={true}
                          value={this.state.account_info_business_type}
                          style={{ marginTop: 10 }}
                          inputLabel="ประเภทลูกค้า"
                          placeholder="ระบุ"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <div style={{ marginTop: 10 }}>
                        <Input
                          styleInput={{ marginTop: 6 }}
                          disabled={true}
                          value={this.state.account_info_email}
                          style={{ marginTop: 10 }}
                          inputLabel="อีเมล"
                          placeholder="ระบุ"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} sm={12}>
                      <div style={{ marginTop: 10 }}>
                        <Input
                          styleInput={{ marginTop: 6 }}
                          disabled={true}
                          value={this.state.account_info_phone_number}
                          style={{ marginTop: 10 }}
                          inputLabel="เบอร์โทรศัพท์"
                          placeholder="ระบุ"
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md={3} style={{ paddingBottom: 20 }}>
                <StatusContainer>
                  <div className="header">
                    <div className="header-text nomargin">
                      <Label bsStyle="primary">ผู้ส่งสินค้า</Label>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="detail-list">
                      <div className="detail-list-left">รหัสลูกค้า :</div>
                      <div className="detail-list-right">CU{this.state.account_info_number}</div>
                    </div>
                    <div className="detail-list">
                      <div className="detail-list-left">ประเภทลูกค้า :</div>
                      <div className="detail-list-right">
                        {this.renderCustomerType(this.state.account_info_customer_type)}
                      </div>
                    </div>
                    <div className="detail-list">
                      <div className="detail-list-left">สถานะ :</div>
                      <div className="detail-list-right">
                        {this.renderUserstatus(this.state.account_info_status)}
                      </div>
                    </div>
                  </div>
                </StatusContainer>
                {this.state.account_users.map((prop, key) => {
                  return (
                    <StatusContainer key={key}>
                      <div className="header">
                        <div className="header-text nomargin">
                          {this.renderUserType(prop.account_user_type)}
                        </div>
                        <div
                          style={{
                            marginTop: '10px',
                          }}
                          className="detail-list-right"
                        >
                          <EditUser
                            username={prop.account_user_display_name}
                            _id={prop._id}
                            infoId={this.state.accountInfoId}
                          />
                        </div>
                      </div>
                      <div className="detail">
                        <div className="detail-list">
                          <div className="detail-list-left">ชื่อที่ใช้แสดง :</div>
                          <div className="detail-list-right">{prop.account_user_display_name}</div>
                        </div>
                        <div className="detail-list">
                          <div className="detail-list-left">อีเมล :</div>
                          <div className="detail-list-right">{prop.account_user_name}</div>
                        </div>
                        <div className="detail-list">
                          <div className="detail-list-left">เบอร์โทรศัพท์ :</div>
                          <div className="detail-list-right">
                            ({prop.account_user_phone_code}) {prop.account_user_phone_number}
                          </div>
                        </div>
                        <div className="detail-list">
                          <div className="detail-list-left">สถานะ :</div>
                          <div className="detail-list-right">
                            {this.renderUserstatus(prop.account_user_status)}
                          </div>
                        </div>
                      </div>
                    </StatusContainer>
                  );
                })}
              </Col>
            </Row>
          </Grid>
        </div>
        <Mutation
          mutation={accountInfoUpdateMutation}
          variables={{
            _id: this.state.accountInfoId,
            account_info_status: 2,
          }}
          update={(cache, { data }) => {
            this.addNotification('success', 'บันทึกสำเร็จแล้ว');
            this.setState({ blockModal: false });
          }}
          onError={error => {
            console.log(error.message.replace('GraphQL error: ', ''));
            this.addNotification('error', error.message.replace('GraphQL error: ', ''));
          }}
          refetchQueries={['search']}
        >
          {(accountUserUpdateBlock, { data, error, loading }) => (
            <Modal
              show={this.state.blockModal}
              backdrop="static"
              onHide={() => this.setState({ blockModal: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>ระงับการใช้งาน ผู้ส่งสินค้า</strong>{' '}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row style={{ textAlign: 'center', fontSize: '18px' }}>
                  <Col>
                    <strong>
                      กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน{' '}
                      <span style={{ color: 'red' }}>
                        เมื่อระงับการใช้งาน ผู้ส่งสินค้าจะไม่สามารถจองงานขนส่งได้
                      </span>{' '}
                    </strong>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={() => accountUserUpdateBlock()}>
                  ยืนยัน
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Mutation>

        <Mutation
          mutation={accountInfoUpdateMutation}
          variables={{
            _id: this.state.accountInfoId,
            account_info_status: 1,
          }}
          update={(cache, { data }) => {
            this.addNotification('success', 'บันทึกสำเร็จแล้ว');
            this.setState({ allowModal: false });
          }}
          onError={error => {
            console.log(error.message.replace('GraphQL error: ', ''));
            this.addNotification('error', error.message.replace('GraphQL error: ', ''));
          }}
          refetchQueries={['search']}
        >
          {(accountUserUpdateAllow, { data, error, loading }) => (
            <Modal
              show={this.state.allowModal}
              backdrop="static"
              onHide={() => this.setState({ allowModal: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>เปิดใช้งาน ผู้ส่งสินค้า</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row style={{ textAlign: 'center', fontSize: '18px' }}>
                  <Col>
                    <strong>กรุณาตรวจสอบข้อมูลก่อนกดยืนยัน</strong>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button bsStyle="primary" onClick={() => accountUserUpdateAllow()}>
                  ยืนยัน
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Mutation>
      </Container>
    );
  }
}

export default compose(
  graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        _id: props.location.search.split('?id=')[1],
      },
    }),
  })
)(ShipperDetail);
