import React from 'react';
import { Button } from 'react-bootstrap';
import formatCurrency from 'utils/formatCurrency';
import { CostPerParcelEachBox } from 'views/CostPerParcel/render/CostPerPacelBoxHeader';
import CostPerPacelModalCreateParcel from './components/CostPerPacelModalCreateParcel';
import CostPerParcelSubHeader from './components/CostPerParcelSubHeader';
import Emitter from './functions/EventEmitter';
import FunctuonChangePrefixToName from './functions/FunctionChangePrefixToName';
import FunctionGroupingArrayByKey from './functions/FunctionGroupingArrayByKey';

const STATE_CONSTANT_COST_PER_PARCEL = {
  COST: 'COST',
  REVENUE: 'REVENUE',
};

let initItem = {
  parentIndex: -1,
  selfIndex: -1,
  item: false,
};

const BoxStyle = {
  border: '1px solid #90909090',
  padding: '10px',
  borderRadius: '5px',
  marginInline: '10px',
};

const RenderRow = ({
  title,
  value,
  styleTitle,
  styleValue,
  styleWrapper = {},
  onClickValue = () => {},
  onClickTitle = () => {},
}) => {
  return (
    <div
      style={{
        ...styleWrapper,
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div onClick={onClickTitle} style={styleTitle}>
        {title}
      </div>
      <div onClick={onClickValue} style={styleValue}>
        {value}
      </div>
    </div>
  );
};

const Line = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '1px',
        backgroundColor: 'rgb(233, 236, 239)',
        margin: '10px 0',
      }}
    />
  );
};

const RenderBox = ({
  data,
  setData,
  setEditIndex,
  setCreateOpen,
  setLoadData,
  setIsEdit,
  isEdit,
}) => {
  let newTotalPrice = 0;
  let newGrandTotalPrice = 0;

  if (data?.serviceItems?.length > 0) {
    newTotalPrice = data?.serviceItems?.reduce((a, b) => {
      return a + b?.total_before_discount;
    }, 0);

    newGrandTotalPrice = data?.serviceItems?.reduce((a, b) => {
      return a + b?.grand_total_price;
    }, 0);
  }

  const dataVat = FunctionGroupingArrayByKey(data, 'vat', 'serviceItems');
  const dataWht = FunctionGroupingArrayByKey(data, 'wht', 'serviceItems');

  return (
    <React.Fragment>
      {setData && (
        <React.Fragment>
          <RenderRow
            title="ราคาต้นทุน"
            styleTitle={{
              fontWeight: 'bold',
              fontSize: '24px',
            }}
            styleWrapper={{
              marginBottom: '15px',
              fontWeight: 'lighter',
              cursor: 'pointer',
            }}
            styleValue={{
              fontWeight: '',
            }}
            value={
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Button
                  style={{
                    borderColor: '#e34942',
                    color: '#e34942',
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    setEditIndex(initItem);
                    setCreateOpen(true);
                  }}
                >
                  <i className="fal fa-plus"></i> เพิ่มรายการ
                </Button>
                <Button
                  bsStyle="primary"
                  style={{ marginLeft: 15 }}
                  onClick={async e => {
                    e.stopPropagation();

                    Emitter.emit('parcel_data', {
                      data: data,
                      total: newGrandTotalPrice,
                    });
                    setLoadData(data);

                    setIsEdit(false);
                  }}
                >
                  <i className="fal fa-save"></i> บันทึก
                </Button>
                <Button
                  style={{ marginLeft: 15 }}
                  onClick={e => {
                    e.stopPropagation();

                    setIsEdit(false);
                  }}
                >
                  ปิด
                </Button>
              </div>
            }
            onClickValue={e => {
              e.stopPropagation();

              setIsEdit(!isEdit);
            }}
          />
          <RenderRow
            styleTitle={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            styleValue={{
              fontWeight: 'bold',
              fontSize: '20px',
            }}
            title="รายการ"
            value="ราคาต้นทุน"
          />
          <Line />
        </React.Fragment>
      )}
      {FunctionGroupingArrayByKey(data, 'prefix', 'serviceItems')?.map((row, index) => {
        return (
          <div key={`key-of-group-${index}`}>
            <RenderRow
              styleTitle={{
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              styleValue={{
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              title={FunctuonChangePrefixToName(row?.prefix)}
              value="หน่วย : บาท"
            />
            {row?.value?.map((item, jindex) => {
              return (
                <React.Fragment key={`key-of-index-${jindex}`}>
                  <RenderRow
                    styleWrapper={{
                      marginBottom: '15px',
                    }}
                    title={
                      <div>
                        {setData && (
                          <React.Fragment>
                            <i
                              onClick={e => {
                                e.stopPropagation();
                                if (item?.selfIndex > 0) {
                                  setData(prev => {
                                    let temp = { ...prev };
                                    let thisTemp = [...temp?.serviceItems];
                                    thisTemp.splice(item?.selfIndex, 1);
                                    temp.serviceItems = thisTemp;
                                    return { ...temp };
                                  });
                                } else {
                                  return;
                                }
                              }}
                              style={{
                                cursor: 'pointer',
                                width: '10px',
                                height: '10px',
                                marginRight: '15px',
                                color: item?.selfIndex > 0 ? 'currentcolor' : 'silver',
                              }}
                              className="fal fa-trash-alt"
                            />

                            <i
                              onClick={e => {
                                e.stopPropagation();
                                setEditIndex({
                                  parentIndex: index,
                                  selfIndex: jindex,
                                  item,
                                });
                                setCreateOpen(true);
                              }}
                              style={{
                                color: 'rgb(40, 139, 237)',
                                width: '10px',
                                height: '10px',
                                marginRight: '15px',
                                cursor: 'pointer',
                              }}
                              className="fal fa-edit"
                            />
                          </React.Fragment>
                        )}
                        {item?.list_name || '-'}
                      </div>
                    }
                    value={formatCurrency(item?.total_before_discount || 0)}
                  />
                </React.Fragment>
              );
            })}
          </div>
        );
      })}
      <Line />

      <RenderRow
        title="(1) รวมค่าขนส่งและค่าบริการ หลังหักส่วนลด"
        value={`${formatCurrency(newTotalPrice || 0)} บาท`}
      />
      <Line />

      {dataVat
        ?.filter(v => {
          return v.vat > 0;
        })
        ?.map((item, index) => {
          let thisVat = 0;
          if (item?.value?.length > 0) {
            thisVat = item?.value?.reduce((a, b) => {
              return a + (b?.price * b?.vat) / 100;
            }, 0);
          }
          return (
            <div key={`key-of-vat-${index}`}>
              <RenderRow
                title={`(${index + 2}) ภาษีมูลค่าเพิ่ม VAT (${item?.vat}%  ค่าบริการ)`}
                value={`${formatCurrency(thisVat || 0)} บาท`}
              />
            </div>
          );
        })}

      {dataWht
        ?.filter(v => {
          return v.wht > 0;
        })
        ?.map((item, index) => {
          let thisWht = 0;
          if (item?.value?.length > 0) {
            thisWht = item?.value?.reduce((a, b) => {
              return a + (b?.price * b?.wht) / 100;
            }, 0);
          }

          const countAfter = dataVat?.filter(v => {
            return v.vat > 0;
          })?.length;

          return (
            <RenderRow
              title={`(${index + 2 + countAfter})  ภาษีหัก ณ ที่จ่าย WHT  (${item?.wht}%)`}
              value={`${formatCurrency(thisWht || 0)} บาท`}
            />
          );
        })}

      {/* {data?.serviceItems
        ?.filter(row => {
          const thisVat = (row?.price * row?.vat) / 100;
          return thisVat > 0;
        })
        ?.map((row, index) => {
          const thisVat = (row?.price * row?.vat) / 100;
          return (
            <div key={`key-of-vat-${index}`}>
              <RenderRow
                title={`(${index + 2}) ภาษีมูลค่าเพิ่ม VAT (${row?.vat}%  ค่าบริการ)`}
                value={`${formatCurrency(thisVat || 0)} บาท`}
              />
            </div>
          );
        })} */}
      {/* {data?.serviceItems
        ?.filter(row => {
          const thisWht = (row?.price * row?.wht) / 100;
          return thisWht > 0;
        })
        ?.map((row, index) => {
          const thisWht = (row?.price * row?.wht) / 100;

          const countAfter = data?.serviceItems?.filter(row => {
            const thisVat = (row?.price * row?.vat) / 100;
            return thisVat > 0;
          })?.length;

          if (thisWht > 0) {
            return (
              <div key={`key-of-wht-${index}`}>
                <RenderRow
                  title={`(${index + 2 + countAfter})  ภาษีหัก ณ ที่จ่าย WHT  (${row?.wht}%)`}
                  value={`${formatCurrency(thisWht || 0)} บาท`}
                />
              </div>
            );
          } else {
            return <React.Fragment />;
          }
        })} */}

      <Line />

      <RenderRow
        title="ราคาต้นทุนรวม"
        styleValue={{
          color: '#d90402',
          fontWeight: 'bold',
          fontSize: '24px',
        }}
        value={`${formatCurrency(newGrandTotalPrice || 0)} บาท`}
      />
      <Line />
    </React.Fragment>
  );
};

const CostPerParcelCost = ({ data, isVat = 0, truckId }) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [dataPrepair, setDataPrepair] = React.useState(null);
  const [editIndex, setEditIndex] = React.useState(initItem);
  const [loadData, setLoadData] = React.useState(false);

  React.useState(() => {
    if (!loadData) {
      setLoadData(data);
    } else {
      return;
    }
  }, [data]);

  React.useEffect(() => {
    if (isEdit && data?.serviceItems?.length > 0 && !dataPrepair) {
      let formatData = { ...data };
      formatData.serviceItems = formatData?.serviceItems.map(item => {
        return {
          ...item,
          prefix: String(item?.list_number)?.slice(0, 2),
          suffix: item?.suffix ? item?.suffix : String(item?.list_number)?.slice(2, 5),
        };
      });

      setDataPrepair(formatData);
    } else {
      return;
    }
  }, [isEdit]);

  // console.log('MAIN DATA :', {
  //   isEdit,
  //   dataPrepair,
  //   newTotalPrice,
  //   newGrandTotalPrice,
  // });

  const canEdit = data?._id === null || data?.costing_status === 4;

  return (
    <React.Fragment>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        {loadData && (
          <div style={BoxStyle}>
            <RenderRow
              title="ราคาต้นทุน"
              styleTitle={{
                fontWeight: 'bold',
                fontSize: '24px',
              }}
              styleWrapper={
                canEdit
                  ? {
                      marginBottom: '15px',
                      fontWeight: 'lighter',
                      cursor: 'pointer',
                    }
                  : {
                      marginBottom: '15px',
                    }
              }
              styleValue={{
                fontWeight: '',
              }}
              value={canEdit ? (isEdit ? '< ยกเลิกการแก้ไข' : 'แก้ไขรายละเอียด >') : ''}
              onClickValue={() => {
                if (canEdit) {
                  setIsEdit(!isEdit);
                } else {
                  return;
                }
              }}
            />
            <RenderRow
              styleTitle={{
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              styleValue={{
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              title="รายการ"
              value="ราคาต้นทุน"
            />
            <Line />
            <RenderBox data={loadData} />
          </div>
        )}
        {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        {isEdit && dataPrepair && (
          <React.Fragment>
            <div style={BoxStyle}>
              <RenderBox
                data={dataPrepair}
                setData={setDataPrepair}
                setCreateOpen={setCreateOpen}
                setEditIndex={setEditIndex}
                setLoadData={setLoadData}
                setIsEdit={setIsEdit}
                isEdit={isEdit}
              />
            </div>
          </React.Fragment>
        )}
      </div>
      <CostPerPacelModalCreateParcel
        open={createOpen}
        setOpen={setCreateOpen}
        isNew={editIndex?.parentIndex !== -1 && editIndex?.selfIndex !== -1 ? false : true}
        truckId={truckId}
        setDataPrepair={setDataPrepair}
        editIndex={editIndex}
      />
    </React.Fragment>
  );
};

const CalcureateVat = data => {
  if (data?.length > 0) {
    const x = data?.map(item => {
      return item?.total_wht;
    });

    return x.reduce((a, b) => a + b, 0);
  } else {
    return 0;
  }
};

const FunctionCheckStatus = input => {
  if (input === 1) {
    return 'รอ agent ยืนยัน';
  } else if (input === 2) {
    return 'รอตรวจสอบ';
  } else if (input === 3) {
    return 'ตรวจสอบแล้ว';
  } else if (input === 4) {
    return 'ปฏิเสธ';
  } else {
    return 'รอส่งราคา';
  }
};

const CostPerParcel = ({ data, productCount = 0, productTarget = 0, truckId }) => {
  const [tab, setTab] = React.useState(STATE_CONSTANT_COST_PER_PARCEL.COST);

  if (data?.parcelCostByShipment) {
    let thisData = data?.parcelCostByShipment;

    // console.log('thisData', thisData);

    return (
      <div
        style={{
          background: '#ffffff',
          padding: '10px',
          borderRadius: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <CostPerParcelEachBox
            icon={'fas fa-file-invoice-dollar'}
            title={'ราคาทุน / ขาย'}
            colorTextStatus={thisData?.costing_status === 4 ? '#b91c1c' : '#404040'}
            colorBgStatus={thisData?.costing_status === 4 ? '#fef2f2' : '#f5f5f5'}
            value={
              <React.Fragment>
                {thisData?.costing_status === 4 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <div>{thisData?.costing_number || '-'}</div>
                    {thisData.reject_remark && (
                      <div
                        style={{
                          color: '#d90402',
                          fontSize: 16,
                        }}
                      >
                        เหตุผล​ : {thisData?.reject_remark}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>{thisData?.costing_number || '-'}</div>
                )}
              </React.Fragment>
            }
            status={true}
            valueStatus={FunctionCheckStatus(thisData?.costing_status)}
          />
          <CostPerParcelEachBox
            icon="fas fa-usd-circle"
            iconColor="#d90402"
            title={'ราคาต้นทุนรวม '}
            value={`${formatCurrency(thisData?.grand_total_price || 0)} บาท`}
            status={true}
            // valueStatus={'รอสั่งจ่าย'}
          />
          <CostPerParcelEachBox
            icon="fas fa-hand-holding-usd"
            iconColor="#337ab7"
            title={'ต้นทุนรายชิ้น(เกิดขึ้นจริง / เป้าหมาย) '}
            value={`${formatCurrency(thisData?.totalCostAvg || 0)}/${formatCurrency(
              thisData?.parcelTarget || 0
            )} บาท`}
          />
          <CostPerParcelEachBox
            icon={'fas fa-money-check-alt'}
            iconColor="#00b24E"
            title={'จำนวนสินค้า( ส่งสำเร็จ / ทั้งหมด)'}
            value={`${productCount}/${productTarget} ชิ้น(${(
              (productCount / productTarget) *
              100
            ).toFixed(2)}%)`}
          />
        </div>

        <div
          style={{
            display: 'flex',
            padding: '10px',
            marginTop: '25px',
          }}
        >
          <CostPerParcelSubHeader
            setValue={setTab}
            value={tab}
            menus={[
              {
                title: 'ราคาต้นทุน',
                stage: STATE_CONSTANT_COST_PER_PARCEL.COST,
                icon: 'fas fa-dollar-sign',
              },
              // {
              //   title: 'ราคาขาย',
              //   stage: STATE_CONSTANT_COST_PER_PARCEL.REVENUE,
              //   icon: 'fas fa-money-bill-alt',
              // },
            ]}
          />
        </div>
        <div
          style={{
            padding: '20px',
          }}
        >
          <CostPerParcelCost
            data={thisData}
            isVat={CalcureateVat(thisData?.all_wht)}
            truckId={truckId}
          />
        </div>
      </div>
    );
  } else {
    return <div>Data Not Found!</div>;
  }
};

export default CostPerParcel;
