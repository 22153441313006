import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';

// graphql
import paymentOrderList from '../../graphql/query/paymentOrderList';
import paymentOrderUpdate from './../../graphql/mutation/paymentOrderUpdate';

const ModalContainer = styled(Modal)`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

class UpdatePaymentOrder extends Component {
  constructor(props) {
    super(props);
    this.state = { messageError: '' };
  }

  render() {
    return (
      <ModalContainer
        show={this.props.show}
        onHide={() => {
          this.props.onHide();
          this.setState({ messageError: '' });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>ต้องการอัพเดทรายการใช่หรือไม่?</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ต้องการอัพเดทรายการนี้
          {this.state.messageError && (
            <div style={{ color: 'red', paddingTop: 15 }}>
              Error: ไม่สามารถอัพเดทได้ <br />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ float: 'left', width: 60 }}
            onClick={() => {
              this.props.onHide();
              this.setState({ messageError: '' });
            }}
          >
            ไม่ใช่
          </Button>
          <Mutation
            mutation={paymentOrderUpdate}
            variables={{
              _id: this.props.paymentOrderId,
              payment_order_status: 1,
            }}
            update={() => {
              this.props.onHide();
            }}
            refetchQueries={() => ['paymentOrderList']}
            onError={error => {
              this.setState({ messageError: error.message });
              console.log('error===', error);
            }}
          >
            {paymentOrderUpdate => {
              return (
                <Button
                  bsStyle="primary"
                  onClick={() => {
                    paymentOrderUpdate();
                    this.setState({ messageError: '' });
                  }}
                  style={{ width: 60 }}
                >
                  ใช่
                </Button>
              );
            }}
          </Mutation>
        </Modal.Footer>
      </ModalContainer>
    );
  }
}

export default UpdatePaymentOrder;
