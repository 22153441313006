import React from 'react';
import classes from './ShipmentTracking.module.scss';
import cn from '../../ShipmentDetail.module.scss';
import Moment from 'react-moment';
import { Row, Col } from 'react-bootstrap';
import { TrackingList } from '../../../../components';
import { Label } from 'react-bootstrap';
import shipmentListQuery from './graphql/query/shipmentList';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';

class ShipmentTracking extends React.Component {
  static contextType = ShipmentDetailContext;

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      default:
        return '';
    }
  }

  getTitleDelivery = item => {
    const { dictrict, delivery_status } = item;
    if (delivery_status === 3) {
      return `ส่งสินค้า - ${dictrict} เรียบร้อยแล้ว`;
    }

    return `ส่งสินค้า - ${dictrict}`;
  };

  getSubTitleDelivery = item => {
    const { delivery_status, signature_date, failure_detail, paid_by_cod } = item;
    let status = '';
    if (delivery_status === 2) {
      status = 'รอดำเนินการ';
    } else if (delivery_status === 3) {
      status = <Moment format="DD/MM/YYYY - HH:mm">{signature_date}</Moment>;
    } else if (delivery_status === 4) {
      const failureDetail = failure_detail ? `(${failure_detail})` : '';
      status = `รอคืนสินค้า ${failureDetail}`;
    }

    if (item.commodity.length > 0) {
      return (
        <div>
          {item.commodity[0].name} : <span className={`${cn.mr5}`}>{status}</span>
          {paid_by_cod === 1 && <span className={`${cn.textRed}`}>[COD]</span>}
        </div>
      );
    }
    return null;
  };

  getColorStatusDelivery = status => {
    if (status === 2) {
      return '#49ACF1';
    } else if (status === 3) {
      return '#5cb85c';
    } else if (status === 4) {
      return '#E70301';
    }

    return null;
  };

  getTitleReturn = item => {
    const { signature_date_return, tag_name } = item;
    if (signature_date_return) {
      return `คืนสินค้า - ${tag_name} เรียบร้อยแล้ว`;
    }

    return `คืนสินค้า - ${tag_name}`;
  };

  getSubTitleReturn = item => {
    const { signature_date_return } = item;
    let status = '';
    if (!signature_date_return) {
      status = 'รอดำเนินการ';
    } else {
      status = <Moment format="DD/MM/YYYY - HH:mm">{signature_date_return}</Moment>;
    }

    return <div>{status}</div>;
  };

  getColorStatusReturn = signature_date_return => {
    if (!signature_date_return) {
      return '#49ACF1';
    }

    return null;
  };

  getColorStatusPickup = commodity => {
    if (commodity.filter(data => data.accept === 2).length === commodity.length) {
      return 'red';
    }

    return null;
  };

  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>การติดตามงาน</div>
        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            if (loading) return 'loading...';
            if (error) return error;

            const { shipmentList, trackingList } = data;
            const shipment = shipmentList.shipment[0];
            const tracking = trackingList.tracking[0];

            return (
              <Row>
                <Col md={12}>
                  <div className={`${cn.mb10}`}>{this.renderStatus(shipment.shipment_status)}</div>
                  <div className={`${cn.textGray} ${cn.textSmall} ${cn.mb10}`}>
                    อัพเดทล่าสุด: <Moment format="DD/MM/YYYY HH:mm">{shipment.last_update}</Moment>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mt10} ${cn.mb10}`}></div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ผู้ดูแลงาน:{' '}
                      <span className={cn.textBold}>{shipment.transporter_name || '-'}</span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      โทร:{' '}
                      <span className={cn.textBold}>{`${shipment.transporter_phone_code ||
                        '-'} ${shipment.transporter_phone_number || '-'}`}</span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      พนักงานขับรถ:{' '}
                      <span className={cn.textBold}>
                        {shipment.driver_name_under_mama
                          ? shipment.driver_name_under_mama || '-'
                          : tracking.driver_name || '-'}
                      </span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ทะเบียนรถ:{' '}
                      <span className={cn.textBold}>
                        {tracking.user_setting_truck_license_plate || '-'}
                      </span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      โทร:{' '}
                      <span className={cn.textBold}>
                        {shipment.driver_tel_under_mama
                          ? `${tracking.driver_phone_code ||
                              '-'} ${shipment.driver_tel_under_mama || '-'}`
                          : `${tracking.driver_phone_code || '-'} ${tracking.driver_phone_number ||
                              '-'}`}
                      </span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>

                  <div className={cn.TrackingWrapper}>
                    {shipment.shipment_status === 4 && shipment.completed_date && (
                      <TrackingList
                        title={`ดำเนินการขนส่งเรียบร้อยแล้ว`}
                        subTitle={
                          <Moment format="DD/MM/YYYY - HH:mm">{shipment.completed_date}</Moment>
                        }
                      />
                    )}

                    {shipment.tracking.tracking_detail_lazada.return.map((item, index) => {
                      return (
                        <TrackingList
                          key={index}
                          arrow
                          title={this.getTitleReturn(item)}
                          subTitle={this.getSubTitleReturn(item)}
                          onPress={() =>
                            this.context.onOpenSubShipmentTracking(
                              this.getTitleReturn(item),
                              index,
                              'return'
                            )
                          }
                          colorIcon={this.getColorStatusReturn(item.signature_date_return)}
                          active={
                            this.context.state.subShipmentTrackingIndex === index &&
                            this.context.state.subShipmentTrackingMode === 'return'
                          }
                        />
                      );
                    })}

                    {shipment.tracking.tracking_detail_lazada.delivery.map((item, index) => {
                      return (
                        <TrackingList
                          key={index}
                          item={item}
                          arrow
                          title={this.getTitleDelivery(item)}
                          subTitle={this.getSubTitleDelivery(item)}
                          onPress={() =>
                            this.context.onOpenSubShipmentTracking(
                              this.getTitleDelivery(item),
                              index,
                              'delivery'
                            )
                          }
                          colorIcon={this.getColorStatusDelivery(item.delivery_status)}
                          active={
                            this.context.state.subShipmentTrackingIndex === index &&
                            this.context.state.subShipmentTrackingMode === 'delivery'
                          }
                        />
                      );
                    })}

                    {shipment.tracking.tracking_detail_lazada.pick_up.map((item, index) => {
                      return (
                        item.signature_date && (
                          <TrackingList
                            key={index}
                            arrow
                            title={`รับสินค้า - ${item.tag_name} เรียบร้อยแล้ว`}
                            subTitle={
                              <Moment format="DD/MM/YYYY - HH:mm">{item.signature_date}</Moment>
                            }
                            onPress={() =>
                              this.context.onOpenSubShipmentTracking(
                                `รับสินค้า - ${item.tag_name} เรียบร้อยแล้ว`,
                                index,
                                'pickup'
                              )
                            }
                            active={
                              this.context.state.subShipmentTrackingIndex === index &&
                              this.context.state.subShipmentTrackingMode === 'pickup'
                            }
                            colorIcon={this.getColorStatusPickup(item.commodity)}
                          />
                        )
                      );
                    })}

                    {shipment.tracking.driver_accept === 1 && (
                      <TrackingList
                        title="ขนส่งยืนยันงาน เรียบร้อยแล้ว"
                        subTitle={
                          <Moment format="DD/MM/YYYY - HH:mm">
                            {shipment.tracking.driver_accept_date}
                          </Moment>
                        }
                      />
                    )}
                  </div>
                </Col>
              </Row>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ShipmentTracking;
