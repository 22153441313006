import React from 'react';
import { StyledModal, TitleWrapper, ColoredButton, FooterWrapper, BodyWrapper } from './styled';
import { Mutation } from 'react-apollo';
import updateCod from './graphql/mutation/updateCod';

const ConfirmPayCodModal = props => {
  const confirmStatus = updateCodMutation => {
    let documents = [];

    documents = props.document.map(data => data);

    //ย้ายไปรอตรวจ cod
    const updateCod = {
      cod_status: 1,
      document: documents,
    };

    console.log('createCod', updateCod);

    updateCodMutation({
      variables: {
        _id: props.idUpdate,
        update: updateCod,
      },
    });
  };

  return (
    <Mutation
      mutation={updateCod}
      update={() => {
        props.close();
        props.closeAll();
      }}
      onError={error => {
        console.log(error);
      }}
      refetchQueries={() => ['newCods']}
    >
      {updateCodMutation => {
        return (
          <StyledModal
            show={props.show}
            labelButtonCancel="ยกเลิก"
            labelButtonConfirm="ใช่, ยืนยัน"
          >
            <TitleWrapper>
              <b style={{ fontSize: '18px' }}>แจ้งเตือน</b>
            </TitleWrapper>
            <BodyWrapper>
              <b>ยืนยันการชำระเงินค่า COD ใช่หรือไม่ ?</b>
              <span>
                * หากส่งแล้วจะถือว่าท่านได้ทำการ <b>“ยืนยัน”</b>{' '}
                ความถูกต้องของข้อมูลชุดนี้แล้วจะไม่สามารถแก้ไขได้อีก
              </span>
            </BodyWrapper>
            <FooterWrapper>
              <ColoredButton
                bgColor="#fff"
                color="#000"
                onClick={() => {
                  props.close();
                }}
              >
                <i className="fas fa-times"></i>
                ยกเลิก
              </ColoredButton>
              <ColoredButton onClick={() => confirmStatus(updateCodMutation)}>
                ใช่, ฉันยืนยัน
              </ColoredButton>
            </FooterWrapper>
          </StyledModal>
        );
      }}
    </Mutation>
  );
};

export default ConfirmPayCodModal;
