import styled from 'styled-components';

export const StyledTag = styled.div`
display
  background-color: #d0e8ff;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
  display: inline;

  &.blue {
    background-color: #3377b7;
    color: #ffffff;

    &--light {
      background-color: #d0e8ff;
      color: #3378b7;
    }
  }

  &.green {
    background-color: #5dba5b;
    color: #ffffff;
  }

  &.red {
    background-color: #d9534f;
    color: #ffffff;
  }

  &.white {
    background-color: #e3e3e3;
    color: #ffffff;
  }

  &.yellow {
    background-color: #fde3a6;
    color: #3e3e3e;
  }
`;

export default StyledTag;
