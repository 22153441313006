import gql from 'graphql-tag';

export default gql`
query {
    adminList(
       my_name 	: true
    ){
      admin{
        external_sale_edit
      }
    }
}
`;
