import React, { Component } from 'react';
import { Row, Col, Radio, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';

import { Input } from '../../components';

// graphql
import updateCostingMutation from '../../graphql/mutation/updateCosting';

const ModalContainer = styled(Modal)`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;

class EditPaymentOrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageError: '',
      messageErrorRemark: '',
      requestStatus: props.requestStatus || 3,
      remark: '',
    };
  }

  render() {
    return (
      <ModalContainer
        show={this.props.show}
        backdrop="static"
        onHide={() => {
          this.props.onHide();
          this.setState({ messageError: '' });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.costingData && this.props.costingData.costing_status === 1 ? (
              <strong>รายการ CT ได้ส่งให้บัญชีอนุมัติแล้ว ต้องการแก้ไขใช่หรือไม่ ?</strong>
            ) : (
              <strong>รายการ CT ได้รับการอนุมัติแล้ว ต้องการแก้ไขใช่หรือไม่ ?</strong>
            )}
          </Modal.Title>
        </Modal.Header>
        {this.props.costingData && this.props.costingData.costing_status === 1 ? (
          <>
            <Modal.Body>
              หากต้องการแก้ไข ราคาต้นทุน-ราคาขาย เนื่องจาก สถานะนะเป็น "รอตรวจสอบ"
              <br />
              <strong>ให้เดินไปบอกบัญชี "ปฎิเสธ" ก่อน</strong>
              <br />
              {/* <Input
                styleInput={{ marginTop: 6 }}
                onChange={event =>
                  this.setState({ remark: event.target.value, messageErrorRemark: '' })
                }
                value={this.state.remark}
                style={{ marginTop: 10 }}
                inputLabel="เหตุผล"
                placeholder="ระบุ"
                required
                messageError={this.state.messageErrorRemark}
              /> */}
              {this.state.messageError && (
                <div style={{ color: 'red', paddingTop: 15 }}>{this.state.messageError}</div>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button
                style={{ float: 'right', width: 60 }}
                onClick={() => {
                  this.props.onHide();
                  this.setState({ messageError: '' });
                }}
              >
                ปิด
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              หากต้องการส่งคำขอแก้ไข ให้เลือกราคาที่ต้องแก้ไข พร้อมใส่เหตุผล <br />
              แล้วกดปุ่ม “ขอแก้ไขรายการ”
              <br />
              {this.state.messageError && (
                <div style={{ color: 'red', paddingTop: 15 }}>{this.state.messageError}</div>
              )}
              <Row style={{ marginBottom: 25 }}>
                <Col md={4}>
                  <CustomRadio
                    value="3"
                    checked={this.state.requestStatus === '3'}
                    onChange={event => this.setState({ requestStatus: event.target.value })}
                    name="radio1"
                    inline
                  >
                    <span className="checkmark"></span> ขอแก้ไขราคาซื้อ-ขาย
                  </CustomRadio>
                </Col>
                <Col md={4}>
                  <CustomRadio
                    value="1"
                    checked={this.state.requestStatus === '1'}
                    onChange={event => this.setState({ requestStatus: event.target.value })}
                    name="radio1"
                    inline
                  >
                    <span className="checkmark"></span> ขอแก้ไขราคาซื้อ
                  </CustomRadio>
                </Col>
                <Col md={4}>
                  <CustomRadio
                    value="2"
                    checked={this.state.requestStatus === '2'}
                    onChange={event => this.setState({ requestStatus: event.target.value })}
                    name="radio1"
                    inline
                  >
                    <span className="checkmark"></span> ขอแก้ไขราคาขาย
                  </CustomRadio>
                </Col>
              </Row>
              <Input
                styleInput={{ marginTop: 6 }}
                onChange={event =>
                  this.setState({ remark: event.target.value, messageErrorRemark: '' })
                }
                value={this.state.remark}
                style={{ marginTop: 10 }}
                inputLabel="เหตุผล"
                placeholder="ระบุ"
                required
                messageError={this.state.messageErrorRemark}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ float: 'left', width: 60 }}
                onClick={() => {
                  this.props.onHide();
                  this.setState({ messageError: '' });
                }}
              >
                ไม่ใช่
              </Button>
              <Mutation
                mutation={updateCostingMutation}
                variables={{
                  shipment_id: this.props.shipmentId,
                  updateCosting: {
                    edit_request: true,
                    edit_request_remark: this.state.remark,
                    edit_request_status: this.state.requestStatus,
                  },
                  updateCostingList: false,
                }}
                update={() => {
                  this.props.onHide();
                }}
                refetchQueries={() => [
                  'shipmentAdmins',
                  'searchCosting',
                  'seachInvoiceList',
                  'shipmentAdmins',
                ]}
                onError={error => {
                  console.log(error.message);
                  let textError =
                    error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message;
                  if (textError === 'Item awaiting create payment order not found.') {
                    textError = 'ไม่พบรายการที่ต้องตั้งเบิกใหม่';
                  } else if (textError === 'Billing system user not found.') {
                    textError = 'ผู้ใช้งานไม่ถูกต้อง';
                  }
                  this.setState({ messageError: textError });
                }}
              >
                {paymentOrderAdd => {
                  return (
                    <Button
                      bsStyle="warning"
                      onClick={() => {
                        if (this.state.remark) {
                          paymentOrderAdd();
                          this.setState({ messageError: '', messageErrorRemark: '' });
                        } else {
                          this.setState({
                            messageError: '',
                            messageErrorRemark: 'กรุณากรอกเหตุผล',
                          });
                        }
                      }}
                    >
                      ขอแก้ไขรายการ
                    </Button>
                  );
                }}
              </Mutation>
            </Modal.Footer>
          </>
        )}
      </ModalContainer>
    );
  }
}

export default EditPaymentOrderList;
