import gql from 'graphql-tag';

export default gql`
query search(
    $shipper: ID
  ){
    shipmentSummaryDashboard(
        shipper : $shipper
      ){
        beforeShipmentAmount
        objectiveShipmentAmount
        beforeTotalAmount
        objectiveTotalAmount
        beforeShipmentAmountCancel
        objectiveShipmentAmountCancel
        beforeTotalAmountCancel
        objectiveTotalAmountCanCel
        nowShipmentAmount
        nowTotalAmount
        nowTotalAmountCancel
        nowShipmentAmountCancel
        timeLeft
      }
}
`;
