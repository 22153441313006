import React from 'react';
import { Query,Mutation} from 'react-apollo';
import classes from './SubShipmentTrackingOrder.module.scss';
import cn from '../../ShipmentDetail.module.scss';
import Moment from 'react-moment';
import { Row, Col ,Button } from 'react-bootstrap';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';
import { TrackingList, LightBoxPreview, OrderList, CheckBoxList } from '../../../../components';
import shipmentListQuery from './graphql/query/shipmentList';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';
import { ModalConfirm } from '../../../../../Lazada/components';
import s3 from 'config/s3';
import moment from 'moment/moment.js'

class SubShipmentTrackingOrder extends React.Component {
  static contextType = ShipmentDetailContext;
  constructor(props) {
    super(props);

    this.state = {
      mode: '',
      commodity:null,
      deliveryObj:null,
    };
  }

  renderCommodityDetail = commodity => {
    let commodityDetailList = [];

    commodityDetailList.push({
      title: 'หยิบสินค้าที่ต้องส่ง ณ จุดนี้',
      content: (
        <>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>{commodity.name}</div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>Order number:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>{commodity.order_number || '-'}</div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>สินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {commodity.name_detail || '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ชื่อผู้รับสินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>
              {commodity.contact_name ? `${commodity.contact_name}, ${commodity.contact_tel}` : '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ที่อยู่:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold} ${cn['no-pb']}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {commodity.address || '-'}
            </div>
          </div>
        </>
      ),
      active: true,
    });

    let pictures = commodity.commodity_picture.map(item => `${s3}/${item}`);
    commodityDetailList.push({
      title: 'ตรวจสอบสภาพสินค้า โดยการถ่ายรูป',
      content: <LightBoxPreview images={pictures} position={commodity.check_lat ? `${commodity.check_lat},${commodity.check_lng}` : null} />,
      active: pictures.length > 0,
    });

    return commodityDetailList.map((item, index) => (
      <CheckBoxList key={index} title={item.title} subTitle={item.subTitle} active={item.active}>
        {item.content}
      </CheckBoxList>
    ));
  };

  renderReturnCommodityDetail = returnPoint => {

    const deliveryPoint = returnPoint.delivery_point[this.context.state.subShipmentTrackingOrderIndex]
    let commodityDetailList = [];
    console.log(deliveryPoint);
    commodityDetailList.push({
      title: 'หยิบสินค้าที่ต้องคืน ณ จุดนี้',
      content: (
        <>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>{deliveryPoint.commodity[0].name}</div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>Order number:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>{deliveryPoint.commodity[0].order_number || '-'}</div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>สินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {deliveryPoint.commodity[0].name_detail || '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ชื่อผู้รับสินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>
              {deliveryPoint.commodity[0].contact_name
                ? `${deliveryPoint.commodity[0].contact_name}, ${deliveryPoint.commodity[0].contact_tel}`
                : '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ที่อยู่:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {deliveryPoint.address || '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>เหตุผล:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold} ${cn['no-pb']}`}>
            <div className={cn.Left}>{deliveryPoint.failure_detail || '-'}</div>
          </div>
        </>
      ),
      active: true,
    });

    // console.log("renderReturnCommodityDetail" , returnPoint)

    let pictures = deliveryPoint.commodity_picture_return.map(item => `${s3}/${item}`);
    commodityDetailList.push({
      title: 'ตรวจสอบสภาพสินค้า โดยการถ่ายรูป',
      content: <LightBoxPreview images={pictures} position={`${returnPoint.check_commodity_return_lat},${returnPoint.check_commodity_return_lng}`} />,
      active: pictures.length > 0,
    });

    return commodityDetailList.map((item, index) => (
      <CheckBoxList key={index} title={item.title} subTitle={item.subTitle} active={item.active}>
        {item.content}
      </CheckBoxList>
    ));
  };


  renderVariables(){

      const {commodity , deliveryObj} = this.state;
      const obj = [];
      const data = deliveryObj ? deliveryObj.delivery_point.filter((element, index, array) => element.commodity[0].name === commodity.name) : [];
    
     if(data.length > 0){
      data.forEach((currentValue, index, arr) => {
          obj.push({
              _id: currentValue._id,
              send_failed_status: 1
          })
        }
      );
     }    
      return obj;
  }


  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>
          {this.context.state.subShipmentTrackingOrderTitle}
          <button
            className={cn.BtnClose}
            onClick={() => this.context.onClose(['subShipmentTrackingOrder'])}
          >
            <i className="fal fa-times"></i>
          </button>
        </div>

        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            if (loading) return 'loading...';
            if (error) return error;

            const { shipmentList } = data;
            const shipment = shipmentList.shipment[0];

            return (
              <div>
                <div className={cn.TrackingWrapper}>
                  <div>
                    {this.context.state.subShipmentTrackingOrderMode === 'pickup' &&
                      this.renderCommodityDetail(
                        shipment.tracking.tracking_detail_lazada.pick_up[
                          this.context.state.subShipmentTrackingIndex
                        ].commodity[this.context.state.subShipmentTrackingOrderIndex]
                      )}

                    {this.context.state.subShipmentTrackingOrderMode === 'return' &&
                      this.renderReturnCommodityDetail(
                        shipment.tracking.tracking_detail_lazada.return[
                          this.context.state.subShipmentTrackingIndex
                        ]
                      )}
                  </div>
                </div>

                <Mutation
                    mutation={lazTrackingUpdateMutation}
                    variables={{
                        shipment_id: shipment._id,
                        _id : shipment.tracking._id,
                        tracking_detail_lazada : {
                            delivery : this.renderVariables()
                        }
                    }}
                    update={(cache, { data }) => {
                        this.setState({isOpenModalLazTrackingUpdate: false , commodity:null, deliveryObj:null});
                    }}
                    refetchQueries={() => ['JDShipmentListDetailSubTracking']}
                >
                    {
                        (lazTrackingUpdate) => {
                            return (
                            <ModalConfirm
                                show={this.state.isOpenModalLazTrackingUpdate}
                                onPressButtonCancel={() => this.setState({isOpenModalLazTrackingUpdate: false})}
                                onPressButtonConfirm={() =>  lazTrackingUpdate() }
                                labelButtonConfirm={`ตกลง`}
                                labelButtonCancel={`กลับ`}
                                styleColorButtonConfirm="primary"
                                titleHeader={`ส่งสถานะให้ลาซาด้า`}
                                onHide={() => this.setState({isOpenModalLazTrackingUpdate: false})}
                            >
                              <div>คุณต้องการยกเลิกการส่งสินค้า งาน <b>{this.state.commodity ? this.state.commodity.name : ''}</b> ใช่หรือไม่ ถ้าใช่ กด <b>"ตกลง"</b></div>
                              
                            </ModalConfirm>
                          )
                        }
                    }
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default SubShipmentTrackingOrder;
