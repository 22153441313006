const FunctionGroupingArrayByKey = (arrayData = [], key = '', inWhere) => {
  try {
    if (inWhere) {
      let output = arrayData[inWhere].reduce(function(previous, current, thisIndex) {
        let occurs = previous.reduce(function(previous_item, current_item, current_index) {
          return current_item[key] === current[key] ? current_index : previous_item;
        }, -1);
        if (occurs >= 0) {
          previous[occurs].value = previous[occurs].value.concat({
            ...current,
            selfIndex: thisIndex,
          });
        } else {
          let obj = {
            [key]: current[key],
            value: [current.value || { ...current, selfIndex: thisIndex }],
          };
          previous = previous.concat([obj]);
        }
        return previous;
      }, []);

      return output;
    } else {
      return [];
    }
  } catch (error) {
    console.error('FunctionGroupingArrayByKey : ', error);
  }
};

export default FunctionGroupingArrayByKey;
