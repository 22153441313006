import React from 'react';

class ShowMenu extends React.Component {
  componentDidMount() {
    localStorage.clear('jdmenu');
    localStorage.clear('lazadamenu');
    localStorage.setItem('giztixmenu', true);
  }

  render() {
    return (window.location = '/');
  }
}

export default ShowMenu;
