import gql from 'graphql-tag';

export default gql`
query seach(
    $shipment_id :ID
) { 
    invoiceBusinessList( 
        shipment_id :$shipment_id 
    ){
        invoice{
            _id
            shipment_id
            summary_invoice_id
            invoice_status
            summary_invoice_number
            summary_invoice_paid_status
            paid_type
            paid_credit_card_detail {
                bank
                last_digits
                brand
                name
            }
            invoice_list {
                _id
                list_type
                list_name
                list_qty
                list_price
                list_total_price
                list_cost_price
                list_cost_total_price
            }
            promotion_code {
                name
                price
                for_you
                title_lang {
                    th
                    en
                }
            }
            invoice_discount {
                detail
                price
                discount_type
                percent
            }
            invoice_business_list {
                _id
                invoice_business_list_category
                invoice_business_list_detail
                invoice_business_list_price
                invoice_business_list_type
                invoice_business_list_qty
                invoice_business_list_total_price
                invoice_business_list_discount
                invoice_business_list_vat
                invoice_business_list_wht
                invoice_business_list_total_vat
                invoice_business_list_total_wht
                invoice_business_list_grand_total_price
                invoice_business_list_cost_price
                invoice_business_list_cost_total_price
                invoice_business_list_cost_discount
                invoice_business_list_cost_vat
                invoice_business_list_cost_wht
                invoice_business_list_cost_total_vat
                invoice_business_list_cost_total_wht
                invoice_business_list_cost_grand_total_price
            }
            total_price
            total_vat
            total_wht1
            total_wht3
            total_wht5
            invoice_business_grand_total_price
            create_date
            payment_date
            last_update
            total_more_vat
            total_more_wht1
            total_more_wht3
            total_more_wht5
            total_more_price
            total_more_grand_price
        }
        invoice_more{
            _id
            invoice_more_status
            shipment_id
            summary_invoice_id
            debit_note_id
            debit_note_number
            debit_note_paid_status
            debit_note_status
            invoice_business_list{
                _id
                invoice_business_list_category
                invoice_business_list_detail
                invoice_business_list_price
                invoice_business_list_type
                invoice_business_list_qty
                invoice_business_list_total_price
                invoice_business_list_discount
                invoice_business_list_vat
                invoice_business_list_wht
                invoice_business_list_total_vat
                invoice_business_list_total_wht
                invoice_business_list_grand_total_price
            }
            total_price
            total_vat
            total_wht1
            total_wht3
            total_wht5
            invoice_business_grand_total_price
        }
        grand_total_price
        grand_total_vat
        grand_total_wht1
        grand_total_wht3
        grand_total_wht5
        business_grand_total_price
    }
} 
`;
