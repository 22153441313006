import styled from 'styled-components';
import { ModalConfirm } from 'views/KaCustomer/components/';
import { FormControl } from 'react-bootstrap';

export const StyledModal = styled(ModalConfirm)`
  .modal-backdrop {
    display: ${props => (props.hide ? 'none' : 'block')};
  }
  .modal-content {
    min-width: 40vw;
    max-width: 40vw;
    min-hieght: 50vh;
    max-hieght: 50vh;
  }

  .modal-dialog {
    min-width: 40vw;
    max-width: 40vw;
  }

  .modal-header {
    display: none;
  }

  .modal-body {
    padding-top: 10px;
    padding-bottom: 0px;
  }
`;

export const DetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  gap: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 0px;
  margin-bottom: 5px;
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
`;

export const ColoredButton = styled.button`
  background-color: ${props => props.bgColor || '#50B55C'};
  color: ${props => props.color || '#fff'};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  pointerevents: ${props => (props.disabled ? 'none' : 'initial')};

  & i {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const FlexListColumn = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  // justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledFormControl = styled(FormControl)`
  color: #000;
  font-weight: bold;
`;

export const CustomPhotoAdd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-color: ${props =>
    props.check ? 'rgba(49, 141, 232, 0.1)' : 'rgba(49, 141, 232, 0.1)'};
  border: ${props => (props.check ? '2px dashed #318DE8' : '2px dashed #318DE8')};
  border-radius: 10px;
`;

export const FlexListRow = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const BodyWrapper = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
