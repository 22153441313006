import gql from 'graphql-tag';

export default gql`
query searchDatamasterServiceCategoryList(
    $_id: ID
    $setting_prefix_id: ID
    $show_data: Int
    $page_number: Int
    $order_by: String
    $order_type: String
    $truck_type_id: ID
){
    datamasterServiceCategoryList(
        _id: $_id
        setting_prefix_id: $setting_prefix_id
        show_data: $show_data
        page_number: $page_number
        order_by:  $order_by
        order_type: $order_type
        truck_type_id : $truck_type_id
    ){
        _id
        job_type_id
        truck_type_id
        position
        service_category_name{
        th
        en
        }
        prefix
        setting_prefix_id
        truck_size_name{
        th
        en
        }
        truck_type_name{
        th
        en
        }
        truck_type_prefix_id
        truck_type_prefix
        datamaster_service_item{
        _id
        datamaster_service_item_number
        truck_type_id
        service_item_name{
            th
            en
        }
        cost
        vat_cost
        wht_cost
        sale
        vat
        wht
        unit
        min_price
        enable
        }
        create_date
    }
}
`;
