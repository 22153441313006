import gql from 'graphql-tag';

export default gql`
  mutation billingCreatePaymentDocument(
    $payment_id: ID!
    $document_type: Int!
    $document_file: String!
    $list_ref: [ID]
  ) {
    billingCreatePaymentDocument(
      payment_id: $payment_id
      document_type: $document_type
      document_file: $document_file
      list_ref: $list_ref
    ) {
      _id
      payment_id
      document_type
      document_file
      list_ref
      delete
      create_date
      last_update
    }
  }
`;
