import React, { Component } from 'react';
import Moment from 'react-moment';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import { Layout, ModalConfirm } from '../../components';
import styled from 'styled-components';
import classesJd from '../../Jd.module.css';
import axios from 'axios';
import { UploadFile } from './container';
import baseUrl from 'config/baseUrl';
import restBaseUrl from 'config/restBaseUrl';
import adminListQuery from './graphql/query/adminList';
import { client } from '../../../../index';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import lazadaImportOrderUpdateMutation from './graphql/mutation/lazadaImportOrderUpdate';
import createShipmentLazadaMutation from './graphql/mutation/createShipmentLazada';
import firebase from 'firebase';
import firebaseConfig from 'config/firebaseConfig';
import customerId from 'config/customerId';
import singleUploadMutation from './graphql/mutation/singleUpload';

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)``;

class BookingFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileStatus: 0,
      createDate: '',
      displayCreateDate: '',
      pickupDate: '',
      displayPickupDate: '',
      pageNumber: 1,
      isOpenModalConfirmCreateShipment: false,
      isOpenModalConfirmDelete: false,

      //select file delete or create
      fileNameBookingFile: '',
      idBookingFile: null,
      isLoading: false,
      showImportModal: false,
      importFile: null,
      importDate: '',
      show_importDate: '',

      //import file single upload
      importFileSingleUpload: null,
    };

    this._inputImportFile = React.createRef();
    this._inputImportFile1 = React.createRef();
    this._inputAttachFileFirstCase = React.createRef();
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  componentDidMount() {
    this.getReportData();
  }

  getReportData = () => {
    try {
      firebase
        .database()
        .ref(`/lazada_import_order`)
        .limitToLast(1)
        .on('value', snapshot => {
          this.props.lazadaImportOrderListQuery.refetch();
        });
    } catch (error) {
      console.log(error);
    }
  };

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="info">กำลังอัพโหลด</Label>;
      case 2:
        return <Label bsStyle="warning">รอสร้างงานขนส่ง</Label>;
      case 3:
        return <Label bsStyle="danger">ผิดพลาด</Label>;
      default:
        return <Label>{status}</Label>;
    }
  }

  handleDateRangePicker(mode, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({ pickupDate: date, displayPickupDate: display });
    }
  }

  handleDateRangePickerImport(event) {
    //console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD HH:mm')}`;
    const show = `${event.format('DD/MM/YYYY HH:mm')}`;

    this.setState({ importDate: date, show_importDate: show });
  }

  uploadFileHandler = async (file, refetchLazadaImportOrderList, mode, adminId) => {
    try {
      const url = `${restBaseUrl}/v1/jd-import-order?path=${file.path}&name=${file.fileName}&admin_id=${adminId}&order_type=${mode}&pick_up_date=${this.state.importDate}`;
      await axios(url).then(res => {
        if (!res.data._id) {
          alert('Error Import');
          return;
        }
        this.setState({ showImportModal: false }, () => refetchLazadaImportOrderList());
      });
    } catch (error) {
      alert(error);
    }
  };

  deleteBookingFile = async () => {
    try {
      this.setState({ isLoading: true });

      const { idBookingFile } = this.state;
      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          _id: idBookingFile,
        },
      });

      const { order } = data.lazadaImportOrderList;

      if (order[0].status === 4) {
        alert('ไม่สามารถลบรายการได้');
        this.setState({ isLoading: false });
        return;
      }

      await this.props
        .lazadaImportOrderUpdateMutation({
          variables: {
            _id: idBookingFile,
            status: 10,
          },
          refetchQueries: ['LazadaImportOrderListBookingFile'],
        })
        .then(({ data }) => {
          if (data.lazadaImportOrderUpdate._id) {
            this.setState({
              isLoading: false,
              isOpenModalConfirmDelete: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmDelete: false,
      });

      alert(error);
    }
  };

  createShipmentLazada = async () => {
    try {
      this.setState({ isLoading: true });

      const { idBookingFile } = this.state;
      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          _id: idBookingFile,
        },
      });

      const { order } = data.lazadaImportOrderList;
      if (order[0].status !== 2 || order[0].status === 4) {
        alert('ไม่สามารถสร้างรายการได้');
        this.setState({ isLoading: false });
        return;
      }

      await this.props
        .createShipmentLazadaMutation({
          variables: {
            _id: idBookingFile,
          },
          refetchQueries: ['LazadaImportOrderListBookingFile'],
        })
        .then(({ data }) => {
          if (data.createShipmentLazada._id) {
            this.setState({
              isLoading: false,
              isOpenModalConfirmCreateShipment: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmCreateShipment: false,
      });

      alert(error);
    }
  };

  //single upload file
  checkFileTypeExcel(file) {
    if (file) {
        const LastExt = file.name.split('.').length - 1
        const ext = file.name.split('.')[LastExt]  

        return ext === 'xlsx'
        // return file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
        // || file.type === "application/vnd.ms-excel";
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    // console.log(uploadFile)

    const timestamp = new Date().getTime();

    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/JdImportFileNew/${documentName}_${timestamp}`,
        },
      });
    }
  }

  render() {
    let admin_id = '';
    let admin = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin = this.props.adminName.adminList.admin[0].admin_username;
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }
    return (
      <Layout>
        <Row>
          <Col md={12}>
            <div>
              <Query
                query={lazadaImportOrderListQuery}
                variables={{
                  name: this.state.fileName,
                  status: this.state.fileStatus,
                  create_shipment: 1,
                  pick_up_date: this.state.pickupDate,
                  create_date: this.state.createDate,
                  page_number: this.state.pageNumber,
                  account_info_id: customerId.jd,
                }}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error, refetch: refetchLazadaImportOrderList }) => {
                  return (
                    <CardTable
                      title="ตาราง File Booking"
                      category="*ต้อง Import file booking มาที่ตารางนี้ก่อน สร้าง Shipment ทุกครั้ง"
                      ctTableFullWidth
                      ctTableResponsive
                      rightButton={
                        <div style={{ float: 'right', marginLeft: '15px' }}>
                          <Button
                            style={{ background: '#87CB16', color: '#ffffff', marginRight: '15px' }}
                            onClick={() => {
                              this.setState(
                                {
                                  showImportModal: true,
                                  importFile: null,
                                  importDate: '',
                                  show_importDate: '',
                                }
                              );
                            }}
                          >
                            <i className="fas fa-cloud-upload"></i> Import booking
                          </Button>
                        </div>
                      }
                      content={
                        <div>
                          <div className="table-vertical">
                            <Table striped hover style={{ marginBottom: '60px' }}>
                              <thead>
                                <tr>
                                  <th style={{ minWidth: '140px' }}>เครื่องมือ</th>
                                  <th>mode</th>
                                  <th style={{ minWidth: '200px' }}>ชื่อไฟล์</th>
                                  <th style={{ minWidth: '100px' }}>วันที่รับสินค้า</th>
                                  <th style={{ minWidth: '140px' }}>สถานะ</th>
                                  <th style={{ minWidth: '120px' }}>อัพโหลดโดย</th>
                                  <th style={{ minWidth: '120px' }}>วันที่ Import file</th>
                                </tr>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      placeholder="ค้นหาชื่อไฟล์"
                                      name="file_name"
                                      value={this.state.fileName}
                                      onChange={event => {
                                        this.setState({
                                          fileName: event.target.value,
                                          pageNumber: 1,
                                        });
                                      }}
                                    />
                                  </th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker('pickupDate', picker)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          pickupDate: '',
                                          displayPickupDate: '',
                                          pageNumber: 1,
                                        })
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.displayPickupDate}
                                        placeholder="วันที่รับสินค้า"
                                      />
                                    </DateRangePicker>
                                  </th>
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.fileStatus}
                                      onChange={event =>
                                        this.setState({
                                          fileStatus: event.target.value,
                                          pageNumber: 1,
                                        })
                                      }
                                      name="shipment_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={1}>กำลังอัพโหลด</option>
                                      <option value={2}>รอสร้างงานขนส่ง</option>
                                      <option value={3}>ผิดพลาด</option>
                                    </FormControl>
                                  </th>
                                  <th></th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker('createDate', picker)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          createDate: '',
                                          displayCreateDate: '',
                                          pageNumber: 1,
                                        })
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.displayCreateDate}
                                        placeholder="วันที่ Import file"
                                      />
                                    </DateRangePicker>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading && (
                                  <tr>
                                    <td colSpan="7">Loading...</td>
                                  </tr>
                                )}

                                {error && (
                                  <tr>
                                    <td colSpan="7">Error</td>
                                  </tr>
                                )}

                                {!loading && !error && data.lazadaImportOrderList.order.length === 0 && (
                                  <tr>
                                    <td colSpan="7">ไม่มีข้อมูล</td>
                                  </tr>
                                )}

                                {!loading &&
                                  !error &&
                                  data.lazadaImportOrderList.order.length > 0 &&
                                  data.lazadaImportOrderList.order.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <NavDropdownContainer
                                          title={
                                            <Button>
                                              เครื่องมือ <i className="fal fa-angle-down"></i>
                                            </Button>
                                          }
                                        >
                                          {item.status === 2 && (
                                            <NavItemDropDownList
                                              onClick={() => {
                                                this.setState({
                                                  isOpenModalConfirmCreateShipment: true,
                                                  fileNameBookingFile: item.name,
                                                  idBookingFile: item._id,
                                                });
                                              }}
                                            >
                                              <i className="fas fa-user-tie"></i> สร้างงานขนส่ง
                                            </NavItemDropDownList>
                                          )}

                                          <NavItemDropDownList
                                            onClick={() => {
                                              this.setState({
                                                isOpenModalConfirmDelete: true,
                                                fileNameBookingFile: item.name,
                                                idBookingFile: item._id,
                                              });
                                            }}
                                          >
                                            <i className="fas fa-truck"></i> ลบ
                                          </NavItemDropDownList>
                                        </NavDropdownContainer>
                                      </td>
                                      <td>
                                        {item.order_type == 1 ? (
                                          <span class="label label-success">NORMAL</span>
                                        ) : item.order_type == 2 ? (
                                          <span class="label label-danger">FAILED</span>
                                        ) : null}
                                      </td>
                                      <td>
                                        {item.name}
                                        {item.error_result && (
                                          <div style={{ color: '#ff0600' }}>{item.error_result}</div>
                                        )}
                                      </td>
                                      <td className={`${classesJd.textDanger} ${classesJd.textBold}`}>
                                        {item.pick_up_date ? (
                                          <Moment format="DD/MM/YYYY HH:mm:ss">
                                            {item.pick_up_date}
                                          </Moment>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>{this.renderStatus(item.status)}</td>
                                      <td>{item.admin_username}</td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {item.create_date}
                                        </Moment>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </div>              
                          <div style={{ textAlign: 'right', marginTop: 20 }}>
                            {!loading && !error && (
                              <ReactPaginate
                                pageCount={data.lazadaImportOrderList.total_page}
                                forcePage={this.state.pageNumber - 1}
                                containerClassName="pagination"
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={({ selected }) => {
                                  this.setState({ pageNumber: selected + 1 });
                                }}
                              />
                            )}
                          </div>

                          <Modal
                            show={this.state.showImportModal}
                            onHide={() => this.setState({ showImportModal: false })}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Import booking</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col md={12}>Import file :</Col>
                              </Row>
                              {
                              //   <UploadFile
                              //   completed={file => {
                              //     this._inputImportFile1.current.value = null;
                              //     console.log(file);
                              //     this.setState({ importFile: file });
                              //   }}
                              // >
                              //   {selectFile => {
                              //     return (
                              //       <React.Fragment>
                              //         <input
                              //           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              //           onChange={event => {
                              //             selectFile(
                              //               event,
                              //               `upload/jdProject/document_${new Date().getTime()}`
                              //             );
                              //           }}
                              //           type="file"
                              //           id="file"
                              //           ref={this._inputImportFile1}
                              //           style={{ display: 'none' }}
                              //         />
                              //         <Button
                              //           style={{ marginRight: '15px' }}
                              //           onClick={() => this._inputImportFile1.current.click()}
                              //         >
                              //           <i className="fas fa-cloud-upload"></i> Import booking
                              //         </Button>
                              //         <span>
                              //           {this.state.importFile
                              //             ? this.state.importFile.fileName
                              //             : ''}
                              //         </span>
                              //       </React.Fragment>
                              //     );
                              //   }}
                              // </UploadFile>
                            }
                              <Button
                                style={{ marginRight: '15px' }}
                                onClick={() => this._inputAttachFileFirstCase.current.click()}
                              >
                                <i className="fas fa-cloud-upload"></i> Import booking
                              </Button>
                              <span>
                                {this.state.importFile
                                  ? this.state.importFile.fileName
                                  : ''}
                              </span>
                              <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                <Col md={12}>Pickup date :</Col>
                              </Row>
                              <FormGroup controlId="formValidationError1">
                                <Datetime
                                  inputProps={{
                                    placeholder: 'Pickup date',
                                    disabled: this.state.importFile == null,
                                  }}
                                  value={this.state.show_importDate}
                                  dateFormat="DD/MM/YYYY"
                                  timeFormat="HH:mm"
                                  onChange={event =>
                                    this.setState({
                                      show_importDate: event,
                                      importDate: event.format('YYYY-MM-DD HH:mm'),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                disabled={this.state.importDate == ''}
                                style={{
                                  background: '#87CB16',
                                  color: '#ffffff',
                                  marginRight: '15px',
                                }}
                                onClick={() =>
                                  this.uploadFileHandler(
                                    this.state.importFile,
                                    refetchLazadaImportOrderList,
                                    1,
                                    admin_id
                                  )
                                }
                              >
                                Import booking
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      }
                    />
                  );
                }}
              </Query>
            </div>

            <ModalConfirm
              show={this.state.isOpenModalConfirmDelete}
              onPressButtonConfirm={this.deleteBookingFile}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'ลบ...' : 'ลบ'}
              labelButtonCancel={`ยกเลิก`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmDelete: false })}
              titleHeader={`คุณต้องการลบ File Booking นี้ ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmDelete: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            <ModalConfirm
              show={this.state.isOpenModalConfirmCreateShipment}
              onPressButtonConfirm={this.createShipmentLazada}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'สร้าง...' : 'สร้าง'}
              labelButtonCancel={`ยกเลิก`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmCreateShipment: false })}
              titleHeader={`คุณสร้างงานขนส่ง(Shipment) จาก File นี้ ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmCreateShipment: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            {
              //S I N G L E   U P L O A D   E X C E L  
            }
            <Mutation
              mutation={singleUploadMutation}
              update={(cache, { data }) => {
                  this._inputAttachFileFirstCase.current.value = '';

                  const fileFinal = {
                    path: data.singleUpload.path,
                    fileName: this.state.importFileSingleUpload.fileName
                  }

                  this.setState({ importFile: fileFinal });
              }}
              onError={error => {
                  console.log(error);
              }}
              >
              {uploadFile => {
                  return (
                      <input
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={event => {
                          const files = event.target.files[0];
                          const checkFileTypeImage = this.checkFileTypeExcel(
                              files
                          );
                          if (checkFileTypeImage === false) {
                              alert('ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท xlsx เท่านั้น');
                              return;
                          }
                          this.setState({
                            importFileSingleUpload:{ fileName: files.name }
                          })
                          this._inputAttachFileFirstCase.current.files[0] &&
                              this.handleSingleUploadDocument(
                              uploadFile,
                              'import_file',
                              event
                              );
                          }}
                          type="file"
                          id="file"
                          style={{ display: 'none' }}
                          ref={this._inputAttachFileFirstCase}
                      />
                  );
              }}
              </Mutation>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default compose(
  graphql(lazadaImportOrderUpdateMutation, {
    name: 'lazadaImportOrderUpdateMutation',
  }),
  graphql(lazadaImportOrderListQuery, {
    name: 'lazadaImportOrderListQuery',
    fetchPolicy: 'cache-and-network',
  }),
  graphql(createShipmentLazadaMutation, {
    name: 'createShipmentLazadaMutation',
  }),
  graphql(adminListQuery, { name: 'adminName' })
)(BookingFiles);
