import gql from 'graphql-tag';

export default gql`
query shipmentAgentView(
  $shipment_prefix: String
  $lazada_import_order_id: ID
  $account_info_id: ID!
){
  shipmentAgentView(
    shipment_prefix: $shipment_prefix
    lazada_import_order_id: $lazada_import_order_id
    account_info_id: $account_info_id
  ){
    agent_shipment_status
    total_price_cod
    total_accept_cod
    
    shipment{
      shipment_total_cod
    }
  }
}

`;