import React, { Component } from 'react';
import { CustomPhotoAdd } from './styled';
import singleUploadMutation from './mutation/singleUpload';
import { Mutation } from 'react-apollo';
// import bucket from 'config/bucket';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this._inputAttachFileFirstCase = React.createRef();
  }

  checkFileTypeImage(file) {
    if (file) {
      return file.type === 'image/png' || file.type === 'image/jpeg';
    }
  }

  handleSingleUploadDocumentCase(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    const timestamp = new Date().getTime();
    // if (validity) {
    //   uploadFile({
    //     variables: {
    //       file: file,
    //       path: `upload/codSlipKa/${documentName}-${timestamp}`,
    //       bucket: bucket,
    //     },
    //   });
    // }
  }

  render() {
    return (
      ""
      // <div>
      //   <CustomPhotoAdd
      //     onClick={() => this._inputAttachFileFirstCase.current.click()}
      //   >
      //     <i class="far fa-plus-circle"></i>
      //     <span>เพิ่มรูปภาพ</span>
      //   </CustomPhotoAdd>

      //   <Mutation
      //     mutation={singleUploadMutation}
      //     update={(_cache, { data }) => {
      //       this._inputAttachFileFirstCase.current.value = '';
      //       this.props.onCompleted(
      //         `https://${bucket}/${data.singleUpload.path}`
      //       );
      //     }}
      //     onError={(error) => {
      //       alert(error.messaage);
      //     }}
      //   >
      //     {(uploadFile) => {
      //       return (
      //         <input
      //           accept="image/jpeg,image/png"
      //           onChange={(event) => {
      //             const files = event.target.files[0];
      //             const checkFileTypeImage = this.checkFileTypeImage(files);
      //             if (checkFileTypeImage === false) {
      //               alert(
      //                 'error',
      //                 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
      //               );
      //               return;
      //             }

      //             this._inputAttachFileFirstCase.current.files[0] &&
      //               this.handleSingleUploadDocumentCase(
      //                 uploadFile,
      //                 this._inputAttachFileFirstCase.current.files[0].name.split(
      //                   '.'
      //                 )[0],
      //                 event
      //               );
      //           }}
      //           type="file"
      //           id="file"
      //           style={{ display: 'none' }}
      //           ref={this._inputAttachFileFirstCase}
      //           disabled={false}
      //         />
      //       );
      //     }}
      //   </Mutation>
      // </div>
    );
  }
}

export default UploadImage;
