import React, { Component } from 'react';
import { HeaderWrapper, TableWrapper, CenterVerticalSpan, Line } from './styled';
import { Table, FormControl, Button } from 'react-bootstrap';
import Input from './components/Input';
import AddSuffixShipment from '../../components/AddSuffixShipment';
import CheckFinalStatusModal from './components/CheckFinalStatusModal';

export class ConfirmStatusTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery_tracking_number_search: '',
      delivery_status_search: 0,
      final_status_search: 0,
      isCheckFinalStatusModal: false,
      deliveryData: '',
      trackingId: '',
    };
  }

  renderDeliveryStatus = status => {
    //1 wait, 2 inprogress, 3 complete = delivered , 4 wait return = fail  5 return complete 6 no return 7 cancel pick up = fail delivery
    switch (status) {
      case 2:
        return (
          <span
            style={{
              backgroundColor: '#318DE8',
              color: ' #fff',
              fontWeight: 'bold',
              padding: '2px 10px',
              borderRadius: '5px',
            }}
          >
            Inprogress
          </span>
        );
      case 3:
        return (
          <span
            style={{
              backgroundColor: '#5DBA5B',
              color: ' #fff',
              fontWeight: 'bold',
              padding: '2px 10px',
              borderRadius: '5px',
            }}
          >
            Delivered
          </span>
        );
      case 4:
        return (
          <span
            style={{
              backgroundColor: '#FF0000',
              color: ' #fff',
              fontWeight: 'bold',
              padding: '2px 10px',
              borderRadius: '5px',
            }}
          >
            Fail Delivery
          </span>
        );
      case 5:
        return (
          <span
            style={{
              backgroundColor: '#FF0000',
              color: ' #fff',
              fontWeight: 'bold',
              padding: '2px 10px',
              borderRadius: '5px',
            }}
          >
            Fail Delivery
          </span>
        );
      case 6:
        return (
          <span
            style={{
              backgroundColor: '#FF0000',
              color: ' #fff',
              fontWeight: 'bold',
              padding: '2px 10px',
              borderRadius: '5px',
            }}
          >
            Fail Delivery
          </span>
        );
      case 9:
        return (
          <span
            style={{
              backgroundColor: '#FF0000',
              color: ' #fff',
              fontWeight: 'bold',
              padding: '2px 10px',
              borderRadius: '5px',
            }}
          >
            Fail Delivery
          </span>
        );
      case 7:
        return <span style={{ fontWeight: 'bold' }}>No Pickup</span>;
      default:
        return <span style={{ fontWeight: 'bold' }}>Pending</span>;
    }
  };

  renderIconStatus = (status, trip) => {
    if (trip) {
      switch (status) {
        case 1:
          return (
            <span
              style={{
                backgroundColor: '#000',
                color: '#fff',
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <i style={{ marginRight: '5px' }} class="far fa-check-circle"></i>
              {trip}
            </span>
          );
        case 2:
          return (
            <span
              style={{
                backgroundColor: '#5DBA5B',
                color: '#fff',
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <i style={{ marginRight: '5px' }} class="far fa-check-circle"></i> {trip}
            </span>
          );
        case 3:
          return (
            <span
              style={{
                backgroundColor: '#FF0000',
                color: '#fff',
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <i style={{ marginRight: '5px' }} class="far fa-check-circle"></i> {trip}
            </span>
          );
        default:
          return (
            <span
              style={{
                backgroundColor: '#000',
                color: '#fff',
                marginRight: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <i style={{ marginRight: '5px' }} class="far fa-check-circle"></i> {trip}
            </span>
          );
      }
    } else {
      switch (status) {
        case 1:
          return (
            <i style={{ color: '#E3E3E3', marginRight: '5px' }} class="far fa-check-circle"></i>
          );
        case 2:
          return (
            <i style={{ color: '#5DBA5B', marginRight: '5px' }} class="far fa-check-circle"></i>
          );
        case 3:
          return (
            <i style={{ color: '#FF0000', marginRight: '5px' }} class="far fa-times-circle"></i>
          );
        default:
          return (
            <i style={{ color: '#E3E3E3', marginRight: '5px' }} class="far fa-check-circle"></i>
          );
      }
    }
  };

  formatMoney = number => {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  renderRejectStatus = status => {
    switch (status) {
      case 1:
        return (
          <i style={{ color: 'red', marginLeft: '5px' }} class="far fa-exclamation-triangle"></i>
        );
      default:
        return null;
    }
  };

  renderLogStatus = log => {
    if (log.length > 0) {
      return (
        <i style={{ color: '#318DE8', marginLeft: '5px' }} class="far fa-exclamation-circle"></i>
      );
    }
    return null;
  };

  filterTrackingNumber = data =>
    data.commodity[0].name.includes(this.state.delivery_tracking_number_search);

  filterDriverStatus = data => {
    if (this.state.delivery_status_search == 0) {
      return true;
    } else if (this.state.delivery_status_search == 1) {
      return data.temp_delivery_status == 3;
    } else if (this.state.delivery_status_search == 2) {
      return (
        data.temp_delivery_status == 4 ||
        data.temp_delivery_status == 5 ||
        data.temp_delivery_status == 6 ||
        data.temp_delivery_status == 9
      );
    } else if (this.state.delivery_status_search == 3) {
      return data.temp_delivery_status == 7;
    }
  };

  filterFinalStatus = data => {
    if (this.state.final_status_search == 0) {
      return true;
    } else if (this.state.final_status_search == 1) {
      return data.final_status == 3;
    } else if (this.state.final_status_search == 2) {
      return (
        data.final_status == 4 ||
        data.final_status == 5 ||
        data.final_status == 6 ||
        data.final_status == 9
      );
    } else if (this.state.final_status_search == 3) {
      return data.final_status == 7;
    }
  };

  render() {
    return (
      <div>
        <HeaderWrapper>
          <CenterVerticalSpan>
            พนักงานขับรถ : <b>{this.props.driverName || '-'}</b>
          </CenterVerticalSpan>
          <Line />
          <CenterVerticalSpan>
            ทะเบียนรถ :<b>{this.props.user_setting_truck_license_plate || '-'}</b>
          </CenterVerticalSpan>
        </HeaderWrapper>
        <TableWrapper className="table-vertical">
          <Table style={{ marginBottom: '0px' }} striped hover>
            <thead>
              <tr>
                <td style={{ minWidth: '10px' }}></td>
                <td style={{ minWidth: '100px', fontSize: '18px' }}>
                  <b>Tracking Number</b>
                </td>
                <td style={{ minWidth: '100px', fontSize: '18px' }}>
                  <b>สถานะ</b> (Driver)
                </td>
                <td style={{ minWidth: '100px', fontSize: '18px' }}>
                  <b>สถานะ</b> (Agent)
                </td>
                <td style={{ minWidth: '100px', fontSize: '18px' }}>
                  <b>COD</b> (บาท)
                </td>
                <td style={{ minWidth: '100px', fontSize: '18px' }}>
                  <b>บันทึกช่วยจำ</b>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <Input
                    placeholder="ค้นหา"
                    onChange={e =>
                      this.setState({
                        delivery_tracking_number_search: e.target.value,
                      })
                    }
                  />
                </td>
                <td>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    defaultValue={0}
                    value={this.state.delivery_status_search}
                    onChange={event =>
                      this.setState({
                        delivery_status_search: event.target.value,
                      })
                    }
                    name="Driver_Delivery_status"
                  >
                    <option value={0}>ทั้งหมด</option>
                    <option value={1}>Delivered</option>
                    <option value={2}>Fail Delivery</option>
                    <option value={3}>No Pickup</option>
                  </FormControl>
                </td>
                <td>
                  <FormControl
                    componentClass="select"
                    placeholder="select"
                    defaultValue={0}
                    value={this.state.final_status_search}
                    onChange={event =>
                      this.setState({
                        final_status_search: event.target.value,
                      })
                    }
                    name="Agent_Delivery_status"
                  >
                    <option value={0}>ทั้งหมด</option>
                    <option value={1}>Delivered</option>
                    <option value={2}>Fail Delivery</option>
                    <option value={3}>No Pickup</option>
                  </FormControl>
                </td>
                <td></td>
                <td></td>
              </tr>
            </thead>

            {this.props.loading ? (
              <tbody>
                <tr>
                  <td></td>
                  <td> Loading</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            ) : this.props.trackingList &&
              this.props.trackingList
                .filter(this.filterTrackingNumber)
                .filter(this.filterDriverStatus)
                .filter(this.filterFinalStatus).length == 0 ? (
              <tbody>
                <tr>
                  <td></td>
                  <td> ไม่มีรายการ</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {this.props.trackingList &&
                  this.props.trackingList.length > 0 &&
                  this.props.trackingList
                    .filter(this.filterTrackingNumber)
                    .filter(this.filterDriverStatus)
                    .filter(this.filterFinalStatus)
                    .map((data, index) => (
                      <tr key={data._id}>
                        <td></td>
                        <td>
                          {' '}
                          <b>{AddSuffixShipment(data.account_info)}</b>
                          {data.commodity[0].name}
                          {this.renderRejectStatus(data.admin_reject_status)}
                          {this.renderLogStatus(data.log)}
                        </td>
                        <td>{this.renderDeliveryStatus(data.temp_delivery_status)}</td>
                        <td>
                          {this.renderDeliveryStatus(data.final_status)}
                          {
                            <Button
                              onClick={() =>
                                this.setState({
                                  isCheckFinalStatusModal: true,
                                  deliveryData: data,
                                })
                              }
                              style={{
                                marginLeft: '10px',
                              }}
                            >
                              ตรวจสอบ
                            </Button>
                          }
                        </td>
                        <td>{data.cod_price ? this.formatMoney(data.cod_price) : 0}</td>
                        <td>{data.remind ? data.remind : '-'}</td>
                      </tr>
                    ))}
              </tbody>
            )
            //     )
            }
          </Table>
        </TableWrapper>
        {
          //check final status
        }
        {this.state.isCheckFinalStatusModal && (
          <CheckFinalStatusModal
            openModal={this.state.isCheckFinalStatusModal}
            closeModal={() => {
              this.setState({
                isCheckFinalStatusModal: false,
                deliveryData: [],
              });
            }}
            trackingId={this.state.trackingId}
            deliveryData={this.state.deliveryData}
          />
        )}
      </div>
    );
  }
}

export default ConfirmStatusTab;
