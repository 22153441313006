import React from 'react'
import { Query, graphql, compose, Mutation } from 'react-apollo';
import {
  Button,
  Modal,
} from 'react-bootstrap';
import clearShipmentDriverAcceptMutation from './graphql/mutation/clearShipmentDriverAccept';
import assignAgentModal from './graphql/mutation/assignAgent'

const ClearShipmentModal = props => {
    return(
        <Mutation
            mutation={assignAgentModal}
            variables={{
                shipment_id: props.shipmentID,
                transporter: props.transporter,
            }}
            update={() => {
                props.closeModal()
                props.refetch()
            }}
        >
            {assignAgent => (
                <Mutation
                    mutation={clearShipmentDriverAcceptMutation}
                    variables={{
                        shipment_id: props.shipmentID,
                    }}
                    update={() => {
                        assignAgent()
                    }}
                >
                    {clearShipmentDriver => (
                    <Modal
                        show={props.showModal}
                        onHide={props.closeModal}
                    >
                        <Modal.Header closeButton>
                        <Modal.Title>คืนค่าเริ่มต้นงานขนส่ง </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        คุณต้องการคืนค่าเริ่มต้น งานขนส่ง <b>{props.shipmentTrip}</b> ใช่หรือไม่
                        ? <br /> กด <b>"ตกลง"</b> เพื่อคืนค่าเริ่มต้นงานนี้
                        </Modal.Body>
                        <Modal.Footer>
                        <Button
                            style={{ float: 'left' }}
                            onClick={props.closeModal}
                        >
                            กลับ
                        </Button>
                        <Button bsStyle="primary" onClick={clearShipmentDriver}>
                            ตกลง
                        </Button>
                        </Modal.Footer>
                    </Modal>
                    )}
                </Mutation>
            )}
        </Mutation>
    )
}

export default ClearShipmentModal