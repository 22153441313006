import React, { Component } from 'react';
import { Grid, Row, Col, Radio, Checkbox, Button, Modal, Table, FormControl } from 'react-bootstrap';
import styled from 'styled-components';
import { Mutation, graphql, Query, compose } from 'react-apollo';
import moment from 'moment'
import NotificationSystem from 'react-notification-system';

import CardTable from 'components/CardTable/CardTable.jsx';
import adminListQuery from "views/Setting/graphql/query/adminList"
import settingHolidaysQuery from "views/Setting/graphql/query/settingHolidays"
import settingHolidayAddUpdate from "views/Setting/graphql/mutation/settingHolidayAddUpdate"
import settingHolidayDelete from 'views/Setting/graphql/mutation/settingHolidayDelete'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

//in Module
import { ModalConfirm } from 'views/Setting/components';

//styled component
const styleNotification = {
	NotificationItem: {
		DefaultStyle: {
			margin: '10px 5px 2px 1px'
		},

		error: {
			color: '#FFFFFF',
			backgroundColor: '#FC727A',
			border: '1px solid #FC727A',
			boxShadow: 'none',
			borderRadius: '4px',
		},

		success: {
			color: '#FFFFFF',
			backgroundColor: '#318DE8',
			border: '1px solid #318DE8',
			boxShadow: 'none',
			borderRadius: '4px',
		}
	},
}

const Container = styled.div`
	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}

	& .text-green {
		color: #19AA19;
	}

	& .text-blue {
		color: #318DE8;
	}

	& .text-red {
		color: #FF0000;
	}

	& .text-grey {
		color: #F2F2F2;
	}

	& .text-primary {
		color: #D90101 !important;
	}

	& .btn-edit{
		cursor: pointer;
    	color: #333;
    }
    & .rdtPicker {
        position: fixed !important;
    }
`;

const CardContainer = styled.div`
	border-radius: 6px;
	border: 1px solid #E3E3E3;
	background-color: #FFFFFF;
	padding: 0;
    display: flex;
	flex-direction: row;
    overflow: hidden;
    min-height: calc(100vh - 265px);
    max-height: calc(100vh - 265px);
`;
const ColLeft = styled.div`
	width:200px;
	border-right:1px solid #DBDBDB;
`;
const ColRight = styled.div`
	width :calc(100% - 200px);
`;

const TextHeader = styled.div`
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	padding:15px;
`;

const Btnaddlistyear = styled.div`
    position: sticky;
    background-color: #fff;
    bottom: 0px;
	border-top: 1px solid #ddd;    
	padding: 10px 15px;
`;
const Btnaddlist = styled.div`
    position: sticky;
    background-color: #fff;
    bottom: 0px;   
	padding: 10px 15px;
`;
const UlTruck = styled.ul`
	padding:0;
	border-top: 1px solid #dddddd;
	overflow: auto;
    min-height: calc(100vh - 371px);
    max-height: calc(100vh - 371px);
	& li{
		list-style: none;
		padding: 10px;    
		border-bottom: 1px solid #EAEAEA;
		cursor: pointer;

		&:hover{
			background-color: #fafafa;
		}

		&.active{
			background-color: #EAEAEA;
		}
	}

	& i {
		float: right;
		16px;
	}
`;


const HeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	border-bottom: 1px solid #efefef;
	display: flex;
	flex-direction: row;
	padding: 10px;

	

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}

		
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
		background-color: #E3E3E3;
		width: 1px;
		margin-left: 20px;
		margin-right: 20px;
	}

	& .menu-wrapper{
		& ul{
			padding-left: 0;
			margin: 0;
			display: flex;

			& li{
				list-style: none;
				margin-right: 15px;
				padding: 22px 10px 15px;

				& a{
					font-size:18px;
					cursor: pointer;
					& i{
						font-size:18px;
						color: #000000;
					}
				}
			}
			& li.active{
				border-bottom: 2px solid #d90402;
			}
			
		}
	}
`;
const SubHeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
	padding: 10px 20px;

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
    background-color: #E3E3E3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
	}
`;


let adminName = "";

class DataMasterGiztixHolidays extends Component {
	constructor(props) {
		super(props);

		this.state = {
			holidaysIndex: 0,
			show_addHolidays: false,
			holidaysList: [],
			holidaysListYear: '',
			holidaysId: '',
			yearholidays: '',
			dataObj: {},
			dataholiday: 0,
			show_delholiday: false,
			show_delYearHoliday: false,
			delYearHolidayId: '',
		};

		this.notificationSystem = React.createRef();
	}

	handleYear(event) {
		const date = `${event.format('YYYY')}`;
		this.state.yearholidays = date;
		this.forceUpdate();
	}
	handleDatePicker(event, index) {
		const date = `${event.format('YYYY-MM-DD')}`;
		this.state.holidaysList[index] = date;
		this.setState({
			holidaysList: this.state.holidaysList,
		});
	}
	handleDeleteholiday(index) {
		this.state.holidaysList.splice(index, 1);
		this.setState({
			holidaysList: this.state.holidaysList,
		});
	}
	handleAddholiday() {
		this.state.holidaysList.push('');
		this.setState({
			holidaysList: this.state.holidaysList,
		});
	}
	renderVariablesdelHolidays() {

	}
	setObjectholiday(holidaysList, holidaysListKey) {
		let arrayObj = [];
		holidaysList.holiday.map((holidaysubList, holidaysubKey) => {
			let get = '';
			if (holidaysubList !== '') {
				get = holidaysubList;
				arrayObj.push(get);
			}
		})
		this.setState({
			holidaysIndex: holidaysListKey,
			holidaysListYear: holidaysList.year,
			holidaysList: arrayObj,
			holidaysId: holidaysList._id,
			dataholiday: 1,
		})
	}
	renderVariablesHolidays() {
		let obj = {
			year: this.state.yearholidays
		};
		return obj;
	}
	renderVariablesHolidayList() {
		let arrayObj = [];
		let obj = {
			_id: this.state.holidaysId,
			year: this.state.holidaysListYear
		};
		obj['holiday'] = [];
		// this.state.holidaysList.splice("");
		if (this.state.holidaysList.length > 0) {
			this.state.holidaysList.map((holidaysubList, holidaysubKey) => {
				let get = '';
				if (holidaysubList !== '') {
					get = holidaysubList;
					arrayObj.push(get);
				}
			})
			obj.holiday = arrayObj;
		}
		return obj;
	}
	splicearray() {
		let arrayObj = [];
		this.state.holidaysList.map((holidaysubList, holidaysubKey) => {
			let get = '';
			if (holidaysubList !== '') {
				get = holidaysubList;
				arrayObj.push(get);
			}
		})
		this.state.holidaysList = arrayObj;
		this.setState({
			holidaysList: arrayObj,
		});
	}
	async openModalHolidays() {
		try {
			this.setState({
				show_addHolidays: true,
			});
		} catch (error) {
			console.log(error);
		}
	}
	validation = (currentDate) => {
		var after = moment(this.state.holidaysListYear).subtract(1, 'day');
		var before = moment(this.state.holidaysListYear).subtract(-1, 'year');

		var setbefore = moment(before).subtract(1, 'day');
		// return currentDate.isBefore(moment(this.props.maxDate)) && currentDate.isAfter(moment(this.props.minDate));
		return currentDate.isAfter(after) && currentDate.isBefore(setbefore);
	};

	openModalDeleteYearConfirm = (id) => {
		this.setState({
			show_delYearHoliday: true,
			delYearHolidayId: id
		})
	}

	closeModalDeleteYearConfirm = () => {
		this.setState({
			show_delYearHoliday: false,
			delYearHolidayId: ''
		})
	}

	deleteHolidayYear = (settingHolidayDelete) => {
		if (this.state.delYearHolidayId) {
			settingHolidayDelete({
				variables: {
					_id: this.state.delYearHolidayId
				}
			});
		}
	}

	checkDuplicateDateTime = (postMutation) => {
		const isDuplicate = this.isDuplicate(this.state.holidaysList)
		if (isDuplicate) {
			this.addNotification('error', 'มีวันที่ซ้ำ');
		} else {
			console.log(this.renderVariablesHolidayList())
			postMutation({
				variables: { ...this.renderVariablesHolidayList() }
			})
		}
	}

	isDuplicate = (valueArr) => valueArr.some(function (item, idx) { return valueArr.indexOf(item) !== idx; });

	addNotification = (typeStatus, message) => {
		const notification = this.notificationSystem.current;
		notification.addNotification({
			message: (
				<span>
					<strong>แจ้งเตือน</strong> {message}
				</span>
			),
			level: typeStatus,
			position: 'tr',
		});
	};

	render() {
		if (!this.props.adminListQuery.loading && !this.props.adminListQuery.error) {
			adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
		}
		return (
			<Container>
				<NotificationSystem ref={this.notificationSystem} style={styleNotification} />

				<div className="content">
					<NotificationSystem ref={this.notificationSystem} style={styleNotification} />


					<HeaderContainer>
						<div className="sub-header-left">
							<a href="/giztixbusiness" className="btn-back"><i className="far fa-chevron-left"></i> กลับ</a>
							<div className="line"></div>
							<div className="menu-wrapper">
								<ul>
									<li>
										<a href={`/settingdatamaster`}> <i className="fal fa-truck"></i> Giztix Trucks</a>
									</li>
									<li className="active">
										<a href={`/settingdatamaster-holidays`}><i class="fal fa-calendar-day"></i> วันหยุุดบริษัท</a>
									</li>
									<li>
										<a href={`/settingdatamaster-condition`}> <i class="far fa-ballot-check"></i> เงื่อนไขปัญหา</a>
									</li>
								</ul>
							</div>
						</div>
					</HeaderContainer>

					<Query query={settingHolidaysQuery} variables={{ showData: 30 }} >
						{
							({ loading, error, data, refetch: refetchSettingHolidays, variables }) => {
								if (loading) return (<Grid fluid>loading...</Grid>);
								if (error) return null;
								return (
									<div>

										<div>
											<SubHeaderContainer>
												<div className="sub-header-left">
													<strong>กรอกรายการวันหยุด</strong>
												</div>
											</SubHeaderContainer>
											{/* Content table */}
											<Grid fluid>
												<Row style={{ paddingTop: 20, paddingBottom: 20 }}>
													<Col md={12}>
														<CardContainer>
															<ColLeft>
																<TextHeader>รายปี</TextHeader>
																<div>
																	<UlTruck>
																		{
																			data.settingHolidays.holidays.map((holidaysList, holidaysListKey) => {
																				if (this.state.dataholiday === 0) {
																					if (holidaysListKey === 0) {
																						this.state.holidaysId = holidaysList._id;
																						this.state.holidaysListYear = holidaysList.year;
																						this.state.holidaysIndex = holidaysListKey;
																						this.state.holidaysList = holidaysList.holiday;
																						this.state.dataholiday = 1;
																						this.setObjectholiday(holidaysList, holidaysListKey)
																					}

																				}
																				return (
																					<li key={holidaysListKey}
																						className={holidaysListKey == this.state.holidaysIndex ? 'active' : ''}
																						onClick={() => this.setObjectholiday(holidaysList, holidaysListKey)}>
																						{holidaysList.year}
																						{adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' &&
																							<span style={{ position: 'relative', float: 'right' }}>
																								<i onClick={() => this.openModalDeleteYearConfirm(holidaysList._id)} class="far fa-trash-alt" style={{ color: 'red', position: 'absolute', right: '20px' }}></i>
																							</span>
																						}
																						{holidaysListKey == this.state.holidaysIndex && (<i className="fal fa-chevron-right"></i>)}
																					</li>
																				)
																			})
																		}
																	</UlTruck>
																	{adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ?
																		(<Btnaddlistyear><button className="btn btn-default" onClick={() => this.openModalHolidays()}>+ เพิ่มปี</button></Btnaddlistyear>) : ('')
																	}
																</div>
															</ColLeft>
															<ColRight>
																<div style={{ padding: "15px" }}>
																	<CardTable
																		title={`รายละเอียดวันหยุด ปี ${this.state.holidaysListYear}`}
																		ctTableFullWidth
																		ctTableResponsive
																		rightButton={
																			<div style={{ float: 'right', marginLeft: '15px' }}>
																				{adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ?
																					(<Mutation
																						mutation={settingHolidayAddUpdate}
																						update={() => { this.setState({ show_complete: true }) }}
																						onError={error => {
																							alert(error);
																						}}
																					>
																						{postMutation => <button className="btn btn-green" onClick={() => this.checkDuplicateDateTime(postMutation)}>บันทึก</button>}
																					</Mutation>) : ('')

																				}
																				{/* {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ?
																					(<button className="btn btn-primary" onClick={() => this.setState({ show_delholiday: true })}>บันทึก</button>) : ('')
																				} */}
																			</div>
																		}
																		content={
																			<div>
																				<div style={{ maxHeight: 'calc(100vh - 430px)', overflow: 'auto', borderTop: '1px solid #ddd' }}>
																					<Table bordered>
																						<thead>
																							<tr>
																								<td colSpan={adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? 2 : 1} style={{ fontWeight: 700 }}>รายการวันหยุด</td>

																							</tr>
																						</thead>
																						{
																							this.state.holidaysList.length == 0 ? (
																								<tbody>
																									<tr >
																										<td colSpan={2}> ไม่มีรายการวันหยุด</td>
																									</tr>
																								</tbody>
																							) : (
																									<tbody>
																										{
																											this.state.holidaysList.map((holidaysubList, holidaysubKey) => {
																												return (
																													<tr key={holidaysubKey}>
																														<td style={{ width: '90%' }}>
																															<Datetime
																																value={holidaysubList ? moment(holidaysubList).format("DD/MM/YYYY") : ''}
																																dateFormat="DD/MM/YYYY"
																																timeFormat={false}
																																viewDate={holidaysubList ? moment(holidaysubList) : moment(this.state.holidaysListYear)}
																																onChange={event =>
																																	this.handleDatePicker(event, holidaysubKey)
																																}

																																inputProps={{ disabled: (adminName != 'fonne' && adminName != 'sarawut@giztix.com' && adminName != 'thames' && adminName != 'soranut@giztix.com') }}
																																isValidDate={this.validation}
																																closeOnSelect
																															/>
																														</td>
																														{adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ?
																															(<td style={{ textAlign: 'center', width: '90%' }}>
																																<a style={{ cursor: 'pointer' }} onClick={() => this.handleDeleteholiday(holidaysubKey)}><i class="far fa-trash-alt" style={{ color: 'red' }}></i></a>
																															</td>) : ('')
																														}
																													</tr>
																												)
																											})
																										}
																									</tbody>
																								)
																						}
																					</Table>
																				</div>
																				{adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
																					<Btnaddlist>
																						<button className="btn btn-default" onClick={() => this.handleAddholiday()}>+ เพิ่มรายการ</button>
																					</Btnaddlist>)
																					: ('')}

																			</div>
																		}
																	/>
																</div>
															</ColRight>
														</CardContainer>
													</Col>
												</Row>
											</Grid>
										</div>
										<Mutation
											mutation={settingHolidayAddUpdate}
											variables={this.renderVariablesHolidays()}
											update={() => {
												refetchSettingHolidays()
												this.setState({ show_addHolidays: false, yearholidays: '', }, () => {
													refetchSettingHolidays();
												});
											}}
											onError={error => {
												alert('Year exist');
											}}
										>
											{settingHolidayAddUpdate => (
												<ModalConfirm
													show={this.state.show_addHolidays}
													onPressButtonCancel={() =>
														this.setState({
															show_addHolidays: false,
															yearholidays: '',
														})
													}
													onPressButtonConfirm={() => settingHolidayAddUpdate()}
													labelButtonConfirm={`บันทึก`}
													labelButtonCancel={`ปิด`}
													disabledButtonConfirm={this.state.yearholidays == ''}
													styleColorButtonConfirm="green"
													titleHeader={`เพิ่มปีวันหยุดบริษัท`}
													onHide={() =>
														this.setState({
															show_addHolidays: false,
															yearholidays: '',
														})
													}
												>
													<div style={{ lineHeight: 1 }}>
														<div style={{ marginBottom: 10 }}>ปี</div>
														<Datetime
															value={this.state.yearholidays}
															dateFormat="YYYY"
															viewMode="years"
															inputProps={{ placeholder: 'ระบุ' }}
															timeFormat={false}
															closeOnSelect
															onChange={event =>
																this.handleYear(event)
															}
														/>
													</div>
												</ModalConfirm>
											)}
										</Mutation>

										<Mutation
											mutation={settingHolidayDelete}
											update={() => {
												refetchSettingHolidays()
												this.setState({ show_delYearHoliday: false, delYearHolidayId: '', }, () => {
													refetchSettingHolidays();
												});
											}}
										>
											{settingHolidayDelete => (
												<ModalConfirm
													show={this.state.show_delYearHoliday}
													onPressButtonCancel={this.closeModalDeleteYearConfirm}
													onPressButtonConfirm={() => this.deleteHolidayYear(settingHolidayDelete)}
													labelButtonConfirm={`ใช่`}
													labelButtonCancel={`ไม่`}
													styleColorButtonConfirm="red"
													titleHeader={`ลบปีวันหยุดบริษัท`}
													onHide={this.closeModalDeleteYearConfirm}
												>
													<div>ท่านต้องการลบปีวันหยุดบริษัทใช่หรือไม่</div>
												</ModalConfirm>
											)
											}
										</Mutation>

										<ModalConfirm
											show={this.state.show_complete}
											onPressButtonCancel={() => {
												refetchSettingHolidays()
												this.setState({
													show_complete: false,
												});
												this.splicearray()

											}}
											labelButtonCancel={`ปิด`}
											styleColorButtonConfirm="green"
											titleHeader={`บันทึกรายการวันหยุดเสร็จสิ้น`}
											onHide={() => {
												refetchSettingHolidays()
												this.setState({
													show_complete: false,
												});
												this.splicearray()
											}}
										>
											<div> บันทึกรายการวันหยุด ปี {this.state.holidaysListYear} เสร็จสิ้น</div>
										</ModalConfirm>
									</div>
								)
							}
						}
					</Query>
				</div>

			</Container>

		);
	}
}

export default compose(
	graphql(adminListQuery, {
		name: 'adminListQuery'
	})
)(DataMasterGiztixHolidays);
