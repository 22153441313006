import gql from 'graphql-tag';

export default gql`
mutation Update(
    $_id: ID!
    $status: Int
    $receipt_id : ID
    $receipt_date: String
    $debit_note_document: String
  ){
    debitNoteUpdate(
        _id: $_id
        status: $status
        receipt_id: $receipt_id
        receipt_date: $receipt_date
        debit_note_document: $debit_note_document
    ){
        _id
    }
}
`;
