import gql from 'graphql-tag';
export default gql`
mutation   Update(
    $sale_profit: [externalSaleProfitAddUpdateArgs]
){
    externalSaleProfitAddUpdate(
        sale_profit: $sale_profit
    ){
        succeed
    }
  }
`;