import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #ffffff;
  padding: 20px 40px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  box-sizing: border-box;
`;

const Card = props => {
  const { children, ...otherProps } = props;
  return <Container {...otherProps}>{children}</Container>;
};

export default Card;
