import gql from 'graphql-tag';

export default gql`
    mutation adminUpdate(
        $_id: ID!
        $adminUpdate: paramsUpdateAdmin
    )
    {
        adminUpdate
        (
        _id: $_id
            adminUpdate: $adminUpdate
        ) 
        {
            _id
            admin_username
            last_update
            blocked
        }
    } 
`;


