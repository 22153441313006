import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';

// graphql
import paymentOrderList from './../../graphql/query/paymentOrderList';
import paymentOrderUpdate from './../../graphql/mutation/paymentOrderUpdate';

const ModalContainer = styled(Modal)`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

class CostingConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  transporterName = paymentOrder => {
    const { transporter_name, driver_name, driver_user_id, transporter } = paymentOrder;
    const id = transporter_name === 'GIZTIX' ? driver_user_id : transporter;
    const name = transporter_name === 'GIZTIX' ? driver_name : transporter_name;
    return name;
  };

  render() {
    return (
      <Query
        query={paymentOrderList}
        variables={{
          _id: this.props.paymentOrderId,
        }}
      >
        {({ loading, error, data, refetch, variables }) => {
          if (loading || error) {
            return (
              <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>{loading ? 'Loading...' : error.message}</Modal.Body>
              </Modal>
            );
          }
          const { payment_order } = data.paymentOrderList;
          if (payment_order.length === 0) {
            return (
              <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>
                  <div>ไม่พบข้อมูล</div>
                </Modal.Body>
              </Modal>
            );
          }
          return (
            <ModalContainer show={this.props.show} onHide={this.props.onHide}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <strong>ส่งตั้งเบิกใช่หรือไม่?</strong>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <>
                  <Row>
                    <Col md={3}>รหัสใบสั่งจ่าย:</Col>
                    <Col md={9}>
                      <strong>
                        {payment_order[0].payments ? payment_order[0].payments.payment_number : '-'}
                      </strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>จำนวนตั้งเบิก:</Col>
                    <Col md={9}>
                      <strong>
                        {payment_order[0].total_price ? payment_order[0].total_price : '-'} บาท
                      </strong>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>ผู้รับเงิน (เจ้าหนี้):</Col>
                    <Col md={9}>
                      <strong>{this.transporterName(payment_order[0])}</strong>
                    </Col>
                  </Row>
                </>
              </Modal.Body>
              <Modal.Footer>
                <Button style={{ float: 'left', width: 60 }} onClick={this.props.onHide}>
                  ไม่ใช่
                </Button>
                <Mutation
                  mutation={paymentOrderUpdate}
                  variables={{
                    _id: payment_order[0]._id,
                    payment_order_status: 2,
                    payment_order_remark: this.props.remark,
                  }}
                  update={() => {
                    this.props.closePaymentOrderDetail();
                  }}
                  refetchQueries={() => ['paymentOrderList']}
                  onError={error => {
                    console.log(error);
                  }}
                >
                  {paymentOrderUpdate => {
                    return (
                      <Button
                        bsStyle="primary"
                        onClick={() => paymentOrderUpdate()}
                        style={{ width: 60 }}
                      >
                        ใช่
                      </Button>
                    );
                  }}
                </Mutation>
              </Modal.Footer>
            </ModalContainer>
          );
        }}
      </Query>
    );
  }
}

export default CostingConfirm;
