import gql from 'graphql-tag';

export default gql`
    query shipmentAdmins(
        $params: paramsShipmentAdmins
        $sort: sort
        $page: Int
        $showData: Int!
    )
    {
        shipmentAdmins(
            params: $params
            sort: $sort
            page: $page
            showData: $showData
            )
            {
            shipments{
                _id
                shipment_prefix
                shipment_status
                pick_up_date
                create_date
                completed_date
                shipment_payment_status{
                    waiting
                    complete
                    total
                }
                paid_status
                shipper{
                    _id
                    account_info_business_name
                    account_info_number
                    account_info_customer_type
                }
                shipper_detail
                transporter{
                    _id
                    account_info_business_name
                    account_info_customer_type
                }
                driver_user_id{
                    _id
                    driver_name
                    driver_phone_code
                    user_setting_truck_license_plate
                }
                user_setting_truck_id
                shipper_info{
                    account_info_email
                }
                shipment_type
                shipment_number
                shipment_running_number
                shipment_address{
                    _id
                    tag_name
                    contact_name
                    contact_tel
                    address
                    subdictrict
                    province
                    structured_formatting
                    {
                        secondary_text
                    }
                }
                re_shipment
                flash_deals_id
                cancel_comment
                round_trip
                setting_truck_name{
                    th
                    en
                }
                truck_type_name{
                    th
                    en
                }
                driver_user_id{
                    driver_name
                    driver_phone_code
                    driver_phone_number
                }
                shipment_additional{
                    _id
                    additional_id
                    additional
                    qty
                }
                sla_max_level
                sla_total
                sla_completed
                billing_costing
            }
            totalPage
            totalDocument
        }
    }
`;
