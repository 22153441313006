import styled from 'styled-components'

export const CustomPhotoAdd = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100px;
    height: 100px;
    background-color: ${props => props.check ? 'rgba(49, 141, 232, 0.1)'  :  'rgba(49, 141, 232, 0.1)'};
    border: ${props => props.check ? '2px dashed #318DE8'  :  '2px dashed #318DE8'};
    border-radius: 10px;
`;