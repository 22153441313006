import gql from 'graphql-tag';

export default gql`
mutation update(
  $admin_username : String!
  $admin_email : String!
  $admin_password : String!
  $admin_user_type: Int!
)
{
    adminRegister(
      admin_username: $admin_username,
      admin_password:$admin_password,
      admin_email:$admin_email ,
      admin_user_type:$admin_user_type 
    ){
      _id
    }
}
`;