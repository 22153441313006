import gql from 'graphql-tag';

export default gql`
    mutation updateShipmentSla(
        $params: paramsUpdateShipmentSla
        $updateShipmentSla: updateShipmentSlaArg
    ){
        updateShipmentSla(
        params: $params
            updateShipmentSla: $updateShipmentSla
        ) {
            _id
            shipment
            sla_case_number
            sla_exp
        }
    }
`;
