import React, { Component } from 'react';
import classes from '../../ShipmentDetail.module.scss';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';
import ShipmentDetailContextProvider from '../../contexts/ShipmentDetailContext';
import {
  ShipmentDetail,
  ShipmentTracking,
  SubShipmentTracking,
  SubShipmentTrackingOrder,
} from '../../containers';

import { ShipmentMap } from '../../components';

class ShipmentTabDetail extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ShipmentDetailContextProvider>
        <ShipmentDetailContext.Consumer>
          {context => {
            return (
              <div className={classes.ContentContainer}>
                <div className={classes.LeftContainer}>
                  <ShipmentDetail shipmentId={this.props._id} />

                  <ShipmentTracking shipmentId={this.props._id} />
                </div>
                <div className={classes.RightContainer}>
                  <ShipmentMap shipmentId={this.props._id} />
                  <div className={classes.TopContainer}>
                    {context.state.subShipmentTracking && (
                      <SubShipmentTracking shipmentId={this.props._id} />
                    )}

                    {context.state.subShipmentTrackingOrder && (
                      <SubShipmentTrackingOrder shipmentId={this.props._id} />
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </ShipmentDetailContext.Consumer>
      </ShipmentDetailContextProvider>
    );
  }
}

export default ShipmentTabDetail;
