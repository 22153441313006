import gql from 'graphql-tag';

export default gql`
mutation Update(
    $_id: ID!
    $read_status: Int
    $paid_credit_card_detail: typePaidCreditCardReceiptUpdate
    $paid_bank_transfer: typePaidBankTransferReceiptUpdate
    $receipt_document: String
  ){
    receiptSummaryUpdate(
        _id: $_id
        read_status: $read_status
        paid_credit_card_detail: $paid_credit_card_detail
        paid_bank_transfer: $paid_bank_transfer
        receipt_document: $receipt_document
    ){
        _id
    }
}
`;
