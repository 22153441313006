import gql from 'graphql-tag';

export default gql`
  mutation manage3plAssignAdd(
    $ka_id: ID
    $import_order_id: ID
    $name: String
    $trip_name: String
    $head_thrid_party: ID
    $name_head: String
    $trip: Int
    $trip_truck: [trip_truck_input]
    $warehouse: String
    $pick_up_date: String
    $order_type: String
    $third_party_list: [thirdParty]
  ){
    manage3plAssignAdd(
      ka_id: $ka_id
      import_order_id: $import_order_id
      name: $name
      trip_name: $trip_name
      head_thrid_party: $head_thrid_party
      name_head: $name_head
      trip: $trip
      trip_truck: $trip_truck
      warehouse: $warehouse
      pick_up_date: $pick_up_date
      order_type: $order_type
      third_party_list: $third_party_list
    ){
      _id
    }
  }
`;
