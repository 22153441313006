import React from 'react'
import styled from 'styled-components'
import renderColorStatus from '../../function/renderColorStatus'
import formatNumber from '../../function/formatNumber'

const StyledParcelRow = styled.tr`
    color: ${props => props.color ? props.color : '#fff'};

    td {
        display: flex;
        justify-content: ${props => props.quantity ? 'space-between': 'center'};
        box-sizing: border-box;
        width: 100%;
    }

    span {
        text-transform: uppercase;
    }
`

const ParcelRow = props => {
    return (
        <StyledParcelRow color={renderColorStatus(props.quantity,props.cpa)} quantity={props.quantity} >
            <td>
                <span>{props.name}</span>
                {props.quantity && <span>{formatNumber(props.quantity)}</span>}
            </td>
        </StyledParcelRow>
    )
}

export default ParcelRow