import gql from 'graphql-tag';

export default gql`
query accountInfoListSearchId (
    $account_info_business_name: String
) {
    accountInfoList (
        show_data : 10,
        account_info_business_name: $account_info_business_name
    ) {
        account_info {
      _id
    account_info_business_name
  }
    }
}
`;