import gql from "graphql-tag";

export default gql`
  mutation AssignDriver(
    $shipment_id: ID!
    $driver_user_id: ID!
    $user_setting_truck_id: ID!
  ) {
    assignDriver(
      shipment_id: $shipment_id
      driver_user_id: $driver_user_id
      user_setting_truck_id: $user_setting_truck_id
    ) {
      succeed
    }
  }
`;
