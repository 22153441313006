import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipmentID: ID!
    $shipment_status: Int
    $cancel_comment: String
    $shipment_refund: shipment_refund_input
    $shipment_fines: shipment_fines_input
){
    shipmentsUpdate(
        _id:$shipmentID,
        shipment_status: $shipment_status,
        cancel_comment: $cancel_comment,
        shipment_refund:$shipment_refund
        shipment_fines: $shipment_fines
    ){
        _id
    }
  }
`;
