import gql from 'graphql-tag';
import { lazada_delivery } from 'utils/GraphTypeModel';

export default gql`
  query shipmentKA($_id: ID!) {
    shipmentKA(_id: $_id) {
      _id
      ka_create_cost_status
      ka_driver_payment_type
      record_mileage
      mileage_start{
        mileage_date
        mileage
        mileage_img
        lat
        lng
      }
      mileage_end{
        mileage_date
        mileage
        mileage_img
        lat
        lng
      }
      total_distance
      distance_shortest_path
      working_period
      mileage_log{
        _id
        shipment
        admin
        mileage_start{
          mileage_date
          mileage
          mileage_img
          lat
          lng
        }
        mileage_end{
          mileage_date
          mileage
          mileage_img
          lat
          lng
        }
        total_distance
        distance_shortest_path
        working_period
        createdAt
        updatedAt
      }
      shipment_number
      shipper
      shipment_name
      shipment_status
      shipment_trip
      transporter
      transporter_name
      driver_user_id
      driver_name
      user_setting_truck_license_plate
      shipment_total_cod
      shipment_accept_cod
      setting_truck_type_id
      confirm_cod_status
      confirm_delivery_status
      shipment_ref_id
      trip_rloy
      billing_cod{
        _id
        cod_in_number
        cod_status
        cod_in_date
        document
        reject_remark
      }
      tracking {
        tracking_detail_lazada {
          pick_up {
            _id
            commodity {
              name
              accept
            }
          }
          delivery {
            ${lazada_delivery}
          }
          all_delivery{
            ${lazada_delivery}
          }
          return {
            pick_up_id
            delivery_point {
              delivery_status
              commodity {
                name
              }
            }
          }
        }
      }
    }
  }
`;
