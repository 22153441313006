import React from 'react';
import classes from './Tabs.module.scss';
import { Link } from 'react-router-dom';

const Tabs = props => {
  let menuList = null;

  const formatNumber = number => {
    return `${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    })}`;
  };

  if (props.menu.length > 0) {
    menuList = (
      <ul>
        {props.menu.map((item, index) => (
          <li key={index} className={item.active ? classes.active : ''} onClick={item.onPress}>
            <div>
              {item.icon && <i className={item.icon}></i>}
              {item.title}
              {<span className={`${item.number >= 10000 ? 'ten-thoundson' : item.number >= 1000 ? 'thoundson' : item.number >= 100 ? 'hundred' : '' }`}>{formatNumber(item.number) || 0}</span>}
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className={classes.Tabs} style={{ marginTop: 0 }}>
      {menuList}
    </div>
  );
};

export default Tabs;
