import React from 'react';
import { FormControl, Row, Col } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import Datetime from 'react-datetime';
import moment from 'moment';
import { MessageError, Divider } from './style';
import Shipment from './Shipment';
import ImportFile from './ImportFile';
import { orderType } from './constant/orderType';
import shipmentKAsList from 'views/KaCustomer/screens/ShipmentList2.0/graphql/query/shipmentKAsList';
import { client } from 'index';
import Autosuggest from 'react-autosuggest';

const Form = () => {
  const [search, setSearch] = React.useState('');
  const [refNumber, setRefNumber] = React.useState([]);

  const { control, watch, setValue, errors, reset } = useFormContext();
  const watchOrderType = watch('orderType');

  const isLoyWithType = (watchOrderType === orderType.TRIP_LOY && search?.split('_')[1]) || false;

  const isRM = watchOrderType === orderType.RETURN_TO_MERCHANT || isLoyWithType === 'RM';
  const isRT = watchOrderType === orderType.RETURN || isLoyWithType === 'RT';

  console.log('TYPE : ', {
    watchOrderType,
    isLoyWithType,
    errors,
  });

  const orderTypeOptions = [
    {
      value: orderType.LAST_MILE,
      label: 'Last Mile (LM)',
    },
    {
      value: orderType.RETURN_TO_MERCHANT,
      label: 'Return to Merchant (RM)',
    },
    {
      value: orderType.RETURN,
      label: 'Return (RT)',
    },
    {
      value: orderType.FIRST_MILE,
      label: 'First Mile (FM)',
    },
    {
      value: orderType.SHUTTLE_HAUL,
      label: 'Shuttle Haul (SH)',
    },
    {
      value: orderType.REJECT_RETURN,
      label: 'Reject Return (RR)',
    },
    {
      value: orderType.DIRECT_SHIP,
      label: 'Direct Ship (DS)',
    },
    {
      value: orderType.LINE_HAUL,
      label: 'Line Haul (LH)',
    },
    {
      value: orderType.BU_FIRST_MILE,
      label: 'BU First Mile (BUFM)',
    },
    {
      value: 0,
      label: 'Trip (LOY)',
    },
  ];

  const FunctionLoadRefNumber = async (input = '') => {
    try {
      const {
        data: {
          shipmentKAs: { shipments: data },
        },
      } = await client.query({
        query: shipmentKAsList,
        variables: {
          params: {
            shipment_trip: input,
          },
          page: 1,
          showData: 10,
        },
        fetchPolicy: 'network-only',
      });

      const formatData = data?.map(row => {
        return {
          id: row._id,
          name: row.shipment_trip,
        };
      });

      setRefNumber(formatData);
    } catch (error) {
      console.error('ERROR TO LOAD REF NUMBER', error);
    }
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <strong>ประเภทงาน:</strong>
          <Controller
            control={control}
            name="orderType"
            defaultValue=""
            render={({ onChange, value }) => (
              <FormControl
                componentClass="select"
                placeholder="select"
                value={value}
                onChange={event => {
                  onChange(event.target.value);
                  setValue('agentId', '');
                  setValue('truckType', '');
                  setValue('referenceNo', '');
                  setSearch('');
                  setValue('file', {
                    path: '',
                    fileName: '',
                  });
                  setValue('shipments', [
                    {
                      pickup: [],
                      delivery: [],
                    },
                  ]);
                }}
              >
                <option value="">เลือกประเภทงาน</option>
                {orderTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </FormControl>
            )}
          />

          <MessageError>{errors?.orderType?.message}</MessageError>
        </Col>

        <Col md={6}>
          <strong>รอบการจัดส่ง:</strong>
          <Controller
            as={<FormControl type="text" placeholder="รอบการจัดส่ง" />}
            name="tripName"
            control={control}
          />
          <MessageError>{errors?.tripName?.message}</MessageError>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <strong>Request BKG date:</strong>
          <Controller
            control={control}
            name="planDate"
            render={({ onChange, value }) => (
              <Datetime
                closeOnSelect
                timeFormat={false}
                dateFormat="DD/MM/YYYY"
                inputProps={{ placeholder: 'DD/MM/YYYY' }}
                value={value ? moment(value) : ''}
                onChange={momentDate => {
                  try {
                    onChange(momentDate.format('YYYY-MM-DD'));
                  } catch {
                    onChange('');
                  }
                }}
              />
            )}
          />
          <MessageError>{errors?.planDate?.message}</MessageError>
        </Col>

        {!isRM && !isRT && (
          <Col md={6}>
            <strong>Pickup Date:</strong>
            <Controller
              control={control}
              name="pickupDate"
              render={({ onChange, value }) => (
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  inputProps={{ placeholder: 'DD/MM/YYYY HH:mm' }}
                  value={value ? moment(value) : ''}
                  onChange={momentDate => {
                    try {
                      onChange(momentDate.format('YYYY-MM-DD HH:mm'));
                    } catch {
                      onChange('');
                    }
                  }}
                />
              )}
            />
            <MessageError>{errors?.pickupDate?.message}</MessageError>
          </Col>
        )}
      </Row>

      {watchOrderType === orderType.TRIP_LOY && (
        <Row>
          <Col md={12}>
            <strong>หมายเลขงานที่ใช่อ้างอิง:</strong>
            <Controller
              control={control}
              name="referenceNo"
              defaultValue=""
              render={({ onChange }) => {
                return (
                  <Autosuggest
                    suggestions={refNumber}
                    onSuggestionsFetchRequested={({ value }) => FunctionLoadRefNumber(value)}
                    onSuggestionsClearRequested={() => {
                      setRefNumber([]);
                    }}
                    getSuggestionValue={suggestion => {
                      return suggestion.id;
                    }}
                    renderSuggestion={suggestion => {
                      return (
                        <div
                          style={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderBottom: '1px solid #ccc',
                          }}
                          key={suggestion.id}
                        >
                          {suggestion.name ? suggestion.name : 'No Data Found'}
                        </div>
                      );
                    }}
                    inputProps={{
                      type: 'text',
                      placeholder: 'หมายเลขงานที่ใช่อ้างอิง',
                      value: search,
                      className: 'form-control',
                      onChange: e => {
                        // setSelected('');
                        setSearch(e.target.value || '');
                      },
                    }}
                    onSuggestionSelected={(_event, { suggestion }) => {
                      setSearch(suggestion.name || '');
                      onChange &&
                        onChange({
                          id: suggestion.id,
                          name: suggestion.name,
                        });
                    }}
                  />
                );
              }}
            />

            {errors?.referenceNo && <MessageError>กรุณาระบุเลขที่เอกสาร</MessageError>}
          </Col>
        </Row>
      )}

      {watchOrderType && <Divider />}
      {watchOrderType && !isRT && !isRM && <Shipment isLoyWithType={isLoyWithType} />}
      {watchOrderType && (isRT || isRM) && <ImportFile />}
    </div>
  );
};

export default Form;
