import React, { Component } from 'react';
import Moment from 'react-moment';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import { Layout, ModalConfirm } from '../../components';
import styled from 'styled-components';
import classesLazada from '../../Lazada.module.css';
import axios from 'axios';
import { UploadFile, FMCRCreateImportModal } from './container';
import baseUrl from 'config/baseUrl';
import restBaseUrl from 'config/restBaseUrl';
import adminListQuery from './graphql/query/adminList';
import { client } from '../../../../index';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import lazadaImportOrderUpdateMutation from './graphql/mutation/lazadaImportOrderUpdate';
import createShipmentLazadaMutation from './graphql/mutation/createShipmentLazada';
import firebase from 'firebase';
import firebaseConfig from 'config/firebaseConfig';
import customerId from 'config/customerId';
import CreateShipmentModal from '../../components/CreateShipmentModal';
import ImportBookingFileModal from '../../components/ImportBookingFileModalSingleUpload';
import DO_NOTHING_MUTATION from './graphql/mutation/doNothing';

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)``;

class BookingFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileStatus: 0,
      createDate: '',
      displayCreateDate: '',
      pickupDate: '',
      displayPickupDate: '',
      pageNumber: 1,
      isOpenModalConfirmCreateShipment: false,
      isOpenModalConfirmDelete: false,
      bookingNumber: '',

      //select file delete or create
      fileNameBookingFile: '',
      idBookingFile: null,
      isLoading: false,

      //craete shipment modal
      isOpenCreateShipmentModal: false,
      pickupDateForCreateShipment: '',
      bookingNumberForCreateShipment: '',
      modeForCreateShipment: '',

      //import booking file
      isOpenImportBookingFileModal: false,

      //FMCR import file
      isOpenFMCRImportFileMOdal: false,
    };

    this._inputImportFile = React.createRef();
    this._inputImportFile1 = React.createRef();
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  componentDidMount() {
    this.getReportData();
  }

  refetchQueries = () => {
    setTimeout(() => {
      client.mutate({
        mutation: DO_NOTHING_MUTATION,
        refetchQueries: () => ['LazadaImportOrderListBookingFile'],
      });
    }, 500);
  };

  getReportData = async () => {
    try {
      firebase
        .database()
        .ref(`/lazada_import_order`)
        .limitToLast(1)
        .on('value', async snapshot => {
          if (snapshot.numChildren() === 1) {
            snapshot.forEach(child => {
              const val = child.val();
            });
          }
          this.refetchQueries();
        });
    } catch (error) {
      console.log(error);
    }
  };

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="info">กำลังอัพโหลด</Label>;
      case 2:
        return <Label bsStyle="warning">รอสร้างงานขนส่ง</Label>;
      case 3:
        return <Label bsStyle="danger">ผิดพลาด</Label>;
      default:
        return <Label>{status}</Label>;
    }
  }

  handleDateRangePicker(mode, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({ pickupDate: date, displayPickupDate: display });
    }
  }
  uploadFileHandler = async (file, refetchLazadaImportOrderList, mode) => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const adminId = admin[0]._id;
      const url = `${restBaseUrl}/importorderlazada?path=${file.path}&name=${file.fileName}&admin_id=${adminId}&order_type=${mode}`;
      await axios(url).then(res => {
        if (!res.data._id) {
          alert('Error');
          return;
        }

        refetchLazadaImportOrderList();
      });
    } catch (error) {
      alert(error);
    }
  };

  deleteBookingFile = async () => {
    try {
      this.setState({ isLoading: true });

      const { idBookingFile } = this.state;
      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          _id: idBookingFile,
        },
      });

      const { order } = data.lazadaImportOrderList;

      if (order[0].status === 4) {
        alert('ไม่สามารถลบรายการได้');
        this.setState({ isLoading: false });
        return;
      }

      await this.props
        .lazadaImportOrderUpdateMutation({
          variables: {
            _id: idBookingFile,
            status: 10,
          },
          refetchQueries: ['LazadaImportOrderListBookingFile'],
        })
        .then(({ data }) => {
          if (data.lazadaImportOrderUpdate._id) {
            this.setState({
              isLoading: false,
              isOpenModalConfirmDelete: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmDelete: false,
      });

      alert(error);
    }
  };

  createShipmentLazada = async () => {
    try {
      this.setState({ isLoading: true });

      const { idBookingFile } = this.state;
      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          _id: idBookingFile,
        },
      });

      const { order } = data.lazadaImportOrderList;
      if (order[0].status !== 2 || order[0].status === 4) {
        alert('ไม่สามารถสร้างรายการได้');
        this.setState({ isLoading: false });
        return;
      }

      await this.props
        .createShipmentLazadaMutation({
          variables: {
            _id: idBookingFile,
          },
          refetchQueries: ['LazadaImportOrderListBookingFile'],
        })
        .then(({ data }) => {
          if (data.createShipmentLazada._id) {
            this.setState({
              isLoading: false,
              isOpenModalConfirmCreateShipment: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmCreateShipment: false,
      });

      alert(error);
    }
  };

  //create shipment modal function

  openCreateShipmentModal = () => {
    this.setState({ isOpenCreateShipmentModal: true });
  };

  closeCreateShipmentModal = () => {
    this.setState({
      isOpenCreateShipmentModal: false,
      pickupDateForCreateShipment: '',
      bookingNumberForCreateShipment: '',
    });
  };

  //Import File
  openImportFileModal = () => {
    this.setState({
      isOpenImportBookingFileModal: true,
    });
  };

  renderStatusMode = status => {
    switch (status) {
      case 1:
        return <span class="label label-success">Last Mile (NM)</span>;
      case 2:
        return <span class="label label-danger">Return to Merchant (RM)</span>;
      case 3:
        return (
          <span class="label" style={{ backgroundColor: '#318DE8' }}>
            FIRST MILE (FM)
          </span>
        );
      case 4:
        return (
          <span class="label" style={{ backgroundColor: '#FFDB46', color: 'black' }}>
            SPECIAL (SP)
          </span>
        );
      case 5:
        return (
          <span class="label" style={{ backgroundColor: '#ff9800' }}>
            Customer Return (CR)
          </span>
        );
      case 6:
        return (
          <span class="label" style={{ backgroundColor: '#82c5b9' }}>
            Return to WH (RTWH)
          </span>
        );
      case 7:
        return (
          <span class="label" style={{ backgroundColor: '#82c5f9' }}>
            Return to Customer (CR/RC)
          </span>
        );
      case 8:
        return (
          <span class="label" style={{ backgroundColor: '#FF0505' }}>
            Return to Merchant (CR/RM)
          </span>
        );
      case 9:
        return (
          <span class="label" style={{ backgroundColor: '#d6949e' }}>
            Line Haul (LH)
          </span>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <Layout>
        <Row>
          <Col md={12}>
            <div>
              <Query
                query={lazadaImportOrderListQuery}
                variables={{
                  name: this.state.fileName,
                  format_bookname: this.state.bookingNumber,
                  status: this.state.fileStatus,
                  create_shipment: 1,
                  pick_up_date: this.state.pickupDate,
                  create_date: this.state.createDate,
                  page_number: this.state.pageNumber,
                  account_info_id: customerId.lazada,
                }}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error, refetch: refetchLazadaImportOrderList }) => {
                  //  console.log("lazadaImportOrderListQuery" , data)
                  return (
                    <div>
                      <CardTable
                        title="ตาราง File Booking"
                        category="*ต้อง Import file booking มาที่ตารางนี้ก่อน สร้าง Shipment ทุกครั้ง"
                        ctTableFullWidth
                        ctTableResponsive
                        rightButton={
                          <div style={{ float: 'right', marginLeft: '15px' }}>
                            <Button
                              style={{
                                background: '#ff9800',
                                color: '#fff',
                                marginRight: '10px',
                                display: 'inline-flex',
                                alignItems: 'center',
                              }}
                              onClick={() => this.setState({ isOpenFMCRImportFileMOdal: true })}
                            >
                              <i style={{ marginRight: '5px' }} className="far fa-plus-circle"></i>
                              LM-Request
                            </Button>

                            <Button
                              style={{
                                background: '#87CB16',
                                color: '#ffffff',
                                marginRight: '15px',
                              }}
                              onClick={this.openImportFileModal}
                            >
                              <i className="fas fa-cloud-upload"></i> import File
                            </Button>
                            {
                              // <UploadFile
                              //   completed={file => {
                              //     this._inputImportFile1.current.value = null;
                              //     this.uploadFileHandler(file, refetchLazadaImportOrderList,1); //1=NORMAL,2=FAILED
                              //   }}
                              // >
                              //   {selectFile => {
                              //     return (
                              //       <React.Fragment>
                              //         <input
                              //           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              //           onChange={event => {
                              //             selectFile(
                              //               event,
                              //               `upload/lazadaProject/document_${new Date().getTime()}`
                              //             );
                              //           }}
                              //           type="file"
                              //           id="file"
                              //           ref={this._inputImportFile1}
                              //           style={{ display: 'none' }}
                              //         />
                              //         <Button style={{background:"#87CB16",color:"#ffffff",marginRight:"15px"}} onClick={() => this._inputImportFile1.current.click()}>
                              //           <i className="fas fa-cloud-upload"></i> Import booking <b>"NORMAL"</b>
                              //         </Button>
                              //       </React.Fragment>
                              //     );
                              //   }}
                              // </UploadFile>
                              // <UploadFile
                              //   completed={file => {
                              //     this._inputImportFile.current.value = null;
                              //     this.uploadFileHandler(file, refetchLazadaImportOrderList,2);//1=NORMAL,2=FAILED
                              //   }}
                              // >
                              //   {selectFileFailed => {
                              //     return (
                              //       <React.Fragment>
                              //         <input
                              //           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              //           onChange={event => {
                              //             selectFileFailed(
                              //               event,
                              //               `upload/lazadaProject/document_${new Date().getTime()}`
                              //             );
                              //           }}
                              //           type="file"
                              //           id="file"
                              //           ref={this._inputImportFile}
                              //           style={{ display: 'none' }}
                              //         />
                              //         <Button style={{background:"#ED1C24",color:"#ffffff"}} onClick={() => this._inputImportFile.current.click()}>
                              //           <i className="fas fa-cloud-upload"></i> Import booking <b>"FAILED"</b>
                              //         </Button>
                              //       </React.Fragment>
                              //     );
                              //   }}
                              // </UploadFile>
                            }
                          </div>
                        }
                        content={
                          <div>
                            <div className="table-vertical">
                              <Table striped hover style={{ marginBottom: '60px' }}>
                                <thead>
                                  <tr>
                                    <th style={{ minWidth: '140px' }}>เครื่องมือ</th>
                                    <th>mode</th>
                                    <th style={{ minWidth: '200px' }}>Booking Number</th>
                                    <th style={{ minWidth: '200px' }}>ชื่อไฟล์</th>
                                    <th style={{ minWidth: '100px' }}>วันที่รับสินค้า</th>
                                    <th style={{ minWidth: '140px' }}>สถานะ</th>
                                    <th style={{ minWidth: '120px' }}>อัพโหลดโดย</th>
                                    <th style={{ minWidth: '120px' }}>วันที่ Import file</th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        placeholder="ค้นหา booking number"
                                        name="bookingNumber"
                                        value={this.state.bookingNumber}
                                        onChange={event => {
                                          this.setState({
                                            bookingNumber: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        placeholder="ค้นหาชื่อไฟล์"
                                        name="file_name"
                                        value={this.state.fileName}
                                        onChange={event => {
                                          this.setState({
                                            fileName: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                      />
                                    </th>
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker('pickupDate', picker)
                                        }
                                        onCancel={() =>
                                          this.setState({
                                            pickupDate: '',
                                            displayPickupDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.displayPickupDate}
                                          placeholder="วันที่รับสินค้า"
                                        />
                                      </DateRangePicker>
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.fileStatus}
                                        onChange={event =>
                                          this.setState({
                                            fileStatus: event.target.value,
                                            pageNumber: 1,
                                          })
                                        }
                                        name="shipment_status"
                                      >
                                        <option value={0}>ทั้งหมด</option>
                                        <option value={1}>กำลังอัพโหลด</option>
                                        <option value={2}>รอสร้างงานขนส่ง</option>
                                        <option value={3}>ผิดพลาด</option>
                                      </FormControl>
                                    </th>
                                    <th></th>
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker('createDate', picker)
                                        }
                                        onCancel={() =>
                                          this.setState({
                                            createDate: '',
                                            displayCreateDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.displayCreateDate}
                                          placeholder="วันที่ Import file"
                                        />
                                      </DateRangePicker>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading && (
                                    <tr>
                                      <td colSpan="7">Loading...</td>
                                    </tr>
                                  )}

                                  {error && (
                                    <tr>
                                      <td colSpan="7">Error</td>
                                    </tr>
                                  )}

                                  {!loading &&
                                    !error &&
                                    data.lazadaImportOrderList.order.length === 0 && (
                                      <tr>
                                        <td colSpan="7">ไม่มีข้อมูล</td>
                                      </tr>
                                    )}

                                  {!loading &&
                                    !error &&
                                    data.lazadaImportOrderList.order.length > 0 &&
                                    data.lazadaImportOrderList.order.map((item, index) => (
                                      <tr key={index}>
                                        <td>
                                          <NavDropdownContainer
                                            title={
                                              <Button>
                                                เครื่องมือ <i className="fal fa-angle-down"></i>
                                              </Button>
                                            }
                                          >
                                            {item.status === 2 && (
                                              <NavItemDropDownList
                                                onClick={() => {
                                                  if (
                                                    item.order_type == 3 ||
                                                    item.order_type == 5 ||
                                                    item.order_type == 9
                                                  ) {
                                                    this.setState({
                                                      isOpenModalConfirmCreateShipment: true,
                                                      fileNameBookingFile: item.name,
                                                      idBookingFile: item._id,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      // isOpenModalConfirmCreateShipment: true,
                                                      isOpenCreateShipmentModal: true,
                                                      modeForCreateShipment: item.order_type,
                                                      fileNameBookingFile: item.name,
                                                      idBookingFile: item._id,
                                                      pickupDateForCreateShipment:
                                                        item.pick_up_date,
                                                      bookingNumberForCreateShipment:
                                                        item.format_bookname,
                                                    });
                                                  }
                                                }}
                                              >
                                                <i className="fas fa-user-tie"></i> สร้างงานขนส่ง
                                              </NavItemDropDownList>
                                            )}

                                            <NavItemDropDownList
                                              onClick={() => {
                                                this.setState({
                                                  isOpenModalConfirmDelete: true,
                                                  fileNameBookingFile: item.name,
                                                  idBookingFile: item._id,
                                                });
                                              }}
                                            >
                                              <i className="fas fa-truck"></i> ลบ
                                            </NavItemDropDownList>
                                          </NavDropdownContainer>
                                        </td>
                                        <td>{this.renderStatusMode(item.order_type)}</td>
                                        <td>
                                          {item.format_bookname ? item.format_bookname : null}
                                        </td>
                                        <td>
                                          {item.name}
                                          {item.error_result && (
                                            <div style={{ color: '#ff0600' }}>
                                              {item.error_result}
                                            </div>
                                          )}
                                        </td>
                                        <td
                                          className={`${classesLazada.textDanger} ${classesLazada.textBold}`}
                                        >
                                          {item.pick_up_date ? (
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {item.pick_up_date}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td>{this.renderStatus(item.status)}</td>
                                        <td>{item.admin_username}</td>
                                        <td>
                                          <Moment format="DD/MM/YYYY HH:mm:ss">
                                            {item.create_date}
                                          </Moment>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                            <div style={{ textAlign: 'right', marginTop: 20 }}>
                              {!loading && !error && (
                                <ReactPaginate
                                  pageCount={data.lazadaImportOrderList.total_page}
                                  forcePage={this.state.pageNumber - 1}
                                  containerClassName="pagination"
                                  nextLabel=">"
                                  previousLabel="<"
                                  onPageChange={({ selected }) => {
                                    this.setState({ pageNumber: selected + 1 });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        }
                      />
                      {this.state.isOpenImportBookingFileModal && (
                        <ImportBookingFileModal
                          refetchLazadaImportOrderList={() => refetchLazadaImportOrderList()}
                          showImportModal={this.state.isOpenImportBookingFileModal}
                          closeModal={() => this.setState({ isOpenImportBookingFileModal: false })}
                        />
                      )}

                      {this.state.isOpenCreateShipmentModal && (
                        <CreateShipmentModal
                          showModal={this.state.isOpenCreateShipmentModal}
                          closeModal={this.closeCreateShipmentModal}
                          customerId={customerId.lazada}
                          idBookingFile={this.state.idBookingFile}
                          fileNameBookingFile={this.state.fileNameBookingFile}
                          pickupDateForCreateShipment={this.state.pickupDateForCreateShipment}
                          bookingNumberForCreateShipment={this.state.bookingNumberForCreateShipment}
                          modeForCreateShipment={this.state.modeForCreateShipment}
                          refetchLazadaImportOrderList={() => refetchLazadaImportOrderList()}
                        />
                      )}
                    </div>
                  );
                }}
              </Query>
            </div>

            <ModalConfirm
              show={this.state.isOpenModalConfirmDelete}
              onPressButtonConfirm={this.deleteBookingFile}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'ลบ...' : 'ลบ'}
              labelButtonCancel={`ยกเลิก`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmDelete: false })}
              titleHeader={`คุณต้องการลบ File Booking นี้ ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmDelete: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            <ModalConfirm
              show={this.state.isOpenModalConfirmCreateShipment}
              onPressButtonConfirm={this.createShipmentLazada}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'สร้าง...' : 'สร้าง'}
              labelButtonCancel={`ยกเลิก`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmCreateShipment: false })}
              titleHeader={`คุณสร้างงานขนส่ง(Shipment) จาก File นี้ ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmCreateShipment: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            {
              // this.state.isOpenCreateShipmentModal &&
              // <CreateShipmentModal
              //   showModal={this.state.isOpenCreateShipmentModal}
              //   closeModal={this.closeCreateShipmentModal}
              //   customerId={customerId.lazada}
              //   idBookingFile={this.state.idBookingFile}
              //   fileNameBookingFile={this.state.fileNameBookingFile}
              //   pickupDateForCreateShipment={this.state.pickupDateForCreateShipment}
              //   bookingNumberForCreateShipment={this.state.bookingNumberForCreateShipment}
              //   modeForCreateShipment={this.state.modeForCreateShipment}
              // />
            }

            {this.state.isOpenFMCRImportFileMOdal && (
              <FMCRCreateImportModal
                open={this.state.isOpenFMCRImportFileMOdal}
                close={() => this.setState({ isOpenFMCRImportFileMOdal: false })}
              />
            )}
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default compose(
  graphql(lazadaImportOrderUpdateMutation, {
    name: 'lazadaImportOrderUpdateMutation',
  }),
  graphql(lazadaImportOrderListQuery, {
    name: 'lazadaImportOrderListQuery',
    fetchPolicy: 'cache-and-network',
  }),
  graphql(createShipmentLazadaMutation, {
    name: 'createShipmentLazadaMutation',
  })
)(BookingFiles);
