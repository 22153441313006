import gql from 'graphql-tag';

export default gql`
	query searchDriverMonthlyCalendarTables(
        $month: String
        $account_info_id: String
    ){
    driverMonthlyCalendarTables(
        month: $month
        account_info_id: $account_info_id
    ) {
        drivers{
            driver{
            _id
            driver_name
            user_setting_truck_license_plate
        }
        account_info{
            _id
            account_info_business_name
        }
        calendars{
            _id
            month
            date
            ready
            createdAt
            updatedAt
        }
        driver_route_service{
            postcode
        }
        }
    }
    }
`;
