import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Modal,
  Table,
  FormControl,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Mutation, graphql } from 'react-apollo';
import { compose } from 'redux';
import NotificationSystem from 'react-notification-system';
import Moment from 'react-moment';
import moment from 'moment/moment.js';
import urijs from 'urijs';
import s3 from 'config/s3';

import { Card, Input, InputDatetime, SelectBox, Select, Label } from './components';

import driverUserUpdateMutation from './graphql/mutation/driverUserUpdate';
import uploadFileMutation from './graphql/mutation/uploadFile';
import singleUploadMutation from './graphql/mutation/singleUpload';
import driverUserListQuery from './graphql/query/driverUserList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import regionsData from 'config/province/regions/th.json';
import provinceData from 'config/province/changwats/th.json';
import districtData from 'config/province/amphoes/th.json';
import subDistrictData from 'config/province/tambons/th.json';
import postcodesData from 'config/province/postcodes/th.json';
import accountInfoListQuery from './graphql/query/accountInfoList';
import accountInfoListShowAllQuery from './graphql/query/accountInfoListShowAll';

//react tag input
const KeyCodes = {
  comma: 188,
  enter: 13,
};

//styled component
const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const Container = styled.div`
	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}

	& .text-green {
		color: #19AA19;
	}

	& .text-blue {
		color: #318DE8;
	}

	& .text-red {
		color: #FF0000;
	}

	& .text-grey {
		color: #F2F2F2;
	}

	& .text-primary {
		color: #D90101 !important;
	}
`;

const TextHeader = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
`;

const TextSubHeader = styled.div`
  font-size: 18px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
`;

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  padding-left: 35px !important;
  margin-top: 12px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  &:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }

  & input:checked ~ .checkmark {
    border: 1px solid #ffffff;
    background-color: #19aa19;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const TextRight = styled.div`
  height: 40px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const TextContent = styled.div`
  font-size: 18px;
  line-height: 1;
  margin-top: 10px;
`;

const TextMessageError = styled.span`
  font-size: 18px;
  color: #ff0000;
  height: 22px;
  text-align: right;
`;

const selectOptionTypeBusiness = [
  { value: '', label: '-- เลือก --' },
  { value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
  { value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
  { value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
  { value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
  { value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
  { value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
  { value: 'การบริการ', label: 'การบริการ' },
  { value: 'เครื่องจักร', label: 'เครื่องจักร' },
  { value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
  { value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
  { value: 'บ้านและสวน', label: 'บ้านและสวน' },
  { value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
  { value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
  { value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
  { value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const selectOptionCountry = [
  { value: '', label: '-- เลือก --' },
  { value: 'ไทย', label: 'ไทย' },
];

const StatusContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
	padding: 20px;
	
	& .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		&-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 44px
			height: 44px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.1);

			& i {
				margin: auto;
				font-size: 20px;
			}

			&-danger {
				background-color: rgba(217, 1, 1, 0.1);

				& i {
					color: #D90101;
				}
			}

			&-success {
				background-color: rgba(25, 170, 25, 0.1);

				& i {
					color: #19AA19;
				}
			}
		}

		&-text {
			flex: 1;
			margin-left: 8px;
			line-height: 1;
			font-size: 18px;
			font-weight: bold;
		}

		& .nomargin {
			margin: 0;
		}
	}

	& .detail {
		font-size:18px;
		line-height: 1.4;
		margin-top: 10px;

		&-list {
			display: flex;
			flex-direction: row;

			&-left {
				align-self: flex-start;
				flex: 1;
			}

			&-right {
				align-self: flex-end;
			}
		}
	}

	& .text-danger {
		color: #D90101;
		font-weight: bold;
	}
`;

const CreditContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  line-height: 1;
  align-items: flex-end;

  & .text-header {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  & .text-bold {
    font-weight: bold;
  }

  & .text-medium {
    font-size: 24px;
  }

  & .text-large {
    font-size: 28px;
  }

  & .line {
    background-color: #e3e3e3;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;
const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const FileContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  padding: 5px 0px 5px 10px;
  height: 40px;
  box-sizing: border-box;

  ${props =>
    props.isBorderValidated ? ` border: 1px solid #FF0000; ` : ` border: 1px solid #E3E3E3; `}

  & .file-left {
    flex: 1;
    display: flex;
    flex-direction: row;

    &-icon {
      font-size: 20px;
      margin-right: 10px;
    }

    &-text {
      font-size: 18px;
    }
  }

  & .file-right {
    display: flex;
    flex-direction: row;

    &-filename {
      margin-right: 10px;
    }

    &-delete {
      margin-right: 10px;
      cursor: pointer;
    }

    & button {
      border-radius: 0px;
      border-left-width: 1px;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
    }
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const TagsEmail = styled.div`
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  padding: 11px;
  line-height: 1;
  background-color: #f9f9f9;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;

  & span.delete {
    font-size: 18px;
    color: #cacaca;
    cursor: pointer;
    margin-left: 5px;
  }
`;

const ImageProfile = styled.div`
  margin: 20px auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  ${props =>
    props.isBorderValidated ? ` border: 1px solid #FF0000; ` : ` border: 1px solid #F2F2F2; `}
  position: relative;
  overflow: hidden;

  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: rgba(0, 0, 0, 0.1);
  }

  & img {
    width: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;

const ButtonUpload = styled.button`
  background-color: #ffffff;
  color: #000000;
  font-size: 18px;
  padding: 4px 15px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin: 0 4px;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #f9f9f9;
  }
`;

class BusinessDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reject_reason: '',
      showEdit: false,
      _id: '',
      driver_number: '',
      document1: '',
      document2: '',
      document3: '',
      document4: '',
      document5: '',
      document6: '',
      document7: '',
      truckType: [],
      settingTruckList: [],
      display_picture: '',
      driver_license_exp: '',
      driver_license_exp_show: '',
      driver_block: 1,
      driver_type: 'บุคคลธรรมดา',
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      driver_enable: '1',
      user_setting_truck_license_plate: '',
      user_setting_truck_license_plate_back: '',
      driver_status: '1',
      line_id: '',
      reserve_phone_code: '66',
      reserve_phone_number: '',
      id_card_number: '',
      id_card_pic: '',
      driver_card_pic: '',
      car_pic: '',
      license_plate_pic: '',
      car_insurance_pic: '',
      book_bank_pic: '',
      setting_truck_service: [],
      driver_delivery_vat: '',
      driver_delivery_wht: '',
      driver_delivery_vat: '',
      driver_service_vat: '',
      driver_service_wht: '',
      setting_truck_id: '',
      setting_truck_type_id: '',
      driver_car_brand: '',
      driver_block_remark: '',
      account_info_id: '',
      bank_account_id: '',
      bank_name: '',
      bank_branch: '',
      account_name: '',
      account_number: '',
      agentIdParamUrl: this.getParamAgentId(),

      taxId: '',
      taxIdInvalid: false,
      branch: '',
      branchInvalid: false,
      postcode: { value: '', label: '-- เลือก --' },
      postcodeInvalid: false,
      address: '',
      addressInvalid: false,
      agentChoosed: { account_info_id: '', agent_name: '', driver_name: '' },
      agentChooseInvalid: false,
      agentSelected: [],
      agentSelectedInvalid: false,
      agentToRemove: [],
      isTplAdd: false,
      country: { value: '', label: '-- เลือก --' },
      countryInvalid: false,
      province: { value: '', label: '-- เลือก --', pid: '' },
      provinceInvalid: false,
      district: { value: '', label: '-- เลือก --', pid: '' },
      districtInvalid: false,
      subdistrict: { value: '', label: '-- เลือก --' },
      subdistrictInvalid: false,
      provicetToRemove: [],
      provinceSelected: [],
      isProviceAdd: false,
      agentSelectedProvice: { value: '', label: '-- เลือก --' },
      proviceChoose: {
        region: { value: '', label: '-- เลือก --', pid: '' },
        provine: { value: '', label: '-- เลือก --', pid: '' },
        district: { value: '', label: '-- เลือก --', pid: '' },
        subdistrict: { value: '', label: '-- เลือก --', pid: '' },
        postcode: { value: '', label: '-- เลือก --' },
        regionsInvalid: false,
        provinceInvalid: false,
        districtInvalid: false,
        subdistrictInvalid: false,
        postcodeInvalid: false,
      },
      proviceChooseInvalid: false,
    };

    this._inputDocument1 = React.createRef();
    this._inputDocument2 = React.createRef();
    this._inputDocument3 = React.createRef();
    this._inputDocument4 = React.createRef();
    this._inputDocument5 = React.createRef();
    this._inputDocument6 = React.createRef();
    this._inputDocument7 = React.createRef();
    this._inputProfileImage = React.createRef();

    this.notificationSystem = React.createRef();

    this._inputCredit = React.createRef();
    this._inputCreditApproved = React.createRef();
  }

  getParamAgentId() {
    return urijs(window.location.href).query(true).agentId;
  }

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  componentWillReceiveProps(newProps) {
    const { loading, error, settingTruckList } = newProps.settingTruckListQuery;
    if (!loading && !error) {
      this.setState({
        settingTruckList: settingTruckList,
      });
    }

    const { loadingdriver, errordriver, driverUserList } = newProps.driverUserListQuery;
    if (!loadingdriver && !errordriver && driverUserList != undefined) {
      //   console.log('driverUserList', driverUserList);
      const driver = driverUserList.driver_user[0];
      let agentSelectData = [];
      let provinceSelectedData = [];
      if (driver.driver_agent_detail.length > 0) {
        agentSelectData = driver.driver_agent_detail.map(data => {
          let newData = { ...data };
          delete newData.__typename;
          return newData;
        });

        driver.driver_agent_detail.map(driverdata => {
          if (driverdata.driver_route_service.length > 0) {
            const province = driverdata.driver_route_service.map(route_service => {
              let newData = { ...route_service };
              newData.account_info = driverdata.account_info_id;
              newData.driver = urijs(window.location.href).query(true).id;
              return newData;
            });
            console.log('driverUserList province', province);
            let newData = [...provinceSelectedData];
            provinceSelectedData = newData.concat(province);
          }
        });
      }
      console.log('driverUserList', provinceSelectedData);

      this.setState({
        _id: driver._id,
        agentSelected: agentSelectData.length > 0 ? agentSelectData : [],
        provinceSelected: provinceSelectedData.length > 0 ? provinceSelectedData : [],
        account_info_id: driver.account_info_id,
        driver_block: driver.driver_block ? driver.driver_block : 1,
        driver_number: driver.driver_number || '',
        document1: driver.id_card_pic.length > 0 ? driver.id_card_pic[0].path : '',
        document2: driver.driver_card_pic.length > 0 ? driver.driver_card_pic[0].path : '',
        document3: driver.car_pic.length > 0 ? driver.car_pic[0].path : '',
        document4: driver.license_plate_pic.length > 0 ? driver.license_plate_pic[0].path : '',
        document5: driver.act_pic.length > 0 ? driver.act_pic[0].path : '',
        document6: driver.car_insurance_pic.length > 0 ? driver.car_insurance_pic[0].path : '',
        document7: driver.book_bank_pic.length > 0 ? driver.book_bank_pic[0].path : '',
        display_picture: driver.driver_display_pic || '',
        driver_license_exp: driver.driver_license_exp
          ? moment(driver.driver_license_exp).format('YYYY-MM-DD')
          : '',
        driver_license_exp_show: driver.driver_license_exp
          ? moment(driver.driver_license_exp).format('DD/MM/YYYY')
          : '',
        driver_type: driver.driver_type || 'บุคคลธรรมดา',
        driver_name: driver.driver_name || '',
        driver_phone_code: driver.driver_phone_code || '66',
        driver_phone_number: driver.driver_phone_number ? 0 + driver.driver_phone_number : '',
        driver_enable: driver.driver_enable || 1,
        user_setting_truck_license_plate: driver.user_setting_truck_license_plate || '',
        user_setting_truck_license_plate_back: driver.user_setting_truck_license_plate_back || '',
        driver_status: driver.driver_status || 1,
        line_id: driver.line_id || '',
        reserve_phone_code: driver.reserve_phone_code || '66',
        reserve_phone_number: driver.reserve_phone_number ? 0 + driver.reserve_phone_number : '',
        id_card_number: driver.id_card_number || '',
        setting_truck_service: driver.setting_truck_service || '',
        driver_delivery_vat: driver.driver_delivery_vat || 0,
        driver_delivery_wht: driver.driver_delivery_wht || 0,
        driver_service_vat: driver.driver_service_vat || 0,
        driver_service_wht: driver.driver_service_wht || 0,
        setting_truck_type_id:
          driver.setting_truck_service.length > 0 ? driver.setting_truck_service[0] : '',
        driver_car_brand: driver.driver_car_brand || '',
        driver_license_number: driver.driver_license_number || '',
        driver_block_remark: driver.driver_block_remark || '',
        bank_account_id:
          driver.bank_account && driver.bank_account.length > 0 ? driver.bank_account[0]._id : null,
        bank_name:
          driver.bank_account && driver.bank_account.length > 0
            ? driver.bank_account[0].bank_name
            : '',
        bank_branch:
          driver.bank_account && driver.bank_account.length > 0
            ? driver.bank_account[0].bank_branch
            : '',
        account_name:
          driver.bank_account && driver.bank_account.length > 0
            ? driver.bank_account[0].account_name
            : '',
        account_number:
          driver.bank_account && driver.bank_account.length > 0
            ? driver.bank_account[0].account_number
            : '',

        branch: driver.branch || '',
        address: driver.address || '',
        province: this.renderProvinceData(driver.province),
        district: this.renderDistrictData(driver.district),
        subdistrict: this.renderSubDistrictData(driver.sub_district),
        postcode: this.renderPostcodesData(driver.postcode),
      });
    }
  }

  clickIsProviceAdd(agent_name, account_info_id) {
    this.setState({
      isProviceAdd: true,
      agentSelectedProvice: { value: account_info_id, label: agent_name },
      proviceChoose: {
        region: { value: '', label: '-- เลือก --', pid: '' },
        provine: { value: '', label: '-- เลือก --', pid: '' },
        district: { value: '', label: '-- เลือก --', pid: '' },
        subdistrict: { value: '', label: '-- เลือก --', pid: '' },
        postcode: { value: '', label: '-- เลือก --' },
        regionsInvalid: false,
        provinceInvalid: false,
        districtInvalid: false,
        subdistrictInvalid: false,
        postcodeInvalid: false,
      },
      proviceChooseInvalid: false,
    });
  }

  getprovinceSelected(id) {
    console.log('driverUserList getprovinceSelected', id, this.state.provinceSelected[0]);
    let dataFilter = this.state.provinceSelected.filter(e => e.account_info == id);
    if (dataFilter[0] != undefined) {
      return dataFilter;
    }
    return [];
  }

  renderRegionsData(data) {
    let dataFilter = regionsData.th.regions.filter(e => e.name === data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data, pid: dataFilter[0].pid };
    }
    return { value: '', label: '-- เลือก --', pid: '' };
  }

  renderProvinceData(data) {
    let dataFilter = provinceData.th.changwats.filter(e => e.name === data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data, pid: dataFilter[0].pid };
    }
    return { value: '', label: '-- เลือก --', pid: '' };
  }

  renderDistrictData(data) {
    let dataFilter = districtData.th.amphoes.filter(e => e.name === data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data, pid: dataFilter[0].pid };
    }
    return { value: '', label: '-- เลือก --', pid: '' };
  }

  renderSubDistrictData(data) {
    let dataFilter = subDistrictData.th.tambons.filter(e => e.name === data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data, pid: dataFilter[0].pid };
    }
    return { value: '', label: '-- เลือก --', pid: '' };
  }

  renderPostcodesData(data) {
    let dataFilter = postcodesData.th.postcodes.filter(e => e.zipcode == data);
    if (dataFilter[0] != undefined) {
      return { value: data, label: data };
    }
    return { value: '', label: '-- เลือก --' };
  }

  selectOptionRegionsData() {
    let options = [{ value: '', label: '-- เลือก --', pid: '' }];

    regionsData.th.regions.map((item, index) => {
      const region = { value: item.name, label: item.name, pid: item.pid };
      options.push(region);
      return region;
    });

    return options;
  }

  selectOptionProvinceData(region_id) {
    let options = [{ value: '', label: '-- เลือก --', pid: '' }];

    if (region_id != undefined) {
      let dataFilter = provinceData.th.changwats.filter(e => e.regions_code === region_id);
      dataFilter.map((item, index) => {
        const province = { value: item.name, label: item.name, pid: item.pid };
        options.push(province);
        return province;
      });
    } else {
      provinceData.th.changwats.map((item, index) => {
        const province = { value: item.name, label: item.name, pid: item.pid };
        options.push(province);
        return province;
      });
    }

    return options;
  }

  selectOptionDistrictData(province_id) {
    let options = [{ value: '', label: '-- เลือก --', pid: '' }];

    let dataFilter = districtData.th.amphoes.filter(e => e.changwat_pid === province_id);
    dataFilter.map((item, index) => {
      const district = { value: item.name, label: item.name, pid: item.pid };
      options.push(district);
      return district;
    });

    return options;
  }

  selectOptionSubDistrictData(district_id) {
    let options = [{ value: '', label: '-- เลือก --' }];

    let dataFilter = subDistrictData.th.tambons.filter(e => e.amphoe_pid === district_id);
    dataFilter.map((item, index) => {
      const subdistrict = { value: item.name, label: item.name, pid: item.pid };
      options.push(subdistrict);
      return subdistrict;
    });

    return options;
  }

  selectOptionPostcodeData(subdistrict_id) {
    let options = [{ value: '', label: '-- เลือก --' }];

    let dataFilter = postcodesData.th.postcodes.filter(e => e.district_code == subdistrict_id);
    dataFilter.map((item, index) => {
      const postcode = { value: item.zipcode, label: item.zipcode };
      options.push(postcode);
      return postcode;
    });

    return options;
  }

  changeRegions(event) {
    this.setState(
      prevState => ({
        proviceChoose: {
          // object that we want to update
          ...prevState.proviceChoose, // keep all other key-value pairs
          region: { value: event.value, label: event.label, pid: event.pid },
          provine: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
        },
      }),
      () => this.selectOptionProvinceData(event.pid)
    );
  }

  changeProvince(event, route) {
    if (route != undefined) {
      if (this.checkProviceSelected(event.value, 'province')) {
        this.setState(prevState => ({
          proviceChoose: {
            // object that we want to update
            ...prevState.proviceChoose,
            provine: { value: event.value, label: event.label, pid: event.pid },
            district: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
            provinceInvalid: true,
          },
        }));
      } else {
        this.setState(
          prevState => ({
            proviceChoose: {
              // object that we want to update
              ...prevState.proviceChoose, // keep all other key-value pairs
              provine: { value: event.value, label: event.label, pid: event.pid },
              district: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
              provinceInvalid: false,
            },
          }),
          () => this.selectOptionDistrictData(event.pid)
        );
      }
    } else {
      this.setState(
        { province: { value: event.value, label: event.label, pid: event.pid } },
        () => {
          this.setState({
            district: { value: '', label: '-- เลือก --' },
            provinceInvalid: false,
          });
          this.selectOptionDistrictData(event.pid);
        }
      );
    }
  }

  changeDistrict(event, route) {
    if (route != undefined) {
      if (this.checkProviceSelected(event.value, 'district')) {
        this.setState(prevState => ({
          proviceChoose: {
            // object that we want to update
            ...prevState.proviceChoose,
            district: { value: event.value, label: event.label, pid: event.pid },
            subdistrict: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
            districtInvalid: true,
          },
        }));
      } else {
        this.setState(
          prevState => ({
            proviceChoose: {
              // object that we want to update
              ...prevState.proviceChoose, // keep all other key-value pairs
              district: { value: event.value, label: event.label, pid: event.pid },
              subdistrict: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
              districtInvalid: false,
            },
          }),
          () => this.selectOptionSubDistrictData(event.pid)
        );
      }
    } else {
      this.setState(
        { district: { value: event.value, label: event.label, pid: event.pid } },
        () => {
          this.setState({
            subdistrict: { value: '', label: '-- เลือก --' },
            districtInvalid: false,
          });
          this.selectOptionSubDistrictData(event.pid);
        }
      );
    }
  }

  changeSubDistrict(event, route) {
    if (route != undefined) {
      if (this.checkProviceSelected(event.value, 'subdistrict')) {
        this.setState(prevState => ({
          proviceChoose: {
            // object that we want to update
            ...prevState.proviceChoose,
            subdistrict: { value: event.value, label: event.label, pid: event.pid },
            postcode: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
            subdistrictInvalid: true,
          },
        }));
      } else {
        this.setState(
          prevState => ({
            proviceChoose: {
              // object that we want to update
              ...prevState.proviceChoose, // keep all other key-value pairs
              subdistrict: { value: event.value, label: event.label, pid: event.pid },
              postcode: { value: '', label: '-- เลือก --', pid: '' }, // update the value of specific key
              subdistrictInvalid: false,
            },
          }),
          () => this.selectOptionPostcodeData(event.pid)
        );
      }
    } else {
      this.setState({
        subdistrict: { value: event.value, label: event.label, pid: event.pid },
        subDistrictInvalid: false,
      });
    }
  }

  changePostcode(event, route) {
    if (route != undefined) {
      if (this.checkProviceSelected(event.value, 'postcode')) {
        this.setState(prevState => ({
          proviceChoose: {
            // object that we want to update
            ...prevState.proviceChoose,
            postcode: { value: event.value, label: event.label },
            postcodeInvalid: true,
          },
        }));
      } else {
        this.setState(prevState => ({
          proviceChoose: {
            // object that we want to update
            ...prevState.proviceChoose,
            postcode: { value: event.value, label: event.label },
            postcodeInvalid: false,
          },
        }));
      }
    } else {
      this.setState({
        postcode: { value: event.value, label: event.label },
        postcodeInvalid: false,
      });
    }
  }

  handleSelectTruckType(event) {
    let truckType = this.state.truckType;
    let data = [];
    console.log(truckType.length);
    if (truckType.length > 0) {
      data = truckType.map(item => {
        if (item.value === event.target.value) {
          item.checked = event.target.checked;
        }

        return item;
      });
    }

    this.setState({ truckType: data });
  }

  checkPhoneNumber = phoneNumber => {
    const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
    if (validatePhoneNumber.test(phoneNumber)) {
      return true;
    }

    return false;
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      cb(event.target.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  }

  handleUploadDocument(uploadFile, documentName, event) {
    const file = event.target.files[0];

    if (file) {
      this.getBase64(file, result => {
        const timestamp = new Date().getTime();
        uploadFile({
          variables: {
            file: result,
            path: `upload/account_transporter/${documentName}_${timestamp}`,
          },
        });
      });
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/account_transporter/${documentName}_${timestamp}`,
        },
      });
    }
  }

  //credit input format
  formatNumber(number, revers) {
    //ป้องกันข้อมูลว่างแล้วค่าเป็น 0
    if (number === '' || number === null) {
      return number;
    }

    if (revers === true) {
      // format number 1,234,567 to 1000000
      return Number(number.replace(/\D/g, ''));
    }

    // format number 1000000 to 1,234,567
    return Number(number.replace(/\D/g, ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  handleClickUploadProfileImage() {
    this._inputProfileImage.current.click();
  }

  checkFileTypeImage(file) {
    if (file) {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        return true;
      } else {
        return false;
      }
    }
  }

  checkFileSizeImage(file) {
    if (file) {
      const size = parseFloat(file.size / 1024).toFixed(2);
      if (size < 10240) {
        //ขนาดไฟล์ 10240KB or 10Mb
        return true;
      } else {
        return false;
      }
    }
  }

  handleSingleUploadProfileImage(uploadFile, documentName, event) {
    const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
    if (checkFileTypeImage === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage:
          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png และ jpg เท่านั้น',
      });
      return;
    }

    const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
    if (checkFileSize === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: this.props.t(
          'common:instancePrice.modal.uploadFileCheckSizeFile.content'
        ),
      });
      return;
    }

    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/profile/${documentName}_${timestamp}`,
        },
      });
    }
  }

  handleDatePicker(event) {
    //console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD')}`;
    const show = `${event.format('DD/MM/YYYY')}`;
    this.setState({
      driver_license_exp: date,
      driver_license_exp_show: show,
      driver_license_expInvalid: false,
    });
  }

  handleSave(driverUserUpdate, mode) {
    let finalAgentSelect = this.state.agentSelected.filter(data => !data._id);
    let finalprovinceSelected = this.state.provinceSelected.filter(data => !data._id);

    this.setState({ driver_nameInvalid: this.state.driver_name ? false : true });
    this.setState({ driver_phone_numberInvalid: this.state.driver_phone_number ? false : true });
    this.setState({
      user_setting_truck_license_plateInvalid: this.state.user_setting_truck_license_plate
        ? false
        : true,
    });
    this.setState({ driver_typeInvalid: this.state.driver_type ? false : true });
    this.setState({
      setting_truck_type_idInvalid: this.state.setting_truck_type_id ? false : true,
    });
    this.setState({ agentSelectedInvalid: this.state.agentSelected ? false : true });

    if (
      //ข้อมูลพนักงานขับรถ
      this.state.driver_nameInvalid ||
      this.state.driver_phone_numberInvalid ||
      this.state.reserve_phone_numberInvalid ||
      this.state.user_setting_truck_license_plateInvalid ||
      this.state.id_card_numberInvalid ||
      this.state.driver_typeInvalid ||
      this.state.driver_delivery_vatInvalid ||
      this.state.driver_delivery_whtInvalid ||
      this.state.driver_service_vatInvalid ||
      this.state.driver_service_whtInvalid ||
      this.state.driver_license_expInvalid ||
      this.state.setting_truck_type_idInvalid ||
      this.state.driver_car_brandInvalid ||
      //เอกสารเพิ่มเติม
      this.state.document1Invalid ||
      this.state.display_pictureInvalid
    ) {
      this.addNotification('error', 'กรุณากรอกข้อมูลที่สำคัญให้ครบก่อน');
    } else {
      if (mode == 'Suspend') {
        driverUserUpdate({
          variables: {
            _id: this.state._id,
            driver_block: 2,
            driver_block_remark: this.state.reject_reason,
          },
        });
      } else if (mode == 'Active') {
        driverUserUpdate({
          variables: {
            _id: this.state._id,
            driver_block: 1,
            driver_block_remark: '',
          },
        });
      } else {
        driverUserUpdate({
          variables: {
            _id: this.state._id,
            driver_add_agent_detail: finalAgentSelect,
            driver_deleted_agent_detail: this.state.agentToRemove,
            driver_route_service_add: finalprovinceSelected,
            driver_route_service_delete: this.state.provicetToRemove,
            account_info_id: this.state.account_info_id,
            driver_name: this.state.driver_name,
            driver_phone_code: this.state.driver_phone_code,
            driver_phone_number: this.state.driver_phone_number.substring(1),
            driver_enable: this.state.driver_enable,
            user_setting_truck_license_plate: this.state.user_setting_truck_license_plate,
            user_setting_truck_license_plate_back: this.checkLicensePlateBack()
              ? this.state.user_setting_truck_license_plate_back
              : this.state.user_setting_truck_license_plate_back
              ? '-'
              : '',
            driver_status: this.state.driver_status,
            line_id: this.state.line_id,
            reserve_phone_code: this.state.reserve_phone_number
              ? this.state.reserve_phone_code
              : '',
            reserve_phone_number: this.state.reserve_phone_number
              ? this.state.reserve_phone_number.substring(1)
              : '',
            criminal_history: this.state.criminal_history,
            id_card_number: this.state.id_card_number,
            driver_display_pic: this.state.display_picture,
            id_card_pic: {
              path: this.state.document1,
            },
            driver_card_pic: {
              path: this.state.document2,
            },
            car_pic: {
              path: this.state.document3,
            },
            license_plate_pic: {
              path: this.state.document4,
            },
            act_pic: {
              path: this.state.document5,
            },
            car_insurance_pic: {
              path: this.state.document6,
            },
            book_bank_pic: {
              path: this.state.document7,
            },
            setting_truck_service: [this.state.setting_truck_type_id],
            driver_type: this.state.driver_type,
            driver_delivery_vat: this.state.driver_delivery_vat,
            driver_delivery_wht: this.state.driver_delivery_wht,
            driver_service_vat: this.state.driver_service_vat,
            driver_service_wht: this.state.driver_service_wht,
            driver_license_number: this.state.driver_license_number,
            driver_license_exp: this.state.driver_license_exp,
            driver_car_brand: this.state.driver_car_brand,
            agent: this.state.agentIdParamUrl || undefined,
            bank_account: [
              {
                _id: this.state.bank_account_id,
                bank_name: this.state.bank_name,
                bank_branch: this.state.bank_branch,
                account_name: this.state.account_name,
                account_number: this.state.account_number,
              },
            ],
            branch: this.state.branch,
            address: this.state.address,
            sub_district: this.state.subdistrict.value,
            district: this.state.district.value,
            province: this.state.province.value,
            postcode: this.state.postcode.value.toString(),
          },
        });
      }
    }
  }

  handelchangeSettingTruckList(event) {
    this.setState({
      setting_truck_id: event.target[event.target.selectedIndex].dataset.truckid,
      setting_truck_type_id: event.target.value,
      setting_truck_type_idInvalid: false,
    });
  }

  checkPhoneNumberFormat(phone, mode) {
    const validatePhoneNumber = /^[0][0-9].{8}$/;
    let error = false;
    if (!validatePhoneNumber.test(phone) || (mode == 'driver_phone_number' && phone == '')) {
      error = true;
    }

    if (error) {
      if (mode == 'driver_phone_number') {
        this.setState({
          driver_phone_number: phone,
          driver_phone_numberInvalid: true,
        });
      } else if (mode == 'reserve_phone_number') {
        this.setState({
          reserve_phone_number: phone,
          reserve_phone_numberInvalid: true,
        });
      }
    } else {
      if (mode == 'driver_phone_number') {
        this.setState({
          driver_phone_number: phone,
          driver_phone_numberInvalid: false,
        });
      } else if ((mode = 'reserve_phone_number')) {
        this.setState({
          reserve_phone_number: phone,
          reserve_phone_numberInvalid: false,
        });
      }
    }
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label style={{ color: '#19AA19' }}>เปิดการใช้งาน</Label>;
      case 2:
        return <Label style={{ color: '#ED1C24' }}>ระงับการใช้งาน</Label>;
      default:
        return '';
    }
  }

  checkLicensePlateBack = () => {
    const { setting_truck_type_id } = this.state;
    if (
      setting_truck_type_id === '5cbe8678723da822892a6412' ||
      setting_truck_type_id === '5cbe8678723da822892a6413' ||
      setting_truck_type_id === '5cbe8678723da822892a6414'
    ) {
      return true;
    }

    return false;
  };

  deleteAgentSelected = agent => {
    this.setState({
      agentSelected: this.state.agentSelected.filter(
        data => data.account_info_id !== agent.account_info_id
      ),
    });
    if (agent._id) {
      this.setState(prevState => ({
        agentToRemove: [...prevState.agentToRemove, { account_info_id: agent.account_info_id }],
      }));

      const getProvice = this.getprovinceSelected(agent.account_info_id).filter(
        e => e._id != undefined
      );

      if (getProvice.length > 0) {
        getProvice.map(agentProvice =>
          this.setState(prevState => ({
            provicetToRemove: [...prevState.provicetToRemove, agentProvice._id],
          }))
        );
      }
    }
  };

  tripRateAgentSelected = (agent, tripRate) => {
    if (agent._id) {
      this.setState(prevState => ({
        agentToRemove: [...prevState.agentToRemove, { account_info_id: agent.account_info_id }],
        agentSelected: prevState.agentSelected.map(obj => {
          if (obj.account_info_id === agent.account_info_id) {
            let newData = { ...obj };
            delete newData._id;
            delete newData.driver_route_service;
            newData.trip_rate = tripRate;
            newData.month_rate = !tripRate;
            return newData;
          } else {
            return obj;
          }
        }),
      }));
    } else {
      this.setState(prevState => ({
        agentSelected: prevState.agentSelected.map(obj =>
          obj.account_info_id === agent.account_info_id
            ? Object.assign(obj, {
                trip_rate: tripRate,
                month_rate: !tripRate,
              })
            : obj
        ),
      }));
    }
  };

  deleteProviceSelected = (agentProvice, key) => {
    console.log('deleteProviceSelected', agentProvice);
    if (agentProvice._id) {
      this.setState(prevState => ({
        provicetToRemove: [...prevState.provicetToRemove, agentProvice._id],
        provinceSelected: this.state.provinceSelected.filter(data => data._id !== agentProvice._id),
      }));
    } else {
      let agentProviceSelected = [
        ...this.state.provinceSelected.filter(
          (data, index) => data.account_info == agentProvice.account_info && index != key
        ),
      ];
      let exAgentProviceSelected = [
        ...this.state.provinceSelected.filter(
          data => data.account_info != agentProvice.account_info
        ),
      ];

      const provinceSelectedData = agentProviceSelected.concat(exAgentProviceSelected);

      this.setState({
        provinceSelected: provinceSelectedData,
      });
    }
  };

  checkProviceSelected = (data, mode) => {
    let agentProviceSelected = [
      ...this.state.provinceSelected.filter(
        (data, index) => data.account_info == this.state.agentSelectedProvice.value
      ),
    ];

    if (
      mode === 'province' &&
      agentProviceSelected.filter(e => e.provine === data && e.district === '').length > 0
    ) {
      return true;
    } else if (
      mode === 'district' &&
      agentProviceSelected.filter(e => e.district === data && e.subdistrict === '').length > 0
    ) {
      return true;
    } else if (
      mode === 'subdistrict' &&
      agentProviceSelected.filter(e => e.subdistrict === data && e.postcode === '').length > 0
    ) {
      return true;
    }

    return false;
  };

  render() {
    let agentList = [];
    if (
      this.props.accountInfoListQuery.loading === false &&
      this.props.accountInfoListQuery.accountInfoList !== undefined
    ) {
      agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
    }

    return (
      <Container>
        <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
        <div className="content">
          <HeaderContainer>
            <div className="sub-header-left">
              <a href="/driver" className="btn-back">
                <i className="far fa-arrow-left"></i> กลับ
              </a>
              <div className="line"></div>
              <div className="menu-wrapper">
                <ul>
                  <li className="active">
                    <a href={`#`}>
                      {' '}
                      <i className="fal fa-address-card"></i> ข้อมูลพนักงานขับรถ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </HeaderContainer>
          <SubHeaderContainer>
            <div className="sub-header-left">
              <strong>
                #{this.state.driver_number} : {this.state.driver_name}
              </strong>
            </div>

            <Mutation
              mutation={driverUserUpdateMutation}
              update={(cache, { data }) => {
                this.addNotification('success', 'บันทึกสำเร็จแล้ว');
                this.setState({
                  showEdit: false,
                });
                ///window.location.reload();
              }}
              onError={error => {
                console.log(error);
                this.addNotification('error', error.message.replace('GraphQL error: ', ''));
              }}
              refetchQueries={['searchDriverUserList']}
            >
              {(driverUserUpdate, { data, error, loading }) => {
                return (
                  <div className="sub-header-right">
                    {this.state.driver_block == 1 ? (
                      <button
                        className="btn btn-grey"
                        onClick={() => this.setState({ showEdit: true, reject_reason: '' })}
                      >
                        ระงับการใช้งาน
                      </button>
                    ) : (
                      <button
                        className="btn btn-grey"
                        onClick={() => this.handleSave(driverUserUpdate, 'Active')}
                      >
                        {' '}
                        เปิดการใช้งาน
                      </button>
                    )}
                    <button
                      className="btn btn-red"
                      onClick={() => this.handleSave(driverUserUpdate)}
                    >
                      บันทึก
                    </button>
                  </div>
                );
              }}
            </Mutation>
          </SubHeaderContainer>
          <Grid fluid>
            <Row>
              <Col md={9}>
                <div style={{ marginBottom: 30 }}>
                  <Card>
                    <div>
                      <TextHeader>ข้อมูลพนักงานขับรถ</TextHeader>
                      <div style={{ marginTop: 20 }}>
                        <div>
                          <Row>
                            <Col md={3} sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    driver_type: event.target.value,
                                    driver_typeInvalid: false,
                                  })
                                }
                                value={this.state.driver_type}
                                style={{ marginTop: 10 }}
                                inputLabel="ประเภท"
                                placeholder="ประเภท"
                                required
                                messageError={this.state.driver_typeInvalid && 'กรุณากรอกข้อมูล'}
                                disabled
                              />
                            </Col>
                            <Col md={3} sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    driver_name: event.target.value,
                                    driver_nameInvalid: false,
                                  })
                                }
                                value={this.state.driver_name}
                                style={{ marginTop: 10 }}
                                inputLabel="ชื่อ-สกุล"
                                placeholder="ชื่อ-สกุล"
                                required
                                messageError={this.state.driver_nameInvalid && 'กรุณากรอกข้อมูล'}
                              />
                            </Col>
                            <Col md={3} sm={12}>
                              <Label
                                style={{
                                  marginTop: 10,
                                  color: `${
                                    this.state.driver_phone_numberInvalid ? '#FF0000' : ''
                                  }`,
                                }}
                                required
                              >
                                เบอร์โทรศัพท์
                              </Label>
                              <InputGroup>
                                <Select
                                  style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderRightWidth: 0,
                                    border: `${
                                      this.state.driver_phone_numberInvalid
                                        ? '1px solid #FF0000'
                                        : ''
                                    }`,
                                  }}
                                  value={this.state.driver_phone_code}
                                  onChange={event =>
                                    this.setState({ driver_phone_code: event.target.value })
                                  }
                                >
                                  <option value="66">+66</option>
                                </Select>
                                <Input
                                  onChange={event =>
                                    this.checkPhoneNumberFormat(
                                      event.target.value,
                                      'driver_phone_number'
                                    )
                                  }
                                  value={this.state.driver_phone_number}
                                  placeholder="0123456789"
                                  style={{ width: '100%' }}
                                  styleInput={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderLeftWidth: 0,
                                  }}
                                  messageError={
                                    this.state.driver_phone_numberInvalid
                                      ? 'กรอกให้ถูกต้อง ตย. 0321456789'
                                      : ''
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3} sm={12}>
                              <Label style={{ marginTop: 10 }}>เบอร์โทรศัพท์สำรอง</Label>
                              <InputGroup>
                                <Select
                                  style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderRightWidth: 0,
                                  }}
                                  value={this.state.reserve_phone_code}
                                  onChange={event =>
                                    this.setState({ reserve_phone_code: event.target.value })
                                  }
                                >
                                  <option value="66">+66</option>
                                </Select>
                                <Input
                                  onChange={event =>
                                    this.checkPhoneNumberFormat(
                                      event.target.value,
                                      'reserve_phone_number'
                                    )
                                  }
                                  value={this.state.reserve_phone_number}
                                  placeholder="0123456789"
                                  style={{ width: '100%' }}
                                  styleInput={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                  messageError={
                                    this.state.reserve_phone_numberInvalid
                                      ? 'กรอกให้ถูกต้อง ตย. 0321456789'
                                      : ''
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col md={3} sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    line_id: event.target.value,
                                    line_idInvalid: false,
                                  })
                                }
                                value={this.state.line_id}
                                style={{ marginTop: 10 }}
                                inputLabel="Line ID"
                                placeholder="Line ID"
                                messageError={this.state.line_idInvalid && 'กรุณากรอกข้อมูล'}
                              />
                            </Col>
                            <Col md={3} sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    driver_license_number: event.target.value,
                                    driver_license_numberInvalid: false,
                                  })
                                }
                                value={this.state.driver_license_number}
                                style={{ marginTop: 10 }}
                                inputLabel="ใบอนุญาตขับรถ ฉบับที่"
                                placeholder="ใบอนุญาตขับรถ ฉบับที่"
                                messageError={
                                  this.state.driver_license_numberInvalid && 'กรุณากรอกข้อมูล'
                                }
                              />
                            </Col>
                            <Col md={3} sm={12}>
                              <InputDatetime
                                onChange={event => this.handleDatePicker(event)}
                                value={this.state.driver_license_exp_show}
                                style={{ marginTop: 10 }}
                                inputLabel="วัน/เดือน/ปี หมดอายุ (ใบขับขี่)"
                                placeholder="วัน/เดือน/ปี หมดอายุ (ใบขับขี่)"
                                messageError={
                                  this.state.driver_license_expInvalid && 'กรุณากรอกข้อมูล'
                                }
                              />
                            </Col>

                            <Col md={12} sm={12}>
                              <Input
                                type="textarea"
                                onChange={event =>
                                  this.setState({
                                    criminal_history: event.target.value,
                                    criminal_historyInvalid: false,
                                  })
                                }
                                value={this.state.criminal_history}
                                style={{ marginTop: 10 }}
                                inputLabel="ประวัติอาชญากรรม"
                                placeholder="ประวัติอาชญากรรม"
                                messageError={
                                  this.state.criminal_historyInvalid && 'กรุณากรอกข้อมูล'
                                }
                              />
                            </Col>
                            <Col sm={12}>
                              <Label
                                required
                                style={{
                                  paddingTop: '10px',
                                  color: this.state.setting_truck_type_idInvalid
                                    ? '#D90101'
                                    : '#000000',
                                }}
                              >
                                ข้อมูลนายหน้าที่สังกัด
                              </Label>
                              <Table style={{ marginBottom: '0px' }}>
                                <thead>
                                  <tr>
                                    <th style={{ width: '50px' }}>เครื่องมือ</th>
                                    <th style={{ minWidth: '120px' }}>นายหน้า</th>
                                    <th>รูปแบบการจ้างงาน</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.agentSelected.length > 0 ? (
                                    this.state.agentSelected.map((agent, agentKey) => (
                                      <tr key={agentKey}>
                                        <td className="text-center">
                                          <span
                                            onClick={() => this.deleteAgentSelected(agent)}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            <i className="far fa-trash-alt"></i>
                                          </span>
                                        </td>
                                        <td>{agent.agent_name ? agent.agent_name : 'null'}</td>
                                        <td>
                                          <Row>
                                            <Col md={6} style={{ maxWidth: '115px' }}>
                                              <Row style={{ marginBottom: '10px' }}>
                                                <CustomRadio
                                                  style={{ marginTop: '0px' }}
                                                  value={true}
                                                  checked={
                                                    agent.trip_rate != undefined
                                                      ? agent.trip_rate
                                                      : true
                                                  }
                                                  onChange={event =>
                                                    this.tripRateAgentSelected(agent, true)
                                                  }
                                                  name={`radio${agent.account_info_id}`}
                                                  inline
                                                >
                                                  <span className="checkmark"></span>รายเที่ยว
                                                </CustomRadio>
                                              </Row>
                                            </Col>
                                            <Col md={6}>
                                              <Row style={{ marginBottom: '10px' }}>
                                                <CustomRadio
                                                  style={{ marginTop: '0px' }}
                                                  value={false}
                                                  checked={
                                                    agent.trip_rate != undefined
                                                      ? !agent.trip_rate
                                                      : false
                                                  }
                                                  onChange={event =>
                                                    this.tripRateAgentSelected(agent, false)
                                                  }
                                                  name={`radio${agent.account_info_id}`}
                                                  inline
                                                >
                                                  <span className="checkmark"></span>รายเที่ยว /
                                                  รายเดือน
                                                </CustomRadio>
                                              </Row>

                                              <Row>
                                                {!agent.trip_rate && (
                                                  <>
                                                    {this.getprovinceSelected(agent.account_info_id)
                                                      .length > 0
                                                      ? this.getprovinceSelected(
                                                          agent.account_info_id
                                                        ).map((agentProvice, agentProviceKey) => (
                                                          <div
                                                            key={agentProviceKey}
                                                            style={{
                                                              border: '1px solid #efefef',
                                                              padding: 10,
                                                              borderRadius: 10,
                                                              margin: 5,
                                                            }}
                                                          >
                                                            {agentProvice.provine}{' '}
                                                            {agentProvice.district &&
                                                              `/${agentProvice.district} `}{' '}
                                                            {agentProvice.subdistrict &&
                                                              `/${agentProvice.subdistrict} `}{' '}
                                                            {agentProvice.postcode &&
                                                              `/${agentProvice.postcode} `}
                                                            <span
                                                              onClick={() =>
                                                                this.deleteProviceSelected(
                                                                  agentProvice,
                                                                  agentProviceKey
                                                                )
                                                              }
                                                              style={{
                                                                float: 'right',
                                                                cursor: 'pointer',
                                                              }}
                                                            >
                                                              X
                                                            </span>
                                                          </div>
                                                        ))
                                                      : null}
                                                    <button
                                                      className="btn btn-red"
                                                      onClick={() =>
                                                        this.clickIsProviceAdd(
                                                          agent.agent_name,
                                                          agent.account_info_id
                                                        )
                                                      }
                                                    >
                                                      เพิ่มจังหวัดที่วิ่ง
                                                    </button>
                                                  </>
                                                )}
                                              </Row>
                                            </Col>
                                          </Row>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={3}>ยังไม่มีข้อมูล กรุณาเพิ่มนายหน้า</td>
                                    </tr>
                                  )}
                                  <td></td>
                                </tbody>
                              </Table>
                              <LineHorizontal style={{ marginTop: 0, marginBottom: 10 }} />
                              <button
                                className="btn btn-red"
                                onClick={() => this.setState({ isTplAdd: true })}
                              >
                                เพิ่มนายหน้าที่สังกัด
                              </button>
                            </Col>
                          </Row>
                        </div>

                        <LineHorizontal style={{ marginTop: 60, marginBottom: 40 }} />

                        <TextHeader>ข้อมูลที่อยู่สำหรับเอกสารทางบัญชี </TextHeader>
                        <Row>
                          <Col md={6} sm={12}>
                            <Input
                              onChange={event =>
                                this.setState({
                                  address: event.target.value,
                                  addressInvalid: false,
                                })
                              }
                              value={this.state.address}
                              style={{ marginTop: 10 }}
                              inputLabel="ที่อยู่"
                              placeholder="123/3"
                              required
                              messageError={this.state.addressInvalid && 'กรุณากรอกข้อมูล'}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <Input
                              onChange={event =>
                                this.setState({
                                  id_card_number: event.target.value,
                                  id_card_numberInvalid: false,
                                })
                              }
                              value={this.state.id_card_number}
                              style={{ marginTop: 10 }}
                              inputLabel="เลขบัตรประจำตัวประชาชน"
                              placeholder="เลขบัตรประจำตัวประชาชน"
                              messageError={this.state.id_card_numberInvalid && 'กรุณากรอกข้อมูล'}
                            />
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col md={3} sm={12}>
																<Input onChange={(event) => this.setState({ branch: event.target.value, branchInvalid: false })} value={this.state.branch} style={{marginTop: 10}} inputLabel="สาขา" placeholder="สำนักงานใหญ่" messageError={this.state.branchnvalid && 'กรุณากรอกข้อมูล'}/>
															</Col> */}
                          <Col md={3} sm={12}>
                            <SelectBox
                              onChange={event => this.changeProvince(event)}
                              value={this.state.province}
                              style={{ marginTop: 10 }}
                              options={this.selectOptionProvinceData()}
                              inputLabel="จังหวัด"
                              messageError={this.state.provinceInvalid && 'กรุณากรอกข้อมูล'}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <SelectBox
                              onChange={event => this.changeDistrict(event)}
                              value={this.state.district}
                              style={{ marginTop: 10 }}
                              options={this.selectOptionDistrictData(this.state.province.pid)}
                              inputLabel="เขต/อำเภอ"
                              disabled={!this.state.province.value}
                              messageError={this.state.districtInvalid && 'กรุณากรอกข้อมูล'}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <SelectBox
                              onChange={event => this.changeSubDistrict(event)}
                              value={this.state.subdistrict}
                              style={{ marginTop: 10 }}
                              options={this.selectOptionSubDistrictData(this.state.district.pid)}
                              inputLabel="แขวง/ตำบล"
                              disabled={!this.state.district.value}
                              messageError={this.state.subdistrictInvalid && 'กรุณากรอกข้อมูล'}
                            />
                          </Col>
                          <Col md={3} sm={12}>
                            <SelectBox
                              onChange={event => this.changePostcode(event)}
                              value={this.state.postcode}
                              style={{ marginTop: 10 }}
                              options={this.selectOptionPostcodeData(this.state.subdistrict.pid)}
                              inputLabel="รหัสไปรษณีย์"
                              disabled={!this.state.subdistrict.value}
                              messageError={this.state.postcodeInvalid && 'กรุณากรอกข้อมูล'}
                            />
                          </Col>
                        </Row>

                        <LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />

                        <TextHeader>ข้อมูลบัญชีธนาคาร </TextHeader>
                        <Row>
                          <Col md={3} sm={12}>
                            <Input
                              onChange={event => this.setState({ bank_name: event.target.value })}
                              value={this.state.bank_name}
                              style={{ marginTop: 10 }}
                              inputLabel="ธนาคาร"
                              placeholder="ธนาคาร"
                            />
                          </Col>

                          <Col md={3} sm={12}>
                            <Input
                              onChange={event => this.setState({ bank_branch: event.target.value })}
                              value={this.state.bank_branch}
                              style={{ marginTop: 10 }}
                              inputLabel="สาขา"
                              placeholder="สาขา"
                            />
                          </Col>

                          <Col md={3} sm={12}>
                            <Input
                              onChange={event =>
                                this.setState({ account_name: event.target.value })
                              }
                              value={this.state.account_name}
                              style={{ marginTop: 10 }}
                              inputLabel="ชื่อบัญชี"
                              placeholder="ชื่อบัญชี"
                            />
                          </Col>

                          <Col md={3} sm={12}>
                            <Input
                              onChange={event =>
                                this.setState({ account_number: event.target.value })
                              }
                              value={this.state.account_number}
                              style={{ marginTop: 10 }}
                              inputLabel="เลขที่บัญชี"
                              placeholder="เลขที่บัญชี"
                            />
                          </Col>
                        </Row>

                        <LineHorizontal style={{ marginTop: 40, marginBottom: 40 }} />

                        <TextHeader>ข้อมูลประเภทรถ</TextHeader>
                        <div>
                          <Row style={{ marginTop: 0 }}>
                            <Col md={3} sm={12}>
                              <Label
                                required
                                style={{
                                  paddingTop: '10px',
                                  color: this.state.setting_truck_type_idInvalid
                                    ? '#D90101'
                                    : '#000000',
                                }}
                              >
                                ประเภทรถ
                              </Label>
                              <Select
                                style={{
                                  width: '100%',
                                  borderTopRightRadius: 0,
                                  borderBottomRightRadius: 0,
                                  borderRightWidth: 0,
                                  border: `${
                                    this.state.setting_truck_type_idInvalid
                                      ? '1px solid #FF0000'
                                      : ''
                                  }`,
                                }}
                                value={this.state.setting_truck_type_id}
                                onChange={event =>
                                  this.handelchangeSettingTruckList(event, 'truck')
                                }
                              >
                                <option value=""></option>
                                {this.state.settingTruckList.map((truck, index) => (
                                  <optgroup key={index} label={`รถ ${truck.setting_truck_name.th}`}>
                                    {truck.setting_truck_type
                                      .filter(item => item.truck_type_enable)
                                      .map((item, key, arr) => (
                                        <option key={key} value={item._id} data-truckId={truck._id}>
                                          {`${truck.setting_truck_name.th} - ${item.truck_type_name.th}` ||
                                            undefined}
                                        </option>
                                      ))}
                                  </optgroup>
                                ))}
                              </Select>
                              <Row>
                                <Col md={12} style={{ textAlign: 'right' }}>
                                  <TextMessageError>
                                    {this.state.setting_truck_type_idInvalid && 'กรุณากรอกข้อมูล'}
                                  </TextMessageError>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={3} sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    user_setting_truck_license_plate: event.target.value,
                                    user_setting_truck_license_plateInvalid: false,
                                  })
                                }
                                value={this.state.user_setting_truck_license_plate}
                                style={{ marginTop: 10 }}
                                inputLabel="ทะเบียนรถ"
                                placeholder="ทะเบียนรถ"
                                required
                                messageError={
                                  this.state.user_setting_truck_license_plateInvalid &&
                                  'กรุณากรอกข้อมูล'
                                }
                              />
                            </Col>

                            {this.checkLicensePlateBack() && (
                              <Col md={3} sm={12}>
                                <Input
                                  onChange={event =>
                                    this.setState({
                                      user_setting_truck_license_plate_back: event.target.value,
                                    })
                                  }
                                  value={this.state.user_setting_truck_license_plate_back}
                                  style={{ marginTop: 10 }}
                                  inputLabel="ทะเบียนหาง"
                                  placeholder="ทะเบียนหาง"
                                />
                              </Col>
                            )}

                            <Col md={3} sm={12}>
                              <Input
                                onChange={event =>
                                  this.setState({
                                    driver_car_brand: event.target.value,
                                    driver_car_brandInvalid: false,
                                  })
                                }
                                value={this.state.driver_car_brand}
                                style={{ marginTop: 10 }}
                                inputLabel="ยี่ห้อ"
                                placeholder="ยี่ห้อ"
                                messageError={
                                  this.state.driver_car_brandInvalid && 'กรุณากรอกข้อมูล'
                                }
                              />
                            </Col>
                          </Row>

                          <LineHorizontal style={{ marginTop: 60, marginBottom: 40 }} />

                          <TextHeader>ข้อมูลเพิ่มเติม </TextHeader>
                          <div style={{ marginTop: 20 }}>
                            <Row>
                              <Col md={6}>
                                <Label>การคิดภาษีค่าขนส่ง</Label>
                                <Row style={{ marginTop: 10 }}>
                                  <Col md={6}>
                                    <Label inline style={{ fontWeight: 'normal' }} required>
                                      ภาษีมูลค่าเพิ่ม (VAT)
                                    </Label>{' '}
                                    {this.state.driver_delivery_vatInvalid ? (
                                      <TextMessageError>
                                        {this.state.driver_delivery_vatInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <InputGroup style={{ marginTop: 6 }}>
                                      <Input
                                        required
                                        type="number"
                                        value={this.state.driver_delivery_vat}
                                        onChange={event =>
                                          this.setState({
                                            driver_delivery_vat: event.target.value,
                                            driver_delivery_vatInvalid: '',
                                          })
                                        }
                                        style={{ width: '100%' }}
                                        styleInput={{
                                          borderBottomRightRadius: 0,
                                          borderTopRightRadius: 0,
                                          border: `${
                                            this.state.driver_delivery_vatInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                        placeholder="ระบุ"
                                      />
                                      <TextRight
                                        style={{
                                          borderBottomLeftRadius: 0,
                                          borderTopLeftRadius: 0,
                                          borderLeftWidth: 0,
                                          border: `${
                                            this.state.driver_delivery_vatInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                      >
                                        %
                                      </TextRight>
                                    </InputGroup>
                                  </Col>
                                  <Col md={6}>
                                    <Label inline style={{ fontWeight: 'normal' }} required>
                                      หัก ณ ที่ จ่าย (WHT)
                                    </Label>{' '}
                                    {this.state.driver_delivery_whtInvalid ? (
                                      <TextMessageError>
                                        {this.state.driver_delivery_whtInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <InputGroup style={{ marginTop: 6 }}>
                                      <Input
                                        required
                                        type="number"
                                        value={this.state.driver_delivery_wht}
                                        onChange={event =>
                                          this.setState({
                                            driver_delivery_wht: event.target.value,
                                            driver_delivery_whtInvalid: '',
                                          })
                                        }
                                        style={{ width: '100%' }}
                                        styleInput={{
                                          borderBottomRightRadius: 0,
                                          borderTopRightRadius: 0,
                                          border: `${
                                            this.state.driver_delivery_whtInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                        placeholder="ระบุ"
                                      />
                                      <TextRight
                                        style={{
                                          borderBottomLeftRadius: 0,
                                          borderTopLeftRadius: 0,
                                          borderLeftWidth: 0,
                                          border: `${
                                            this.state.driver_delivery_whtInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                      >
                                        %
                                      </TextRight>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={6}>
                                <Label>การคิดภาษีค่าบริการ</Label>
                                <Row style={{ marginTop: 10 }}>
                                  <Col md={6}>
                                    <Label inline style={{ fontWeight: 'normal' }} required>
                                      ภาษีมูลค่าเพิ่ม (VAT)
                                    </Label>{' '}
                                    {this.state.driver_service_vatInvalid ? (
                                      <TextMessageError>
                                        {this.state.driver_service_vatInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <InputGroup style={{ marginTop: 6 }}>
                                      <Input
                                        required
                                        type="number"
                                        value={this.state.driver_service_vat}
                                        onChange={event =>
                                          this.setState({
                                            driver_service_vat: event.target.value,
                                            driver_service_vatInvalid: '',
                                          })
                                        }
                                        style={{ width: '100%' }}
                                        styleInput={{
                                          borderBottomRightRadius: 0,
                                          borderTopRightRadius: 0,
                                          border: `${
                                            this.state.driver_service_vatInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                        placeholder="ระบุ"
                                      />
                                      <TextRight
                                        style={{
                                          borderBottomLeftRadius: 0,
                                          borderTopLeftRadius: 0,
                                          borderLeftWidth: 0,
                                          border: `${
                                            this.state.driver_service_vatInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                      >
                                        %
                                      </TextRight>
                                    </InputGroup>
                                  </Col>
                                  <Col md={6}>
                                    <Label inline style={{ fontWeight: 'normal' }} required>
                                      หัก ณ ที่ จ่าย (WHT)
                                    </Label>{' '}
                                    {this.state.driver_service_whtInvalid ? (
                                      <TextMessageError>
                                        {this.state.driver_service_whtInvalid}
                                      </TextMessageError>
                                    ) : null}
                                    <InputGroup style={{ marginTop: 6 }}>
                                      <Input
                                        required
                                        type="number"
                                        value={this.state.driver_service_wht}
                                        onChange={event =>
                                          this.setState({
                                            driver_service_wht: event.target.value,
                                            driver_service_whtInvalid: '',
                                          })
                                        }
                                        style={{ width: '100%' }}
                                        styleInput={{
                                          borderBottomRightRadius: 0,
                                          borderTopRightRadius: 0,
                                          border: `${
                                            this.state.driver_service_whtInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                        placeholder="ระบุ"
                                      />
                                      <TextRight
                                        style={{
                                          borderBottomLeftRadius: 0,
                                          borderTopLeftRadius: 0,
                                          borderLeftWidth: 0,
                                          border: `${
                                            this.state.driver_service_whtInvalid
                                              ? '1px solid #FF0000'
                                              : ''
                                          }`,
                                        }}
                                      >
                                        %
                                      </TextRight>
                                    </InputGroup>
                                  </Col>
                                </Row>
                              </Col>

                              <Col sm={12}>
                                <Label>เอกสารเพิ่มเติม</Label>
                                <Row style={{ marginTop: 10 }}>
                                  <Col sm={12}>
                                    <FileContainer isBorderValidated={this.state.document1Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document1 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">
                                          (1) รูปบัตรประชาชน หน้า-หลัง{' '}
                                          <span className="text-primary">*</span>
                                        </div>
                                      </div>
                                      {this.state.document1 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document1}
                                              target="_blank"
                                            >
                                              {this.state.document1.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document1: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document1: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'id_card_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument1}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument1.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>

                                    <FileContainer isBorderValidated={this.state.document2Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document2 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">(2) รูปใบขับขี่</div>
                                      </div>
                                      {this.state.document2 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document2}
                                              target="_blank"
                                            >
                                              {this.state.document2.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document2: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document2: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'driver_card_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument2}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument2.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>

                                    <FileContainer isBorderValidated={this.state.document3Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document3 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">(3) รูปรถ</div>
                                      </div>
                                      {this.state.document3 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document3}
                                              target="_blank"
                                            >
                                              {this.state.document3.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document3: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document3: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'car_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument3}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument3.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>

                                    <FileContainer isBorderValidated={this.state.document4Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document4 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">(4) รูปทะเบียนรถ</div>
                                      </div>
                                      {this.state.document4 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document4}
                                              target="_blank"
                                            >
                                              {this.state.document4.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document4: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document4: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'license_plate_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument4}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument4.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>

                                    <FileContainer isBorderValidated={this.state.document5Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document5 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">(5) รูป พรบ.</div>
                                      </div>
                                      {this.state.document5 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document5}
                                              target="_blank"
                                            >
                                              {this.state.document5.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document5: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document5: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'act_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument5}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument5.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>

                                    <FileContainer isBorderValidated={this.state.document6Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document6 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">
                                          (6) รูปกรมธรรม์ประกันรถ
                                        </div>
                                      </div>
                                      {this.state.document6 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document6}
                                              target="_blank"
                                            >
                                              {this.state.document6.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document6: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document6: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'car_insurance_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument6}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument6.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>

                                    <FileContainer isBorderValidated={this.state.document7Invalid}>
                                      <div className="file-left">
                                        <div className="file-left-icon">
                                          {this.state.document7 ? (
                                            <i className="fas fa-check-circle text-green"></i>
                                          ) : (
                                            <i className="fas fa-circle text-grey"></i>
                                          )}
                                        </div>
                                        <div className="file-left-text">
                                          (7) รูปหน้าสมุดบัญชีธนาคาร
                                        </div>
                                      </div>
                                      {this.state.document7 ? (
                                        <div className="file-right">
                                          <div className="file-right-filename text-blue">
                                            <a
                                              href={s3 + '/' + this.state.document7}
                                              target="_blank"
                                            >
                                              {this.state.document7.split('/')[2]}
                                            </a>
                                          </div>
                                          <div
                                            className="file-right-delete text-red"
                                            onClick={() => this.setState({ document7: '' })}
                                          >
                                            <i className="far fa-times"></i> ลบ
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="file-right">
                                          <Mutation
                                            mutation={singleUploadMutation}
                                            update={(cache, { data }) => {
                                              this.setState({ document7: data.singleUpload.path });
                                            }}
                                            onError={error => {
                                              console.log(error);
                                            }}
                                          >
                                            {uploadFile => {
                                              return (
                                                <input
                                                  onChange={event =>
                                                    this.handleSingleUploadDocument(
                                                      uploadFile,
                                                      'book_bank_pic',
                                                      event
                                                    )
                                                  }
                                                  type="file"
                                                  id="file"
                                                  ref={this._inputDocument7}
                                                  style={{ display: 'none' }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                          <Button
                                            onClick={() => this._inputDocument7.current.click()}
                                          >
                                            + อัพโหลด
                                          </Button>
                                        </div>
                                      )}
                                    </FileContainer>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </Col>
              <Col md={3}>
                <StatusContainer>
                  <div style={{ textAlign: 'center' }}>
                    <div className="header">
                      <div className="header-text nomargin">ข้อมูลบัญชีผู้ใช้</div>
                    </div>
                    <ImageProfile isBorderValidated={this.state.display_pictureInvalid}>
                      {this.state.display_picture ? (
                        <img src={`${s3}/${this.state.display_picture}`} alt="" />
                      ) : (
                        <i className="fas fa-user"></i>
                      )}
                    </ImageProfile>

                    <Mutation
                      mutation={singleUploadMutation}
                      update={async (cache, { data }) => {
                        this.setState({ display_picture: data.singleUpload.path });
                      }}
                      onError={error => {
                        console.log(error);
                      }}
                    >
                      {uploadFile => {
                        return (
                          <input
                            accept="image/jpeg, image/png"
                            onChange={event =>
                              this.handleSingleUploadProfileImage(
                                uploadFile,
                                'driver_display_pic_' + this.state.account_users_id,
                                event
                              )
                            }
                            type="file"
                            id="file"
                            ref={this._inputProfileImage}
                            style={{ display: 'none' }}
                          />
                        );
                      }}
                    </Mutation>

                    <Button onClick={() => this.handleClickUploadProfileImage()}>
                      อัพโหลดรูปภาพ
                    </Button>
                  </div>
                  <div className="detail" style={{ marginTop: 10 }}>
                    <div className="detail-list">
                      <div className="detail-list-left">Driver ID :</div>
                      <div className="detail-list-right">
                        <b>DV{this.state.driver_number}</b>
                      </div>
                    </div>
                    <div className="detail-list" style={{ marginTop: 5 }}>
                      <div className="detail-list-left">สถานะ :</div>
                      <div className="detail-list-right">
                        {this.renderStatus(this.state.driver_block)}
                      </div>
                    </div>
                    {this.state.driver_block_remark && this.state.driver_block == 2 && (
                      <Row style={{ marginTop: 10 }}>
                        <Col md={12}>เหตุผลที่ระงับการใช้งาน :</Col>
                        <Col md={12}>{this.state.driver_block_remark}</Col>
                      </Row>
                    )}
                  </div>
                </StatusContainer>
              </Col>
            </Row>
          </Grid>
        </div>
        <Mutation
          mutation={driverUserUpdateMutation}
          variables={{
            _id: this.state._id,
            driver_block: 2,
            driver_block_remark: this.state.reject_reason,
          }}
          update={(cache, { data }) => {
            this.addNotification('success', 'ระงับการใช้งาน พนักงานขับรถสำเร็จแล้ว');
            this.setState({
              showEdit: false,
            });
          }}
          onError={error => {
            console.log(error);
            this.addNotification('error', 'หมายเลขโทรศัพท์นี้ ได้สมัครเป็นคนขับรถแล้ว');
          }}
          refetchQueries={['searchDriverUserList']}
        >
          {(driverUserUpdate, { data, error, loading }) => (
            <Modal
              show={this.state.showEdit}
              backdrop="static"
              onHide={() => this.setState({ showEdit: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>ระงับการใช้งาน พนักงานขับรถ</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={12} style={{ marginBottom: '20px' }}>
                    <b>
                      คุณต้องการระงับการใช้งาน พนักงานขับรถ: {this.state.driver_name} ใช่หรือไม่?
                    </b>{' '}
                  </Col>
                  <Col md={12}> กรุณากรอกเหตุผลที่ระงับการใช้งาน</Col>
                </Row>
                <FormControl
                  type="text"
                  value={this.state.reject_reason}
                  placeholder="เหตุผลที่ระงับการใช้งาน"
                  onChange={e => this.setState({ reject_reason: e.target.value })}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  bsStyle="primary"
                  disabled={this.state.reject_reason ? false : true}
                  onClick={() => driverUserUpdate()}
                >
                  ยืนยัน
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Mutation>
        <Modal
          show={this.state.isTplAdd}
          backdrop="static"
          onHide={() =>
            this.setState({ isTplAdd: false, agentChoosed: {}, agentChooseInvalid: false })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>เพิ่่มนายหน้าที่สังกัด</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              style={{
                width: '100%',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRightWidth: 0,
                border: `${false ? '1px solid #FF0000' : ''}`,
              }}
              value={this.state.agentChoosed.account_info_id}
              onChange={event => {
                // console.log(agentList.filter(data => data._id == event.target.value)[0].account_info_business_name)
                const agentName = agentList.filter(data => data._id == event.target.value)[0]
                  .account_info_business_name;
                this.setState({
                  agentChoosed: {
                    account_info_id: event.target.value,
                    agent_name: agentName,
                    driver_name: this.state.driver_name,
                    user_setting_truck_license_plate: this.state.user_setting_truck_license_plate,
                    user_setting_truck_license_plate_back: this.state
                      .user_setting_truck_license_plate_back,
                  },
                  agentChooseInvalid:
                    this.state.agentSelected.filter(ck => ck.account_info_id == event.target.value)
                      .length > 0,
                });
              }}
            >
              <option value="">- เลือกนายหน้า -</option>
              {agentList.map((agent, index) => (
                <option key={agent._id} value={agent._id}>
                  {agent.account_info_business_name}
                </option>
              ))}
            </Select>
            {this.state.agentChooseInvalid && <div style={{ color: 'red' }}>มีนายหน้านี้แล้ว</div>}
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="primary"
              disabled={!this.state.agentChoosed.account_info_id || this.state.agentChooseInvalid}
              onClick={() =>
                this.setState(prevState => ({
                  agentSelected: [
                    ...prevState.agentSelected,
                    {
                      account_info_id: this.state.agentChoosed.account_info_id,
                      agent_name: this.state.agentChoosed.agent_name,
                      driver_name: this.state.agentChoosed.driver_name,
                      user_setting_truck_license_plate: this.state.agentChoosed
                        .user_setting_truck_license_plate,
                      user_setting_truck_license_plate_back: this.state.agentChoosed
                        .user_setting_truck_license_plate_back,
                      trip_rate: true,
                      month_rate: false,
                    },
                  ],
                  isTplAdd: false,
                  agentChoosed: {},
                  agentChooseInvalid: false,
                }))
              }
            >
              ยืนยัน
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.isProviceAdd && (
          <Modal
            show={this.state.isProviceAdd}
            backdrop="static"
            onHide={() =>
              this.setState({
                isProviceAdd: false,
                isProviceAdd: false,
                agentSelectedProvice: null,
                proviceChoose: {
                  region: { value: '', label: '-- เลือก --', pid: '' },
                  provine: { value: '', label: '-- เลือก --', pid: '' },
                  district: { value: '', label: '-- เลือก --', pid: '' },
                  subdistrict: { value: '', label: '-- เลือก --', pid: '' },
                  postcode: { value: '', label: '-- เลือก --' },
                  regionsInvalid: false,
                  provinceInvalid: false,
                  districtInvalid: false,
                  subdistrictInvalid: false,
                  postcodeInvalid: false,
                },
                proviceChooseInvalid: false,
              })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>เพิ่มจังหวัดที่วิ่ง </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col sm={12}>
                  <small>
                    **ไม่จำเป็นต้องเลือกทุกช่อง เช่น คนขับวิ่งงาน ทุกเขตของจังหวัดกรุงเทพมหานคร
                    เลือก เฉพาะจังหวัดกรุงเทพมหานคร แล้วบันทึกได้เลย
                  </small>
                </Col>
                <Col sm={12}>
                  <SelectBox
                    onChange={event => this.changeRegions(event)}
                    value={this.state.proviceChoose.region}
                    style={{ marginTop: 10 }}
                    options={this.selectOptionRegionsData()}
                    inputLabel="ภาค"
                    messageError={this.state.proviceChoose.regionsInvalid && 'กรุณากรอกข้อมูล'}
                  />
                </Col>
                <Col sm={12}>
                  {console.log(
                    'this.state.proviceChoose.provinceInvalid',
                    this.state.proviceChoose.provinceInvalid
                  )}
                  <SelectBox
                    onChange={event => this.changeProvince(event, 'route')}
                    value={this.state.proviceChoose.provine}
                    style={{ marginTop: 10 }}
                    options={this.selectOptionProvinceData(this.state.proviceChoose.region?.pid)}
                    inputLabel="จังหวัด"
                    messageError={
                      this.state.proviceChoose.provinceInvalid &&
                      'คุณได้เลือกทั้งจังหวัดนี้แล้ว หากต้องการเลือกอำเภอกรุณาลบรายการจังหวัดนี้ออกก่อน'
                    }
                    disabled={!this.state.proviceChoose.region?.value}
                  />
                </Col>
                <Col sm={12}>
                  <SelectBox
                    onChange={event => this.changeDistrict(event, 'route')}
                    value={this.state.proviceChoose.district}
                    style={{ marginTop: 10 }}
                    options={this.selectOptionDistrictData(this.state.proviceChoose.provine?.pid)}
                    inputLabel="เขต/อำเภอ"
                    messageError={
                      this.state.proviceChoose.districtInvalid &&
                      'คุณได้เลือกทั้งเขต/อำเภอนี้แล้ว หากต้องการเลือกตำบลกรุณาลบรายการเขต/อำเภอนี้ออกก่อน'
                    }
                    disabled={
                      this.state.proviceChoose.provinceInvalid ||
                      !this.state.proviceChoose.provine?.value
                    }
                  />
                </Col>
                <Col sm={12}>
                  <SelectBox
                    onChange={event => this.changeSubDistrict(event, 'route')}
                    value={this.state.proviceChoose.subdistrict}
                    style={{ marginTop: 10 }}
                    options={this.selectOptionSubDistrictData(
                      this.state.proviceChoose.district?.pid
                    )}
                    inputLabel="แขวง/ตำบล"
                    messageError={
                      this.state.proviceChoose.subdistrictInvalid &&
                      'คุณได้เลือกทั้งแขวง/ตำบลนี้แล้ว หากต้องการเลือกตำบลกรุณาลบรายการแขวง/ตำบลนี้ออกก่อน'
                    }
                    disabled={
                      this.state.proviceChoose.districtInvalid ||
                      !this.state.proviceChoose.district?.value
                    }
                  />
                </Col>
                <Col sm={12}>
                  <SelectBox
                    onChange={event => this.changePostcode(event, 'route')}
                    value={this.state.proviceChoose.postcode}
                    style={{ marginTop: 10 }}
                    options={this.selectOptionPostcodeData(
                      this.state.proviceChoose.subdistrict?.pid
                    )}
                    inputLabel="รหัสไปรษณีย์"
                    messageError={
                      this.state.proviceChoose.postcodeInvalid && 'คุณได้เลือกรหัสไปรษณีย์นี้แล้ว'
                    }
                    disabled={
                      this.state.proviceChoose.subdistrictInvalid ||
                      !this.state.proviceChoose.subdistrict?.value
                    }
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                bsStyle="primary"
                disabled={!this.state.proviceChoose.provine?.value}
                onClick={() =>
                  this.setState(prevState => ({
                    provinceSelected: [
                      ...prevState.provinceSelected,
                      {
                        account_info: this.state.agentSelectedProvice.value,
                        driver: urijs(window.location.href).query(true).id,
                        region: this.state.proviceChoose.region.value,
                        provine: this.state.proviceChoose.provine.value,
                        district: this.state.proviceChoose.district.value,
                        subdistrict: this.state.proviceChoose.subdistrict.value,
                        postcode: this.state.proviceChoose.postcode.value,
                      },
                    ],
                    isProviceAdd: false,
                    agentSelectedProvice: null,
                    proviceChoose: {
                      region: { value: '', label: '-- เลือก --', pid: '' },
                      provine: { value: '', label: '-- เลือก --', pid: '' },
                      district: { value: '', label: '-- เลือก --', pid: '' },
                      subdistrict: { value: '', label: '-- เลือก --', pid: '' },
                      postcode: { value: '', label: '-- เลือก --' },
                      regionsInvalid: false,
                      provinceInvalid: false,
                      districtInvalid: false,
                      subdistrictInvalid: false,
                      postcodeInvalid: false,
                    },
                    proviceChooseInvalid: false,
                  }))
                }
              >
                 ยืนยัน
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Container>
    );
  }
}

export default compose(
  graphql(driverUserListQuery, {
    name: 'driverUserListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        _id: urijs(window.location.href).query(true).id,
        agent: urijs(window.location.href).query(true).agentId,
      },
    }),
  }),
  graphql(settingTruckListQuery, {
    name: 'settingTruckListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(accountInfoListShowAllQuery, {
    name: 'accountInfoListQuery',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        account_info_type: 3,
      },
    }),
  })
)(BusinessDetail);
