import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import reportResultListQuery from './graphql/query/reportResultList';
import firebase from 'firebase';
import firebaseConfig from 'config/firebaseConfig';

import s3 from './../../config/s3';
import reportUrl from './../../config/reportUrl';
import adminListQuery from './graphql/query/adminList';
import { UploadFile } from './container';
import restBaseUrl from 'config/restBaseUrl';
import { client } from './../../index';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const ButtonA = styled.a`
  border: 1px solid #e3e3e3;
  color: #000;
  padding: 5px 8px;
  font-weight: normal;
  background-color: #ffffff;
  min-height: 32px;
  font-size: 18px;
  line-height: 1;
  border-radius: 4px;
  display: inline-block;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    color: #000;
  }
`;

class ChallengeDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_page: 0,
      page_number: 1,
      order_by: 'create_date',
      order_type: -1,
      status: 0,
      name: '',
      admin_username: '',
      create_date: '',
      show_create_date: '',
      report_id: null,
      report_status: null,
      report_file: null,
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
    this._inputImportFile = React.createRef();
  }

  componentDidMount() {
    this.getReportData();
  }

  uploadFileHandler = async (file, refetch) => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const adminId = admin[0]._id;
      const url = `${restBaseUrl}/import-file-get-price?path=${file.path}&admin_id=${adminId}`;
      await axios(url).then(res => {
        console.log('res', res);
        //   if (!res.data._id) {
        //     // alert('Error');
        //     return;
        //   }

        refetch();
      });
    } catch (error) {
      console.log(error);
      refetch();
    }
  };

  getReportData = async () => {
    try {
      firebase
        .database()
        .ref(`/report_result/`)
        .limitToLast(1)
        .on('value', async snapshot => {
          if (snapshot.numChildren() === 1) {
            snapshot.forEach(child => {
              const keys = child.key;
              child.forEach(item => {
                if (item.key === 'status') {
                  this.setState({
                    report_id: keys,
                    report_status: item.val() == 2 ? item.val() : null,
                  });
                }

                if (item.key === 'file_result') {
                  this.setState({
                    report_id: keys,
                    report_status: null,
                    report_file: item.val(),
                  });
                }
              });
            });
          }
        });
    } catch (error) {
      this.setState({
        report_id: null,
        report_status: null,
      });
      console.log(error);
    }
  };

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    // console.log(page+1);
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  handleDateRangePicker(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ create_date: date, show_create_date: show });
  }

  renderChess(rank) {
    switch (rank) {
      case 1:
        return <i style={{ color: '#FFCE00', paddingRight: '1px' }} class="fas fa-chess-queen"></i>;
      case 2:
        return <i style={{ color: '#B5B5B5', paddingRight: '1px' }} class="fas fa-chess-queen"></i>;
      case 3:
        return <i style={{ color: '#CC6600', paddingRight: '1px' }} class="fas fa-chess-queen"></i>;
      default:
        return (
          <i style={{ color: 'transparent', paddingRight: '1px' }} class="fas fa-chess-queen"></i>
        );
    }
  }

  renderStatus(status, id, file_result) {
    let file_report = null;

    if (id == this.state.report_id && this.state.report_status) {
      status = this.state.report_status;
    }

    if (file_result) {
      file_report = `${s3}/${file_result}`;
    } else {
      if (id == this.state.report_id && this.state.report_file) {
        status = 2;
        file_report = this.state.report_file;
      }
    }

    switch (status) {
      case 1:
        return <Label bsStyle="warning">รอดาวน์โหลด</Label>;
      case 2:
        return file_report ? (
          <ButtonA bsStyle="default" href={file_report} download>
            ดาวน์โหลด
          </ButtonA>
        ) : (
          <Button
            className="btn btn-default"
            onClick={() => (window.location = `${reportUrl}/create-excel?id=${id}`)}
          >
            ดาวน์โหลด
          </Button>
        );
      case 3:
        return <Label bsStyle="danger">error</Label>;
      default:
        return '';
    }
  }

  renderReportName(name) {
    switch (name) {
      case 'driver user report.':
        return 'รายงานรายชื่อพนักงานขับรถ';
      case 'report lazada summary.':
        return 'รายงานงานขนส่ง (Summary Report KA)';
      case 'Truck Costing Data':
        return 'รายงานราคางานขนส่ง (Truck Costing Data)';
      case 'report get instance price.':
        return 'รายงานการค้นหาราคาทั้งหมด';
      case 'account business report.':
        return 'รายงานรายชื่อลูกค้า GIZTIX Business';
      case 'report summary invoice':
        return 'รายงานใบแจ้งหนี้ (Acc)';
      case 'report summary invoice shipment.':
        return 'รายงานใบแจ้งหนี้ (Operation)';
      case 'report booking data.':
        return 'รายงานงานขนส่ง (Booking Data)';
      case 'report commission.':
        return 'รายงานค่าคอม (commission)';
      case 'Ka order delivered Report':
        return 'รายงานสินค้าส่งสำเร็จ (KA)';
      case 'Shipment Driver Invite Report':
        return 'รายงาน Campaign คนขับรถช่วยขาย';
      case 'Truck Case Report':
        return 'รายงาน Truck Case Report';
      default:
        return name;
    }
  }

  render() {
    // console.log(this.state);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Query
                query={reportResultListQuery}
                variables={{
                  page_number: this.state.page_number,
                  order_by: this.state.order_by,
                  order_type: this.state.order_type,
                  status: this.state.status,
                  name: this.state.name,
                  admin_username: this.state.admin_username,
                  create_date: this.state.create_date,
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  return (
                    <div>
                      <CardTable
                        title="ตารางรายงานรอดาวน์โหลด"
                        category="รายงานที่ขอรอดาวน์โหลด"
                        ctTableFullWidth
                        ctTableResponsive
                        rightButton={
                          <div style={{ float: 'right', marginLeft: '15px' }}>
                            <a style={{ marginRight: '10px' }} href="/report-main">
                              <Button>หน้ารวมรายงาน</Button>
                            </a>
                          </div>
                        }
                        content={
                          <div>
                            <PageWrapper style={{ textAlign: 'right' }}>
                              <div className="text"> หน้า :</div>
                              <FormControl
                                type="number"
                                min={1}
                                value={this.state.page_number}
                                onChange={event =>
                                  setInterval(
                                    this.nextPage(
                                      Number(event.target.value) - 1,
                                      loading || error ? '0' : data.reportResultList.total_page,
                                      refetch
                                    ),
                                    1000
                                  )
                                }
                                name="page_number"
                              />
                              <div className="text">
                                {' '}
                                {loading || error ? '' : `จาก ${data.reportResultList.total_page}`}
                              </div>
                            </PageWrapper>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th
                                    style={{ minWidth: '100px' }}
                                    className={this.renderSorting('status')}
                                    onClick={() => this.sort('status', refetch)}
                                  >
                                    สถานะ
                                  </th>
                                  <th
                                    style={{ minWidth: '250px' }}
                                    className={this.renderSorting('name')}
                                    onClick={() => this.sort('name', refetch)}
                                  >
                                    ชื่อรายงาน
                                  </th>
                                  <th
                                    style={{ minWidth: '100px' }}
                                    className={this.renderSorting('admin_username')}
                                    onClick={() => this.sort('admin_username', refetch)}
                                  >
                                    Admin ที่กดรายงาน
                                  </th>
                                  <th
                                    style={{ minWidth: '150px' }}
                                    className={this.renderSorting('create_date')}
                                    onClick={() => this.sort('create_date', refetch)}
                                  >
                                    วันที่สร้าง
                                  </th>
                                </tr>
                                <tr className="tableFilter">
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.status}
                                      onChange={event =>
                                        this.setState(
                                          { status: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipment_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={1}>รอดาวน์โหลด</option>
                                      <option value={2}>ดาวน์โหลด</option>
                                      <option value={3}>error</option>
                                    </FormControl>
                                  </th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.name}
                                      placeholder="ค้นหารหัสลูกค้า"
                                      onChange={event =>
                                        this.setState(
                                          { name: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="name"
                                    />
                                  </th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.admin_username}
                                      placeholder="ค้นหาอีเมล"
                                      onChange={event =>
                                        this.setState(
                                          { admin_username: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="admin_username"
                                    />
                                  </th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() =>
                                        this.setState(
                                          { create_date: '', show_create_date: '' },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.show_create_date}
                                        placeholder="ค้นหาสร้าง"
                                        name="create_date"
                                      />
                                    </DateRangePicker>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading || error ? (
                                  <tr>
                                    <td colSpan="4" className="textCenter">
                                      {loading ? 'Loading...' : error.message}
                                    </td>
                                  </tr>
                                ) : (
                                  data.reportResultList.report.map((prop, key) => (
                                    <tr key={key}>
                                      <td>
                                        {this.renderStatus(prop.status, prop._id, prop.file_result)}
                                      </td>
                                      <td>{this.renderReportName(prop.name)}</td>
                                      <td>{prop.admin_username}</td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {prop.create_date}
                                        </Moment>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                        }
                      />

                      <div style={{ textAlign: 'right' }}>
                        {loading ? null : (
                          <ReactPaginate
                            pageCount={data.reportResultList.total_page}
                            forcePage={this.state.page_number - 1}
                            containerClassName="pagination"
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={({ selected }) => {
                              this.nextPage(selected, data.reportResultList.total_page, refetch);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default ChallengeDetail;
