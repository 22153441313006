import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledPhoto = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  & i {
    position: absolute;
    font-size: 18px;
    margin: 5px 5px;
    top: 5px;
    right: 5px;
  }

  & .fas {
    color: #ffff;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;

const PhotoPreview = (props) => {
  const { isCanDelete, onClick, onDelete, pathUrl } = props;

  return (
    <StyledPhoto>
      {isCanDelete && <i class="fas fa-times-circle"></i>}
      {isCanDelete && (
        <i
          class="far fa-times-circle"
          onClick={() => onDelete && onDelete()}
        ></i>
      )}

      <Image
        onClick={() => onClick && onClick()}
        style={{ width: '100%' }}
        src={pathUrl}
        rounded
      />
    </StyledPhoto>
  );
};

export default PhotoPreview;
