import gql from 'graphql-tag';

export default gql`
mutation UpdateSendEmailDocument(
    $mode: String!
    $id_array: [ID]
){
    sendEmailDocument(
        mode: $mode,
        id_array: $id_array
    ){
        succeed
    }
  }
`;
