import React from 'react';
import { Document, pdfjs, Page } from 'react-pdf';
import styled from 'styled-components';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const ButtonPDFPreview = styled.button`
  & {
    min-width: ${props => props.width}px;
    min-height: ${props => props.height}px;
    background-color: #fafafa;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: default;
  }

  ${props =>
    props.onClick &&
    `
    &:hover,
    &:active,
    &:focus {
      border-color: #d90101;
      cursor: pointer;
    }
  `}
`;

export default class PDFView extends React.Component {
  render() {
    const { pdfUrl, onClick } = this.props;
    const width = 220;
    const height = 330;

    return (
      <ButtonPDFPreview onClick={onClick} width={width} height={height}>
        <Document
          loading="กำลังโหลด..."
          error="ไม่สามารถโหลดไฟล์ PDF ได้"
          noData="ไม่มีไฟล์ PDF"
          file={pdfUrl}
        >
          <Page pageNumber={1} width={width} />
        </Document>
      </ButtonPDFPreview>
    );
  }
}
