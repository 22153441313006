import gql from 'graphql-tag';

export default gql`
  query($date: String!) {
    trackingCodByCashPopup(date: $date) {
      date
      total_tracking_wait_tranfer
      wait_tranfer_price
      detail_by_agent {
        agent
        agent_name
        wait_tranfer_price
        total_tracking_wait_tranfer
      }
    }
  }
`;
