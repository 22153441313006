import gql from 'graphql-tag';

export default gql`
  mutation subThirdPartySettingUpdate($_id: ID!,$agent_shipment_status: Int,$proof_of_transfer: [typeProofOfTransferArg]) {
    subThirdPartySettingUpdate(
      _id: $_id
      agent_shipment_status: $agent_shipment_status
      proof_of_transfer: $proof_of_transfer
    ) {
      succeed
    }
  }
`;