import React from 'react';
import LeafletMap from '../../../../../../components/LeafletMap';
import shipmentListQuery from './graphql/query/shipmentList';
import { client } from '../../../../../../index';
import firebase from 'firebase';
import firebaseConfig from 'config/firebaseConfig';
import moment from 'moment';
import 'moment/locale/th';

class ShipmentMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shipmentStatus: null,
      shipmentType: null,
      isWorking: false,

      //gps tracking
      driverTrackingLatitude: 0,
      driverTrackingLongitude: 0,
      driverTrackingSpeed: 0,
      driverTrackingActivity: '',
      driverTrackingUpdateAt: null,
      isBounds: true,
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  componentDidMount() {
    this.fetchDataShipmentList();
    this.getGpsData();
  }

  fetchDataShipmentList = async () => {
    try {
      const { data } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: this.props.shipmentId,
        },
      });
      const { shipment_status, shipment_type } = data.shipmentList.shipment[0];
      const { working } = data.trackingList.tracking[0];
      this.setState({
        isWorking: working,
        shipmentStatus: shipment_status,
        shipmentType: shipment_type,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getGpsData = async () => {
    try {
      const { data } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: this.props.shipmentId,
        },
      });

      const driverId = data.shipmentList.shipment[0].driver_user_id;
      firebase
        .database()
        .ref(`/gps/${driverId}`)
        .limitToLast(1)
        .on('value', async snapshot => {
          if (snapshot.numChildren() === 1) {
            snapshot.forEach(child => {
              const val = child.val();
              // console.log(val);
              this.setState({
                driverTrackingLatitude: val.location.coords.latitude,
                driverTrackingLongitude: val.location.coords.longitude,
                driverTrackingSpeed: val.location.coords.speed > 0 ? val.location.coords.speed : 0,
                driverTrackingActivity: val.location.activity ? val.location.activity.type : '',
                driverTrackingUpdateAt: val.location.timestamp,
              });
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  boundsLatLng = () => {
    const {
      driverTrackingLatitude,
      driverTrackingLongitude,
      shipmentStatus,
      isBounds,
    } = this.state;

    if (!isBounds) {
      return;
    }

    let driverLatLng = [];
    if (
      driverTrackingLatitude !== 0 &&
      driverTrackingLongitude !== 0 &&
      (shipmentStatus === 3 || shipmentStatus === 9)
    ) {
      this.setState({ isBounds: false });
      driverLatLng = [{ lat: driverTrackingLatitude, lng: driverTrackingLongitude }];
    }

    const mergeLatLng = [...driverLatLng];

    return mergeLatLng;
  };

  getDriverMarker = () => {
    const {
      driverTrackingLatitude,
      driverTrackingLongitude,
      shipmentStatus,
    } = this.state;

    if (
      driverTrackingLatitude !== 0 &&
      driverTrackingLongitude !== 0 &&
      (shipmentStatus === 3 || shipmentStatus === 9)
    ) {
      return {
        lat: driverTrackingLatitude,
        lng: driverTrackingLongitude,
        popup: this.getDetailPopupDriverGps(),
      };
    }

    return null;
  };

  getDetailPopupDriverGps = () => {
    const {
      driverTrackingActivity,
      driverTrackingSpeed,
      driverTrackingUpdateAt,
    } = this.state;

    return {
      speed: `ความเร็ว: ${(driverTrackingSpeed * 3.6).toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })} กม./ชม.`,
      activity: `Activity: ${this.getStatusDriverActivity(driverTrackingActivity)}`,
      updateAt: `อัพเดทเมื่อ: ${this.getTimeAgo(driverTrackingUpdateAt)}`,
    };
  };

  getTimeAgo = time => {
    const currentTime = moment();
    const driverTime = moment(time);
    if (driverTime.isAfter(currentTime)) {
      return 'ไม่กี่วินาทีที่แล้ว';
    }

    return moment(driverTime).fromNow();
  };

  getStatusDriverActivity = activity => {
    if (activity === 'in_vehicle') {
      return 'กำลังขับรถ';
    } else if (activity === 'still') {
      return 'หยุดนิ่ง';
    } else if (activity === 'unknown') {
      return 'ไม่รู้สถานะ';
    } else if (activity === 'on_foot' || activity === 'walking') {
      return 'กำลังเดิน';
    } else if (activity === 'running') {
      return 'กำลังวิ่ง';
    } else if (activity === 'on_bicycle') {
      return 'กำลังขับรถ';
    } else if (!activity) {
      return 'จอดรถ';
    }

    return activity;
  };

  render() {
    moment.locale('th');

    return <LeafletMap markerDriver={this.getDriverMarker()} boundsLatLng={this.boundsLatLng()} />;
  }
}

export default ShipmentMap;
