import gql from 'graphql-tag';

export default gql`
mutation createCostingDocument(
    $createCostingDocument: inputCostingDocument
){
    createCostingDocument(
      createCostingDocument: $createCostingDocument
    ){
      _id
    }
  }
`;
