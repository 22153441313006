import gql from 'graphql-tag';

export default gql`
  query LazadaImportOrderList($lazada_import_order_id: ID, $account_info_id: ID) {
    lazadaImportOrderList(_id: $lazada_import_order_id, account_info_id: $account_info_id) {
      order {
        _id
        name
        create_date
        pick_up_date
        format_bookname
      }

      total_page
    }
  }
`;
