import gql from 'graphql-tag';

export default gql`
  query challengeDistances(
    $params: paramsChallengeDistances
    $sort: sort
    $page: Int
  ){
    challengeDistances(
      params: $params
      sort: $sort
      page: $page
      showData: 30
    ){
      challengeDistances{
        _id
        challenge
        account_info
        account_business
        business_req_number
        account_info_business_name
        distance
        rank
        rank_old
        shipment
        create_date
        last_update
      }
      totalPage
    }
  }
`;


