import React from 'react'
import styled from 'styled-components'
import formatNumber from '../../function/formatNumber'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    border-radius: 10px;
    background-color: ${props => props.color ? props.color : '#fafafa'};
    min-width: 50%;
    padding: 20px;
    font-size: 3.5vw;
    font-weight: bold;
    box-sizing: border-box;
    margin: 0px 5px;
`

const BoxStatus = props => {
    return (
        <Wrapper color={props.color} >
            <div 
                style={{
                    textAlign: 'start',
                    width: '100%',
                    color: '#fff'
                }}
            >
                <span>{props.title}</span>
            </div>
            <div
                style={{
                    textAlign: 'start',
                    width: '100%',
                    color: '#fff',
                    fontSize: '7vw'
                }}
            >
                <span>{formatNumber(props.quantity)}</span>
            </div>
        </Wrapper>
    )
}

export default BoxStatus