import gql from 'graphql-tag';

export default gql`
query search(
  $_id: ID
  $name: String
  $admin_id: ID
  $admin_username: String
  $status: Int
  $create_date: String
  $complete_date: String
  $page_number: Int
  $order_by: String
  $order_type: String
){
    reportResultList(
        show_data: 30
        _id: $_id
        name: $name
        admin_id: $admin_id
        admin_username: $admin_username
        status: $status
        create_date: $create_date
        complete_date: $complete_date
        page_number: $page_number
        order_by: $order_by
        order_type: $order_type
  	) {
    report {
        _id
        admin_id
        name
        file_result
        admin_username
        status
        create_date
        complete_date
        error_result
    }
    total_page
  }
}
`;


