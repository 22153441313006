import React from 'react';
import { NumberIndicator, TabHeaderContainer } from '../styled';

const CostPerParcelSubHeader = ({ setValue, value, menus = [] }) => {
  return (
    <TabHeaderContainer>
      <div className="sub-header-left">
        <div className="menu-wrapper">
          <ul>
            {menus &&
              menus.length > 0 &&
              menus.map(data => (
                <li
                  className={value === data.stage && 'active'}
                  onClick={() => setValue(data.stage)}
                >
                  <i className={data.icon}></i>
                  <a>{data.title}</a>
                  {/* <NumberIndicator num={data.num} /> */}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </TabHeaderContainer>
  );
};

export default CostPerParcelSubHeader;
