import gql from "graphql-tag";

export default gql`
  query {
    adminList(my_name: true) {
      admin {
        _id
        admin_username
        admin_email
        admin_display_picture
      }
    }
  }
`;
