import React, { Component } from 'react';
import { TabHeaderContainer, NumberIndicator } from './styled';

class SubHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: '',
    };
  }

  render() {
    return (
      <TabHeaderContainer>
        <div className="sub-header-left">
          <div className="menu-wrapper">
            <ul>
              {this.props.menus &&
                this.props.menus.length > 0 &&
                this.props.menus.map(data => (
                  <li
                    className={this.props.stage == data.stage && 'active'}
                    onClick={() => this.props.selectedState(data.stage)}
                  >
                    <i className={data.icon}></i>
                    <a>{data.title}</a>
                    <NumberIndicator num={data.num} />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </TabHeaderContainer>
    );
  }
}

export default SubHeader;
