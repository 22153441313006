import gql from 'graphql-tag';

export default gql`
	mutation update(
		$_id: ID!
		$driver_name: String
		$driver_phone_code: String
		$driver_phone_number: String
		$driver_accept: Int
	){
		trackingUpdate(
			_id: $_id,
			driver_accept:$driver_accept,
			driver_name:$driver_name,
			driver_phone_code:$driver_phone_code,
			driver_phone_number:$driver_phone_number
		){
			_id
		}
	}
`;
