import gql from 'graphql-tag';

export default gql`
query  settingHolidays(
    $params: paramsSettingHolidays,
       $sort: sort,
    $page: Int,
    $showData: Int!
  ){
      settingHolidays (
        params: $params,
        sort: $sort,
        page: $page,
          showData: $showData,
      ){
        holidays{
          _id
          year
          holiday
          create_date
          last_update
        }
        totalPage
        totalDocument
      }
  }
`;
