import React from 'react';
import { Popup, Tooltip } from 'react-leaflet';
import styled from 'styled-components';
import './MarkerPopup.css';

const Container = styled.div`
  & .leaflet-popup-content {
    margin: 10px !important;
  }
`;

const MarkerPopup = props => {
  const { title, ...except } = props;
  return (
    <Popup {...except} className="popup-time">
      <div className="popup-time__title">{props.title}</div>
      <div>{props.subTitle}</div>
    </Popup>
  );
};

export default MarkerPopup;
