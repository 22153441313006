import React from 'react';
import { Button, Col, FormControl, FormGroup, Row, Modal } from 'react-bootstrap';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import SelectCustomerParcel from '../container/SelectCustomerParcel';
// import DateRangePicker from 'react-bootstrap-daterangepicker';

const RepoerPD = ({ callExportReport, adminId }) => {
  const [open, setOpen] = React.useState(false);
  const [customer, setCustomer] = React.useState(null);
  const [date, setDate] = React.useState(null);

  //   const handleDateRangePickerReport = (event, picker) => {
  //     const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
  //     const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
  //       'DD/MM/YYYY'
  //     )}`;
  //     this.setState({ reportDate: date, show_reportDate: show });
  //   };

  const onClickExport = () => {
    if (date && customer && adminId) {
      callExportReport(
        JSON.stringify({
          admin_id: adminId,
          create_date: date,
          account_info: customer,
        }),
        '/parcel-detail-report'
      );
    } else {
      alert('กรุณาเลือกข้อมูลให้ครบถ้วน');
    }
  };

  return (
    <React.Fragment>
      <tr>
        <td style={{ width: '100px' }}>
          <Button onClick={() => setOpen(true)}>
            <i style={{ color: '#6666666' }} className="fas fa-search"></i> ดาวน์โหลดรายงาน
          </Button>
        </td>
        <td>
          <b>รายงาน PD</b>
        </td>
        <td>แสดงรายละเอียด PD ทั้งหมด</td>
      </tr>
      <Modal
        show={open}
        onHide={() => {
          setOpen(false);
          setCustomer(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>รายงาน PD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row style={{ marginBottom: '10px' }}>
            <Col md={12}> ช่วงวันที่ </Col>
          </Row>
          <FormGroup controlId="formValidationError1">
            <DateRangePicker
              onApply={(event, picker) => {
                setDate(
                  picker.startDate.format('YYYY-MM-DD') + ':' + picker.endDate.format('YYYY-MM-DD')
                );
              }}
              onCancel={() => setDate(null)}
              containerStyles={{ display: 'block' }}
              opens="right"
              format="DD/MM/YYYY"
            >
              <FormControl type="text" value={date} placeholder="ค้นหาวันที่" name="reportDate" />
            </DateRangePicker>
          </FormGroup>

          <SelectCustomerParcel onChange={valueChange => setCustomer(valueChange)} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="primary"
            onClick={() => {
              onClickExport();
            }}
          >
            ดาวน์โหลดรายงาน
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default RepoerPD;
