import gql from 'graphql-tag';

export default gql`
    mutation createShipmentSla(
        $createShipmentSla: createShipmentSlaArg
    )
    {
        createShipmentSla
        (
        createShipmentSla: $createShipmentSla
        ) 
        {
            _id
            shipment
            sla_exp
            sla_case_number
        }
    }
`;
