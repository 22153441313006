import styled from 'styled-components';

export const CustomPhotoAdd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-color: rgba(49, 141, 232, 0.1);
  border: 1px dashed #318de8;
  border-radius: 6px;

  & i {
    color: #318de8;
    font-size: 20px;
  }

  & > span {
    margin-top: 6px;
    color: #318de8;
  }
`;
