import gql from 'graphql-tag';

export const shipmentListFragment = gql`
  fragment shipmentFields on typeShipment {
    _id
    shipment_prefix
    shipment_number
    shipment_name
    shipment_total_cod
    shipment_accept_cod
    shipment_cancel_cod
    admin
    flash_deals_id
    create_date
    shipment_running_number
    matching_time
    shipment_type
    driver_name_under_mama
    driver_tel_under_mama
    round_trip
    shipment_status
    shipper
    shipper_detail
    account_user_name
    account_user_display_name
    transporter
    transporter_detail
    transporter_name
    transporter_phone_code
    transporter_phone_number
    user_setting_truck_id
    driver_user_id
    shipment_tracking_number
    distance
    paid_type
    paid_status
    cancel_comment
    assign_driver_date
    last_update
    completed_date
    cancel_date
    user_setting_truck_license_plate
    driver_name
    driver_phone_code
    driver_phone_number
    setting_truck_name {
      th
    }
    truck_type_name {
      th
    }
    truck_type_qty
    pick_up_date
    shipment_address {
      _id
      tag_name
      mode
      address
      subdictrict
      province
      lat
      lng
      contact_name
      contact_tel
      remark
      use_contact_point
      paid_by_cod
      pick_up_date
      delivery_date
      dictrict
      commodity {
        name
      }
    }
    shipment_other_detail {
      remark
      user_ref
      document {
        path
      }
    }
    shipment_additional {
      additional_id
      additional
      qty
      price
      address
    }
    shipment_confirm_document
    shipment_confirm_detail {
      receipt_doc {
        path
      }
    }
    shipment_refund {
      amount
      status
      detail {
        th
        en
      }
    }
    shipment_fines {
      amount
      status
      detail {
        th
        en
      }
      detail_complete {
        th
        en
      }
    }
    multi_point {
      distance_point
      distance_point_detail
      total_point
      price_per_point
    }
    polyline_list {
      distance
      duration
      polyline
    }
    tracking {
      _id
      driver_accept
      driver_accept_date
      tracking_detail_lazada {
        pick_up {
          tag_name
          dictrict
          contact_status
          contact_customer_date
          check_commodity
          check_commodity_date
          check_commodity_lat
          check_commodity_lng
          signature_picture
          signature_date
          lat_signature_picture
          lng_signature_picture
          commodity_picture
          commodity {
            _id
            accept
            name
            order_number
            address
            commodity_picture
            check_lat
            check_lng
          }
        }
        delivery {
          _id
          tag_name
          province
          dictrict
          delivery_status
          failure_date
          failure_detail
          paid_by_cod
          contact_status
          contact_customer_date
          check_commodity
          check_commodity_date
          check_commodity_lat
          check_commodity_lng
          signature_picture
          signature_date
          lat_signature_picture
          lng_signature_picture
          commodity_picture
          commodity_picture_end
          cod_price
          cod_tranfer
          cod_tranfer_date
          cod_tranfer_slip
          address
          send_final_status
          lazada_order_api
          send_failed_status
          jd_send_fail_status
          commodity {
            _id
            accept
            name
            order_number
            address
            commodity_picture
            name_detail
            contact_name
            contact_tel
            check_lat
            check_lng
          }
        }
        return {
          tag_name
          check_commodity_return_date
          check_commodity_return
          check_commodity_return_lat
          check_commodity_return_lng
          contact_return_customer_date
          contact_return_status
          signature_date_return
          signature_picture_return
          lat_signature_picture_return
          lng_signature_picture_return
          send_failed_status
          delivery_point {
            _id
            delivery_status
            address
            commodity_picture_return
            failure_detail
            send_final_status
            lazada_order_api
            lazada_order_damaged
            send_package_damaged
            send_failed_status
            jd_send_fail_status
            commodity {
              name
              order_number
              reject_detail
              name_detail
              contact_name
              contact_tel
            }
          }
        }
      }
      working
    }
    lazada_import_order_id
    lazada_send_final_status
    lazada_order_type
    jd_send_fail_status
  }
`;
