import React, { Component } from 'react'
import styled from 'styled-components';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import BoxStatus from './components/BoxStatus';
import GTXSortingLogo from 'assets/img/GTXSortingLogo.svg';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import { ParcelRow } from './components'
import dashboardLazadaPredictQuery from './graphql/query/dashboardLazadaPredict'
import DO_NOTHING_MUTATION from './graphql/mutation/doNothing'
import {client} from '../../../../index';

const Wraper = styled.div`
    height: 100vh;
    background-color: #000;
    padding: 50px;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none; 
    scrollbar-width: none;

    .table-vertical {
        display:flex;
        flex-direction: row;
        min-height: calc(80vh);
        max-height: calc(80vh);

        &::-webkit-scrollbar {
            display: none;
        }
    
        -ms-overflow-style: none; 
        scrollbar-width: none;
    }

    .table-responsive {
        overflow: auto;
        border: 1px solid #fff;

        &::-webkit-scrollbar {
            display: none;
        }
    
        -ms-overflow-style: none; 
        scrollbar-width: none;

        &::fist-child {
            margin-right: 5px;
        }

        &::last-child {
            margin-left: 5px;
        }
    }

    & table {
        margin: 0px;

        thead tr th {
            text-align: center;
            font-size: 3.5vw;
            color: #fff;
            font-weight: bold;
            // border-left: 1px solid #fff;
            width: 50%;
            padding: 4px !important;

            &:last-child {
                // border-right: 1px solid #fff;
            }
        }

        thead tr {
            &:first-child {
                border-top: 1px solid #fff;
            }
        }

        tbody tr {
            &:hover{
                background-color: #000;
                color: #fff;
            }

            border-top: 0px solid #fff;

            &:last-child{
                border-bottom: 1px solid rgba(255,255,255, 0.2);
            }

            td {
                padding: 0 25% !important;
                text-align: center;
                font-size: 3.5vw;
                font-weight: bold;
                border-top: 1px solid rgba(255,255,255, 0.2);

                // border-left: 1px solid #fff;

                &:last-child {
                    // border-right: 1px solid #fff;
                }
            }
        }
    }
`

const StyledParcelRow = styled.tr`
    color: ${props => props.color ? props.color : '#fff'};

    .textWrap {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        width: 100%;
        padding: 8px 30% !important;
    }
`

const LogoWrapper = styled.div`
    font-size: 4vw;
    color: #fff;
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 10px;
    }
`

const StyledReactPaginate = styled.div`
    ul.pagination>li>a, ul.pagination>li>span{
        background: transparent !important;
    }
`

const HorizontalWrapper = styled.div`
    display: flex;
    justify-content: center;
`

class ParcelFlight extends Component {
    componentDidMount(){
        this.intervalId = setInterval( () => client.mutate({ mutation: DO_NOTHING_MUTATION, refetchQueries: () => ['dashboardLazadaPredict'] }), 5000);
    }

    componentWillUnmount(){
        clearInterval(this.intervalId);
    }

    render(){
        return (
            <Query
                query={dashboardLazadaPredictQuery}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error, refetch }) => {
                    if(error){
                        return (
                            <Wraper>
                                <LogoWrapper>
                                    <img src={GTXSortingLogo} style={{width: '10%', height: '10%', marginRight: '20px'}} />
                                    ARRIVAL PARCEL FROM BKK2
                                    &nbsp;
                                    <i className="fas fa-truck-moving"></i>
                                </LogoWrapper>
                                
                                <HorizontalWrapper>
                                    <BoxStatus title={'คาดการณ์สินค้าเข้า GSC ทั้งหมด (ชิ้น)'} quantity={0} color={'#4285F4'}/>
                                    <BoxStatus title={'กำลังดำเนินการจัดส่ง GSC (ชิ้น)'} quantity={0} color={'#33A853'}/>
                                </HorizontalWrapper>
                                <div className="table-vertical" style={{marginTop: '10px'}} >
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th>PREDICT ZONE</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ParcelRow name={'No Data'} quantity={0} cpa={16} />
                                        </tbody>
                                    </Table>
                                    <Table hover responsive>
                                        <thead>
                                            <tr>
                                                <th>DELIVERING TO GSC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ParcelRow name={'No Data'} quantity={0} cpa={16} />
                                        </tbody>
                                    </Table>
                                </div>
                            </Wraper>
                        )
                    }

                    return(
                        data && data.dashboardLazadaPredict ? 
                        <Wraper>
                            <LogoWrapper>
                                <img src={GTXSortingLogo} style={{width: '10%', height: '10%', marginRight: '20px'}} />
                                INBOUND PARCEL PREDICTION
                                &nbsp;
                                <i className="fas fa-truck-moving"></i>
                            </LogoWrapper>
                            
                            <HorizontalWrapper>
                                <BoxStatus title={'คาดการณ์สินค้าเข้า GSC ทั้งหมด (ชิ้น)'} quantity={data.dashboardLazadaPredict.totalPredict ? data.dashboardLazadaPredict.totalPredict : 0} color={'#4285F4'}/>
                                <BoxStatus title={'กำลังดำเนินการจัดส่ง GSC (ชิ้น)'} quantity={data.dashboardLazadaPredict.totalDelivering ? data.dashboardLazadaPredict.totalDelivering : 0} color={'#33A853'}/>
                            </HorizontalWrapper>
                            <div className="table-vertical" style={{marginTop: '10px'}} >
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>PREDICT ZONE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.dashboardLazadaPredict.predict ? 
                                                (data.dashboardLazadaPredict.predict.length > 0 ? 
                                                    data.dashboardLazadaPredict.predict.map(data => <ParcelRow name={data.name} quantity={data.amount} cpa={16} />)
                                                    : 
                                                    <ParcelRow name="NO DATA" />
                                                ) 
                                                : 
                                                <ParcelRow name="NO DATA" />
                                        }
                                    </tbody>
                                </Table>
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>DELIVERING TO GSC</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.dashboardLazadaPredict.deliving ? 
                                                (data.dashboardLazadaPredict.deliving.length > 0 ? 
                                                    data.dashboardLazadaPredict.deliving.map(data => <ParcelRow name={data.name} quantity={data.amount} cpa={16} />)
                                                    : 
                                                    <ParcelRow name="NO DATA" />
                                                ) 
                                                : 
                                                <ParcelRow name="NO DATA" />
                                        }
                                    </tbody>
                                </Table>
                            </div>

                            {false && 
                            <StyledReactPaginate style={{ textAlign: 'right', marginTop: 20 }}>
                                {true && (
                                    <ReactPaginate
                                        pageCount={5}
                                        forcePage={this.state.pageNumber - 1}
                                        containerClassName="pagination"
                                        nextLabel=">"
                                        previousLabel="<"
                                        onPageChange={({ selected }) => {
                                            this.setState({
                                                pageNumber: selected + 1,
                                            });
                                        }}
                                    />
                                )}
                            </StyledReactPaginate>
                            }
                        </Wraper>
                        :
                        <Wraper>
                            No Data
                        </Wraper>
                    )
                }}
            </Query>
        )
    }
}

export default ParcelFlight