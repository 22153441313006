import gql from 'graphql-tag';

export default gql`
mutation updateCosting(
    $shipment_id: ID!
    $updateCosting: updateCostingArg
    $updateCostingList: Boolean
){
    updateCosting(
      shipment_id: $shipment_id
      updateCosting: $updateCosting
      updateCostingList: $updateCostingList
    ){
      _id
    }
  }
`;
