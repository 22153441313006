import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Modal,
  Table,
  FormControl,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Mutation, graphql, Query, compose } from 'react-apollo';
import Moment from 'react-moment';
import NotificationSystem from 'react-notification-system';

import adminListQuery from 'views/Setting/graphql/query/adminList';
import datamasterJobTypeListQuery from 'views/Setting/graphql/query/datamasterJobTypeList';
import datamasterServiceCategoryListQuery from 'views/Setting/graphql/query/datamasterServiceCategoryType';
import datamasterServiceItemAddMutation from 'views/Setting/graphql/mutation/datamasterServiceItemAdd';

//global
import s3 from 'config/s3';

//in Module
import { Card, Input, SelectBox, Select, Label } from 'views/Setting/components';

import { FormDataMaster, FormDataMasterEdit } from './components';

//styled component
const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const Container = styled.div`
	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}

	& .text-green {
		color: #19AA19;
	}

	& .text-blue {
		color: #318DE8;
	}

	& .text-red {
		color: #FF0000;
	}

	& .text-grey {
		color: #F2F2F2;
	}

	& .text-primary {
		color: #D90101 !important;
	}

	& .btn-edit{
		cursor: pointer;
    	color: #333;
	}
`;

const CardContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 0;
  display: flex;
  flex-direction: row;
`;
const ColLeft = styled.div`
  width: 200px;
  border-right: 1px solid #dbdbdb;
`;
const ColRight = styled.div`
  width: calc(100% - 200px);
`;

const TextHeader = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  padding: 15px;
`;

const UlTruck = styled.ul`
	padding:0;
	border-top: 1px solid #dddddd;

	& li{
		list-style: none;
		padding: 10px;    
		border-bottom: 1px solid #EAEAEA;
		cursor: pointer;

		&:hover{
			background-color: #fafafa;
		}

		&.active{
			background-color: #EAEAEA;
		}

		&:last-child{
			border-bottom: none;
		}
	}

	& i {
		float: right;
		16px;
	}
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
`;

const TextRight = styled.div`
  height: 40px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const TextContent = styled.div`
  font-size: 18px;
  line-height: 1;
  margin-top: 10px;
`;

const TextMessageError = styled.span`
  font-size: 18px;
  color: #ff0000;
`;

const selectOptionTypeBusiness = [
  { value: '', label: '-- เลือก --' },
  { value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
  { value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
  { value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
  { value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
  { value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
  { value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
  { value: 'การบริการ', label: 'การบริการ' },
  { value: 'เครื่องจักร', label: 'เครื่องจักร' },
  { value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
  { value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
  { value: 'บ้านและสวน', label: 'บ้านและสวน' },
  { value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
  { value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
  { value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
  { value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const selectOptionCategory = [
  { value: '', label: '-- เลือก --' },
  { value: '5e54cef8e99db3b888a2b73d', label: 'ค่าขนส่ง', prefix: 'T1' },
  { value: '5e54cef8e99db3b888a2b749', label: 'ค่าบริการ', prefix: 'S1' },
  { value: '5e54cef8e99db3b888a2b755', label: 'ค่าสำรองจ่าย', prefix: 'R1' },
];

const StatusContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
	padding: 20px;
	
	& .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		&-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 44px
			height: 44px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.1);

			& i {
				margin: auto;
				font-size: 20px;
			}

			&-danger {
				background-color: rgba(217, 1, 1, 0.1);

				& i {
					color: #D90101;
				}
			}

			&-success {
				background-color: rgba(25, 170, 25, 0.1);

				& i {
					color: #19AA19;
				}
			}
		}

		&-text {
			flex: 1;
			margin-left: 8px;
			line-height: 1;
			font-size: 18px;
			font-weight: bold;
		}

		& .nomargin {
			margin: 0;
		}
	}

	& .detail {
		font-size:18px;
		line-height: 1.4;
		margin-top: 10px;

		&-list {
			display: flex;
			flex-direction: row;

			&-left {
				align-self: flex-start;
				flex: 1;
			}

			&-right {
				align-self: flex-end;
			}
		}
	}

	& .text-danger {
		color: #D90101;
		font-weight: bold;
	}
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;
const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const ModalContainer = styled(Modal)`
  & .modal-dialog {
    width: 100%;
    max-width: 1024px;
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const IconStyled = styled.i`
  color: #0b8d10;
  font-size: 24px;
  cursor: pointer;

  &.off {
    transform: rotate(180deg);
    color: #b3b3b3;
  }
`;

let adminName = '';

class DataMasterGiztixTruck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_modal: false,
      JobTypeListIndex: 1,
      truckTypeIndex: 0,
      show_enable_datamaster: false,
      text_confirmEdit: '',
      dataModalService: null,
    };
  }

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  componentWillReceiveProps(newProps) {}

  openChangeDataMaster(category, truck, data) {
    console.log(category, truck, data);
    this.setState({
      show_enable_datamaster: true,
      service_category_id: category._id,
      serviceItem: data,
      text_confirmEdit: `${category.prefix}${data.datamaster_service_item_number} - ${truck.prefix} ${data.service_item_name.th}  รถ ${truck.truck_size_name.th}${truck.truck_type_name.th}`,
    });
  }

  openModalService(mode, JobTypeList, categoryList, truckTypeList, itemList) {
    if (mode == 'new') {
      this.setState({
        show_modal: true,
        MasterMode: mode,
        dataModalService: {
          JobTypeList: JobTypeList,
          categoryList: null,
          truckTypeList: null,
          itemList: null,
        },
      });
    } else if (mode == 'edit') {
      this.setState({
        show_modal: true,
        MasterMode: mode,
        dataModalService: {
          JobTypeList: JobTypeList,
          categoryList: categoryList,
          truckTypeList: truckTypeList,
          itemList: itemList,
        },
      });
    }
  }

  render() {
    if (!this.props.adminListQuery.loading && !this.props.adminListQuery.error) {
      adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
    }
    return (
      <Container>
        <NotificationSystem ref={this.notificationSystem} style={styleNotification} />

        <div className="content">
          <NotificationSystem ref={this.notificationSystem} style={styleNotification} />

          <HeaderContainer>
            <div className="sub-header-left">
              <a href="/giztixbusiness" className="btn-back">
                <i className="far fa-chevron-left"></i> กลับ
              </a>
              <div className="line"></div>
              <div className="menu-wrapper">
                <ul>
                  <li className="active">
                    <a href={`/settingdatamaster`}>
                      {' '}
                      <i className="fal fa-truck"></i> Giztix Trucks
                    </a>
                  </li>
                  <li>
                    <a href={`/settingdatamaster-holidays`}>
                      {' '}
                      <i class="fal fa-calendar-day"></i> วันหยุุดบริษัท
                    </a>
                  </li>
                  <li>
                    <a href={`/settingdatamaster-condition`}>
                      {' '}
                      <i class="far fa-ballot-check"></i> เงื่อนไขปัญหา
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </HeaderContainer>
          <Query query={datamasterJobTypeListQuery}>
            {({ loading, error, data, refetch: refetchJobTypeList, variables }) => {
              if (loading) return <Grid fluid>loading...</Grid>;
              if (error) return null;
              return (
                <div>
                  {data.datamasterJobTypeList.map((JobTypeList, JobTypeListKey) => {
                    return (
                      <div
                        data-id={JobTypeList._id}
                        key={JobTypeListKey}
                        className={
                          JobTypeList.position === this.state.JobTypeListIndex
                            ? 'JobTypeList_wrapper active'
                            : 'JobTypeList_wrapper'
                        }
                      >
                        <SubHeaderContainer>
                          <div className="sub-header-left">
                            <strong>ตารางประเภทงาน : {JobTypeList.jon_type_name}</strong>
                          </div>
                          <div className="sub-header-right">
                            <button
                              className="btn btn-primary"
                              onClick={() => this.openModalService('new', JobTypeList)}
                            >
                              + เพิ่มรายการ
                            </button>
                          </div>
                        </SubHeaderContainer>
                        {/* Content table */}
                        <Grid fluid>
                          <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                            <Col md={12}>
                              <CardContainer>
                                <ColLeft>
                                  <TextHeader>ประเภทรถ</TextHeader>

                                  <div>
                                    <UlTruck>
                                      {JobTypeList.datamaster_truck_type.map(
                                        (truckTypeList, truckTypeListKey) => {
                                          return (
                                            <li
                                              key={truckTypeListKey}
                                              className={
                                                truckTypeListKey == this.state.truckTypeIndex
                                                  ? 'active'
                                                  : ''
                                              }
                                              onClick={() =>
                                                this.setState({ truckTypeIndex: truckTypeListKey })
                                              }
                                            >
                                              {`${truckTypeList.truck_size_name.th} ${truckTypeList.truck_type_name.th}`}
                                              {truckTypeListKey == this.state.truckTypeIndex && (
                                                <i className="fal fa-chevron-right"></i>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </UlTruck>
                                  </div>
                                </ColLeft>
                                <ColRight>
                                  <TextHeader>ตารางรายละเอียดค่าบริการต่างๆ</TextHeader>
                                  {JobTypeList.datamaster_truck_type.map(
                                    (truckTypeList, truckTypeListKey) => {
                                      return (
                                        <div
                                          key={truckTypeListKey}
                                          style={{
                                            padding: '0 15px',
                                            display: `${
                                              truckTypeListKey == this.state.truckTypeIndex
                                                ? 'block'
                                                : 'none'
                                            }`,
                                          }}
                                        >
                                          <Table bordered>
                                            <thead>
                                              <tr>
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    fontWeight: 700,
                                                    width: 45,
                                                  }}
                                                >
                                                  เปิดใช้
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    fontWeight: 700,
                                                    width: 85,
                                                  }}
                                                >
                                                  รหัส
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    fontWeight: 700,
                                                    width: 150,
                                                  }}
                                                >
                                                  ประเภทรถ
                                                </td>
                                                <td
                                                  style={{
                                                    textAlign: 'center',
                                                    fontWeight: 700,
                                                    width: 85,
                                                  }}
                                                >
                                                  หน่วยนับ
                                                </td>
                                                <td
                                                  colSpan={3}
                                                  style={{ textAlign: 'center', fontWeight: 700 }}
                                                >
                                                  ราคาต้นทุน
                                                </td>
                                                <td
                                                  colSpan={3}
                                                  style={{ textAlign: 'center', fontWeight: 700 }}
                                                >
                                                  ราคาขาย
                                                </td>
                                                <td
                                                  style={{ textAlign: 'center', fontWeight: 700 }}
                                                >
                                                  ราคาขั้นต่ำ (บาท)
                                                </td>
                                              </tr>
                                              <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>ราคา (บาท)</td>
                                                <td>VAT (%)</td>
                                                <td>WHT (%)</td>
                                                <td>ราคา (บาท)</td>
                                                <td>VAT (%)</td>
                                                <td>WHT (%)</td>
                                                <td></td>
                                              </tr>
                                            </thead>
                                            {truckTypeList.datamaster_service_category.length ==
                                            0 ? (
                                              <tbody>
                                                <tr>
                                                  <td colSpan={11}> ไม่มีรายการ</td>
                                                </tr>
                                              </tbody>
                                            ) : (
                                              truckTypeList.datamaster_service_category.map(
                                                (categoryList, categoryKey) => {
                                                  return (
                                                    <tbody key={categoryKey}>
                                                      <tr style={{ backgroundColor: '#E3E3E3' }}>
                                                        <td colSpan={11}>
                                                          <b>
                                                            {categoryList.prefix}000 -{' '}
                                                            {truckTypeList.prefix} /{' '}
                                                            {categoryList.service_category_name.th}{' '}
                                                            {`${truckTypeList.truck_size_name.th} ${truckTypeList.truck_type_name.th}`}
                                                          </b>
                                                        </td>
                                                      </tr>
                                                      {categoryList.datamaster_service_item.length >
                                                      0 ? (
                                                        categoryList.datamaster_service_item.map(
                                                          (itemList, itemKey) => {
                                                            return (
                                                              <tr>
                                                                <td>
                                                                  <IconStyled
                                                                    className={`fas fa-toggle-on ${!itemList.enable &&
                                                                      'off'}`}
                                                                    onClick={() =>
                                                                      this.openChangeDataMaster(
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  />
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {categoryList.prefix}
                                                                    {
                                                                      itemList.datamaster_service_item_number
                                                                    }
                                                                    -{truckTypeList.prefix}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {itemList.service_item_name.th}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {itemList.unit}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >{`${itemList.cost.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )}`}</a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {itemList.vat_cost}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {itemList.wht_cost}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >{`${itemList.sale.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )}`}</a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {itemList.vat}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >
                                                                    {itemList.wht}
                                                                  </a>
                                                                </td>
                                                                <td>
                                                                  <a
                                                                    className="btn-edit"
                                                                    onClick={() =>
                                                                      this.openModalService(
                                                                        'edit',
                                                                        JobTypeList,
                                                                        categoryList,
                                                                        truckTypeList,
                                                                        itemList
                                                                      )
                                                                    }
                                                                  >{`${itemList.min_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )}`}</a>
                                                                </td>
                                                              </tr>
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <tr>
                                                          <td colSpan={11}>ไม่มีรายการ</td>
                                                        </tr>
                                                      )}
                                                    </tbody>
                                                  );
                                                }
                                              )
                                            )}
                                          </Table>
                                        </div>
                                      );
                                    }
                                  )}
                                </ColRight>
                              </CardContainer>
                            </Col>
                          </Row>
                        </Grid>
                      </div>
                    );
                  })}

                  <Mutation
                    mutation={datamasterServiceItemAddMutation}
                    variables={{
                      datamaster_service_detail: [
                        {
                          service_category_id: this.state.service_category_id,
                          serviceItem: [
                            {
                              _id: this.state.serviceItem ? this.state.serviceItem._id : null,
                              enable: this.state.serviceItem && !this.state.serviceItem.enable,
                            },
                          ],
                        },
                      ],
                    }}
                    update={() => {
                      this.setState({ show_enable_datamaster: false }, () => {
                        refetchJobTypeList();
                      });
                    }}
                  >
                    {datamasterServiceItemAdd => (
                      <Modal
                        show={this.state.show_enable_datamaster}
                        onHide={() => this.setState({ show_enable_datamaster: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title> เปิด/ปิด การใช้งาน data master </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: '30px 15px' }}>
                          คุณต้องการ{' '}
                          <b>
                            "
                            {this.state.serviceItem && this.state.serviceItem.enable
                              ? 'ปิด'
                              : 'เปิด'}
                            บริการ {this.state.text_confirmEdit}"
                          </b>{' '}
                          ใช่หรือไม่ ? <br />
                          กด <b>"ตกลง"</b> เพื่อเปลี่ยนข้อมูล
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            style={{ float: 'left' }}
                            onClick={() => this.setState({ show_enable_datamaster: false })}
                          >
                            กลับ
                          </Button>
                          <Button bsStyle="primary" onClick={() => datamasterServiceItemAdd()}>
                            ตกลง
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </Mutation>
                </div>
              );
            }}
          </Query>

          {/* Modal Add service */}

          <FormDataMasterEdit
            show_modal={this.state.MasterMode === 'edit' ? this.state.show_modal : false}
            onHideModal={bool => this.setState({ show_modal: false })}
            mode={this.state.MasterMode}
            dataModalService={this.state.dataModalService}
          />

          <FormDataMaster
            show_modal={this.state.MasterMode === 'new' ? this.state.show_modal : false}
            onHideModal={bool => this.setState({ show_modal: false })}
            mode={this.state.MasterMode}
            dataModalService={this.state.dataModalService}
          />
        </div>
      </Container>
    );
  }
}

export default compose(
  graphql(adminListQuery, {
    name: 'adminListQuery',
  })
)(DataMasterGiztixTruck);
