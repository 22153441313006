import React from 'react';
import { Grid, Row, Col, Tooltip, Button, Modal, Table } from 'react-bootstrap';
import styled from 'styled-components';
import formatCurrency from 'utils/formatCurrency';
import { Query } from 'react-apollo';
import billingParcel from './../graphql/billingParcel';
import {
  TEXT_CLOSE,
  TEXT_LIST,
  TEXT_REVENUE,
  TEXT_SPEND,
  TEXT_SUM_COST,
  TEXT_SUM_INCOME,
  TEXT_SUM_PROFIT,
  TEXT_TITLE_CUSTOMER,
  TEXT_TITLE_ITEM_NAME,
  TEXT_TITLE_SENT_DATE,
  TEXT_TITLE_SIZE,
  TEXT_TITLE_STATUS,
  TEXT_TITLE_WEIGHT,
  TEXT_TOTAL_COST,
} from '../utils/useConstants';

export const StyledTable = styled(Table)`
  &.custom-table {
    & thead tr th {
      color: #000000;
      font-weight: bold;
      font-size: 20px;
      text-transform: none;
    }

    & td {
      word-break: break-all;
      height: 50px;
    }
  }
`;

const Line = () => {
  return <Row style={{ marginBottom: '10px', borderBottom: '1px solid #ccc' }} />;
};

const columns = [
  {
    key: '1',
    title: 'รายการ',
    width: '200px',
  },
  {
    key: '2',
    title: 'ต้นทุน(บาท)',
    width: '130px',
  },
  {
    key: '3',
    title: 'รายได้(บาท)',
    width: '110px',
  },
  {
    key: '4',
    title: 'กำไร/ขาดทุน(บาท)',
    width: '100px',
  },
];

const TablePer = ({ data }) => {
  return (
    <StyledTable striped hover className="custom-table">
      <thead>
        <tr>
          {columns.map(item => (
            <th
              key={item.title}
              style={{
                minWidth: item.width,
                width: item.width,
                textAlign: item.textAlign,
              }}
            >
              {item.title}
            </th>
          ))}
        </tr>
      </thead>
      {console.log('parcel_order', data)}
      {data?.map(parcel_order => {
        return (
          <tbody>
            <tr style={{ right: '0' }}>
              <td> {parcel_order?.refNumber} </td>
              <td>{formatCurrency(parcel_order?.totalCostAvg || 0)} </td>
              <td>{formatCurrency(parcel_order?.totalRevenue || 0)} </td>
              <td>
                {formatCurrency(
                  Number(parcel_order?.totalRevenue || 0) -
                    Number(parcel_order?.totalCostAvg || 0) || 0
                )}
              </td>
            </tr>
          </tbody>
        );
      })}
    </StyledTable>
  );
};

const FormatStatus = styled.div`
  background: #fff;
  color: #000;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 70%;
  font-weight: bold;

  &.none {
    border: 2px solid black;
  }

  &.black {
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
  }

  &.blue {
    background: #337ab7;
    color: rgb(255, 255, 255);
  }

  &.red {
    background: #c63b3c;
    color: rgb(255, 255, 255);
  }

  &.yellow {
    background: #e89c37;
    color: rgb(255, 255, 255);
  }

  &.green {
    background: #81b214;
    color: rgb(255, 255, 255);
  }

  &.emerald {
    background: #82c5b9;
    color: rgb(255, 255, 255);
  }

  &.velvet {
    background: #d54062;
    color: rgb(255, 255, 255);
  }
`;

const RenderStatus = status => {
  switch (status) {
    case 1:
      return <FormatStatus className="black">คงคลังลูกค้า</FormatStatus>;
    case 2:
      return <FormatStatus className="none">แพลนไปรับสินค้า</FormatStatus>;
    case 3:
      return <FormatStatus className="yellow">กำลังไปคลัง GSC</FormatStatus>;
    case 4:
      return <FormatStatus className="none">Failed นำสินค้าออกจาก Lazada</FormatStatus>;
    case 5:
      return <FormatStatus className="blue">คงคลัง Giztix</FormatStatus>;
    case 6:
      return <FormatStatus className="red">ยกเลิก</FormatStatus>;
    case 7:
      return <FormatStatus className="emerald">ออกจัดส่งลูกค้า</FormatStatus>;
    case 8:
      return <FormatStatus className="green">ส่งสำเร็จ</FormatStatus>;
    case 9:
      return <FormatStatus className="velvet">คืนคลังลูกค้า</FormatStatus>;
    default:
      return <div> - </div>;
  }
};

const CalcuratePercent = (cost, revenue) => {
  const profit = Number(revenue) - Number(cost);

  const percent = (profit / Number(cost || 0)) * 100;

  console.log('CalcuratePercent', cost, revenue, percent);

  return percent || 0;
};

const CalcurateTotalCost = data => {
  let totalCost = 0;

  if (data?.length > 0) {
    data.forEach(item => {
      totalCost += Number(item?.totalCostAvg) || 0;
    });

    return totalCost;
  } else {
    return 0;
  }
};

const CostPerParcelModalData = ({ dataParcel }) => {
  const [open, setOpen] = React.useState(false);
  console.log('dataParcel', dataParcel);
  return (
    <React.Fragment>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="btn btn-default"
        target="_blank"
        style={{
          float: 'left',
          marginLeft: '10px',
          marginTop: '5px',
        }}
      >
        <i style={{ marginTop: '3px' }} className="fas fa-search"></i>
      </div>
      {open && (
        <Modal
          dialogClassName="modal-dialog-size"
          show={open}
          backdrop="static"
          onHide={() => setOpen(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>{dataParcel?.trackingNumber}</Modal.Title>
          </Modal.Header>
          <Query
            query={billingParcel}
            variables={{
              _id: dataParcel?._id,
            }}
          >
            {({ loading, error, data }) => {
              if (loading || error) {
                return null;
              }
              const parcelCostData = data.billingParcel;

              console.log('billingParcel', parcelCostData);
              let tableData = [...parcelCostData?.parcelCost] || [];

              if (parcelCostData?.parcelRevenue?.jobId) {
                const findIndex = tableData?.findIndex(item => {
                  return item?.jobId === parcelCostData?.parcelRevenue?.jobId;
                });

                if (findIndex !== -1) {
                  // console.log('IN FIND INDEX');
                  tableData[findIndex] = {
                    ...tableData[findIndex],
                    totalRevenue: parcelCostData?.parcelRevenue?.totalRevenue,
                  };
                } else {
                  tableData.push({
                    jobId: parcelCostData?.parcelRevenue?.jobId,
                    totalCostAvg: 0,
                    totalRevenue: parcelCostData?.parcelRevenue?.totalRevenue,
                    refNumber: parcelCostData?.parcelRevenue?.refNumber,
                  });
                }
              }

              if (tableData?.length === 0 && parcelCostData?.parcelRevenue?.totalRevenue > 0) {
                // console.log('IN 2 ');
                tableData.push({
                  jobId: parcelCostData?.parcelRevenue?.jobId,
                  totalCostAvg: 0,
                  totalRevenue: parcelCostData?.parcelRevenue?.totalRevenue,
                  refNumber: parcelCostData?.parcelRevenue?.refNumber,
                });
              }

              const CALCURATE_TOTAL_COST = CalcurateTotalCost(parcelCostData?.parcelCost);
              const PERCENT = CalcuratePercent(
                CALCURATE_TOTAL_COST,
                parcelCostData?.parcelRevenue?.totalRevenue || 0
              );
              // const TOTAL_COST = formatCurrency(data?.totalCost || 0);
              const TOTAL_REVENUE = formatCurrency(
                parcelCostData?.parcelRevenue?.totalRevenue || 0
              );
              const TOTAL_PROFIT = formatCurrency(
                Number(parcelCostData?.parcelRevenue?.totalRevenue || 0) -
                  Number(CALCURATE_TOTAL_COST)
              );

              const FINAL_PERCENT =
                PERCENT === Infinity && (parcelCostData?.cost || 0) === 0 ? 100 : PERCENT;

              return (
                <>
                  <Modal.Body>
                    <Row style={{ marginBottom: '10px' }}>
                      <Col md={12}>{/* <b>{TEXT_MODAL_SUBTITLE}</b> */}</Col>
                    </Row>

                    <Row style={{ marginBottom: '10px ', lineHeight: '1.2' }}>
                      <Col md={12}>
                        {TEXT_TITLE_CUSTOMER}:
                        <b
                          style={{
                            minWidth: '160px',
                            marginLeft: '57px',
                          }}
                        >
                          {parcelCostData?.customer?.full_name}
                        </b>
                      </Col>

                      <Col md={12}>
                        {TEXT_TITLE_ITEM_NAME}:
                        <b
                          style={{
                            minWidth: '160px',
                            marginLeft: '42px',
                          }}
                        >
                          {/* {dataParcel?.serviceItems[0]?.list_name} */}
                          {parcelCostData?.itemName}
                        </b>
                      </Col>
                      <Col md={12}>
                        {TEXT_TITLE_SIZE}:
                        <b
                          style={{
                            minWidth: '160px',
                            marginLeft: '57px',
                          }}
                        >
                          {parcelCostData?.width} x {parcelCostData?.length} x{' '}
                          {parcelCostData?.height} cm.
                        </b>
                      </Col>
                      <Col md={12}>
                        {TEXT_TITLE_WEIGHT}:
                        <b
                          style={{
                            minWidth: '160px',
                            marginLeft: '49px',
                          }}
                        >
                          {parcelCostData?.weight} kg.
                        </b>
                      </Col>
                      <Col md={12}>
                        <div
                          style={{
                            display: 'flex',
                          }}
                        >
                          {TEXT_TITLE_STATUS}:{' '}
                          <div
                            style={{
                              width: '160px',
                              marginLeft: '50px',
                            }}
                          >
                            {RenderStatus(parcelCostData?.status)}
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        {TEXT_TITLE_SENT_DATE}:
                        <b
                          style={{
                            minWidth: '160px',
                            marginLeft: '20px',
                          }}
                        >
                          {parcelCostData?.deliveredDate}
                        </b>
                      </Col>
                    </Row>

                    <Line />
                    <Row>
                      <TablePer data={tableData} />
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    {/* <Button onClick={() => setOpen(false)}>{TEXT_CLOSE}</Button> */}
                    <Row
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          width: '30%',
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '20px',
                          width: '40%',
                          justifyContent: 'end',
                          marginLeft: 'auto',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 'auto',
                          }}
                        >
                          {TEXT_SUM_COST}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 'auto',
                          }}
                        >
                          {TEXT_SUM_INCOME}{' '}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 'auto',
                          }}
                        >
                          {TEXT_SUM_PROFIT}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '20px',
                          width: '30%',
                          justifyContent: 'end',
                          marginLeft: 'auto',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 'auto',
                          }}
                        >
                          <div style={{}}> {formatCurrency(CALCURATE_TOTAL_COST)}</div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 'auto',
                          }}
                        >
                          <div style={{}}> {TOTAL_REVENUE}</div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 'auto',
                          }}
                        >
                          <b style={{}}>
                            {TOTAL_PROFIT} ({formatCurrency(FINAL_PERCENT)}
                            %)
                          </b>
                        </div>
                      </div>
                    </Row>
                  </Modal.Footer>
                </>
              );
            }}
          </Query>
          <Modal.Footer></Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default CostPerParcelModalData;
