import React, { Component } from 'react';
import { Grid, Row, Col, Label, Button, Modal, Table, FormControl, Checkbox } from 'react-bootstrap';
import styled from 'styled-components';
import { Mutation, graphql, Query, compose } from 'react-apollo';
import NotificationSystem from 'react-notification-system';
import Select from 'react-select';
import adminListQuery from "views/Setting/graphql/query/adminList";
import datamasterSlaTitlesListQuery from "views/Setting/graphql/query/datamasterSlaTitlesList";
import datamasterSlaLevelsListQuery from "views/Setting/graphql/query/datamasterSlaLevelsList";
import datamasterSlaLevelUpdateMutation from "views/Setting/graphql/mutation/datamasterSlaLevelUpdate";

import 'react-datetime/css/react-datetime.css';
//in Module
import { ModalConfirm } from 'views/Setting/components';

import { client } from '../../../../../index';

//styled component
const styleNotification = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px'
        },

        error: {
            color: '#FFFFFF',
            backgroundColor: '#FC727A',
            border: '1px solid #FC727A',
            boxShadow: 'none',
            borderRadius: '4px',
        },

        success: {
            color: '#FFFFFF',
            backgroundColor: '#318DE8',
            border: '1px solid #318DE8',
            boxShadow: 'none',
            borderRadius: '4px',
        }
    },
}
const Container = styled.div`
& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
    border: 1px solid #E3E3E3;
    background-color: #FFFFFF;
    color: #000000;
}

& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
    border: 0px
    background-color: #FF0000;
    color: #FFFFFF;
}

& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
    border: 0px
    background-color: #318DE8;
    color: #FFFFFF;
}

& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
    border: 0px
    background-color: #19AA19 !important;
    color: #FFFFFF;
}

& .text-green {
    color: #19AA19;
}

& .text-blue {
    color: #318DE8;
}

& .text-red {
    color: #FF0000;
}

& .text-grey {
    color: #F2F2F2;
}

& .text-primary {
    color: #D90101 !important;
}

& .btn-edit{
    cursor: pointer;
    color: #333;
}
& .rdtPicker {
    position: fixed !important;
}
`;

const CardContainer = styled.div`
	border-radius: 6px;
	border: 1px solid #E3E3E3;
	background-color: #FFFFFF;
	padding: 0;
    display: flex;
    flex-direction: row;
`;

const ColLeft = styled.div`
	width:300px;
	border-right:1px solid #DBDBDB;
`;

const ColRight = styled.div`
	width :calc(100% - 200px);
`;

const ColLeftContainer = styled.div`
    padding: 15px 10px; 
	width :calc(100% - 200px);
    border-right: 1px solid #ddd;
`;

const ColRightContainer = styled.div`
    width :calc(100% - 200px);
    overflow: auto;
    min-height: calc(100vh - 360px);
    max-height: calc(100vh - 360px);
`;

const TextHeader = styled.div`
	font-size: 18px;
	font-weight: 700;
	line-height: 1;
	padding:15px;
`;

const UlTruck = styled.ul`
	padding:0;
	border-top: 1px solid #dddddd;

	& li{
		list-style: none;
		padding: 10px;    
		border-bottom: 1px solid #EAEAEA;
		cursor: pointer;

		&:hover{
			background-color: #fafafa;
		}

		&.active{
			background-color: #EAEAEA;
		}

		&:last-child{
			border-bottom: none;
		}
	}

	& i {
		float: right;
		16px;
	}
`;

const HeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	border-bottom: 1px solid #efefef;
	display: flex;
	flex-direction: row;
	padding: 10px;

	

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}

		
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
		background-color: #E3E3E3;
		width: 1px;
		margin-left: 20px;
		margin-right: 20px;
	}

	& .menu-wrapper{
		& ul{
			padding-left: 0;
			margin: 0;
			display: flex;

			& li{
				list-style: none;
				margin-right: 15px;
				padding: 22px 10px 15px;

				& a{
					font-size:18px;
					cursor: pointer;
					& i{
						font-size:18px;
						color: #000000;
					}
				}
			}
			& li.active{
				border-bottom: 2px solid #d90402;
			}
			
		}
	}
`;

const SubHeaderContainer = styled.div`
	background-color: #FFFFFF;
	height: 60px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
	padding: 10px 20px;

	& .sub-header {
		&-left {
			flex: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
		}

		&-right {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-end;

			& button:not(:last-child) {
				margin-right: 10px;
			}
		}
	}

	& a, a:hover, a:active, a:focus, a:visited {
		color: #000000;
		font-size: 18px;

		& i {
			color: #D90101;
			font-size: 18px;
			margin-right: 5px;
		}
	}

	& .line {
		height: 100%;
    background-color: #E3E3E3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
	}
`;

const UICondition = styled.div`
    
`;
const Btnaddlist = styled.div`
    position: sticky;
    background-color: #fff;
    bottom: 0px;
    border-top: 1px solid #ddd;
`;

const CustomCheckbox = styled(Checkbox)`
	padding-left: 35px !important;
	margin-top: 12px;

	& input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #ffffff;
		border: 1px solid #e3e3e3;
		border-radius: 4px;
	}

	&:hover input ~ .checkmark {
		background-color: #f1f1f1;
	}

	& input:checked ~ .checkmark {
		border: 1px solid #ffffff;
		background-color: #19aa19;
	}

	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	& input:checked ~ .checkmark:after {
		display: block;
	}

	& .checkmark:after {
		left: 6px;
		top: 3px;
		width: 6px;
		height: 10px;
		border: solid #ffffff;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;
let adminName = "";
class DataMasterGiztixCondition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataslaTitlesList: 0,
            show_delSlaLevel: false,
            delSlaLevelTitles: '',
            delSlaLevelTitlesId: '',
            delSlaLevelTitlesIndex: '',
            sort: { "order_type": "1" },
            sortlist: { "order_type": "1", "order_by": 'create_date' },

            //slaLevels
            serviceName: { value: null, label: null },
            slaLevelsIndex: 0,
            slaLevelsId: '',
            slaTitleslevel: '',
            slalevelImpact: '',
            respondTime: '',
            respondName: { value: null, label: null },
            investigateTime: '',
            investigateName: { value: null, label: null },
            resolutionTime: '',
            resolutionName: { value: null, label: null },

            // slaTitles
            slaTitles: [],
            slaLevelsList: '',
            show_complete: false,
        };
    }
    handleChangeServiceTime(service) {
        this.state.dataslaTitlesList = 1;
        this.setState({ serviceName: { value: service.value, label: service.label } });
    }
    handleChangeRespond(respond) {
        this.state.dataslaTitlesList = 1;
        this.setState({ respondName: { value: respond.value, label: respond.label } });
    }
    handleChangeInvestigate(investigate) {
        this.state.dataslaTitlesList = 1;
        this.setState({ investigateName: { value: investigate.value, label: investigate.label } });
    }
    handleChangeResolution(resolution) {
        this.state.dataslaTitlesList = 1;
        this.setState({ resolutionName: { value: resolution.value, label: resolution.label } });
    }

    handleSelectCheckmark(event, index) {
        if (event) {
            this.state.slaTitles[index].show_driver_app = false;
        } else {
            this.state.slaTitles[index].show_driver_app = true;
        }
        this.setState({
            slaTitles: this.state.slaTitles,
        });
    }
    async setObjectslaLevelssub(slaLevelsList, slaLevelsListKey) {
        this.state.dataslaTitlesList = 1;
        try {
            const { data } = await client.query({
                query: datamasterSlaTitlesListQuery,
                fetchPolicy: 'network-only',
                variables: {
                    params: {
                        datamaster_sla_level: slaLevelsList._id,
                        status_delete: false
                    },
                    sort: this.state.sortlist,
                    showData: 30
                },
            });

            let arrayobj = [];
            data.datamasterSlaTitles.slaTitles.map(e => {
                let obj = {};
                obj = {
                    id: e._id,
                    title: e.title,
                    status_delete: e.status_delete,
                    show_driver_app: e.show_driver_app,
                }
                arrayobj.push(obj);

            });
            this.setState({
                slaTitles: arrayobj,
                slaLevelsIndex: slaLevelsListKey,
                slaLevelsList: slaLevelsList,
            });
        } catch (error) {
            console.log(error);
        }
    }
    async setObjectslaLevels(slaLevelsList, slaLevelsListKey) {
        this.state.dataslaTitlesList = 1;
        try {
            const { data } = await client.query({
                query: datamasterSlaTitlesListQuery,
                fetchPolicy: 'network-only',
                variables: {
                    params: {
                        datamaster_sla_level: slaLevelsList._id,
                        status_delete: false
                    },
                    sort: this.state.sortlist,
                    showData: 30
                },
            });

            let arrayobj = [];
            data.datamasterSlaTitles.slaTitles.map(e => {
                let obj = {};
                obj = {
                    id: e._id,
                    title: e.title,
                    status_delete: e.status_delete,
                    show_driver_app: e.show_driver_app,
                }
                arrayobj.push(obj);

            });
            this.setState({
                serviceName: { value: slaLevelsList.service_time, label: (slaLevelsList.service_time == 1 ? 'ทุกวัน  ( 7 วัน 24 ชั่วโมง )' : 'จันทร์ - ศุกร์  ( 09:00 - 18:00 น. )') },
                slaLevelsIndex: slaLevelsListKey,
                slaLevelsId: slaLevelsList._id,
                slaTitleslevel: slaLevelsList.level,
                slalevelImpact: slaLevelsList.impact,
                respondTime: slaLevelsList.respond_time.time,
                respondName: { value: slaLevelsList.respond_time.unit, label: slaLevelsList.respond_time.unit },
                investigateTime: slaLevelsList.investigate_time.time,
                investigateName: { value: slaLevelsList.investigate_time.unit, label: slaLevelsList.investigate_time.unit },
                resolutionTime: slaLevelsList.resolution_time.time,
                resolutionName: { value: slaLevelsList.resolution_time.unit, label: slaLevelsList.resolution_time.unit },
                slaLevelsList: slaLevelsList,
            })

            this.setState({
                slaTitles: arrayobj,
            });
        } catch (error) {
            console.log(error);
        }
    }
    renderVariablesdelSlaLevel() {
        let obj = {
            _id: this.state.slaLevelsId,
            datamasterSlaLevelUpdate: {
                datamasterSlaTitle: [{
                    _id: this.state.delSlaLevelTitlesId,
                    status_delete: true,
                }]
            }
        };
        return obj;
    }

    renderVariablesSlaLevelList() {
        let arrayObj = [];
        let obj = {
            _id: this.state.slaLevelsId,
            datamasterSlaLevelUpdate: {
                service_time: this.state.serviceName.value,
                respond_time: {
                    time: this.state.respondTime,
                    unit: this.state.respondName.value
                },
                investigate_time: {
                    time: this.state.investigateTime,
                    unit: this.state.investigateName.value
                },
                resolution_time: {
                    time: this.state.resolutionTime,
                    unit: this.state.resolutionName.value
                },
                impact: this.state.slalevelImpact
            }
        };
        if (this.state.slaTitles.length > 0) {
            obj.datamasterSlaLevelUpdate['datamasterSlaTitle'] = [];
            this.state.slaTitles.map((slaTitlesList, slaTitlesKey) => {
                if (slaTitlesList.title) {
                    let get = {
                        title: slaTitlesList.title,
                        status_delete: slaTitlesList.status_delete,
                        show_driver_app: slaTitlesList.show_driver_app,

                    };
                    if (slaTitlesList.id) {
                        get._id = slaTitlesList.id;
                    }
                    arrayObj.push(get);
                }
            })
            obj.datamasterSlaLevelUpdate.datamasterSlaTitle = arrayObj;
        }
        // return false;
        return obj;
    }

    handleAddslaTitlesList() {
        let obj = {
            id: '',
            title: '',
            status_delete: false,
            show_driver_app: false,
        };
        this.state.slaTitles.push(obj);
        this.setState({
            slaTitles: this.state.slaTitles,
        });
    }
    handleDeleteslaTitlesList(obj, index) {
        if (obj.id) {
            this.setState({
                show_delSlaLevel: true,
                delSlaLevelTitles: obj.title,
                delSlaLevelTitlesId: obj.id,
                delSlaLevelTitlesIndex: index,
            });
        } else {
            this.state.slaTitles.splice(index, 1);
            this.setState({
                slaTitles: this.state.slaTitles,
            });
        }
    }
    setInputslaTitlesList(event, index) {
        const value = event.target.value;
        this.state.slaTitles[index].title = value;
        this.setState({
            slaTitles: this.state.slaTitles,
        });
    }

    selectOptionServiceTime() {
        let options = [
            { value: '1', label: 'ทุกวัน  ( 7 วัน 24 ชั่วโมง )' },
            { value: '2', label: 'จันทร์ - ศุกร์  ( 09:00 - 18:00 น. )' }
        ]
        return options;
    }
    selectOptionMinsHoursDate() {
        let options = [
            { value: 'นาที', label: 'นาที' },
            { value: 'ชั่วโมง', label: 'ชั่วโมง' },
            { value: 'วัน', label: 'วัน' }
        ]
        return options;
    }

    render() {
        if (!this.props.adminListQuery.loading && !this.props.adminListQuery.error) {
            adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
        }
        return (
            <Container>
                <NotificationSystem ref={this.notificationSystem} style={styleNotification} />

                <div className="content">
                    <NotificationSystem ref={this.notificationSystem} style={styleNotification} />

                    <HeaderContainer>
                        <div className="sub-header-left">
                            <a href="/giztixbusiness" className="btn-back"><i className="far fa-chevron-left"></i> กลับ</a>
                            <div className="line"></div>
                            <div className="menu-wrapper">
                                <ul>
                                    <li>
                                        <a href={`/settingdatamaster`}> <i className="fal fa-truck"></i> Giztix Trucks</a>
                                    </li>
                                    <li>
                                        <a href={`/settingdatamaster-holidays`}> <i class="fal fa-calendar-day"></i> วันหยุุดบริษัท</a>
                                    </li>
                                    <li className="active">
                                        <a href={`/settingdatamaster-condition`}> <i class="far fa-ballot-check"></i> เงื่อนไขปัญหา</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </HeaderContainer>
                    <Query query={datamasterSlaLevelsListQuery} variables={{ sort: this.state.sort, showData: 30 }} >
                        {
                            ({ loading, error, data, refetch: refetchSlaLevelsList, variables }) => {
                                if (loading) return (<Grid fluid>loading...</Grid>);
                                if (error) return null;
                                return (
                                    <div>

                                        <div>
                                            <SubHeaderContainer>
                                                <div className="sub-header-left">
                                                    <strong>Service Level Agreement Condition</strong>
                                                </div>
                                            </SubHeaderContainer>
                                            {/* Content table */}
                                            <Grid fluid>
                                                <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                                                    <Col md={12}>
                                                        <CardContainer>
                                                            <ColLeft>
                                                                <TextHeader>SLA LEVEL</TextHeader>
                                                                <div>
                                                                    <UlTruck>
                                                                        {
                                                                            data.datamasterSlaLevels.slaLevels.map((slaLevelsList, slaLevelsListKey) => {
                                                                                if (this.state.dataslaTitlesList === 0) {
                                                                                    if (slaLevelsListKey === 0) {
                                                                                        this.state.serviceTime = slaLevelsList.service_time;
                                                                                        this.state.serviceName = { value: slaLevelsList.service_time, label: (slaLevelsList.service_time == 1 ? 'ทุกวัน  ( 7 วัน 24 ชั่วโมง )' : 'จันทร์ - ศุกร์  ( 09:00 - 18:00 น. )') };
                                                                                        this.state.slaLevelsId = slaLevelsList._id;
                                                                                        this.state.slaTitleslevel = slaLevelsList.level;
                                                                                        this.state.slalevelImpact = slaLevelsList.impact;
                                                                                        this.state.respondTime = slaLevelsList.respond_time.time;
                                                                                        this.state.respondName = { value: slaLevelsList.respond_time.unit, label: slaLevelsList.respond_time.unit };
                                                                                        this.state.investigateTime = slaLevelsList.investigate_time.time;
                                                                                        this.state.investigateName = { value: slaLevelsList.investigate_time.unit, label: slaLevelsList.investigate_time.unit };
                                                                                        this.state.resolutionTime = slaLevelsList.resolution_time.time;
                                                                                        this.state.resolutionName = { value: slaLevelsList.resolution_time.unit, label: slaLevelsList.resolution_time.unit };
                                                                                        this.state.dataslaTitlesList = 1;
                                                                                        this.state.slaLevelsList = slaLevelsList;
                                                                                        this.setObjectslaLevels(slaLevelsList, slaLevelsListKey)
                                                                                    }
                                                                                }

                                                                                return (
                                                                                    <li key={slaLevelsListKey}
                                                                                        className={slaLevelsListKey == this.state.slaLevelsIndex ? 'active' : ''}
                                                                                        onClick={() => this.setObjectslaLevels(slaLevelsList, slaLevelsListKey)}
                                                                                    >
                                                                                        {`LEVEL ${slaLevelsList.level}`}
                                                                                        {slaLevelsListKey == this.state.slaLevelsIndex && (<i className="fal fa-chevron-right"></i>)}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </UlTruck>
                                                                </div>
                                                            </ColLeft>
                                                            <ColRight>
                                                                <SubHeaderContainer>
                                                                    <div className="sub-header-left">
                                                                        <TextHeader>{`รายละเอียดการทำงานของ Driver Case  Lv.${this.state.slaTitleslevel}`}</TextHeader>
                                                                    </div>
                                                                    <div className="sub-header-right">
                                                                        <Mutation
                                                                            mutation={datamasterSlaLevelUpdateMutation}
                                                                            variables={this.renderVariablesSlaLevelList()}
                                                                            update={(cache, data) => {
                                                                                {
                                                                                    data &&
                                                                                        refetchSlaLevelsList();
                                                                                    this.setState({
                                                                                        show_complete: true,
                                                                                    });

                                                                                }
                                                                            }}>
                                                                            {postMutation => <button className="btn btn-green" onClick={postMutation}>บันทึก</button>}
                                                                        </Mutation>
                                                                    </div>
                                                                </SubHeaderContainer>
                                                                <div style={{ padding: "15px 15px" }}>
                                                                    <CardContainer>
                                                                        <ColLeftContainer>
                                                                            <UICondition>
                                                                                <Row style={{ marginBottom: "10px" }}>
                                                                                    <Col md={6} style={{ marginBottom: "10px" }}> Service Time</Col>
                                                                                    <Col md={8}>
                                                                                        {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                            <Select
                                                                                                value={this.state.serviceName}
                                                                                                onChange={event => this.handleChangeServiceTime(event)}
                                                                                                options={this.selectOptionServiceTime()}
                                                                                                placeholder="Service Time"
                                                                                            />
                                                                                        ) : (
                                                                                                <div style={{ marginTop: '10px' }}>{this.state.serviceName.label}</div>
                                                                                            )}
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row style={{ marginBottom: "10px" }}>
                                                                                    <Col md={4}>
                                                                                        <div>Respond Time </div>
                                                                                        <Row>
                                                                                            <Col md={4} style={{ paddingRight: '0px' }}>
                                                                                                {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                                    <FormControl
                                                                                                        type="number"
                                                                                                        value={this.state.respondTime}
                                                                                                        placeholder="0"
                                                                                                        min="0"
                                                                                                        onChange={event =>
                                                                                                            this.setState({
                                                                                                                respondTime: event.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                        <div style={{ marginTop: '10px' }}>{this.state.respondTime}</div>
                                                                                                    )}
                                                                                            </Col>
                                                                                            <Col md={8}>
                                                                                                {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                                    <Select
                                                                                                        value={this.state.respondName}
                                                                                                        defaultValue={this.state.respondName}
                                                                                                        onChange={event => this.handleChangeRespond(event)}
                                                                                                        options={this.selectOptionMinsHoursDate()}
                                                                                                        placeholder="ระบุ"
                                                                                                    />
                                                                                                ) : (
                                                                                                        <div style={{ marginTop: '10px' }}>{this.state.respondName.label}</div>
                                                                                                    )}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <div>Investigate Time </div>
                                                                                        <Row>
                                                                                            <Col md={4} style={{ paddingRight: '0px' }}>
                                                                                                {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                                    <FormControl
                                                                                                        type="number"
                                                                                                        min="0"
                                                                                                        value={this.state.investigateTime}
                                                                                                        onChange={event =>
                                                                                                            this.setState({
                                                                                                                investigateTime: event.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                        placeholder="0"
                                                                                                    />
                                                                                                ) : (
                                                                                                        <div style={{ marginTop: '10px' }}>{this.state.investigateTime}</div>
                                                                                                    )}
                                                                                            </Col>
                                                                                            <Col md={8}>
                                                                                                {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                                    <Select
                                                                                                        value={this.state.investigateName}
                                                                                                        defaultValue={this.state.investigateName}
                                                                                                        onChange={event => this.handleChangeInvestigate(event)}
                                                                                                        options={this.selectOptionMinsHoursDate()}
                                                                                                        placeholder="ระบุ"
                                                                                                    />
                                                                                                ) : (
                                                                                                        <div style={{ marginTop: '10px' }}>{this.state.investigateName.label}</div>
                                                                                                    )}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md={4}>
                                                                                        <div>Resolution Time</div>
                                                                                        <Row>
                                                                                            <Col md={4} style={{ paddingRight: '0px' }}>
                                                                                                {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                                    <FormControl
                                                                                                        type="number"
                                                                                                        min="0"
                                                                                                        value={this.state.resolutionTime}
                                                                                                        onChange={e =>
                                                                                                            this.setState({
                                                                                                                resolutionTime: e.target.value,
                                                                                                            })
                                                                                                        }
                                                                                                        placeholder="0"
                                                                                                    />

                                                                                                ) : (
                                                                                                        <div style={{ marginTop: '10px' }}>{this.state.resolutionTime}</div>
                                                                                                    )}
                                                                                            </Col>
                                                                                            <Col md={8}>
                                                                                                {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                                    <Select
                                                                                                        value={this.state.resolutionName}
                                                                                                        defaultValue={this.state.resolutionName}
                                                                                                        onChange={event => this.handleChangeResolution(event)}
                                                                                                        options={this.selectOptionMinsHoursDate()}
                                                                                                        placeholder="ระบุ"
                                                                                                    />

                                                                                                ) : (
                                                                                                        <div style={{ marginTop: '10px' }}>{this.state.resolutionName.label}</div>
                                                                                                    )}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <hr></hr>
                                                                                <Row>
                                                                                    <Col md={12} style={{ marginBottom: "10px" }}>Impact</Col>
                                                                                    <Col md={12}>
                                                                                        {adminName == 'fonne' || adminName == 'sarawut@giztix.com' || adminName == 'thames' || adminName == 'soranut@giztix.com' ? (
                                                                                            <FormControl
                                                                                                as="textarea"
                                                                                                aria-label="With textarea"
                                                                                                placeholder="Impact"
                                                                                                onChange={e =>
                                                                                                    this.setState({
                                                                                                        slalevelImpact: e.target.value,
                                                                                                    })
                                                                                                }
                                                                                                value={this.state.slalevelImpact == null ? '' : this.state.slalevelImpact}
                                                                                            />

                                                                                        ) : (
                                                                                                <div style={{ marginTop: '10px' }}>{this.state.slalevelImpact == null ? '-' : this.state.slalevelImpact}</div>
                                                                                            )}

                                                                                    </Col>
                                                                                </Row>
                                                                            </UICondition>
                                                                        </ColLeftContainer>
                                                                        <ColRightContainer>

                                                                            <div>
                                                                                <Table style={{ marginBottom: '0px' }}>
                                                                                    <thead>
                                                                                        <tr style={{ height: '50px', backgroundColor: '#ddd' }}>
                                                                                            <td style={{ fontWeight: 700, width: '70%' }}>หัวข้อปัญหา</td>
                                                                                            <td colSpan={2} style={{ fontWeight: 700, width: '30%' }}>แสดงใน Driver App</td>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    {
                                                                                        this.state.slaTitles.length == 0 ? (
                                                                                            <tbody>
                                                                                                <tr >
                                                                                                    <td> ไม่มีรายการหัวข้อปัญหา</td>
                                                                                                    <td colSpan={2}></td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        ) : (
                                                                                                <tbody>
                                                                                                    {
                                                                                                        this.state.slaTitles.map((slaTitlesList, slaTitlesListKey) => {
                                                                                                            return (
                                                                                                                <tr key={slaTitlesListKey}>
                                                                                                                    <td>
                                                                                                                        <FormControl
                                                                                                                            type="text"
                                                                                                                            placeholder="กรอกข้อมูล"
                                                                                                                            value={slaTitlesList.title}
                                                                                                                            onChange={event =>
                                                                                                                                this.setInputslaTitlesList(event, slaTitlesListKey)
                                                                                                                            }
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                                                        <CustomCheckbox value="" checked={slaTitlesList.show_driver_app === true ? 'checked' : ''} onClick={(event) => this.handleSelectCheckmark(slaTitlesList.show_driver_app, slaTitlesListKey)}>
                                                                                                                            <span className="checkmark"></span>
                                                                                                                        </CustomCheckbox>
                                                                                                                    </td>
                                                                                                                    <td style={{ textAlign: 'center' }}>
                                                                                                                        <a onClick={() => this.handleDeleteslaTitlesList(slaTitlesList, slaTitlesListKey)} style={{ cursor: 'pointer' }}><i class="far fa-trash-alt" style={{ color: 'red' }}></i></a>
                                                                                                                    </td>
                                                                                                                </tr>

                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            )
                                                                                    }
                                                                                </Table>
                                                                            </div>

                                                                            <Btnaddlist>
                                                                                <button className="btn btn-default" onClick={() => this.handleAddslaTitlesList()} style={{ margin: '10px' }} >+ เพิ่มรายการ</button>
                                                                            </Btnaddlist>
                                                                        </ColRightContainer>
                                                                    </CardContainer>
                                                                </div>
                                                            </ColRight>
                                                        </CardContainer>
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </div>

                                        <Mutation
                                            mutation={datamasterSlaLevelUpdateMutation}
                                            variables={this.renderVariablesdelSlaLevel()}
                                            update={() => {
                                                this.state.slaTitles.splice(this.state.delSlaLevelTitlesIndex, 1);
                                                this.setState({
                                                    show_delSlaLevel: false,
                                                    delSlaLevelTitles: '',
                                                    delSlaLevelTitlesId: '',
                                                    slaTitles: this.state.slaTitles,
                                                    delSlaLevelTitlesIndex: '',
                                                });
                                            }}
                                            onError={error => {
                                                alert(error);
                                            }}
                                        >
                                            {datamasterSlaLevelUpdate => (
                                                <Modal
                                                    show={this.state.show_delSlaLevel}
                                                    onHide={() => this.setState({
                                                        show_delSlaLevel: false,
                                                        delSlaLevelTitles: '',
                                                        delSlaLevelTitlesId: '',
                                                        delSlaLevelTitlesIndex: '',
                                                    })}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title> ลบหัวข้อปัญหานี้ใช่หรือไม่ </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body style={{ padding: "30px 15px" }} >
                                                        คุณต้องการที่จะลบหัวข้อปัญหา <b>"{this.state.delSlaLevelTitles}"</b>  นี้ใช่หรือไม่ ? <br />
													กด <b>"ลบ"</b> เพื่อลบหัวข้อปัญหา
												</Modal.Body>
                                                    <Modal.Footer>
                                                        <Button
                                                            style={{ float: "left" }}
                                                            onClick={() => this.setState({
                                                                show_delSlaLevel: false,
                                                                delSlaLevelTitles: '',
                                                                delSlaLevelTitlesId: '',
                                                                delSlaLevelTitlesIndex: '',
                                                            })}>ปิด</Button>
                                                        <Button bsStyle="primary" onClick={() => datamasterSlaLevelUpdate()}>ลบ</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            )}
                                        </Mutation>

                                        <ModalConfirm
                                            show={this.state.show_complete}
                                            onPressButtonCancel={() => {
                                                this.setObjectslaLevelssub(this.state.slaLevelsList, this.state.slaLevelsIndex)
                                                this.setState({
                                                    show_complete: false,
                                                });


                                            }}
                                            labelButtonCancel={`ปิด`}
                                            styleColorButtonConfirm="green"
                                            titleHeader={`บันทึกรายการServiceเสร็จสิ้น`}
                                            onHide={() => {
                                                this.setObjectslaLevelssub(this.state.slaLevelsList, this.state.slaLevelsIndex)
                                                this.setState({
                                                    show_complete: false,
                                                });
                                            }}
                                        >
                                            <div>บันทึกรายการService Level {this.state.slaTitleslevel} เสร็จสิ้น</div>
                                        </ModalConfirm>
                                    </div>
                                )
                            }
                        }
                    </Query>

                </div>

            </Container >

        );
    }
}

export default compose(
    graphql(adminListQuery, {
        name: 'adminListQuery'
    }),
)(DataMasterGiztixCondition);