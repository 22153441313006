import * as React from 'react';
import { Table, Button } from 'react-bootstrap';

import {
  TEXT_ALL,
  TEXT_BUTTON_DOWNLOAD,
  TEXT_COMPLETE,
  TEXT_ERROR,
  TEXT_ERROR_DETAIL,
  TEXT_LOADING,
  TEXT_PLACEHOLDER_SEARCH_CREATE_BY,
  TEXT_PLACEHOLDER_SEARCH_STATUS,
  TEXT_PROCESS,
  TEXT_REPORT_DATE,
  TEXT_REPORT_DETAIL,
  TEXT_REPORT_NAME,
  TEXT_TOOLS,
  TEXT_UPLOADTER,
  TITLE_COMMON,
  TITLE_JDBU,
  TITLE_JDSM,
  TITLE_LAZADA,
} from '../utils/useConstants';
import { Query } from 'react-apollo';
import { querySearch } from '../utils/useGraphql';
import { formatDateTime } from 'utils/dateTime';
import { COLOR_BLACK, COLOR_GREEN, COLOR_RED, COLOR_WHITE, COLOR_YELLOW } from '../utils/useColor';
import { FunctionOpenInNewTab } from '../utils/useFunctions';
import { Select } from 'views/GiztixBusiness/components';
import ReactPaginate from 'react-paginate';
import s3 from 'config/s3';

export const RenderHeader = () => {
  return (
    <thead>
      <tr>
        <th style={{ minWidth: '100px' }}>{TEXT_TOOLS}</th>
        <th style={{ width: '250px' }}>{TEXT_REPORT_NAME}</th>
        <th>{TEXT_UPLOADTER}</th>
        <th>{TEXT_REPORT_DATE}</th>
        <th>{TEXT_ERROR_DETAIL}</th>
      </tr>
    </thead>
  );
};

export const RednerHeaderSearch = ({
  status,
  setStatus,
  reportName,
  setReportName,
  createBy,
  setCreateBy,
}) => {
  return (
    <tr>
      <th style={{ minWidth: '100px' }}>
        <Select
          style={{
            width: '100%',
          }}
          value={status}
          isSae
          onChange={e => {
            setStatus(e.target.value);
          }}
        >
          <option value="">{TEXT_ALL}</option>
          <option value="complete">{TEXT_COMPLETE}</option>
          <option value="process">{TEXT_PROCESS}</option>
          <option value="error">{TEXT_ERROR}</option>
        </Select>
      </th>
      <th style={{ width: '250px' }}>
        <Select
          style={{
            width: '100%',
          }}
          value={reportName}
          isSae
          onChange={e => {
            setReportName(e.target.value);
          }}
        >
          <option value="">{TEXT_ALL}</option>
          <option value={TITLE_JDBU}>{TITLE_JDBU}</option>
          <option value={TITLE_JDSM}>{TITLE_JDSM}</option>
          <option value={TITLE_LAZADA}>{TITLE_LAZADA}</option>
          <option value={TITLE_COMMON}>{TITLE_COMMON}</option>
        </Select>
      </th>
      <th>
        <input
          style={{
            height: '40px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            padding: '0 10px',
          }}
          type={'text'}
          placeholder={TEXT_PLACEHOLDER_SEARCH_CREATE_BY}
          value={createBy}
          onChange={e => {
            setCreateBy(e.target.value);
          }}
        />
      </th>
      <th>
        {/* <DateRangePicker
          onApply={(event, picker) =>
            this.handlePickupDateRangePicker(event, picker, refetchContactList)
          }
          onCancel={() =>
            this.setState({ create_date: '', show_create_date: '' }, () => {
              refetchContactList();
            })
          }
          containerStyles={{ display: 'block' }}
          opens="right"
          format="DD/MM/YYYY"
        >
          <FormControl
            type="text"
            value={this.state.show_create_date}
            placeholder="ค้นหาสร้าง"
            name="create_date"
          />
        </DateRangePicker> */}
      </th>
      <th />
    </tr>
  );
};

export const RenderTableBody = ({ status, pagenumber, reportName, createBy, setCount }) => {
  // const hashDownload =
  //   process.env.NODE_ENV === 'development'
  //     ? 'https://s3-ap-southeast-1.amazonaws.com/media.kaikin.co/'
  //     : 'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/';

  return (
    <React.Fragment>
      <Query
        query={querySearch}
        variables={{
          create_by: createBy,
          page_number: pagenumber,
          status: status,
          report_name: reportName,
          // createDate,
          // orderBy,
          // orderType,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return new Array(15).fill(0).map((_, index) => {
              return (
                <tr key={`key-table-loading-${index}`}>
                  <td style={{ width: '100px' }}>{TEXT_LOADING}</td>
                  <td>{TEXT_LOADING}</td>
                  <td>{TEXT_LOADING} </td>
                  <td>{TEXT_LOADING} </td>
                </tr>
              );
            });
          if (error)
            return new Array(15).fill(0).map((_, index) => {
              return (
                <tr key={`key-table-error-${index}`}>
                  <td style={{ width: '100px' }}>{TEXT_ERROR}</td>
                  <td>{TEXT_ERROR}</td>
                  <td>{TEXT_ERROR} </td>
                  <td>{TEXT_ERROR} </td>
                </tr>
              );
            });

          if (data.reportCostRateCardResultList.total_page > 0) {
            setCount(data.reportCostRateCardResultList.total_page);
          }

          return (
            <React.Fragment>
              {data?.reportCostRateCardResultList?.report?.map((row, index) => {
                return (
                  <tr key={`key-table-${index}`}>
                    <td style={{ width: '100px' }}>
                      <Button
                        style={{
                          backgroundColor:
                            row?.status === 'complete'
                              ? ''
                              : row?.status === 'error'
                              ? COLOR_RED
                              : COLOR_YELLOW,
                          color: row?.status === 'complete' ? COLOR_BLACK : COLOR_WHITE,
                        }}
                        onClick={() => {
                          if (row?.status === 'complete') {
                            FunctionOpenInNewTab(`${s3}/${row?.file_result}`);
                          }
                        }}
                      >
                        {row?.status === 'complete' ? TEXT_BUTTON_DOWNLOAD : row?.status}
                      </Button>
                    </td>
                    <td>
                      <b>{row?.report_name}</b>
                    </td>
                    <td>{row?.create_by} </td>
                    <td>{formatDateTime(row?.create_date)} </td>
                    {row?.error_result ? <td>{row?.error_result} </td> : <td />}
                  </tr>
                );
              })}
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
};

export const ReportDownload = () => {
  const [createBy, setCreateBy] = React.useState('');
  // const [createDate, setCreateDate] = React.useState('');
  // const [orderBy, setOrderBy] = React.useState('');
  // const [orderType, setOrderType] = React.useState(-1);
  const [pagenumber, setPagenumber] = React.useState(1);
  const [status, setStatus] = React.useState('');
  const [reportName, setReportName] = React.useState('');
  const [count, setCount] = React.useState(0);

  return (
    <React.Fragment>
      <Table
        style={{
          width: '100%',
        }}
        striped
        hover
      >
        <RenderHeader />
        <tbody>
          <RednerHeaderSearch
            createBy={createBy}
            setCreateBy={setCreateBy}
            status={status}
            setStatus={setStatus}
            reportName={reportName}
            setReportName={setReportName}
          />
          <RenderTableBody
            createBy={createBy}
            pagenumber={pagenumber}
            reportName={reportName}
            status={status}
            setCount={setCount}
          />
        </tbody>
      </Table>
      <div
        style={{
          margin: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ReactPaginate
          pageCount={count}
          forcePage={pagenumber - 1}
          containerClassName="pagination"
          nextLabel=">"
          previousLabel="<"
          onPageChange={({ selected }) => {
            setPagenumber(selected + 1);
          }}
        />
      </div>
    </React.Fragment>
  );
};
