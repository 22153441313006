import gql from 'graphql-tag';

export default gql`
    mutation accountUserUpdate(
        $account_user_status: Int
        $_id: ID
        $account_info_id: ID
    )
    {
        accountUserUpdate(
            _id: $_id
            account_info_id: $account_info_id
            account_user_status: $account_user_status
        )
        {
            _id
        }
    }
`;
