import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Input } from './../../../../components';
import { Query, Mutation } from 'react-apollo';
import { connect } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import DocumentList from './DocumentList';

// utils
// import formatCurrency from './../../../../../../utils/formatCurrency';

// graphql
import costingDocumentsQuery from './../../../../graphql/query/costingDocuments';
import singleUpload from './../../../../graphql/mutation/singleUpload';
import createCostingDocument from './../../../../graphql/mutation/createCostingDocument';

import s3Bucket from 'config/s3Bucket';
import s3 from 'config/s3';

const ContainerStyled = styled.div`
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;
  padding-bottom: 20px;

  & .subTitle {
    color: #aaaaaa;
  }

  & .filepond--root {
    font-size: 16px !important;
  }

  & .filepond--root {
    height: 76px !important;
  }
`;

const DocumentContainerStyled = styled.div`
  padding: 15px;
`;

const FileContainer = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

class CostingDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
    };
  }

  render() {
    return (
      <ContainerStyled>
        <Row>
          <Col md={12}>
            <div style={{ marginTop: 10 }}>
              <Query
                query={costingDocumentsQuery}
                variables={{
                  costing_id: this.props.costingId,
                  delete: false
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  if (loading) return 'Loading...';
                  if (error) return { error };

                  console.log(data.costingDocuments.documents);

                  return (
                    <DocumentContainerStyled>
                      {data.costingDocuments.documents.length === 0 ? (
                        <div>ไม่มีข้อมูล</div>
                      ) : (
                          <div>
                            <Row>
                              <Col>
                                {data.costingDocuments.documents.map((item, index) => {
                                  return (
                                    <FileContainer>
                                      <a
                                        href={item.document_file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {item.document_file}
                                      </a>
                                    </FileContainer>
                                  );
                                })}
                              </Col>
                            </Row>
                          </div>
                        )}
                    </DocumentContainerStyled>
                  );
                }}
              </Query>
            </div>
          </Col>
        </Row>
      </ContainerStyled>
    );
  }
}

export default CostingDocument;
