import React from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import classes from './LightBoxPreview.module.scss';
import { PDFPreview } from './components';
import mimeTypes from 'mime-types';

class LightBoxPreview extends React.Component {
  state = {
    isOpenLightBox: false,
    indexImage: 0,
  };

  getFileType = path => {
    const mime = mimeTypes.lookup(path);
    return {
      ext: mimeTypes.extension(mime),
      mime: mime,
    };
  };

  renderThumbnail = path => {
    const fileType = this.getFileType(path);
    const { ext, mime } = fileType;

    if (ext === 'pdf') {
      return require('./images/file-type-pdf.svg');
    } else if (mime.indexOf('image') > -1) {
      return path;
    }

    return require('./images/file-type-file.svg');
  };

  setPreviewFromFileType = path => {
    const fileType = this.getFileType(path);
    const { ext, mime } = fileType;

    if (ext === 'pdf') {
      return <PDFPreview file={path} />;
    } else if (mime.indexOf('image') > -1) {
      return path;
    }

    return require('./images/file-type-file.svg');
  };

  getLabelButton = path => {
    const fileType = this.getFileType(path);
    const { ext, mime } = fileType;

    if (ext === 'pdf') {
      return 'ดูเอกสาร';
    } else if (mime.indexOf('image') > -1) {
      return 'ดูรูปภาพ';
    }

    return 'ดาวน์โหลด';
  };

  viewFromFileType = (path, index) => {
    const fileType = this.getFileType(path);
    const { ext, mime } = fileType;
    const isFilePreview = ext === 'pdf' || mime.indexOf('image') > -1;

    return (
      <button
        className={classes.btn}
        onClick={() => {
          if (isFilePreview) {
            this.setState({
              indexImage: index,
              isOpenLightBox: true,
            });
          } else {
            window.open(path, '_blank');
          }
        }}
      >
        {this.getLabelButton(path)}
      </button>
    );
  };

  render() {
    const imagePosition = this.props.position ? this.props.position : null;
    const images = this.props.images
      ? this.props.images.map(item => {
          return {
            path: item,
            thumbnail: this.renderThumbnail(item),
            preview: this.setPreviewFromFileType(item),
          };
        })
      : [];

    const imageList = images.map((item, index) => (
      <div className={classes.thumbnail}>
        <img src={item.thumbnail} alt="lightboxImage" />
        <div className={classes.hoverImage}>
          {this.viewFromFileType(item.path, index)} 
          {
            imagePosition && (
              <button
                className={classes.btn}
                onClick={() => { window.open(`https://www.google.com/maps/search/?api=1&query=${imagePosition}`, '_blank'); }}
              >
                ดูตำแหน่ง
              </button>
            )
          }
          
        </div>
      </div>
    ));

    let lightBox = null;
    if (this.state.isOpenLightBox) {
      lightBox = (
        <Lightbox
          mainSrc={images[this.state.indexImage].preview}
          nextSrc={
            images.length > 1 ? images[(this.state.indexImage + 1) % images.length] : undefined
          }
          prevSrc={
            images.length > 1
              ? images[(this.state.indexImage + images.length - 1) % images.length]
              : undefined
          }
          onCloseRequest={() => this.setState({ isOpenLightBox: false })}
          onMovePrevRequest={() =>
            this.setState(prev => ({
              indexImage: (prev.indexImage + images.length - 1) % images.length,
            }))
          }
          onMoveNextRequest={() =>
            this.setState(prev => ({
              indexImage: (prev.indexImage + 1) % images.length,
            }))
          }
        />
      );
    }

    return (
      <React.Fragment>
        <div className={classes.wrapper}>{imageList}</div>
        {lightBox}
      </React.Fragment>
    );
  }
}

export default LightBoxPreview;
