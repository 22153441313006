import React, { Component } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import { Query , Mutation , compose , graphql } from "react-apollo";
import ReactPaginate from 'react-paginate';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { Grid, Row, Col, Table, Tooltip, Label, Button, Modal, FormControl,FormGroup } from "react-bootstrap";
import styled from 'styled-components';

import CardTable from "components/CardTable/CardTable.jsx";
import CustomButton from "components/CustomButton/CustomButton.jsx";

import contactList from "./graphql/query/contactList";
import adminNameQuery from "./graphql/query/adminName";
import contactusUpdateMutation from "./graphql/mutation/contactusUpdate"

const tooltip = (
    <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>
);
const tooltip1 = (
    <Tooltip id="tooltip">แก้ไข</Tooltip>
);

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;

class ContactList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total_page: 0,
            page_number: 1,
            order_by: null,
            order_type: null,
            _id: "",
            search_name: "",
            search_email: "",
            create_date : "",
            show_create_date : "",
            show_assign:false,
            _id : null,
            contactData : null,
            show_detail:false,
            remark: ""
        }
    }

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }

    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    nextPage(page, total_page, refetch) {
        // console.log(page+1);
        this.setState({ page_number: page + 1, total_page });
        // console.log(this.state);
        refetch();
    }

    handlePickupDateRangePicker(event, picker,refetch) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState({ create_date: date , show_create_date: show},() => { refetch() });
    }

    handleDateRangePicker(event, picker,refetch) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState({ create_date: date , show_create_date: show},() => { refetch() });
    }

    renderStatus(status){
        switch (status) {
            case 2:
                return <Label bsStyle="warning">ยังไม่ปิดงาน</Label>;
                break;
            case 3:
                return <Label bsStyle="success">ปิดงานแล้ว</Label>;
                break;
            default:
                return <Label bsStyle="danger">ยังไม่มีผู้ดูแล</Label>;
                break;
        }
    }

    handleShowAssignAdmin(_id,contactData){
        this.setState({ 
            show_assign: true , 
            _id,
            contactData
        });
    }

    handleShowDetail(contactData){
        this.setState({ 
            show_detail: true , 
            contactData,
            remark : contactData.remark
        });
    }

    submit(contactusUpdate){
        contactusUpdate(
            { variables: {
                _id : this.state.contactData._id,
                remark: this.state.remark
            }}
        );
    }

    closeJob(contactusUpdate){
        contactusUpdate(
            { variables: {
                _id : this.state.contactData._id,
                status: 3
            }}
        );
    }

    render() {
        // console.log(this.state);
        let admin_username = "";
        let admin_id = "";
        if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
            admin_username = this.props.adminName.adminList.admin[0].admin_username;
            admin_id = this.props.adminName.adminList.admin[0]._id;
        }

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Query
                                query={contactList}
                                variables={{
                                    page_number: this.state.page_number,
                                    order_by: this.state.order_by,
                                    order_type: this.state.order_type,
                                    name: this.state.search_name,
                                    email: this.state.search_email,
                                    create_date: this.state.create_date
                                }}
                            >
                                {
                                    ({ loading, error, data, refetch : refetchContactList, variables }) => {
                                        {/*console.log(variables);*/ }
                                        var itemLength = 'loading';
                                        const itemIndicatorSize = (this.state.page_number - 1)*30;
                                        if(data.contactList){
                                            if(data.contactList.contactus.length > 0){
                                                itemLength = data.contactList.contactus.length;
                                            }
                                        }
                                        return (
                                            <div>
                                                <CardTable
                                                    title="ตารางรายการติดต่อเรา"
                                                    category="ข้อมูลรายการติดต่อเรา"
                                                    ctTableFullWidth
                                                    ctTableResponsive
                                                    content={
                                                        <div>
                                                            <PageWrapper style={{ textAlign: "right" }}>
                                                                <div className="text">{this.state.page_number === 1 ? `1 - ${itemLength}` : `${itemIndicatorSize + 1} - ${itemLength + itemIndicatorSize}`} หน้า :</div>
                                                                    <FormControl
                                                                        type="number"
                                                                        min={1}
                                                                        value={this.state.page_number}
                                                                        onChange={(event) => 
                                                                        setInterval(this.nextPage(Number(event.target.value) -1, loading || error ? '0' : data.contactList.total_page,refetchContactList), 1000)
                                                                        }
                                                                        name="page_number"
                                                                    />
                                                                <div className="text"> {loading || error ? '' : `จาก ${data.contactList.total_page}`}</div>
                                                            </PageWrapper>
                                                            <div className="table-vertical">
                                                                <Table striped hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            <th>ผู้ดูแล</th>
                                                                            <th>สถานะ</th>
                                                                            <th style={{width:"150px"}} className={this.renderSorting("name")} onClick={() => this.sort("name", refetchContactList)}>Name</th>
                                                                            <th className={this.renderSorting("email")} onClick={() => this.sort("email", refetchContactList)}>Email</th>
                                                                            <th className={this.renderSorting("phone")} onClick={() => this.sort("phone", refetchContactList)}>Phone</th>
                                                                            <th>Message</th>
                                                                            <th className={this.renderSorting("create_date")} onClick={() => this.sort("create_date", refetchContactList)}>Create date</th>
                                                                        </tr>
                                                                        <tr className="tableFilter">
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th></th>
                                                                            <th>
                                                                                <FormControl
                                                                                    type="text"
                                                                                    value={this.state.search_name}
                                                                                    placeholder="ค้นหา"
                                                                                    onChange={(event) => this.setState({ search_name: event.target.value }, () => { refetchContactList() })}
                                                                                    name="search_name"
                                                                                />
                                                                            </th>
                                                                            <th>
                                                                                <FormControl
                                                                                    type="text"
                                                                                    value={this.state.search_email}
                                                                                    placeholder="ค้นหา"
                                                                                    onChange={(event) => this.setState({ search_email: event.target.value }, () => { refetchContactList() })}
                                                                                    name="search_email"
                                                                                />
                                                                            </th>
                                                                            <th>
                                                                            </th>
                                                                            <th>
                                                                            </th>
                                                                            <th>
                                                                                <DateRangePicker 
                                                                                    onApply={(event,picker) => this.handlePickupDateRangePicker(event,picker,refetchContactList)} 
                                                                                    onCancel={() => this.setState({create_date : "",show_create_date : ""},() => { refetchContactList() })} 
                                                                                    containerStyles={{display: "block"}}
                                                                                    opens='right'
                                                                                    format='DD/MM/YYYY'
                                                                                >
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        value={this.state.show_create_date}
                                                                                        placeholder="ค้นหาสร้าง"
                                                                                        name="create_date"
                                                                                    />
                                                                                </DateRangePicker>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(loading || error) ?
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan="8" className="textCenter">loading...</td>
                                                                                </tr>
                                                                            ) :
                                                                            data.contactList.contactus.map((prop, key) => (
                                                                                <tr key={key}>
                                                                                    <td><Button onClick={() => this.handleShowDetail(prop)}><i className="fal fa-search"></i></Button></td>
                                                                                    <td>{prop.admin_username ? prop.admin_username : (<Button onClick={() => this.handleShowAssignAdmin(prop._id ,prop)}>ดูแลงานนี้</Button>)}</td>
                                                                                    <td>{this.renderStatus(prop.status)}</td>
                                                                                    <td>{prop.name} {prop.lastName}</td>
                                                                                    <td>{prop.email}</td>
                                                                                    <td>{prop.phone}</td>
                                                                                    <td>{prop.msg}</td>
                                                                                    <td><Moment format="DD/MM/YYYY HH:mm:ss">{prop.create_date}</Moment></td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    }
                                                />

                                                <div style={{ textAlign: "right" }}>

                                                    {(loading) ? null :
                                                        (<ReactPaginate pageCount={data.contactList.total_page} forcePage={this.state.page_number - 1} containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected, data.contactList.total_page, refetchContactList); }} />)
                                                    }
                                                </div>

                                                <Mutation
                                                    mutation={contactusUpdateMutation}
                                                    update={() => {
                                                        this.setState({ show_detail: false }, () => { refetchContactList() })
                                                    }}
                                                >
                                                    {contactusUpdate => (
                                                        <Modal show={this.state.show_detail} onHide={() => this.setState({ show_detail: false})}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>ข้อมูลงาน</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                
                                                                ชื่อลูกค้า : <b>{this.state.contactData && `${this.state.contactData.name} ${this.state.contactData.lastName}`}</b> <br/>
                                                                อีเมล : <b>{this.state.contactData && this.state.contactData.email}</b> <br/>
                                                                เบอร์โทรศัพท์ : <b>{this.state.contactData && this.state.contactData.phone}</b> <br/>
                                                                ข้อความ : <b>{this.state.contactData && this.state.contactData.msg}</b> <br/><br/>

                                                                <b>หมายเหตุ</b><br/>
                                                                <FormControl
                                                                    type="text"
                                                                    value={this.state.remark}
                                                                    placeholder="หมายเหตุ"
                                                                    onChange={(event) => this.setState({ remark: event.target.value})}
                                                                    name="search_driver_number"
                                                                />
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                {
                                                                    this.state.contactData && this.state.contactData.status != 3 && (
                                                                        <Button style={{float:"left"}} bsStyle="info" onClick={() => this.closeJob(contactusUpdate)}>ปิดงาน</Button>
                                                                    )
                                                                }
                                                                

                                                                <Button onClick={() => this.setState({ show_detail: false})}>กลับ</Button>
                                                                <Button bsStyle="primary" onClick={() => this.submit(contactusUpdate)}>บันทึกหมายเหตุ</Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    )}
                                                </Mutation>

                                                <Mutation
                                                    mutation={contactusUpdateMutation}
                                                    variables={{
                                                        _id : this.state._id,
                                                        admin_id: admin_id,
                                                        admin_username: admin_username,
                                                        status: 2
                                                    }}
                                                    update={() => {
                                                        this.setState({ show_assign: false }, () => { refetchContactList() })
                                                    }}
                                                >
                                                    {assignAdmin => (
                                                        <Modal show={this.state.show_assign} onHide={() => this.setState({ show_assign: false})}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title>รับดูแลงานนี้</Modal.Title>
                                                            </Modal.Header>
                                                            <Modal.Body>
                                                                ชื่อลูกค้า : <b>{this.state.contactData && `${this.state.contactData.name} ${this.state.contactData.lastName}`}</b> <br/>
                                                                อีเมล : <b>{this.state.contactData && this.state.contactData.email}</b> <br/>
                                                                เบอร์โทรศัพท์ : <b>{this.state.contactData && this.state.contactData.phone}</b> <br/>
                                                                ข้อความ : <b>{this.state.contactData && this.state.contactData.msg}</b> <br/><br/>
                                                                กด "ตกลง" เพื่อรับดูแลงานนี้
                                                            </Modal.Body>
                                                            <Modal.Footer>
                                                                <Button style={{float:"left"}} onClick={() => this.setState({ show_assign: false})}>กลับ</Button>
                                                                <Button bsStyle="primary" onClick={() => assignAdmin()}>ตกลง</Button>
                                                            </Modal.Footer>
                                                        </Modal>
                                                    )}
                                                </Mutation>
                                            </div>
                                        )
                                    }
                                }
                            </Query>

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}


export default compose(
    graphql(adminNameQuery, { name: 'adminName' }),
)(ContactList);