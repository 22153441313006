import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

const Container = styled.div``;
const SelectBoxContainer = styled(Select)`
  .react-select__control {
    ${props => props.messageError && `
      border: 1px solid #FF0000 !important;
    `}
  }

  .react-select__option {
    color: #000000;
    font-size:18px;
  }


`;

const customStyles = {
  control: (base, state) => ({
    ...base,
    '&:hover': { borderColor: '#E3E3E3' },
    border: '1px solid #E3E3E3',
    boxShadow: 'none',
    color: '#000000',
  }),
};

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;

  ${props => props.messageError && `
    color: #FF0000;
  `}
`;

const Required = styled.span`
  color: #D90101;
`;

const MessageError = styled.div`
  font-size: 16px;
  color: #FF0000;
  height: 22px;
  text-align: right;
`;

class SelectBox extends React.Component {
  render() {
    return (
      <Container style={this.props.style}>
        { this.props.inputLabel ? (<Label messageError={this.props.messageError}>{this.props.inputLabel}{ this.props.required && (<Required>*</Required>) }</Label>) : null }
        <SelectBoxContainer
          className="react-select-container"
          classNamePrefix="react-select"
          styles={customStyles}
          onChange={this.props.onChange}
          options={this.props.options}
          placeholder={this.props.placeholder}
          defaultValue={this.props.options[0]}
          value={this.props.value}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary50: '#F2F2F270',
              primary25: '#F2F2F260',
              primary: '#F2F2F2',
            },
          })}
          isDisabled={this.props.disabled}
          messageError={this.props.messageError}
        />
        {this.props.messageError && <MessageError>{this.props.messageError}</MessageError>}
      </Container>
      
    )
  }
}

export default SelectBox;