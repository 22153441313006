import React from 'react';
import cn from './CheckBoxList.module.scss';
import classnames from 'classnames';

const CheckBoxList = props => {
  let cnWrapper = classnames(cn.Wrapper);
  let cnDetailTitle = classnames(cn.DetailTitle);
  let cnDetailSubTitle = classnames(cn.DetailSubTitle);
  let cnIcon = classnames(cn.Icon, { [cn.NonActive]: !props.active });

  let icon = null;
  if (props.active) {
    icon = <i className="fas fa-check-circle"></i>;
  } else {
    icon = <i className="fal fa-circle"></i>;
  }

  return (
    <div className={cnWrapper} onClick={() => (props.onPress ? props.onPress() : null)}>
      <div className={cnIcon}>{icon}</div>
      <div className={cn.Detail}>
        <div className={cn.DetailHeader}>
          <div className={cn.Left}>
            <div className={cnDetailTitle}>{props.title}</div>
            <div className={cnDetailSubTitle}>{props.subTitle}</div>
          </div>
        </div>
        {props.children && <div className={cn.SubDetail}>{props.children}</div>}
      </div>
    </div>
  );
};

export default CheckBoxList;
