/* Import pageview*/
import Shipper from 'views/Shipper/Shipper';
import ShipperDetail from 'views/Shipper/ShipperDetail';
import ShipperDiver from 'views/Shipper/ShipperDiver';
import ShipperShipment from 'views/Shipper/ShipperShipment';
import Shipment from 'views/Shipment/Shipment-case';
import Driver from 'views/Driver/Driver';
import DriverLocation from 'views/Driver/DriverLocation';
import DriverRegister from 'views/Driver/DriverRegister';
import DriverDetail from 'views/Driver/DriverDetail';
import AgentRegister from 'views/Agent/AgentRegister';
import AgentDriver from 'views/Agent/AgentDriver';
import AgentDetail from 'views/Agent/AgentDetail';
import Agent3PL from 'views/Agent/Agent3PL';
import AgentContractCalendar from 'views/Agent/AgentContractCalendar';
import AgentContractTable from 'views/Agent/AgentContractTable';
import Licenseplete from 'views/Licenseplete/Licenseplete';

import ShipmentDetail from 'views/ShipmentDetail/ShipmentDetail';
import ShipmentCost from 'views/ShipmentDetail/ShipmentCost';
import ShipmentPayment from 'views/ShipmentDetail/ShipmentPayment';
import ShipmentPVC from 'views/ShipmentDetail/ShipmentPVC';

import AdminUser from 'views/AdminUser/AdminUser';
import PriceLog from 'views/PriceLog/PriceLog';
import ContactList from 'views/ContactList/ContactList';
import GiztixBusiness from 'views/GiztixBusiness/GiztixBusiness';
import BusinessDetail from 'views/GiztixBusiness/BusinessDetail';
import BusinessPayment from 'views/GiztixBusiness/BusinessPayment';
import BusinessPaymentDetail from 'views/GiztixBusiness/BusinessPaymentDetail';
import BusinessShipment from 'views/GiztixBusiness/BusinessShipment';
import BusinessTrackingSetting from 'views/GiztixBusiness/Tracking';
import BusinessDriver from 'views/GiztixBusiness/BusinessDriver';
import Payment from 'views/Payment/Payment';
import PaymentDetail from 'views/Payment/PaymentDetail';
import GiztixChallenge from 'views/GiztixChallenge/Challenge';
import GiztixChallengeDetail from 'views/GiztixChallenge/ChallengeDetail';

import Report from 'views/Report/Report';
import DownloadReportList from 'views/Report/DownloadReportList';
import GetOTP from 'views/GetOTP';
import ShowMenu from 'views/ShowMenu';
import HideMenu from 'views/HideMenu';
import ShowMenuJD from 'views/ShowMenuJD';

import Flashdeals from 'views/Flashdeals/Flashdeals';
import Dashboard from 'views/Dashboard/Dashboard';

import PointMap from 'views/PointMap/index';
import CostingList from 'views/BusinessCosting/screens/CostingList';

import KaCodDashboard from 'views/KaCustomer/screens/CodDashboard';
import COD from 'views/COD';

import ReLabel from 'views/ReLabel';

//setting
import {
  DataMasterGiztixTruck,
  DataMasterGiztixHolidays,
  DataMasterGiztixCondition,
} from 'views/Setting/Screens';

//KA Customer
import {
  Dashboard as KaDashboard,
  BookingFiles as KaBookingFiles,
  Shipments as KaShipments,
  ShipmentList as KaShipmentList,
  ShipmentDetail as KaShipmentDetail,
  AddDriver as KaAddDriver,
  MasterData3PL as KaMasterData3PL,
  ShipmentAgent as KaShipmentAgent,
  ThirdpartyDetail as Ka3PLDetail,
  CashOnDelivery as KaCODDetail,
  ShipmentSubAgent as KaShipmentSubAgent,
  LazadaQueue as KaQueue,
  LazadaCosting as KaCosting,
  LazadaCutOff as KaCutOff,
  ParcelFlight as KaParcelFlight,
  ShipmentListNew,
  TruckRequest as TruckRequestScreen,
} from 'views/KaCustomer/screens';

//Lazada
import {
  Dashboard as LazadaDashboard,
  BookingFiles as LazadaBookingFiles,
  Shipments as LazadaShipments,
  ShipmentList as LazadaShipmentList,
  ShipmentDetail as LazadaShipmentDetail,
  AddDriver as LazadaAddDriver,
  MasterData3PL as LazadaMasterData3PL,
  ShipmentAgent as LazadaShipmentAgent,
  ThirdpartyDetail as Lazada3PLDetail,
  CashOnDelivery as LazadaCODDetail,
  ShipmentSubAgent as LazadaShipmentSubAgent,
  LazadaQueue,
  LazadaCosting,
  LazadaCutOff,
  ParcelFlight as LazadaParcelFlight,
} from 'views/Lazada/screens';

//JD
import {
  Dashboard as JdDashboard,
  BookingFiles as JdBookingFiles,
  Shipments as JdShipments,
  ShipmentList as JdShipmentList,
  ShipmentDetail as JdShipmentDetail,
  AddDriver as JdAddDriver,
  //        JD New Flow        //
  // MasterData3PL as JDMasterData3PL,
  // ShipmentAgent as JDShipmentAgent,
  // ThirdpartyDetail as JD3PLDetail,
  // ShipmentSubAgent as JDShipmentSubAgent,
  // CashOnDelivery as JDCODDetail,
  // JDQueue,
  //        JD New Flow        //
} from 'views/JD/screens';

//SHOPEE
import {
  Dashboard as ShopeeDashboard,
  BookingFiles as ShopeeBookingFiles,
  Shipments as ShopeeShipments,
  ShipmentList as ShopeeShipmentList,
  ShipmentDetail as ShopeeShipmentDetail,
  AddDriver as ShopeeAddDriver,
} from 'views/Shopee/screens';

import KaICON from 'assets/img/kaIcon.svg';
import lzdICON from 'assets/img/lzdIcon.svg';
import jdICON from 'assets/img/jdIcon.svg';
import spICON from 'assets/img/spIcon.svg';
import CODParcel from 'views/CODParcel';
import ReportCalcurate from 'views/ReportCalcurate';
import CostPerParcel from 'views/CostPerParcel';
import CostParcelBilling from 'views/CostParcelBilling';

const giztixMenu = () => {
  return !localStorage.getItem('giztixmenu');
};
const lazadaMenu = () => {
  return !localStorage.getItem('lazadamenu');
};
const jdMenu = () => {
  return !localStorage.getItem('jdmenu');
};

/* Sidebar menu */
const dashboardRoutes = [
  {
    path: '#',
    name: 'menu',
    enName: 'menu',
    icon: null,
  },
  {
    hide: giztixMenu(),
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-chart-pie',
    component: Dashboard,
  },
  //KA
  {
    hide: true,
    path: '/re-label',
    name: 'Re Label',
    icon: 'fas fa-sms',
    component: ReLabel,
  },
  {
    hide: true,
    path: '/ka-shipment-list',
    name: 'Ka Shipment List',
    icon: 'fal fa-heart',
    component: KaShipmentList,
  },
  {
    hide: true,
    path: '/ka-shipment-list-new',
    name: 'Ka Shipment List',
    icon: 'fal fa-heart',
    component: ShipmentListNew,
  },
  {
    hide: true,
    path: '/ka-shipment-detail',
    name: 'Ka Shipment Detail',
    icon: 'fal fa-heart',
    component: KaShipmentDetail,
  },
  {
    hide: true,
    path: '/ka-product-list',
    name: 'Ka Product List',
    icon: 'fal fa-heart',
    component: KaCutOff,
  },
  {
    hide: true,
    path: '/ka-cod-dashboard',
    name: 'Ka COD Dashboard',
    icon: 'fal fa-handshake',
    component: KaCodDashboard,
  },
  {
    hide: true,
    path: '/ka-parcel-flight',
    name: 'Ka Parcel Flight',
    icon: 'fal fa-heart',
    component: KaParcelFlight,
    isHideLayout: true,
  },
  {
    hide: true,
    path: '/ka-dashboard',
    name: 'Ka Project',
    icon: 'fal fa-heart',
    component: KaDashboard,
  },
  {
    hide: true,
    path: '/ka-adddriver',
    name: 'Ka Add Driver',
    icon: 'fal fa-heart',
    component: KaAddDriver,
  },
  {
    hide: true,
    path: '/ka-import-file',
    name: 'Ka Import File',
    icon: 'fal fa-heart',
    component: KaBookingFiles,
  },
  {
    hide: true,
    path: '/ka-3pl-masterdata',
    name: 'Ka 3PL Master Data',
    icon: 'fal fa-heart',
    component: KaMasterData3PL,
  },
  {
    hide: true,
    path: '/ka-shipment-agent',
    name: 'Ka Shipment Agent',
    icon: 'fal fa-heart',
    component: KaShipmentAgent,
  },
  {
    hide: true,
    path: '/ka-3pl-detail',
    name: 'Ka 3PL Detail',
    icon: 'fal fa-heart',
    component: Ka3PLDetail,
  },
  {
    hide: true,
    path: '/ka-cod-detail',
    name: 'Ka Cod Detail',
    icon: 'fal fa-heart',
    component: KaCODDetail,
  },
  {
    hide: true,
    path: '/ka-shipment-subagent',
    name: 'Ka Cod Detail',
    icon: 'fal fa-heart',
    component: KaShipmentSubAgent,
  },
  {
    hide: true,
    path: '/ka-shipment-costing',
    name: 'Ka Shipment Costing',
    icon: 'fal fa-heart',
    component: KaCosting,
  },
  {
    hide: true,
    path: '/ka-queue/:id',
    name: 'KA Queue',
    icon: 'fal fa-heart',
    component: KaQueue,
    isHideLayout: true,
  },
  //Lazada
  {
    hide: true,
    path: '/lazada-dashboard',
    name: 'Lazada Project',
    icon: 'fal fa-heart',
    component: LazadaDashboard,
  },
  {
    hide: true,
    path: '/lazada-adddriver',
    name: 'Lazada Add Driver',
    icon: 'fal fa-heart',
    component: LazadaAddDriver,
  },
  {
    hide: true,
    path: '/lazada-import-file',
    name: 'Lazada Import File',
    icon: 'fal fa-heart',
    component: LazadaBookingFiles,
  },
  {
    hide: true,
    path: '/lazada-3pl-masterdata',
    name: 'Lazada 3PL Master Data',
    icon: 'fal fa-heart',
    component: LazadaMasterData3PL,
  },
  {
    hide: true,
    path: '/lazada-shipment-agent',
    name: 'Lazada Shipment Agent',
    icon: 'fal fa-heart',
    component: LazadaShipmentAgent,
  },
  {
    hide: true,
    path: '/lazada-3pl-detail',
    name: 'Lazada 3PL Detail',
    icon: 'fal fa-heart',
    component: Lazada3PLDetail,
  },
  {
    hide: true,
    path: '/lazada-cod-detail',
    name: 'Lazada Cod Detail',
    icon: 'fal fa-heart',
    component: LazadaCODDetail,
  },
  {
    hide: true,
    path: '/lazada-shipment-subagent',
    name: 'Lazada Cod Detail',
    icon: 'fal fa-heart',
    component: LazadaShipmentSubAgent,
  },
  {
    hide: true,
    path: '/lazada-shipment-costing',
    name: 'Lazada Shipment Costing',
    icon: 'fal fa-heart',
    component: LazadaCosting,
  },
  {
    path: '#',
    name: 'SHIPMENT MANAGEMENT',
    enName: 'shipManGroup',
    icon: 'fas fa-clipboard-list',
    subMenu: [
      {
        hide: giztixMenu(),
        path: '/shipment',
        name: 'TRUCK',
        icon: 'fas fa-file-alt',
        component: Shipment,
      },
      {
        hide: giztixMenu(),
        path: '/contactlist',
        name: 'CUSTOMER INQUIRY',
        icon: 'fas fa-mail-bulk',
        component: ContactList,
      },
      {
        hide: giztixMenu(),
        path: '/payment',
        name: 'GTX BU INVOICE',
        icon: 'fas fa-money-check-alt',
        component: Payment,
      },
      {
        hide: giztixMenu(),
        path: '/business-costing',
        name: 'BU COSTING',
        icon: 'fas fa-money-check-alt',
        component: CostingList,
      },
    ],
  },
  {
    path: '#',
    name: 'PROJECT',
    enName: 'projectGroup',
    icon: 'fas fa-folder-open',
    subMenu: [
      {
        hide: lazadaMenu() && giztixMenu(),
        path: '/ka',
        name: 'งานขนส่งพิเศษ KA',
        icon: 'fas fa-ballot',
        component: KaShipments,
      },
      {
        path: '/cod-parcel',
        name: 'COD By Parcel',
        icon: 'fas fa-box',
        component: CODParcel,
        mainOnly: false,
      },
      {
        path: '/cod-shipment',
        name: 'COD By Shipment',
        icon: 'fas fa-box-check',
        component: COD,
        mainOnly: false,
      },
      {
        path: '/cost-parcel-billing',
        name: 'ต้นทุน Parcel ',
        icon: 'fas fa-box-usd',
        component: CostParcelBilling,
        mainOnly: false,
      },
      {
        path: '/cod-per-parcel',
        name: 'Rev. per parcel ',
        icon: 'fas fa-box-usd',
        component: CostPerParcel,
        mainOnly: false,
      },
      {
        // hide: lazadaMenu() && giztixMenu(),
        path: '/truck-request',
        name: 'รายการขอรถจากคลัง',
        icon: 'fas fa-truck',
        component: TruckRequestScreen,
      },
      {
        hide: lazadaMenu() && giztixMenu(),
        path: '/lazada',
        name: 'งานขนส่ง LAZADA',
        icon: 'fal fa-heart',
        svg: lzdICON,
        component: LazadaShipments,
      },
      {
        hide: jdMenu() && giztixMenu(),
        path: '/jd',
        name: 'งานขนส่ง JD',
        icon: 'fal fa-heart',
        svg: jdICON,
        component: JdShipments,
      },
      {
        path: '/shopee',
        name: 'งานขนส่ง SHOPEE',
        icon: 'fal fa-heart',
        svg: spICON,
        component: ShopeeShipments,
      },
    ],
  },
  {
    path: '#',
    name: '3PL MANAGEMENT',
    enName: 'thirdGroup',
    icon: 'fas fa-truck',
    subMenu: [
      {
        path: '/contract-calendar',
        name: 'ตารางรถรายเดือน',
        icon: 'far fa-calendar-alt',
        component: AgentContractCalendar,
      },
      {
        hide: true,
        path: '/contract-calendar-table',
        name: 'ตารางการวิ่งรถรายเดือน',
        icon: 'far fa-calendar-alt',
        component: AgentContractTable,
      },
      {
        path: '/agent',
        name: '3PL AGENT',
        icon: 'far fa-handshake',
        component: Agent3PL,
      },
      {
        path: '/driver',
        name: 'DRIVER',
        icon: 'fas fa-truck',
        component: Driver,
      },
      {
        hide: giztixMenu(),
        path: '/licenseplete',
        name: 'LICENSE PLATE',
        icon: 'fas fa-address-card',
        component: Licenseplete,
      },
    ],
  },
  {
    path: '#',
    name: 'CUSTOMER MANAGEMENT',
    enName: 'cusGroup',
    icon: 'fas fa-portrait',
    subMenu: [
      {
        hide: giztixMenu(),
        path: '/shipper',
        name: 'SHIPPER',
        icon: 'fas fa-box-open',
        component: Shipper,
      },
      {
        hide: giztixMenu(),
        path: '/giztixbusiness',
        name: 'Giztix Business',
        icon: 'fal fa-registered',
        component: GiztixBusiness,
      },
    ],
  },

  {
    path: '#',
    name: 'MARKETING',
    enName: 'marketingGroup',
    icon: 'fas fa-bullhorn',
    subMenu: [
      {
        hide: giztixMenu(),
        path: '/pricelog',
        name: 'SEARCH PRICE DATA',
        icon: 'fas fa-search',
        component: PriceLog,
      },
      {
        hide: giztixMenu(),
        path: '/flashdeals',
        name: 'FLASHDEALS',
        icon: 'fas fa-bolt',
        component: Flashdeals,
      },
      {
        hide: giztixMenu(),
        path: '/giztixchallenge',
        name: 'GIZTIX CHALLENGE',
        icon: 'fas fa-flag-checkered',
        component: GiztixChallenge,
      },
    ],
  },
  {
    hide: giztixMenu(),
    path: '/report',
    name: 'REPORT',
    icon: 'fas fa-file-chart-line',
    // component: Report,
    subMenu: [
      {
        hide: giztixMenu(),
        path: '/report-main',
        name: 'รายงาน',
        icon: 'fas fa-file-chart-line',
        component: Report,
      },
      {
        hide: giztixMenu(),
        path: '/report-calculate',
        name: 'คำนวณรายได้/ค่าใช้จ่าย',
        icon: 'fas fa-calculator',
        component: ReportCalcurate,
      },
    ],
  },
  {
    hide: true,
    path: '/downloadreport',
    name: 'ดาวน์โหลดรายงาน',
    icon: 'fas fa-file-chart-line',
    component: DownloadReportList,
  },
  {
    path: '#',
    name: 'SETTING',
    enName: 'settingGroup',
    icon: null,
  },
  {
    hide: giztixMenu(),
    path: '/adminuser',
    name: 'USER MANAGEMENT',
    icon: 'fas fa-users',
    component: AdminUser,
  },
  {
    hide: giztixMenu(),
    path: '/settingdatamaster',
    name: 'DATA MASTER',
    icon: 'fas fa-cogs',
    component: DataMasterGiztixTruck,
  },
  {
    hide: giztixMenu(),
    path: '/get-otp',
    name: 'GET OTP',
    icon: 'fas fa-sms',
    component: GetOTP,
  },
  {
    hide: true,
    path: '/settingdatamaster-holidays',
    name: 'DATA MASTER',
    icon: 'fas fa-cogs',
    component: DataMasterGiztixHolidays,
  },
  {
    hide: true,
    path: '/settingdatamaster-condition',
    name: 'DATA MASTER',
    icon: 'fas fa-cogs',
    component: DataMasterGiztixCondition,
  },
  // LAZADA
  {
    hide: true,
    path: '/lazada-shipment-list',
    name: 'Lazada Shipment List',
    icon: 'fal fa-heart',
    component: LazadaShipmentList,
  },
  {
    hide: true,
    path: '/lazada-shipment-detail',
    name: 'Lazada Shipment Detail',
    icon: 'fal fa-heart',
    component: LazadaShipmentDetail,
  },
  {
    hide: true,
    path: '/lazada-product-list',
    name: 'Lazada Product List',
    icon: 'fal fa-heart',
    component: LazadaCutOff,
  },
  {
    hide: true,
    path: '/lazada-parcel-flight',
    name: 'Lazada Parcel Flight',
    icon: 'fal fa-heart',
    component: LazadaParcelFlight,
    isHideLayout: true,
  },
  // JD
  {
    hide: true,
    path: '/jd-dashboard',
    name: 'JD Project',
    icon: 'fal fa-heart',
    component: JdDashboard,
  },
  {
    hide: true,
    path: '/jd-adddriver',
    name: 'JD Add Driver',
    icon: 'fal fa-heart',
    component: JdAddDriver,
  },
  {
    hide: true,
    path: '/jd-import-file',
    name: 'JD Import File',
    icon: 'fal fa-heart',
    component: JdBookingFiles,
  },
  {
    hide: true,
    path: '/jd-shipment-list',
    name: 'JD Shipment List',
    icon: 'fal fa-heart',
    component: JdShipmentList,
  },
  {
    hide: true,
    path: '/jd-shipment-detail',
    name: 'JD Shipment Detail',
    icon: 'fal fa-heart',
    component: JdShipmentDetail,
  },
  //        JD New Flow        //

  // {
  //   hide: true,
  //   path: '/jd-3pl-masterdata',
  //   name: 'JD 3PL Master Data',
  //   icon: 'fal fa-heart',
  //   component: JDMasterData3PL,
  // },
  // {
  //   hide: true,
  //   path: '/jd-shipment-agent',
  //   name: 'JD Shipment Agent',
  //   icon: 'fal fa-heart',
  //   component: JDShipmentAgent,
  // },
  // {
  //   hide: true,
  //   path: '/jd-3pl-detail',
  //   name: 'JD 3PL Detail',
  //   icon: 'fal fa-heart',
  //   component: JD3PLDetail,
  // },
  // {
  //   hide: true,
  //   path: '/jd-shipment-subagent',
  //   name: 'JD Shipment SubAgent',
  //   icon: 'fal fa-heart',
  //   component: JDShipmentSubAgent,
  // },
  // {
  //   hide: true,
  //   path: '/jd-cod-detail',
  //   name: 'JD Cod Detail',
  //   icon: 'fal fa-heart',
  //   component: JDCODDetail,
  // },
  // {
  //   hide: true,
  //   path: '/jd-queue/:id',
  //   name: 'JD Queue',
  //   icon: 'fal fa-heart',
  //   component: JDQueue,
  //   isHideLayout: true,
  // },

  //        JD New Flow        //

  // SHOPEE
  {
    hide: true,
    path: '/shopee-dashboard',
    name: 'Shopee Project',
    icon: 'fal fa-heart',
    component: ShopeeDashboard,
  },
  {
    hide: true,
    path: '/shopee-adddriver',
    name: 'Shopee Add Driver',
    icon: 'fal fa-heart',
    component: ShopeeAddDriver,
  },
  {
    hide: true,
    path: '/shopee-import-file',
    name: 'Shopee Import File',
    icon: 'fal fa-heart',
    component: ShopeeBookingFiles,
  },
  {
    hide: true,
    path: '/shopee-shipment-list',
    name: 'Shopee Shipment List',
    icon: 'fal fa-heart',
    component: ShopeeShipmentList,
  },
  {
    hide: true,
    path: '/shopee-shipment-detail',
    name: 'Shopee Shipment Detail',
    icon: 'fal fa-heart',
    component: ShopeeShipmentDetail,
  },
  // SHIPPER
  {
    hide: true,
    path: '/shipperdetail',
    name: 'ผู้ส่งสินค้า',
    icon: 'fas fa-box-open',
    component: ShipperDetail,
  },
  {
    hide: true,
    path: '/shipperdriver',
    name: 'พนักงานับรถ',
    icon: 'fas fa-box-open',
    component: ShipperDiver,
  },
  {
    hide: true,
    path: '/shippershipment',
    name: 'งานขนส่ง',
    icon: 'fas fa-box-open',
    component: ShipperShipment,
  },
  // GTX BUSINESS
  {
    hide: true,
    path: '/giztixbusinessdetail',
    name: 'Giztix Business',
    icon: 'fal fa-registered',
    component: BusinessDetail,
  },
  {
    hide: true,
    path: '/giztixbusinesspayment',
    name: 'Giztix Business',
    icon: 'fal fa-registered',
    component: BusinessPayment,
  },
  {
    hide: true,
    path: '/giztixbusinesspaymentdetail',
    name: 'Giztix Business',
    icon: 'fal fa-registered',
    component: BusinessPaymentDetail,
  },
  {
    hide: true,
    path: '/giztixbusinessshipment',
    name: 'Giztix Business',
    icon: 'fal fa-registered',
    component: BusinessShipment,
  },
  {
    hide: true,
    path: '/giztixbusinesstrackingsetting',
    name: 'Giztix Business',
    icon: 'fal fa-registered',
    component: BusinessTrackingSetting,
  },
  {
    hide: true,
    path: '/giztixbusinessdriver',
    name: 'พนักงานขับรถคนโปรด',
    icon: 'fal fa-user-hard-hat',
    component: BusinessDriver,
  },
  // GTX CHALLENGE
  {
    hide: true,
    path: '/giztixchallengedetail',
    name: 'Giztix Challenge',
    icon: 'fas fa-flag-checkered',
    component: GiztixChallengeDetail,
  },
  // PAYMENT
  {
    hide: true,
    path: '/paymentdetail',
    name: 'รายละเอียดรายรับ',
    icon: 'fas fa-money-check-alt',
    component: PaymentDetail,
  },
  // PRICE LOG
  {
    hide: true,
    path: '/shipmentdetail',
    name: 'รายละเอียดขนส่ง',
    icon: 'fas fa-file-alt',
    component: ShipmentDetail,
  },
  {
    hide: true,
    path: '/shipmentcost',
    name: 'รายละเอียดต้นทุน - ราคาขาย',
    icon: 'fal fa-coins',
    component: ShipmentCost,
  },
  {
    hide: true,
    path: '/shipmentpayment',
    name: 'รายละเอียดใบแจ้งหนี้-เพิ่มหนี้',
    icon: 'fal fa-coins',
    component: ShipmentPayment,
  },
  {
    hide: true,
    path: '/shipmentpvc',
    name: 'ต้นทุน - ราคาขาย',
    icon: 'fal fa-coins',
    component: ShipmentPVC,
  },
  // DRIVER
  {
    hide: true,
    path: '/driverlocation',
    name: 'ตำแหน่งพนักงานขับรถ',
    icon: 'fas fa-truck',
    component: DriverLocation,
  },
  {
    hide: true,
    path: '/agentregister',
    name: 'เพิ่มนายหน้า',
    icon: 'fas fa-truck',
    component: AgentRegister,
  },
  {
    hide: true,
    path: '/agentdetail',
    name: 'นายหน้า',
    icon: 'fas fa-truck',
    component: AgentDetail,
  },
  {
    hide: true,
    path: '/agentdriver',
    name: 'พนักงานขับรถของนายหน้า',
    icon: 'fas fa-truck',
    component: AgentDriver,
  },
  {
    hide: true,
    path: '/driverregister',
    name: 'เพิ่มพนักงานขับรถ',
    icon: 'fas fa-truck',
    component: DriverRegister,
  },
  {
    hide: true,
    path: '/driverdetail',
    name: 'พนักงานขับรถ',
    icon: 'fas fa-truck',
    component: DriverDetail,
  },
  {
    hide: true,
    path: '/showmenu',
    name: 'Show Menu',
    icon: 'fas fa-bar',
    component: ShowMenu,
  },
  {
    hide: true,
    path: '/showmenujd',
    name: 'Show Menu',
    icon: 'fas fa-bar',
    component: ShowMenuJD,
  },
  {
    hide: true,
    path: '/hidemenu',
    name: 'Hide Menu',
    icon: 'fas fa-bar',
    component: HideMenu,
  },
  {
    hide: true,
    path: '/pointmap',
    name: 'PointMap',
    icon: 'fal fa-heart',
    component: PointMap,
  },
  {
    hide: true,
    path: '/lazada-queue/:id',
    name: 'Lazada Queue',
    icon: 'fal fa-heart',
    component: LazadaQueue,
    isHideLayout: true,
  },
  {
    redirect: true,
    path: '/',
    to: !giztixMenu() || !lazadaMenu() ? '/shipment' : '/jd',
    name: !giztixMenu() || !lazadaMenu() ? 'งานขนส่งพิเศษ ka' : 'JD Project',
  },

  // { redirect: true, path: "/",to: "/shopee" , name: "SHOPEE" },
];

export default dashboardRoutes;
