import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../graphql/query/shipmentList';

export default gql`
    query shipmentAdminSubThirdpartys(
        $params: paramsShipmentAdminSubThirdPartys
    $sort: sort
    $page: Int
    $showData: Int!
    ){
        shipmentAdminSubThirdpartys(
        params: $params
        sort: $sort
        page: $page
        showData: $showData
        ){
        sub_thirdparty{
            _id
            import_order_id
            name
            agent_shipment_status
            name_head
            total_price_cod
            name_agent
            account_info_id
            third_party_setting_id
            trip_truck{
                _id
                truck_id
                truck_type
                name
                total_average
            }
            total_cod
            wait_accept_cod
            accept_cod
            cancel_cod
            total_assign_driver
            order_type

            trip
      accept_trip
      wait_accept_trip
      checkin
      no_accept_trip
      wait_assign_work
      assign_work
        }
        totalPage
        totalDocument
        }
    }
`;
