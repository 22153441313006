import gql from 'graphql-tag';

export default gql`
 mutation UpdateContactusList(
        $_id: ID!
        $status: Int
        $admin_id: ID
        $admin_username: String
        $remark: String
    ) 
    {
        contactusUpdate(
            _id: $_id
            status: $status
            admin_id: $admin_id
            admin_username: $admin_username
            remark: $remark
        ) 
        {
            _id
        }
    }
`;