import gql from 'graphql-tag';

export default gql`
    query shipmentKADetailTracking($_id: ID!) {
        shipmentKA(_id: $_id) {
            _id
            ka_driver_payment_type
            record_mileage
            mileage_start{
                mileage_date
                mileage
                mileage_img
                lat
                lng
            }
            mileage_end{
                mileage_date
                mileage
                mileage_img
                lat
                lng
            }
            total_distance
            distance_shortest_path
            working_period
            mileage_log{
                _id
                shipment
                admin
                mileage_start{
                mileage_date
                mileage
                mileage_img
                lat
                lng
                }
                mileage_end{
                mileage_date
                mileage
                mileage_img
                lat
                lng
                }
                total_distance
                distance_shortest_path
                working_period
                createdAt
                updatedAt
            }
        }
    }
`;
