import gql from 'graphql-tag';

export default gql`
mutation deleteCostingDocuments(
    $_id: [ID]!
){
    deleteCostingDocuments(
      _id: $_id
    ){
      _id
    }
  }
`;
