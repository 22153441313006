export const MONTH_TH =
    [
        'มกราคม',
        'กุมภาพันธ์',
        'มีนาคม',
        'เมษายน',
        'พฤษภาคม',
        'มิถุนายน',
        'กรกฎาคม',
        'สิงหาคม',
        'กันยายน',
        'ตุลาคม',
        'พฤศจิกายน',
        'ธันวาคม',
    ];

export const MONTH_ENG = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]


export const TEXT_TITLE = "รายการแสดงจำนวนสินค้า ที่มี COD / จำนวนสินค้าที่รอโอน COD"
export const TEXT_EVENT_TITLE = "จำนวนสินค้ารอส่ง COD"
export const TEXT_EVENT_UNIT = "ชิ้น"
export const TEXT_EVENT_REMAINING = "ยอดที่เหลือ [count] บาท"

export const TEXT_MODAL_TITLE = "รายการรอจ่าย COD ของวันที่"

export const TEXT_MODAL_SUBTITLE = "ยอดรอโอน COD รวม(บาท)"
export const TEXT_MODAL_SUBTITLE_DATE = "วันที่ต้องทำจ่าย"
export const TEXT_MODAL_SUBTITLE_AMOUNT = "จำนวนสินค้า(ชิ้น)"

export const TEXT_MODAL_TOOL = "เครื่องมือ"
export const TEXT_MODAL_AGENT = "Agent"
export const TEXT_MODAL_COST = "มูลค่าที่ต้องโอน"
export const TEXT_MODAL_UNIT = "จำนวนชิ้น"

export const TEXT_CLOSE = "ปิด"
export const TEXT_EVENT_EMPTY = "ไม่มีข้อมูล"
