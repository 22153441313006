import React from 'react';

class ShowMenu extends React.Component {
  componentDidMount() {
    localStorage.clear('giztixmenu');
    localStorage.clear('lazadamenu');
    localStorage.setItem('jdmenu', true);
  }

  render() {
    return (window.location = '/');
  }
}

export default ShowMenu;
