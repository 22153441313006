import React from 'react';
import PropTypes from 'prop-types';
import { StyledTag } from './Styles';

const colors = ['blue', 'blue--light', 'green', 'red', 'white', 'yellow'];
const propTypes = {
  color: PropTypes.oneOf(colors),
  children: PropTypes.string.isRequired,
};

const defaultProps = {
  color: undefined,
};

const Tag = props => {
  const { color, children } = props;
  return <StyledTag className={color}>{children}</StyledTag>;
};

Tag.propTypes = propTypes;
Tag.defaultProps = defaultProps;

export default Tag;
