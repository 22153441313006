import gql from 'graphql-tag';

export default gql`
  query newCod($_id: ID!) {
    newCod(_id: $_id) {
      _id
      vendor {
        _id
        first_name
        last_name
        full_name
      }
      cod_in_number
      total_cod
      shipment_numbers
      due_date
      cod_in_date
      ops_employee {
        _id
        department
        first_name
        last_name
        full_name
      }
      payment_cod {
        _id
      }
      cod_status
      cod_item {
        _id
        customer {
          _id
          full_name
        }
        total_cod
        shipment_list {
          _id
          shipment_number
          booking_number
          cod_price
        }
        document
      }
      document
      cod_remark
      reject_remark
      send_history {
        timestamp
      }
      last_send
      create_date
      last_update
    }
  }
`;
