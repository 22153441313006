import gql from "graphql-tag";

export default gql`
    query dashboardLazadaPredict{
        dashboardLazadaPredict{
            totalPredict
        totalDelivering
        predict{
            name
            amount
        }
        deliving{
            name
            amount
        }
        }
    }
`;
