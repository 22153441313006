import React, { useState, useEffect } from 'react';
import { FormControl, Row, Col, Button } from 'react-bootstrap';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { MessageError, Table, ButtonRemove, ButtonLink } from '../style';
import ListItem from './ListItem';
import SelectKaWarehouses from '../components/SelectKaWarehouses';
import SelectAgent from '../components/SelectAgent';
import { orderType } from '../constant/orderType';
import ModalWarehouseCustomer from './ModalWarehouseCustomer';
import ModalWarehouse from './ModalWarehouse';

const Shipment = ({ isLoyWithType }) => {
  const { control, errors, watch, setValue, trigger } = useFormContext();
  const watchOrderType = watch('orderType');
  const watchShipments = watch('shipments') || [];
  const watchAmount = watch('amount');
  const [keyOpen, setKeyOpen] = useState(0);
  const [isOpenModalWarehouse, setOpenModalWarehouse] = useState(false);
  const [isOpenModalWarehouseCustomer, setOpenModalWarehouseCustomer] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState({
    mode: 'pickup',
    shipmentIndex: 0,
  });
  const [shipment, setShipment] = useState({
    pickup: [],
    delivery: [],
  });

  const isLastMile = watchOrderType === orderType.LAST_MILE || isLoyWithType === 'LM';
  const isTripLoySelected = watchOrderType !== orderType.TRIP_LOY || isLoyWithType;
  const isFirstMile = watchOrderType === orderType.FIRST_MILE || isLoyWithType === 'FM';
  const isDirectShip = watchOrderType === orderType.DIRECT_SHIP || isLoyWithType === 'DS';
  const isBUFM = watchOrderType === orderType.BU_FIRST_MILE || isLoyWithType === 'BUFM';
  const isRR = watchOrderType === orderType.REJECT_RETURN || isLoyWithType === 'RR';

  useEffect(() => {
    if (isLastMile && typeof +watchAmount === 'number' && +watchAmount > 0) {
      setValue(
        'shipments',
        new Array(+watchAmount).fill().map(() => ({
          pickup: shipment.pickup,
          delivery: shipment.delivery,
        })),
        { shouldDirty: true }
      );
      if (shipment.pickup[0]) {
        trigger('shipments');
      }
    }
  }, [watchOrderType, shipment, +watchAmount]);

  const addShipment = () => {
    setValue('shipments', [
      ...watchShipments,
      {
        pickup: [],
        delivery: watchOrderType === orderType.FIRST_MILE ? [...watchShipments[0].delivery] : [],
      },
    ]);
  };

  const removeShipment = index => {
    setValue('shipments', [
      ...watchShipments.filter((_, shipmentIndex) => shipmentIndex !== index),
    ]);

    if (keyOpen === index) {
      setKeyOpen(null);
    } else if (index < keyOpen) {
      setKeyOpen(keyOpen - 1);
    }
  };

  const toggleOpenShipment = index => {
    setKeyOpen(keyOpen === index ? null : index);
  };

  return (
    <div>
      <Row>
        {isTripLoySelected && (
          <Col md={6}>
            <strong>เลือกนายหน้า:</strong>
            <Controller
              name="agentId"
              control={control}
              defaultValue=""
              render={({ onChange }) => (
                <SelectAgent onChange={valueChange => onChange(valueChange)} />
              )}
            />

            <MessageError>{errors?.agentId?.message}</MessageError>
          </Col>
        )}

        {isFirstMile && (
          <Col md={6}>
            <strong>เลือกจุดส่งสินค้า:</strong>
            <SelectKaWarehouses
              onChange={(_event, extraData) => {
                const deliveryPoint = extraData
                  ? {
                      tagName: extraData.tag_name,
                      address: extraData.address,
                      province: extraData.province,
                      district: extraData.dictrict,
                      subDistrict: extraData.subdictrict,
                      postCode: extraData.postcode,
                      lat: extraData.lat,
                      lng: extraData.lng,
                      contactName: extraData.contact_name,
                      contactTel: extraData.contact_tel,
                      warehouseType: null,
                      active: extraData.active,
                      wmsBranch: extraData.wms_branch,
                    }
                  : null;

                const updateDeliveryPoint = watchShipments.map(shipment => ({
                  ...shipment,
                  delivery: deliveryPoint ? [{ ...deliveryPoint }] : [],
                }));

                setValue('shipments', [...updateDeliveryPoint]);
                trigger('shipments');
              }}
            />
            <ErrorMessage
              errors={errors}
              name="shipments[0].delivery"
              render={({ message }) => <MessageError>{message}</MessageError>}
            />
          </Col>
        )}

        {isLastMile && (
          <Col md={6}>
            <strong>เลือกจุดรับสินค้า:</strong>
            <SelectKaWarehouses
              selectLabel="เลือกจุดรับสินค้า"
              onChange={(_event, extraData) => {
                const pickupPoint = extraData
                  ? {
                      tagName: extraData.tag_name,
                      address: extraData.address,
                      province: extraData.province,
                      district: extraData.dictrict,
                      subDistrict: extraData.subdictrict,
                      postCode: extraData.postcode,
                      lat: extraData.lat,
                      lng: extraData.lng,
                      contactName: extraData.contact_name,
                      contactTel: extraData.contact_tel,
                      warehouseType: null,
                      active: extraData.active,
                      wmsBranch: extraData.wms_branch,
                    }
                  : null;

                trigger('shipments');
                setShipment({
                  pickup: pickupPoint ? [{ ...pickupPoint }] : [],
                  delivery: [],
                });

                trigger('amount');
              }}
            />
            <ErrorMessage
              errors={errors}
              name="shipments[0].pickup"
              render={({ message }) => <MessageError>{message}</MessageError>}
            />
          </Col>
        )}
      </Row>

      {isTripLoySelected && (
        <Row>
          <Col md={6}>
            <strong>เลือกประเภทรถ:</strong>
            <Controller
              name="truckType"
              control={control}
              defaultValue=""
              render={({ onChange, value }) => (
                <FormControl
                  value={value}
                  onChange={event => onChange(event.target.value)}
                  componentClass="select"
                  placeholder="select"
                >
                  <option value="">เลือกประเภทรถ</option>
                  <option value="4W">4 ล้อ - ตู้ทึบ</option>
                  <option value="6W">6 ล้อ - ตู้ทึบ</option>
                  <option value="10W">10 ล้อ - ตู้ทึบ</option>
                </FormControl>
              )}
            />
            <MessageError>{errors?.truckType?.message}</MessageError>
          </Col>

          {!isLastMile && (
            <Col md={6}>
              <Button
                bsStyle="info"
                style={{ marginTop: 20 }}
                onClick={() => {
                  addShipment();
                }}
              >
                + เพิ่มรถ
              </Button>
            </Col>
          )}

          {isLastMile && (
            <Col md={6}>
              <strong>จำนวนรถ:</strong>
              <Controller
                name="amount"
                control={control}
                render={({ onChange, value }) => (
                  <FormControl
                    type="text"
                    value={value}
                    placeholder="จำนวนรถ"
                    onChange={event => {
                      onChange(event.target.value);
                      trigger('amount');
                      trigger('shipments');
                    }}
                  />
                )}
              />
              <MessageError>{errors?.amount?.message}</MessageError>
            </Col>
          )}
        </Row>
      )}

      {!isLastMile && isTripLoySelected && (
        <Row>
          <Col xs={12} style={{ textAlign: 'right', marginBottom: 6 }}>
            <strong>จำนวน {watchShipments.length} คัน</strong>
          </Col>
          <Col xs={12}>
            {(watchShipments || []).map((shipment, shipmentIndex, shipmentArr) => {
              let textTitle = '';
              if (isFirstMile || isDirectShip || isBUFM) {
                textTitle = `จำนวนจุดรับสินค้า : ${shipment.pickup.length} จุด`;
              } else {
                textTitle = `จำนวนจุดรับ - ส่งสินค้า : ${shipment.pickup.length +
                  shipment.delivery.length} จุด`;
              }

              return (
                <ListItem
                  key={shipmentIndex}
                  isOpen={keyOpen === shipmentIndex}
                  header={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {shipmentArr.length > 1 && (
                        <Button
                          style={{
                            border: 0,
                            padding: 0,
                            marginRight: 10,
                            color: 'rgba(0, 0, 0, 0.3)',
                          }}
                          onClick={event => {
                            event.stopPropagation();
                            removeShipment(shipmentIndex);
                          }}
                        >
                          <i class="fas fa-minus-circle" />
                        </Button>
                      )}

                      <span>
                        คันที่ {shipmentIndex + 1}{' '}
                        {(!!errors?.shipments?.[shipmentIndex]?.pickup ||
                          ((watchOrderType === orderType.RETURN_TO_MERCHANT ||
                            watchOrderType === orderType.SHUTTLE_HAUL ||
                            watchOrderType === orderType.REJECT_RETURN ||
                            watchOrderType === orderType.LINE_HAUL ||
                            isLoyWithType === 'SH' ||
                            isLoyWithType === 'LH' ||
                            isLoyWithType === 'RR' ||
                            isLoyWithType === 'RM') &&
                            !!errors?.shipments?.[shipmentIndex]?.delivery)) && (
                          <MessageError style={{ display: 'inline-block', marginLeft: 10 }}>
                            กรุณากรอกข้อมูล
                          </MessageError>
                        )}
                      </span>
                    </div>
                  }
                  textRight={textTitle}
                  onClick={() => toggleOpenShipment(shipmentIndex)}
                >
                  <div style={{ padding: 14 }}>
                    {(isFirstMile || isDirectShip || isBUFM) && (
                      <>
                        <Table style={{ marginTop: -14, marginBottom: 14 }}>
                          <thead>
                            <tr>
                              <th style={{ width: 40 }}>Del</th>
                              <th>ที่อยู่รับสินค้า</th>
                              <th className="text-right">จำนวนสินค้าที่ต้องเข้ารับ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipment.pickup.map((pickupItem, pickupIndex) => (
                              <tr key={pickupIndex}>
                                <td>
                                  <ButtonRemove
                                    onClick={() => {
                                      const filterPickup = shipment.pickup.filter(
                                        (_, index) => pickupIndex !== index
                                      );
                                      setValue(`shipments.[${shipmentIndex}].pickup`, [
                                        ...filterPickup,
                                      ]);
                                    }}
                                  >
                                    <i className="fal fa-trash-alt" />
                                  </ButtonRemove>
                                </td>
                                <td>{pickupItem.tagName}</td>
                                <td className="text-right">
                                  {pickupItem.totalOrder ? `${pickupItem.totalOrder} ชิ้น` : '-'}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <ButtonLink
                          bsStyle="link"
                          onClick={() => {
                            setSelectedShipment({
                              mode: 'pickup',
                              shipmentIndex,
                            });

                            setOpenModalWarehouseCustomer(true);
                          }}
                        >
                          + เพิ่มจุดรับสินค้า
                        </ButtonLink>
                      </>
                    )}

                    {(watchOrderType === orderType.SHUTTLE_HAUL ||
                      watchOrderType === orderType.LINE_HAUL ||
                      watchOrderType === orderType.REJECT_RETURN ||
                      watchOrderType === orderType.RETURN_TO_MERCHANT ||
                      isLoyWithType === 'SH' ||
                      isLoyWithType === 'LH' ||
                      isLoyWithType === 'RR' ||
                      isLoyWithType === 'RM') && (
                      <>
                        <Table style={{ marginTop: -14, marginBottom: 14 }}>
                          <thead>
                            <tr>
                              <th style={{ width: 40 }}>Del</th>
                              <th>ที่อยู่รับสินค้า (คลัง)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipment.pickup.map((pickupItem, pickupIndex) => (
                              <tr key={pickupIndex}>
                                <td>
                                  <ButtonRemove
                                    onClick={() => {
                                      const filterPickup = shipment.pickup.filter(
                                        (_, index) => pickupIndex !== index
                                      );
                                      setValue(`shipments.[${shipmentIndex}].pickup`, [
                                        ...filterPickup,
                                      ]);
                                    }}
                                  >
                                    <i className="fal fa-trash-alt" />
                                  </ButtonRemove>
                                </td>
                                <td>{pickupItem.tagName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <ButtonLink
                          bsStyle="link"
                          onClick={() => {
                            setSelectedShipment({
                              mode: 'pickup',
                              shipmentIndex,
                            });
                            setOpenModalWarehouse(true);
                          }}
                        >
                          + เพิ่มจุดรับสินค้า
                        </ButtonLink>

                        <Table style={{ marginTop: 20, marginBottom: 14 }}>
                          <thead>
                            <tr>
                              <th style={{ width: 40 }}>Del</th>
                              <th>ที่อยู่ส่งสินค้า (คลัง)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipment.delivery.map((deliveryItem, deliveryIndex) => (
                              <tr key={deliveryIndex}>
                                <td>
                                  <ButtonRemove
                                    onClick={() => {
                                      const filterDelivery = shipment.delivery.filter(
                                        (_, index) => deliveryIndex !== index
                                      );
                                      setValue(`shipments.[${shipmentIndex}].delivery`, [
                                        ...filterDelivery,
                                      ]);
                                    }}
                                  >
                                    <i className="fal fa-trash-alt" />
                                  </ButtonRemove>
                                </td>
                                <td>{deliveryItem.tagName}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>

                        <ButtonLink
                          bsStyle="link"
                          onClick={() => {
                            setSelectedShipment({
                              mode: 'delivery',
                              shipmentIndex,
                            });
                            setOpenModalWarehouse(true);
                          }}
                        >
                          + เพิ่มจุดส่งสินค้า
                        </ButtonLink>
                      </>
                    )}
                  </div>
                </ListItem>
              );
            })}
          </Col>
        </Row>
      )}

      <ModalWarehouseCustomer
        dropPoint={watchShipments?.[selectedShipment.shipmentIndex]?.[selectedShipment.mode] || []}
        shipmentIndex={selectedShipment.shipmentIndex}
        open={isOpenModalWarehouseCustomer}
        onClose={() => setOpenModalWarehouseCustomer(false)}
        onComplete={values => {
          setOpenModalWarehouseCustomer(false);
          setValue(`shipments.[${selectedShipment.shipmentIndex}].pickup`, [...values.warehouses]);
          trigger(`shipments[${selectedShipment.shipmentIndex}]`);
        }}
      />

      <ModalWarehouse
        mode={selectedShipment.mode}
        dropPoint={watchShipments?.[selectedShipment.shipmentIndex]?.[selectedShipment.mode] || []}
        shipmentIndex={selectedShipment.shipmentIndex}
        open={isOpenModalWarehouse}
        onClose={() => setOpenModalWarehouse(false)}
        onComplete={values => {
          setOpenModalWarehouse(false);
          setValue(`shipments.[${selectedShipment.shipmentIndex}].${selectedShipment.mode}`, [
            ...values.warehouses,
          ]);

          trigger(`shipments[${selectedShipment.shipmentIndex}]`);
        }}
      />
    </div>
  );
};

export default Shipment;
