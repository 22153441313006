import gql from 'graphql-tag';

export default gql`
query search(
  $page_number : Int
  $order_by : String
	$order_type : String
  $admin_username : String
  $admin_email : String
  $my_name : Boolean
  $admin_user_type: Int
){
  adminList(
      page_number :$page_number,
      show_data: 30,
      order_by :$order_by,
	  	order_type :$order_type,
      admin_username :$admin_username,
      admin_email :$admin_email
      my_name : $my_name,
      admin_user_type : $admin_user_type
  	) {
      admin{
        _id
       admin_username
       admin_email
       admin_display_picture
       blocked
       admin_user_type
     }
    total_page
  }
}
`;


