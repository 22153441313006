import gql from 'graphql-tag';

export default gql`
mutation Update(
    $category: String
    $detail: String
    $price: Float
    $qty: Float
    $vat: Int
    $wht: Int
    $invoice_id: ID
    $invoice_more_id: ID
    $item_id: ID!
    $mode: String!
){
    invoiceMoreUpdate(
        mode : $mode,
      	invoice_id: $invoice_id
        invoice_more_id: $invoice_more_id
        item_id: $item_id
        invoice_business_list_category: $category
        invoice_business_list_detail: $detail
        invoice_business_list_price: $price
        invoice_business_list_qty: $qty
        invoice_business_list_vat: $vat
        invoice_business_list_wht: $wht
    ){
        _id
    }
  }
`;
