//SHOPEE
import {
  Dashboard as ShopeeDashboard,
  BookingFiles as ShopeeBookingFiles,
  Shipments as ShopeeShipments,
  ShipmentList as ShopeeShipmentList,
  ShipmentDetail as ShopeeShipmentDetail,
  AddDriver as ShopeeAddDriver,
} from 'views/Shopee/screens';

const giztixMenu = () => {
  return !localStorage.getItem('giztixmenu');
};
const lazadaMenu = () => {
  return !localStorage.getItem('lazadamenu');
};
const jdMenu = () => {
  return !localStorage.getItem('jdmenu');
};

/* Sidebar menu */
const dashboardRoutes = [
  {
    path: '#',
    name: 'PROJECT',
    enName: 'projectGroup',
    icon: 'far fa-chess-queen',
    subMenu: [
      {
        path: '/shopee',
        name: 'SHOPEE',
        icon: 'fal fa-heart',
        component: ShopeeShipments,
      },
    ],
  },
  // SHOPEE
  {
    hide: true,
    path: '/shopee-dashboard',
    name: 'Shopee Project',
    icon: 'fal fa-heart',
    component: ShopeeDashboard,
  },
  {
    hide: true,
    path: '/shopee-adddriver',
    name: 'Shopee Add Driver',
    icon: 'fal fa-heart',
    component: ShopeeAddDriver,
  },
  {
    hide: true,
    path: '/shopee-import-file',
    name: 'Shopee Import File',
    icon: 'fal fa-heart',
    component: ShopeeBookingFiles,
  },
  {
    hide: true,
    path: '/shopee-shipment-list',
    name: 'Shopee Shipment List',
    icon: 'fal fa-heart',
    component: ShopeeShipmentList,
  },
  {
    hide: true,
    path: '/shopee-shipment-detail',
    name: 'Shopee Shipment Detail',
    icon: 'fal fa-heart',
    component: ShopeeShipmentDetail,
  },
  { redirect: true, path: '/', to: '/shopee', name: 'SHOPEE' },
];

export default dashboardRoutes;
