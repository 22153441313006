import React from 'react';
import cn from './OrderList.module.scss';
import { Label } from 'react-bootstrap';
import classnames from 'classnames';

const OrderList = props => {
  let cnWrapper = classnames(cn.Wrapper, { [cn.NonActive]: !props.active });
  let cnDetailTitle = classnames(cn.DetailTitle, { [cn.NonActive]: !props.active });
  let cnDetailSubTitle = classnames(cn.DetailSubTitle, { [cn.NonActive]: !props.active });
  let cnIcon = classnames(cn.Icon, { [cn.NonActive]: !props.active });
  let cnRight = classnames(cn.Right, { [cn.Right]: !props.active })

    const renderLazadaSendFinalStatus  = status => {
    switch (status) {
      case true:
        return <Label bsStyle="danger" style={{padding:"0 3px",lineHeight:".5em"}}><i className="fas fa-check"></i></Label>;
      default:
        return <Label bsStyle="warning" style={{padding:"0 3px",lineHeight:".5em"}}><i className="fas fa-spinner"></i></Label>;
    }
  }

  let icon = null;
  if (props.active) {
    icon = <i className="fas fa-check-circle"></i>;
  } else {
    icon = <i className="fas fa-circle"></i>;
  }

  return (
    <div className={cnWrapper} onClick={() => (props.onPress ? props.onPress() : null)}>
      <div className={cnIcon}>{icon}</div>
      {console.log(props.item)}
      <div className={cn.Detail}>
        <div className={cn.DetailHeader}>
          <div className={cn.Left}>
            <div className={cnDetailTitle}>{props.title}  { props.item && props.item.delivery_status == 5 && renderLazadaSendFinalStatus(props.item.send_package_damaged)} </div>
            <div className={cnDetailSubTitle}>{props.subTitle}</div>
          </div>
          <div className={cnRight}>
            <i className="far fa-angle-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
