import { client } from 'index';
import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import accountInfoUpdate from 'views/Driver/graphql/mutation/accountInfoUpdate';
import { Input } from '../../components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import adminList from 'utils/AdminName/graphql/query/adminList';

const accountUserUpdate = gql`
  mutation accountUserUpdate(
    $_id: ID
    $account_info_id: ID
    $account_user_name: String
    $account_user_password: String
  ) {
    accountUserUpdate(
      _id: $_id
      account_info_id: $account_info_id
      account_user_name: $account_user_name
      account_user_password: $account_user_password
    ) {
      _id
    }
  }
`;

export const FunctionCheckEmail = input => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmail = emailRegex.test(String(input).toLowerCase());
  if (isEmail) {
    return true;
  } else {
    return false;
  }
};

const IconEditUser = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: '24px',
        height: '24px',
        cursor: 'pointer',
      }}
      aria-hidden="true"
      role="img"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 32 32"
    >
      <path
        fill="currentColor"
        d="M13 3c-3.855 0-7 3.145-7 7c0 2.41 1.23 4.55 3.094 5.813C5.527 17.343 3 20.883 3 25h2c0-4.43 3.57-8 8-8c2.145 0 4.063.879 5.5 2.25l-4.719 4.719l-.062.312l-.688 3.532l-.312 1.468l1.469-.312l3.53-.688l.313-.062l10.094-10.094c1.16-1.16 1.16-3.09 0-4.25a3.018 3.018 0 0 0-4.219-.031l-3.968 3.969a10.103 10.103 0 0 0-3.032-2A7.024 7.024 0 0 0 20 10c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm13 10c.254 0 .52.082.719.281a.977.977 0 0 1 0 1.406l-9.688 9.688l-1.781.375l.375-1.781l9.688-9.688A.934.934 0 0 1 26 15z"
      />
    </svg>
  );
};

const EditUser = ({ username = 'username', _id, infoId }) => {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [confirmEmail, setConfirmEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const FunctionClearState = () => {
    setEmail('');
    setConfirmEmail('');
    setPassword('');
    setConfirmPassword('');
  };

  const FunctionUpdateEmail = async () => {
    try {
      await client.mutate({
        mutation: accountUserUpdate,
        variables: {
          _id,
          account_user_name: email,
          account_info_id: infoId,
        },
      });

      await client.mutate({
        mutation: accountInfoUpdate,
        variables: {
          _id: infoId,
          account_info_email: email,
        },
      });

      window.location.reload();
    } catch (error) {
      alert(error);
    }
  };

  const FunctionUpdatePassword = async () => {
    try {
      await client.mutate({
        mutation: accountUserUpdate,
        variables: {
          _id,
          account_user_password: password,
          account_info_id: infoId,
        },
      });
    } catch (error) {
      alert(error);
    }
  };

  const onClickSubmit = () => {
    const isEmail = FunctionCheckEmail(email);

    if (email !== confirmEmail) {
      alert('Email is not match');
      return;
    } else if (password !== confirmPassword) {
      alert('Password is not match');
      return;
    } else if (email === '' && password === '') {
      alert('Please fill something please');
      return;
    } else if (
      email === confirmEmail &&
      password === confirmPassword &&
      email !== '' &&
      password !== ''
    ) {
      if (isEmail) {
        FunctionUpdateEmail();
        FunctionUpdatePassword();
        FunctionClearState();
        setOpen(false);
      } else {
        alert('โปรดกรอกอีเมล์ให้ถูกต้อง');
        return;
      }
    } else if (email === confirmEmail && email !== '') {
      if (isEmail) {
        FunctionUpdateEmail();
        FunctionClearState();
        setOpen(false);
      } else {
        alert('โปรดกรอกอีเมล์ให้ถูกต้อง');
        return;
      }
    } else if (password === confirmPassword) {
      FunctionUpdatePassword();
      FunctionClearState();
      setOpen(false);
    }
  };

  return (
    <Query query={adminList}>
      {({ data }) => {
        const admin_username = data?.adminList?.admin[0]?.admin_username;
        const admin_email = data?.adminList?.admin[0]?.admin_email;
        const isSuperAdmin =
          admin_username === 'palmolive' ||
          admin_username === 'pang' ||
          admin_username === 'anusorn' ||
          admin_username === 'ann' ||
          admin_email === 'sirilak.c@giztix.com' ||
          admin_username === 'earnadmin' ||
          admin_email === 'outsorce4@mail.com';

        if (data && isSuperAdmin) {
          return (
            <React.Fragment>
              <IconEditUser
                onClick={() => {
                  setOpen(true);
                }}
              />
              {open && (
                <Modal
                  dialogClassName="modal-dialog-size"
                  show={open}
                  backdrop="static"
                  onHide={() => setOpen(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>แก้ไขข้อมูลของ {username}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      Email สำหรับเข้าสู่ระบบ
                    </div>
                    <Input
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                      value={email}
                      style={{ marginTop: 10 }}
                      inputLabel="อีเมล"
                      placeholder="อีเมล"
                    />
                    <Input
                      onChange={e => {
                        setConfirmEmail(e.target.value);
                      }}
                      value={confirmEmail}
                      style={{ marginTop: 10 }}
                      inputLabel="ยืนยันอีเมล"
                      placeholder="ยืนยันอีเมล"
                    />
                    <div
                      style={{
                        borderBottom: '1px solid #e6e6e6',
                        marginTop: 10,
                        marginBottom: 20,
                      }}
                    />
                    <div
                      style={{
                        marginBottom: '10px',
                      }}
                    >
                      รหัสผ่าน สำหรับเข้าสู่ระบบ
                    </div>
                    <Input
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      value={password}
                      style={{ marginTop: 10 }}
                      inputLabel="รหัสผ่าน"
                      placeholder="รหัสผ่าน"
                    />
                    <Input
                      onChange={e => {
                        setConfirmPassword(e.target.value);
                      }}
                      value={confirmPassword}
                      style={{ marginTop: 10 }}
                      inputLabel="ยืนยันรหัสผ่าน"
                      placeholder="ยืนยันรหัสผ่าน"
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button onClick={() => setOpen(false)}>{`กลับ`}</Button>
                      <Button
                        className="btn btn-red"
                        onClick={() => {
                          onClickSubmit();
                        }}
                      >{`ตกลง`}</Button>
                    </div>
                  </Modal.Footer>
                </Modal>
              )}
            </React.Fragment>
          );
        } else {
          return <React.Fragment />;
        }
      }}
    </Query>
  );
};

export default EditUser;
