import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipmentID: ID!
    $pick_up_date: String
    ){
    shipmentsUpdate(
        _id:$shipmentID,
        pick_up_date: $pick_up_date
    ){
        _id
    }
  }
`;
