import React, { Component } from "react";
import Moment from 'react-moment';

import { graphql, Mutation, Query ,compose} from "react-apollo";
import { Grid, Row, Col, Table, Label, Image , Button, Modal, FormGroup,
	ControlLabel,
	FormControl,
	InputGroup,  } from "react-bootstrap";
import Select from 'react-select';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import moment from 'moment/moment.js'

// Component
import styled from 'styled-components';
import { 
    LeafletMap
} from './components';
import Card from "components/Card/Card.jsx";

import s3 from "config/s3"

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { UploadFile } from './container';
import restBaseUrl from 'config/restBaseUrl';
import { client } from './../../index';

import axios from 'axios';

class PointMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position:[]
        }
		this._inputImportFile = React.createRef();
    }

    boundsLatLng = () => {
        const { position} = this.state;
        const markerLatLng = position.map(item => {
            const res = item.location.split(",");
            return [res[0],res[1]];
        });
        const mergeLatLng = [...markerLatLng];
        return mergeLatLng;
    };

    uploadFileHandler = async (file) => {
        console.log(file);
        try {
            const url = `${restBaseUrl}/excel-to-json?path=${file.path}`;
            await axios(url).then(res => {
                if (res.data) {
                    const shipmentAddressFormat = res.data
                    .map((item, index) => ({ ...item, index: index + 1 }))
                    .map(item => {
                        return {
                        location: item.location,
                        text: item.index,
                        };
                    });

                    console.log(shipmentAddressFormat)
                    this.setState({ position: shipmentAddressFormat });
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        return (
            <div>
                <div className="content">
                    <Grid fluid>
                        <Col md={12} className="card-shipment" >
                            <Card
                                title="แผนที่จุดส่งสินค้า แพนกิว"
                                category="อัพโหลดไฟล์เพื่อดูตำแหน่งสถานที่"
                                ctTableFullWidth
                                ctTableResponsive
                                rightButton={
                                    <UploadFile
                                    completed={file => {
                                        this._inputImportFile.current.value = null;
                                        this.uploadFileHandler(file);
                                    }}
                                >
                                    {selectFile => {
                                    return (
                                        <React.Fragment>
                                        <input
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={event => {
                                            selectFile(
                                                event,
                                                `upload/locationMap/document_${new Date().getTime()}`
                                            );
                                            }}
                                            type="file"
                                            id="file"
                                            ref={this._inputImportFile}
                                            style={{ display: 'none' }}
                                        />
                                        <Button style={{float:"right"}} onClick={() => this._inputImportFile.current.click()}>
                                            <i className="fas fa-cloud-upload"></i> อัพโหลดไฟล์
                                        </Button>
                                        </React.Fragment>
                                    );
                                    }}
                                </UploadFile>
                                                      }
                                content={
                                    <div>
                                        <LeafletMap
                                            markerShipment={this.state.position}
                                            boundsLatLng={this.boundsLatLng()}
                                        />
                                    </div>
                                }
                            />
                        </Col>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default PointMap;