import gql from 'graphql-tag';

export default gql`
query search(
    $_id: ID
  ){
    expensesCategoryList(
        _id 	: $_id
    ){
    _id
    name
    position
    expenses_item{
        _id
        name
      tax_type
      default_vat
      default_wht
    }
    }
}
`;
