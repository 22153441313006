import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Input } from './../../../../components';
import { Query } from 'react-apollo';
import { connect } from 'formik';
import _ from 'lodash';

// utils
import formatCurrency from './../../../../../../utils/formatCurrency';

// graphql
import costingQuery from './../../../../graphql/query/costing';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-top: none;
  padding: 10px;
`;

const DetailContainer = styled.div`
  & .table > tbody > tr > td {
    font-size: 19px;
    vertical-align: text-top;
  }

  & .table > tbody > tr {
    &:last-child {
      border-bottom: 1px solid #e3e3e3;
    }

    &:not(:last-child) > td {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & th {
    font-weight: bold !important;
    color: #000000 !important;
    font-size: 19px !important;
    text-align: right;
  }

  & .divider {
    border-right: 1px solid #e3e3e3;
  }

  & td {
    text-align: right;
  }
`;

const SumContainer = styled.div`
  & .table-sum {
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    width: 550px;
    float: right;
    padding-bottom: 0px;

    & td {
      text-align: right;
    }

    & tr:last-child > td {
      font-weight: bold;
    }

    & tr:nth-child(odd) > td,
    & tr.wht > td  {
      border-bottom: 1px solid transparent;
    }

    & tr.wht.last > td  {
      border-bottom: 1px solid #e3e3e3;
    }

    .table > tbody > tr > td {
      font-size: 19px;
    }
  }
`;

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      costingId : props.costingId
    };
  }

  checkDisabled(costing) {
    const { costing_status } = costing;

    if (costing_status === 1) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <Query
        query={costingQuery}
        variables={{
          _id: this.state.costingId,
        }}
      >
        {({ loading, error, data, refetch, variables }) => {
          const serviceGroup = _.chain(data.costing.costing_list)
              .groupBy('service_category_name')
              .map((value, key) => ({
                serviceCategoryName: key,
                data: value,
              }))
              .value();
          return (
            <TableContainer>
              <DetailContainer>
                <Table hover>
                  <thead>
                    <tr style={{backgroundColor: "#f9f9f9"}}>
                      <th colSpan={2}></th>
                      <th style={{ textAlign: 'center' }} colSpan={5}>รายการต้นทุน</th>
                      <th style={{ textAlign: 'center' }}  colSpan={7}>รายการขาย</th>
                    </tr>
                    <tr style={{backgroundColor: "#f9f9f9"}}>
                      <th style={{ textAlign: 'left', width: 100 }}>รายการ</th>
                      <th className="divider" >จำนวน</th>
                      <th >ราคาต่อหน่วย</th>
                      <th >ราคารวม</th>
                      <th >ภาษีมูลค่าเพิ่ม</th>
                      <th >หัก ณ ที่จ่าย</th>
                      <th className="divider" style={{ width: 100 }}>
                        ราคาต้นทุน
                      </th>
                      <th >ราคาต่อหน่วย</th>
                      <th >ราคารวม</th>
                      <th >ส่วนลด</th>
                      <th >ราคาหลังลด</th>
                      <th >ภาษีมูลค่าเพิ่ม</th>
                      <th >ราคาขาย</th>
                      <th >
                        ราคาขาย
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading || error ? (
                      <tr>
                        <td colSpan="14" style={{ textAlign: 'center' }}>
                          {loading ? 'Loading...' : error.message}
                        </td>
                      </tr>
                    ) : data.costing.costing_list.length === 0 ? (
                      <tr>
                        <td colSpan="14" style={{ textAlign: 'center' }}>
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    ) : (
                      <>
                      {
                        serviceGroup.map((serviceGroupItem, key) => (
                          <>
                            <tr>
                              <td colSpan="14" style={{ textAlign: 'left',fontWeight : "bold" }}>
                                {serviceGroupItem.serviceCategoryName}
                              </td>
                            </tr>
                            {
                              serviceGroupItem.data.map((dataItem, key) => (
                                <tr key={dataItem.datamaster_service_item_id}>
                                  <td style={{ textAlign: 'left', width: "200px" }}>
                                    {dataItem.list_number}-{dataItem.list_name}
                                  </td>
                                  <td className="divider" >{formatCurrency(dataItem.qty)}</td>
                                  <td >{formatCurrency(dataItem.price.cost)}</td>
                                  <td >{formatCurrency(dataItem.total_price.cost)}</td>
                                  <td >{formatCurrency(dataItem.total_vat.cost)}</td>
                                  <td >{formatCurrency(dataItem.total_wht.cost)}</td>
                                  <td className="divider" >{formatCurrency(dataItem.grand_total_price.cost)} </td>
                                  <td >{formatCurrency(dataItem.price.sale)}</td>
                                  <td >{formatCurrency(dataItem.total_before_discount.sale)}</td>
                                  <td >{formatCurrency(dataItem.discount.sale)}</td>
                                  <td >{formatCurrency(dataItem.total_price.sale)}</td>
                                  <td >{formatCurrency(dataItem.total_vat.sale)}</td>
                                  <td >{formatCurrency(dataItem.total_wht.sale)}</td>
                                  <td >{formatCurrency(dataItem.grand_total_price.sale)}</td>
                                </tr>
                              ))
                            }

                          </>
                        ))
                      }
                      </>
                    )}
                  </tbody>
                </Table>
              </DetailContainer>
              <DetailContainer>
                <Row>
                  <Col md={12}>
                    <Table hover>
                        <thead>
                          <tr style={{backgroundColor: "#f9f9f9"}}>
                            <th></th>
                            <th style={{ textAlign: 'center' }}  colSpan={4}>รายการต้นทุน</th>
                            <th style={{ textAlign: 'center' }}  colSpan={6}>รายการขาย</th>
                          </tr>
                          <tr style={{backgroundColor: "#f9f9f9"}}>
                            <th className="divider"  style={{textAlign: 'left' }}>รายการ</th>
                            <th>ราคาต่อหน่วย</th>
                            <th>ราคารวม</th>
                            <th>ภาษีมูลค่าเพิ่ม</th>
                            <th className="divider" >หัก ณ ที่จ่าย</th>
                            <th>ราคาต่อหน่วย</th>
                            <th>ราคารวม</th>
                            <th>ส่วนลด</th>
                            <th>ราคาหลังลด</th>
                            <th>ภาษีมูลค่าเพิ่ม</th>
                            <th>หัก ณ ที่จ่าย</th>
                          </tr>
                        </thead>
                        <tbody>
                          
                          <tr>
                            <td className="divider"  style={{textAlign: 'left' }}>ราคารวมก่อนส่วนลด</td>
                            <td></td>
                            <td> 
                              {formatCurrency(data.costing.total_before_discount.cost)}
                            </td>
                            <td></td>
                            <td className="divider" ></td>
                            <td></td>
                            <td>
                              {formatCurrency(data.costing.total_before_discount.sale)}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td  className="divider" style={{textAlign: 'left' }}>รวมส่วนลด</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="divider" ></td>
                            <td></td>
                            <td></td>
                            <td>
                              {formatCurrency(data.costing.total_discount.sale)}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="divider"  style={{textAlign: 'left' }}>ราคารวมหลังส่วนลด</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="divider" ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {formatCurrency(data.costing.total_after_discount.sale)}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td className="divider"  style={{textAlign: 'left' }}>รวมภาษีมูลค่าเพิ่ม 7%</td>
                            <td></td>
                            <td></td>
                            <td>
                              {formatCurrency(data.costing.total_vat.cost)}
                            </td>
                            <td className="divider" ></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {formatCurrency(data.costing.total_vat.sale)}
                            </td>
                            <td></td>
                          </tr>
                          {
                            data.costing.all_wht.map((whtItem) => (
                              <tr>
                                <td className="divider"  style={{textAlign: 'left' }}>รวมหัก ณ ที่จ่าย {whtItem.wht}%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="divider">
                                  {formatCurrency(whtItem.total_wht.cost)}
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  {formatCurrency(whtItem.total_wht.cost)}
                                </td>
                              </tr>
                            ))
                          }
                          <tr style={{backgroundColor: "#f9f9f9"}}>
                            <td colSpan={5} className="divider">
                              <h4 style={{fontWeight : "bold"}}> <small style={{fontWeight : "bold"}}>ต้นทุนสุทธิ / Net Cost</small> {formatCurrency(data.costing.grand_total_price.cost)} บาท</h4>
                            </td>
                            <td colSpan={6}>
                              <h4 style={{fontWeight : "bold"}}> <small style={{fontWeight : "bold"}}>รายได้สุทธิ / Net Revenue </small> {formatCurrency(data.costing.grand_total_price.sale)} บาท</h4>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                  </Col>
                </Row>
              </DetailContainer>
            </TableContainer>
          );
        }}
      </Query>
    );
  }
}

export default connect(PaymentList);
