import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';
import ModalConfirm from '../../../../components/ModalConfirm'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { FormControl, Button } from 'react-bootstrap';
import Datetime from "react-datetime";
import moment from 'moment';
import { Query, compose, graphql, Mutation } from "react-apollo";
import { SelectBox, Input } from './components'
import thirPartySettingSet from 'views/Lazada/screens/MasterData3PL/graphql/mutation/thirPartySettingSet';
import lambdaUrl from 'config/lambdaUrl';
import { client } from '../../../../../../index';
import axios from 'axios';

//Query
import adminListQuery from './graphql/query/adminList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import shipmentAgentView from 'views/Lazada/screens/ThirdpartyDetail/graphql/query/shipmentAgentView';

const Wrapper = styled.div`
    // background: #F3D8FF;
    display: flex;
    flex-direction: column;
`

const LeftSide = styled.div`
    flex: 1;
`

const RightSide = styled.div`
    flex: 1;
`

const Box = styled.div`
    width: 100%;
    display: flex;
    // background: #e3aa42;
`

const BoxDetail = styled.div`
    width: 100%;
    display: flex;
    // background: #e3aa42;
    margin-top: 10px;
    // border-top: 0.01px solid #a1a1a1;
    // border-bottom: 0.01px solid #a1a1a1;
    // padding: 10px 0px;
`

const WrapInput = styled.div`
    width: 80%;
`

const AutosuggestContainer = styled.div`
  display: inline-block;
  width: 100%;

  & .form-control {
    background: transparent;
    border: none;
    color: #000;
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    transition: border-width .1s linear;
  }

//   & .form-control:focus {
//     background: transparent;
//     border: none;
//     color: #000;
//     font-weight: bold;
//     font-size: 2.7vw;
//     border-radius: 0;
//     border-width: 0px;
//   }

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 5px 5px;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

let transporterList = []

class PlanFromDateModal extends Component {
    constructor(props){
        super(props)
        this.state = {
            //Agent List Suggestion
            agentListSuggestion: [],

            //ประเภทงาน
            admin_id: '',
            order_type: 9,

            //plan date
                planDate: '',
                displayPlanDate: '',
                planDateInvalid: false,
                dateValid: true,

            //Trip name
                trip_name: '',
                trip_nameInvalid: false,

            //Pickup Date
                pickup_date: '',
                displayPickupDate: '',
                pickupDateInvalid: false,
                PickupDateValid: true,

            //normal case
                shipment_quantity: '',
                shipment_quantityInvalid: false,

                truck_type: '4W',

                pickup_code: 'GIZTIX-WH',

            //เลือกนายหน้า
            shipment_detail: [
                {
                    shipment_quantity: '',
                    shipment_quantityInvalid: false,

                    truck_type: '4W',

                    pickup_code: 'BKK2',

                    transporter: '',
                    transporterInvalid: false,

                    agentSelected: '',
                }
            ],
        }
    }

    handleSubmit = () => {
        if(this.state.order_type == 3 || this.state.order_type == 5 || this.state.order_type == 9){
            if(this.checkInvalid()){
                console.log('invalid case35')
            }else{
                console.log('valid cxase35')
                this.handleFinalCRFM()
            }
        }else{
            if(this.checkNormalInvalid()){
                console.log('invalid caseNot35')
            }else{
                console.log('valid caseNot35')
                this.handleFinalData()
            }
        }
    }

    handleDateRangePicker = (mode, picker) => {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    
        const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
          'DD/MM/YYYY'
        )}`;
    
        if (mode === 'createDate') {
          this.setState({ createDate: date, displayCreateDate: display, createDateInvalid: false });
        } else if (mode === 'lastUpdate') {
          this.setState({ lastUpdate : date, displayLastUpdate: display });
        }
        else if (mode === 'planDate') {
          this.setState({ planDate : date, displayPlanDate: display });
        }
      }
    
    // Returns if a value is really a number
    isNumber (value) {
        return typeof value === 'number' && isFinite(value);
    }

    checkInvalid = () => {
        //checl quantity
        const newShipmentDetail =  this.state.shipment_detail.map((item, index) => {
            if(item.shipment_quantity){
                return item
            }else{
                const newSub = {...item}
                newSub.shipment_quantityInvalid = true
                return newSub
            }
        })

        //check transporter

        const newShipmentDetailSecond = newShipmentDetail.map((item, index) => {
            if(item.transporter){
                return item
            }else{
                const newSub = {...item}
                newSub.transporterInvalid = true
                return newSub
            }
        })

        console.log('newShipmentDetail',newShipmentDetail)
        console.log('OldShipmentDetail',this.state.shipment_detail)

        this.setState({
            planDateInvalid: this.state.dateValid ? (moment(this.state.planDate, 'YYYY-MM-DD', true).isValid()  ? false : true) : true,
            pickupDateInvalid: this.state.PickupDateValid ? (moment(this.state.pickup_date, 'YYYY-MM-DD HH:mm', true).isValid()  ? false : true) : true,
            trip_nameInvalid: this.state.trip_name ? false : true,
            shipment_detail: newShipmentDetailSecond
        })

        return !(
            this.state.trip_name && 
            this.state.planDate && moment(this.state.planDate, 'YYYY-MM-DD', true).isValid() && this.state.dateValid &&
            this.state.pickup_date && moment(this.state.pickup_date, 'YYYY-MM-DD HH:mm', true).isValid() && this.state.PickupDateValid &&
            (newShipmentDetail.filter(data => data.shipment_quantityInvalid === true ).length === 0) &&
            (newShipmentDetailSecond.filter(data => data.transporterInvalid === true ).length === 0)
        )
    }

    //check case normal
    checkNormalInvalid = () => {
       
        this.setState({
            planDateInvalid: this.state.dateValid ? (moment(this.state.planDate, 'YYYY-MM-DD', true).isValid()  ? false : true) : true,
            pickupDateInvalid: this.state.PickupDateValid ? (moment(this.state.pickup_date, 'YYYY-MM-DD HH:mm', true).isValid()  ? false : true) : true,
            trip_nameInvalid: this.state.trip_name ? false : true,
            shipment_quantityInvalid: this.state.shipment_quantity ? false : true
        })

        return !(
            this.state.trip_name && 
            this.state.planDate && moment(this.state.planDate, 'YYYY-MM-DD', true).isValid() && this.state.dateValid &&
            this.state.pickup_date && moment(this.state.pickup_date, 'YYYY-MM-DD HH:mm', true).isValid() && this.state.PickupDateValid &&
            this.state.shipment_quantity
        )
    }

    handleDatePicker(mode ,event) {
        if (event) {
            try {
                if(mode == 'planDate'){
                    const date = `${event.format('YYYY-MM-DD')}`;
                    const show = `${event.format('DD/MM/YYYY')}`;
                    this.setState({ planDate: date, displayPlanDate: show, planDateInvalid: false, dateValid: true});
                }
                else if(mode == 'pickupDate'){
                    const date = `${event.format('YYYY-MM-DD HH:mm')}`;
                    const show = `${event.format('DD/MM/YYYY HH:mm')}`;
                    this.setState({ pickup_date: date, displayPickupDate: show, pickupDateInvalid: false, PickupDateValid: true});
                }
            } catch (error) {
                if(mode == 'planDate'){
                    this.setState({ planDateInvalid: true, dateValid: false});
                }
                else if(mode == 'pickupDate'){
                    this.setState({ pickupDateInvalid: true, PickupDateValid: false});
                }
                console.log(error)
            }
        } else {
            if(mode == 'planDate'){
                this.setState({ planDate: '', displayPlanDate: '', planDateInvalid: false, dateValid: true});
            }
            else if(mode == 'pickupDate'){
                this.setState({ pickup_date: '', displayPickupDate: '', pickupDateInvalid: false, PickupDateValid: true});
            }
            if(!(this.state.order_type == 3 || this.state.order_type == 5 || this.state.order_type == 9)){
                this.checkNormalInvalid()
            }else{
                this.checkInvalid()
            }
        }
    }

    checkFormatEnAndNum = (file) => {
        const validate =/^[a-zA-Z0-9_.-]*$/;
        if(validate.test(file)){
            return true
        }
        return false
    }

    removeShipmentDetail = (index) => {
        // console.log('removeShipmentDetail', index)
        const newShipmentDetail = [...this.state.shipment_detail]
        newShipmentDetail.splice(index, 1)
        // console.log('removeShipmentDetail', newShipmentDetail)
        this.setState({ shipment_detail: newShipmentDetail })
    }

    handleFinalCRFM = async () => {
        try {
            const { data } = await client.query({
                query: adminListQuery,
              });
        
              const { admin } = data.adminList;
        
              if (admin.length === 0) {
                alert('ไม่พบ Admin');
                return;
              }
      
            const adminId = admin[0]._id;

            const newShipmentDetail = this.state.shipment_detail.map(item => ({
                shipment_quantity: parseInt(item.shipment_quantity),
                truck_type: item.truck_type,
                pickup_code: item.pickup_code,
                transporter: item.transporter
            }))
            const content = JSON.stringify({
                admin_id: adminId,
                order_type: this.state.order_type,
                plan_date: this.state.planDate,
                trip_name: this.state.trip_name,
                pickup_date: this.state.pickup_date,
                shipment_detail: newShipmentDetail
            })

            // console.log('check state: ', this.state)
            // console.log('handleFinalCRFM: ', content)

            const configs = {
                headers: {
                  'Content-Type': 'application/json',
                },
              };
        
              const url = '/lazada-import-wait-add-order';
            //   const baseUrl = 'http://192.168.101.24:3003/dev'
              const result = axios
                .post(lambdaUrl + url, content, configs)
                .then(res => {
                  console.log(res);
                })
                .catch(error => {
                  console.log(error);
                });
                setTimeout(() => {
                    window.open("/lazada-import-file","_self")
                }, 500);
                    this.props.close()
          } catch (error) {
            console.log(error);
          }
    }

    handleFinalData = async() => {
        try {
            const { data } = await client.query({
                query: adminListQuery,
              });
        
              const { admin } = data.adminList;
        
              if (admin.length === 0) {
                alert('ไม่พบ Admin');
                return;
              }
      
            const adminId = admin[0]._id;

            const newShipmentDetail = [{
                shipment_quantity: parseInt(this.state.shipment_quantity),
                truck_type: this.state.truck_type,
                pickup_code: this.state.pickup_code
            }]

            const content = JSON.stringify({
                admin_id: adminId,
                order_type: this.state.order_type,
                plan_date: this.state.planDate,
                trip_name: this.state.trip_name,
                pickup_date: this.state.pickup_date,
                shipment_detail: newShipmentDetail
            })

            console.log('handleFinal: ', content)

            const configs = {
                headers: {
                  'Content-Type': 'application/json',
                },
              };
        
              const url = '/lazada-import-wait-add-order';
              const result = axios
                .post(lambdaUrl + url, content, configs)
                .then(res => {
                  console.log(res);
                })
                .catch(error => {
                  console.log(error);
                });
            // setTimeout(() => {
                this.props.close()
                // }, 1000);
          } catch (error) {
            console.log(error);
          }
    }

    getSuggestions = async value => {    
        // console.log('getSuggestions')
        try {
            const inputValue = value
                .trim()
                .toString()
                .toLowerCase();

          const { data } = await client.query({
            query: accountInfoListQuery,
            variables:{
                account_info_business_name: inputValue,
                show_data: 10
            },
            fetchPolicy: 'network-only',
          });
    
        //   console.log('agent: ', data)

          const agentListData = data.accountInfoList.account_info.map(e => ({
              id: e._id,
              name: e.account_info_business_name
          }))
    
          this.setState({
            agentListSuggestion: agentListData,
          });

        } catch (error) {
          console.log(error);
        }
      };
    
      onSuggestionsClearRequested = () => {
        // console.log('onSuggestionsClearRequested')
        this.setState({
            agentListSuggestion: []
        });
      };
    
      onSuggestionsFetchRequested = ({ value }) => {
        //   console.log('onSuggestionsFetchRequested')
        this.getSuggestions(value);
      };
    
      getSuggestionValue = suggestion => suggestion.name;
    
      renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name　? suggestion.name : 'No Agent'}</div>;
    
      shouldRenderSuggestions() {
        //   console.log('shouldRenderSuggestions')
        return true;
      }


    render(){
        // let agentList = [];
        // if (
        //     this.props.accountInfoListQuery.loading === false &&
        //     this.props.accountInfoListQuery.accountInfoList !== undefined
        // ) {
        //     agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
        //     transporterList = agentList.map(item => ({value: item._id, label: item.account_info_business_name}))
        // }
        return (
                <ModalConfirm
                    show={this.props.open}
                    onPressButtonConfirm={() => this.handleSubmit()}
                    styleColorButtonConfirm="primary"
                    labelButtonConfirm={'สร้าง'}
                    labelButtonCancel={`ยกเลิก`}
                    onPressButtonCancel={this.props.close}
                    titleHeader={`Import File`}
                    onHide={this.props.close}
                    disabledButtonConfirm={false}
                    >
                    <Wrapper>
                        <Box>
                            <LeftSide>
                                <strong>ประเภทงาน</strong>
                                <WrapInput>
                                    <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.order_type}
                                        onChange={event =>
                                        this.setState({
                                            order_type: event.target.value
                                        })
                                        }
                                        name="status"
                                    >
                                        {
                                            // Old Option
                                            // <option value={3}>FIRST MILE (FM)</option>
                                            // <option value={5}>Customer Return (CR)</option>
                                        }
                                        <option value={9}>Line Haul (LH)</option>
                                    </FormControl>
                                </WrapInput>
                                {
                                    this.state.trip_nameInvalid && <br/>
                                }

                                <br/>

                                <b>LZD request BKG date :</b><br />
                                <WrapInput>
                                    <div style={{ border: this.state.planDateInvalid ? '1px solid #FF0000' : '0px solid #e3e3e3', borderRadius: '5px' }}>
                                        <Datetime
                                            closeOnSelect
                                            value={this.state.displayPlanDate}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat={false}
                                            inputProps={{ placeholder: "DD/MM/YYYY" }}
                                            onChange={event => {
                                                this.handleDatePicker('planDate',event)
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.planDateInvalid &&
                                        <div style={{ fontSize: '16px', color: '#FF0000', height: '22px', textAlign: 'right', }}>
                                            {this.state.planDateInvalid ? 'กรุณาระบุเวลา' : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                        </div>
                                    }
                                </WrapInput>
                            </LeftSide>
                            <RightSide>
                                <b>รอบการจัดส่ง :</b><br />
                                <WrapInput>
                                    <Input
                                        value={this.state.trip_name}
                                        onChange={(event) => this.setState({ trip_name: event.target.value, trip_nameInvalid: !this.checkFormatEnAndNum(event.target.value) })}
                                        placeholder="กรุณาระบุรอบการจัดส่ง"
                                        messageError={this.state.trip_nameInvalid && 'กรุณาระบุรอบการจัดส่งให้ถูกต้อง'}
                                    ></Input>
                                </WrapInput>

                                <br/>

                                <b>Pickup Date:</b><br />
                                <WrapInput>
                                    <div style={{ border: this.state.pickupDateInvalid ? '1px solid #FF0000' : '0px solid #e3e3e3', borderRadius: '5px' }}>
                                        <Datetime
                                            value={this.state.displayPickupDate}
                                            dateFormat="DD/MM/YYYY"
                                            timeFormat="HH:mm"
                                            inputProps={{ placeholder: "DD/MM/YYYY HH:mm" }}
                                            onChange={event => {
                                                this.handleDatePicker('pickupDate',event)
                                            }}
                                        />
                                    </div>
                                    {
                                        this.state.pickupDateInvalid &&
                                        <div style={{ fontSize: '16px', color: '#FF0000', height: '22px', textAlign: 'right', }}>
                                            {this.state.pickupDateInvalid ? 'กรุณาระบุเวลา' : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                        </div>
                                    }
                                </WrapInput>
                            </RightSide>
                        </Box>

                        {
                                ///////////////
                                //  งานปกติ   //
                                ///////////////
                        }                
                        {
                            !(this.state.order_type == 3 || this.state.order_type == 5 || this.state.order_type == 9) &&
                            <Box>
                                <LeftSide>
                                    <br/>
                                    <strong>ประเภทรถ</strong>
                                    <WrapInput>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.truck_type}
                                            value={this.state.truck_type}
                                            onChange={event =>
                                                {
                                                    this.setState({
                                                        truck_type: event.target.value
                                                    })
                                                }
                                            }
                                            name="status"
                                        >
                                            <option value={'4W'}>4W</option>
                                            <option value={'6W'}>6W</option>
                                            <option value={'10W'}>10W</option>
                                        </FormControl>
                                    </WrapInput>
                                    <br/>
                                    <strong>Pickup From</strong>
                                    <WrapInput>
                                        <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.pickup_code}
                                            value={this.state.pickup_code}
                                            onChange={event =>
                                                {
                                                    this.setState({
                                                        pickup_code: event.target.value
                                                    })
                                                }
                                            }
                                            name="status"
                                        >
                                            <option value={'BKK2'}>BKK2</option>
                                            <option value={'DHL3'}>DHL3</option>
                                            <option value={'LEXSORT'}>LEXSORT</option>
                                            <option value={'MWH'}>MWH</option>
                                            <option value={'GIZTIX-WH'}>GIZTIX-WH</option>
                                        </FormControl>
                                    </WrapInput>
                                </LeftSide>
                                <RightSide>
                                    <br/>
                                    <b>จำนวน :</b><br />
                                    <WrapInput>
                                        <Input
                                            type="number"
                                            value={this.state.shipment_quantity}
                                            onChange={(event) => 
                                                {
                                                    this.setState({ 
                                                        shipment_quantity: event.target.value < 0 ? 0 : event.target.value,
                                                        shipment_quantityInvalid: false
                                                    })
                                                }
                                            }
                                            placeholder="กรุณาระบุรอบการจัดส่ง"
                                            messageError={this.state.shipment_quantityInvalid && 'กรุณาระบุรอบการจัดส่งให้ถูกต้อง'}
                                        ></Input>
                                    </WrapInput>  
                                </RightSide>
                            </Box>
                        }

                        {
                                ///////////////
                            // เพิ่มนายหน้า //
                            ///////////////
                        }
                        {
                            (this.state.order_type == 3 || this.state.order_type == 5 || this.state.order_type == 9) && this.state.shipment_detail.map(
                                (item, index) => 
                                <div 
                                    style={{
                                        // marginTop: '10px',
                                        padding: '10px 0'
                                    }}
                                >
                                    <hr/>
                                    <i  onClick={() => this.removeShipmentDetail(index)} style={{color: 'red', float: 'right', marginRight: '20px', display: this.state.shipment_detail.length === 1 && 'none' }} className="fal fa-trash-alt"></i>
                                    <BoxDetail>
                                        <LeftSide>
                                            <strong>ประเภทรถ</strong>
                                            <WrapInput>
                                                <FormControl
                                                    componentClass="select"
                                                    placeholder="select"
                                                    defaultValue={this.state.shipment_detail[index].truck_type}
                                                    value={this.state.shipment_detail[index].truck_type}
                                                    onChange={event =>
                                                        {
                                                            const newShipmentDetail = [...this.state.shipment_detail]
                                                            newShipmentDetail[index].truck_type = event.target.value
                                                            this.setState({
                                                                shipment_detail: newShipmentDetail
                                                            })
                                                        }
                                                    }
                                                    name="status"
                                                >
                                                    <option value={'4W'}>4W</option>
                                                    <option value={'6W'}>6W</option>
                                                    <option value={'10W'}>10W</option>
                                                </FormControl>
                                            </WrapInput>
                                            {
                                                this.state.shipment_detail[index].shipment_quantityInvalid && <br/>
                                            }

                                            <br/>

                                            <strong>Pickup From</strong>
                                            <WrapInput>
                                                <FormControl
                                                    componentClass="select"
                                                    placeholder="select"
                                                    defaultValue={this.state.shipment_detail[index].pickup_code}
                                                    value={this.state.shipment_detail[index].pickup_code}
                                                    onChange={event =>
                                                        {
                                                            const newShipmentDetail = [...this.state.shipment_detail]
                                                            newShipmentDetail[index].pickup_code = event.target.value
                                                            this.setState({
                                                                shipment_detail: newShipmentDetail
                                                            })
                                                        }
                                                    }
                                                    name="status"
                                                >
                                                    <option value={'BKK2'}>BKK2</option>
                                                    <option value={'DHL3'}>DHL3</option>
                                                    <option value={'LEXSORT'}>LEXSORT</option>
                                                    <option value={'MWH'}>MWH</option>
                                                    <option value={'GIZTIX-WH'}>GIZTIX-WH</option>
                                                </FormControl>
                                            </WrapInput>
                                        </LeftSide>

                                        <RightSide>
                                            <b>จำนวน :</b><br />
                                            <WrapInput>
                                                <Input
                                                    type="number"
                                                    value={this.state.shipment_detail[index].shipment_quantity}
                                                    onChange={(event) => 
                                                        {
                                                            const newShipmentDetail = [...this.state.shipment_detail]
                                                            newShipmentDetail[index].shipment_quantity = event.target.value < 0 ? 0 : event.target.value
                                                            newShipmentDetail[index].shipment_quantityInvalid = false

                                                            this.setState({ 
                                                                shipment_detail: newShipmentDetail
                                                            })
                                                        }
                                                    }
                                                    placeholder="กรุณาระบุจำนวนรถ"
                                                    messageError={this.state.shipment_detail[index].shipment_quantityInvalid && 'กรุณาระบุจำนวนรถ'}
                                                ></Input>
                                            </WrapInput>  
                                            
                                            <br/>
                                            
                                            <strong>นายหน้า</strong>
                                            <WrapInput >
                                                <FormControl
                                                    style={{display: 'none'}}
                                                    componentClass="select"
                                                    placeholder="select"
                                                    defaultValue={this.state.shipment_detail[index].transporter}
                                                    value={this.state.shipment_detail[index].transporter}
                                                    onChange={event =>
                                                        {
                                                            const newShipmentDetail = [...this.state.shipment_detail]
                                                            // check duplicate
                                                            const duplicate = newShipmentDetail.filter(data => data.transporter == event.target.value).length > 0
                                                            if(duplicate){
                                                                console.log('duplicate')
                                                                newShipmentDetail[index].transporterInvalid = true
                                                                newShipmentDetail[index].transporter = ''
                                                            }else{
                                                                console.log('no duplicate')
                                                                newShipmentDetail[index].transporterInvalid = false
                                                                newShipmentDetail[index].transporter = event.target.value
                                                            }
                                                            // newShipmentDetail[index].transporterInvalid = false
                                                            this.setState({
                                                                shipment_detail: newShipmentDetail
                                                            })
                                                        }
                                                    }
                                                    name="status"
                                                >
                                                    <option value={''}>เลือกนายหน้า</option>
                                                    {
                                                        transporterList.map(tsp => <option value={tsp.value}>{tsp.label}</option>)
                                                    }
                                                </FormControl>
                                                <AutosuggestContainer>
                                                        <Autosuggest
                                                            suggestions={this.state.agentListSuggestion}
                                                            onSuggestionsFetchRequested={
                                                                this.onSuggestionsFetchRequested
                                                            }
                                                            onSuggestionsClearRequested={
                                                                this.onSuggestionsClearRequested
                                                            }
                                                            getSuggestionValue={this.getSuggestionValue}
                                                            renderSuggestion={this.renderSuggestion}
                                                            focusInputOnSuggestionClick={false}
                                                            inputProps={{
                                                                placeholder: 'ค้นหา',
                                                                value: this.state.shipment_detail[index].agentSelected,
                                                                onChange: event =>
                                                                {
                                                    
                                                                        const newShipmentDetail = [...this.state.shipment_detail]
                                                                        newShipmentDetail[index].agentSelected = event.target.value 
                                                                        if(!event.target.value){
                                                                            newShipmentDetail[index].transporter = ''
                                                                        }
            
                                                                        this.setState({ 
                                                                            shipment_detail: newShipmentDetail
                                                                        })
                                                                },
                                                                type: 'text',
                                                                className: 'form-control',
                                                            }}
                                                            onSuggestionSelected={(event, { suggestion }) => {
                                                                console.log(suggestion)
                                                                const newShipmentDetail = [...this.state.shipment_detail]
                                                                // check duplicate
                                                                const duplicate = newShipmentDetail.filter(data => data.transporter == suggestion.id).length > 0
                                                                if(duplicate){
                                                                    console.log('duplicate')
                                                                    newShipmentDetail[index].transporterInvalid = true
                                                                    newShipmentDetail[index].transporter = ''
                                                                    newShipmentDetail[index].agentSelected = ''
                                                                }else{
                                                                    console.log('no duplicate')
                                                                    newShipmentDetail[index].transporterInvalid = false
                                                                    newShipmentDetail[index].transporter = suggestion.id
                                                                    newShipmentDetail[index].agentSelected = suggestion.name
                                                                }
                                                                // newShipmentDetail[index].transporterInvalid = false
                                                                this.setState({
                                                                    shipment_detail: newShipmentDetail
                                                                })

                                                                // this.setState({
                                                                // orderId: suggestion.id,
                                                                // orderRound: suggestion.name,
                                                                // });
                                                                // this.props.setOrderId(suggestion.id, suggestion.name)
                                                            }}
                                                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                                                            />
                                                    </AutosuggestContainer>
                                                {
                                                    this.state.shipment_detail[index].transporterInvalid &&
                                                    <div style={{ fontSize: '16px', color: '#FF0000', height: '22px', textAlign: 'right', }}>
                                                        {this.state.shipment_detail[index].transporterInvalid ? 'กรุณาระบุนายหน้่าที่ไม่ซ้ำกัน' : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                                    </div>
                                                }
                                            </WrapInput>

                                        </RightSide>

                                    </BoxDetail>
                                </div>
                            )
                        }
                    </Wrapper>
                    <Button 
                        onClick={() => this.setState(prevState => ({ 
                            shipment_detail: [...prevState.shipment_detail, 
                                {
                                    shipment_quantity: '',
                                    shipment_quantityInvalid: false,

                                    truck_type: '4W',

                                    pickup_code: 'BKK2',

                                    transporter: '',
                                    transporterInvalid: false,

                                    agentSelected: '',
                                }
                            ]
                        }))} 
                        style={{
                            marginTop: 10,
                            display: !(this.state.order_type == 3 || this.state.order_type == 5 || this.state.order_type == 9) && 'none'
                        }} 
                    >
                        เพิ่มนายหน้า
                    </Button>
                </ModalConfirm>
        )
    }
}

export default PlanFromDateModal