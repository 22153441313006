import gql from 'graphql-tag';

export default gql`
    mutation accountInfoUpdate(
        $_id: ID
        $account_info_status: Int
    )
        {
        accountInfoUpdate(
            _id: $_id
            account_info_status: $account_info_status
        )
        {
            _id
        }
    }
`;
