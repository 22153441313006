import * as React from 'react';
import { TEXT_UPLOAD_FILE } from '../utils/useConstants';

export const FunctionRenameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};

const FieldUploadFile = ({ setValue = () => {}, name = 'name' }) => {
  const [fileName, setFileName] = React.useState('');
  const onClickUpLoadImage = async ({ target: { files } }) => {
    if (files) {
      const file = await files[0];

      const newName = `document_${new Date().getTime()}`;

      const newFile = FunctionRenameFile(file, newName);

      setFileName(file.name);

      setValue({ file: newFile, type: name });
    } else {
      return;
    }
  };

  return (
    <div
      style={{
        border: '1px solid #80808030',
        borderRadius: '5px',
        height: '52px',
        background: '#fff',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
    >
      <label
        style={{
          width: '100%',
        }}
        htmlFor={`index-reference-${name}`}
      >
        <div>
          <div
            style={{
              fontSize: '18px',
              padding: '5px',
              color: '#000',
            }}
          >
            <span
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <i style={{ marginRight: 'auto', marginLeft: 'auto' }} className="fas fa-upload"></i>
              {TEXT_UPLOAD_FILE} {fileName && ` : ${fileName}`}
            </span>
          </div>
          <input
            style={{
              display: 'none',
            }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            name={name}
            id={`index-reference-${name}`}
            type="file"
            onChange={onClickUpLoadImage}
          />
        </div>
      </label>
    </div>
  );
};

export default FieldUploadFile;
