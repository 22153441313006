import gql from 'graphql-tag';

export default gql`
query search(
  $page_number : Int
  $order_by : String
	$order_type : String
  $name : String
  $email : String
  $create_date : String
){
  contactList(
      page_number :$page_number,
      show_data: 30,
      order_by :$order_by,
	  	order_type :$order_type,
      name :$name,
	  	email :$email,
	  	create_date :$create_date
  	) {
      contactus{
        _id
        name
        lastName
        email
        phone
        msg
        status
        admin_id
        admin_username
        remark
        create_date
        last_update
     }
    total_page
  }
}
`;


