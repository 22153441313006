import gql from "graphql-tag";

export default gql`
  mutation LazadaShipmentsUpdate(
    $_id: ID!
    $shipment_status: Int
    $cancel_comment: String
  ) {
    shipmentsUpdate(
      _id: $_id
      shipment_status: $shipment_status
      cancel_comment: $cancel_comment
    ) {
      _id
    }
  }
`;
