import gql from 'graphql-tag';

export default gql`
query searchDriverUserList(
    $page_number : Int
    $order_by : String
    $order_type : String
    $driver_number: String
    $driver_name: String
    $driver_enable: Int
    $driver_block: Int
    $_id: ID
    $account_info_id: ID
    $driver_phone_number: String
    $last_update: String
    $user_setting_truck_license_plate: String
    $agent: ID
    $month_rate: Boolean
    $trip_rate: Boolean
  ){
    driverUserList(
      driver_number :$driver_number
      user_setting_truck_license_plate: $user_setting_truck_license_plate
      driver_block :$driver_block
      driver_name :$driver_name
      driver_enable :$driver_enable
      page_number :$page_number
      show_data: 30
      order_by : $order_by
      order_type : $order_type
      _id : $_id
      account_info_id : $account_info_id
      driver_phone_number : $driver_phone_number
			last_update : $last_update
			agent : $agent
      month_rate: $month_rate
      trip_rate: $trip_rate
    ){
      driver_user{
        _id
        driver_agent_detail{
          _id
          account_info_id
          agent_name
          driver_name
          user_setting_truck_license_plate
          user_setting_truck_license_plate_back
          month_rate
          trip_rate
        	driver_route_service{
          	provine
          	postcode
          	district
          	subdistrict
            region
            createdAt
            updatedAt
          }
        }
        account_info_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_enable
        driver_block
        driver_block_remark
        user_setting_truck_license_plate
        user_setting_truck_license_plate_back
        line_id
        reserve_phone_code
        reserve_phone_number
        criminal_history
        id_card_number
        id_card_pic{
          path
        }
        driver_card_pic{
          path
        }
        car_pic{
          path
        }
        license_plate_pic{
          path
        }
        act_pic{
          path
        }
        car_insurance_pic{
          path
        }
        book_bank_pic{
          path
        }
        setting_truck_service
        driver_number
        driver_type
        driver_delivery_vat
        driver_delivery_wht
        driver_service_vat
        driver_service_wht
        create_date
        last_update
        driver_license_number
        driver_license_exp
        driver_car_brand
        driver_display_pic
        working
        bank_account{
          _id
          bank_name
          bank_branch
          account_name
          account_number
        }
        branch
        address
        sub_district
        district
        province
        postcode
      }
      total_page
    }
  }
`;
