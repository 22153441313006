import gql from 'graphql-tag';

export default gql`
  query parcelCostStatus {
    parcelCostStatus {
      waitAgentConfirm
      waitingConfirm
      confirm
      reject
      edit
      cancel
    }
  }
`;
