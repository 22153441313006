import React, { Component } from 'react'
import { Query, graphql, compose, Mutation } from "react-apollo";
import { Grid, Row, Col, Table, OverlayTrigger, Button, Modal, DropdownButton, MenuItem, FormControl, Label, FormGroup, Popover, Tooltip, NavDropdown, NavItem, Dropdown, ButtonGroup, SplitButton } from "react-bootstrap";
import styled from 'styled-components';
import { SelectBox, Input, ModalConfirm } from './components'
import settingTruckListQuery from "./graphql/query/settingTruckList"
import thirdPartyList from './graphql/query/thirdPartyList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import manage3plAssignViewQuery from './graphql/query/manage3plAssignViewList';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import manage3plAssignAddMutation from './graphql/mutation/manage3plAssignAdd';
import manage3plAssignUpdateMutation from './graphql/mutation/manage3plAssignUpdate';

import { client } from '../../../../../../index';
import Moment from 'react-moment';
import moment from 'moment'
import NotificationSystem from 'react-notification-system';

const Content = styled.div`
  margin: 16px;

`;

const styleNotification = {
    NotificationItem: {
        DefaultStyle: {
            margin: '10px 5px 2px 1px',
        },

        error: {
            color: '#FFFFFF',
            backgroundColor: '#FC727A',
            border: '1px solid #FC727A',
            boxShadow: 'none',
            borderRadius: '4px',
        },

        success: {
            color: '#FFFFFF',
            backgroundColor: '#318DE8',
            border: '1px solid #318DE8',
            boxShadow: 'none',
            borderRadius: '4px',
        },
    },
};

const StyledRow = styled(Row)`
  margin-top: 16px;
`;

const Card = styled.div`
    padding: 16px;
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    margin-bottom: 16px;
`

const RightContent = styled.div`
    float: right;
    text-align: right;
`

const Section = styled.div`
    display: block;
    margin-bottom: 16px;
`

const StyledInput = styled.input`
    line-height: 1;
    font-size: 18px;
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    width: 50%;
    margin-right: 5px
    padding-left: 5px;
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  margin: unset;
`;

const TextRight = styled.div`
  height: 38px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const selectOptionTransporters = [
    { value: "0", label: "R3P" },
    { value: "1", label: "หจก.เอส พี ที 99 ทรานสปอร์ต" },
    { value: "2", label: "บริษัท ทรัพย์เพิ่มพูน 999 จำกัด" },
]

let truckList = []
let thirdPartyActiveList = []
let agentList = [];

class CreateShipmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {

            topId: '',

            //header

            warehouse: '',
            trip: '',
            trip_name: '',
            ka_id: '',
            importOrderId: '',
            selectedTransporter: { value: "", label: "" },
            truck_setting: [],

            //thirdpartyList
            ids:[],
            accountInfoIds:[],

            transporters: [],
            percent: [],

            carType: [],
            carTypeInvalid: [],
            carTypeInvalidMessege: [],
            carTypeLimitMessege: [],

            previousTrip: [],
            currentTrip: [],
            summaryTrip: [],

            subThirdpartyId: [],

            finalData: {},

            //modal confirm
            isOpenModalConfirm: false,
            isDisableButton: false,

            loadingData: true,

            //order type
            pickUpDate: '',
            orderType: ''
        }

        this.notificationSystem = React.createRef();
    }

    componentDidMount() {
        // const { shipment, admin } = this.props;
        // console.log('idBookingFile',this.props.idBookingFile)
        // console.log('fileNameBookingFile',this.props.fileNameBookingFile)

        this.getShipmentCreate()
    }

    getShipmentCreate = async () => {

        // after get data
        try {
            const { data: lazadaImportOrderList } = await client.query({
                query: lazadaImportOrderListQuery,
                fetchPolicy: 'network-only',
                variables: {
                    _id: this.props.idBookingFile
                }
            });

            let lazadaImportOrderListData = {}

            if(lazadaImportOrderList && lazadaImportOrderList.lazadaImportOrderList){
                if(lazadaImportOrderList.lazadaImportOrderList.order.length > 0){
                    lazadaImportOrderListData = lazadaImportOrderList.lazadaImportOrderList.order[0]
                }
            }
            // console.log('lazadaImportOrderListData',lazadaImportOrderListData)

            const { data: accountInfoList } = await client.query({
                query: accountInfoListQuery,
                fetchPolicy: 'network-only',
                variables: {
                    account_info_type: 3,
                }
            });

            if(accountInfoList && accountInfoList.accountInfoList){
                agentList = accountInfoList.accountInfoList.account_info
            }

            const { data } = await client.query({
                query: manage3plAssignViewQuery,
                fetchPolicy: 'network-only',
                variables: {
                    import_order_id: this.props.idBookingFile
                }
            });

            // console.log(data)
            if(data){
                if(data.manage3plAssignView.third_party_list){

                    let idsData = [...this.state.ids]
                    let accountInfoData = [...this.state.accountInfoIds]
                    let transporterData = [...this.state.transporters]
                    let percentData = [...this.state.percent]
                    let previousTripData = [...this.state.previousTrip]
                    let currentTripData = [...this.state.currentTrip]
                    let summaryTripData = [...this.state.summaryTrip]
                    let carTypeData = [...this.state.carType]
                    let carTypeInvalidData = [...this.state.carTypeInvalid]
                    let subThirdpartyIdData = [...this.state.subThirdpartyId]

                    const tplList = data.manage3plAssignView.third_party_list
                    tplList.map(item => {
                        idsData.push(item._id)
                        accountInfoData.push(item.account_info_id)
                        transporterData.push(item.account_info_id)
                        percentData.push(item.percent)
                        previousTripData.push(item.trip_previous ? item.trip_previous : 0)
                        currentTripData.push(item.trip ? item.trip : 0)
                        summaryTripData.push(item.trip + item.trip_previous)
                        subThirdpartyIdData.push(item.sub_thirdparty_id)

                        carTypeData.push(
                            item.trip_truck.map(truck => ({truck_id:truck.truck_id, truck_type: truck.truck_type , type: truck.name, number: truck.total_average, all: truck.total, limit: truck.total_assign})).filter(data => data.all !== null)
                        )
                    })

                    carTypeData.map((_, index) => carTypeInvalidData.push(false))
                    let carTypeInvalidMessegeData = carTypeData.map(() => "")

                    // console.log(carTypeData)
                    // console.log(data)
                    // console.log(carTypeInvalidData)
                    // console.log(carTypeInvalidMessegeData)

                    this.setState({
                        topId: data.manage3plAssignView._id,
                        ka_id: this.props.customerId,
                        importOrderId: data.manage3plAssignView.import_order_id ,
                        selectedTransporter: { value: data.manage3plAssignView.head_thrid_party, label: this.renderAgentName(agentList, data.manage3plAssignView.head_thrid_party) },
                        trip: data.manage3plAssignView.trip,
                        trip_name: data.manage3plAssignView.trip_name ? data.manage3plAssignView.trip_name : 0,
                        warehouse: data.manage3plAssignView.warehouse,
                        truck_setting: data.manage3plAssignView.trip_truck.filter(data => data.truck_id !== null).map(data => {
                            if(!data.total_average){
                                let newData = data
                                delete newData.__typename
                                newData.total_average = 0
                                return newData
                            }else{
                                delete data.__typename
                                return data
                            }
                        }),

                        //3pl list
                        ids: idsData,
                        accountInfoIds: accountInfoData,
                        transporters: transporterData,
                        percent: percentData,
                        previousTrip: previousTripData,
                        currentTrip: currentTripData,
                        summaryTrip: summaryTripData,
                        carType: carTypeData,
                        carTypeInvalid: carTypeInvalidData,
                        carTypeInvalidMessege: carTypeInvalidMessegeData,
                        finalData: {},
                        loadingData: false,
                        subThirdpartyId: subThirdpartyIdData,

                        //orderList
                        pickUpDate: lazadaImportOrderListData.pick_up_date,
                        orderType: lazadaImportOrderListData.order_type
                    })

                    this.checkInvalid()
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    checkInvalid = () => {
        // let currenPercent = 0
        // this.state.percent.map(item => currenPercent += item)

        // if (currenPercent !== 100) {
        //     this.setState({isDisableButton: true})
        //     return true
        // }

        let checkCar = false
        // console.log(this.state.carType)
        if (this.state.carType.length > 0 && this.state.carType[0].length > 0) {
            let invalidDate = [...this.state.carTypeInvalid]
            let messageError = [...this.state.carTypeInvalidMessege]
            this.state.carType[0].map((item, index) => {
                let currentCarNumber = 0
                this.state.carType.map((_, carIndex) => {
                    currentCarNumber += this.state.carType[carIndex][index].number
                })

                // console.log(`${currentCarNumber}/${item.all}`)

                if (currentCarNumber !== item.all) {

                    invalidDate[index] = true
                    messageError[index] = currentCarNumber > item.all ? `เกิน ${currentCarNumber - item.all} คัน` : `ขาด ${item.all - currentCarNumber} คัน`
                    
                    checkCar = true
                    // console.log(invalidDate)
                }else{
                    invalidDate[index] = false
                }
            })
            this.setState({
                carTypeInvalid: invalidDate,
                carTypeInvalidMessege: messageError
            })
            if (checkCar) {
                this.setState({isDisableButton: true})
                return true
            }
        }
        this.setState({isDisableButton: false})
        return false
    }

    handlerSubmitData = () => {
        if (this.checkInvalid()) {
            // console.log('invalid')
        } else {
            // console.log('valid')
            // console.log(this.state.carType)
            // console.log(this.state.selectedTransporter)
            // console.log(this.state.percent)
            // console.log(this.state.currentTrip)
            // console.log(this.state.summaryTrip)
            let information3PL = []
            // console.log(this.state.carType)
            let newCarType = this.state.carType.map(data => data.map(truck => 
                ({
                    truck_id: truck.truck_id,
                    truck_type: truck.truck_type,
                    name: truck.type,
                    total_average: truck.number,
                    // total_assign: truck.limit
                })    
            ))
            // console.log(newCarType)
            information3PL = this.state.ids.map((id, index) => {
                let agentName = this.renderAgentName(agentList, this.state.accountInfoIds[index])
                return (
                    {
                        account_info_id: this.state.accountInfoIds[index],
                        name_agent: agentName,
                        ka_id: this.props.customerId ,
                        _id: id,
                        percent: this.state.percent[index],
                        active: true,
                        trip_previous: this.state.previousTrip[index],
                        trip: this.state.currentTrip[index],
                        trip_amount: this.state.summaryTrip[index],
                        trip_truck: newCarType[index],
                        sub_thirdparty_id: this.state.subThirdpartyId[index]
                    }
                )
            }).filter(data => data.trip > 0);

            // console.log(information3PL)
            // console.log(moment(this.props.pickupDateForCreateShipment).format('YYYY-MM-DD'))

            let finalData = {
                _id: this.state.topId,
                ka_id: this.props.customerId,
                import_order_id: this.state.importOrderId ,
                head_thrid_party: this.state.selectedTransporter.value,
                name_head: this.state.selectedTransporter.label,
                order_type: this.state.orderType.toString(),
                name: this.props.bookingNumberForCreateShipment,
                trip: this.state.trip,
                trip_truck: this.state.truck_setting,
                trip_name: this.state.trip_name,
                warehouse: this.state.warehouse,
                pick_up_date: moment(this.state.pickUpDate).format('YYYY-MM-DD HH:mm:ss'),
                third_party_list: information3PL
            }

            // console.log(finalData)

            this.setState({
                isOpenModalConfirm: true,
                finalData: finalData
            })
        }
    }

    changeTransporters = (event) => {
        this.setState({
            selectedTransporter: { value: event.value, label: event.label }
        })
    }

    createShipmentData = (manage3plAssignAdd) => {
        manage3plAssignAdd(
            {
            variables: this.state.finalData
        }
        )
        // console.log(this.state.finalData)
    }   

    updateShipmentData = (manage3plAssignUpdate) => {
        // console.log(this.state.finalData)
        manage3plAssignUpdate(
            {
                variables: this.state.finalData
            }
        )
    }

    renderAgentName = (agentList, agentId) => {
        const transporterName = agentList.filter(agent => agent._id === agentId) 
        // console.log(agentList)
        if(transporterName[0])
            return transporterName[0].account_info_business_name
        return null
    }

    renderStatusMode = (status) => {
        switch(status){
            case 1:
                return <span>Last Mile (NM)</span>
            case 2:
                return <span>Return to Merchant (RM)</span>
            case 3:
                return <span>FIRST MILE (FM)</span>
            case 4:
                return <span>SPECIAL (SP)</span>
            case 5:
                return <span>Customer Return (CR)</span>
            case 6:
                return <span>Return to WH (RTWH)</span>
            case 7:
                return <span>Return to Customer (CR/RC)</span>
            case 8:
                return <span>Return to Merchant (CR/RM)</span>
            default:
                return null
        }
      }

      addNotification = (typeStatus, message) => {
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: (
                <span>
                    <strong>แจ้งเตือน</strong> {message}
                </span>
            ),
            level: typeStatus,
            position: 'tr',
        });
    };

    render() {
        if (
            this.props.settingTruckListQuery.loading === false &&
            this.props.settingTruckListQuery.settingTruckList !== undefined
        ) {
            truckList = [...this.props.settingTruckListQuery.settingTruckList];
            // console.log(truckList)
        }

        if (
            this.props.thirdPartyList.loading === false &&
            this.props.thirdPartyList.thirdPartyList.third_party_list !== undefined
        ) {
            thirdPartyActiveList = [...this.props.thirdPartyList.thirdPartyList.third_party_list]
            // console.log(thirdPartyActiveList)
        }

        let listForSelected = [];
        if(thirdPartyActiveList && agentList){
            thirdPartyActiveList.map((tpl) => {
                let agList = agentList.filter((ag) => ag._id === tpl.account_info_id)
                if(agList.length > 0)
                    listForSelected.push({value: agList[0]._id, label: agList[0].account_info_business_name})
            })
            // console.log(listForSelected)
        }

        return (
            <div>
                <Modal
                    show={this.props.showModal}
                    dialogClassName="modal80x80"
                >
                    <Modal.Header>
                        <Modal.Title>
                            <div>
                                <b>สร้างงานขนส่ง(shipment) : { this.renderStatusMode(this.state.orderType) } Trip</b>
                                <span style={{ float: "right" }} >File Name: 
                                    <b>
                                        {this.props.bookingNumberForCreateShipment}
                                    </b>
                                </span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body
                        style={{
                            height: 'calc(70vh)',
                            padding: 'unset',
                            overflow: 'auto'
                        }}
                    >
                        <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
                        <Content >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div>
                                    <span>วัน : 
                                        <b>
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                                {this.state.pickUpDate}
                                            </Moment>
                                        </b>
                                    </span>
                                    <span style={{ marginLeft: '10px' }}>คลัง : <b>{this.state.warehouse ? this.state.warehouse : '-'}</b></span>
                                    <span style={{ marginLeft: '10px' }}>รอบ : <b>{this.state.trip_name ? this.state.trip_name : '-'}</b></span>
                                    <span style={{ marginLeft: '10px' }}>จำนวน Trip : <b>{this.state.trip ? this.state.trip : '-'} คัน</b></span>
                                </div>
                                <div style={{ float: "right", display: 'flex', alignItems: "baseline" }}>
                                    คนดูแลงาน:
                                <SelectBox
                                        onChange={event => this.changeTransporters(event)}
                                        value={this.state.selectedTransporter}
                                        style={{
                                            width: '200px',
                                            marginLeft: '5px'
                                        }}
                                        options={listForSelected}
                                    />
                                </div>
                            </div>
                            {
                                this.state.loadingData ? <p>loading</p> : this.state.transporters &&
                            <StyledRow>
                                {this.state.transporters.map((item, index) =>
                                    <Col md={6}>
                                        <Card>
                                            <Section style={{ marginBottom: 'unset' }}>
                                                <Row>
                                                    <Col md={8}>3PL:</Col>
                                                    <Col md={4}>อัตราส่วน</Col>
                                                </Row>
                                            </Section>
                                            <Section>
                                                <Row style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <Col md={8}>
                                                        <b>{this.renderAgentName(agentList, this.state.transporters[index])}</b>
                                                    </Col>
                                                    <Col md={4}>
                                                        <InputGroup style={{ marginTop: 6 }}>
                                                            <Input
                                                                type="number"
                                                                min={0}
                                                                max={100}
                                                                value={this.state.percent[index]}
                                                                disabled={true}
                                                                onChange={event => {
                                                                    let shipmentPercent = this.state.percent
                                                                    shipmentPercent[index] = event.target.value

                                                                    this.setState({
                                                                        percent: shipmentPercent,
                                                                    })
                                                                }
                                                                }
                                                                style={{ width: '100%' }}
                                                                styleInput={{
                                                                    borderBottomRightRadius: 0,
                                                                    borderTopRightRadius: 0,
                                                                }}
                                                            />

                                                            <TextRight
                                                                style={{
                                                                    borderBottomLeftRadius: 0,
                                                                    borderTopLeftRadius: 0,
                                                                    borderLeftWidth: 0,
                                                                }}
                                                            >
                                                                %
                                                            </TextRight>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Section>
                                            <Section>
                                                ประเภทรถ:
                                            </Section>
                                            {
                                                this.state.carType[index] && this.state.carType[index].length > 0 &&
                                                this.state.carType[index].map((car, carIndex) =>
                                                car.all > 0 &&
                                                    <Section>
                                                        <Row>
                                                            <Col md={7}><b style={{ color: '#00BDF9' }}>{car.type}</b></Col>
                                                            <Col md={5}>
                                                                <StyledInput
                                                                    min={0}
                                                                    type="number"
                                                                    style={{ borderColor: this.state.carTypeInvalid[carIndex] ? 'red' : '#E3E3E3' }}
                                                                    value={car.number}
                                                                    onChange={event => {
                                                                        let carTypeData = this.state.carType
                                                                        let carTypeLimitMessegeData = [...this.state.carTypeLimitMessege]
                                                                        if (parseInt(event.target.value) > carTypeData[index][carIndex].all) {
                                                                            return
                                                                        }
                                                                        if (parseInt(event.target.value) < car.limit || event.target.value === "") {
                                                                            carTypeData[index][carIndex].number = car.limit
                                                                        } else {
                                                                            carTypeData[index][carIndex].number = parseInt(event.target.value)
                                                                        }

                                                                        carTypeLimitMessegeData[carIndex] = ""

                                                                        // console.log(this.state.carTypeInvalidMessege)

                                                                        // set current trip
                                                                            let currentTrip = this.state.currentTrip
                                                                            let nowCurrentTrip = 0
                                                                            // console.log(currentTrip)

                                                                            carTypeData[index].map((trip,indexTrip) => {
                                                                                nowCurrentTrip += trip.number 
                                                                            })

                                                                            currentTrip[index] = nowCurrentTrip;
                                                                        //

                                                                        // set summary trip
                                                                            let summaryTrip = this.state.summaryTrip
                                                                            let nowSummaryTrip = nowCurrentTrip + this.state.previousTrip[index]
                                                                            summaryTrip[index] = nowSummaryTrip
                                                                        //

                                                                        let carTypeInvalidData = [...this.state.carTypeInvalid]
                                                                        carTypeInvalidData[carIndex] = false

                                                                        this.setState({
                                                                            carType: carTypeData,
                                                                            carTypeInvalid: carTypeInvalidData,
                                                                            currentTrip: currentTrip,
                                                                            summaryTrip: summaryTrip
                                                                        })

                                                                        this.checkInvalid()
                                                                    }
                                                                    }
                                                                >
                                                                </StyledInput>
                                                                <b style={{ color: '#00BDF9' }}>/{car.all} คัน</b>
                                                                {
                                                                    this.state.carTypeInvalid[carIndex] && <span style={{color: 'red'}}><br/>{this.state.carTypeInvalidMessege[carIndex]}</span>
                                                                }
                                                                {
                                                                    car.limit > 0 ? <div  style={{color: 'red'}}> มีคนขับที่กำลังทำงานอยู่ {car.limit} คน </div> : <div style={{whiteSpace: 'pre-wrap'}}>{` `}</div>
                                                                }
                                                                </Col>
                                                        </Row>
                                                    </Section>
                                                )
                                            }
                                            <Section>
                                                <Row>
                                                    <Col md={4}>
                                                        จำนวนเที่ยวรอบที่แล้ว<br /><br />
                                                        <b>{this.state.previousTrip[index]} คัน</b>
                                                    </Col>
                                                    <Col md={4}>
                                                        จำนวนเที่ยวรอบนี้<br /><br />
                                                        <b>{this.state.currentTrip[index]} คัน</b>
                                                    </Col>
                                                    <Col md={4}>
                                                        จำนวนเที่ยวรวม<br /><br />
                                                        <b>{this.state.summaryTrip[index]} คัน</b>
                                                    </Col>
                                                </Row>
                                            </Section>
                                        </Card>
                                    </Col>
                                )}
                            </StyledRow>
                        }
                        </Content>
                    </Modal.Body>
                    <Modal.Footer
                        style={{ padding: '10px 15px' }}
                    >
                        <Button style={{ padding: "5px 15px" }} onClick={this.props.closeModal} >ยกเลิก</Button>
                        <Button style={{ background: "#87CB16", color: "#ffffff", padding: "5px 15px", marginLeft: "10px" }} disabled={this.state.isDisableButton} onClick={this.handlerSubmitData}>สร้าง</Button>
                    </Modal.Footer>
                </Modal>

                <Mutation
                    mutation={manage3plAssignUpdateMutation}
                    update={(cache, { data }) => {
                        // if(data._id){
                            // this.props.refetchLazadaImportOrderList()
                            this.props.closeModal()
                        // }else{
                        //     this.addNotification('error', '_id: null');
                        // }
                    }}
                    onError={error => {
                        // console.log(error)
                        this.addNotification('error', error.message.replace("GraphQL error: ",""));
                        // return false
                    }}
                    refetchQueries={[`shipmentAdminSubThirdpartys`]}
                >
                {(manage3plAssignUpdate, { loading, refetch }) => (
                    this.state.isOpenModalConfirm &&
                    <ModalConfirm
                        show={this.state.isOpenModalConfirm}
                        onPressButtonConfirm={() => this.updateShipmentData(manage3plAssignUpdate)}
                        styleColorButtonConfirm="primary"
                        labelButtonConfirm={'สร้าง'}
                        labelButtonCancel={`ยกเลิก`}
                        onPressButtonCancel={() => this.setState({ isOpenModalConfirm: false })}
                        titleHeader={`คุณสร้างงานขนส่ง(Shipment) จาก File นี้ ใช่หรือไม่?`}
                        onHide={() => this.setState({ isOpenModalConfirm: false })}
                    >
                        <div style={{ lineHeight: 1 }}>
                        <strong>File Name: </strong>{this.props.bookingNumberForCreateShipment}<br/>
                        <strong>ประเภทงาน :</strong> {this.renderStatusMode(this.state.orderType)}<br/>
                        <strong>วันที่เริ่มงาน :</strong> <Moment format="DD/MM/YYYY HH:mm:ss">{this.state.pickUpDate}</Moment><br/>
                        {
                            this.state.finalData.trip_truck.map(
                            data => 
                            <div>
                                <br/>
                                <strong>ประเภทรถ :&nbsp;</strong>{data.name} ทั้งหมด {data.total} คัน
                                {this.state.finalData.third_party_list.map(tpl => tpl.trip_truck.filter(tripTruck => tripTruck.truck_id === data.truck_id).filter(truck3pl => truck3pl.total_average > 0).map(final => <div>&nbsp;- {tpl.name_agent} {final.total_average} คัน</div>))}
                            </div>
                        )
                    }
                        <br/>        
                        <strong>คนคุมงาน + ดูแลคิว + ดูแลใบงาน :</strong> {this.state.finalData.name_head}
                        </div>
                    </ModalConfirm>
                )}   
                </Mutation>
                

            </div>
        );
    }
}

export default compose(
    graphql(thirdPartyList, { 
        name: 'thirdPartyList',
        options: props => ({
            fetchPolicy: 'network-only',
            variables: {
                active: true,
                ka_id: props.customerId
            },
        }),
    }),
    graphql(accountInfoListQuery, {
        name: 'accountInfoListQuery',
        options: () => ({
            fetchPolicy: 'network-only',
            variables: {
                account_info_type: 3,
            },
        }),
    }),
    graphql(settingTruckListQuery, { name: 'settingTruckListQuery' }),
)(CreateShipmentModal);