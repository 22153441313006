import gql from 'graphql-tag';

export default gql`
  query($month: String) {
    trackingCodByCashCalendar(month: $month) {
      day
      month
      date
      total_price
      total_tracking
      wait_tranfer_price
      total_tracking_wait_tranfer
    }
  }
`;
