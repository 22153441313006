import styled from 'styled-components'

export const CustomPhoto = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  // border: 2px solid #E3E3E3;
  position: relative;
  cursor: pointer;

  & i {
    position: absolute;
    font-size: 18px;
    margin: 5px 5px;
    top: 5px;
    right: 5px;
  }

  & .fas {
    color: #ffff;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;