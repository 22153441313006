import React, { Component } from 'react';
import {
  HeaderWrapper,
  CenterVerticalSpan,
  Line,
  ContentWrapper,
  BoxGridWrapper,
  UlDecorated,
  HeadWrapper,
  CodNumberStyled,
  TableWrapper,
  Tag,
  FlexListRow,
} from './styled';
import { Table } from 'react-bootstrap';
import PhotoBox from '../../../../components/PhotoBox';
import s3 from 'config/s3';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import formatMoney from 'utils/formatMoney';

export class CodTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenLightbox: false,
      viewImageSrc: '',
    };
  }

  renderCodStatus = (cod_status, confirm_cod_status) => {
    //billing_cod > cod_status
    if (confirm_cod_status === 3) {
      switch (cod_status) {
        case 1:
          return <Tag>รอ OPS ตรวจสอบ</Tag>;
          break;

        case 2:
          return <Tag>รอบัญชีตรวจสอบ</Tag>;
          break;

        case 3:
          return <Tag backgroundColor={'#50B35C'}>เสร็จสิ้น</Tag>;
          break;

        case 4:
          return <Tag backgroundColor={'red'}>ถูกปฏิเสธ COD</Tag>;
          break;

        case 5:
          return <Tag backgroundColor={'red'}>ถูกยกเลิก</Tag>;
          break;

        default:
          return <span>-</span>;
          break;
      }
    } else if (confirm_cod_status === 2) {
      return <Tag backgroundColor={'#E28200'}>รอจ่าย COD</Tag>;
    }
  };

  filterPriceCod = data => (data.cod_price ? data.cod_price > 0 : false);

  filterDelivery = data => data.final_status == 3;

  renderLightbox(pathUrl) {
    this.setState({
      isOpenLightbox: true,
      viewImageSrc: pathUrl,
    });
  }

  render() {
    return (
      <div>
        <HeaderWrapper>
          <CenterVerticalSpan>
            พนักงานขับรถ : <b>{this.props.driverName || '-'}</b>
          </CenterVerticalSpan>
          <Line />
          <CenterVerticalSpan>
            ทะเบียนรถ :<b>{this.props.user_setting_truck_license_plate || '-'}</b>
          </CenterVerticalSpan>
        </HeaderWrapper>
        <ContentWrapper>
          <BoxGridWrapper>
            <UlDecorated>
              <li>
                รหัส COD-IN:<b>{this.props.cod_in_number || '-'}</b>
              </li>
              <li>
                วันที่จ่าย COD:<span>{this.props.cod_in_date || '-'}</span>
              </li>
              <li>
                สถานะ COD:
                {this.renderCodStatus(this.props.cod_status, this.props.confirm_cod_status)}
              </li>
              <li>
                หลักฐานการโอน:
                <FlexListRow>
                  {this.props.document && this.props.document.length > 0
                    ? this.props.document.map(data => (
                        <PhotoBox
                          pathUrl={data}
                          noDelete={true}
                          renderLightbox={() => this.renderLightbox(data)}
                        />
                      ))
                    : '-'}
                </FlexListRow>
              </li>
              {this.props.cod_status === 4 && (
                <li>
                  หลักฐานการปฏิเสธ:
                  <span style={{ color: 'red', fontWeight: 'bold' }}>
                    {this.props.reject_remark}
                  </span>
                </li>
              )}
            </UlDecorated>
          </BoxGridWrapper>
          <BoxGridWrapper>
            <HeadWrapper>
              <span>รายการสินค้า</span>
              <span>
                ยอดรวม COD ที่ต้องโอน(บาท):{' '}
                <CodNumberStyled>
                  {this.props.shipment_accept_cod ? formatMoney(this.props.shipment_accept_cod) : 0}
                </CodNumberStyled>
              </span>
            </HeadWrapper>
            <TableWrapper className="table-vertical">
              <Table style={{ marginBottom: '0px' }} striped hover>
                <thead>
                  <tr>
                    <td style={{ minWidth: '200px' }}>Tracking Number</td>
                    <td style={{ minWidth: '100px' }}>COD (บาท)</td>
                    <td style={{ minWidth: '100px' }}>ประเภทชำระ</td>
                    <td style={{ minWidth: '100px' }}>Ref. 1</td>
                    <td style={{ minWidth: '100px' }}>Ref. 2</td>
                  </tr>
                </thead>

                {this.props.loading ? (
                  <tbody>
                    <tr>
                      <td>Loading</td>
                      <td> </td>
                    </tr>
                  </tbody>
                ) : this.props.trackingList &&
                  this.props.trackingList.filter(this.filterPriceCod).filter(this.filterDelivery)
                    .length == 0 ? (
                  <tbody>
                    <tr>
                      <td>ไม่มีรายการ</td>
                      <td></td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {this.props.trackingList &&
                      this.props.trackingList.length > 0 &&
                      this.props.trackingList
                        .filter(this.filterPriceCod)
                        .filter(this.filterDelivery)
                        .map((data, index) => {
                          return (
                            <tr key={`keys-table-ka-shipment-${index}`}>
                              <td>{data.commodity[0].name}</td>
                              <td>{data.cod_price ? formatMoney(data.cod_price) : 0}</td>
                              <td
                                style={{
                                  color: 'green',
                                }}
                              >
                                {data.cod_qr ? 'QR Direct' : 'เงินสด'}
                              </td>
                              <td> {data.cod_qr ? data?.qr_ref1 : '-'}</td>
                              <td>{data.cod_qr ? data?.qr_ref2 : '-'}</td>
                            </tr>
                          );
                        })}
                  </tbody>
                )}
              </Table>
            </TableWrapper>
          </BoxGridWrapper>
        </ContentWrapper>

        {this.state.isOpenLightbox && (
          <Lightbox
            style={{ zIndex: '9999' }}
            mainSrc={this.state.viewImageSrc}
            onCloseRequest={() => this.setState({ isOpenLightbox: false, viewImageSrc: '' })}
          />
        )}
      </div>
    );
  }
}

export default CodTab;
