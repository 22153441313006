import React from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
  Popover,
} from 'react-bootstrap';
import styled from 'styled-components';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import Moment from 'react-moment';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import newCodStatus from './graphql/query/newCodStatus';
import newCods from './graphql/query/newCods';
import { client } from '../../index.js';
import { CardTable } from 'components';
import { Layout, SubHeader, ModalConfirm, Tabs } from './components';
import urijs from 'urijs';
import PayCodModal from './components/PayCodModal';
import refetchQueries from 'utils/refetchQuery';
import AutoSuggestTransporter from './components/AutoSuggestTransporter';

const StyledHeader = styled.div`
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
`;
const CenterVerticalSpan = styled.span`
  display: flex;
  align-items: center;
`;
const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)``;
const AutosuggestContainer = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;
const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #e3e3e3;
`;

class COD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_by: null,
      order_type: null,
      codStatus: '',
      codInNumber: '',
      shipmentNumber: '',
      createDate: '',
      displayCreateDate: '',
      pageNumber: 1,
      codInStatus: null,
      isOpenModalConfirm: false,
      idNewCod: null,
      isPayCodModal: false,
      codStatusModal: '',
      vendorId: '',

      onceCheck: true,

      transporterId: '',
    };
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  handleDateRangePicker(mode, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({ pickupDate: date, displayPickupDate: display });
    }
  }

  getParamUrl = () => {
    const uriRoute = urijs(window.location.href);
    const uriFragment = uriRoute.fragment(true);
    const pathname = uriFragment.pathname();

    return pathname;
  };

  setActive = path => {
    const paramUrl = this.getParamUrl();
    return path === paramUrl;
  };

  openUrl = url => {
    window.location = url;
  };

  getStatusFromUrl = status => {
    let statusUrl = urijs(window.location.href).query(true).status;
    if (statusUrl === undefined) {
      statusUrl = 'inprogress';
    }
    return status === statusUrl;
  };

  formatMoney = number => {
    return `฿ ${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;
  };

  setCodStatus = status => {
    this.setState({
      codInStatus: status,
      pageNumber: 1,
    });
  };

  getCodStatus = () => {
    return this.getStatusFromUrl('inprogress')
      ? 1
      : this.getStatusFromUrl('reject')
      ? 4
      : this.getStatusFromUrl('inprogress-acc')
      ? 2
      : 3;
  };

  changeUrl = stage => {
    window.history.pushState(
      'data',
      null,
      `/cod-shipment?${new URLSearchParams({
        status: stage,
      }).toString()}`
    );
  };

  getDataLastSlashUrl = () => {
    const url = window.location.href;
    const parts = url.split('/');
    const id = parts[parts.length - 1];
    // console.log('id: ', id);
    return id.includes('cod-shipment') ? null : id;
  };

  getCodListQuery = async id => {
    try {
      const { data } = await client.query({
        query: newCods,
        variables: {
          showData: 1,
          page: 1,
          params: {
            _id: id,
          },
        },
      });

      if (data && data.newCods && data.newCods.cods.length > 0) {
        const codSet = data.newCods.cods[0];

        this.setState({
          isPayCodModal: true,
          idNewCod: codSet._id,
          codStatusModal: codSet.cod_status,
          vendorId: codSet.vendor.external_system_id,
          onceCheck: false,
        });
      }
    } catch (error) {
      // alert(error);
    }
  };

  onChangeTransporter = id => {
    this.setState({
      transporterId: id,
    });
  };

  render() {
    return (
      <Layout isShipmentList>
        <Query query={newCodStatus} fetchPolicy="cache-and-network">
          {({ data, loading, error }) => {
            // if (error) {
            //   return <div>Error: {error.graphQLErrors[0].message} </div>;
            // }

            // if (data) {
            return (
              <Tabs
                menu={[
                  {
                    icon: 'fas fa-file-alt',
                    title: 'รอ 3PL จ่าย',
                    active: this.getStatusFromUrl('inprogress'),
                    onPress: () => {
                      this.setCodStatus(1);
                      if (data && data.newCodStatus) {
                        refetchQueries(['newCodStatus', 'newCods']);
                      }
                      this.changeUrl('inprogress');
                    },
                    number: data ? (data.newCodStatus ? data.newCodStatus.ops_pending : 0) : 0,
                    // toUrl: '/cod?status=inprogress',
                  },
                  {
                    icon: 'fas fa-file-alt',
                    title: 'รอบัญชียืนยัน',
                    active: this.getStatusFromUrl('inprogress-acc'),
                    onPress: () => {
                      this.setCodStatus(2);
                      if (data && data.newCodStatus) {
                        refetchQueries(['newCodStatus', 'newCods']);
                      }
                      this.changeUrl('inprogress-acc');
                    },
                    number: data ? (data.newCodStatus ? data.newCodStatus.pending : 0) : 0,
                    // toUrl: '/cod?status=inprogress-acc',
                  },
                  {
                    icon: 'fas fa-file-excel',
                    title: 'ถูกปฎิเสธ COD',
                    active: this.getStatusFromUrl('reject'),
                    onPress: () => {
                      this.setCodStatus(4);
                      if (data && data.newCodStatus) {
                        refetchQueries(['newCodStatus', 'newCods']);
                      }
                      this.changeUrl('reject');
                    },
                    number: data ? (data.newCodStatus ? data.newCodStatus.reject : 0) : 0,
                    // toUrl: '/cod?status=reject',
                  },
                  {
                    icon: 'fas fa-file-check',
                    title: 'เสร็จสิ้น',
                    active: this.getStatusFromUrl('completed'),
                    onPress: () => {
                      this.setCodStatus(3);
                      if (data && data.newCodStatus) {
                        refetchQueries(['newCodStatus', 'newCods']);
                      }
                      this.changeUrl('completed');
                    },
                    number: data ? (data.newCodStatus ? data.newCodStatus.confirm : 0) : 0,
                    // toUrl: '/cod?status=completed',
                  },
                ]}
                bold
              />
            );
            // }
          }}
        </Query>
        <Grid fluid>
          <div>
            <Row>
              <Col md={12}>
                <div>
                  <CardTable
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Query
                        query={newCods}
                        variables={{
                          showData: 30,
                          page: this.state.pageNumber,
                          sort: {
                            order_by: this.state.order_by || '',
                            order_type: this.state.order_type || '',
                          },
                          params: {
                            cod_in_number: this.state.codInNumber,
                            shipment_numbers: this.state.shipmentNumber,
                            cod_status: this.state.codInStatus || this.getCodStatus(),
                            create_date: this.state.createDate,
                            vendor: this.state.transporterId,
                          },
                        }}
                        fetchPolicy="cache-and-network"
                      >
                        {({ data, loading, error, refetch }) => {
                          let cods = [];
                          let totalPage = 0;

                          if (!loading && !error && data && data.newCods) {
                            cods = [...data.newCods.cods];
                            totalPage = data.newCods.totalPage;

                            const newId = this.getDataLastSlashUrl();

                            if (newId && this.state.onceCheck) {
                              this.getCodListQuery(newId);
                            }
                          }

                          return (
                            <div>
                              <Table striped hover responsive style={{ marginBottom: '80px' }}>
                                <thead>
                                  <tr>
                                    <th style={{ width: '80px' }}>เครื่องมือ</th>
                                    <th
                                      style={{ width: '200px' }}
                                      className={this.renderSorting('cod_in_number')}
                                      onClick={() => this.sort('cod_in_number', 'refetch')}
                                    >
                                      COD-IN Number
                                    </th>
                                    <th style={{ width: '150px', maxWidth: '150px' }}>ชื่อ 3PL</th>
                                    <th style={{ width: '160px' }}>ยอดรวม COD (บาท)</th>
                                    <th
                                      style={{ width: '200px' }}
                                      className={this.renderSorting('shipment_numbers')}
                                      onClick={() => this.sort('shipment_numbers', 'refetch')}
                                    >
                                      Shipment Number
                                    </th>
                                    {this.getStatusFromUrl('reject') && (
                                      <th style={{ minWidth: '200px' }}>เหตุผลการปฎิเสธ</th>
                                    )}
                                    <th>วัน/เวลาที่ส่ง COD</th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th style={{ maxWidth: '200px' }}>
                                      <FormControl
                                        type="text"
                                        placeholder="ค้นหาเลข COD-IN Number"
                                        value={this.state.codInNumber}
                                        onChange={event => {
                                          this.setState({
                                            codInNumber: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        name="codInNumber"
                                      />
                                    </th>
                                    <th style={{ position: 'relative', maxWidth: '100px' }}>
                                      <AutoSuggestTransporter
                                        setIdSearch={id => this.onChangeTransporter(id)}
                                      />
                                    </th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        placeholder="ค้นหาเลข Shipment Number"
                                        value={this.state.shipmentNumber}
                                        onChange={event => {
                                          this.setState({
                                            shipmentNumber: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        name="shipmentNumber"
                                      />
                                    </th>
                                    {this.getStatusFromUrl('reject') && <th></th>}
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker('createDate', picker)
                                        }
                                        onCancel={() =>
                                          this.setState({
                                            createDate: '',
                                            displayCreateDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        containerStyles={{
                                          display: 'block',
                                        }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.displayCreateDate}
                                          placeholder="วัน/เวลาที่ส่ง COD"
                                        />
                                      </DateRangePicker>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading && (
                                    <tr>
                                      {this.getStatusFromUrl('reject') ? (
                                        <td colSpan="6">Loading...</td>
                                      ) : (
                                        <td colSpan="5">Loading...</td>
                                      )}
                                    </tr>
                                  )}

                                  {error && (
                                    <tr>
                                      {this.getStatusFromUrl('reject') ? (
                                        <td colSpan="6">error</td>
                                      ) : (
                                        <td colSpan="5">error</td>
                                      )}
                                    </tr>
                                  )}
                                  {!loading && !error && cods.length === 0 && (
                                    <tr>
                                      {this.getStatusFromUrl('reject') ? (
                                        <td colSpan="6">ไม่มีข้อมูล</td>
                                      ) : (
                                        <td colSpan="5">ไม่มีข้อมูล</td>
                                      )}
                                    </tr>
                                  )}
                                  {cods.map((item, index) => (
                                    <tr>
                                      <td>
                                        <div
                                          onClick={() =>
                                            this.setState({
                                              isPayCodModal: true,
                                              idNewCod: item._id,
                                              codStatusModal: item.cod_status,
                                              vendorId: item.vendor.external_system_id,
                                            })
                                          }
                                          target="_blank"
                                          style={{ float: 'left' }}
                                          className="btn btn-default"
                                        >
                                          <i
                                            style={{ marginTop: '3px' }}
                                            className="fas fa-search"
                                          ></i>
                                        </div>
                                      </td>
                                      <td>{item.cod_in_number}</td>
                                      <td>{item.vendor ? item.vendor.full_name || '-' : '-'}</td>
                                      <td>
                                        <b>{this.formatMoney(item.total_cod)}</b>
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          trigger="hover"
                                          key="bottom"
                                          placement="bottom"
                                          overlay={
                                            <Popover id={`popover-positioned-1`}>
                                              {item.shipment_numbers.map((numbers, key) => (
                                                <p>{numbers}</p>
                                              ))}
                                            </Popover>
                                          }
                                        >
                                          <span
                                            style={{
                                              cursor: 'pointer',
                                              color: '#0C77FF',
                                            }}
                                          >
                                            {item.shipment_numbers.length} รายการ
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      {this.getStatusFromUrl('reject') && (
                                        <td
                                          style={{ maxWidth: '400px', overflowWrap: 'break-word' }}
                                        >
                                          <span style={{ color: '#D80000' }}>
                                            {item.reject_remark}
                                          </span>
                                        </td>
                                      )}
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {item.create_date}
                                        </Moment>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>

                              <div
                                style={{
                                  textAlign: 'right',
                                  marginTop: 20,
                                }}
                              >
                                {!loading && !error && totalPage !== 0 && (
                                  <ReactPaginate
                                    pageCount={totalPage}
                                    forcePage={this.state.pageNumber - 1}
                                    containerClassName="pagination"
                                    nextLabel=">"
                                    previousLabel="<"
                                    onPageChange={({ selected }) => {
                                      this.setState({
                                        pageNumber: selected + 1,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Query>
                    }
                  />
                </div>
              </Col>
            </Row>

            {this.state.isPayCodModal && (
              <PayCodModal
                show={this.state.isPayCodModal}
                close={() => this.setState({ isPayCodModal: false, idNewCod: null })}
                defaultList={[]}
                idNewCod={this.state.idNewCod}
                hideAddCodBTN
                hideTools
                cod_status={this.state.codStatusModal}
                vendorId={this.state.vendorId}
              />
            )}
          </div>
        </Grid>
      </Layout>
    );
  }
}

export default compose(graphql(newCodStatus, { name: 'newCodStatusQuery' }))(COD);
