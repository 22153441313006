import styled from 'styled-components';

const formatNumber = number => {
  return `${number.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  })}`;
};

export const NumberIndicator = styled.div`
  position: relative;
  display: inline-block;
  width: ${props =>
    props.num
      ? `${props.num > 999 ? '50px' : props.num > 99 ? '40px' : props.num > 9 ? '30px' : '20px'}`
      : '20px'};
  height: 20px;
  border-radius: ${props => (props.num ? `${props.num > 9 ? '10px' : '50%'}` : '50%')};
  background: #d6d6d6;
  color: #000;

  &::before {
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    content: "${props => (props.num ? formatNumber(props.num) : '0')}";
    transform: translate(50%, -50%);
    right: 50%;
    top: 50%;
  }
`;

export const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  margin-top: -15px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-itemsd: center;
        gap: 5px;
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        color: #d90402;
        & a {
          color: #d90402;
        }

        & ${NumberIndicator} {
            background: #d90402
            color: #fff
        }
      }
    }
  }
`;
