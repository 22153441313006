import React, { Component } from 'react'
import ModalConfirm from './components/ModalConfirm'
import TextArea from './components/TextArea'
import subThirdPartySettingUpdateMutation from './graphql/mutation/subThirdPartySettingUpdate'
import { Query, graphql, compose, Mutation } from "react-apollo";

class DataDeniedModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            problemDetail: '',
            problemDetailInvalid: false
        }
    }

    checkInvalid = () => {
        this.setState({
            problemDetailInvalid: this.state.problemDetail ? false : true
        })

        return !(this.state.problemDetail)
    }

    handlerSubmit = (subThirdPartySettingUpdate) => {
        if (this.checkInvalid()) {
            console.log('invalid')
        } else {
            console.log('valid')

            let dummyData = {
                _id: this.props.subThirdPartyId,
                // เปลี่ยนเป็นสถานะไม่ผ่าน
                agent_shipment_status: 9,
                admin_reject_detail: this.state.problemDetail
            }

            console.log(dummyData)

            subThirdPartySettingUpdate({
                variables: {
                    _id: this.props.subThirdPartyId,
                    agent_shipment_status: 9,
                    admin_reject_detail: this.state.problemDetail
                }
            })
        }
    }

    render() {
        return (
            <Mutation
                mutation={subThirdPartySettingUpdateMutation}
                update={(cache, { data }) => {
                    this.props.closeModal()
                }}
                onError={error => {
                    console.log(error);
                    this.addNotification('error', error.message.replace("GraphQL error: ", ""));
                }}
                refetchQueries={() => ["shipmentAgentView"]}
            >
                {(subThirdPartySettingUpdate) => {
                    return (

                        <ModalConfirm
                            show={this.props.isDataDeniedModal}
                            onPressButtonCancel={this.props.closeModal}
                            onPressButtonConfirm={() => this.handlerSubmit(subThirdPartySettingUpdate)}
                            labelButtonConfirm={`ใช่, ฉันยืนยัน`}
                            labelButtonCancel={`ไม่ใช่`}
                            // disabledButtonConfirm={this.state.yearholidays == ''}
                            styleColorButtonConfirm="green"
                            titleHeader={`แจ้งเตือน`}
                            onHide={() =>
                                this.setState({
                                    isDataDeniedModal: false,
                                })
                            }
                        >
                            <div style={{ lineHeight: 1 }}>
                                ต้องการปฎิเสธข้อมูลนี้ใช้หรือไม่?<br />
                                                Trip ที่มี trackingโดนปฏิเสธข้อมูล :<br />
                                <TextArea
                                    onChange={event =>
                                        this.setState({
                                            problemDetail: event.target.value,
                                            problemDetailInvalid: false,
                                        })
                                    }
                                    value={this.state.problemDetail}
                                    inputLabel="เหตุผลที่ปฏิเสธข้อมูล"
                                    style={{ marginTop: '10px' }}
                                    placeholder="ระบุ"
                                    required
                                    messageError={this.state.problemDetailInvalid &&
                                        'กรุณากรอกข้อมูล'}
                                />
                            </div>
                        </ModalConfirm>

                    )
                }}
            </Mutation>
        );
    }
}

export default DataDeniedModal