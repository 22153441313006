import styled from 'styled-components';
import { Checkbox } from 'react-bootstrap';

export const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  // justify-content: space-between;
  flex-direction: row;
  align-items: start;
  padding: 10px;
  position: relative;

  & button {
    transition: width 1s ease-in-out;
  }

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
  
      & button {
        transition: width 1s ease-in-out;
      }

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  background-color: #f4f4f4;
  height: 60px;
  border: 1px solid #e3e3e3;
  border-bottom: unset;
`;

export const CardContainer = styled.div`
  position: relative;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 0;
  display: block;
  flex-direction: row;
  margin-bottom: 15px;
  height: calc(90vh - 150px);
`;

export const ColLeft = styled.div`
  min-width: 237px;
  max-width: 237px;
  border-right: 0.5px solid #dbdbdb;
  background-color: #ffff;
  height: 100%;
  overflow: auto;
`;

export const ColRight = styled.div`
    padding 14px;
    padding-top: 0px;
    width: 100%;
    overflow: auto;
`;

export const UlTruck = styled.ul`
  padding: 0;
  max-height: calc(100vh - 200px);

  & li {
    position: relative;
    list-style: none;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    cursor: pointer;
    color: #c4c4c4;

    & span {
      font-weight: bold;
      opacity: 0.5;
    }

    &:hover {
      background-color: #fafafa;
    }

    &.active {
      & span {
        opacity: 1;
      }
      background: rgba(0,0,0,0.05);

      .fa-chevron-right {
        font-weight: bold;
      }
    }
  }
  & li > i {
    position: absolute;
    right: 5px;
    top: 15px;
    font-size: 12px;
  }
`;

export const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  margin-left: ${({left}) => left && left};
  border-left: 1px solid #e3e3e3;
`;

export const TextHeader = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  padding: 15px;
`;

export const CenterVerticalSpan = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomCheckbox = styled(Checkbox)`
  padding-left: 35px !important;
  margin-top: 12px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  &:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }

  & input:checked ~ .checkmark {
    border: 1px solid #ffffff;
    background-color: #ff0000;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark:before {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & input:checked ~ .checkmark:before {
    display: block;
  }

  & .checkmark:after {
    left: 6.8px;
    top: 2.9px;
    width: 4px;
    height: 13px;
    background-color: #ffffff;
    transform: rotate(45deg);
  }

  & .checkmark:before {
    left: 6.8px;
    top: 2.9px;
    width: 4px;
    height: 13px;
    background-color: #ffffff;
    transform: rotate(315deg);
  }
`;

export const styleddefault = {
  backgroundColor: '#B3B3B3',
  border: '1px solid #B3B3B3',
  color: '#ffffff',
  fontWeight: 'bold',
};
export const styleddefaultoutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #B3B3B3',
  color: '#B3B3B3',
  fontWeight: 'bold',
};

export const styledprimary = {
  backgroundColor: '#318DE8',
  border: '1px solid #318DE8',
  color: '#ffffff',
  fontWeight: 'bold',
};
export const styledprimaryoutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #318DE8',
  color: '#318DE8',
  fontWeight: 'bold',
};

export const styledsuccess = {
  backgroundColor: '#66C066',
  border: '1px solid #66C066',
  color: '#ffffff',
  fontWeight: 'bold',
};
export const styledsuccessoutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #66C066',
  color: '#66C066',
  fontWeight: 'bold',
};
export const styleddark = {
  backgroundColor: '#ffffff',
  border: '1px solid #000000',
  color: '#000000',
  fontWeight: 'bold',
};
export const styleddanger = {
  backgroundColor: ' #FF0000',
  border: '1px solid #FF0000',
  color: '#ffffff',
  fontWeight: 'bold',
};
export const styleddangeroutline = {
  backgroundColor: '#ffffff',
  border: '1px solid #FF0000',
  color: '#FF0000',
  fontWeight: 'bold',
};
export const styledwarning = {
  backgroundColor: '#ffffff',
  border: '1px solid #FF9D00',
  color: '#FF9D00',
  fontWeight: 'bold',
};