import React, { Component } from 'react';
import urijs from 'urijs';
import styled from 'styled-components';
import {
    Grid,
    Row,
    Col,
    Label,
    Button,
    FormControl,
    OverlayTrigger,
    Tooltip,
    Table,
} from 'react-bootstrap';
import CardTable from 'components/CardTable/CardTable.jsx';
import moment from 'moment'
import Autosuggest from 'react-autosuggest';
import ReactPaginate from 'react-paginate';
import SubHeaderCOD from '../../components/SubHeaderCOD';
import agentManageCODListQuery from './graphql/query/agentManageCODList';
import driverUserListQuery from './graphql/query/driverUserList';
import { client } from '../../../../index';
import { Query, graphql, compose, Mutation } from "react-apollo";
import ProofofTransferModal from './components/ProofofTransferModal';
import AccountInfoList from './graphql/query/accountInfoList';
import shipmentAgentViewQuery from './graphql/query/shipmentAgentView';

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    background-color: #F4F4F4;
    height: 60px;
    border: 1px solid #E3E3E3;
    border-bottom: unset;
`;
const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;
const CardContainer = styled.div`
    position: relative;
	border-radius: 6px;
	border: 1px solid #E3E3E3;
	background-color: #FFFFFF;
	padding: 0;
    display: block;
    flex-direction: row;
    margin-bottom: 15px;
    height: calc(90vh - 150px);
`;
const CenterVerticalSpan = styled.span`
    display: flex;
    align-items: center;
`;
const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #E3E3E3;
`;

const AutosuggestContainer = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const styleddefault = {
    backgroundColor: '#B3B3B3',
    border: '1px solid #B3B3B3',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styleddefaultoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #B3B3B3',
    color: '#B3B3B3',
    fontWeight: 'bold',
};

const styledprimary = {
    backgroundColor: '#318DE8',
    border: '1px solid #318DE8',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styledprimaryoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #318DE8',
    color: '#318DE8',
    fontWeight: 'bold',
};

const styledsuccess = {
    backgroundColor: '#66C066',
    border: '1px solid #66C066',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styledsuccessoutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #66C066',
    color: '#66C066',
    fontWeight: 'bold',
};
const styleddark = {
    backgroundColor: '#ffffff',
    border: '1px solid #000000',
    color: '#000000',
    fontWeight: 'bold',
};
const styleddanger = {
    backgroundColor: ' #FF0000',
    border: '1px solid #FF0000',
    color: '#ffffff',
    fontWeight: 'bold',
};
const styleddangeroutline = {
    backgroundColor: '#ffffff',
    border: '1px solid #FF0000',
    color: '#FF0000',
    fontWeight: 'bold',
};
const styledwarning = {
    backgroundColor: '#ffffff',
    border: '1px solid #FF9D00',
    color: '#FF9D00',
    fontWeight: 'bold',
};

let datalist = [
    {
        date: '09/04/2020 - 10:10',
        money: 2500,
    },
    {
        date: '09/04/2020 - 11:11',
        money: 2400,
    }
]
class CashOnDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isDataCheckedModal: false,
            shipment_trip: '',
            truckLicense: '',
            pageNumber: 1,
            shipmentRunningNumber: '',
            shipment_number: '',
            showData: 30,

            //ค้นหา driver
            driverUserListSuggestions: [],
            driverName: '',
            driverId: '',
        }
        
        this._inputAttachFileFirstCase = React.createRef();
    }
    renderIconStatus = (status) => {
        switch (status) {
            case (1):
                return <i style={{ color: "#E3E3E3", marginRight: '5px' }} class="far fa-check-circle"></i>
            case (2):
                return <i style={{ color: "#5DBA5B", marginRight: '5px' }} class="far fa-check-circle"></i> 
            case (3):
                return <i style={{ color: "#FF0000", marginRight: '5px' }} class="far fa-check-circle"></i>
            default:
                return <i style={{ color: "#E3E3E3", marginRight: '5px' }} class="far fa-check-circle"></i>
        }
    }
    renderReportStatus(status) {
        switch (status) {
            
            case 1:
                return <Label style={styleddefault}>รอส่งสถานะ</Label>;
            case 2:
                return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
            case 3:
                return <Label style={styleddefault}>รอโอน COD</Label>;
            case 4:
                return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
            case 5:
                return <Label style={styleddefault}>รอโอน COD</Label>;
            case 6:
                return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
            case 7:
                return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
            case 8:
                return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
            default:
                return '';
        }
    }
    renderStatus(status) {
        switch (status) {
            case 2:
                return <Label style={styleddefault}>รอขนส่งรับงาน</Label>;
            case 10:
                return <Label style={styleddefaultoutline} >รอเริ่มงาน</Label>;
            case 3:
                return <Label style={styledprimaryoutline}>กำลังดำเนินการขนส่ง</Label>;
            case 4:
                return <Label style={styledsuccessoutline}>ขนส่งเสร็จสิ้น</Label>;
            case 5:
                return <Label style={styleddark}>ถูกยกเลิก</Label>;
            case 6:
                return <Label style={styleddangeroutline}>งานเกิดปัญหา</Label>;
            case 7:
                return <Label style={styleddefaultoutline} >แบบร่าง</Label>;
            case 8:
                return <Label style={styledprimary} >นัดหมายลูกค้าแล้ว</Label>;
            case 9:
                return <Label style={styledwarning}>กำลังคืนคลัง</Label>;
            case 11:
                return <Label style={styleddefault}>รอส่งสถานะ</Label>;
            case 12:
                return <Label style={styledprimary}>รอตรวจสอบสถานะ</Label>;
            case 13:
                return <Label style={styledsuccess}>สถานะผ่าน</Label>;
            case 14:
                return <Label style={styleddanger}>สถานะไม่ผ่าน</Label>;
            case 15:
                return <Label style={styleddefault}>รอโอน COD</Label>;
            case 16:
                return <Label style={styledprimary}>รอตรวจสอบ COD</Label>;
            case 17:
                return <Label style={styledsuccess}>เสร็จสมบูรณ์</Label>;
            case 18:
                return <Label style={styleddanger}>COD ไม่ผ่าน</Label>;
            default:
                return '';
        }
    }

    // ค้นหา driver
    getSuggestionValue = suggestion => suggestion.name;

    renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

    onSuggestionsClearRequested = () => {
        this.setState({
          driverUserListSuggestions: [],
        });
      };
    onSuggestionsFetchRequested = ({ value }) => {
        this.getSuggestions(value);
      };
    getSuggestions = async value => {
        const inputValue = value
          .trim()
          .toString()
          .toLowerCase();
    
        try {
          const { data } = await client.query({
            query: driverUserListQuery,
            fetchPolicy: 'network-only',
            variables: {
              driver_name: inputValue,
            },
          });
    
          const driverUserListData = data.driverUserList.driver_user.map(e => {
            return {
              id: e._id,
              name: e.driver_name,
            };
          });
    
          this.setState({
            driverUserListSuggestions: driverUserListData,
          });
        } catch (error) {
          console.log(error);
        }
      };
      shouldRenderSuggestions() {
        return true;
      }
    // End ค้นหา driver
    
    getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    formatMoney = number => {
        return `฿ ${number.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}`;
    };
    
    getIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).id;
        return id;
    };
    getorderIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).orderid;
        return id;
    };
    render() {
        return (
            <div>
                <Query
                    query={AccountInfoList}
                    context={`${this.getCookie("token_key_agent")}`}
                >
                    {({ data: accountInfoListData, loading, error }) => {
                        if (loading || error) return null;
                        const account_info = accountInfoListData.accountInfoList.account_info[0];
                        let accountinfoId = account_info;
                        return (
                            <div className="content">
                                <Query
                                query={agentManageCODListQuery}
                                variables={{
                                    _id: this.getIdFromUrl(),
                                    shipment_running_number: this.state.shipmentRunningNumber,
                                    shipment_number: this.state.shipment_number,
                                    user_setting_truck_license_plate: this.state.truckLicense,
                                    driver_name : this.state.driverName,
                                    shipment_trip: this.state.shipment_trip,
                                    page: this.state.pageNumber,
                                    showData: this.state.showData

                                }}
                                fetchPolicy="cache-and-network"
                                >
                                    {({ data, loading, error, refetch: refetchagentManageCODList }) => {
                                        let shipmentList = [];
                                        let proofTransfer = [];
                                        let shipment = [];
                                        let header = null;
                                        let totalPage = 1;

                                        var itemLength = 'loading';
                                        const itemIndicatorSize = (this.state.pageNumber - 1) * 30;
                                        if (!loading && !error) {
                                            if(data.AgentManageCOD.shipment){
                                                shipment= data.AgentManageCOD.shipment[0];
                                                header = <div>{moment(shipment.pick_up_date).format('DD/MM/YYYY - HH:mm')}&nbsp;/&nbsp;{ ' รอบ ' + shipment.trip_name}</div>
                                                totalPage = data.AgentManageCOD.total_page;
                                                shipmentList = [...shipment.shipmentList];
                                                proofTransfer = [...shipment.proof_of_transfer];
                                                if (shipmentList.length > 0) {
                                                    itemLength = shipment.shipmentList.length;
                                                }
                                            }
                                        }
                                            return (
                                                    <div>
                                                        <Query
                                                            query={shipmentAgentViewQuery}
                                                            variables={{
                                                                shipment_prefix: 'GLZ',
                                                                lazada_import_order_id: this.getorderIdFromUrl(),
                                                                account_info_id: account_info._id
                                                            }}
                                                            fetchPolicy="cache-and-network"
                                                        >
                                                            {({ data: datashipmentAgentView}) => {
                                                            return(
                                                                <SubHeaderCOD headerName={header} pathname={this.props.location.pathname} agent_status={datashipmentAgentView && datashipmentAgentView.shipmentAgentView && datashipmentAgentView.shipmentAgentView.agent_shipment_status} />
                                                            )
                                                        }}
                                                    </Query>
                                                    <Grid fluid>
                                                        <Row>
                                                            <Col md={12}>
                                                                <div>
                                                                    <StyledHeader>
                                                                        <Grid style={{ margin: 'unset', width: '100%' }} >
                                                                            <Row style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                                                <Col md={2}>
                                                                                    <strong style={{ fontSize: '18px' }}>ตรวจสอบ COD</strong>
                                                                                </Col>
                                                                                <Col md={4} style={{ display: 'flex' }} >
                                                                                    <Line />
                                                                                    <CenterVerticalSpan  style={{color: '#5DBA5B'}}>{this.renderIconStatus(2)}ยอด COD ทั้งหมด:&nbsp;&nbsp;<b>{shipment.total_price_cod ? this.formatMoney(shipment.total_price_cod) : this.formatMoney(0)}</b></CenterVerticalSpan>
                                                                                    
                                                                                    {shipment.outstanding_balance_cod ? 
                                                                                    (
                                                                                        <div>
                                                                                            <Line />
                                                                                            <CenterVerticalSpan style={{color: '#FF0000'}}>ยอด COD ที่ขาด:&nbsp;&nbsp;<b>{this.formatMoney(shipment.outstanding_balance_cod)}</b></CenterVerticalSpan>
                                                                                        </div>
                                                                                        
                                                                                    ): ('')}
                                                                                </Col>
                                                                                <Col md={4} style={{ display: 'flex' }}>
                                                                                <Line style={{ marginRight: '5px', marginLeft: 'unset' }} />
                                                                                    <div 
                                                                                        style={{ 
                                                                                            display: 'flex', 
                                                                                            flexDirection: 'column', 
                                                                                            justifyContent: 'center', 
                                                                                            overflow: 'auto', 
                                                                                            height: 52 ,
                                                                                            padding: '0 10'
                                                                                        }} >
                                                                                        <CenterVerticalSpan style={{marginTop: shipment.agent_shipment_status == 8 ? '15px' : ''}} >สถานะรายงาน: &nbsp;&nbsp;<b> {this.renderReportStatus(shipment.agent_shipment_status) }</b></CenterVerticalSpan>
                                                                                        {false ? <span style={{color: 'red'}} >ERROR</span> : null}
                                                                                        {shipment.agent_shipment_status == 8 && <div style={{ color: 'red', wordBreak: 'break-all', marginTop: '5px' }}>
                                                                                            <span style={{color: '#000'}} >เหตุผล:</span> {shipment.cod_reject_remark}
                                                                                        </div>}
                                                                                    </div>
                                                                                </Col>
                                                                                {proofTransfer.length > 0 &&
                                                                                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                                                        <Button
                                                                                                style={{ border: '2px dashed #318de8', backgroundColor: '#FFF', color: '#318DE8' }}
                                                                                                onClick={() => {
                                                                                                    this.setState({ 
                                                                                                        isDataCheckedModal: true,
                                                                                                    })}
                                                                                                }
                                                                                            >
                                                                                                <i style={{ marginRight: '5px',fontSize: '16px',transform: 'rotate(180deg)' }} class="far fa-upload"></i>หลักฐานการโอน
                                                                                        </Button>
                                                                                    </Col>
                                                                                }
                                                                            </Row>
                                                                        </Grid>

                                                                    </StyledHeader>
                                                                    <CardTable
                                                                        ctTableFullWidth
                                                                        ctTableResponsive
                                                                        content={
                                                                        <div>
                                                                            <PageWrapper style={{ textAlign: 'right' }}>
                                                                                <div className="text">
                                                                                {this.state.pageNumber === 1
                                                                                    ? `1 - ${itemLength}`
                                                                                    : `${itemIndicatorSize + 1} - ${itemLength +
                                                                                    itemIndicatorSize}`}{' '}
                                                                                    หน้า :
                                                                                </div>
                                                                                <FormControl
                                                                                    type="number"
                                                                                    min={1}
                                                                                    value={this.state.pageNumber}
                                                                                    onChange={event =>
                                                                                        Number(event.target.value) >= 0
                                                                                            ? setInterval(
                                                                                                this.setState({
                                                                                                    pageNumber: Number(event.target.value),
                                                                                                }),
                                                                                                1000
                                                                                            )
                                                                                            : setInterval(this.setState({ pageNumber: 1 }), 1000)
                                                                                    }
                                                                                    name="page_number"
                                                                                />
                                                                                <div className="text">
                                                                                    {' '}
                                                                                    {loading || error ? '' : `จาก ${totalPage}`}
                                                                                </div>
                                                                            </PageWrapper>
                                                                            <div className="table-vertical">
                                                                                <Table striped hover responsive style={{ marginBottom: '80px' }}>
                                                                                <thead>
                                                                                    <tr>
                                                                                    <th style={{ minWidth: '100px' }}>เครื่องมือ</th>
                                                                                    <th style={{ minWidth: '230px' }}>Trip No.</th>
                                                                                    <th style={{ minWidth: '160px' }}>สถานะ</th>
                                                                                    <th style={{ minWidth: '160px' }}>พนักงานขับรถ</th>
                                                                                    <th style={{ minWidth: '100px' }}>เลขทะเบียน</th>
                                                                                    <th style={{ minWidth: '130px' }}>COD จากสถานะ</th>
                                                                                    <th style={{ minWidth: '130px' }}>COD ที่คนรถได้รับ</th>
                                                                                    <th style={{ minWidth: '130px' }}>COD ยกเลิก</th>
                                                                                    <th style={{ minWidth: '130px' }}>COD ทั้งหมด</th>
                                                                                    <th style={{ minWidth: '120px' }}>เลขรัน</th>
                                                                                    <th style={{ minWidth: '160px' }}>หมายเลขงานขนส่ง</th> 
                                                                                    </tr>
                                                                                    <tr>
                                                                                    <th></th>
                                                                                    <th>
                                                                                        <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหา"
                                                                                        value={this.state.shipment_trip}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                                shipment_trip: event.target.value,
                                                                                                pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <FormControl
                                                                                        componentClass="select"
                                                                                        placeholder="select"
                                                                                        defaultValue={this.state.shipmentStatus}
                                                                                        onChange={event =>
                                                                                            this.setState({
                                                                                            shipmentStatus: event.target.value,
                                                                                            page_number: 1,
                                                                                            })
                                                                                        }
                                                                                        >
                                                                                        <option value={0}>ทั้งหมด</option>
                                                                                        <option value={2}>รอขนส่งรับงาน</option>
                                                                                        <option value={10}>รอเริ่มงาน</option>
                                                                                        <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                                                                        <option value={3}>กำลังดำเนินการขนส่ง</option>
                                                                                        <option value={4}>เสร็จสิ้นแล้ว</option>
                                                                                        <option value={5}>ถูกยกเลิก</option>
                                                                                        <option value={6}>งานเกิดปัญหา</option>
                                                                                        <option value={7}>แบบร่าง</option>
                                                                                        <option value={9}>กำลังคืนคลัง</option>

                                                                                        {/* new status */}
                                                                                        <option value={11}>รอส่งสถานะ</option>
                                                                                        <option value={12}>รอตรวจสอบสถานะ</option>
                                                                                        <option value={13}>สถานะผ่าน</option> {/* สถานะผ่าน ถ้าไม่มี COD จะเป็น เสร็จสมบูรณ์ */}
                                                                                        <option value={14}>สถานะไม่ผ่าน</option>
                                                                                        <option value={15}>รอโอน COD</option>
                                                                                        <option value={16}>รอตรวจสอบ COD</option>
                                                                                        <option value={17}>เสร็จสมบูรณ์</option>
                                                                                        <option value={18}>COD ไม่ผ่าน</option>

                                                                                        </FormControl>
                                                                                    </th>
                                                                                    <th>
                                                                                        <AutosuggestContainer>
                                                                                        <Autosuggest
                                                                                            suggestions={this.state.driverUserListSuggestions}
                                                                                            onSuggestionsFetchRequested={
                                                                                            this.onSuggestionsFetchRequested
                                                                                            }
                                                                                            onSuggestionsClearRequested={
                                                                                            this.onSuggestionsClearRequested
                                                                                            }
                                                                                            getSuggestionValue={this.getSuggestionValue}
                                                                                            renderSuggestion={this.renderSuggestion}
                                                                                            focusInputOnSuggestionClick={false}
                                                                                            inputProps={{
                                                                                            placeholder: 'ค้นหา',
                                                                                            value: this.state.driverName,
                                                                                            onChange: event =>
                                                                                                this.setState({
                                                                                                driverName: event.target.value,
                                                                                                driverId: '',
                                                                                                }),
                                                                                            type: 'text',
                                                                                            className: 'form-control',
                                                                                            }}
                                                                                            onSuggestionSelected={(event, { suggestion }) => {
                                                                                            this.setState({
                                                                                                driverId: suggestion.id,
                                                                                                driverName: suggestion.name,
                                                                                                pageNumber: 1,
                                                                                            });
                                                                                            }}
                                                                                            shouldRenderSuggestions={this.shouldRenderSuggestions}
                                                                                        />
                                                                                        </AutosuggestContainer>
                                                                                    </th>
                                                                                    <th>
                                                                                        <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหา เลขทะเบียน"
                                                                                        value={this.state.truckLicense}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                                truckLicense: event.target.value,
                                                                                                pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                        />
                                                                                    </th>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                    <th></th>
                                                                                    <th>
                                                                                        <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหาเลขรัน"
                                                                                        value={this.state.shipmentRunningNumber}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                            shipmentRunningNumber: event.target.value,
                                                                                            pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                        />
                                                                                    </th>
                                                                                    <th>
                                                                                        <FormControl
                                                                                        type="text"
                                                                                        placeholder="ค้นหาหมายเลขงานขนส่ง"
                                                                                        value={this.state.shipment_number}
                                                                                        onChange={event => {
                                                                                            this.setState({
                                                                                            shipment_number: event.target.value,
                                                                                            pageNumber: 1,
                                                                                            });
                                                                                        }}
                                                                                        />
                                                                                    </th>
                                                                                    
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {loading && (
                                                                                    <tr>
                                                                                        <td colSpan="14">Loading...</td>
                                                                                    </tr>
                                                                                    )}

                                                                                    {error && (
                                                                                    <tr>
                                                                                        <td colSpan="14">Error</td>
                                                                                    </tr>
                                                                                    )}

                                                                                    {!loading && !error && shipmentList.length === 0 && (
                                                                                    <tr>
                                                                                        <td colSpan="14">ไม่มีข้อมูล</td>
                                                                                    </tr>
                                                                                    )}

                                                                                    {shipmentList.map((item, index) => (
                                                                                    <tr>
                                                                                        <td>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}
                                                                                        >
                                                                                            <a
                                                                                            href={`/ka-shipment-detail?id=${item._id}`}
                                                                                            target="_blank"
                                                                                            style={{ float: 'left' }}
                                                                                            className="btn btn-default"
                                                                                            >
                                                                                            <i
                                                                                                style={{ marginTop: '3px' }}
                                                                                                className="fas fa-eye"
                                                                                            ></i>
                                                                                            </a>
                                                                                        </OverlayTrigger>
                                                                                        </td>
                                                                                        <td>{item.shipment_trip}</td>
                                                                                        <td>
                                                                                        {this.renderStatus(item.shipment_status)}
                                                                                        </td>
                                                                                        <td>
                                                                                        {item.driver_name ? (
                                                                                            <div>
                                                                                                <div>{item.driver_name}</div>
                                                                                                <div>{item.driver_phone_code} {item.driver_phone_number}</div>
                                                                                            </div>
                                                                                            ):('')
                                                                                        }
                                                                                        </td>
                                                                                        <td>{item.user_setting_truck_license_plate}</td>
                                                                                        <td>{item.shipment_accept_cod ? this.formatMoney(item.shipment_accept_cod) : this.formatMoney(0)}</td>
                                                                                        <td>{item.shipment_driver_accept_cod ? this.formatMoney(item.shipment_driver_accept_cod) : this.formatMoney(0)}</td>
                                                                                        <td>{item.shipment_cancel_cod ? this.formatMoney(item.shipment_cancel_cod) : this.formatMoney(0)}</td>
                                                                                        <td>{item.shipment_total_cod ? this.formatMoney(item.shipment_total_cod) : this.formatMoney(0)}</td>
                                                                                        <td>{item.shipment_running_number}</td>
                                                                                        <td>{item.shipment_number}</td>
                                                                                    </tr>
                                                                                ))} 
                                                                                </tbody>
                                                                                </Table>
                                                                                <div style={{ textAlign: 'right', marginTop: 20 }}>
                                                                                {!loading && !error && (
                                                                                    <ReactPaginate
                                                                                    pageCount={totalPage}
                                                                                    forcePage={this.state.pageNumber - 1}
                                                                                    containerClassName="pagination"
                                                                                    nextLabel=">"
                                                                                    previousLabel="<"
                                                                                    onPageChange={({ selected }) => {
                                                                                        this.setState({
                                                                                            pageNumber: selected + 1,
                                                                                        });
                                                                                    }}
                                                                                    />
                                                                                )}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        }
                                                                    />
                                                                </div> 
                                                                {this.state.isDataCheckedModal &&
                                                                    <ProofofTransferModal
                                                                        shipment = {shipment}
                                                                        isDataCheckedModal={this.state.isDataCheckedModal}
                                                                        closeModal={() => this.setState({ isDataCheckedModal: false })}
                                                                        refetchagentManageCODList = {refetchagentManageCODList}
                                                                        accountinfoId = {account_info}
                                                                        proofTransfer = {proofTransfer}
                                                                        cod_reject_remark = {shipment.cod_reject_remark}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Grid>
                                                </div>
                                            )
                                        }}
                                </Query>
                            </div>
                        )
                    }}
                </Query>

            </div>
                  
        )
    }
}
export default CashOnDelivery;