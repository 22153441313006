import gql from 'graphql-tag';

export default gql`
  query PickupInventoryCus($account_info: ID!) {
    pickupInventoryCus(account_info: $account_info) {
      address
      totalOrder
      orders {
        _id
        trackingNumber
        origin {
          name
          email
          phone
          address {
            details
            city
            province
            zipCode
          }
          geocoding {
            latitude
            longitude
          }
        }
      }
    }
  }
`;
