import gql from 'graphql-tag';

export default gql`
  query accountInfoListSearchAgent($_id: ID, $account_info_type: Int) {
    accountInfoList(_id: $_id, show_data: 200, account_info_type: $account_info_type) {
      account_info {
        _id
        account_info_business_name
        payment_bank_account{
          bank_name
          bank_branch
          account_name
          account_number
        }
      }
    }
  }
`;
