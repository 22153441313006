import gql from 'graphql-tag';

export default gql`
query searchReceiptSummaryList(
      $_id: ID
      $account_info_id: ID
      $page_number: Int
      $order_by: String
      $order_type: String
      $summary_invoice_id: ID
      $receipt_number: String
      $show_data: Int
      $receipt_date: String
    ){
      receiptSummaryList(
        _id 	: $_id
        account_info_id: $account_info_id
        show_data: $show_data
        receipt_date: $receipt_date
        page_number: $page_number
        order_by: $order_by
        order_type: $order_type
        summary_invoice_id: $summary_invoice_id
        receipt_number: $receipt_number
      ){
      total_page
      receipt{
          _id
          send_email
          receipt_number
          receipt_date
          receipt_document
          receipt_type
          status
          summary_invoice_id
          summary_invoice_number
          summary_invoice_create_date
          bill_date_start
          bill_date_end
          issue_date
          due_date
          debit_note_number
          debit_note_id
          debit_note_create_date
          account_info_id
          account_info_business_name
          credit_used
          receipt_total_price
          paid_type
          paid_credit_card_detail{
              bank
            last_digits
            brand
            name
          }
          paid_bank_transfer{
            transfer_price
            transfer_pic
            transfer_date
          }
          create_date
          last_update
          read_status
      }
      }
  }
`;
