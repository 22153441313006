import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Input } from './../../../../components';
import { Query, Mutation } from 'react-apollo';
import { connect } from 'formik';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import DocumentList from './DocumentList';

// utils
// import formatCurrency from './../../../../../../utils/formatCurrency';

// graphql
import costingDocumentsQuery from './../../../../graphql/query/costingDocuments';
import singleUpload from './../../../../graphql/mutation/singleUpload';
import createCostingDocument from './../../../../graphql/mutation/createCostingDocument';

import s3Bucket from 'config/s3Bucket';
import s3 from 'config/s3';

const ContainerStyled = styled.div`
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;
  padding-bottom: 20px;

  & .subTitle {
    color: #aaaaaa;
  }

  & .filepond--root {
    font-size: 16px !important;
  }

  & .filepond--root {
    height: 76px !important;
  }
`;


const DocumentContainerStyled = styled.div`
  padding: 15px;
`;

const FileContainer = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);


class CostingDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: []
    };
    this.myRef = React.createRef();
  }

  render() {
    return (
      <ContainerStyled>
        <Row>
          <Col md={12}>
            <div>อัพโหลดเอกสารทั่วไป</div>
            <div className="subTitle">
              (รูปแบบไฟล์ที่รองรับ .jpg , .jpeg , .png , .pdf , และขนาดไฟล์ไม่เกิน 2Mb.)
              </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div style={{ marginTop: 10 }}>
              <Mutation
                mutation={createCostingDocument}
                refetchQueries={() => ['searchCostingDocuments']}
                onError={error => {
                  console.log(error);
                }}
              >
                {createCostingDocument => {
                  return (
                    <Mutation mutation={singleUpload}>
                      {singleUpload => {
                        return (
                          <>
                            <FilePond
                              ref={this.myRef}
                              maxFiles={1}
                              server={{
                                process: (fieldName, file, metadata, load, error) => {
                                  singleUpload({
                                    variables: {
                                      file,
                                      bucket: s3Bucket,
                                    },
                                    update: (cache, { data }) => {
                                      const { path } = data.singleUpload;
                                      createCostingDocument({
                                        variables: {
                                          createCostingDocument: {
                                            costing_id: this.props.costingId,
                                            document_file: path,
                                          }
                                        },
                                      });

                                      this.setState(prevState => ({
                                        documents: [...prevState.documents, path]
                                      }));
                                      this.props.updateDocument(this.state.documents);
                                    },
                                  })
                                    .then(() => {
                                      this.myRef.current.removeFile();
                                    })
                                    .catch(err => {
                                      console.log(err)
                                      // message.error(err.message);
                                      error.message(err);
                                    });
                                },
                              }}
                              acceptedFileTypes={['image/jpeg', 'image/png', 'application/pdf']}
                              maxFileSize="2MB"
                            />
                          </>
                        );
                      }}
                    </Mutation>
                  );
                }}
              </Mutation>
              <DocumentList
                costingId={this.props.costingId}
              />
            </div>
          </Col>
        </Row>
      </ContainerStyled>
    );
  }
}

export default CostingDocument;
