import styled from 'styled-components';

const StyledTabs = styled.div`
  border-bottom: 1px solid #e3e3e3;

  & ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  & li {
    display: inline-block;
    color: #000000;
    padding: 10px 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    background-color: #f2f2f2;
    margin-right: 10px;
    margin-bottom: 5px;

    & i {
      margin-right: 10px;
      vertical-align: middle;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.active {
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #e3e3e3;
      border-top: 4px solid #000000;
      border-bottom: none;
      position: relative;

      &::after {
        content: '';
        width: calc(100% + 2px);
        height: 6px;
        background-color: #ffffff;
        position: absolute;
        bottom: -6px;
        left: -1px;
        border-left: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
      }
    }
  }
`;

export default StyledTabs;
