import gql from "graphql-tag";

export default gql`
  query newCodStatus {
    newCodStatus {
      ops_pending
      pending
      confirm
      reject
      cancel
    }
  }
`;
