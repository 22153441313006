import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';
import LogoLazada from './logo-lazada.png';
import Autosuggest from 'react-autosuggest';
import LazadaImportOrderListQuery from '../../graphql/query/lazadaImportOrderList'
import { client } from '../../../../../../index'
import customerId from 'config/customerId';
import DO_NOTHING_MUTATION from '../../graphql/mutation/doNothing';
import { Button } from 'react-bootstrap' 

const Container = styled.div`
  font-size: 2.7vw;
  display: flex;
  flex-direction: row;
  height: 10vh;
  background-color: #0d1578;
  color: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;

  & div:first-child {
    margin-right: 45px;
  }
`;

const LogoContainer = styled.div`
  & img {
    height: 6vh;
  }
`;

const Divider = styled.div`
  width: 1px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #ffffff;
  height: 6vh;
`;

const AutosuggestContainer = styled.div`
  display: inline-block;
  width: 90%;

  & .form-control {
    background: transparent;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 2.7vw;
    border-bottom: 3px solid #fff;
    border-radius: 0;
    transition: border-width .1s linear;
  }

  & .form-control:focus {
    background: transparent;
    border: none;
    color: #fff;
    font-weight: bold;
    font-size: 2.7vw;
    border-radius: 0;
    border-width: 0px;
  }

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 5px 5px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 40px;
  background: #fff !important;
  opacity: 1 !important;
`
class Header extends Component {
  state = {
    currentTime: '00:00:00',
    orderListSuggestions: [],
    orderRound: this.props.textRound,
    orderId: '',
    refetchAnimation: 'fas fa-redo-alt',
  };

  componentDidMount() {
    this.getCurrentTime();
  }

  getCurrentTime = () => {
    setInterval(() => {
      this.setState({
        currentTime: moment().format('HH:mm:ss'),
      });
    }, 1000);
  };

  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: LazadaImportOrderListQuery,
        fetchPolicy: 'network-only',
        variables: {
          format_bookname: inputValue,
          show_data:10,
          page_number: 1,
          account_info_id: customerId.lazada,
          create_shipment: 2
        },
      });

      const orderListData = data.lazadaImportOrderList.order.filter(dt => dt.format_bookname).map(e => {
        return {
          id: e._id,
          name: e.format_bookname,
        };
      });

      this.setState({
        orderListSuggestions: orderListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      orderListSuggestions: [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name ? suggestion.name : 'No Booking Number'}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  refetchQueries = () => {
    client.mutate({ mutation: DO_NOTHING_MUTATION, refetchQueries: () => ["DashboardLazada"] })
    this.setState({ refetchAnimation: 'fas fa-redo-alt fa-spin' })
    setTimeout(() => { this.setState({ refetchAnimation: 'fas fa-redo-alt' }) }, 1000)
  }

  render() {
    return (
      <Container>
        <LeftContainer>
          <LogoContainer>
            <img src={LogoLazada} alt="logo lazada" />
          </LogoContainer>
          <Divider />
          <div>
          รอบ: 
          {
            // รอบ: <strong>{this.props.textRound}</strong>
            <AutosuggestContainer>
              <Autosuggest
                  suggestions={this.state.orderListSuggestions}
                  onSuggestionsFetchRequested={
                    this.onSuggestionsFetchRequested
                  }
                  onSuggestionsClearRequested={
                    this.onSuggestionsClearRequested
                  }
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  focusInputOnSuggestionClick={false}
                  inputProps={{
                    placeholder: 'ค้นหา',
                    value: this.state.orderRound,
                    onChange: event =>
                      this.setState({
                        orderRound: event.target.value,
                        orderId: '',
                      }),
                    type: 'text',
                    className: 'form-control',
                  }}
                  onSuggestionSelected={(event, { suggestion }) => {
                    this.setState({
                      orderId: suggestion.id,
                      orderRound: suggestion.name,
                    });
                    this.props.setOrderId(suggestion.id, suggestion.name)
                  }}
                  shouldRenderSuggestions={this.shouldRenderSuggestions}
                />
              </AutosuggestContainer>
            }
          </div>
          <StyledButton
              onClick={this.refetchQueries}
          >
              <i style={{ animationDuration: '.5s', fontSize: '25px' }} class={this.state.refetchAnimation}></i>
          </StyledButton>
        </LeftContainer>
        <RightContainer>
          <div>
            วันที่รับสินค้า: <strong>{this.props.textPickupDate}</strong>
          </div>
          <div>
            เวลาในขณะนี้: <strong>{this.state.currentTime}</strong>
          </div>
        </RightContainer>
      </Container>
    );
  }
}

export default Header;
