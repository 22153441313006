import gql from 'graphql-tag';

export default gql`
  query {
    kaWarehouses {
      _id
      tag_name
      address
      lat
      lng
      province
      subdictrict
      dictrict
      postcode
      contact_tel
      contact_name
      wms_branch
      warehouse_type
      active
    }
  }
`;
