export const commodity_laz_pickup = `
    _id
    name
    name_detail
    order_number
    package_number
    remark
    accept
    reject_detail
    cod_price
`;

export const lazada_delivery = `
    _id
    delivery_status
    cod_price
    remind
    final_status
    temp_delivery_status
    remark
    failure_status
    admin_reject_status
    account_info
    failure_detail
    failure_date
    postpone_delivery_date
    failure_picture
    commodity_picture
    commodity_picture_end
    commodity_picture_return
    return_status
    cod_qr
    qr_ref1
    qr_ref2
    log{
    _id
    }   

    commodity{
        ${commodity_laz_pickup}
    }
`;
