import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import { Query } from "react-apollo";
import ReactPaginate from "react-paginate";
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import CardTable from "components/CardTable/CardTable.jsx";
import { Layout } from "../../components";
import { withRouter } from "react-router-dom";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ""
    };
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="info">กำลังอัพโหลด</Label>;
      case 2:
        return <Label bsStyle="danger">ผิดพลาด</Label>;
      case 3:
        return <Label bsStyle="warning">รอสร้างงานขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">สร้างงานขนส่งแล้ว</Label>;
      default:
        return "";
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Layout>
        <Row>
          <Col md={12}>
            <div>
              <CardTable
                title="Dashboard"
                category="รายการภาพรวม"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    coming soon
                  </div>
                }
              />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default withRouter(Dashboard);
