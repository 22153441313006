import React, { Component } from 'react'
import { Query, graphql, compose, Mutation } from "react-apollo";
import { Row, Col, Button, Modal } from "react-bootstrap";
import styled from 'styled-components';
import UploadFile from './components/UploadFile'
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import restBaseUrl from 'config/restBaseUrl';
import lamdaUrl from 'config/lambdaUrl';
import adminListQuery from './graphql/query/adminList';
import lazadaImportOrderCheckBooknameQuery from './graphql/query/lazadaImportOrderCheckBookname';
import axios from 'axios';
import { client } from '../../../../index';
import { SelectBox, Input } from './components'
import Datetime from "react-datetime";
import moment from 'moment';
import thirPartySettingSet from 'views/Lazada/screens/MasterData3PL/graphql/mutation/thirPartySettingSet';
import singleUploadMutation from './graphql/mutation/singleUpload';
import s3 from 'config/s3';
import customerId from 'config/customerId';

// const selectOptionJobType = [
//     { value: "1", label: "NORMAL" },
//     { value: "2", label: "FAILED" },
//     { value: "3", label: "FIRST MILE" },
//     { value: "4", label: "SPECIAL" },
//     { value: "5", label: "LAST MILE" },
//     { value: "6", label: "Return to Warehouse" }
// ]

const selectOptionJobType = [
    { value: "1", label: "Last Mile (NM)" },
    { value: "2", label: "Return to Merchant (RM)" },
    { value: "3", label: "FIRST MILE (FM)" },
    { value: "4", label: "SPECIAL (SP)" },
    { value: "5", label: "Customer Return (CR)" },
    { value: "6", label: "Return to WH (RTWH)" },
    { value: "7", label: "Return to Customer (CR/RC)" },
    { value: "8", label: "Return to Merchant (CR/RM)" },
]

class ImportBookingFileModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            importFile: null,
            importFileSingleUpload: null,

            selectedJobType: '',
            selectedJobTypeInvalid: false,

            round: '',
            roundInvalid: false,

            planDate: '',
            planDateShow: '',
            planDateInvalid: false,
            dateValid: true,
            animationUpload: false,
            animationCreate: false
        }
        this._inputImportFile1 = React.createRef();
        this._inputAttachFileFirstCase = React.createRef();
    }

    handleSingleUploadDocument(uploadFile, documentName, event) {
        const {
          target: {
            validity,
            files: [file],
          },
        } = event;
    
        // console.log(uploadFile)
    
        const timestamp = new Date().getTime();
    
        if (validity) {
          uploadFile({
            variables: {
              file: file,
              path: `upload/LazadaImportFileNew/${documentName}_${timestamp}`,
            },
          });
        }
      }
    
      checkFileTypeExcel(file) {
        if (file) {
            const LastExt = file.name.split('.').length - 1
            const ext = file.name.split('.')[LastExt]  
    
            return ext === 'xlsx'
            // return file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
            // || file.type === "application/vnd.ms-excel";
        }
    }

    uploadFileHandler = async (file, refetchLazadaImportOrderList) => {
        if (this.checkInvalid()) {
            // console.log('invalid')
        } else {
            // console.log('valid')
            const { selectedJobType, round, planDate } = this.state
            // console.log(selectedJobType.value)
            // console.log(round)
            // console.log(planDate)
            try {
                const { data } = await client.query({
                    query: adminListQuery,
                });

                const { admin } = data.adminList;

                if (admin.length === 0) {
                    alert('ไม่พบ Admin');
                    return;
                }

                const adminId = admin[0]._id;
                const url = `${restBaseUrl}/importorderlazada?path=${file.path}&name=${file.fileName}&admin_id=${adminId}&order_type=${selectedJobType.value}&plan_date=${planDate}&trip_name=${round}`;
                await axios(url).then(res => {
                    if (!res.data._id) {
                        alert('มีรอบการจัดส่งซ้ำ');
                        return;
                    }

                    refetchLazadaImportOrderList();
                    this.props.closeModal()
                }).catch(error => {
                    console.log(error)
                    // refetchLazadaImportOrderList();
                    // this.props.closeModal()
                    alert(error);
                });
            } catch (error) {
                alert(error);
            }
        }
    };

    uploadFileHandlerSingleUpload = async (file, refetchLazadaImportOrderList) => {
        if (this.checkInvalid()) {
            // console.log('invalid')
        } else {
            // console.log('valid')
            const { selectedJobType, round, planDate } = this.state
            // console.log(selectedJobType.value)
            // console.log(round)
            // console.log(planDate)
            this.setState({animationCreate: true})
            try {

                const { data: checkBookNameData  } = await client.query({
                    query: lazadaImportOrderCheckBooknameQuery,
                    variables: {
                        order_type: selectedJobType.value,
                        plan_date: planDate,
                        trip_name: round,
                        ka_id: customerId.lazada
                    }
                });

                const { succeed } = checkBookNameData.lazadaImportOrderCheckBookname;
                
                // console.log('succeed', succeed)

                if(succeed){
                    const { data } = await client.query({
                        query: adminListQuery,
                    });
    
                    const { admin } = data.adminList;
    
                    if (admin.length === 0) {
                        alert('ไม่พบ Admin');
                        return;
                    }
    
                    const adminId = admin[0]._id;
                    const url = `${lamdaUrl}/lazada-import-order?path=${file.path}&name=${file.fileName}&admin_id=${adminId}&order_type=${selectedJobType.value}&plan_date=${planDate}&trip_name=${round}`;
                    // console.log(url)
                    const result =  axios(url).then(res => {
                        this.props.closeModal()
                    }).catch(error => {
                        console.log(error)
                    });
                    this.props.closeModal()
                    setTimeout(() => {
                        this.props.refetchLazadaImportOrderList()
                      }, 500);
                }else{
                    alert('มีรอบการจัดส่งซ้ำ');
                    this.setState({ animationCreate: false })
                }
            } catch (error) {
                alert(error);
            }
        }
    };

    checkFormatEnAndNum = (file) => {
        const validate =/^[a-zA-Z0-9_.-]*$/;
        if(validate.test(file)){
            return true
        }
        return false
    }

    checkInvalid = () => {
        this.setState({
            selectedJobTypeInvalid: this.state.selectedJobType ? false : true,
            roundInvalid: this.state.round ? false : true,
            planDateInvalid: this.state.dateValid ? (moment(this.state.planDate, 'YYYY-MM-DD', true).isValid()  ? false : true) : true
        })

        return !(this.state.selectedJobType && this.state.round && this.state.planDate && moment(this.state.planDate, 'YYYY-MM-DD', true).isValid() && this.state.dateValid)
    }

    changeJobType = (event) => {
        this.setState({
            selectedJobType: { value: event.value, label: event.label },
            selectedJobTypeInvalid: false
        })
    }

    handleDatePicker(event) {
        if (event) {
            try {
                const date = `${event.format('YYYY-MM-DD')}`;
                const show = `${event.format('DD/MM/YYYY')}`;
                this.setState({ planDate: date, planDateShow: show, planDateInvalid: false, dateValid: true});
            } catch (error) {
                this.setState({ planDateInvalid: true, dateValid: false});
                console.log(error)
            }
        } else {
            this.setState({ planDate: '', planDateShow: '', planDateInvalid: false, dateValid: true});
            this.checkInvalid(true)
        }
    }

    checkFileTypeImage(file) {
        if (file) {
            return file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
            || file.type === "application/vnd.ms-excel";
        }
    }

    render() {
        return (
            <div>
                <Modal
                    show={this.props.showImportModal}
                >
                    <Modal.Header
                        style={{
                            padding: "10px 15px"
                        }}
                    >
                        <Modal.Title><b>Import File</b></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={5}>
                                <b>ประเภทงาน :</b><br />
                                <SelectBox
                                    onChange={event => this.changeJobType(event)}
                                    value={this.state.selectedJobType}
                                    style={{
                                        width: '250px',
                                        marginTop: '5px'
                                    }}
                                    placeholder="เลือกประเภทงาน"
                                    options={selectOptionJobType}
                                    messageError={
                                        this.state.selectedJobTypeInvalid && 'กรุณาระบุประเภทงาน'
                                    }
                                />
                            </Col>
                            <Col md={5} style={{ marginLeft: '20px' }}>
                                <b>รอบการจัดส่ง :</b><br />
                                <Input
                                    value={this.state.round}
                                    onChange={(event) => this.setState({ round: event.target.value, roundInvalid: !this.checkFormatEnAndNum(event.target.value) })}
                                    style={{ marginTop: '5px' }}
                                    placeholder="กรุณาระบุรอบการจัดส่ง"
                                    messageError={this.state.roundInvalid && 'กรุณาระบุรอบการจัดส่งให้ถูกต้อง'}
                                ></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} style={{ marginTop: '10px' }}>
                                <b> LZD request BKG date :</b><br />
                                <div style={{ border: this.state.planDateInvalid ? '1px solid #FF0000' : '0px solid #e3e3e3', borderRadius: '5px' }}>
                                    <Datetime
                                        closeOnSelect
                                        value={this.state.planDateShow}
                                        dateFormat="DD/MM/YYYY"
                                        timeFormat={false}
                                        inputProps={{ placeholder: "DD/MM/YYYY" }}
                                        onChange={event => {
                                            this.handleDatePicker(event)
                                        }}
                                    />
                                </div>
                                {
                                    this.state.planDateInvalid &&
                                    <div style={{ fontSize: '16px', color: '#FF0000', height: '22px', textAlign: 'right', }}>
                                        {this.state.planDateInvalid ? 'กรุณาระบุเวลา' : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
                            <Col md={12}><b>Import file :</b></Col>
                        </Row>
                        <Button
                            style={{ marginRight: '15px' }}
                            onClick={() => this._inputAttachFileFirstCase.current.click()}
                            
                        >
                            <i className="fas fa-cloud-upload"></i> Import Booking
                        </Button>
                        <span>{this.state.animationUpload ? <i className="fas fa-circle-notch fa-spin" ></i> : (this.state.importFileSingleUpload && this.state.importFileSingleUpload.path ? this.state.importFileSingleUpload.fileName : '')}</span>
                    </Modal.Body>
                    <Modal.Footer
                        style={{
                            padding: "10px 15px"
                        }}
                    >
                        <Button
                            style={{ padding: "5px 15px" }}
                            onClick={this.props.closeModal}
                        >
                            ยกเลิก
                        </Button>
                        <Button
                            disabled={ this.state.animationCreate ? true : (this.state.importFileSingleUpload ? (this.state.importFileSingleUpload.path == null) : true)}
                            style={{
                                background: '#87CB16',
                                color: '#ffffff',
                                marginRight: '15px',
                                padding: "5px 15px",
                                width: '62px'
                            }}
                            onClick={() =>
                                this.uploadFileHandlerSingleUpload(this.state.importFileSingleUpload, this.props.refetchLazadaImportOrderList)
                            }
                        >
                        {this.state.animationCreate ?  <i className="fas fa-circle-notch fa-spin" ></i> : 'สร้าง'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                    <Mutation
                        mutation={singleUploadMutation}
                        update={(cache, { data }) => {
                            this._inputAttachFileFirstCase.current.value = '';
                            const filename = data.singleUpload.path.split('upload/LazadaImportFileNew/')[1]
                            this.setState({
                                importFileSingleUpload: {
                                    ...this.state.importFileSingleUpload,
                                    path: data.singleUpload.path
                                },
                                animationUpload: false
                            })
                        }}
                        onError={error => {
                            console.log(error);
                        }}
                        >
                        {uploadFile => {
                            return (
                                <input
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={event => {
                                    const files = event.target.files[0];
                                    const checkFileTypeImage = this.checkFileTypeExcel(
                                        files
                                    );
                                    if (checkFileTypeImage === false) {
                                        alert('ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท xlsx เท่านั้น');
                                        return;
                                    }
                                    files && this.setState({ animationUpload: true, importFileSingleUpload:{ fileName: files.name } });
                                    this._inputAttachFileFirstCase.current.files[0] &&
                                        this.handleSingleUploadDocument(
                                        uploadFile,
                                        'import_file',
                                        event
                                        );
                                    }}
                                    type="file"
                                    id="file"
                                    style={{ display: 'none' }}
                                    ref={this._inputAttachFileFirstCase}
                                />
                            );
                        }}
                </Mutation>
            </div>
        );
    }
}

export default ImportBookingFileModal;