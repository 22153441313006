import gql from 'graphql-tag';

export default gql`
  query {
    codParcelStatus {
      waiting3PL
      pending
      confirm
      reject
      cancel
    }
  }
`;
