import gql from 'graphql-tag';

export default gql`
  query DashboardLazada($_id: ID) {
    dashboardLazada(_id: $_id) {
      round
      pick_up_date
      total_truck
      truck_finish
      truck_balance
      truck_ready
      truck_get_job
      truck_inprogess
      truck_not_get_job
      pick_up_average_time
      scan_not_complete
      scan_complete
      product_leave_warehouse
      shipment_not_assign
      trip_list {
        _id
        trip
        status
      }
    }
  }
`;
