import styled from 'styled-components';

export const AutoSuggestContainer = styled.div`
  display: inline-block;
  width: 100%;

  & .form-control {
    background: transparent;
    font-size: 18px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    transition: border-width 0.1s linear;
  }

  & .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 8px;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;
