import React from 'react';
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  FormControl,
  Popover,
  NavItem,
} from 'react-bootstrap';
import { NavDropdownContainer, PopUpItemWrapper, TablePopover } from './styled';
import Moment from 'react-moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AutoSuggestTransporter from '../AutoSuggestTransporter';
import moment from 'moment';

const ShipmentTable = ({
  loading,
  error,
  data,
  agentList,
  handleDateRangePicker,
  handleAssignAgent,
  handleAssignDriver,
  renderStatus,
  formatMoney,
  renderStatusMode,
  //parameter
  shipmentStatus,
  searchShipmentStatus,
  shipmentType,
  searchShipmentType,
  shipmentNumber,
  searchShipmentNumber,
  bookingNumber,
  searchBookingNumber,
  displayPickupDate,
  cancelDisplayPickupDate,
  displayCreateDate,
  cancelDisplayCreateDate,
  tplName,
  searchTplName,
  restoreShipment,
  cancelShipment,
  onChangeTransporter,
  stage,
  trackingNumber,
  searchTrackingNumber,
}) => {
  const filterDeliveryList = deliveryList => {
    return deliveryList.filter(
      item =>
        item.delivery_status === 3 &&
        item.paid_by_cod === 1 &&
        item.cod_price > 0 &&
        (!item.cod_qr || item.cod_cash) &&
        !item.billing_cod
    );
  };

  const renderDeliveryList = deliveryList => {
    const filterDelivery = filterDeliveryList(deliveryList);

    return (
      <div style={{ maxHeight: 300, overflowY: 'auto' }}>
        <TablePopover>
          <thead style={{ position: 'sticky', zIndex: 1, top: 0, backgroundColor: 'white' }}>
            <tr>
              <th style={{ minWidth: 150 }}>Tracking No.</th>
              <th style={{ minWidth: 120 }}>Delivered Date</th>
              <th style={{ minWidth: 120, textAlign: 'right' }}>COD Value</th>
            </tr>
          </thead>

          <tbody>
            {filterDelivery.map((item, index) => (
              <tr key={index}>
                <td>{item.commodity.map(commodityItem => commodityItem.name).join(', ')}</td>
                <td>
                  <Moment format="DD/MM/YYYY HH:mm">{item.signature_date}</Moment>
                </td>
                <td style={{ textAlign: 'right' }}>{formatMoney(item.cod_price)}</td>
              </tr>
            ))}

            {filterDelivery.length === 0 && (
              <tr>
                <td colSpan={3}>ไม่มีรายการ</td>
              </tr>
            )}
          </tbody>
        </TablePopover>
      </div>
    );
  };

  const minDateTime = dateTimeArr => {
    if (dateTimeArr.length === 0) {
      return '-';
    }
    const dateTimeMoment = dateTimeArr.map(date => moment(date));
    return moment.min(dateTimeMoment).format('DD/MM/YYYY');
  };

  return (
    <Table striped hover>
      <thead>
        <tr>
          <th style={{ minWidth: '150px' }}>เครื่องมือ</th>
          <th style={{ minWidth: '160px' }}>สถานะ</th>
          <th style={{ minWidth: '160px' }}>ประเภทงาน</th>
          <th style={{ minWidth: '160px' }}>Shipment Number</th>
          <th style={{ minWidth: '160px' }}>Booking Number</th>
          <th style={{ minWidth: '160px' }}>วัน/เวลาที่รับสินค้า</th>
          <th style={{ minWidth: '160px' }}>Driver ID / ชื่อ 3PL</th>
          <th style={{ minWidth: '160px' }}>COD ที่ต้องโอน</th>
          {stage === 'WAITINGCOD' && <th style={{ minWidth: '160px' }}>วันที่ต้องโอน</th>}
          <th style={{ minWidth: '200px' }}>จุดรับ</th>
          <th style={{ minWidth: '160px' }}>จำนวนชิ้นที่ส่ง</th>
          <th style={{ minWidth: '160px' }}>วัน/เวลาที่จองงานขนส่ง</th>
          <th style={{ minWidth: '160px' }}>Trip LOY Ref</th>
        </tr>
        <tr>
          <th></th>
          <th>
            {stage == 'WAITING' && (
              <FormControl
                componentClass="select"
                placeholder="select"
                defaultValue={shipmentStatus}
                value={shipmentStatus}
                onChange={event => searchShipmentStatus(event)}
              >
                <option value={0}>ทั้งหมด</option>
                <option value={2}>รอขนส่งรับงาน</option>
                <option value={10}>รอเริ่มงาน</option>
              </FormControl>
            )}

            {stage == 'INPROGRESS' && (
              <FormControl
                componentClass="select"
                placeholder="select"
                defaultValue={shipmentStatus}
                value={shipmentStatus}
                onChange={event => searchShipmentStatus(event)}
              >
                <option value={0}>ทั้งหมด</option>
                <option value={3}>กำลังดำเนินการขนส่ง</option>
                <option value={9}>กำลังคืนคลัง</option>
              </FormControl>
            )}
          </th>
          <th>
            <FormControl
              componentClass="select"
              placeholder="select"
              defaultValue={shipmentType}
              onChange={
                event => searchShipmentType(event)
                // this.setState({
                // shipmentType: event.target.value,
                // page_number: 1,
                // })
              }
            >
              <option value={0}>ทั้งหมด</option>
              <option value={1}>Last Mile (LM)</option>
              <option value={2}>Return to Merchant (RM)</option>
              <option value={5}>Return (RT)</option>
              <option value={9}>First Mile (FM)</option>
              <option value={10}>Shuttle Haul (SH)</option>
              <option value={11}>Reject Return (RR)</option>
              <option value={12}>Direct Ship (DS)</option>
              <option value={13}>Line Haul (LH)</option>
              <option value={14}>BU First Mile (BUFM)</option>
            </FormControl>
          </th>
          <th>
            <FormControl
              type="text"
              placeholder="ค้นหา Shipment Number"
              value={shipmentNumber}
              onChange={event => searchShipmentNumber(event)}
            />
          </th>
          <th>
            <FormControl
              type="text"
              placeholder="ค้นหา Booking Number"
              value={bookingNumber}
              onChange={event => searchBookingNumber(event)}
            />
          </th>
          <th>
            <DateRangePicker
              onApply={(event, picker) => handleDateRangePicker('pickupDate', picker)}
              onCancel={cancelDisplayPickupDate}
              containerStyles={{ display: 'block' }}
              opens="right"
              format="DD/MM/YYYY"
            >
              <FormControl type="text" value={displayPickupDate} placeholder="วันที่รับสินค้า" />
            </DateRangePicker>
          </th>
          <th style={{ position: 'relative' }}>
            <AutoSuggestTransporter setIdSearch={id => onChangeTransporter(id)} />
          </th>
          <th></th>
          {stage === 'WAITINGCOD' && <th></th>}
          <th></th>
          <th>
            <FormControl
              type="text"
              placeholder="ค้นหาเลข tracking number "
              value={trackingNumber}
              onChange={event => searchTrackingNumber(event)}
            />
          </th>
          <th>
            <DateRangePicker
              onApply={(event, picker) => handleDateRangePicker('createDate', picker)}
              onCancel={cancelDisplayCreateDate}
              containerStyles={{ display: 'block' }}
              opens="right"
              format="DD/MM/YYYY"
            >
              <FormControl
                type="text"
                value={displayCreateDate}
                placeholder="วันที่เวลาที่จองงานขนส่ง"
              />
            </DateRangePicker>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td colSpan="15">Loading...</td>
          </tr>
        )}

        {error && (
          <tr>
            <td colSpan="15">Error</td>
          </tr>
        )}

        {!loading && !error && data && data.length === 0 && (
          <tr>
            <td colSpan="15">ไม่มีข้อมูล</td>
          </tr>
        )}

        {data &&
          data.map((item, index) => (
            <tr key={index}>
              <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}>
                  <a
                    href={`/ka-shipment-detail?id=${item._id}`}
                    target="_blank"
                    style={{ float: 'left' }}
                    className="btn btn-default"
                  >
                    <i style={{ marginTop: '3px' }} className="fas fa-search"></i>
                  </a>
                </OverlayTrigger>
                {item.shipment_status !== 4 && item.shipment_status !== 5 && (
                  <NavDropdownContainer
                    title={
                      <Button>
                        เครื่องมือ <i className="fal fa-angle-down"></i>
                      </Button>
                    }
                  >
                    <NavItem onClick={() => handleAssignAgent(item, agentList)}>
                      <i className="fas fa-user-tie"></i> เปลี่ยนนายหน้า
                    </NavItem>
                    <NavItem onClick={() => handleAssignDriver(item)}>
                      <i className="fas fa-truck"></i> เพิ่มคนขับ
                    </NavItem>
                    {false && item.shipment_status == 10 && item.driver_user_id && (
                      <NavItem onClick={() => restoreShipment(item)}>
                        <i className="fas fa-history"></i> คืนค่าเริ่มต้น
                      </NavItem>
                    )}
                    {item.shipment_status !== 4 && item.shipment_status !== 5 && (
                      <NavItem onClick={() => cancelShipment(item)}>
                        <i className="fas fa-times"></i> ยกเลิก
                      </NavItem>
                    )}
                  </NavDropdownContainer>
                )}
              </td>
              <td>
                {renderStatus(
                  item.shipment_status,
                  item.confirm_cod_status,
                  item.confirm_delivery_status
                )}
              </td>
              <td>{renderStatusMode(item.lazada_order_type)}</td>
              <td>{item.shipment_trip}</td>
              <td>{item.format_bookname}</td>
              <td>
                <Moment format="DD/MM/YYYY HH:mm:ss">{item.pick_up_date}</Moment>
              </td>
              <td>
                <OverlayTrigger
                  trigger="hover"
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-positioned-${item._id}`}>
                      <PopUpItemWrapper>
                        ID พนักงานขับรถ:<span>{`#${item.driver_number}` || '-'}</span>
                      </PopUpItemWrapper>
                      <PopUpItemWrapper style={{ paddingLeft: '16px' }}>
                        พนักงานขับรถ:<span>{item.driver_name || '-'}</span>
                      </PopUpItemWrapper>
                      <PopUpItemWrapper style={{ paddingLeft: '38px' }}>
                        ทะเบียนรถ:<span>{item.user_setting_truck_license_plate || '-'}</span>
                      </PopUpItemWrapper>
                      <PopUpItemWrapper style={{ paddingLeft: '47px' }}>
                        เบอร์โทร:
                        <span>
                          {item.driver_phone_number
                            ? `+${item.driver_phone_code} ${item.driver_phone_number}`
                            : '-'}
                        </span>
                      </PopUpItemWrapper>
                    </Popover>
                  }
                >
                  <b style={{ cursor: 'pointer' }}>
                    {item.driver_number ? `#${item.driver_number}` : ''}
                    <br />
                    {item.transporter_name ? item.transporter_name : ''}
                  </b>
                </OverlayTrigger>
              </td>
              <td>
                <OverlayTrigger
                  trigger="hover"
                  key="bottom"
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-positioned-${item._id}`}>
                      <PopUpItemWrapper>
                        COD ที่ต้องโอน:<span>{formatMoney(item.shipment_accept_cod || 0)}</span>
                      </PopUpItemWrapper>
                      <PopUpItemWrapper style={{ paddingLeft: '14px' }}>
                        COD ยกเลิก:<span>{formatMoney(item.shipment_cancel_cod || 0)}</span>
                      </PopUpItemWrapper>
                      <PopUpItemWrapper style={{ paddingLeft: '12px' }}>
                        COD ทั้งหมด:
                        <span>{formatMoney(item.shipment_total_cod || 0)}</span>
                      </PopUpItemWrapper>
                    </Popover>
                  }
                >
                  <b style={{ cursor: 'pointer' }}>{formatMoney(item.shipment_accept_cod || 0)}</b>
                </OverlayTrigger>
              </td>
              {stage === 'WAITINGCOD' && (
                <td>
                  {minDateTime(
                    filterDeliveryList(item.tracking.tracking_detail_lazada.delivery)
                      .map(deliveryItem => deliveryItem.tranfer_cod_cash_date)
                      .filter(item => !!item)
                  )}
                </td>
              )}
              <td>
                {
                  <OverlayTrigger
                    trigger="hover"
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Popover id={`popover-positioned-${item._id}`}>
                        {item.shipment_address
                          .filter(shipmentAddress => shipmentAddress.mode === 'รับสินค้า')
                          .map((address, key) => (
                            <PopUpItemWrapper>{`${address.tag_name}`} </PopUpItemWrapper>
                          ))}
                      </Popover>
                    }
                  >
                    <b style={{ cursor: 'pointer' }}>
                      {item.shipment_address.filter(
                        shipmentAddress => shipmentAddress.mode === 'รับสินค้า'
                      ).length > 1
                        ? `${
                            item.shipment_address.filter(
                              shipmentAddress => shipmentAddress.mode === 'รับสินค้า'
                            ).length
                          } จุด`
                        : item.shipment_address[0].tag_name}
                    </b>
                  </OverlayTrigger>
                }
              </td>
              <td>
                {stage === 'WAITINGCOD' ? (
                  <OverlayTrigger
                    trigger="focus"
                    placement="left"
                    overlay={
                      <Popover id="popover-table" style={{ maxWidth: 500 }}>
                        {renderDeliveryList(item.tracking.tracking_detail_lazada.delivery)}
                      </Popover>
                    }
                  >
                    <button
                      style={{
                        backgroundColor: 'transparent',
                        textDecorationStyle: 'dashed',
                        border: 0,
                      }}
                    >
                      {`${
                        filterDeliveryList(item.tracking.tracking_detail_lazada.delivery).length
                      } ชิ้น`}
                    </button>
                  </OverlayTrigger>
                ) : (
                  <OverlayTrigger
                    trigger="hover"
                    key="bottom"
                    placement="bottom"
                    overlay={
                      <Popover id={`popover-positioned-${item._id}`}>
                        {item.shipment_address
                          .filter(shipmentAddress => shipmentAddress.mode === 'ส่งสินค้า')
                          .map((address, key) => (
                            <PopUpItemWrapper key={key}>{`${
                              address.commodity ? address.commodity[0].name : '-'
                            }`}</PopUpItemWrapper>
                          ))}
                      </Popover>
                    }
                  >
                    <b style={{ cursor: 'pointer' }}>
                      {item.shipment_address.filter(
                        shipmentAddress => shipmentAddress.mode === 'ส่งสินค้า'
                      ).length > 1
                        ? `${
                            item.shipment_address.filter(
                              shipmentAddress => shipmentAddress.mode === 'ส่งสินค้า'
                            ).length
                          } ชิ้น`
                        : item.shipment_address.filter(
                            shipmentAddress => shipmentAddress.mode === 'ส่งสินค้า'
                          ).length == 0
                        ? '0 ชิ้น'
                        : item.shipment_address.filter(
                            shipmentAddress => shipmentAddress.mode === 'ส่งสินค้า'
                          )[0]
                        ? item.shipment_address.filter(
                            shipmentAddress => shipmentAddress.mode === 'ส่งสินค้า'
                          )[0].commodity[0].name
                        : '-'}
                    </b>
                  </OverlayTrigger>
                )}
              </td>
              <td>
                <Moment format="DD/MM/YYYY HH:mm:ss">{item.create_date}</Moment>
              </td>
              <td> {item?.shipment_ref_id ? item?.shipment_ref_id : '-'}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default ShipmentTable;
