import gql from 'graphql-tag';

export default gql`
    query shipmentSlas(
        $params: paramsShipmentSlas
        $sort: sort
        $page: Int
        $showData: Int!
    ){
        shipmentSlas(
        params:$params
        sort: $sort
            showData:$showData
        page: $page
        ){
            shipmentSlas{
                _id
                shipment
                title
                sla_case_number
                sla_exp
                sla_status
                completed_date
              }
              totalPage
              totalDocument
        }
    } 
`;
