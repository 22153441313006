import gql from 'graphql-tag';

export default gql`
	query DetailRating($shipment_id: ID,$check_agent_accept: ID) {
		trackingList(shipment_id: $shipment_id, check_agent_accept: $check_agent_accept) {
			tracking {
				shipment_tracking_number
				driver_name
				review_detail
				review_score
				review_reason {
					th
					en
				}
			}
		}
    	shipmentList(_id: $shipment_id, see_detail: true) {
			shipment {
				shipment_number
				driver_name_under_mama
				shipment_address {
					subdictrict
				}
			}
		}
	}
`;
