import React from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import { Input, Label, Tag, SelectBox, Tabs } from '../../components';
import { Formik } from 'formik';
import moment from 'moment';

import PaymentOrderConfirm from '../PaymentOrderConfirm';
import DeletePaymentOrder from '../DeletePaymentOrder';

// container
import PaymentList from './container/PaymentList';
import PaymentDocument from './container/PaymentDocument';
import PaymentInvoiceDocument from './container/PaymentInvoiceDocument';

// utils
import formatCurrency from '../../../../utils/formatCurrency';
import { formatDateTime } from '../../../../utils/dateTime';

// graphql
import paymentOrderList from '../../graphql/query/paymentOrderList';
import paymentOrderDelete from '../../graphql/mutation/paymentOrderDelete';

const ModalContainer = styled(Modal)`
  font-size: 20px;

  .modal-dialog {
    max-width: 1300px;
    width: 100%;
  }

  & .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .text-header {
      font-size: 24px;
      font-weight: bold;
    }

    & .button-header {
      display: flex;
      flex-direction: row;
    }
  }

  & .condition-payment-container {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    width: 100%;
    height: 240px;

    & .title-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 50px;

      & .icon-title {
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        width: 36px;
        height: 26px;
        margin: 10px 10px 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & .text-title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
    }

    & .body-container {
      display: flex;
      flex-direction: row;
      font-size: 20px;

      & .condition-container {
        display: flex;
        flex-direction: row;

        & .column {
          width: 100%;
        }
        & .column1 {
          width: 35%;
        }
        & .column2 {
          width: 65%;
        }
      }
    }
  }

  & .detail-container {
    width: 100%;
    margin-top: 20px;
  }
`;

const ButtonBlue = styled(Button)`
  background-color: #3377b7 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bold !important;
`;

const ButtonDefault = styled(Button)`
  font-size: 20px !important;
  font-weight: bold !important;
`;

const LineVertical = styled.div`
  border-left: 1px solid #e3e3e3;
`;

const LineHorizontal = styled.div`
  border-top: 1px solid #e3e3e3;
`;

const selectOption1 = [{ value: '', label: 'เลือก' }];

const selectOption2 = [{ value: '', label: 'เลือก' }];

const selectOption3 = [
  {
    value: '0',
    label: 'เลือก',
  },
];

const selectOptionPaymentType = [
  { value: 1, label: 'Advance Payment' },
  { value: 2, label: 'COGs' },
  { value: 3, label: 'Operating Cost' },
  { value: 4, label: 'Petty Cash' },
];

const selectOptionIdShipment = [{ value: '', label: 'เลือก' }];

const selectOptionDate = [{ value: '', label: 'เลือก' }];

const selectOptionTypeVendor = [{ value: 0, label: 'ไม่มีข้อมูล' }];

class PaymentOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenPaymentOrderConfirm: false,
      isOpenDeletePayment: false,
      selectTab: 1,
    };
  }

  selectTab = indexTab => {
    this.setState({
      selectTab: indexTab,
    });
  };

  checkTabsActive = indexTab => {
    return this.state.selectTab === indexTab;
  };

  checkDisabled(paymentOrder) {
    const { payment_order_status } = paymentOrder;

    if (payment_order_status === 1) {
      return false;
    }
    return true;
  }

  transporterName = paymentOrder => {
    const { transporter_name, driver_name, driver_user_id, transporter } = paymentOrder;
    const id = transporter_name === 'GIZTIX' ? driver_user_id : transporter;
    const name = transporter_name === 'GIZTIX' ? driver_name : transporter_name;
    return { value: id, label: name };
  };

  paymentType = paymentOrder => {
    const { payment_type } = paymentOrder;
    const name = payment_type;
    switch (name) {
      case 1:
        return { value: 1, label: 'Advance Payment' };
      case 2:
        return { value: 1, label: 'COGs' };
      case 3:
        return { value: 1, label: 'Operating Cost' };
      case 4:
        return { value: 1, label: 'Petty Cash' };
      default:
        return name;
    }
  };

  paymentStatusTag(paymentStatus) {
    switch (paymentStatus) {
      case 1:
        return <Tag color="yellow">รอตรวจสอบ</Tag>;
      case 2:
        return <Tag color="blue--light">รอสั่งจ่าย</Tag>;
      case 3:
        return <Tag color="blue">สั่งจ่ายแล้ว</Tag>;
      case 4:
        return <Tag color="red">ปฏิเสธ</Tag>;
      case 5:
        return <Tag color="white">ยกเลิก</Tag>;
      default:
        return <Tag>{paymentStatus}</Tag>;
    }
  }

  render() {
    const tabs = [
      {
        name: 'รายการจ่าย',
        active: this.checkTabsActive(1),
        clicked: () => this.selectTab(1),
      },
      {
        name: 'ใบแจ้งหนี้/ใบเสร็จ/ภาษีซื้อ',
        active: this.checkTabsActive(2),
        clicked: () => this.selectTab(2),
      },
      {
        name: 'เอกสารทั่วไป',
        active: this.checkTabsActive(3),
        clicked: () => this.selectTab(3),
      },
    ];

    return (
      <Query
        query={paymentOrderList}
        variables={{
          _id: this.props.paymentOrderId,
        }}
      >
        {({ loading, error, data, refetch, variables }) => {
          if (loading || error) {
            return (
              <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>{loading ? 'Loading...' : error.message}</Modal.Body>
              </Modal>
            );
          }
          const { payment_order } = data.paymentOrderList;

          if (payment_order.length === 0) {
            return (
              <Modal show={this.props.show} onHide={this.props.onHide}>
                <Modal.Body>
                  <div>ไม่พบข้อมูล</div>
                </Modal.Body>
              </Modal>
            );
          }
          return (
            <Formik
              initialValues={{
                remark: `${
                  payment_order[0].payments && payment_order[0].payments.payment_remark
                    ? payment_order[0].payments.payment_remark
                    : ''
                }`,
              }}
              onSubmit={(values, actions) => {
                this.setState({
                  isOpenPaymentOrderConfirm: true,
                });
              }}
            >
              {props => {
                return (
                  <ModalContainer show={this.props.show} onHide={this.props.onHide}>
                    <Modal.Header>
                      <Modal.Title>
                        <div className="header-container">
                          <div className="text-header">ใบสั่งจ่าย :</div>
                          <div className="button-header">
                            <ButtonBlue
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                props.handleSubmit();
                              }}
                              disabled={this.checkDisabled(payment_order[0])}
                            >
                              <i className="far fa-check-circle" /> ส่งตั้งเบิก
                            </ButtonBlue>
                            <ButtonDefault
                              onClick={() => {
                                this.setState({
                                  isOpenDeletePayment: true,
                                });
                              }}
                              style={{ marginRight: 10 }}
                              disabled={
                                payment_order[0].payment_order_status !== 1 ||
                                payment_order[0].payments
                              }
                            >
                              ลบ
                            </ButtonDefault>
                            <LineVertical style={{ marginRight: 10 }} />
                            <ButtonDefault onClick={this.props.onHide}>
                              <i className="far fa-times-circle" /> ปิด
                            </ButtonDefault>
                          </div>
                        </div>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="condition-payment-container">
                        <div className="title-container">
                          <div className="icon-title">
                            <i class="fas fa-caret-down"></i>
                          </div>
                          <div className="text-title">เงื่อนไขรายการจ่าย</div>
                        </div>
                        <LineHorizontal />
                        <div className="body-container">
                          <Col md={4} style={{ padding: 20 }}>
                            <Label style={{ marginBottom: 5, fontSize: 20 }} required>
                              ผู้ตั้งเบิก
                            </Label>
                            <div className="condition-container">
                              <div className="column1">
                                <SelectBox
                                  value={{
                                    value: '-',
                                    label: '-',
                                  }}
                                  options={selectOption1}
                                  disabled
                                />
                              </div>
                              <div className="column2">
                                <SelectBox
                                  value={{
                                    value: payment_order[0].admin_username,
                                    label: payment_order[0].admin_username,
                                  }}
                                  options={selectOption2}
                                  disabled
                                />
                              </div>
                            </div>
                            <Label style={{ marginBottom: 5, fontSize: 20 }} required>
                              ผู้รับเงิน <span style={{ color: '#B3B3B3' }}>(เจ้าหนี้)</span>
                            </Label>
                            <div className="column">
                              <SelectBox
                                value={this.transporterName(payment_order[0])}
                                options={selectOption3}
                                disabled
                              />
                            </div>
                          </Col>
                          <LineVertical style={{ marginBottom: 20 }} />
                          <Col md={4} style={{ padding: 20 }}>
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0, marginRight: 20 }}>
                                <Label style={{ marginBottom: 5, fontSize: 20 }} required>
                                  ประเภทการจ่าย
                                </Label>
                                <div className="column">
                                  <SelectBox
                                    value={this.paymentType(payment_order[0])}
                                    options={selectOptionPaymentType}
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col md={6} style={{ padding: 0 }}>
                                <Label style={{ marginBottom: 5, fontSize: 20 }}>
                                  รหัส Shipment/JOB
                                </Label>
                                <div className="column">
                                  <SelectBox
                                    value={{
                                      value: `${payment_order[0].shipment_number}`,
                                      label: `${payment_order[0].shipment_number}`,
                                    }}
                                    options={selectOptionIdShipment}
                                    disabled
                                  />
                                </div>
                              </Col>
                            </div>
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0, marginRight: 20 }}>
                                <Label style={{ marginBottom: 5, fontSize: 20 }} required>
                                  ระบุรอบตั้งเบิก
                                </Label>
                                <div className="column">
                                  <SelectBox
                                    value={{
                                      value: formatDateTime(payment_order[0].round_paid_date),
                                      label: formatDateTime(payment_order[0].round_paid_date),
                                    }}
                                    options={selectOptionDate}
                                    disabled
                                  />
                                </div>
                              </Col>
                              <Col md={6} style={{ padding: 0 }}>
                                <Label style={{ marginBottom: 5, fontSize: 20 }}>
                                  ประเภท Vendor
                                </Label>
                                <div className="column">
                                  <SelectBox
                                    defaultValue={0}
                                    options={selectOptionTypeVendor}
                                    disabled
                                  />
                                </div>
                              </Col>
                            </div>
                          </Col>
                          <LineVertical style={{ marginBottom: 20 }} />
                          <Col md={4} style={{ padding: 20 }}>
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0 }}>
                                สถานะ
                              </Col>
                              <Col md={6} style={{ padding: 0, textAlign: 'right' }}>
                                {payment_order[0].payments ? (
                                  this.paymentStatusTag(payment_order[0].payments.payment_status)
                                ) : (
                                  <Tag color="blue--light">รอตั้งเบิก</Tag>
                                )}
                              </Col>
                            </div>
                            {payment_order[0].payments &&
                            payment_order[0].payments.payment_status === 4 &&
                            payment_order[0].payments.reject_remark ? (
                              <div className="condition-container">
                                <Col md={6} style={{ padding: 0 }}>
                                  เหตุผล : {payment_order[0].payments.reject_remark}
                                </Col>
                              </div>
                            ) : payment_order[0].payments &&
                              payment_order[0].payments.payment_status === 5 &&
                              payment_order[0].payments.cancel_remark ? (
                              <div className="condition-container">
                                <Col md={6} style={{ padding: 0 }}>
                                  เหตุผล : {payment_order[0].payments.cancel_remark}
                                </Col>
                              </div>
                            ) : null}
                            <LineHorizontal style={{ marginTop: 10, marginBottom: 10 }} />
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0 }}>
                                วันที่ใบสั่งจ่าย:
                              </Col>
                              <Col md={6} style={{ padding: 0, textAlign: 'right' }}>
                                {payment_order[0].payments === null
                                  ? '-'
                                  : payment_order[0].payments.create_date}
                              </Col>
                            </div>
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0 }}>
                                วันที่ใบสำคัญจ่าย
                              </Col>
                              <Col md={6} style={{ padding: 0, textAlign: 'right' }}>
                                {payment_order[0].payment_voucher === null
                                  ? '-'
                                  : payment_order[0].payment_voucher.create_date === null
                                  ? '-'
                                  : payment_order[0].payment_voucher.create_date}
                              </Col>
                            </div>
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0 }}>
                                รหัสใบสำคัญจ่าย
                              </Col>
                              <Col md={6} style={{ padding: 0, textAlign: 'right' }}>
                                {payment_order[0].payment_voucher === null
                                  ? '-'
                                  : payment_order[0].payment_voucher.voucher_number === null
                                  ? '-'
                                  : payment_order[0].payment_voucher.voucher_number}
                              </Col>
                            </div>
                            <div className="condition-container">
                              <Col md={6} style={{ padding: 0 }}>
                                วันที่จ่าย
                              </Col>
                              <Col md={6} style={{ padding: 0, textAlign: 'right' }}>
                                {payment_order[0].payment_voucher === null
                                  ? '-'
                                  : payment_order[0].payment_voucher.paid_date === null
                                  ? '-'
                                  : payment_order[0].payment_voucher.paid_date}
                              </Col>
                            </div>
                          </Col>
                        </div>
                      </div>
                      <div className="detail-container">
                        <Tabs menu={tabs} />
                        {this.state.selectTab === 1 && (
                          <PaymentList paymentOrderId={payment_order[0]._id} />
                        )}
                        {this.state.selectTab === 2 && (
                          <PaymentInvoiceDocument paymentOrderId={payment_order[0]._id} />
                        )}
                        {this.state.selectTab === 3 && (
                          <PaymentDocument
                            paymentOrderId={payment_order[0]._id}
                            paymentOrderStatus={payment_order[0].payment_order_status}
                          />
                        )}
                      </div>
                      <PaymentOrderConfirm
                        show={this.state.isOpenPaymentOrderConfirm}
                        paymentOrderId={this.props.paymentOrderId}
                        remark={props.values.remark}
                        onHide={() => {
                          this.setState({ isOpenPaymentOrderConfirm: false });
                        }}
                        closePaymentOrderDetail={() => {
                          this.setState({ isOpenPaymentOrderConfirm: false }, () =>
                            this.props.onHide()
                          );
                        }}
                      />
                      <DeletePaymentOrder
                        show={this.state.isOpenDeletePayment}
                        paymentOrderId={this.props.paymentOrderId}
                        onHide={() => {
                          this.setState({ isOpenDeletePayment: false });
                        }}
                        closePaymentOrderDetail={() => {
                          this.setState({ isOpenDeletePayment: false }, () => this.props.onHide());
                        }}
                      />
                    </Modal.Body>
                  </ModalContainer>
                );
              }}
            </Formik>
          );
        }}
      </Query>
    );
  }
}

export default PaymentOrderDetail;
