import gql from 'graphql-tag';

export default gql`
  query AccountInfoList($_id: ID) {
    accountInfoList(_id: $_id) {
      account_info {
        _id
        account_info_type
        old_user
        account_business_email {
          _id
          email
        }

        account_users {
          _id
          account_user_register_from
          account_user_name
          account_user_type
          account_user_password
          account_user_phone_code
          account_user_phone_number
          account_user_display_name
          account_user_display_picture
          account_user_status
          account_user_activate_code
          create_date
          last_update
        }
        payment_bank_account {
          bank_name
          bank_branch
          account_name
          account_number
        }
      }
    }
  }
`;
