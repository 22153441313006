import gql from 'graphql-tag';

export default gql`
query search(
    $_id: ID
    $account_info_id: ID
    $status: Int
    $page_number: Int
    $order_by: String
    $order_type: String
    $summary_invoice_id: ID
    ){
      debitNoteList(
        _id 	: $_id
        account_info_id: $account_info_id
        status: $status
        show_data: 30
        page_number: $page_number
        order_by: $order_by
        order_type: $order_type
        summary_invoice_id: $summary_invoice_id
      ){
      total_page
      debitNote{
          _id
          debit_note_number
          status
          account_info_id
          account_info_business_name
          issue_date
          due_date
          credit_used
          debit_note_total_price
          total_price_vat
          total_price_wnt1
          total_price_wnt3
          total_price_wnt5
          debit_note_document
          paid_type
          receipt_id
          receipt_number
          receipt_date
          receipt_create_date
          receipt_document
          paid_bank_transfer{
            transfer_price
            transfer_pic
            transfer_date
          }
          paid_credit_card_detail{
              bank
              last_digits
              brand
              name
          }
          read_status
          create_date
          last_update
          summary_invoice_id
          summary_invoice_number
          summary_invoice_create_date
      }
      }
  }
`;
