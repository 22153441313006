import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../../../graphql/query/shipmentList';

export default gql`
  query search($_id: ID) {
    shipmentList(_id: $_id, shipment_prefix: "GLZ", see_detail: true) {
      shipment {
        ...shipmentFields
      }

      total_page
    }

    trackingList(shipment_id: $_id) {
      tracking {
        working
      }
      total_page
    }
  }

  ${shipmentListFragment}
`;
