import gql from 'graphql-tag';

export default gql`
query searchDashboardByDay(
    $month_arrary : [String]
    $sum: Boolean
){
    shipmentSummaryDashboardByDay(
        month_arrary: $month_arrary
        sum : $sum
    )
    {
      month
      shipment
      price
      max_shipment
      max_price
    }
}
`;
