import gql from "graphql-tag";

export default gql`
  query LazadaImportOrderListShipments (
    $_id: ID
    $create_shipment: Int
    $page_number: Int
    $name: String
    $pick_up_date: String
    $create_date: String
    $transfer_date: String
    $status: Int
    $account_info_id: ID
  ) {
    lazadaImportOrderList(
      _id: $_id
      create_shipment: $create_shipment
      show_data: 30
      page_number: $page_number
      name: $name
      pick_up_date: $pick_up_date
      create_date: $create_date
      transfer_date: $transfer_date
      status: $status
      account_info_id: $account_info_id
    ) {
      order {
        _id
        name
        pick_up_date
        status
        total_shipment
        total_accept_cod
        total_price_cod
        total_cancel_cod
        document_driver
        file
        transfer_date
        create_date
        order_type
      }

      total_page
    }
  }
`;
