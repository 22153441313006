import React, { Component } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import { Query } from "react-apollo";
import { client } from '../../index';
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger, Button, Modal, FormGroup, FormControl,Label } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from "components/CardTable/CardTable.jsx";
import accountBusinessListQuery from "./graphql/query/accountBusinessList"
import challengeDistancesListQuery from "./graphql/query/challengeDistancesList"
import urijs from 'urijs';

import baseUrl from "./../../config/baseUrl" 
import lambdaUrl from './../../config/lambdaUrl'

//query
import adminListQuery from './graphql/query/adminList';

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;

class ChallengeDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total_page: 0,
            page_number: 1,
            order_by: "rank",
            order_type: 1,
            business_req_number: "",
            account_info_business_name: "",
            showReport: false, 
            reportDate: "" ,
            show_reportDate : ""
        }
    }

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }

    getIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).id;
        return id;
      };

    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    nextPage(page, total_page, refetch) {
        // console.log(page+1);
        this.setState({ page_number: page + 1, total_page });
        // console.log(this.state);
        refetch();
    }

    openReport(mode) {
        this.setState({ showReport: true, reportDate: "" ,show_reportDate : ""});
    }

    handleDateRangePickerReport(event, picker) {
        const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
        const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
        this.setState({ reportDate: date , show_reportDate: show});
    }

    exportReport = async () => {
        const { data } = await client.query({
            query: adminListQuery,
        });

        const { admin } = data.adminList;

        if (admin.length === 0) {
            alert('ไม่พบ Admin');
            return;
        }

        const adminId = admin[0]._id;

        let idUrl = this.getIdFromUrl()

        const body = JSON.stringify({
            admin_id: adminId,
            challenge: idUrl,
          });
    
        const configs = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // let uri =`${baseUrl}/challengedistancereport?_id=${idUrl}`; 
        let url =`/challenge-distance-report`; 

        const result = axios
            .post(lambdaUrl + url, body, configs)
            .then(res => {
                console.log(res);
            })
            .catch(error => {
                console.log(error);
            });

            setTimeout(() => {
                window.location = '/downloadreport';
            }, 1000);

        this.setState({ showReport: false, reportDate: "" ,show_reportDate : "",modeReport: ""});
    }

    renderChess(rank){
        switch (rank) {
            case 1:
                return <i style={{color:"#FFCE00",paddingRight:"1px"}} class="fas fa-chess-queen"></i>;
            case 2:
                return <i style={{color:"#B5B5B5",paddingRight:"1px"}} class="fas fa-chess-queen"></i>;
            case 3:
                return <i style={{color:"#CC6600",paddingRight:"1px"}} class="fas fa-chess-queen"></i>;
            case 4:
                return <i style={{color:"#FFD09A",paddingRight:"1px"}} class="fas fa-chess-queen"></i>;
            case 5:
                return <i style={{color:"#9B4E00",paddingRight:"1px"}} class="fas fa-chess-queen"></i>;
            case 6:
                return <i style={{paddingRight:"1px"}} class="fas fa-chess-pawn"></i>;
            case 7:
                return <i style={{paddingRight:"1px"}} class="fas fa-chess-pawn"></i>;
            case 8:
                return <i style={{paddingRight:"1px"}} class="fas fa-chess-pawn"></i>;
            case 9:
                return <i style={{paddingRight:"1px"}} class="fas fa-chess-pawn"></i>;
            case 10:
                return <i style={{paddingRight:"1px"}} class="fas fa-chess-pawn"></i>;
            default:
                return <i style={{paddingRight:"1px"}} class="fas fa-circle"></i>;
        }
    }

    calcRank(currentRank = 0, formRank = 0) {
		if(formRank === 0 || formRank === currentRank) {
			return '-'
		}

		const subRank = Math.abs(formRank - currentRank);
        const label = subRank !== 0 ? subRank : '-';
        
        return label;
    }

    renderRank(rank, rank_old) {
        // console.log("challenge" , challenge);
        let rankFinal = <span>{this.renderChess(rank)} {rank}</span>;
        if(rank_old > 0){ 
            if(rank_old < rank){
                rankFinal = <span>{this.renderChess(rank)} {rank} <small style={{color:"#ED1C24",paddingLeft:"10px"}}><i class="fal fa-chevron-down"></i> {this.calcRank(rank, rank_old)}Rank</small> </span>;
            }
            else{
                rankFinal = <span>{this.renderChess(rank)} {rank} <small style={{color:"#01A714",paddingLeft:"10px"}}><i class="fal fa-chevron-up"></i> {this.calcRank(rank, rank_old)} Rank</small> </span>;
            }
        }
        
        return rankFinal;
    }

    render() {
        // console.log(this.state);
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Query
                                query={challengeDistancesListQuery}
                                variables={{
                                    params: {
                                        challenge: this.getIdFromUrl(),
                                        business_req_number: this.state.business_req_number,
                                        account_info_business_name: this.state.account_info_business_name,
                                    },
                                    sort: {
                                        order_by: this.state.order_by,
                                        order_type: this.state.order_type,
                                    },
                                    page: this.state.page_number,
                                }}
                            >
                                {
                                    ({ loading, error, data, refetch, variables }) => {
                                        {console.log(variables);}

                                        var itemLength = 'loading';
                                        const itemIndicatorSize = (this.state.page_number - 1)*30;
                                        if(data.challengeDistances){
                                            if(data.challengeDistances.challengeDistances.length > 0){
                                                itemLength = data.challengeDistances.challengeDistances.length;
                                            }
                                        }
                                        return (
                                            <div>
                                                <CardTable
                                                    title="ตารางรายการ Campaign GTX BU Distance Challenge"
                                                    category="ข้อมูลงาน GIZTIX Challenge"
                                                    ctTableFullWidth
                                                    ctTableResponsive
                                                    rightButton={
                                                        <div style={{ float: "right", marginLeft: "15px" }}>
                                                            <Button style={{marginLeft:"15px"}} onClick={() => this.exportReport()}> <i className="fal fa-clipboard-user"></i> Export Excel </Button>
                                                        </div>
                                                    }
                                                    content={
                                                        <div>
                                                            <PageWrapper style={{ textAlign: "right" }}>
                                                                <div className="text">{this.state.page_number === 1 ? `1 - ${itemLength}` : `${itemIndicatorSize + 1} - ${itemLength + itemIndicatorSize}`} หน้า :</div>
                                                                    <FormControl
                                                                        type="number"
                                                                        min={1}
                                                                        value={this.state.page_number}
                                                                        onChange={(event) => 
                                                                        setInterval(this.nextPage(Number(event.target.value) -1, loading || error ? '0' : data.challengeDistances.totalPage,refetch), 1000)
                                                                        }
                                                                        name="page_number"
                                                                    />
                                                                <div className="text"> {loading || error ? '' : `จาก ${data && data.challengeDistances && data.challengeDistances.totalPage}`}</div>
                                                            </PageWrapper>
                                                            <div className="table-vertical">            
                                                                <Table striped hover>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{minWidth:"120px"}} className={this.renderSorting("rank")} onClick={() => this.sort("rank", refetch)}>Rank</th>
                                                                            <th style={{minWidth:"100px"}} className={this.renderSorting("business_req_number")} onClick={() => this.sort("business_req_number", refetch)}>รหัสคำร้อง</th>
                                                                            <th style={{minWidth:"150px"}} className={this.renderSorting("account_info_business_name")} onClick={() => this.sort("account_info_business_name", refetch)}>ชื่อบริษัท <small>(คลิกที่ชื่อเพิ่อดูโปรไฟล์)</small></th>
                                                                            <th style={{minWidth:"110px"}} >ระยะทางรวม</th>
                                                                            <th style={{minWidth:"90px"}} >จำนวน Book</th>
                                                                        </tr>
                                                                        <tr className="tableFilter">
                                                                            <th> </th>
                                                                            <th>
                                                                                <FormControl
                                                                                    type="text"
                                                                                    value={this.state.business_req_number}
                                                                                    placeholder="ค้นหารหัสลูกค้า"
                                                                                    onChange={(event) => this.setState({ business_req_number: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                    name="business_req_number"
                                                                                />
                                                                            </th>
                                                                            <th>
                                                                                <FormControl
                                                                                    type="text"
                                                                                    value={this.state.account_info_business_name}
                                                                                    placeholder="ค้นหาอีเมล"
                                                                                    onChange={(event) => this.setState({ account_info_business_name: event.target.value,page_number:1 }, () => { refetch() })}
                                                                                    name="account_info_business_name"
                                                                                />
                                                                            </th>
                                                                            <th> </th>
                                                                            <th> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {(loading || error) ?
                                                                            (
                                                                                <tr>
                                                                                    <td colSpan="5" className="textCenter">{(loading) ? "Loading..." : error.message}</td>
                                                                                </tr>
                                                                            ) : 
                                                                            data.challengeDistances.challengeDistances.map((prop, key) => (
                                                                                <tr key={key}>
                                                                                    <td>{(prop.rank) ? this.renderRank(prop.rank, prop.rank_old) : "-"}</td>
                                                                                    <td>BU{prop.business_req_number}</td>
                                                                                    <td>
                                                                                    <a style={{marginRight:"10px",float: "left"}} href={`/giztixbusinessdetail?id=${prop.account_info}`} target="_blank">{prop.account_info_business_name}</a>
                                                                                    </td>
                                                                                    <td>{(prop.distance) ? `${parseFloat(prop.distance).toLocaleString('en-US', { maximumFractionDigits: 2 })} กม.`: "0 กม."}</td>
                                                                                    <td>{(prop.shipment) ? `${parseFloat(prop.shipment).toLocaleString('en-US', { maximumFractionDigits: 2 })}`: "0"}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>        
                                                        </div>
                                                    }
                                                />

                                                <div style={{ textAlign: "right" }}>

                                                    {(loading) ? null :
                                                        (<ReactPaginate pageCount={data.challengeDistances.totalPage} forcePage={this.state.page_number - 1} containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected, data.challengeDistances.totalPage, refetch); }} />)
                                                    }
                                                </div>

                                                <Modal show={this.state.showReport} onHide={() => this.setState({ showReport: false })}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>รายงาน Challenge 1 November - 24 December</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row style={{ marginBottom: "10px" }}>
                                                            <Col md={12}> ช่วงวันที่</Col>
                                                        </Row>
                                                        <FormGroup controlId="formValidationError1">
                                                            <DateRangePicker 
                                                                onApply={(event,picker) => this.handleDateRangePickerReport(event,picker)} 
                                                                onCancel={() => this.setState({reportDate : "",show_reportDate : ""})} 
                                                                containerStyles={{display: "block"}}
                                                                opens='right'
                                                                format='DD/MM/YYYY'
                                                            >
                                                                <FormControl
                                                                    type="text"
                                                                    value={this.state.show_reportDate}
                                                                    placeholder="ค้นหาวันที่สมัคร"
                                                                    name="reportDate"
                                                                />
                                                            </DateRangePicker>
                                                        </FormGroup>
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" onClick={() => this.exportReport()}>ดาวน์โหลดรายงาน</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                            </div>
                                        )
                                    }
                                }
                            </Query>

                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ChallengeDetail;