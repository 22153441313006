import gql from 'graphql-tag';

export default gql`
  query search(
    $_id: ID
    $page_number: Int
    $order_by: String
    $order_type: String
    $account_info_status: Int
    $account_info_email: String
    $account_info_phone_number: String
    $account_info_business_type_th: String
    $create_date: String
    $old_user: Int
    $account_info_register_channel: Int
    $account_info_number: String
    $account_info_type: Int
    $account_info_customer_type: Int
    $account_info_business_name: String
    $customer_service_type: String
  ) {
    accountInfoList(
      _id: $_id
      account_info_type: $account_info_type
      page_number: $page_number
      show_data: 30
      order_by: $order_by
      order_type: $order_type
      create_date: $create_date
      account_info_status: $account_info_status
      account_info_email: $account_info_email
      account_info_phone_number: $account_info_phone_number
      account_info_business_type: { th: $account_info_business_type_th }
      old_user: $old_user
      account_info_register_channel: $account_info_register_channel
      account_info_number: $account_info_number
      account_info_customer_type: $account_info_customer_type
      account_info_business_name: $account_info_business_name
      customer_service_type: $customer_service_type
    ) {
      account_info {
        _id
        account_info_number
        account_info_type
        account_info_customer_type
        old_user
        account_info_register_channel
        account_info_status

        account_info_business_type {
          th
          en
        }

        account_info_business_name
        account_info_tax_id
        account_info_postcode
        account_info_branch
        account_info_email
        account_info_address
        account_info_country
        account_info_province
        account_info_district
        account_info_subdistrict
        account_info_corporate_type
        account_info_corporate_phone_code
        account_info_corporate_phone_number
        account_info_corporate_co_phone_code
        account_info_corporate_co_phone_number
        account_info_postcode
        account_info_tel
        account_info_phone_code
        account_info_phone_number
        account_info_logo
        account_info_location_map
        account_info_tracking_url
        create_date
        last_update
        account_business_email {
          _id
          email
        }

        account_users {
          _id
          account_user_register_from
          account_user_name
          account_user_type
          account_user_password
          account_user_phone_code
          account_user_phone_number
          account_user_display_name
          account_user_display_picture
          account_user_status
          account_user_activate_code
          create_date
          last_update
        }

        account_documents {
          _id
          account_document_type
          account_document_name
          account_document_path
          create_date
          last_update
        }

        account_billing_addresses {
          _id
          account_billing_address_tax
          account_billing_address_name
          account_billing_address_address
          account_billing_address_country
          account_billing_address_province
          account_billing_address_district
          account_billing_address_postcode
          account_billing_address_tel
          account_billing_address_email
          create_date
          last_update
        }

        account_info_note
        customer_service_type
      }
      total_page
    }
  }
`;
