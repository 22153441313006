import React from "react";
import { CustomCheckbox as Checkbox } from "./styled";
const CustomCheckBox = (props) => {
  return (
    <Checkbox
      {...props}
    >
      <span className="checkmark"></span>
    </Checkbox>
  );
};

export default CustomCheckBox;
