import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { graphql, compose } from 'react-apollo';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';

import { style } from 'variables/Variables.jsx';

import adminListQuery from '../../utils/AdminName/graphql/query/adminList';

import dashboardRoutes from 'routes/dashboard.jsx';
import dashboardRoutesShopee from 'routes/dashboardShopee.jsx';
import requireAuth from 'requireAuth.js';
import CurrentUser from 'utils/CurrentUser';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.state = {
      _notificationSystem: null,
    };
  }

  handleNotificationClick(position) {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15,
    });
  }
  componentDidMount() {
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    var _notificationSystem = this.refs.notificationSystem;
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = 'success';
        break;
      case 2:
        level = 'warning';
        break;
      case 3:
        level = 'error';
        break;
      case 4:
        level = 'info';
        break;
      default:
        break;
    }
    // _notificationSystem.addNotification({
    //   title: <span data-notify="icon" className="pe-7s-gift" />,
    //   message: (
    //     <div>
    //       Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
    //       every web developer.
    //     </div>
    //   ),
    //   level: level,
    //   position: "tr",
    //   autoDismiss: 15
    // });
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
    if (e.history.action === 'PUSH') {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  render() {
    let adminName = '';

    if (
      this.props.adminListQuery &&
      this.props.adminListQuery.loading == false &&
      this.props.adminListQuery.adminList != undefined
    ) {
      adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
    }

    const routes = adminName === 'shopee' ? dashboardRoutesShopee : dashboardRoutes;
    const renderRoute = routes.map((route, key) => {
      if (route.name === 'Notifications') {
        return (
          <Route
            path={route.path}
            key={key}
            render={routeProps => (
              <route.component {...routeProps} handleClick={this.handleNotificationClick} />
            )}
          />
        );
      }

      if (route.redirect) {
        if (adminName === 'shopee') {
          return <Redirect from="/" to="/shopee" key={key} />;
        } else {
          return <Redirect from={route.path} to={route.to} key={key} />;
        }
      }

      if (route.subMenu) {
        return route.subMenu.map((subRoute, subKey) => (
          <Route
            path={subRoute.path}
            render={props => (
              <div className="main-panel">
                <Sidebar {...this.props} />
                <Header {...this.props} adminName={adminName} />
                <CurrentUser>
                  <subRoute.component {...props} />
                </CurrentUser>
                <Footer />
              </div>
            )}
            key={subKey}
          />
        ));
      }

      return (
        <Route
          path={route.path}
          render={props => (
            <div className={route.isHideLayout ? undefined : 'main-panel'}>
              {!route.isHideLayout && (
                <>
                  <Sidebar {...this.props} />
                  <Header {...this.props} adminName={adminName} />
                </>
              )}

              <CurrentUser>
                <route.component {...props} />
              </CurrentUser>

              {!route.isHideLayout && <Footer />}
            </div>
          )}
          key={key}
        />
      );
    });

    return (
      <div className="wrapper">
        <NotificationSystem ref="notificationSystem" style={style} />
        <div id="main-panel" ref="mainPanel">
          <Switch>{renderRoute}</Switch>
        </div>
      </div>
    );
  }
}

export default compose(graphql(adminListQuery, { name: 'adminListQuery' }))(Dashboard);
