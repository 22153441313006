import gql from 'graphql-tag';

export default gql`
mutation createCosting(
    $createCosting: createCostingArg
){
    createCosting(
      createCosting: $createCosting
    ){
      _id
    }
  }
`;
