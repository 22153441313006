import React from 'react';
import styled from 'styled-components';
import color from 'config/color';
import URI from 'urijs';
import { Query, graphql, compose } from 'react-apollo';
import accountInfoListQuery from './graphql/query/accountInfoList';

const MenuContainer = styled.div`
	& ul {
		margin: 0px;
		padding: 0px;
		list-style-type: none;
	}

	& ul > li {
		font-size: 20px;
		padding: 14px 12px 12px 20px;
		color: #000000;
		border-radius: 4px;
		line-height: 1;
		position: relative;

		& a {
			text-decoration: none;
			color: inherit;
			display: inline-block;
			width: 100%;
			cursor: pointer;
		}

		& i {
			font-size: 20px;
			padding-right: 12px;
		}

		&.active {
			background-color: #f2f2f2;
			font-weight: bold;

			&::after {
				content: '';
				background-color: ${color.Primary};
				width: 4px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
		}
	}
`;

class Menu extends React.Component {
	state = {
		urlPath: ''
	}

	componentWillMount() {
		const uri = URI(window.location.href).tld("com").path();
		this.setState({urlPath: uri});
	}

  render() {
    return (
      <MenuContainer>
        <ul>
          <li className={this.state.urlPath === '/setting-profile' && 'active'}>
            <a href="/setting-profile">
              <i className="far fa-user" /> แก้ไขบัญชีผู้ใช้
            </a>
          </li>
					{
						!this.props.accountInfoListQuery.loading && this.props.accountInfoListQuery.accountInfoList.account_business ? (
							<li className={this.state.urlPath === '/setting-business' && 'active'}>
								<a href="/setting-business">
									<i className="far fa-briefcase" />
									จิซทิกซ์สำหรับองค์กร/ธุรกิจ
								</a>
							</li>
						) : null
					}
          
        </ul>
      </MenuContainer>
    )
  }
}

export default compose(graphql(accountInfoListQuery, {
	name: 'accountInfoListQuery',
	fetchPolicy: 'network-only'}
))(Menu);