import React from 'react';
import {
  TEXT_MODAL_SUBTITLE,
  TEXT_MODAL_SUBTITLE_DATE,
  TEXT_MODAL_SUBTITLE_AMOUNT,
  TEXT_MODAL_TOOL,
  TEXT_MODAL_AGENT,
  TEXT_MODAL_COST,
  TEXT_MODAL_UNIT,
} from '../../utils/Special.Constant';
import dayjs from 'dayjs';
import { Table } from 'react-bootstrap';
import { mock_data_modals } from '../../utils/Special.MockData';
import { Route } from 'react-router-dom';
import formatCurrency from 'utils/formatCurrency';

const RenderModalBody = ({ total, date, count, tableData = [] }) => {
  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr 1fr',
        }}
      >
        <div>
          <div>
            <div> {TEXT_MODAL_SUBTITLE}</div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
              }}
            >
              {' '}
              {formatCurrency(count || 0)}{' '}
            </div>
          </div>{' '}
        </div>

        <div>
          <div>
            <div> {TEXT_MODAL_SUBTITLE_DATE}</div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
              }}
            >
              {' '}
              {date}{' '}
            </div>
          </div>{' '}
        </div>
        <div>
          <div>
            <div> {TEXT_MODAL_SUBTITLE_AMOUNT}</div>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '22px',
              }}
            >
              {' '}
              {total}{' '}
            </div>
          </div>{' '}
        </div>
      </div>

      <Table style={{ marginTop: 10, marginBottom: 10 }}>
        <thead>
          <tr>
            <th> {TEXT_MODAL_TOOL} </th>
            <th>{TEXT_MODAL_AGENT}</th>
            <th> {TEXT_MODAL_COST}</th>
            <th>{TEXT_MODAL_UNIT}</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map(data => {
            return (
              <tr>
                <th>
                  <a
                    href={`/ka-shipment-list-new?agent=${data?.agent}&tab=tracking&stage=WAITINGCOD&transferCodCashDate=${date}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        height: '12px',
                        width: '12px',
                        cursor: 'pointer',
                      }}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </a>
                </th>

                <th>{data?.agent_name}</th>
                <th>{formatCurrency(data?.wait_tranfer_price)}</th>
                <th>{data?.total_tracking_wait_tranfer}</th>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
export default RenderModalBody;
