import React from 'react';
import LeafletMap from '../../../../../../components/LeafletMap';
import shipmentListQuery from './graphql/query/shipmentList';
import polyline from '@mapbox/polyline';
import moment from 'moment';
import 'moment/locale/th';
import 'moment-duration-format';
import { client } from '../../../../../../index';
import firebase from 'firebase';
import firebaseConfig from 'config/firebaseConfig';
import mapuri from 'config/mapTrackingUri';
import axios from 'axios';

class ShipmentMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      shipmentAddress: [],
      polylineList: [],
      markerPopup: [],
      shipmentStatus: null,
      shipmentType: null,
      isWorking: false,

      //gps tracking
      driverTrackingLatitude: 0,
      driverTrackingLongitude: 0,
      driverTrackingSpeed: 0,
      driverTrackingActivity: '',
      driverTrackingUpdateAt: null,
      driverPolyline: {
        distance: 0,
        duration: 0,
        coordinates: [],
      },
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }

  componentDidMount() {
    this.setState({ isLoading: false });
    this.fetchDataShipmentList();
    this.getGpsData();
  }

  getMode = mode => {
    return mode === 'รับสินค้า' ? 'pickup' : 'delivery';
  };

  fetchDataShipmentList = async () => {
    try {
      const { data } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: this.props.shipmentId,
        },
      });

      const {
        shipment_address,
        polyline_list,
        shipment_status,
        shipment_type,
      } = data.shipmentList.shipment[0];

      const { working } = data.trackingList.tracking[0];

      this.setShipmentAddressMarker(shipment_address);
      this.setPolylineList(polyline_list);
      this.setState({
        isWorking: working,
        shipmentStatus: shipment_status,
        shipmentType: shipment_type,
      });
    } catch (error) {
      console.log(error);
    }
  };

  async fetchPolyline() {
    try {
      const { driverTrackingLatitude, driverTrackingLongitude } = this.state;
      const param = {
        lat: driverTrackingLatitude,
        lng: driverTrackingLongitude,
        shipment_id: this.props.shipmentId,
      };

      const res = await axios.post(`${mapuri}/driver-track-polyline`, param);
      return res.data;
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  getGpsData = async () => {
    try {
      const { data } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: this.props.shipmentId,
        },
      });

      const driverId = data.shipmentList.shipment[0].driver_user_id;
      firebase
        .database()
        .ref(`/gps/${driverId}`)
        .limitToLast(1)
        .on('value', async snapshot => {
          if (snapshot.numChildren() === 1) {
            snapshot.forEach(child => {
              const val = child.val();
              this.setState(
                {
                  driverTrackingLatitude: val.location.coords.latitude,
                  driverTrackingLongitude: val.location.coords.longitude,
                  driverTrackingSpeed:
                    val.location.coords.speed > 0 ? val.location.coords.speed : 0,
                  driverTrackingActivity: val.location.activity ? val.location.activity.type : '',
                  driverTrackingUpdateAt: val.location.timestamp,
                },
                async () => {
                  const data = await this.fetchPolyline();

                  if (data == null) {
                    this.setState({
                      driverPolyline: {
                        distance: 0,
                        duration: 0,
                        coordinates: [],
                      },
                    });

                    return;
                  }

                  const { polyline: polylineEncode, duration, distance } = data;

                  if (polylineEncode === null) {
                    this.setState({
                      driverPolyline: {
                        distance: 0,
                        duration: 0,
                        coordinates: [],
                      },
                    });

                    return;
                  }

                  this.setState({
                    driverPolyline: {
                      distance: distance,
                      duration: duration,
                      coordinates: this.polylineDecode(polylineEncode),
                    },
                  });
                }
              );
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  setShipmentAddressMarker = shipmentAddress => {
    const shipmentAddressFormat = shipmentAddress
      .filter(item => item.mode !== 'roundtrip')
      .map((item, index) => ({ ...item, index: index + 1 }))
      .map(item => {
        return {
          lat: item.lat,
          lng: item.lng,
          mode: this.getMode(item.mode),
          text: item.index,
        };
      });

    this.setState({ shipmentAddress: shipmentAddressFormat });
  };

  setPolylineList = polylineList => {
    const polylineListDecode = polylineList.map(polylineEncode => ({
      duration: polylineEncode.duration,
      distance: polylineEncode.distance,
      coordinates: polyline.decode(polylineEncode.polyline).map(point => {
        return {
          lat: point[0],
          lng: point[1],
        };
      }),
    }));

    this.setState({
      polylineList: polylineListDecode,
    });
  };

  checkShowPolylineAndPopupTime = () => {
    const { shipmentType, shipmentStatus, isWorking } = this.state;

    if (
      shipmentType === 1 &&
      shipmentStatus !== 4 &&
      shipmentStatus !== 5 &&
      shipmentStatus !== 6 &&
      isWorking === false
    ) {
      return true;
    }

    return false;
  };

  getPolyline = () => {
    const { polylineList, driverPolyline, shipmentType, shipmentStatus, isWorking } = this.state;
    if (
      shipmentType === 1 &&
      shipmentStatus !== 4 &&
      shipmentStatus !== 5 &&
      shipmentStatus !== 6 &&
      !isWorking
    ) {
      return polylineList.map(item => item.coordinates);
    } else if (shipmentType === 1 && isWorking) {
      return [driverPolyline.coordinates];
    }

    return [];
  };

  getFormatTime(second) {
    return moment.duration(second, 'second').format(`D [ว.] HH [ชม.] mm [นาที]`, {
      trunc: true,
      trim: 'both',
    });
  }

  getLatLngPolylineCenter = polylineList => {
    return polylineList
      .filter(item => item.coordinates.length >= 2)
      .map(item => {
        const coordinateCenterIndex = item.coordinates.length / 2 - 1;
        return {
          lat: item.coordinates[Math.floor(coordinateCenterIndex)].lat,
          lng: item.coordinates[Math.floor(coordinateCenterIndex)].lng,
          title: this.getFormatTime(item.duration),
          subTitle: `ระยะทาง ${item.distance.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })} กม.`,
        };
      });
  };

  boundsLatLng = () => {
    const { shipmentAddress, polylineList } = this.state;
    const markerLatLng = shipmentAddress.map(item => [item.lat, item.lng]);

    let polylineLatLng = [];
    if (this.checkShowPolylineAndPopupTime()) {
      polylineLatLng = this.getLatLngPolylineCenter(polylineList).map(item => [item.lat, item.lng]);
    }

    const mergeLatLng = [...markerLatLng, ...polylineLatLng];
    return mergeLatLng;
  };

  getDriverMarker = () => {
    const {
      driverTrackingLatitude,
      driverTrackingLongitude,
      isWorking,
      shipmentType,
      shipmentStatus,
    } = this.state;

    if (
      (driverTrackingLatitude !== 0 &&
        driverTrackingLongitude !== 0 &&
        isWorking &&
        shipmentType === 1) ||
      (driverTrackingLatitude !== 0 &&
        driverTrackingLongitude !== 0 &&
        shipmentType !== 1 &&
        shipmentStatus === 3)
    ) {
      return {
        lat: driverTrackingLatitude,
        lng: driverTrackingLongitude,
        popup: this.getDetailPopupDriverGps(),
      };
    }

    return null;
  };

  getDetailPopupDriverGps = () => {
    const {
      driverPolyline,
      driverTrackingActivity,
      driverTrackingSpeed,
      driverTrackingUpdateAt,
      shipmentType,
    } = this.state;

    if (shipmentType !== 1) {
      return {
        speed: `ความเร็ว: ${(driverTrackingSpeed * 3.6).toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })} กม./ชม.`,
        activity: `Activity: ${this.getStatusDriverActivity(driverTrackingActivity)}`,
        updateAt: `อัพเดทเมื่อ: ${this.getTimeAgo(driverTrackingUpdateAt)}`,
      };
    }

    return {
      title: this.getFormatTime(driverPolyline.duration),
      subTitle: `ระยะทาง: ${driverPolyline.distance.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} กม.`,
      speed: `ความเร็ว: ${(driverTrackingSpeed * 3.6).toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })} กม./ชม.`,
      activity: `Activity: ${this.getStatusDriverActivity(driverTrackingActivity)}`,
      updateAt: `อัพเดทเมื่อ: ${this.getTimeAgo(driverTrackingUpdateAt)}`,
    };
  };

  getTimeAgo = time => {
    const currentTime = moment();
    const driverTime = moment(time);
    if (driverTime.isAfter(currentTime)) {
      return 'ไม่กี่วินาทีที่แล้ว';
    }

    return moment(driverTime).fromNow();
  };

  getStatusDriverActivity = activity => {
    if (activity === 'in_vehicle') {
      return 'กำลังขับรถ';
    } else if (activity === 'still') {
      return 'หยุดนิ่ง';
    } else if (activity === 'unknown') {
      return 'ไม่รู้สถานะ';
    } else if (activity === 'on_foot' || activity === 'walking') {
      return 'กำลังเดิน';
    } else if (activity === 'running') {
      return 'กำลังวิ่ง';
    } else if (activity === 'on_bicycle') {
      return 'กำลังขับรถ';
    } else if (!activity) {
      return 'จอดรถ';
    }

    return activity;
  };

  polylineDecode(polylineEncode) {
    return polyline.decode(polylineEncode).map(point => {
      return {
        lat: point[0],
        lng: point[1],
      };
    });
  }

  render() {
    moment.locale('th');

    return (
      <LeafletMap
        markerShipment={this.state.shipmentAddress}
        polyline={this.getPolyline()}
        markerPopup={
          this.checkShowPolylineAndPopupTime()
            ? this.getLatLngPolylineCenter(this.state.polylineList)
            : []
        }
        boundsLatLng={this.boundsLatLng()}
        markerDriver={this.getDriverMarker()}
      />
    );
  }
}

export default ShipmentMap;
