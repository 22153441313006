import gql from 'graphql-tag';

export default gql`
    query searchdatamasterSlaLevels(
        $sort: sort,
        $page: Int,
    ){
        datamasterSlaLevels(
            sort: $sort
            page: $page
            showData: 30
            ) {
        slaLevels {
            _id
            level
            service_time
            respond_time{
            time
        unit
            }
            investigate_time{
            time
        unit
            }
            resolution_time{
            time
        unit
            }
            impact
            create_date
            last_update
        }
        }
    }
`;
