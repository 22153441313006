import React from 'react';
import classes from './ShipmentTracking.module.scss';
import cn from '../../ShipmentDetail.module.scss';
import Moment from 'react-moment';
import { Row, Col, Button, Radio, FormControl, Modal, Image } from 'react-bootstrap';
import styled from 'styled-components';
import { TrackingList } from '../../../../components';
import { Label } from 'react-bootstrap';
import s3 from 'config/s3';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import dayjs from 'dayjs';

import singleUploadMutation from './graphql/mutation/singleUpload';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import adminUpdateMileageMutation from './graphql/mutation/adminUpdateMileage';
import shipmentListQuery from './graphql/query/shipmentList';
import shipmentKAQuery from './graphql/query/shipmentKA';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';
import { AddSuffixShipment } from '../../components';

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;
const CustomPhoto = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  // border: 2px solid #E3E3E3;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & .del-btn {
    background-color: #ffff;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 5px;
    right: 5px;
    border-radius: 50%;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;

const ButtonAddImage = styled.div`
  width: 100px;
  height: 100px;
  border: 1px dashed #3daeee;
  color: #3daeee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    background-color: #fafafa;
  }

  & i {
    color: #3daeee;
    font-size: 24px;
    margin-bottom: 6px;
  }
`;

const ModalStyle = styled(Modal)`
  & .modal-dialog {
    max-width: 350px;
  }
`;

class ShipmentTracking extends React.Component {
  static contextType = ShipmentDetailContext;
  constructor(props) {
    super(props);
    this.state = {
      isOpenEditKaDriverPaymentType: false,
      kaDriverPaymentType: '',
      isEditmileage: false,
      mileageStart: '',
      mileageEnd: '',

      mileageStartPhotos: '',
      mileageStartPhotosInvalid: false,
      mileageStartAnimationUpload: false,
      mileageEndPhotos: '',
      mileageEndPhotosInvalid: false,
      mileageEndAnimationUpload: false,

      //viewImageSrc
      isOpenLightbox: false,
      viewImageSrc: '',
    };

    this._inputMileageStartPhotos = React.createRef();
    this._inputMileageEndPhotos = React.createRef();
  }

  secondsToString(interval, timeLevels) {
    const cbFun = (d, c) => {
      let bb = d[1] % c[0],
        aa = (d[1] - bb) / c[0];
      aa = aa > 0 ? aa + c[1] : '';
      return [d[0] + aa, bb];
    };
    let rslt = timeLevels.scale
      .map((d, i, a) => a.slice(i).reduce((d, c) => d * c))
      .map((d, i) => [d, timeLevels.units[i]])
      .reduce(cbFun, ['', interval]);
    return rslt[0];
  }

  secondsToHoursColon(interval) {
    const timeLevels = {
      scale: [60, 60, 1],
      units: [':', ':', ''],
    };
    return this.secondsToString(interval, timeLevels);
  }

  renderLightbox(data) {
    this.setState({
      isOpenLightbox: true,
      viewImageSrc: `${s3}/${data}`,
    });
  }

  deleteMileageStartPhotos = id => {
    const newAttachPhotos = this.state.mileageStartPhotos.filter(data => data !== id);
    this.setState({
      mileageStartPhotos: newAttachPhotos,
    });
  };

  deleteMileageEndPhotos = id => {
    const newAttachPhotos = this.state.mileageEndPhotos.filter(data => data !== id);
    this.setState({
      mileageEndPhotos: newAttachPhotos,
    });
  };

  checkFileTypeImage(file) {
    if (file) {
      return file.type === 'image/png' || file.type === 'image/jpeg';
    }
  }

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      default:
        return '';
    }
  }

  getTitleDelivery = item => {
    const { dictrict, delivery_status } = item;
    if (delivery_status === 3) {
      return `ส่งสินค้า - ${dictrict} เรียบร้อยแล้ว`;
    }

    return `ส่งสินค้า - ${dictrict}`;
  };

  getSubTitleDelivery = item => {
    const { delivery_status, signature_date, failure_detail, paid_by_cod } = item;
    let status = '';
    if (delivery_status === 2) {
      status = 'รอดำเนินการ';
    } else if (delivery_status === 3) {
      status = <Moment format="DD/MM/YYYY - HH:mm">{signature_date}</Moment>;
    } else if (delivery_status === 4) {
      const failureDetail = failure_detail ? `(${failure_detail})` : '';
      status = `รอคืนสินค้า ${failureDetail}`;
    }

    if (item.commodity.length > 0) {
      return (
        <div>
          <>
            <b style={{ color: '#000' }}>{AddSuffixShipment(item.account_info)}</b>
            {item.commodity[0].name}
          </>{' '}
          : <span className={`${cn.mr5}`}>{status}</span>
          {paid_by_cod === 1 && <span className={`${cn.textRed}`}>[COD]</span>}
        </div>
      );
    }
    return null;
  };

  getColorStatusDelivery = status => {
    if (status === 2) {
      return '#49ACF1';
    } else if (status === 3) {
      return '#5cb85c';
    } else if (status === 4) {
      return '#E70301';
    }

    return null;
  };

  getTitleReturn = item => {
    const { signature_date_return, tag_name } = item;
    if (signature_date_return) {
      return `คืนสินค้า - ${tag_name} เรียบร้อยแล้ว`;
    }

    return `คืนสินค้า - ${tag_name}`;
  };

  getSubTitleReturn = item => {
    const { signature_date_return } = item;
    let status = '';
    if (!signature_date_return) {
      status = 'รอดำเนินการ';
    } else {
      status = <Moment format="DD/MM/YYYY - HH:mm">{signature_date_return}</Moment>;
    }

    return <div>{status}</div>;
  };

  getColorStatusReturn = signature_date_return => {
    if (!signature_date_return) {
      return '#49ACF1';
    }

    return null;
  };

  getColorStatusPickup = commodity => {
    if (commodity.filter(data => data.accept === 2).length === commodity.length) {
      return 'red';
    }

    return null;
  };

  handleSingleUploadMileage(shipment, uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    const timestamp = new Date().getTime();
    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/${shipment}/mileagelog/${documentName}-${timestamp}`,
        },
      });
    }
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>การติดตามงาน</div>
        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error, refetch }) => {
            if (loading) return 'loading...';
            if (error) return error;

            const { shipmentList, trackingList } = data;
            const shipment = shipmentList.shipment[0];
            const tracking = trackingList.tracking[0];

            return (
              <Row>
                <Col md={12}>
                  <div className={`${cn.mb10}`}>{this.renderStatus(shipment.shipment_status)}</div>
                  <div className={`${cn.textGray} ${cn.textSmall} ${cn.mb10}`}>
                    อัพเดทล่าสุด: <Moment format="DD/MM/YYYY HH:mm">{shipment.last_update}</Moment>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mt10} ${cn.mb10}`}></div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ผู้ดูแลงาน:{' '}
                      <span className={cn.textBold}>{shipment.transporter_name || '-'}</span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      โทร:{' '}
                      <span className={cn.textBold}>{`${shipment.transporter_phone_code ||
                        ''} ${shipment.transporter_phone_number || '-'}`}</span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      พนักงานขับรถ:{' '}
                      <span className={cn.textBold}>
                        {shipment.driver_name_under_mama
                          ? shipment.driver_name_under_mama || '-'
                          : tracking.driver_name || '-'}
                      </span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ทะเบียนรถ:{' '}
                      <span className={cn.textBold}>
                        {tracking.user_setting_truck_license_plate || '-'}
                      </span>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      โทร:{' '}
                      <span className={cn.textBold}>
                        {shipment.driver_tel_under_mama
                          ? `${tracking.driver_phone_code || ''} ${shipment.driver_tel_under_mama ||
                              '-'}`
                          : `${tracking.driver_phone_code || ''} ${tracking.driver_phone_number ||
                              '-'}`}
                      </span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>
                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      รูปแบบการจ้างงาน:{' '}
                      <span className={cn.textBold}>
                        {shipment.ka_driver_payment_type == 1
                          ? 'รายเดือน'
                          : shipment.ka_driver_payment_type == 2
                          ? 'รายเดือน (ขับแทน)'
                          : 'รายเที่ยว'}
                      </span>
                      <a
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() =>
                          this.setState({
                            isOpenEditKaDriverPaymentType: true,
                            kaDriverPaymentType: shipment.ka_driver_payment_type || 3,
                          })
                        }
                      >
                        แก้ไข
                      </a>
                    </div>
                  </div>

                  {shipment.record_mileage && (
                    <>
                      <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>
                      <div className={cn.RowItem}>
                        <div className={cn.Left}>
                          เลขไมล์เริ่มต้น:{' '}
                          <span className={cn.textBold}>{shipment.mileage_start.mileage}</span>
                        </div>
                      </div>
                      <div className={cn.RowItem}>
                        <div className={cn.Left}>
                          เลขไมล์สิ้นสุด:{' '}
                          <span className={cn.textBold}>{shipment.mileage_end.mileage}</span>
                        </div>
                      </div>
                      <div className={cn.RowItem}>
                        <div className={cn.Left}>
                          ระยะทางรวม:{' '}
                          <span className={cn.textBold}>{shipment.total_distance || 0} km</span>
                        </div>
                      </div>
                      <div className={cn.RowItem}>
                        <div className={cn.Left}>
                          ระยะทางจากระบบ shortest path:{' '}
                          <span className={cn.textBold}>
                            {shipment.distance_shortest_path || 0} km
                          </span>
                          {shipment.shipment_status == 4 && (
                            <a
                              style={{ float: 'right', cursor: 'pointer' }}
                              onClick={() =>
                                this.setState({
                                  isEditmileage: true,
                                  mileageStart: shipment.mileage_start
                                    ? shipment.mileage_start.mileage
                                    : '',
                                  mileageEnd: shipment.mileage_end
                                    ? shipment.mileage_end.mileage
                                    : '',
                                  mileageStartPhotos: shipment.mileage_end
                                    ? shipment.mileage_start.mileage_img
                                    : '',
                                  mileageStartPhotosInvalid: false,
                                  mileageStartAnimationUpload: false,
                                  mileageEndPhotos: shipment.mileage_end
                                    ? shipment.mileage_end.mileage_img
                                    : '',
                                  mileageEndPhotosInvalid: false,
                                  mileageEndAnimationUpload: false,
                                })
                              }
                            >
                              แก้ไข
                            </a>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>
                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      ระยะเวลาทำงาน:{' '}
                      <span className={cn.textBold}>
                        {shipment.working_period
                          ? this.secondsToHoursColon(shipment.working_period)
                          : '-'}
                      </span>
                    </div>
                  </div>

                  <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>
                  <div className={cn.TrackingWrapper}>
                    {shipment.shipment_status === 4 && shipment.completed_date && (
                      <TrackingList
                        title={`ดำเนินการขนส่งเรียบร้อยแล้ว`}
                        subTitle={
                          <Moment format="DD/MM/YYYY - HH:mm">{shipment.completed_date}</Moment>
                        }
                      />
                    )}

                    {shipment.tracking.tracking_detail_lazada.return.map((item, index) => {
                      return (
                        <TrackingList
                          key={index}
                          arrow
                          title={this.getTitleReturn(item)}
                          subTitle={this.getSubTitleReturn(item)}
                          onPress={() =>
                            this.context.onOpenSubShipmentTracking(
                              this.getTitleReturn(item),
                              index,
                              'return'
                            )
                          }
                          colorIcon={this.getColorStatusReturn(item.signature_date_return)}
                          active={
                            this.context.state.subShipmentTrackingIndex === index &&
                            this.context.state.subShipmentTrackingMode === 'return'
                          }
                        />
                      );
                    })}

                    {shipment.tracking.tracking_detail_lazada.delivery.map((item, index) => {
                      return (
                        <TrackingList
                          key={index}
                          item={item}
                          arrow
                          title={this.getTitleDelivery(item)}
                          subTitle={this.getSubTitleDelivery(item)}
                          onPress={() =>
                            this.context.onOpenSubShipmentTracking(
                              this.getTitleDelivery(item),
                              index,
                              'delivery'
                            )
                          }
                          colorIcon={this.getColorStatusDelivery(item.delivery_status)}
                          active={
                            this.context.state.subShipmentTrackingIndex === index &&
                            this.context.state.subShipmentTrackingMode === 'delivery'
                          }
                        />
                      );
                    })}

                    {shipment.tracking.tracking_detail_lazada.pick_up.map((item, index) => {
                      return (
                        item.signature_date && (
                          <TrackingList
                            key={index}
                            arrow
                            title={`รับสินค้า - ${item.tag_name} เรียบร้อยแล้ว`}
                            subTitle={
                              <Moment format="DD/MM/YYYY - HH:mm">{item.signature_date}</Moment>
                            }
                            onPress={() =>
                              this.context.onOpenSubShipmentTracking(
                                `รับสินค้า - ${item.tag_name} เรียบร้อยแล้ว`,
                                index,
                                'pickup'
                              )
                            }
                            active={
                              this.context.state.subShipmentTrackingIndex === index &&
                              this.context.state.subShipmentTrackingMode === 'pickup'
                            }
                            colorIcon={this.getColorStatusPickup(item.commodity)}
                          />
                        )
                      );
                    })}

                    {shipment.tracking.driver_accept === 1 && (
                      <TrackingList
                        title="ขนส่งยืนยันงาน เรียบร้อยแล้ว"
                        subTitle={
                          <Moment format="DD/MM/YYYY - HH:mm">
                            {shipment.tracking.driver_accept_date}
                          </Moment>
                        }
                      />
                    )}
                  </div>
                </Col>

                {this.state.isOpenEditKaDriverPaymentType && (
                  <Mutation
                    mutation={shipmentsUpdateMutation}
                    variables={{
                      _id: shipment._id,
                      ka_driver_payment_type: this.state.kaDriverPaymentType,
                    }}
                    update={() => {
                      this.setState(
                        {
                          kaDriverPaymentType: '',
                          isOpenEditKaDriverPaymentType: false,
                        },
                        () => {
                          refetch();
                        }
                      );
                    }}
                    onError={error => {
                      alert(error.graphQLErrors[0].message);
                    }}
                  >
                    {shipmentsUpdate => (
                      <ModalStyle
                        show={this.state.isOpenEditKaDriverPaymentType}
                        backdrop="static"
                        onHide={() => this.setState({ isOpenEditKaDriverPaymentType: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>แก้ไขรูปแบบการจ้างงาน</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row style={{ marginBottom: '10px' }}>
                            <Col md={12}>
                              <b>
                                รหัสงานขนส่ง :{shipment.shipment_trip || shipment.shipment_number}
                              </b>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '20px' }}>
                            <Col md={12}> รูปแบบการจ้างงาน</Col>
                          </Row>

                          <Row>
                            <Col md={12}>
                              <CustomRadio
                                value={1}
                                checked={this.state.kaDriverPaymentType == 1}
                                onChange={event => this.setState({ kaDriverPaymentType: 1 })}
                                name="kaDriverPaymentType"
                                inline
                              >
                                <span className="checkmark"></span>รายเดือน
                              </CustomRadio>
                            </Col>
                            <Col md={12}>
                              <CustomRadio
                                value={2}
                                checked={this.state.kaDriverPaymentType == 2}
                                onChange={event => this.setState({ kaDriverPaymentType: 2 })}
                                name="kaDriverPaymentType"
                                inline
                              >
                                <span className="checkmark"></span>รายเดือน (ขับแทน)
                              </CustomRadio>
                            </Col>
                            <Col md={12}>
                              <CustomRadio
                                value={3}
                                checked={this.state.kaDriverPaymentType == 3}
                                onChange={event => this.setState({ kaDriverPaymentType: 3 })}
                                name="kaDriverPaymentType"
                                inline
                              >
                                <span className="checkmark"></span>รายเที่ยว
                              </CustomRadio>
                            </Col>
                          </Row>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            bsStyle="primary"
                            onClick={() => shipmentsUpdate()}
                            disabled={
                              shipment.ka_driver_payment_type == this.state.kaDriverPaymentType
                            }
                          >
                            บันทึก
                          </Button>
                        </Modal.Footer>
                      </ModalStyle>
                    )}
                  </Mutation>
                )}

                {this.state.isOpenLightbox && (
                  <Lightbox
                    style={{ zIndex: '9999' }}
                    mainSrc={this.state.viewImageSrc}
                    onCloseRequest={() =>
                      this.setState({ isOpenLightbox: false, viewImageSrc: '' })
                    }
                  />
                )}

                {this.state.isEditmileage && (
                  <Mutation
                    mutation={adminUpdateMileageMutation}
                    variables={{
                      params: {
                        _id: shipment._id,
                      },
                      update: {
                        mileage_start: {
                          mileage: this.state.mileageStart,
                          mileage_img: this.state.mileageStartPhotos,
                        },
                        mileage_end: {
                          mileage: this.state.mileageEnd,
                          mileage_img: this.state.mileageEndPhotos,
                        },
                      },
                    }}
                    update={() => {
                      this.setState(
                        {
                          isEditmileage: false,
                          mileageStart: '',
                          mileageEnd: '',

                          mileageStartPhotos: '',
                          mileageStartPhotosInvalid: false,
                          mileageStartAnimationUpload: false,
                          mileageEndPhotos: '',
                          mileageEndPhotosInvalid: false,
                          mileageEndAnimationUpload: false,
                        },
                        () => {
                          refetch();
                        }
                      );
                    }}
                    onError={error => {
                      alert(error.graphQLErrors[0].message);
                    }}
                  >
                    {adminUpdateMileage => (
                      <Modal
                        show={this.state.isEditmileage}
                        backdrop="static"
                        onHide={() => this.setState({ isEditmileage: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>แก้ไขการบันทึกเลขไมล์</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row style={{ marginBottom: '10px' }}>
                            <Col md={12}>
                              <b>
                                รหัสงานขนส่ง :{shipment.shipment_trip || shipment.shipment_number}
                              </b>
                            </Col>
                          </Row>
                          <Row style={{ marginBottom: '10px' }}>
                            <Col md={6}>
                              <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                  {' '}
                                  เลขไมล์เริ่มต้น <span style={{ color: '#FA0000' }}>*</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <FormControl
                                    type="text"
                                    value={this.state.mileageStart}
                                    placeholder="ระบุเลขไมล์เริ่มต้น"
                                    onChange={event =>
                                      this.setState({
                                        mileageStart: event.target.value,
                                      })
                                    }
                                    name="mileageStart"
                                  />
                                </Col>
                              </Row>

                              <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                  {' '}
                                  หลักฐานเลขไมล์เริ่มต้น <span style={{ color: '#FA0000' }}>*</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Row>
                                    {this.state.mileageStartAnimationUpload ? (
                                      <Col md={2} style={{ marginTop: '10px' }}>
                                        <ButtonAddImage>
                                          <i className="fad fa-spinner-third fa-spin"></i>
                                          <div>กำลังอัพโหลด</div>
                                        </ButtonAddImage>
                                      </Col>
                                    ) : this.state.mileageStartPhotos &&
                                      this.state.mileageStartPhotos != '' ? (
                                      <Col
                                        key={this.state.mileageStartPhotos}
                                        md={4}
                                        style={{ marginTop: '10px' }}
                                      >
                                        <CustomPhoto>
                                          <div
                                            className="del-btn"
                                            onClick={() =>
                                              this._inputMileageStartPhotos.current.click()
                                            }
                                          >
                                            <i class="far fa-times-circle"></i>
                                          </div>
                                          <Image
                                            onClick={() =>
                                              this.renderLightbox(this.state.mileageStartPhotos)
                                            }
                                            style={{ width: '100%' }}
                                            src={s3 + '/' + this.state.mileageStartPhotos}
                                            rounded
                                          />
                                        </CustomPhoto>
                                      </Col>
                                    ) : (
                                      <Col md={4} style={{ marginTop: '10px' }}>
                                        <ButtonAddImage
                                          onClick={() =>
                                            this._inputMileageStartPhotos.current.click()
                                          }
                                        >
                                          <i className="fal fa-plus-circle"></i>
                                          <div>เพิ่มรูปภาพ</div>
                                        </ButtonAddImage>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={6}>
                              <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                  {' '}
                                  เลขไมล์สิ้นสุด <span style={{ color: '#FA0000' }}>*</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <FormControl
                                    type="text"
                                    value={this.state.mileageEnd}
                                    placeholder="ระบุเลขไมล์สิ้นสุด"
                                    onChange={event =>
                                      this.setState({
                                        mileageEnd: event.target.value,
                                      })
                                    }
                                    name="mileageEnd"
                                  />
                                </Col>
                              </Row>

                              <Row style={{ marginTop: '20px' }}>
                                <Col md={12}>
                                  {' '}
                                  หลักฐานเลขไมล์สิ้นสุด <span style={{ color: '#FA0000' }}>*</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <Row>
                                    {this.state.mileageEndAnimationUpload ? (
                                      <Col md={2} style={{ marginTop: '10px' }}>
                                        <ButtonAddImage>
                                          <i className="fad fa-spinner-third fa-spin"></i>
                                          <div>กำลังอัพโหลด</div>
                                        </ButtonAddImage>
                                      </Col>
                                    ) : this.state.mileageEndPhotos &&
                                      this.state.mileageEndPhotos != '' ? (
                                      <Col
                                        key={this.state.mileageEndPhotos}
                                        md={4}
                                        style={{ marginTop: '10px' }}
                                      >
                                        <CustomPhoto>
                                          <div
                                            className="del-btn"
                                            onClick={() =>
                                              this._inputMileageEndPhotos.current.click()
                                            }
                                          >
                                            <i class="far fa-times-circle"></i>
                                          </div>
                                          <Image
                                            onClick={() =>
                                              this.renderLightbox(this.state.mileageEndPhotos)
                                            }
                                            style={{ width: '100%' }}
                                            src={s3 + '/' + this.state.mileageEndPhotos}
                                            rounded
                                          />
                                        </CustomPhoto>
                                      </Col>
                                    ) : (
                                      <Col md={4} style={{ marginTop: '10px' }}>
                                        <ButtonAddImage
                                          onClick={() =>
                                            this._inputMileageEndPhotos.current.click()
                                          }
                                        >
                                          <i className="fal fa-plus-circle"></i>
                                          <div>เพิ่มรูปภาพ</div>
                                        </ButtonAddImage>
                                      </Col>
                                    )}
                                  </Row>
                                </Col>
                              </Row>
                            </Col>

                            <Mutation
                              mutation={singleUploadMutation}
                              update={(cache, { data }) => {
                                this._inputMileageStartPhotos.current.value = '';
                                // console.log(data.singleUpload.path);
                                this.setState({
                                  mileageStartPhotos: data.singleUpload.path,
                                  mileageStartPhotosInvalid: false,
                                  mileageStartAnimationUpload: false,
                                  mileageEndAnimationUpload: false,
                                });
                              }}
                              onError={error => {
                                console.log(error);
                              }}
                            >
                              {uploadFile => {
                                return (
                                  <input
                                    accept="image/jpeg,image/png"
                                    onChange={event => {
                                      const files = event.target.files[0];
                                      const checkFileTypeImage = this.checkFileTypeImage(files);
                                      if (checkFileTypeImage === false) {
                                        this.addNotification(
                                          'error',
                                          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
                                        );
                                        return;
                                      }
                                      files && this.setState({ mileageStartAnimationUpload: true });
                                      this._inputMileageStartPhotos.current.files[0] &&
                                        this.handleSingleUploadMileage(
                                          shipment._id,
                                          uploadFile,
                                          this._inputMileageStartPhotos.current.files[0].name.split(
                                            '.'
                                          )[0],
                                          event
                                        );
                                    }}
                                    type="file"
                                    id="file"
                                    style={{ display: 'none' }}
                                    ref={this._inputMileageStartPhotos}
                                  />
                                );
                              }}
                            </Mutation>

                            <Mutation
                              mutation={singleUploadMutation}
                              update={(cache, { data }) => {
                                this._inputMileageEndPhotos.current.value = '';
                                // console.log(data.singleUpload.path);
                                this.setState({
                                  mileageEndPhotos: data.singleUpload.path,
                                  mileageEndPhotosInvalid: false,
                                  mileageStartAnimationUpload: false,
                                  mileageEndAnimationUpload: false,
                                });
                              }}
                              onError={error => {
                                console.log(error);
                              }}
                            >
                              {uploadFile => {
                                return (
                                  <input
                                    accept="image/jpeg,image/png"
                                    onChange={event => {
                                      const files = event.target.files[0];
                                      const checkFileTypeImage = this.checkFileTypeImage(files);
                                      if (checkFileTypeImage === false) {
                                        this.addNotification(
                                          'error',
                                          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
                                        );
                                        return;
                                      }
                                      files && this.setState({ mileageEndAnimationUpload: true });
                                      this._inputMileageEndPhotos.current.files[0] &&
                                        this.handleSingleUploadMileage(
                                          shipment._id,
                                          uploadFile,
                                          this._inputMileageEndPhotos.current.files[0].name.split(
                                            '.'
                                          )[0],
                                          event
                                        );
                                    }}
                                    type="file"
                                    id="file"
                                    style={{ display: 'none' }}
                                    ref={this._inputMileageEndPhotos}
                                  />
                                );
                              }}
                            </Mutation>
                          </Row>

                          <Query
                            query={shipmentKAQuery}
                            variables={{
                              _id: shipment._id,
                            }}
                            fetchPolicy="cache-and-network"
                          >
                            {({ data, loading, error, refetch }) => {
                              if (loading) return 'loading...';
                              if (error) return error;

                              if (data.shipmentKA.mileage_log.length > 0) {
                                return data.shipmentKA.mileage_log.map((log, index) => (
                                  <Row style={{ marginBottom: '10px' }}>
                                    {console.log('shipmentKAQuery', log)}
                                    <Col md={12}>
                                      <div className={`${cn.LineHorizontal} ${cn.mb10}`}></div>
                                      <div className={cn.RowItem}>
                                        <div className={cn.Left}>
                                          บันทึกเมื่อ:{' '}
                                          <span className={cn.textBold}>
                                            {dayjs(log.updatedAt).format('DD/MM/YYYY HH:mm')}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={cn.RowItem}>
                                        <div className={cn.Left}>
                                          เลขไมล์เริ่มต้น:{' '}
                                          <span className={cn.textBold}>
                                            {log.mileage_start.mileage}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={cn.RowItem}>
                                        <div className={cn.Left}>
                                          เลขไมล์สิ้นสุด:{' '}
                                          <span className={cn.textBold}>
                                            {log.mileage_end.mileage}
                                          </span>
                                        </div>
                                      </div>
                                      <div className={cn.RowItem}>
                                        <div className={cn.Left}>
                                          ระยะทางรวม:{' '}
                                          <span className={cn.textBold}>
                                            {log.total_distance} กม.
                                          </span>
                                        </div>
                                      </div>
                                      <div className={cn.RowItem}>
                                        <div className={cn.Left}>
                                          ระยะทางจากระบบ shortest path:{' '}
                                          <span className={cn.textBold}>
                                            {log.distance_shortest_path || 0} กม.
                                          </span>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                ));
                              }

                              return null;
                            }}
                          </Query>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            bsStyle="primary"
                            onClick={() => adminUpdateMileage()}
                            disabled={
                              !this.state.mileageStartPhotos ||
                              !this.state.mileageStartPhotos ||
                              this.state.mileageStartPhotos == '' ||
                              !this.state.mileageEndPhotos ||
                              !this.state.mileageEndPhotos ||
                              this.state.mileageEndPhotos == ''
                            }
                          >
                            บันทึก
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                  </Mutation>
                )}
              </Row>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ShipmentTracking;
