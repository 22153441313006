import React, { Component } from 'react';
import { Navbar } from 'react-bootstrap';

import HeaderLinks from './HeaderLinks.jsx';

import dashboardRoutes from 'routes/dashboard.jsx';
import styled from 'styled-components';

const ButtonMiniSidebar = styled.button`
  background-color: #ffffff;
  border: none;
  margin-right: 20px;
  font-size: 20px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
    this.state = {
      sidebarExists: false,
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true,
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.subMenu) {
        prop.subMenu.map((propSubMenu, key) => {
          if (propSubMenu.path === this.props.location.pathname) {
            name = propSubMenu.name;
          }
          return null;
        });
      } else if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }

  handleMinimizeSidebar() {
    document.body.classList.toggle('sidebar-mini');
  }

  render() {
    return (
      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <ButtonMiniSidebar style={{ display: 'none' }} onClick={this.handleMinimizeSidebar}>
              <i className="fal fa-ellipsis-v"></i>
            </ButtonMiniSidebar>
            <a href="#pablo" style={{ color: '#777' }}>
              {this.getBrand()}
            </a>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        {this.props.adminName && (
          <span style={{ float: 'right', margin: '18px', fontSize: '24px', fontWeight: 'bold' }}>
            {this.props.adminName}
          </span>
        )}
        <Navbar.Collapse>
          <HeaderLinks />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
