import gql from 'graphql-tag';

export default gql`
	mutation Update(
		$shipmentID: ID!
		$pick_up_date: String
		$shipment_address_update: [typeShipmentAddressUpdateArg]
		$remark: String
		$shipment_status: Int
		$user_ref: String
		$document: [type_pathArg]
	) {
		shipmentsUpdate(
			_id: $shipmentID
			shipment_status: $shipment_status
			pick_up_date: $pick_up_date
			shipment_other_detail: {
				document: $document
				remark: $remark
				user_ref: $user_ref
			}
			shipment_address_update: $shipment_address_update
		) {
			_id
		}
	}
`;
