import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipmentID: ID!
    $admin_name: String
){
    shipmentsUpdate(
        _id:$shipmentID,
        admin: $admin_name
    ){
        _id
    }
  }
`;
