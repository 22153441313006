import React from 'react';
import { Modal as ModalBootstrap, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import updateCodParcelMutation from './graphql/mutation/updateCodParcel';
import { useFormContext } from 'react-hook-form';

const Modal = styled(ModalBootstrap)`
  & .custom-modal {
    width: 700px;
    top: 10%;
  }

  & h4.modal-title {
    font-size: 20px;
    font-weight: bold;
  }
`;

const ModalConfirmPayCod = ({ isOpen = false, onCancel, onCompleted, cod }) => {
  const { watch } = useFormContext();
  const watchAllField = watch();

  return (
    <Modal
      show={isOpen}
      onHide={() => onCancel()}
      dialogClassName="custom-modal"
      backdropStyle={{
        zIndex: 1050,
      }}
    >
      <Modal.Header>
        <Modal.Title>
          <div>ยืนยันการโอนเงิน</div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <strong>ยืนยันการชำระเงินค่า COD ใช่หรือไม่ ?</strong>
        </div>
        <span>
          * หากส่งแล้วจะถือว่าท่านได้ทำการ <strong>"ยืนยัน"</strong>{' '}
          ความถูกต้องของข้อมูลชุดนี้แล้วจะไม่สามารถแก้ไขได้อีก
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => onCancel && onCancel()}>
          <i className="fas fa-times"></i> ยกเลิก
        </Button>
        <Mutation
          mutation={updateCodParcelMutation}
          update={(_cache, { data }) => {
            if (data?.updateCodParcel?._id) {
              onCompleted && onCompleted();
            }
          }}
          onError={(error) => {
            alert(error.graphQLErrors[0].message);
          }}
          refetchQueries={() => [
            'newCod',
            'shipmentKA',
            'shipmentKAs',
            'shipmentKAStatus',
          ]}
        >
          {(updateCodParcel, { loading }) => (
            <Button
              style={{
                color: '#ffffff',
                backgroundColor: '#50B55C',
              }}
              disabled={loading}
              onClick={() => {
                const images = watchAllField.document.map((item) => item.path);
                updateCodParcel({
                  variables: {
                    _id: watchAllField.qrCode.billingCod,
                    cod_status: 2,
                    document: images,
                    cod_in_date: watchAllField.dateTime,
                  },
                });
              }}
            >
              ใช่, ฉันยืนยัน
            </Button>
          )}
        </Mutation>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmPayCod;
