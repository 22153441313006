import React from 'react';
import styled from 'styled-components';

const SelectBox = styled.select`
  font-size:18px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #E3E3E3;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 6px;
  outline: none;

  &:disabled {
    background-color: #F9F9F9;
  }
`;

class Select extends React.Component {
  render() {
    return(
      <SelectBox style={this.props.style} value={this.props.value} onChange={this.props.onChange} disabled={this.props.disabled} >
        {
          React.Children.map(this.props.children, (child, key) => {
            return React.cloneElement(child);
          })
        }
      </SelectBox>
    )
  }
}

export default Select;