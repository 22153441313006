import gql from 'graphql-tag';

export default gql`
query search(
    $_id: ID
    $location_start_lang: String
    $location_end_lang: String
    $delete_fake: Int
    $page_number: Int
    $order_by: String
    $order_type: String
  ){
    flashDealsList(
      _id: $_id,
      location_start_lang: $location_start_lang,
      location_end_lang: $location_end_lang,
      delete_fake: $delete_fake,
      show_data: 30,
      page_number: $page_number,
      order_by: $order_by,
      order_type: $order_type
    ){
      flash_deals{
        _id
        status
        count_used
        setting_truck_name{
          th
          en
        }
				truck_type_name{
          th
          en
        }
				truck_type_pic
        name_lang{
          th
          en
        }
        discount
        limit_count
        setting_truck_type_id
        location_start_lang{
          th
          en
        }
        location_end_lang{
          th
          en
        }
        start
        exp
        pickup_start
        pickup_end
        price_cost
        price_sale
      }
      total_page
    }
  }
`;
