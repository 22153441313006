import React from "react";
import Tabs from "../Tabs";
import urijs from "urijs";
import { Grid } from "react-bootstrap";

class Layout extends React.Component {
  getParamUrl = () => {
    const uriRoute = urijs(window.location.href);
    const uriFragment = uriRoute.fragment(true);
    const pathname = uriFragment.pathname();

    return pathname;
  };

  setActive = path => {
    const paramUrl = this.getParamUrl();
    return path === paramUrl;
  };

  openUrl = url => {
    window.location = url;
  };

  render() {
    const menuTabs = [
      {
        icon: "fal fa-list-ul",
        title: "Product List",
        active: this.setActive("/lazada-product-list"),
        toUrl: "/lazada-product-list"
      },
      {
        icon: "fal fa-file-excel",
        title: "Import file",
        active: this.setActive("/lazada-import-file"),
        toUrl: "/lazada-import-file"
      },
      {
        icon: "fal fa-file-alt",
        title: "Booking file",
        active: this.setActive("/lazada"),
        toUrl: "/lazada"
      },
      {
        icon: "fal fa-truck",
        title: "Shipment list",
        active: this.setActive("/lazada-shipment-list"),
        toUrl: "/lazada-shipment-list"
      },
      {
        icon: "fal fa-user",
        title: "แจงงานให้คนขับรถ",
        active: this.setActive("/lazada-adddriver"),
        toUrl: "/lazada-adddriver"
      },
      {
        icon: "fal fa-user",
        title: "3PL ที่รับงานได้",
        active: this.setActive("/lazada-3pl-masterdata"),
        toUrl: "/lazada-3pl-masterdata"
      }
    ];

    return (
      <div className="content">
        <Grid fluid>
          <div {...this.props}>
            {<Tabs menu={menuTabs} />}
            <div>{this.props.children}</div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default Layout;
