import gql from 'graphql-tag';

export default gql`
mutation Update(
    $account_info_id: ID
    $shipment_id_array: [String]
    $bill_date_start: String
    $bill_date_end: String
    $issue_date: String
    $due_date: String
  
  ){
    makeSummaryInvoiceManual(
    account_info_id: $account_info_id
    shipment_id_array: $shipment_id_array
    bill_date_start: $bill_date_start
    bill_date_end: $bill_date_end
    issue_date: $issue_date
    due_date: $due_date
    ){
    _id
    }
}
`;
