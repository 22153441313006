import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Input } from './../../../../components';
import { Query } from 'react-apollo';
import { connect } from 'formik';

// utils
import formatCurrency from './../../../../../../../../utils/formatCurrency';

// graphql
import paymentOrderList from './../../../../graphql/query/paymentOrderList';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  border-top: none;
  padding: 10px;
`;

const DetailContainer = styled.div`
  & .table > tbody > tr > td {
    font-size: 19px;
    vertical-align: text-top;
  }

  & .table > tbody > tr {
    &:last-child {
      border-bottom: 1px solid #e3e3e3;
    }

    &:not(:last-child) > td {
      border-bottom: 1px solid transparent;
    }
  }

  & th {
    font-weight: bold !important;
    color: #000000 !important;
    font-size: 19px !important;
    text-align: right;
  }

  & .divider {
    border-right: 1px solid #e3e3e3;
  }

  & td {
    text-align: right;
  }
`;

const SumContainer = styled.div`
  & .table-sum {
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    width: 550px;
    float: right;
    padding-bottom: 0px;

    & td {
      text-align: right;
    }

    & tr:last-child > td {
      font-weight: bold;
    }

    & tr:nth-child(odd) > td,
    & tr.wht > td  {
      border-bottom: 1px solid transparent;
    }

    & tr.wht.last > td  {
      border-bottom: 1px solid #e3e3e3;
    }

    .table > tbody > tr > td {
      font-size: 19px;
    }
  }
`;

class PaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  checkDisabled(paymentOrder) {
    const { payment_order_status } = paymentOrder;

    if (payment_order_status === 1) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <Query
        query={paymentOrderList}
        variables={{
          _id: this.props.paymentOrderId,
        }}
      >
        {({ loading, error, data, refetch, variables }) => {
          const { payment_order } = data.paymentOrderList;
          return (
            <TableContainer>
              <DetailContainer>
                <Table striped condensed hover>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left', width: 100 }}>รหัสบริการ</th>
                      <th style={{ textAlign: 'left' }}>รายละเอียดบริการ</th>
                      <th style={{ textAlign: 'center' }}>จำนวน</th>
                      <th style={{ width: 100 }}>ราคาต่อหน่วย</th>
                      <th className="divider" style={{ width: 100 }}>
                        ราคารวม
                      </th>
                      <th style={{ width: 100 }}>ส่วนลด</th>
                      <th className="divider" style={{ width: 150 }}>
                        รวมหลังส่วนลด
                      </th>
                      <th style={{ width: 100 }}>เรียกคืน</th>
                      <th style={{ width: 100 }}>ยกเว้นภาษี</th>
                      <th style={{ width: 100 }}>ภาษีมูลค่าเพิ่ม</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading || error ? (
                      <tr>
                        <td colSpan="10" style={{ textAlign: 'center' }}>
                          {loading ? 'Loading...' : error.message}
                        </td>
                      </tr>
                    ) : data.paymentOrderList.payment_order[0].payment_order_item.length === 0 ? (
                      <tr>
                        <td colSpan="10" style={{ textAlign: 'center' }}>
                          ไม่พบข้อมูล
                        </td>
                      </tr>
                    ) : (
                          data.paymentOrderList.payment_order[0].payment_order_item.map((item, key) => (
                            <tr>
                              <td style={{ textAlign: 'left' }}>
                                {item.prefix}-{item.datamaster_service_item_number}
                              </td>
                              <td style={{ textAlign: 'left' }}>{item.name}</td>
                              <td style={{ textAlign: 'center' }}>{formatCurrency(item.qty)}</td>
                              <td>{formatCurrency(item.price)}</td>
                              <td className="divider">{formatCurrency(item.total_price)}</td>
                              <td>
                                {item.discount === 0 || null ? '-' : formatCurrency(item.discount)}
                              </td>
                              <td className="divider">
                                {formatCurrency(item.total_price - item.discount)}
                              </td>
                              <td>
                                {item.setting_prefix_id === '5e54cef8e99db3b888a2b755'
                                  ? formatCurrency(item.total_price)
                                  : '-'}
                              </td>
                              <td>
                                {item.setting_prefix_id !== '5e54cef8e99db3b888a2b755' && item.vat === 0
                                  ? formatCurrency(item.total_price)
                                  : '-'}
                              </td>
                              <td>
                                {item.prefix !== '5e54cef8e99db3b888a2b755' && item.vat !== 0
                                  ? formatCurrency(item.total_price)
                                  : '-'}
                              </td>
                            </tr>
                          ))
                        )}
                  </tbody>
                </Table>
              </DetailContainer>
              <SumContainer>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <ControlLabel style={{ marginTop: 50 }}>
                        หมายเหตุ <span style={{ color: '#B3B3B3' }}>ไม่บังคับ</span>
                      </ControlLabel>
                      <FormControl
                        componentClass="textarea"
                        rows={5}
                        placeholder="ระบุ"
                        onChange={event => {
                          this.props.formik.setFieldValue('remark', event.target.value);
                        }}
                        value={this.props.formik.values.remark}
                        disabled={this.checkDisabled(data.paymentOrderList.payment_order[0])}
                      />
                    </FormGroup>
                    {/* <Input
                      type="textarea"
                      onChange={event => {
                        this.props.formik.setFieldValue('remark', event.target.value);
                      }}
                      value={
                        data.paymentOrderList.payment_order[0].payments &&
                        data.paymentOrderList.payment_order[0].payments.payment_remark
                          ? data.paymentOrderList.payment_order[0].payments.payment_remark
                          : this.props.formik.values.remark
                      }
                      style={{ marginTop: 55 }}
                      inputLabel={
                        <div>
                          <strong>หมายเหตุ </strong>
                          <span style={{ color: '#B3B3B3' }}>ไม่บังคับ</span>
                        </div>
                      }
                      placeholder="ระบุ"
                      disabled={this.checkDisabled(data.paymentOrderList.payment_order[0])}
                    /> */}
                  </Col>
                  <Col md={6}>
                    <div className="table-sum">
                      <Table hover style={{ marginBottom: 0 }}>
                        <tbody>
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>ราคารวมก่อนส่วนลด</td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].advance_before_disconst_price)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].non_vat_before_disconst_price)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].vat_before_disconst_price)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>ส่วนลด</td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].advance_disconst)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].non_vat_disconst)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].vat_disconst)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>ราคารวมหลังส่วนลด</td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].advance_after_disconst_price)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].non_vat_after_disconst)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].vat_after_disconst)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>ภาษีมูลค่าเพิ่ม 7%</td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].advance_vat7)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].non_vat_vat7)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].vat_vat7)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>รวมเป็นเงิน</td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].advance_included_vat_price)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].non_vat_included_vat_price)}
                            </td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].vat_included_vat_price)}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>จำนวนรวมทั้งสิ้น</td>
                            <td style={{ width: 100 }}></td>
                            <td style={{ width: 100 }}></td>
                            <td style={{ width: 100, fontWeight: 'bold' }}>
                              {formatCurrency(payment_order[0].total_price_included_vat)}
                            </td>
                          </tr>
                          {
                            console.log(payment_order[0].wht_list)
                          }
                          {
                            payment_order[0].wht_list.length > 0 ?
                              payment_order[0].wht_list.map((listWht, key) =>
                                <tr className={`wht ${key === payment_order[0].wht_list.length - 1 && 'last'}`}>
                                  <td style={{ width: 250, textAlign: 'left' }}>
                                    หัก ณ ที่จ่าย {listWht.wht}% ({formatCurrency(listWht.price)})
                                  </td>
                                  <td style={{ width: 100 }}></td>
                                  <td style={{ width: 100 }}></td>
                                  <td style={{ width: 100 }}>
                                    {formatCurrency(listWht.total_wht)}
                                  </td>
                                </tr>
                              ) : ''
                          }
                          <tr>
                            <td style={{ width: 250, textAlign: 'left' }}>รวมชำระทั้งสิ้น</td>
                            <td style={{ width: 100 }}></td>
                            <td style={{ width: 100 }}></td>
                            <td style={{ width: 100 }}>
                              {formatCurrency(payment_order[0].grand_total_price)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </SumContainer>
            </TableContainer>
          );
        }}
      </Query>
    );
  }
}

export default connect(PaymentList);
