import gql from 'graphql-tag';

export default gql`
mutation Update(
    $_id: ID!
    $paid_type: Int!
    $call_from: Int!
    $paymentCreditCardId: ID
    $paymentCreditCardBank: String
    $paymentCreditCardLastDigits: String
    $paymentCreditCardBrand: String
    $paymentCreditCarName: String
    $debit_note_id: ID
    $transfer_price: Float
    $transfer_pic: String
    $transfer_date: String
  
  ){
    payInvoice(
        _id: $_id
        paid_type: $paid_type
        call_from: $call_from
        paymentCreditCardId: $paymentCreditCardId
        paymentCreditCardBank: $paymentCreditCardBank
        paymentCreditCardLastDigits: $paymentCreditCardLastDigits
        paymentCreditCardBrand: $paymentCreditCardBrand
        paymentCreditCarName: $paymentCreditCarName
        debit_note_id: $debit_note_id
        transfer_price: $transfer_price
        transfer_pic: $transfer_pic
        transfer_date: $transfer_date
    ){
        _id
    }
}
`;
