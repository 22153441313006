import gql from 'graphql-tag';

export default gql`
query search(
  $account_info_business_name : String
  $account_info_type: Int
){
  accountInfoList(
	  	account_info_business_name :$account_info_business_name
	  	account_info_type :$account_info_type
  	) {
    account_info {
      _id
      account_info_business_name
    }
    total_page
  }
}
`;


