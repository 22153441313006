import React, { Component } from 'react';
import Moment from 'react-moment';
import { graphql, Mutation, Query, compose } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment/moment.js';

// Component
import styled from 'styled-components';
import {
  GoogleMaps,
  TrackingItem,
  TrackingItemImport,
  TrackingItemExport,
  PDFPreview,
  StarRating,
  ModalConfirm,
} from './components';
import Card from 'components/Card/Card.jsx';

// graphql
import dateNowQuery from './graphql/query/dateNow';
import detailShipment from './graphql/query/detailShipment';
import invoiceListQuery from './graphql/query/invoiceList';
import trackingList from './graphql/query/trackingList';
import accountInfo from './graphql/query/accountInfo';
import shipmentCostList from './graphql/query/shipmentCostList';
import editShipment from './graphql/mutation/editShipment';
import editpickupDate from './graphql/mutation/editpickupDate';
import cancelShipment from './graphql/mutation/cancelShipment';
import detailRating from './graphql/query/detailRating';

import s3 from 'config/s3';

/* Import & Export */
import singleUploadMutation from './graphql/mutation/singleUpload';
import trackingAddTransporterMutation from './graphql/mutation/trackingAddTransporter';
import trackingUpdateImportMutation from './graphql/mutation/trackingUpdateImport';
import trackingUpdateExportMutation from './graphql/mutation/trackingUpdateExport';

/* Aditional paymnet */
import invoiceBusinessListQuery from './graphql/query/invoiceBusinessList';
import expensesCategoryListQuery from './graphql/query/expensesCategoryList';
import invoiceMoreAddMutation from './graphql/mutation/invoiceMoreAdd';
import invoiceMoreUpdateMutation from './graphql/mutation/invoiceMoreUpdate';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { client } from '../..';

let diverWorking = false;

const ButtonEdit = styled.div`
  display: inline;
  font-size: 19px;
  cursor: pointer;
  margin-left: 15px;
  float: right;
`;

const BlankSpace = styled.div`
  height: 10px;
  width: calc(100% + 40px);
  background-color: #f7f7f8;
  margin-left: -25px;
  margin-right: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const Line = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #e6e6e6;
`;

const BoxWrapper = styled.div`
  background-color: #f7f7f8;
  margin-right: -20px;
  margin-left: -20px;
  padding: 15px 20px;

  & .inner-wrapper {
    background-color: white;
    width: 100%;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #f3f3f3;
  }
`;

const SubList = styled.div`
  line-height: 1.2;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  padding: 0 0 10px;

  & .title {
    color: #808080 !important;
    flex: 1;
    margin-right: 10px;
  }

  & .detail {
    color: #808080 !important;
    text-align: right;
  }
`;

const RowItem = styled.div`
  display: flow-root;
  color: #000000;
  padding: 0 0 10px;
  font-size: 18px;
  line-height: 1;
  min-height: 20px;

  & small {
    color: #999999;
    cursor: pointer;
  }
  & small.selected {
    color: #d90101;
  }

  &.selected {
    color: #d90101;

    & small {
      color: #d90101;
    }
  }

  & .left {
    display: flex;
    float: left;
    max-width: 270px;
    word-break: break-word;
  }

  & .right {
    display: block;
    float: right;
    text-align: right;
  }

  & .total {
    font-size: 18px;
    color: #d90001;
    font-weight: bold;
  }

  & .total.green {
    color: #19aa19;
  }

  & .total.black {
    color: #000000;
  }
`;

const RowItemList = styled.div`
  padding: 0 15px 10px 0;
  font-size: 18px;
  line-height: 1;
  display: block;
  width: 100%;
  float: left;
  border-bottom: 1px solid #ececec;
  margin-bottom: 10px;

  & .icon-left {
    display: block;
    float: left;
    width: 25px;
    padding: 10px 0;

    & i {
      font-size: 18px;
      color: #000000;
      cursor: pointer;
    }
    &:hover i {
      color: #d90101;
    }
  }

  & .left {
    display: block;
    float: left;
    width: calc(100% - 50px);
    color: #000000;

    & b {
      width: 100%;
      float: left;
      margin-bottom: 5px;
    }
    & small {
      width: 100%;
      float: left;
    }
  }

  & .icon-right {
    width: 25px;
    display: block;
    float: right;
    text-align: right;
    padding: 10px 0;

    & i {
      font-size: 18px;
      color: #bcbcbc;
      cursor: pointer;
    }
  }

  &.selected {
    & .left {
      color: #d90101;
    }
    & .icon-right i {
      color: #d90101;
    }
  }
`;

const AdditionalCostscontainer = styled.div`
  padding: 10px 0 5px;
`;

const ShipmentItem = styled.div`
  color: #000000;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  float: left;
  width: 100%;

  & .icon {
    float: left;
    width: 40px;
    padding-top: 5px;
    text-align: center;
  }
  & .icon.address {
    float: left;
    width: 40px;
    padding-top: 5px;
    text-align: center;
  }
  & .icon i {
    font-size: 18px;
  }
  & .detail {
    float: left;
    width: calc(100% - 40px);
    padding-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 10px;
  }
  & .detail span {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }
  & .detail-address {
    border-left: 2px dotted #e3e3e3;
    float: left;
    width: calc(100% - 40px);
    padding-bottom: 10px;
    padding-left: 20px;
    margin-left: 20px;
  }
  & .detail-address span {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  & ::last-child .detail-address {
    border-left: 2px dotted #e3e3e3;
    float: left;
    width: calc(100% - 40px);
    padding-bottom: 10px;
    padding-left: 20px;
    margin-left: 20px;
  }
`;

const ImageProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  & .image-product {
    width: 86px;
    height: 86px;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;
    cursor: pointer;

    & img {
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    margin-right: 10px;
    margin-bottom: 10px;
  }

  & .image-product-hover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000030;
    border-radius: 4px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  & .image-product:hover {
    .image-product-hover {
      display: block;
    }
  }
`;

const TextControlLabel = styled(ControlLabel)`
    width: 100%;
    font-size:18px; !important;
    color: #000000 !important;

    & small {
        color: #888888;
        float: right;
        cursor: pointer;
    }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  & .image {
    width: 86px !important;
    height: 86px !important;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;

    & img {
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    margin-right: 10px;
    margin-bottom: 10px;
  }

  .image-hover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000080;
    border-radius: 4px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  .image:hover {
    .image-hover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
    }
  }

  .btn-hover-image {
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;

    & i {
      margin-right: 5px;
    }
  }
`;

const ReasonReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ReasonReviewList = styled.div`
  padding: 6px 10px;
  border: 1px solid #e3e3e3;
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #000000;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #e3e3e3;
  }

  ${props =>
    props.selected &&
    `
		color: #d90101;
		border: 1px solid #d90101;
	`}

  ${props =>
    props.disabled &&
    `
		&:hover {
			background-color: transparent;
			cursor: default;
		}
	`}
`;

const ModalContent = styled.div`

	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
    }

    & .content{
        width: 100%;
        float: left;
        display: block;
        padding-bottom: 25px;
    }
    
    & .detail {
        width : 100%;
        float:left;
        
        & .left {
            width : auto;
            float:left;
        }
        & .right {
            float:left;
        }
	}

	& .footer {
		text-align: right;
		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-top: -20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const RowItemIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px;

  & .icon {
    font-size: 30px;
    padding-right: 10px;
  }

  & .left {
    flex: 1;
  }

  & .right {
    color: #888888;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    & i {
      font-size: 20px;
      margin-left: 5px;
    }
  }

  & .text-bold {
    font-weight: bold;
  }
`;

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  { value: 'ลูกค้าต้องการใช้บริการเสริม', label: 'ลูกค้าต้องการใช้บริการเสริม' },
  { value: 'เลือกใช้บริการเจ้าอื่น', label: 'เลือกใช้บริการเจ้าอื่น' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const vatList = [
  { value: '0', label: '0' },
  { value: '7', label: '7' },
];

const WhtList = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '3', label: '3' },
  { value: '5', label: '5' },
];

class ShipmentDetail extends Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(document.location.search.substring(1));
    let paramsShipmentId = params.get('shipment');

    this.state = {
      paramsShipmentId: paramsShipmentId,
      viewImageSrc: [],
      photoIndex: 0,
      isOpenLightbox: false,
      pick_up_date: '',
      pick_up_date_show: '',
      openEditPickupdate: false,
      openEditContactModal: false,
      pickup_id: '',
      pickup_contact_name: '',
      pickup_contact_tel: '',
      pickup_remark: '',
      delivery_id: '',
      delivery_contact_name: '',
      delivery_contact_tel: '',
      delivery_remark: '',
      delivery_id2: '',
      delivery_contact_name2: '',
      delivery_contact_tel2: '',
      delivery_remark2: '',
      addressObj: [],
      photoProductIndexLightBox: 0,
      isOpenPhotoProductLightBox: false,
      openEditTransporter: '',
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      license_plate_front: '',
      license_plate_back: '',

      openEditDriver: '',
      openEditDriverMode: 'new',
      openEditContainer: '',
      openEditContainerMode: 'new',
      openReturnContainer: false,
      cont_number: '',
      seal_number: '',
      tare_weight: '',
      cont_picture: [],

      isOpenModalAlertImage: false,
      textAlertModalImage: '',

      openEditDriverExport: '',
      openEditDriverModeExport: 'new',
      openEditContainerExport: '',
      openReturnContainerExport: false,
      OpenEditUploadImageExport: '',
      openDriverAcceptDocumentExport: false,

      show_cancel: false,
      shipmentID: '',
      shipmentNumber: '',
      rejectSelected: null,
      rejectRemark: '',
      rejectNumber: '',
      refundPrice: '',
      rejectDetail: '',
      showRejectDetail: false,
      shipmentGrandTotal: 0,
      Today: '',
      createDate: '',
      pickupDate: '',
      openApprovePaid: false,

      openAdditionalPaymentList: false,
      openAdditionalPayment: false,
      invoiceMoreMode: 'new',
      additionnalCostCategory: { value: null, label: null },
      additionnalCostSubCategory: { value: null, label: null },
      additionnalCostPriceVat: { value: '0', label: '0' },
      additionnalCostPriceWht: { value: '0', label: '0' },
      nvoiceMoreUpdateMode: 'update',
      invoiceMore_itemId: null,
      invoice_id: null,
      invoice_more_id: null,
      additionnalCostQty: 0,
      additionnalCostPrice: 0,
      tax_type: 0,
      disableTpyeDiscount: false,

      isOpenDetailReview: true,
      selectedAdditionalItem: [],
      selectedAdditionalItemMode: null,
      selectedAdditionalItemKey: null,
      showDeleteModal: false,
      DeleteItemName: '',
      DeleteItemPrice: '',

      //shipment edit payment
      isOpenModalEditPayment: false,
      selectEditPayment: null,
    };

    this._inputUploadImage = React.createRef();
  }

  handleDatePicker(event) {
    //console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD HH:mm')}`;
    const show = `${event.format('DD/MM/YYYY HH:mm')}`;
    this.setState({ pick_up_date: show, pick_up_date_show: date });
  }

  renderPayType(status) {
    switch (status) {
      case 0:
        return 'ไม่มีค่าใช้จ่าย';
      case 1:
        return 'บัตรเดบิต/เครดิต';
      case 2:
        return 'เงินสด';
      case 3:
        return 'ออกใบแจ้งหนี้';
      default:
        return '';
    }
  }

  renderPayTypeIcon(status) {
    switch (status) {
      case 0:
        return '';
      case 1:
        return 'fal fa-credit-card';
      case 2:
        return 'fal fa-hand-holding-usd';
      case 3:
        return 'fal fa-file-alt';
      default:
        return '';
    }
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 2:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      default:
        return '';
    }
  }

  renderStatusPayment(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 2:
        return <Label bsStyle="warning">รอตรวจสอบ</Label>;
      case 3:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      case 4:
        return <Label bsStyle="danger">ค้างชำระ</Label>;
      case 5:
        return <Label>แบบร่าง</Label>;
      case 6:
        return <Label bsStyle="default">รอสร้างใบเพิ่มหนี้</Label>;
      case 7:
        return <Label bsStyle="default">รอสร้างใบแจ้งหนี้</Label>;
      default:
        return '-';
    }
  }

  renderLightboxContainner(data, index) {
    let image = [];
    for (var i = 0; i < data.length; i++) {
      const item = data[i];
      image.push(`${s3}/${item}`);
    }

    this.setState({
      isOpenLightbox: true,
      photoIndex: index,
      viewImageSrc: image,
    });
  }

  renderLightbox(mode, data, index) {
    if (mode === 'signature') {
      this.setState({
        isOpenLightbox: true,
        photoIndex: 0,
        viewImageSrc: [`${s3}/${data.signature_picture}`],
      });
    } else {
      let image = [];
      if (index == 1) {
        image.push(`${s3}/${data.back_picture}`);
        if (data.side_picture) {
          image.push(`${s3}/${data.side_picture}`);
        }
        if (data.front_picture) {
          image.push(`${s3}/${data.front_picture}`);
        }
      } else if (index == 2) {
        image.push(`${s3}/${data.side_picture}`);
        if (data.front_picture) {
          image.push(`${s3}/${data.front_picture}`);
        }
        if (data.back_picture) {
          image.push(`${s3}/${data.back_picture}`);
        }
      } else {
        image.push(`${s3}/${data.front_picture}`);

        if (data.back_picture) {
          image.push(`${s3}/${data.back_picture}`);
        }
        if (data.side_picture) {
          image.push(`${s3}/${data.side_picture}`);
        }
      }

      this.setState({
        isOpenLightbox: true,
        photoIndex: 0,
        viewImageSrc: image,
      });
    }
  }

  renderPercentage(shipmentList) {
    const discountPrice = shipmentList.promotion_code.price;
    let distancePrice = 0;
    shipmentList.invoice_list.map((Pricing, key) => {
      if (Pricing.list_type == 1) {
        distancePrice += Pricing.list_total_price;
      }
    });
    return Math.round((discountPrice / distancePrice) * 100);
  }

  openEditContact(shipment) {
    //console.log("edit contact", shipment.shipment_address);
    let addressObj = [];
    for (var i = 0; i < shipment.shipment_address.length; i++) {
      const item = shipment.shipment_address[i];
      const obj = {
        _id: item._id,
        contact_name: item.contact_name,
        contact_tel: item.contact_tel,
        remark: item.remark,
      };
      addressObj.push(obj);
    }

    this.setState({
      addressObj: addressObj,
      remark: shipment.shipment_other_detail.remark,
      openEditContactModal: true,
    });
  }

  setValueContactName(index, value) {
    this.state.addressObj[index].contact_name = value;
    this.forceUpdate();
  }

  setValueContactTel(index, value) {
    this.state.addressObj[index].contact_tel = value;
    this.forceUpdate();
  }

  setValueContactRemark(index, value) {
    this.state.addressObj[index].remark = value;
    this.forceUpdate();
  }

  chkEditShipment() {
    let num = 0;
    let error = false;
    for (var i = 0; i < this.state.addressObj.length; i++) {
      const item = this.state.addressObj[i];

      if (item.contact_name == '' || item.contact_tel == '') {
        num++;
      }
    }

    if (num > 0) {
      error = true;
    }

    return error;
  }

  chkAdditionalPod(shipment) {
    const additional = shipment.shipment_additional.slice();
    const additionalFilter = additional.filter(
      item => item.additional_id === '5ca46d2d910efac4f7704577'
    );
    if (additionalFilter.length === 0) {
      return false;
    }
    return true;
  }

  renderAddressPOD(additional) {
    const POD = additional.filter(item => item.additional == 'บริการคืนใบส่งสินค้า (POD)');
    if (POD.length !== 0) {
      return POD[0].address;
    }
    return null;
  }

  renderAddressMode(mode, index) {
    if (mode == 'roundtrip') {
      return 'กลับจุดที่ 1 :';
    }
    return `จุดที่ ${index + 1} - ${mode}`;
  }

  renderVariablesShipment(shipment) {
    let obj = {
      shipmentID: shipment._id,
      shipment_address_update: this.state.addressObj,
      remark: this.state.remark,
    };
    //console.log("renderVariablesShipment",obj);
    return obj;
  }

  renderVariablesCancelShipment(shipment) {
    const bookingTime = moment(shipment.pick_up_date).diff(moment(shipment.create_date), 'minutes');
    const timeLeft = moment(shipment.pick_up_date).diff(moment(this.state.today), 'minutes');
    const paidType = shipment.paid_type;

    let rejectDetail = '';
    let obj = {};
    let cancelComment = this.state.rejectRemark;
    if (cancelComment == 'อื่นๆ') {
      cancelComment = this.state.rejectDetail;
    }

    if (paidType == 1) {
      //Credit
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการจะได้รับเงินคืนเต็มจำนวน';
        } else if (timeLeft < 300 && timeLeft >= 180) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการจะได้รับเงินคืนเป็นจำนวน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 3 ชั่วโมง ผู้ใช้บริการจะไม่ได้รับเงินคืน';
        }
      } else {
        if (timeLeft >= 100) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการจะได้รับเงินคืนเต็มจำนวน';
        } else if (timeLeft < 100 && timeLeft >= 50) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการจะได้รับเงินคืนเป็นจำนวน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการจะไม่ได้รับเงินคืน';
        }
      }

      obj = {
        shipmentID: shipment._id,
        cancel_comment: cancelComment,
        shipment_status: 5,
        shipment_refund: {
          amount: this.state.refundPrice,
          status: 1,
          detail: { th: rejectDetail, en: '' },
        },
      };
    } else if (paidType == 2) {
      //COD
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 300 && timeLeft >= 180) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      } else {
        if (timeLeft >= 100) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 100 && timeLeft >= 50) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      }

      obj = {
        shipmentID: shipment._id,
        cancel_comment: cancelComment,
        shipment_status: 5,
        shipment_fines: {
          amount: this.state.refundPrice,
          status: 1,
          detail: { th: rejectDetail, en: '' },
          detail_complete: { th: rejectDetail, en: '' },
        },
      };
    } else if (paidType == 3) {
      //Invoice
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 300 && timeLeft >= 180) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการขอยกเลิกงานและได้แจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      } else {
        if (timeLeft >= 100) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที ผู้ใช้บริการไม่ต้องชำระเงิน';
        } else if (timeLeft < 100 && timeLeft >= 50) {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที ผู้ใช้บริการต้องชำระเงิน 50%';
        } else {
          rejectDetail =
            'เนื่องจากผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที ผู้ใช้บริการต้องชำระเงินเต็มจำนวน';
        }
      }

      obj = {
        shipmentID: shipment._id,
        cancel_comment: cancelComment,
        shipment_status: 5,
        shipment_fines: {
          amount: this.state.refundPrice,
          status: 1,
          detail: { th: rejectDetail, en: '' },
          detail_complete: { th: rejectDetail, en: '' },
        },
      };
    }

    return obj;
  }

  async handlecheckcancel(shipment, cancelShipmentMutation) {
    const shipmentID = shipment._id;
    try {
      const {
        data: {
          dateNow: { now: today },
        },
      } = await client.query({
        query: dateNowQuery,
      });

      const {
        data: { shipmentList },
      } = await client.query({
        query: detailShipment,
        variables: {
          _id: shipmentID,
        },
      });

      console.log(shipmentList);
      if (shipmentList.shipment[0].shipment_status == 4) {
        alert('ไม่สามารถยกเลิกงานนี้ได้เนื้อจากงานเสร็จสิ้นแล้ว');
        window.location.reload();
      } else if (shipmentList.shipment[0].shipment_status == 5) {
        alert('ไม่สามารถยกเลิกงานนี้ได้เนื้อจากงานถูกยกเลิกแล้ว');
        window.location.reload();
      } else {
        this.setState({
          Today: today,
        });

        cancelShipmentMutation();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async handleShowcancel(shipment) {
    const shipmentNumber = shipment.shipment_number;
    const shipmentID = shipment._id;
    const paidType = shipment.paid_type;

    let today = '';
    let invoiceBusinessList = null;
    let invoiceList = null;
    try {
      const {
        data: {
          dateNow: { now: today },
        },
      } = await client.query({
        query: dateNowQuery,
      });

      const {
        data: { invoiceBusinessList },
      } = await client.query({
        query: invoiceBusinessListQuery,
        variables: {
          shipment_id: shipmentID,
        },
      });

      const {
        data: { invoiceList },
      } = await client.query({
        query: invoiceListQuery,
        variables: {
          shipment_id: shipmentID,
        },
      });

      let grandTotal = 0;
      if (invoiceBusinessList) {
        grandTotal = invoiceBusinessList.grand_total_price;
      } else {
        grandTotal = invoiceList[0].total_price;
      }

      this.setState({
        show_cancel: true,
        shipmentID: shipmentID,
        shipmentNumber: shipmentNumber,
        rejectSelected: null,
        showRejectDetail: false,
        rejectNumber: '',
        rejectRemark: '',
        refundPrice: '',
        rejectDetail: '',
        shipmentGrandTotal: grandTotal,
        Today: today,
        createDate: shipment.create_date,
        pickupDate: shipment.pick_up_date,
      });
    } catch (error) {
      today = moment();
      console.log(error);
    }
  }

  checkMaxPrice(value) {
    if (value > this.state.shipmentGrandTotal) {
      alert('จำนวนเงินที่คืน หรือ เรียกเก็บจากลูกค้าไม่ควรเกิน ยอดรวมทั้งหมดของงาน');
      this.setState({ refundPrice: this.state.shipmentGrandTotal });
    } else {
      this.setState({ refundPrice: value });
    }
  }

  renderShipmentCancelError(shipment) {
    const bookingTime = moment(shipment.pick_up_date).diff(moment(shipment.create_date), 'minutes');
    const timeLeft = moment(shipment.pick_up_date).diff(moment(this.state.today), 'minutes');
    const paidType = shipment.paid_type;

    if (paidType == 1) {
      //Credit
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า น้อยกว่า 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 300 && timeLeft >= 180) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า น้อยกว่า 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง{' '}
                <b> ไม่สามารถยื่นคำร้องขอคืนเงินได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า น้อยกว่า 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า : <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        }
      } else {
        if (timeLeft >= 100) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที{' '}
                <b> สามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 100 && timeLeft >= 50) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที{' '}
                <b> สามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า :{' '}
                  <span style={{ color: '#D90101' }}>
                    {(this.state.shipmentGrandTotal / 2).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที{' '}
                <b> ไม่สามารถยื่นคำร้องขอคืนเงินได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้เต็มจำนวน
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าอย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอคืนเงินได้ 50 เปอร์เซ็น
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการไม่สามารถยื่นคำร้องของคืนเงิน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ต้องคืนลูกค้า : <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        }
      }
    } else if (paidType == 2) {
      //COD
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 300 && timeLeft >= 180) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      } else {
        if (timeLeft >= 100) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที
                <b> สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 100 && timeLeft >= 50) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {(this.state.shipmentGrandTotal / 2).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      }
    } else if (paidType == 3) {
      //Invoice
      if (bookingTime >= 320) {
        if (timeLeft >= 300) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 5 ชั่วโมง{' '}
                <b>สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 300 && timeLeft >= 180) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 5
                    ชั่วโมงก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 5 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 3 ชั่วโมง ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      } else {
        if (timeLeft >= 100) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าอย่างน้อย 100 นาที{' '}
                <b> สามารถยื่นคำร้องขอไม่ชำระค่าบริการได้</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>{0.0} บาท</span>
                </b>
              </p>
            </Col>
          );
        } else if (timeLeft < 100 && timeLeft >= 50) {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 100 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {(this.state.shipmentGrandTotal / 2).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        } else {
          return (
            <Col md={12}>
              <p>
                ขณะนี้ผู้ใช้บริการยกเลิกการใช้งานบริการและแจ้งล่วงหน้าน้อยกว่า 50 นาที{' '}
                <b>ผู้ใช้บริการต้องชำระเงินเต็มจำนวน</b>
              </p>
              <p>
                <b>เงื่อนไข</b>
                <ol style={{ padddingLeft: '15px' }}>
                  <li>
                    ผู้ใช้บริการสามารถยื่นคำร้องขอไม่ชำระค่าบริการได้
                    เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ ในกรณีแจ้งล่วงหน้า อย่างน้อย 100 นาที
                    ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการ 50 เปอร์เซ็น เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 100 นาที ก่อนกำหนดการให้บริการ
                  </li>
                  <li>
                    ผู้ใช้บริการต้องชำระค่าบริการเต็มจำนวน เนื่องจากเปลี่ยนใจยกเลิกการใช้บริการ
                    ในกรณีแจ้งล่วงหน้าน้อยกว่า 50 นาที ก่อนกำหนดการให้บริการ
                  </li>
                </ol>
              </p>
              <p>
                ค่าบริการทั้งหมด :{' '}
                {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                บาท <br />
                <b>
                  จำนวนเงินที่ลูกค้าที่ต้องชำระ :{' '}
                  <span style={{ color: '#D90101' }}>
                    {this.state.shipmentGrandTotal.toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}{' '}
                    บาท
                  </span>
                </b>
              </p>
            </Col>
          );
        }
      }
    }
  }

  handleChangeReject(reject) {
    // console.log(`truckSelected:`, truck);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ rejectSelected: reject, rejectRemark: reject.value });
    if (reject.value == 'อื่นๆ') {
      this.setState({ showRejectDetail: true });
    } else {
      this.setState({ showRejectDetail: false });
    }
    // console.log(`truckSelected:`, this.state.truckID);
  }

  checkDisableCancel() {
    if (
      this.state.rejectNumber === this.state.shipmentNumber &&
      this.rejectSelected !== null &&
      this.state.refundPrice !== ''
    ) {
      return false;
    }
    return true;
  }

  handleChangeAdditionnalCostCategory(CategoryList, event) {
    this.setState({ additionnalCostCategory: event });

    if (event.value == '5d4009ec9799b1f96549d21e') {
      this.setState({
        additionnalCostSubCategory: { value: 'ส่วนลด', label: 'ส่วนลด' },
        disableTpyeDiscount: true,
        tax_type: 3,
        additionnalCostPriceVat: { value: '0', label: '0' },
        additionnalCostPriceWht: { value: '0', label: '0' },
      });
    } else {
      this.setState({
        additionnalCostSubCategory: { value: null, label: null },
        disableTpyeDiscount: false,
        tax_type: 0,
        additionnalCostPriceVat: { value: '0', label: '0' },
        additionnalCostPriceWht: { value: '0', label: '0' },
      });
    }
  }

  handleChangeAdditionnalCostSubCategory(CategoryList, event) {
    this.setState({ additionnalCostSubCategory: event });

    const CategoryListelected = CategoryList.filter(list => {
      return list._id == this.state.additionnalCostCategory.value;
    });

    const expenses = CategoryListelected[0].expenses_item.filter(list => {
      return list._id == event.value;
    });

    this.setState({
      disableTpyeDiscount: false,
      tax_type: expenses[0].tax_type,
      additionnalCostPriceVat: { value: expenses[0].default_vat, label: expenses[0].default_vat },
      additionnalCostPriceWht: { value: expenses[0].default_wht, label: expenses[0].default_wht },
    });

    console.log(expenses);
  }

  handleChangeadditionnalCostPriceVat(event) {
    this.setState({ additionnalCostPriceVat: event });
  }

  handleChangeadditionnalCostPriceWht(event) {
    this.setState({ additionnalCostPriceWht: event });
  }

  renderAdditionnalCostCategory(CategoryList) {
    const CategoryListData = CategoryList.map(e => {
      return {
        value: e._id,
        label: e.name,
      };
    });

    return CategoryListData;
  }

  renderAdditionnalCostSubCategory(CategoryList) {
    const CategoryListelected = CategoryList.filter(list => {
      return list._id == this.state.additionnalCostCategory.value;
    });
    if (CategoryListelected.length > 0) {
      const CategoryListData = CategoryListelected[0].expenses_item.map(e => {
        return {
          value: e._id,
          label: e.name,
        };
      });
      return CategoryListData;
    } else {
      return [
        {
          value: null,
          label: null,
        },
      ];
    }
  }

  checkDisableAddCost() {
    if (
      this.state.additionnalCostCategory.value == null ||
      this.state.additionnalCostSubCategory.value == null ||
      this.state.additionnalCostQty <= 0 ||
      this.state.additionnalCostPrice <= 0
    ) {
      return 'disable';
    } else {
      return '';
    }
  }

  onClickAddInvoiceMore(mode) {
    this.setState({
      openAdditionalPayment: true,
      isOpenDetailReview: false,
      invoiceMoreMode: mode,
      additionnalCostCategory: { value: null, label: null },
      additionnalCostSubCategory: { value: null, label: null },
      additionnalCostPriceVat: { value: '0', label: '0' },
      additionnalCostPriceWht: { value: '0', label: '0' },
      invoiceMore_itemId: null,
      invoice_id: null,
      invoice_more_id: null,
      additionnalCostQty: 0,
      additionnalCostPrice: 0,
    });
  }

  renderAdditionnalCostCategorySelected(CategoryList, id) {}

  renderAdditionnalCostSubCategorySelected(CategoryList, id) {
    // const CategoryListelected = CategoryList.filter((list) => {
    //     return list._id == this.state.additionnalCostCategory.value;
    // })
  }

  onClickUpdateInvoiceMore(item, mode, _id) {
    if (mode == 'edit') {
      this.setState({
        openAdditionalPayment: true,
        showDeleteModal: false,
        invoiceMoreMode: mode,
        additionnalCostCategory: { value: null, label: null },
        additionnalCostSubCategory: { value: null, label: null },
        additionnalCostPriceVat: {
          value: item.invoice_business_list_vat,
          label: item.invoice_business_list_vat,
        },
        additionnalCostPriceWht: {
          value: item.invoice_business_list_wht,
          label: item.invoice_business_list_wht,
        },
        invoiceMore_itemId: item._id,
        additionnalCostQty: item.invoice_business_list_qty,
        additionnalCostPrice: item.invoice_business_list_total_price,
      });
    } else if (mode == 'delete') {
      this.setState({
        openAdditionalPayment: false,
        showDeleteModal: true,
        invoiceMoreMode: mode,
        DeleteItemName: item.invoice_business_list_detail,
        DeleteItemPrice: item.invoice_business_list_total_price,
        invoiceMore_itemId: item._id,
        invoice_more_id: this.state.selectedAdditionalItemMode != 'mainInvoice' ? _id : null,
        invoice_id: this.state.selectedAdditionalItemMode == 'mainInvoice' ? _id : null,
      });
    }
  }

  renderVariablesInvoiceMoreUpdate() {
    let obj = {};
    if (this.state.invoiceMoreMode == 'edit') {
      obj = {
        invoice_id: this.state.invoice_id,
        invoice_more_id: this.state.invoice_more_id,
        item_id: this.state.invoiceMore_itemId,
        category: this.state.additionnalCostCategory.label,
        detail: this.state.additionnalCostSubCategory.label,
        price: this.state.additionnalCostPrice,
        qty: this.state.additionnalCostQty,
        vat: this.state.additionnalCostPriceVat,
        wht: this.state.additionnalCostPriceWht,
        mode: this.state.invoiceMoreMode,
      };
    } else if (this.state.invoiceMoreMode == 'delete') {
      obj = {
        invoice_id: this.state.invoice_id,
        invoice_more_id: this.state.invoice_more_id,
        item_id: this.state.invoiceMore_itemId,
        mode: this.state.invoiceMoreMode,
      };
    }

    return obj;
  }

  onClickOpenAdditionalPayment(item, key, mode) {
    this.setState({
      openAdditionalPaymentList: true,
      selectedAdditionalItemKey: key,
      selectedAdditionalItem: item,
      selectedAdditionalItemMode: mode,
      isOpenDetailReview: false,
    });
  }

  chkOpenAdditionalItem(key) {
    if (key == this.state.selectedAdditionalItemKey) {
      return true;
    }
    return false;
  }

  /*Import */

  openEditTransporter(tracking) {
    this.setState({
      openEditTransporter: 'open',
      driver_name: tracking[0].driver_name ? tracking[0].driver_name : '',
      driver_phone_code: tracking[0].driver_phone_code ? tracking[0].driver_phone_code : '66',
      driver_phone_number: tracking[0].driver_phone_number
        ? '0' + tracking[0].driver_phone_number
        : '',
    });
  }

  checkValidateAddTransporter() {
    const { driver_name, driver_phone_code, driver_phone_number } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (driver_name && validatePhoneNumber.test(driver_phone_number)) {
      return true;
    }

    return false;
  }

  onSubmitAddTransporter(e, trackingId, trackingAddTransporter) {
    const chkError = this.checkValidateAddTransporter();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const { driver_name, driver_phone_number, driver_phone_code } = this.state;

      trackingAddTransporter({
        variables: {
          _id: trackingId,
          driver_accept: 1,
          driver_name: driver_name,
          driver_phone_code: driver_phone_code,
          driver_phone_number: driver_phone_number.substring(1),
        },
      });
    }
  }

  checkValidateAddDriver() {
    const {
      driver_name,
      driver_phone_code,
      driver_phone_number,
      license_plate_front,
      license_plate_back,
    } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (driver_name && license_plate_front && validatePhoneNumber.test(driver_phone_number)) {
      return true;
    }

    return false;
  }

  checkValidateAddContainer() {
    const { cont_number, seal_number, tare_weight } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (cont_number && seal_number && tare_weight) {
      return true;
    }

    return false;
  }

  openEditDriver(tracking, mode) {
    this.setState({
      openEditDriver: 'open',
      openEditDriverMode: mode,
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      license_plate_front: '',
      license_plate_back: '',
    });

    if (tracking[0].tracking_import_detail[0].track_driver.length > 0) {
      this.setState({
        openEditDriver: 'open',
        openEditDriverMode: mode,
        driver_name: tracking[0].tracking_import_detail[0].track_driver[0].driver_name,
        driver_phone_code: tracking[0].tracking_import_detail[0].track_driver[0].driver_phone_code,
        driver_phone_number:
          '0' + tracking[0].tracking_import_detail[0].track_driver[0].driver_phone_number,
        license_plate_front:
          tracking[0].tracking_import_detail[0].track_driver[0].license_plate_front,
        license_plate_back:
          tracking[0].tracking_import_detail[0].track_driver[0].license_plate_back,
      });
    }
  }

  onSubmitAddDriver(e, tracking, trackingAddDriver) {
    const chkError = this.checkValidateAddDriver();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const {
        driver_name,
        driver_phone_number,
        driver_phone_code,
        license_plate_front,
        license_plate_back,
      } = this.state;

      if (this.state.openEditDriverMode == 'new') {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_add: [
              {
                driver_name: driver_name,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: license_plate_front,
                license_plate_back: license_plate_back,
              },
            ],
          },
        });
      } else {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_update: [
              {
                _id: tracking[0].tracking_import_detail[0].track_driver[0]._id,
                position_driver: '0',
                driver_name: driver_name,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: license_plate_front,
                license_plate_back: license_plate_back,
              },
            ],
          },
        });
      }
    }
  }

  openEditContainer(tracking, mode) {
    this.setState({
      openEditContainer: 'open',
      openEditContainerMode: mode,
      cont_number: '',
      seal_number: '',
      tare_weight: '',
      cont_picture: [],
    });

    if (tracking[0].tracking_import_detail[0].accept_container.length > 0) {
      this.setState({
        openEditContainer: 'open',
        openEditContainerMode: mode,
        cont_number: tracking[0].tracking_import_detail[0].accept_container[0].cont_number,
        seal_number: tracking[0].tracking_import_detail[0].accept_container[0].seal_number,
        tare_weight: tracking[0].tracking_import_detail[0].accept_container[0].tare_weight,
        cont_picture: tracking[0].tracking_import_detail[0].accept_container[0].picture,
      });
    }
  }

  onSubmitAddContainer(e, tracking, trackingAddContainer) {
    const chkError = this.checkValidateAddContainer();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const { cont_number, seal_number, tare_weight, cont_picture } = this.state;
      //  //console.log("cont_picture",cont_picture.length)
      if (this.state.openEditContainerMode == 'new') {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_add: [
              {
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      } else {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_import_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_import_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_update: [
              {
                position_accept_container: '0',
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      }
    }
  }

  onOpenReturnContainer() {
    this.setState({
      openReturnContainer: true,
    });
  }

  onSubmitReturnContainer(e, tracking, trackingReturnContainer) {
    trackingReturnContainer({
      variables: {
        _id: tracking[0]._id,
        _id_detail: tracking[0].tracking_import_detail[0]._id,
        position: '0',
        droppoint: tracking[0].tracking_import_detail[0].droppoint,
        return_container_date: '1',
      },
    });
  }
  /*end Import */

  /* Export */

  openEditTransporterExport(tracking) {
    this.setState({
      openEditTransporterExport: 'open',
      driver_name: tracking[0].driver_name ? tracking[0].driver_name : '',
      driver_phone_code: tracking[0].driver_phone_code ? tracking[0].driver_phone_code : '66',
      driver_phone_number: tracking[0].driver_phone_number
        ? '0' + tracking[0].driver_phone_number
        : '',
    });
  }

  openEditDriverExport(tracking, mode) {
    this.setState({
      openEditDriverExport: 'open',
      openEditDriverMode: mode,
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      license_plate_front: '',
      license_plate_back: '',
    });

    if (tracking[0].tracking_export_detail[0].track_driver.length > 0) {
      this.setState({
        openEditDriverExport: 'open',
        openEditDriverMode: mode,
        driver_name: tracking[0].tracking_export_detail[0].track_driver[0].driver_name,
        driver_phone_code: tracking[0].tracking_export_detail[0].track_driver[0].driver_phone_code,
        driver_phone_number:
          '0' + tracking[0].tracking_export_detail[0].track_driver[0].driver_phone_number,
        license_plate_front:
          tracking[0].tracking_export_detail[0].track_driver[0].license_plate_front,
        license_plate_back:
          tracking[0].tracking_export_detail[0].track_driver[0].license_plate_back,
      });
    }
  }

  onSubmitAddDriverExport(e, tracking, trackingAddDriver) {
    const chkError = this.checkValidateAddDriver();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const {
        driver_name,
        driver_phone_number,
        driver_phone_code,
        license_plate_front,
        license_plate_back,
      } = this.state;

      if (this.state.openEditDriverMode == 'new') {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_add: [
              {
                driver_name: driver_name,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: license_plate_front,
                license_plate_back: license_plate_back,
              },
            ],
          },
        });
      } else {
        trackingAddDriver({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            track_driver_date: '1',
            track_driver_update: [
              {
                _id: tracking[0].tracking_export_detail[0].track_driver[0]._id,
                position_driver: '0',
                driver_name: driver_name,
                driver_phone_code: driver_phone_code,
                driver_phone_number: driver_phone_number.substring(1),
                license_plate_front: license_plate_front,
                license_plate_back: license_plate_back,
              },
            ],
          },
        });
      }
    }
  }

  openEditContainerExport(tracking, mode) {
    this.setState({
      openEditContainerExport: 'open',
      openEditContainerMode: mode,
      cont_number: '',
      seal_number: '',
      tare_weight: '',
      cont_picture: [],
    });

    if (tracking[0].tracking_export_detail[0].accept_container.length > 0) {
      this.setState({
        openEditContainerExport: 'open',
        openEditContainerMode: mode,
        cont_number: tracking[0].tracking_export_detail[0].accept_container[0].cont_number,
        seal_number: tracking[0].tracking_export_detail[0].accept_container[0].seal_number,
        tare_weight: tracking[0].tracking_export_detail[0].accept_container[0].tare_weight,
        cont_picture: tracking[0].tracking_export_detail[0].accept_container[0].picture,
      });
    }
  }

  onSubmitAddContainerExport(e, tracking, trackingAddContainer) {
    const chkError = this.checkValidateAddContainer();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const { cont_number, seal_number, tare_weight, cont_picture } = this.state;
      //console.log("cont_picture",cont_picture)
      if (this.state.openEditContainerMode == 'new') {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_add: [
              {
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      } else {
        trackingAddContainer({
          variables: {
            _id: tracking[0]._id,
            _id_detail: tracking[0].tracking_export_detail[0]._id,
            position: '0',
            droppoint: tracking[0].tracking_export_detail[0].droppoint,
            accept_container_date: '1',
            accept_container_update: [
              {
                position_accept_container: '0',
                cont_number: cont_number,
                seal_number: seal_number,
                tare_weight: tare_weight,
                picture: cont_picture,
              },
            ],
          },
        });
      }
    }
  }

  onOpenDriverAcceptDocument() {
    this.setState({
      openDriverAcceptDocumentExport: true,
    });
  }

  onSubmitDriverAcceptDocument(e, tracking, trackingReturnContainer) {
    trackingReturnContainer({
      variables: {
        _id: tracking[0]._id,
        _id_detail: tracking[0].tracking_export_detail[0]._id,
        position: '0',
        droppoint: tracking[0].tracking_export_detail[0].droppoint,
        driver_accept_document: '1',
      },
    });
  }

  onOpenReturnContainerExport() {
    this.setState({
      openReturnContainerExport: true,
    });
  }

  onSubmitReturnContainerExport(e, tracking, trackingReturnContainer) {
    trackingReturnContainer({
      variables: {
        _id: tracking[0]._id,
        _id_detail: tracking[0].tracking_export_detail[0]._id,
        position: '0',
        droppoint: tracking[0].tracking_export_detail[0].droppoint,
        return_container_date: '1',
      },
    });
  }

  OpenUploadImageExport(tracking) {
    this.setState({
      OpenEditUploadImageExport: 'open',
      cont_picture: [],
    });

    if (tracking[0].tracking_export_detail[0].commodity_picture.length > 0) {
      this.setState({
        OpenEditUploadImageExport: 'open',
        cont_picture: tracking[0].tracking_export_detail[0].commodity_picture,
      });
    }
  }

  onSubmitUploadImageExport(e, tracking, trackingUploadImageCommodity) {
    const chkError = this.state.cont_picture.length > 0;
    if (chkError) {
      const { cont_picture } = this.state;
      trackingUploadImageCommodity({
        variables: {
          _id: tracking[0]._id,
          _id_detail: tracking[0].tracking_export_detail[0]._id,
          position: '0',
          droppoint: tracking[0].tracking_export_detail[0].droppoint,
          commodity_picture_date: '1',
          commodity_picture: cont_picture,
        },
      });
    }
  }
  /* Image */

  checkFileTypeImage(file) {
    if (file) {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        return true;
      } else {
        return false;
      }
    }
  }

  checkFileSizeImage(file) {
    if (file) {
      const size = parseFloat(file.size / 1024).toFixed(2);
      if (size < 10240) {
        //ขนาดไฟล์ 10240KB or 10Mb
        return true;
      } else {
        return false;
      }
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
    if (checkFileTypeImage === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png, jpg เท่านั้น',
      });
      return;
    }

    const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
    if (checkFileSize === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 10MB',
      });
      return;
    }

    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/shipments/${documentName}_${timestamp}`,
        },
      });
    }
  }

  onDeletePhoto(file) {
    const document = this.state.cont_picture.filter(item => `${item}` !== file);

    this.setState({
      cont_picture: document,
    });
  }

  rendeReceiptImage(shipment, mode) {
    const receipt_doc = shipment.shipment_confirm_detail.receipt_doc.map(item => {
      const patternFileType = /\.[0-9a-z]{1,5}$/;
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      const thumbnail = patternCheckFileType.test(item.path)
        ? `${require('./../../assets/img/file-type-pdf.png')}`
        : `${s3}/${item.path}`;
      const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

      return {
        path: `${s3}/${item.path}`, //path ไฟล์
        fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
        type: type, //ประเภทไฟล์
        thumbnail: thumbnail, //รูปตัวอย่าง
      };
    });
    const receiptImage = receipt_doc.map(item => {
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      if (patternCheckFileType.test(item.path)) {
        return <PDFPreview file={item.path} />;
      }

      return item.thumbnail;
    });

    if (mode == 'view') {
      return receipt_doc;
    }

    return receiptImage;
  }

  renderConfirmImage(shipment, mode) {
    let shipmentConfirmImage = '';
    if (shipment.shipment_confirm_document) {
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      const shipment_confirm = {
        path: `${s3}/${shipment.shipment_confirm_document}`, //path ไฟล์
        fileType: shipment.shipment_confirm_document.match(/\.[0-9a-z]{1,5}$/)[0], //นามสกุลไฟล์
        type: patternCheckFileType.test(shipment.shipment_confirm_document) ? 'pdf' : 'image', //ประเภทไฟล์
        thumbnail: patternCheckFileType.test(shipment.shipment_confirm_document)
          ? `${require('./../../assets/img/file-type-pdf.png')}`
          : `${s3}/${shipment.shipment_confirm_document}`, //รูปตัวอย่าง
      };

      if (patternCheckFileType.test(shipment_confirm.path)) {
        shipmentConfirmImage = <PDFPreview file={shipment_confirm.path} />;
      } else {
        shipmentConfirmImage = shipment_confirm.thumbnail;
      }

      if (mode == 'view') {
        return shipment_confirm;
      }
    }

    return shipmentConfirmImage;
  }
  /* end image */

  checkTransporterHideDriver(transporterId) {
    console.log('checkTransporterHideDriver', diverWorking);
    if (!diverWorking) {
      return true;
    }
    return false;

    // const transporterList = [
    //     { transporter: '5c9afba08c8cf32b81d62a40', transporter_name: 'K.ณัฐพล 4+6+10' },
    //     { transporter: '5c9afbc08c8cf32b81d62a44', transporter_name: 'K.ธนูรัตน์ 4 + 6' },
    //     { transporter: '5c9afbe58c8cf32b81d62a48', transporter_name: 'K.บูรพา 4+6+10' },
    //     { transporter: '5c9afbff8c8cf32b81d62a4c', transporter_name: 'K.องอาจ 4' },
    //     { transporter: '5cc190734ad2050dfd0f4631', transporter_name: 'K.อุดม 6+10' },
    //     { transporter: '5cc18f774ad2050dfd0f462a', transporter_name: 'K.วราลักษณ์ 4+6+10' },
    //     { transporter: '5cd142a2092a935384fa2c71', transporter_name: 'k.วีระพนธ์ 4' },
    //     { transporter: '5d2fe9807ddc225bc8cf99db', transporter_name: 'K.สุรินทร์' },
    //     { transporter: '5d353a91088ba84277201784', transporter_name: 'บ. K2C logistics 6 + 10 พื้นเรียบ' },
    // ];

    // return transporterList.filter(item => item.transporter === transporterId).length > 0 ? true : false;
  }

  checkMatchingTime(matching_time) {
    //const timeLeft = moment(shipment.assign_driver_date).diff(moment(shipment.create_date), 'seconds');
    return `${this.getTimeFromMins(matching_time)}`;
  }

  getTimeFromMins(times) {
    var day = 0;
    var hours = 0;
    var minutes = 0;
    var seconds = 0;

    if (times >= 86400) {
      day = (times / 86400) | 0;
      times -= day * 86400;
    }
    if (times >= 3600) {
      hours = (times / 3600) | 0;
      times -= hours * 3600;
    }
    if (times >= 60) {
      minutes = (times / 60) | 0;
      times -= minutes * 60;
    }
    if (times > 0 && times < 60) {
      seconds = times % 60 | 0;
    }

    var showTime = '';

    if (day > 0) {
      showTime = `${day} วัน `;
    }
    if (hours > 0) {
      showTime += `${hours} ชั่วโมง `;
    }
    if (minutes > 0) {
      showTime += `${minutes} นาที `;
    }
    if (seconds > 0) {
      showTime += `${seconds} วินาที`;
    }
    return showTime;
  }

  /* Rating */
  getRatingToWord(rating) {
    if (rating === 5) {
      return {
        en: 'Excellent',
        th: 'ดีมาก',
      };
    } else if (rating === 4) {
      return {
        en: 'Good',
        th: 'ดี',
      };
    } else if (rating === 3) {
      return {
        en: 'Average',
        th: 'พอใช้',
      };
    } else if (rating === 2) {
      return {
        en: 'Bad',
        th: 'แย่',
      };
    } else if (rating === 1) {
      return {
        en: 'Worst',
        th: 'แย่มาก',
      };
    }

    return {
      en: 'Rate driver',
      th: 'ให้คะแนนพนักงานขับรถ',
    };
  }

  getReasonFromRating() {
    const { rating } = this.state;
    if (rating === 5 || rating === 4) {
      return [
        {
          id: 1,
          en: 'Good manner',
          th: 'มารยาทดี',
        },
        {
          id: 2,
          en: 'Rapidly',
          th: 'ขนส่งรวดเร็ว',
        },
        {
          id: 3,
          en: 'On time delivery',
          th: 'ตรงต่อเวลา',
        },
        {
          id: 4,
          en: 'Formal dressed',
          th: 'แต่งกายสุภาพ',
        },
        {
          id: 5,
          en: 'Good condition goods',
          th: 'สินค้าอยู่ในสภาพดี',
        },
      ];
    } else if (rating === 3) {
      return [
        {
          id: 1,
          en: 'Delay',
          th: 'ขนส่งล่าช้า',
        },
        {
          id: 2,
          en: 'Rude',
          th: 'พูดจาไม่ค่อยดี',
        },
        {
          id: 3,
          en: 'Bad navigation',
          th: 'ไม่ชำนาญเส้นทาง',
        },
        {
          id: 4,
          en: 'Unsatisfied',
          th: 'ไม่ค่อยประทับใจ',
        },
        {
          id: 5,
          en: 'Damaged during delivery',
          th: 'เกิดปัญหาระหว่างการขนส่ง',
        },
      ];
    } else if (rating === 2 || rating === 1) {
      return [
        {
          id: 1,
          en: 'Rude',
          th: 'พูดจาไม่ดี',
        },
        {
          id: 2,
          en: 'Delay',
          th: 'ขนส่งล่าช้า',
        },
        {
          id: 3,
          en: 'Bad navigation',
          th: 'ไม่ชำนาญเส้นทาง',
        },
        {
          id: 4,
          en: 'Informal dressed',
          th: 'แต่งกายไม่เรียบร้อย',
        },
        {
          id: 5,
          en: 'Damaged goods',
          th: 'สินค้าเกิดชำรุด',
        },
      ];
    }

    return [];
  }

  render() {
    const { viewImageSrc, isOpenLightbox, photoIndex } = this.state;
    const shipmentId = this.props.location.search.split('?shipment=')[1];

    //console.log(shipmentId);
    return (
      <div>
        {isOpenLightbox ? (
          viewImageSrc.length > 1 ? (
            <Lightbox
              mainSrc={viewImageSrc[photoIndex]}
              nextSrc={viewImageSrc[(photoIndex + 1) % viewImageSrc.length]}
              prevSrc={viewImageSrc[(photoIndex + viewImageSrc.length - 1) % viewImageSrc.length]}
              onCloseRequest={() => this.setState({ isOpenLightbox: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + viewImageSrc.length - 1) % viewImageSrc.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % viewImageSrc.length,
                })
              }
            />
          ) : (
            <Lightbox
              mainSrc={viewImageSrc[photoIndex]}
              onCloseRequest={() => this.setState({ isOpenLightbox: false })}
            />
          )
        ) : null}
        <div className="content">
          <Grid fluid>
            <Row>
              <Query
                query={detailShipment}
                variables={{
                  _id: shipmentId,
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  if (loading) return 'Loading...';
                  if (error) return { error };
                  const shipment = data.shipmentList.shipment;

                  const documents = shipment[0].shipment_other_detail.document.map(item => {
                    const patternFileType = /\.[0-9a-z]{1,5}$/;
                    const patternCheckFileType = /^.*\.(pdf|PDF)$/;
                    const thumbnail = patternCheckFileType.test(item.path)
                      ? `${require('./../../assets/img/file-type-pdf.png')}`
                      : `${s3}/${item.path}`;
                    const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

                    return {
                      path: `${s3}/${item.path}`, //path ไฟล์
                      fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
                      type: type, //ประเภทไฟล์
                      thumbnail: thumbnail, //รูปตัวอย่าง
                    };
                  });

                  const documentsImage = documents.map(item => {
                    const patternCheckFileType = /^.*\.(pdf|PDF)$/;
                    if (patternCheckFileType.test(item.path)) {
                      return <PDFPreview file={item.path} />;
                    }

                    return item.thumbnail;
                  });

                  let multidropPrice = 0;
                  if (
                    shipment[0].shipment_address.length > 2 &&
                    shipment[0].round_trip !== 1 &&
                    shipment[0].shipment_type == 1
                  ) {
                    if (shipment[0].multi_point.length > 0) {
                      multidropPrice = shipment[0].multi_point.reduce((sum, item) => {
                        return sum + item.price_per_point * item.total_point;
                      }, 0);
                    }
                  }

                  //console.log(shipment[0]);

                  return (
                    <Col md={12} className="card-shipment">
                      <div style={{ margin: '5px -15px 0' }}>
                        <HeaderContainer>
                          <div className="sub-header-left">
                            <a href="/shipment" className="btn-back">
                              <i className="far fa-arrow-left"></i> กลับ
                            </a>
                            <div className="line"></div>
                            <div className="menu-wrapper">
                              <ul>
                                <li>
                                  <a
                                    href={`/shipmentdetail?shipment=${this.state.paramsShipmentId}`}
                                  >
                                    {' '}
                                    <i className="fal fa-truck"></i> ข้อมูลงานขนส่ง
                                  </a>
                                </li>
                                <li>
                                  <a href={`/shipmentcost?shipment=${this.state.paramsShipmentId}`}>
                                    {' '}
                                    <i className="fal fa-sack-dollar"></i> ต้นทุน - ราคาขาย
                                  </a>
                                </li>
                                <li>
                                  <a href={`/shipmentpvc?shipment=${this.state.paramsShipmentId}`}>
                                    {' '}
                                    <i className="fal fa-money-check-alt"></i> รายการสั่งจ่าย
                                  </a>
                                </li>
                                <li className="active">
                                  <a
                                    href={`/shipmentpayment?shipment=${this.state.paramsShipmentId}`}
                                  >
                                    {' '}
                                    <i className="fal fa-file-invoice-dollar"></i> เอกสารการเงิน
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </HeaderContainer>
                        <SubHeaderContainer>
                          <div className="sub-header-left">
                            <strong>
                              {shipment[0].shipment_number
                                ? `รหัสการขนส่ง: ${shipment[0].shipment_number}`
                                : 'แบบร่าง'}
                            </strong>
                          </div>
                          <div className="sub-header-right">
                            {shipment[0].shipment_status != 4 && shipment[0].shipment_status != 5 && (
                              <div style={{ float: 'right', marginLeft: '15px' }}>
                                <Button onClick={() => this.handleShowcancel(shipment[0])}>
                                  {' '}
                                  <i className="fal fa-times"></i> ยกเลิกงานขนส่ง{' '}
                                </Button>
                              </div>
                            )}
                          </div>
                        </SubHeaderContainer>
                      </div>
                      <Card
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <Query
                            query={invoiceBusinessListQuery}
                            variables={{
                              shipment_id: shipment[0]._id,
                            }}
                          >
                            {({ loading, error, data, refetch }) => {
                              if (loading) return null;
                              if (error) return null;
                              // console.log(variables);refetchInvoiceBusinessListQuery
                              const refetchInvoiceBusinessListQuery = refetch;
                              const invoiceBusinessLoading = loading;
                              const invoiceBusinessError = error;
                              const invoiceBusinessList = data.invoiceBusinessList;

                              return (
                                <div className="Row">
                                  {/* รายละเอียดค่าขนส่ง */}
                                  <div className="box-content shipment">
                                    <div className="inner">
                                      <Query
                                        query={invoiceListQuery}
                                        variables={{
                                          shipment_id: shipmentId,
                                        }}
                                      >
                                        {({ loading, error, data, refetch, variables }) => {
                                          if (loading) return 'Loading...';
                                          const invoice = data.invoiceList;
                                          //console.log(invoice);
                                          if (invoice.length > 0) {
                                            return (
                                              <div>
                                                <h5
                                                  style={{
                                                    marginTop: 15,
                                                    marginBottom: 10,
                                                    paddingBottom: 10,
                                                    borderBottom: '1px solid #ddd',
                                                  }}
                                                >
                                                  <b>ค่าใช้จ่ายเพิ่มเติม</b>
                                                </h5>

                                                <RowItem
                                                  className={
                                                    this.state.openAdditionalPayment
                                                      ? 'selected'
                                                      : ''
                                                  }
                                                >
                                                  <div className="left">
                                                    ระบุค่าใช้จ่ายเพิ่มเติม
                                                  </div>
                                                  <div className="right">
                                                    <small
                                                      onClick={() =>
                                                        this.onClickAddInvoiceMore('new')
                                                      }
                                                    >
                                                      เพิ่มรายการ{' '}
                                                      {this.state.openAdditionalPayment ? '<' : '>'}
                                                    </small>
                                                  </div>
                                                </RowItem>

                                                <BlankSpace />

                                                {!invoiceBusinessLoading && !invoiceBusinessError
                                                  ? invoiceBusinessList.invoice != undefined &&
                                                    invoiceBusinessList.invoice.length > 0
                                                    ? invoiceBusinessList.invoice.map(
                                                        (mainInvoice, key) => (
                                                          <AdditionalCostscontainer>
                                                            {console.log(
                                                              'mainInvoice',
                                                              mainInvoice
                                                            )}
                                                            <RowItem>
                                                              <div className="left">
                                                                รหัสใบแจ้งหนี้:
                                                              </div>
                                                              <div className="right">
                                                                {mainInvoice.summary_invoice_id
                                                                  ? mainInvoice.summary_invoice_number
                                                                  : '-'}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">สถานะ:</div>
                                                              <div className="right">
                                                                {mainInvoice.summary_invoice_id
                                                                  ? this.renderStatusPayment(
                                                                      mainInvoice.summary_invoice_paid_status
                                                                    )
                                                                  : this.renderStatusPayment(7)}
                                                              </div>
                                                            </RowItem>
                                                            <Line />
                                                            {/* {console.log("mainInvoice",mainInvoice)} */}
                                                            <RowItem>
                                                              <b>ค่าขนส่ง</b>
                                                            </RowItem>
                                                            {mainInvoice.invoice_list.map(
                                                              (invoice, invoiceKey) =>
                                                                invoice.list_type == 1 && (
                                                                  <RowItem key={invoiceKey}>
                                                                    <div className="left">
                                                                      {invoice.list_name} [
                                                                      {invoice.list_price.toLocaleString(
                                                                        'en-US',
                                                                        { maximumFractionDigits: 2 }
                                                                      )}{' '}
                                                                      x {invoice.list_qty}]
                                                                    </div>
                                                                    <div className="right">{`${invoice.list_total_price.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )} บาท`}</div>
                                                                  </RowItem>
                                                                )
                                                            )}
                                                            {mainInvoice.invoice_list.filter(
                                                              item => item.list_type === 2
                                                            ).length > 0 && <Line />}
                                                            {mainInvoice.invoice_list.filter(
                                                              item => item.list_type === 2
                                                            ).length > 0 && (
                                                              <RowItem key={key}>
                                                                <div className="left">
                                                                  <b>ค่าบริการเสริม</b>
                                                                </div>
                                                              </RowItem>
                                                            )}
                                                            {mainInvoice.invoice_list.map(
                                                              (invoice, key) =>
                                                                invoice.list_type == 2 && (
                                                                  <RowItem key={key}>
                                                                    <div className="left">
                                                                      {invoice.list_name} [
                                                                      {invoice.list_price.toLocaleString(
                                                                        'en-US',
                                                                        { maximumFractionDigits: 2 }
                                                                      )}{' '}
                                                                      x {invoice.list_qty}]
                                                                    </div>
                                                                    <div className="right">{`${invoice.list_total_price.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )} บาท`}</div>
                                                                  </RowItem>
                                                                )
                                                            )}
                                                            {mainInvoice.total_more_price != 0 && (
                                                              <Line />
                                                            )}
                                                            {mainInvoice.total_more_price != 0 && (
                                                              <RowItem key={key}>
                                                                <div className="left">
                                                                  <b>ค่าใช้จ่ายเพิ่มเติม</b>
                                                                </div>
                                                              </RowItem>
                                                            )}
                                                            {mainInvoice.invoice_business_list.map(
                                                              (invoice, key) =>
                                                                invoice.invoice_business_list_type ==
                                                                  5 && (
                                                                  <RowItem key={key}>
                                                                    <div className="left">
                                                                      {
                                                                        invoice.invoice_business_list_detail
                                                                      }{' '}
                                                                      [
                                                                      {invoice.invoice_business_list_price.toLocaleString(
                                                                        'en-US',
                                                                        { maximumFractionDigits: 2 }
                                                                      )}{' '}
                                                                      x{' '}
                                                                      {
                                                                        invoice.invoice_business_list_qty
                                                                      }
                                                                      ]
                                                                    </div>
                                                                    <div className="right">{`${invoice.invoice_business_list_total_price.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )} บาท`}</div>
                                                                  </RowItem>
                                                                )
                                                            )}
                                                            {mainInvoice.summary_invoice_id ==
                                                              null && (
                                                              <RowItem>
                                                                <div className="left"></div>
                                                                <div className="right">
                                                                  <small
                                                                    className={
                                                                      this.chkOpenAdditionalItem(
                                                                        key
                                                                      )
                                                                        ? 'selected'
                                                                        : ''
                                                                    }
                                                                    onClick={() =>
                                                                      this.onClickOpenAdditionalPayment(
                                                                        mainInvoice,
                                                                        key,
                                                                        'mainInvoice'
                                                                      )
                                                                    }
                                                                  >
                                                                    แก้ไข{' '}
                                                                    {this.chkOpenAdditionalItem(key)
                                                                      ? '<'
                                                                      : '>'}
                                                                  </small>
                                                                </div>
                                                              </RowItem>
                                                            )}
                                                            {mainInvoice.paid_credit_card_detail
                                                              .bank != null ||
                                                            mainInvoice.total_more_vat > 0 ||
                                                            mainInvoice.total_more_wht1 > 0 ||
                                                            mainInvoice.total_more_wht3 > 0 ||
                                                            mainInvoice.total_more_wht5 > 0 ? (
                                                              <Line />
                                                            ) : null}

                                                            {mainInvoice.paid_type == 1 && (
                                                              <RowItem>
                                                                <div className="left">
                                                                  ค่าบริการชำระผ่านบัตรเครดิต
                                                                </div>
                                                                <div className="right">
                                                                  {(
                                                                    (mainInvoice.total_more_grand_price /
                                                                      100) *
                                                                      3.25 +
                                                                    (((mainInvoice.total_more_grand_price /
                                                                      100) *
                                                                      3.25) /
                                                                      100) *
                                                                      7
                                                                  ).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                  })}{' '}
                                                                  บาท
                                                                </div>
                                                              </RowItem>
                                                            )}

                                                            {mainInvoice.total_more_vat > 0 && (
                                                              <RowItem>
                                                                <div className="left">
                                                                  ภาษีมูลค่าเพิ่ม (7%)
                                                                </div>
                                                                <div className="right">{`${mainInvoice.total_more_vat.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )} บาท`}</div>
                                                              </RowItem>
                                                            )}

                                                            {mainInvoice.total_more_wht1 > 0 && (
                                                              <RowItem>
                                                                <div className="left">
                                                                  ภาษีหัก ณ ที่จ่าย (1%)
                                                                </div>
                                                                <div className="right">{`${mainInvoice.total_more_wht1.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )} บาท`}</div>
                                                              </RowItem>
                                                            )}

                                                            {mainInvoice.total_more_wht3 > 0 && (
                                                              <RowItem>
                                                                <div className="left">
                                                                  ภาษีหัก ณ ที่จ่าย (3%)
                                                                </div>
                                                                <div className="right">{`${mainInvoice.total_more_wht3.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )} บาท`}</div>
                                                              </RowItem>
                                                            )}

                                                            {mainInvoice.total_more_wht5 > 0 && (
                                                              <RowItem>
                                                                <div className="left">
                                                                  ภาษีหัก ณ ที่จ่าย (5%)
                                                                </div>
                                                                <div className="right">{`${mainInvoice.total_more_wht5.toLocaleString(
                                                                  'en-US',
                                                                  { maximumFractionDigits: 2 }
                                                                )} บาท`}</div>
                                                              </RowItem>
                                                            )}
                                                            <Line />
                                                            <RowItem>
                                                              <div className="left">
                                                                ที่ต้องชำระ
                                                              </div>
                                                              <div className="right">
                                                                <span className="total black">{`${(
                                                                  mainInvoice.total_more_grand_price +
                                                                  mainInvoice.total_price
                                                                ).toLocaleString('en-US', {
                                                                  maximumFractionDigits: 2,
                                                                })} บาท`}</span>
                                                              </div>
                                                            </RowItem>
                                                          </AdditionalCostscontainer>
                                                        )
                                                      )
                                                    : null
                                                  : null}

                                                {!invoiceBusinessLoading && !invoiceBusinessError
                                                  ? invoiceBusinessList.invoice_more != undefined &&
                                                    invoiceBusinessList.invoice_more.length > 0
                                                    ? invoiceBusinessList.invoice_more.map(
                                                        (additionalCost, key) => (
                                                          <div>
                                                            <BlankSpace />
                                                            <AdditionalCostscontainer>
                                                              {console.log(
                                                                'additionalCost',
                                                                additionalCost
                                                              )}
                                                              <RowItem>
                                                                <div className="left">
                                                                  รหัสใบเพิ่มหนี้:
                                                                </div>
                                                                <div className="right">
                                                                  {additionalCost.debit_note_id
                                                                    ? additionalCost.debit_note_number
                                                                    : '-'}
                                                                </div>
                                                              </RowItem>
                                                              <RowItem>
                                                                <div className="left">สถานะ:</div>
                                                                <div className="right">
                                                                  {additionalCost.debit_note_id
                                                                    ? this.renderStatusPayment(
                                                                        additionalCost.debit_note_paid_status
                                                                      )
                                                                    : this.renderStatusPayment(6)}
                                                                </div>
                                                              </RowItem>
                                                              <Line />
                                                              {additionalCost.invoice_business_list.map(
                                                                (invoice, key) => (
                                                                  <RowItem key={key}>
                                                                    <div className="left">
                                                                      {
                                                                        invoice.invoice_business_list_detail
                                                                      }{' '}
                                                                      [
                                                                      {
                                                                        invoice.invoice_business_list_price
                                                                      }{' '}
                                                                      x{' '}
                                                                      {
                                                                        invoice.invoice_business_list_qty
                                                                      }
                                                                      ]
                                                                    </div>
                                                                    <div className="right">{`${invoice.invoice_business_list_total_price.toLocaleString(
                                                                      'en-US',
                                                                      { maximumFractionDigits: 2 }
                                                                    )} บาท`}</div>
                                                                  </RowItem>
                                                                )
                                                              )}
                                                              {additionalCost.debit_note_id ==
                                                                null && (
                                                                <RowItem>
                                                                  <div className="left"></div>
                                                                  <div className="right">
                                                                    <small
                                                                      className={
                                                                        this.chkOpenAdditionalItem(
                                                                          key
                                                                        )
                                                                          ? 'selected'
                                                                          : ''
                                                                      }
                                                                      onClick={() =>
                                                                        this.onClickOpenAdditionalPayment(
                                                                          additionalCost,
                                                                          key,
                                                                          'additionalCost'
                                                                        )
                                                                      }
                                                                    >
                                                                      แก้ไข{' '}
                                                                      {this.chkOpenAdditionalItem(
                                                                        key
                                                                      )
                                                                        ? '<'
                                                                        : '>'}
                                                                    </small>
                                                                  </div>
                                                                </RowItem>
                                                              )}
                                                              {additionalCost.total_vat > 0 ||
                                                              additionalCost.total_wht1 > 0 ||
                                                              additionalCost.total_wht3 > 0 ||
                                                              additionalCost.total_wht5 > 0 ? (
                                                                <Line />
                                                              ) : null}

                                                              {additionalCost.total_vat > 0 && (
                                                                <RowItem>
                                                                  <div className="left">
                                                                    ภาษีมูลค่าเพิ่ม (7%)
                                                                  </div>
                                                                  <div className="right">{`${additionalCost.total_vat.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )} บาท`}</div>
                                                                </RowItem>
                                                              )}

                                                              {additionalCost.total_wht1 > 0 && (
                                                                <RowItem>
                                                                  <div className="left">
                                                                    ภาษีหัก ณ ที่จ่าย (1%)
                                                                  </div>
                                                                  <div className="right">{`${additionalCost.total_wht1.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )} บาท`}</div>
                                                                </RowItem>
                                                              )}

                                                              {additionalCost.total_wht3 > 0 && (
                                                                <RowItem>
                                                                  <div className="left">
                                                                    ภาษีหัก ณ ที่จ่าย (3%)
                                                                  </div>
                                                                  <div className="right">{`${additionalCost.total_wht3.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )} บาท`}</div>
                                                                </RowItem>
                                                              )}

                                                              {additionalCost.total_wht5 > 0 && (
                                                                <RowItem>
                                                                  <div className="left">
                                                                    ภาษีหัก ณ ที่จ่าย (5%)
                                                                  </div>
                                                                  <div className="right">{`${additionalCost.total_wht5.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )} บาท`}</div>
                                                                </RowItem>
                                                              )}
                                                              <Line />
                                                              <RowItem>
                                                                <div className="left">
                                                                  ที่ต้องชำระ
                                                                </div>
                                                                <div className="right">
                                                                  <span className="total black">{`${additionalCost.invoice_business_grand_total_price.toLocaleString(
                                                                    'en-US',
                                                                    { maximumFractionDigits: 2 }
                                                                  )} บาท`}</span>
                                                                </div>
                                                              </RowItem>
                                                            </AdditionalCostscontainer>
                                                          </div>
                                                        )
                                                      )
                                                    : null
                                                  : null}
                                              </div>
                                            );
                                          } else {
                                            if (shipment[0].paid_type === 3) {
                                              return (
                                                <RowItem>
                                                  <div className="left">ออกใบแจ้งหนี้</div>
                                                </RowItem>
                                              );
                                            }
                                            return (
                                              <RowItem>
                                                <div className="left">
                                                  ค่าบริการขนส่งไม่มีเนื่องจากเป็นแบบร่าง
                                                </div>
                                              </RowItem>
                                            );
                                          }
                                        }}
                                      </Query>
                                    </div>
                                  </div>

                                  <Mutation
                                    mutation={invoiceMoreAddMutation}
                                    variables={{
                                      shipment_id: shipment[0]._id,
                                      category: this.state.additionnalCostCategory.label,
                                      detail: this.state.additionnalCostSubCategory.label,
                                      price:
                                        this.state.additionnalCostCategory.value ==
                                        '5d4009ec9799b1f96549d21e'
                                          ? parseFloat(this.state.additionnalCostPrice) * -1
                                          : parseFloat(this.state.additionnalCostPrice),
                                      qty: parseFloat(this.state.additionnalCostQty),
                                      vat: parseInt(this.state.additionnalCostPriceVat.label),
                                      wht: parseInt(this.state.additionnalCostPriceWht.label),
                                      tax_type: this.state.tax_type,
                                    }}
                                    update={() => {
                                      this.setState(
                                        {
                                          selectedAdditionalItemKey: null,
                                          openAdditionalPayment: false,
                                          showDeleteModal: false,
                                          openAdditionalPaymentList: false,
                                        },
                                        () => refetchInvoiceBusinessListQuery()
                                      );
                                    }}
                                  >
                                    {invoiceMoreAdd => (
                                      <Mutation
                                        mutation={invoiceMoreUpdateMutation}
                                        variables={this.renderVariablesInvoiceMoreUpdate()}
                                        update={() => {
                                          this.setState(
                                            {
                                              openAdditionalPayment: false,
                                              showDeleteModal: false,
                                              openAdditionalPaymentList: false,
                                              selectedAdditionalItemKey: null,
                                            },
                                            () => refetchInvoiceBusinessListQuery()
                                          );
                                        }}
                                      >
                                        {invoiceMoreUpdate => (
                                          <div>
                                            {/* รายการค่าใช้จ่ายเพิ่มเติม */}
                                            {this.state.openAdditionalPaymentList ||
                                            this.state.openAdditionalPayment ? (
                                              <div className="wraper-additionalpayment">
                                                {this.state.openAdditionalPaymentList && (
                                                  <div className="inner-left">
                                                    <div className="header">
                                                      <h5>
                                                        <b>รายการค่าใช้จ่ายเพิ่มเติม</b>{' '}
                                                        <button
                                                          className="btn btn-link"
                                                          style={{
                                                            float: 'right',
                                                            marginTop: '-5px',
                                                          }}
                                                          onClick={() =>
                                                            this.setState({
                                                              openAdditionalPaymentList: false,
                                                              selectedAdditionalItemKey: null,
                                                            })
                                                          }
                                                          disabled={
                                                            this.state.openAdditionalPayment
                                                              ? 'disabled'
                                                              : false
                                                          }
                                                        >
                                                          <i className="fal fa-times"></i>
                                                        </button>
                                                      </h5>
                                                      <hr style={{ margin: '0 0 10px' }} />
                                                    </div>

                                                    <div className="body-main">
                                                      <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        style={{
                                                          width: '100%',
                                                          marginBottom: '15px',
                                                        }}
                                                        onClick={() =>
                                                          this.onClickAddInvoiceMore('newsub')
                                                        }
                                                      >
                                                        {' '}
                                                        <i className="fal fa-plus-circle"></i>{' '}
                                                        เพิ่มรายการ
                                                      </button>
                                                      <div className="box-item">
                                                        {this.state.selectedAdditionalItem
                                                          .invoice_business_list.length > 0
                                                          ? this.state.selectedAdditionalItem.invoice_business_list.map(
                                                              (prop, key) =>
                                                                prop.invoice_business_list_type ==
                                                                  5 && (
                                                                  <RowItemList className="">
                                                                    <div
                                                                      className="icon-left"
                                                                      onClick={() =>
                                                                        this.onClickUpdateInvoiceMore(
                                                                          prop,
                                                                          'delete',
                                                                          this.state
                                                                            .selectedAdditionalItem
                                                                            ._id
                                                                        )
                                                                      }
                                                                    >
                                                                      <i className="far fa-trash-alt"></i>
                                                                    </div>
                                                                    <div className="left">
                                                                      <b>
                                                                        {
                                                                          prop.invoice_business_list_detail
                                                                        }
                                                                      </b>
                                                                      <small>
                                                                        {parseFloat(
                                                                          prop.invoice_business_list_total_price
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })}{' '}
                                                                        บาท
                                                                      </small>
                                                                    </div>
                                                                    {/* <div className="icon-right" onClick={() => this.onClickUpdateInvoiceMore(prop , "edit")}> 
                                                                                                                                    <i className="fal fa-chevron-right"></i>
                                                                                                                                </div> */}
                                                                  </RowItemList>
                                                                )
                                                            )
                                                          : null}
                                                        <Modal show={this.state.showDeleteModal}>
                                                          <Modal.Body>
                                                            <ModalContent>
                                                              <div className="content">
                                                                <h5 style={{ marginBottom: '0' }}>
                                                                  <b>
                                                                    คุณต้องการลบรายการค่าใช้จ่ายใช่หรือไม่
                                                                  </b>
                                                                </h5>
                                                                <p>
                                                                  กรุณาตรวจสอบความถูกต้องก่อนกดยืนยัน
                                                                </p>
                                                                <p style={{ marginTop: 10 }}>
                                                                  <div className="detail">
                                                                    <div className="left">
                                                                      {this.state.DeleteItemName}
                                                                    </div>
                                                                    <div className="right">
                                                                      {parseFloat(
                                                                        this.state.DeleteItemPrice
                                                                      ).toLocaleString('en-US', {
                                                                        maximumFractionDigits: 2,
                                                                      })}{' '}
                                                                      บาท
                                                                    </div>
                                                                  </div>
                                                                </p>
                                                              </div>

                                                              <div className="footer">
                                                                <button
                                                                  className="btn btn-grey"
                                                                  style={{ width: '100px' }}
                                                                  onClick={() =>
                                                                    this.setState({
                                                                      showDeleteModal: false,
                                                                    })
                                                                  }
                                                                >
                                                                  ปิด
                                                                </button>
                                                                <button
                                                                  className="btn btn-red"
                                                                  style={{ width: '100px' }}
                                                                  onClick={() =>
                                                                    invoiceMoreUpdate()
                                                                  }
                                                                >
                                                                  ยืนยัน
                                                                </button>
                                                              </div>
                                                            </ModalContent>
                                                          </Modal.Body>
                                                        </Modal>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}

                                                {this.state.openAdditionalPayment && (
                                                  <div
                                                    className={
                                                      this.state.invoiceMoreMode == 'new'
                                                        ? 'inner-left'
                                                        : 'inner-right'
                                                    }
                                                  >
                                                    <div className="header">
                                                      <h5>
                                                        <b>รายละเอียด</b>{' '}
                                                        <button
                                                          className="btn btn-link"
                                                          style={{
                                                            float: 'right',
                                                            marginTop: '-5px',
                                                          }}
                                                          onClick={() =>
                                                            this.setState({
                                                              openAdditionalPayment: false,
                                                            })
                                                          }
                                                        >
                                                          <i className="fal fa-times"></i>
                                                        </button>
                                                      </h5>
                                                      <hr style={{ margin: '0 0 10px' }} />
                                                    </div>

                                                    <div className="body">
                                                      <Query query={expensesCategoryListQuery}>
                                                        {({ loading, error, data }) => {
                                                          if (loading) return 'loading...';
                                                          // if(error) return null;
                                                          // //console.log(variables);
                                                          const CategoryList =
                                                            data.expensesCategoryList;
                                                          console.log(CategoryList);
                                                          return (
                                                            <div className="box-item">
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  หมวดค่าใช้จ่าย*
                                                                </TextControlLabel>
                                                                <Select
                                                                  name={`additionnalCostCategory`}
                                                                  value={
                                                                    this.state
                                                                      .additionnalCostCategory
                                                                  }
                                                                  onChange={event =>
                                                                    this.handleChangeAdditionnalCostCategory(
                                                                      CategoryList,
                                                                      event
                                                                    )
                                                                  }
                                                                  options={this.renderAdditionnalCostCategory(
                                                                    CategoryList
                                                                  )}
                                                                />
                                                              </FormGroup>
                                                              {this.state.additionnalCostCategory
                                                                .value ==
                                                              '5d4009ec9799b1f96549d21e' ? (
                                                                <FormGroup controlId="formBasicText">
                                                                  <TextControlLabel>
                                                                    รายการ*
                                                                  </TextControlLabel>
                                                                  <FormControl
                                                                    type="text"
                                                                    value={
                                                                      this.state
                                                                        .additionnalCostSubCategory
                                                                        .label
                                                                    }
                                                                    placeholder="ระบุ"
                                                                    onChange={e =>
                                                                      this.setState({
                                                                        additionnalCostSubCategory: {
                                                                          value: e.target.value,
                                                                          label: e.target.value,
                                                                        },
                                                                      })
                                                                    }
                                                                  />
                                                                </FormGroup>
                                                              ) : (
                                                                <FormGroup controlId="formBasicText">
                                                                  <TextControlLabel>
                                                                    รายการ*
                                                                  </TextControlLabel>
                                                                  <Select
                                                                    name={`additionnalCostSubCategory`}
                                                                    value={
                                                                      this.state
                                                                        .additionnalCostSubCategory
                                                                    }
                                                                    onChange={event =>
                                                                      this.handleChangeAdditionnalCostSubCategory(
                                                                        CategoryList,
                                                                        event
                                                                      )
                                                                    }
                                                                    options={this.renderAdditionnalCostSubCategory(
                                                                      CategoryList
                                                                    )}
                                                                  />
                                                                </FormGroup>
                                                              )}
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  จำนวน*
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="number"
                                                                  min="0"
                                                                  value={
                                                                    this.state.additionnalCostQty
                                                                  }
                                                                  placeholder="ระบุ"
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      additionnalCostQty:
                                                                        e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  ราคาต่อหน่วย*
                                                                </TextControlLabel>
                                                                <FormControl
                                                                  type="number"
                                                                  min="0"
                                                                  value={
                                                                    this.state.additionnalCostPrice
                                                                  }
                                                                  placeholder="ระบุ"
                                                                  onChange={e =>
                                                                    this.setState({
                                                                      additionnalCostPrice:
                                                                        e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  ภาษีมูลค่าเพิ่ม (บาท)*
                                                                </TextControlLabel>
                                                                <Select
                                                                  name={`additionnalCostPriceVat`}
                                                                  value={
                                                                    this.state
                                                                      .additionnalCostPriceVat
                                                                  }
                                                                  onChange={event =>
                                                                    this.handleChangeadditionnalCostPriceVat(
                                                                      event
                                                                    )
                                                                  }
                                                                  options={vatList}
                                                                  isDisabled={
                                                                    this.state.disableTpyeDiscount
                                                                  }
                                                                />
                                                              </FormGroup>
                                                              <FormGroup controlId="formBasicText">
                                                                <TextControlLabel>
                                                                  ภาษีหัก ณ ที่จ่าย (บาท)*
                                                                </TextControlLabel>
                                                                <Select
                                                                  name={`additionnalCostPriceWht`}
                                                                  value={
                                                                    this.state
                                                                      .additionnalCostPriceWht
                                                                  }
                                                                  onChange={event =>
                                                                    this.handleChangeadditionnalCostPriceWht(
                                                                      event
                                                                    )
                                                                  }
                                                                  options={WhtList}
                                                                  isDisabled={
                                                                    this.state.disableTpyeDiscount
                                                                  }
                                                                />
                                                              </FormGroup>
                                                            </div>
                                                          );
                                                        }}
                                                      </Query>
                                                    </div>

                                                    <div className="footer">
                                                      <button
                                                        type="button"
                                                        className="btn btn-default"
                                                        onClick={() =>
                                                          this.setState({
                                                            openAdditionalPayment: false,
                                                          })
                                                        }
                                                      >
                                                        {' '}
                                                        ปิด{' '}
                                                      </button>
                                                      <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        style={{ marginLeft: '20px' }}
                                                        disabled={this.checkDisableAddCost()}
                                                        onClick={() =>
                                                          this.state.invoiceMoreMode == 'new' ||
                                                          this.state.invoiceMoreMode == 'newsub'
                                                            ? invoiceMoreAdd()
                                                            : invoiceMoreUpdate()
                                                        }
                                                      >
                                                        {' '}
                                                        บันทึก{' '}
                                                      </button>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                        )}
                                      </Mutation>
                                    )}
                                  </Mutation>
                                </div>
                              );
                            }}
                          </Query>
                        }
                      />

                      <Mutation
                        mutation={cancelShipment}
                        variables={this.renderVariablesCancelShipment(shipment[0])}
                        update={() => {
                          this.setState({ show_cancel: false }, () => {
                            refetch();
                          });
                        }}
                      >
                        {cancelShipment => (
                          <Modal
                            show={this.state.show_cancel}
                            onHide={() => this.setState({ show_cancel: false })}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                ยกเลิกงานขนส่ง {shipment[0].shipment_number}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row
                                style={{ marginBottom: '10px', borderBottom: '1px dashed #ddd' }}
                              >
                                {this.renderShipmentCancelError(shipment[0])}
                              </Row>

                              <Row style={{ marginBottom: '10px' }}>
                                <Col md={12}>
                                  <b>
                                    เหตุผลที่ยกเลิกงาน <small style={{ color: '#D90101' }}>*</small>
                                  </b>
                                </Col>
                              </Row>

                              <Select
                                name={`driver_select`}
                                value={this.state.rejectSelected}
                                onChange={event => this.handleChangeReject(event)}
                                options={rejectList}
                                placeholder="เลือกเหตุผลที่ยกเลิกงาน"
                              />

                              {this.state.showRejectDetail && (
                                <FormControl
                                  style={{ marginTop: '10px' }}
                                  type="text"
                                  value={this.state.rejectDetail}
                                  placeholder="ระบุ"
                                  onChange={event =>
                                    this.setState({ rejectDetail: event.target.value })
                                  }
                                  name="rejectDetail"
                                />
                              )}

                              <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <Col md={12}>
                                  <b>
                                    จำนวนเงินที่ต้องการคืน (บาท)
                                    <small style={{ color: '#D90101' }}>*</small>
                                  </b>
                                </Col>
                              </Row>

                              <FormControl
                                type="number"
                                value={this.state.refundPrice}
                                max={this.state.shipmentGrandTotal}
                                placeholder="0.00"
                                onChange={event => this.checkMaxPrice(event.target.value)}
                                name="refundPrice"
                              />

                              <Row style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <Col md={12}>
                                  <b>
                                    กรอกหมายเลขงานขนส่งที่ยกเลิกงาน
                                    <small style={{ color: '#D90101' }}>*</small>
                                  </b>
                                </Col>
                              </Row>

                              <FormControl
                                type="text"
                                value={this.state.rejectNumber}
                                placeholder="19040079"
                                onChange={event =>
                                  this.setState({ rejectNumber: event.target.value })
                                }
                                name="rejectNumber"
                              />

                              <Row style={{ marginTop: '10px' }}>
                                <Col md={12}>คุณต้องการยกเลิกงานขนส่งนี้ให้กด "ตกลง"</Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                style={{ float: 'left' }}
                                onClick={() => this.setState({ show_cancel: false })}
                              >
                                กลับ
                              </Button>
                              <Button
                                bsStyle="primary"
                                disabled={this.checkDisableCancel()}
                                onClick={() =>
                                  this.handlecheckcancel(shipment[0], cancelShipment())
                                }
                              >
                                ตกลง
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </Mutation>

                      <Mutation
                        mutation={editpickupDate}
                        variables={{
                          shipmentID: shipmentId,
                          pick_up_date: this.state.pick_up_date_show,
                        }}
                        update={() => {
                          this.setState({ openEditPickupdate: false }, () => {
                            refetch();
                          });
                        }}
                      >
                        {editpickupDate => (
                          <Modal
                            show={this.state.openEditPickupdate}
                            onHide={() => this.setState({ openEditPickupdate: false })}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>
                                แก้ไขวันเริ่มงาน {shipment[0].shipment_number}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col md={12}>
                                  <b>เลือกวัน-เวลา ที่เริ่มต้นงาน</b>
                                </Col>
                              </Row>
                              <Datetime
                                value={this.state.pick_up_date}
                                dateFormat="DD/MM/YYYY"
                                timeFormat="HH:mm"
                                timeConstraints={{ minutes: { step: 5 } }}
                                onChange={event => this.handleDatePicker(event)}
                              />

                              <Row style={{ marginTop: '10px' }}>
                                <Col md={12}>
                                  คุณต้องการแก้ไขวันเริ่มงาน งานขนส่งนี้ให้กด "ตกลง"
                                </Col>
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                style={{ float: 'left' }}
                                onClick={() => this.setState({ openEditPickupdate: false })}
                              >
                                กลับ
                              </Button>
                              <Button
                                bsStyle="primary"
                                disabled={this.state.pick_up_date == ''}
                                onClick={() => editpickupDate()}
                              >
                                ตกลง
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </Mutation>

                      {this.state.openEditContactModal && (
                        <Mutation
                          mutation={editShipment}
                          variables={this.renderVariablesShipment(shipment[0])}
                          update={() => {
                            this.setState({ openEditContactModal: false }, () => {
                              refetch();
                            });
                          }}
                        >
                          {editShipment => (
                            <Modal
                              show={this.state.openEditContactModal}
                              onHide={() => this.setState({ openEditContactModal: false })}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  แก้ไขข้อมูลติดต่อ {shipment[0].shipment_number}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                {shipment[0].shipment_address.map((item, index) => (
                                  <Row
                                    style={{
                                      marginBottom: '20px',
                                      paddingBottom: '20px',
                                      borderBottom: '1px solid #dddddd',
                                    }}
                                  >
                                    <strong style={{ marginLeft: '15px' }}>
                                      {this.renderAddressMode(item.mode, index)}
                                    </strong>
                                    <Col style={{ margin: '10px 0' }} md={12}>
                                      ชื่อผู้ติดต่อ :{' '}
                                    </Col>
                                    <Col md={12}>
                                      <FormControl
                                        type="text"
                                        value={this.state.addressObj[index].contact_name}
                                        placeholder="ชื่อผู้ติดต่อ"
                                        onChange={event =>
                                          this.setValueContactName(index, event.target.value)
                                        }
                                        name="pickup_contact_name"
                                      />
                                    </Col>
                                    <Col style={{ margin: '10px 0' }} md={12}>
                                      เบอร์โทรติดต่อ :{' '}
                                    </Col>
                                    <Col md={12}>
                                      <FormControl
                                        type="text"
                                        value={this.state.addressObj[index].contact_tel}
                                        placeholder="เบอร์โทรติดต่อ"
                                        onChange={event =>
                                          this.setValueContactTel(index, event.target.value)
                                        }
                                        name="pickup_contact_tel"
                                      />
                                    </Col>
                                    <Col style={{ margin: '10px 0' }} md={12}>
                                      หมายเหตุ :{' '}
                                    </Col>
                                    <Col md={12}>
                                      <FormControl
                                        componentclassName="textarea"
                                        type="text"
                                        value={this.state.addressObj[index].remark}
                                        placeholder="หมายเหตุ "
                                        onChange={event =>
                                          this.setValueContactRemark(index, event.target.value)
                                        }
                                        name="pickup_remark"
                                      />
                                    </Col>
                                  </Row>
                                ))}
                                <Row>
                                  <Col style={{ margin: ' 0 0 10px' }} md={12}>
                                    <b>หมายเหตุ</b>
                                  </Col>
                                  <Col md={12}>
                                    <FormControl
                                      componentclassName="textarea"
                                      type="text"
                                      value={this.state.remark}
                                      placeholder="หมายเหตุ "
                                      onChange={event =>
                                        this.setState({ remark: event.target.value })
                                      }
                                      name="remark"
                                    />
                                  </Col>
                                </Row>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  style={{ float: 'left' }}
                                  onClick={() => this.setState({ openEditContactModal: false })}
                                >
                                  กลับ
                                </Button>
                                <Button
                                  bsStyle="primary"
                                  disabled={this.chkEditShipment()}
                                  onClick={() => editShipment()}
                                >
                                  ตกลง
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>
                      )}
                    </Col>
                  );
                }}
              </Query>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
}

export default compose(graphql(dateNowQuery, { name: 'dateNow' }))(ShipmentDetail);
