import { Grid, Row, Col, Tooltip, Button, Modal, Table } from 'react-bootstrap';

import React from 'react';
import { Select, Input, Label } from 'views/GiztixBusiness/components';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import datamasterServiceCategoryList from 'views/KaCustomer/screens/ShipmentCosting/graphql/query/datamasterServiceCategoryType';
import formatCurrency from 'utils/formatCurrency';
import { client } from 'index';

const TableEditCostDetail = styled(Table)`
  & td {
    border-top: none !important;
    text-align: right;
    padding: 5px 8px !important;
  }

  & td:first-child {
    text-align: left;
  }

  &.table > tbody > tr > td {
    padding: 5px 8px !important;
  }

  & .border-top td {
    border-top: 1px solid #dddddd !important;
  }
`;

const RenderMainCategory = Category => {
  let defaultValue = [{ value: '', label: '-- เลือก --' }];
  const list = Category.map(fee => {
    return {
      value: fee._id,
      label: `${fee.prefix}000 - ${fee.truck_type_prefix} / ${fee.service_category_name.th}`,
      // prefix: fee.prefix,
      // service_category_name: fee.service_category_name.th,
      // truck_type_prefix: fee.truck_type_prefix,
      // datamaster_job_type_id: fee.job_type_id,
      // truck_type_id: fee.truck_type_id,
      // truck_type_prefix_id: fee.truck_type_prefix_id,
      // datamaster_service_category_id: fee._id,
      // datamaster_service_category_prefix_id: fee.setting_prefix_id,
      // datamaster_service_item: fee.datamaster_service_item,
    };
  });
  return defaultValue.concat(list);
};

const RenderSubCategory = mainCategory => {
  let list = [{ value: '', label: '-- เลือก --' }];

  if (mainCategory?.datamaster_service_item?.length > 0) {
    list = [
      ...list,
      ...mainCategory?.datamaster_service_item?.map(fee => {
        return {
          value: fee._id,
          label: `${mainCategory?.prefix}${fee.datamaster_service_item_number} - ${fee.service_item_name.th}`,
          // service_item_name: fee.service_item_name.th,
          // detail: fee,
        };
      }),
    ];
  } else {
    list = [{ value: '', label: '-- เลือก --' }];
  }

  return list;
};

const CostPerPacelModalCreateParcel = ({
  open,
  setOpen,
  isNew,
  truckId,
  setDataPrepair,
  editIndex,
}) => {
  const [mainCategory, setMainCategory] = React.useState('');

  const [mainSelect, setMainSelect] = React.useState('');

  const [subCategory, setSubCategory] = React.useState('');

  const [subSelect, setSubSelect] = React.useState('');

  const [value, setValue] = React.useState({
    qty: '',
    price: '',
    vat: '',
    wht: '',
  });

  React.useEffect(() => {
    FunctionResetState();
    if (!isNew) {
      FunctionLoadForEditData();
    } else {
      return;
    }
  }, [open, editIndex]);

  const FunctionLoadForEditData = async () => {
    try {
      const { data } = await client.query({
        query: datamasterServiceCategoryList,
        variables: {
          truck_type_id: truckId,
        },
      });

      const findMain = data.datamasterServiceCategoryList.find(d => {
        return d.prefix === editIndex?.item?.prefix;
      });

      if (findMain) {
        const findSub = findMain?.datamaster_service_item?.find(d => {
          return d.datamaster_service_item_number === editIndex?.item?.suffix;
        });

        // console.log('FIND DATA : ', {
        //   editIndex,
        //   data,
        //   main: findMain,
        //   sub: findSub,
        // });

        setMainSelect(findMain._id);
        setMainCategory({
          ...findMain,
          value: findMain._id,
          label: `${findMain.prefix}000 - ${findMain.truck_type_prefix} / ${findMain.service_category_name.th}`,
          prefix: findMain.prefix,
          service_category_name: findMain.service_category_name.th,
          truck_type_prefix: findMain.truck_type_prefix,
          datamaster_job_type_id: findMain.job_type_id,
          truck_type_id: findMain.truck_type_id,
          truck_type_prefix_id: findMain.truck_type_prefix_id,
          datamaster_service_category_id: findMain._id,
          datamaster_service_category_prefix_id: findMain.setting_prefix_id,
          datamaster_service_item: findMain.datamaster_service_item,
        });
        setSubSelect(findSub._id);
        setSubCategory({
          ...findSub,
          value: findSub._id,
          label: `${findMain?.prefix}${findSub.datamaster_service_item_number} - ${findSub.service_item_name.th}`,
          service_item_name: findSub.service_item_name.th,
          detail: findSub,
        });
        setValue({
          qty: editIndex.item.qty,
          price: editIndex.item.price,
          vat: editIndex.item.vat,
          wht: editIndex.item.wht,
        });
      }
    } catch (error) {
      console.error('FunctionLoadForEditData : ', FunctionLoadForEditData);
    }
  };

  const FunctionGetNumberFromString = input => {
    try {
      return input.replace(/[^0-9]/g, '');
    } catch (error) {
      console.error('FunctionGetNumberFromString : ', error);
    }
  };

  const FunctionResetState = () => {
    setMainCategory({
      value: '',
      label: '-- เลือก --',
    });
    setMainSelect('');
    setSubCategory({
      value: '',
      label: '-- เลือก --',
    });
    setValue({
      qty: '',
      price: '',
      vat: '',
      wht: '',
    });
  };

  if (open) {
    return (
      <Query
        query={datamasterServiceCategoryList}
        variables={{
          truck_type_id: truckId,
        }}
      >
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          let total_before_discount = Number(Number(value?.price) * Number(value?.qty));
          let cal_wht = Number(total_before_discount) * (Number(value?.wht) / 100);

          let cal_tax = Number(total_before_discount) * (Number(value?.vat) / 100);

          let total_after_discount =
            Number(total_before_discount) + Number(cal_tax) - Number(cal_wht);

          console.log('DATA : ', {
            main: mainSelect,
            mainData: mainCategory,
            sub: RenderSubCategory(mainCategory),
            subSelected: subSelect,
            subData: subCategory,
            value,
            total_before_discount,
            cal_wht,
            cal_tax,
            total_after_discount,
          });

          return (
            <Modal
              dialogClassName="modal-dialog-size"
              show={open}
              backdrop="static"
              onHide={() => setOpen(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>{isNew ? 'เพื่มรายการ' : 'แก้ไขรายการ'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={8}>
                    <Label
                      style={{
                        marginTop: 10,
                      }}
                      required
                    >
                      หมวดหมู่
                    </Label>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      value={mainSelect}
                      onChange={event => {
                        setMainSelect(event?.target?.value);

                        const findMain = data.datamasterServiceCategoryList.find(d => {
                          return d._id === event?.target?.value;
                        });

                        if (findMain) {
                          setMainCategory({
                            ...findMain,
                            value: findMain._id,
                            label: `${findMain.prefix}000 - ${findMain.truck_type_prefix} / ${findMain.service_category_name.th}`,
                            prefix: findMain.prefix,
                            service_category_name: findMain.service_category_name.th,
                            truck_type_prefix: findMain.truck_type_prefix,
                            datamaster_job_type_id: findMain.job_type_id,
                            truck_type_id: findMain.truck_type_id,
                            truck_type_prefix_id: findMain.truck_type_prefix_id,
                            datamaster_service_category_id: findMain._id,
                            datamaster_service_category_prefix_id: findMain.setting_prefix_id,
                            datamaster_service_item: findMain.datamaster_service_item,
                          });
                        }
                      }}
                    >
                      {RenderMainCategory(data?.datamasterServiceCategoryList).map((row, index) => {
                        return (
                          <option key={`key-of-data-${index}`} value={row?.value}>
                            {row?.label}
                          </option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col md={4}>
                    <Input
                      onChange={event =>
                        setValue({
                          ...value,
                          price: FunctionGetNumberFromString(event.target.value),
                        })
                      }
                      value={value.price}
                      style={{ marginTop: 10 }}
                      inputLabel="ราคาต้นทุน"
                      placeholder="0.00"
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Label
                      style={{
                        marginTop: 10,
                      }}
                      required
                    >
                      รายการค่าใช้จ่าย
                    </Label>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      value={subSelect}
                      onChange={event => {
                        setSubSelect(event?.target?.value);

                        const findSub = mainCategory?.datamaster_service_item?.find(d => {
                          return d._id === event?.target?.value;
                        });

                        if (findSub) {
                          setSubCategory({
                            ...findSub,
                            value: findSub._id,
                            label: `${mainCategory?.prefix}${findSub.datamaster_service_item_number} - ${findSub.service_item_name.th}`,
                            service_item_name: findSub.service_item_name.th,
                            detail: findSub,
                          });
                          setValue({
                            ...value,
                            vat: findSub.vat || 0,
                            wht: findSub.wht || 0,
                            qty: findSub.qty || 1,
                            price: findSub.price || 0,
                          });
                        }
                      }}
                    >
                      {RenderSubCategory(mainCategory).map((row, index) => {
                        return (
                          <option key={`key-of-sub-data-${index}`} value={row?.value}>
                            {row?.label}
                          </option>
                        );
                      })}
                    </Select>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Input
                      onChange={event =>
                        setValue({
                          ...value,
                          qty: FunctionGetNumberFromString(event.target.value),
                        })
                      }
                      value={value.qty}
                      style={{ marginTop: 15 }}
                      inputLabel="จำนวน"
                      placeholder="0.00"
                      required
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      onChange={event =>
                        setValue({
                          ...value,
                          vat: FunctionGetNumberFromString(event?.target?.value),
                        })
                      }
                      value={value.vat}
                      style={{ marginTop: 10 }}
                      inputLabel="ภาษีมูลค่าเพิ่ม"
                      placeholder="0.00"
                      required
                      // type="number"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={8}></Col>
                  <Col md={4}>
                    <Input
                      onChange={event =>
                        setValue({
                          ...value,
                          wht: FunctionGetNumberFromString(event?.target?.value),
                        })
                      }
                      value={value.wht}
                      style={{ marginTop: 10 }}
                      inputLabel="ภาษีหัก ณ ที่จ่าย"
                      placeholder="0.00"
                      required
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: 10,
                    borderTop: '1px solid #e9ecef',
                  }}
                />

                <Row>
                  <Col md={12}>
                    <TableEditCostDetail>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            <b>ราคาต้นทุน</b>
                          </td>
                        </tr>
                        <tr>
                          <td>ยอดก่อนภาษี</td>
                          <td>{formatCurrency(total_before_discount)} บาท</td>
                        </tr>
                        <tr>
                          <td>ภาษีมูลค่าเพิ่ม VAT({value?.vat || 0}%)</td>
                          <td>{formatCurrency(cal_tax)} บาท</td>
                        </tr>
                        <tr>
                          <td>ภาษีหัก ณ ที่จ่าย WHT({value?.wht || 0}%)</td>
                          <td>{formatCurrency(cal_wht)} บาท</td>
                        </tr>

                        <tr>
                          <td>
                            <b>รวมทั้งหมด</b>
                          </td>
                          <td>
                            <b>{formatCurrency(total_after_discount)} บาท</b>
                          </td>
                        </tr>
                      </tbody>
                    </TableEditCostDetail>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => setOpen(false)}>{`ยกเลิก`}</Button>
                <Button
                  onClick={() => {
                    if (
                      mainCategory?.value &&
                      subCategory?.value &&
                      value?.qty > 0 &&
                      value?.price > 0 &&
                      value?.vat >= 0 &&
                      value?.wht >= 0 &&
                      subSelect &&
                      mainSelect
                    ) {
                      setDataPrepair(prev => {
                        let temp = { ...prev };
                        let thisTemp = [...temp?.serviceItems];

                        let newObject = {
                          datamaster_service_item_id: subCategory?.detail?.truck_type_id,
                          service_category_name: mainCategory?.service_category_name,
                          advance_type:
                            mainCategory?.service_category_name === 'ค่าสำรองจ่าย' ? true : false,
                          list_number: subCategory?.label?.slice(0, 5),
                          list_name: subCategory?.service_item_name,
                          qty: +value?.qty,
                          unit: subCategory?.detail?.unit,
                          price: +value?.price,
                          discount: 0,
                          vat: +value?.vat,
                          wht: +value?.wht,
                          calcurate_wht: +cal_wht,
                          total_before_discount: total_before_discount,
                          total_price: total_before_discount,
                          grand_total_price: total_after_discount,
                          prefix: mainCategory?.prefix,
                          suffix: mainCategory?.suffix || subCategory?.label?.slice(2, 5),
                        };

                        // console.log('DATA IN F : ', {
                        //   prev,
                        //   mainCategory,
                        //   editIndex,
                        //   thisTemp,
                        //   newObject,
                        // });

                        if (isNew) {
                          thisTemp.push(newObject);
                          temp.serviceItems = thisTemp;
                          console.log('temp', { ...temp });

                          return { ...temp };
                        } else {
                          thisTemp[editIndex?.item?.selfIndex] = newObject;
                          temp.serviceItems = thisTemp;
                          console.log('temp', { ...temp });

                          return { ...temp };
                        }
                      });
                      setOpen(false);
                    } else {
                      alert('กรุณากรอกข้อมูลให้ครบถ้วน');
                    }
                  }}
                >{`บันทึก`}</Button>
              </Modal.Footer>
            </Modal>
          );
        }}
      </Query>
    );
  } else {
    return <React.Fragment />;
  }
};

export default CostPerPacelModalCreateParcel;
