import React, { Component } from 'react';
import { Query, Mutation, graphql, compose } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  FormControl,
  Modal,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import Moment from 'react-moment';
import dayjs from 'dayjs';
import Autosuggest from 'react-autosuggest';
import { client } from '../..';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import 'bootstrap/dist/css/bootstrap.css';

import CardTable from 'components/CardTable/CardTable.jsx';
import CustomButton from 'components/CustomButton/CustomButton.jsx';

import accountInfoListQuery from './graphql/query/accountInfoList';
import driverMonthlyCalendarTablesQuery from './graphql/query/driverMonthlyCalendarTables';
import createDriverMonthlyCalendarMutation from './graphql/mutation/createDriverMonthlyCalendar';
import driverMonthlyCalendarsQuery from './graphql/query/driverMonthlyCalendars';
import driverMonthlyCalendarDetailQuery from './graphql/query/driverMonthlyCalendarDetail';

let driverItem = [];

const Container = styled(Row)`
  & .card_table {
    margin-bottom: 0 !important;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

const TabContentContainer = styled.div`
  margin-top: 15px;

  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 15px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const AutosuggestContainerAgent = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

class AgentContractCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_page: 0,
      page_number: 1,
      order_by: null,
      order_type: null,
      month: dayjs().format('M'),
      year: dayjs().format('YYYY'),
      agentId: null,
      agentName: '',
      agentSuggestions: [],
      isOpenCalendarDetail: false,
      calendarDetail: null,
    };
    this._calendarRef = React.createRef();
  }

  //Autosuggest Transporter
  getSuggestionsTransporter = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        agentSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTransporter = suggestion => suggestion.name;

  renderSuggestionTransporter = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTransporter = ({ value }) => {
    this.getSuggestionsTransporter(value);
  };

  onSuggestionsClearRequestedTransporter = () => {
    this.setState({
      agentSuggestions: [],
    });
  };

  shouldRenderSuggestionsTransporter() {
    return true;
  }
  //End Autosuggest Transporter

  getMonthList() {
    return [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];
  }

  getYearList() {
    const minYear = '2021';
    const diffYear = dayjs().diff(minYear, 'year') + 1;
    return new Array(diffYear).fill({}).map((_, index) => (+minYear + index).toString());
  }

  getDayList() {
    const days = ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    const indexDay = Number(
      dayjs(`${this.state.year}-${this.state.month}-1`)
        .startOf('month')
        .format('d')
    );
    const min = 1;
    const max = Number(
      dayjs(`${this.state.year}-${this.state.month}-1`)
        .endOf('month')
        .format('D')
    );
    return new Array(max)
      .fill({})
      .map((_, index) => days[indexDay + index <= 6 ? indexDay + index : (indexDay + index) % 7]);
  }

  getDateList() {
    const min = 1;
    const max = Number(
      dayjs(`${this.state.year}-${this.state.month}-1`)
        .endOf('month')
        .format('D')
    );

    return new Array(max).fill({}).map((_, index) => (min + index).toString());
  }

  renderEventContent(eventInfo) {
    return (
      <>
        <small>มีรถวิ่ง</small>
        <b>
          <i className="fal fa-truck"></i>
          {eventInfo.event.title || 0} คัน
        </b>
      </>
    );
  }

  handleEventClick = info => {
    // bind with an arrow function
    this.setState({
      isOpenCalendarDetail: true,
      calendarDetail: `${dayjs(info.event.start).format('YYYY-MM-DD')}`,
    });
  };

  gotoSetDate = (month, year) => {
    let calendarApi = this._calendarRef.current.getApi();
    const date = dayjs(`${year}-${month}-1`).format('YYYY-MM-DD');
    console.log(date);
    calendarApi.gotoDate(date); // call a method on the Calendar object
    // calendarApi.prev();

    console.log('calendarApi.getDate()', calendarApi.getDate());
  };

  render() {
    return (
      <div className="content" style={{ paddingTop: 0 }}>
        <HeaderContainer>
          <div className="sub-header-left">
            <div className="menu-wrapper">
              <ul>
                <li className="active">
                  <a href={`#`}>
                    {' '}
                    <i className="fas fa-calendar-alt"></i> ตารางจำนวนรถรายเดือน
                  </a>
                </li>
                <li>
                  <a href={`/contract-calendar-table`}>
                    {' '}
                    <i className="fas fa-truck"></i> ตารางการวิ่งรถรายเดือน
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </HeaderContainer>
        <Grid fluid>
          <Container>
            <Col md={12}>
              <CardTable
                ctTableFullWidth
                ctTableResponsive
                leftButton={
                  <div
                    style={{
                      float: 'left',
                      marginRight: '15px',
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'baseline',
                      gap: '15px',
                    }}
                  >
                    <span>ตารางรถวิ่งงานของนายหน้า:</span>
                    <AutosuggestContainerAgent>
                      <Autosuggest
                        suggestions={this.state.agentSuggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTransporter}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequestedTransporter}
                        getSuggestionValue={this.getSuggestionValueTransporter}
                        renderSuggestion={this.renderSuggestionTransporter}
                        focusInputOnSuggestionClick={false}
                        inputProps={{
                          placeholder: 'ค้นหานายหน้า',
                          value: this.state.agentName,
                          onChange: event =>
                            this.setState({
                              agentName: event.target.value,
                              agentId: null,
                            }),
                          type: 'text',
                          className: 'form-control',
                        }}
                        onSuggestionSelected={(event, { suggestion }) => {
                          this.setState({
                            agentId: suggestion.id,
                            agentName: suggestion.name,
                          });
                        }}
                        shouldRenderSuggestions={this.shouldRenderSuggestionsTransporter}
                      />
                    </AutosuggestContainerAgent>
                  </div>
                }
                rightButton={
                  <div
                    style={{
                      float: 'right',
                      marginLeft: '15px',
                      flexDirection: 'row',
                      display: 'flex',
                      gap: '12px',
                    }}
                  >
                    <FormControl
                      style={{ width: '120px' }}
                      componentClass="select"
                      placeholder="select"
                      defaultValue={this.state.month}
                      onChange={event => {
                        this.setState({
                          month: event.target.value,
                        });
                        this.gotoSetDate(event.target.value, this.state.year);
                      }}
                      name="month"
                    >
                      {this.getMonthList().map((item, key) => (
                        <option value={key + 1} key={item}>
                          {item}
                        </option>
                      ))}
                    </FormControl>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      defaultValue={this.state.year}
                      onChange={event => {
                        this.setState({
                          year: event.target.value,
                        });
                        this.gotoSetDate(this.state.month, event.target.value);
                      }}
                      name="search_year"
                    >
                      {this.getYearList().map(item => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </FormControl>
                  </div>
                }
                content={
                  <div>
                    <Query
                      query={driverMonthlyCalendarsQuery}
                      variables={{
                        month: `${this.state.year}-${this.state.month}`,
                        account_info: this.state.agentId,
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        {
                          if (!loading && !error) {
                            console.log(
                              'driverMonthlyCalendarsQuery',
                              this.state.month,
                              this.state.year,
                              this.state.agentId,
                              data.driverMonthlyCalendars.calendars
                            );
                          }
                        }

                        if (loading || error) {
                          return (
                            <FullCalendar plugins={[dayGridPlugin]} initialView="dayGridMonth" />
                          );
                        }

                        return (
                          <FullCalendar
                            plugins={[dayGridPlugin, interactionPlugin]}
                            headerToolbar={{
                              left: '',
                              center: '',
                              right: '',
                            }}
                            initialView="dayGridMonth"
                            events={data.driverMonthlyCalendars.calendars.map((calendar, key) => ({
                              id: key,
                              title: calendar.value,
                              date: calendar.date,
                            }))}
                            eventContent={this.renderEventContent}
                            eventClick={this.handleEventClick}
                            select={this.handleDateSelect}
                            ref={this._calendarRef}
                          />
                        );
                      }}
                    </Query>
                  </div>
                }
              />
            </Col>

            {this.state.isOpenCalendarDetail && (
              <Modal
                show={this.state.isOpenCalendarDetail}
                backdrop="static"
                onHide={() => this.setState({ isOpenCalendarDetail: false })}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    วันที่ {dayjs(this.state.calendarDetail).format('DD/MM/YYYY')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                      <b>รายชื่อนายหน้าและพนักงานขับรถ</b>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                      <Query
                        query={driverMonthlyCalendarDetailQuery}
                        variables={{
                          date: dayjs(this.state.calendarDetail).format('YYYY-MM-DD'),
                        }}
                      >
                        {({ loading, error, data, refetch }) => {
                          {
                            if (!loading && !error) {
                              console.log(
                                'driverMonthlyCalendarDetailQuery',
                                this.state.calendarDetail,
                                data.driverMonthlyCalendarDetail.detail
                              );
                            }
                          }
                          return (
                            <Table striped hover responsive>
                              <thead>
                                <tr style={{ borderTop: '1px solid #ddd' }}>
                                  <th style={{ width: '50px', minWidth: '50px' }}>ลำดับ</th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>นายหน้า</th>
                                  <th style={{ width: '120px', minWidth: '120px' }}>
                                    ชื่อพนักงานขับรถ
                                  </th>
                                  {/* <th style={{ minWidth: '100px' }}>ทะเบียน</th> */}
                                  <th style={{ minWidth: '100px' }}>ประเภท</th>
                                  <th>จังหวัดที่วิ่ง</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading || error || !data ? (
                                  <tr>
                                    <td colSpan={5}>{loading ? 'Loading...' : error.message}</td>
                                  </tr>
                                ) : (
                                  data.driverMonthlyCalendarDetail.detail.map((prop, key) => (
                                    <tr>
                                      <td>{key + 1}</td>
                                      <td>{prop.account_info.account_info_business_name}</td>
                                      <td>{prop.driver.driver_name}</td>
                                      {/* <td>{prop.driver.user_setting_truck_license_plate}</td> */}
                                      <td>
                                        {prop.setting_truck_name.en} {prop.truck_type_name.en}
                                      </td>
                                      <td>{prop.driver_route_service.length} เส้นทาง</td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          );
                        }}
                      </Query>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.setState({ isOpenCalendarDetail: false })}>
                    ปิด
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Container>
        </Grid>
      </div>
    );
  }
}

export default AgentContractCalendar;
