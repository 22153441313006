import React, { Component } from 'react';
import { Query } from 'react-apollo';
import Header from './container/Header';
import dashboardLazadaQuery from './graphql/query/dashboardLazada';
import {
  Container,
  StyledCardTripStatus,
  ContentContainer,
  TripStatusContainer,
  StyledCardTruckStatusWrapper,
  StyledCardTripStatusWrapper,
  StyledCardLargeNumber,
  CardIconContainer,
  StyledCardIcon,
  CardLargeNumberContainer,
  TripStatusCounterContainer,
  StyledCardTripCounter,
  TextCountDown,
  StyledQRCode
} from './Styles';

class LazadaQueue extends Component {
  state = {
    id: this.props.match.params.id,
    timeRefetch: 15, // second
    countDown: 0,
    mathRandom: 0,
    isStartCountDown: false,
  };

  componentDidMount() {
    this.setState({
      countDown: this.state.timeRefetch,
    });
  }

  setOrderId = (bookId, bookName) => {
    this.setState({
      id: bookId
    })
  }

  setCountDown = () => {
    setInterval(() => {
      if (this.state.countDown <= 0) {
        this.setState({
          countDown: this.state.timeRefetch,
          mathRandom: Math.random(),
        });
      } else {
        this.setState(prevState => ({
          countDown: prevState.countDown - 1,
        }));
      }
    }, 1000);
  };

  render() {
    return (
      <Container>
        <Query
          query={dashboardLazadaQuery}
          variables={{
            random: this.state.mathRandom,
            _id: this.state.id,
          }}
          fetchPolicy="cache-and-network"
          onCompleted={() => {
            if (!this.state.isStartCountDown) {
              this.setState(
                {
                  isStartCountDown: true,
                },
                () => this.setCountDown()
              );
            }
          }}
        >
          {({ data, error, networkStatus }) => {
            if (networkStatus === 1) {
              return <div>Loading...</div>;
            }

            if (error) {
              return <div>{error.message}</div>;
            }

            const {
              round,
              pick_up_date,
              total_truck,
              truck_finish,
              truck_balance,
              truck_ready,
              truck_get_job,
              truck_not_get_job,
              pick_up_average_time,
              truck_inprogess,
              scan_not_complete,
              scan_complete,
              product_leave_warehouse,
              trip_list,
              shipment_not_assign,
            } = data.dashboardLazada;

            return (
              <>
                <TextCountDown>{this.state.countDown}</TextCountDown>
                <Header textRound={round} textPickupDate={pick_up_date} setOrderId={this.setOrderId}/>
                <ContentContainer>
                  <StyledCardTruckStatusWrapper>
                    <div
                      style={{
                        fontSize: '1.5vw',
                        fontWeight: 'bold',
                        marginBottom: 10,
                        height: 'calc(10% - 10px)',
                      }}
                    >
                      แสดงสถานะรถขนส่งสินค้า
                    </div>

                    <CardLargeNumberContainer>
                      <StyledCardLargeNumber
                        title="รถทั้งหมด:"
                        bgColor="#000000"
                        textColor="#ffffff"
                        number={total_truck}
                      />
                      <StyledCardLargeNumber
                        title="รับสินค้าเสร็จ/ออกจากคลัง:"
                        bgColor="#ffffff"
                        textColor="#3EAF2C"
                        borderColor="#3EAF2C"
                        number={truck_finish}
                      />
                      <StyledCardLargeNumber
                        title="เหลืออีก:"
                        bgColor="#ffffff"
                        textColor="#E25700"
                        borderColor="#E25700"
                        number={truck_balance}
                      />
                      <StyledQRCode round={round} />
                    </CardLargeNumberContainer>

                    <CardIconContainer>
                      <StyledCardIcon
                        title="งานที่ยังไม่ได้จ่าย"
                        icon="fa-engine-warning"
                        text={shipment_not_assign}
                        unit="งาน"
                      />
                      <StyledCardIcon
                        title="รถยังไม่กดรับงาน"
                        icon="fa-times-circle"
                        text={truck_not_get_job}
                        unit="คัน"
                      />
                      <StyledCardIcon
                        title="รถรับงานแล้ว"
                        icon="fa-check-circle"
                        text={truck_get_job}
                        unit="คัน"
                      />
                      <StyledCardIcon
                        title="รถพร้อมขึ้นสินค้า"
                        icon="fa-garage-car"
                        text={truck_ready}
                        unit="คัน"
                      />
                      <StyledCardIcon
                        title="รถกำลังขึ้นสินค้่า"
                        icon="fa-truck-loading"
                        text={truck_inprogess}
                        unit="คัน"
                      />
                      <StyledCardIcon
                        title="เวลาเฉลี่ยรับสินค้า"
                        icon="fa-clock"
                        text={pick_up_average_time > 0 ? pick_up_average_time : '-'}
                        unit="นาที/คัน"
                      />
                    </CardIconContainer>
                  </StyledCardTruckStatusWrapper>

                  <StyledCardTripStatusWrapper>
                    <TripStatusCounterContainer>
                      <div
                        style={{
                          color: '#ffffff',
                          fontSize: '1.5vw',
                          fontWeight: 'bold',
                          marginBottom: 10,
                        }}
                      >
                        แสดงสถานะการขึ้นสินค้า
                      </div>
                      <StyledCardTripCounter
                        bgColor="#D00000"
                        textColor="#ffffff"
                        title="ยังไม่สแกนสินค้า"
                        icon="fa-exclamation-circle"
                        text={scan_not_complete}
                        unit="Trip"
                      />
                      <StyledCardTripCounter
                        bgColor="#0D1578"
                        textColor="#ffffff"
                        title="สแกนสินค้าแล้ว"
                        icon="fa-box-check"
                        text={scan_complete}
                        unit="Trip"
                      />
                      <StyledCardTripCounter
                        bgColor="#3EAF2C"
                        textColor="#ffffff"
                        title="สินค้าออกจากคลัง"
                        icon="fa-truck"
                        text={product_leave_warehouse}
                        unit="Trip"
                      />
                    </TripStatusCounterContainer>
                    <TripStatusContainer>
                      {trip_list.map((trip, index) => (
                        <StyledCardTripStatus
                          key={index}
                          count={trip_list.length}
                          tripNumber={trip.trip}
                          status={trip.status}
                        />
                      ))}
                    </TripStatusContainer>
                  </StyledCardTripStatusWrapper>
                </ContentContainer>
              </>
            );
          }}
        </Query>
      </Container>
    );
  }
}

export default LazadaQueue;
