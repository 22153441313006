import gql from 'graphql-tag';

export default gql`
  query newCods($params: paramsNewCods, $sort: sort, $page: Int, $showData: Int!) {
    newCods(params: $params, sort: $sort, page: $page, showData: $showData) {
      cods {
        _id
        vendor {
          _id
          external_system_id
          first_name
          last_name
          full_name
        }
        cod_in_number
        total_cod
        shipment_numbers
        due_date
        cod_in_date
        ops_employee {
          _id
          department
          first_name
          last_name
          full_name
        }
        payment_cod {
          _id
        }
        cod_status
        cod_item {
          _id
        }
        document
        cod_remark
        reject_remark
        send_history {
          timestamp
        }
        last_send
        create_date
        last_update
      }
      totalPage
      totalDocument
    }
  }
`;
