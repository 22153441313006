import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Tooltip,
  OverlayTrigger,
  Table,
  FormControl,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import s3 from 'config/s3';
import CardTable from 'components/CardTable/CardTable.jsx';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import { Card, Input, InputDatetime, SelectBox, Select, Label } from './components';

import adminRegisterAccountMutation from './graphql/mutation/adminRegisterAccount';
import accountInfoListListQuery from './graphql/query/accountInfoList';
import driverUserListQuery from './graphql/query/driverUserList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import { Query, Mutation, graphql } from 'react-apollo';
import { compose } from 'redux';
import NotificationSystem from 'react-notification-system';
import Moment from 'react-moment';
import moment from 'moment/moment.js';

import urijs from 'urijs';

const tooltip = <Tooltip id="tooltip">แก้ไข</Tooltip>;
const tooltip1 = <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>;

//react tag input
const KeyCodes = {
  comma: 188,
  enter: 13,
};

//styled component
const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const Container = styled.div`
	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}

	& .text-green {
		color: #19AA19;
	}

	& .text-blue {
		color: #318DE8;
	}

	& .text-red {
		color: #FF0000;
	}

	& .text-grey {
		color: #F2F2F2;
	}

	& .text-primary {
		color: #D90101 !important;
	}
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const TextHeader = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 1;
`;

const TextSubHeader = styled.div`
  font-size: 18px;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineHorizontal = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e3e3e3;
`;

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  padding-left: 35px !important;
  margin-top: 12px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  &:hover input ~ .checkmark {
    background-color: #f1f1f1;
  }

  & input:checked ~ .checkmark {
    border: 1px solid #ffffff;
    background-color: #19aa19;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const TextRight = styled.div`
  height: 40px;
  top: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  padding: 0px 12px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 40px;
`;

const TextContent = styled.div`
  font-size: 18px;
  line-height: 1;
  margin-top: 10px;
`;

const TextMessageError = styled.span`
  font-size: 18px;
  color: #ff0000;
  height: 22px;
  text-align: right;
`;

const selectOptionTypeBusiness = [
  { value: '', label: '-- เลือก --' },
  { value: 'ยานยนต์ Spare Parts', label: 'ยานยนต์ Spare Parts' },
  { value: 'อิเล็กทรอนิกส์', label: 'อิเล็กทรอนิกส์' },
  { value: 'อาหารและเครื่องดื่ม', label: 'อาหารและเครื่องดื่ม' },
  { value: 'อาหารสด และวัตถุดิบ', label: 'อาหารสด และวัตถุดิบ' },
  { value: 'โลจิสติกส์', label: 'โลจิสติกส์' },
  { value: 'แฟชั่นเครื่องแต่งกาย', label: 'แฟชั่นเครื่องแต่งกาย' },
  { value: 'การบริการ', label: 'การบริการ' },
  { value: 'เครื่องจักร', label: 'เครื่องจักร' },
  { value: 'นำเข้า ส่งออก', label: 'นำเข้า ส่งออก' },
  { value: 'อุตสาหกรรมการผลิต', label: 'อุตสาหกรรมการผลิต' },
  { value: 'บ้านและสวน', label: 'บ้านและสวน' },
  { value: 'ก่อสร้าง', label: 'ก่อสร้าง' },
  { value: 'เคมีภัณฑ์ วัตถุอันตราย', label: 'เคมีภัณฑ์ วัตถุอันตราย' },
  { value: 'เฟอร์นิเจอร์', label: 'เฟอร์นิเจอร์' },
  { value: 'เครื่องมือแพทย์', label: 'เครื่องมือแพทย์' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

const selectOptionCountry = [
  { value: '', label: '-- เลือก --' },
  { value: 'ไทย', label: 'ไทย' },
];

const StatusContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #E3E3E3;
  background-color: #FFFFFF;
	padding: 20px;
	
	& .header {
		display: flex;
		flex-direction: row;
		align-items: center;

		&-icon {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 44px
			height: 44px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.1);

			& i {
				margin: auto;
				font-size: 20px;
			}

			&-danger {
				background-color: rgba(217, 1, 1, 0.1);

				& i {
					color: #D90101;
				}
			}

			&-success {
				background-color: rgba(25, 170, 25, 0.1);

				& i {
					color: #19AA19;
				}
			}
		}

		&-text {
			flex: 1;
			margin-left: 8px;
			line-height: 1;
			font-size: 18px;
			font-weight: bold;
		}

		& .nomargin {
			margin: 0;
		}
	}

	& .detail {
		font-size:18px;
		line-height: 1.4;
		margin-top: 10px;

		&-list {
			display: flex;
			flex-direction: row;

			&-left {
				align-self: flex-start;
				flex: 1;
			}

			&-right {
				align-self: flex-end;
			}
		}
	}

	& .text-danger {
		color: #D90101;
		font-weight: bold;
	}
`;

const CreditContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 10px;
  line-height: 1;
  align-items: flex-end;

  & .text-header {
    align-self: flex-start;
    margin-bottom: 10px;
  }

  & .text-bold {
    font-weight: bold;
  }

  & .text-medium {
    font-size: 24px;
  }

  & .text-large {
    font-size: 28px;
  }

  & .line {
    background-color: #e3e3e3;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;
const SubHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const FileContainer = styled.div`
  margin: 5px 0;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  padding: 5px 0px 5px 10px;
  height: 40px;
  box-sizing: border-box;

  ${props =>
    props.isBorderValidated ? ` border: 1px solid #FF0000; ` : ` border: 1px solid #E3E3E3; `}

  & .file-left {
    flex: 1;
    display: flex;
    flex-direction: row;

    &-icon {
      font-size: 20px;
      margin-right: 10px;
    }

    &-text {
      font-size: 18px;
    }
  }

  & .file-right {
    display: flex;
    flex-direction: row;

    &-filename {
      margin-right: 10px;
    }

    &-delete {
      margin-right: 10px;
      cursor: pointer;
    }

    & button {
      border-radius: 0px;
      border-left-width: 1px;
      border-top-width: 0px;
      border-right-width: 0px;
      border-bottom-width: 0px;
    }
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const TagsEmail = styled.div`
  font-size: 18px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  padding: 11px;
  line-height: 1;
  background-color: #f9f9f9;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;

  & span.delete {
    font-size: 18px;
    color: #cacaca;
    cursor: pointer;
    margin-left: 5px;
  }
`;

const ImageProfile = styled.div`
  margin: 20px auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  ${props =>
    props.isBorderValidated ? ` border: 1px solid #FF0000; ` : ` border: 1px solid #F2F2F2; `}
  position: relative;
  overflow: hidden;

  & i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: rgba(0, 0, 0, 0.1);
  }

  & img {
    width: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    position: absolute;
    display: block;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;

const ButtonUpload = styled.button`
  background-color: #ffffff;
  color: #000000;
  font-size: 18px;
  padding: 4px 15px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  margin: 0 4px;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #f9f9f9;
  }
`;

const TabContentContainer = styled.div`
  margin-top: 15px;

  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

class BusinessDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      truckTypeInvalid: false,
      document1: '',
      document2: '',
      document3: '',
      document4: '',
      document5: '',
      document6: '',
      document7: '',
      truckType: [],
      settingTruckList: [],
      display_picture: '',
      driver_license_exp: '',
      driver_license_exp_show: '',
      username: '',
      password: '',
      driver_type: 'นายหน้า',
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      driver_enable: '1',
      user_setting_truck_license_plate: '',
      driver_status: '1',
      line_id: '',
      reserve_phone_code: '66',
      reserve_phone_number: '',
      id_card_number: '',
      setting_truck_service: [],
      driver_delivery_vat: 0,
      driver_delivery_wht: 0,
      driver_service_vat: 0,
      driver_service_wht: 0,
      setting_truck_id: '',
      setting_truck_type_id: '',

      account_info_id: urijs(window.location.href).query(true).agent || '5d0083ebc88a512bb2f5f7a6',

      viewPass: 'password',

      search_user_setting_truck_license_plate: '',
      search_driver_number: '',
      search_driver_phonenumber: '',
      search_driver_name: '',
      search_driver_enable: 0,
      search_driver_block: 0,
      total_page: 0,
      page_number: 1,
      order_by: null,
      order_type: null,
      last_update: '',
      show_last_update: '',
      trip_rate: null,
    };

    this._inputDocument1 = React.createRef();
    this._inputDocument2 = React.createRef();
    this._inputDocument3 = React.createRef();
    this._inputDocument4 = React.createRef();
    this._inputDocument5 = React.createRef();
    this._inputDocument6 = React.createRef();
    this._inputDocument7 = React.createRef();
    this._inputProfileImage = React.createRef();

    this.notificationSystem = React.createRef();

    this._inputCredit = React.createRef();
    this._inputCreditApproved = React.createRef();
  }

  handleDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ last_update: date, show_last_update: show }, () => {
      refetch();
    });
  }

  onClickViewPass(type) {
    if (type == 'password') {
      this.setState({
        viewPass: 'text',
      });
    } else {
      this.setState({
        viewPass: 'password',
      });
    }
  }

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  componentWillReceiveProps(newProps) {
    const { loading, error, settingTruckList } = newProps.settingTruckListQuery;
    if (!loading && !error) {
      this.setState({
        settingTruckList: settingTruckList,
      });
    }

    const { loadingagent, erroragent, accountInfoList } = newProps.accountInfoListListQuery;
    if (!loadingagent && !erroragent && accountInfoList != undefined) {
      console.log('accountInfoList', accountInfoList);
      const driver = accountInfoList.account_info[0];
      this.setState({
        _id: driver._id,
        account_info_id: driver._id,
        username: driver.account_users[0].account_user_name || '',
        password: '',
        driver_block: driver.account_info_status ? driver.account_info_status : 1,
        driver_number: driver.account_agent_number || '',
        document1: driver.id_card_pic.length > 0 ? driver.id_card_pic[0].path : '',
        document2: driver.driver_card_pic.length > 0 ? driver.driver_card_pic[0].path : '',
        document3: driver.car_pic.length > 0 ? driver.car_pic[0].path : '',
        document4: driver.license_plate_pic.length > 0 ? driver.license_plate_pic[0].path : '',
        document5: driver.act_pic.length > 0 ? driver.act_pic[0].path : '',
        document6: driver.car_insurance_pic.length > 0 ? driver.car_insurance_pic[0].path : '',
        document7: driver.book_bank_pic.length > 0 ? driver.book_bank_pic[0].path : '',
        display_picture: driver.account_users[0].account_user_display_picture || '',
        driver_type: driver.account_info_business_type.th || 'บุคคลธรรมดา',
        driver_name: driver.account_info_business_name || '',
        driver_phone_code: driver.account_info_phone_code || '66',
        driver_phone_number: driver.account_info_phone_number
          ? 0 + driver.account_info_phone_number
          : '',
        driver_enable: driver.driver_enable || 1,
        user_setting_truck_license_plate: driver.user_setting_truck_license_plate || '',
        driver_status: driver.driver_status || 1,
        line_id: driver.line_id || 1,
        reserve_phone_code: driver.reserve_phone_code || '66',
        reserve_phone_number: driver.reserve_phone_number ? 0 + driver.reserve_phone_number : '',
        id_card_number: driver.id_card_number || '',
        setting_truck_service: driver.setting_truck_service || '',
        truckType: driver.setting_truck_service || '',
        driver_delivery_vat: driver.account_info_delivery_vat || 0,
        driver_delivery_wht: driver.account_info_delivery_wht || 0,
        driver_service_vat: driver.account_info_service_vat || 0,
        driver_service_wht: driver.account_info_service_wht || 0,
      });
    }
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  handleSelectTruckType(event) {
    let truckType = this.state.truckType;
    const obj = this.state.truckType.filter(item => item === Event);

    if (obj.length > 0) {
      const obj = this.state.truckType.filter(item => item != event);
      this.setState({ truckType: obj });
    } else {
      this.setState(state => {
        const truckType = [...state.truckType, event];
        return {
          truckType,
        };
      });
    }
  }

  handelCheckTrucktype(_id) {
    const obj = this.state.truckType.filter(item => item === _id);

    if (obj.length > 0) {
      return true;
    }
    return false;
  }

  checkPhoneNumber = phoneNumber => {
    const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
    if (validatePhoneNumber.test(phoneNumber)) {
      return true;
    }

    return false;
  };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      cb(event.target.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  }

  handleUploadDocument(uploadFile, documentName, event) {
    const file = event.target.files[0];

    if (file) {
      this.getBase64(file, result => {
        const timestamp = new Date().getTime();
        uploadFile({
          variables: {
            file: result,
            path: `upload/account_transporter/${documentName}_${timestamp}`,
          },
        });
      });
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/account_transporter/${documentName}_${timestamp}`,
        },
      });
    }
  }

  //credit input format
  formatNumber(number, revers) {
    //ป้องกันข้อมูลว่างแล้วค่าเป็น 0
    if (number === '' || number === null) {
      return number;
    }

    if (revers === true) {
      // format number 1,234,567 to 1000000
      return Number(number.replace(/\D/g, ''));
    }

    // format number 1000000 to 1,234,567
    return Number(number.replace(/\D/g, ''))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  handleClickUploadProfileImage() {
    this._inputProfileImage.current.click();
  }

  checkFileTypeImage(file) {
    if (file) {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        return true;
      } else {
        return false;
      }
    }
  }

  checkFileSizeImage(file) {
    if (file) {
      const size = parseFloat(file.size / 1024).toFixed(2);
      if (size < 10240) {
        //ขนาดไฟล์ 10240KB or 10Mb
        return true;
      } else {
        return false;
      }
    }
  }

  handleSingleUploadProfileImage(uploadFile, documentName, event) {
    const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
    if (checkFileTypeImage === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage:
          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png และ jpg เท่านั้น',
      });
      return;
    }

    const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
    if (checkFileSize === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: this.props.t(
          'common:instancePrice.modal.uploadFileCheckSizeFile.content'
        ),
      });
      return;
    }

    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/profile/${documentName}_${timestamp}`,
        },
      });
    }
  }

  handleDatePicker(event) {
    //console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD')}`;
    const show = `${event.format('DD/MM/YYYY')}`;
    this.setState({
      driver_license_exp: date,
      driver_license_exp_show: show,
      driver_license_expInvalid: false,
    });
  }

  handleSave(registerAgent) {
    this.setState({ driver_nameInvalid: this.state.driver_name ? false : true });
    this.setState({ driver_phone_numberInvalid: this.state.driver_phone_number ? false : true });
    this.setState({
      user_setting_truck_license_plateInvalid: this.state.user_setting_truck_license_plate
        ? false
        : true,
    });
    this.setState({ line_idInvalid: this.state.line_id ? false : true });
    //this.setState({ reserve_phone_numberInvalid: this.state.reserve_phone_number ? false : true });
    this.setState({ id_card_numberInvalid: this.state.id_card_number ? false : true });
    this.setState({ usernameInvalid: this.state.username ? false : true });
    this.setState({ passwordInvalid: this.state.password ? false : true });
    this.setState({ driver_typeInvalid: this.state.driver_type ? false : true });
    this.setState({
      driver_delivery_vatInvalid: this.state.driver_delivery_vat != '' ? false : true,
    });
    this.setState({
      driver_delivery_whtInvalid: this.state.driver_delivery_wht != '' ? false : true,
    });
    this.setState({
      driver_service_vatInvalid: this.state.driver_service_vat != '' ? false : true,
    });
    this.setState({
      driver_service_whtInvalid: this.state.driver_service_wht != '' ? false : true,
    });
    this.setState({ document1Invalid: this.state.document1 ? false : true });
    this.setState({ truckTypeInvalid: this.state.truckType.length > 0 ? false : true });

    if (
      //ข้อมูลนายหน้า
      this.state.driver_nameInvalid ||
      this.state.driver_phone_numberInvalid ||
      this.state.reserve_phone_numberInvalid ||
      this.state.user_setting_truck_license_plateInvalid ||
      this.state.line_idInvalid ||
      this.state.id_card_numberInvalid ||
      this.state.usernameInvalid ||
      this.state.driver_typeInvalid ||
      this.state.driver_delivery_vatInvalid ||
      this.state.driver_delivery_whtInvalid ||
      this.state.driver_service_vatInvalid ||
      this.state.driver_service_whtInvalid ||
      this.state.driver_license_expInvalid ||
      this.state.truckTypeInvalid ||
      //เอกสารเพิ่มเติม
      this.state.document1Invalid
    ) {
      this.addNotification('error', 'กรุณากรอกข้อมูลที่สำคัญให้ครบก่อน');
    } else {
      registerAgent({
        variables: {
          account_info_type: 3,
          account_user_register_from: 1,
          account_info_register_channel: 3,
          account_info_business_type: {
            th: 'นายหน้า',
            en: 'Agent',
          },
          account_user_name: this.state.username,
          account_user_password: this.state.password,
          account_info_business_name: this.state.driver_name,
          account_info_tax_id: this.state.id_card_number,
          account_user_phone_code: this.state.driver_phone_code,
          account_user_phone_number: this.state.driver_phone_number.substring(1),
          line_id: this.state.line_id,
          reserve_phone_code: this.state.reserve_phone_number ? this.state.reserve_phone_code : '',
          reserve_phone_number: this.state.reserve_phone_number
            ? this.state.reserve_phone_number.substring(1)
            : '',
          criminal_history: this.state.criminal_history,
          id_card_number: this.state.id_card_number,
          id_card_pic: {
            path: this.state.document1,
          },
          driver_card_pic: {
            path: this.state.document2,
          },
          car_pic: {
            path: this.state.document3,
          },
          license_plate_pic: {
            path: this.state.document4,
          },
          act_pic: {
            path: this.state.document5,
          },
          car_insurance_pic: {
            path: this.state.document6,
          },
          book_bank_pic: {
            path: this.state.document7,
          },
          account_user_display_picture: this.state.display_picture,
          setting_truck_service: this.state.truckType,
          driver_type: this.state.driver_type,
          account_info_delivery_vat: this.state.driver_delivery_vat,
          account_info_delivery_wht: this.state.driver_delivery_wht,
          account_info_service_vat: this.state.driver_service_vat,
          account_info_service_wht: this.state.driver_service_wht,
          bank_account: [
            {
              _id: this.state.bank_account_id,
              bank_name: this.state.bank_name,
              bank_branch: this.state.bank_branch,
              account_name: this.state.account_name,
              account_number: this.state.account_number,
            },
          ],
        },
      });
    }
  }

  checkPhoneNumberFormat(phone, mode) {
    const validatePhoneNumber = /^[0][0-9].{8}$/;
    let error = false;
    if (!validatePhoneNumber.test(phone) || (mode == 'driver_phone_number' && phone == '')) {
      error = true;
    }

    if (error) {
      if (mode == 'driver_phone_number') {
        this.setState({
          driver_phone_number: phone,
          driver_phone_numberInvalid: true,
        });
      } else if (mode == 'reserve_phone_number') {
        this.setState({
          reserve_phone_number: phone,
          reserve_phone_numberInvalid: true,
        });
      }
    } else {
      if (mode == 'driver_phone_number') {
        this.setState({
          driver_phone_number: phone,
          driver_phone_numberInvalid: false,
        });
      } else if ((mode = 'reserve_phone_number')) {
        this.setState({
          reserve_phone_number: phone,
          reserve_phone_numberInvalid: false,
        });
      }
    }
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label style={{ color: '#19AA19' }}>เปิดการใช้งาน</Label>;
      case 2:
        return <Label style={{ color: '#ED1C24' }}>ระงับการใช้งาน</Label>;
      default:
        return '';
    }
  }

  getDriverRouteService(agents) {
    const agent = agents.filter(
      item => item.account_info_id === urijs(window.location.href).query(true).agent
    );

    if (agent.length > 0) {
      return agent[0].driver_route_service ? agent[0].driver_route_service.length : 0;
    }

    return 0;
  }

  getDriverTripRate(agents) {
    const agent = agents.filter(
      item => item.account_info_id === urijs(window.location.href).query(true).agent
    );

    if (agent.length > 0) {
      return agent[0].trip_rate;
    }

    return true;
  }

  render() {
    return (
      <Container>
        <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
        <div className="content">
          <HeaderContainer>
            <div className="sub-header-left">
              <a href="/driver" className="btn-back">
                <i className="far fa-arrow-left"></i> กลับ
              </a>
              <div className="line"></div>
              <div className="menu-wrapper">
                <ul>
                  <li>
                    <a href={`/agentdetail?agent=${this.state._id}`}>
                      {' '}
                      <i className="fal fa-address-card"></i> ข้อมูลนายหน้า
                    </a>
                  </li>
                  <li className="active">
                    <a href={`#`}>
                      {' '}
                      <i className="fal fa-truck"></i> ข้อมูลพนักงานขับรถ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </HeaderContainer>
          <SubHeaderContainer>
            <div className="sub-header-left">
              <strong>
                AG{this.state.driver_number} : {this.state.driver_name}
              </strong>
            </div>

            <div className="sub-header-right">
              <Button
                bsStyle="primary"
                onClick={() =>
                  (window.location =
                    '/driverregister?agent=' + urijs(window.location.href).query(true).agent)
                }
              >
                <i className="fal fa-users-medical"></i> เพิ่มคนขับรถ{' '}
              </Button>
            </div>
          </SubHeaderContainer>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <CardTable
                  title="ตารางรายการพนักงานขับรถ"
                  category="ข้อมูลพนักงานขับรถในระบบ"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div>
                      <Query
                        query={driverUserListQuery}
                        variables={{
                          account_info_id: urijs(window.location.href).query(true).agent || '',
                          user_setting_truck_license_plate: this.state
                            .search_user_setting_truck_license_plate,
                          driver_number: this.state.search_driver_number,
                          driver_name: this.state.search_driver_name,
                          driver_enable: this.state.search_driver_enable,
                          driver_block: this.state.search_driver_block,
                          page_number: this.state.page_number,
                          order_by: this.state.order_by,
                          order_type: this.state.order_type,
                          driver_phone_number:
                            this.state.search_driver_phonenumber.charAt(0) == 0
                              ? this.state.search_driver_phonenumber.substring(1)
                              : this.state.search_driver_phonenumber,
                          last_update: this.state.last_update,
                          agent: urijs(window.location.href).query(true).agent || '',
                          month_rate: this.state.trip_rate == 'true' ? true : null,
                          trip_rate: this.state.trip_rate == 'false' ? true : null,
                        }}
                      >
                        {({ loading, error, data, refetch }) => {
                          var itemLengthAgent = 'loading';
                          const itemIndicatorSizeAgent = (this.state.page_number - 1) * 30;
                          if (data && data.driverUserList) {
                            if (data.driverUserList.driver_user.length > 0) {
                              itemLengthAgent = data.driverUserList.driver_user.length;
                            }
                            console.log('driverUserListQuery', data);
                          }
                          return (
                            <div>
                              <PageWrapper style={{ textAlign: 'right' }}>
                                <div className="text">
                                  {this.state.page_number === 1
                                    ? `1 - ${itemLengthAgent}`
                                    : `${itemIndicatorSizeAgent + 1} - ${itemLengthAgent +
                                        itemIndicatorSizeAgent}`}{' '}
                                  หน้า :
                                </div>
                                <FormControl
                                  type="number"
                                  min={1}
                                  value={this.state.page_number}
                                  onChange={event =>
                                    setInterval(
                                      this.nextPage(
                                        Number(event.target.value) - 1,
                                        loading || error ? '0' : data.driverUserList.total_page,
                                        refetch
                                      ),
                                      1000
                                    )
                                  }
                                  name="page_number"
                                />
                                <div className="text">
                                  {' '}
                                  {loading || error ? '' : `จาก ${data.driverUserList.total_page}`}
                                </div>
                              </PageWrapper>
                              <div className="table-vertical">
                                <Table striped hover responsive>
                                  <thead>
                                    <tr>
                                      <th style={{ width: '95px' }}></th>
                                      <th
                                        style={{ maxWidth: '150px' }}
                                        className={this.renderSorting('driver_number')}
                                        onClick={() => this.sort('driver_number', refetch)}
                                      >
                                        Driver ID
                                      </th>
                                      <th
                                        style={{ width: '200px', minWidth: '200px' }}
                                        className={this.renderSorting('driver_name')}
                                        onClick={() => this.sort('driver_name', refetch)}
                                      >
                                        ชื่อพนักงานขับรถ
                                      </th>
                                      <th style={{ minWidth: '130px' }}>ทะเบียนรถ</th>
                                      <th style={{ minWidth: '130px' }}>เบอร์โทรศัพท์</th>
                                      <th style={{ minWidth: '130px' }}>Line ID</th>
                                      <th style={{ minWidth: '130px' }}>รูปแบบการจ้างงาน</th>
                                      <th style={{ minWidth: '80px' }}>จังหวัดที่วิ่ง</th>
                                      <th style={{ minWidth: '100px' }}>สถานะ</th>
                                      <th style={{ minWidth: '100px' }}>สถานะรับงาน</th>
                                      <th
                                        style={{ width: '150px', minWidth: '150px' }}
                                        className={this.renderSorting('last_update')}
                                        onClick={() => this.sort('last_update', refetch)}
                                      >
                                        Last Update
                                      </th>
                                    </tr>
                                    <tr className="tableFilter">
                                      <th></th>
                                      <th>
                                        <FormControl
                                          type="text"
                                          value={this.state.search_driver_number}
                                          placeholder="ค้นหา"
                                          onChange={event =>
                                            this.setState(
                                              {
                                                search_driver_number: event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          name="search_driver_number"
                                        />
                                      </th>
                                      <th>
                                        <FormControl
                                          type="text"
                                          value={this.state.search_driver_name}
                                          placeholder="ค้นหา"
                                          onChange={event =>
                                            this.setState(
                                              {
                                                search_driver_name: event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          name="search_driver_name"
                                        />
                                      </th>
                                      <th>
                                        <FormControl
                                          type="text"
                                          value={this.state.search_user_setting_truck_license_plate}
                                          placeholder="ค้นหา"
                                          onChange={event =>
                                            this.setState(
                                              {
                                                search_user_setting_truck_license_plate:
                                                  event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          name="search_user_setting_truck_license_plate"
                                        />
                                      </th>
                                      <th>
                                        <FormControl
                                          type="text"
                                          value={this.state.search_driver_phonenumber}
                                          placeholder="ค้นหา"
                                          onChange={event =>
                                            this.setState(
                                              {
                                                search_driver_phonenumber: event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          name="search_driver_phonenumber"
                                        />
                                      </th>
                                      <th></th>
                                      <th>
                                        <FormControl
                                          componentClass="select"
                                          placeholder="select"
                                          defaultValue={this.state.trip_rate}
                                          onChange={event => {
                                            console.log('trip_rate', event.target.value);
                                            this.setState(
                                              {
                                                trip_rate: event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            );
                                          }}
                                          name="trip_rate"
                                        >
                                          <option value={''}>ทั้งหมด</option>
                                          <option value={true}>รายเที่ยว/รายเดือน</option>
                                          <option value={false}>รายเที่ยว</option>
                                        </FormControl>
                                      </th>
                                      <th></th>
                                      <th>
                                        <FormControl
                                          componentClass="select"
                                          placeholder="select"
                                          defaultValue={this.state.search_driver_block}
                                          onChange={event =>
                                            this.setState(
                                              {
                                                search_driver_block: event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          name="search_driver_block"
                                        >
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={1}>กำลังใช้งาน</option>
                                          <option value={2}>ระงับการใช้งาน</option>
                                        </FormControl>
                                      </th>
                                      <th>
                                        <FormControl
                                          componentClass="select"
                                          placeholder="select"
                                          defaultValue={this.state.search_driver_enable}
                                          onChange={event =>
                                            this.setState(
                                              {
                                                search_driver_enable: event.target.value,
                                                page_number: 1,
                                              },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          name="search_driver_enable"
                                        >
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={1}>เปิดรับงาน</option>
                                          <option value={2}>ปิดรับงาน</option>
                                        </FormControl>
                                      </th>
                                      <th>
                                        <DateRangePicker
                                          onApply={(event, picker) =>
                                            this.handleDateRangePicker(event, picker, refetch)
                                          }
                                          onCancel={() =>
                                            this.setState(
                                              { last_update: '', show_last_update: '' },
                                              () => {
                                                refetch();
                                              }
                                            )
                                          }
                                          containerStyles={{ display: 'block' }}
                                          opens="right"
                                          format="DD/MM/YYYY"
                                        >
                                          <FormControl
                                            type="text"
                                            value={this.state.show_last_update}
                                            placeholder="ค้นหาวันที่"
                                            name="last_update"
                                          />
                                        </DateRangePicker>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {loading || error ? (
                                      <tr>
                                        <td colSpan="10" className="textCenter">
                                          {loading ? 'Loading...' : error.message}
                                        </td>
                                      </tr>
                                    ) : (
                                      data.driverUserList.driver_user.map((prop, key) => (
                                        <tr key={key} data-id={prop._id}>
                                          <td style={{ width: '95px' }}>
                                            <OverlayTrigger placement="top" overlay={tooltip}>
                                              <Button
                                                onClick={() =>
                                                  window.open(
                                                    `/driverdetail?id=${prop._id}&agentId=${
                                                      urijs(window.location.href).query(true).agent
                                                    }`,
                                                    '_blank'
                                                  )
                                                }
                                              >
                                                <i
                                                  style={{ color: '#6666666' }}
                                                  className="fas fa-pencil-alt"
                                                ></i>{' '}
                                                แก้ไข
                                              </Button>
                                            </OverlayTrigger>
                                          </td>
                                          <td style={{ width: '100px' }}>
                                            {prop.driver_number ? `#${prop.driver_number}` : ''}
                                          </td>
                                          <td>{prop.driver_name}</td>
                                          <td>{prop.user_setting_truck_license_plate}</td>
                                          <td>{`${prop.driver_phone_code} ${prop.driver_phone_number}`}</td>
                                          <td>{prop.line_id}</td>
                                          <td>
                                            {console.log(
                                              prop.driver_name,
                                              prop.driver_agent_detail
                                            )}
                                            {this.getDriverTripRate(prop.driver_agent_detail) ? (
                                              <>
                                                <i
                                                  style={{ color: '#E13434' }}
                                                  className="fas fa-truck"
                                                ></i>{' '}
                                                รายเที่ยว
                                              </>
                                            ) : (
                                              <>
                                                <i
                                                  style={{ color: '#0088C4' }}
                                                  className="far fa-calendar-alt"
                                                ></i>{' '}
                                                รายเที่ยว/รายเดือน
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            {this.getDriverRouteService(prop.driver_agent_detail)}{' '}
                                            เส้นทาง
                                          </td>
                                          <td style={{ width: '130px' }}>
                                            {prop.driver_block == 1 ? (
                                              <Button bsStyle="link" style={{ color: '#19AA19' }}>
                                                {' '}
                                                กำลังใช้งาน{' '}
                                              </Button>
                                            ) : (
                                              <Button bsStyle="link" style={{ color: '#ED1C24' }}>
                                                {' '}
                                                ระงับการใช้งาน{' '}
                                                <i className="fas fa-times-circle"></i>
                                              </Button>
                                            )}
                                          </td>
                                          <td style={{ width: '130px' }}>
                                            {prop.driver_enable == 1 ? (
                                              <Button bsStyle="link" style={{ color: '#19AA19' }}>
                                                {' '}
                                                เปิดรับงาน{' '}
                                              </Button>
                                            ) : (
                                              <Button bsStyle="link" style={{ color: '#ED1C24' }}>
                                                {' '}
                                                ปิดการรับงาน <i className="fas fa-times-circle"></i>
                                              </Button>
                                            )}
                                          </td>
                                          <td>
                                            <Moment format="DD/MM/YYYY HH:mm">
                                              {prop.last_update}
                                            </Moment>
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </Table>

                                <div style={{ textAlign: 'right' }}>
                                  {loading ? null : (
                                    <ReactPaginate
                                      pageCount={data.driverUserList.total_page}
                                      forcePage={this.state.page_number - 1}
                                      containerClassName="pagination"
                                      nextLabel=">"
                                      previousLabel="<"
                                      onPageChange={({ selected }) => {
                                        this.nextPage(
                                          selected,
                                          data.driverUserList.total_page,
                                          refetch
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div style={{ textAlign: 'right' }}>
                                {loading || error || !data ? null : (
                                  <ReactPaginate
                                    pageCount={data.driverUserList.total_page}
                                    forcePage={this.state.page_number - 1}
                                    containerClassName="pagination"
                                    nextLabel=">"
                                    previousLabel="<"
                                    onPageChange={({ selected }) => {
                                      this.nextPageAgent(
                                        selected,
                                        data.driverUserList.total_page,
                                        refetch
                                      );
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Query>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default compose(
  graphql(accountInfoListListQuery, {
    name: 'accountInfoListListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        _id: props.location.search.split('?agent=')[1],
      },
    }),
  }),
  graphql(settingTruckListQuery, {
    name: 'settingTruckListQuery',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        shipmentType: 1,
      },
    }),
  })
)(BusinessDetail);
