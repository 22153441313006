import gql from 'graphql-tag';

export default gql`
  query AccountInfoList($_id: ID, $account_info_business_name: String, $show_data: Int) {
    accountInfoList(
      _id: $_id
      account_info_type: 3
      account_info_status: 1
      account_info_business_name: $account_info_business_name
      show_data: $show_data
    ) {
      account_info {
        _id
        account_info_business_name
      }
    }
  }
`;
