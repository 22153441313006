import gql from 'graphql-tag';

export default gql`
  query UserSettingTruckList($_id: ID, $user_setting_truck_license_plate: String) {
    userSettingTruckList(
      _id: $_id,
      show_data: 5
      user_setting_truck_license_plate: $user_setting_truck_license_plate
    ) {
      user_setting_truck {
        _id
        user_setting_truck_license_plate
        driver_status
      }
      total_page
    }
  }
`;
