import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  background: #fff;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CenterVerticalSpan = styled.span`
  display: flex;
  align-items: center;
`;

export const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #e3e3e3;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 65vh;
  background: #fff;
`;

export const BoxGridWrapper = styled.div`
  padding: 10px;

  &:nth-child(2) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const UlDecorated = styled.ul`
  decoration: none;
  list-style-type: none;

  & li {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 10px;
    font-weight: 500;
  }
`;

export const HeadWrapper = styled.div`
  display: flex;
  padding: 5px 10px;
  font-weight: bold;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CodNumberStyled = styled.span`
  color: #2393ed;
`;

export const TableWrapper = styled.div`
  position: relative;
  min-height: calc(100% - 30px);
  max-height: calc(100% - 30px);
  background: #fff;

  table thead {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  table thead tr {
    border: unset;
  }
`;

export const Tag = styled.div`
  display: block;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  padding: 5px 15px;
  min-width: 70px;
  width: fit-content;
  background-color: ${props => props.backgroundColor || '#2393ED'}
  color: ${props => props.color || '#fff'}
`;

export const FlexListRow = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;
