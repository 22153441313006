import React from 'react';
import Tabs from '../Tabs';
import urijs from 'urijs';
import { Grid } from 'react-bootstrap';

class Layout extends React.Component {
  getParamUrl = () => {
    const uriRoute = urijs(window.location.href);
    const uriFragment = uriRoute.fragment(true);
    const pathname = uriFragment.pathname();

    return pathname;
  };

  setActive = path => {
    const paramUrl = this.getParamUrl();
    return path === paramUrl;
  };

  openUrl = url => {
    window.location = url;
  };

  render() {
    const menuTabs = [
      {
        icon: 'fas fa-list-ul',
        title: 'Product List',
        active: this.setActive('/ka-product-list'),
        toUrl: '/ka-product-list',
      },
      {
        icon: 'fas fa-file-excel',
        title: 'Import file',
        active: this.setActive('/ka-import-file'),
        toUrl: '/ka-import-file',
      },
      {
        icon: 'fas fa-file-alt',
        title: 'Booking file',
        active: this.setActive('/ka'),
        toUrl: '/ka',
      },
      // {
      //   icon: 'fas fa-truck',
      //   title: 'Shipment list',
      //   active: this.setActive('/ka-shipment-list'),
      //   toUrl: '/ka-shipment-list',
      // },
      {
        icon: 'fas fa-truck',
        title: 'Shipment list',
        active: this.setActive('/ka-shipment-list-new'),
        toUrl: '/ka-shipment-list-new',
      },
      // {
      //   icon: 'fas fa-users-medical',
      //   title: 'แจงงานให้คนขับรถ',
      //   active: this.setActive('/ka-adddriver'),
      //   toUrl: '/ka-adddriver',
      // },
      // {
      //   icon: 'fas fa-address-card',
      //   title: '3PL ที่รับงานได้',
      //   active: this.setActive('/ka-3pl-masterdata'),
      //   toUrl: '/ka-3pl-masterdata',
      // },
      {
        icon: 'far fa-handshake',
        title: 'COD Dashboard',
        active: this.setActive('/ka-cod-dashboard'),
        toUrl: '/ka-cod-dashboard',
      },
    ];

    return (
      <div className="content">
        <Grid fluid>
          <div {...this.props}>
            {!this.props.noMenuTab && <Tabs menu={menuTabs} />}
            <div>{this.props.children}</div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default Layout;
