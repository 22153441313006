import gql from 'graphql-tag';

export default gql`
query search(
    $account_info_id: ID
  ){
    shipmentDashboard(
        shipper : $account_info_id
      ){
        domestic
        import
        export
      }
}
`;
