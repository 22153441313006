import gql from 'graphql-tag';

export default gql`
mutation Update(
    $_id: ID!
    $status: Int
    $receipt_id : ID
  
  ){
    debitNoteUpdate(
        _id: $_id
        status: $status
        receipt_id: $receipt_id
    ){
        _id
    }
}
`;
