import * as React from 'react';

// import { graphql, compose, Query } from 'react-apollo';
import { Grid, Row, Col, Button } from 'react-bootstrap';

import CardTable from 'components/CardTable/CardTable.jsx';
import {
  TEXT_REPORT_DOWNLOAD,
  TEXT_REPORT_TABLE_TITLE,
  TEXT_REPORT_UPLOAD,
} from './utils/useConstants';
import { ReportDownload } from './panels/ReportDownload';
import { ReportUpload } from './panels/ReportUpload';

const ReportCalcurate = () => {
  const [tab, setTab] = React.useState('upload');

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <CardTable
            title="ตารางรายงาน"
            category={TEXT_REPORT_TABLE_TITLE}
            ctTableFullWidth
            ctTableResponsive
            rightButton={
              <div style={{ float: 'right', marginLeft: '15px' }}>
                <Button
                  onClick={() => {
                    if (tab === 'upload') {
                      setTab('download');
                    } else {
                      setTab('upload');
                    }
                  }}
                >
                  <i
                    style={{
                      marginRight: '5px',
                    }}
                    className="fal fa-clipboard-list"
                  ></i>
                  {tab === 'download' ? TEXT_REPORT_DOWNLOAD : TEXT_REPORT_UPLOAD}
                </Button>
              </div>
            }
            content={
              <React.Fragment>
                {tab === 'upload' ? <ReportUpload setTab={setTab} /> : <ReportDownload />}
              </React.Fragment>
            }
          />
        </Col>
      </Row>
    </Grid>
  );
};

export default ReportCalcurate;
