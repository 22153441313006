import React, { Component } from "react";
import { Query } from "react-apollo";
import { Grid, Row, Col} from "react-bootstrap";
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.css';

import driverUserListQuery from "./graphql/query/driverUserList"
import { 
    GoogleMaps, 
    DriverMap,
} from './components';

const Container = styled(Row)`

    & .card_table{
        margin-bottom: 0 !important;
    }
`;

const MapContainner = styled(Col)`
    width: 100%;
    height: calc(100vh - 135px);
    padding: 0 15px;
`;

class DriverLocation extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="content">
                <Grid fluid >
                    <Container>
                        <MapContainner>
                            <DriverMap />
                        </MapContainner> 
                    </Container>
                </Grid>
            </div>
        );
    }
}

export default DriverLocation;