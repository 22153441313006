import gql from 'graphql-tag';

export const querySearch = gql`
  query search(
    $_id: ID
    $report_name: String
    $create_by_id: ID
    $create_by: String
    $status: String
    $create_date: String
    $complete_date: String
    $page_number: Int
    $order_by: String
    $order_type: String
  ) {
    reportCostRateCardResultList(
      show_data: 30
      _id: $_id
      report_name: $report_name
      create_by_id: $create_by_id
      create_by: $create_by
      status: $status
      create_date: $create_date
      complete_date: $complete_date
      page_number: $page_number
      order_by: $order_by
      order_type: $order_type
    ) {
      report {
        _id
        create_by_id
        report_name
        file_result
        create_by
        status
        create_date
        complete_date
        error_result
        __typename
      }
      total_page
      __typename
    }
  }
`;
