import React from 'react';
import { Query, Mutation } from 'react-apollo';
import classes from './SubShipmentTrackingOrder.module.scss';
import cn from '../../ShipmentDetail.module.scss';
import Moment from 'react-moment';
import { Row, Col, Button } from 'react-bootstrap';
import { ShipmentDetailContext } from '../../contexts/ShipmentDetailContext';
import { TrackingList, LightBoxPreview, OrderList, CheckBoxList } from '../../../../components';
import shipmentListQuery from './graphql/query/shipmentList';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';
import lazadaSendTrackPackageStatusMutation from './graphql/mutation/lazadaSendTrackPackageStatus';
import { ModalConfirm } from '../../../../../Lazada/components';
import s3 from 'config/s3';
import moment from 'moment/moment.js';
import { AddSuffixShipment } from '../../components';

class SubShipmentTrackingOrder extends React.Component {
  static contextType = ShipmentDetailContext;
  constructor(props) {
    super(props);

    this.state = {
      isOpenModalLazadaStatus: false,
      mode: '',
      commodity: null,
      deliveryObj: null,
    };
  }

  renderFailureStatusName = (status, detail, deliveryPoint) => {
    if (detail) {
      if (detail == 'ลูกค้าเลื่อนส่ง') {
        return (
          <div>
            ลูกค้าเลื่อนส่ง <br />
            <br />
            {deliveryPoint.postpone_delivery_date && (
              <div>
                <span style={{ fontWeight: 'bold' }}>วันที่ลูกค้าเลื่อนส่ง: </span>
                <Moment format="DD/MM/YYYY - HH:mm">{deliveryPoint.postpone_delivery_date}</Moment>
              </div>
            )}
          </div>
        );
      }
      if (detail == 'ติดต่อลูกค้าไม่ได้') {
        return (
          <div>
            ติดต่อลูกค้าไม่ได้
            <Row>
              {deliveryPoint.failure_picture &&
                deliveryPoint.failure_picture.map(picPath => (
                  <Col md={6}>
                    <LightBoxPreview images={[`${s3}/${picPath}`]} />
                  </Col>
                ))}
            </Row>
          </div>
        );
      } else {
        return detail;
      }
    } else {
      switch (parseInt(status)) {
        case 1:
          return 'ที่อยู่ไม่ถูกต้อง';
        case 2:
          return (
            <div>
              ติดต่อลูกค้าไม่ได้
              <Row>
                {deliveryPoint.failure_picture &&
                  deliveryPoint.failure_picture.map(picPath => (
                    <Col md={6}>
                      <LightBoxPreview images={[`${s3}/${picPath}`]} />
                    </Col>
                  ))}
              </Row>
            </div>
          );
        case 3:
          return 'ลูกค้าปฎิเสธรับสินค้า';
        case 4:
          return 'ส่งช้าเกินคำมั่นสัญาต่อลูกค้า';
        case 5:
          return 'สินค้าราคาซ้ำ';
        case 6:
          return 'สินค้าสูญหาย';
        case 7:
          return 'สินค้าชำรุด';
        case 8:
          return (
            <div>
              ลูกค้าเลื่อนส่ง <br />
              <br />
              {deliveryPoint.postpone_delivery_date && (
                <div>
                  <span style={{ fontWeight: 'bold' }}>วันที่ลูกค้าเลื่อนส่ง: </span>
                  <Moment format="DD/MM/YYYY - HH:mm">
                    {deliveryPoint.postpone_delivery_date}
                  </Moment>
                </div>
              )}
            </div>
          );
        case 9:
          return deliveryPoint.failure_detail ? deliveryPoint.failure_detail : '';
        default:
          return '-';
      }
    }
  };

  renderCommodityDetail = commodity => {
    let commodityDetailList = [];

    commodityDetailList.push({
      title: 'หยิบสินค้าที่ต้องส่ง ณ จุดนี้',
      content: (
        <>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>
              {AddSuffixShipment(commodity.account_info)}
              {commodity.name}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>Order number:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>{commodity.order_number || '-'}</div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>สินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {commodity.name_detail || '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ชื่อผู้รับสินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>
              {commodity.contact_name ? `${commodity.contact_name}, ${commodity.contact_tel}` : '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ที่อยู่:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold} ${cn['no-pb']}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {commodity.address || '-'}
            </div>
          </div>
        </>
      ),
      active: true,
      addNew: commodity.accept == 1 && commodity.add_new == 1,
    });
    let pictures = [];
    if (commodity.commodity_picture && commodity.commodity_picture.length > 0) {
      pictures = commodity.commodity_picture.map(item => `${s3}/${item}`);
    }
    if (commodity.damage_picture && commodity.damage_picture.length > 0) {
      commodity.damage_picture.map(item => pictures.push(`${s3}/${item}`));
    }
    commodityDetailList.push({
      title: 'ตรวจสอบสภาพสินค้า โดยการถ่ายรูป',
      content: (
        <LightBoxPreview
          images={pictures}
          position={commodity.check_lat ? `${commodity.check_lat},${commodity.check_lng}` : null}
        />
      ),
      active: pictures.length > 0,
    });

    return commodityDetailList.map((item, index) => (
      <CheckBoxList
        key={index}
        title={item.title}
        subTitle={item.subTitle}
        active={item.active}
        addNew={item.addNew}
      >
        {item.content}
      </CheckBoxList>
    ));
  };

  renderReturnCommodityDetail = returnPoint => {
    const deliveryPoint =
      returnPoint.delivery_point[this.context.state.subShipmentTrackingOrderIndex];
    let commodityDetailList = [];
    console.log(deliveryPoint);
    commodityDetailList.push({
      title: 'หยิบสินค้าที่ต้องคืน ณ จุดนี้',
      content: (
        <>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>
              {AddSuffixShipment(deliveryPoint.account_info)}
              {deliveryPoint.commodity[0].name}
            </div>
          </div>
          {
            // deliveryPoint.send_failed_status !== 1 && deliveryPoint.lazada_order_api ? (
            //   <div className={`${cn.RowItem} ${cn.mt10}`}>
            //     <div className={cn.Left}>ยกเลิกรับสินค้า</div> <br />
            //     <div className={cn.Left}>
            //       <Button style={{ background: "#49acf1", color: "#ffffff", opacity: 1 }} onClick={() => this.setState({ isOpenModalLazTrackingUpdate: true, commodity: deliveryPoint.commodity[0], deliveryObj: returnPoint })}> <i className="fal fa-reload"></i> ยกเลิกรับสินค้า </Button>
            //     </div>
            //   </div>
            // ) : deliveryPoint.send_failed_status === 1 && (
            //   <div className={`${cn.RowItem} ${cn.mt10}`}>
            //     <div className={cn.Left}>ยกเลิกรับสินค้า</div> <br />
            //     <div className={cn.Left}>
            //       <b className={cn.textRed}>ยกเลิกรับสินค้าเรียบร้อย</b>
            //     </div>
            //   </div>
            // )
          }

          {
            // deliveryPoint.lazada_order_damaged && deliveryPoint.lazada_order_api ? (
            //   <div className={`${cn.RowItem} ${cn.mt10}`}>
            //     <div className={cn.Left}>ส่งสถานะให้ลาซาด้า</div> <br />
            //     <div className={cn.Left}>
            //       <Button style={{ background: "#D90101", color: "#ffffff", opacity: 1 }} onClick={() => this.setState({ isOpenModalLazadaStatus: true, commodity: deliveryPoint.commodity[0], deliveryObj: returnPoint })}> <i className="fal fa-reload"></i> สินค้าบรรจุภัณฑ์ชำรุด </Button>
            //     </div>
            //   </div>
            // ) : deliveryPoint.send_package_damaged && (
            //   <div className={`${cn.RowItem} ${cn.mt10}`}>
            //     <div className={cn.Left}>ส่งสถานะให้ลาซาด้า</div> <br />
            //     <div className={cn.Left}>
            //       <b className={cn.textRed}>ส่งสถานะให้ลาซาด้าว่าสินค้าบรรจุภัณฑ์ชำรุดเรียบร้อย</b>
            //     </div>
            //   </div>
            // )
          }
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>Order number:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>{deliveryPoint.commodity[0].order_number || '-'}</div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>สินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {deliveryPoint.commodity[0].name_detail || '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ชื่อผู้รับสินค้า:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left}>
              {deliveryPoint.commodity[0].contact_name
                ? `${deliveryPoint.commodity[0].contact_name}, ${deliveryPoint.commodity[0].contact_tel}`
                : '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>ที่อยู่:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold}`}>
            <div className={cn.Left} style={{ lineHeight: 1.5 }}>
              {deliveryPoint.address || '-'}
            </div>
          </div>
          <div className={`${cn.RowItem} ${cn.mt10}`}>
            <div className={cn.Left}>เหตุผล:</div>
          </div>
          <div className={`${cn.RowItem} ${cn.textBold} ${cn['no-pb']}`}>
            <div className={cn.Left}>
              {this.renderFailureStatusName(
                deliveryPoint.failure_status,
                deliveryPoint.failure_detail,
                deliveryPoint
              )}
            </div>
          </div>
        </>
      ),
      active: true,
    });

    // console.log("renderReturnCommodityDetail" , returnPoint)

    let pictures = deliveryPoint.commodity_picture_return.map(item => `${s3}/${item}`);
    commodityDetailList.push({
      title: 'ตรวจสอบสภาพสินค้า โดยการถ่ายรูป',
      content: (
        <>
          <LightBoxPreview
            images={pictures}
            position={`${returnPoint.check_commodity_return_lat},${returnPoint.check_commodity_return_lng}`}
          />
          {deliveryPoint.return_status && (
            <div className={`${cn.RowItem} ${cn.mt10}`}>
              <div className={cn.Left}>สภาพสินค้า: </div>
              <b>{deliveryPoint.return_status == 1 ? 'สินค้าชำรุด' : 'สินค้าสภาพสมบูรณ์'}</b>
            </div>
          )}
        </>
      ),
      active: pictures.length > 0,
    });

    return commodityDetailList.map((item, index) => (
      <CheckBoxList key={index} title={item.title} subTitle={item.subTitle} active={item.active}>
        {item.content}
      </CheckBoxList>
    ));
  };

  renderVariables() {
    const { commodity, deliveryObj } = this.state;
    const obj = [];
    const data = deliveryObj
      ? deliveryObj.delivery_point.filter(
          (element, index, array) => element.commodity[0].name === commodity.name
        )
      : [];

    if (data.length > 0) {
      data.forEach((currentValue, index, arr) => {
        obj.push({
          _id: currentValue._id,
          send_failed_status: 1,
        });
      });
    }
    return obj;
  }

  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>
          {this.context.state.subShipmentTrackingOrderTitle}
          <button
            className={cn.BtnClose}
            onClick={() => this.context.onClose(['subShipmentTrackingOrder'])}
          >
            <i className="fal fa-times"></i>
          </button>
        </div>

        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            if (loading) return 'loading...';
            if (error) return error;

            const { shipmentList } = data;
            const shipment = shipmentList.shipment[0];

            return (
              <div>
                <div className={cn.TrackingWrapper}>
                  <div>
                    {this.context.state.subShipmentTrackingOrderMode === 'pickup' &&
                      this.renderCommodityDetail(
                        shipment.tracking.tracking_detail_lazada.pick_up[
                          this.context.state.subShipmentTrackingIndex
                        ].commodity[this.context.state.subShipmentTrackingOrderIndex]
                      )}

                    {this.context.state.subShipmentTrackingOrderMode === 'return' &&
                      this.renderReturnCommodityDetail(
                        shipment.tracking.tracking_detail_lazada.return[
                          this.context.state.subShipmentTrackingIndex
                        ]
                      )}
                  </div>
                </div>

                <Mutation
                  mutation={lazadaSendTrackPackageStatusMutation}
                  variables={{
                    barcode: this.state.commodity ? this.state.commodity.name : '',
                    status: this.state.mode === 'damaged' ? 'package_damaged' : '',
                    statusDescription: this.state.mode === 'damaged' ? 'พัสดุเสียหายโดยขนส่ง' : '',
                    statusDate: moment().format('DD/MM/YYYY HH:mm:ss'),
                  }}
                  update={(cache, { data }) => {
                    this.setState({
                      isOpenModalLazadaStatus: false,
                      commodity: null,
                      deliveryObj: null,
                    });
                  }}
                  refetchQueries={() => ['LazadaShipmentListDetailSubTrackingOrder']}
                >
                  {lazadaSendTrackPackageStatu => {
                    return (
                      <ModalConfirm
                        show={this.state.isOpenModalLazadaStatus}
                        onPressButtonCancel={() =>
                          this.setState({ isOpenModalLazadaStatus: false })
                        }
                        onPressButtonConfirm={() => lazadaSendTrackPackageStatu()}
                        labelButtonConfirm={`ตกลง`}
                        labelButtonCancel={`กลับ`}
                        styleColorButtonConfirm="primary"
                        titleHeader={`ส่งสถานะให้ลาซาด้า`}
                        onHide={() => this.setState({ isOpenModalLazadaStatus: false })}
                      >
                        <div>
                          คุณต้องการส่งสถานะให้ลาซาด้า งาน{' '}
                          <b>{this.state.commodity ? this.state.commodity.name : ''}</b> ใช่หรือไม่
                          ถ้าใช่ กด <b>"ตกลง"</b>
                        </div>
                      </ModalConfirm>
                    );
                  }}
                </Mutation>

                <Mutation
                  mutation={lazTrackingUpdateMutation}
                  variables={{
                    shipment_id: shipment._id,
                    _id: shipment.tracking._id,
                    tracking_detail_lazada: {
                      delivery: this.renderVariables(),
                    },
                  }}
                  update={(cache, { data }) => {
                    this.setState({
                      isOpenModalLazTrackingUpdate: false,
                      commodity: null,
                      deliveryObj: null,
                    });
                  }}
                  refetchQueries={() => ['LazadaShipmentListDetailSubTracking']}
                >
                  {lazTrackingUpdate => {
                    return (
                      <ModalConfirm
                        show={this.state.isOpenModalLazTrackingUpdate}
                        onPressButtonCancel={() =>
                          this.setState({ isOpenModalLazTrackingUpdate: false })
                        }
                        onPressButtonConfirm={() => lazTrackingUpdate()}
                        labelButtonConfirm={`ตกลง`}
                        labelButtonCancel={`กลับ`}
                        styleColorButtonConfirm="primary"
                        titleHeader={`ส่งสถานะให้ลาซาด้า`}
                        onHide={() => this.setState({ isOpenModalLazTrackingUpdate: false })}
                      >
                        <div>
                          คุณต้องการยกเลิกการส่งสินค้า งาน{' '}
                          <b>{this.state.commodity ? this.state.commodity.name : ''}</b> ใช่หรือไม่
                          ถ้าใช่ กด <b>"ตกลง"</b>
                        </div>
                      </ModalConfirm>
                    );
                  }}
                </Mutation>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default SubShipmentTrackingOrder;
