import gql from 'graphql-tag';

export default gql`
  mutation Update(
    $_id: ID!
    $_id_detail: ID!
    $position: String!
    $droppoint: Int
    $track_driver_add: [type_update_track_driver_add_import]
    $track_driver_update: [type_update_track_driver_update_import]
    $accept_container_add: [type_update_accept_container_add_import]
    $accept_container_update: [type_update_accept_container_update_import]
    $track_driver_date: String
    $accept_container_date: String
    $return_container_date: String
  ) {
    trackingUpdate(
      _id: $_id
      tracking_import_detail_update: [
        {
          _id: $_id_detail
          position: $position
          droppoint: $droppoint
          track_driver_add: $track_driver_add
          track_driver_update: $track_driver_update
          track_driver_date: $track_driver_date
          accept_container_add: $accept_container_add
          accept_container_update: $accept_container_update
          accept_container_date: $accept_container_date
          return_container_date: $return_container_date
        }
      ]
    ) {
      _id
    }
  }
`;
