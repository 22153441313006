import { CardTable } from 'components';
import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  FormControl,
  Modal,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { CostPerParcelEachBox } from './render/CostPerPacelBoxHeader';
import CostPerParcelModalData from './render/CostPerPacelModalData';
import CostPerParcelModalFile from './render/CostPerPacelModalFile';
import { TEXT_TOP_COST, TEXT_TOP_INCOME, TEXT_TOP_NUM, TEXT_TOP_SUM } from './utils/useConstants';
import { Query } from 'react-apollo';
import parcelCostList from './graphql/parcelCostList';
import parcelCost from './graphql/parcelCost';
import billingParcel from './graphql/billingParcelList';
import moment from 'moment';
import SelectCustomer from './components/FieldSelectCustomer';
import formatCurrency from 'utils/formatCurrency';

const Container = styled(Row)`
  & .card_table {
    margin-bottom: 0 !important;
  }
`;
const FormatStatus = styled.div`
  background: #fff;
  color: #000;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  width: 70%;
  font-weight: bold;

  &.none {
    border: 2px solid black;
  }

  &.black {
    background: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
  }

  &.blue {
    background: #337ab7;
    color: rgb(255, 255, 255);
  }

  &.red {
    background: #c63b3c;
    color: rgb(255, 255, 255);
  }

  &.yellow {
    background: #e89c37;
    color: rgb(255, 255, 255);
  }

  &.green {
    background: #81b214;
    color: rgb(255, 255, 255);
  }

  &.emerald {
    background: #82c5b9;
    color: rgb(255, 255, 255);
  }

  &.velvet {
    background: #d54062;
    color: rgb(255, 255, 255);
  }
`;

const RenderStatus = status => {
  switch (status) {
    case 1:
      return <FormatStatus className="black">คงคลังลูกค้า</FormatStatus>;
    case 2:
      return <FormatStatus className="none">แพลนไปรับสินค้า</FormatStatus>;
    case 3:
      return <FormatStatus className="yellow">กำลังไปคลัง GSC</FormatStatus>;
    case 4:
      return <FormatStatus className="none">Failed นำสินค้าออกจาก Lazada</FormatStatus>;
    case 5:
      return <FormatStatus className="blue">คงคลัง Giztix</FormatStatus>;
    case 6:
      return <FormatStatus className="red">ยกเลิก</FormatStatus>;
    case 7:
      return <FormatStatus className="emerald">ออกจัดส่งลูกค้า</FormatStatus>;
    case 8:
      return <FormatStatus className="green">ส่งสำเร็จ</FormatStatus>;
    case 9:
      return <FormatStatus className="velvet">คืนคลังลูกค้า</FormatStatus>;
    default:
      return <div> - </div>;
  }
};

const CostPerParcel = () => {
  const [openFile, setOpenFile] = React.useState(false);
  const [dateCost, setDateCost] = React.useState(
    `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`
  );
  const [tracking, setTracking] = React.useState('');
  const [customer, setCustomer] = React.useState('');
  const [status, setStatus] = React.useState(0);
  const [page, setPage] = React.useState(1);

  let params = {};

  if (status) {
    params.status = status;
  }
  if (tracking) {
    params.trackingNumber = tracking;
  }
  if (customer) {
    params.customer = customer;
  }
  if (dateCost) {
    params.deliveredDate = dateCost;
  }

  return (
    <>
      <div className="content">
        <Grid fluid>
          <Container>
            <Col md={12}>
              <CardTable
                title={`รายการสินค้าช่วงวันที่ : ${dateCost}`}
                category=""
                ctTableFullWidth
                ctTableResponsive
                rightButton={
                  <div style={{ float: 'right', marginLeft: '15px' }}>
                    <Button
                      bsStyle="primary"
                      onClick={() => {
                        setOpenFile(true);
                      }}
                    >
                      <i className="fal fa-file"></i> อัพเดทไฟล์ / แก้ไข
                    </Button>
                  </div>
                }
                content={
                  <div style={{ overflowY: 'scroll', height: '70vh' }}>
                    <Query
                      query={billingParcel}
                      variables={{
                        // dateCost: dateCost,
                        params: params,
                        page: page,
                      }}
                    >
                      {({ loading, error, data }) => {
                        let parcels = data?.billingParcelList?.parcels;

                        return (
                          <React.Fragment>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                position: 'sticky',
                                zIndex: 2,
                                top: 0,
                                backgroundColor: '#fff',
                                paddingBottom: '20px',
                              }}
                            >
                              <CostPerParcelEachBox
                                icon={'fas fa-box'}
                                title={TEXT_TOP_NUM}
                                value={`${formatCurrency(
                                  data?.billingParcelList?.totalDocument || 0
                                )} ชิ้น`}
                              />
                              <CostPerParcelEachBox
                                icon={'fas fa-hand-holding-usd'}
                                title={TEXT_TOP_SUM}
                                value={`${formatCurrency(
                                  data?.billingParcelList?.totalCostAvg || 0
                                )}/${formatCurrency(
                                  data?.billingParcelList?.parcelTarget || 0
                                )} บาท`}
                              />
                              <CostPerParcelEachBox
                                icon={'fas fa-usd-circle'}
                                title={TEXT_TOP_COST}
                                value={`${formatCurrency(
                                  data?.billingParcelList?.totalCost || 0
                                )} บาท `}
                              />
                              <CostPerParcelEachBox
                                icon={'fas fa-money-check-alt'}
                                title={TEXT_TOP_INCOME}
                                value={`${formatCurrency(
                                  data?.billingParcelList?.totalRevenue || 0
                                )} บาท `}
                                colorValue={true}
                              />
                            </div>
                            <Table
                              striped
                              hover
                              responsive
                              style={{
                                overflowY: 'scroll',
                                height: '10vh',
                              }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ minWidth: '50px', fontSize: '18px', color: '#000' }}>
                                    เครื่องมือ
                                  </th>
                                  <th
                                    style={{ minWidth: '100px', fontSize: '18px', color: '#000' }}
                                  >
                                    วันที่ส่งสำเร็จ
                                  </th>
                                  <th
                                    style={{ minWidth: '160px', fontSize: '18px', color: '#000' }}
                                  >
                                    Tracking Number
                                  </th>
                                  <th
                                    style={{ minWidth: '200px', fontSize: '18px', color: '#000' }}
                                  >
                                    ชื่อลูกค้า
                                  </th>
                                  <th
                                    style={{ minWidth: '160px', fontSize: '18px', color: '#000' }}
                                  >
                                    สถานะ
                                  </th>
                                  <th
                                    style={{ minWidth: '100px', fontSize: '18px', color: '#000' }}
                                  >
                                    ต้นทุน(บาท)
                                  </th>
                                  <th
                                    style={{ minWidth: '100px', fontSize: '18px', color: '#000' }}
                                  >
                                    รายได้(บาท)
                                  </th>
                                  <th
                                    style={{ minWidth: '100px', fontSize: '18px', color: '#000' }}
                                  >
                                    กำไร/ขาดทุน(บาท)
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ width: '20px' }}></th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(e, p) => {
                                        setDateCost(
                                          `${p.startDate.format('YYYY-MM-DD')}:${p.endDate.format(
                                            'YYYY-MM-DD'
                                          )}`
                                        );
                                        setPage(1);
                                      }}
                                      startDate={
                                        dateCost ? moment(dateCost.split(':')[0]) : moment()
                                      }
                                      endDate={dateCost ? moment(dateCost.split(':')[1]) : moment()}
                                      onCancel={() => {
                                        setDateCost('');
                                      }}
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={dateCost}
                                        placeholder="ค้นหาวันที่"
                                        name="deliveredDate"
                                      />
                                    </DateRangePicker>
                                  </th>

                                  <th style={{ maxWidth: '90px' }}>
                                    <FormControl
                                      type="text"
                                      placeholder="ค้นหา"
                                      value={tracking}
                                      onChange={event => {
                                        setTracking(event.target.value);
                                        setPage(1);
                                      }}
                                      name="trackingCost"
                                    />
                                  </th>

                                  <th>
                                    <SelectCustomer
                                      value={customer}
                                      onChange={event => {
                                        setCustomer(event.target.value);
                                        setPage(1);
                                      }}
                                      selectLabel="ทั้งหมด"
                                    />
                                  </th>

                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      onChange={event => {
                                        setStatus(event.target.value);
                                        setPage(1);
                                      }}
                                      name="search_driver_block"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={3}>กำลังไปคลัง GSC</option>
                                      <option value={5}>คงคลัง Giztix</option>
                                      <option value={7}>ออกจัดส่งลูกค้า</option>
                                      <option value={8}>ส่งสำเร็จ</option>
                                      <option value={9}>คืนคลังลูกค้า</option>
                                    </FormControl>
                                  </th>
                                  <th style={{ width: '20px' }}></th>
                                  <th style={{ width: '20px' }}></th>
                                  <th style={{ width: '20px' }}></th>
                                </tr>
                              </thead>

                              <tbody>
                                {parcels?.map(row => {
                                  return (
                                    <>
                                      <tr>
                                        <td
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <CostPerParcelModalData dataParcel={row} />
                                        </td>
                                        <td>
                                          {row.deliveredDate ? (
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {row?.deliveredDate}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td> {row?.trackingNumber} </td>
                                        <td> {row?.customer?.full_name} </td>

                                        {/* {row?.statusPer === 'ส่งสำเร็จ' ? (
                                    <td style={{ color: ' #00b24e' }}> {`row?.statusPer`}</td>
                                  ) : (
                                    <td style={{ color: '#3379b7' }}> {`row?.statusPer`}</td>
                                  )} */}
                                        <td>{RenderStatus(row?.status)}</td>

                                        <td> {formatCurrency(row?.cost || 0)}</td>
                                        <td>
                                          {' '}
                                          {formatCurrency(row?.parcelRevenue?.totalRevenue || 0)}
                                        </td>
                                        <td>
                                          {formatCurrency(
                                            Number(row?.parcelRevenue?.totalRevenue || 0) -
                                              Number(row?.cost || 0)
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <div
                              style={{
                                textAlign: 'right',
                                marginTop: 20,
                              }}
                            >
                              <div
                                style={{
                                  textAlign: 'right',
                                  marginTop: 10,
                                  marginBottom: 20,
                                }}
                              >
                                <ReactPaginate
                                  pageCount={data?.billingParcelList?.totalPage || 0}
                                  forcePage={0}
                                  containerClassName="pagination"
                                  nextLabel=">"
                                  previousLabel="<"
                                  onPageChange={({ selected }) => {
                                    setPage(selected + 1);
                                  }}
                                />
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      }}
                    </Query>

                    {openFile && <CostPerParcelModalFile open={openFile} setOpen={setOpenFile} />}
                  </div>
                }
              />
            </Col>
          </Container>
        </Grid>
        <div></div>
      </div>
    </>
  );
};

export default CostPerParcel;
