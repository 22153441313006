import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Tooltip,
  Modal,
  Button,
  Label,
  NavDropdown,
  NavItem,
  ControlLabel,
  FormControl,
  FormGroup,
} from 'react-bootstrap';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';

import moment from 'moment/moment.js';

import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import transporterInfoListQuery from './graphql/query/transporterInfoList';

import singleUploadMutation from './graphql/mutation/singleUpload';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import shipmentListQuery from './graphql/query/shipmentList';

import summaryInvoiceListQuery from './graphql/query/summaryInvoiceList';
import waitingMakeDebitNoteListQuery from './graphql/query/waitingMakeDebitNoteList';
import debitNoteListQuery from './graphql/query/debitNoteList';
import receiptSummaryListQuery from './graphql/query/receiptSummaryList';
import makeDebitNoteManualMutation from './graphql/mutation/makeDebitNoteManual';
import debitNoteUpdateMutation from './graphql/mutation/debitNoteUpdate';
import summaryInvoiceUpdateMutation from './graphql/mutation/summaryInvoiceUpdate';
import payInvoiceMutation from './graphql/mutation/payInvoice';
import receiptSummaryUpdateMutation from './graphql/mutation/receiptSummaryUpdate';

import CardTable from 'components/CardTable/CardTable.jsx';
import styled from 'styled-components';
import { client } from '../..';
import baseUrl from 'config/baseUrl';
import { PDFPreview, SelectBox, ModalConfirm } from './components';
import s3 from 'config/s3';

import imgSCBBank from './../../assets/img/scbbank-logo.png';

const tooltip = <Tooltip id="tooltip">ดูข้อมูล</Tooltip>;

const tooltip1 = <Tooltip id="tooltip">เพิ่มคนขับ</Tooltip>;

const tooltip2 = <Tooltip id="tooltip">ยกเลิกงาน</Tooltip>;

let driverList = [];
let truckList = [];
let driverItem = [];
let truckItem = [];

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  { value: 'ลูกค้าต้องการใช้บริการเสริม', label: 'ลูกค้าต้องการใช้บริการเสริม' },
];

const Container = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    // position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 4px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 13px;
        position: relative;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

const TabContentContainer = styled.div`
  & .tab-item {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 220px);
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

const Line = styled.hr`
  margin-bottom: 10px;
  margin-top: 5px;
  margin-right: 21px;
  float: left;
  width: calc(100% - 15px);
`;

const ShipmentItem = styled.div`
  color: #000000;
  padding: 0;
  font-size: 18px;
  line-height: 1;
  float: left;
  width: calc(100% - 15px);
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 10px;
  cursor: pointer;

  &.selected {
    color: #d90101;
  }

  & .icon {
    float: left;
    width: 40px;
    padding-top: 5px;
    text-align: center;
  }
  & .icon i {
    font-size: 24px;
  }
  & .righticon {
    float: left;
    width: 130px;
    padding-top: 10px;
    text-align: center;
  }
  & .righticon i {
    font-size: 18px;
  }
  & .detail {
    float: left;
    width: calc(100% - 180px);
    padding-bottom: 10px;
  }
  & .detail b {
    float: left;
    width: 100%;
    margin-bottom: 5px;
  }
  & .detail span {
    float: left;
    width: 100%;
  }
`;
const RowItemGroup = styled.div`
  display: block;
  float: left;
  width: 100%;
  & .left {
    display: block;
    float: left;
    font-weight: normal;
    width: calc(100% - 100px);
  }
  & .right {
    display: block;
    float: right;
    text-align: right;
    font-weight: bold;
    padding-right: 15px;
    padding-top: 20px;
  }
`;

const RowItem = styled.div`
  color: #000000;
  padding: 0 15px 10px 0;
  font-size: 18px;
  line-height: 1;
  width: 100%;
  float: left;

  & .left {
    display: contents;
    float: left;
  }

  & .right {
    display: block;
    float: right;
    text-align: right;
  }

  & .total {
    font-size: 18px;
    color: #d90001;
    font-weight: bold;
  }
`;

const RowItemList = styled.div`
  color: #000000;
  padding: 0 15px 10px 0;
  font-size: 18px;
  line-height: 1;

  & .left {
    display: contents;
    float: left;

    & i {
      padding-right: 10px;
      font-size: 18px;
      color: #bcbcbc;
    }
  }

  &.selected i {
    color: #19aa19;
  }

  & .right {
    display: block;
    float: right;
    text-align: right;

    & i {
      font-size: 18px;
      color: #bcbcbc;
    }
  }
`;

const Blankspace = styled.div`
  height: 10px;
  width: calc(100% + 10px);
  background-color: #f7f7f8;
  margin-left: -25px;
  margin-right: -20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const EmptyWrapper = styled.div`
  width: 100%;
  float: left;
  text-align: center;
  padding: 30px 0;

  & i {
    width: 100%;
    float: left;
    font-size: 50px;
    margin-bottom: 10px;
    color: #bcbcbc;
  }
  & b {
    width: 100%;
    float: left;
    margin-bottom: 5px;
  }
  & small {
    width: 100%;
    float: left;
  }
`;

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)`
  & a {
    & i {
    }
  }
`;

const TitleLeft = styled.h5`
  font-weight: bold;
  margin-top: 20px;
  border-left: 2px solid #d90402;
  padding-left: 10px;
`;

const PaymentDetailContainer = styled.div`
  position: relative;

  & .paymentDetail-main {
    float: left;
    width: 400px;
    height: calc(100vh - 180px);
    border-right: 1px solid #efefef;
  }

  & .paymentDetail-sub {
    position: absolute;
    left: 400px;
    width: calc(100% - 400px);
    height: calc(100vh - 180px);
    background-color: rgba(0, 0, 0, 0.5);

    & .inner {
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #fff;
      width: 400px;
      height: calc(100vh - 180px);
      border-right: 1px solid #efefef;
      box-shadow: inset 4px 0px 6px -5px rgba(0, 0, 0, 0.2);
    }
  }

  & .paymentDetail-pdf {
    float: left;
    width: calc(100% - 400px);
    height: calc(100vh - 180px);
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
    }

    & .content{
        float: left;
        display: block;
        padding-bottom: 25px;
    }
    
    & .detail {
        width : 100%;
        float:left;
        
        & .left {
            width : 150px;
            float:left;
        }
        & .right {
            float:left;
        }
	}

	& .footer {
		text-align: right;
		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const ImageProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  & .image-product {
    width: 86px;
    height: 86px;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;
    cursor: pointer;

    & img {
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    margin-right: 10px;
    margin-bottom: 10px;
  }

  & .image-product-hover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000030;
    border-radius: 4px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  & .image-product:hover {
    .image-product-hover {
      display: block;
    }
  }
`;

const TextControlLabel = styled(ControlLabel)`
    width: 100%;
    font-size:18px; !important;
    color: #000000 !important;

    & small {
        color: #888888;
        float: right;
        cursor: pointer;
    }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;

  & .image {
    width: 86px !important;
    height: 86px !important;
    border: 1px solid #e3e3e3;
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;

    & img {
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
    }

    margin-right: 10px;
    margin-bottom: 10px;
  }

  .image-hover {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #00000080;
    border-radius: 4px;
    z-index: 999;
    width: 100%;
    height: 100%;
  }

  .image:hover {
    .image-hover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
    }
  }

  .btn-hover-image {
    color: white;
    font-size: 16px;
    cursor: pointer;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;

    & i {
      margin-right: 5px;
    }
  }
`;

let params = new URLSearchParams(document.location.search.substring(1));
let id = params.get('id');
let type = params.get('type');

class PaymentDetail extends Component {
  constructor(props) {
    super(props);

    // let invoiceList = "";
    // if (props.invoiceList.loading == false && props.invoiceList.summaryInvoiceList != undefined) {
    //     invoiceList = props.invoiceList.summaryInvoiceList.summaryInvoice;
    // }

    // let debitNoteList = "";
    // if (this.props.debitNoteList.loading == false && this.props.debitNoteList.debitNoteList != undefined) {
    //     debitNoteList = this.props.debitNoteList.debitNoteList.debitNote;
    // }

    // //console.log(this.props.receiptList);
    // let receiptList = "";
    // if (this.props.receiptList.loading == false && this.props.receiptList.receiptSummaryList != undefined) {
    //     receiptList = this.props.receiptList.receiptSummaryList.receipt;
    // }

    this.state = {
      mode: type,
      selectIndex: null,
      summary_invoice_id: id,
      status_invoice: [],
      status_debitnote: [],
      status_receipt: [],
      pdfFile: null, //"http://www.pdf995.com/samples/pdf.pdf"
      openBanktranfer: false,
      waitingDebitNoteList: [],
      waitingDebitNoteselected: [],
      TotalWaitingDebitNote: 0,
      TotalWaitingDebitNoteVat: 0,
      TotalWaitingDebitNoteWht1: 0,
      TotalWaitingDebitNoteWht3: 0,
      TotalWaitingDebitNoteWht5: 0,
      GrandtotalWaitingDebitNote: 0,

      isOpenModalInvoiceUpdateStatus: false,
      isOpenModalDebitNoteUpdateStatus: false,

      debitnote_key: null,
      debitnote_number: null,
      debitnote_status: null,

      _id: null,
      debitnote_id: null,
      receipt_id: null,
      tranfer_date: moment(),
      tranfer_date_send: null,
      tranfer_amount: '',
      tranfer_picture: null,

      isOpeTranferLightbox: false,

      receipt_date: null,
      receipt_date_send: null,
      show_editcost: false,
      credit_used: '',
      summary_total_price: '',
    };
    this._inputUploadImage = React.createRef();
    this._inputUploadInvoice = React.createRef();
    this._inputUploadDebitNote = React.createRef();
    this._inputUploadRecipt = React.createRef();
  }

  renderUpdateStatusVariables(id, status, receipt_id) {
    let obj = null;
    if (status == 3) {
      obj = {
        _id: id,
        receipt_id: receipt_id,
        receipt_date: this.state.receipt_date_send,
        status: status,
      };
    } else {
      obj = {
        _id: id,
        status: status,
      };
    }
    return obj;
  }
  renderVariableseditinvoice(id) {
    let obj = {
      _id: id,
    };
    if (this.state.summary_total_price) {
      obj['summary_total_price'] = this.state.summary_total_price;
    }
    if (this.state.credit_used) {
      obj['credit_used'] = this.state.credit_used;
    }
    return obj;
  }

  onOpenbanktranfer(transfer, _id, key, tranfer_amount, debitnote_id, receipt_id) {
    if (transfer == null) {
      this.setState({
        openBanktranfer: true,
        tranfer_date: moment().format('DD/MM/YYYY HH:mm'),
        tranfer_date_send: moment().format('YYYY-MM-DD HH:mm'),
        tranfer_amount: parseFloat(tranfer_amount).toFixed(2),
        tranfer_picture: null,
        _id: _id,
        debitnote_id: debitnote_id,
        receipt_id: receipt_id,
        selectIndex: key,
      });
    } else {
      this.setState({
        openBanktranfer: true,
        tranfer_date: moment(transfer.transfer_date).format('DD/MM/YYYY HH:mm'),
        tranfer_date_send: moment(transfer.transfer_date).format('YYYY-MM-DD HH:mm'),
        tranfer_amount: transfer.transfer_price,
        tranfer_picture: transfer.transfer_pic,
        _id: _id,
        debitnote_id: debitnote_id,
        receipt_id: receipt_id,
        selectIndex: key,
      });
    }
  }
  /* Image */
  checkFileTypeImage(file) {
    if (file) {
      if (file.type === 'image/png' || file.type === 'image/jpeg') {
        return true;
      } else {
        return false;
      }
    }
  }

  checkFileSizeImage(file) {
    if (file) {
      const size = parseFloat(file.size / 1024).toFixed(2);
      if (size < 10240) {
        //ขนาดไฟล์ 10240KB or 10Mb
        return true;
      } else {
        return false;
      }
    }
  }

  handleSingleUploadDocumentfile(uploadFile, documentName, event) {
    const checkFileType = event.target.files[0].type === 'application/pdf';
    if (checkFileType === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท pdf เท่านั้น',
      });
      return;
    }

    const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
    if (checkFileSize === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 10MB',
      });
      return;
    }

    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/payments/${documentName}_${timestamp}`,
        },
      });
    }
  }

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const checkFileTypeImage = this.checkFileTypeImage(event.target.files[0]);
    if (checkFileTypeImage === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท png, jpg เท่านั้น',
      });
      return;
    }

    const checkFileSize = this.checkFileSizeImage(event.target.files[0]);
    if (checkFileSize === false) {
      this.setState({
        isOpenModalAlertImage: true,
        textAlertModalImage: 'ไฟล์รูปภาพต้องมีขนาดไม่เกิน 10MB',
      });
      return;
    }

    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity) {
      const timestamp = new Date().getTime();
      uploadFile({
        variables: {
          file: file,
          path: `upload/payments/${documentName}_${timestamp}`,
        },
      });
    }
  }

  onDeletePhoto(file) {
    this.setState({
      tranfer_picture: null,
    });
  }

  checkValidateAddTranfer() {
    const { tranfer_date, tranfer_amount, tranfer_picture } = this.state;

    if (tranfer_date && tranfer_amount && tranfer_picture) {
      return true;
    }

    return false;
  }

  onSubmitAddTranfer(e, payInvoice, receiptSummaryUpdate) {
    const chkError = this.checkValidateAddTranfer();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const {
        _id,
        tranfer_date_send,
        tranfer_amount,
        tranfer_picture,
        debitnote_id,
        receipt_id,
      } = this.state;

      if (receipt_id) {
        receiptSummaryUpdate({
          variables: {
            _id: receipt_id,
            paid_bank_transfer: {
              transfer_price: tranfer_amount,
              transfer_pic: tranfer_picture,
              transfer_date: tranfer_date_send,
            },
          },
        });
      } else {
        payInvoice({
          variables: {
            _id: _id,
            debit_note_id: debitnote_id,
            paid_type: 4,
            call_from: 2,
            transfer_price: tranfer_amount,
            transfer_pic: tranfer_picture,
            transfer_date: tranfer_date_send,
          },
        });
      }
    }
  }

  rendeReceiptImage(shipment, mode) {
    const receipt_doc = shipment.shipment_confirm_detail.receipt_doc.map(item => {
      const patternFileType = /\.[0-9a-z]{1,5}$/;
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      const thumbnail = patternCheckFileType.test(item.path)
        ? `${require('./../../assets/img/file-type-pdf.png')}`
        : `${s3}/${item.path}`;
      const type = patternCheckFileType.test(item.path) ? 'pdf' : 'image';

      return {
        path: `${s3}/${item.path}`, //path ไฟล์
        fileType: item.path.match(patternFileType)[0], //นามสกุลไฟล์
        type: type, //ประเภทไฟล์
        thumbnail: thumbnail, //รูปตัวอย่าง
      };
    });
    const receiptImage = receipt_doc.map(item => {
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      if (patternCheckFileType.test(item.path)) {
        return <PDFPreview file={item.path} />;
      }

      return item.thumbnail;
    });

    if (mode == 'view') {
      return receipt_doc;
    }

    return receiptImage;
  }

  renderConfirmImage(shipment, mode) {
    let shipmentConfirmImage = '';
    if (shipment.shipment_confirm_document) {
      const patternCheckFileType = /^.*\.(pdf|PDF)$/;
      const shipment_confirm = {
        path: `${s3}/${shipment.shipment_confirm_document}`, //path ไฟล์
        fileType: shipment.shipment_confirm_document.match(/\.[0-9a-z]{1,5}$/)[0], //นามสกุลไฟล์
        type: patternCheckFileType.test(shipment.shipment_confirm_document) ? 'pdf' : 'image', //ประเภทไฟล์
        thumbnail: patternCheckFileType.test(shipment.shipment_confirm_document)
          ? `${require('./../../assets/img/file-type-pdf.png')}`
          : `${s3}/${shipment.shipment_confirm_document}`, //รูปตัวอย่าง
      };

      if (patternCheckFileType.test(shipment_confirm.path)) {
        shipmentConfirmImage = <PDFPreview file={shipment_confirm.path} />;
      } else {
        shipmentConfirmImage = shipment_confirm.thumbnail;
      }

      if (mode == 'view') {
        return shipment_confirm;
      }
    }

    return shipmentConfirmImage;
  }
  /* end image */

  handleChangeTruck(truck) {
    // //console.log(`truckSelected:`, truck);
    // //console.log(`truckSelected:`, truck.value);
    this.setState({ truckSelected: truck, truckID: truck.value });
    // //console.log(`truckSelected:`, this.state.truckID);
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 2:
        return <Label bsStyle="danger">ค้างชำระ</Label>;
      case 3:
        return <Label bsStyle="default">รอตรวจสอบ</Label>;
      case 4:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      default:
        return '-';
    }
  }

  renderTextStatus(status) {
    switch (status) {
      case 1:
        return 'รอชำระ';
      case 2:
        return 'รอตรวจสอบ';
      case 3:
        return 'ชำระแล้ว';
      case 4:
        return 'ค้างชำระ';
      case 5:
        return 'แบบร่าง';
      default:
        return '-';
    }
  }

  renderTextStatusReceipt(status) {
    switch (status) {
      case 1:
        return 'รอตรวจสอบ';
      case 2:
        return 'ชำระแล้ว';
      default:
        return '-';
    }
  }

  renderPaidType(type) {
    switch (type) {
      case 1:
        return 'บัตรเครดิต / เดบิต';
      case 2:
        return '>ออนไลน์แบงค์';
      case 3:
        return 'จ่ายบิล';
      case 4:
        return 'โอนเงิน';
      default:
        return '-';
    }
  }

  changeStatus(event) {
    switch (this.state.mode) {
      case 'debitnote':
        return this.setState({ status_debitnote: { value: event.value, label: event.label } });
      case 'receipt':
        return this.setState({ status_receipt: { value: event.value, label: event.label } });
      default:
        // invoice
        return this.setState({ status_invoice: { value: event.value, label: event.label } });
    }
  }

  renderSelectedStatusInvoice(status, key) {
    if (this.state.status_invoice[key] == undefined || this.state.status_invoice[key].value == '') {
      return { value: status, label: this.renderTextStatus(status) };
    }
    return this.state.status_invoice[key];
  }

  checkDisabledUpdateStatus(key) {
    if (this.state.status_invoice[key] != undefined) {
      if (this.state.status_invoice[key].value != '') {
        return false;
      }
      return 'disabled';
    }
    return 'disabled';
  }

  checkDisabledUpdateStatusDebitnote(key) {
    if (this.state.status_debitnote[key] != undefined) {
      if (this.state.status_debitnote[key].value != '') {
        return false;
      }
      return 'disabled';
    }
    return 'disabled';
  }

  changeStatusInvoice(event, key) {
    if (this.state.status_invoice.length > key) {
      const newItems = [...this.state.status_invoice];
      newItems[key] = event;
      this.setState({ status_invoice: newItems });
    } else {
      var num = this.state.status_invoice.length;
      for (var i = num; i <= key; i++) {
        if (i != key) {
          const status = { value: '', label: '' };
          this.setState(state => {
            const status_invoice = [...state.status_invoice, status];
            return {
              status_invoice,
            };
          });
        } else {
          this.setState(state => {
            const status_invoice = [...state.status_invoice, event];
            return {
              status_invoice,
            };
          });
        }
      }
    }
  }

  renderSelectedStatusDebitNote(status, key) {
    if (
      this.state.status_debitnote[key] == undefined ||
      this.state.status_debitnote[key].value == ''
    ) {
      return { value: status, label: this.renderTextStatus(status) };
    }
    return this.state.status_debitnote[key];
  }

  changeStatusDebitNote(event, key) {
    if (this.state.status_debitnote.length > key) {
      const newItems = [...this.state.status_debitnote];
      newItems[key] = event;
      this.setState({ status_debitnote: newItems });
    } else {
      var num = this.state.status_debitnote.length;
      for (var i = num; i <= key; i++) {
        if (i != key) {
          const status = { value: '', label: '' };
          this.setState(state => {
            const status_debitnote = [...state.status_debitnote, status];
            return {
              status_debitnote,
            };
          });
        } else {
          this.setState(state => {
            const status_debitnote = [...state.status_debitnote, event];
            return {
              status_debitnote,
            };
          });
        }
      }
    }
  }

  renderPdfFile(mode, invoiceList, debitNoteList, receiptSummaryList) {
    if (this.state.pdfFile == null) {
      if (mode == 'invoice') {
        return invoiceList.length > 0 ? `${s3}/${invoiceList[0].invoice_document}` : null;
      } else if (mode == 'waiting') {
        return invoiceList.length > 0 ? `${s3}/${invoiceList[0].invoice_document}` : null;
      } else if (mode == 'debitnote') {
        return debitNoteList.length > 0
          ? `${s3}/${debitNoteList[0].debit_note_document}`
          : invoiceList.length > 0
            ? `${s3}/${invoiceList[0].invoice_document}`
            : null;
      } else if (mode == 'receipt') {
        return receiptSummaryList.length > 0
          ? `${s3}/${receiptSummaryList[0].receipt_document}`
          : invoiceList.length > 0
            ? `${s3}/${invoiceList[0].invoice_document}`
            : null;
      }
    }
    return this.state.pdfFile;
  }

  //waitingMakeDebitNoteListQuery
  getWaitingDebitNoteList = async summary_invoice_id => {
    try {
      const { data } = await client.query({
        query: waitingMakeDebitNoteListQuery,
        fetchPolicy: 'network-only',
        variables: {
          summary_invoice_id: summary_invoice_id,
        },
      });

      this.setState(
        {
          waitingDebitNoteList: data.waitingMakeDebitNoteList.list,
          waitingDebitNoteselected: data.waitingMakeDebitNoteList.list,
        },
        this.calcGrandtotal(data.waitingMakeDebitNoteList.list)
      );
    } catch (error) {
      ////console.log(error)
    }
  };

  calcGrandtotal(price, mode, item) {
    let total_price = 0;
    let total_vat = 0;
    let total_wht1 = 0;
    let total_wht3 = 0;
    let total_wht5 = 0;

    if (mode == 'price') {
      total_price =
        price.total_price +
        item.total_price +
        price.total_vat +
        item.total_vat -
        price.total_wht1 -
        item.total_wht1 -
        price.total_wht3 -
        item.total_wht3 -
        price.total_wht5 -
        item.total_wht5;
      total_vat = price.total_vat + item.total_vat;
      total_wht1 = price.total_wht1 + item.total_wht1;
      total_wht3 = price.total_wht3 + item.total_wht3;
      total_wht5 = price.total_wht5 + item.total_wht5;
    } else {
      if (price.length > 0) {
        price.forEach(list => {
          total_price +=
            list.total_price + list.total_vat - list.total_wht1 - list.total_wht3 - list.total_wht5;
          total_vat += list.total_vat;
          total_wht1 += list.total_wht1;
          total_wht3 += list.total_wht3;
          total_wht5 += list.total_wht5;
        });
      }
    }

    this.setState({
      TotalWaitingDebitNote: total_price - total_vat + total_wht1 + total_wht3 + total_wht5,
      GrandtotalWaitingDebitNote: total_price,
      TotalWaitingDebitNoteVat: total_vat,
      TotalWaitingDebitNoteWht1: total_wht1,
      TotalWaitingDebitNoteWht3: total_wht3,
      TotalWaitingDebitNoteWht5: total_wht5,
    });
  }

  onWaitingDebitNoteFetchRequested = summary_invoice_id => {
    this.getWaitingDebitNoteList(summary_invoice_id);
  };

  onWaitingDebitNoteClearRequested = () => {
    this.setState({
      waitingDebitNoteselected: [],
      waitingDebitNoteList: [],
    });
  };

  renderShipmentArray() {
    let id = [];
    if (this.state.waitingDebitNoteselected.length > 0) {
      this.state.waitingDebitNoteselected.forEach(list => {
        id.push(list._id);
      });
    }

    return id;
  }

  checkWaitingDebitNoteItemSelected(id) {
    const list = this.state.waitingDebitNoteselected.filter(list => {
      return list.shipment_id == id;
    });
    if (list.length > 0) {
      return true;
    }
    return false;
  }

  onClickWaitDebitNoteItem(id) {
    const listSelected = this.state.waitingDebitNoteselected.filter(list => {
      return list.shipment_id == id;
    });

    let total_price = 0;
    let total_vat = 0;
    let total_wht1 = 0;
    let total_wht3 = 0;
    let total_wht5 = 0;

    if (listSelected.length > 0) {
      listSelected.forEach(list => {
        total_price +=
          list.total_price + list.total_vat - list.total_wht1 - list.total_wht3 - list.total_wht5;
        total_vat += list.total_vat;
        total_wht1 += list.total_wht1;
        total_wht3 += list.total_wht3;
        total_wht5 += list.total_wht5;
      });
    }

    const price = {
      total_price: total_price,
      total_vat: total_vat,
      total_wht1: total_wht1,
      total_wht3: total_wht3,
      total_wht5: total_wht5,
    };

    if (listSelected.length > 0) {
      const listSelected = this.state.waitingDebitNoteselected.filter(list => {
        return list.shipment_id != id;
      });
      this.setState({ waitingDebitNoteselected: listSelected }, this.calcGrandtotal(listSelected));
    } else {
      const list = this.state.waitingDebitNoteList.filter(list => {
        return list.shipment_id == id;
      });

      this.setState(
        {
          waitingDebitNoteselected: [...this.state.waitingDebitNoteselected, list[0]],
        },
        this.calcGrandtotal(price, 'price', list[0])
      );
    }
  }

  checkNew(mode, item) {
    let check = false;
    if (mode == 'invoice') {
      if (item != null && item.length > 0) {
        item.forEach(list => {
          if (list.read_status == 1) {
            check = true;
          }
        });
      }
    } else if (mode == 'waiting') {
      if (item != null && item.length > 0) {
        check = true;
      }
    } else if (mode == 'debitnote') {
      //console.log(item);
      if (item != null && item.length > 0) {
        item.forEach(list => {
          if (list.read_status == 1) {
            check = true;
          }
        });
      }
    } else if (mode == 'receipt') {
      if (item != null && item.length > 0) {
        item.forEach(list => {
          if (list.read_status == 1) {
            check = true;
          }
        });
      }
    }

    return check;
  }

  handleDatePickerTranfer(event) {
    ////console.log(event.format('YYYY-MM-DD HH:MM'));
    const date = `${event.format('YYYY-MM-DD HH:mm')}`;
    const show = `${event.format('DD/MM/YYYY HH:mm')}`;
    this.setState({ tranfer_date: show, tranfer_date_send: date });
  }

  handleDatePickerReceiptDate(event) {
    const date = `${event.format('YYYY-MM-DD')}`;
    const show = `${event.format('DD/MM/YYYY')}`;
    this.setState({ receipt_date: show, receipt_date_send: date });
  }

  checkDisabledSummaryInvoiceUpdate(key) {
    if (
      this.state.status_invoice[key] != undefined &&
      this.state.status_invoice[key].label == 'ชำระแล้ว'
    ) {
      if (this.state.receipt_date == null) {
        return true;
      }
      return false;
    }
    return false;
  }

  render() {
    const selectOptionStatus = [
      { value: '', label: '-- เลือก --' },
      { value: 1, label: 'รอชำระ' },
      { value: 2, label: 'รอตรวจสอบ' },
      { value: 3, label: 'ชำระแล้ว' },
      { value: 4, label: 'ค้างชำระ' },
      { value: 5, label: 'แบบร่าง' },
    ];

    let refetchWaitingMakeDebitNoteList = null;
    let refetchDebitNote = null;
    let refetchReceipt = null;

    let invoiceList = null;
    let waitingMakeDebitNote = null;
    let waitingMakeDebitNoteList = null;
    let debitNoteList = null;
    let receiptSummaryList = null;

    let debitNote_loading = null;
    let debitNote_error = null;
    let receipt_loading = null;
    let receipt_error = null;

    return (
      <Query
        query={summaryInvoiceListQuery}
        variables={{
          summary_invoice_id: this.state.summary_invoice_id,
        }}
      >
        {({ loading, error, data, refetch: refetchInvoice, variables }) => {
          if (loading) return 'loading...';
          // if(error) return null;
          // ////console.log(variables);
          invoiceList = data.summaryInvoiceList.summaryInvoice;
          return (
            <Query
              query={debitNoteListQuery}
              variables={{
                summary_invoice_id: this.state.summary_invoice_id,
              }}
            >
              {({ loading, error, data, refetch, variables }) => {
                if (loading) return 'loading...';
                // if(error) return null;
                // ////console.log(variables);
                debitNoteList = data.debitNoteList.debitNote;
                refetchDebitNote = refetch;
                debitNote_loading = loading;
                debitNote_error = error;
                //console.log(debitNoteList);
                return (
                  <Query
                    query={receiptSummaryListQuery}
                    variables={{
                      summary_invoice_id: this.state.summary_invoice_id,
                    }}
                  >
                    {({ loading, error, data, refetch, variables }) => {
                      if (loading) return 'loading...';
                      // if(error) return null;
                      // ////console.log(variables);
                      receiptSummaryList = data.receiptSummaryList.receipt;
                      receipt_loading = loading;
                      receipt_error = error;
                      refetchReceipt = refetch;
                      //console.log(receiptSummaryList);
                      return (
                        <Container>
                          {this.state.isOpeTranferLightbox && (
                            <Lightbox
                              mainSrc={`${s3}/${this.state.tranfer_picture}`}
                              onCloseRequest={() => this.setState({ isOpeTranferLightbox: false })}
                            />
                          )}
                          <HeaderContainer>
                            <div className="sub-header-left">
                              <a
                                href={`/giztixbusinesspayment?id=${invoiceList[0].account_info_id}`}
                                className="btn-back"
                              >
                                <i className="far fa-arrow-left"></i> กลับ
                              </a>
                              <div className="line"></div>
                              <div className="menu-wrapper">
                                <b>
                                  เอกสารที่เกี่ยวข้องกับ {invoiceList[0].summary_invoice_number}
                                </b>
                              </div>
                            </div>
                          </HeaderContainer>

                          <ModalConfirm
                            show={this.state.isOpenModalAlertImage}
                            onPressButtonConfirm={() =>
                              this.setState({
                                isOpenModalAlertImage: false,
                              })
                            }
                            labelButtonConfirm={'ตกลง'}
                            titleHeader={'แจ้งเตือน'}
                            styleColorButtonConfirm="primary"
                            onHide={() =>
                              this.setState({
                                isOpenModalAlertImage: false,
                              })
                            }
                          >
                            {this.state.textAlertModalImage}
                          </ModalConfirm>

                          <Grid fluid style={{ paddingTop: 5 }}>
                            <Row>
                              <Col md={12}>
                                <CardTable
                                  ctTableFullWidth
                                  ctTableResponsive
                                  content={
                                    <PaymentDetailContainer>
                                      <div className="paymentDetail-main">
                                        <TabHeaderContainer>
                                          <div className="sub-header-left">
                                            <div className="menu-wrapper">
                                              <ul>
                                                <li
                                                  className={
                                                    this.state.mode == 'invoice' ? 'active' : ''
                                                  }
                                                >
                                                  <a
                                                    onClick={() =>
                                                      this.setState({
                                                        mode: 'invoice',
                                                        openBanktranfer: false,
                                                      })
                                                    }
                                                  >
                                                    ใบแจ้งหนี้{' '}
                                                    {this.checkNew('invoice', invoiceList) && (
                                                      <i className="fas fa-circle"></i>
                                                    )}
                                                  </a>
                                                </li>
                                                <li
                                                  className={
                                                    this.state.mode == 'waiting' ? 'active' : ''
                                                  }
                                                >
                                                  <a
                                                    onClick={() =>
                                                      this.setState(
                                                        { mode: 'waiting', openBanktranfer: false },
                                                        this.onWaitingDebitNoteFetchRequested(
                                                          this.state.summary_invoice_id
                                                        )
                                                      )
                                                    }
                                                  >
                                                    รอสร้างใบเพิ่มหนี้{' '}
                                                    {this.checkNew(
                                                      'waiting',
                                                      waitingMakeDebitNoteList
                                                    ) && <i className="fas fa-circle"></i>}
                                                  </a>
                                                </li>
                                                <li
                                                  className={
                                                    this.state.mode == 'debitnote' ? 'active' : ''
                                                  }
                                                >
                                                  <a
                                                    onClick={() =>
                                                      this.setState({
                                                        mode: 'debitnote',
                                                        openBanktranfer: false,
                                                      })
                                                    }
                                                  >
                                                    ใบเพิ่มหนี้{' '}
                                                    {this.checkNew('debitnote', debitNoteList) && (
                                                      <i className="fas fa-circle"></i>
                                                    )}
                                                  </a>
                                                </li>
                                                <li
                                                  className={
                                                    this.state.mode == 'receipt' ? 'active' : ''
                                                  }
                                                >
                                                  <a
                                                    onClick={() =>
                                                      this.setState({
                                                        mode: 'receipt',
                                                        openBanktranfer: false,
                                                      })
                                                    }
                                                  >
                                                    ใบเสร็จรับเงิน{' '}
                                                    {this.checkNew(
                                                      'receipt',
                                                      receiptSummaryList
                                                    ) && <i className="fas fa-circle"></i>}
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </TabHeaderContainer>

                                        <TabContentContainer>
                                          {this.state.mode == 'invoice' && (
                                            <div className="tab-item">
                                              <TitleLeft>รายละเอียดการชำระเงิน</TitleLeft>
                                              {loading || error ? (
                                                <div>loading...</div>
                                              ) : invoiceList.length === 0 ? (
                                                <EmptyWrapper>
                                                  <i class="fal fa-empty-set"></i>
                                                  <b>- ไม่มีรายการ -</b>
                                                </EmptyWrapper>
                                              ) : (
                                                    invoiceList.map((prop, key) => (
                                                      <div key={key}>
                                                        {key != 0 && <Blankspace />}
                                                        <Row>
                                                          <Col md={12}>
                                                            <Mutation
                                                              mutation={summaryInvoiceUpdateMutation}
                                                              variables={this.renderUpdateStatusVariables(
                                                                prop._id,
                                                                this.state.status_invoice[key] !=
                                                                  undefined
                                                                  ? this.state.status_invoice[key].value
                                                                  : 5,
                                                                prop.receipt_id
                                                              )}
                                                              update={() => {
                                                                const newItems = [
                                                                  ...this.state.status_invoice,
                                                                ];
                                                                newItems[key] = {
                                                                  value: '',
                                                                  label: '',
                                                                };

                                                                this.setState(
                                                                  {
                                                                    _id: null,
                                                                    debitnote_id: null,
                                                                    tranfer_date: moment().format(
                                                                      'DD/MM/YYYY HH:mm'
                                                                    ),
                                                                    tranfer_date_send: moment().format(
                                                                      'YYYY-MM-DD HH:mm'
                                                                    ),
                                                                    tranfer_amount: '',
                                                                    tranfer_picture: null,
                                                                    openBanktranfer: false,
                                                                    isOpenModalInvoiceUpdateStatus: false,
                                                                    status_invoice: newItems,
                                                                  },
                                                                  () => {
                                                                    refetchInvoice();
                                                                    refetchReceipt();
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              {summaryInvoiceUpdate => (
                                                                <Modal
                                                                  show={
                                                                    this.state
                                                                      .isOpenModalInvoiceUpdateStatus
                                                                  }
                                                                >
                                                                  <Modal.Body>
                                                                    <ModalContent>
                                                                      <div className="content">
                                                                        <h5
                                                                          style={{ marginBottom: '0' }}
                                                                        >
                                                                          <b>
                                                                            คุณต้องการเปลี่ยนแปลงสถานะการชำระเงินใช่หรือไม่
                                                                      </b>
                                                                        </h5>
                                                                        <p>
                                                                          กรุณาตรวจสอบความถูกต้องก่อนกดยืนยัน
                                                                    </p>

                                                                        <p style={{ marginTop: 10 }}>
                                                                          <div className="detail">
                                                                            <div className="left">
                                                                              เลขที่ใบแจ้งหนี้:
                                                                        </div>
                                                                            <div className="right">
                                                                              {
                                                                                prop.summary_invoice_number
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                          <div className="detail">
                                                                            <div className="left">
                                                                              สถานะเดิม:
                                                                        </div>
                                                                            <div className="right">
                                                                              {this.renderTextStatus(
                                                                                prop.status
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                          <div className="detail">
                                                                            <div className="left">
                                                                              สถานะใหม่:
                                                                        </div>
                                                                            <div className="right">
                                                                              {this.state
                                                                                .status_invoice[key] !=
                                                                                undefined
                                                                                ? this.state
                                                                                  .status_invoice[key]
                                                                                  .label
                                                                                : ''}
                                                                            </div>
                                                                          </div>

                                                                          {this.state.status_invoice[
                                                                            key
                                                                          ] != undefined &&
                                                                            this.state.receipt_id !=
                                                                            null &&
                                                                            this.state.status_invoice[
                                                                              key
                                                                            ].label == 'ชำระแล้ว' && (
                                                                              <Row className="detail">
                                                                                <Col
                                                                                  style={{
                                                                                    margin: '10px 0',
                                                                                  }}
                                                                                  md={12}
                                                                                >
                                                                                  วันที่ออกใบเสร็จ{' '}
                                                                                  <span
                                                                                    style={{
                                                                                      color: 'red',
                                                                                    }}
                                                                                  >
                                                                                    *
                                                                              </span>{' '}
                                                                                </Col>
                                                                                <Col md={12}>
                                                                                  <Datetime
                                                                                    value={
                                                                                      this.state
                                                                                        .receipt_date
                                                                                    }
                                                                                    dateFormat="DD/MM/YYYY"
                                                                                    timeFormat={false}
                                                                                    timeConstraints={{
                                                                                      minutes: {
                                                                                        step: 5,
                                                                                      },
                                                                                    }}
                                                                                    onChange={event =>
                                                                                      this.handleDatePickerReceiptDate(
                                                                                        event
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </Col>
                                                                              </Row>
                                                                            )}
                                                                          {this.state.status_invoice[
                                                                            key
                                                                          ] == undefined
                                                                            ? null
                                                                            : this.state.status_invoice[
                                                                              key
                                                                            ].value == 3 &&
                                                                            this.state.receipt_id ==
                                                                            null && (
                                                                              <div className="detail">
                                                                                <div
                                                                                  style={{
                                                                                    color: 'red',
                                                                                  }}
                                                                                >
                                                                                  ไม่สามารถ
                                                                                อัพเดตเป็นสถานะ{' '}
                                                                                  {this.state
                                                                                    .status_invoice[
                                                                                    key
                                                                                  ] != undefined
                                                                                    ? this.state
                                                                                      .status_invoice[
                                                                                      key
                                                                                    ].label
                                                                                    : ''}{' '}
                                                                                ได้เนื่องจากไม่มีหลักฐานการชำระเงิน
                                                                                .
                                                                              </div>
                                                                              </div>
                                                                            )}
                                                                        </p>
                                                                      </div>

                                                                      <div className="footer">
                                                                        <button
                                                                          className="btn btn-grey"
                                                                          style={{ width: '100px' }}
                                                                          onClick={() =>
                                                                            this.setState({
                                                                              isOpenModalInvoiceUpdateStatus: false,
                                                                            })
                                                                          }
                                                                        >
                                                                          ปิด
                                                                    </button>
                                                                        {this.state.status_invoice[
                                                                          key
                                                                        ] == undefined ? null : this
                                                                          .state.status_invoice[key]
                                                                          .value == 3 &&
                                                                          this.state.receipt_id ==
                                                                          null ? null : (
                                                                              <button
                                                                                className="btn btn-red"
                                                                                style={{ width: '100px' }}
                                                                                disabled={this.checkDisabledSummaryInvoiceUpdate(
                                                                                  key
                                                                                )}
                                                                                onClick={() =>
                                                                                  summaryInvoiceUpdate()
                                                                                }
                                                                              >
                                                                                ยืนยัน
                                                                              </button>
                                                                            )}
                                                                      </div>
                                                                    </ModalContent>
                                                                  </Modal.Body>
                                                                </Modal>
                                                              )}
                                                            </Mutation>

                                                            <RowItemGroup>
                                                              <div className="left">
                                                                <SelectBox
                                                                  onChange={event =>
                                                                    this.changeStatusInvoice(event, key)
                                                                  }
                                                                  disabled={
                                                                    prop.status == 3
                                                                      ? 'disabled'
                                                                      : false
                                                                  }
                                                                  value={this.renderSelectedStatusInvoice(
                                                                    prop.status,
                                                                    key
                                                                  )}
                                                                  options={selectOptionStatus}
                                                                  inputLabel="สถานะ:"
                                                                  LabelWeight={true}
                                                                  required
                                                                  messageError={
                                                                    this.state.statusInvalid &&
                                                                    'กรุณากรอกข้อมูล'
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="right">
                                                                <Button
                                                                  className="btn btn-red"
                                                                  onClick={() =>
                                                                    this.setState({
                                                                      isOpenModalInvoiceUpdateStatus: true,
                                                                      receipt_id: prop.receipt_id,
                                                                    })
                                                                  }
                                                                  disabled={this.checkDisabledUpdateStatus(
                                                                    key
                                                                  )}
                                                                >
                                                                  อัพเดท
                                                            </Button>
                                                              </div>
                                                            </RowItemGroup>

                                                            <RowItem>
                                                              <div className="left">ลูกค้า:</div>
                                                              <div className="right">
                                                                {prop.account_info_business_name}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                เลขที่ใบแจ้งหนี้:
                                                          </div>
                                                              <div className="right">
                                                                {prop.summary_invoice_number}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">รอบบิล:</div>
                                                              <div className="right">
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.bill_date_start}
                                                                </Moment>{' '}
                                                            -{' '}
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.bill_date_end}
                                                                </Moment>
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                วันที่ออกใบแจ้งหนี้:
                                                          </div>
                                                              <div className="right">
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.issue_date}
                                                                </Moment>
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                วันครบกำหนดชำระ:
                                                          </div>
                                                              <div className="right">
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.due_date}
                                                                </Moment>
                                                              </div>
                                                            </RowItem>
                                                            <Line />
                                                            <RowItem>
                                                              <div
                                                                className="left"
                                                                style={{
                                                                  paddingTop: '5px',
                                                                  display: 'block',
                                                                }}
                                                              >
                                                                ที่ต้องชำระ:
                                                          </div>
                                                              <div className="right">
                                                                <span className="total">
                                                                  {parseFloat(
                                                                    prop.summary_total_price
                                                                  ).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                  })}{' '}
                                                              บาท
                                                            </span>
                                                              </div>
                                                            </RowItem>

                                                            <Line />
                                                            <Mutation
                                                              mutation={summaryInvoiceUpdateMutation}
                                                              variables={this.renderVariableseditinvoice(prop._id)}
                                                              update={(cache, { data }) => {
                                                                this.setState({
                                                                  show_editcost: false,
                                                                  credit_used: '',
                                                                  summary_total_price: '',
                                                                },
                                                                  () => {
                                                                    refetchInvoice();
                                                                  });
                                                              }}
                                                              onError={error => {
                                                                console.log(error);
                                                              }}
                                                            >
                                                              {summaryInvoiceUpdateMutation => (
                                                                <Modal
                                                                  show={this.state.show_editcost}
                                                                  onHide={() => this.setState({ show_editcost: false })}
                                                                  backdrop="static"
                                                                >
                                                                  <Modal.Header closeButton>
                                                                    <Modal.Title>แก้ไขยอด Invoice</Modal.Title>
                                                                  </Modal.Header>
                                                                  <Modal.Body>
                                                                    <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                                                      <Col md={6}>
                                                                        <div>ราคารวมทั้งหมดหลังหักส่วนลด (เครดิตลูกค้า BU) :</div>
                                                                        <FormControl
                                                                          type="number"
                                                                          min="1"
                                                                          value={this.state.credit_used || this.state.credit_used != '' ? this.state.credit_used : prop.credit_used}
                                                                          onChange={event =>
                                                                            this.setState({
                                                                              credit_used: event.target.value,
                                                                            })
                                                                          }
                                                                          placeholder="0"
                                                                        />

                                                                      </Col>
                                                                      <Col md={6}>
                                                                        <div>ยอดที่ต้องชำระ (บวก vat ลบ wht):</div>
                                                                        <FormControl
                                                                          type="number"
                                                                          min="1"
                                                                          value={this.state.summary_total_price || this.state.summary_total_price != '' ? this.state.summary_total_price : prop.summary_total_price}
                                                                          onChange={event =>
                                                                            this.setState({
                                                                              summary_total_price: event.target.value,
                                                                            })
                                                                          }
                                                                          placeholder="0"
                                                                        />

                                                                      </Col>
                                                                    </Row>
                                                                  </Modal.Body>

                                                                  <Modal.Footer>
                                                                    <Button onClick={() => this.setState({ show_editcost: false })}>ปิด</Button>
                                                                    <Button
                                                                      disabled={this.state.summary_total_price == '' && this.state.credit_used == ''}
                                                                      style={{
                                                                        background: '#87CB16',
                                                                        color: '#ffffff',
                                                                        marginRight: '15px',
                                                                      }}
                                                                      onClick={() => summaryInvoiceUpdateMutation()}
                                                                    >
                                                                      บันทึก
                                                                    </Button>
                                                                  </Modal.Footer>
                                                                </Modal>
                                                              )}
                                                            </Mutation>
                                                            <RowItem>
                                                              <Button
                                                                style={{
                                                                  float: 'right'
                                                                }}
                                                                onClick={() =>
                                                                  this.setState({
                                                                    show_editcost: true,
                                                                    credit_used: '',
                                                                    summary_total_price: '',
                                                                  })
                                                                }
                                                              >
                                                                แก้ไขยอดที่ต้องชำระ
                                                            </Button>
                                                            </RowItem>

                                                            <Line />
                                                            <ShipmentItem
                                                              className={
                                                                !this.state.openBanktranfer &&
                                                                  (this.state.pdfFile ==
                                                                    `${s3}/${prop.invoice_document}` ||
                                                                    (this.state.pdfFile == null &&
                                                                      key == 0 &&
                                                                      this.state.mode == 'invoice'))
                                                                  ? 'selected'
                                                                  : ''
                                                              }
                                                              onClick={() =>
                                                                this.setState({
                                                                  pdfFile: `${s3}/${prop.invoice_document}`,
                                                                  openBanktranfer: false,
                                                                  selectIndex: key,
                                                                })
                                                              }
                                                            >
                                                              <div className="icon">
                                                                <i className="fal fa-file-alt"></i>
                                                              </div>
                                                              <div className="detail">
                                                                <b>ใบแจ้งหนี้:</b>
                                                                <span>
                                                                  {prop.summary_invoice_number}
                                                                </span>
                                                              </div>
                                                              <div className="righticon">
                                                                <Mutation
                                                                  mutation={
                                                                    summaryInvoiceUpdateMutation
                                                                  }
                                                                  update={(cache, { data }) => {
                                                                    this.setState({
                                                                      pdfFile: null,
                                                                    });
                                                                    refetchInvoice();
                                                                    refetchReceipt();
                                                                  }}
                                                                  onError={error => {
                                                                    console.log(error);
                                                                  }}
                                                                >
                                                                  {summaryInvoiceDocumentUpdate => (
                                                                    <Mutation
                                                                      mutation={singleUploadMutation}
                                                                      update={(cache, { data }) => {
                                                                        summaryInvoiceDocumentUpdate({
                                                                          variables: {
                                                                            _id: prop._id,
                                                                            invoice_document:
                                                                              data.singleUpload.path,
                                                                          },
                                                                        });
                                                                      }}
                                                                      onError={error => {
                                                                        console.log(error);
                                                                      }}
                                                                    >
                                                                      {uploadFileInvoice => {
                                                                        return (
                                                                          <input
                                                                            accept="image/jpeg, image/png, application/pdf"
                                                                            onChange={event => {
                                                                              this.handleSingleUploadDocumentfile(
                                                                                uploadFileInvoice,
                                                                                'document_invoice',
                                                                                event
                                                                              );
                                                                            }}
                                                                            type="file"
                                                                            id="file"
                                                                            ref={
                                                                              this._inputUploadInvoice
                                                                            }
                                                                            style={{ display: 'none' }}
                                                                          />
                                                                        );
                                                                      }}
                                                                    </Mutation>
                                                                  )}
                                                                </Mutation>
                                                                <Button
                                                                  style={{
                                                                    float: 'left',
                                                                    marginTop: '-7px',
                                                                  }}
                                                                  onClick={() =>
                                                                    this._inputUploadInvoice.current.click()
                                                                  }
                                                                >
                                                                  เปลี่ยนเอกสาร
                                                            </Button>

                                                                {!this.state.openBanktranfer &&
                                                                  (this.state.pdfFile ==
                                                                    `${s3}/${prop.invoice_document}` ||
                                                                    (this.state.pdfFile == null &&
                                                                      key == 0 &&
                                                                      this.state.mode == 'invoice')) ? (
                                                                    <i className="fal fa-chevron-left"></i>
                                                                  ) : (
                                                                    <i className="fal fa-chevron-right"></i>
                                                                  )}
                                                              </div>
                                                            </ShipmentItem>

                                                            {prop.paid_type == null ||
                                                              prop.paid_type == 4 ? (
                                                                <ShipmentItem
                                                                  className={
                                                                    this.state.openBanktranfer &&
                                                                      this.state.selectIndex == key
                                                                      ? 'selected'
                                                                      : ''
                                                                  }
                                                                  onClick={() =>
                                                                    this.onOpenbanktranfer(
                                                                      prop.paid_bank_transfer,
                                                                      prop._id,
                                                                      key,
                                                                      prop.summary_total_price,
                                                                      null,
                                                                      prop.receipt_id
                                                                    )
                                                                  }
                                                                >
                                                                  {
                                                                    //console.log("paid_bank_transfer" , prop.paid_bank_transfer)
                                                                  }
                                                                  <div className="icon">
                                                                    <i className="fal fa-file-invoice-dollar"></i>
                                                                  </div>
                                                                  <div
                                                                    className="detail"
                                                                    style={{ width: 'calc(100% - 80px)' }}
                                                                  >
                                                                    <b>หลักฐานการชำระ:</b>
                                                                    {prop.paid_bank_transfer ? (
                                                                      <span>
                                                                        แจ้งเมื่อ{' '}
                                                                        <Moment format="DD/MM/YYYY HH:mm">
                                                                          {
                                                                            prop.paid_bank_transfer
                                                                              .transfer_date
                                                                          }
                                                                        </Moment>{' '}
                                                                      </span>
                                                                    ) : (
                                                                        <span>
                                                                          อัปโหลดข้อมูลการชำระเงิน
                                                                        </span>
                                                                      )}
                                                                  </div>
                                                                  <div
                                                                    className="righticon"
                                                                    style={{ width: '40px' }}
                                                                  >
                                                                    {this.state.openBanktranfer &&
                                                                      this.state.selectIndex == key ? (
                                                                        <i className="fal fa-chevron-left"></i>
                                                                      ) : (
                                                                        <i className="fal fa-chevron-right"></i>
                                                                      )}
                                                                  </div>
                                                                </ShipmentItem>
                                                              ) : null}

                                                            {prop.paid_type == 1 &&
                                                              prop.paid_credit_card_detail && (
                                                                <ShipmentItem>
                                                                  <div className="icon">
                                                                    <i className="fal fa-file-invoice-dollar"></i>
                                                                  </div>
                                                                  <div className="detail">
                                                                    <b>ชำระค่าบริการผ่านบัตรเครดิต:</b>
                                                                    <span>
                                                                      แจ้งเมื่อ{' '}
                                                                      <Moment format="DD/MM/YYYY HH:mm">
                                                                        {prop.receipt_create_date}
                                                                      </Moment>{' '}
                                                                    </span>
                                                                  </div>
                                                                </ShipmentItem>
                                                              )}
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    ))
                                                  )}
                                            </div>
                                          )}

                                          {this.state.mode == 'waiting' && (
                                            <Query
                                              query={waitingMakeDebitNoteListQuery}
                                              variables={{
                                                summary_invoice_id: this.state.summary_invoice_id,
                                              }}
                                            >
                                              {({ loading, error, data, refetch, variables }) => {
                                                if (loading) return 'loading...';
                                                // if(error) return null;
                                                // ////console.log(variables);
                                                waitingMakeDebitNote =
                                                  data.waitingMakeDebitNoteList;
                                                waitingMakeDebitNoteList =
                                                  data.waitingMakeDebitNoteList.list;
                                                refetchWaitingMakeDebitNoteList = refetch;
                                                return (
                                                  <div className="tab-item">
                                                    <TitleLeft>
                                                      รายการงานขนส่งที่มีค่าใช้จ่ายเพิ่มเติม
                                                    </TitleLeft>
                                                    {loading || error ? (
                                                      <div>loading...</div>
                                                    ) : waitingMakeDebitNoteList.length === 0 ? (
                                                      <Row>
                                                        <Col md={12}>
                                                          <EmptyWrapper>
                                                            <i class="fal fa-empty-set"></i>
                                                            <b>- ไม่มีรายการ -</b>
                                                            <small>
                                                              *กรุณาเพิ่มค่าใช้จ่ายเพิ่มเติมในงานขนส่ง{' '}
                                                              <br />
                                                              เมื่อมีค่าใช้จ่ายเพิ่มเติมและตรวจสอบความถูกต้องก่อนเสมอ
                                                            </small>
                                                          </EmptyWrapper>
                                                        </Col>
                                                      </Row>
                                                    ) : (
                                                          <Row>
                                                            <Col md={12}>
                                                              <RowItemList>
                                                                <div className="left">รายการงาน</div>
                                                                <div className="right">ที่ต้องชำระ</div>
                                                              </RowItemList>

                                                              {waitingMakeDebitNoteList.map(
                                                                (prop, key) => (
                                                                  <div>
                                                                    {key != 0 && <Line />}
                                                                    <RowItemList
                                                                      className={`${
                                                                        this.checkWaitingDebitNoteItemSelected(
                                                                          prop.shipment_id
                                                                        )
                                                                          ? 'selected'
                                                                          : ''
                                                                        }`}
                                                                      key={key}
                                                                      data-id={prop.shipment_id}
                                                                    >
                                                                      <div
                                                                        className="left"
                                                                        onClick={() =>
                                                                          this.onClickWaitDebitNoteItem(
                                                                            prop.shipment_id
                                                                          )
                                                                        }
                                                                      >
                                                                        {this.checkWaitingDebitNoteItemSelected(
                                                                          prop.shipment_id
                                                                        ) ? (
                                                                            <i className="fas fa-check-square"></i>
                                                                          ) : (
                                                                            <i className="fal fa-square"></i>
                                                                          )}{' '}
                                                                        <b>{prop.shipment_number}</b>
                                                                      </div>
                                                                      <div
                                                                        className="right"
                                                                        onClick={() =>
                                                                          window.open(
                                                                            `/shipmentdetail?shipment=${prop.shipment_id}`,
                                                                            '_blank'
                                                                          )
                                                                        }
                                                                      >
                                                                        {parseFloat(
                                                                          prop.invoice_business_grand_total_price
                                                                        ).toLocaleString('en-US', {
                                                                          maximumFractionDigits: 2,
                                                                        })}{' '}
                                                                        <i className="fal fa-chevron-right"></i>
                                                                      </div>
                                                                    </RowItemList>
                                                                  </div>
                                                                )
                                                              )}

                                                              <Line />

                                                              <RowItem>
                                                                <div className="left">รวม:</div>
                                                                <div className="right">
                                                                  {parseFloat(
                                                                    this.state.TotalWaitingDebitNote
                                                                  ).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                  })}{' '}
                                                              บาท
                                                            </div>
                                                              </RowItem>
                                                              {this.state.TotalWaitingDebitNoteVat >
                                                                0 && (
                                                                  <RowItem>
                                                                    <div className="left">
                                                                      ภาษีมูลค่าเพิ่ม (7%)
                                                              </div>
                                                                    <div className="right">
                                                                      {parseFloat(
                                                                        this.state
                                                                          .TotalWaitingDebitNoteVat
                                                                      ).toLocaleString('en-US', {
                                                                        maximumFractionDigits: 2,
                                                                      })}{' '}
                                                                บาท
                                                              </div>
                                                                  </RowItem>
                                                                )}
                                                              {this.state.TotalWaitingDebitNoteWht1 >
                                                                0 && (
                                                                  <RowItem>
                                                                    <div className="left">
                                                                      ภาษีหัก ณ ที่จ่าย (1%)
                                                              </div>
                                                                    <div className="right">
                                                                      {parseFloat(
                                                                        this.state
                                                                          .TotalWaitingDebitNoteWht1
                                                                      ).toLocaleString('en-US', {
                                                                        maximumFractionDigits: 2,
                                                                      })}{' '}
                                                                บาท
                                                              </div>
                                                                  </RowItem>
                                                                )}
                                                              {this.state.TotalWaitingDebitNoteWht3 >
                                                                0 && (
                                                                  <RowItem>
                                                                    <div className="left">
                                                                      ภาษีหัก ณ ที่จ่าย (3%)
                                                              </div>
                                                                    <div className="right">
                                                                      {parseFloat(
                                                                        this.state
                                                                          .TotalWaitingDebitNoteWht3
                                                                      ).toLocaleString('en-US', {
                                                                        maximumFractionDigits: 2,
                                                                      })}{' '}
                                                                บาท
                                                              </div>
                                                                  </RowItem>
                                                                )}
                                                              {this.state.TotalWaitingDebitNoteWht5 >
                                                                0 && (
                                                                  <RowItem>
                                                                    <div className="left">
                                                                      ภาษีหัก ณ ที่จ่าย (3%)
                                                              </div>
                                                                    <div className="right">
                                                                      {parseFloat(
                                                                        this.state
                                                                          .TotalWaitingDebitNoteWht5
                                                                      ).toLocaleString('en-US', {
                                                                        maximumFractionDigits: 2,
                                                                      })}{' '}
                                                                บาท
                                                              </div>
                                                                  </RowItem>
                                                                )}
                                                              <Line />
                                                              <RowItem>
                                                                <div className="left">ที่ต้องชำระ:</div>
                                                                <div className="right">
                                                                  <span className="total">
                                                                    {parseFloat(
                                                                      this.state
                                                                        .GrandtotalWaitingDebitNote
                                                                    ).toLocaleString('en-US', {
                                                                      maximumFractionDigits: 2,
                                                                    })}{' '}
                                                                บาท
                                                              </span>
                                                                </div>
                                                              </RowItem>

                                                              <Line />
                                                              <Mutation
                                                                mutation={makeDebitNoteManualMutation}
                                                                variables={{
                                                                  account_info_id:
                                                                    invoiceList[0].account_info_id,
                                                                  id_array: this.renderShipmentArray(),
                                                                }}
                                                                update={() => {
                                                                  refetchWaitingMakeDebitNoteList();
                                                                  refetchDebitNote();
                                                                }}
                                                              >
                                                                {makeDebitNoteManual => (
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      makeDebitNoteManual()
                                                                    }
                                                                    disabled={
                                                                      this.state
                                                                        .GrandtotalWaitingDebitNote > 0
                                                                        ? false
                                                                        : 'disabled'
                                                                    }
                                                                    className="btn btn-red btn btn-default"
                                                                    style={{
                                                                      width: 'calc(100% - 15px)',
                                                                    }}
                                                                  >
                                                                    {' '}
                                                                    <i className="fal fa-file-alt"></i>{' '}
                                                                สร้างใบเพิ่มหนี้
                                                                  </button>
                                                                )}
                                                              </Mutation>
                                                            </Col>
                                                          </Row>
                                                        )}
                                                  </div>
                                                );
                                              }}
                                            </Query>
                                          )}

                                          {this.state.mode == 'debitnote' && (
                                            <div className="tab-item">
                                              <TitleLeft>รายละเอียดการชำระเงิน</TitleLeft>

                                              <Mutation
                                                mutation={debitNoteUpdateMutation}
                                                update={(cache, { data }) => {
                                                  this.setState(
                                                    {
                                                      pdfFile: null,
                                                      debitnote_id: null,
                                                    },
                                                    () => {
                                                      refetchDebitNote();
                                                      refetchReceipt();
                                                    }
                                                  );
                                                }}
                                                onError={error => {
                                                  console.log(error);
                                                }}
                                              >
                                                {debitNoteUpdateDocumentUpdate => (
                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      debitNoteUpdateDocumentUpdate({
                                                        variables: {
                                                          _id: this.state.debitnote_id,
                                                          debit_note_document:
                                                            data.singleUpload.path,
                                                        },
                                                      });
                                                    }}
                                                    onError={error => {
                                                      console.log(error);
                                                    }}
                                                  >
                                                    {uploadFileDebitNote => {
                                                      return (
                                                        <input
                                                          accept="image/jpeg, image/png, application/pdf"
                                                          onChange={event => {
                                                            this.handleSingleUploadDocumentfile(
                                                              uploadFileDebitNote,
                                                              'document_debitnote',
                                                              event
                                                            );
                                                          }}
                                                          type="file"
                                                          id="file"
                                                          ref={this._inputUploadDebitNote}
                                                          style={{ display: 'none' }}
                                                        />
                                                      );
                                                    }}
                                                  </Mutation>
                                                )}
                                              </Mutation>
                                              <Mutation
                                                mutation={debitNoteUpdateMutation}
                                                variables={this.renderUpdateStatusVariables(
                                                  this.state.debitnote_id,
                                                  this.state.status_debitnote[
                                                    this.state.debitnote_key
                                                  ] != undefined
                                                    ? this.state.status_debitnote[
                                                      this.state.debitnote_key
                                                    ].value
                                                    : 5,
                                                  this.state.receipt_id
                                                )}
                                                update={() => {
                                                  const newItems = [...this.state.status_debitnote];
                                                  newItems[this.state.debitnote_key] = {
                                                    value: '',
                                                    label: '',
                                                  };

                                                  this.setState(
                                                    {
                                                      isOpenModalDebitNoteUpdateStatus: false,
                                                      status_debitnote: newItems,
                                                      receipt_id: null,
                                                      debitnote_id: null,
                                                      debitnote_key: null,
                                                      debitnote_number: null,
                                                      debitnote_status: null,
                                                      receipt_date: null,
                                                      receipt_date_send: null,
                                                      _id: null,
                                                      tranfer_date: moment().format(
                                                        'DD/MM/YYYY HH:mm'
                                                      ),
                                                      tranfer_date_send: moment().format(
                                                        'YYYY-MM-DD HH:mm'
                                                      ),
                                                      tranfer_amount: '',
                                                      tranfer_picture: null,
                                                      openBanktranfer: false,
                                                    },
                                                    () => {
                                                      refetchDebitNote();
                                                      refetchReceipt();
                                                    }
                                                  );
                                                }}
                                              >
                                                {debitNoteUpdate => (
                                                  <Modal
                                                    show={
                                                      this.state.isOpenModalDebitNoteUpdateStatus
                                                    }
                                                  >
                                                    <Modal.Body>
                                                      <ModalContent>
                                                        <div className="content">
                                                          <h5 style={{ marginBottom: '0' }}>
                                                            <b>
                                                              คุณต้องการเปลี่ยนแปลงสถานะการชำระเงินใช่หรือไม่
                                                            </b>
                                                          </h5>
                                                          <p>กรุณาตรวจสอบความถูกต้องก่อนกดยืนยัน</p>

                                                          <p style={{ marginTop: 10 }}>
                                                            <div className="detail">
                                                              <div className="left">
                                                                เลขที่ใบเพิ่มหนี้:
                                                              </div>
                                                              <div className="right">
                                                                {this.state.debitnote_number}
                                                              </div>
                                                            </div>
                                                            <div className="detail">
                                                              <div className="left">สถานะเดิม:</div>
                                                              <div className="right">
                                                                {this.renderTextStatus(
                                                                  this.state.debitnote_status
                                                                )}
                                                              </div>
                                                            </div>
                                                            <div className="detail">
                                                              <div className="left">สถานะใหม่:</div>
                                                              <div className="right">
                                                                {this.state.status_debitnote[
                                                                  this.state.debitnote_key
                                                                ] != undefined
                                                                  ? this.state.status_debitnote[
                                                                    this.state.debitnote_key
                                                                  ].label
                                                                  : ''}
                                                              </div>
                                                            </div>

                                                            {this.state.status_debitnote[
                                                              this.state.debitnote_key
                                                            ] != undefined &&
                                                              this.state.receipt_id != null &&
                                                              this.state.status_debitnote[
                                                                this.state.debitnote_key
                                                              ].label == 'ชำระแล้ว' && (
                                                                <Row className="detail">
                                                                  <Col
                                                                    style={{ margin: '10px 0' }}
                                                                    md={12}
                                                                  >
                                                                    วันที่ออกใบเสร็จ{' '}
                                                                    <span style={{ color: 'red' }}>
                                                                      *
                                                                    </span>{' '}
                                                                  </Col>
                                                                  <Col md={12}>
                                                                    <Datetime
                                                                      value={
                                                                        this.state.receipt_date
                                                                      }
                                                                      dateFormat="DD/MM/YYYY"
                                                                      timeFormat={false}
                                                                      timeConstraints={{
                                                                        minutes: { step: 5 },
                                                                      }}
                                                                      onChange={event =>
                                                                        this.handleDatePickerReceiptDate(
                                                                          event
                                                                        )
                                                                      }
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              )}

                                                            {this.state.status_debitnote[
                                                              this.state.debitnote_key
                                                            ] == undefined
                                                              ? null
                                                              : this.state.status_debitnote[
                                                                this.state.debitnote_key
                                                              ].value == 3 &&
                                                              this.state.receipt_id == null && (
                                                                <div className="detail">
                                                                  <div style={{ color: 'red' }}>
                                                                    ไม่สามารถ อัพเดตเป็นสถานะ{' '}
                                                                    {this.state.status_debitnote[
                                                                      this.state.debitnote_key
                                                                    ] != undefined
                                                                      ? this.state
                                                                        .status_debitnote[
                                                                        this.state.debitnote_key
                                                                      ].label
                                                                      : ''}{' '}
                                                                      ได้เนื่องจากไม่มีหลักฐานการชำระเงิน
                                                                      .
                                                                    </div>
                                                                </div>
                                                              )}
                                                          </p>
                                                        </div>

                                                        <div className="footer">
                                                          <button
                                                            className="btn btn-grey"
                                                            style={{ width: '100px' }}
                                                            onClick={() =>
                                                              this.setState({
                                                                isOpenModalDebitNoteUpdateStatus: false,
                                                              })
                                                            }
                                                          >
                                                            ปิด
                                                          </button>
                                                          {this.state.status_debitnote[
                                                            this.state.debitnote_key
                                                          ] == undefined ? null : this.state
                                                            .status_debitnote[
                                                            this.state.debitnote_key
                                                          ].value == 3 &&
                                                            this.state.receipt_id == null ? null : (
                                                                <button
                                                                  className="btn btn-red"
                                                                  style={{ width: '100px' }}
                                                                  onClick={() => debitNoteUpdate()}
                                                                >
                                                                  ยืนยัน
                                                                </button>
                                                              )}
                                                        </div>
                                                      </ModalContent>
                                                    </Modal.Body>
                                                  </Modal>
                                                )}
                                              </Mutation>
                                              {debitNote_loading || debitNote_error ? (
                                                <div>loading...</div>
                                              ) : debitNoteList.length === 0 ? (
                                                <EmptyWrapper>
                                                  <i class="fal fa-empty-set"></i>
                                                  <b>- ไม่มีรายการ -</b>
                                                </EmptyWrapper>
                                              ) : (
                                                    debitNoteList.map((prop, key) => (
                                                      <div key={key}>
                                                        {key != 0 && <Blankspace />}
                                                        <Row>
                                                          <Col md={12}>
                                                            <RowItemGroup>
                                                              <div className="left">
                                                                <SelectBox
                                                                  onChange={event =>
                                                                    this.changeStatusDebitNote(
                                                                      event,
                                                                      key
                                                                    )
                                                                  }
                                                                  disabled={
                                                                    prop.status == 3
                                                                      ? 'disabled'
                                                                      : false
                                                                  }
                                                                  value={this.renderSelectedStatusDebitNote(
                                                                    prop.status,
                                                                    key
                                                                  )}
                                                                  options={selectOptionStatus}
                                                                  inputLabel="สถานะ:"
                                                                  LabelWeight={true}
                                                                  required
                                                                  messageError={
                                                                    this.state.statusInvalid &&
                                                                    'กรุณากรอกข้อมูล'
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="right">
                                                                <Button
                                                                  className="btn btn-red"
                                                                  onClick={() =>
                                                                    this.setState({
                                                                      isOpenModalDebitNoteUpdateStatus: true,
                                                                      receipt_id: prop.receipt_id,
                                                                      debitnote_id: prop._id,
                                                                      debitnote_key: key,
                                                                      debitnote_number:
                                                                        prop.debit_note_number,
                                                                      debitnote_status: prop.status,
                                                                      receipt_date: null,
                                                                      receipt_date_send: null,
                                                                    })
                                                                  }
                                                                  disabled={this.checkDisabledUpdateStatusDebitnote(
                                                                    key
                                                                  )}
                                                                >
                                                                  อัพเดท
                                                            </Button>
                                                              </div>
                                                            </RowItemGroup>

                                                            <RowItem>
                                                              <div className="left">ลูกค้า:</div>
                                                              <div className="right">
                                                                {prop.account_info_business_name}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                เลขที่ใบเพิ่มหนี้:
                                                          </div>
                                                              <div className="right">
                                                                {prop.debit_note_number}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                อ้างอิงเลขที่ใบแจ้งหนี้:
                                                          </div>
                                                              <div className="right">
                                                                {prop.summary_invoice_number}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                วันที่ออกใบเพิ่มหนี้:
                                                          </div>
                                                              <div className="right">
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.issue_date}
                                                                </Moment>
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                วันครบกำหนดชำระ:
                                                          </div>
                                                              <div className="right">
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.due_date}
                                                                </Moment>
                                                              </div>
                                                            </RowItem>
                                                            <Line />
                                                            <RowItem>
                                                              <div
                                                                className="left"
                                                                style={{
                                                                  paddingTop: '5px',
                                                                  display: 'block',
                                                                }}
                                                              >
                                                                ที่ต้องชำระ:
                                                          </div>
                                                              <div className="right">
                                                                <span className="total">
                                                                  {parseFloat(
                                                                    prop.debit_note_total_price
                                                                  ).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                  })}{' '}
                                                              บาท
                                                            </span>
                                                              </div>
                                                            </RowItem>

                                                            <Line />

                                                            <ShipmentItem
                                                              className={
                                                                !this.state.openBanktranfer &&
                                                                  (this.state.pdfFile ==
                                                                    `${s3}/${prop.debit_note_document}` ||
                                                                    (this.state.pdfFile == null &&
                                                                      key == 0 &&
                                                                      this.state.mode == 'debitnote'))
                                                                  ? 'selected'
                                                                  : ''
                                                              }
                                                              onClick={() =>
                                                                this.setState({
                                                                  pdfFile: `${s3}/${prop.debit_note_document}`,
                                                                  openBanktranfer: false,
                                                                  selectIndex: null,
                                                                })
                                                              }
                                                            >
                                                              <div className="icon">
                                                                <i className="fal fa-file-alt"></i>
                                                              </div>
                                                              <div className="detail">
                                                                <b>ใบเพิ่มหนี้:</b>
                                                                <span>{prop.debit_note_number}</span>
                                                              </div>
                                                              <div className="righticon">
                                                                <Button
                                                                  style={{
                                                                    float: 'left',
                                                                    marginTop: '-7px',
                                                                  }}
                                                                  onClick={() =>
                                                                    this.setState(
                                                                      {
                                                                        debitnote_id: prop._id,
                                                                      },
                                                                      () =>
                                                                        this._inputUploadDebitNote.current.click()
                                                                    )
                                                                  }
                                                                >
                                                                  เปลี่ยนเอกสาร
                                                            </Button>
                                                                {!this.state.openBanktranfer &&
                                                                  (this.state.pdfFile ==
                                                                    `${s3}/${prop.debit_note_document}` ||
                                                                    (this.state.pdfFile == null &&
                                                                      key == 0 &&
                                                                      this.state.mode == 'debitnote')) ? (
                                                                    <i className="fal fa-chevron-left"></i>
                                                                  ) : (
                                                                    <i className="fal fa-chevron-right"></i>
                                                                  )}
                                                              </div>
                                                            </ShipmentItem>

                                                            {prop.paid_type == null ||
                                                              prop.paid_type == 4 ? (
                                                                <ShipmentItem
                                                                  className={
                                                                    this.state.openBanktranfer &&
                                                                      this.state.selectIndex == key
                                                                      ? 'selected'
                                                                      : ''
                                                                  }
                                                                  onClick={() =>
                                                                    this.onOpenbanktranfer(
                                                                      prop.paid_bank_transfer,
                                                                      prop.summary_invoice_id,
                                                                      key,
                                                                      prop.debit_note_total_price,
                                                                      prop._id,
                                                                      prop.receipt_id
                                                                    )
                                                                  }
                                                                >
                                                                  {console.log(
                                                                    'paid_bank_transfer',
                                                                    prop
                                                                  )}
                                                                  <div className="icon">
                                                                    <i className="fal fa-file-invoice-dollar"></i>
                                                                  </div>
                                                                  <div
                                                                    className="detail"
                                                                    style={{ width: 'calc(100% - 80px)' }}
                                                                  >
                                                                    <b>หลักฐานการชำระ:</b>
                                                                    {prop.paid_bank_transfer ? (
                                                                      <span>
                                                                        แจ้งเมื่อ{' '}
                                                                        <Moment format="DD/MM/YYYY HH:mm">
                                                                          {
                                                                            prop.paid_bank_transfer
                                                                              .transfer_date
                                                                          }
                                                                        </Moment>{' '}
                                                                      </span>
                                                                    ) : (
                                                                        <span>
                                                                          อัปโหลดข้อมูลการชำระเงิน
                                                                        </span>
                                                                      )}
                                                                  </div>
                                                                  <div
                                                                    className="righticon"
                                                                    style={{ width: '40px' }}
                                                                  >
                                                                    {this.state.openBanktranfer &&
                                                                      this.state.selectIndex == key ? (
                                                                        <i className="fal fa-chevron-left"></i>
                                                                      ) : (
                                                                        <i className="fal fa-chevron-right"></i>
                                                                      )}
                                                                  </div>
                                                                </ShipmentItem>
                                                              ) : null}

                                                            {prop.paid_type == 1 &&
                                                              prop.paid_credit_card_detail && (
                                                                <ShipmentItem>
                                                                  <div className="icon">
                                                                    <i className="fal fa-file-invoice-dollar"></i>
                                                                  </div>
                                                                  <div className="detail">
                                                                    <b>ชำระค่าบริการผ่านบัตรเครดิต:</b>
                                                                    <span>
                                                                      แจ้งเมื่อ{' '}
                                                                      <Moment format="DD/MM/YYYY HH:mm">
                                                                        {prop.receipt_create_date}
                                                                      </Moment>{' '}
                                                                    </span>
                                                                  </div>
                                                                </ShipmentItem>
                                                              )}

                                                            {/* <ShipmentItem className="selected">
                                                                                                <div className="icon">
                                                                                                    <i className="fal fa-file-alt"></i>
                                                                                                </div>
                                                                                                <div className="detail">
                                                                                                    <b>ใบแจ้งหนี้:</b>
                                                                                                    <span>INVG2019050003</span>
                                                                                                </div>
                                                                                                <div className="righticon">
                                                                                                    <i className="fal fa-chevron-left"></i>
                                                                                                </div>
                                                                                            </ShipmentItem>  */}
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    ))
                                                  )}
                                            </div>
                                          )}

                                          {this.state.mode == 'receipt' && (
                                            <div className="tab-item">
                                              <TitleLeft>รายละเอียดการชำระเงิน</TitleLeft>
                                              <Mutation
                                                mutation={receiptSummaryUpdateMutation}
                                                update={(cache, { data }) => {
                                                  this.setState(
                                                    {
                                                      pdfFile: null,
                                                      receipt_id: null,
                                                    },
                                                    () => {
                                                      refetchDebitNote();
                                                      refetchReceipt();
                                                    }
                                                  );
                                                }}
                                                onError={error => {
                                                  console.log(error);
                                                }}
                                              >
                                                {receiptSummaryDocummentUpdate => (
                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      receiptSummaryDocummentUpdate({
                                                        variables: {
                                                          _id: this.state.receipt_id,
                                                          receipt_document:
                                                            data.singleUpload.path,
                                                        },
                                                      });
                                                    }}
                                                    onError={error => {
                                                      console.log(error);
                                                    }}
                                                  >
                                                    {uploadFileRecipt => {
                                                      return (
                                                        <input
                                                          accept="image/jpeg, image/png, application/pdf"
                                                          onChange={event => {
                                                            this.handleSingleUploadDocumentfile(
                                                              uploadFileRecipt,
                                                              'document_recipt',
                                                              event
                                                            );
                                                          }}
                                                          type="file"
                                                          id="file"
                                                          ref={this._inputUploadRecipt}
                                                          style={{ display: 'none' }}
                                                        />
                                                      );
                                                    }}
                                                  </Mutation>
                                                )}
                                              </Mutation>

                                              {receipt_loading || receipt_error ? (
                                                <div>loading...</div>
                                              ) : receiptSummaryList.length === 0 ? (
                                                <EmptyWrapper>
                                                  <i class="fal fa-empty-set"></i>
                                                  <b>- ไม่มีรายการ -</b>
                                                </EmptyWrapper>
                                              ) : (
                                                    receiptSummaryList.map((prop, key) => (
                                                      <div key={key}>
                                                        {key != 0 && <Blankspace />}
                                                        <Row>
                                                          <Col md={12}>
                                                            <ShipmentItem>
                                                              <div className="icon">
                                                                <i className="fal fa-file-alt"></i>
                                                              </div>
                                                              <div className="detail">
                                                                <span style={{ marginBottom: '10px' }}>
                                                                  ช่องทางการชำระ:
                                                            </span>
                                                                <b style={{ marginBottom: '0' }}>
                                                                  {this.renderPaidType(prop.paid_type)}
                                                                </b>
                                                              </div>
                                                            </ShipmentItem>

                                                            <RowItem>
                                                              <div className="left">ลูกค้า:</div>
                                                              <div className="right">
                                                                {prop.account_info_business_name}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">สถานะ:</div>
                                                              <div className="right">
                                                                {this.renderTextStatusReceipt(
                                                                  prop.status
                                                                )}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">วันที่ชำระ:</div>
                                                              <div className="right">
                                                                <b>
                                                                  <Moment format="DD/MM/YYYY">
                                                                    {prop.paid_type == 4
                                                                      ? prop.paid_bank_transfer
                                                                        .transfer_date
                                                                      : prop.receipt_date}
                                                                  </Moment>
                                                                </b>
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">รหัสใบเสร็จ:</div>
                                                              <div className="right">
                                                                {prop.receipt_number}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                {prop.summary_invoice_number
                                                                  ? 'อ้างอิงใบแจ้งหนี้'
                                                                  : 'อ้างอิงใบเพิ่มหนี้'}
                                                            :
                                                          </div>
                                                              <div className="right">
                                                                {prop.summary_invoice_number
                                                                  ? prop.summary_invoice_number
                                                                  : prop.debit_note_number}
                                                              </div>
                                                            </RowItem>
                                                            <RowItem>
                                                              <div className="left">
                                                                วันที่ออกใบเสร็จ:
                                                          </div>
                                                              <div className="right">
                                                                <Moment format="DD/MM/YYYY">
                                                                  {prop.create_date}
                                                                </Moment>
                                                              </div>
                                                            </RowItem>
                                                            <Line />
                                                            <RowItem>
                                                              <div
                                                                className="left"
                                                                style={{
                                                                  paddingTop: '5px',
                                                                  display: 'block',
                                                                }}
                                                              >
                                                                ที่ต้องชำระ:
                                                          </div>
                                                              <div className="right">
                                                                <span
                                                                  className="total"
                                                                  style={{ color: '#19AA19' }}
                                                                >
                                                                  {parseFloat(
                                                                    prop.receipt_total_price
                                                                  ).toLocaleString('en-US', {
                                                                    maximumFractionDigits: 2,
                                                                  })}{' '}
                                                              บาท
                                                            </span>
                                                              </div>
                                                            </RowItem>

                                                            <Line />

                                                            <ShipmentItem
                                                              className={
                                                                this.state.pdfFile ==
                                                                  `${s3}/${prop.receipt_document}` ||
                                                                  (this.state.pdfFile == null &&
                                                                    key == 0 &&
                                                                    this.state.mode == 'receipt')
                                                                  ? 'selected'
                                                                  : ''
                                                              }
                                                              onClick={() =>
                                                                this.setState({
                                                                  pdfFile: `${s3}/${prop.receipt_document}`,
                                                                })
                                                              }
                                                            >
                                                              <div className="icon">
                                                                <i className="fal fa-file-alt"></i>
                                                              </div>
                                                              <div className="detail">
                                                                <b>ใบเสร็จ:</b>
                                                                <span>{prop.receipt_number}</span>
                                                              </div>
                                                              <div className="righticon">
                                                                <Button
                                                                  style={{
                                                                    float: 'left',
                                                                    marginTop: '-7px',
                                                                  }}
                                                                  onClick={() =>
                                                                    this.setState(
                                                                      {
                                                                        receipt_id: prop._id,
                                                                      },
                                                                      () =>
                                                                        this._inputUploadRecipt.current.click()
                                                                    )
                                                                  }
                                                                >
                                                                  เปลี่ยนเอกสาร
                                                            </Button>
                                                                {this.state.pdfFile ==
                                                                  `${s3}/${prop.receipt_document}` ||
                                                                  (this.state.pdfFile == null &&
                                                                    key == 0 &&
                                                                    this.state.mode == 'receipt') ? (
                                                                    <i className="fal fa-chevron-left"></i>
                                                                  ) : (
                                                                    <i className="fal fa-chevron-right"></i>
                                                                  )}
                                                              </div>
                                                            </ShipmentItem>

                                                            {/* <ShipmentItem className="selected">
                                                                                                <div className="icon">
                                                                                                    <i className="fal fa-file-alt"></i>
                                                                                                </div>
                                                                                                <div className="detail">
                                                                                                    <b>ใบแจ้งหนี้:</b>
                                                                                                    <span>INVG2019050003</span>
                                                                                                </div>
                                                                                                <div className="righticon">
                                                                                                    <i className="fal fa-chevron-left"></i>
                                                                                                </div>
                                                                                            </ShipmentItem>  */}
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    ))
                                                  )}
                                            </div>
                                          )}
                                        </TabContentContainer>
                                      </div>

                                      {this.state.openBanktranfer && (
                                        <Mutation
                                          mutation={payInvoiceMutation}
                                          update={() => {
                                            this.setState(
                                              {
                                                _id: null,
                                                debitnote_id: null,
                                                receipt_id: null,
                                                tranfer_date: moment().format('DD/MM/YYYY HH:mm'),
                                                tranfer_date_send: moment().format(
                                                  'YYYY-MM-DD HH:mm'
                                                ),
                                                tranfer_amount: '',
                                                tranfer_picture: null,
                                                openBanktranfer: false,
                                              },
                                              () => {
                                                refetchInvoice();
                                                refetchDebitNote();
                                              }
                                            );
                                          }}
                                          onError={() => {
                                            alert('กรอกข้อมูลให้ครบถ้วน และ ถูกต้อง');
                                          }}
                                        >
                                          {payInvoice => (
                                            <Mutation
                                              mutation={receiptSummaryUpdateMutation}
                                              update={() => {
                                                this.setState(
                                                  {
                                                    receipt_id: null,
                                                    _id: null,
                                                    debitnote_id: null,
                                                    tranfer_date: moment().format(
                                                      'DD/MM/YYYY HH:mm'
                                                    ),
                                                    tranfer_date_send: moment().format(
                                                      'YYYY-MM-DD HH:mm'
                                                    ),
                                                    tranfer_amount: '',
                                                    tranfer_picture: null,
                                                    openBanktranfer: false,
                                                  },
                                                  () => {
                                                    refetchInvoice();
                                                    refetchDebitNote();
                                                  }
                                                );
                                              }}
                                              onError={() => {
                                                alert('กรอกข้อมูลให้ครบถ้วน และ ถูกต้อง');
                                              }}
                                            >
                                              {receiptSummaryUpdate => (
                                                <div className="paymentDetail-sub">
                                                  <Mutation
                                                    mutation={singleUploadMutation}
                                                    update={(cache, { data }) => {
                                                      this.setState({
                                                        tranfer_picture: data.singleUpload.path,
                                                      });
                                                    }}
                                                    onError={error => {
                                                      ////console.log(error)
                                                    }}
                                                  >
                                                    {uploadFile => {
                                                      return (
                                                        <input
                                                          accept="image/jpeg, image/png, application/pdf"
                                                          onChange={event =>
                                                            this.handleSingleUploadDocument(
                                                              uploadFile,
                                                              'document',
                                                              event
                                                            )
                                                          }
                                                          type="file"
                                                          id="file"
                                                          ref={this._inputUploadImage}
                                                          style={{ display: 'none' }}
                                                        />
                                                      );
                                                    }}
                                                  </Mutation>
                                                  <div className="inner">
                                                    <h5
                                                      style={{ margin: '0', padding: '11px 15px' }}
                                                    >
                                                      <b>ชำระผ่าน Mobile banking/ ATM</b>
                                                      <i
                                                        style={{
                                                          float: 'right',
                                                          cursor: 'pointer',
                                                        }}
                                                        className="fal fa-times"
                                                        onClick={() =>
                                                          this.setState({
                                                            openBanktranfer: false,
                                                            selectIndex: null,
                                                          })
                                                        }
                                                      ></i>
                                                    </h5>
                                                    <hr style={{ margin: '0 0 10px' }} />

                                                    <div style={{ paddingLeft: '15px' }}>
                                                      <TitleLeft>
                                                        สามารถโอนมาได้ที่บัญชีดังนี้:
                                                      </TitleLeft>

                                                      <RowItem>
                                                        <div className="left">บัญชี:</div>
                                                        <div className="right">
                                                          <img src={imgSCBBank} />
                                                        </div>
                                                      </RowItem>
                                                      <RowItem>
                                                        <div className="left">ชื่อย่อบัญชี:</div>
                                                        <div className="right">
                                                          <b>GIZTIX CO.,LTD.</b>
                                                        </div>
                                                      </RowItem>
                                                      <RowItem>
                                                        <div className="left">เลขที่บัญชี:</div>
                                                        <div className="right">
                                                          <b> 4680752306</b>
                                                        </div>
                                                      </RowItem>

                                                      <small>
                                                        หมายเหตุ :
                                                        หลังจากโอนเงินแล้วกรุณาแจ้งการโอนโดย
                                                        กรอกข้อมูลด้านล่างให้ถูกต้องครบถ้วน
                                                        ระบบจะทำการตรวจสอบ และแจ้งสถานะการชำระเงิน
                                                      </small>
                                                      <Line />
                                                    </div>

                                                    <Row
                                                      style={{
                                                        marginBottom: '20px',
                                                        paddingBottom: '20px',
                                                        padding: '15px',
                                                      }}
                                                    >
                                                      <Col style={{ margin: '10px 0' }} md={12}>
                                                        วัน/เวลาที่โอน{' '}
                                                        <span style={{ color: 'red' }}>*</span>{' '}
                                                      </Col>
                                                      <Col md={12}>
                                                        <Datetime
                                                          value={this.state.tranfer_date}
                                                          dateFormat="DD/MM/YYYY"
                                                          timeFormat="HH:mm"
                                                          timeConstraints={{ minutes: { step: 5 } }}
                                                          onChange={event =>
                                                            this.handleDatePickerTranfer(event)
                                                          }
                                                        />
                                                      </Col>

                                                      <Col style={{ margin: '10px 0' }} md={12}>
                                                        จำนวนเงินที่ชำระ
                                                        <span style={{ color: 'red' }}>*</span>{' '}
                                                        (บาท){' '}
                                                      </Col>
                                                      <Col md={12}>
                                                        <FormControl
                                                          type="number"
                                                          min={0}
                                                          value={this.state.tranfer_amount}
                                                          onChange={e =>
                                                            this.setState({
                                                              tranfer_amount: e.target.value,
                                                            })
                                                          }
                                                          placeholder="จำนวนเงินที่ชำระ"
                                                          name="tranfer_amount"
                                                        />
                                                      </Col>

                                                      <Col style={{ margin: '10px 0' }} md={12}>
                                                        อัพโหลดหลักฐานการชำระเงิน{' '}
                                                        {this.state.tranfer_picture == null && (
                                                          <small
                                                            style={{
                                                              float: 'right',
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={() =>
                                                              this._inputUploadImage.current.click()
                                                            }
                                                          >
                                                            เพิ่มรูปภาพ >{' '}
                                                          </small>
                                                        )}
                                                      </Col>
                                                      <Col md={12}>
                                                        {this.state.tranfer_picture != null && (
                                                          <ImageContainer>
                                                            <div className="image">
                                                              <div className="image-hover">
                                                                <div
                                                                  className="btn-hover-image"
                                                                  onClick={() => {
                                                                    this.setState({
                                                                      isOpeTranferLightbox: true,
                                                                    });
                                                                  }}
                                                                >
                                                                  <i className="fal fa-search"></i>
                                                                  <span>ดูรูปภาพ</span>
                                                                </div>
                                                                <div
                                                                  className="btn-hover-image"
                                                                  onClick={() => {
                                                                    this.onDeletePhoto(
                                                                      this.state.tranfer_picture
                                                                    );
                                                                  }}
                                                                >
                                                                  <i className="fal fa-trash-alt"></i>
                                                                  <span>ลบรูปภาพ</span>
                                                                </div>
                                                              </div>
                                                              <img
                                                                src={`${s3}/${this.state.tranfer_picture}`}
                                                                alt=""
                                                              />
                                                            </div>
                                                          </ImageContainer>
                                                        )}
                                                      </Col>

                                                      <Col md={12}>
                                                        <Button
                                                          onClick={e =>
                                                            this.onSubmitAddTranfer(
                                                              e,
                                                              payInvoice,
                                                              receiptSummaryUpdate
                                                            )
                                                          }
                                                          disabled={!this.checkValidateAddTranfer()}
                                                          className="btn btn-red"
                                                          block
                                                        >
                                                          ตกลง
                                                        </Button>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                </div>
                                              )}
                                            </Mutation>
                                          )}
                                        </Mutation>
                                      )}
                                      <div className="paymentDetail-pdf">
                                        <PDFPreview
                                          file={this.renderPdfFile(
                                            this.state.mode,
                                            invoiceList,
                                            debitNoteList,
                                            receiptSummaryList
                                          )}
                                        />
                                      </div>
                                    </PaymentDetailContainer>
                                  }
                                />
                              </Col>
                            </Row>
                          </Grid>
                        </Container>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default compose(
  graphql(summaryInvoiceListQuery, {
    name: 'invoiceList',
    options: {
      variables: {
        summary_invoice_id: id,
      },
    },
  }),
  graphql(debitNoteListQuery, {
    name: 'debitNoteList',
    options: {
      variables: {
        summary_invoice_id: id,
      },
    },
  }),
  graphql(receiptSummaryListQuery, {
    name: 'receiptList',
    options: {
      variables: {
        summary_invoice_id: id,
      },
    },
  })
)(PaymentDetail);
