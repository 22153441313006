import React, { useState } from 'react';
import styled from 'styled-components';
import ModalConfirmCreateQr from './ModalConfirmCreateQr';

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 130px;
  height: 130px;
  background-color: rgba(49, 141, 232, 0.1);
  border: 1px dashed #318de8;
  border-radius: 6px;

  & i {
    color: #318de8;
    font-size: 20px;
  }

  & > span {
    color: #318de8;
    margin-top: 10px;
  }
`;

const MessageRed = styled.span`
  color: red;
`;

const GenerateQrCode = ({ onCompleted, codList = [] }) => {
  const [isOpenModalConfirmCreateQr, setOpenModalConfirmCreateQr] =
    useState(false);

  return (
    <>
      <Box onClick={() => setOpenModalConfirmCreateQr(true)}>
        <i class="far fa-plus-circle"></i>
        <span>
          สร้าง QR-Code<MessageRed>*</MessageRed>
        </span>
      </Box>

      <ModalConfirmCreateQr
        isOpen={isOpenModalConfirmCreateQr}
        codList={codList}
        onCancel={() => setOpenModalConfirmCreateQr(false)}
        onCompleted={(values) => {
          onCompleted &&
            onCompleted({
              id: values.id,
              date: values.date,
              ref1: values.ref1,
              ref2: values.ref2,
              image: values.image,
              paidAmount: values.paidAmount,
              billingCod: values.billingCod,
            });
        }}
      />
    </>
  );
};

export default GenerateQrCode;
