import gql from 'graphql-tag';

export default gql`
mutation  Update(
    $_id: ID!
    $license_plate: String
){
    userSettingTruckUpdate(
      _id: $_id,
      user_setting_truck_license_plate: $license_plate
    ){
      _id
    }
  }
`;