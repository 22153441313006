import React, { Component, createRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import lambdaUrl from 'config/lambdaUrl';
import UploadFile from '../UploadFile';
import SelectCustomer from '../../components/SelectCustomer';
import ModalConfirm from '../../../../components/ModalConfirm';
import { json } from 'body-parser';

class ModalImportFileProduct extends Component {
  constructor(props) {
    super(props);
    this._inputImportFile = createRef();
    this.state = {
      customer: '',
      file: null,
    };
  }

  componentDidMount() {
    this.setState({
      customer: '',
      file: null,
    });
  }

  onCancel = () => {
    this.props.onClose && this.props.onClose();
    this.setState({
      file: null,
      customer: '',
      isLoading: false,
    });
  };

  uploadFileHandler = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const data = JSON.stringify({
        path: this.state.file.path,
        account_info: this.state.customer,
      });

      await axios
        .post(`${lambdaUrl}/import-excel-order`, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(res => {
          if (res.data.statusCode === 500) {
            // throw new Error(`${lambdaUrl}/import-excel-order ` + res.data?.messageError);
            throw new Error(res.data?.messageError);
          }

          if (res.data.statusCode === 200) {
            this.onCancel();
            this.props.onCompleted && this.props.onCompleted();
          }
        });
    } catch (err) {
            alert(err.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { open } = this.props;
    const { customer, file } = this.state;

    return (
      <ModalConfirm
        show={open}
        onPressButtonConfirm={() => {
          this.uploadFileHandler();
        }}
        styleColorButtonConfirm="primary"
        labelButtonConfirm={`อัพโหลดไฟล์${this.state.isLoading ? '...' : ''}`}
        labelButtonCancel="ยกเลิก"
        onPressButtonCancel={() => this.onCancel()}
        titleHeader="Import Product list"
        onHide={() => this.onCancel()}
        disabledButtonConfirm={!this.state.customer || !this.state.file || this.state.isLoading}
      >
        <Row>
          <Col xs={6} style={{ marginBottom: 20 }}>
            <strong>เลือกลูกค้า :</strong>
            <SelectCustomer
              value={customer}
              onChange={event =>
                this.setState({
                  customer: event.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} style={{ marginBottom: 20 }}>
            <strong>Import file :</strong>
            <div>
              <UploadFile
                completed={({ fileName, path }) => {
                  this._inputImportFile.current.value = null;
                  this.setState({
                    file: {
                      fileName,
                      path,
                    },
                  });
                }}
              >
                {selectFileImport => {
                  return (
                    <React.Fragment>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={event => {
                          selectFileImport(
                            event,
                            `upload/lazadaProject/document_${new Date().getTime()}`
                          );
                        }}
                        type="file"
                        id="file"
                        ref={this._inputImportFile}
                        style={{ display: 'none' }}
                      />
                      <Button onClick={() => this._inputImportFile.current.click()}>
                        <i className="fas fa-cloud-upload"></i> Import Booking
                      </Button>{' '}
                      {file?.fileName || ''}
                    </React.Fragment>
                  );
                }}
              </UploadFile>
            </div>
          </Col>
          <Col xs={12}>
            <a
              target="_blank"
              href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_import_products_v4.xlsx"
              style={{
                color: '#0F8EE9',
              }}
            >
              ดาวน์โหลดไฟล์ตัวอย่าง
            </a>
          </Col>
          <Col xs={12}>
            <a
              target="_blank"
              href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/GiztixMasterProvince_v1.xlsx"
              style={{
                marginTop: 15,
                color: '#0F8EE9',
              }}
            >
              ดาวน์โหลดไฟล์ Data master จังหวัด/อำเภอ/รหัสไปรษณีย์
            </a>
          </Col>
        </Row>
      </ModalConfirm>
    );
  }
}

export default ModalImportFileProduct;
