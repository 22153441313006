import gql from 'graphql-tag';

export default gql`
query thirdPartyList(
    $_id: ID
    $account_info_id: ID
    $ka_id: ID!
    $active: Boolean
  ) {
      thirdPartyList (
        _id: $_id,
        account_info_id: $account_info_id, 
        ka_id: $ka_id,
        active: $active
      ) {
        third_party_list {
          _id
          account_info_id
          ka_id
          percent
          active
          shipment_improgress
          shipment_completed
          shipment_sum
        }
      }
    }
`;
