import gql from 'graphql-tag';

export default gql`
  query CustomerImportProduct(
    $show_data: Int
    $customer_service_type: String
    $account_info_business_name: String
  ) {
    accountInfoList(
      show_data: $show_data
      customer_service_type: $customer_service_type
      account_info_business_name: $account_info_business_name
    ) {
      account_info {
        _id
        account_info_business_name
      }
    }
  }
`;
