import dayjs from 'dayjs';

export const FunctionGetYearList = () => {
  const minYear = '2021';
  const diffYear = dayjs().diff(minYear, 'year') + 1;
  return new Array(diffYear).fill({}).map((_, index) => (+minYear + index).toString());
};

export const FunctionGoToSetDate = (mouth, year, ref) => {
  let calendarApi = ref.current.getApi();
  const date = dayjs(`${year}-${mouth}-1`).format('YYYY-MM-DD');
  console.log(date);
  calendarApi.gotoDate(date); // call a method on the Calendar object
  // calendarApi.prev();

  console.log('calendarApi.getDate()', calendarApi.getDate());
};

export const FunctionOnClickEvnet = (info, setCalendarDetail, setOpen) => {
  setCalendarDetail(`${dayjs(info.event.start).format('YYYY-MM-DD')}`);
  setOpen(true);
};
