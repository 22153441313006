import gql from 'graphql-tag';

export default gql`
  mutation TrackingUpdate(
    $_id: ID!
    $transporter_detail: String
    $transporter_phone_code: String
    $transporter_phone: String
    $transporter: ID
  ) {
    trackingUpdate(
      _id: $_id
      transporter_detail : $transporter_detail
      transporter_phone_code : $transporter_phone_code
      transporter_phone : $transporter_phone
      transporter : $transporter
    ) {
      _id
    }
  }
`;
