import React from 'react';
import { MenuWrapper } from './styled';
import SubHeader from '../SubHeader';
import classes from './SubHeaderCOD.module.css';

const HeaderLayoutWithBackUrl = props => {
  return (
    <SubHeader
      backUrl={props.backUrl ? props.backUrl : `/`}
      style={{ margin: '0', borderBottom: '1px solid #efefef' }}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <div style={{ width: 'max-content' }}>
            <strong style={{ fontSize: '20px' }}>{props.headerName ? props.headerName : ''}</strong>
          </div>
        </div>
        <MenuWrapper>
          {console.log('props hide', props)}
          <ul>
            {props.menus &&
              props.menus.length > 0 &&
              props.menus.map(
                data =>
                  !data.hide && (
                    <li
                      className={`${props.stage == data.stage && 'active'} ${data.disabled &&
                        'disabled'}`}
                      onClick={() => props.selectedState(data.stage)}
                    >
                      <i className={data.icon} style={{ marginRight: '5px' }}></i>
                      <a>
                        {data.title} {data.hide}
                      </a>
                    </li>
                  )
              )}
          </ul>
        </MenuWrapper>
      </div>
    </SubHeader>
  );
};

export default HeaderLayoutWithBackUrl;
