import Customer_Id from 'config/customerId';

const addSuffixShipment = customerId => {
  if (customerId == Customer_Id.jd) {
    return ' (JDBU) ';
  } else if (customerId == Customer_Id.jdsm) {
    return ' (JDSM) ';
  } else if (customerId == Customer_Id.lazada) {
    return ' (Lazada) ';
  } else {
    return '';
  }
};

export default addSuffixShipment;
