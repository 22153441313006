import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Label,
  Image,
  Button,
  Modal,
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
import DocumentDelete from './DocumentDelete';

import costingDocumentsQuery from './../../../../../graphql/query/costingDocuments';

import s3Bucket from 'config/s3Bucket';

const ContainerStyled = styled.div`
  padding: 15px;
`;

const FileContainer = styled.div`
  border: 1px solid #e3e3e3;
  background-color: #f9f9f9;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentId : null,
      isOpenDocumentDelete: false
    };
  }

  render() {
    return (
      <ContainerStyled>
      <Query
          query={costingDocumentsQuery}
          variables={{
            costing_id: this.props.costingId,
            delete: false,
          }}
        >
          {({ loading, error, data, refetch, variables }) => {
            if (loading || error) {
              return <div></div>;
            }

            if (
              data &&
              data.costingDocuments &&
              data.costingDocuments.documents.length === 0
            ) {
              return <div>ไม่มีข้อมูล</div>;
            }

            return (
              <div>
                <Row>
                  <Col>
                    {data.costingDocuments.documents.map((item, index) => {
                      return (
                        <FileContainer>
                          <Button
                            style={{ color: 'red', marginRight: 10 }}
                            onClick={() => {
                              this.setState({ documentId: item._id, isOpenDocumentDelete: true });
                            }}
                          >
                            <i className="far fa-trash-alt" />
                          </Button>
                          <a href={item.document_file} target="_blank" rel="noopener noreferrer">
                            {item.document_file}
                          </a>
                        </FileContainer>
                      );
                    })}
                  </Col>
                </Row>
              </div>
            );
          }}
        </Query>
        <DocumentDelete
          costingId={this.props.costingId}
          documentId={this.state.documentId}
          show={this.state.isOpenDocumentDelete}
          onHide={() => {
            this.setState({ isOpenDocumentDelete: false });
          }}
        />
      </ContainerStyled>
    );
  }
}

export default DocumentList;
