import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../../../graphql/query/shipmentList';

export default gql`
  query JDShipmentDetailTracking($_id: ID) {
    shipmentList(_id: $_id, shipment_prefix: "GSP") {
      shipment {
        ...shipmentFields
      }

      total_page
    }

    trackingList(shipment_id: $_id) {
      tracking {
        driver_name
        user_setting_truck_license_plate
        driver_phone_code
        driver_phone_number
      }
    }
  }

  ${shipmentListFragment}
`;
