import React, { Component } from 'react';
import styled from 'styled-components';
import urijs from 'urijs';
import {
    Row,
    Col,
    Button,
    FormControl,
    Modal,
    Image,
} from 'react-bootstrap';
import moment from 'moment';
import s3 from 'config/s3';
import Lightbox from 'lightbox-react';
import { Query, graphql, compose, Mutation } from "react-apollo";
import subThirdPartySettingUpdateMutation from './graphql/mutation/subThirdPartySettingUpdate';
import classes from './ProofofTransfer.module.scss';

class ProofofTransferModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showtransferDate: '',
            transferDate: '',
            start_date: '',
            proof_of_transfer: null,

            //reject cod 
            isOpenReject: false,
            objReject: null,
            remark: null,
            
            //viewImageSrc
            isOpenLightbox: false,
            viewImageSrc: '',

            //dataImage
            dataImage: null,
            photoIndex: 0,
            isOpenLightboxarray: false,

            //preventDoubleClick
            isAbleClick: true
        }
        
        this._inputAttachFileFirstCase = React.createRef();
    }
    renderLightboxarray(data) {
        const array = data.map((item, index) => {
            let obj = `${s3}/${item}`;
            return obj
        })
        this.setState({
            isOpenLightboxarray: true,
            dataImage: array,
            photoIndex: 0,
        });
      
    }
    renderLightbox(data) {
        this.setState({
            isOpenLightbox: true,
            viewImageSrc: `${s3}/${data}`,
        });
      
    }
    renderVariablesagentManageCOD (agentManageCOD,status,obj){
        this.setState({isAbleClick: false});
        const setobj = {
            _id: this.props.shipment._id,
            agent_shipment_status: 7,
            proof_of_transfer: {
                _id: obj._id,
                status: status,
                start_date: obj.start_date,
                trip_name: obj.trip_name,
                name_agent: obj.name_agent,
                account_info_id: obj.account_info_id,
                total_price_cod: obj.total_price_cod ? obj.total_price_cod : 0,
                slip: obj.slip,
                transfer_date: obj.transferDate,
            }
        }
        agentManageCOD({
            variables: setobj
        })
    }
    renderVariablesagentManageCODReject (agentManageCODReject,status,obj){
        const setobj = {
            _id: this.props.shipment._id,
            agent_shipment_status: 8,
            proof_of_transfer: {
                _id: obj._id,
                status: status,
                start_date: obj.start_date,
                trip_name: obj.trip_name,
                name_agent: obj.name_agent,
                account_info_id: obj.account_info_id,
                total_price_cod: obj.total_price_cod ? obj.total_price_cod : 0,
                slip: obj.slip,
                transfer_date: obj.transferDate,
                remark: this.state.remark,
            }
        }
        agentManageCODReject({
            variables: setobj
        })
    }
    getorderIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).orderid;
        return id;
    };
    formatMoney = number => {
        return parseInt(number).toLocaleString('en-US', {
            maximumFractionDigits: 2,
        });
    };
    getIdFromUrl = () => {
        const id = urijs(window.location.href).query(true).shipmentid;
        return id;
    };

    render() {
        let rowLen = this.props.proofTransfer.length;
        let lastitem = null;
        let payment = this.props.accountinfoId.payment_bank_account;
        return (
            <div  style={{display: this.props.isDataCheckedModal? 'block': 'none'}}>
                <Mutation
                    mutation={subThirdPartySettingUpdateMutation}
                    update={() => {
                        this.props.closeModal();
                        this.props.refetchagentManageCODList();
                      }}
                    onError={error => {
                        alert(error.graphQLErrors[0].message);
                        // this.checkTextError(error.graphQLErrors[0].message);
                    }}
                >
                    
                    {agentManageCOD => (
                        <Modal
                            show={this.props.isDataCheckedModal && this.state.isOpenLightbox || this.state.isOpenLightboxarray || this.state.isOpenReject ? false : true }
                            onHide={this.props.closeModal}>
                            <Modal.Header closeButton>
                                <h3 style={{marginBottom: '5px'}}><b>รายการชำระเงินค่า COD</b></h3>
                                <div style={{ marginBottom: '5px' }}><span>ตรวจสอบรายการโอนชำระ COD จาก 3PL</span></div>
                                <div>
                                    <span style={{ marginRight: '20px' }}>วันที่เริ่มงาน:&nbsp;&nbsp;<b>{moment(this.props.shipment.pick_up_date).format('DD/MM/YYYY - HH:mm')}</b></span>
                                    <span style={{ marginRight: '20px' }}>รอบ:&nbsp;&nbsp;<b>{this.props.shipment.trip_name}</b></span>
                                    <span style={{ marginRight: '20px' }}>นายหน้า:&nbsp;&nbsp;<b>{this.props.shipment.name_agent}</b></span>
                                </div>
                            </Modal.Header>
                            <Modal.Body style={{paddingTop: '0px'}}>
                                {
                                    this.props.proofTransfer.length > 0 && this.props.proofTransfer.map((item, index) => {
                                        if(rowLen === index + 1){
                                            lastitem = item;
                                            return (
                                                <div style={{padding: '10px'}}>
                                                    <Row>
                                                        <Col md={3} style={{ textAlign: 'right' }}>ยอดโอนทั้งหมด :</Col>
                                                        <Col md={9}><b>{this.formatMoney(item.total_price_cod)} บาท</b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={3} style={{ textAlign: 'right' }}>วัน-เวลาที่โอน :</Col>
                                                        <Col md={9}><b>{moment(item.transfer_date).format('DD/MM/YYYY - HH:mm')}</b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={3} style={{ textAlign: 'right' }}>เลขบัญชีที่โอนเข้า :</Col>
                                                        <Col md={9}><b>{payment.account_name ? payment.account_name : 'Giztix CO.,LTD'} , {payment.bank_name ? payment.bank_name : 'ซิตี้แบงก์'} , {payment.account_number ? payment.account_number : '8898500000'}</b></Col>
                                                    </Row>
                                                    <Row style={{marginTop: '10px'}}>
                                                        {
                                                            item.slip.length === 1 && item.slip.map((subitem, subindex) => (
                                                                <Col md={6}  mdOffset={3}>
                                                                    <Image onClick={() => this.renderLightbox(subitem)} style={{width: '100%'}} src={s3 + '/' + subitem} rounded />
                                                                </Col>
                                                            ))
                                                        }
                                                        {
                                                            item.slip.length > 1 && item.slip.map((subitem, subindex) => (
                                                                <Col md={6}>
                                                                    <Image onClick={() => this.renderLightbox(subitem)} style={{width: '100%'}} src={s3 + '/' + subitem} rounded />
                                                                </Col>
                                                            ))
                                                        }
                                                    </Row>
                                                </div>
                                            )
                                        }else{
                                            return (
                                                < div>
                                                    <div className={classes.wrapper}>
                                                        <div className={classes.title}>การโอนรอบที่ผ่านมา</div>
                                                        <div className={classes.content}>
                                                            <div className={classes.amount}>จำนวนที่โอน: <b>{item.total_price_cod} บาท</b></div>
                                                            <div className={classes.time}>วัน-เวลาที่โอน: <b>{moment(item.transfer_date).format('DD/MM/YYYY - HH:mm')}</b></div>
                                                            <div className={classes.btnright}>
                                                                <Button  onClick={() => this.renderLightboxarray(item.slip)} style={{ backgroundColor: '#ffffff', color: '#318de8', border: '1px dashed #318de8' }}>หลักฐานการโอน</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >
                                            )
                                        };
                                    })
                                }

                            </Modal.Body>
                            <Modal.Footer>
                                <Button style={{ float: "left" }} onClick={this.props.closeModal}>ปิด</Button>
                                    { this.props.shipment.agent_shipment_status !== 8 && this.props.shipment.agent_shipment_status !== 7 && 
                                        <>
                                            <Button 
                                                onClick={() => this.renderVariablesagentManageCOD(agentManageCOD,2,lastitem)} 
                                                style={{ float: "right", backgroundColor: '#5DBA5B', color: '#fff' }}
                                                disabled={!this.state.isAbleClick}
                                            >
                                                ยืนยันการโอนเงิน
                                            </Button>
                                            <Button 
                                                onClick={() => { 
                                                    this.setState({
                                                        isOpenReject: true,
                                                        objReject: lastitem,
                                                    })
                                                }} 
                                                style={{ float: "right", backgroundColor: '#D91010', color: '#fff' }}><i style={{ marginRight: '5px' }} class="far fa-times-circle"></i>ปฏิเสธยอดโอน
                                            </Button>
                                        </>
                                    }
                                    
                            </Modal.Footer>
                        </Modal>
                        )}
                </Mutation>
                
                <Mutation
                    mutation={subThirdPartySettingUpdateMutation}
                    update={() => {
                        this.props.closeModal();
                        this.props.refetchagentManageCODList();
                        this.setState({
                            isOpenReject: false,
                            objReject: null,
                            remark: null,
                        })
                    }}
                    onError={error => {
                        alert(error);
                    }}
                >
                {agentManageCODReject => (
                    <Modal
                        show={this.state.isOpenReject}
                        onHide={this.state.isOpenReject}>
                        <Modal.Header>
                            <Modal.Title><b>แจ้งเตือน</b></Modal.Title>
                            <div style={{ marginBottom: '5px' }}><span>ต้องการปฎิเสธยอดโอนนี้ใช่หรือไม่</span></div>
                        </Modal.Header>
                        <Modal.Body>
                        {this.state.objReject && 
                            <div>
                                <Row>
                                    <Col md={6}>วันที่เริ่มงาน: <b>{this.state.objReject ? moment(this.state.objReject.start_date).format('DD/MM/YYYY - HH:mm') : ''}</b></Col>
                                    <Col md={6}>จำนวนที่ต้องโอน: <b>{this.state.objReject.total_price_cod} บาท</b></Col>
                                    <Col md={12}>รอบ: <b>{this.state.objReject.trip_name}</b></Col>
                                    <Col md={12}>นายหน้า: <b>{this.state.objReject.name_agent}</b></Col>
                                </Row>
                                <Row  style={{marginTop: '15px'}}>
                                    <Col md={12} style={{ marginBottom: "10px" }}>เหตุผลที่ปฏิเสธ<span style={{color: 'red'}}>*</span></Col>
                                    <Col md={12}>
                                            <FormControl
                                                as="textarea"
                                                aria-label="With textarea"
                                                placeholder="ระบุ"
                                                rows="3"
                                                onChange={e =>
                                                    this.setState({
                                                        remark: e.target.value,
                                                    })
                                                }
                                                value={this.state.remark ? this.state.remark : this.state.objReject.remark }
                                            />
                                    </Col>
                                </Row>
                            </div>    
                        }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button  onClick={() => {  this.setState({  isOpenReject: false,   objReject: null, })}} >ปิด</Button>
                            <Button 
                                onClick={() => this.renderVariablesagentManageCODReject(agentManageCODReject,3,lastitem)} 
                                style={{ float: "right", backgroundColor: '#5DBA5B', color: '#fff' }}
                                disabled={!this.state.remark}
                            >
                                ใช่, ฉันยืนยัน</Button>
                        </Modal.Footer>
                    </Modal>
                    )}
                </Mutation>       
                {this.state.isOpenLightbox &&
                    <Lightbox
                        style={{zIndex: '9999'}}
                        mainSrc={this.state.viewImageSrc}
                        onCloseRequest={() => this.setState({ isOpenLightbox: false,  viewImageSrc: ''})}
                    />
                }    

                {this.state.isOpenLightboxarray &&
                    <Lightbox
                        style={{zIndex: '9999'}}
                        mainSrc={this.state.dataImage[this.state.photoIndex]}
                        nextSrc={this.state.dataImage[(this.state.photoIndex + 1) % this.state.dataImage.length]}
                        prevSrc={this.state.dataImage[(this.state.photoIndex + this.state.dataImage.length - 1) % this.state.dataImage.length]}
                        onCloseRequest={() => this.setState({ isOpenLightboxarray: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + this.state.dataImage.length - 1) % this.state.dataImage.length
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.dataImage.length
                        })
                        }
                    />
                }    

            </div>
        )
    }
}
                                                    
export default ProofofTransferModal;