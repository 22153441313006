import React, {Component} from "react";
import { CustomPhotoAdd } from "./styled";
import singleUploadMutation from "./mutation/singleUpload";
import { Mutation } from "react-apollo";

class AddPhotoBox extends Component {
  constructor(props) {
    super(props);
    this._inputAttachFileFirstCase = React.createRef();
  }

  checkFileTypeImage(file) {
    if (file) {
      return file.type === "image/png" || file.type === "image/jpeg";
    }
  }

  handleSingleUploadDocumentCase(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    const timestamp = new Date().getTime();
    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/codSlipKa/${documentName}-${timestamp}`,
        },
      });
    }
  }

  render() {
    return (
      <div>
        {
          //S T Y L E
        }
        <CustomPhotoAdd
            onClick={() => this._inputAttachFileFirstCase.current.click()}
          //   check={
          //     this.state.attachPhotos.length > 0 || checkSlip.length > 0
          //   }
          style={
            {
              // pointerEvents:
              //   this.props.shipment.agent_shipment_status === 7 ||
              //   this.props.shipment.agent_shipment_status === 6
              //     ? " none"
              //     : "unset",
            }
          }
        >
          <i
            style={{
              color: "#318DE8",
              // this.state.attachPhotos.length > 0 ||
              // checkSlip.length > 0
              //   ? "#318DE8"
              //   : "red",
              fontSize: "23px",
              fontWeight: 100,
            }}
            class="far fa-plus-circle"
          ></i>
          <span
            style={{
              color: "#318DE8",
              // this.state.attachPhotos.length > 0 ||
              // checkSlip.length > 0
              //   ? "#318DE8"
              //   : "red",
            }}
          >
            เพิ่มรูปภาพ
          </span>
        </CustomPhotoAdd>

        <Mutation
          mutation={singleUploadMutation}
          update={(cache, { data }) => {
            this._inputAttachFileFirstCase.current.value = "";
            this.props.addAttachPhoto(data.singleUpload.path)
            // this.setState((previousState) => ({
            //   attachPhotos: [
            //     ...previousState.attachPhotos,
            //     data.singleUpload.path,
            //   ],
            //   animationUploadFirst: false,
            // }));
          }}
          onError={(error) => {
            console.log(error);
          }}
        >
          {(uploadFile) => {
            return (
              <input
                accept="image/jpeg,image/png"
                onChange={(event) => {
                  const files = event.target.files[0];
                  const checkFileTypeImage = this.checkFileTypeImage(files);
                  if (checkFileTypeImage === false) {
                    alert(
                      "error",
                      "ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น"
                    );
                    return;
                  }
                  // files && this.setState({ animationUploadFirst: true });
                  this._inputAttachFileFirstCase.current.files[0] &&
                    this.handleSingleUploadDocumentCase(
                      uploadFile,
                      this._inputAttachFileFirstCase.current.files[0].name.split(
                        "."
                      )[0],
                      event
                    );
                }}
                type="file"
                id="file"
                style={{ display: "none" }}
                ref={this._inputAttachFileFirstCase}
                disabled={false}
              />
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default AddPhotoBox;
