import React from 'react';
import styled from 'styled-components';
import { Card } from '../components';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 1.5vw;
  color: ${props => props.textColor};
  background-color: ${props => props.bgColor};
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
`;

const TextTitle = styled.div`
  font-size: 1.5vw;
`;

const TextNumber = styled.div`
  font-size: 7vh;
  font-weight: bold;
  line-height: 0.7;
`;

const TextUnit = styled.div`
  display: inline-block;
  font-size: 4vh;
  font-weight: bold;
  margin-left: 0.5vw;
`;

const LeftContainer = styled.div`
  margin-right: 2vw;
`;

const Icon = styled.i`
  font-size: 2.5vw;
  line-height: 1.5;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const defaultProps = {
  title: 'Title',
  bgColor: '#ffffff',
  textColor: '#000000',
  number: 0,
};

const CardTripCounter = props => {
  const { bgColor, textColor, text, icon, title, unit, ...otherProps } = props;

  return (
    <StyledCard bgColor={bgColor} textColor={textColor} {...otherProps}>
      <LeftContainer>
        <Icon className={`fas ${icon}`} />
      </LeftContainer>
      <RightContainer>
        <TextTitle>{title}</TextTitle>
        <TextNumber>
          {text} {unit && <TextUnit>{unit}</TextUnit>}
        </TextNumber>
      </RightContainer>
    </StyledCard>
  );
};

CardTripCounter.defaultProps = defaultProps;

export default CardTripCounter;
