import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../../../graphql/query/shipmentList';

export default gql`
query LazadaShipmentListDetailSubTrackingOrder($_id: ID) {
    shipmentList(_id: $_id, shipment_prefix: "GLZ") {
      shipment {
        ...shipmentFields
      }

      total_page
    }
  }

  ${shipmentListFragment}
`;
