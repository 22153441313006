import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Radio,
  FormControl,
  Modal,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import Moment from 'react-moment';
import moment from 'moment/moment.js';
import dayjs from 'dayjs';
import Autosuggest from 'react-autosuggest';
import { client } from '../..';

import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import CardTable from 'components/CardTable/CardTable.jsx';
import CustomButton from 'components/CustomButton/CustomButton.jsx';

import accountInfoListQuery from './graphql/query/accountInfoList';
import createDriverMonthlyCalendarMutation from './graphql/mutation/createDriverMonthlyCalendar';
import updateDriverMonthlyCalendarMutation from './graphql/mutation/updateDriverMonthlyCalendar';
import driverMonthlyCalendarTablesQuery from './graphql/query/driverMonthlyCalendarTables';

let dataDriver = null;

const Container = styled(Row)`
  & .card_table {
    margin-bottom: 0 !important;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const CustomRadio = styled(Radio)`
  padding-left: 35px !important;
  margin-top: 12px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & input:checked ~ .checkmark {
    background-color: #ffffff;
  }

  &:hover .checkmark {
    background-color: #f1f1f1;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ .checkmark:after {
    display: block;
  }

  & .checkmark:after {
    top: 5px;
    left: 5px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #19aa19;
  }
`;

const ModalStyle = styled(Modal)`
  & .modal-dialog {
    max-width: 350px;
  }
`;

const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

const TabContentContainer = styled.div`
  margin-top: 15px;

  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 15px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

const AutosuggestContainerAgent = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

class AgentContractCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: dayjs().format('M'),
      year: dayjs().format('YYYY'),
      agentId: null,
      agentName: '',
      agentSuggestions: [],
      driver: null,
      driverCalendar: null,
      driverReady: false,
      isOpenEditDriver: false,
    };
  }

  //Autosuggest Transporter
  getSuggestionsTransporter = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: accountInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        agentSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTransporter = suggestion => suggestion.name;

  renderSuggestionTransporter = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTransporter = ({ value }) => {
    this.getSuggestionsTransporter(value);
  };

  onSuggestionsClearRequestedTransporter = () => {
    this.setState({
      agentSuggestions: [],
    });
  };

  shouldRenderSuggestionsTransporter() {
    return true;
  }
  //End Autosuggest Transporter

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  getMonthList() {
    return [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];
  }

  getYearList() {
    const minYear = '2021';
    const diffYear = dayjs().diff(minYear, 'year') + 1;
    return new Array(diffYear).fill({}).map((_, index) => (+minYear + index).toString());
  }

  getDayList() {
    const days = ['อา', 'จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
    const indexDay = Number(
      dayjs(`${this.state.year}-${this.state.month}-1`)
        .startOf('month')
        .format('d')
    );
    const min = 1;
    const max = Number(
      dayjs(`${this.state.year}-${this.state.month}-1`)
        .endOf('month')
        .format('D')
    );
    return new Array(max)
      .fill({})
      .map((_, index) => days[indexDay + index <= 6 ? indexDay + index : (indexDay + index) % 7]);
  }

  getDateList() {
    const min = 1;
    const max = Number(
      dayjs(`${this.state.year}-${this.state.month}-1`)
        .endOf('month')
        .format('D')
    );

    return new Array(max).fill({}).map((_, index) => (min + index).toString());
  }

  checkNoData(drivers) {
    const driverNodata = drivers.filter((driver, index) => driver.calendars.length == 0);
    return driverNodata;
  }

  createDriverMonthlyCalendarVariables(drivers) {
    let driverList = [];
    this.getDateList().map((date, index) => {
      drivers.map((data, index) => {
        const dateData = Number(date) < 10 ? `0${date}` : date;
        const dates = {
          month: `${this.state.year}-${this.state.month}`,
          date: `${this.state.year}-${this.state.month}-${dateData}`,
          ready: true,
          driver: data.driver._id,
          account_info: data.account_info._id,
        };

        driverList.push(dates);
      });
    });

    return driverList;
  }

  render() {
    return (
      <div className="content" style={{ paddingTop: 0 }}>
        <HeaderContainer>
          <div className="sub-header-left">
            <div className="menu-wrapper">
              <ul>
                <li>
                  <a href={`/contract-calendar`}>
                    {' '}
                    <i className="fas fa-calendar-alt"></i> ตารางจำนวนรถรายเดือน
                  </a>
                </li>
                <li className="active">
                  <a href={`#`}>
                    {' '}
                    <i className="fas fa-truck"></i> ตารางการวิ่งรถรายเดือน
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </HeaderContainer>
        <Grid fluid>
          <Container>
            <Col md={12}>
              <CardTable
                title="ตารางการวิ่งรถรายเดือน"
                ctTableFullWidth
                ctTableResponsive
                rightButton={
                  <div
                    style={{
                      float: 'right',
                      marginLeft: '15px',
                      flexDirection: 'row',
                      display: 'flex',
                      gap: '12px',
                    }}
                  >
                    <FormControl
                      style={{ width: '120px' }}
                      componentClass="select"
                      placeholder="select"
                      defaultValue={this.state.month}
                      onChange={event => {
                        console.log(event.target.value);
                        this.setState({
                          month: event.target.value,
                        });
                      }}
                      name="month"
                    >
                      {this.getMonthList().map((item, key) => (
                        <option value={key + 1} key={item}>
                          {item}
                        </option>
                      ))}
                    </FormControl>
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      defaultValue={this.state.year}
                      onChange={event => {
                        console.log(event.target.value);
                        this.setState({
                          year: event.target.value,
                        });
                      }}
                      name="search_year"
                    >
                      {this.getYearList().map(item => (
                        <option value={item} key={item}>
                          {item}
                        </option>
                      ))}
                    </FormControl>
                  </div>
                }
                content={
                  <div>
                    <Query
                      query={driverMonthlyCalendarTablesQuery}
                      variables={{
                        month: `${this.state.year}-${this.state.month}`,
                        account_info_id: this.state.agentId,
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        return (
                          <div>
                            <div>
                              <Table striped hover responsive>
                                <thead>
                                  <tr style={{ borderTop: '1px solid #ddd' }}>
                                    <th style={{ width: '150px', minWidth: '150px' }}>นายหน้า</th>
                                    <th style={{ width: '120px', minWidth: '120px' }}>
                                      ชื่อพนักงานขับรถ
                                    </th>
                                    <th style={{ minWidth: '100px' }}>ทะเบียน</th>
                                    <th style={{ width: '75px', minWidth: '75px' }}>
                                      จังหวัดที่วิ่ง
                                    </th>
                                    {this.getDayList().map((date, key) => (
                                      <th
                                        style={{
                                          borderLeft: '1px solid #ddd',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {date}
                                      </th>
                                    ))}
                                  </tr>
                                  <tr className="tableFilter">
                                    <th>
                                      <AutosuggestContainerAgent>
                                        <Autosuggest
                                          suggestions={this.state.agentSuggestions}
                                          onSuggestionsFetchRequested={
                                            this.onSuggestionsFetchRequestedTransporter
                                          }
                                          onSuggestionsClearRequested={
                                            this.onSuggestionsClearRequestedTransporter
                                          }
                                          getSuggestionValue={this.getSuggestionValueTransporter}
                                          renderSuggestion={this.renderSuggestionTransporter}
                                          focusInputOnSuggestionClick={false}
                                          inputProps={{
                                            placeholder: 'ค้นหานายหน้า',
                                            value: this.state.agentName,
                                            onChange: event =>
                                              this.setState({
                                                agentName: event.target.value,
                                                agentId: null,
                                              }),
                                            type: 'text',
                                            className: 'form-control',
                                          }}
                                          onSuggestionSelected={(event, { suggestion }) => {
                                            this.setState({
                                              agentId: suggestion.id,
                                              agentName: suggestion.name,
                                            });
                                          }}
                                          shouldRenderSuggestions={
                                            this.shouldRenderSuggestionsTransporter
                                          }
                                        />
                                      </AutosuggestContainerAgent>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    {this.getDateList().map((date, key) => (
                                      <th
                                        style={{
                                          borderLeft: '1px solid #ddd',
                                          textAlign: 'center',
                                        }}
                                      >
                                        {date}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading || error || !data ? (
                                    <tr>
                                      <td
                                        colSpan={`${Number(
                                          dayjs(`${this.state.year}-${this.state.month}-1`)
                                            .endOf('month')
                                            .format('D')
                                        ) + 4}`}
                                      >
                                        {loading ? 'Loading...' : error.message}
                                      </td>
                                    </tr>
                                  ) : data.driverMonthlyCalendarTables.drivers.length == 0 ? (
                                    <tr>
                                      <td
                                        colSpan={`${Number(
                                          dayjs(`${this.state.year}-${this.state.month}-1`)
                                            .endOf('month')
                                            .format('D')
                                        ) + 4}`}
                                      >
                                        ไม่มีข้อมุลคนขับรายเดือน
                                      </td>
                                    </tr>
                                  ) : this.checkNoData(data.driverMonthlyCalendarTables.drivers)
                                      .length > 0 ? (
                                    <tr>
                                      <Mutation
                                        mutation={createDriverMonthlyCalendarMutation}
                                        variables={{
                                          create: this.createDriverMonthlyCalendarVariables(
                                            this.checkNoData(
                                              data.driverMonthlyCalendarTables.drivers
                                            )
                                          ),
                                        }}
                                        update={() => {
                                          refetch();
                                        }}
                                        onError={error => {
                                          alert(error.graphQLErrors[0].message);
                                        }}
                                      >
                                        {createDriverMonthlyCalendar => (
                                          <td
                                            colSpan={`${Number(
                                              dayjs(`${this.state.year}-${this.state.month}-1`)
                                                .endOf('month')
                                                .format('D')
                                            ) + 4}`}
                                          >
                                            ไม่มีรายการ ต้องสร้างปฎิทินรับงานคนขับก่อน{' '}
                                            <Button
                                              bsStyle="primary"
                                              onClick={() => createDriverMonthlyCalendar()}
                                              type="button"
                                              bsSize="xs"
                                            >
                                              <i className="fa fa-plus" /> สร้างปฎิทินรับงานคนขับ
                                            </Button>
                                          </td>
                                        )}
                                      </Mutation>
                                    </tr>
                                  ) : (
                                    data.driverMonthlyCalendarTables.drivers.map((prop, key) => (
                                      <tr>
                                        <td>{prop.account_info.account_info_business_name}</td>
                                        <td>{prop.driver.driver_name}</td>
                                        <td>{prop.driver.user_setting_truck_license_plate}</td>
                                        <td>{prop.driver_route_service.length} เส้นทาง</td>
                                        {prop.calendars.length == 0
                                          ? this.getDateList().map((date, key) => (
                                              <td
                                                style={{
                                                  borderLeft: '1px solid #ddd',
                                                  textAlign: 'center',
                                                }}
                                              >
                                                -
                                              </td>
                                            ))
                                          : prop.calendars.map((calendar, key) => (
                                              <>
                                                {calendar.ready ? (
                                                  <td
                                                    style={{
                                                      backgroundColor: '#F0FFF3',
                                                      color: '#1BA919',
                                                      borderLeft: '1px solid #ddd',
                                                      textAlign: 'center',
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <i
                                                      className="fas fa-check-circle"
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() =>
                                                        this.setState({
                                                          driver: prop,
                                                          driverCalendar: calendar,
                                                          driverReady: calendar.ready,
                                                          isOpenEditDriver: true,
                                                        })
                                                      }
                                                    ></i>
                                                  </td>
                                                ) : (
                                                  <td
                                                    style={{
                                                      backgroundColor: '#FFEBEB',
                                                      color: '#D90101',
                                                      borderLeft: '1px solid #ddd',
                                                      textAlign: 'center',
                                                      cursor: 'pointer',
                                                    }}
                                                  >
                                                    <i
                                                      className="fas fa-times-circle"
                                                      style={{ cursor: 'pointer' }}
                                                      onClick={() =>
                                                        this.setState({
                                                          driver: prop,
                                                          driverCalendar: calendar,
                                                          driverReady: calendar.ready,
                                                          isOpenEditDriver: true,
                                                        })
                                                      }
                                                    ></i>
                                                  </td>
                                                )}
                                              </>
                                            ))}
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </Table>
                            </div>

                            {this.state.isOpenEditDriver && (
                              <Mutation
                                mutation={updateDriverMonthlyCalendarMutation}
                                variables={{
                                  params: { _id: this.state.driverCalendar._id },
                                  update: { ready: this.state.driverReady },
                                }}
                                update={() => {
                                  this.setState(
                                    {
                                      driver: null,
                                      driverCalendar: null,
                                      driverReady: false,
                                      isOpenEditDriver: false,
                                    },
                                    () => {
                                      refetch();
                                    }
                                  );
                                }}
                                onError={error => {
                                  alert(error.graphQLErrors[0].message);
                                }}
                              >
                                {updateDriverMonthlyCalendar => (
                                  <ModalStyle
                                    show={this.state.isOpenEditDriver}
                                    backdrop="static"
                                    onHide={() => this.setState({ isOpenEditDriver: false })}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>แก้ไขสถานะวันทำงาน</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      {console.log(this.state.driverCalendar)}
                                      <Row style={{ marginBottom: '10px' }}>
                                        <Col md={12}>
                                          <b>
                                            พนักงานขับรถ :{this.state.driver.driver.driver_name}
                                          </b>
                                        </Col>
                                        <Col md={12}>
                                          นายหน้า :
                                          {
                                            this.state.driver.account_info
                                              .account_info_business_name
                                          }
                                        </Col>
                                        <Col md={12}>
                                          ทะเบียนรถ :
                                          {
                                            this.state.driver.driver
                                              .user_setting_truck_license_plate
                                          }
                                        </Col>
                                      </Row>
                                      <Row style={{ marginBottom: '10px' }}>
                                        <Col md={12}>
                                          <b>
                                            วันที่ต้องการแก้ไขสถานะวันทำงาน :
                                            {dayjs(this.state.driverCalendar.date).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </b>
                                        </Col>
                                      </Row>

                                      <Row style={{ marginTop: '20px' }}>
                                        <Col md={12}> สถานะวันทำงาน</Col>
                                      </Row>

                                      <Row>
                                        <Col md={12}>
                                          <CustomRadio
                                            value={true}
                                            checked={this.state.driverReady}
                                            onChange={event => this.setState({ driverReady: true })}
                                            name="driverReady"
                                            inline
                                          >
                                            <span className="checkmark"></span>พร้อมวิ่งงาน
                                          </CustomRadio>
                                        </Col>
                                        <Col md={12}>
                                          <CustomRadio
                                            value={false}
                                            checked={!this.state.driverReady}
                                            onChange={event =>
                                              this.setState({ driverReady: false })
                                            }
                                            name="driverReady"
                                            inline
                                          >
                                            <span className="checkmark"></span>หยุดงาน
                                          </CustomRadio>
                                        </Col>
                                      </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        bsStyle="primary"
                                        onClick={() => updateDriverMonthlyCalendar()}
                                        disabled={
                                          this.state.driverCalendar.ready == this.state.driverReady
                                        }
                                      >
                                        บันทึก
                                      </Button>
                                    </Modal.Footer>
                                  </ModalStyle>
                                )}
                              </Mutation>
                            )}
                          </div>
                        );
                      }}
                    </Query>
                  </div>
                }
              />
            </Col>
          </Container>
        </Grid>
      </div>
    );
  }
}

export default AgentContractCalendar;
