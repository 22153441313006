import axios from 'axios';
import { Query } from 'react-apollo';
import DatePicker from 'react-datepicker';
import { CardTable } from 'components';
import wmsConfig from 'config/wmsUrl';
import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import { FormControl, Grid, Table } from 'react-bootstrap';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import SelectBranchField from 'shared/components/SelectBranchField';
import KAWarehouses from 'shared/graphql/query/KAWarehouses';
import { withRouter } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
class TruckRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      requestShipment: {
        branch: '',
        shipmentDate: new Date(),
      },
      truckRequestList: [],
    };
  }

  componentDidMount() {
    this.getTruckRequest(
      this.state.requestShipment.branch,
      this.state.requestShipment.shipmentDate
    );
  }

  getTruckRequest = async (branch, shipmentDate) => {
    this.setState({
      isLoading: true,
    });
    try {
      const url = `${
        wmsConfig.wmsUrl
      }/request_shipment/requestShipments?branch=${branch}&shipmentDate=${moment(
        shipmentDate
      ).format('YYYY-MM-DD')}`;
      await axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${wmsConfig.token}`,
          },
        })
        .then(res => {
          if (res.status === 200) {
            let _newData = [];

            res.data.map((item, idx) => {
              item.requestLog.forEach(element => {
                _newData.push({
                  ...item,
                  full_name: element.requestBy.full_name,
                  createdAt: element.createdAt,
                  updatedAt: element.updatedAt,
                  itemRequest: element.totalRequest,
                });
              });
            });

            this.setState({
              truckRequestList: _newData,
            });
          }
        });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <div>
            <CardTable
              title="รายการขอรถจากคลัง"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <div className="table-vertical">
                    <Table striped hover style={{ marginBottom: '60px' }}>
                      <thead>
                        <tr>
                          <th style={{ minWidth: '240px' }}>Branch </th>
                          <th style={{ minWidth: '140px' }}>วันที่ขอรถ</th>
                          <th style={{ minWidth: '240px' }}>
                            จำนวนรถที่เรียกแล้ว / จำนวนรถที่ขอทั้งหมด
                          </th>
                          <th style={{ minWidth: '200px' }}>จำนวนรถที่กำลังไปที่คลัง</th>
                          <th style={{ minWidth: '200px' }}>จำนวนรถที่กำลังขึ้นสินค้า</th>
                          <th style={{ minWidth: '200px' }}>จำนวนรถที่กำลังออกจัดส่ง</th>
                          <th style={{ minWidth: '200px' }}>จำนวนรถที่กำลังกลับคลัง</th>
                          <th style={{ minWidth: '200px' }}>จำนวนรถที่ส่งสำเร็จ</th>
                          <th style={{ minWidth: '140px' }}>ขอโดย</th>
                          <th style={{ minWidth: '140px' }}>จำนวนรถที่ขอ</th>
                        </tr>
                        <tr>
                          <th>
                            <SelectBranchField
                              onChange={(_event, extraData) => {
                                this.setState({
                                  requestShipment: {
                                    branch: extraData ? extraData.wms_branch : '',
                                    shipmentDate: this.state.requestShipment.shipmentDate,
                                  },
                                });

                                this.getTruckRequest(
                                  extraData ? extraData.wms_branch : '',
                                  this.state.requestShipment.shipmentDate
                                );
                              }}
                            />
                          </th>
                          <th>
                            <DatePicker
                              selected={this.state.requestShipment.shipmentDate}
                              onChange={date => {
                                this.setState({
                                  requestShipment: {
                                    branch: this.state.requestShipment.branch,
                                    shipmentDate: date,
                                  },
                                });

                                this.getTruckRequest(this.state.requestShipment.branch, date);
                              }}
                              dateFormat="dd/MM/yyyy"
                            />
                          </th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.isLoading && (
                          <tr>
                            <td colSpan="9">Loading...</td>
                          </tr>
                        )}

                        {!this.state.isLoading &&
                          this.state.truckRequestList
                            .sort(function(a, b) {
                              return moment(b.createdAt) - moment(a.createdAt);
                            })
                            .map(item => {
                              return (
                                <tr>
                                  <td>
                                    <Query query={KAWarehouses} fetchPolicy="cache-and-network">
                                      {({ data, loading, error }) => {
                                        const findBranch = data.kaWarehouses.find(
                                          ktem => ktem.wms_branch === item.branch
                                        );

                                        if (loading) return <p>กำลังโหลด...</p>;
                                        if (error) return <p>{error.message}</p>;
                                        if (!loading && !error && data?.kaWarehouses.length === 0)
                                          return <p>ไม่มีคลังสินค้า</p>;
                                        if (!loading && !error) return <p>{findBranch.tag_name}</p>;
                                      }}
                                    </Query>
                                  </td>
                                  <td>{moment(item.shipmentDate).format('DD/MM/YYYY')}</td>
                                  <td>
                                    {item.totalBooking} / {item.totalRequest}
                                  </td>
                                  <td>{item.totalOTW}</td>
                                  <td>{item.totalLoading}</td>
                                  <td>{item.totalDeparture}</td>
                                  <td>{item.returnWH}</td>
                                  <td>{item.totalCompleted}</td>
                                  <td>{item.full_name}</td>
                                  <td>{item.itemRequest}</td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              }
            />
          </div>
        </Grid>
      </div>
    );
  }
}

export default TruckRequest;
