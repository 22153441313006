import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const ContainerInner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const ContainerInputBox = styled.div`
  display: flex;
  flex-direction: row;
`;
const InputBox = styled.input`
  font-size:16px;
  ${props => props.prefix ? `
  border-radius: 0 6px 6px 0;
  ` : `border-radius: 6px;`
  }
  
  border: 1px solid #E3E3E3;
  padding: 0 12px;
  line-height: 1;
  height: 35px;
  ${props => props.disabled || props.readOnly ? ` background-color: #f2f2f2;` : `background-color: #FFFFFF;` }  
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    ${props => props.disabled && `
      background-color: #f2f2f2;
    `}
  }
  
  &:readonly {
    background-color: #f2f2f2;
  }

  ${props => props.isBorderValidated && `
    border: 1px solid #FF0000;
  `}

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B3B3B3;
    opacity: 1; /* Firefox */
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #B3B3B3;
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #B3B3B3;
  }
`;

const InputBoxPrefix = styled.div`
    font-size: 16px;
    border: 1px solid #E3E3E3;
    padding: 10px 12px;
    line-height: 1;
    height: 35px;
    background-color: #f2f2f2;
    width: 38px;
    border-radius: 6px 0 0 6px;
    border-right: none;

    &::focus {
      outline: none;
    }
  
    &::disabled {
      ${props => props.disabled  && `
        background-color: #F9F9F9;
      `}
    }
  
    ${props => props.isBorderValidated && `
      border: 1px solid #FF0000;
    `}
  
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #B3B3B3;
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #B3B3B3;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #B3B3B3;
    }
`;

const MessageError = styled.div`
  font-size:16px;
  color: #FF0000;
  height: 22px;
  text-align: right;
`;

const Label = styled.div`
  font-size:18px;
  font-weight: bold;
  min-width: 120px;

  ${props => props.messageError && `
    color: #FF0000;
  `}
`;

const Required = styled.span`
  color: #D90101;
`;

class Input extends React.Component {
  render() {
    return (
      <Container style={this.props.style}>
        { this.props.inputLabel ? (<Label messageError={this.props.messageError}>{this.props.inputLabel}{ this.props.required && (<Required>*</Required>) }</Label>) : null }
        <ContainerInner>
          <ContainerInputBox>
            {
              this.props.prefix && (<InputBoxPrefix >{this.props.prefix}</InputBoxPrefix>)
            }
            <InputBox name={this.props.name} prefix={this.props.prefix} ref={this.props.inputRef} readOnly={this.props.readOnly} onBlur={this.props.onBlur} onKeyPress={this.props.onKeyPress} name={this.props.name} style={this.props.styleInput} onChange={this.props.onChange} value={this.props.value} isBorderValidated={this.props.messageError} placeholder={this.props.placeholder} type={this.props.type} disabled={this.props.disabled} />
          </ContainerInputBox>
          <MessageError>{this.props.messageError}</MessageError>
        </ContainerInner>
      </Container>
    )
  }
}

export default Input;