import { CardTable } from 'components';
import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  FormControl,
  Modal,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
// import { CostPerParcelEachBox } from './render/CostPerPacelBoxHeader';
import { Query } from 'react-apollo';
import parcelCostList from './graphql/parcelCostList';
import CostPerParcelModalData from './render/CostPerPacelModalData';
import parcelCostStatus from './graphql/parcelCostStatus';
import { Tabs } from 'views/CODParcel/components';
import formatCurrency from 'utils/formatCurrency.jsx';
import Moment from 'react-moment';
import moment from 'moment';

const Container = styled(Row)`
  & .card_table {
    margin-bottom: 0 !important;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const CostParcelBilling = () => {
  const [selected, setSelected] = React.useState(1);
  const [refNumber, setRefNumber] = React.useState(null);
  const [costingNumber, setCostingNumber] = React.useState(null);
  const [createDate, setCreateDate] = React.useState(
    `${moment()
      .startOf('month')
      .format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`
  );
  const [showCreateDate, setShowCreateDate] = React.useState(
    `${moment()
      .startOf('month')
      .format('DD/MM/YYYYD')} - ${moment().format('DD/MM/YYYY')}`
  );
  const [vender, setVender] = React.useState(null);
  const [orderType, setOrderType] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState('createdAt');
  const [pageNumber, setPageNumber] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);

  const sort = (order_by, refetch) => {
    if (order_by == orderBy) {
      setOrderType(orderType * -1);
    } else {
      setOrderType(1);
      setOrderBy(order_by);
    }
    refetch();
  };

  const renderSorting = order_by => {
    if (order_by == orderBy) {
      if (orderType == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  };

  const nextPage = (page, total_page, refetch) => {
    setPageNumber(page + 1);
    setTotalPage(total_page);
    refetch();
  };

  const handlePickupDateRangePicker = (event, picker, refetch) => {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    setCreateDate(date);
    setShowCreateDate(show);

    refetch();
  };

  return (
    <>
      <div className="content">
        <Grid fluid>
          <Container>
            <Col md={12}>
              <CardTable
                // title="ต้นทุน Parcel"
                category=""
                ctTableFullWidth
                ctTableResponsive
                // rightButton={
                //   <div style={{ float: 'right', marginLeft: '15px' }}>
                //     <Button
                //       bsStyle="primary"
                //       onClick={() => {
                //         setOpenFile(true);
                //       }}
                //     >
                //       <i className="fal fa-file"></i> อัพโหลดไฟล์
                //     </Button>
                //   </div>
                // }
                content={
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                      }}
                    ></div>
                    <Query query={parcelCostStatus} fetchPolicy="cache-and-network">
                      {({ data }) => {
                        return (
                          <Tabs
                            menu={[
                              {
                                icon: 'far fa-file-invoice',
                                title: 'รอ Agent ยืนยัน',
                                active: selected === 1,
                                onPress: () => {
                                  setSelected(1);
                                },
                                number: data
                                  ? data.parcelCostStatus
                                    ? data.parcelCostStatus.waitAgentConfirm
                                    : 0
                                  : 0,
                              },
                              {
                                icon: 'fal fa-file-signature',
                                title: 'รอบัญชี ยืนยัน',
                                active: selected === 2,
                                onPress: () => {
                                  setSelected(2);
                                },
                                number: data
                                  ? data.parcelCostStatus
                                    ? data.parcelCostStatus.waitingConfirm
                                    : 0
                                  : 0,
                              },
                              {
                                icon: 'far fa-check-circle',
                                title: 'ตรวจสอบแล้ว',
                                active: selected === 3,
                                onPress: () => {
                                  setSelected(3);
                                },
                                number: data
                                  ? data.parcelCostStatus
                                    ? data.parcelCostStatus.confirm
                                    : 0
                                  : 0,
                              },
                              {
                                icon: 'far fa-times-circle',
                                title: 'ปฏิเสธ',
                                active: selected === 4,
                                onPress: () => {
                                  setSelected(4);
                                },
                                number: data
                                  ? data.parcelCostStatus
                                    ? data.parcelCostStatus.reject
                                    : 0
                                  : 0,
                              },
                              {
                                icon: 'far fa-file-edit',
                                title: 'แก้ไข',
                                active: selected === 5,
                                onPress: () => {
                                  setSelected(5);
                                },
                                number: data
                                  ? data.parcelCostStatus
                                    ? data.parcelCostStatus.edit
                                    : 0
                                  : 0,
                              },
                            ]}
                            bold
                          />
                        );
                      }}
                    </Query>
                    <Query
                      query={parcelCostList}
                      variables={{
                        params: {
                          createdAt: createDate,
                          costing_number: costingNumber,
                          refNumber: refNumber,
                          costing_status: selected,
                        },
                        sort: {
                          order_by: orderBy,
                          order_type: orderType,
                        },
                        page: pageNumber,
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        if (loading) return <div>Loading...</div>;
                        if (error) return <div>Error :(</div>;
                        console.log('parcelCostList', data);
                        return (
                          <div>
                            <Table striped hover responsive style={{ position: 'relative' }}>
                              <thead>
                                <tr>
                                  <th style={{ minWidth: '80px', fontSize: '18px', color: '#000' }}>
                                    เครื่องมือ
                                  </th>
                                  <th
                                    style={{
                                      minWidth: '100px',
                                      fontSize: '18px',
                                      color: '#000',
                                      minWidth: '100px',
                                    }}
                                    className={renderSorting('createdAt')}
                                    onClick={() => sort('createdAt', refetch)}
                                  >
                                    วันที่สร้าง
                                  </th>
                                  <th
                                    style={{ fontSize: '18px', color: '#000', minWidth: '110px' }}
                                    className={renderSorting('costing_number')}
                                    onClick={() => sort('costing_number', refetch)}
                                  >
                                    รหัสต้นทุน/ขาย
                                  </th>
                                  <th
                                    style={{ fontSize: '18px', color: '#000', minWidth: '150px' }}
                                  >
                                    รหัสงานขนส่ง
                                  </th>
                                  <th
                                    style={{ fontSize: '18px', color: '#000', minWidth: '110px' }}
                                  >
                                    ต้นทุนก่อนภาษี
                                  </th>

                                  <th
                                    style={{ fontSize: '18px', color: '#000', minWidth: '200px' }}
                                  >
                                    ผู้รับเงิน
                                  </th>
                                  <th
                                    style={{ fontSize: '18px', color: '#000', minWidth: '200px' }}
                                  >
                                    ผู้ส่งราคา
                                  </th>
                                  <th
                                    style={{ fontSize: '18px', color: '#000', minWidth: '100px' }}
                                  >
                                    อัพเดทล่าสุด
                                  </th>
                                </tr>
                                <tr>
                                  <th></th>
                                  <th>
                                    {/* วันที่ส่งสำเร็จ */}
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        handlePickupDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() => {
                                        setCreateDate('');
                                        setShowCreateDate('');
                                        refetch();
                                      }}
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={showCreateDate}
                                        placeholder="ค้นหาวันที่"
                                      />
                                    </DateRangePicker>
                                  </th>

                                  <th>
                                    {/* รหัสต้นทุน/ขาย */}
                                    <FormControl
                                      type="text"
                                      placeholder="ค้นหา"
                                      value={costingNumber}
                                      onChange={event => {
                                        setCostingNumber(event.target.value);
                                      }}
                                    />
                                  </th>
                                  <th>
                                    {/* รหัสงานขนส่ง 
                                    <FormControl
                                      type="text"
                                      placeholder="ค้นหา"
                                      value={refNumber}
                                      onChange={event => {
                                        setRefNumber(event.target.value);
                                      }}
                                    />*/}
                                  </th>
                                  <th> </th>
                                  <th> </th>
                                  <th> </th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.parcelCostList?.parcelsCost?.map(row => {
                                  return (
                                    <>
                                      <tr>
                                        <td
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                        >
                                          <CostPerParcelModalData data={row} />
                                        </td>
                                        <td>
                                          {row.createdAt ? (
                                            <Moment format="DD/MM/YYYY HH:mm">
                                              {row?.createdAt}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td> {row?.costing_number} </td>
                                        <td> {row?.refNumber} </td>
                                        <td> {formatCurrency(row?.total_price)}</td>

                                        <td>{row?.vendor?.full_name}</td>
                                        <td>{row?.employee?.full_name}</td>

                                        <td>
                                          {row.updatedAt ? (
                                            <Moment format="DD/MM/YYYY HH:mm">
                                              {row?.updatedAt}
                                            </Moment>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </Table>
                            <div
                              style={{
                                textAlign: 'right',
                                marginTop: 10,
                                marginBottom: 20,
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              {data?.parcelCostList?.totalPage > 0 && (
                                <ReactPaginate
                                  pageCount={data?.parcelCostList?.totalPage}
                                  forcePage={pageNumber}
                                  containerClassName="pagination"
                                  nextLabel=">"
                                  previousLabel="<"
                                  onPageChange={({ selected }) => {
                                    nextPage(selected, data?.parcelCostList?.totalPage, refetch);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      }}
                    </Query>
                  </div>
                }
              />
            </Col>
          </Container>
        </Grid>
        <div></div>
      </div>
    </>
  );
};

export default CostParcelBilling;
