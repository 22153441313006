import gql from 'graphql-tag';

export default gql`
mutation   Update(
    $name_lang: TypeFlashDealsArgsName
    $discount: Int
    $limit_count: Int!
    $setting_truck_id: ID!
    $setting_truck_type_id: ID!
    $limit_per_user: Int
    $location_start_lang: TypeFlashDealsArgsLocationStart
    $location_end_lang: TypeFlashDealsArgsLocationEnd
    $position_start: TypeFlashDealsArgsPositionStart
    $position_end: TypeFlashDealsArgsPositionEnd
    $start: String
    $exp: String
    $pickup_start: String
    $pickup_end: String
    $price: Int!
    $price_cost: Int!
    $price_sale: Int!
){
  flashDealsAdd(
      name_lang: $name_lang,
      discount: $discount,
      limit_count: $limit_count,
      setting_truck_id : $setting_truck_id,
      setting_truck_type_id: $setting_truck_type_id,
      limit_per_user: $limit_per_user,
      location_start_lang: $location_start_lang,
      location_end_lang: $location_end_lang,
      position_start: $position_start,
      position_end: $position_end,
      start: $start,
      exp: $exp,
      pickup_start: $pickup_start,
      pickup_end: $pickup_end,
      price: $price,
      price_cost: $price_cost,
      price_sale: $price_sale
    ){
      _id
    }
  }
`;