import React, { useRef } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import UploadFile from './UploadFile';
import { MessageError } from '../style';
import { useFormContext, Controller } from 'react-hook-form';

const ImportFile = () => {
  const _inputImportFile = useRef(null);
  const { control, errors, watch, setValue, trigger } = useFormContext();
  const watchFileName = watch('file.fileName');

  return (
    <Row>
      <Col xs={12} style={{ marginBottom: 20 }}>
        <strong>Import file :</strong>
        <div>
          <Controller
            name="file.path"
            control={control}
            render={({ onChange }) => (
              <UploadFile
                completed={({ fileName, path }) => {
                  _inputImportFile.current.value = null;
                  onChange(path);
                  setValue('file.fileName', fileName);
                  trigger(['file.fileName', 'file.path']);
                }}
              >
                {selectFileImport => {
                  return (
                    <React.Fragment>
                      <input
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={event => {
                          selectFileImport(
                            event,
                            `upload/lazadaProject/document_${new Date().getTime()}`
                          );
                        }}
                        type="file"
                        id="file"
                        ref={_inputImportFile}
                        style={{ display: 'none' }}
                      />
                      <Button onClick={() => _inputImportFile.current.click()}>
                        <i className="fas fa-cloud-upload" /> Import Booking
                      </Button>{' '}
                      {watchFileName || ''}
                    </React.Fragment>
                  );
                }}
              </UploadFile>
            )}
          />
          <MessageError>{errors?.file?.path?.message}</MessageError>
        </div>
      </Col>
      <Col xs={12}>
        <a
          target="_blank"
          href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_import_RT_v1.xlsx"
          style={{
            color: '#0F8EE9',
          }}
        >
          ดาวน์โหลดไฟล์ตัวอย่าง
        </a>
      </Col>
    </Row>
  );
};

export default ImportFile;
