import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { Query, compose, graphql, Mutation } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import { Layout } from '../../components';
import { withRouter } from 'react-router-dom';
import { Input } from './../../components';
import shipmentListQuery from './graphql/query/shipmentList';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { client } from '../../../../index';
import driverUserListQuery from './graphql/query/driverUserList';
import userTruckListQuery from './graphql/query/userTruckList';
import accountInfoListQuery from './graphql/query/accountInfoList';
import Select from 'react-select';
import assignAgentMutation from './graphql/mutation/assignAgent';
import assignDriverMutation from './graphql/mutation/assignDriver';

const AutosuggestContainer = styled.div`
  min-height: 70px;

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Required = styled.span`
  color: #d90101;
`;

class AddDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //ค้นหา shipment list
      shipmentListSuggestions: [],
      shipmentName: '',
      shipmentId: '',
      //ค้นหา driver list
      driverListSuggestions: [],
      driverName: '',
      driverId: '',
      //ค้นหาทะเบียน
      userSettingTruckLicensePlateListSuggestions: [],
      userSettingTruckLicensePlate: '',
      userSettingTruckLicensePlateId: '',
      //agent
      agentName: null,
      agentId: '',
    };
  }

  getShipmentListSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: shipmentListQuery,
        fetchPolicy: 'network-only',
        variables: {
          shipment_name: inputValue,
        },
      });

      const shipmentListData = data.shipmentList.shipment.map(e => {
        return {
          id: e._id,
          name: e.shipment_name,
        };
      });

      this.setState({
        shipmentListSuggestions: shipmentListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      shipmentListSuggestions: [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  getDriverListSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
        },
      });

      const driverUserListData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: e.driver_name,
          licensePlate: e.user_setting_truck_license_plate,
          licensePlateId: e.user_setting_truck_id,
          account_info_id: e.account_info_id,
        };
      });

      this.setState({
        driverListSuggestions: driverUserListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getLicensePlateListSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: userTruckListQuery,
        fetchPolicy: 'network-only',
        variables: {
          user_setting_truck_license_plate: inputValue,
        },
      });

      const userSettingTruckListData = data.userSettingTruckList.user_setting_truck.map(e => {
        return {
          id: e._id,
          name: e.user_setting_truck_license_plate,
        };
      });

      this.setState({
        userSettingTruckLicensePlateListSuggestions: userSettingTruckListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChangeAgent(agent) {
    this.setState({ agentName: agent, agentId: agent.value });
  }

  selectOptionAgentList = agentList => {
    return agentList.map(agent => {
      return {
        value: agent._id,
        label: agent.account_info_business_name,
      };
    });
  };

  getDataDriver = async () => {
    try {
      this.setState({
        driverId: '',
        driverName: '',
        agentId: '',
        agentName: null,
        userSettingTruckLicensePlate: '',
        userSettingTruckLicensePlateId: '',
      });

      const { data: shipmentListData } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: this.state.shipmentId,
        },
      });

      const { shipment } = shipmentListData.shipmentList;

      if (shipment.length === 0) {
        return;
      }

      if (shipment[0].driver_user_id) {
        await this.setDriverNameData(shipment[0].driver_user_id);
      }

      if (shipment[0].user_setting_truck_id) {
        await this.setUserSettingTruckLicensePlateData(shipment[0].user_setting_truck_id);
      }

      if (shipment[0].transporter) {
        await this.setAgentData(shipment[0].transporter);
      }
    } catch (error) {
      alert(error);
    }
  };

  setUserSettingTruckLicensePlateData = async userSettingTruckId => {
    const { data: userTruckListData } = await client.query({
      query: userTruckListQuery,
      variables: {
        _id: userSettingTruckId,
      },
    });

    const {
      _id,
      user_setting_truck_license_plate: userSettingTruckLicensePlate,
    } = userTruckListData.userSettingTruckList.user_setting_truck[0];

    this.setState({
      userSettingTruckLicensePlateId: _id,
      userSettingTruckLicensePlate: userSettingTruckLicensePlate,
    });
  };

  setDriverNameData = async driverUserId => {
    const { data: driverUserListData } = await client.query({
      query: driverUserListQuery,
      variables: {
        _id: driverUserId,
      },
    });

    const {
      _id: driverId,
      driver_name: driverName,
    } = driverUserListData.driverUserList.driver_user[0];

    this.setState({
      driverId: driverId,
      driverName: driverName,
    });
  };

  setAgentData = async transporterId => {
    try {
      const { data: accountInfoListData } = await client.query({
        query: accountInfoListQuery,
        variables: {
          _id: transporterId,
        },
      });

      if (accountInfoListData.accountInfoList.account_info.length > 0) {
        const {
          _id,
          account_info_business_name,
        } = accountInfoListData.accountInfoList.account_info[0];

        this.setState({
          agentId: _id,
          agentName: { value: _id, label: account_info_business_name },
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  render() {
    let agentList = [];
    if (
      this.props.accountInfoListQuery.loading === false &&
      this.props.accountInfoListQuery.accountInfoList !== undefined
    ) {
      agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
    }

    return (
      <Layout>
        <Row>
          <Col md={12}>
            <div>
              <CardTable
                title="แจงงานให้คนขับรถ"
                category="กรอกข้อมูลคนขับรถเพื่อ Assign งานขนส่งให้"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <Col lg={6} md={12}>
                      <Row style={{ marginTop: 20 }}>
                        <Col md={8}>
                          <Label>
                            เลขที่งานขนส่ง<Required>*</Required>
                          </Label>
                          <AutosuggestContainer>
                            <Autosuggest
                              suggestions={this.state.shipmentListSuggestions}
                              onSuggestionsFetchRequested={({ value }) => {
                                this.getShipmentListSuggestions(value);
                              }}
                              onSuggestionsClearRequested={() => {
                                this.setState({
                                  shipmentListSuggestions: [],
                                });
                              }}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              focusInputOnSuggestionClick={false}
                              inputProps={{
                                placeholder: 'เลขที่งานขนส่ง',
                                value: this.state.shipmentName,
                                onChange: event =>
                                  this.setState({
                                    shipmentName: event.target.value,
                                    shipmentId: '',
                                  }),
                                type: 'text',
                                className: 'form-control',
                              }}
                              onSuggestionSelected={(event, { suggestion }) => {
                                this.setState(
                                  {
                                    shipmentId: suggestion.id,
                                    shipmentName: suggestion.name,
                                  },
                                  () => this.getDataDriver()
                                );
                              }}
                              shouldRenderSuggestions={this.shouldRenderSuggestions}
                            />
                          </AutosuggestContainer>
                        </Col>
                        <Col md={8}>
                          <Label>
                            ชื่อ-สกุล พนักงานขับรถ<Required>*</Required>
                          </Label>
                          <AutosuggestContainer>
                            <Autosuggest
                              suggestions={this.state.driverListSuggestions}
                              onSuggestionsFetchRequested={({ value }) => {
                                this.getDriverListSuggestions(value);
                              }}
                              onSuggestionsClearRequested={() => {
                                this.setState({
                                  driverListSuggestions: [],
                                });
                              }}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              focusInputOnSuggestionClick={false}
                              inputProps={{
                                placeholder: 'ชื่อ-สกุล พนักงานขับรถ',
                                value: this.state.driverName,
                                onChange: event =>
                                  this.setState({
                                    driverName: event.target.value,
                                    driverId: '',
                                  }),
                                type: 'text',
                                className: 'form-control',
                              }}
                              onSuggestionSelected={(event, { suggestion }) => {
                                this.setState(
                                  {
                                    driverId: suggestion.id,
                                    driverName: suggestion.name,
                                    userSettingTruckLicensePlate: suggestion.licensePlate,
                                    userSettingTruckLicensePlateId: suggestion.licensePlateId,
                                  },
                                  () => this.setAgentData(suggestion.account_info_id)
                                );
                              }}
                              shouldRenderSuggestions={this.shouldRenderSuggestions}
                            />
                          </AutosuggestContainer>
                        </Col>
                        <Col md={8}>
                          <Label>
                            ทะเบียนรถ<Required>*</Required>
                          </Label>
                          <AutosuggestContainer>
                            <Autosuggest
                              suggestions={this.state.userSettingTruckLicensePlateListSuggestions}
                              onSuggestionsFetchRequested={({ value }) => {
                                this.getLicensePlateListSuggestions(value);
                              }}
                              onSuggestionsClearRequested={() => {
                                this.setState({
                                  userSettingTruckLicensePlateListSuggestions: [],
                                });
                              }}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              focusInputOnSuggestionClick={false}
                              inputProps={{
                                placeholder: 'ทะเบียนรถ',
                                value: this.state.userSettingTruckLicensePlate,
                                onChange: event =>
                                  this.setState({
                                    userSettingTruckLicensePlate: event.target.value,
                                    userSettingTruckLicensePlateId: '',
                                  }),
                                type: 'text',
                                className: 'form-control',
                              }}
                              onSuggestionSelected={(event, { suggestion }) => {
                                this.setState({
                                  userSettingTruckLicensePlateId: suggestion.id,
                                  userSettingTruckLicensePlate: suggestion.name,
                                });
                              }}
                              shouldRenderSuggestions={this.shouldRenderSuggestions}
                            />
                          </AutosuggestContainer>
                        </Col>
                        <Col md={8}>
                          <Label>
                            นายหน้า<Required>*</Required>
                          </Label>

                          <Select
                            value={this.state.agentName}
                            onChange={event => this.handleChangeAgent(event)}
                            options={this.selectOptionAgentList(agentList)}
                            placeholder="เลือกนายหน้า"
                          />
                        </Col>
                        <Col md={8} style={{ textAlign: 'right', marginBottom: 20, marginTop: 50 }}>
                          <Mutation
                            mutation={assignAgentMutation}
                            variables={{
                              shipment_id: this.state.shipmentId,
                              transporter: this.state.agentId,
                            }}
                            update={(cache, { data }) => {
                              if (data.assignAgent.succeed) {
                                alert('แจงงานให้คนขับรถ สำเร็จ');
                              }
                            }}
                            onError={error => {
                              alert(error);
                            }}
                          >
                            {(assignAgent, { loading: loadingAssignAgent }) => {
                              return (
                                <Mutation
                                  mutation={assignDriverMutation}
                                  variables={{
                                    shipmentID: this.state.shipmentId,
                                    driverID: this.state.driverId,
                                    truckID: this.state.userSettingTruckLicensePlateId,
                                  }}
                                  update={(cache, { data }) => {
                                    if (data.assignDriver.succeed) {
                                      assignAgent();
                                    }
                                  }}
                                  onError={error => {
                                    alert(error);
                                  }}
                                >
                                  {(assignDriver, { loading: loadingAssignDriver }) => {
                                    return (
                                      <Button
                                        bsStyle="primary"
                                        disabled={
                                          !this.state.shipmentId ||
                                          !this.state.agentId ||
                                          !this.state.driverId ||
                                          !this.state.userSettingTruckLicensePlateId ||
                                          loadingAssignDriver ||
                                          loadingAssignAgent
                                        }
                                        onClick={() => assignDriver()}
                                      >
                                        เพิ่มพนักงานขับรถ
                                      </Button>
                                    );
                                  }}
                                </Mutation>
                              );
                            }}
                          </Mutation>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                }
              />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default compose(graphql(accountInfoListQuery, { name: 'accountInfoListQuery' }))(AddDriver);
