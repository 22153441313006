import gql from "graphql-tag";

export default gql`
    query lazadaImportOrderCheckBookname(
        $order_type: Int!
        $plan_date: String!
        $trip_name: String!
        $ka_id: ID!
    ){
        lazadaImportOrderCheckBookname(
            order_type: $order_type
            plan_date: $plan_date
            trip_name: $trip_name
            ka_id: $ka_id
        ){
            succeed
        }
    }
`;
