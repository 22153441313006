import gql from 'graphql-tag';

export default gql`
  mutation updateContactShipmentKA(
    $params: paramsUpdateContactShipmentKA
    $update: objectUpdateContactShipmentKA
  ) {
    updateContactShipmentKA(params: $params, update: $update) {
      succeed
    }
  }
`;
