import React from 'react';
import styled from 'styled-components';
import { Row, Col, FormGroup, FormControl, Button, ControlLabel } from 'react-bootstrap';
import { Mutation, Query, graphql, compose } from 'react-apollo';
import Select from 'react-select';
import editShipmentMutation from './graphql/mutation/editShipment';
import trackingAddTransporterMutation from './graphql/mutation/trackingUpdate';
import accountInfoList from './graphql/query/accountInfoList';
import assignAgentMutation from './graphql/mutation/assignAgent';

const TextControlLabel = styled(ControlLabel)`
  width: 100%;
  font-size: 18px !important;
  color: #000000 !important;

  & small {
    color: #888888;
    float: right;
    cursor: pointer;
  }
`;

let agentList = [];
let agentItem = [];

class TransporterTrackingEdit extends React.Component {
  state = {
    trackingId: this.props.trackingId,
    shipmentId: this.props.shipmentId,
    agentSelected: {
      value: this.props.transporter,
      label: this.props.driverName,
      phone_code: 66,
      phone_number: this.props.driverPhoneNumber,
    },
    agentId: this.props.transporter,
    driver_name: this.props.driverName,
    driver_phone_code: 66,
    driver_phone_number: this.props.driverPhoneNumber,
  };

  onSubmitAddTransporter(e, trackingId, trackingAddTransporter) {
    const chkError = this.checkValidateAddTransporter();
    if (e.type == 'click' || (e.type == 'keypress' && e.charCode == 13 && chkError)) {
      const { driver_name, driver_phone_number, driver_phone_code, agentId } = this.state;

      trackingAddTransporter({
        variables: {
          _id: trackingId,
          driver_accept: 1,
          transporter_detail: driver_name,
          transporter_phone_code: driver_phone_code,
          transporter_phone: driver_phone_number.substring(1),
          transporter: agentId,
        },
      });
    }
  }

  checkValidateAddTransporter() {
    const { driver_name, driver_phone_number } = this.state;
    const validatePhoneNumber = /^[0]\d{8,9}$/;
    if (driver_name && validatePhoneNumber.test(driver_phone_number)) {
      return true;
    }

    return false;
  }

  renderAgent(data) {
    // console.log("renderAgent",data.accountInfoList.account_info);
    agentList = data.accountInfoList.account_info.map(agent => {
      // console.log("renderAgent", agent);
      return {
        value: agent._id,
        label: agent.account_info_business_name,
        phone_code: agent.account_info_phone_code,
        phone_number: agent.account_info_phone_number,
      };
    });
  }

  handleChangeAgent(agent) {
    // console.log(`handleChangeAgent:`, agent);
    // console.log(`truckSelected:`, truck.value);
    this.setState({
      agentSelected: agent,
      agentId: agent.value,
      driver_phone_number: `0${agent.phone_number}`,
      driver_phone_code: agent.phone_code,
      driver_name: agent.label,
    });
    // console.log(`truckSelected:`, this.state.truckID);
  }

  render() {
    if (
      this.props.accountInfoList.loading == false &&
      this.props.accountInfoList.accountInfoList != undefined
    ) {
      agentItem = this.props.accountInfoList.accountInfoList.account_info;
      this.renderAgent(this.props.accountInfoList);
    }

    return (
      <Mutation
        mutation={editShipmentMutation}
        variables={{
          shipmentID: this.state.shipmentId,
          shipment_status: 3,
        }}
        update={() => {
          this.props.callback();
        }}
      >
        {updateStatus => {
          return (
            <Mutation
              mutation={assignAgentMutation}
              variables={{
                shipment_id: this.state.shipmentId,
                transporter: this.state.agentId,
              }}
              update={() => {
                updateStatus();
              }}
            >
              {assignAgent => (
                <Mutation
                  mutation={trackingAddTransporterMutation}
                  update={() => {
                    assignAgent();
                  }}
                  onError={error => {
                    alert(error);
                  }}
                >
                  {trackingAddTransporter => (
                    <Row>
                      <Col md={12}>
                        <FormGroup controlId="formBasicText">
                          <TextControlLabel>ชื่อบริษัทขนส่ง*</TextControlLabel>
                          <Select
                            name={`agent_select`}
                            value={this.state.agentSelected}
                            onChange={event => this.handleChangeAgent(event)}
                            options={agentList}
                            placeholder="เลือกนายหน้า"
                          />
                          {/* <FormControl
                        type="text"
                        value={this.state.driver_name}
                        placeholder="ระบุ"
                        onKeyPress={e =>
                          this.onSubmitAddTransporter(
                            e,
                            this.state.trackingId,
                            trackingAddTransporter
                          )
                        }
                        onChange={e =>
                          this.setState({
                            driver_name: e.target.value,
                          })
                        }
                      /> */}
                        </FormGroup>
                        <FormGroup controlId="formBasicText">
                          <TextControlLabel>เบอร์โทรติดต่อ*</TextControlLabel>
                          <FormControl
                            type="text"
                            value={this.state.driver_phone_number}
                            placeholder="ตัวอย่าง: 0123456789"
                            onKeyPress={e =>
                              this.onSubmitAddTransporter(
                                e,
                                this.state.trackingId,
                                trackingAddTransporter
                              )
                            }
                            onChange={e =>
                              this.setState({
                                driver_phone_number: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                        <Button
                          onClick={e =>
                            this.onSubmitAddTransporter(
                              e,
                              this.state.trackingId,
                              trackingAddTransporter
                            )
                          }
                          disabled={!this.checkValidateAddTransporter()}
                          className="btn btn-red"
                          block
                        >
                          ตกลง
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

export default compose(
  graphql(accountInfoList, {
    name: 'accountInfoList',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        account_info_type: 3,
      },
    }),
  })
)(TransporterTrackingEdit);
