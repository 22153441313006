import React from 'react';
import { Query } from 'react-apollo';
import { FormControl } from 'react-bootstrap';
import accountInfoListQuery from './graphql/query/accountInfoList';

const SelectCustomer = ({ value, onChange, selectLabel }) => {
  const label = selectLabel || 'เลือกลูกค้า';

  return (
    <Query
      query={accountInfoListQuery}
      variables={{
        customer_service_type: 'PARCEL',
        showData: 1000,
      }}
      fetchPolicy="cache-and-network"
    >
      {({ data, loading, error }) => {
        return (
          <FormControl
            componentClass="select"
            placeholder="select"
            value={value}
            onChange={onChange}
            disabled={error || loading}
          >
            <option value="">{loading ? 'กำลังโหลด...' : label}</option>
            {!loading &&
              !error &&
              data.accountInfoList.account_info.map(item => (
                <option key={item._id} value={item._id}>
                  {item.account_info_business_name}
                </option>
              ))}
          </FormControl>
        );
      }}
    </Query>
  );
};

export default SelectCustomer;
