import gql from 'graphql-tag';

export default gql`
  mutation UpdateCreateDriverMonthlyCalendar(
    $create: [createDriverMonthlyCalendarArg]!
  ) {
    createDriverMonthlyCalendar(create: $create) {
      calendar{
        _id
        driver{
          _id
        }
        month
        account_info{
          _id
          account_info_business_name
        }
        date
        ready
      }
    }
  }
`;