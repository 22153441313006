import React, { useState, useEffect } from 'react';
import {
  Grid,
  Row,
  Col,
  Radio,
  Checkbox,
  Button,
  Modal,
  Table,
  FormControl,
} from 'react-bootstrap';
import { Mutation, graphql, Query, compose } from 'react-apollo';
import Moment from 'react-moment';
import NotificationSystem from 'react-notification-system';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import styled from 'styled-components';

import datamasterJobTypeListQuery from './../../../../../graphql/query/datamasterJobTypeList';
import datamasterServiceCategoryListQuery from './../../../../../graphql/query/datamasterServiceCategoryType';
import datamasterServiceItemAddMutation from './../../../../../graphql/mutation/datamasterServiceItemAdd';

//in Module
import { Card, Input, SelectBox, Select, Label } from './../../../../../components';

const selectOptionCategory = [
  { value: '5e54cef8e99db3b888a2b73d', label: 'ค่าขนส่ง', prefix: 'T1' },
  { value: '5e54cef8e99db3b888a2b749', label: 'ค่าบริการ', prefix: 'S1' },
  { value: '5e54cef8e99db3b888a2b755', label: 'ค่าสำรองจ่าย', prefix: 'R1' },
];

const ModalContainer = styled(Modal)`
  & .modal-dialog {
    width: 100%;
    max-width: 1024px;
  }
`;

const ModalContent = styled.div`
	padding: 10px;
	line-height: 1.8;

	& .header {
		font-size: 20px;
		font-weight: bold;
		line-height: 2;
	}

	& .footer {
		text-align: right;
		margin-top: 30px;

		& button:not(:last-child) {
			margin-right: 10px;
		}
	}

	& .btn-grey, .btn-grey:hover, .btn-grey:active, .btn-grey:focus {
		border: 1px solid #E3E3E3;
		background-color: #FFFFFF;
		color: #000000;
	}

	& .btn-red, .btn-red:hover, .btn-red:active, .btn-red:focus {
		border: 0px
		background-color: #FF0000;
		color: #FFFFFF;
	}

	& .btn-blue, .btn-blue:hover, .btn-blue:active, .btn-blue:focus {
		border: 0px
		background-color: #318DE8;
		color: #FFFFFF;
	}

	& .btn-green, .btn-green:hover, .btn-green:active, .btn-green:focus {
		border: 0px
		background-color: #19AA19 !important;
		color: #FFFFFF;
	}
`;

const IconStyled = styled.i`
  color: #0b8d10;
  font-size: 24px;
  cursor: pointer;

  &.off {
    transform: rotate(180deg);
    color: #b3b3b3;
  }
`;

const FormDataMaster = props => {
  const { show_modal, mode: MasterMode, dataModalService } = props;

  const {
    control,
    register,
    handleSubmit,
    watch,
    errors,
    setValue,
    clearErrors,
    getValues,
  } = useForm();
  const [values, setReactSelect] = useState({
    selectedOption: null,
  });
  const [prefix, setPrefix] = useState(
    dataModalService && dataModalService.categoryList ? dataModalService.categoryList.prefix : null
  );

  const onSubmit = (dataForm, datamasterServiceItemAdd) => {
    let serviceItem = [];
    dataForm.serviceItem.forEach((ele, key) => {
      if (MasterMode == 'new') {
        const data = {
          service_category_id: ele.service_category_id,
          serviceItem: [
            {
              datamaster_service_item_number: dataForm.datamaster_service_item_number,
              truck_type_id: ele.truck_type_id,
              service_item_name: {
                th: dataForm.service_item_name_th,
                en: dataForm.service_item_name_en,
              },
              cost: ele.cost,
              vat_cost: ele.vat_cost,
              wht_cost: ele.wht_cost,
              sale: ele.sale,
              vat: ele.vat,
              wht: ele.wht,
              unit: ele.unit,
              min_price: ele.min_price,
              enable: ele.enable,
            },
          ],
        };
        serviceItem.push(data);
      } else {
        const data = {
          service_category_id: ele.service_category_id,
          serviceItem: [
            {
              _id: ele._id,
              datamaster_service_item_number: dataForm.datamaster_service_item_number,
              truck_type_id: ele.truck_type_id,
              service_item_name: {
                th: dataForm.service_item_name_th,
                en: dataForm.service_item_name_en,
              },
              cost: ele.cost,
              vat_cost: ele.vat_cost,
              wht_cost: ele.wht_cost,
              sale: ele.sale,
              vat: ele.vat,
              wht: ele.wht,
              unit: ele.unit,
              min_price: ele.min_price,
              enable: ele.enable,
            },
          ],
        };
        serviceItem.push(data);
      }
    });

    datamasterServiceItemAdd({
      variables: {
        datamaster_service_detail: serviceItem,
      },
    });
  };

  const handleMultiChange = (selectedOption, CategoryList) => {
    setValue('reactSelect', selectedOption);
    const length = CategoryList.datamasterServiceCategoryList
      ? CategoryList.datamasterServiceCategoryList[0].datamaster_service_item.length
      : 0;
    let number = length + 1;
    if (length.toString().length == 1) {
      number = `00${length + 1}`;
    } else if (length.toString().length == 2) {
      number = `0${length + 1}`;
    }
    setValue('datamaster_service_item_number', number);
    setReactSelect({ selectedOption });
  };

  const renderIdCategory = (mode, dataProps) => {
    if (values.selectedOption == null) {
      if (mode == 'new') {
        return '5e54cef8e99db3b888a2b73d';
      } else {
        return dataProps.categoryList.setting_prefix_id;
      }
    }
    return values.selectedOption.value;
  };

  const renderPrefixCategory = (mode, dataProps) => {
    if (values.selectedOption == null) {
      if (mode == 'new') {
        return 'T1';
      } else {
        return dataProps.categoryList.prefix;
      }
    }
    return values.selectedOption.prefix;
  };

  const renderValueMain = (name, mode, dataProps, CategoryList) => {
    if (mode == 'new') {
      return '';
    } else {
      if (name == 'reactSelect') {
        return {
          value: dataProps.categoryList._id,
          label: dataProps.categoryList.service_category_name.th,
          prefix: dataProps.categoryList.prefix,
        };
      } else if (name == 'prefix') {
        return dataProps.categoryList.prefix;
      } else if (name == 'datamaster_service_item_number') {
        return dataProps.itemList.datamaster_service_item_number;
      } else if (name == 'service_item_name_th') {
        return dataProps.itemList.service_item_name.th;
      } else if (name == 'service_item_name_en') {
        return dataProps.itemList.service_item_name.en;
      }
      return '';
    }
  };

  const checkEnable = (key, mode, truckItem, dataModalService) => {
    if (watch(`serviceItem[${key}]["enable"]`) == undefined) {
      if (mode == 'new') {
        return true;
      } else {
        return truckItem.datamaster_service_item.filter(
          item =>
            item.datamaster_service_item_number ==
            dataModalService.itemList.datamaster_service_item_number
        )[0].enable;
      }
    }
    return watch(`serviceItem[${key}]["enable"]`);
  };

  return (
    <ModalContainer
      show={show_modal}
      onHide={() => {
        clearErrors();
        props.onHideModal(false);
      }}
    >
      <Mutation
        mutation={datamasterServiceItemAddMutation}
        update={() => {
          clearErrors();
          props.onHideModal(false);
          window.reload();
        }}
      >
        {datamasterServiceItemAdd => (
          <form onSubmit={handleSubmit(data => onSubmit(data, datamasterServiceItemAdd))}>
            <Modal.Header closeButton>
              <Modal.Title>{MasterMode == 'new' ? 'เพิ่มรายการ' : 'แก้ไขรายการ'} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Query
                query={datamasterServiceCategoryListQuery}
                variables={{
                  setting_prefix_id: renderIdCategory(MasterMode, dataModalService),
                }}
              >
                {({
                  loading,
                  error,
                  data: datamasterServiceCategoryList,
                  refetch: refetchdatamasterServiceCategoryList,
                  variables,
                }) => {
                  return (
                    <ModalContent>
                      <FormDataMaster />
                      <Row>
                        <Col md={6} sm={12}>
                          <Controller
                            as={
                              <SelectBox
                                style={{ marginTop: 10 }}
                                inputLabel="หมวดหมู่:"
                                options={selectOptionCategory}
                                placeholder="หมวดหมู่"
                                disabled={MasterMode != 'new'}
                                messageError={
                                  errors['reactSelect'] && errors['reactSelect'].message
                                }
                                required
                              />
                            }
                            onChange={([selected]) =>
                              handleMultiChange(selected, datamasterServiceCategoryList)
                            }
                            name={`reactSelect`}
                            control={control}
                            defaultValue={renderValueMain(
                              'reactSelect',
                              MasterMode,
                              dataModalService
                            )}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <Controller
                            as={
                              <Input
                                style={{ marginTop: 10 }}
                                inputLabel="รหัสบริการ:"
                                placeholder="รหัสบริการ"
                                readOnly
                                prefix={renderValueMain(
                                  'prefix',
                                  MasterMode,
                                  dataModalService,
                                  datamasterServiceCategoryList
                                )}
                                messageError={
                                  errors['datamaster_service_item_number'] &&
                                  errors['datamaster_service_item_number'].message
                                }
                                required
                              />
                            }
                            rules={{
                              required: 'กรุณากรอกข้อมูล',
                              maxLength: { value: 3, message: 'จำนวน 3 ตัวอักษรเท่านั้น' },
                              minLength: { value: 3, message: 'จำนวน 3 ตัวอักษรเท่านั้น' },
                              pattern: { value: /^[0-9]+$/i, message: 'กรอกเฉพาะตัวเลขเท่านั้น' },
                            }}
                            name={`datamaster_service_item_number`}
                            control={control}
                            defaultValue={renderValueMain(
                              'datamaster_service_item_number',
                              MasterMode,
                              dataModalService,
                              datamasterServiceCategoryList
                            )}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <Controller
                            as={
                              <Input
                                style={{ marginTop: 10 }}
                                inputLabel="ชื่อบริการ (ไทย):"
                                placeholder="ชื่อบริการ (ไทย):"
                                messageError={
                                  errors['service_item_name_th'] &&
                                  errors['service_item_name_th'].message
                                }
                                required
                              />
                            }
                            rules={{ required: 'กรุณากรอกข้อมูล' }}
                            name={`service_item_name_th`}
                            control={control}
                            defaultValue={renderValueMain(
                              'service_item_name_th',
                              MasterMode,
                              dataModalService
                            )}
                          />
                        </Col>
                        <Col md={6} sm={12}>
                          <Controller
                            as={
                              <Input
                                style={{ marginTop: 10 }}
                                inputLabel="ชื่อบริการ (อังกฤษ):"
                                placeholder="ชื่อบริการ (อังกฤษ):"
                                messageError={
                                  errors['service_item_name_en'] &&
                                  errors['service_item_name_en'].message
                                }
                                required
                              />
                            }
                            rules={{ required: 'กรุณากรอกข้อมูล' }}
                            name={`service_item_name_en`}
                            control={control}
                            defaultValue={renderValueMain(
                              'service_item_name_en',
                              MasterMode,
                              dataModalService
                            )}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <div style={{ padding: '15px' }}>
                          <Table bordered responsive>
                            <thead>
                              <tr>
                                <td style={{ textAlign: 'center', fontWeight: 700, width: 45 }}>
                                  เปิดใช้
                                </td>
                                <td style={{ textAlign: 'center', fontWeight: 700, width: 85 }}>
                                  รหัส
                                </td>
                                <td style={{ textAlign: 'center', fontWeight: 700, width: 180 }}>
                                  ประเภทรถ
                                </td>
                                <td style={{ textAlign: 'center', fontWeight: 700 }}>หน่วยนับ</td>
                                <td colSpan={3} style={{ textAlign: 'center', fontWeight: 700 }}>
                                  ราคาต้นทุน
                                </td>
                                <td colSpan={3} style={{ textAlign: 'center', fontWeight: 700 }}>
                                  ราคาขาย
                                </td>
                                <td style={{ textAlign: 'center', fontWeight: 700 }}>
                                  ราคาขั้นต่ำ (บาท)
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>ราคา (บาท)</td>
                                <td>VAT (%)</td>
                                <td>WHT (%)</td>
                                <td>ราคา (บาท)</td>
                                <td>VAT (%)</td>
                                <td>WHT (%)</td>
                                <td></td>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ||
                              error ||
                              datamasterServiceCategoryList.datamasterServiceCategoryList ==
                                undefined ? (
                                <tr>
                                  <td colSpan={11}>กำลังค้นหาข้อมูล</td>
                                </tr>
                              ) : (
                                datamasterServiceCategoryList.datamasterServiceCategoryList.map(
                                  (truckItem, key) => (
                                    <tr key={key}>
                                      <td style={{ textAlign: 'center' }}>
                                        <Controller
                                          as={<FormControl style={{ display: 'none' }} />}
                                          name={`serviceItem[${key}]["service_category_id"]`}
                                          control={control}
                                          defaultValue={truckItem._id}
                                        />
                                        <Controller
                                          as={<FormControl style={{ display: 'none' }} />}
                                          name={`serviceItem[${key}]["_id"]`}
                                          control={control}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0]._id
                                              : null
                                          }
                                        />
                                        <Controller
                                          as={<FormControl style={{ display: 'none' }} />}
                                          name={`serviceItem[${key}]["truck_type_id"]`}
                                          control={control}
                                          defaultValue={truckItem.truck_type_id}
                                        />
                                        <Controller
                                          as={
                                            <FormControl
                                              type="checkbox"
                                              style={{ display: 'none' }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["enable"]`}
                                          control={control}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].enable
                                              : true
                                          }
                                        />
                                        {checkEnable(
                                          key,
                                          MasterMode,
                                          truckItem,
                                          dataModalService
                                        ) ? (
                                          <IconStyled
                                            onClick={() =>
                                              setValue(`serviceItem[${key}]["enable"]`, false)
                                            }
                                            name="enable-on"
                                            className="fas fa-toggle-on"
                                          />
                                        ) : (
                                          <IconStyled
                                            onClick={() =>
                                              setValue(`serviceItem[${key}]["enable"]`, true)
                                            }
                                            name="enable-off"
                                            className="fas fa-toggle-on off"
                                          />
                                        )}
                                      </td>
                                      <td>
                                        {`${renderPrefixCategory(
                                          MasterMode,
                                          dataModalService
                                        )}${renderValueMain(
                                          'datamaster_service_item_number',
                                          MasterMode,
                                          dataModalService,
                                          datamasterServiceCategoryList
                                        )}`}
                                        -{truckItem.truck_type_prefix}
                                      </td>
                                      <td>
                                        {truckItem.truck_size_name.th}
                                        {truckItem.truck_type_name.th}
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="text"
                                              placeholder="หน่วยนับ"
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['unit']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["unit"]`}
                                          control={control}
                                          rules={{ required: 'กรุณากรอกข้อมูล' }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].unit
                                              : ''
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="number"
                                              placeholder="ราคา"
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['cost']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["cost"]`}
                                          control={control}
                                          rules={{
                                            required: 'กรุณากรอกข้อมูล',
                                            min: 0,
                                            pattern: {
                                              value: /^[0-9]+$/i,
                                              message: 'กรอกเฉพาะตัวเลขเท่านั้น',
                                            },
                                          }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].cost
                                              : 0
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              min={0}
                                              step={0.1}
                                              type="number"
                                              placeholder="VAT"
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['vat_cost']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["vat_cost"]`}
                                          control={control}
                                          rules={{ required: 'กรุณากรอกข้อมูล' }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].vat_cost
                                              : 0
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="number"
                                              min={0}
                                              step={0.1}
                                              placeholder="WHT"
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['wht_cost']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["wht_cost"]`}
                                          control={control}
                                          rules={{ required: 'กรุณากรอกข้อมูล' }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].wht_cost
                                              : 0
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="number"
                                              placeholder="ราคา"
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['sale']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["sale"]`}
                                          control={control}
                                          rules={{
                                            required: 'กรุณากรอกข้อมูล',
                                            min: 0,
                                            pattern: {
                                              value: /^[0-9]+$/i,
                                              message: 'กรอกเฉพาะตัวเลขเท่านั้น',
                                            },
                                          }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].sale
                                              : 0
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="number"
                                              placeholder="VAT"
                                              min={0}
                                              step={0.1}
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['vat']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["vat"]`}
                                          control={control}
                                          rules={{ required: 'กรุณากรอกข้อมูล' }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].vat
                                              : 0
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="number"
                                              placeholder="WHT"
                                              min={0}
                                              step={0.1}
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['wht']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["wht"]`}
                                          control={control}
                                          rules={{ required: 'กรุณากรอกข้อมูล' }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].wht
                                              : 0
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Controller
                                          as={
                                            <FormControl
                                              type="number"
                                              placeholder="ราคา"
                                              style={{
                                                minWidth: 70,
                                                borderColor:
                                                  errors['serviceItem'] &&
                                                  errors['serviceItem'][key] &&
                                                  errors['serviceItem'][key]['min_price']
                                                    ? '#FF0000'
                                                    : '#E3E3E3',
                                              }}
                                            />
                                          }
                                          name={`serviceItem[${key}]["min_price"]`}
                                          control={control}
                                          rules={{
                                            required: 'กรุณากรอกข้อมูล',
                                            min: 0,
                                            pattern: {
                                              value: /^[0-9]+$/i,
                                              message: 'กรอกเฉพาะตัวเลขเท่านั้น',
                                            },
                                          }}
                                          defaultValue={
                                            MasterMode == 'edit'
                                              ? truckItem.datamaster_service_item.filter(
                                                  item =>
                                                    item.datamaster_service_item_number ==
                                                    dataModalService.itemList
                                                      .datamaster_service_item_number
                                                )[0].min_price
                                              : 0
                                          }
                                        />
                                      </td>
                                    </tr>
                                  )
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </Row>
                    </ModalContent>
                  );
                }}
              </Query>
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ float: 'left' }}
                onClick={() => {
                  clearErrors();
                  props.onHideModal(false);
                }}
              >
                กลับ
              </Button>
              {/* <Button type="button" bsStyle="primary" onClick={handleSubmit((data) => saveDataMaster(data,errors,datamasterServiceItemAdd))}>ตกลง</Button> */}
              <Button type="submit" bsStyle="primary">
                ตกลง
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Mutation>
    </ModalContainer>
  );
};

export default React.memo(FormDataMaster);
