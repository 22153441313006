import React from 'react';
import classes from './SubHeader.module.scss';
import { withRouter } from 'react-router-dom';

const SubHeader = props => {
  return (
    <div className={classes.wrapper} {...props}>
      <a href={props.backUrl} className={classes.btnBack}>
        <i className="far fa-arrow-left"></i> กลับ
      </a>
      <div className={classes.line} />
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

export default withRouter(SubHeader);
