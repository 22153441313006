import React, { Component } from 'react';
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  FormControl,
  OverlayTrigger,
  Popover,
  Button,
  Tooltip,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CardTable from 'components/CardTable/CardTable.jsx';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'react-datetime/css/react-datetime.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {
  Container,
  TabHeaderContainer,
  TabContentContainer,
  PageWrapper,
  NavDropdownContainer,
  NavItemA,
} from './Styles';
import SelectCustomerField from './SelectCustomerField';
import _ from 'lodash';
import costingUrl from '../../../../config/costingUrl';

class CostingList extends Component {
  state = {
    total_page: 0,
    page_number: 1,
    orderBy: null,
    orderType: null,
    costingList: [],
    costingStatus: 1,
    costingNumber: '',
    numberRef: '',
    lastSend: '',
    displayLastSendDate: '',
    isLoading: false,
    payer: '',
    currentPage: 1,
    totalPage: 1,
    totalDocument: 0,
  };

  componentDidMount() {
    this.fetchData();
  }

  delay = (fn, ms) => {
    let timer = 0;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(fn(...args), ms || 0);
    };
  };

  fetchData = () => {
    this.setState({ isLoading: true });
    const debounceFetch = _.debounce(() => {
      try {
        axios
          .get(costingUrl, {
            params: {
              last_send: this.state.lastSend,
              costing_status: this.state.costingStatus,
              costing_number: this.state.costingNumber,
              number_ref: this.state.numberRef,
              order_by: this.state.orderBy,
              order_type: this.state.orderType,
              payer: this.state.payer,
              page: this.state.currentPage,
            },
          })
          .then(response => {
            const { costings, totalDocument, totalPage } = response.data;
            this.setState({
              costingList: costings,
              isLoading: false,
              totalDocument,
              totalPage,
            });
          });
      } catch (err) {
        this.setState({
          costingList: [],
          isLoading: false,
        });
      }
    }, 2000);
    debounceFetch();
  };

  sort(orderBy, cb) {
    if (orderBy === this.state.orderBy) {
      this.setState({ orderType: this.state.orderType * -1 }, () => cb());
    } else {
      this.setState({ orderType: 1, orderBy }, () => cb());
    }
  }

  renderSorting(orderBy) {
    if (orderBy === this.state.orderBy) {
      if (this.state.orderType === 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    this.setState({ page_number: page + 1, total_page });
    refetch();
  }

  formatCurrency = number => {
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  renderProfit = value => {
    return (
      <div
        style={{
          color: value < 0 ? '#D90101' : value > 0 ? '#5DBA5B' : undefined,
        }}
      >
        <string>
          {value < 0 ? `(${this.formatCurrency(value * -1)})` : `${this.formatCurrency(value)}`}
        </string>
      </div>
    );
  };

  handleDateRangePicker = (mode, picker, cb) => {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'lastSend') {
      this.setState({ lastSend: date, displayLastSendDate: display }, () => cb());
    }
  };

  render() {
    const tabs = [
      {
        id: 1,
        title: 'รอตรวจสอบ',
      },
      {
        id: 2,
        title: 'ตรวจสอบแล้ว',
      },
      {
        id: 3,
        title: 'ปฏิเสธ',
      },
    ];

    const columns = [
      {
        title: 'เครื่องมือ',
        width: 150,
      },
      {
        title: 'วันที่ส่งรายการ',
        width: 160,
        sortName: 'last_send',
        search: (
          <DateRangePicker
            onApply={(_, picker) =>
              this.handleDateRangePicker('lastSend', picker, () => {
                this.setState(
                  {
                    currentPage: 1,
                  },
                  () => this.fetchData()
                );
              })
            }
            onCancel={() =>
              this.setState(
                {
                  lastSend: '',
                  displayLastSendDate: '',
                  currentPage: 1,
                },
                () => this.fetchData()
              )
            }
            containerStyles={{ display: 'block' }}
            opens="right"
            format="DD/MM/YYYY"
          >
            <FormControl type="text" value={this.state.displayLastSendDate} placeholder="ค้นหา" />
          </DateRangePicker>
        ),
      },
      {
        title: 'รหัสต้นทุน/ขาย',
        width: 160,
        sortName: '_id',
        search: (
          <FormControl
            value={this.state.costingNumber}
            type="text"
            placeholder="ค้นหา"
            onChange={event => {
              this.setState({ costingNumber: event.target.value, currentPage: 1 }, () =>
                this.fetchData()
              );
            }}
          />
        ),
      },
      {
        title: 'รหัสงานขนส่ง',
        width: 160,
        sortName: 'ref_id',
        search: (
          <FormControl
            value={this.state.numberRef}
            type="text"
            placeholder="ค้นหา"
            onChange={event => {
              this.setState({ numberRef: event.target.value, currentPage: 1 }, () =>
                this.fetchData()
              );
            }}
          />
        ),
      },
      {
        title: 'ชื่อลูกค้า',
        width: 160,
        search: (
          <SelectCustomerField
            onSearch={() => {
              if (this.state.payer) {
                this.setState(
                  {
                    payer: '',
                    currentPage: 1,
                  },
                  () => this.fetchData()
                );
              }
            }}
            onSelected={valueId => {
              this.setState(
                {
                  payer: valueId,
                  currentPage: 1,
                },
                () => this.fetchData()
              );
            }}
          />
        ),
      },
      {
        title: 'ต้นทุนก่อนภาษี',
        width: 160,
        sortName: 'total_after_discount.cost',
      },
      {
        title: 'รายได้ก่อนภาษี',
        width: 160,
        sortName: 'total_after_discount.sale',
      },
      {
        title: 'กำไร - ขาดทุน',
        width: 160,
      },
      {
        title: 'ผู้รับเงิน',
        width: 160,
      },
      {
        title: 'ผู้ส่งราคา',
        width: 160,
      },
      {
        title: 'อ้างอิงรหัสใบสั่งจ่าย',
        width: 160,
        sortName: 'payment.payment_number',
      },
      {
        title: 'อ้างอิงรหัสใบแจ้งหนี้',
        width: 160,
        sortName: 'invoice.invoice_number',
      },
      {
        title: 'อ้างอิงรหัสใบเสร็จ',
        width: 160,
      },
      {
        title: 'อัพเดทล่าสุด',
        width: 160,
        sortName: 'last_update',
      },
    ];

    const dataTable = this.state.costingList
      .map(item => ({
        tools: (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}>
              <a
                href={`/shipmentcost?shipment=${item.ref_id}`}
                target="_blank"
                style={{ float: 'left' }}
                className="btn btn-default"
              >
                <i style={{ marginTop: '3px' }} className="fas fa-eye"></i>
              </a>
            </OverlayTrigger>

            <NavDropdownContainer
              title={
                <Button disabled={!item.invoice_document && !item.receipt_document}>
                  เครื่องมือ <i className="fal fa-angle-down"></i>
                </Button>
              }
            >
              {item.invoice_document && (
                <NavItemA>
                  <a href={`${item.invoice_document}`} target="_blank">
                    <i className="fal fa-file-archive"></i> ดาวน์โหลด invoice
                  </a>
                </NavItemA>
              )}

              {item.receipt_document && (
                <NavItemA>
                  <a href={`${item.receipt_document}`} target="_blank">
                    <i className="fal fa-file-archive"></i> ดาวน์โหลด receipt
                  </a>
                </NavItemA>
              )}
            </NavDropdownContainer>
          </>
        ),
        lastSend: <Moment format="DD/MM/YYYY HH:mm">{item.last_send}</Moment>,
        costingNumber: (
          <div>
            {item.costing_number}{' '}
            <span>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover>
                    <div style={{ fontSize: 18, color: '#000000' }}>
                      {item.send_history.map((sendHistoryItem, sendHistoryIndex) => (
                        <div key={sendHistoryIndex}>
                          <strong>ครั้งที่ {sendHistoryIndex + 1}:</strong> ส่งเมื่อ{' '}
                          {sendHistoryItem.timestamp}
                        </div>
                      ))}
                    </div>
                  </Popover>
                }
              >
                <i
                  className="far fa-history"
                  style={{ color: 'rgba(0, 0, 0, 0.65)', fontSize: 15 }}
                ></i>
              </OverlayTrigger>
            </span>
          </div>
        ),
        numberRef: item.number_ref,
        payerFullName: item.payer.full_name,
        totalAfterDiscountCost: this.formatCurrency(item.total_after_discount.cost),
        totalAfterDiscountSale: this.formatCurrency(item.total_after_discount.sale),
        profit: this.renderProfit(item.profit),
        payeeFullName: item.payee.full_name,
        employeeFullName: item.employee.full_name,
        paymentRefNumber:
          item.payment.length === 0
            ? '-'
            : item.payment.map(payment => payment.payment_number).join(', '),
        invoiceRefNumber:
          item.invoice.length === 0
            ? '-'
            : item.invoice.map(invoice => invoice.invoice_number).join(', '),
        receiptNumber: item.receipt_number || '-',
        lastUpdate: <Moment format="DD/MM/YYYY HH:mm">{item.last_update}</Moment>,
      }))
      .map((item, index) => (
        <tr key={index}>
          <td>{item.tools}</td>
          <td>{item.lastSend}</td>
          <td>{item.costingNumber}</td>
          <td>{item.numberRef}</td>
          <td>{item.payerFullName}</td>
          <td>{item.totalAfterDiscountCost}</td>
          <td>{item.totalAfterDiscountSale}</td>
          <td>{item.profit}</td>
          <td>{item.payeeFullName}</td>
          <td>{item.employeeFullName}</td>
          <td>{item.paymentRefNumber}</td>
          <td>{item.invoiceRefNumber}</td>
          <td>{item.receiptNumber}</td>
          <td>{item.lastUpdate}</td>
        </tr>
      ));

    return (
      <Container>
        <Grid fluid style={{ paddingTop: 5, marginBottom: '40px' }}>
          <Row>
            <Col md={12}>
              <CardTable
                title="ต้นทุน/ขาย (Costing)"
                category="ข้อมูลต้นทุน/ขาย"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div>
                    <TabHeaderContainer>
                      <div className="sub-header-left">
                        <div className="menu-wrapper">
                          <ul>
                            {tabs.map(tab => (
                              <li className={this.state.costingStatus === tab.id && 'active'}>
                                <a
                                  onClick={() => {
                                    this.setState(
                                      {
                                        costingStatus: tab.id,
                                        currentPage: 1,
                                      },
                                      () => this.fetchData()
                                    );
                                  }}
                                >
                                  {tab.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </TabHeaderContainer>

                    <TabContentContainer>
                      <div className="tab-item">
                        <PageWrapper style={{ textAlign: 'right' }}>
                          {!this.state.isLoading && (
                            <div className="text">
                              {this.state.currentPage === 1
                                ? `1 - ${dataTable.length}`
                                : `${(this.state.currentPage - 1) * 30 + 1} - ${dataTable.length +
                                    (this.state.currentPage - 1) * 30}`}{' '}
                              หน้า :
                            </div>
                          )}
                          <FormControl
                            type="number"
                            min={1}
                            value={this.state.currentPage}
                            onChange={event =>
                              this.setState({ currentPage: event.target.value }, () =>
                                this.fetchData()
                              )
                            }
                          />
                          <div className="text">{`จาก ${this.state.totalPage}`}</div>
                        </PageWrapper>

                        <div className="table-vertical">
                          <Table striped hover>
                            <thead>
                              <tr>
                                {columns.map(column => (
                                  <th
                                    style={{ width: column.width, minWidth: column.width }}
                                    className={
                                      column.sortName
                                        ? this.renderSorting(column.sortName)
                                        : undefined
                                    }
                                    onClick={() =>
                                      this.sort(column.sortName, () => {
                                        this.fetchData();
                                      })
                                    }
                                  >
                                    {column.title}
                                  </th>
                                ))}
                              </tr>
                              <tr className="tableFilter">
                                {columns.map(column => (
                                  <th>{column.search || undefined}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.isLoading && (
                                <tr>
                                  <td colSpan={columns.length}>Loading...</td>
                                </tr>
                              )}

                              {!this.state.isLoading && dataTable.length === 0 && (
                                <tr>
                                  <td colSpan={columns.length}>ไม่พบข้อมูล</td>
                                </tr>
                              )}

                              {!this.state.isLoading && dataTable}
                            </tbody>
                          </Table>
                        </div>
                        {this.state.totalPage > 0 && (
                          <div style={{ textAlign: 'right' }}>
                            <ReactPaginate
                              pageCount={this.state.totalPage}
                              forcePage={this.state.currentPage - 1}
                              containerClassName="pagination"
                              nextLabel=">"
                              previousLabel="<"
                              onPageChange={({ selected }) => {
                                this.setState({ currentPage: selected + 1 }, () =>
                                  this.fetchData()
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </TabContentContainer>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </Container>
    );
  }
}

export default CostingList;
