import gql from 'graphql-tag';

export default gql`
  query paymentOrderList($shipment_id: ID, $_id: ID) {
    paymentOrderList(shipment_id: $shipment_id, _id: $_id) {
      payment_order {
        _id
        shipment_id
        shipment_prefix
        shipment_number
        account_info_id
        billing_customer_id
        payment_order_status
        billing_payment_order_id
        round_paid_date
        admin_id
        admin_username
        employee_id
        system_request
        transporter
        transporter_name
        driver_user_id
        driver_name
        payment_type
        payment_order_item {
          setting_prefix_id
          datamaster_service_item_id
          prefix
          datamaster_service_item_number
          name
          qty
          unit
          price
          discount
          total_price
          vat
          wht
          total_vat
          total_wht
          grand_total_price
        }
        total_price
        grand_total_price
        create_date
        last_update
        payments {
          _id
          payment_number
          payment_status
          payment_remark
          create_date
          round_paid
          reject_remark
          cancel_remark
        }
        payment_voucher {
          _id
          voucher_number
          voucher_status
          voucher_remark
          due_date
          payment_method
          payment_method_detail
          paid_date
          create_date
        }
        advance_before_disconst_price
        advance_disconst
        advance_after_disconst_price
        advance_vat7
        advance_included_vat_price
        non_vat_before_disconst_price
        non_vat_disconst
        non_vat_after_disconst
        non_vat_total_price
        non_vat_vat7
        non_vat_included_vat_price
        vat_before_disconst_price
        vat_disconst
        vat_after_disconst
        vat_total_price
        vat_vat7
        vat_included_vat_price
        total_price_included_vat
        total_wht1
        total_wht3
        total_wht5
        total_price_wht1
        total_price_wht3
        total_price_wht5
        wht_list{
          wht
          price
          total_wht
        }
      }
    }
  }
`;
