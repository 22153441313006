import React from 'react';
import { StyledTable } from './styled';
import formatMoney from 'utils/formatMoney.js';

const CodLisyTable = props => {
  const newCodMap = [];
  props.selectedListWithCustomer.map(data =>
    data.shipment_list.map((shipList, index) =>
      newCodMap.push({
        customer: index == 0 ? data.customer.full_name : '',
        booking_number: shipList.booking_number,
        shipment_number: shipList.shipment_number,
        cod_price: shipList.cod_price,
      })
    )
  );

  return (
    <StyledTable striped>
      <thead>
        <tr>
          <th style={{ width: '22%', minWidth: '22%' }}>ชื่อลูกค้า</th>
          <th style={{ width: '25%', minWidth: '25%' }}>Booking Number</th>
          <th style={{ width: '28%', minWidth: '28%' }}>Shipment Number</th>
          <th style={{ width: '25%', minWidth: '25%' }}>ยอดรวม COD ที่ต้องโอน (บาท)</th>
        </tr>
      </thead>
      <tbody>
        {newCodMap.map(data => (
          <tr key={data._id}>
            {props.newCodCase && <td style={{ width: '22%', minWidth: '22%' }}>{data.customer}</td>}
            <td style={{ width: '25%', minWidth: '25%' }}>
              {props.newCodCase ? data.booking_number : data.shipment_trip}
            </td>
            <td style={{ width: '28%', minWidth: '28%' }}>
              {props.newCodCase ? data.shipment_number : data.shipment_name}
            </td>
            <td className="cod" style={{ width: '25%', minWidth: '25%' }}>
              {props.newCodCase
                ? formatMoney(data.cod_price || 0)
                : formatMoney(data.shipment_accept_cod || 0)}
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default CodLisyTable;
