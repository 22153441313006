import React, { Component } from "react";
import cookie from 'react-cookies'
import { Mutation } from "react-apollo";
import { Grid, Row, Col, Tooltip, Button, FormGroup, FormControl, InputGroup } from "react-bootstrap";

import CardLogin from "components/CardLogin/CardLogin.jsx";
import version from "config/version";

import logo from "assets/img/logo_giztix.svg";

import adminSignin from "./graphql/mutation/adminSignin"

class Login extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            admin_username: "",
            admin_password: "",
            admin_token_key: null,
        }
    }

    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <CardLogin
                                logo={logo}
                                title="ระบบบริหารงานขนส่ง"
                                content={
                                    <>
                                        <Mutation
                                            mutation={adminSignin}
                                            variables={{
                                                admin_username: this.state.admin_username,
                                                admin_password: this.state.admin_password,
                                                signin_from: 1
                                            }}
                                            update={(cache, { data }) => {
                                                cookie.save('token_key', data.adminSignin.admin_token_key, { path: '/' });
                                                console.log(cookie.load('token_key'));
                                                window.open('/', "_self");
                                            }}
                                            onError={(error) => {
                                                alert("Username or password incorrect.");
                                                this.setState({ admin_username: "", admin_password: "" })
                                                console.log(error.message);
                                            }}
                                        >
                                            {(adminSignin, { loading }) => (
                                                <form id="login_form" method="POST" action="javascript:;" style={{ margin: "0 25px" }}>
                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon><i className="fas fa-envelope"></i></InputGroup.Addon>
                                                            <FormControl
                                                                type="text"
                                                                value={this.state.admin_username}
                                                                onChange={(e) => this.setState({ admin_username: e.target.value })}
                                                                onKeyPress={(e) => e.charCode == 13 && !loading && adminSignin()}
                                                                placeholder="username"
                                                                name="user_name"
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <InputGroup>
                                                            <InputGroup.Addon><i className="fas fa-key"></i></InputGroup.Addon>
                                                            <FormControl
                                                                type="password"
                                                                value={this.state.admin_password}
                                                                onChange={(e) => this.setState({ admin_password: e.target.value })}
                                                                onKeyPress={(e) => e.charCode == 13 && !loading && adminSignin()}
                                                                placeholder="รหัสผ่านอย่างน้อย 6 ตัวขึ้นไป"
                                                                name="password"
                                                            />
                                                        </InputGroup>
                                                    </FormGroup>
                                                    <Button block bsStyle="danger" type="button" onClick={() => adminSignin()} style={{ marginTop: "50px" }}>ลงชื่อเข้าใช้</Button>

                                                </form>
                                            )
                                            }
                                        </Mutation>
                                        <Col style={{ textAlign: 'center', marginTop: 20 }}>v{version}</Col>
                                    </>
                                }

                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Login;