import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 5%;
  height: 20%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d00000;
  justify-content: center;

  ${props =>
    props.status === 1 &&
    `
    background-color: #d00000;
  `}

  ${props =>
    props.status === 2 &&
    `
    background-color: #0D1578;
  `}

  ${props =>
    props.status === 3 &&
    `
    background-color: #3EAF2C;
  `}

  ${props =>
    props.status === 4 &&
    `
    color: #000000;
    background-color: #dddddd;
  `}
`;

const TextTitle = styled.div`
  font-size: 1.2vw;
  font-weight: bold;
  height: 1vw;
`;

const TextNumber = styled.div`
  font-size: 5vh;
  font-weight: bold;
  height: 4vh;
`;

const Icon = styled.div`
  font-size: 2vw;
  height: 2.4vw;

  & i {
    line-height: 1.5;
  }
`;

const CardTripStatus = props => {
  const { status, tripNumber, ...otherProps } = props;

  const textTitle = status !== 4 ? 'Trip' : 'ยกเลิก';

  return (
    <Container status={status} {...otherProps}>
      <TextTitle>{textTitle}</TextTitle>
      <TextNumber>{tripNumber}</TextNumber>
    </Container>
  );
};

export default CardTripStatus;
