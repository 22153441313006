import React, { useEffect, useState } from 'react';
import { Button, Modal as ModalBootstrap } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ModalConfirmPayCod from './ModalConfirmPayCod';
import styled from 'styled-components';
import { validateSchema } from './validate';
import initialValue from './initialValue';
import Form from './Form';
import moment from 'moment';

export const Modal = styled(ModalBootstrap)`
  & .custom-modal {
    width: 100%
    max-width: 1000px;
  }

  & h4.modal-title {
    font-size: 20px;
    font-weight: bold;
  }
`;

const ModalPayCod = ({
  isOpen = false,
  onCancel,
  codList = [],
  onCompleted,
}) => {
  const [isOpenModalConfirmPayCode, setOpenModalConfirmPayCode] =
    useState(false);

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: initialValue,
    resolver: yupResolver(validateSchema),
  });

  const { handleSubmit, reset } = methods;


  // useEffect(() => {
  //   if (isOpen) {
  //     reset({
  //       ...initialValue,
  //       dateTime: moment().format('YYYY-MM-DD HH:mm'),
  //       codList: codList.map((item) => ({
  //         id: item._id,
  //         lazadaOrderType: item.lazada_order_type,
  //         shipmentTrip: item.shipment_trip,
  //         trackingNumber: item.trackingNumber,
  //         trackingDeliveredDate: item.trackingDeliveredDate,
  //         codPrice: item.cod_price,
  //         deliveryId: item.delivery_id,
  //       })),
  //     });
  //   }
  // }, [reset, isOpen]);

  const onSubmit = handleSubmit(() => {
    setOpenModalConfirmPayCode(true);
  });

  return (
    <FormProvider {...methods}>
      <Modal show={isOpen} dialogClassName="custom-modal">
        <Modal.Header>
          <Modal.Title>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>รายการชำระเงินค่า COD</div>
              <div>
                {/* <Button
                  style={{
                    color: '#ffffff',
                    marginRight: 10,
                    backgroundColor: '#5DBA5B',
                  }}
                  onClick={() => onSubmit()}
                >
                  ยืนยันการโอนเงิน
                </Button> */}
                <Button onClick={() => onCancel && onCancel()}>ปิด</Button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form data={codList} />
        </Modal.Body>
      </Modal>

      <ModalConfirmPayCod
        isOpen={isOpenModalConfirmPayCode}
        onCancel={() => setOpenModalConfirmPayCode(false)}
        onCompleted={() => {
          setOpenModalConfirmPayCode(false);
          onCompleted();
        }}
      />
    </FormProvider>
  );
};

export default ModalPayCod;
