import * as React from 'react';
import { FunctionRenameFile } from './useFunctions';
const { TEXT_UPLOAD_FILE } = require('./useConstants');

const FieldUploadFile = ({ setValue = () => {}, name = 'name' }) => {
  const onClickUpLoadImage = async ({ target: { files } }) => {
    const file = await files[0];

    const newName = `document_${new Date().getTime()}`;

    const newFile = FunctionRenameFile(file, newName);

    setValue({ file: newFile, type: name });
  };

  return (
    <div
      style={{
        border: '1px solid #80808030',
        borderRadius: '5px',
        height: '31px',
        background: '#fff',
      }}
    >
      <label htmlFor={`index-reference-${name}`}>
        <div className={``}>
          <div
            style={{
              fontSize: '18px',
              padding: '5px',
              color: '#000',
            }}
          >
            {TEXT_UPLOAD_FILE}
          </div>
          <input
            style={{
              display: 'none',
            }}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            name={name}
            id={`index-reference-${name}`}
            type="file"
            onChange={onClickUpLoadImage}
          />
        </div>
      </label>
    </div>
  );
};

export default FieldUploadFile;
