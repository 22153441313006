import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { Grid } from "react-bootstrap";
import version from 'config/version'

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://www.giztix.com">Giztix</a>- ALL Rights Reserved. ( Version {version} )
          </p>
        </Grid>
      </footer>
    );
  }
}

export default Footer;
