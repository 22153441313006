import gql from 'graphql-tag';

export default gql`
  query ShipmentWaitCodInByTracking(
    $shipment_trip: String
    $transporter: ID
    $trackingNumber: String
    $tranfer_cod_cash_date: String
    $trackingDeliveredDate: String
    $page: Int
    $showData: Int
  ) {
    shipmentWaitCodInByTracking(
      params: {
        shipment_trip: $shipment_trip
        trackingNumber: $trackingNumber
        transporter: $transporter
        tranfer_cod_cash_date: $tranfer_cod_cash_date
        trackingDeliveredDate: $trackingDeliveredDate
      }
      page: $page
      showData: $showData
    ) {
      trackings {
        _id
        shipment_trip
        lazada_import_order_id
        lazada_order_type
        shipment_status
        confirm_cod_status
        shipment_ka_compeleted
        shipment_ka_compeleted_date
        create_date
        last_update
        tracking_id
        delivery_id
        trackingNumber
        cod_cash
        cod_price
        paid_by_cod
        orderNumber
        trackingDeliveredDate
        tranfer_cod_cash_date
        driver_user_id
        driver_number
        driver_name
        user_setting_truck_license_plate
        agent
        agent_name
      }
      totalPage
      totalDocument
    }
  }
`;
