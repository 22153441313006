import gql from 'graphql-tag';

export default gql`
query search(
    $page_number : Int
    $order_by : String
    $order_type : String
  ){
    userSettingTruckList(
      page_number :$page_number,
      show_data: 30,
      order_by : $order_by
      order_type : $order_type
    ){
      user_setting_truck{
         _id
          user_setting_truck_license_plate
          driver_status
      }
      total_page
    }
  }
`;
