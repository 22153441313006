import gql from 'graphql-tag';

export default gql`
    mutation setShipmentsUpdate(
        $_id: ID!
        $ka_driver_payment_type: Int
    ) {
        shipmentsUpdate(
            _id: $_id
            ka_driver_payment_type: $ka_driver_payment_type
        ){
            _id
        }
    }
`;