import gql from 'graphql-tag';

export default gql`
mutation   Update(
    $account_info_id: ID!
    $line_user_id: ID
    $driver_name: String!
    $driver_phone_code: String!
    $driver_phone_number: String!
    $driver_enable: Int!
    $user_setting_truck_license_plate: String!
    $user_setting_truck_license_plate_back: String
    $driver_status: Boolean
    $line_id: String
    $reserve_phone_code: String
    $reserve_phone_number: String
    $criminal_history: String
    $id_card_number: String
    $id_card_pic: [typePathArg]
    $driver_card_pic: [typePathArg]
    $car_pic: [typePathArg]
    $license_plate_pic: [typePathArg]
    $act_pic: [typePathArg]
    $car_insurance_pic: [typePathArg]
    $book_bank_pic: [typePathArg]
    $setting_truck_service: [ID]!
    $driver_type: String!
    $driver_delivery_vat: Int
    $driver_delivery_wht: Int
    $driver_service_vat: Int
    $driver_service_wht: Int
    $driver_license_number: String
    $driver_license_exp: String
    $driver_car_brand: String
    $driver_display_pic: String
    $bank_account: [driverBankAccountArg]
    $branch: String
    $address: String
    $sub_district: String
    $district: String
    $province: String
    $postcode: String
){
    registerDriver(
        account_info_id: $account_info_id
        line_user_id: $line_user_id
        driver_name: $driver_name
        driver_phone_code: $driver_phone_code
        driver_phone_number: $driver_phone_number
        driver_enable: $driver_enable
        user_setting_truck_license_plate: $user_setting_truck_license_plate
        user_setting_truck_license_plate_back: $user_setting_truck_license_plate_back
        driver_status: $driver_status
        line_id: $line_id
        reserve_phone_code: $reserve_phone_code
        reserve_phone_number: $reserve_phone_number
        criminal_history: $criminal_history
        id_card_number: $id_card_number
        id_card_pic: $id_card_pic
        driver_card_pic: $driver_card_pic
        car_pic: $car_pic
        license_plate_pic: $license_plate_pic
        act_pic: $act_pic
        car_insurance_pic: $car_insurance_pic
        book_bank_pic: $book_bank_pic
        setting_truck_service: $setting_truck_service
        driver_type: $driver_type
        driver_delivery_vat: $driver_delivery_vat
        driver_delivery_wht: $driver_delivery_wht
        driver_service_vat: $driver_service_vat
        driver_service_wht: $driver_service_wht
        driver_license_number: $driver_license_number
        driver_license_exp: $driver_license_exp
        driver_car_brand : $driver_car_brand
        driver_display_pic : $driver_display_pic
        bank_account: $bank_account
        branch: $branch
        address: $address
        sub_district: $sub_district
        district: $district
        province: $province
        postcode: $postcode
    ){
        _id
        exist
    }
  }
`;