
import React from "react"
import styled from 'styled-components';
import {
  Row,
} from 'react-bootstrap';

export const Container = styled(Row)`
  & .card_table {
    margin-bottom: 0 !important;
  }
`;


export const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

export const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

export const TabContentContainer = styled.div`
  margin-top: 15px;

  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

export const HeaderContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 15px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

export const HeaderSubContainer = styled.div`
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 15px;
   margin-top: -14px;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        margin-right: 15px;
        padding: 22px 10px 15px;

        & a {
          font-size: 18px;
          cursor: pointer;
          & i {
            font-size: 18px;
            color: #000000;
          }
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
      }
    }
  }
`;

export const AutosuggestContainerAgent = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;



