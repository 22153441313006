import gql from 'graphql-tag';

export default gql`
mutation UpdatelazadaSendTrackPackageStatus(
    $barcode: String!
    $status: String!
    $statusDescription: String
    $statusDate: String
    $cod: String
){
    lazadaSendTrackPackageStatus(
        barcode: $barcode
        status: $status
        statusDescription: $statusDescription
        statusDate: $statusDate
        cod: $cod
    ){
       succeed
    }
  }
`;
