import gql from 'graphql-tag';
import { shipmentListFragment } from '../../../../graphql/query/shipmentList';

export default gql`
  query shipmentKAs($params: paramsShipmentKAs, $sort: sort, $page: Int, $showData: Int!) {
    shipmentKAs(params: $params, sort: $sort, page: $page, showData: $showData) {
      shipments {
        _id
        shipment_name
        shipment_status
        shipment_trip
        transporter
        transporter_name
        driver_user_id
        driver_phone_number
        driver_phone_code
        driver_name
        driver_number
        user_setting_truck_license_plate
        shipment_accept_cod
        shipment_total_cod
        shipment_cancel_cod
        lazada_order_type
        pick_up_date
        create_date
        shipment_number
        shipment_ref_id
        format_bookname
        confirm_delivery_status
        confirm_cod_status
        shipment_address {
          _id
          tag_name
          mode
          commodity {
            name
          }
        }
        tracking {
          tracking_detail_lazada {
            delivery {
              delivery_status
              cod_qr
              cod_cash
              cod_price
              paid_by_cod
              signature_date
              tranfer_cod_cash_date
              commodity {
                name
              }
            }
          }
        }
      }
      totalPage
      totalDocument
    }
  }
`;
