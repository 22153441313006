import React from 'react';
import { SubHeader, ModalConfirm } from '../../components';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
  Popover,
} from 'react-bootstrap';
import CardTable from 'components/CardTable/CardTable.jsx';
import classes from './ShipmentList.module.scss';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import shipmentListQuery from './graphql/query/shipmentList';
import urijs from 'urijs';
import Moment from 'react-moment';
import assignAdminShipment from './graphql/mutation/assignAdminShipment';
import { client } from '../../../../index';
import adminListQuery from './graphql/query/adminList';
import driverUserListQuery from './graphql/query/driverUserList';
import userTruckListQuery from './graphql/query/userTruckList';
import settingTruckListQuery from './graphql/query/settingTruckList';
import ReactPaginate from 'react-paginate';
import shipmentsUpdateMutation from './graphql/mutation/shipmentsUpdate';
import Select from 'react-select';
import assignDriverMutation from './graphql/mutation/assignDriver';
import Autosuggest from 'react-autosuggest';
import reportUrl from 'config/reportUrl';
import assignAgentMutation from './graphql/mutation/assignAgent';
import accountInfoListQuery from './graphql/query/accountInfoList';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import customerId from 'config/customerId';
import ClearShipmentDriverModal from './components/ClearShipmentDriverModal'

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;
const Tag = styled.div`
  background-color: #e5ffe5;
  color: #5dba5b;
  font-weight: bold;
  padding: 2px 5px;
  margin-left: 30px;
  border-radius: 4px;
`;
const Line = styled.div`
  height: 36px;
  width: 0px;
  margin: 0px 20px;
  border-left: 1px solid #e3e3e3;
`;
const MenuWrapper = styled.div`
  width: 100%;
  & ul {
    padding-left: 0;
    margin: 0;
    display: flex;

    & li {
      list-style: none;
      margin-right: 15px;
      padding: 22px 10px 15px;

      & a {
        font-size: 18px;
        cursor: pointer;
        color: #000000;
        & i {
          font-size: 18px;
          color: #000000;
        }
      }
    }
    & li.active {
      border-bottom: 2px solid #d90402;
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const NavItemDropDownList = styled(NavItem)``;
const AutosuggestContainer = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  {
    value: 'ลูกค้าต้องการใช้บริการเสริม',
    label: 'ลูกค้าต้องการใช้บริการเสริม',
  },
  { value: 'เลือกใช้บริการเจ้าอื่น', label: 'เลือกใช้บริการเจ้าอื่น' },
  { value: 'อื่นๆ', label: 'อื่นๆ' },
];

class ShipmentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order_by: null,
      order_type: null,
      shipmentName: '',
      tripNumber: '',
      shipment_number: '',
      tagName: '',
      commodityName: '',
      pickupDate: '',
      displayPickupDate: '',
      shipmentStatus: 0,
      shipmentRunningNumber: '',
      admin: '',
      createDate: '',
      displayCreateDate: '',
      pageNumber: 1,
      lazadaImportOrderId: null,
      lazadaImportOrderName: '',
      lazadaImportOrderNumber: '',
      lazadaImportOrderPickupDate: null,

      //ค้นหา driver
      driverUserListSuggestions: [],
      driverName: '',
      driverId: '',
      setting_truck_type_id: '',

      //assign admin
      isOpenModalAssignAdmin: false,
      shipmentID: '',
      shipmentNumber: '',
      adminName: '',

      //cancel shipment
      rejectDetail: '',
      isShowRejectDetail: false,
      rejectSelected: null,
      rejectRemark: '',
      isOpenModalConfirmCancel: false,

      //assign driver
      driverUserId: '',
      userSettingTruckId: '',
      isOpenModalAssignDriver: false,
      driverSelected: '',
      truckSelected: '',
      DriverSuggestions: [],
      TruckSuggestions: [],
      driverUserSelected: null,

      fileNameBookingFile: '',
      isOpenModalConfirmDownloadReport: false,
      idBookingFile: null,

      //assign agent
      agentSelected: null,
      agentId: null,
      isOpenModalAssignAgent: false,

      OrderNumber: '',
      lazada_import_order_id: null,

      //clear shipment modal
      isShowClearShipmentModal: false,
      shipmentTripClearModal: '',
      shipmentIdClearModal: '',
      agentIdClearModal: ''
    };
  }

  componentDidMount() {
    // this.fetchDataLazadaImportOrderList();
  }

  fetchDataLazadaImportOrderList = async () => {
    try {
      const id = this.state.lazadaImportOrderId;

      if (!id) {
        return;
      }

      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          lazada_import_order_id: id,
          account_info_id: customerId.lazada,
        },
      });

      const { order } = data.lazadaImportOrderList;

      if (order.length === 1) {
        const { _id, pick_up_date, name, format_bookname } = order[0];
        this.setState({
          lazadaImportOrderName: name,
          lazadaImportOrderNumber: format_bookname,
          lazadaImportOrderPickupDate: pick_up_date,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  getIdFromUrl = () => {
    const id = urijs(window.location.href).query(true).id;
    return id;
  };

  renderStatus(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="default">รอขนส่งรับงาน</Label>;
      case 10:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 3:
        return <Label bsStyle="primary">กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      case 6:
        return <Label bsStyle="warning">งานเกิดปัญหา</Label>;
      case 7:
        return <Label bsStyle="default">แบบร่าง</Label>;
      case 8:
        return <Label bsStyle="primary">นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label bsStyle="primary">กำลังคืนคลัง</Label>;
      default:
        return '';
    }
  }

  renderLazadaSendFinalStatus(status, key) {
    switch (status) {
      case 1:
        return (
          <Label bsStyle="warning">
            <i className="fas fa-spinner"></i>
          </Label>
        );
      case 2:
        return (
          <Label bsStyle="success">
            <i className="fas fa-check"></i>
          </Label>
        );
      default:
        return (
          <Label bsStyle="danger">
            <i className="fas fa-times"></i>
          </Label>
        );
    }
  }

  handleDateRangePicker(mode, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({ pickupDate: date, displayPickupDate: display });
    }
  }

  handleShowAssignAdmin = async (shipmentID, shipmentNumber) => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      this.setState({
        adminName: admin[0].admin_username,
        isOpenModalAssignAdmin: true,
        shipmentID: shipmentID,
        shipmentNumber: shipmentNumber,
      });
    } catch (error) {
      alert(error);
    }
  };

  renderViewDriver(driverId, driverList) {
    if (driverId === null) {
      return '-';
    }

    const driver = driverList.filter(item => item._id === driverId);
    if (driver.length > 0) {
      return `${driver[0].driver_name}, ${driver[0].driver_phone_code} ${driver[0].driver_phone_number}`;
    }
  }

  renderViewTruck(userSettingTruckId, userTruckList) {
    if (userSettingTruckId === null) {
      return '-';
    }

    const truck = userTruckList.filter(item => item._id === userSettingTruckId);
    if (truck.length > 0) {
      return `${truck[0].user_setting_truck_license_plate}`;
    }
  }

  handleChangeReject(event) {
    this.setState({
      rejectSelected: event,
      rejectRemark: event.value,
    });

    if (event.value === 'อื่นๆ') {
      this.setState({ isShowRejectDetail: true });
    } else {
      this.setState({ isShowRejectDetail: false });
    }
  }

  handleCancelShipment = async (shipmentID, shipmentsUpdate) => {
    try {
      this.setState({ isLoading: true });
      const { shipmentID } = this.state;
      const { data } = await client.query({
        query: shipmentListQuery,
        variables: {
          _id: shipmentID,
        },
        fetchPolicy: 'network-only',
      });

      const { shipment } = data.shipmentList;
      if (
        shipment[0].shipment_status === 4 ||
        shipment[0].shipment_status === 5 ||
        shipment[0].shipment_status === 8
      ) {
        this.setState({ isLoading: false, isOpenModalConfirmCancel: false });
        alert('ไม่สามารถยกเลิกรายการได้');
        return;
      }

      shipmentsUpdate();
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmCancel: false,
      });

      alert(error);
    }
  };

  handleAssignDriver = shipment => {
    const { _id, shipment_number, driver_user_id, user_setting_truck_id } = shipment;
    const shipmentNumber = shipment_number;

    if (driver_user_id) {
      this.setState(
        {
          isOpenModalAssignDriver: true,
          shipmentID: _id,
          shipmentNumber: shipmentNumber,
          driverUserId: driver_user_id,
          userSettingTruckId: user_setting_truck_id,
          driverSelected: `${shipment.driver_name} (0${shipment.driver_phone_number})`,
          truckSelected: shipment.user_setting_truck_license_plate,
          agentSelected: {
            value: shipment.transporter,
            label: shipment.transporter_name,
          },
          agentId: shipment.transporter || null,
          lazadaImportOrderId: shipment.lazada_import_order_id,
        },
        () => this.fetchDataLazadaImportOrderList()
      );
    } else {
      this.setState(
        {
          isOpenModalAssignDriver: true,
          shipmentID: _id,
          shipmentNumber: shipmentNumber,
          driverUserId: driver_user_id,
          userSettingTruckId: user_setting_truck_id,
          driverSelected: '',
          truckSelected: '',
          agentSelected: {
            value: shipment.transporter,
            label: shipment.transporter_name,
          },
          agentId: shipment.transporter || null,
          lazadaImportOrderId: shipment.lazada_import_order_id,
        },
        () => this.fetchDataLazadaImportOrderList()
      );
    }
  };

  setAgentData = async transporterId => {
    if (!transporterId) {
      return;
    }

    try {
      const { data: accountInfoListData } = await client.query({
        query: accountInfoListQuery,
        variables: {
          _id: transporterId,
        },
      });
      if (accountInfoListData.accountInfoList.account_info.length > 0) {
        const {
          _id,
          account_info_business_name,
        } = accountInfoListData.accountInfoList.account_info[0];

        this.setState({
          agentId: _id,
          agentSelected: { value: _id, label: account_info_business_name },
        });
      }
    } catch (error) {
      alert(error);
    }
  };

  handleAssignAgent = (shipment, agentList) => {
    const { _id, shipment_number, transporter } = shipment;
    const shipmentNumber = shipment_number;
    const agent = agentList.filter(item => item._id === transporter);
    this.clearDataSelectAgent();

    if (agent.length > 0) {
      this.setState({
        isOpenModalAssignAgent: true,
        shipmentID: _id,
        shipmentNumber: shipmentNumber,
        agentSelected: { value: agent[0]._id, label: agent[0].account_info_business_name },
      });
    } else {
      this.setState({
        isOpenModalAssignAgent: true,
        shipmentID: _id,
        shipmentNumber: shipmentNumber,
        agentId: transporter,
      });
    }
  };

  selectOptionDriverList = driverList => {
    return driverList.map(driver => {
      return {
        value: driver._id,
        label:
          driver.driver_name +
          ' (' +
          driver.driver_phone_code +
          '-' +
          driver.driver_phone_number +
          ')',
        user_setting_truck_id: driver.user_setting_truck_id,
        user_setting_truck_license_plate: driver.user_setting_truck_license_plate,
      };
    });
  };

  selectOptionTruckList = userTruckList => {
    return userTruckList.map(truck => {
      return {
        value: truck._id,
        label: truck.user_setting_truck_license_plate,
      };
    });
  };

  selectOptionAgentList = agentList => {
    return agentList.map(agent => {
      return {
        value: agent._id,
        label: agent.account_info_business_name,
      };
    });
  };

  handleChangeDriver(driver, driverList) {
    this.setState(
      {
        driverSelected: driver,
        driverUserId: driver.value,
        truckSelected: {
          value: driver.user_setting_truck_id,
          label: driver.user_setting_truck_license_plate,
        },
        userSettingTruckId: driver.user_setting_truck_id,
      },
      () => {
        this.clearDataSelectAgent();
        const driverFilter = driverList.filter(item => item._id === driver.value);
        this.setAgentData(driverFilter[0].account_info_id);
      }
    );
  }

  handleChangeAgent = agent => {
    console.log('handleChangeAgent', agent);
    this.setState({ agentSelected: agent, agentId: agent.value });
  };

  handleChangeTruck(truck) {
    this.setState({ truckSelected: truck, userSettingTruckId: truck.value });
  }

  getSuggestions = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();

    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
        },
      });

      const driverUserListData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: e.driver_name,
        };
      });

      this.setState({
        driverUserListSuggestions: driverUserListData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      driverUserListSuggestions: [],
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value);
  };

  getSuggestionValue = suggestion => suggestion.name;

  renderSuggestion = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  shouldRenderSuggestions() {
    return true;
  }

  downloadReportHandler = async () => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const { idBookingFile } = this.state;
      const adminId = admin[0]._id;
      const url = `${reportUrl}/lazada-summary?id=${idBookingFile}&admin_id=${adminId}`;
      window.open(url, '_blank');
      this.setState({
        isOpenModalConfirmDownloadReport: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  formatMoney = number => {
    return `฿ ${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    })}`;
  };

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  clearDataSelectAgent = () => {
    this.setState({
      agentSelected: null,
      agentId: null,
    });
  };

  //Autosuggest Driver
  getSuggestionsDriver = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: driverUserListQuery,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
        },
      });

      const driverData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: `${e.driver_name} (0${e.driver_phone_number})`,
          user_setting_truck_id: e.user_setting_truck_id ? e.user_setting_truck_id : '',
          user_setting_truck_license_plate: e.user_setting_truck_license_plate
            ? e.user_setting_truck_license_plate
            : '',
          driver_name: e.driver_name || '',
          driver_phone: e.driver_phone_number || '',
          driver_phone_code: e.driver_phone_code || '',
          setting_truck_id: e.setting_truck_service[0] || '',
          driver_agent_detail: {
            account_info_id: e.driver_agent_detail[0]?.account_info_id || '',
            agent_name: e.driver_agent_detail[0]?.agent_name || '',
          },
        };
      });

      this.setState({
        DriverSuggestions: driverData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueDriver = suggestion => suggestion.name;

  renderSuggestionDriver = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedDriver = ({ value }) => {
    this.getSuggestionsDriver(value);
  };

  onSuggestionsClearRequestedDriver = () => {
    this.setState({
      DriverSuggestions: [],
    });
  };

  shouldRenderSuggestionsDriver() {
    return true;
  }
  //End Autosuggest Driver

  //Autosuggest Truck
  getSuggestionsTruck = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: userTruckListQuery,
        fetchPolicy: 'network-only',
        variables: {
          user_setting_truck_license_plate: inputValue,
        },
      });

      console.log('userSettingTruckList', data.userSettingTruckList.user_setting_truck);

      const driverData = data.userSettingTruckList.user_setting_truck.map(e => {
        return {
          id: e._id,
          name: e.user_setting_truck_license_plate,
        };
      });

      this.setState({
        TruckSuggestions: driverData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTruck = suggestion => suggestion.name;

  renderSuggestionTruck = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTruck = ({ value }) => {
    this.getSuggestionsTruck(value);
  };

  onSuggestionsClearRequestedTruck = () => {
    this.setState({
      TruckSuggestions: [],
    });
  };

  shouldRenderSuggestionsTruck() {
    return true;
  }
  //End Autosuggest Truck

  getIdFromUrl = () => {
    return urijs(window.location.href).query(true).id;
  };
  render() {
    let driverList = [];
    if (
      this.props.driverUserListQuery.loading === false &&
      this.props.driverUserListQuery.driverUserList !== undefined
    ) {
      driverList = this.props.driverUserListQuery.driverUserList.driver_user;
    }

    let userTruckList = [];
    if (
      this.props.userTruckListQuery.loading === false &&
      this.props.userTruckListQuery.userSettingTruckList !== undefined
    ) {
      userTruckList = this.props.userTruckListQuery.userSettingTruckList.user_setting_truck;
    }

    let agentList = [];
    if (
      this.props.accountInfoListQuery.loading === false &&
      this.props.accountInfoListQuery.accountInfoList !== undefined
    ) {
      agentList = this.props.accountInfoListQuery.accountInfoList.account_info;
    }

    return (
      <div className="content">
        <Query
          query={shipmentListQuery}
          variables={{
            page_number: this.state.pageNumber,
            order_by: this.state.order_by,
            order_type: this.state.order_type,
            shipment_trip: this.state.tripNumber,
            shipment_name: this.state.shipmentName,
            tag_name: this.state.tagName,
            pick_up_date: this.state.pickupDate,
            shipment_status: this.state.shipmentStatus,
            shipment_running_number: this.state.shipmentRunningNumber,
            admin: this.state.admin,
            create_date: this.state.createDate,
            shipment_number: this.state.shipment_number,
            driver_user_id: this.state.driverId,
            commodity_name: this.state.commodityName,
            commodity_order_number: this.state.OrderNumber,
            lazada_import_order_id: this.getIdFromUrl(),
            setting_truck_type_id: this.state.setting_truck_type_id,
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error, refetch }) => {
            let shipmentList = [];
            let pickupDate = '';
            let totalPage = 0;

            if (!loading && !error) {
              shipmentList = [...data.shipmentList.shipment];
              totalPage = data.shipmentList.total_page;
            }

            var itemLength = 'loading';
            const itemIndicatorSize = (this.state.pageNumber - 1) * 30;
            if (data.shipmentList) {
              if (data.shipmentList.shipment.length > 0) {
                itemLength = data.shipmentList.shipment.length;
              }
            }

            return (
              <>
                <SubHeader style={{ marginTop: '-15px' }} backUrl={`/lazada`}>
                  <div className={classes.header}>
                    <div className={classes.title}>
                      <div style={{ width: 'max-content' }}>
                        <strong>ตารางรายการงานขนส่ง</strong>
                        {this.getIdFromUrl() && this.state.lazadaImportOrderPickupDate && (
                          <span>
                            ประจำวันที่{' '}
                            <Moment format="DD/MM/YYYY HH:mm:ss">
                              {this.state.lazadaImportOrderPickupDate}
                            </Moment>
                          </span>
                        )}
                      </div>
                      {this.getIdFromUrl() && (
                        <div className={classes.small}>{this.state.lazadaImportOrderName}</div>
                      )}
                    </div>
                    {
                      // <Tag>Normal</Tag>
                    }
                    <Line />
                    <MenuWrapper>
                      <ul>
                        {this.getIdFromUrl() ? (
                          <li>
                            <a href={`/lazada-shipment-agent?id=${this.getIdFromUrl()}`}>
                              ดูตาม Agent
                            </a>
                          </li>
                        )
                        :
                        (
                          <li>
                            <a href={`/lazada-shipment-agent`}>
                              ดูตาม Agent
                            </a>
                          </li>
                        )
                        }
                        <li className="active">
                          {' '}
                          {this.getIdFromUrl() ? (
                            <a href={`/lazada-shipment-list?id=${this.getIdFromUrl()}`}>
                              ดูตามงานขนส่ง
                            </a>
                          ) : (
                            <a href={`/lazada-shipment-list`}>ดูตามงานขนส่ง</a>
                          )}
                        </li>
                      </ul>
                    </MenuWrapper>
                    <div className={classes.btnContainer}>
                      {/* <Button bsStyle="warning" disabled>
                        <i className="fal fa-usd-circle"></i> แจ้งโอนให้ Lazada
                      </Button> */}
                      {this.getIdFromUrl() && (
                        <Button
                          onClick={() => {
                            this.setState({
                              isOpenModalConfirmDownloadReport: true,
                              fileNameBookingFile: this.state.lazadaImportOrderName,
                              idBookingFile: this.state.lazadaImportOrderId,
                            });
                          }}
                        >
                          <i className="fal fa-file-alt"></i> รายงานงานขนส่ง
                        </Button>
                      )}
                    </div>
                  </div>
                </SubHeader>
                <Grid fluid>
                  <div>
                    <Row>
                      <Col md={12}>
                        <div>
                          <CardTable
                            ctTableFullWidth
                            ctTableResponsive
                            content={
                              <div>
                                <PageWrapper style={{ textAlign: 'right' }}>
                                  <div className="text">
                                    {this.state.pageNumber === 1
                                      ? `1 - ${itemLength}`
                                      : `${itemIndicatorSize + 1} - ${itemLength +
                                          itemIndicatorSize}`}{' '}
                                    หน้า :
                                  </div>
                                  <FormControl
                                    type="number"
                                    min={1}
                                    value={this.state.pageNumber}
                                    onChange={event =>
                                      Number(event.target.value) >= 0
                                        ? setInterval(
                                            this.setState({
                                              pageNumber: Number(event.target.value),
                                            }),
                                            1000
                                          )
                                        : setInterval(this.setState({ pageNumber: 1 }), 1000)
                                    }
                                    name="page_number"
                                  />
                                  <div className="text">
                                    {' '}
                                    {loading || error ? '' : `จาก ${data.shipmentList.total_page}`}
                                  </div>
                                </PageWrapper>
                                <div className="table-vertical">
                                  <Table striped hover>
                                    <thead>
                                      <tr>
                                        <th style={{ minWidth: '200px' }}>เครื่องมือ</th>
                                        <th
                                          style={{ minWidth: '150px' }}
                                          className={this.renderSorting('shipment_trip')}
                                          onClick={() => this.sort('shipment_trip', refetch)}
                                        >
                                          Trip No.
                                        </th>
                                        <th
                                          style={{ minWidth: '140px' }}
                                          className={this.renderSorting('shipment_name')}
                                          onClick={() => this.sort('shipment_name', refetch)}
                                        >
                                          Trip Name
                                        </th>
                                        <th
                                          style={{ minWidth: '160px' }}
                                          className={this.renderSorting('pick_up_date')}
                                          onClick={() => this.sort('pick_up_date', refetch)}
                                        >
                                          วันที่รับสินค้า
                                        </th>
                                        <th style={{ minWidth: '160px' }}>
                                          สถานะ / ส่ง final_status
                                        </th>
                                        <th style={{ minWidth: '160px' }}>พนักงานขับรถ</th>
                                        <th style={{ minWidth: '100px' }}>เลขทะเบียน</th>
                                        <th style={{ minWidth: '100px' }}>ชื่อนายหน้า</th>
                                        <th style={{ minWidth: '100px' }}>จุดรับสินค้า</th>
                                        <th style={{ minWidth: '100px' }}>จุดส่งสินค้า</th>
                                        <th style={{ minWidth: '100px' }}>COD รับแล้ว</th>
                                        <th style={{ minWidth: '130px' }}>COD ยังไม่ได้รับ</th>
                                        <th style={{ minWidth: '100px' }}>COD ยกเลิก</th>
                                        <th style={{ minWidth: '100px' }}>COD ทั้งหมด</th>
                                        <th style={{ minWidth: '120px' }}>เลขรัน</th>
                                        <th style={{ minWidth: '160px' }}>หมายเลขงานขนส่ง</th>
                                        <th style={{ minWidth: '140px' }}>ผู้ดูแลงาน</th>
                                        <th style={{ minWidth: '140px' }}>ประเภทรถขนส่ง</th>
                                        <th style={{ minWidth: '160px' }}>วันที่จอง</th>
                                      </tr>
                                      <tr>
                                        <th></th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหา Trip No."
                                            value={this.state.tripNumber}
                                            onChange={event => {
                                              this.setState({
                                                tripNumber: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหา Trip Name"
                                            value={this.state.shipmentName}
                                            onChange={event => {
                                              this.setState({
                                                shipmentName: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th>
                                          <DateRangePicker
                                            onApply={(event, picker) =>
                                              this.handleDateRangePicker('pickupDate', picker)
                                            }
                                            onCancel={() =>
                                              this.setState({
                                                pickupDate: '',
                                                displayPickupDate: '',
                                                pageNumber: 1,
                                              })
                                            }
                                            containerStyles={{ display: 'block' }}
                                            opens="right"
                                            format="DD/MM/YYYY"
                                          >
                                            <FormControl
                                              type="text"
                                              value={this.state.displayPickupDate}
                                              placeholder="วันที่รับสินค้า"
                                            />
                                          </DateRangePicker>
                                        </th>
                                        <th>
                                          <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.shipmentStatus}
                                            onChange={event =>
                                              this.setState({
                                                shipmentStatus: event.target.value,
                                                page_number: 1,
                                              })
                                            }
                                          >
                                            <option value={0}>ทั้งหมด</option>
                                            <option value={1}>รอเพิ่มคนขับ</option>
                                            <option value={2}>รอขนส่งรับงาน</option>
                                            <option value={10}>รอเริ่มงาน</option>
                                            <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                            <option value={3}>กำลังดำเนินการขนส่ง</option>
                                            <option value={4}>เสร็จสิ้นแล้ว</option>
                                            <option value={5}>ถูกยกเลิก</option>
                                            <option value={6}>งานเกิดปัญหา</option>
                                            <option value={7}>แบบร่าง</option>
                                            <option value={9}>กำลังคืนคลัง</option>
                                            {/* new status */}
                                            <option value={11}>รอส่งสถานะ</option>
                                            <option value={12}>รอตรวจสอบสถานะ</option>
                                            <option value={13}>สถานะผ่าน</option>{' '}
                                            {/* สถานะผ่าน ถ้าไม่มี COD จะเป็น เสร็จสมบูรณ์ */}
                                            <option value={14}>สถานะไม่ผ่าน</option>
                                            <option value={15}>รอโอน COD</option>
                                            <option value={16}>รอตรวจสอบ COD</option>
                                            <option value={17}>เสร็จสมบูรณ์</option>
                                            <option value={18}>COD ไม่ผ่าน</option>
                                          </FormControl>
                                        </th>
                                        <th>
                                          <AutosuggestContainer>
                                            <Autosuggest
                                              suggestions={this.state.driverUserListSuggestions}
                                              onSuggestionsFetchRequested={
                                                this.onSuggestionsFetchRequested
                                              }
                                              onSuggestionsClearRequested={
                                                this.onSuggestionsClearRequested
                                              }
                                              getSuggestionValue={this.getSuggestionValue}
                                              renderSuggestion={this.renderSuggestion}
                                              focusInputOnSuggestionClick={false}
                                              inputProps={{
                                                placeholder: 'ค้นหา',
                                                value: this.state.driverName,
                                                onChange: event =>
                                                  this.setState({
                                                    driverName: event.target.value,
                                                    driverId: '',
                                                  }),
                                                type: 'text',
                                                className: 'form-control',
                                              }}
                                              onSuggestionSelected={(event, { suggestion }) => {
                                                this.setState({
                                                  driverId: suggestion.id,
                                                  driverName: suggestion.name,
                                                  pageNumber: 1,
                                                });
                                              }}
                                              shouldRenderSuggestions={this.shouldRenderSuggestions}
                                            />
                                          </AutosuggestContainer>
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหาจุดรับสินค้า"
                                            value={this.state.tagName}
                                            onChange={event => {
                                              this.setState({
                                                tagName: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหาเลข tracking"
                                            value={this.state.commodityName}
                                            onChange={event => {
                                              this.setState({
                                                commodityName: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหาเลขรัน"
                                            value={this.state.shipmentRunningNumber}
                                            onChange={event => {
                                              this.setState({
                                                shipmentRunningNumber: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหาหมายเลขงานขนส่ง"
                                            value={this.state.shipment_number}
                                            onChange={event => {
                                              this.setState({
                                                shipment_number: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th>
                                          <FormControl
                                            type="text"
                                            placeholder="ค้นหาหมายผู้ดูแลงาน"
                                            value={this.state.admin}
                                            onChange={event => {
                                              this.setState({
                                                admin: event.target.value,
                                                pageNumber: 1,
                                              });
                                            }}
                                          />
                                        </th>
                                        <th>
                                          <Query
                                            query={settingTruckListQuery}
                                            fetchPolicy="network-only"
                                          >
                                            {({ loading, error, data, refetch }) => {
                                              return (
                                                <FormControl
                                                  componentClass="select"
                                                  placeholder="select"
                                                  defaultValue={this.state.setting_truck_type_id}
                                                  onChange={event =>
                                                    this.setState(
                                                      {
                                                        setting_truck_type_id:
                                                          event.target.value == 0
                                                            ? null
                                                            : event.target.value,
                                                        page_number: 1,
                                                      },
                                                      () => {
                                                        refetch();
                                                      }
                                                    )
                                                  }
                                                  name="setting_truck_type_id"
                                                >
                                                  <option value={0}>ทั้งหมด</option>
                                                  {!loading &&
                                                    !error &&
                                                    data.settingTruckList.map((truck, index) => (
                                                      <optgroup
                                                        label={`รถ ${truck.setting_truck_name.th}`}
                                                      >
                                                        {truck.setting_truck_type
                                                          .filter(item => item.truck_type_enable)
                                                          .map((item, key, arr) => (
                                                            <option value={item._id}>
                                                              {`${truck.setting_truck_name.th} - ${item.truck_type_name.th}` ||
                                                                undefined}
                                                            </option>
                                                          ))}
                                                      </optgroup>
                                                    ))}
                                                </FormControl>
                                              );
                                            }}
                                          </Query>
                                        </th>
                                        <th>
                                          <DateRangePicker
                                            onApply={(event, picker) =>
                                              this.handleDateRangePicker('createDate', picker)
                                            }
                                            onCancel={() =>
                                              this.setState({
                                                createDate: '',
                                                displayCreateDate: '',
                                                pageNumber: 1,
                                              })
                                            }
                                            containerStyles={{ display: 'block' }}
                                            opens="right"
                                            format="DD/MM/YYYY"
                                          >
                                            <FormControl
                                              type="text"
                                              value={this.state.displayCreateDate}
                                              placeholder="วันที่จอง"
                                            />
                                          </DateRangePicker>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading && (
                                        <tr>
                                          <td colSpan="15">Loading...</td>
                                        </tr>
                                      )}

                                      {error && (
                                        <tr>
                                          <td colSpan="15">Error</td>
                                        </tr>
                                      )}

                                      {!loading && !error && shipmentList.length === 0 && (
                                        <tr>
                                          <td colSpan="15">ไม่มีข้อมูล</td>
                                        </tr>
                                      )}

                                      {shipmentList.map((item, index) => (
                                        <tr>
                                          <td>
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}
                                            >
                                              <a
                                                href={`/lazada-shipment-detail?id=${item._id}`}
                                                target="_blank"
                                                style={{ float: 'left' }}
                                                className="btn btn-default"
                                              >
                                                <i
                                                  style={{ marginTop: '3px' }}
                                                  className="fas fa-eye"
                                                ></i>
                                              </a>
                                            </OverlayTrigger>
                                            {item.shipment_status !== 4 &&
                                              item.shipment_status !== 5 && (
                                                <NavDropdownContainer
                                                  title={
                                                    <Button>
                                                      เครื่องมือ{' '}
                                                      <i className="fal fa-angle-down"></i>
                                                    </Button>
                                                  }
                                                >
                                                  <NavItemDropDownList
                                                    onClick={() =>
                                                      this.handleAssignAgent(item, agentList)
                                                    }
                                                  >
                                                    <i className="fas fa-user-tie"></i>{' '}
                                                    เปลี่ยนนายหน้า
                                                  </NavItemDropDownList>
                                                  <NavItemDropDownList
                                                    onClick={() => this.handleAssignDriver(item)}
                                                  >
                                                    <i className="fas fa-truck"></i> เพิ่มคนขับ
                                                  </NavItemDropDownList>
                                                  {false && item.shipment_status == 10 && item.driver_user_id &&
                                                    <NavItemDropDownList
                                                      onClick={() => 
                                                        this.setState({
                                                          isShowClearShipmentModal: true, 
                                                          shipmentTripClearModal: item.shipment_trip ,
                                                          shipmentIdClearModal: item._id,
                                                          agentIdClearModal: item.transporter
                                                        })}
                                                    >
                                                      <i className="fas fa-history"></i> คืนค่าเริ่มต้น
                                                    </NavItemDropDownList>
                                                  }
                                                  {item.shipment_status !== 4 &&
                                                    item.shipment_status !== 5 && (
                                                      <NavItemDropDownList
                                                        onClick={() => {
                                                          this.setState({
                                                            isOpenModalConfirmCancel: true,
                                                            shipmentID: item._id,
                                                            shipmentNumber: item.shipment_number,
                                                            rejectSelected: null,
                                                            rejectRemark: '',
                                                            rejectDetail: '',
                                                          });
                                                        }}
                                                      >
                                                        <i className="fas fa-times"></i> ยกเลิก
                                                      </NavItemDropDownList>
                                                    )}
                                                </NavDropdownContainer>
                                              )}
                                          </td>
                                          <td>{item.shipment_trip}</td>
                                          <td>{item.shipment_name}</td>
                                          <td>
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {item.pick_up_date}
                                            </Moment>
                                          </td>
                                          <td>
                                            {this.renderStatus(item.shipment_status)}{' '}
                                            {item.lazada_order_type == 1 &&
                                              this.renderLazadaSendFinalStatus(
                                                item.lazada_send_final_status,
                                                index
                                              )}
                                          </td>
                                          <td>
                                            {item.driver_name
                                              ? <span><b>{`#${item.driver_number},`}</b> {`${item.driver_name}, ${item.driver_phone_code} ${item.driver_phone_number}`}</span>
                                              : '-'}
                                          </td>
                                          <td>{item.user_setting_truck_license_plate || '-'}</td>
                                          <td>{item.transporter_name}</td>
                                          <td>{item.shipment_address[0].tag_name}</td>
                                          <td>
                                            <OverlayTrigger
                                              trigger="hover"
                                              key="bottom"
                                              placement="bottom"
                                              overlay={
                                                <Popover
                                                  id={`popover-positioned-${item._id}`}
                                                  title={`จุดส่งสินค้าทั้งหมด ${
                                                    item.shipment_address.filter(
                                                      shipmentAddress =>
                                                        shipmentAddress.mode === 'ส่งสินค้า'
                                                    ).length
                                                  } จุด `}
                                                >
                                                  {item.shipment_address
                                                    .filter(
                                                      shipmentAddress =>
                                                        shipmentAddress.mode === 'ส่งสินค้า'
                                                    )
                                                    .map((address, key) => (
                                                      <p>
                                                        <strong>{`จุดที่ ${key + 1} `}</strong>
                                                        {`${address.commodity[0].name}`}{' '}
                                                      </p>
                                                    ))}
                                                </Popover>
                                              }
                                            >
                                              <b style={{ cursor: 'pointer' }}>
                                                {`${
                                                  item.shipment_address.filter(
                                                    shipmentAddress =>
                                                      shipmentAddress.mode === 'ส่งสินค้า'
                                                  ).length
                                                } จุดส่ง`}
                                              </b>
                                            </OverlayTrigger>
                                          </td>
                                          <td>{this.formatMoney(item.shipment_accept_cod)}</td>
                                          <td>
                                            {this.formatMoney(
                                              item.shipment_total_cod -
                                                item.shipment_cancel_cod -
                                                item.shipment_accept_cod
                                            )}
                                          </td>
                                          <td>{this.formatMoney(item.shipment_cancel_cod)}</td>
                                          <td>{this.formatMoney(item.shipment_total_cod)}</td>
                                          <td>{item.shipment_running_number}</td>
                                          <td>{item.shipment_number}</td>
                                          <td>
                                            {item.admin ? (
                                              item.admin
                                            ) : (
                                              <Button
                                                className="link hover-blue"
                                                onClick={() =>
                                                  this.handleShowAssignAdmin(
                                                    item._id,
                                                    item.shipment_number
                                                  )
                                                }
                                              >
                                                <i className="fal fa-smile"></i> ดูแลงานนี้
                                              </Button>
                                            )}
                                          </td>
                                          <td>
                                            {`${item.setting_truck_name.th} ${item.truck_type_name.th}`}
                                          </td>
                                          <td>
                                            <Moment format="DD/MM/YYYY HH:mm:ss">
                                              {item.create_date}
                                            </Moment>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                                <div style={{ textAlign: 'right', marginTop: 20 }}>
                                  {!loading && !error && (
                                    <ReactPaginate
                                      pageCount={totalPage}
                                      forcePage={this.state.pageNumber - 1}
                                      containerClassName="pagination"
                                      nextLabel=">"
                                      previousLabel="<"
                                      onPageChange={({ selected }) => {
                                        this.setState({
                                          pageNumber: selected + 1,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            }
                          />
                        </div>

                        <Mutation
                          mutation={assignAdminShipment}
                          variables={{
                            shipmentID: this.state.shipmentID,
                            admin_name: this.state.adminName,
                          }}
                          update={() => {
                            this.setState({ isOpenModalAssignAdmin: false });
                          }}
                          refetchQueries={() => ['LazadaShipmentList']}
                        >
                          {assignAdminShipment => (
                            <Modal
                              show={this.state.isOpenModalAssignAdmin}
                              onHide={() => this.setState({ isOpenModalAssignAdmin: false })}
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>รับดูแลงาน {this.state.shipmentNumber}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>กด "ตกลง" เพื่อรับดูแลงานนี้</Modal.Body>
                              <Modal.Footer>
                                <Button
                                  style={{ float: 'left' }}
                                  onClick={() =>
                                    this.setState({
                                      isOpenModalAssignAdmin: false,
                                    })
                                  }
                                >
                                  กลับ
                                </Button>
                                <Button bsStyle="primary" onClick={() => assignAdminShipment()}>
                                  ตกลง
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>

                        <Mutation
                          mutation={shipmentsUpdateMutation}
                          variables={{
                            _id: this.state.shipmentID,
                            shipment_status: 5,
                            cancel_comment:
                              this.state.rejectRemark === 'อื่นๆ'
                                ? this.state.rejectDetail
                                : this.state.rejectRemark,
                          }}
                          update={() => {
                            this.setState({
                              isLoading: false,
                              isOpenModalConfirmCancel: false,
                            });
                          }}
                          refetchQueries={() => ['LazadaShipmentList']}
                        >
                          {shipmentsUpdate => (
                            <Modal
                              show={this.state.isOpenModalConfirmCancel}
                              onHide={() =>
                                this.setState({
                                  isOpenModalConfirmCancel: false,
                                })
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>
                                  ยกเลิกงานขนส่ง {this.state.shipmentNumber}
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col md={12}>
                                    <b>
                                      เหตุผลที่ยกเลิกงาน{' '}
                                      <small style={{ color: '#D90101' }}>*</small>
                                    </b>
                                  </Col>
                                </Row>

                                <Select
                                  value={this.state.rejectSelected}
                                  onChange={event => this.handleChangeReject(event)}
                                  options={rejectList}
                                  placeholder="เลือกเหตุผลที่ยกเลิกงาน"
                                />

                                {this.state.isShowRejectDetail && (
                                  <FormControl
                                    style={{ marginTop: '10px' }}
                                    type="text"
                                    value={this.state.rejectDetail}
                                    placeholder="ระบุ"
                                    onChange={event =>
                                      this.setState({
                                        rejectDetail: event.target.value,
                                      })
                                    }
                                  />
                                )}

                                <Row style={{ marginTop: '10px' }}>
                                  <Col md={12}>คุณต้องการยกเลิกงานขนส่งนี้ให้กด "ตกลง"</Col>
                                </Row>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  style={{ float: 'left' }}
                                  onClick={() =>
                                    this.setState({
                                      isOpenModalConfirmCancel: false,
                                    })
                                  }
                                >
                                  กลับ
                                </Button>
                                <Button
                                  bsStyle="primary"
                                  disabled={
                                    this.state.isLoading ||
                                    this.state.rejectRemark === '' ||
                                    (this.state.rejectRemark === 'อื่นๆ' &&
                                      this.state.rejectDetail === '')
                                  }
                                  onClick={() =>
                                    this.handleCancelShipment(
                                      this.state.shipmentID,
                                      shipmentsUpdate
                                    )
                                  }
                                >
                                  ตกลง
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>

                        <Mutation
                          mutation={assignAgentMutation}
                          variables={{
                            shipment_id: this.state.shipmentID,
                            driver_user_id: this.state.driverUserId,
                            transporter: this.state.agentId,
                          }}
                          update={() => {
                            this.setState({ isOpenModalAssignDriver: false });
                          }}
                          refetchQueries={() => ['LazadaShipmentList']}
                        >
                          {assignAgent => (
                            <Mutation
                              mutation={assignDriverMutation}
                              variables={{
                                shipment_id: this.state.shipmentID,
                                driver_user_id: this.state.driverUserId,
                                user_setting_truck_id: this.state.userSettingTruckId,
                                transporter: this.state.agentId,
                                lazada_import_order_id: this.state.lazadaImportOrderId,
                                book_number: this.state.lazadaImportOrderNumber,
                                driver_name: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.driver_name
                                  : '',
                                driver_phone: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.driver_phone
                                  : '',
                                driver_phone_code: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.driver_phone_code
                                  : '',
                                name_agent: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.agent_name
                                  : this.state.agentId
                                  ? this.state.agentSelected.label
                                  : '',
                                truck_license: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.user_setting_truck_license_plate
                                  : '',
                                truck_type_id: this.state.driverUserSelected
                                  ? this.state.driverUserSelected.setting_truck_id
                                  : '',
                              }}
                              update={() => {
                                assignAgent();
                              }}
                              refetchQueries={() => ['LazadaShipmentList']}
                            >
                              {assignDriver => (
                                <Modal
                                  show={this.state.isOpenModalAssignDriver}
                                  onHide={() =>
                                    this.setState({
                                      isOpenModalAssignDriver: false,
                                    })
                                  }
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      แก้ไขคนขับรถ {this.state.shipmentNumber}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <Row style={{ marginBottom: '10px' }}>
                                      <Col md={12}>คนขับรถ</Col>
                                    </Row>

                                    <AutosuggestContainer>
                                      <Autosuggest
                                        suggestions={this.state.DriverSuggestions}
                                        onSuggestionsFetchRequested={
                                          this.onSuggestionsFetchRequestedDriver
                                        }
                                        onSuggestionsClearRequested={
                                          this.onSuggestionsClearRequestedDriver
                                        }
                                        getSuggestionValue={this.getSuggestionValueDriver}
                                        renderSuggestion={this.renderSuggestionDriver}
                                        focusInputOnSuggestionClick={false}
                                        inputProps={{
                                          placeholder: 'เลือกคนขับรถ',
                                          value: this.state.driverSelected,
                                          onChange: event =>
                                            this.setState({
                                              driverSelected: event.target.value,
                                              driverUserId: null,
                                            }),
                                          type: 'text',
                                          className: 'form-control',
                                        }}
                                        onSuggestionSelected={(event, { suggestion }) => {
                                          if (this.state.agentId != null) {
                                            this.setState({
                                              driverUserId: suggestion.id,
                                              driverSelected: suggestion.name,
                                              userSettingTruckId: suggestion.user_setting_truck_id,
                                              truckSelected:
                                                suggestion.user_setting_truck_license_plate,
                                              driverUserSelected: suggestion,
                                            });
                                          } else {
                                            this.setState({
                                              driverUserId: suggestion.id,
                                              driverSelected: suggestion.name,
                                              userSettingTruckId: suggestion.user_setting_truck_id,
                                              truckSelected:
                                                suggestion.user_setting_truck_license_plate,
                                              driverUserSelected: suggestion,
                                              agentSelected: {
                                                label: suggestion.driver_agent_detail.agent_name,
                                                value:
                                                  suggestion.driver_agent_detail.account_info_id,
                                              },
                                              agentId:
                                                suggestion.driver_agent_detail.account_info_id,
                                            });
                                          }
                                        }}
                                        shouldRenderSuggestions={this.shouldRenderSuggestionsDriver}
                                      />
                                    </AutosuggestContainer>

                                    <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                      <Col md={12}>ทะเบียนรถ</Col>
                                    </Row>
                                    <AutosuggestContainer>
                                      <Autosuggest
                                        suggestions={this.state.TruckSuggestions}
                                        onSuggestionsFetchRequested={
                                          this.onSuggestionsFetchRequestedTruck
                                        }
                                        onSuggestionsClearRequested={
                                          this.onSuggestionsClearRequestedTruck
                                        }
                                        getSuggestionValue={this.getSuggestionValueTruck}
                                        renderSuggestion={this.renderSuggestionTruck}
                                        focusInputOnSuggestionClick={false}
                                        inputProps={{
                                          placeholder: 'เลือกทะเบียนรถ',
                                          value: this.state.truckSelected,
                                          onChange: event =>
                                            this.setState({
                                              truckSelected: event.target.value,
                                              userSettingTruckId: null,
                                            }),
                                          type: 'text',
                                          className: 'form-control',
                                        }}
                                        onSuggestionSelected={(event, { suggestion }) => {
                                          this.setState({
                                            userSettingTruckId: suggestion.id,
                                            truckSelected: suggestion.name,
                                          });
                                        }}
                                        shouldRenderSuggestions={this.shouldRenderSuggestionsTruck()}
                                      />
                                    </AutosuggestContainer>

                                    <Row
                                      style={{
                                        marginBottom: '10px',
                                        marginTop: '20px',
                                      }}
                                    >
                                      <Col md={12}>นายหน้า</Col>
                                    </Row>

                                    <Select
                                      disabled={this.state.agentId != null}
                                      value={this.state.agentSelected}
                                      onChange={event => this.handleChangeAgent(event)}
                                      options={this.selectOptionAgentList(agentList)}
                                      placeholder="เลือกนายหน้า"
                                    />
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      bsStyle="primary"
                                      onClick={() => assignDriver()}
                                      disabled={
                                        !this.state.driverSelected ||
                                        !this.state.truckSelected ||
                                        !this.state.agentSelected
                                      }
                                    >
                                      บันทึก
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              )}
                            </Mutation>
                          )}
                        </Mutation>

                        <Mutation
                          mutation={assignAgentMutation}
                          variables={{
                            shipment_id: this.state.shipmentID,
                            driver_user_id: this.state.driverUserId,
                            transporter: this.state.agentId,
                          }}
                          update={() => {
                            this.setState({ isOpenModalAssignAgent: false });
                          }}
                          refetchQueries={() => ['LazadaShipmentList']}
                        >
                          {assignAgent => (
                            <Modal
                              show={this.state.isOpenModalAssignAgent}
                              onHide={() =>
                                this.setState({
                                  isOpenModalAssignAgent: false,
                                })
                              }
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>แก้ไขนายหน้า {this.state.shipmentNumber}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Row style={{ marginBottom: '10px' }}>
                                  <Col md={12}>นายหน้า</Col>
                                </Row>

                                <Select
                                  value={this.state.agentSelected}
                                  onChange={event => this.handleChangeAgent(event)}
                                  options={this.selectOptionAgentList(agentList)}
                                  placeholder="เลือกนายหน้า"
                                />
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  disabled={!this.state.agentSelected}
                                  bsStyle="primary"
                                  onClick={() => assignAgent()}
                                >
                                  บันทึก
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          )}
                        </Mutation>

                        <ModalConfirm
                          show={this.state.isOpenModalConfirmDownloadReport}
                          onPressButtonConfirm={this.downloadReportHandler}
                          styleColorButtonConfirm="primary"
                          labelButtonConfirm="ดาวน์โหลด"
                          labelButtonCancel="ยกเลิก"
                          onPressButtonCancel={() =>
                            this.setState({ isOpenModalConfirmDownloadReport: false })
                          }
                          titleHeader={`Download Report ใช่หรือไม่?`}
                          onHide={() => this.setState({ isOpenModalConfirmDownloadReport: false })}
                        >
                          <div style={{ lineHeight: 1 }}>
                            File Name: <strong>{this.state.fileNameBookingFile}</strong>
                          </div>
                        </ModalConfirm>

                        {
                          this.state.isShowClearShipmentModal && 
                          <ClearShipmentDriverModal 
                            showModal={this.state.isShowClearShipmentModal}  
                            closeModal={() => this.setState({ isShowClearShipmentModal: false, shipmentTripClearModal: '', shipmentIdClearModal: '', agentIdClearModal: '' })} 
                            shipmentTrip={this.state.shipmentTripClearModal}
                            shipmentID={this.state.shipmentIdClearModal}
                            transporter={this.state.agentIdClearModal}
                            refetch={refetch}
                          />
                        }
                      </Col>
                    </Row>
                  </div>
                </Grid>
              </>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default compose(
  graphql(driverUserListQuery, { name: 'driverUserListQuery' }),
  graphql(userTruckListQuery, { name: 'userTruckListQuery' }),
  graphql(accountInfoListQuery, {
    name: 'accountInfoListQuery',
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        account_info_type: 3,
      },
    }),
  })
)(ShipmentList);
