import gql from 'graphql-tag';

export default gql`
query search(
    $_id: ID
    $shipper: ID
    $driver_name: String
    $mode: String!
    $setting_truck_service: ID
    $show_data: Int
    $page_number: Int
    $order_by: String
    $order_type: String
  ){
    favoriteAndBlacklistDriverList(
        _id: $_id
        shipper: $shipper
        driver_name: $driver_name
        mode: $mode
        setting_truck_service: $setting_truck_service
        show_data: $show_data
        page_number: $page_number
        order_by: $order_by
        order_type: $order_type
    ){
        driver_user {
            _id
            account_info_id
            driver_name
            driver_phone_code
            driver_phone_number
            driver_enable
            driver_block
            user_setting_truck_id
            user_setting_truck_license_plate
            line_id
            reserve_phone_code
            reserve_phone_number
            criminal_history
            id_card_number
            id_card_pic{
                path
            }
            driver_card_pic{
                path
            }
            car_pic{
                path
            }
            license_plate_pic{
                path
            }
            act_pic{
                path
            }
            car_insurance_pic{
                path
            }
            book_bank_pic{
                path
            }
            setting_truck_service
            driver_number
            driver_type
            driver_delivery_vat
            driver_delivery_wht
            driver_service_vat
            driver_service_wht
            create_date
            last_update
            driver_license_number
            driver_license_exp
            driver_car_brand
            driver_display_pic
            driver_block_remark
            working
            setting_truck_id
            setting_truck_name {
                th
                en
            }
            truck_type_name {
                th
                en
            }
        }
        total_page
    }
}
`;
