import gql from 'graphql-tag';

export default gql`
	query transporterInfoList(
		$account_info_business_name: String
	) {
		accountInfoList(
			account_info_business_name: $account_info_business_name
			show_data: 10
			# //account_info_type = 2 คือ transporter
			account_info_type: 2
		) {
			account_info {
				_id
				account_info_business_name
			}
		}
	}
`;
