import React from 'react';
import styled from 'styled-components';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';

const Container = styled.div``;
const InputBox = styled(Datetime)`
  & .form-control{
    font-size:18px;
    border-radius: 6px;
    border: 1px solid #E3E3E3;
    padding: 0 12px;
    line-height: 1;
    height: 40px;
    background-color: #FFFFFF;
    width: 100%;

    &:focus {
      outline: none;
    }

    &:disabled {
      ${props => props.disabled && `
        background-color: #F9F9F9;
      `}
    }

    ${props => props.isBorderValidated && `
      border: 1px solid #FF0000;
    `}

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #B3B3B3;
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #B3B3B3;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #B3B3B3;
    }
  }
  
`;

const MessageError = styled.div`
  font-size:18px;
  color: #FF0000;
  height: 22px;
  text-align: right;
`;

const Label = styled.div`
  font-size:18px;
  font-weight: bold;

  ${props => props.messageError && `
    color: #FF0000;
  `}
`;

const Required = styled.span`
  color: #D90101;
`;

class Input extends React.Component {
  render() {
    return (
      <Container style={this.props.style}>
        { this.props.inputLabel ? (<Label messageError={this.props.messageError}>{this.props.inputLabel}{ this.props.required && (<Required>*</Required>) }</Label>) : null }
        <InputBox 
            value={this.props.value}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            onChange={this.props.onChange} 
            isBorderValidated={this.props.messageError} 
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
        />
        <MessageError>{this.props.messageError}</MessageError>
      </Container>
    )
  }
}

export default Input;