import gql from 'graphql-tag';

export default gql`
  query serachDriverList($driver_name: String, $_id: ID) {
    driverUserList(show_data: 10, _id: $_id, driver_name: $driver_name) {
      driver_user {
        _id
        account_info_id
        driver_name
        driver_phone_code
        driver_phone_number
        driver_enable
        user_setting_truck_license_plate
        user_setting_truck_id
        setting_truck_service
        setting_truck_id
        driver_agent_detail {
          _id
          account_info_id
          agent_name
        }
      }
      total_page
    }
  }
`;
