import React, { Component } from 'react';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormControl,
  FormGroup,
  Label,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Select } from './components';

import CardTable from 'components/CardTable/CardTable.jsx';
import CustomButton from 'components/CustomButton/CustomButton.jsx';

import adminList from './graphql/query/adminList';
import adminRegister from './graphql/mutation/adminRegister';
import adminUpdate from './graphql/mutation/adminUpdate';

const tooltip = <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>;
const tooltip1 = <Tooltip id="tooltip">แก้ไข</Tooltip>;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

class Shipper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_page: 0,
      page_number: 1,
      order_by: null,
      order_type: null,
      _id: '',
      admin_username: '',
      admin_email: '',
      admin_password: '',
      search_username: '',
      search_email: '',
      // block admin
      showBlockAdmin: false,
      blockAdminName: '',
      blockStatus: '',
      blockAdminId: '',
      admin_user_type: '',
      search_admin_user_type: 0,
    };
  }

  checkDisabled() {
    const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      !validateEmail.test(this.state.admin_email) ||
      this.state.admin_username == '' ||
      this.state.admin_user_type == '' ||
      this.state.admin_email == '' ||
      this.state.admin_password == ''
    ) {
      return true;
    }
    return false;
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    // console.log(page+1);
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  addAdmin() {
    this.setState({
      showAdd: true,
      admin_username: '',
      admin_email: '',
      admin_password: '',
      _id: '',
      admin_user_type: '',
    });
  }

  handleShowEdit(obj) {
    this.setState({
      _id: obj._id,
      admin_username: obj.admin_username,
      admin_email: obj.admin_email,
    });
  }

  handleShowCancel(_id) {
    this.setState({ _id });
  }

  renderAdminBlockButton = (id, status, admin) => {
    switch (status) {
      case false:
        return (
          <Button
            onClick={() => this.handlerShowAdminBlock(id, status, admin)}
            style={{ backgroundColor: '#d90101', color: 'white' }}
          >
            ระงับการใช้งาน
          </Button>
        );
      case true:
        return (
          <Button
            onClick={() => this.handlerShowAdminBlock(id, status, admin)}
            style={{ backgroundColor: '#94DA57', color: 'white' }}
          >
            ปลดระงับการใช้งาน
          </Button>
        );
      default:
        return;
    }
  };

  handlerShowAdminBlock = (id, status, admin) => {
    this.setState({
      showBlockAdmin: true,
      blockAdminName: admin,
      blockStatus: status,
      blockAdminId: id,
    });
  };

  handlerCloseAdminBlock = () => {
    this.setState({
      showBlockAdmin: false,
      blockAdminName: '',
      blockStatus: '',
      blockAdminId: '',
    });
  };

  renderAdminStatus = status => {
    switch (status) {
      case false:
        return <span style={{ color: '#94DA57', fontWeight: 'bold' }}>กำลังใช้งาน</span>;
      case true:
        return <span style={{ color: '#d90101', fontWeight: 'bold' }}>ถูกระงับการใช้งาน</span>;
      default:
        return;
    }
  };

  renderAdminType = status => {
    switch (status) {
      case 1:
        return <Label bsStyle="primary">Admin</Label>;
      case 2:
        return <Label bsStyle="success">Checker</Label>;
      case 3:
        return <Label bsStyle="warning">Tracking Lazada</Label>;
      case 4:
        return <Label bsStyle="warning">Tracking JDBU</Label>;
      case 5:
        return <Label bsStyle="warning">Tracking BTV</Label>;
      case 6:
        return <Label bsStyle="warning">Tracking JDSM</Label>;
      default:
        return;
    }
  };

  render() {
    // console.log(this.state);
    let myAdminName = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      // console.log(this.props.adminName.adminList.admin[0].admin_username)
      myAdminName = this.props.adminName.adminList.admin[0].admin_username;
    }
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Query
                query={adminList}
                variables={{
                  page_number: this.state.page_number,
                  order_by: this.state.order_by,
                  order_type: this.state.order_type,
                  admin_username: this.state.search_username,
                  admin_email: this.state.search_email,
                  admin_user_type: this.state.search_admin_user_type,
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  {
                    /*console.log(variables);*/
                  }

                  var itemLength = 'loading';
                  const itemIndicatorSize = (this.state.page_number - 1) * 30;
                  if (data && data.adminList) {
                    if (data.adminList.admin.length > 0) {
                      itemLength = data.adminList.admin.length;
                    }
                  }
                  return (
                    <div>
                      <CardTable
                        title="ตารางรายการแอดมิน"
                        category="ข้อมูลแอดมิน"
                        ctTableFullWidth
                        ctTableResponsive
                        rightButton={
                          <div style={{ float: 'right', marginLeft: '15px' }}>
                            <Button bsStyle="primary" onClick={() => this.addAdmin()}>
                              <i className="fal fa-user-plus"></i> เพิ่มแอดมิน{' '}
                            </Button>
                          </div>
                        }
                        content={
                          <div>
                            <PageWrapper style={{ textAlign: 'right' }}>
                              <div className="text">
                                {this.state.page_number === 1
                                  ? `1 - ${itemLength}`
                                  : `${itemIndicatorSize + 1} - ${itemLength +
                                      itemIndicatorSize}`}{' '}
                                หน้า :
                              </div>
                              <FormControl
                                type="number"
                                min={1}
                                value={this.state.page_number}
                                onChange={event =>
                                  setInterval(
                                    this.nextPage(
                                      Number(event.target.value) - 1,
                                      loading || error || !data ? '0' : data.adminList.total_page,
                                      refetch
                                    ),
                                    1000
                                  )
                                }
                                name="page_number"
                              />
                              <div className="text">
                                {' '}
                                {loading || error || !data.adminList || !data.adminList.total_page
                                  ? 'loading'
                                  : `จาก ${data.adminList.total_page}`}
                              </div>
                            </PageWrapper>
                            <div className="table-vertical">
                              <Table striped hover>
                                <thead>
                                  <tr>
                                    <th
                                      style={{ width: '250px' }}
                                      className={this.renderSorting('admin_email')}
                                      onClick={() => this.sort('admin_email', refetch)}
                                    >
                                      username
                                    </th>
                                    <th
                                      style={{ width: '350px' }}
                                      className={this.renderSorting('admin_username')}
                                      onClick={() => this.sort('admin_username', refetch)}
                                    >
                                      อีเมล
                                    </th>
                                    <th>สถานะ</th>
                                    <th>ประเภท</th>
                                    <th></th>
                                  </tr>
                                  <tr className="tableFilter">
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.search_username}
                                        placeholder="ค้นหา"
                                        onChange={event =>
                                          this.setState(
                                            { search_username: event.target.value },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="search_username"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.search_email}
                                        placeholder="ค้นหา"
                                        onChange={event =>
                                          this.setState(
                                            { search_email: event.target.value },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="search_email"
                                      />
                                    </th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.search_admin_user_type}
                                        onChange={event => {
                                          console.log(event.target.value);
                                          this.setState({
                                            search_admin_user_type: event.target.value,
                                          });
                                        }}
                                        name="search_admin_user_type"
                                      >
                                        <option value={0}>ทั้งหมด</option>
                                        <option value={1}>Admin</option>
                                        <option value={2}>Checker</option>
                                        <option value={3}>Tracking Lazada</option>
                                        <option value={4}>Tracking JDBU</option>
                                        <option value={5}>Tracking BTV</option>
                                        <option value={6}>Tracking JDSM</option>
                                      </FormControl>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading || error || !data ? (
                                    <tr>
                                      <td colSpan="4" className="textCenter">
                                        loading...
                                      </td>
                                    </tr>
                                  ) : (
                                    data.adminList.admin.map((prop, key) => (
                                      <tr key={key}>
                                        {/* 
                                          <td style={{width:"100px"}}>
                                              <OverlayTrigger placement="top" overlay={tooltip1}>
                                                  <a className="link hover-blue" onClick={() => this.handleShowEdit(prop._id)}><i className="fas fa-pencil"></i></a>
                                              </OverlayTrigger>
                                              <OverlayTrigger placement="top" overlay={tooltip}>
                                                  <a className="link hover-red" onClick={() => this.handleShowCancel(prop._id)}><i className="fas fa-file-excel"></i></a>
                                              </OverlayTrigger>
                                          </td> 
                                        */}
                                        <td>{prop.admin_username}</td>
                                        <td>{prop.admin_email}</td>
                                        <td>{this.renderAdminStatus(prop.blocked)}</td>
                                        <td>{this.renderAdminType(prop.admin_user_type)}</td>
                                        <td>
                                          {myAdminName !== prop.admin_username &&
                                            this.renderAdminBlockButton(
                                              prop._id,
                                              prop.blocked,
                                              prop.admin_username
                                            )}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        }
                      />

                      <div style={{ textAlign: 'right' }}>
                        {loading || error || !data ? null : (
                          <ReactPaginate
                            pageCount={data.adminList.total_page}
                            forcePage={this.state.page_number - 1}
                            containerClassName="pagination"
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={({ selected }) => {
                              this.nextPage(selected, data.adminList.total_page, refetch);
                            }}
                          />
                        )}
                      </div>

                      <Mutation
                        mutation={adminRegister}
                        variables={{
                          admin_username: this.state.admin_username,
                          admin_email: this.state.admin_email,
                          admin_password: this.state.admin_password,
                          admin_user_type: this.state.admin_user_type,
                        }}
                        update={() => {
                          this.setState({ showAdd: false }, () => {
                            refetch();
                          });
                        }}
                        onError={() => {
                          alert('กรอกข้อมูลให้ครบถ้วน และ ถูกต้อง');
                        }}
                      >
                        {adminRegister => (
                          <Modal
                            show={this.state.showAdd}
                            backdrop="static"
                            onHide={() => this.setState({ showAdd: false })}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>เพิ่มแอดมิน</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row style={{ marginBottom: '10px' }}>
                                <Col md={12}> Username</Col>
                              </Row>
                              <FormGroup controlId="formValidationError1">
                                <FormControl
                                  type="text"
                                  value={this.state.admin_username}
                                  placeholder="Username"
                                  onChange={e => this.setState({ admin_username: e.target.value })}
                                />
                              </FormGroup>

                              <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                <Col md={12}> Email</Col>
                              </Row>

                              <FormGroup controlId="formHorizontalEmail">
                                <FormControl
                                  validationState="error"
                                  type="email"
                                  value={this.state.admin_email}
                                  placeholder="Email"
                                  onChange={e => this.setState({ admin_email: e.target.value })}
                                />
                              </FormGroup>

                              <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                <Col md={12}> Password</Col>
                              </Row>

                              <FormGroup controlId="formValidationError3">
                                <FormControl
                                  validationState="error"
                                  type="password"
                                  value={this.state.admin_password}
                                  placeholder="Password"
                                  onChange={e => this.setState({ admin_password: e.target.value })}
                                />
                              </FormGroup>

                              <Row style={{ marginBottom: '10px', marginTop: '20px' }}>
                                <Col md={12}> ประเภท</Col>
                              </Row>

                              <FormControl
                                componentClass="select"
                                placeholder="select"
                                defaultValue={this.state.admin_user_type}
                                onChange={event => {
                                  console.log(event.target.value);
                                  this.setState({ admin_user_type: event.target.value });
                                }}
                                name="admin_user_type"
                              >
                                <option value={''}> -- กรุณาเลือก --</option>
                                <option value={1}>Admin</option>
                                <option value={2}>Checker</option>
                                <option value={3}>Tracking Lazada</option>
                                <option value={4}>Tracking JDBU</option>
                                <option value={5}>Tracking BTV</option>
                                <option value={6}>Tracking JDSM</option>
                              </FormControl>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                bsStyle="primary"
                                disabled={this.checkDisabled()}
                                onClick={() => adminRegister()}
                              >
                                บันทึก
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </Mutation>

                      <Mutation
                        mutation={adminUpdate}
                        variables={{
                          _id: this.state.blockAdminId,
                          adminUpdate: {
                            blocked: !this.state.blockStatus,
                          },
                        }}
                        update={() => {
                          this.handlerCloseAdminBlock();
                        }}
                        onError={() => {
                          alert('กรอกข้อมูลให้ครบถ้วน และ ถูกต้อง');
                        }}
                        refetchQueries={() => ['search']}
                      >
                        {adminUpdate => (
                          <Modal
                            show={this.state.showBlockAdmin}
                            backdrop="static"
                            onHide={this.handlerCloseAdminBlock}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title style={{ fontWeight: 'bold' }}>
                                {!this.state.blockStatus ? (
                                  <span>การระงับการใช้งานแอดมิน</span>
                                ) : (
                                  <span>การปลดระงับการใช้งานแอดมิน</span>
                                )}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Row
                                style={{
                                  marginBottom: '10px',
                                  textAlign: 'center',
                                  fontSize: '22px',
                                }}
                              >
                                {!this.state.blockStatus ? (
                                  <span>
                                    ต้องการระงับการใช้งานแอดมิน{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      {this.state.blockAdminName}
                                    </span>{' '}
                                    ใช่หรือไม่
                                  </span>
                                ) : (
                                  <span>
                                    ต้องการปลดระงับการใช้งานแอดมิน{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                      {this.state.blockAdminName}
                                    </span>{' '}
                                    ใช่หรือไม่
                                  </span>
                                )}
                              </Row>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button onClick={this.handlerCloseAdminBlock}>ไม่ใช่</Button>
                              <Button
                                style={{ backgroundColor: '#086CAF', color: 'white' }}
                                onClick={() => adminUpdate()}
                              >
                                ใช่
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        )}
                      </Mutation>
                    </div>
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(
  graphql(adminList, {
    name: 'adminName',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        my_name: true,
      },
    }),
  })
)(Shipper);
