import { client } from 'index';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import formatCurrency from 'utils/formatCurrency';
import Emitter from '../../containers/CostPerParcel/functions/EventEmitter';
import createParcelCost from '../../containers/CostPerParcel/graphql/createParcelCost';
import updateParcelCostServiceItem from '../../containers/CostPerParcel/graphql/updateParcelCostServiceItem';

const ButtonClickSentToAdmin = ({ data, prepairData }) => {
  const [open, setOpen] = React.useState(false);
  const [listener, setListener] = React.useState(null);
  const [total, setTotal] = React.useState('ยังไม่มีรายการ');

  React.useEffect(() => {
    Emitter.on('parcel_data', async ({ data, total }) => {
      setListener(data);
      setTotal(total);
    });
    return () => {
      Emitter.off('parcel_data');
    };
  }, []);

  React.useEffect(() => {
    if (prepairData) {
      setListener(prepairData?.parcelCostByShipment);
      setTotal(prepairData?.parcelCostByShipment?.grand_total_price);
    } else {
      return;
    }
  }, [prepairData]);

  const onClickConfirm = async () => {
    try {
      const isUpdate = prepairData?.parcelCostByShipment?._id;
      const thisVariable = {
        serviceItems: listener?.serviceItems?.map(row => {
          return {
            datamaster_service_item_id: row?.datamaster_service_item_id,
            service_category_name: row?.service_category_name || 'ค่าขนส่ง',
            advance_type: row?.advance_type,
            list_number: row?.list_number,
            list_name: row?.list_name,
            qty: row?.qty,
            unit: row?.unit,
            price: row?.price,
            discount: row?.discount || 0,
            vat: row?.vat,
            wht: row?.wht,
            total_before_discount: row?.total_before_discount,
            total_price: row?.total_price,
            grand_total_price: row?.grand_total_price,
          };
        }),
      };

      if (!isUpdate) {
        thisVariable.shipment_id = data?.shipmentKA?._id;
        thisVariable.totalCost = total;
        thisVariable.costModel = prepairData?.parcelCostByShipment?.costModel;
      }
      if (isUpdate) {
        thisVariable._id = prepairData?.parcelCostByShipment?._id;
      }
      await client.mutate({
        mutation: isUpdate ? updateParcelCostServiceItem : createParcelCost,
        variables: thisVariable,
      });

      window.location.reload();
      setOpen(false);
    } catch (error) {
      alert(error.graphQLErrors[0].message);
      console.log('ERROR CLICK CONFIRM', error);
    }
  };

  return (
    <React.Fragment>
      <div
        onClick={() => {
          setOpen(true);
        }}
        style={{
          border: '1px solid #ccc',
          padding: '5px 10px',
          borderRadius: '5px',
          cursor: 'pointer',
          backgroundColor: '#d90402',
          color: '#fff',
        }}
      >
        <i
          style={{
            marginRight: '5px',
          }}
          class="fal fa-file-invoice-dollar"
        ></i>{' '}
        ยืนยันส่งราคา
      </div>
      {open && (
        <Modal
          dialogClassName="modal-dialog-size"
          show={open}
          backdrop="static"
          onHide={() => setOpen(false)}
        >
          <Modal.Header>
            <Modal.Title>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>ยืนยันราคา</div>
                <div
                  onClick={() => {
                    setOpen(false);
                  }}
                  style={{
                    marin: 'auto',
                    fontSize: '20px',
                    cursor: 'pointer',
                    color: '#808080',
                  }}
                >
                  x
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row
              style={{
                marginBottom: '10px',
              }}
            >
              <Col md={12}>{/* <b>{TEXT_MODAL_SUBTITLE}</b> */}</Col>
            </Row>
            <Row
              style={{
                marginBottom: '10px',
              }}
            >
              <Col md={12}>ต้องการยืนยันราคา รหัสงานขนส่ง :{data?.shipmentKA?.shipment_trip}</Col>
              <Col md={12}>ยอดรวม {formatCurrency(total)} บาท ใช่หรือไม่</Col>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Button onClick={() => setOpen(false)}>ยกเลิก</Button>
              <Button
                style={{
                  backgroundColor: '#d90402',
                  color: '#fff',
                  display: 'flex',
                }}
                onClick={() => onClickConfirm()}
              >
                <i
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    marginRight: '5px',
                  }}
                  class="fal fa-file-plus"
                ></i>
                <div
                  style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  }}
                >
                  ยืนยันราคา
                </div>
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default ButtonClickSentToAdmin;
