import gql from 'graphql-tag';

export default gql`
  mutation accountInfoUpdate(
    $_id: ID
    $account_info_type: Int
    $account_info_status: Int
    $account_info_business_type: languageArg
    $account_info_business_name: String
    $account_info_tax_id: String
    $account_info_email: String
    $account_info_address: String
    $account_info_country: String
    $account_info_province: String
    $account_info_district: String
    $account_info_subdistrict: String
    $account_info_postcode: String
    $account_info_branch: String
    $account_info_tel: String
    $account_info_phone_code: String
    $account_info_phone_number: String
    $account_info_location_map: String
    $line_id: String
    $reserve_phone_code: String
    $reserve_phone_number: String
    $criminal_history: String
    $id_card_number: String
    $id_card_pic: [typePathArg]
    $driver_card_pic: [typePathArg]
    $car_pic: [typePathArg]
    $license_plate_pic: [typePathArg]
    $act_pic: [typePathArg]
    $car_insurance_pic: [typePathArg]
    $book_bank_pic: [typePathArg]
    $company_certificate_pic: [typePathArg]
    $shareholder_document: [typePathArg]
    $director_id_card_pic: [typePathArg]
    $vat_registration_pic: [typePathArg]
    $company_profile_pic: [typePathArg]
    $setting_truck_service: [ID]
    $account_info_delivery_vat: Int
    $account_info_delivery_wht: Int
    $account_info_service_vat: Int
    $account_info_service_wht: Int
    $account_info_tracking_url: String
    $bank_account: [accountInfoBankAccountArg]
    $payment_bank_account: paymentBankAccountArg
    $agent_trip_rate: Boolean
    $agent_trip_month_rate: Boolean
    $month_rate_contract_doc: String
    $month_rate_contract_date_start: String
    $month_rate_contract_date_end: String
  ) {
    accountInfoUpdate(
      _id: $_id
      account_info_type: $account_info_type
      account_info_status: $account_info_status
      account_info_business_type: $account_info_business_type
      account_info_business_name: $account_info_business_name
      account_info_tax_id: $account_info_tax_id
      account_info_email: $account_info_email
      account_info_address: $account_info_address
      account_info_country: $account_info_country
      account_info_province: $account_info_province
      account_info_district: $account_info_district
      account_info_subdistrict: $account_info_subdistrict
      account_info_postcode: $account_info_postcode
      account_info_branch: $account_info_branch
      account_info_tel: $account_info_tel
      account_info_phone_code: $account_info_phone_code
      account_info_phone_number: $account_info_phone_number
      account_info_location_map: $account_info_location_map
      line_id: $line_id
      reserve_phone_code: $reserve_phone_code
      reserve_phone_number: $reserve_phone_number
      criminal_history: $criminal_history
      id_card_number: $id_card_number
      id_card_pic: $id_card_pic
      driver_card_pic: $driver_card_pic
      car_pic: $car_pic
      license_plate_pic: $license_plate_pic
      act_pic: $act_pic
      car_insurance_pic: $car_insurance_pic
      book_bank_pic: $book_bank_pic
      company_certificate_pic: $company_certificate_pic
      shareholder_document: $shareholder_document
      director_id_card_pic: $director_id_card_pic
      vat_registration_pic: $vat_registration_pic
      company_profile_pic: $company_profile_pic
      setting_truck_service: $setting_truck_service
      account_info_delivery_vat: $account_info_delivery_vat
      account_info_delivery_wht: $account_info_delivery_wht
      account_info_service_vat: $account_info_service_vat
      account_info_service_wht: $account_info_service_wht
      account_info_tracking_url: $account_info_tracking_url
      bank_account: $bank_account
      payment_bank_account: $payment_bank_account
      agent_trip_rate: $agent_trip_rate
      agent_trip_month_rate: $agent_trip_month_rate
      month_rate_contract_doc: $month_rate_contract_doc
      month_rate_contract_date_start: $month_rate_contract_date_start
      month_rate_contract_date_end: $month_rate_contract_date_end
    ) {
      _id
    }
  }
`;
