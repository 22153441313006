import React from "react";
import Tabs from "../Tabs";
import urijs from "urijs";
import { compose, Query, Mutation, graphql } from 'react-apollo';
import adminListQuery from '../../screens/ShipmentList/graphql/query/adminList';
import { Grid } from "react-bootstrap";

class Layout extends React.Component {
  getParamUrl = () => {
    const uriRoute = urijs(window.location.href);
    const uriFragment = uriRoute.fragment(true);
    const pathname = uriFragment.pathname();

    return pathname;
  };

  setActive = path => {
    const paramUrl = this.getParamUrl();
    return path === paramUrl;
  };

  openUrl = url => {
    window.location = url;
  };

  render() {
    let adminName = '';
    if (this.props.adminListQuery && this.props.adminListQuery.loading == false && this.props.adminListQuery.adminList != undefined) {
      adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
    }

    var menuTabs = []

    menuTabs =  adminName === "shopee" ? 
    [
      {
        icon: "fal fa-file-excel",
        title: "Import file",
        active: this.setActive("/shopee-import-file"),
        toUrl: "/shopee-import-file"
      },
      {
        icon: "fal fa-file-alt",
        title: "Booking file",
        active: this.setActive("/shopee"),
        toUrl: "/shopee"
      },
      {
        icon: "fal fa-truck",
        title: "Shipment list",
        active: this.setActive("/shopee-shipment-list"),
        toUrl: "/shopee-shipment-list"
      }
    ] : 
    [
      {
        icon: "fal fa-file-excel",
        title: "Import file",
        active: this.setActive("/shopee-import-file"),
        toUrl: "/shopee-import-file"
      },
      {
        icon: "fal fa-file-alt",
        title: "Booking file",
        active: this.setActive("/shopee"),
        toUrl: "/shopee"
      },
      {
        icon: "fal fa-truck",
        title: "Shipment list",
        active: this.setActive("/shopee-shipment-list"),
        toUrl: "/shopee-shipment-list"
      },
      {
        icon: "fal fa-user",
        title: "แจงงานให้คนขับรถ",
        active: this.setActive("/shopee-adddriver"),
        toUrl: "/shopee-adddriver"
      }
    ]

    return (
      <div className="content">
        <Grid fluid>
          <div {...this.props}>
            <Tabs menu={menuTabs} />
            <div>{this.props.children}</div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default compose(
  graphql(adminListQuery, { name: 'adminListQuery' }),
)(Layout);
