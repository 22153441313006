import gql from 'graphql-tag';

export default gql`
  query billingPaymentDocuments(
    $_id: ID
    $payment_id: ID!
    $document_type: Int
    $delete: Boolean
    $create_date: String
    $last_update: String
  ) {
    billingPaymentDocuments(
      _id: $_id
      payment_id: $payment_id
      document_type: $document_type
      delete: $delete
      create_date: $create_date
      last_update: $last_update
    ) {
      documents {
        _id
        payment_id
        document_type
        document_file
        list_ref
        delete
        create_date
        last_update
      }
      totalDocument
    }
  }
`;
