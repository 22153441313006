import gql from 'graphql-tag';

export default gql`
query search(
        $shipment_id: ID!
    ){
    shipmentCostList(
            shipment_id:$shipment_id
        )
        {
            shipment_id
            shipment_cost
            additional_cost
            backhaul_cost
        }
    }
`;
