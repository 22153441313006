import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import { Grid, Row, Col, Table, Tooltip, OverlayTrigger, Button, FormControl, Modal } from "react-bootstrap";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

import CardTable from "components/CardTable/CardTable.jsx";
import CustomButton from "components/CustomButton/CustomButton.jsx";
import { LicensepleteArray } from "variables/Variables.jsx";

import userTruckList from "./graphql/query/userTruckList"
import truckAdd from "./graphql/mutation/truckAdd"
import truckUpdate from "./graphql/mutation/truckUpdate"

const tooltip = (
    <Tooltip id="tooltip">
        แก้ไข
  </Tooltip>
);

const tooltip1 = (
    <Tooltip id="tooltip">
        บล๊อค / ยกเลิกการบล็อค
  </Tooltip>
);

const PageWrapper = styled.div`
    display: flex;
    flex-direction: row;
    float: right;

    & .text{
        padding:5px;
    }
    & .form-control{
        padding: 6px 10px;
        height: 30px;
        width: 70px;
    }
`;


class Licenseplete extends Component {
    constructor(props) {
        super(props)

        this.state = {
            total_page: 0,
            page_number: 1,
            order_by: null,
            order_type: null,
            showAdd: false,
            showEdit: false,
            license_plate: "",
            _id: null,
        }
    }

    addLicenseplete() {
        this.setState({ showAdd: true, license_plate: "", _id: null });
    }

    editLicenseplete(license_plate, _id) {
        this.setState({ showEdit: true, license_plate, _id });
    }

    nextPage(page,total_page,refetch) {
        this.setState({ page_number: page+1,total_page });
        // console.log(this.state);
        refetch();
    }
    
    renderSorting(order_by) {
        if (order_by == this.state.order_by) {
            if (this.state.order_type == 1) {
                return "sorting_asc";
            }
            else {
                return "sorting_desc";
            }
        }

        return "sorting";
    }

    sort(order_by, refetch) {
        if (order_by == this.state.order_by) {
            this.setState({ order_type: this.state.order_type * -1 });
        }
        else {
            this.setState({ order_type: 1, order_by });
        }
        refetch();
    }


    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        <Query
                            query={userTruckList}
                            variables={{
                                page_number: this.state.page_number,
                                order_by: this.state.order_by,
                                order_type: this.state.order_type
                            }}
                        >
                            {
                                ({ loading, error, data, refetch,variables }) => {
                                    console.log(data,variables);

                                    var itemLength = 'loading';
                                        const itemIndicatorSize = (this.state.page_number - 1)*30;
                                        if(data.userSettingTruckList){
                                            if(data.userSettingTruckList.user_setting_truck.length > 0){
                                                itemLength = data.userSettingTruckList.user_setting_truck.length;
                                            }
                                        }
                                    return (
                                        <Col md={12}>
                                            <CardTable
                                                title="ตารางรายการเลขทะเบียนรถ"
                                                category="ข้อมูลเลขทะเบียนรถรถในระบบ"
                                                ctTableFullWidth
                                                ctTableResponsive
                                                rightButton={
                                                    <div style={{ float: "right", marginLeft: "15px" }}>
                                                        <Button  bsStyle="primary" onClick={() => this.addLicenseplete()}><i className="fal fa-truck-moving"></i> เพิ่มรถ </Button>
                                                    </div>
                                                }
                                                content={
                                                    <div>
                                                        <PageWrapper style={{ textAlign: "right" }}>
                                                            <div className="text">{this.state.page_number === 1 ? `1 - ${itemLength}` : `${itemIndicatorSize + 1} - ${itemLength + itemIndicatorSize}`} หน้า :</div>
                                                                <FormControl
                                                                    type="number"
                                                                    min={1}
                                                                    value={this.state.page_number}
                                                                    onChange={(event) => 
                                                                    setInterval(this.nextPage(Number(event.target.value) -1, loading || error ? '0' : data.userSettingTruckList.total_page,refetch), 1000)
                                                                    }
                                                                    name="page_number"
                                                                />
                                                            <div className="text"> {loading || error ? '' : `จาก ${data.userSettingTruckList.total_page}`}</div>
                                                        </PageWrapper>
                                                        <div className="table-vertical">
                                                            <Table striped hover>
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th style={{ width: "120px",minWidth:"120px" }}>หมายเลขทะเบียนรถ</th>
                                                                        <th>สถานะ</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(loading || error) ?
                                                                        (<tr>
                                                                            <td colSpan="10" className="textCenter">{(loading) ? "Loading..." : error.message}</td>
                                                                        </tr>
                                                                        ) :
                                                                        data.userSettingTruckList.user_setting_truck.map((prop, key) => (
                                                                            <tr key={key} data-id={prop._id}>
                                                                                <td style={{ width: "50px" }}>
                                                                                    <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                        <Button bsStyle="link" onClick={() => this.editLicenseplete(prop.user_setting_truck_license_plate, prop._id)}><i style={{ color: "#CCCCCC" }} className="fas fa-pencil-alt"></i></Button>
                                                                                    </OverlayTrigger>
                                                                                    {/* <OverlayTrigger placement="top" overlay={tooltip1}>
                                                                                        <Button bsStyle="link"><i style={{ color: "#CCCCCC" }} className="fas fa-times-circle"></i></Button>
                                                                                    </OverlayTrigger> */}
                                                                                </td>
                                                                                <td style={{ width: "130px" }}>{prop.user_setting_truck_license_plate}</td>
                                                                                <td style={{ width: "250px" }}>
                                                                                    {(prop.driver_status ? <Button bsStyle="link"> รับงาน <i style={{ color: "#CCCCCC" }} className="fas fa-check-circle"></i></Button> : <Button bsStyle="link" style={{ color: "#ED1C24" }}> กำลังใช้งาน <i className="fas fa-times-circle"></i></Button>)}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>       
                                                }

                                            />

                                            <Mutation
                                                mutation={truckAdd}
                                                variables={{
                                                    license_plate: this.state.license_plate,
                                                }}
                                                update={() => {
                                                    this.setState({ showAdd: false }, () => { refetch() })
                                                }}
                                            >
                                                {truckAdd => (
                                                    <Modal show={this.state.showAdd} backdrop='static' onHide={() => this.setState({showAdd : false})}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>เพิ่มรถ</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Row style={{ marginBottom: "10px"}}>
                                                                <Col md={12}>ทะเบียนรถ</Col>
                                                            </Row>
                                                            <FormControl
                                                                type="text"
                                                                value={this.state.license_plate}
                                                                placeholder="ทะเบียนรถ"
                                                                onChange={(e) => this.setState({ license_plate: e.target.value })}
                                                            />
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button bsStyle="primary"   disabled={this.state.license_plate == ""} onClick={() => truckAdd()}>บันทึก</Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                )

                                                }
                                            </Mutation>

                                            <Mutation
                                                mutation={truckUpdate}
                                                variables={{
                                                    _id: this.state._id,
                                                    license_plate: this.state.license_plate,
                                                }}
                                                update={() => {
                                                    this.setState({ showEdit: false }, () => { refetch() })
                                                }}
                                            >
                                                {truckUpdate => (
                                                <Modal show={this.state.showEdit} backdrop='static' onHide={() => this.setState({showEdit : false})}>
                                                    <Modal.Header closeButton>
                                                        <Modal.Title>เพิ่มรถ</Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <Row style={{ marginBottom: "10px"}}>
                                                            <Col md={12}>ทะเบียนรถ</Col>
                                                        </Row>

                                                        <FormControl
                                                            type="text"
                                                            value={this.state.license_plate}
                                                            placeholder="ทะเบียนรถ"
                                                            onChange={(e) => this.setState({ license_plate: e.target.value })}
                                                        />
                                                    </Modal.Body>
                                                    <Modal.Footer>
                                                        <Button bsStyle="primary" onClick={() => truckUpdate()}>บันทึก</Button>
                                                    </Modal.Footer>
                                                </Modal>
                                                )}
                                            </Mutation>

                                            <div style={{ textAlign: "right" }}>
                                                {(loading) ? null :
                                                    (<ReactPaginate pageCount={data.userSettingTruckList.total_page} forcePage={this.state.page_number -1} containerClassName="pagination" nextLabel=">" previousLabel="<" onPageChange={({ selected }) => { this.nextPage(selected,data.userSettingTruckList.total_page, refetch); }} />)
                                                }
                                            </div>
                                        </Col>
                                    )
                                }
                            }
                        </Query>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default Licenseplete;