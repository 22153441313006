import gql from 'graphql-tag';

export default gql`
  query KAWarehouses {
    kaWarehouses {
      _id
      tag_name
      address
      province
      dictrict
      subdictrict
      postcode
      lat
      lng
      contact_name
      contact_tel
      wms_branch
      active
      warehouse_type
    }
  }
`;
