import React from 'react'
import Input from '../Input'
import {ColLeft, UlTruck } from '../../styled'

const TripNumberList = ({setShipmentSearch , shipmentAgentView, getAgentDetail, renderIconStatus, renderIconArrow, tripId}) => {
    return (
        <ColLeft>
            <UlTruck style={{ marginBottom: '0px' }}>
                <li style={{ color: 'unset', backgroundColor: '#fff' }}>
                <b>TRIP No.</b>
                </li>
            </UlTruck>
            <Input
                style={{
                    width: '80%',
                    marginLeft: '25px',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}
                onChange={event =>
                    // this.setState({ shipment_trip_search: event.target.value })
                    setShipmentSearch(event.target.value)
                }
                placeholder="ค้นหา"
            />
            <UlTruck style={{ marginBottom: '0px' }}>
                {shipmentAgentView && shipmentAgentView.shipment ? (
                shipmentAgentView.shipment.length > 0 ? (
                    shipmentAgentView.shipment.map(shipment => (
                    <li
                        key={shipment._id}
                        className={shipment._id == tripId ? 'active' : ''}
                        onClick={() => getAgentDetail(shipment._id)}
                    >
                        <div>
                        {renderIconStatus(shipment.check_status, shipment.shipment_trip)}
                        </div>
                        {
                            renderIconArrow(shipment)
                        }
                    </li>
                    ))
                ) : (
                    <li style={{ backgroundColor: '' }}>ไม่มีข้อมูล</li>
                )
                ) : (
                <li style={{ backgroundColor: '' }}>ไม่มีข้อมูล</li>
                )}
            </UlTruck>
            </ColLeft>
    )
}

export default TripNumberList
