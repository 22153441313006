import gql from "graphql-tag";

export default gql`
    mutation lazadaPackageOrderPlaning(
        $params: paramsLazPackageOrderPlaning
        $update: updatePackageOrderPlaning
    ){
        lazadaPackageOrderPlaning(
        params: $params
        update: $update
        ){
            succeed
        }
    }
`;
