import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipmentID: ID!
    $driverID: ID!
    $truckID: ID!
){
    assignDriver(
      shipment_id:$shipmentID,
      driver_user_id:$driverID,
      user_setting_truck_id:$truckID
    ){
      succeed
    }
  }
`;
