import React, { Component } from 'react';
import ModalConfirm from './components/ModalConfirm';
import SelectBox from './components/SelectBox';
import TextArea from './components/TextArea';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import NotificationSystem from 'react-notification-system';
import s3 from 'config/s3';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import moment from 'moment';
//Mutation
import singleUploadMutation from './graphql/mutation/singleUpload';
import lazTrackingUpdateMutation from './graphql/mutation/lazTrackingUpdate';
//Query
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
  Popover,
  Checkbox,
  Image,
} from 'react-bootstrap';
import styled from 'styled-components';

const selectOptionFinalStatus = [
  { value: 3, label: 'Delivered' },
  { value: 5, label: 'Fail Delivery' },
  { value: 7, label: 'No pickup' },
];

const selectOptionFailDeliveryStatus = [
  { value: 1, label: 'ที่อยู่ลูกค้าไม่ถูกต้อง' },
  { value: 2, label: 'ไม่สามารถติดต่อได้ทางโทรศัพท์' },
  { value: 3, label: 'ปฏิเสธรับสินค้า โดยไม่สมเหตุสมผล' },
  { value: 4, label: 'ปฏิเสธรับสินค้า เนื่องจากเงินไม่พอชำระ' },
  { value: 5, label: 'ปฏิเสธรับสินค้า เนื่องจากสินค้าไม่ตรงตามที่สั่ง' },
  { value: 6, label: 'ปฏิเสธรับสินค้า เนื่องจากสินค้าชำรุด' },
  { value: 7, label: 'ลูกค้าไม่อยู่ ณ จุดส่งตามที่ระบบแจ้งไว้' },
  { value: 8, label: 'ลูกค้าเลื่อนส่ง' },
  { value: 9, label: 'รถเสีย / เกิดอุบัติเหตุ' },
  { value: 10, label: 'ลูกค้ายกเลิกสินค้าก่อนเริ่มจัดส่ง' },
  { value: 11, label: 'ไม่สามารถจัดส่งสินค้าได้สำเร็จตามเป้า เราจะพยายามจัดส่งใหม่อีกครั้ง' },
];

const selectOptionProductStatus = [
  { value: 1, label: 'สินค้าชำรุด' },
  { value: 2, label: 'สินค้าสภาพสมบูรณ์' },
];

const CustomPhotoAdd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-color: ${props => (props.check ? 'rgba(49, 141, 232, 0.1)' : 'rgba(255, 0, 0, 0.1)')};
  border: ${props => (props.check ? '2px dashed #318DE8' : '2px dashed red')};
  border-radius: 10px;
`;

const CustomPhoto = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  // border: 2px solid #E3E3E3;
  position: relative;

  & i {
    position: absolute;
    font-size: 18px;
    margin: 5px 5px;
    top: 5px;
    right: 5px;
  }

  & .fas {
    color: #ffff;
  }

  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
`;

const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

class ChangeFinalStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalStatus: { value: 1, label: 'Delivered' },
      finalStatusInvalid: false,

      // หัวข้อปัญหา
      failStatus: '',
      failStatusInvalid: false,

      //รายละเอียดปัญหา
      problemDetail: '',
      problemDetailInvalid: false,

      //สภาพสินค้า
      productStatus: '',
      productStatusInvalid: false,

      //Failed

      //วันที่ลูกค้าเลื่อนส่ง
      delayDate: '',
      delayDateShow: '',
      delayDateInvalid: false,

      // ใช้ แนบรูปภาพยืนยัน && รูปถ่ายสินค้า
      attachPhotos: [],
      attachPhotosInvalid: false,
      animationUploadFirst: false,

      // ใช้ หลักฐานการโทร
      attachSecondPhoto: [],
      attachSecondPhotoInvalid: false,
      animationUploadSecond: false,

      //ใข้ แนบรูปภาพยืนยัน
      attachThirdPhotos: [],
      attachThirdPhotosInvalid: false,
      animationUploadThird: false,

      //viewImageSrc
      isOpenLightbox: false,
      viewImageSrc: '',

      // LOG
      logDetail: [],

      //Final
      finalData: {},
      submitModal: false,
    };
    this._inputAttachFileFirstCase = React.createRef();
    this._inputAttachFileSecondCase = React.createRef();
    this._inputAttachFileThirdCase = React.createRef();
    this.notificationSystem = React.createRef();
  }

  renderFailStatus = status => {
    const newFail = selectOptionFailDeliveryStatus.filter(data => data.value === status);
    return newFail[0];
  };

  componentDidMount = () => {
    console.log(this.props);
    this.setState({
      finalStatus:
        this.props.deliveryData.final_status === 3
          ? { value: 3, label: 'Delivered' }
          : this.props.deliveryData.final_status === 4 ||
            this.props.deliveryData.final_status === 5 ||
            this.props.deliveryData.final_status === 6 ||
            this.props.deliveryData.final_status === 9
          ? { value: 5, label: 'Fail Delivery' }
          : this.props.deliveryData.final_status === 7 && { value: 7, label: 'No pickup' },
      attachPhotos:
        this.props.deliveryData.final_status === 3
          ? this.props.deliveryData.commodity_picture.length > 0
            ? this.props.deliveryData.commodity_picture
            : this.props.deliveryData.commodity_picture_end
          : [],
      attachSecondPhoto: this.props.deliveryData.failure_picture
        ? this.props.deliveryData.failure_picture
        : [],
      attachThirdPhotos:
        this.props.deliveryData.final_status === 4 ||
        this.props.deliveryData.final_status === 5 ||
        this.props.deliveryData.final_status === 6 ||
        this.props.deliveryData.final_status === 9
          ? this.props.deliveryData.commodity_picture_return
          : [],

      //fail
      failStatus: this.props.deliveryData.failure_status
        ? this.renderFailStatus(this.props.deliveryData.failure_status)
        : '',
      problemDetail: this.props.deliveryData.failure_detail
        ? this.props.deliveryData.failure_detail
        : '',
      productStatus:
        this.props.deliveryData.return_status === 1
          ? { value: 1, label: 'สินค้าชำรุด' }
          : this.props.deliveryData.return_status === 2
          ? { value: 2, label: 'สินค้าสภาพสมบูรณ์' }
          : '',
      delayDate: this.props.deliveryData.postpone_delivery_date
        ? `${moment(this.props.deliveryData.postpone_delivery_date).format('YYYY-MM-DD HH:mm')}`
        : '',
      delayDateShow: this.props.deliveryData.postpone_delivery_date
        ? `${moment(this.props.deliveryData.postpone_delivery_date).format('DD/MM/YYYY HH:mm')}`
        : '',

      //LOG
      logDetail: {
        final_status: this.props.deliveryData.final_status,
        failure_status: this.props.deliveryData.failure_status,
        failure_detail: this.props.deliveryData.failure_detail,
        postpone_delivery_date: this.props.deliveryData.postpone_delivery_date,
        failure_picture: this.props.deliveryData.failure_picture,
        commodity_picture: this.props.deliveryData.commodity_picture,
        commodity_picture_end: this.props.deliveryData.commodity_picture_end,
        commodity_picture_return: this.props.deliveryData.commodity_picture_return,
        return_status: this.props.deliveryData.return_status,
      },
    });
  };

  handleDatePicker(event) {
    if (event) {
      try {
        const date = `${event.format('YYYY-MM-DD HH:mm')}`;
        const show = `${event.format('DD/MM/YYYY HH:mm')}`;
        this.setState({ delayDate: date, delayDateShow: show, delayDateInvalid: false });
      } catch (error) {
        console.log(error);
      }
    }
  }

  handleSingleUploadDocumentCase(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    // console.log(uploadFile)

    const timestamp = new Date().getTime();

    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/3plLazDetail/FinalStatus/${timestamp}/${documentName}`,
        },
      });
    }
  }

  checkFileTypeImage(file) {
    if (file) {
      return file.type === 'image/png' || file.type === 'image/jpeg';
    }
  }

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  deleteAttachPhotoFirst = id => {
    const newAttachPhotos = this.state.attachPhotos.filter(data => data !== id);
    this.setState({
      attachPhotos: newAttachPhotos,
    });
  };

  deleteAttachPhotoSecond = id => {
    const newAttachPhotos = this.state.attachSecondPhoto.filter(data => data !== id);
    this.setState({
      attachSecondPhoto: newAttachPhotos,
    });
  };

  deleteAttachPhotoThird = id => {
    const newAttachPhotos = this.state.attachThirdPhotos.filter(data => data !== id);
    this.setState({
      attachThirdPhotos: newAttachPhotos,
    });
  };

  renderLightbox(data) {
    this.setState({
      isOpenLightbox: true,
      viewImageSrc: `${s3}/${data}`,
    });
  }

  onChangeFinalStatus = () => {
    this.setState({
      // หัวข้อปัญหา
      failStatus: '',
      failStatusInvalid: false,

      //รายละเอียดปัญหา
      problemDetail: '',
      problemDetailInvalid: false,

      //สภาพสินค้า
      productStatus: '',
      productStatusInvalid: false,

      //Failed

      //วันที่ลูกค้าเลื่อนส่ง
      delayDate: '',
      delayDateShow: '',
      delayDateInvalid: false,

      // ใช้ แนบรูปภาพยืนยัน && รูปถ่ายสินค้า
      attachPhotos: [],
      animationUploadFirst: false,

      // ใช้ หลักฐานการโทร
      attachSecondPhoto: [],

      //viewImageSrc
      isOpenLightbox: false,
      viewImageSrc: '',
    });
  };

  onChangeTopicStatus = () => {
    this.setState({
      //วันที่ลูกค้าเลื่อนส่ง
      delayDate: '',
      delayDateShow: '',
      delayDateInvalid: false,

      // ใช้ หลักฐานการโทร
      attachSecondPhoto: [],

      //viewImageSrc
      isOpenLightbox: false,
      viewImageSrc: '',
    });
  };

  checkInvalid = () => {
    switch (this.state.finalStatus.value) {
      //Delivered
      case 3:
        this.setState({
          attachPhotosInvalid: !(this.state.attachPhotos.length > 0),
        });
        console.log('case 3');
        return !(this.state.attachPhotos.length > 0);
        break;
      //Failed Delivery
      case 4:
        console.log('case 4');
        if (this.state.failStatus) {
          switch (this.state.failStatus.value) {
            //ติดต่อลูกค้าไม่ได้
            case 2:
              console.log('case 4-2');
              this.setState({
                attachSecondPhotoInvalid: !(this.state.attachSecondPhoto.length > 0),
                attachThirdPhotosInvalid: !(this.state.attachThirdPhotos.length > 0),
                productStatusInvalid: this.state.productStatus.value ? false : true,
              });
              return !(
                this.state.attachSecondPhoto.length > 0 &&
                this.state.attachThirdPhotos.length > 0 &&
                this.state.productStatus.value
              );
            case 8:
              console.log('case 4-8');
              this.setState({
                delayDateInvalid: this.state.delayDate ? false : true,

                attachThirdPhotosInvalid: !(this.state.attachThirdPhotos.length > 0),
                productStatusInvalid: this.state.productStatus.value ? false : true,
              });
              return !(
                this.state.delayDate &&
                this.state.attachThirdPhotos.length > 0 &&
                this.state.productStatus.value
              );
            case 11:
              console.log('case 4-10');
              this.setState({
                problemDetailInvalid: this.state.problemDetail ? false : true,

                attachThirdPhotosInvalid: !(this.state.attachThirdPhotos.length > 0),
                productStatusInvalid: this.state.productStatus.value ? false : true,
              });
              return !(
                this.state.problemDetail &&
                this.state.attachThirdPhotos.length > 0 &&
                this.state.productStatus.value
              );
            default:
              console.log('case 4-default');
              this.setState({
                attachThirdPhotosInvalid: !(this.state.attachThirdPhotos.length > 0),
                productStatusInvalid: this.state.productStatus.value ? false : true,
              });
              return !(this.state.attachThirdPhotos.length > 0 && this.state.productStatus.value);
          }
        } else {
          this.setState({
            failStatusInvalid: true,
          });
          return true;
        }
        break;
      //No Pick Up
      case 7:
        console.log('case 7');
        return false;
      default:
        console.log('default');
    }
  };

  handlerSubmit = () => {
    if (this.checkInvalid()) {
      console.log('Invalid');
    } else {
      console.log('valid');

      let finalData = {};

      //complete
      if (this.state.finalStatus.value === 3) {
        finalData = {
          _id: this.props.trackingId,
          tracking_detail_lazada: {
            delivery: [
              {
                _id: this.props.deliveryData._id,
                final_status: this.state.finalStatus.value,
                delivery_status: this.state.finalStatus.value,
                commodity_picture_end: this.state.attachPhotos,
                log: this.state.logDetail,
              },
            ],
          },
        };
      } else if (
        this.state.finalStatus.value === 4 ||
        this.state.finalStatus.value === 5 ||
        this.state.finalStatus.value === 9
      ) {
        // fail
        finalData = {
          _id: this.props.trackingId,
          tracking_detail_lazada: {
            delivery: [
              {
                _id: this.props.deliveryData._id,
                final_status: this.state.finalStatus.value,
                delivery_status: this.state.finalStatus.value,
                log: this.state.logDetail,
                commodity_picture_return: this.state.attachThirdPhotos,
                failure_status: this.state.failStatus.value,
                return_status: this.state.productStatus.value,

                // รายละเอียด
                failure_detail: this.state.problemDetail,
                // เลื่อนนัด
                postpone_delivery_date: this.state.delayDate,
                //ติดต่อไม่ได้
                failure_picture: this.state.attachSecondPhoto,
              },
            ],
          },
        };
      } else if (this.state.finalStatus.value === 7) {
        //no pickup
        finalData = {
          _id: this.props.trackingId,
          tracking_detail_lazada: {
            delivery: [
              {
                _id: this.props.deliveryData._id,
                final_status: this.state.finalStatus.value,
                delivery_status: this.state.finalStatus.value,
                log: this.state.logDetail,
              },
            ],
          },
        };
      }

      // console.log(finalData)
      this.setState({
        submitModal: true,
        finalData: finalData,
      });
    }
  };

  handlerMutation = lazTrackingUpdate => {
    console.log(this.state.finalData);
    console.log(lazTrackingUpdate);
    lazTrackingUpdate({
      variables: this.state.finalData,
    });
  };

  render() {
    return (
      <div style={{ display: this.props.openModal ? 'block' : 'none' }}>
        <ModalConfirm
          show={this.props.openModal && this.state.isOpenLightbox ? false : true}
          // onPressButtonCancel={this.props.closeModal}
          onPressButtonConfirm={this.props.closeModal}
          labelButtonConfirm={`ปิด`}
          // style={{width:'calc(50vw - 200px)'}}
          // disabledButtonConfirm={this.state.yearholidays == ''}
          styleColorButtonConfirm="white"
          titleHeader={`เปลี่ยนสถานะ ${this.props.deliveryData.commodity[0].name}`}
          // onHide={this.props.closeModal}
        >
          <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
          ตรวจสอบรายการสินค้าที่ แก้สถานะการขนส่งและยืนยัน
          <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '10px' }}>
            <b>สถานะ:</b>
            <SelectBox
              disabled
              onChange={event => {
                this.setState(prevState => {
                  if (prevState.finalStatus.value !== event.value) {
                    // this.onChangeFinalStatus()
                    return {
                      finalStatus: { value: event.value, label: event.label },
                      finalStatusInvalid: false,
                    };
                  }
                });
              }}
              value={this.state.finalStatus}
              style={{ width: '140px', marginLeft: '10px' }}
              options={selectOptionFinalStatus}
              placeholder="- เลือก -"
              messageError={this.state.finalStatusInvalid && 'กรุณากรอกข้อมูล'}
            />
          </div>
          <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
          {//Delivered
          this.state.finalStatus.value === 3 && (
            <div>
              <Row>
                <Col md={11}>
                  <span style={{ color: this.state.attachPhotosInvalid ? 'red' : 'unset' }}>
                    รูปถ่ายสินค้า(อย่างน้อย 1 รูป)
                  </span>{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col md={12}>
                  <Row>
                    {this.state.attachPhotos.length > 0 &&
                      this.state.attachPhotos.map(data => (
                        <Col key={data} md={2} style={{ marginTop: '10px' }}>
                          <CustomPhoto>
                            <Image
                              onClick={() => this.renderLightbox(data)}
                              style={{ width: '100%' }}
                              src={s3 + '/' + data}
                              rounded
                            />
                          </CustomPhoto>
                        </Col>
                      ))}
                    {this.state.animationUploadFirst && (
                      <Col md={2} style={{ marginTop: '10px' }}>
                        <CustomPhoto
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ width: '100%', height: '100%' }}>
                            <i
                              class="fad fa-spinner-third fa-spin"
                              style={{ fontSize: '50px', top: 'unset', right: 'unset' }}
                            ></i>
                          </div>
                        </CustomPhoto>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          )}
          {//Failed Delivery
          (this.state.finalStatus.value === 4 ||
            this.state.finalStatus.value === 5 ||
            this.state.finalStatus.value === 9) && (
            <div>
              <Row>
                <Col md={6}>
                  <SelectBox
                    disabled
                    onChange={event => {
                      this.setState(prevState => {
                        if (prevState.failStatus.value !== event.value) {
                          this.onChangeTopicStatus();
                          return {
                            failStatus: { value: event.value, label: event.label },
                            failStatusInvalid: false,
                          };
                        }
                      });
                    }}
                    value={this.state.failStatus}
                    options={selectOptionFailDeliveryStatus}
                    inputLabel="หัวข้อปัญหา"
                    placeholder="- เลือก -"
                    required
                    messageError={this.state.failStatusInvalid && 'กรุณากรอกข้อมูล'}
                  />
                </Col>
                <Col md={6}>
                  {// ติดต่อลูกค้าไม่ได้
                  this.state.failStatus.value === 2 && (
                    <div>
                      <span
                        style={{
                          color: this.state.attachSecondPhotoInvalid ? 'red' : 'unset',
                          fontWeight: 'bold',
                        }}
                      >
                        หลักฐานการโทร
                      </span>
                      <span style={{ color: 'red' }}>*</span>
                      <Row style={{ height: '200px', overflow: 'auto' }}>
                        <Col md={12}>
                          <Row>
                            {this.state.attachSecondPhoto.length > 0 &&
                              this.state.attachSecondPhoto.map(data => (
                                <Col key={data} md={4} style={{ marginTop: '10px' }}>
                                  <CustomPhoto>
                                    <Image
                                      onClick={() => this.renderLightbox(data)}
                                      style={{ width: '100%' }}
                                      src={s3 + '/' + data}
                                      rounded
                                    />
                                  </CustomPhoto>
                                </Col>
                              ))}
                            {this.state.animationUploadSecond && (
                              <Col md={2} style={{ marginTop: '10px' }}>
                                <CustomPhoto
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{ width: '100%', height: '100%' }}>
                                    <i
                                      class="fad fa-spinner-third fa-spin"
                                      style={{ fontSize: '50px', top: 'unset', right: 'unset' }}
                                    ></i>
                                  </div>
                                </CustomPhoto>
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {//ลูกค้าเลื่อน
                  this.state.failStatus.value === 8 && (
                    <div>
                      <b>
                        วันที่ลูกค้าเลื่อนส่ง<span style={{ color: 'red' }}>*</span>
                      </b>
                      <div
                        style={{
                          border: this.state.delayDateInvalid && '1px solid #FF0000',
                          borderRadius: '5px',
                        }}
                      >
                        <Datetime
                          value={this.state.delayDateShow}
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm"
                          inputProps={{ placeholder: '00/00/00 - 00:00น', disabled: true }}
                          // className="caseModal"
                          input={true}
                          onChange={event => {
                            this.handleDatePicker(event);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>

              {// อื่นๆ
              this.state.failStatus.value === 11 && (
                <Row style={{ marginTop: '10px' }}>
                  <Col md={11}>
                    <TextArea
                      disabled
                      onChange={event =>
                        this.setState({
                          problemDetail: event.target.value,
                          problemDetailInvalid: false,
                        })
                      }
                      value={this.state.problemDetail}
                      inputLabel="รายละเอียดปัญหา"
                      style={{ marginTop: '10px' }}
                      placeholder="ระบุ"
                      required
                      messageError={this.state.problemDetailInvalid && 'กรุณากรอกข้อมูล'}
                    />
                  </Col>
                </Row>
              )}

              {// นอกเหนือจากข้างบน
              this.state.failStatus.value !== 2 && this.state.failStatus.value !== 8 && (
                <Row style={{ marginTop: '10px' }}>
                  <Col md={11}>
                    {
                      //Nothing
                    }
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: '20px' }}>
                <Col md={6}>
                  <span style={{ fontWeight: 'bold', fontSize: '18px' }}>การคืนคลัง</span>
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col md={6}>
                  <SelectBox
                    disabled
                    onChange={event =>
                      this.setState({
                        productStatus: { value: event.value, label: event.label },
                        productStatusInvalid: false,
                      })
                    }
                    value={this.state.productStatus}
                    options={selectOptionProductStatus}
                    inputLabel="สถานะสินค้าขณะคืนคลัง"
                    placeholder="- เลือก -"
                    required
                    messageError={this.state.productStatusInvalid && 'กรุณากรอกข้อมูล'}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: '10px' }}>
                <Col md={11}>
                  <span
                    style={{
                      color: this.state.attachThirdPhotosInvalid ? 'red' : 'unset',
                      fontWeight: 'bold',
                    }}
                  >
                    แนบรูปภาพยืนยัน{' '}
                  </span>{' '}
                  <span style={{ color: 'red' }}>*</span>
                </Col>
                <Col md={12}>
                  <Row>
                    {this.state.attachThirdPhotos.length > 0 &&
                      this.state.attachThirdPhotos.map(data => (
                        <Col key={data} md={2} style={{ marginTop: '10px' }}>
                          <CustomPhoto>
                            <Image
                              onClick={() => this.renderLightbox(data)}
                              style={{ width: '100%' }}
                              src={s3 + '/' + data}
                              rounded
                            />
                          </CustomPhoto>
                        </Col>
                      ))}
                    {this.state.animationUploadThird && (
                      <Col md={2} style={{ marginTop: '10px' }}>
                        <CustomPhoto
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ width: '100%', height: '100%' }}>
                            <i
                              class="fad fa-spinner-third fa-spin"
                              style={{ fontSize: '50px', top: 'unset', right: 'unset' }}
                            ></i>
                          </div>
                        </CustomPhoto>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </div>
          )}
          {//No Pick Up
          this.state.finalStatus.value === 7 && (
            <div style={{ marginTop: '100px', marginBottom: '100px' }}></div>
          )}
          {
            //Upload Photo แนบรูปภาพยืนยัน && รูปถ่ายสินค้า
          }
          <Mutation
            mutation={singleUploadMutation}
            update={(cache, { data }) => {
              this._inputAttachFileFirstCase.current.value = '';
              // console.log(data.singleUpload.path);
              this.setState(previousState => ({
                attachPhotos: [...previousState.attachPhotos, data.singleUpload.path],
                attachPhotosInvalid: false,
                animationUploadFirst: false,
              }));
            }}
            onError={error => {
              console.log(error);
            }}
          >
            {uploadFile => {
              return (
                <input
                  accept="image/jpeg,image/png"
                  onChange={event => {
                    const files = event.target.files[0];
                    const checkFileTypeImage = this.checkFileTypeImage(files);
                    if (checkFileTypeImage === false) {
                      this.addNotification(
                        'error',
                        'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
                      );
                      return;
                    }
                    files && this.setState({ animationUploadFirst: true });
                    this._inputAttachFileFirstCase.current.files[0] &&
                      this.handleSingleUploadDocumentCase(
                        uploadFile,
                        this._inputAttachFileFirstCase.current.files[0].name.split('.')[0],
                        event
                      );
                  }}
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  ref={this._inputAttachFileFirstCase}
                />
              );
            }}
          </Mutation>
          {
            //Upload Photo หลักฐานการโทร
          }
          <Mutation
            mutation={singleUploadMutation}
            update={(cache, { data }) => {
              this._inputAttachFileSecondCase.current.value = '';
              // console.log(data.singleUpload.path);
              this.setState(previousState => ({
                attachSecondPhoto: [...previousState.attachSecondPhoto, data.singleUpload.path],
                attachSecondPhotoInvalid: false,
                animationUploadSecond: false,
              }));
            }}
            onError={error => {
              console.log(error);
            }}
          >
            {uploadFile => {
              return (
                <input
                  accept="image/jpeg,image/png"
                  onChange={event => {
                    const files = event.target.files[0];
                    const checkFileTypeImage = this.checkFileTypeImage(files);
                    if (checkFileTypeImage === false) {
                      this.addNotification(
                        'error',
                        'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
                      );
                      return;
                    }
                    files && this.setState({ animationUploadSecond: true });
                    this._inputAttachFileSecondCase.current.files[0] &&
                      this.handleSingleUploadDocumentCase(
                        uploadFile,
                        this._inputAttachFileSecondCase.current.files[0].name.split('.')[0],
                        event
                      );
                  }}
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  ref={this._inputAttachFileSecondCase}
                />
              );
            }}
          </Mutation>
          {
            //Upload Photo หลักฐานการโทร
          }
          <Mutation
            mutation={singleUploadMutation}
            update={(cache, { data }) => {
              this._inputAttachFileThirdCase.current.value = '';
              // console.log(data.singleUpload.path);
              this.setState(previousState => ({
                attachThirdPhotos: [...previousState.attachThirdPhotos, data.singleUpload.path],
                attachThirdPhotosInvalid: false,
                animationUploadThird: false,
              }));
            }}
            onError={error => {
              console.log(error);
            }}
          >
            {uploadFile => {
              return (
                <input
                  accept="image/jpeg,image/png"
                  onChange={event => {
                    const files = event.target.files[0];
                    const checkFileTypeImage = this.checkFileTypeImage(files);
                    if (checkFileTypeImage === false) {
                      this.addNotification(
                        'error',
                        'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png เท่านั้น'
                      );
                      return;
                    }
                    files && this.setState({ animationUploadThird: true });
                    this._inputAttachFileThirdCase.current.files[0] &&
                      this.handleSingleUploadDocumentCase(
                        uploadFile,
                        this._inputAttachFileThirdCase.current.files[0].name.split('.')[0],
                        event
                      );
                  }}
                  type="file"
                  id="file"
                  style={{ display: 'none' }}
                  ref={this._inputAttachFileThirdCase}
                />
              );
            }}
          </Mutation>
        </ModalConfirm>

        <Mutation
          mutation={lazTrackingUpdateMutation}
          update={(cache, { data }) => {
            this.props.closeModal();
          }}
          onError={error => {
            console.log(error);
            this.addNotification('error', error.message.replace('GraphQL error: ', ''));
          }}
          refetchQueries={() => ['shipmentAgentView']}
        >
          {lazTrackingUpdate => {
            return (
              <Modal
                show={this.state.submitModal}
                onHide={() => this.setState({ submitModal: false })}
                style={{ marginTop: '100px' }}
              >
                <Modal.Body
                  style={{
                    height: '150px',
                    padding: 'unset',
                    backgroundColor: '#F7F7F8',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        flex: 2,
                        marginTop: '20px',
                        marginLeft: '20px',
                        marginRight: '20px',
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}
                    >
                      ยืนยันการเปลี่ยนสถานะใช่หรือไม่
                    </div>
                    <div
                      style={{
                        width: '100%',
                        flex: 2,
                      }}
                    >
                      <Button
                        style={{ left: '50px', position: 'absolute' }}
                        onClick={() => this.setState({ submitModal: false })}
                      >
                        ไม่ใช่
                      </Button>
                      <Button
                        style={{
                          right: '50px',
                          position: 'absolute',
                          backgroundColor: 'rgb(93, 186, 91)',
                          color: '#fff',
                        }}
                        onClick={() => this.handlerMutation(lazTrackingUpdate)}
                      >
                        ใช่, ยืนยัน
                      </Button>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            );
          }}
        </Mutation>

        {this.state.isOpenLightbox && (
          <Lightbox
            style={{ zIndex: '9999' }}
            mainSrc={this.state.viewImageSrc}
            onCloseRequest={() => this.setState({ isOpenLightbox: false, viewImageSrc: '' })}
          />
        )}
      </div>
    );
  }
}

export default ChangeFinalStatusModal;
