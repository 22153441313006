import React, { Component , useEffect } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col ,Table,Button, Modal , FormControl } from "react-bootstrap";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import Chart from "react-apexcharts";
import moment from 'moment';

import styled from 'styled-components';
import { client } from "../..";
import { Query, graphql, compose, Mutation } from "react-apollo";
import paymentDashboardQuery from "./graphql/query/summaryDashboardPayment"
import shipmentDashboardQuery from "./graphql/query/shipmentDashboard"
import shipmentSummaryDashboardQuery from "./graphql/query/shipmentSummaryDashboard"
import shipmentSummaryDashboardByDayQuery from "./graphql/query/shipmentSummaryDashboardByDay"
import externalSaleProfitListQuery from "./graphql/query/externalSaleProfitList"
import externalSaleProfitAddUpdateMutation from "./graphql/mutation/externalSaleProfitAddUpdate"
import adminListQuery from "./graphql/query/adminList"
//import externalSaleProfitAddUpdateMutation from "./graphql/mutation/externalSaleProfitAddUpdate"

const Container = styled.div`
    & .table > thead > tr > th {
        border-bottom-width: 1px;
        font-size: 18px;
        text-transform: uppercase;
        color: #666666;
        font-weight: 400;
        padding-bottom: 5px;
        text-align: center;
    }
`;

const BoxDashboard = styled(Row)`
    border: 1px solid #dddddd;
    margin-right: 0px !important;
    margin-left: 0px !important;
    border-radius: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom:30px;

    & .col-md-4{
        border-right: 1px solid #dddddd;
    }
    
    & .col-md-4:last-child{
        border-right: none;
    }

    & h4{
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }
`;

const WrapperChart = styled(Row)`
    & svg{
        position: absolute;
        left:0;  
    }

    & .card .ct-chart {
        margin: 50px 0 0;
        height: 270px;
    }

    & chartist.ct-tooltip {
        position: absolute;
        display: inline-block;
        min-width: 5em;
        padding: 8px 10px;
        background: #383838;
        color: #fff;
        text-align: center;
        pointer-events: none;
        z-index: 100;
        transition: opacity .2s linear;
     
        // Arrow 
        &:before {
            position: absolute;
            bottom: -14px;
            left: 50%;
            border: solid transparent;
            content: ' ';
            height: 0;
            width: 0;
            pointer-events: none;
            border-color: rgba(251, 249, 228, 0);
            border-top-color: #383838;
            border-width: 7px;
            margin-left: -8px;
        }
     
        &.hide {
            display: block;
            opacity: 0;
            visibility: hidden;
        }
    }
`;

const TableExtra = styled(Table)`
    &.table > tbody > tr > td{
        vertical-align: top;  
    }
`;

const ChartWrapper = styled(Row)`
    margin: 20px 15px 30px;
    height: 450px;
`;
const ChartWrapperShipment = styled(Row)`
    margin: 20px 15px 30px;
    height: 250px;
`;

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nowValue  : [],
            beforeValue  : [],
            openEdit : false,
            gtx_sale_target: 0,
            ondemand_sale_target: 0,
            month_price : null,
            month_shipment : null,
            show_month_price : null,
            show_month_shipment : null,
            issue_date : null,
            show_issue_date : null,
        };
    }

    handlePriceRangePicker(event, picker,refetch) {
        const start = picker.startDate.format('YYYY-MM').split("-");
        const end = picker.endDate.format('YYYY-MM').split("-");
        
        let arrayDate = [];
        if(start[0] == end[0]){
            for(var i = start[1];i <= end[1]; i++){
                arrayDate.push(`${start[0]}-${i < 10  && i != start[1] ? `0${i}` : i}`)
            }
        }
        else{
            for(var i = start[0];i <= end[0]; i++){
                if( i < end[0]){
                    if(i == start[0]){
                        for(var j = start[1];j <= 12;j++){
                            arrayDate.push(`${i}-${j < 10  && j != start[1] ? `0${j}` : j}`)
                        }
                    }
                    else{
                        for(var k = 1 ; k<= 12; k++){
                            arrayDate.push(`${i}-${k < 10 ? `0${k}` : k}`)
                        }
                    }
                }
                else{
                    for(var l = 1 ; l <= end[1]; l++){
                        arrayDate.push(`${end[0]}-${l < 10 ? `0${l}` : l}`)
                    }
                }
                
            }
        }

        const date = arrayDate;
        const show = `${picker.startDate.format('MM/YYYY')} - ${picker.endDate.format('MM/YYYY')}`;
        this.setState({ month_price: date , show_month_price: show},() => { refetch() });
    }

    handleShipmentRangePicker(event, picker,refetch) {
        const start = picker.startDate.format('YYYY-MM').split("-");
        const end = picker.endDate.format('YYYY-MM').split("-");
        
        let arrayDate = [];
        if(start[0] == end[0]){
            for(var i = start[1];i <= end[1]; i++){
                arrayDate.push(`${start[0]}-${i < 10 && i != start[1] ? `0${i}` : i}`)
            }
        }
        else{
            for(var i = start[0];i <= end[0]; i++){
                if( i < end[0]){
                    if(i == start[0]){
                        for(var j = start[1];j <= 12;j++){
                            arrayDate.push(`${i}-${j < 10  && j != start[1] ? `0${j}` : j}`)
                        }
                    }
                    else{
                        for(var k = 1 ; k<= 12; k++){
                            arrayDate.push(`${i}-${k < 10 ? `0${k}` : k}`)
                        }
                    }
                }
                else{
                    for(var l = 1 ; l <= end[1]; l++){
                        arrayDate.push(`${end[0]}-${l < 10 ? `0${l}` : l}`)
                    }
                }
                
            }
        }

        const date = arrayDate;
        const show = `${picker.startDate.format('MM/YYYY')} - ${picker.endDate.format('MM/YYYY')}`;
        this.setState({ month_shipment: date , show_month_shipment: show},() => { refetch() });
    }

    renderColorLegend(key){
        switch (key) {
            case 2:
                return <i style={{color:"#FFA534"}} className="fas fa-circle"></i>;
            case 1:
                return <i style={{color:"#FB404B"}} className="fas fa-circle"></i>;
            case 0:
                return <i style={{color:"#1DC7EA"}} className="fas fa-circle"></i>;
            case 3:
                return <i style={{color:"#9368E9"}} className="fas fa-circle"></i>;
            case 4:
                return <i style={{color:"#87CB16"}} className="fas fa-circle"></i>;
            case 5:
                return <i style={{color:"#1b8dff"}} className="fas fa-circle"></i>;
            case 6:
                return <i style={{color:"#5e5e5e"}} className="fas fa-circle"></i>;
            case 7:
                return <i style={{color:"#dd4b39"}} className="fas fa-circle"></i>;
            case 8:
                return <i style={{color:"#35465c"}} className="fas fa-circle"></i>;
            case 9:
                return <i style={{color:"#e52d27"}} className="fas fa-circle"></i>;
            case 10:
                return <i style={{color:"#55acee"}} className="fas fa-circle"></i>;
            case 11:
                return <i style={{color:"#cc2127"}} className="fas fa-circle"></i>;
            default:
                return <i className="fas fa-circle"></i>;
        }
    }

    createLegend(json) {
        var legend = [];
        for (var i = 0; i < json["names"].length; i++) {
        var type = "fa fa-circle";
        legend.push(<i style={{color:`#${json["types"][i]}`}} className={type} key={i} />);
        legend.push(" ");
        legend.push(json["names"][i]);
        }
        return legend;
    }

    percent(object,now){
        const per = ((object - now)/now)*100;

        if(now < object){
            return per;
        }
        else{
            return per * -1;
        }
        
    }

    renderFilterPriceExtra(data,type,mode){
        const obj = data.filter(item => item.external_type === type);
        let price = 0;
        if(obj.length > 0){
            switch(mode) {
                case "sale":
                    price = obj[0].sale;
                    break;
                case "sale_target":
                    price = obj[0].sale_target;
                    break;
                case "profit":
                    price = obj[0].profit;
                    break;
                case "profit_target":
                    price = obj[0].profit_target;
                    break;
                default:
                    price = 0;
            }
        }
        return price;
    }

    renderComparePriceExtra(data_before,data_now,type,mode,color){
        //console.log("renderComparePriceExtra",now)
        const before = data_before.filter(item => item.external_type === type);
        const now = data_now.filter(item => item.external_type === type);

        //console.log("renderComparePriceExtra",now);
        if(color){
            if(mode == "sale_target"){
                if(now.length > 0){
                    if(Number(now[0].sale) > Number(now[0].sale_target)){
                        return "#87CB16";
                    }
                    return "#da0d0d";
                }
                return "#000000";
            }
            else if(mode == "profit_target"){
                if(now.length > 0){
                    if(Number(now[0].profit) > Number(now[0].profit_target)){
                        return "#87CB16";
                    }
                    return "#da0d0d";
                }
                return "#000000";
            }
        }
        else{
            if(type != 5){
                if(mode == "sale_target"){
                    if(now.length > 0){
                        return now[0].sale - now[0].sale_target;
                    }
                    return 0 - now[0].sale_target;
                }
                else if(mode == "profit_target"){
                    if(now.length > 0){
                        return now[0].profit - now[0].profit_target;
                    }
                    return 0 - now[0].profit_target;
                }
                else if(mode == "sale"){
                    if(now.length > 0){
                        return now[0].sale - before[0].sale;
                    }
                    return 0 - before[0].sale;
                }
                else if(mode == "profit"){
                    if(now.length > 0){
                        return now[0].profit - before[0].profit;
                    }
                    return 0 - before[0].profit;
                }
            }
            else{
                if(mode == "sale"){
                    return this.renderSumPriceExtra(data_now,"sale") - now[0].sale_target;
                }
                return this.renderSumPriceExtra(data_now,"profit") - now[0].profit_target;
            }
        }
    }

    renderCompareTarget(target,now,mode){
        if(mode == "price"){
            if(now <= target){
                return  (target - now).toLocaleString('en-US');
            }
            else{
                return  (now - target).toLocaleString('en-US');
            }
        }
        else if(mode == "icon"){
            if(now <= target){
                return <i className="fas fa-hand-peace text-warning" style={{fontSize:42}}></i>;
            }
            else{
                return <i className="fas fa-smile text-success" style={{fontSize:42}}></i>;
            }
        }
        else{
            if(now <= target){
                return  `น้อยกว่าเป้าหมาย ${Math.round(this.percent(target,now)).toLocaleString('en-US')}%`;
            }
            else{
                return  `มากกว่าเป้าหมาย ${Math.round(this.percent(target,now)).toLocaleString('en-US')}%`;
            }
        }
    }

    renderSumPriceExtra(data_now,mode){        
        let sum = 0;        
        for(var i=1;i<5;i++){
            const now = data_now.filter(item => item.external_type === i);
            if(now.length > 0){
                sum += now[0][mode];
            }
        }

        return sum
    }

    /* form update */
    openEditPriceExtra(nowValue,beforeValue){
      this.setState({nowValue,beforeValue,
        openEdit:true,
        gtx_sale_target: nowValue.filter(item => item.external_type === 5)[0].sale_target,
        ondemand_sale_target: nowValue.filter(item => item.external_type === 6)[0].sale_target,});
    }
    updateEditPriceExtra(){
        this.setState({nowValue : [],beforeValue: [],openEdit:false});
    }
    renderVariablesExternalSaleProfit(){
        var sale_profit = [];
        this.state.beforeValue.map((item, key) => {
            const data = {
                mode:  "before",
                external_type: item.external_type,
                sale: item.sale,
                profit: item.profit,
            }
            sale_profit.push(data);
        });

        this.state.nowValue.map((item, key) => {
            const data = {
                mode:  "now",
                external_type: item.external_type,
                sale: item.sale,
                sale_target: item.sale_target,
                profit: item.profit,
                profit_target: item.profit_target
            }
            sale_profit.push(data);
        });

        const gtx_target = {
          mode:  "now",
          external_type: 5,
          sale_target: this.state.gtx_sale_target,
        }
        sale_profit.push(gtx_target);


        const ondemand_target = {
          mode:  "now",
          external_type: 6,
          sale_target: this.state.ondemand_sale_target,
        }
        sale_profit.push(ondemand_target);

        return {sale_profit};
    }

    renderValuePriceExtra(data,type,mode){
        const obj = data.filter(item => item.external_type === type);
        let price = 0;
        if(obj.length > 0){
            switch(mode) {
                case "sale":
                    price = obj[0].sale;
                    break;
                case "sale_target":
                    price = obj[0].sale_target;
                    break;
                case "profit":
                    price = obj[0].profit;
                    break;
                case "profit_target":
                    price = obj[0].profit_target;
                    break;
                default:
                    price = 0;
              }
        }
        return price;
    }
    
    setValuePriceExtra(time,value,type,mode){
        if(time == "before"){
            switch(mode) {
                case "sale":
                    this.setState(state => {
                        const list = state.beforeValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "sale" : value
                             }) : item
                        ));
                        return {
                            beforeValue : list,
                        };
                    });
                    break;
                case "sale_target":
                    this.setState(state => {
                        const list = state.beforeValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "sale_target" : value
                             }) : item
                        ));
                        return {
                            beforeValue : list,
                        };
                    });
                    break;
                case "profit":
                    this.setState(state => {
                        const list = state.beforeValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "profit" : value
                             }) : item
                        ));
                        return {
                            beforeValue : list,
                        };
                    });
                    break;
                case "profit_target":
                    this.setState(state => {
                        const list = state.beforeValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "profit_target" : value
                             }) : item
                        ));
                        return {
                            beforeValue : list,
                        };
                    });
                    break;
                default:
                    return false;
            }
        }
        else if(time == "now"){
            switch(mode) {
                case "sale":
                    this.setState(state => {
                        const list = state.nowValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "sale" : value
                                }) : item
                        ));
                        return {
                            nowValue : list,
                        };
                    });
                    break;
                case "sale_target":
                    this.setState(state => {
                        const list = state.nowValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "sale_target" : value
                                }) : item
                        ));
                        return {
                            nowValue : list,
                        };
                    });
                    break;
                case "profit":
                    this.setState(state => {
                        const list = state.nowValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "profit" : value
                                }) : item
                        ));
                        return {
                            nowValue : list,
                        };
                    });
                    break;
                case "profit_target":
                    this.setState(state => {
                        const list = state.nowValue.map(item => (
                            item.external_type == type ? Object.assign({}, item, {
                                "profit_target" : value
                                }) : item
                        ));
                        return {
                            nowValue : list,
                        };
                    });
                    break;
                default:
                    return false;
            }
        }
        //"before",event.target.value,1,"sale"
    }

    checkDisableExternalSaleProfit(){
    }

    /* Chart */
    renderOptionsAreaChart(){
        var options = {
            chart: {
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },           
            xaxis: {
                categories: [
                    "1","2","3","4","5","6","7","8","9","10",
                    "11","12","13","14","15","16","17","18","19","20",
                    "21","22","23","24","25","26","27","28","29","30","31",
                ]
            },
            grid: {
                row: {
                    colors: ['#fafafa', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            stroke: {
                curve: 'smooth'
            },
            tooltip: {
                shared: true,
                onDatasetHover: {
                    highlightDataSeries: true,
                },
                x: {
                    show: true,
                    formatter: (seriesName) => `วันที่ ${seriesName}`
                },
                y: {
                    formatter: (seriesName) => `${seriesName != undefined ? seriesName.toLocaleString('en-US') : seriesName}`,
                    title: {
                        formatter: (seriesName) => `${moment(seriesName).format('MM/YYYY')} `,
                    },
                },
            },
            fill: {
              type: 'gradient',
              gradient: {
                opacityFrom: 0.8,
                opacityTo: 0,
              }
            },
            legend: {
              position: 'top',
              horizontalAlign: 'left'
            },
        };

        return options;
    }

    renderDataAreaChart(obj,mode){
        let series = [];
        if(mode == "price"){
            obj.month.forEach((element,key) => {
                const data = {
                    name: element,
                    data: obj.price[key]
                }
                series.push(data);
            });
        }
        else if(mode == "shipment"){
            obj.month.forEach((element,key) => {
                const data = {
                    name: element,
                    data: obj.shipment[key]
                }
                series.push(data);
            });
        }
        return series;
    }

    render() {
        // Data for Pie Chart
        var dataPie = {
            labels: ["40%", "20%", "40%"],
            series: [40, 20, 40]
        };
        var legendPie = {
            names: ["ภายในประเทศ", "นำเข้าสินค้า", "ส่งออกสินค้า"],
            types: ["info", "danger", "warning"]
        };

        // Data for Line Chart
        var responsiveSales = [
            [
            "screen and (max-width: 640px)",
            {
                axisX: {
                labelInterpolationFnc: function(value) {
                    return value[0];
                }
                }
            }
            ]
        ];

        return (
            <Container className="content">
                <Grid fluid>
                <Query
                        query={shipmentSummaryDashboardQuery}
                    >
                        {
                            ({ loading, error, data, refetch, variables }) => {
                                if (loading) return "loading..";
                                if (error) return error;
                                //console.log(variables ,data);
                                const refetchshipmentSummaryDashboard = refetch;
                                const dashboard = data.shipmentSummaryDashboard;
                                return (
                                <Query
                                    query={externalSaleProfitListQuery}
                                >
                                {
                                    ({ loading, error, data, refetch, variables }) => {
                                        if (loading) return null;
                                        if (error) return null;
                                        // //console.log(variables);
                                        const refetchExternalSaleProfitListQuery = refetch;
                                        const externalSaleProfitList = data.externalSaleProfitList;
                                        const before = externalSaleProfitList.before;
                                        const now = externalSaleProfitList.now;
                                        return (
                                            <div>
                                                <Row>
                                                    <Col md={12}>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Card
                                                                    content={
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={6} style={{ float: "left" }}>
                                                                                    <p className="category">เปรียบเทียบยอดกับรายรับทั้งหมด</p>
                                                                                    <h3 className="title">Giztix</h3>
                                                                                </Col>
                                                                                <Col md={6} style={{ float: "right",textAlign:"right",paddingTop:15 }}>
                                                                                    <h2 className="title">฿ {this.renderFilterPriceExtra(now,5,"sale_target").toLocaleString('en-US')}</h2>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <hr/>
                                                                                <Col md={6} style={{ float: "left" }}>
                                                                                    {this.renderCompareTarget(this.renderFilterPriceExtra(now,5,"sale_target"),(this.renderSumPriceExtra(now,"sale") + dashboard.nowTotalAmount),"icon")}
                                                                                </Col>
                                                                                <Col md={6} style={{ float: "right",textAlign:"right" }}>
                                                                                    <h3 className="title">฿{this.renderCompareTarget(this.renderFilterPriceExtra(now,5,"sale_target"),(this.renderSumPriceExtra(now,"sale") + dashboard.nowTotalAmount),"price")}</h3>
                                                                                    <p className="category">{this.renderCompareTarget(this.renderFilterPriceExtra(now,5,"sale_target"),(this.renderSumPriceExtra(now,"sale") + dashboard.nowTotalAmount),"percent")}</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col md={6}>
                                                                <Card
                                                                    content={
                                                                        <div>
                                                                            <Row>
                                                                                <Col md={6} style={{ float: "left" }}>
                                                                                    <p className="category">เปรียบเทียบยอดกับยอดขาย on Demand</p>
                                                                                    <h3 className="title">On Demand</h3>
                                                                                </Col>
                                                                                <Col md={6} style={{ float: "right",textAlign:"right",paddingTop:15 }}>
                                                                                    <h2 className="title">฿ {this.renderFilterPriceExtra(now,6,"sale_target").toLocaleString('en-US')}</h2>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <hr/>
                                                                                <Col md={6} style={{ float: "left" }}>
                                                                                {this.renderCompareTarget(this.renderFilterPriceExtra(now,6,"sale_target"),dashboard.nowTotalAmount,"icon")}
                                                                                </Col>
                                                                                <Col md={6} style={{ float: "right",textAlign:"right" }}>
                                                                                    <h3 className="title">฿{this.renderCompareTarget(this.renderFilterPriceExtra(now,6,"sale_target"),dashboard.nowTotalAmount,"price")}</h3>
                                                                                    <p className="category">{this.renderCompareTarget(this.renderFilterPriceExtra(now,6,"sale_target"),dashboard.nowTotalAmount,"percent")}</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                
                                                <Query
                                                    query={shipmentSummaryDashboardByDayQuery}
                                                    variables={{
                                                        month_arrary: this.state.month_price
                                                    }}
                                                >
                                                    {
                                                        ({ loading, error, data, refetch, variables }) => {
                                                            if (loading) return "loading..";
                                                            if (error) return null;
                                                            console.log("shipmentSummaryDashboardByDayQuery" , data);

                                                            const obj = data.shipmentSummaryDashboardByDay;
                                                            
                                                            return (
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Card
                                                                            statsIcon="fa fa-history"
                                                                            id="chartHours"
                                                                            title="GMV by days"
                                                                            category="24 Hours performance"
                                                                            rightButton={ 
                                                                                <div style={{ float: "right" }}>
                                                                                    <DateRangePicker 
                                                                                        DatePicker=  {false}
                                                                                        onApply={(event,picker) => this.handlePriceRangePicker(event,picker,refetch)} 
                                                                                        onCancel={() => this.setState({month_price : null,show_month_price : null},() => { refetch() })} 
                                                                                        containerStyles={{display: "block"}}
                                                                                        opens='right'
                                                                                        format='MM/YYYY'
                                                                                    >
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            value={this.state.show_month_price}
                                                                                            placeholder="เลือกช่วงเวลา"
                                                                                            name="pick_up_date"
                                                                                        />
                                                                                    </DateRangePicker>
                                                                                </div>
                                                                            }
                                                                            content={
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <ChartWrapper>
                                                                                            <Chart
                                                                                                options={this.renderOptionsAreaChart()} 
                                                                                                series={this.renderDataAreaChart(obj,"price")}
                                                                                                type="area"
                                                                                                height="450" 
                                                                                            />
                                                                                        </ChartWrapper>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <h5>ตารางสรุป</h5>
                                                                                        <div className="table-responsive">

                                                                                        <Table striped hover>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>เดือน / จำนวน</th>
                                                                                                    <th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th>
                                                                                                    <th>11</th><th>12</th><th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th><th>19</th><th>20</th>
                                                                                                    <th>21</th><th>22</th><th>23</th><th>24</th><th>25</th><th>26</th><th>27</th><th>28</th><th>29</th><th>30</th><th>31</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    obj.month.map((prop, key) => (
                                                                                                        <tr key={key}>
                                                                                                            <td style={{minWidth:"100px"}}>{prop}</td>
                                                                                                            {
                                                                                                                obj.price[key].length > 0 ?
                                                                                                                obj.price[key].map((data, index) => (
                                                                                                                    <td style={{textAlign:"right"}} key={index}>{data.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                                                )) : ( <td style={{textAlign:"left"}} colSpan={31}>  ไม่มีข้อมูล </td>) 
                                                                                                            }
                                                                                                        </tr>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    
                                                                                        </div>
                                                                                    </Col>
                                                                                     
                                                                                    <Col md={12}>
                                                                                        <h3>จำนวนงานขนส่งต่อวัน</h3>
                                                                                        <ChartWrapperShipment>
                                                                                            <Chart
                                                                                                options={this.renderOptionsAreaChart()} 
                                                                                                series={this.renderDataAreaChart(obj,"shipment")}
                                                                                                type="area"
                                                                                                height="300" 
                                                                                            />
                                                                                        </ChartWrapperShipment>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <h5>ตารางสรุป</h5>
                                                                                        <div className="table-responsive">

                                                                                        <Table striped hover>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>เดือน / จำนวน</th>
                                                                                                    <th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th>
                                                                                                    <th>11</th><th>12</th><th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th><th>19</th><th>20</th>
                                                                                                    <th>21</th><th>22</th><th>23</th><th>24</th><th>25</th><th>26</th><th>27</th><th>28</th><th>29</th><th>30</th><th>31</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    obj.month.map((prop, key) => (
                                                                                                        <tr key={key}>
                                                                                                            <td style={{minWidth:"100px"}}>{prop}</td>
                                                                                                            {
                                                                                                                obj.shipment[key].length > 0 ?
                                                                                                                obj.shipment[key].map((data, index) => (
                                                                                                                    <td style={{textAlign:"right"}} key={index}>{data.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                                                )) : ( <td style={{textAlign:"left"}} colSpan={31}>  ไม่มีข้อมูล </td>) 
                                                                                                            }
                                                                                                        </tr>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    
                                                                                        </div>
                                                                                    </Col>
                                                                                          
                                                                                </Row>
                                                                                
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                )
                                                        }
                                                    }
                                                </Query>

                                                <Query
                                                    query={shipmentSummaryDashboardByDayQuery}
                                                    variables={{
                                                        month_arrary: this.state.month_shipment,
                                                        sum : true
                                                    }}
                                                >
                                                    {
                                                        ({ loading, error, data, refetch, variables }) => {
                                                            if (loading) return "loading..";
                                                            if (error) return null;
                                                            console.log("shipmentSummaryDashboardByDayQuery" , data);

                                                            const obj = data.shipmentSummaryDashboardByDay;
                                                            
                                                            return (
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Card
                                                                            statsIcon="fa fa-history"
                                                                            id="chartHours"
                                                                            title="GMV by days ยอดรวม"
                                                                            category="24 Hours performance"
                                                                            rightButton={ 
                                                                                <div style={{ float: "right" }}>
                                                                                    <DateRangePicker 
                                                                                        DatePicker=  {false}
                                                                                        onApply={(event,picker) => this.handleShipmentRangePicker(event,picker,refetch)} 
                                                                                        onCancel={() => this.setState({month_shipment : null,show_month_shipment : null},() => { refetch() })} 
                                                                                        containerStyles={{display: "block"}}
                                                                                        opens='right'
                                                                                        format='MM/YYYY'
                                                                                    >
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            value={this.state.show_month_shipment}
                                                                                            placeholder="เลือกช่วงเวลา"
                                                                                            name="pick_up_date"
                                                                                        />
                                                                                    </DateRangePicker>
                                                                                </div>
                                                                            }
                                                                            content={
                                                                                <Row>
                                                                                    <Col md={12}>
                                                                                        <ChartWrapper>
                                                                                            <Chart
                                                                                                options={this.renderOptionsAreaChart()} 
                                                                                                series={this.renderDataAreaChart(obj,"price")}
                                                                                                type="area"
                                                                                                height="450" 
                                                                                            />
                                                                                        </ChartWrapper>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <h5>ตารางสรุป</h5>
                                                                                        <div className="table-responsive">

                                                                                        <Table striped hover>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>เดือน / จำนวน</th>
                                                                                                    <th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th>
                                                                                                    <th>11</th><th>12</th><th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th><th>19</th><th>20</th>
                                                                                                    <th>21</th><th>22</th><th>23</th><th>24</th><th>25</th><th>26</th><th>27</th><th>28</th><th>29</th><th>30</th><th>31</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    obj.month.map((prop, key) => (
                                                                                                        <tr key={key}>
                                                                                                            <td style={{minWidth:"100px"}}> {prop}</td>
                                                                                                            {
                                                                                                                obj.price[key].length > 0 ?
                                                                                                                obj.price[key].map((data, index) => (
                                                                                                                    <td style={{textAlign:"right"}} key={index}>{data.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                                                )) : ( <td style={{textAlign:"left"}} colSpan={31}>  ไม่มีข้อมูล </td>) 
                                                                                                            }
                                                                                                        </tr>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    
                                                                                        </div>
                                                                                    </Col>
                                                                                     
                                                                                    <Col md={12}>
                                                                                        <h3>จำนวนงานขนส่งต่อวัน ยอดรวม</h3>
                                                                                        <ChartWrapperShipment>
                                                                                            <Chart
                                                                                                options={this.renderOptionsAreaChart()} 
                                                                                                series={this.renderDataAreaChart(obj,"shipment")}
                                                                                                type="area"
                                                                                                height="300" 
                                                                                            />
                                                                                        </ChartWrapperShipment>
                                                                                    </Col>
                                                                                    <Col md={12}>
                                                                                        <h5>ตารางสรุป</h5>
                                                                                        <div className="table-responsive">

                                                                                        <Table striped hover>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>เดือน / จำนวน</th>
                                                                                                    <th>1</th><th>2</th><th>3</th><th>4</th><th>5</th><th>6</th><th>7</th><th>8</th><th>9</th><th>10</th>
                                                                                                    <th>11</th><th>12</th><th>13</th><th>14</th><th>15</th><th>16</th><th>17</th><th>18</th><th>19</th><th>20</th>
                                                                                                    <th>21</th><th>22</th><th>23</th><th>24</th><th>25</th><th>26</th><th>27</th><th>28</th><th>29</th><th>30</th><th>31</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {
                                                                                                    obj.month.map((prop, key) => (
                                                                                                        <tr key={key}>
                                                                                                            <td style={{minWidth:"100px"}}>{prop}</td>
                                                                                                            {
                                                                                                                obj.shipment[key].length > 0 ?
                                                                                                                obj.shipment[key].map((data, index) => (
                                                                                                                    <td style={{textAlign:"right"}} key={index}>{data.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                                                                                                                )) : ( <td style={{textAlign:"left"}} colSpan={31}>  ไม่มีข้อมูล </td>) 
                                                                                                            }
                                                                                                        </tr>
                                                                                                    ))
                                                                                                }
                                                                                            </tbody>
                                                                                        </Table>
                                                                                    
                                                                                        </div>
                                                                                    </Col>
                                                                                          
                                                                                </Row>
                                                                                
                                                                            }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                )
                                                        }
                                                    }
                                                </Query>

                                                <Row>
                                                    <Col md={8}>
                                                        <Card
                                                            statsIcon="fa fa-clock-o"
                                                            title="Shipment"
                                                            category="เปรียบเทียบยอดกับเดือนที่แล้ว"
                                                            rightButton={ <div style={{ float: "right" }}>
                                                            <h3 className="title">{dashboard.timeLeft} วัน</h3>
                                                            <p className="category">เวลาที่เหลือ</p>
                                                        </div>}
                                                            stats={null}
                                                            content={
                                                                <Row>
                                                                    <Col md={12}>
                                                                        <Table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{textAlign:'left'}}>
                                                                                        หัวข้อ
                                                                                    </th>
                                                                                    <th style={{textAlign:'left'}}>
                                                                                        ยอดเดือนที่แล้ว
                                                                                    </th>
                                                                                    <th style={{textAlign:'left'}}>
                                                                                        เป้าหมายเดือนนี้
                                                                                    </th>
                                                                                    <th style={{textAlign:'left'}}>
                                                                                        ยอดเดือนนี้
                                                                                    </th>
                                                                                    <th style={{textAlign:'left'}}>
                                                                                        ส่วนต่างจากเดือนที่แล้ว
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        จำนวนงานขนส่ง
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.beforeShipmentAmount}
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.objectiveShipmentAmount}
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{dashboard.nowShipmentAmount}</b> 
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{dashboard.nowShipmentAmount - dashboard.beforeShipmentAmount}</b> 
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        ยอดขายงานขนส่ง
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.beforeTotalAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.objectiveTotalAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{dashboard.nowTotalAmount.toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{(dashboard.nowTotalAmount - dashboard.beforeTotalAmount).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        จำนวนงานยกเลิก
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.beforeShipmentAmountCancel}
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.objectiveShipmentAmountCancel}
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{dashboard.nowShipmentAmountCancel}</b>
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{(dashboard.nowShipmentAmountCancel - dashboard.beforeShipmentAmountCancel)}</b>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        ยอดขายงานยกเลิก
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.beforeTotalAmountCancel.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                                                    </td>
                                                                                    <td>
                                                                                        {dashboard.objectiveTotalAmountCanCel.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{dashboard.nowTotalAmountCancel.toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                                                                    </td>
                                                                                    <td>
                                                                                        <b>{(dashboard.nowTotalAmountCancel - dashboard.beforeTotalAmountCancel).toLocaleString('en-US', { maximumFractionDigits: 2 })}</b>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        />
                                                    </Col>                                                    
                                                    <Col md={4}>
                                                        <Row>
                                                            <Col md={12}>
                                                                <StatsCard
                                                                    bigIcon={dashboard.nowTotalAmount < dashboard.objectiveTotalAmount ? (<i className="fal fa-hand-peace text-warning"></i>) : (<i className="fal fa-smile text-success"></i>)}
                                                                    statsText={"ส่วนต่าง ยอดขายงานขนส่ง"}
                                                                    statsValue={dashboard.nowTotalAmount < dashboard.objectiveTotalAmount ?  (dashboard.objectiveTotalAmount - dashboard.nowTotalAmount).toLocaleString('en-US') : (dashboard.nowTotalAmount - dashboard.objectiveTotalAmount).toLocaleString('en-US')}
                                                                    statsIcon={<i className="fal fa-engine-warning"></i>}
                                                                    statsIconText={<div>{dashboard.nowTotalAmount < dashboard.objectiveTotalAmount ? "น้อยกว่าเป้าหมาย" : "มากกว่าเป้าหมาย"} {Math.round(this.percent(dashboard.objectiveTotalAmount,dashboard.nowTotalAmount)).toLocaleString('en-US')}%</div>}
                                                                />
                                                            </Col>
                                                            <Col md={12}>
                                                                <StatsCard
                                                                    bigIcon={dashboard.nowShipmentAmount < dashboard.objectiveShipmentAmount ? (<i className="fal fa-hand-peace text-warning"></i>) : (<i className="fal fa-smile text-success"></i>)}
                                                                    statsText={"ส่วนต่าง จำนวนงานขนส่ง"}
                                                                    statsValue={dashboard.nowShipmentAmount < dashboard.objectiveShipmentAmount ? (dashboard.objectiveShipmentAmount - dashboard.nowShipmentAmount ).toLocaleString('en-US') : (dashboard.nowShipmentAmount - dashboard.objectiveShipmentAmount).toLocaleString('en-US')}
                                                                    statsIcon={<i className="fal fa-engine-warning"></i>}
                                                                    statsIconText={<div>{dashboard.nowShipmentAmount < dashboard.objectiveShipmentAmount ? "น้อยกว่าเป้าหมาย" : "มากกว่าเป้าหมาย"} {Math.round(this.percent(dashboard.objectiveShipmentAmount,dashboard.nowShipmentAmount)).toLocaleString('en-US')}%</div>}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Mutation
                                                    mutation={externalSaleProfitAddUpdateMutation}
                                                    variables={this.renderVariablesExternalSaleProfit()}
                                                    update={() => {
                                                        this.setState({nowValue : [], beforeValue: [], openEdit:false }, () => { 
                                                            refetchshipmentSummaryDashboard(); 
                                                            refetchExternalSaleProfitListQuery(); 
                                                        });
                                                    }}
                                                    onError={(errors) => {
                                                        console.log(errors);
                                                    }}
                                                >
                                                    {externalSaleProfitAddUpdate => (
                                                    <Row>
                                                        <Col md={12}>
                                                            <Card
                                                                statsIcon="fa fa-clock-o"
                                                                title="รายได้เพิ่มเติม"
                                                                category="เปรียบเทียบยอดกับเดือนที่แล้ว"
                                                                rightButton={ 
                                                                    <Query
                                                                        query={adminListQuery}
                                                                    >
                                                                    {
                                                                        ({ loading, error, data, refetch, variables }) => {
                                                                            if (loading) return null;
                                                                            if (error) return null;
                                                                            if(data.adminList.admin[0].external_sale_edit == 1){
                                                                                return (
                                                                                    <div style={{ float: "right" }}>             
                                                                                        {this.state.openEdit ? (
                                                                                            <div>
                                                                                                <Button style={{marginLeft:"15px"}} onClick={() =>this.setState({nowValue : [], beforeValue: [], openEdit:false })} > ปิด </Button>
                                                                                                <Button style={{marginLeft:"15px"}} bsStyle="success" onClick={() => externalSaleProfitAddUpdate()} > <i className="fal fa-save"></i> บันทึก </Button>
                                                                                            </div>
                                                                                            
                                                                                        ) : (
                                                                                            <Button style={{marginLeft:"15px"}} onClick={() => this.openEditPriceExtra(now,before)} > <i className="fal fa-edit"></i> แก้ไข </Button>
                                                                                        )}
                                                                                    </div>
                                                                                );
                                                                            } 
                                                                            return null;
                                                                        }
                                                                    }
                                                                    </Query>  
                                                                }
                                                                stats={null}
                                                                content={
                                                                    <Row>
                                                                        <Col md={12}>
                                                                            <TableExtra>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{width:120,textAlign:'left'}}>
                                                                                            หัวข้อ
                                                                                        </th>
                                                                                        <th style={{textAlign:'left'}}>
                                                                                            ยอดขาย <br/>เดือนที่แล้ว
                                                                                        </th>
                                                                                        <th style={{textAlign:'left'}}>
                                                                                            กำไร <br/>เดือนที่แล้ว
                                                                                        </th>
                                                                                        <th style={{borderLeft:"1px solid #ddd",textAlign:'left'}}>
                                                                                            เป้าหมาย <br/>ยอดขายเดือนนี้
                                                                                        </th>
                                                                                        <th style={{textAlign:'left'}}>
                                                                                            ยอดขายเดือนนี้
                                                                                        </th>
                                                                                        <th style={{textAlign:'left'}}>
                                                                                            ส่วนต่างกับเป้าหมาย<br/>ส่วนต่างกับเดือนที่แล้ว
                                                                                        </th>
                                                                                        <th style={{borderLeft:"1px solid #ddd",textAlign:'left'}}>
                                                                                            เป้าหมาย <br/> กำไรเดือนนี้
                                                                                        </th>
                                                                                        <th style={{textAlign:'left'}}>
                                                                                            กำไรเดือนนี้<br/>ส่วนต่าง
                                                                                        </th>
                                                                                        <th style={{textAlign:'left'}}>
                                                                                            ส่วนต่างกับเป้าหมาย<br/>ส่วนต่างกับเดือนที่แล้ว
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td> Enterprice-01 </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,1,"sale")}
                                                                                                    placeholder="ยอดขายเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,1,"sale")}
                                                                                                    name="before_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,1,"sale").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,1,"profit")}
                                                                                                    placeholder="กำไรเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,1,"profit")}
                                                                                                    name="before_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,1,"profit").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,1,"sale_target")}
                                                                                                    placeholder="เป้าหมายยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,1,"sale_target")}
                                                                                                    name="now_sale_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,1,"sale_target").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,1,"sale")}
                                                                                                    placeholder="ยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,1,"sale")}
                                                                                                    name="now_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,1,"sale").toLocaleString('en-US')} <br/></b>) }
                                                                                        </td>
                                                                                        <td> 
                                                                                            <b style={{color:this.renderComparePriceExtra(before,now,1,"sale_target",true)}}>{this.renderComparePriceExtra(before,now,1,"sale_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,1,"sale").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,1,"profit_target")}
                                                                                                    placeholder="เป้าหมายกำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,1,"profit_target")}
                                                                                                    name="now_profit_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,1,"profit_target").toLocaleString('en-US')}
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,1,"profit")}
                                                                                                    placeholder="กำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,1,"profit")}
                                                                                                    name="now_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,1,"profit").toLocaleString('en-US')}<br/></b>) }
                                                                                        </td>

                                                                                        <td> 
                                                                                            <b style={{color:this.renderComparePriceExtra(before,now,1,"profit_target",true)}}>{this.renderComparePriceExtra(before,now,1,"profit_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,1,"profit").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td> Enterprice-02 </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,2,"sale")}
                                                                                                    placeholder="ยอดขายเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,2,"sale")}
                                                                                                    name="before_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,2,"sale").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,2,"profit")}
                                                                                                    placeholder="กำไรเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,2,"profit")}
                                                                                                    name="before_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,2,"profit").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,2,"sale_target")}
                                                                                                    placeholder="เป้าหมายยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,2,"sale_target")}
                                                                                                    name="now_sale_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,2,"sale_target").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,2,"sale")}
                                                                                                    placeholder="ยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,2,"sale")}
                                                                                                    name="now_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,2,"sale").toLocaleString('en-US')} <br/></b>) }
                                                                                        </td>

                                                                                        <td> 
                                                                                            <b style={{color:this.renderComparePriceExtra(before,now,2,"sale_target",true)}}>{this.renderComparePriceExtra(before,now,2,"sale_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,2,"sale").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,2,"profit_target")}
                                                                                                    placeholder="เป้าหมายกำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,2,"profit_target")}
                                                                                                    name="now_profit_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,2,"profit_target").toLocaleString('en-US')}
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,2,"profit")}
                                                                                                    placeholder="กำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,2,"profit")}
                                                                                                    name="now_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,2,"profit").toLocaleString('en-US')}<br/></b>) }
                                                                                        </td>

                                                                                        <td> 
                                                                                        <b style={{color:this.renderComparePriceExtra(before,now,2,"profit_target",true)}}>{this.renderComparePriceExtra(before,now,2,"profit_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,2,"profit").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td> TMS </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,3,"sale")}
                                                                                                    placeholder="ยอดขายเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,3,"sale")}
                                                                                                    name="before_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,3,"sale").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,3,"profit")}
                                                                                                    placeholder="กำไรเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,3,"profit")}
                                                                                                    name="before_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,3,"profit").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,3,"sale_target")}
                                                                                                    placeholder="เป้าหมายยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,3,"sale_target")}
                                                                                                    name="now_sale_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,3,"sale_target").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,3,"sale")}
                                                                                                    placeholder="ยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,3,"sale")}
                                                                                                    name="now_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,3,"sale").toLocaleString('en-US')} <br/></b>) }
                                                                                        </td>
                                                                                        <td> 
                                                                                            <b style={{color:this.renderComparePriceExtra(before,now,3,"sale_target",true)}}>{this.renderComparePriceExtra(before,now,3,"sale_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,3,"sale").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,3,"profit_target")}
                                                                                                    placeholder="เป้าหมายกำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,3,"profit_target")}
                                                                                                    name="now_profit_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,3,"profit_target").toLocaleString('en-US')}
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,3,"profit")}
                                                                                                    placeholder="กำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,3,"profit")}
                                                                                                    name="now_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,3,"profit").toLocaleString('en-US')}<br/></b>) }
                                                                                        </td>
                                                                                        <td> 
                                                                                        <b style={{color:this.renderComparePriceExtra(before,now,3,"profit_target",true)}}>{this.renderComparePriceExtra(before,now,3,"profit_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,3,"profit").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td> Others </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,4,"sale")}
                                                                                                    placeholder="ยอดขายเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,4,"sale")}
                                                                                                    name="before_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,4,"sale").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.beforeValue,4,"profit")}
                                                                                                    placeholder="กำไรเดือนที่แล้ว"
                                                                                                    onChange={(event) => this.setValuePriceExtra("before",event.target.value,4,"profit")}
                                                                                                    name="before_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(before,4,"profit").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,4,"sale_target")}
                                                                                                    placeholder="เป้าหมายยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,4,"sale_target")}
                                                                                                    name="now_sale_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,4,"sale_target").toLocaleString('en-US')} 
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,4,"sale")}
                                                                                                    placeholder="ยอดขายเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,4,"sale")}
                                                                                                    name="now_sale"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,4,"sale").toLocaleString('en-US')} <br/></b>) }

                                                                                        </td>
                                                                                        <td> 
                                                                                        <b style={{color:this.renderComparePriceExtra(before,now,4,"sale_target",true)}}>{this.renderComparePriceExtra(before,now,4,"sale_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,4,"sale").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                        <td style={{borderLeft:"1px solid #ddd"}}> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,4,"profit_target")}
                                                                                                    placeholder="เป้าหมายกำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,4,"profit_target")}
                                                                                                    name="now_profit_target"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : this.renderFilterPriceExtra(now,4,"profit_target").toLocaleString('en-US')}
                                                                                        </td>
                                                                                        <td> 
                                                                                            {this.state.openEdit ? (
                                                                                                <FormControl
                                                                                                    type="text"
                                                                                                    bsSize="sm"
                                                                                                    value={this.renderValuePriceExtra(this.state.nowValue,4,"profit")}
                                                                                                    placeholder="กำไรเดือนนี้"
                                                                                                    onChange={(event) => this.setValuePriceExtra("now",event.target.value,4,"profit")}
                                                                                                    name="now_profit"
                                                                                                    style={{maxWidth:100}}
                                                                                                />
                                                                                            ) : (<b>{this.renderFilterPriceExtra(now,4,"profit").toLocaleString('en-US')}<br/></b>) }
                                                                                        </td>
                                                                                        <td> 
                                                                                            <b style={{color:this.renderComparePriceExtra(before,now,4,"profit_target",true)}}>{this.renderComparePriceExtra(before,now,4,"profit_target").toLocaleString('en-US')}</b> <br/>
                                                                                            <small>
                                                                                                {this.renderComparePriceExtra(before,now,4,"profit").toLocaleString('en-US')}
                                                                                            </small>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </TableExtra>
                                                                            {this.state.openEdit && (
                                                                                <Row>
                                                                                    <Col md={6}>
                                                                                        <h5>เป้าหมายยอดขายของ giztix</h5>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            bsSize="sm"
                                                                                            value={this.state.gtx_sale_target}
                                                                                            placeholder="เป้าหมายของ giztix"
                                                                                            onChange={(event) => this.setState({gtx_sale_target : event.target.value})}
                                                                                            name="gtx_sale_target"
                                                                                            style={{maxWidth:200}}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col md={6}>
                                                                                        <h5>เป้าหมายยอดขายของ ondemand</h5>
                                                                                        <FormControl
                                                                                            type="text"
                                                                                            bsSize="sm"
                                                                                            value={this.state.ondemand_sale_target}
                                                                                            placeholder="เป้าหมายของ ondemand"
                                                                                            onChange={(event) => this.setState({ondemand_sale_target : event.target.value})}
                                                                                            name="ondemand_sale_target"
                                                                                            style={{maxWidth:200}}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                </Mutation>       
                                                
                                                <Query
                                                    query={shipmentDashboardQuery}
                                                >
                                                    {
                                                        ({ loading, error, data, refetch, variables }) => {
                                                            if (loading) return null;
                                                            if (error) return null;
                                                            // //console.log(variables);
                                                            const refetchShipmentDashboard = refetch;
                                                            const summary = data.shipmentDashboard;
                                                            const summarytotal = summary.domestic + summary.import + summary.export;

                                                            const dataPieSummarytotal = {
                                                                labels: [`${Math.round(summary.domestic / (summarytotal / 100))}%`, `${Math.round(summary.import / (summarytotal / 100))}%`, `${Math.round(summary.export / (summarytotal / 100))}%`],
                                                                series: [summary.domestic / (summarytotal / 100), summary.import / (summarytotal / 100), summary.export / (summarytotal / 100)]
                                                            };
                                                            return (
                                                            <Row>
                                                                <Col md={12}>
                                                                    <Col md={4}>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <StatsCard
                                                                                    bigIcon={<i className="fal fa-road text-success" />}
                                                                                    statsText="จำนวนบุคทั้งหมด"
                                                                                    statsValue={summarytotal.toLocaleString('en-US')}
                                                                                    statsIcon="fa fa-history"
                                                                                    statsIconText={null}
                                                                                />
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <StatsCard
                                                                                    bigIcon={<i className="fal fa-truck text-info" />}
                                                                                    statsText="ภายในประเทศ"
                                                                                    statsValue={summary.domestic.toLocaleString('en-US')}
                                                                                    statsIcon={<i className="fal fa-truck" />}
                                                                                    statsIconText={null}
                                                                                />
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <StatsCard
                                                                                    bigIcon={<i className="fal fa-plane-arrival text-danger" />}
                                                                                    statsText="นำเข้าสินค้า"
                                                                                    statsValue={summary.import.toLocaleString('en-US')}
                                                                                    statsIcon="fa fa-history"
                                                                                    statsIconText={null}
                                                                                />
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <StatsCard
                                                                                    bigIcon={<i className="fal fa-plane-departure text-warning" />}
                                                                                    statsText="ส่งออกสินค้า"
                                                                                    statsValue={summary.export.toLocaleString('en-US')}
                                                                                    statsIcon="fa fa-history"
                                                                                    statsIconText={null}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    {/* <Col md={4}>
                                                                        <WrapperChart>
                                                                            <Card
                                                                                statsIcon="fa fa-clock-o"
                                                                                title="Shipment"
                                                                                category="สัดส่วนของแต่ละประเเภท"
                                                                                stats={null}
                                                                                content={
                                                                                    <div
                                                                                        id="chartPreferences"
                                                                                        className="ct-chart ct-perfect-fourth"
                                                                                    >
                                                                                        <ChartistGraph data={dataPieSummarytotal} type="Pie" />
                                                                                    </div>
                                                                                }
                                                                                legend={
                                                                                    <div className="legend">{this.createLegend(legendPie)}</div>
                                                                                }
                                                                            />
                                                                        </WrapperChart>
                                                                    </Col> */}
                                                                    </Col>
                                                            </Row>
                                                            )
                                                        }
                                                    }
                                                </Query>
                                            </div>
                                            )
                                        }
                                    }
                                </Query>
                                )
                            }
                        }
                    </Query>   
                                                                
                </Grid>
            </Container>
        );
    }
}

export default Dashboard;
