import gql from 'graphql-tag';

export default gql`
mutation HolidayAddUpdate(
    $_id: ID,
    $year: String!,
    $holiday: [String]
){
    settingHolidayAddUpdate(
        _id: $_id
        year: $year
        holiday: $holiday
    ){
      _id
    }
  }
`;
