

 const renderColorStatus = (qty, cpa) => {
  const percent = qty/cpa

  if(percent < 0.41){
    return '#34a853'
  }
  else if(percent > 0.40 && percent < 0.61){
    return '#FFBF04'
  }
  else if(percent > 0.60 && percent < 0.81){
    return '#EE7D31'
  }
  else if(percent > 0.80){
    return '#FF0002'
  }
  else{
    return '#807E7F'
  }
}

export default renderColorStatus;

