/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import urijs from 'urijs';
import styled from 'styled-components';
import Moment from 'react-moment';
import { compose, Query, Mutation, graphql } from 'react-apollo';
import { SubHeader, Layout } from '../../components';
import SubHeaderWithCosting from '../../components/SubHeaderWithCosting';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CardTable from 'components/CardTable/CardTable.jsx';
import classes from './ShipmentDetail.module.scss';
import classesLazada from '../../Lazada.module.css';
import {
  ShipmentDetail,
  ShipmentTracking,
  SubShipmentTracking,
  SubShipmentTrackingOrder,
} from './containers';
import shipmentListQuery from './graphql/query/shipmentList';
import { ShipmentDetailContext } from './contexts/ShipmentDetailContext';
import ShipmentDetailContextProvider from './contexts/ShipmentDetailContext';
import { ShipmentMap } from './components';

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)``;
const Message = styled.div`
  margin: 10px;
`;

class ShipmentList extends React.Component {
  getIdFromUrl = () => {
    return urijs(window.location.href).query(true).id;
  };

  render() {
    return (
      <ShipmentDetailContextProvider>
        <ShipmentDetailContext.Consumer>
          {context => {
            return (
              <div className="content" style={{ paddingTop: 0 }}>
                <Query
                  query={shipmentListQuery}
                  variables={{
                    _id: this.getIdFromUrl(),
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ data, loading, error }) => {
                    let shipmentName = '';
                    let lazadaImportOrderId = '';
                    let shipmentTrip = '';
                    let idUrl = this.getIdFromUrl()
                    if (!loading && !error && data.shipmentList.shipment.length > 0) {
                      const {
                        shipment_name,
                        shipment_trip,
                        lazada_import_order_id,
                      } = data.shipmentList.shipment[0];
                      shipmentName = shipment_name;
                      shipmentTrip = shipment_trip;
                      lazadaImportOrderId = lazada_import_order_id;
                    }

                    return (
                      <>
                        <SubHeaderWithCosting idUrl={idUrl} backUrl={`/lazada-shipment-list?id=${lazadaImportOrderId}`} pathname={this.props.location.pathname} lazadaImportOrderId={lazadaImportOrderId} />
                        <div
                          style={{
                            height: 55,
                            backgroundColor: '#fff',
                            padding: '20px 20px 20px 20px',
                            marginBottom: '40px',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
                          }}
                        >
                          <div >
                            {shipmentTrip ? (
                              <div>
                                Trip No: <strong>{shipmentTrip}</strong>
                              </div>
                            )
                              : (
                                <div>
                                  Trip Name: <strong>{shipmentName ? shipmentName : ''}</strong>
                                </div>
                              )
                            }
                          </div>
                        </div>

                        <Grid fluid>
                          <div>
                            <Row>
                              <Col md={12}>
                                {loading && <Message>Loading...</Message>}
                                {error && <Message>{error}</Message>}
                                {!loading && !error && (
                                  <div className={classes.ContentContainer}>
                                    <div className={classes.LeftContainer}>
                                      <ShipmentDetail
                                        shipmentId={data.shipmentList.shipment[0]._id}
                                      />

                                      <ShipmentTracking
                                        shipmentId={data.shipmentList.shipment[0]._id}
                                      />
                                    </div>
                                    <div className={classes.RightContainer}>
                                      <ShipmentMap
                                        shipmentId={data.shipmentList.shipment[0]._id}
                                      />
                                      <div className={classes.TopContainer}>
                                        {context.state.subShipmentTracking && (
                                          <SubShipmentTracking
                                            shipmentId={data.shipmentList.shipment[0]._id}
                                          />
                                        )}

                                        {context.state.subShipmentTrackingOrder && (
                                          <SubShipmentTrackingOrder
                                            shipmentId={data.shipmentList.shipment[0]._id}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </Grid>
                      </>
                    );
                  }}
                </Query>
              </div>
            );
          }}
        </ShipmentDetailContext.Consumer>
      </ShipmentDetailContextProvider>
    );
  }
}

export default ShipmentList;
