import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import MediaQuery from 'react-responsive';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Container = styled.div`
  width: 80%;
  min-height: 90vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  background-color: #FFFFFF;
  font-size: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & object{
    min-height: 90vh;
  }

  @media(max-width: 767px) {
    width: 90%;
  }
`;

const Paginate = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  line-height: 1;

  & i {
    padding-top: 4px;
  }

  & div:not(:first-child), & div:not(:last-child) {
    margin: 0px 10px;
  }
`;

class PDFPreview extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  onNextPage() {
    this.setState({ pageNumber: this.state.pageNumber < this.state.numPages ? (this.state.pageNumber + 1) : this.state.pageNumber });
  }

  onPreviousPage() {
    this.setState({ pageNumber: this.state.pageNumber > 1 ? (this.state.pageNumber - 1) : this.state.pageNumber })
  }

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <Container>
        <object data={this.props.file} type="application/pdf" width="100%" height="100%">
          <p>Alternative text - include a link <a href={this.props.file}>to the PDF!</a></p>
        </object>
        {/* <Document
          file={`https://cors-anywhere.herokuapp.com/${this.props.file}`}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <MediaQuery minWidth={768}>
            <Page width={400} pageNumber={pageNumber} />
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <Page width={300} pageNumber={pageNumber} />
          </MediaQuery>
        </Document>

        {
          numPages && (
            <Paginate>
              <div onClick={() => this.onPreviousPage()}><i className="far fa-caret-circle-left"></i></div>
              <div> Page {pageNumber} of {numPages} </div>
              <div onClick={() => this.onNextPage()}><i className="far fa-caret-circle-right"></i></div>
            </Paginate>
          )
        } */}
      </Container>
    )
  }
}

PDFPreview.propTypes = {
  file: PropTypes.string,
};

PDFPreview.default = {
  file: '',
};

export default PDFPreview;