import React from 'react';
import { Grid, Row, Col, Table, OverlayTrigger, FormControl, Popover } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactPaginate from 'react-paginate';
import { compose, Query, graphql } from 'react-apollo';
import { CardTable } from 'components';
import { Layout, Tabs } from './components';
import urijs from 'urijs';
import refetchQueries from 'utils/refetchQuery';
import AutoSuggestTransporter from './components/AutoSuggestTransporter';
import codParcelStatus from './graphql/query/codParcelStatus';
import codParcels from './graphql/query/codParcels';
import { formatDate, formatDateTime } from 'utils/dateTime.js';
import ModalPayCod from './components/ModalPayCod/index.jsx';
import codParcel from './graphql/query/codParcel.js';
import formatCurrency from 'utils/formatCurrency.jsx';

class CODParcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order_by: null,
      order_type: null,
      codStatus: '',
      codInNumber: '',
      trackingNumber: '',
      createDate: '',
      displayCreateDate: '',
      pageNumber: 1,
      codInStatus: null,
      isOpenModalConfirm: false,
      idNewCod: null,
      isPayCodModal: false,
      codStatusModal: '',
      vendorId: '',
      codInDate: '',
      onceCheck: true,
      phone: '',
      transporterId: '',
      keepStatus: 'inprogress',
    };
  }

  componentWillMount() {
    const thisLocation = window.location.pathname;
    console.log('thisLocation', thisLocation);
    if (thisLocation) {
      const thisPath = thisLocation.slice(0, 19);
      console.log('thisPath', thisPath);

      if (thisPath === '/cod-parcel/detail/') {
        this.setState({
          idNewCod: thisLocation.slice(19, thisLocation.length),
          isPayCodModal: true,
        });
      } else {
        window.history.pushState('data', null, `/cod-parcel?status=${'inprogress'}`);
        this.setState({
          codInStatus: 1,
        });
      }
    }

    // const uri = URI(window.location.href).tld("com").path();
    // this.setState({urlPath: uri});
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  handleDateRangePicker(mode, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({ pickupDate: date, displayPickupDate: display });
    }
  }

  getParamUrl = () => {
    const uriRoute = urijs(window.location.href);
    const uriFragment = uriRoute.fragment(true);
    const pathname = uriFragment.pathname();

    return pathname;
  };

  setActive = path => {
    const paramUrl = this.getParamUrl();
    return path === paramUrl;
  };

  openUrl = url => {
    window.location = url;
  };

  getStatusFromUrl = status => {
    let statusUrl = urijs(window.location.href).query(true).status;
    if (statusUrl === undefined) {
      statusUrl = 'inprogress';
    }
    return status === statusUrl;
  };

  formatMoney = number => {
    return `฿ ${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;
  };

  setCodStatus = status => {
    this.setState({
      codInStatus: status,
      pageNumber: 1,
    });
  };

  getCodStatus = () => {
    return this.getStatusFromUrl('inprogress')
      ? 1
      : this.getStatusFromUrl('reject')
      ? 4
      : this.getStatusFromUrl('inprogress-acc')
      ? 2
      : 3;
  };

  changeUrl = stage => {
    window.history.pushState(
      'data',
      null,
      `/cod-parcel?${new URLSearchParams({
        status: stage,
      }).toString()}`
    );
  };

  getDataLastSlashUrl = () => {
    const url = window.location.href;
    const parts = url.split('/');
    const id = parts[parts.length - 1];
    return id.includes('cod-parcel') ? null : id;
  };

  // getCodListQuery = async id => {
  //   try {
  //     const { data } = await client.query({
  //       query: codParcels,
  //       variables: {
  //         showData: 30,
  //         page: this.state.pageNumber,
  //         sort: {
  //           order_by: this.state.order_by || '',
  //           order_type: this.state.order_type || '',
  //         },
  //         params: {
  //           cod_status: this.state.codInStatus || this.getCodStatus(),
  //         },
  //       },
  //     });

  //     if (data && data.codParcels && data.codParcels.cods.length > 0) {
  //       const codSet = data.codParcels.codParcels

  //       this.setState({
  //         // isPayCodModal: true,
  //         // idNewCod: codSet._id,
  //         codStatusModal: codSet.cod_status,
  //         vendorId: codSet.vendor._id,
  //         onceCheck: false,
  //       });
  //     }
  //   } catch (error) {
  //     // alert(error);
  //   }
  // };

  onChangeTransporter = id => {
    this.setState({
      transporterId: id,
    });
  };

  render() {
    return (
      <Layout isShipmentList>
        <Query query={codParcelStatus} fetchPolicy="cache-and-network">
          {({ data, loading, error }) => {
            // if (error) {
            //   return <div>Error: {error.graphQLErrors[0].message} </div>;
            // }

            // if (data) {
            return (
              <Tabs
                menu={[
                  {
                    icon: 'fas fa-file-alt',
                    title: 'รอ 3PL จ่าย',
                    active:
                      this.getStatusFromUrl('inprogress') && this.state.isPayCodModal === false,
                    onPress: () => {
                      this.setCodStatus(1);
                      if (data && data.codParcelStatus) {
                        refetchQueries(['codParcelStatus', 'codParcels']);
                      }
                      this.changeUrl('inprogress');
                    },
                    number: data ? (data.codParcelStatus ? data.codParcelStatus.waiting3PL : 0) : 0,
                  },
                  {
                    icon: 'fas fa-file-alt',
                    title: 'รอบัญชียืนยัน',
                    active:
                      this.getStatusFromUrl('inprogress-acc') && this.state.isPayCodModal === false,
                    onPress: () => {
                      this.setCodStatus(2);
                      if (data && data.codParcelStatus) {
                        refetchQueries(['codParcelStatus', 'codParcels']);
                      }
                      this.changeUrl('inprogress-acc');
                    },
                    number: data ? (data.codParcelStatus ? data.codParcelStatus.pending : 0) : 0,
                  },
                  {
                    icon: 'fas fa-file-excel',
                    title: 'ถูกปฎิเสธ COD',
                    active: this.getStatusFromUrl('reject') && this.state.isPayCodModal === false,
                    onPress: () => {
                      this.setCodStatus(4);
                      if (data && data.codParcelStatus) {
                        refetchQueries(['codParcelStatus', 'codParcels']);
                      }
                      this.changeUrl('reject');
                    },
                    number: data ? (data.codParcelStatus ? data.codParcelStatus.reject : 0) : 0,
                  },
                  {
                    icon: 'fas fa-file-check',
                    title: 'เสร็จสิ้น',
                    active:
                      this.getStatusFromUrl('completed') && this.state.isPayCodModal === false,
                    onPress: () => {
                      this.setCodStatus(3);
                      if (data && data.codParcelStatus) {
                        refetchQueries(['codParcelStatus', 'codParcels']);
                      }
                      this.changeUrl('completed');
                    },
                    number: data ? (data.codParcelStatus ? data.codParcelStatus.confirm : 0) : 0,
                  },
                ]}
                bold
              />
            );
            // }
          }}
        </Query>
        <Grid fluid>
          <div>
            <Row>
              <Col md={12}>
                <div>
                  <CardTable
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Query
                        query={codParcels}
                        variables={{
                          showData: 30,
                          page: this.state.pageNumber,
                          sort: {
                            order_by: this.state.order_by || '',
                            order_type: this.state.order_type || '',
                          },
                          params: {
                            cod_status: this.state.codInStatus,
                            cod_in_number: this.state.codInNumber,
                            tracking_number: this.state.trackingNumber,
                            cod_in_date: this.state.createDate,
                            // vendor: this.state.transporterId,
                          },
                        }}
                        fetchPolicy="cache-and-network"
                      >
                        {({ data, loading, error, refetch }) => {
                          let cods = [];
                          let totalPage = 0;

                          if (!loading && !error && data && data.codParcels) {
                            cods = [...data.codParcels.codParcels];

                            totalPage = data.codParcels.totalPage;

                            // const newId = this.getDataLastSlashUrl();

                            // if (newId && this.state.onceCheck) {
                            //   this.getCodListQuery(newId);
                            // }
                          }

                          return (
                            <div>
                              <Table striped hover responsive style={{ marginBottom: '80px' }}>
                                <thead>
                                  <tr>
                                    <th style={{ width: '80px' }}>เครื่องมือ</th>
                                    <th
                                      style={{ width: '200px' }}
                                      className={this.renderSorting('cod_in_date')}
                                      onClick={() => this.sort('cod_in_date', refetch)}
                                    >
                                      COD-IN Date
                                    </th>

                                    <th
                                      style={{ width: '200px' }}
                                      className={this.renderSorting('cod_in_number')}
                                      onClick={() => this.sort('cod_in_number', refetch)}
                                    >
                                      COD-IN No.
                                    </th>
                                    <th style={{ width: '150px', maxWidth: '150px' }}>
                                      วันที่ต้องจ่าย
                                    </th>
                                    <th style={{ width: '160px' }}>Delivered Date</th>
                                    <th
                                      style={{ width: '200px' }}
                                      className={this.renderSorting('shipment_numbers')}
                                      onClick={() => this.sort('shipment_numbers', refetch)}
                                    >
                                      Tracking No.
                                    </th>
                                    <th>มูลค่า COD-IN</th>
                                    <th>Channel</th>
                                    <th>3PL Name</th>
                                    <th>Phone No.</th>
                                    <th>อัพเดทล่าสุด</th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th style={{ maxWidth: '200px' }}>
                                      {/* <FormControl
                                        type="text"
                                        placeholder="ค้นหาเลข COD-IN Date"
                                        value={this.state.codInDate}
                                        onChange={event => {
                                          this.setState({
                                            codInDate: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        name="codInDate"
                                      /> */}
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker('createDate', picker)
                                        }
                                        onCancel={() =>
                                          this.setState({
                                            createDate: '',
                                            displayCreateDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        containerStyles={{
                                          display: 'block',
                                        }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.displayCreateDate}
                                          placeholder="วัน/เวลาที่ส่ง COD"
                                        />
                                      </DateRangePicker>
                                    </th>
                                    <th style={{ maxWidth: '200px' }}>
                                      <FormControl
                                        type="text"
                                        placeholder="ค้นหาเลข COD-IN Number"
                                        value={this.state.codInNumber}
                                        onChange={event => {
                                          this.setState({
                                            codInNumber: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        name="codInNumber"
                                      />
                                    </th>
                                    <th style={{ position: 'relative', maxWidth: '100px' }}>
                                      {/* <AutoSuggestTransporter
                                        setIdSearch={id => this.onChangeTransporter(id)}
                                      /> */}
                                    </th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        placeholder="ค้นหาเลข Tracking No."
                                        value={this.state.trackingNumber}
                                        onChange={event => {
                                          this.setState({
                                            trackingNumber: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        name="trackingNumber"
                                      />
                                    </th>
                                    <th>
                                      {/* <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker('createDate', picker)
                                        }
                                        onCancel={() =>
                                          this.setState({
                                            createDate: '',
                                            displayCreateDate: '',
                                            pageNumber: 1,
                                          })
                                        }
                                        containerStyles={{
                                          display: 'block',
                                        }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.displayCreateDate}
                                          placeholder="วัน/เวลาที่ส่ง COD"
                                        />
                                      </DateRangePicker> */}
                                    </th>
                                    <th></th>
                                    <th>
                                      {/* <FormControl
                                        type="text"
                                        placeholder="ค้นหาเลข 3PL Name"
                                        value={this.state.transporterId}
                                        onChange={event => {
                                          this.setState({
                                            transporterId: event.target.value,
                                            pageNumber: 1,
                                          });
                                        }}
                                        name="transporterId"
                                      /> */}
                                    </th>
                                    <th>
                                      {/* <AutoSuggestTransporter
                                        setIdSearch={id =>
                                          this.setState({
                                            phone: id,
                                          })
                                        }
                                      /> */}
                                    </th>
                                    <th></th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {loading && (
                                    <tr>
                                      {this.getStatusFromUrl('reject') ? (
                                        <td colSpan="6">Loading...</td>
                                      ) : (
                                        <td colSpan="5">Loading...</td>
                                      )}
                                    </tr>
                                  )}

                                  {error && (
                                    <tr>
                                      {this.getStatusFromUrl('reject') ? (
                                        <td colSpan="6">error</td>
                                      ) : (
                                        <td colSpan="5">error</td>
                                      )}
                                    </tr>
                                  )}
                                  {!loading && !error && cods.length === 0 && (
                                    <tr>
                                      {this.getStatusFromUrl('reject') ? (
                                        <td colSpan="6">ไม่มีข้อมูล</td>
                                      ) : (
                                        <td colSpan="5">ไม่มีข้อมูล</td>
                                      )}
                                    </tr>
                                  )}
                                  {cods?.map((item, index) => {
                                    return (
                                      <tr key={`table-key-cod-${index}`}>
                                        <td>
                                          <div
                                            onClick={() => {
                                              this.setState({
                                                isPayCodModal: true,
                                                idNewCod: item._id,
                                                codStatusModal: item.cod_status,
                                                vendorId: item.vendor._id,
                                                keepStatus: urijs(window.location.href).query(true)
                                                  .status,
                                              });
                                              window.history.pushState(
                                                'data',
                                                null,
                                                `/cod-parcel/detail/${item._id}`
                                              );
                                            }}
                                            target="_blank"
                                            style={{ float: 'left' }}
                                            className="btn btn-default"
                                          >
                                            <i
                                              style={{ marginTop: '3px' }}
                                              className="fas fa-search"
                                            ></i>
                                          </div>
                                        </td>
                                        <td>{item?.cod_in_date ? item?.cod_in_date : '-'}</td>
                                        <td>{item?.cod_in_number ? item?.cod_in_number : '-'}</td>
                                        <td>{item?.due_date ? formatDate(item?.due_date) : '-'}</td>
                                        <td>
                                          {item?.delivered_date
                                            ? formatDateTime(item?.delivered_date)
                                            : '-'}
                                        </td>
                                        {item?.parcel_orders?.length > 1 ? (
                                          <td>
                                            <OverlayTrigger
                                              trigger="hover"
                                              key="bottom"
                                              placement="bottom"
                                              overlay={
                                                <Popover
                                                  style={{
                                                    minWidth: 400,
                                                  }}
                                                  id={`popover-positioned-1`}
                                                >
                                                  <tr>
                                                    <td
                                                      style={{
                                                        minWidth: 145,
                                                      }}
                                                    >
                                                      {`Tracking Number`}
                                                    </td>
                                                    <td
                                                      style={{ minWidth: 150 }}
                                                    >{`Deilvered Date`}</td>
                                                    <td style={{ minWidth: 60 }}>{`COD Value`}</td>
                                                  </tr>
                                                  {item?.parcel_orders?.map((row, jdex) => {
                                                    return (
                                                      <div key={`key-of-popover-${jdex}`}>
                                                        <tr>
                                                          <td
                                                            style={{
                                                              minWidth: 145,
                                                            }}
                                                          >
                                                            {row?.tracking_number}
                                                          </td>
                                                          <td
                                                            style={{
                                                              minWidth: 150,
                                                            }}
                                                          >
                                                            {row?.delivered_date}
                                                          </td>
                                                          <td
                                                            style={{
                                                              minWidth: 60,
                                                            }}
                                                          >
                                                            {formatCurrency(row?.cod_price)}
                                                          </td>
                                                        </tr>
                                                      </div>
                                                    );
                                                  })}
                                                </Popover>
                                              }
                                            >
                                              <span
                                                style={{
                                                  cursor: 'pointer',
                                                  color: '#0C77FF',
                                                }}
                                              >
                                                {item?.parcel_orders?.length} รายการ
                                              </span>
                                            </OverlayTrigger>
                                          </td>
                                        ) : (
                                          [
                                            item?.parcel_orders[0]?.tracking_number ? (
                                              <td>{item?.parcel_orders[0]?.tracking_number}</td>
                                            ) : (
                                              <td>-</td>
                                            ),
                                          ]
                                        )}
                                        <td>
                                          {item?.total_cod
                                            ? this.formatMoney(item?.total_cod)
                                            : '-'}
                                        </td>
                                        <td>{item?.paid_channel ? item?.paid_channel : '-'}</td>
                                        <td>
                                          {item?.vendor?.full_name ? item?.vendor?.full_name : '-'}
                                        </td>
                                        <td>
                                          {item?.vendor?.phone?.length > 0
                                            ? item?.vendor?.phone.join(', ')
                                            : '-'}
                                        </td>
                                        <td>
                                          {item?.last_update
                                            ? formatDateTime(item?.last_update)
                                            : '-'}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>

                              <div
                                style={{
                                  textAlign: 'right',
                                  marginTop: 20,
                                }}
                              >
                                {!loading && !error && totalPage !== 0 && (
                                  <ReactPaginate
                                    pageCount={totalPage}
                                    forcePage={this.state.pageNumber - 1}
                                    containerClassName="pagination"
                                    nextLabel=">"
                                    previousLabel="<"
                                    onPageChange={({ selected }) => {
                                      this.setState({
                                        pageNumber: selected + 1,
                                      });
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }}
                      </Query>
                    }
                  />
                </div>
              </Col>
            </Row>

            {this.state.isPayCodModal && this.state.idNewCod && (
              <div>
                <Query
                  query={codParcel}
                  variables={{
                    _id: this.state.idNewCod,
                  }}
                  fetchPolicy="cache-and-network"
                >
                  {({ data, loading, error, refetch }) => {
                    if (data?.codParcel) {
                      return (
                        <ModalPayCod
                          isOpen={this.state.isPayCodModal}
                          onCancel={() => {
                            this.setState({
                              isPayCodModal: false,
                            });
                            window.history.pushState(
                              'data',
                              null,
                              `/cod-parcel?status=${this.state.keepStatus}`
                            );
                          }}
                          codList={data?.codParcel}
                          // show={this.state.isPayCodModal}
                          // close={() => this.setState({ isPayCodModal: false, idNewCod: null })}
                          // defaultList={[]}
                          // idNewCod={this.state.idNewCod}
                          // hideAddCodBTN
                          // hideTools
                          // cod_status={this.state.codStatusModal}
                          // vendorId={this.state.vendorId}
                        />
                      );
                    } else {
                      return null;
                    }
                  }}
                  {/* {({ data, loading, error, refetch }) => {
                  let cods = [];
                  let totalPage = 0;

                  if (!loading && !error && data && data.codParcels) {
                    cods = [...data.codParcels.codParcels];

                    totalPage = data.codParcels.totalPage;

                    // const newId = this.getDataLastSlashUrl();

                    // if (newId && this.state.onceCheck) {
                    //   this.getCodListQuery(newId);
                    // }
                  } */}

                  {/* {({ data, loading, error, refetch }) => {
                    console.log("DaffafafaATA : ", data)
                    return (
                      HELLO
                    )
                  }} */}
                </Query>
              </div>
            )}
          </div>
        </Grid>
      </Layout>
    );
  }
}

export default compose(graphql(codParcelStatus, { name: 'codParcelStatus' }))(CODParcel);
