import gql from 'graphql-tag';

export default gql`
  mutation UpdateCodParcel(
    $_id: ID!
    $cod_status: Int
    $document: [String]
    $cod_in_date: String
  ) {
    updateCodParcel(
      _id: $_id
      update: {
        cod_status: $cod_status
        document: $document
        cod_in_date: $cod_in_date
      }
    ) {
      _id
    }
  }
`;
