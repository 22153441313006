import gql from 'graphql-tag';

export default gql`
  query search(
    $page_number: Int
    $order_by: String
    $order_type: String
    $_id: ID
    $account_info_business_name: String
    $business_req_number: String
    $business_status: Int
    $create_date: String
    $last_update: String
    $business_register_from: Int
    $invite_code: String
    $account_info_email: String
    $account_info_customer_type: Int
    $customer_service_type: String
  ) {
    accountBusinessList(
      page_number: $page_number
      show_data: 30
      order_by: $order_by
      order_type: $order_type
      create_date: $create_date
      last_update: $last_update
      _id: $_id
      account_info_business_name: $account_info_business_name
      business_req_number: $business_req_number
      business_status: $business_status
      business_register_from: $business_register_from
      invite_code: $invite_code
      account_info_email: $account_info_email
      account_info_customer_type: $account_info_customer_type
      customer_service_type: $customer_service_type
    ) {
      account_business {
        _id
        invite_code
        account_info_id
        account_info_business_name
        account_info_email
        account_info_customer_type
        business_req_number
        business_status
        business_usage_information_1
        business_usage_information_2
        business_usage_information_3
        business_credit_req
        business_credit_approved
        business_credit_used
        business_edocument_receipt
        business_alway_wht
        business_admin_delivery_vat
        business_admin_delivery_wht
        business_admin_service_vat
        business_admin_service_wht
        business_admin_document_1
        business_admin_document_2
        business_admin_document_3
        business_register_from
        business_free_credit
        business_free_credit_used
        business_invite_benefit_discount_start {
          discount
          start
          end
        }
        business_invite_benefit_discount {
          discount
          start
          end
        }
        business_admin_benefit_discount {
          setting_truck_type_id
          discount
          discountend
        }
        business_invoice_type
        create_date
        last_update
        customer_service_type
      }
      total_page
    }
  }
`;
