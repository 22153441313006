import React from 'react';
import { StyledModal, TitleWrapper, ColoredButton, FooterWrapper, BodyWrapper } from './styled';
import { Mutation } from 'react-apollo';
import updateCod from './graphql/mutation/updateCod';
import TextArea from './components/TextArea';
import { Component } from 'react';

class OPSDenielCodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      denielText: '',
    };
  }

  confirmStatus = updateCodMutation => {
    //ย้ายไปรอตรวจ cod
    const updateCod = {
      cod_status: 4,
      reject_remark: this.state.denielText,
    };

    console.log('createCod', updateCod);

    updateCodMutation({
      variables: {
        _id: this.props.idUpdate,
        update: updateCod,
      },
    });
  };

  render() {
    return (
      <Mutation
        mutation={updateCod}
        update={() => {
          this.props.close();
          this.props.closeAll();
        }}
        onError={error => {
          console.log(error);
          alert(error.graphQLErrors[0].message);
        }}
        refetchQueries={() => ['newCods', 'newCodStatus']}
      >
        {updateCodMutation => {
          return (
            <StyledModal
              show={this.props.show}
              labelButtonCancel="ยกเลิก"
              labelButtonConfirm="ใช่, ยืนยัน"
            >
              <TitleWrapper style={{ marginBottom: 0 }}>
                <b style={{ fontSize: '18px' }}>คุณต้องการปฎิเสธยอดโอนนี้ใช่หรือไม่ ?</b>
              </TitleWrapper>
              <BodyWrapper>
                <TextArea
                  onChange={event =>
                    this.setState({
                      denielText: event.target.value,
                    })
                  }
                  value={this.state.denielText}
                  inputLabel="เหตุผลที่ปฎิเสธ"
                  style={{}}
                  placeholder="ระบุ"
                  required
                />
              </BodyWrapper>
              <FooterWrapper>
                <ColoredButton
                  bgColor="#fff"
                  color="#000"
                  onClick={() => {
                    this.props.close();
                  }}
                >
                  <i className="fas fa-times"></i>
                  ยกเลิก
                </ColoredButton>
                <ColoredButton
                  disabled={!this.state.denielText}
                  onClick={() => this.confirmStatus(updateCodMutation)}
                >
                  ใช่, ฉันยืนยัน
                </ColoredButton>
              </FooterWrapper>
            </StyledModal>
          );
        }}
      </Mutation>
    );
  }
}

export default OPSDenielCodModal;
