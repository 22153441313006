import gql from 'graphql-tag';

export default gql`
  query($params: paramsBillingParcelListType, $page: Int) {
    billingParcelList(params: $params, page: $page, showData: 30) {
      parcels {
        _id
        cost
        parcelCost {
          _id
          jobId
          refNumber
          costModel
          jobType
          pickupDate
          deliveredDate
          totalCost
          remark
          costing_number
          costing_status
          edit_request_status
          re_total_before_discount
          non_total_before_discount
          inc_total_before_discount
          re_total_discount
          non_total_discount
          inc_total_discount
          re_total_after_discount
          non_total_after_discount
          inc_total_after_discount
          total_vat
          re_total
          non_total
          inc_total
          total_price
          totalCostAvg
          grand_total_price
          cancel_remark
          edit_remark
          approve_edit_request
          createdAt
          updatedAt
        }
        parcelRevenue {
          totalRevenue
          jobId
          refNumber
        }
        customer {
          _id
          full_name
        }
        parcelStatus
        status
        trackingNumber
        orderNumber
        itemName
        goodsValue
        cod
        weight
        width
        length
        height
        proof
        origin {
          name
          phone
          details
          province
          district
          subDistrict
          zipCode
        }
        destination {
          name
          phone
          details
          province
          district
          subDistrict
          zipCode
        }
        deliveredDate
        pickupDate
        createdAt
        updatedAt
      }
      totalPage
      totalDocument
      totalCost
      totalRevenue
      totalCostAvg
      parcelTarget
    }
  }
`;
