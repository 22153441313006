import * as React from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios';

import {
  TEXT_DOWNLOAD_TEMPLATE,
  TEXT_RECOMMEND_TITLE,
  TEXT_REPORT_DETAIL,
  TEXT_REPORT_NAME,
  TEXT_TOOLS,
  TITLE_COMMON,
  TITLE_JDBU,
  TITLE_JDSM,
  TITLE_LAZADA,
} from '../utils/useConstants';
import FieldUploadFile from '../utils/useUpload';
import { FunctionOpenInNewTab } from '../utils/useFunctions';
import { client } from 'index';
import adminListQuery from '../../Report/graphql/query/adminList';
import gql from 'graphql-tag';

import lamdaUrl from 'config/lambdaUrl';

const upload = gql`
  mutation SingleUpload($file: Upload, $path: String) {
    singleUpload(file: $file, path: $path) {
      path
    }
  }
`;

export const RenderEachTable = ({ name, link, setTab }) => {
  const [file, setFile] = React.useState({ file: null, type: '' });

  React.useEffect(() => {
    if (file) {
      FunctionUploadFile();
    } else {
      return;
    }
  }, [file]);

  const FunctionUploadFile = async () => {
    if (file?.type && file?.file) {
      try {
        const { data } = await client.query({
          query: adminListQuery,
        });
        const { admin } = data.adminList;
        if (admin.length === 0) {
          alert('ไม่พบ Admin');
          return;
        }
        const adminId = admin[0]._id;
        if (file.type === TITLE_JDBU) {
          const {
            data: { singleUpload },
          } = await client.mutate({
            mutation: upload,
            variables: {
              file: file.file,
              path: `upload/CostJDBURate/${file.file.name}`,
            },
          });
          if (singleUpload) {
            await axios.post(`${lamdaUrl}/jdbu-rate-card-report`, {
              admin_id: adminId,
              path: singleUpload.path,
            });
          }
        } else if (file.type === TITLE_JDSM) {
          const {
            data: { singleUpload },
          } = await client.mutate({
            mutation: upload,
            variables: {
              file: file.file,
              path: `upload/CostJDSMRate/${file.file.name}`,
            },
          });
          if (singleUpload) {
            await axios.post(`${lamdaUrl}/jdsm-rate-card-report`, {
              admin_id: adminId,
              path: singleUpload.path,
            });
          }
        } else if (file.type === TITLE_LAZADA) {
          const {
            data: { singleUpload },
          } = await client.mutate({
            mutation: upload,
            variables: {
              file: file.file,
              path: `upload/CostLZDRate/${file.file.name}`,
            },
          });
          if (singleUpload) {
            await axios.post(`${lamdaUrl}/lazada-import-rate-card`, {
              admin_id: adminId,
              path: singleUpload.path,
            });
          }
        } else if (file.type === TITLE_COMMON) {
          const {
            data: { singleUpload },
          } = await client.mutate({
            mutation: upload,
            variables: {
              file: file.file,
              path: `upload/CostRate/${file.file.name}`,
            },
          });
          if (singleUpload) {
            await axios.post(`${lamdaUrl}/import-rate-card`, {
              admin_id: adminId,
              path: singleUpload.path,
            });
          }
        } else {
          console.log('IN ELSE');
          return;
        }
      } catch (error) {
        console.error('ERROR TO UPLOAD FILE : ', error);
      } finally {
        setTab('download');
      }
    } else {
      return;
    }
  };

  return (
    <tr>
      <td style={{ width: '100px' }}>
        <FieldUploadFile name={name} value={file} setValue={setFile} />
      </td>

      <td>
        <b>{name}</b>
      </td>

      <td>{TEXT_RECOMMEND_TITLE.replace('{NAME}', name)}</td>
      <td
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: '#337ab7',
        }}
        onClick={() => {
          FunctionOpenInNewTab(link);
        }}
      >
        {TEXT_DOWNLOAD_TEMPLATE}
      </td>
    </tr>
  );
};

export const ReportUpload = ({ setTab }) => {
  return (
    <div>
      <Table striped hover>
        <thead>
          <tr>
            <th style={{ minWidth: '100px' }}>{TEXT_TOOLS}</th>
            <th style={{ width: '250px' }}>{TEXT_REPORT_NAME}</th>
            <th style={{ width: '400px' }}>{TEXT_REPORT_DETAIL}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <RenderEachTable
            setTab={setTab}
            link={`https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_rate_card_jdbu_v1.xlsx`}
            name={TITLE_JDBU}
          />
          <RenderEachTable
            setTab={setTab}
            link={`https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_rate_card_jdsm_v1.xlsx`}
            name={TITLE_JDSM}
          />
          <RenderEachTable
            setTab={setTab}
            link={`https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_rate_card_lzd_v1.xlsx`}
            name={TITLE_LAZADA}
          />
          <RenderEachTable
            setTab={setTab}
            link={`https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_rate_card_normal_v1.xlsx`}
            name={TITLE_COMMON}
          />
        </tbody>
      </Table>
    </div>
  );
};
