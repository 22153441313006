import gql from 'graphql-tag';

export default gql`
    mutation lazTrackingUpdate(
        $_id: ID
        $shipment_id: ID
        $tracking_detail_lazada: tracking_detail_lazada_arg
    ){
        lazTrackingUpdate(
        _id: $_id
        shipment_id: $shipment_id
        tracking_detail_lazada: $tracking_detail_lazada
        )
        {
            _id
        }
    }
`;