import gql from 'graphql-tag';

export default gql`
query{
    userSettingTruckList{
      user_setting_truck{
         _id
          user_setting_truck_license_plate
          driver_status
      }
      total_page
    }
  }
`;
