import React, { useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import { AutoSuggestContainer } from './styles';
import { client } from '../../../../index';
import accountInfoListQuery from './graphql/query/accountInfoList';

const SelectCustomerParcel = props => {
  const { onChange } = props;
  const [agentListSuggestion, setAgentListSuggestion] = useState([]);
  const [agentSelected, setAgentSelected] = useState('');

  const getSuggestions = async value => {
    try {
      const inputValue = value
        .trim()
        .toString()
        .toLowerCase();

      const { data } = await client.query({
        query: accountInfoListQuery,
        variables: {
          account_info_business_name: inputValue,
          customer_service_type: 'PARCEL',
          show_data: 10,
        },
        fetchPolicy: 'network-only',
      });

      const agentListData = data.accountInfoList.account_info.map(e => ({
        id: e._id,
        name: e.account_info_business_name,
      }));

      setAgentListSuggestion(agentListData);
    } catch (error) {
      console.log(error);
    }
  };

  const onSuggestionsFetchRequested = value => {
    getSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setAgentListSuggestion([]);
  };

  const getSuggestionValue = suggestion => suggestion.name;

  const renderSuggestion = suggestion => (
    <div key={suggestion.id}>{suggestion.name ? suggestion.name : 'No Agent'}</div>
  );

  const shouldRenderSuggestions = () => {
    return true;
  };

  return (
    <AutoSuggestContainer>
      <AutoSuggest
        suggestions={agentListSuggestion}
        onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={false}
        inputProps={{
          type: 'text',
          placeholder: 'เลือกลูกค้า',
          value: agentSelected,
          className: 'form-control',
          onChange: event => {
            setAgentSelected(event.target.value || '');
            onChange && onChange('');
          },
        }}
        onSuggestionSelected={(_event, { suggestion }) => {
          setAgentSelected(suggestion.name);
          onChange && onChange(suggestion.id);
        }}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </AutoSuggestContainer>
  );
};

export default SelectCustomerParcel;
