import styled from 'styled-components';
import { Table } from 'react-bootstrap';

export const StyledTable = styled(Table)`
  .clickable {
    cursor: pointer;
  }
  .red {
    color: red;
  }
  text-align: left;

  & tbody {
    display: block;
    overflow-y: scroll;
    max-height: 20vh;
    width: 102%;
  }
  & thead tr th {
    font-size: 16px !important;
    color: #000 !important;
    font-weight: bold !important;
  }

  & thead tr {
    & .tools {
      width: 10%;
      min-width: 10%;
    }
  }

  & thead tr th,
  td {
    overflow-wrap: break-word;
  }

  & thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;

    & .tools {
      width: 10%;
      min-width: 10%;
    }

    & .cod {
      color: #3378b7;
      font-weight: bold;
    }
  }

  td {
    border-style: hidden !important;
  }
`;
