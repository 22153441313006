import gql from 'graphql-tag';

export default gql`
query shipmentAgentView(
  $shipment_prefix: String
  $lazada_import_order_id: ID
  $_id: ID
  $shipment_trip: String
  $delivery_tracking_number_search: String
  $delivery_status_search: Int
  $final_status_search: Int
  $temp_delivery_status_search: Int
  $account_info_id: ID!
){
  shipmentAgentView(
    shipment_prefix: $shipment_prefix
    lazada_import_order_id: $lazada_import_order_id
    _id: $_id
    shipment_trip: $shipment_trip
    delivery_tracking_number_search: $delivery_tracking_number_search
    delivery_status_search: $delivery_status_search
    final_status_search: $final_status_search
    temp_delivery_status_search: $temp_delivery_status_search
    account_info_id: $account_info_id
  ){
    plan_date
    trip_name
    status
    agent_shipment_status
    shipment_sub_thirdparty_id
    admin_reject_detail
    total_price_cod
    total_accept_cod

    shipment{
      _id
      check_status
      shipment_trip
      driver_user_id
      driver_name
      driver_phone_code
      driver_phone_number
      shipment_total_cod
      user_setting_truck_license_plate
      transporter
      tracking{
        _id
        
        tracking_detail_lazada{
          delivery{
            _id
            delivery_status
            cod_price
            remind
            final_status
            temp_delivery_status
            remark
            failure_status
            admin_reject_status
            account_info
            failure_detail
            failure_date
            postpone_delivery_date
            failure_picture
            commodity_picture
            commodity_picture_end
            commodity_picture_return
            return_status
            log{
              _id
            }
            
            commodity{
              _id
              name
              name_detail
              order_number
              package_number
              remark
              accept
              reject_detail
              cod_price
            }
          }
        }
      }
    }
  }
}

`;