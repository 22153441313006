import gql from 'graphql-tag';

export default gql`
mutation UpdateInvoice(
    $shipment_id: ID
    $invoice_business_list: [invoiceBusinessArgs]
    $invoice_discount: [invoiceDiscountArgs]
    $promotion_code: invoicePromotionCodeArgs
){
    invoiceUpdate(
      shipment_id: $shipment_id
      invoice_business_list: $invoice_business_list
      invoice_discount: $invoice_discount
      promotion_code: $promotion_code
    ){
      succeed
    }
  }
`;
