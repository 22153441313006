import gql from 'graphql-tag';

export default gql`
query search(
    $external_type: Int
  ){
    externalSaleProfitList(
        external_type : $external_type
      ){
        now{
            _id
            external_type
            sale
            sale_target
            profit
            profit_target
            create_from_date
            create_date
            last_update
        }
        before{
            _id
            external_type
            sale
            sale_target
            profit
            profit_target
            create_from_date
            create_date
            last_update
        }
      }
}
`;
