import gql from 'graphql-tag';

export default gql`
mutation Update(
    $shipment_id: ID!
    $transporter: ID!
){
    assignAgent(
      shipment_id:$shipment_id,
      transporter:$transporter
    ){
      succeed
    }
  }
`;
