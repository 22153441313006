import React, { Component } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { graphql, Mutation, Query, compose } from 'react-apollo';

import deleteCostingDocumentsMutation from './../../../../../../graphql/mutation/deleteCostingDocuments';

const ModalContainer = styled(Modal)`
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
`;

class DocumentDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <ModalContainer show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>ยืนยัน</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col md={9}>
                <strong>ต้องการ ลบไฟล์เอกสารนี้ ใช่หรือไม่?</strong>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ float: 'left', width: 60 }} onClick={this.props.onHide}>
            ไม่ใช่
          </Button>
          <Mutation
            mutation={deleteCostingDocumentsMutation}
            variables={{
              _id: this.props.documentId
            }}
            update={() => {
              this.props.onHide();
            }}
            refetchQueries={() => ['searchCostingDocuments']}
            onError={error => {
              alert(error);
            }}
          >
            {deleteCostingDocuments => {
              return (
                <Button
                  bsStyle="primary"
                  onClick={() => deleteCostingDocuments()}
                  style={{ width: 60 }}
                >
                  ใช่
                </Button>
              );
            }}
          </Mutation>
        </Modal.Footer>
      </ModalContainer>
    );
  }
}

export default DocumentDelete;
