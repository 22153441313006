import React, { Component } from 'react';
import {
  StyledModal,
  DetailWrapper,
  TitleWrapper,
  ColoredButton,
  FlexListColumn,
  StyledFormControl,
  FlexListRow,
} from './styled';
import Datetime from 'react-datetime';
import { graphql, compose } from 'react-apollo';
import AddPhotoBox from './components/AddPhotoBox';
import PhotoBox from './components/PhotoBox';
import CodListTable from './components/CodListTable';
import ConfirmPayCodModal from './components/ConfirmPayCodModal';
import OPSConfirmCodModal from './components/OPSConfirmCodModal';
import OPSDenielCodModal from './components/OPSDenielCodModal';
import EditUpdateCodModal from './components/EditUpdateCodModal';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import s3 from 'config/s3';
import newCodQuery from './graphql/query/newCod';
import { client } from '../../../../index.js';
import accountInfoListQuery from './graphql/query/accountInfoList';
import moment from 'moment';

export class PayCodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showtransferDate: null,
      transferDate: null,
      transferDateInvalid: true,
      isPayableCodListModal: false,
      selectedList: props.defaultList,
      totalCod: this.addTotalCod(props.defaultList),
      attachPhotos: [],
      //viewImageSrc
      isOpenLightbox: false,
      viewImageSrc: '',

      //ConfirmPayCod
      isConfirmPayCodModal: false,

      //ConfirmPayCod
      isEditUpdateCodCodModal: false,

      //paymentAccountForNewCod
      paymentBankAccount: {},

      //reject_remark
      reject_remark: '',

      //OPSConfirmCodModal
      isOPSConfirmCodModal: false,

      //OPSDenielCodModal
      isOPSDenielCodModal: false,

      selectedListWithCustomer: [],
    };
  }

  fetchNewCod = async () => {
    console.log('fetchCod', this.props.idNewCod);
    const { data } = await client.query({
      query: newCodQuery,
      variables: {
        _id: this.props.idNewCod,
      },
    });

    if (data.newCod.cod_item.length > 0) {
      const shipmentList = [];

      data.newCod.cod_item.map(data => data.shipment_list.map(data => shipmentList.push(data)));

      console.log('shipmentList', shipmentList);

      this.setState({
        selectedList: shipmentList,
        selectedListWithCustomer: data.newCod.cod_item,
        totalCod: this.addTotalCod(shipmentList),
        showtransferDate: moment(data.newCod.cod_in_date).format('DD/MM/YYYY HH:mm'),
        transferDateInvalid: false,
        attachPhotos: data.newCod.document,
        reject_remark: data.newCod.reject_remark,
        // paymentBankAccount:
      });
    }
  };

  componentDidMount() {
    if (this.props.idNewCod) {
      this.fetchNewCod();
    }
  }

  addTotalCod = data => {
    let totalCod = 0;

    if (this.props.idNewCod) {
      data.map(dt => (totalCod += dt.cod_price));
    } else {
      data.map(dt => (totalCod += dt.shipment_accept_cod));
    }

    return totalCod;
  };

  setSelectedList = list => {
    this.setState(prevState => ({
      ...prevState,
      selectedList: [...prevState.selectedList, ...list],
      isPayableCodListModal: false,
      totalCod: this.addTotalCod([...prevState.selectedList, ...list]),
    }));
  };

  removeList = id => {
    const removedList = this.state.selectedList.filter(data => data._id !== id);
    this.setState({
      selectedList: removedList,
      totalCod: this.addTotalCod(removedList),
    });
  };

  clearState = () =>
    this.setState({
      showtransferDate: null,
      transferDate: null,
      isPayableCodListModal: false,
      selectedList: this.props.defaultList,
      totalCod: this.addTotalCod(this.props.defaultList),
      reject_remark: '',
      paymentBankAccount: {},
    });

  checkDateInvalid = event => {
    if (event) {
      try {
        const date = `${event.format('YYYY-MM-DD HH:mm')}`;
        const show = `${event.format('DD/MM/YYYY HH:mm')}`;
        this.setState({
          transferDate: date,
          showtransferDate: show,
          transferDateInvalid: false,
        });
      } catch (error) {
        this.setState({ transferDateInvalid: true });
      }
    } else {
      this.setState({
        transferDate: '',
        showtransferDate: '',
        transferDateInvalid: true,
      });
    }
  };

  checkConfirmCod = () => {
    const check = {
      transferDateInvalid: this.state.transferDateInvalid,
      totalCod: this.state.totalCod,
      attachPhotos: this.state.attachPhotos.length,
    };
    console.log('checkConfirm', check);
    return (
      this.state.transferDateInvalid ||
      this.state.totalCod <= 0 ||
      this.state.attachPhotos.length <= 0
    );
  };

  addAttachPhoto = pathUrl => {
    this.setState(prevState => ({
      ...prevState,
      attachPhotos: [...prevState.attachPhotos, pathUrl],
    }));
  };

  deleteAttachPhoto = pathUrl => {
    const newAttachPhotos = this.state.attachPhotos.filter(data => data !== pathUrl);
    this.setState({
      attachPhotos: newAttachPhotos,
    });
  };

  renderLightbox(pathUrl) {
    this.setState({
      isOpenLightbox: true,
      viewImageSrc: pathUrl,
    });
  }

  formatMoney = number => {
    return number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  render() {
    let paymentBankAccount = '';
    if (
      this.props.accountInfoListProps.loading == false &&
      this.props.accountInfoListProps.accountInfoList != undefined &&
      this.props.accountInfoListProps.accountInfoList.account_info.length &&
      this.props.accountInfoListProps.accountInfoList.account_info.length > 0
    ) {
      paymentBankAccount = this.props.accountInfoListProps.accountInfoList.account_info[0]
        .payment_bank_account;
    }

    return (
      <div>
        <StyledModal show={this.state.isOpenLightbox ? false : this.props.show}>
          <TitleWrapper>
            <b style={{ fontSize: '18px' }}>รายการชำระเงินค่า COD</b>
            <div style={{ display: 'flex', gap: '5px' }}>
              {!this.props.idNewCod && (
                <ColoredButton onClick={() => this.setState({ isConfirmPayCodModal: true })}>
                  ปฏิเสธการโอนเงิน
                </ColoredButton>
              )}
              {this.props.idNewCod && this.props.cod_status == 1 && (
                <ColoredButton
                  onClick={() => this.setState({ isOPSDenielCodModal: true })}
                  bgColor="red"
                  color="#fff"
                >
                  ปฏิเสธการโอนเงิน
                </ColoredButton>
              )}
              {this.props.idNewCod && this.props.cod_status == 1 && (
                <ColoredButton onClick={() => this.setState({ isOPSConfirmCodModal: true })}>
                  ยืนยันการโอนเงิน
                </ColoredButton>
              )}

              <ColoredButton
                bgColor="#fff"
                color="#000"
                onClick={() => {
                  this.props.close();
                  this.clearState();
                }}
              >
                <i className="fas fa-times"></i>
                ปิด
              </ColoredButton>
            </div>
          </TitleWrapper>
          <b>รายละเอียด COD</b>
          <DetailWrapper>
            <FlexListColumn>
              <div>
                <span>
                  ยอดโอนทั้งหมด (บาท)<span style={{ color: 'red' }}>*</span>
                </span>
                <StyledFormControl
                  type="text"
                  disabled={true}
                  value={this.formatMoney(this.state.totalCod)}
                />
              </div>
              <b>เลขบัญชีที่โอนเข้า:</b>
              <div></div>
              <span>
                ชื่อบัญชี:
                <b> {paymentBankAccount.account_name || 'Giztix CO.,LTD'}</b>
              </span>
              <span>
                ธนาคาร: <b>{paymentBankAccount.bank_name || 'ซิตี้แบงก์'}</b>
              </span>
              <span>
                เลขบัญชี:
                <b>{paymentBankAccount.account_number || '88978945612'}</b>
              </span>

              {this.props.cod_status === 4 && 'เหตุผลการปฏิเสธ:'}
              {this.props.cod_status === 4 && (
                <FlexListRow>
                  <div style={{ color: 'red', fontWeight: 'bold', overflowWrap: 'anywhere' }}>
                    {this.props.idNewCod
                      ? this.state.reject_remark
                      : this.props.reject_remark || '-'}
                  </div>
                </FlexListRow>
              )}
            </FlexListColumn>
            <FlexListColumn>
              <div>
                <span>วัน - เวลาที่โอน</span>
                <span style={{ color: 'red' }}>*</span>
                <Datetime
                  style={{
                    marginTop: '10px',
                  }}
                  inputProps={{
                    placeholder: 'เลือกวันที่',
                    disabled: this.props.idNewCod ? true : false,
                  }}
                  value={this.state.showtransferDate}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  onChange={event => this.checkDateInvalid(event)}
                />
                {this.state.transferDateInvalid && (
                  <div
                    style={{
                      fontSize: '16px',
                      color: '#FF0000',
                      height: '22px',
                      textAlign: 'right',
                    }}
                  >
                    {this.state.transferDateInvalid ? 'กรุณาระบุเวลา' : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                  </div>
                )}
              </div>
              <div>
                <span>หลักฐานการโอน</span>
                <span style={{ color: 'red' }}>*</span>
                <FlexListRow>
                  {this.state.attachPhotos.length > 0 &&
                    this.state.attachPhotos.map(data => (
                      <PhotoBox
                        pathUrl={data}
                        deleteAttachPhoto={this.deleteAttachPhoto}
                        renderLightbox={() => this.renderLightbox(data)}
                        noDelete={this.props.idNewCod}
                      />
                    ))}
                  {
                    //ถ้ามี idNewCod ให้ซ่อนปุ่ม
                  }
                  {false && <AddPhotoBox addAttachPhoto={this.addAttachPhoto} />}
                </FlexListRow>
              </div>
            </FlexListColumn>
          </DetailWrapper>
          <CodListTable
            dataList={this.state.selectedList}
            selectedListWithCustomer={this.state.selectedListWithCustomer}
            removeList={this.removeList}
            hideTools={this.props.hideTools}
            newCodCase={this.props.idNewCod}
          />
          {!this.props.hideAddCodBTN && (
            <ColoredButton
              onClick={() => this.setState({ isPayableCodListModal: true })}
              color="#3377B7"
              bgColor="#D0E8FF"
            >
              <i
                style={{
                  color: '#318DE8',
                }}
                class="far fa-plus-circle"
              ></i>
              เพิ่มรายการ COD
            </ColoredButton>
          )}
        </StyledModal>

        {this.state.isOpenLightbox && (
          <Lightbox
            style={{ zIndex: '9999' }}
            mainSrc={this.state.viewImageSrc}
            onCloseRequest={() => this.setState({ isOpenLightbox: false, viewImageSrc: '' })}
          />
        )}

        <ConfirmPayCodModal
          show={this.state.isConfirmPayCodModal}
          close={() => this.setState({ isConfirmPayCodModal: false })}
          selectedList={this.state.selectedList}
          document={this.state.attachPhotos}
          cod_in_date={this.state.transferDate}
          closeAll={() => {
            this.props.close();
            this.clearState();
          }}
        />

        {this.state.isEditUpdateCodCodModal && (
          <EditUpdateCodModal
            show={this.state.isEditUpdateCodCodModal}
            close={() => this.setState({ isEditUpdateCodCodModal: false })}
            document={this.state.attachPhotos}
            cod_in_date={this.state.transferDate}
            idUpdate={this.props.idNewCod}
            closeAll={() => {
              this.props.close();
              this.clearState();
            }}
          />
        )}

        {this.state.isOPSConfirmCodModal && (
          <OPSConfirmCodModal
            show={this.state.isOPSConfirmCodModal}
            close={() => this.setState({ isOPSConfirmCodModal: false })}
            idUpdate={this.props.idNewCod}
            closeAll={() => {
              this.props.close();
              this.clearState();
            }}
          />
        )}

        {this.state.isOPSDenielCodModal && (
          <OPSDenielCodModal
            show={this.state.isOPSDenielCodModal}
            close={() => this.setState({ isOPSDenielCodModal: false })}
            idUpdate={this.props.idNewCod}
            closeAll={() => {
              this.props.close();
              this.clearState();
            }}
          />
        )}
      </div>
    );
  }
}

export default compose(
  graphql(accountInfoListQuery, {
    name: 'accountInfoListProps',
    options: props => {
      console.log(props);
      return {
        fetchPolicy: 'network-only',
        variables: {
          _id: props.vendorId,
        },
      };
    },
  })
)(PayCodModal);
