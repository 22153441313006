import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Button,
  Modal,
  DropdownButton,
  MenuItem,
  FormControl,
  Label,
  FormGroup,
  Popover,
  Tooltip,
  NavDropdown,
  NavItem,
  Dropdown,
  ButtonGroup,
  SplitButton,
  Nav,
} from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import CountDownTimer from './components/CountdownTimer/countdownTimer';
import moment from 'moment';
import _, { isUndefined } from 'lodash';
import Autosuggest from 'react-autosuggest';
import transporterInfoListQuery from './graphql/query/transporterInfoList';
import DO_NOTHING_MUTATION from './graphql/mutation/doNothing';
import Input from './components/Input';
import TextArea from './components/TextArea';
import SelectBox from './components/SelectBox';
import SelectComponent from './components/Select';
import s3 from 'config/s3';
import NotificationSystem from 'react-notification-system';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import shipmentSlasList from './graphql/query/shipmentSlasList';
import shipmentSlaList from './graphql/query/shipmentSlaList';

import accountInfoList from './graphql/query/accountInfoList';
import driverUserList from './graphql/query/driverUserList';
import userTruckList from './graphql/query/userTruckList';
import datamasterSlaTitlesList from './graphql/query/datamasterSlaTitlesList';
import datamasterSlaLevelsList from './graphql/query/datamasterSlaLevels';
import adminName from './graphql/query/adminName';
import shipmentListQuery from './graphql/query/shipmentList';
import shipmentAdminListQuery from './graphql/query/shipmentAdminList';
import assignDriver from './graphql/mutation/assignDriver';
import cancelShipment from './graphql/mutation/cancelShipment';
import assignAdminShipment from './graphql/mutation/assignAdminShipment';
import settingTruckListQuery from './graphql/query/settingTruckList';
import assignAgentMutation from './graphql/mutation/assignAgent';

import singleUploadMutation from './graphql/mutation/singleUpload';
import createShipmentSlaMutation from './graphql/mutation/createShipmentSla';
import updateShipmentSla from './graphql/mutation/updateShipmentSla';
// import cubicImportShipmentMutation from './graphql/mutation/cubicImportShipment';
import importShipmentMutation from './graphql/mutation/importShipment';

import axios from 'axios';
import { UploadFile } from './container';

import clearShipmentDriverAcceptMutation from './graphql/mutation/clearShipmentDriverAccept';
import sendShipmentToMamaMutation from './graphql/mutation/sendShipmentToMama';

import CardTable from 'components/CardTable/CardTable.jsx';
import styled from 'styled-components';
import { client } from '../..';
import baseUrl from './../../config/baseUrl';
import reportUrl from './../../config/reportUrl';
import doNothing from './graphql/mutation/doNothing';

const tooltip = <Tooltip id="tooltip">ดูข้อมูล</Tooltip>;

const tooltipFlash = <Tooltip id="tooltip">flashdeals</Tooltip>;

const tooltipREbook = <Tooltip id="tooltip">re-book</Tooltip>;

let driverList = [];
let truckList = [];
let driverItem = [];
let truckItem = [];
let agentList = [];
let agentItem = [];

let caseTitlelist = [];
let caseLevelList = [];

const selectOptionCaseChanel = [
  { value: 'อีเมล์', label: 'อีเมล์' },
  { value: 'โทรศัพท์', label: 'โทรศัพท์' },
  { value: 'ข้อความทางไลน์', label: 'ข้อความทางไลน์' },
  { value: 'ข้อความทางเฟสบุ๊ค', label: 'ข้อความทางเฟสบุ๊ค' },
  { value: 'Driver App', label: 'Driver App' },
];

const rejectList = [
  { value: 'ทดสอบ', label: 'ทดสอบ' },
  { value: 'ลูกค้ายกเลิกงาน', label: 'ลูกค้ายกเลิกงาน' },
  { value: 'เปลี่ยนเส้นทาง', label: 'เปลี่ยนเส้นทาง' },
  { value: 'เปลี่ยนวันใช้งาน', label: 'เปลี่ยนวันใช้งาน' },
  { value: 'เปลี่ยนประเภทรถ', label: 'เปลี่ยนประเภทรถ' },
  { value: 'ไม่ตรงตามเงื่อนไข', label: 'ไม่ตรงตามเงื่อนไข' },
  { value: 'ไม่พร้อมให้บริการ', label: 'ไม่พร้อมให้บริการ' },
  { value: 'ลูกค้าจองซ้ำ', label: 'ลูกค้าจองซ้ำ' },
  { value: 'ไม่สามารถติดต่อลูกค้าได้', label: 'ไม่สามารถติดต่อลูกค้าได้' },
  { value: 'ลูกค้าต้องการใช้บริการเสริม', label: 'ลูกค้าต้องการใช้บริการเสริม' },
];

//styled component
const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const Container = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    //position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-style: inset;
  border-width: 0 15px 30px 15px;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  & p {
    text-align: center;
    top: 10px;
    left: -8px;
    position: relative;
    width: 15px;
    height: 15px;
    margin: 0px;
    color: #fff;
    font-weight: bold;
  }
  & span {
    position: relative;
    top: -5px;
    left: 20px;
  }
`;

const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const NavItemDropDownList = styled(NavItem)`
  & a {
    & i {
    }
  }
`;

const AutosuggestContainerAgent = styled.div`
  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestions-container {
    position: relative;
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;
const AutosuggestContainer = styled.div`
  min-height: 70px;

  & .react-autosuggest__suggestions-list {
    list-style: none;
    color: #000000;
    padding: 0;
    margin: 0;
    border: 1px solid #e3e3e3;
    margin-top: 0px;
    border-radius: 4px;

    position: absolute;
    background-color: white;
    z-index: 999;
    min-width: 150px;
    white-space: nowrap;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  }

  & .react-autosuggest__suggestion {
    padding: 10px 10px;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: #e3e3e3;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #e3e3e3;
    }
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #e3e3e3;
  }
`;

const ColLeft = styled.div`
  width: 300px;
  border-right: 0px solid #dbdbdb;
  background-color: #ffff;
  // overflow: auto;
`;
const ColRight = styled.div`
    padding 14px;
    width: 100%;
    overflow: auto;
`;

const ColRightOne = styled.div`
    // width :calc(100% - 300px);
    // height: calc(100vh - 320px);
    // margin: 10px;
    padding 14px;
    background-color: #ffff;
    // border-radius: 5px;
`;

const UlTruck = styled.ul`
  padding: 0;
  max-height: calc(100vh - 200px);
  overflow: auto;

  & li {
    position: relative;
    list-style: none;
    padding: 10px;
    border-bottom: 1px solid #eaeaea;
    cursor: pointer;
    color: #c4c4c4;

    &:hover {
      background-color: #fafafa;
    }

    &.active {
      color: unset;
    }
  }

  & i {
    position: absolute;
    right: 5px;
    top: 40px;
    font-size: 12px;
  }
`;

const BoxDashboard = styled(Row)`
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-top: 10px
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #F7F7F8;


    & h4{
        margin: 0;
        font-size: 18px;
        font-weight: bold;
    }
`;

const ColRightPopup = styled(Col)`
  padding-left: unset;
  padding-right: 5px;
  text-align: right;
  color: black;
`;

const RightButtonContainer = styled.div`
  float: 'right';
  marginleft: '15px';

  & #dropdown-split-variants-cubic {
    height: 32px;
    margin-right: 10px;
  }
`;

const styleddefault = {
  backgroundColor: '#000000',
  border: '1px solid #000000',
  color: '#FFFFFF',
  fontWeight: 'bold',
};
const styleddefaultoutline = {
  backgroundColor: '#FFFFFF',
  border: '1px solid #E3E3E3',
  color: '#000000',
  fontWeight: 'bold',
};

const styledprimary = {
  backgroundColor: '#D90101',
  border: '1px solid #D90101',
  color: '#FFFFFF',
  fontWeight: 'bold',
};
const styledprimaryoutline = {
  backgroundColor: '#FFFFFF',
  border: '1px solid #D90101',
  color: '#D90101',
  fontWeight: 'bold',
};
const styledsuccessoutline = {
  backgroundColor: '#D90101',
  border: '1px solid #D90101',
  color: '#FFFFFF',
  fontWeight: 'bold',
};
const styleddark = {
  backgroundColor: '#FFFFFF',
  border: '1px solid #E3E3E3',
  color: '#000000',
  fontWeight: 'bold',
};
const styleddangeroutline = {
  backgroundColor: '#FFFFFF',
  border: '1px solid #E3E3E3',
  color: '#000000',
  fontWeight: 'bold',
};
const styledwarning = {
  backgroundColor: '#ffffff',
  border: '1px solid #FF9D00',
  color: '#FF9D00',
  fontWeight: 'bold',
};

class Shipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      refetchAnimation: 'fas fa-redo-alt',
      animationUpload: false,
      animationUploadSecond: false,
      show_cancel: false,
      show_assign: false,
      // admin_name: "",
      shipmentNumber: '',
      shipmentID: null,
      show_reset_shipment: false,
      show_share_shipment: false,
      show_create_date: '',
      show_completed_date: '',
      // transporterId: null,
      transporterName: '',
      transporterSuggestions: [],
      agentSuggestions: [],
      showReport: false,
      reportDate: '',
      show_reportDate: '',
      // driver: 0,
      // shipment_type:0,
      agentId: 0,
      agentName: '',
      agentSelected: '',
      show_transporter: false,
      admin_id: 0,
      // shipment_name : '',
      DriverSuggestions: [],
      TruckSuggestions: [],
      driverID: 0,
      truckID: 0,
      driverSelected: '',
      truckSelected: '',
      dropdownPickup_date: 'งานทั้งหมด',

      pickUpDate: '',
      shipperName: '',
      truckType: '',
      driverName: '',
      driverPhone: '',
      pickUpPoint: '',
      dropPoint: '',
      additionService: [],

      rejectSelected: null,
      rejectRemark: '',

      // data management //////////////////////////////
      expireDateCheck: null,
      completedDateChecK: null,
      saveCaseCheck: '',
      caseTitleChange: false,
      serviceTimeCase: '',
      showProblem: false,
      showConfirmModalCase: false,
      caseLevelDetail: [],
      caseId: '',
      caseIndex: -1,
      caseStatus: 0,
      editable: false,
      attachFile: [],
      attachSecondFile: [],
      secondEditable: false,
      expireDate: '',
      levelUnit: '',
      levelTime: '',
      newCase: true,
      //หัวข้อปัญหา
      caseTitle: { value: '', label: '-- เลือก --' },
      caseTitleId: '',
      caseTitleInvalid: false,

      //ระดับปัญหา
      caseLevel: { value: '', label: '-- เลือก --' },
      caseLevelInvalid: false,
      caseLevelId: '',

      //1.รายละเอียดปัญหา
      caseDetail: '',
      caseDetailInvalid: false,
      //วัน-เวลาในการรับแจ้งปัญหา
      InformRecieveDateTime: '',
      InformRecieveDateTimeShow: '',
      InformRecieveDateTimeInvalid: false,

      //ผู้รับปัญหา
      caseOwner: '',
      caseOwnerInvalid: false,
      //ช่องทางรับปัญหา
      caseRecieveChanel: { value: '', label: '-- เลือก --' },
      caseRecieveChanelInvalid: false,
      //เบอร์โทรศัพท์ผู้แจ้งปัญหา
      phoneInformer: '',
      phoneInformerInvalid: false,
      //อีเมล (ผู้แจ้งปัญหา)
      mailInformer: '',
      mailInformerInvalid: false,

      //2. รายละเอียดการแก้ไขปัญหา
      caseResolveDetail: '',
      caseResolveDetailInvalid: false,
      //วัน-เวลาตอบกลับลูกค้า
      customerReplyDateTime: '',
      customerReplyDateTimeShow: '',
      customerReplyDateTimeInvalid: false,

      //ผู้ตอบกลับปัญหา
      caseAnswer: '',
      caseAnswerInvalid: false,
      //ช่องทางการตอบกลับปัญหา
      caseAnswerChanel: { value: '', label: '-- เลือก --' },
      caseAnswerChanelInvalid: false,

      //Query ShipmentSlas
      shipmentSlas: [],

      //case_status
      sla_max_level: 0,
      level_status: 0,
      sla_completed: null,
      ///////////////////////////////////////////////////

      //Query ShipmentAdmins
      stage: '',
      pick_up_date: '',
      show_pick_up_date: '',
      shipment_number: '',
      shipment_status: 0,
      setting_truck_id: null,
      setting_truck_type_id: null,
      transporter: null,
      paid_status: null,
      completed_date: '',
      create_date: '',
      last_update: '',
      total_page: 0,
      page_number: 1,
      order_by: 'create_date',
      order_type: '-1',
      showData: 30,
      // shiper_info
      account_info_email: '',

      //isOpenImportBookingFileModal
      isOpenImportBookingFileModal: false,
    };

    this._inputImportFile = React.createRef();
    this._inputAttachFileFirstCase = React.createRef();
    this._inputAttachFileSecondCase = React.createRef();
    this.notificationSystem = React.createRef();
    this.notificationSystem1 = React.createRef();
  }

  componentDidMount() {
    setInterval(this.refetchQueries, 300000);
  }

  giztixMenu() {
    return localStorage.getItem('giztixmenu');
  }

  handlePickupDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ pick_up_date: date, show_pick_up_date: show }, () => {
      refetch();
    });
  }

  handleDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ create_date: date, show_create_date: show }, () => {
      refetch();
    });
  }

  handleCompletedDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ completed_date: date, show_completed_date: show }, () => {
      refetch();
    });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShowcancel(shipmentNumber, shipmentID) {
    this.setState({
      show_cancel: true,
      shipmentID: shipmentID,
      shipmentNumber: shipmentNumber,
      rejectSelected: null,
      rejectRemark: '',
    });
  }

  handleShowAssignAdmin(shipmentNumber, shipmentID) {
    this.setState({
      show_assign: true,
      shipmentID: shipmentID,
      shipmentNumber: shipmentNumber,
    });
  }

  handleShowTransporter(shipment) {
    this.setState({
      show_transporter: true,
      shipmentID: shipment._id,
      shipmentNumber: shipment.shipment_number,
      agentId: shipment.transporter ? shipment.transporter._id : '',
      agentName: shipment.transporter ? shipment.transporter.account_info_business_name : '',
      agentSelected: shipment.transporter ? shipment.transporter.account_info_business_name : '',
    });
  }

  handleResetShipment(shipment) {
    this.setState({
      show_reset_shipment: true,
      shipmentID: shipment._id,
      shipmentNumber: shipment.shipment_number,
    });
  }

  handleShareShipment(shipment) {
    this.setState({
      show_share_shipment: true,
      shipmentID: shipment._id,
      shipmentNumber: shipment.shipment_number,
    });
  }

  handleCloseAgent() {
    this.setState({
      show_transporter: false,
      shipmentID: '',
      shipmentNumber: '',
      agentId: '',
      agentName: '',
    });
  }

  handleShow(shipment) {
    if (shipment.driver_user_id) {
      this.setState({
        show: true,
        shipmentID: shipment._id,
        shipmentNumber: shipment.shipment_number,
        driverID: shipment.driver_user_id._id,
        truckID: shipment.user_setting_truck_id,
        driverSelected: shipment.driver_user_id.driver_name
          ? `${shipment.driver_user_id.driver_name} (0${shipment.driver_user_id.driver_phone_number})`
          : '',
        truckSelected: shipment.driver_user_id.user_setting_truck_license_plate
          ? shipment.driver_user_id.user_setting_truck_license_plate
          : '',
      });
    } else {
      this.setState({
        show: true,
        shipmentID: shipment._id,
        shipmentNumber: shipment.shipment_number,
        driverID: shipment.driver_user_id,
        truckID: shipment.user_setting_truck_id,
        driverSelected: '',
        truckSelected: '',
      });
    }
  }

  renderDriver(data) {
    // console.log(data.driverUserList.driver_user);
    driverList = data.driverUserList.driver_user.map(driver => {
      return {
        value: driver._id,
        label:
          driver.driver_name +
          ' (' +
          driver.driver_phone_code +
          '-' +
          driver.driver_phone_number +
          ')',
        user_setting_truck_id: driver.user_setting_truck_id,
        user_setting_truck_license_plate: driver.user_setting_truck_license_plate,
      };
    });
  }

  renderAgent(data) {
    // console.log("renderAgent",data.accountInfoList.account_info);
    agentList = data.accountInfoList.account_info.map(agent => {
      // console.log("renderAgent", agent);
      return {
        value: agent._id,
        label: agent.account_info_business_name,
      };
    });
  }

  renderTruck(data) {
    truckList = data.userSettingTruckList.user_setting_truck.map(truck => {
      return { value: truck._id, label: truck.user_setting_truck_license_plate };
    });
  }

  handleChangeDriver(driver) {
    // console.log(`driverSelected:`, driver);
    this.setState({
      driverSelected: driver,
      driverID: driver.value,
      truckSelected: {
        value: driver.user_setting_truck_id,
        label: driver.user_setting_truck_license_plate,
      },
      truckID: driver.user_setting_truck_id,
    });
  }

  handleChangeTruck(truck) {
    // console.log(`truckSelected:`, truck);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ truckSelected: truck, truckID: truck.value });
    // console.log(`truckSelected:`, this.state.truckID);
  }

  handleChangeAgent(agent) {
    // console.log(`handleChangeAgent:`, agent);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ agentSelected: agent, agentId: agent.value });
    // console.log(`truckSelected:`, this.state.truckID);
  }

  handleChangeReject(reject) {
    // console.log(`truckSelected:`, truck);
    // console.log(`truckSelected:`, truck.value);
    this.setState({ rejectSelected: reject, rejectRemark: reject.value });
    // console.log(`truckSelected:`, this.state.truckID);
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  async renderViewDriver(id) {
    // if (id == null) {
    //     return "-";
    // }
    // const { data } = await client.query({
    //     query: driverUserList,
    //     fetchPolicy: 'network-only',
    //     variables: {
    //         _id: id,
    //     }
    // });
    // const driver = data.driverUserList.driver_user.filter(item => item._id == id );
    // if(driver.length > 0){
    //     return `${driver[0].driver_name} (${driver[0].driver_phone_code} ${driver[0].driver_phone_number}`;
    // }
  }

  async renderViewTruck(id) {
    // if (id == null) {
    //     return "-";
    // }
    // const { data } = await client.query({
    //     query: userTruckList,
    //     fetchPolicy: 'network-only',
    //     variables: {
    //         _id: id,
    //     }
    // });
    // const truck = data.userSettingTruckList.user_setting_truck.filter(item => item._id == id );
    // if(truck.length > 0){
    //     return `${truck[0].user_setting_truck_license_plate}`;
    // }
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    if (page < 0) {
      page = 0;
    }
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  renderStatus(status) {
    switch (status) {
      case 1:
        return <Label style={styleddefaultoutline}>รอขนส่งรับงาน</Label>;
      case 2:
        return <Label style={styleddefaultoutline}>รอขนส่งรับงาน</Label>;
      case 3:
        return <Label style={styledprimaryoutline}>กำลังดำเนินการขนส่ง</Label>;
      case 4:
        return <Label style={styledsuccessoutline}>เสร็จสิ้นแล้ว</Label>;
      case 5:
        return <Label style={styleddark}>ถูกยกเลิก</Label>;
      case 6:
        return <Label style={styleddangeroutline}>งานเกิดปัญหา</Label>;
      case 7:
        return <Label style={styleddefaultoutline}>แบบร่าง</Label>;
      case 8:
        return <Label style={styledprimary}>นัดหมายลูกค้าแล้ว</Label>;
      case 9:
        return <Label style={styledwarning}>กำลังคืนคลัง</Label>;
      case 10:
        return <Label style={styleddefault}>รอเริ่มงาน</Label>;
      default:
        return 'งานทั้งหมด';
    }
  }
  //1=complete/ชำระแล้ว,2null=pending/รองานเสร็จ,3=wait summary invoice/รอสร้างใบแจ้งหนี้,4=wait billing/รอวางบิล,5=wait pay/รอชำระ,6=cancel/ถูกยกเลิก
  renderStatusPayment(status) {
    if (status) {
      return <Label bsStyle="success">ส่ง CT แล้ว</Label>;
    } else {
      return <Label bsStyle="default">รอส่ง CT</Label>;
    }

    /*if (status.total > 0) {
      if (status.complete === status.total) {
        return <Label bsStyle="success">ส่งตั้งเบิกแล้ว</Label>;
      } else {
        return <Label bsStyle="primary">รอส่งตั้งเบิก</Label>;
      }
    } else {
      return <Label bsStyle="default">รอตั้งเบิก</Label>;
    }*/
  }

  renderStatusPaid(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="success">ชำระแล้ว</Label>;
      case 2:
        return <Label bsStyle="default">รองานเสร็จ</Label>;
      case 3:
        return <Label bsStyle="warning">รอสร้างใบแจ้งหนี้</Label>;
      case 4:
        return <Label bsStyle="warning">รอวางบิล</Label>;
      case 5:
        return <Label bsStyle="primary">รอชำระ</Label>;
      case 6:
        return <Label bsStyle="danger">ถูกยกเลิก</Label>;
      default:
        return '-';
    }
  }

  searchPickupDate(mode, refetch) {
    let date = '';
    let show = '';
    if (mode == 'today') {
      date = `${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`;
      show = `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
    } else if (mode == 'tomorrow') {
      date = `${moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')}:${moment()
        .add(1, 'days')
        .format('YYYY-MM-DD')}`;
      show = `${moment()
        .add(1, 'days')
        .format('DD/MM/YYYY')} - ${moment()
        .add(1, 'days')
        .format('DD/MM/YYYY')}`;
    } else {
      date = `${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}:${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`;
      show = `${moment()
        .subtract(1, 'days')
        .format('DD/MM/YYYY')} - ${moment()
        .subtract(1, 'days')
        .format('DD/MM/YYYY')}`;
    }
    this.setState(
      { pick_up_date: date, show_pick_up_date: show, completed_date: '', show_completed_date: '' },
      () => {
        refetch();
      }
    );
  }

  searchCompletedDate(mode, refetch) {
    let date = '';
    let show = '';
    if (mode == 'completedToday') {
      date = `${moment().format('YYYY-MM-DD')}:${moment().format('YYYY-MM-DD')}`;
      show = `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`;
    } else {
      date = `${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}:${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`;
      show = `${moment()
        .subtract(1, 'days')
        .format('DD/MM/YYYY')} - ${moment()
        .subtract(1, 'days')
        .format('DD/MM/YYYY')}`;
    }
    this.setState(
      { completed_date: date, show_completed_date: show, pick_up_date: '', show_pick_up_date: '' },
      () => {
        refetch();
      }
    );
  }

  refetchQueries = () => {
    client.mutate({ mutation: DO_NOTHING_MUTATION, refetchQueries: () => ['shipmentAdmins'] });
    this.setState({ refetchAnimation: 'fas fa-redo-alt fa-spin' });
    setTimeout(() => {
      this.setState({ refetchAnimation: 'fas fa-redo-alt' });
    }, 1000);
  };

  //Autosuggest Agent
  getSuggestionsAgent = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: accountInfoList,
        fetchPolicy: 'network-only',
        variables: {
          account_info_type: 3,
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        agentSuggestions:
          transporterData.length == 0
            ? [
                {
                  id: 'dd',
                  name: 'ddddd',
                },
                {
                  id: 'ee',
                  name: 'ee',
                },
              ]
            : transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueAgent = suggestion => suggestion.name;

  renderSuggestionAgent = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedAgent = ({ value }) => {
    this.getSuggestionsAgent(value);
  };

  onSuggestionsClearRequestedAgent = () => {
    this.setState({
      agentSuggestions: [],
    });
  };

  shouldRenderSuggestionsAgent() {
    return true;
  }
  //End Autosuggest Agent

  //Autosuggest Transporter
  getSuggestionsTransporter = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: transporterInfoListQuery,
        fetchPolicy: 'network-only',
        variables: {
          account_info_business_name: inputValue,
        },
      });

      const transporterData = data.accountInfoList.account_info.map(e => {
        return {
          id: e._id,
          name: e.account_info_business_name,
        };
      });

      this.setState({
        transporterSuggestions: transporterData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTransporter = suggestion => suggestion.name;

  renderSuggestionTransporter = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTransporter = ({ value }) => {
    this.getSuggestionsTransporter(value);
  };

  onSuggestionsClearRequestedTransporter = () => {
    this.setState({
      DriverSuggestions: [],
    });
  };

  shouldRenderSuggestionsTransporter() {
    return true;
  }
  //End Autosuggest Transporter

  //Autosuggest Driver
  getSuggestionsDriver = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: driverUserList,
        fetchPolicy: 'network-only',
        variables: {
          driver_name: inputValue,
        },
      });

      const driverData = data.driverUserList.driver_user.map(e => {
        return {
          id: e._id,
          name: `${e.driver_name} (0${e.driver_phone_number})`,
          user_setting_truck_id: e.user_setting_truck_id ? e.user_setting_truck_id : '',
          user_setting_truck_license_plate: e.user_setting_truck_license_plate
            ? e.user_setting_truck_license_plate
            : '',
        };
      });

      this.setState({
        DriverSuggestions: driverData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueDriver = suggestion => suggestion.name;

  renderSuggestionDriver = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedDriver = ({ value }) => {
    this.getSuggestionsDriver(value);
  };

  onSuggestionsClearRequestedDriver = () => {
    this.setState({
      DriverSuggestions: [],
    });
  };

  shouldRenderSuggestionsDriver() {
    return true;
  }
  //End Autosuggest Driver

  //Autosuggest Truck
  getSuggestionsTruck = async value => {
    const inputValue = value
      .trim()
      .toString()
      .toLowerCase();
    const inputLength = inputValue.length;

    try {
      const { data } = await client.query({
        query: userTruckList,
        fetchPolicy: 'network-only',
        variables: {
          user_setting_truck_license_plate: inputValue,
        },
      });

      const driverData = data.userSettingTruckList.user_setting_truck.map(e => {
        return {
          id: e._id,
          name: e.user_setting_truck_license_plate,
        };
      });

      this.setState({
        TruckSuggestions: driverData,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getSuggestionValueTruck = suggestion => suggestion.name;

  renderSuggestionTruck = suggestion => <div key={suggestion.id}>{suggestion.name}</div>;

  onSuggestionsFetchRequestedTruck = ({ value }) => {
    this.getSuggestionsTruck(value);
  };

  onSuggestionsClearRequestedTruck = () => {
    this.setState({
      TruckSuggestions: [],
    });
  };

  shouldRenderSuggestionsTruck() {
    return true;
  }
  //End Autosuggest Truck

  openReportShipment(mode, admin_id) {
    this.setState({
      showReport: true,
      modeReport: mode,
      reportDate: '',
      show_reportDate: '',
      admin_id,
    });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  exportReportShipment() {
    const uri = `${reportUrl}/booking-data-result?admin_id=${this.state.admin_id}&date=${this.state.reportDate}`;
    window.location = uri;

    this.setState({ showReport: false, reportDate: '', show_reportDate: '', modeReport: '' });
  }

  getShipmentNumberFormat = (shipmentNumber, prefix = '') => {
    return `${prefix || ''}${shipmentNumber}`;
  };

  getShipmentUrlFromPrefix = (prefix, id) => {
    if (prefix === 'GLZ') {
      return `/lazada-shipment-detail?id=${id}`;
    }

    return `/shipmentdetail?shipment=${id}`;
  };

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  addNotificationCubic = (typeStatus, message) => {
    const notification = this.notificationSystem1.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  renderCustomerType = (status, btxId) => {
    switch (status) {
      case 0:
        return <span>ทั้งหมด</span>;
      case 1:
        return (
          <span>
            <span
              style={{ padding: '2px 6px', marginRight: '5px', backgroundColor: '#FED249' }}
              className="label"
            >
              <i className="fal fa-user" />
            </span>
          </span>
        );
      case 2:
        return (
          <span>
            <span
              style={{ padding: '2px 6px', marginRight: '5px' }}
              className="label label-warning"
            >
              <i className="fal fa-users" />
            </span>
          </span>
        );
      case 3:
        return (
          <a target="_blank" href={`/giztixbusinessdetail?id=${btxId}`}>
            <span
              style={{ padding: '2px 6px', marginRight: '5px' }}
              className="label label-success"
            >
              <i class="fal fa-donate" />
            </span>
          </a>
        );
      case 4:
        return (
          <a target="_blank" href={`/giztixbusinessdetail?id=${btxId}`}>
            <span
              style={{ padding: '2px 6px', marginRight: '5px' }}
              className="label label-primary"
            >
              <i class="fal fa-gem" />
            </span>
          </a>
        );
      default:
        return '';
    }
  };

  handlerShowCase = (shipment, admin) => {
    var additionl = [];
    if (shipment.shipment_additional.length > 0) {
      additionl = [...shipment.shipment_additional];
    }
    // console.log(additionl)

    this.setState({
      shipmentID: shipment._id,
      shipmentNumber: shipment.shipment_number,
      InformRecieveDateTime: moment().format('YYYY-MM-DD HH:mm'),
      InformRecieveDateTimeShow: moment().format('DD/MM/YYYY HH:mm'),
      InformRecieveDateTimeInvalid: false,

      pickUpDate: shipment.pick_up_date ? shipment.pick_up_date : '-',
      shipperName: shipment.shipper ? shipment.shipper.account_info_business_name : '-',
      truckType:
        shipment.setting_truck_name && shipment.truck_type_name
          ? `${shipment.setting_truck_name.th} ${shipment.truck_type_name.th}`
          : '-',
      driverName: shipment.driver_user_id
        ? shipment.driver_user_id.driver_name
          ? shipment.driver_user_id.driver_name
          : '-'
        : '-',
      driverPhone: shipment.driver_user_id
        ? shipment.driver_user_id.driver_phone_number
          ? `${shipment.driver_user_id.driver_phone_code}  ${shipment.driver_user_id.driver_phone_number}`
          : '-'
        : '-',
      pickUpPoint:
        shipment.shipment_address.length > 0 ? shipment.shipment_address[0].address : '-',
      dropPoint: shipment.shipment_address.length > 1 ? shipment.shipment_address[1].address : '-',
      transporterName: shipment.transporter ? shipment.transporter.account_info_business_name : '-',
      additionService:
        shipment.shipment_additional.length > 0 ? [...shipment.shipment_additional] : [],

      showProblem: true,
      caseOwner: admin,
      caseAnswer: admin,
    });

    this.refetchCaseLevelAndTitle();
    this.getShipmentCase(shipment._id);
  };

  renderProblemStatus = (completed, level, total) => {
    // console.log(completed, level, total);
    if (level === 1 && completed === false) {
      return (
        <span>
          <Triangle style={{ borderColor: 'transparent transparent #A6ACAF transparent' }}>
            <p>{total}</p>
            <span>Lv.1</span>
          </Triangle>
        </span>
      );
    } else if (level === 2 && completed === false) {
      return (
        <span>
          <Triangle style={{ borderColor: 'transparent transparent #F39C12 transparent' }}>
            <p>{total}</p>
            <span>Lv.2</span>
          </Triangle>
        </span>
      );
    } else if (level === 3 && completed === false) {
      return (
        <span>
          <Triangle style={{ borderColor: 'transparent transparent #6E2C00 transparent' }}>
            <p>{total}</p>
            <span>Lv.3</span>
          </Triangle>
        </span>
      );
    } else if (level === 4 && completed === false) {
      return (
        <span>
          <Triangle style={{ borderColor: 'transparent transparent #7F4099 transparent' }}>
            <p>{total}</p>
            <span>Lv.4</span>
          </Triangle>
        </span>
      );
    } else if (level === 5 && completed === false) {
      return (
        <span>
          <Triangle style={{ borderColor: 'transparent transparent #FF3333 transparent' }}>
            <p>{total}</p>
            <span>Lv.5</span>
          </Triangle>
        </span>
      );
    } else if (completed === true) {
      return (
        <span>
          <i style={{ color: '#76D233' }} class="fas fa-thumbs-up"></i> แก้ไขปัญหาแล้ว
        </span>
      );
    } else {
      return '';
    }
  };

  resetFieldCase = () => {
    this.setState({
      shipmentSlas: [],
      caseLevelDetail: [],
      newCase: true,
      caseIndex: -1,
      caseId: '',
      caseStatus: -1,
      caseTitle: { value: '', label: '-- เลือก --' },
      caseTitleId: '',
      caseTitleInvalid: false,

      caseLevel: { value: '', label: '-- เลือก --' },
      caseLevelId: '',
      caseLevelInvalid: false,
      attachFile: [],
      attachSecondFile: [],

      serviceTimeCase: '',
      levelTime: '',
      levelUnit: '',
      caseTitleChange: false,
      expireDateCheck: null,
      completedDateChecK: null,

      //1.รายละเอียดปัญหา
      caseDetail: '',
      caseDetailInvalid: false,

      InformRecieveDateTime: '',
      InformRecieveDateTimeShow: '',
      InformRecieveDateTimeInvalid: false,
      //ผู้รับปัญหา
      caseOwner: '',
      caseOwnerInvalid: false,

      caseRecieveChanel: { value: '', label: '-- เลือก --' },
      caseRecieveChanelInvalid: false,

      //เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)
      phoneInformer: '',
      phoneInformerInvalid: false,

      mailInformer: '',
      mailInformerInvalid: false,

      //2. รายละเอียดการแก้ไขปัญหา
      caseResolveDetail: '',
      caseResolveDetailInvalid: false,

      customerReplyDateTime: '',
      customerReplyDateTimeShow: '',
      customerReplyDateTimeInvalid: false,

      //ผู้ตอบกลับปัญหา
      caseAnswer: '',
      caseAnswerInvalid: false,

      caseAnswerChanel: { value: '', label: '-- เลือก --' },
      caseAnswerChanelInvalid: false,

      //Props
      shipmentID: '',
      shipmentNumber: '',
      pickUpDate: '',
      shipperName: '',
      truckType: '',
      driverName: '',
      driverPhone: '',
      pickUpPoint: '',
      dropPoint: '',
      additionService: [],
    });
  };

  handlerCreateNewCase = adminName => {
    this.refetchCaseLevelAndTitle();

    this.setState({
      newCase: true,
      caseLevelDetail: [],
      caseStatus: -1,
      caseId: '',
      caseIndex: -1,
      caseTitle: { value: '', label: '-- เลือก --' },
      caseTitleId: '',
      caseTitleInvalid: false,
      attachFile: [],
      attachSecondFile: [],
      serviceTimeCase: '',
      levelTime: '',
      levelUnit: '',
      caseTitleChange: false,
      expireDateCheck: null,
      completedDateChecK: null,

      caseLevel: { value: '', label: '-- เลือก --' },
      caseLevelId: '',
      caseLevelInvalid: false,

      //1.รายละเอียดปัญหา
      caseDetail: '',
      caseDetailInvalid: false,

      InformRecieveDateTime: moment().format('YYYY-MM-DD HH:mm'),
      InformRecieveDateTimeShow: moment().format('DD/MM/YYYY HH:mm'),
      InformRecieveDateTimeInvalid: false,
      //ผู้รับปัญหา
      caseOwner: adminName,
      caseOwnerInvalid: false,

      caseRecieveChanel: { value: '', label: '-- เลือก --' },
      caseRecieveChanelInvalid: false,

      //เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)
      phoneInformer: '',
      phoneInformerInvalid: false,

      mailInformer: '',
      mailInformerInvalid: false,

      //2. รายละเอียดการแก้ไขปัญหา
      caseResolveDetail: '',
      caseResolveDetailInvalid: false,

      customerReplyDateTime: '',
      customerReplyDateTimeShow: '',
      customerReplyDateTimeInvalid: false,

      //ผู้ตอบกลับปัญหา
      caseAnswer: adminName,
      caseAnswerInvalid: false,

      caseAnswerChanel: { value: '', label: '-- เลือก --' },
      caseAnswerChanelInvalid: false,
    });
  };

  handlerCreateShipmentSla = createShipmentSlaMutation => {
    if (!this.handlerCheckFirstCaseInvalid()) {
      createShipmentSlaMutation({
        variables: {
          createShipmentSla: {
            shipment: this.state.shipmentID,
            datamaster_sla_title: this.state.caseTitleId,
            datamaster_sla_level: this.state.caseLevelId,
            sla_issue: this.state.InformRecieveDateTime,
            sla_description: this.state.caseDetail,
            sla_file: this.state.attachFile,
            sla_channel: this.state.caseRecieveChanel.value,
            customer_email: this.state.mailInformer,
            customer_phone: this.state.phoneInformer,
          },
        },
      });
    }
  };

  handlerCheckFirstCaseInvalid = () => {
    let phoneInvalid = this.state.phoneInformer
      ? this.state.phoneInformer.match('^[0][0-9]{8,9}$')
        ? false
        : true
      : false;
    let dateInvalid = this.state.InformRecieveDateTime
      ? moment(this.state.InformRecieveDateTime) > moment()
        ? true
        : false
      : true;
    let mailInvalid = this.state.mailInformer ? !this.checkEmail(this.state.mailInformer) : false;
    this.setState({
      caseTitleInvalid: this.state.caseTitle.value ? false : true,
      caseLevelInvalid: this.state.caseLevel.value ? false : true,
      caseDetailInvalid: this.state.caseDetail ? false : true,
      InformRecieveDateTimeInvalid: this.state.InformRecieveDateTime
        ? moment(this.state.InformRecieveDateTime) > moment()
          ? true
          : false
        : true,
      caseOwnerInvalid: this.state.caseOwner ? false : true,
      caseRecieveChanelInvalid: this.state.caseRecieveChanel.value ? false : true,
      phoneInformerInvalid: this.state.phoneInformer
        ? this.state.phoneInformer.match('^[0][0-9]{8,9}$')
          ? false
          : true
        : false,
      mailInformerInvalid: this.state.mailInformer
        ? !this.checkEmail(this.state.mailInformer)
        : false,
    });
    return !(
      this.state.caseTitle.value &&
      this.state.caseTitleId &&
      this.state.caseLevel.value &&
      this.state.caseLevelId &&
      this.state.caseDetail &&
      this.state.InformRecieveDateTime &&
      this.state.InformRecieveDateTimeShow &&
      this.state.caseOwner &&
      this.state.caseRecieveChanel.value &&
      !phoneInvalid &&
      !dateInvalid &&
      !mailInvalid
    );
  };

  handlerSaveFirstCase = (updateShipment, caseId) => {
    if (!this.handlerCheckFirstCaseInvalid()) {
      this.setState({ saveCaseCheck: 1 });
      if (false) {
        if (!this.handlerCheckSecondCaseInvalid()) {
          this.state.caseTitleChange
            ? updateShipment({
                variables: {
                  params: {
                    _id: caseId,
                  },
                  updateShipmentSla: {
                    datamaster_sla_title: this.state.caseTitleId,
                    datamaster_sla_level: this.state.caseLevelId,
                    sla_issue: this.state.InformRecieveDateTime,
                    sla_description: this.state.caseDetail,
                    sla_file: this.state.attachFile,
                    sla_channel: this.state.caseRecieveChanel.value,
                    customer_email: this.state.mailInformer,
                    customer_phone: this.state.phoneInformer,
                  },
                },
              })
            : updateShipment({
                variables: {
                  params: {
                    _id: caseId,
                  },
                  updateShipmentSla: {
                    sla_issue: this.state.InformRecieveDateTime,
                    sla_description: this.state.caseDetail,
                    sla_file: this.state.attachFile,
                    sla_channel: this.state.caseRecieveChanel.value,
                    customer_email: this.state.mailInformer,
                    customer_phone: this.state.phoneInformer,
                  },
                },
              });
        }
      } else {
        this.state.caseTitleChange
          ? updateShipment({
              variables: {
                params: {
                  _id: caseId,
                },
                updateShipmentSla: {
                  datamaster_sla_title: this.state.caseTitleId,
                  datamaster_sla_level: this.state.caseLevelId,
                  sla_issue: this.state.InformRecieveDateTime,
                  sla_description: this.state.caseDetail,
                  sla_file: this.state.attachFile,
                  sla_channel: this.state.caseRecieveChanel.value,
                  customer_email: this.state.mailInformer,
                  customer_phone: this.state.phoneInformer,
                },
              },
            })
          : updateShipment({
              variables: {
                params: {
                  _id: caseId,
                },
                updateShipmentSla: {
                  sla_issue: this.state.InformRecieveDateTime,
                  sla_description: this.state.caseDetail,
                  sla_file: this.state.attachFile,
                  sla_channel: this.state.caseRecieveChanel.value,
                  customer_email: this.state.mailInformer,
                  customer_phone: this.state.phoneInformer,
                },
              },
            });
      }
    } else {
      console.log('Invalid');
    }
  };

  handlerCheckSecondCaseInvalid = () => {
    const dateInvalid = this.state.customerReplyDateTime
      ? moment(this.state.customerReplyDateTime) > moment()
        ? true
        : false
      : true;
    this.setState({
      caseResolveDetailInvalid: this.state.caseResolveDetail ? false : true,
      customerReplyDateTimeInvalid: this.state.customerReplyDateTime
        ? moment(this.state.customerReplyDateTime) > moment()
          ? true
          : false
        : true,
    });

    return !(this.state.caseResolveDetail && this.state.customerReplyDateTime && !dateInvalid);
  };

  handlerSaveSecondCase = (updateShipment, caseId) => {
    if (!this.handlerCheckSecondCaseInvalid()) {
      this.setState({ saveCaseCheck: 2 });
      updateShipment({
        variables: {
          params: {
            _id: caseId,
          },
          updateShipmentSla: {
            respond_description: this.state.caseResolveDetail,
            respond_date: this.state.customerReplyDateTime,
            respond_file: this.state.attachSecondFile,
            respond_channel: this.state.caseAnswerChanel.value,
          },
        },
      });
    } else {
      console.log('second Invalid');
    }
  };

  handleDatePicker(event, mode) {
    if (event) {
      try {
        const date = `${event.format('YYYY-MM-DD HH:mm')}`;
        const show = `${event.format('DD/MM/YYYY HH:mm')}`;
        if (mode == 'receive') {
          this.setState({
            InformRecieveDateTime: date,
            InformRecieveDateTimeShow: show,
            InformRecieveDateTimeInvalid: false,
          });
        } else if ((mode = 'answer')) {
          this.setState({
            customerReplyDateTime: date,
            customerReplyDateTimeShow: show,
            customerReplyDateTimeInvalid: false,
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      if (mode == 'receive') {
        this.setState({
          InformRecieveDateTime: '',
          InformRecieveDateTimeShow: '',
          InformRecieveDateTimeInvalid: false,
        });
      } else if ((mode = 'answer')) {
        this.setState({
          customerReplyDateTime: '',
          customerReplyDateTimeShow: '',
          customerReplyDateTimeInvalid: false,
        });
      }
    }
  }

  handlerChangeShipmentStatus = (status, refetch) => {
    this.setState(
      {
        stage:
          status == 100
            ? ''
            : status == 2
            ? 'pending'
            : status == 3 || status == 8 || status == 10
            ? 'inprogress'
            : status == 4
            ? 'completed'
            : status == 5
            ? 'cancel'
            : status == 6
            ? 'problem'
            : status == 0
            ? this.state.stage
            : '',
        shipment_status: status == 100 || status == 6 ? 0 : status,
        page_number: 1,
      },
      () => {
        refetch();
      }
    );
  };

  handlerChangeCaseStatus = (status, refetch) => {
    this.setState(
      {
        sla_max_level: status != 6 ? status : 0,
        level_status: status,
        sla_completed: status == 6 ? true : status == 0 ? null : false,
        page_number: 1,
      },
      () => {
        refetch();
      }
    );
  };

  handlerCaseStatus = (status, expire, complete) => {
    switch (status) {
      case 1:
        return <span style={{ color: '#FF2A2D', fontWeight: 'bold' }}>กำลังดำเนินการ</span>;
      case 2:
        if (moment(complete) > moment(expire)) {
          return (
            <span style={{ color: '#6DD634', fontWeight: 'bold' }}>
              แก้ไขแล้ว<span style={{ color: '#FF2A2D', fontWeight: 'bold' }}> (เกินเวลา)</span>{' '}
            </span>
          );
        }
        return <span style={{ color: '#6DD634', fontWeight: 'bold' }}>แก้ไขแล้ว</span>;
      default:
        return '';
    }
  };

  handleSingleUploadDocument(uploadFile, documentName, event) {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    // console.log(uploadFile)

    const timestamp = new Date().getTime();

    if (validity) {
      uploadFile({
        variables: {
          file: file,
          path: `upload/shipment/caseFile/${timestamp}/${documentName}`,
        },
      });
    }
  }

  getShipmentCase = async shipmentId => {
    try {
      const { data } = await client.query({
        query: shipmentSlasList,
        fetchPolicy: 'network-only',
        variables: {
          params: {
            shipment: shipmentId,
          },
          showData: 30,
        },
      });

      // console.log(data)

      data.shipmentSlas.totalDocument > 0 &&
        this.setState({
          shipmentSlas: data.shipmentSlas.shipmentSlas,
        });
    } catch (error) {
      console.log(error);
    }
  };

  getShipmentCaseDetail = async (caseId, admin) => {
    // console.log(caseId)

    try {
      const { data } = await client.query({
        query: shipmentSlaList,
        fetchPolicy: 'network-only',
        variables: {
          _id: caseId,
        },
      });

      // console.log(data)

      this.setState({
        caseIndex: data.shipmentSla.sla_case_number,
        caseStatus: data.shipmentSla.sla_status,
        newCase: false,
        editable: false,
        secondEditable: false,
        caseId: caseId,
        caseLevelDetail: data.shipmentSla.datamaster_sla_level
          ? caseLevelList.filter(item => item.level == data.shipmentSla.datamaster_sla_level.level)
          : '',
        expireDate: data.shipmentSla.sla_exp
          ? moment(data.shipmentSla.sla_exp).format('YYYY-MM-DD HH:mm:ss')
          : '',
        levelUnit: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.unit : '',
        levelTime: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.time : '',
        serviceTimeCase: data.shipmentSla.service_time ? data.shipmentSla.service_time : '',
        caseTitleChange: false,
        expireDateCheck: data.shipmentSla.sla_exp ? data.shipmentSla.sla_exp : null,
        completedDateChecK: data.shipmentSla.completed_date
          ? data.shipmentSla.completed_date
          : null,
        //card 1
        caseTitle: data.shipmentSla.title
          ? { value: data.shipmentSla.title, label: data.shipmentSla.title }
          : '',
        caseTitleId: data.shipmentSla.datamaster_sla_title
          ? data.shipmentSla.datamaster_sla_title._id
          : '',
        caseLevel: data.shipmentSla.level
          ? { value: data.shipmentSla.level, label: `Level ${data.shipmentSla.level}` }
          : '',
        caseLevelId: data.shipmentSla.datamaster_sla_level
          ? data.shipmentSla.datamaster_sla_level._id
          : '',

        caseDetail: data.shipmentSla.sla_description ? data.shipmentSla.sla_description : '',
        InformRecieveDateTime: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('YYYY-MM-DD HH:mm')
          : '',
        InformRecieveDateTimeShow: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('DD/MM/YYYY HH:mm')
          : '',
        attachFile: data.shipmentSla.sla_file ? data.shipmentSla.sla_file : [],
        caseOwner: data.shipmentSla.admin ? data.shipmentSla.admin.admin_username : admin,
        caseRecieveChanel: data.shipmentSla.sla_channel
          ? { value: data.shipmentSla.sla_channel, label: data.shipmentSla.sla_channel }
          : '',
        phoneInformer: data.shipmentSla.customer_phone ? data.shipmentSla.customer_phone : '',
        mailInformer: data.shipmentSla.customer_email ? data.shipmentSla.customer_email : '',

        // //card 2
        caseResolveDetail: data.shipmentSla.respond_description
          ? data.shipmentSla.respond_description
          : '',
        customerReplyDateTime: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('YYYY-MM-DD HH:mm')
          : '',
        customerReplyDateTimeShow: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('DD/MM/YYYY HH:mm')
          : '',
        attachSecondFile:
          data.shipmentSla.respond_file.length > 0 ? data.shipmentSla.respond_file : [],
        caseAnswer: data.shipmentSla.respond_admin
          ? data.shipmentSla.respond_admin.admin_username
          : admin,
        caseAnswerChanel: data.shipmentSla.respond_channel
          ? { value: data.shipmentSla.respond_channel, label: data.shipmentSla.respond_channel }
          : '',

        // //reset invalid
        caseTitleInvalid: false,
        caseLevelInvalid: false,
        caseDetailInvalid: false,
        caseRecieveChanelInvalid: false,
        caseOwnerInvalid: false,
        InformRecieveDateTimeInvalid: false,
        phoneInformerInvalid: false,
        mailInformerInvalid: false,

        caseResolveDetailInvalid: false,
        customerReplyDateTimeInvalid: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getShipmentCaseDetailCancelFirst = async (caseId, admin) => {
    // console.log(caseId)

    try {
      const { data } = await client.query({
        query: shipmentSlaList,
        fetchPolicy: 'network-only',
        variables: {
          _id: caseId,
        },
      });

      // console.log(data)

      this.setState({
        caseIndex: data.shipmentSla.sla_case_number,
        caseStatus: data.shipmentSla.sla_status,
        newCase: false,
        editable: false,
        caseId: caseId,
        caseLevelDetail: data.shipmentSla.datamaster_sla_level
          ? caseLevelList.filter(item => item.level == data.shipmentSla.datamaster_sla_level.level)
          : '',
        expireDate: data.shipmentSla.sla_exp
          ? moment(data.shipmentSla.sla_exp).format('YYYY-MM-DD HH:mm:ss')
          : '',
        levelUnit: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.unit : '',
        levelTime: data.shipmentSla.respond_time ? data.shipmentSla.respond_time.time : '',
        serviceTimeCase: data.shipmentSla.service_time ? data.shipmentSla.service_time : '',
        caseTitleChange: false,
        expireDateCheck: data.shipmentSla.sla_exp ? data.shipmentSla.sla_exp : null,
        completedDateChecK: data.shipmentSla.completed_date
          ? data.shipmentSla.completed_date
          : null,
        //card 1
        caseTitle: data.shipmentSla.title
          ? { value: data.shipmentSla.title, label: data.shipmentSla.title }
          : '',
        caseTitleId: data.shipmentSla.datamaster_sla_title
          ? data.shipmentSla.datamaster_sla_title._id
          : '',
        caseLevel: data.shipmentSla.level
          ? { value: data.shipmentSla.level, label: `Level ${data.shipmentSla.level}` }
          : '',
        caseLevelId: data.shipmentSla.datamaster_sla_level
          ? data.shipmentSla.datamaster_sla_level._id
          : '',

        caseDetail: data.shipmentSla.sla_description ? data.shipmentSla.sla_description : '',
        InformRecieveDateTime: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('YYYY-MM-DD HH:mm')
          : '',
        InformRecieveDateTimeShow: data.shipmentSla.sla_issue
          ? moment(data.shipmentSla.sla_issue).format('DD/MM/YYYY HH:mm')
          : '',
        attachFile: data.shipmentSla.sla_file ? data.shipmentSla.sla_file : [],
        caseOwner: data.shipmentSla.admin ? data.shipmentSla.admin.admin_username : admin,
        caseRecieveChanel: data.shipmentSla.sla_channel
          ? { value: data.shipmentSla.sla_channel, label: data.shipmentSla.sla_channel }
          : '',
        phoneInformer: data.shipmentSla.customer_phone ? data.shipmentSla.customer_phone : '',
        mailInformer: data.shipmentSla.customer_email ? data.shipmentSla.customer_email : '',

        // //reset invalid
        caseTitleInvalid: false,
        caseLevelInvalid: false,
        caseDetailInvalid: false,
        caseRecieveChanelInvalid: false,
        caseOwnerInvalid: false,
        InformRecieveDateTimeInvalid: false,
        phoneInformerInvalid: false,
        mailInformerInvalid: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  getShipmentCaseDetailCancelSecond = async (caseId, admin) => {
    // console.log(caseId)

    try {
      const { data } = await client.query({
        query: shipmentSlaList,
        fetchPolicy: 'network-only',
        variables: {
          _id: caseId,
        },
      });

      // console.log(data)

      this.setState({
        secondEditable: false,
        // card 1
        caseStatus: data.shipmentSla.sla_status,
        // //card 2
        caseResolveDetail: data.shipmentSla.respond_description
          ? data.shipmentSla.respond_description
          : '',
        customerReplyDateTime: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('YYYY-MM-DD HH:mm')
          : '',
        customerReplyDateTimeShow: data.shipmentSla.respond_date
          ? moment(data.shipmentSla.respond_date).format('DD/MM/YYYY HH:mm')
          : '',
        attachSecondFile:
          data.shipmentSla.respond_file.length > 0 ? data.shipmentSla.respond_file : [],
        caseAnswer: data.shipmentSla.respond_admin
          ? data.shipmentSla.respond_admin.admin_username
          : admin,
        caseAnswerChanel: data.shipmentSla.respond_channel
          ? { value: data.shipmentSla.respond_channel, label: data.shipmentSla.respond_channel }
          : '',

        // //reset invalid

        caseResolveDetailInvalid: false,
        customerReplyDateTimeInvalid: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderCaseTitleList(data) {
    caseTitlelist = data.slaTitles.map(item => {
      return {
        value: item.title,
        label: item.title,
        level: item.level,
        levelId: item.datamaster_sla_level,
        titleId: item._id,
      };
    });
  }

  renderCaseLevelList(data) {
    caseLevelList = data.slaLevels;
  }

  renderServiceTime = status => {
    switch (status) {
      case 1:
        return <span>ทุกวัน ( 7 วัน 24 ชั่วโมง )</span>;
      case 2:
        return <span>จันทร์ - ศุกร์ ( 09:00 - 18:00 น. )</span>;
      default:
        return '';
    }
  };

  checkFileTypeImage(file) {
    if (file) {
      return (
        file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf'
      );
    }
  }

  checkEmail = email => {
    const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (validateEmail.test(email)) {
      return true;
    }

    return false;
  };

  exceedCountdownDate = async (caseId, shipmentId) => {
    try {
      if (caseId !== null) {
        const { data } = await client.query({
          query: shipmentSlaList,
          fetchPolicy: 'network-only',
          variables: {
            _id: caseId,
          },
        });

        this.setState({
          caseStatus: data.shipmentSla.sla_status,
        });
      }

      if (shipmentId !== null) {
        const { data } = await client.query({
          query: shipmentSlasList,
          fetchPolicy: 'network-only',
          variables: {
            params: {
              shipment: shipmentId,
            },
            showData: 30,
          },
        });

        data.shipmentSlas.totalDocument > 0 &&
          this.setState({
            shipmentSlas: data.shipmentSlas.shipmentSlas,
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  refetchCaseLevelAndTitle = async () => {
    try {
      const { data: titles } = await client.query({
        query: datamasterSlaTitlesList,
        fetchPolicy: 'network-only',
        variables: {
          params: {
            status_delete: false,
          },
        },
      });

      this.renderCaseTitleList(titles.datamasterSlaTitles);

      const { data: levels } = await client.query({
        query: datamasterSlaLevelsList,
        fetchPolicy: 'network-only',
      });

      this.renderCaseLevelList(levels.datamasterSlaLevels);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    // if (this.props.driverUserList.loading == false && this.props.driverUserList.driverUserList != undefined) {
    //     driverItem = this.props.driverUserList.driverUserList.driver_user;
    //     this.renderDriver(this.props.driverUserList);
    // }
    // if (this.props.userTruckList.loading == false && this.props.userTruckList.userSettingTruckList != undefined) {
    //     truckItem = this.props.userTruckList.userSettingTruckList.user_setting_truck;
    //     this.renderTruck(this.props.userTruckList);
    // }

    if (
      this.props.accountInfoList.loading == false &&
      this.props.accountInfoList.accountInfoList != undefined
    ) {
      agentItem = this.props.accountInfoList.accountInfoList.account_info;
      this.renderAgent(this.props.accountInfoList);
    }

    if (
      this.props.datamasterSlaTitlesList.loading == false &&
      this.props.datamasterSlaTitlesList.datamasterSlaTitles != undefined
    ) {
      this.renderCaseTitleList(this.props.datamasterSlaTitlesList.datamasterSlaTitles);
    }

    if (
      this.props.datamasterSlaLevelsList.loading == false &&
      this.props.datamasterSlaLevelsList.datamasterSlaLevels != undefined
    ) {
      this.renderCaseLevelList(this.props.datamasterSlaLevelsList.datamasterSlaLevels);
    }

    let admin = '';
    let admin_id = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin = this.props.adminName.adminList.admin[0].admin_username;
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }
    return (
      <Container className="content">
        <Grid fluid>
          <Row>
            <Query
              query={shipmentAdminListQuery}
              variables={{
                params: {
                  stage: this.state.stage,
                  pick_up_date: this.state.pick_up_date,
                  shipment_number: this.state.shipment_number,
                  shipment_status: this.state.shipment_status,
                  shipper_info: {
                    account_info_email: this.state.account_info_email,
                  },
                  setting_truck_id: this.state.setting_truck_id,
                  setting_truck_type_id: this.state.setting_truck_type_id,
                  transporter: this.state.transporter,
                  paid_status: this.state.paid_status,
                  completed_date: this.state.completed_date,
                  create_date: this.state.create_date,
                  last_update: this.state.last_update,
                  sla_max_level: this.state.sla_max_level,
                  sla_completed: this.state.sla_completed,
                },
                sort: {
                  order_by: this.state.order_by,
                  order_type: this.state.order_type,
                },
                page: this.state.page_number,
                showData: this.state.showData,
              }}
            >
              {({ loading, error, data, refetch, variables }) => {
                // if(loading) return null;
                // if(error) return null;
                // console.log(variables);
                var itemLength = 'loading';
                const itemIndicatorSize = (this.state.page_number - 1) * 30;
                if (data.shipmentAdmins) {
                  if (data.shipmentAdmins.shipments.length > 0) {
                    itemLength = data.shipmentAdmins.shipments.length;
                  }
                }
                return (
                  <Col md={12}>
                    <CardTable
                      title="ตารางรายการขนส่ง"
                      category="ข้อมูลงานขนส่งจากระบบออนไลน์ Marketplace"
                      rightButton={
                        <RightButtonContainer style={{ float: 'right', marginLeft: '15px' }}>
                          {
                            // <Button  bsStyle="warning" onClick={() => this.searchPickupDate("yesterday",refetch)}><i className="fal fa-calendar-star"></i> งานวิ่งเมื่อวาน </Button>
                            // <Button style={{marginLeft:"15px"}} bsStyle="success" onClick={() => this.searchPickupDate("today",refetch)}><i className="fal fa-calendar-star"></i> งานวิ่งวันนี้ </Button>
                            // <Button style={{marginLeft:"15px"}}  bsStyle="info" onClick={() => this.searchPickupDate("tomorrow",refetch)}><i className="fal fa-calendar-day"></i> งานวิ่งพรุ่งนี้ </Button>
                            // <Button style={{marginLeft:"15px"}} onClick={() => this.openReportShipment("booking",admin_id)}> <i className="fal fa-clipboard-list"></i> รายงานงานขนส่ง </Button>
                          }
                          <Mutation
                            mutation={importShipmentMutation}
                            update={() => {
                              this.addNotificationCubic(
                                'success',
                                'Import ไฟล์สำเร็จ รอระบบสร้างงาน'
                              );
                              refetch();
                            }}
                            onError={error => {
                              this.addNotificationCubic('error', error.graphQLErrors[0].message);
                            }}
                          >
                            {importShipment => (
                              <UploadFile
                                completed={file => {
                                  this._inputImportFile.current.value = null;
                                  console.log('UploadFile', file);
                                  importShipment({
                                    variables: {
                                      path: file.path,
                                    },
                                  });
                                }}
                              >
                                {selectFile => {
                                  return (
                                    <React.Fragment>
                                      <input
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        onChange={event => {
                                          selectFile(
                                            event,
                                            `upload/cubic/document_${new Date().getTime()}`
                                          );
                                        }}
                                        type="file"
                                        id="file"
                                        ref={this._inputImportFile}
                                        style={{ display: 'none' }}
                                      />
                                      <SplitButton
                                        style={{
                                          maxHeight: '32px',
                                        }}
                                        id={`dropdown-split-variants-cubic`}
                                        onClick={() => this._inputImportFile.current.click()}
                                        title={
                                          <span>
                                            <i
                                              style={{ marginRight: '10px' }}
                                              className="fas fa-upload"
                                            ></i>
                                            Import
                                          </span>
                                        }
                                      >
                                        <NavItemDropDownList
                                          onClick={() =>
                                            window.open(
                                              'https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/template_FTL_offline.xlsx'
                                            )
                                          }
                                        >
                                          ดาวน์โหลดตัวอย่าง
                                        </NavItemDropDownList>
                                      </SplitButton>
                                    </React.Fragment>
                                  );
                                }}
                              </UploadFile>
                            )}
                          </Mutation>

                          {this.state.shipment_status !== 4 ? (
                            <DropdownButton
                              style={{
                                minWidth: '120px',
                                height: '33px',
                                textAlign: 'left',
                                marginRight: '10px',
                              }}
                              title={
                                <span style={{ marginRight: '20px' }}>
                                  {this.state.dropdownPickup_date}
                                </span>
                              }
                              id="dropdown"
                              onSelect={(eventKey, key) => {
                                // console.log(eventKey);
                                this.setState({ dropdownPickup_date: eventKey.name });
                                eventKey.value === 0
                                  ? this.setState({ pick_up_date: '', show_pick_up_date: '' })
                                  : this.searchPickupDate(eventKey.value, refetch);
                              }}
                            >
                              <MenuItem eventKey={{ value: 0, name: 'งานทั้งหมด' }}>
                                งานทั้งหมด
                              </MenuItem>
                              <MenuItem eventKey={{ value: 'today', name: 'งานวิ่งวันนี้' }}>
                                งานวิ่งวันนี้
                              </MenuItem>
                              <MenuItem eventKey={{ value: 'tomorrow', name: 'งานวิ่งพรุ่ง' }}>
                                งานวิ่งพรุ่ง
                              </MenuItem>
                              <MenuItem eventKey={{ value: 'yesterday', name: 'งานวิ่งเมื่อวาน' }}>
                                งานวิ่งเมื่อวาน
                              </MenuItem>
                            </DropdownButton>
                          ) : (
                            <DropdownButton
                              style={{
                                minWidth: '120px',
                                height: '33px',
                                textAlign: 'left',
                                marginRight: '10px',
                              }}
                              title={this.state.dropdownPickup_date}
                              id="dropdown"
                              onSelect={(eventKey, key) => {
                                this.setState({ dropdownPickup_date: eventKey.name });
                                eventKey.value === 0
                                  ? this.setState({
                                      pick_up_date: '',
                                      show_pick_up_date: '',
                                      completed_date: '',
                                      show_completed_date: '',
                                    })
                                  : eventKey.value == 'completedToday' ||
                                    eventKey.value == 'completedYesterday'
                                  ? this.searchCompletedDate(eventKey.value, refetch)
                                  : this.searchPickupDate(eventKey.value, refetch);
                              }}
                            >
                              <MenuItem eventKey={{ value: 0, name: 'งานทั้งหมด' }}>
                                งานทั้งหมด
                              </MenuItem>
                              <MenuItem eventKey={{ value: 'today', name: 'งานวิ่งวันนี้' }}>
                                งานวิ่งวันนี้
                              </MenuItem>
                              <MenuItem eventKey={{ value: 'tomorrow', name: 'งานวิ่งพรุ่ง' }}>
                                งานวิ่งพรุ่ง
                              </MenuItem>
                              <MenuItem eventKey={{ value: 'yesterday', name: 'งานวิ่งเมื่อวาน' }}>
                                งานวิ่งเมื่อวาน
                              </MenuItem>
                              <MenuItem
                                eventKey={{ value: 'completedToday', name: 'งานเสร็จวันนี้' }}
                              >
                                งานเสร็จวันนี้
                              </MenuItem>
                              <MenuItem
                                eventKey={{ value: 'completedYesterday', name: 'งานเสร็จเมื่อวาน' }}
                              >
                                งานเสร็จเมื่อวาน
                              </MenuItem>
                            </DropdownButton>
                          )}
                          <Button onClick={this.refetchQueries}>
                            <i
                              style={{ animationDuration: '.5s' }}
                              class={this.state.refetchAnimation}
                            ></i>
                          </Button>
                        </RightButtonContainer>
                      }
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <div>
                          <TabHeaderContainer>
                            <div className="sub-header-left">
                              <div className="menu-wrapper">
                                <ul>
                                  {this.giztixMenu() && (
                                    <li className={this.state.stage == '' && 'active'}>
                                      <a
                                        onClick={() =>
                                          this.setState({
                                            stage: '',
                                            shipment_status: 0,
                                            last_update: '',
                                            page_number: 1,
                                          })
                                        }
                                      >
                                        ทั้งหมด
                                      </a>
                                    </li>
                                  )}
                                  <li
                                    className={
                                      (this.state.stage == 'pending' ||
                                        this.state.shipment_status == 2) &&
                                      'active'
                                    }
                                  >
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          stage: 'pending',
                                          shipment_status: 0,
                                          last_update: '',
                                          page_number: 1,
                                        })
                                      }
                                    >
                                      รอขนส่งรับงาน
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      (this.state.stage == 'inprogress' ||
                                        this.state.shipment_status == 3 ||
                                        this.state.shipment_status == 8 ||
                                        this.state.shipment_status == 10) &&
                                      'active'
                                    }
                                  >
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          stage: 'inprogress',
                                          shipment_status: 0,
                                          last_update: '',
                                          page_number: 1,
                                        })
                                      }
                                    >
                                      กำลังดำเนินการ
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      (this.state.shipment_status == 4 ||
                                        this.state.stage == 'completed') &&
                                      'active'
                                    }
                                  >
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          stage: 'completed',
                                          shipment_status: 0,
                                          last_update: '',
                                          page_number: 1,
                                        })
                                      }
                                    >
                                      เสร็จสิ้น
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      (this.state.stage == 'cancel' ||
                                        this.state.shipment_status == 5) &&
                                      'active'
                                    }
                                  >
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          stage: 'cancel',
                                          shipment_status: 0,
                                          last_update: '',
                                          page_number: 1,
                                        })
                                      }
                                    >
                                      ถูกยกเลิก
                                    </a>
                                  </li>
                                  <li
                                    className={
                                      (this.state.stage == 'problem' ||
                                        this.state.shipment_status == 6) &&
                                      'active'
                                    }
                                  >
                                    <a
                                      onClick={() =>
                                        this.setState({
                                          stage: 'problem',
                                          shipment_status: 0,
                                          last_update: '',
                                          page_number: 1,
                                        })
                                      }
                                    >
                                      งานเกิดปัญหา
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </TabHeaderContainer>

                          <PageWrapper style={{ textAlign: 'right' }}>
                            <div className="text">
                              {this.state.page_number === 1
                                ? `1 - ${itemLength}`
                                : `${itemIndicatorSize + 1} - ${itemLength +
                                    itemIndicatorSize}`}{' '}
                              หน้า :
                            </div>
                            <FormControl
                              type="number"
                              min={1}
                              value={this.state.page_number}
                              onChange={event =>
                                setInterval(
                                  this.nextPage(
                                    Number(event.target.value) - 1,
                                    loading || error ? '0' : data.shipmentAdmins.totalPage,
                                    refetch
                                  ),
                                  1000
                                )
                              }
                              name="page_number"
                            />
                            <div className="text">
                              {' '}
                              {loading || error ? '' : `จาก ${data.shipmentAdmins.totalPage}`}
                            </div>
                          </PageWrapper>

                          <div className="table-vertical">
                            <NotificationSystem
                              ref={this.notificationSystem1}
                              style={styleNotification}
                            />
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th style={{ width: '180px', minWidth: '180px' }}>เครื่องมือ</th>
                                  <th
                                    style={{ width: '150px', minWidth: '150px' }}
                                    className={this.renderSorting('pick_up_date')}
                                    onClick={() => this.sort('pick_up_date', refetch)}
                                  >
                                    วันรับสินค้า
                                  </th>
                                  <th
                                    style={{ width: '140px', minWidth: '140px' }}
                                    className={this.renderSorting('shipment_number')}
                                    onClick={() => this.sort('shipment_number', refetch)}
                                  >
                                    หมายเลขงานขนส่ง
                                  </th>
                                  <th
                                    style={{ width: '150px', minWidth: '150px' }}
                                    className={this.renderSorting('shipment_status')}
                                    onClick={() => this.sort('shipment_status', refetch)}
                                  >
                                    สถานะ
                                  </th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>ปัญหา</th>
                                  <th style={{ width: '180px', minWidth: '180px' }}>
                                    ผู้ส่งสินค้า
                                  </th>
                                  <th style={{ width: '100px', minWidth: '100px' }}>ประเภทรถ</th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    พนักงานขับรถ
                                  </th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    จุดรับสินค้า/ รับตู้
                                  </th>
                                  <th style={{ width: '30px', minWidth: '30px' }}></th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>
                                    จุดส่ง/ ขึ้น/ ลงสินค้า
                                  </th>
                                  <th style={{ width: '150px', minWidth: '150px' }}>ชื่อนายหน้า</th>
                                  <th style={{ width: '120px', minWidth: '120px' }}>สถานะส่ง CT</th>
                                  <th
                                    style={{ width: '120px', minWidth: '120px' }}
                                    className={this.renderSorting('paid_status')}
                                    onClick={() => this.sort('paid_status', refetch)}
                                  >
                                    สถานะชำระเงิน
                                  </th>
                                  <th
                                    style={{ width: '150px', minWidth: '150px' }}
                                    className={this.renderSorting('create_date')}
                                    onClick={() => this.sort('create_date', refetch)}
                                  >
                                    วันที่จอง
                                  </th>
                                  <th
                                    style={{ width: '150px', minWidth: '150px' }}
                                    className={this.renderSorting('completed_date')}
                                    onClick={() => this.sort('completed_date', refetch)}
                                  >
                                    วันที่เสร็จ
                                  </th>
                                </tr>
                                <tr className="tableFilter">
                                  <th></th>
                                  {/*วันรับสินค้า */}
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handlePickupDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() =>
                                        this.setState(
                                          {
                                            pick_up_date: '',
                                            show_pick_up_date: '',
                                            dropdownPickup_date: 'งานทั้งหมด',
                                          },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.show_pick_up_date}
                                        placeholder="ค้นหาสร้าง"
                                        name="pick_up_date"
                                      />
                                    </DateRangePicker>
                                  </th>
                                  {/*หมายเลขงานขนส่ง */}
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.shipment_number}
                                      placeholder="ค้นหา"
                                      onChange={event =>
                                        this.setState(
                                          { shipment_number: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="shipment_number"
                                    />
                                  </th>
                                  {/*สถานะ */}
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.shipment_status}
                                      value={this.state.shipment_status}
                                      onChange={event =>
                                        this.handlerChangeShipmentStatus(
                                          event.target.value,
                                          refetch
                                        )
                                      }
                                      name="shipment_status"
                                    >
                                      {this.state.stage == '' ? (
                                        <>
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={2}>รอขนส่งรับงาน</option>
                                          <option value={10}>รอเริ่มงาน</option>
                                          <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                          <option value={3}>กำลังดำเนินการขนส่ง</option>
                                          <option value={4}>เสร็จสิ้นแล้ว</option>
                                          <option value={5}>ถูกยกเลิก</option>
                                          <option value={6}>งานเกิดปัญหา</option>
                                        </>
                                      ) : this.state.stage == 'pending' ||
                                        this.state.shipment_status == 2 ? (
                                        <>
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={2}>รอขนส่งรับงาน</option>
                                        </>
                                      ) : this.state.stage == 'inprogress' ||
                                        this.state.shipment_status == 3 ||
                                        this.state.shipment_status == 8 ||
                                        this.state.shipment_status == 10 ? (
                                        <>
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={10}>รอเริ่มงาน</option>
                                          <option value={8}>นัดหมายลูกค้าแล้ว</option>
                                          <option value={3}>กำลังดำเนินการขนส่ง</option>
                                        </>
                                      ) : this.state.stage == 'completed' ||
                                        this.state.shipment_status == 4 ? (
                                        <>
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={4}>เสร็จสิ้นแล้ว</option>
                                        </>
                                      ) : this.state.stage == 'cancel' ||
                                        this.state.shipment_status == 5 ? (
                                        <>
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={5}>ถูกยกเลิก</option>
                                        </>
                                      ) : this.state.stage == 'problem' ||
                                        this.state.shipment_status == 6 ? (
                                        <>
                                          <option value={0}>ทั้งหมด</option>
                                          <option value={6}>ถูกยกเลิก</option>
                                        </>
                                      ) : null}
                                    </FormControl>
                                  </th>
                                  {/*ปัญหา */}
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.level_status}
                                      value={this.state.level_status}
                                      onChange={event =>
                                        this.handlerChangeCaseStatus(event.target.value, refetch)
                                      }
                                      name="level_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={1}>Level 1</option>
                                      <option value={2}>Level 2</option>
                                      <option value={3}>Level 3</option>
                                      <option value={4}>Level 4</option>
                                      <option value={5}>Level 5</option>
                                      <option value={6}>แก้ไขปัญหาแล้ว</option>
                                    </FormControl>
                                  </th>
                                  {/*ผู้ส่งสินค้า */}
                                  <th>
                                    <FormControl
                                      type="text"
                                      value={this.state.account_info_email}
                                      placeholder="ค้นหาอีเมลผู้ส่งสินค้า"
                                      onChange={event =>
                                        this.setState(
                                          {
                                            account_info_email: event.target.value,
                                            page_number: 1,
                                          },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="account_info_email"
                                    />
                                  </th>
                                  {/*ประเภทรถ */}
                                  <th>
                                    <Query query={settingTruckListQuery} fetchPolicy="network-only">
                                      {({ loading, error, data, refetch }) => {
                                        return (
                                          <FormControl
                                            componentClass="select"
                                            placeholder="select"
                                            defaultValue={this.state.setting_truck_type_id}
                                            onChange={event =>
                                              this.setState(
                                                {
                                                  setting_truck_type_id:
                                                    event.target.value == 0
                                                      ? null
                                                      : event.target.value,
                                                  page_number: 1,
                                                },
                                                () => {
                                                  refetch();
                                                }
                                              )
                                            }
                                            name="setting_truck_type_id"
                                          >
                                            <option value={0}>ทั้งหมด</option>
                                            {!loading &&
                                              !error &&
                                              data.settingTruckList.map((truck, index) => (
                                                <optgroup
                                                  label={`รถ ${truck.setting_truck_name.th}`}
                                                >
                                                  {truck.setting_truck_type
                                                    .filter(item => item.truck_type_enable)
                                                    .map((item, key, arr) => (
                                                      <option value={item._id}>
                                                        {`${truck.setting_truck_name.th} - ${item.truck_type_name.th}` ||
                                                          undefined}
                                                      </option>
                                                    ))}
                                                </optgroup>
                                              ))}
                                          </FormControl>
                                        );
                                      }}
                                    </Query>
                                  </th>
                                  {/*พนักงานขับรถ */}
                                  <th></th>
                                  {/*จุดรับสินค้า/รับตู้ */}
                                  <th></th>
                                  {/* -> */}
                                  <th></th>
                                  {/* จุดส่ง/ขึ้น/ลงสินค้า */}
                                  <th></th>
                                  {/* ชื่อนายหน้า */}
                                  <th>
                                    <AutosuggestContainerAgent>
                                      <Autosuggest
                                        suggestions={this.state.transporterSuggestions}
                                        onSuggestionsFetchRequested={
                                          this.onSuggestionsFetchRequestedTransporter
                                        }
                                        onSuggestionsClearRequested={
                                          this.onSuggestionsClearRequestedTransporter
                                        }
                                        getSuggestionValue={this.getSuggestionValueTransporter}
                                        renderSuggestion={this.renderSuggestionTransporter}
                                        focusInputOnSuggestionClick={false}
                                        inputProps={{
                                          placeholder: 'ค้นหา',
                                          value: this.state.transporterName,
                                          onChange: event =>
                                            this.setState({
                                              transporterName: event.target.value,
                                              transporter: null,
                                            }),
                                          type: 'text',
                                          className: 'form-control',
                                        }}
                                        onSuggestionSelected={(event, { suggestion }) => {
                                          this.setState({
                                            transporter: suggestion.id,
                                            transporterName: suggestion.name,
                                            page_number: 1,
                                          });
                                        }}
                                        shouldRenderSuggestions={
                                          this.shouldRenderSuggestionsTransporter
                                        }
                                      />
                                    </AutosuggestContainerAgent>
                                  </th>
                                  {/* สถานะตั้งเบิก */}
                                  <th></th>
                                  {/* สถานะชำระเงิน */}
                                  <th>
                                    {
                                      //1=complete/ชำระแล้ว,2null=pending/รองานเสร็จ,3=wait summary invoice/รอสร้างใบแจ้งหนี้,4=wait billing/รอวางบิล,5=wait pay/รอชำระ,6=cancel/ถูกยกเลิก
                                    }
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.paid_status}
                                      onChange={event =>
                                        this.setState(
                                          { paid_status: event.target.value, page_number: 1 },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      name="paid_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={1}>ชำระแล้ว</option>
                                      <option value={2}>รองานเสร็จ</option>
                                      <option value={3}>รอสร้างใบแจ้งหนี้</option>
                                      <option value={4}>รอวางบิล</option>
                                      <option value={5}>รอชำระ</option>
                                      <option value={6}>ถูกยกเลิก</option>
                                    </FormControl>
                                  </th>
                                  {/* วันที่จอง */}
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() =>
                                        this.setState(
                                          { create_date: '', show_create_date: '' },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.show_create_date}
                                        placeholder="ค้นหาสร้าง"
                                        name="create_date"
                                      />
                                    </DateRangePicker>
                                  </th>
                                  {/* วันที่เสร็จ */}
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleCompletedDateRangePicker(event, picker, refetch)
                                      }
                                      onCancel={() =>
                                        this.setState(
                                          {
                                            completed_date: '',
                                            show_completed_date: '',
                                            dropdownPickup_date: 'งานทั้งหมด',
                                          },
                                          () => {
                                            refetch();
                                          }
                                        )
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.show_completed_date}
                                        placeholder="ค้นหาสร้าง"
                                        name="completed_date"
                                      />
                                    </DateRangePicker>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading || error ? (
                                  <tr>
                                    <td colSpan="20" className="textCenter">
                                      {loading ? 'Loading...' : error.message}
                                    </td>
                                  </tr>
                                ) : data.shipmentAdmins.shipments.length === 0 ? (
                                  <tr>
                                    <td colSpan="20" className="textCenter">
                                      ไม่พบข้อมูล
                                    </td>
                                  </tr>
                                ) : (
                                  data.shipmentAdmins.shipments.map((prop, key) => (
                                    <tr key={key} data-id={prop._id}>
                                      <td>
                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                          <a
                                            href={this.getShipmentUrlFromPrefix(
                                              prop.shipment_prefix,
                                              prop._id
                                            )}
                                            target="_blank"
                                            style={{ float: 'left' }}
                                            className="btn btn-default"
                                          >
                                            <i
                                              style={{ marginTop: '3px' }}
                                              className="fas fa-eye"
                                            ></i>
                                          </a>
                                        </OverlayTrigger>

                                        {admin == 'ann' ||
                                        admin == 'palmolive' ||
                                        (prop.shipment_status != 5 &&
                                          prop.shipment_status != 4 &&
                                          prop.shipment_status != 7 &&
                                          prop.shipment_number) ? (
                                          prop.shipment_type == 1 ? (
                                            false ? (
                                              <NavDropdownContainer
                                                title={
                                                  <Button>
                                                    เครื่องมือ <i className="fal fa-angle-down"></i>
                                                  </Button>
                                                }
                                              >
                                                <NavItemDropDownList
                                                  onClick={() => this.handleShowTransporter(prop)}
                                                >
                                                  <i className="fas fa-user-tie"></i> เปลี่ยนนายหน้า
                                                </NavItemDropDownList>
                                                <NavItemDropDownList
                                                  onClick={() => this.handleShow(prop)}
                                                >
                                                  <i className="fas fa-truck"></i>{' '}
                                                  {prop.driver_user_id
                                                    ? 'เปลี่ยนคนขับ'
                                                    : 'เพิ่มคนขับ'}
                                                </NavItemDropDownList>
                                                {(prop.driver_user_id && prop.driver_user_id._id) ||
                                                prop.transporter ? (
                                                  <NavItemDropDownList
                                                    onClick={() => this.handleResetShipment(prop)}
                                                  >
                                                    <i className="fas fa-history"></i>{' '}
                                                    คืนค่าเริ่มต้น
                                                  </NavItemDropDownList>
                                                ) : (
                                                  <NavItemDropDownList
                                                    onClick={() => this.handleShareShipment(prop)}
                                                  >
                                                    <i className="fas fa-users-class"></i> กระจายงาน
                                                  </NavItemDropDownList>
                                                )}
                                                {admin == 'ann' || admin == 'palmolive' ? (
                                                  <NavItemDropDownList
                                                    onClick={() =>
                                                      this.handleShowcancel(
                                                        prop.shipment_number,
                                                        prop._id
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-times"></i> ยกเลิก
                                                  </NavItemDropDownList>
                                                ) : null}
                                              </NavDropdownContainer>
                                            ) : (
                                              <SplitButton
                                                style={{ marginLeft: '5px' }}
                                                key={prop._id}
                                                id={`dropdown-split-variants-${prop._id}`}
                                                onClick={() => this.handlerShowCase(prop, admin)}
                                                title={
                                                  <span>
                                                    <i
                                                      style={{ marginRight: '10px' }}
                                                      className="fas fa-exclamation-triangle"
                                                    ></i>
                                                    แจ้งปัญหา
                                                  </span>
                                                }
                                              >
                                                <NavItemDropDownList
                                                  onClick={() => this.handleShowTransporter(prop)}
                                                >
                                                  <i className="fas fa-user-tie"></i> เปลี่ยนนายหน้า
                                                </NavItemDropDownList>

                                                <NavItemDropDownList
                                                  onClick={() => this.handleShow(prop)}
                                                >
                                                  <i className="fas fa-truck"></i>{' '}
                                                  {prop.driver_user_id
                                                    ? 'เปลี่ยนคนขับ'
                                                    : 'เพิ่มคนขับ'}
                                                </NavItemDropDownList>
                                                {(prop.driver_user_id && prop.driver_user_id._id) ||
                                                prop.transporter ? (
                                                  <NavItemDropDownList
                                                    onClick={() => this.handleResetShipment(prop)}
                                                  >
                                                    <i className="fas fa-history"></i>{' '}
                                                    คืนค่าเริ่มต้น
                                                  </NavItemDropDownList>
                                                ) : (
                                                  <NavItemDropDownList
                                                    onClick={() => this.handleShareShipment(prop)}
                                                  >
                                                    <i className="fas fa-users-class"></i> กระจายงาน
                                                  </NavItemDropDownList>
                                                )}

                                                {admin == 'ann' || admin == 'palmolive' ? (
                                                  <NavItemDropDownList
                                                    onClick={() =>
                                                      this.handleShowcancel(
                                                        prop.shipment_number,
                                                        prop._id
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-times"></i> ยกเลิก
                                                  </NavItemDropDownList>
                                                ) : null}
                                              </SplitButton>
                                            )
                                          ) : (
                                            <a
                                              href={this.getShipmentUrlFromPrefix(
                                                prop.shipment_prefix,
                                                prop._id
                                              )}
                                              target="_blank"
                                              style={{ float: 'left', marginLeft: '5px' }}
                                              className="btn btn-default"
                                            >
                                              <i className="fas fa-truck-container"></i> อัพเดตงาน
                                            </a>
                                          )
                                        ) : (
                                          <Button
                                            style={{ marginLeft: '5px' }}
                                            className="btn btn-default"
                                            key={prop._id}
                                            id={`dropdown-split-variants-${prop._id}`}
                                            onClick={() => this.handlerShowCase(prop, admin)}
                                          >
                                            <span>
                                              <i
                                                style={{ marginRight: '10px' }}
                                                className="fas fa-exclamation-triangle"
                                              ></i>
                                              แจ้งปัญหา
                                            </span>
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm">
                                          {prop.pick_up_date}
                                        </Moment>
                                      </td>
                                      <td>
                                        {prop.shipment_number
                                          ? this.getShipmentNumberFormat(
                                              prop.shipment_number,
                                              prop.shipment_prefix
                                            )
                                          : 'แบบร่าง'}{' '}
                                        {prop.flash_deals_id && (
                                          <OverlayTrigger placement="top" overlay={tooltipFlash}>
                                            <span className="label label-danger">
                                              <i className="fas fa-bolt"></i>
                                            </span>
                                          </OverlayTrigger>
                                        )}{' '}
                                        {prop.re_shipment && (
                                          <OverlayTrigger placement="top" overlay={tooltipREbook}>
                                            <Label bsStyle="info">
                                              <i className="fas fa-clone"></i>
                                            </Label>
                                          </OverlayTrigger>
                                        )}
                                      </td>
                                      <td>
                                        {this.renderStatus(prop.shipment_status)}{' '}
                                        {prop.shipment_status == 5 && prop.cancel_comment ? (
                                          <div style={{ marginTop: 5 }}>
                                            <small>เหตุผล : {prop.cancel_comment}</small>
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td>
                                        {this.renderProblemStatus(
                                          prop.sla_completed,
                                          prop.sla_max_level,
                                          prop.sla_total
                                        )}
                                      </td>
                                      <td>
                                        {' '}
                                        {prop.shipper &&
                                          this.renderCustomerType(
                                            prop.shipper.account_info_customer_type,
                                            prop.shipper._id
                                          )}
                                        <b>{prop.shipper.account_info_business_name}</b> <br />
                                        {prop.shipper_info.account_info_email}
                                      </td>
                                      <td>{`${prop.setting_truck_name.th} ${prop.truck_type_name.th}`}</td>
                                      <td>
                                        {prop.driver_user_id.driver_name ? (
                                          <span>
                                            {prop.driver_user_id.driver_name} , <br />{' '}
                                            {prop.driver_user_id.driver_phone_code}{' '}
                                            {prop.driver_user_id.driver_phone_number}
                                          </span>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        <div>
                                          <b>
                                            {prop.shipment_address[0].subdictrict},
                                            {prop.shipment_address[0].province}
                                          </b>{' '}
                                          <br />
                                          {`${prop.shipment_address[0].contact_name}, ${prop.shipment_address[0].contact_tel}`}
                                        </div>
                                      </td>
                                      <td>
                                        {prop.round_trip == 1 ? (
                                          <i className="fal fa-exchange"></i>
                                        ) : (
                                          <i className="fal fa-arrow-right"></i>
                                        )}
                                      </td>
                                      <td>
                                        {prop.round_trip != 1 &&
                                        prop.shipment_address.length > 2 &&
                                        prop.shipment_type == 1 ? (
                                          <OverlayTrigger
                                            trigger={['click']}
                                            key="bottom"
                                            placement="bottom"
                                            rootClose={true}
                                            overlay={
                                              <Popover
                                                id={`popover-positioned-${prop._id}`}
                                                title={`จุดส่งสินค้าทั้งหมด ${prop.shipment_address
                                                  .length - 1} จุด `}
                                              >
                                                <div
                                                  style={{
                                                    overflow: 'auto',
                                                    maxHeight: '10vh',
                                                    width: '100%',
                                                  }}
                                                >
                                                  {prop.shipment_address &&
                                                    prop.shipment_address.map(
                                                      (address, key) =>
                                                        key != 0 && (
                                                          <p>
                                                            <strong>{`จุดที่ ${key + 1} `}</strong>
                                                            {`${address.subdictrict ||
                                                              ''} ${address.province || ''}`}{' '}
                                                            <br />{' '}
                                                            {`${address.contact_name}, ${address.contact_tel}`}{' '}
                                                          </p>
                                                        )
                                                    )}
                                                </div>
                                              </Popover>
                                            }
                                          >
                                            <b style={{ cursor: 'pointer' }}>
                                              {`จุดส่งสินค้าทั้งหมด ${prop.shipment_address.length -
                                                1} จุด`}
                                            </b>
                                          </OverlayTrigger>
                                        ) : (
                                          <div>
                                            {' '}
                                            <b>
                                              {prop.shipment_address[1].subdictrict},
                                              {prop.shipment_address[1].province}
                                            </b>{' '}
                                            <br />{' '}
                                            {`${prop.shipment_address[1].contact_name}, ${prop.shipment_address[1].contact_tel}`}{' '}
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {prop.transporter
                                          ? prop.transporter.account_info_business_name
                                          : '-'}
                                      </td>
                                      <td>{this.renderStatusPayment(prop.billing_costing)}</td>
                                      <td>{this.renderStatusPaid(prop.paid_status)}</td>
                                      <td>
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {prop.create_date}
                                        </Moment>
                                      </td>
                                      <td>
                                        {prop.completed_date !== null ? (
                                          <Moment format="DD/MM/YYYY HH:mm:ss">
                                            {prop.completed_date}
                                          </Moment>
                                        ) : (
                                          ' - '
                                        )}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </Table>
                          </div>
                          <div style={{ textAlign: 'right' }}>
                            {loading || error ? null : (
                              <ReactPaginate
                                pageCount={data.shipmentAdmins.totalPage}
                                forcePage={this.state.page_number - 1}
                                containerClassName="pagination"
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={({ selected }) => {
                                  this.nextPage(selected, data.shipmentAdmins.totalPage, refetch);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      }
                    />

                    <Mutation
                      mutation={clearShipmentDriverAcceptMutation}
                      variables={{
                        shipment_id: this.state.shipmentID,
                      }}
                      update={() => {
                        this.setState({ show_reset_shipment: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {clearShipmentDriver => (
                        <Modal
                          show={this.state.show_reset_shipment}
                          onHide={() => this.setState({ show_reset_shipment: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>คืนค่าเริ่มต้นงานขนส่ง </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            คุณต้องการคืนค่าเริ่มต้น งานขนส่ง {this.state.shipmentNumber} ใช่หรือไม่
                            ? <br /> กด <b>"ตกลง"</b> เพื่อคืนค่าเริ่มต้นงานนี้
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              style={{ float: 'left' }}
                              onClick={() => this.setState({ show_reset_shipment: false })}
                            >
                              กลับ
                            </Button>
                            <Button bsStyle="primary" onClick={() => clearShipmentDriver()}>
                              ตกลง
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={sendShipmentToMamaMutation}
                      variables={{
                        shipment_id: this.state.shipmentID,
                      }}
                      update={() => {
                        this.setState({ show_share_shipment: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {sendShipmentToMama => (
                        <Modal
                          show={this.state.show_share_shipment}
                          onHide={() => this.setState({ show_share_shipment: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>กระจายงานขนส่ง</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            คุณต้องการกระจายงาน งานขนส่ง {this.state.shipmentNumber} ใช่หรือไม่ ?{' '}
                            <br /> กด <b>"ตกลง"</b> เพื่อส่งกระจายงานอีกครั้ง
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              style={{ float: 'left' }}
                              onClick={() => this.setState({ show_share_shipment: false })}
                            >
                              กลับ
                            </Button>
                            <Button bsStyle="primary" onClick={() => sendShipmentToMama()}>
                              ตกลง
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={assignAdminShipment}
                      variables={{
                        shipmentID: this.state.shipmentID,
                        admin_name: admin,
                      }}
                      update={() => {
                        this.setState({ show_assign: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {assignAdminShipment => (
                        <Modal
                          show={this.state.show_assign}
                          onHide={() => this.setState({ show_assign: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>รับดูแลงาน {this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>กด "ตกลง" เพื่อรับดูแลงานนี้</Modal.Body>
                          <Modal.Footer>
                            <Button
                              style={{ float: 'left' }}
                              onClick={() => this.setState({ show_assign: false })}
                            >
                              กลับ
                            </Button>
                            <Button bsStyle="primary" onClick={() => assignAdminShipment()}>
                              ตกลง
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={cancelShipment}
                      variables={{
                        shipmentID: this.state.shipmentID,
                        cancel_comment: this.state.rejectRemark,
                      }}
                      update={() => {
                        this.setState({ show_cancel: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {cancelShipment => (
                        <Modal
                          show={this.state.show_cancel}
                          onHide={() => this.setState({ show_cancel: false })}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>ยกเลิกงานขนส่ง GTX{this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Row style={{ marginBottom: '10px' }}>
                              <Col md={12}>
                                <b>เหตุผลที่ยกเลิกงาน</b>
                              </Col>
                            </Row>

                            <Select
                              name={`driver_select`}
                              value={this.state.rejectSelected}
                              onChange={event => this.handleChangeReject(event)}
                              options={rejectList}
                              placeholder="เลือกเหตุผลที่ยกเลิกงาน"
                            />

                            <Row style={{ marginTop: '10px' }}>
                              <Col md={12}>คุณต้องการยกเลิกงานขนส่งนี้ให้กด "ตกลง"</Col>
                            </Row>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              style={{ float: 'left' }}
                              onClick={() => this.setState({ show_cancel: false })}
                            >
                              กลับ
                            </Button>
                            <Button
                              bsStyle="primary"
                              disabled={this.state.rejectSelected == null}
                              onClick={() => cancelShipment()}
                            >
                              ตกลง
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={assignDriver}
                      variables={{
                        shipmentID: this.state.shipmentID,
                        driverID: this.state.driverID,
                        truckID: this.state.truckID,
                      }}
                      update={() => {
                        this.setState({ show: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {assignDriver => (
                        <Modal show={this.state.show} onHide={() => this.handleClose()}>
                          <Modal.Header closeButton>
                            <Modal.Title>แก้ไขคนขับรถ {this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Row style={{ marginBottom: '10px' }}>
                              <Col md={12}>คนขับรถ</Col>
                            </Row>

                            <AutosuggestContainer>
                              <Autosuggest
                                suggestions={this.state.DriverSuggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedDriver}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequestedDriver}
                                getSuggestionValue={this.getSuggestionValueDriver}
                                renderSuggestion={this.renderSuggestionDriver}
                                focusInputOnSuggestionClick={false}
                                inputProps={{
                                  placeholder: 'เลือกคนขับรถ',
                                  value: this.state.driverSelected,
                                  onChange: event =>
                                    this.setState({
                                      driverSelected: event.target.value,
                                      driverID: null,
                                    }),
                                  type: 'text',
                                  className: 'form-control',
                                }}
                                onSuggestionSelected={(event, { suggestion }) => {
                                  this.setState({
                                    driverID: suggestion.id,
                                    driverSelected: suggestion.name,
                                    truckID: suggestion.user_setting_truck_id,
                                    truckSelected: suggestion.user_setting_truck_license_plate,
                                  });
                                }}
                                shouldRenderSuggestions={this.shouldRenderSuggestionsDriver}
                              />
                            </AutosuggestContainer>

                            <Row style={{ marginBottom: '10px' }}>
                              <Col md={12}>ทะเบียนรถ</Col>
                            </Row>
                            <AutosuggestContainer>
                              <Autosuggest
                                suggestions={this.state.TruckSuggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedTruck}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequestedTruck}
                                getSuggestionValue={this.getSuggestionValueTruck}
                                renderSuggestion={this.renderSuggestionTruck}
                                focusInputOnSuggestionClick={false}
                                inputProps={{
                                  placeholder: 'เลือกทะเบียนรถ',
                                  value: this.state.truckSelected,
                                  onChange: event =>
                                    this.setState({
                                      truckSelected: event.target.value,
                                      truckID: null,
                                    }),
                                  type: 'text',
                                  className: 'form-control',
                                }}
                                onSuggestionSelected={(event, { suggestion }) => {
                                  this.setState({
                                    truckID: suggestion.id,
                                    truckSelected: suggestion.name,
                                  });
                                }}
                                shouldRenderSuggestions={this.shouldRenderSuggestionsTruck()}
                              />
                            </AutosuggestContainer>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              bsStyle="primary"
                              onClick={() => assignDriver()}
                              disabled={this.state.truckID == null || this.state.driverID == null}
                            >
                              บันทึก
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Mutation
                      mutation={assignAgentMutation}
                      variables={{
                        shipment_id: this.state.shipmentID,
                        transporter: this.state.agentId,
                      }}
                      update={() => {
                        this.setState({ show_transporter: false }, () => {
                          refetch();
                        });
                      }}
                    >
                      {assignAgent => (
                        <Modal
                          show={this.state.show_transporter}
                          onHide={() => this.handleCloseAgent()}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>แก้ไขนายหน้า {this.state.shipmentNumber}</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Row style={{ marginBottom: '10px' }}>
                              <Col md={12}>นายหน้า</Col>
                            </Row>

                            <AutosuggestContainer>
                              <Autosuggest
                                suggestions={this.state.agentSuggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedAgent}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequestedAgent}
                                getSuggestionValue={this.getSuggestionValueAgent}
                                renderSuggestion={this.renderSuggestionAgent}
                                focusInputOnSuggestionClick={false}
                                inputProps={{
                                  placeholder: 'ค้นหา',
                                  value: this.state.agentSelected,
                                  onChange: event =>
                                    this.setState({
                                      agentSelected: event.target.value,
                                      agentId: null,
                                    }),
                                  type: 'text',
                                  className: 'form-control',
                                }}
                                onSuggestionSelected={(event, { suggestion }) => {
                                  this.setState({
                                    agentId: suggestion.id,
                                    agentSelected: suggestion.name,
                                    page_number: 1,
                                  });
                                }}
                                shouldRenderSuggestions={this.shouldRenderSuggestionsAgent}
                              />
                            </AutosuggestContainer>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button bsStyle="primary" onClick={() => assignAgent()}>
                              บันทึก
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Mutation>

                    <Modal
                      show={this.state.showReport}
                      onHide={() => this.setState({ showReport: false })}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>รายงานรายงานขนส่ง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row style={{ marginBottom: '10px' }}>
                          <Col md={12}> ช่วงวันที่</Col>
                        </Row>
                        <FormGroup controlId="formValidationError1">
                          <DateRangePicker
                            onApply={(event, picker) =>
                              this.handleDateRangePickerReport(event, picker)
                            }
                            onCancel={() => this.setState({ reportDate: '', show_reportDate: '' })}
                            containerStyles={{ display: 'block' }}
                            opens="right"
                            format="DD/MM/YYYY"
                          >
                            <FormControl
                              type="text"
                              value={this.state.show_reportDate}
                              placeholder="ค้นหาวันที่"
                              name="reportDate"
                            />
                          </DateRangePicker>
                        </FormGroup>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button bsStyle="primary" onClick={() => this.exportReportShipment()}>
                          ดาวน์โหลดรายงาน
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Mutation
                      mutation={createShipmentSlaMutation}
                      update={(cache, { data }) => {
                        this.addNotification('success', 'บันทึกสำเร็จแล้ว');
                        this.getShipmentCase(data.createShipmentSla.shipment);
                        this.setState({
                          newCase: false,
                          caseIndex: data.createShipmentSla.sla_case_number,
                        });
                        this.getShipmentCaseDetail(data.createShipmentSla._id, admin);
                        // this.getShipmentCaseDetailCancelFirst(data.updateShipmentSla._id, admin)
                        // this.handlerCreateNewCase(admin)
                        // console.log('mutation success')
                      }}
                      onError={error => {
                        this.addNotification('error', error.graphQLErrors[0].message);
                      }}
                      refetchQueries={() => ['shipmentAdmins']}
                    >
                      {(createShipment, { loading, refetch }) => (
                        <Mutation
                          mutation={updateShipmentSla}
                          update={(cache, { data }) => {
                            // this.addNotification('success', 'บันทึกสำเร็จแล้ว');
                            this.setState({ showConfirmModalCase: true });
                            // console.log(data)
                            this.getShipmentCase(data.updateShipmentSla.shipment);
                            this.state.saveCaseCheck == 1
                              ? this.getShipmentCaseDetailCancelFirst(
                                  data.updateShipmentSla._id,
                                  admin
                                )
                              : this.getShipmentCaseDetailCancelSecond(
                                  data.updateShipmentSla._id,
                                  admin
                                );
                            // this.getShipmentCaseDetail(data.updateShipmentSla._id, admin)
                          }}
                          onError={error => {
                            this.addNotification('error', error.graphQLErrors[0].message);
                          }}
                          refetchQueries={() => ['shipmentAdmins']}
                        >
                          {(updateShipment, { loading }) => (
                            <div>
                              <Modal
                                show={this.state.showProblem}
                                onHide={() => {
                                  this.resetFieldCase();
                                  this.setState({ showProblem: false });
                                }}
                                dialogClassName="modal90x90"
                              >
                                <Modal.Header>
                                  <Modal.Title>
                                    <div>
                                      <b>รายงานปัญหา รหัสงานขนส่ง: {this.state.shipmentNumber}</b>
                                      <Button
                                        onClick={() => {
                                          this.resetFieldCase();
                                          this.setState({ showProblem: false });
                                        }}
                                        style={{ float: 'right', marginRight: '10px' }}
                                      >
                                        X ปิด
                                      </Button>
                                      <OverlayTrigger
                                        trigger={['hover']}
                                        key="bottom"
                                        placement="bottom"
                                        rootClose={true}
                                        overlay={
                                          <Popover
                                            style={{
                                              width: '300px',
                                              padding: '5px 0px',
                                              overflow: 'hidden',
                                              zIndex: 1070,
                                            }}
                                            id={`popover-positioned-${this.state.shipmentID}`}
                                            title={`รายละเอียดงานขนส่ง`}
                                          >
                                            <Grid fluid style={{ paddingLeft: '0px' }}>
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>วันรับสินค้า:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    <Moment format="DD/MM/YYYY HH:mm">
                                                      {this.state.pickUpDate}
                                                    </Moment>
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>ผู้ส่งสินค้า:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.shipperName}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <hr style={{ margin: '5px' }} />
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>ประเภทรถ:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.truckType}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>

                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>พนักงานขับรถ:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.driverName}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>เบอร์โทรศัพท์:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.driverPhone}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <hr style={{ margin: '5px' }} />
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={7}>
                                                  <div>จุดรับสินค้า/ รับตู้ :</div>
                                                </Col>
                                                <ColRightPopup md={5}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.pickUpPoint}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={7}>
                                                  <div>จุดส่ง/ ขึ้น/ ลงสินค้า:</div>
                                                </Col>
                                                <ColRightPopup md={5}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.dropPoint}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>นายหน้า:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  <div
                                                    style={{
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }}
                                                  >
                                                    {this.state.transporterName}
                                                  </div>
                                                </ColRightPopup>
                                              </Row>
                                              <hr style={{ margin: '5px' }} />
                                              <Row style={{ fontSize: '18px' }}>
                                                <Col md={6}>
                                                  <div>บริการเสริม:</div>
                                                </Col>
                                                <ColRightPopup md={6}>
                                                  {
                                                    // console.log(this.state.additionService)
                                                  }
                                                  {this.state.additionService.length > 0
                                                    ? this.state.additionService.map(item =>
                                                        item.qty ? (
                                                          <div>
                                                            {`${item.additional}X${item.qty}`}
                                                          </div>
                                                        ) : (
                                                          <div>{`${item.additional}`}</div>
                                                        )
                                                      )
                                                    : '-'}
                                                </ColRightPopup>
                                              </Row>
                                            </Grid>
                                          </Popover>
                                        }
                                      >
                                        <i
                                          style={{
                                            float: 'right',
                                            color: '#0B9CFF',
                                            marginRight: '15px',
                                            marginTop: '7px',
                                          }}
                                          class="fas fa-info-circle"
                                        ></i>
                                      </OverlayTrigger>
                                    </div>
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body
                                  style={{
                                    height: 'calc(85vh)',
                                    padding: 'unset',
                                    backgroundColor: '#F7F7F8',
                                  }}
                                >
                                  <NotificationSystem
                                    ref={this.notificationSystem}
                                    style={styleNotification}
                                  />
                                  <div style={{ display: 'flex', height: 'inherit' }}>
                                    <ColLeft>
                                      <div>
                                        {
                                          // <Button
                                          //     onClick={() => this.handlerCreateNewCase(admin)}
                                          //     style={{ marginLeft: '10px', fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', marginTop: '10px' }}
                                          //     disabled={this.state.newCase}
                                          // >
                                          //     + เพิ่มรายการ
                                          // </Button>
                                        }
                                        <UlTruck style={{ marginBottom: '0px' }}>
                                          <li
                                            onClick={() => this.handlerCreateNewCase(admin)}
                                            style={{
                                              color: 'unset',
                                              backgroundColor: '#F7F7F8',
                                              opacity: this.state.newCase ? '1' : '0.5',
                                            }}
                                          >
                                            <b>
                                              สร้างรายการปัญหา
                                              <i
                                                style={{ position: 'unset', float: 'right' }}
                                                className="fal fa-chevron-right"
                                              ></i>
                                            </b>
                                          </li>
                                        </UlTruck>
                                        <UlTruck
                                          style={{
                                            marginBottom: 0,
                                            marginTop: 0,
                                          }}
                                        >
                                          {this.state.shipmentSlas.length > 0 &&
                                            this.state.shipmentSlas.map((item, index) => (
                                              <li
                                                style={{
                                                  borderLeft:
                                                    item.sla_status == 1
                                                      ? '5px solid #FF9700'
                                                      : '5px solid #6DD634',
                                                }}
                                                className={
                                                  item.sla_case_number == this.state.caseIndex
                                                    ? 'active'
                                                    : ''
                                                }
                                                onClick={() =>
                                                  this.getShipmentCaseDetail(item._id, admin)
                                                }
                                              >
                                                <div>
                                                  <b>
                                                    {index + 1}. {item.title ? item.title : '-'}
                                                  </b>
                                                </div>
                                                <div>ID: #{item.sla_case_number}</div>
                                                {item.sla_status == 1 ? (
                                                  moment() > moment(item.sla_exp) ? null : (
                                                    <CountDownTimer
                                                      exceed={() =>
                                                        this.exceedCountdownDate(
                                                          null,
                                                          item.shipment
                                                        )
                                                      }
                                                      timeTillDate={moment(item.sla_exp).format(
                                                        'YYYY-MM-DD HH:mm:ss'
                                                      )}
                                                      timeFormat="YYYY-MM-DD HH:mm:ss"
                                                      type="once"
                                                    />
                                                  )
                                                ) : null}
                                                <div>
                                                  <b>
                                                    {item.sla_status == 1 ? (
                                                      moment() > moment(item.sla_exp) ? (
                                                        <span
                                                          style={{
                                                            color: 'red',
                                                            fontWeight: 'bold',
                                                          }}
                                                        >
                                                          เกินเวลา
                                                        </span>
                                                      ) : (
                                                        this.handlerCaseStatus(
                                                          item.sla_status,
                                                          item.sla_exp,
                                                          item.completed_date
                                                        )
                                                      )
                                                    ) : (
                                                      this.handlerCaseStatus(
                                                        item.sla_status,
                                                        item.sla_exp,
                                                        item.completed_date
                                                      )
                                                    )}{' '}
                                                  </b>
                                                </div>
                                                <i className="fal fa-chevron-right"></i>
                                              </li>
                                            ))}
                                        </UlTruck>
                                      </div>
                                    </ColLeft>

                                    <ColRight>
                                      {
                                        /////////////////////////
                                        //       CARD 1       //
                                        ////////////////////////
                                      }
                                      <div
                                        style={{
                                          backgroundColor: '#fff',
                                          padding: '10px',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        <Row>
                                          <Col md={4}>
                                            <b>สร้างรายการปัญหา</b>
                                          </Col>
                                          <Col md={4}>
                                            สถานะ:{' '}
                                            {this.state.newCase ? (
                                              <b>กำลังสร้าง...</b>
                                            ) : this.state.caseStatus == 1 ? (
                                              moment() > moment(this.state.expireDate) ? (
                                                <span style={{ color: 'red', fontWeight: 'bold' }}>
                                                  เกินเวลา
                                                </span>
                                              ) : (
                                                this.handlerCaseStatus(
                                                  this.state.caseStatus,
                                                  this.state.expireDateCheck,
                                                  this.state.completedDateCheck
                                                )
                                              )
                                            ) : (
                                              this.handlerCaseStatus(
                                                this.state.caseStatus,
                                                this.state.expireDateCheck,
                                                this.state.completedDateCheck
                                              )
                                            )}
                                          </Col>
                                          <Col md={4}>
                                            Case ID:{' '}
                                            <b>{this.state.newCase ? '-' : this.state.caseIndex}</b>
                                          </Col>
                                        </Row>
                                        <hr style={{ margin: '5px' }} />
                                        {
                                          //  หัวข้อปัญหา
                                        }
                                        <Row>
                                          <Col md={7}>
                                            <SelectBox
                                              disabled={
                                                this.state.newCase ? false : !this.state.editable
                                              }
                                              onChange={event => {
                                                this.setState({
                                                  caseTitle: {
                                                    value: event.value,
                                                    label: event.label,
                                                  },
                                                  caseLevel: {
                                                    value: event.level,
                                                    label: `Level ${event.level}`,
                                                  },
                                                  caseTitleChange: true,
                                                  caseTitleId: event.titleId,
                                                  caseLevelId: event.levelId,
                                                  caseLevelDetail: caseLevelList.filter(
                                                    item => item.level == event.level
                                                  ),
                                                  caseTitleInvalid: false,
                                                  caseLevelInvalid: false,
                                                });
                                              }}
                                              value={this.state.caseTitle}
                                              options={caseTitlelist}
                                              style={{ marginTop: '10px' }}
                                              inputLabel="หัวข้อปัญหา"
                                              placeholder="- เลือก -"
                                              required
                                              messageError={
                                                this.state.caseTitleInvalid && 'กรุณากรอกข้อมูล'
                                              }
                                            />
                                          </Col>
                                          <Col md={5}>
                                            <SelectBox
                                              disabled
                                              value={this.state.caseLevel}
                                              options={caseLevelList}
                                              style={{ marginTop: '10px' }}
                                              inputLabel="ระดับปัญหา"
                                              placeholder="- เลือก -"
                                              required
                                              messageError={
                                                this.state.caseLevelInvalid && 'กรุณากรอกข้อมูล'
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={12}>
                                            <BoxDashboard>
                                              <Col md={4}>
                                                <span>Service Time </span>
                                                {this.state.caseTitleChange ? (
                                                  <div>
                                                    <b>
                                                      {this.state.caseLevelDetail[0]
                                                        ? this.renderServiceTime(
                                                            this.state.caseLevelDetail[0]
                                                              .service_time
                                                          )
                                                        : '-'}
                                                    </b>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <b>
                                                      {' '}
                                                      {this.state.serviceTimeCase
                                                        ? this.renderServiceTime(
                                                            this.state.serviceTimeCase
                                                          )
                                                        : '-'}{' '}
                                                    </b>
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={4}>
                                                <span>ระยะเวลาทั้งหมด </span>
                                                {this.state.caseTitleChange ? (
                                                  <div>
                                                    <b>
                                                      {this.state.caseLevelDetail[0]
                                                        ? `${this.state.caseLevelDetail[0].respond_time.time} ${this.state.caseLevelDetail[0].respond_time.unit}`
                                                        : '-'}
                                                    </b>
                                                  </div>
                                                ) : (
                                                  <div>
                                                    <b>
                                                      {' '}
                                                      {this.state.levelTime
                                                        ? `${this.state.levelTime} ${this.state.levelUnit}`
                                                        : '-'}{' '}
                                                    </b>
                                                  </div>
                                                )}
                                              </Col>
                                              <Col md={4}>
                                                <span>เหลือเวลาในการแก้ไข </span>
                                                {this.state.newCase ? (
                                                  <div>
                                                    <b>-</b>
                                                  </div>
                                                ) : this.state.caseStatus === 1 ? (
                                                  moment() > moment(this.state.expireDate) ? (
                                                    <div>
                                                      <b>-</b>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <b>
                                                        <CountDownTimer
                                                          exceed={() =>
                                                            this.exceedCountdownDate(
                                                              this.state.caseId,
                                                              null
                                                            )
                                                          }
                                                          timeTillDate={this.state.expireDate}
                                                          timeFormat="YYYY-MM-DD HH:mm:ss"
                                                        />{' '}
                                                      </b>
                                                    </div>
                                                  )
                                                ) : (
                                                  <div>
                                                    <b>-</b>
                                                  </div>
                                                )}
                                              </Col>
                                            </BoxDashboard>
                                          </Col>
                                        </Row>

                                        <hr style={{ margin: '5px' }} />
                                        {
                                          //รายละเอียดปัญหา
                                        }
                                        <Row>
                                          <Col md={8}>
                                            <TextArea
                                              disabled={
                                                this.state.newCase ? false : !this.state.editable
                                              }
                                              onChange={event =>
                                                this.setState({
                                                  caseDetail: event.target.value,
                                                  caseDetailInvalid: false,
                                                })
                                              }
                                              value={this.state.caseDetail}
                                              inputLabel="รายละเอียดปัญหา"
                                              style={{ marginTop: '10px' }}
                                              placeholder="ระบุ"
                                              required
                                              messageError={
                                                this.state.caseDetailInvalid && 'กรุณากรอกข้อมูล'
                                              }
                                            />
                                          </Col>
                                          {
                                            //วัน-เวลาในการรับแจ้งปัญหา
                                          }
                                          <Col md={4}>
                                            <div
                                              style={{
                                                marginTop: '10px',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color:
                                                  this.state.InformRecieveDateTimeInvalid &&
                                                  '#FF0000',
                                              }}
                                            >
                                              วัน-เวลาที่เกิดปัญหา
                                              <span style={{ color: '#FF0000' }}>*</span>
                                            </div>
                                            <div
                                              style={{
                                                border:
                                                  this.state.InformRecieveDateTimeInvalid &&
                                                  '1px solid #FF0000',
                                                borderRadius: '5px',
                                              }}
                                            >
                                              <Datetime
                                                value={this.state.InformRecieveDateTimeShow}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat="HH:mm"
                                                inputProps={{
                                                  disabled: this.state.newCase
                                                    ? false
                                                    : !this.state.editable,
                                                  placeholder: '00/00/00 - 00:00น',
                                                }}
                                                className="caseModal"
                                                input={true}
                                                onChange={event => {
                                                  this.handleDatePicker(event, 'receive');
                                                }}
                                              />
                                            </div>
                                            {this.state.InformRecieveDateTimeInvalid && (
                                              <div
                                                style={{
                                                  fontSize: '16px',
                                                  color: '#FF0000',
                                                  height: '22px',
                                                  textAlign: 'right',
                                                }}
                                              >
                                                {this.state.InformRecieveDateTimeShow
                                                  ? 'เวลาต้องไม่เกินกว่าเวลาปัจจุบัน'
                                                  : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                              </div>
                                            )}
                                          </Col>
                                          {
                                            //แนบไฟล์
                                          }
                                          <Col md={12}>
                                            <Mutation
                                              mutation={singleUploadMutation}
                                              update={(cache, { data }) => {
                                                this._inputAttachFileFirstCase.current.value = '';
                                                this.setState({ animationUpload: false });
                                                this.setState(previousState => ({
                                                  attachFile: [
                                                    ...previousState.attachFile,
                                                    data.singleUpload.path,
                                                  ],
                                                }));
                                              }}
                                              onError={error => {
                                                console.log(error);
                                              }}
                                            >
                                              {uploadFile => {
                                                return (
                                                  <input
                                                    accept="image/jpeg,image/png,application/pdf"
                                                    onChange={event => {
                                                      const files = event.target.files[0];
                                                      const checkFileTypeImage = this.checkFileTypeImage(
                                                        files
                                                      );
                                                      if (checkFileTypeImage === false) {
                                                        this.addNotification(
                                                          'error',
                                                          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png, pdf เท่านั้น'
                                                        );
                                                        return;
                                                      }
                                                      files &&
                                                        this.setState({ animationUpload: true });
                                                      this._inputAttachFileFirstCase.current
                                                        .files[0] &&
                                                        this.handleSingleUploadDocument(
                                                          uploadFile,
                                                          this._inputAttachFileFirstCase.current.files[0].name.split(
                                                            '.'
                                                          )[0],
                                                          event
                                                        );
                                                    }}
                                                    type="file"
                                                    id="file"
                                                    style={{ display: 'none' }}
                                                    ref={this._inputAttachFileFirstCase}
                                                  />
                                                );
                                              }}
                                            </Mutation>

                                            <div style={{ marginTop: '10px' }}>
                                              <Button
                                                disabled={
                                                  this.state.newCase ? false : !this.state.editable
                                                }
                                                onClick={() =>
                                                  this._inputAttachFileFirstCase.current.click()
                                                }
                                                style={{
                                                  border: '1px dashed #0B9CFF',
                                                  padding: '2.5px unset',
                                                  color: '#0B9CFF',
                                                }}
                                              >
                                                + แนบไฟล์
                                              </Button>
                                              <span style={{ color: 'grey', marginLeft: '5px' }}>
                                                รูปแบบไฟล์แนบรับ .jpg, .jpeg, .png , .pdf
                                              </span>
                                            </div>
                                          </Col>

                                          {this.state.animationUpload ? (
                                            <Col md={12}>
                                              <div
                                                style={{
                                                  backgroundColor: 'rgba(10, 156, 255, 0.2)',
                                                  color: 'black',
                                                  borderRadius: '5px',
                                                  border: '0.1px solid #E7E7E7',
                                                  marginTop: '5px',
                                                  padding: '5px 5px',
                                                  position: 'relative',
                                                }}
                                              >
                                                <i class="fad fa-spinner-third fa-spin"></i>
                                              </div>
                                            </Col>
                                          ) : null}
                                          {this.state.attachFile &&
                                            this.state.attachFile.length > 0 &&
                                            this.state.attachFile.map(
                                              (item, index) =>
                                                item && (
                                                  <Col md={12}>
                                                    <div
                                                      style={{
                                                        backgroundColor: 'rgba(10, 156, 255, 0.2)',
                                                        borderRadius: '5px',
                                                        border: '0.1px solid #E7E7E7',
                                                        marginTop: '5px',
                                                        padding: '5px 5px',
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      {
                                                        <a
                                                          href={s3 + '/' + item}
                                                          target="_blank"
                                                          style={{ color: 'black' }}
                                                        >
                                                          {item.split('/').length > 2
                                                            ? item.split('/')[4]
                                                            : item}
                                                        </a>
                                                      }
                                                      {
                                                        <Button
                                                          disabled={
                                                            this.state.newCase
                                                              ? false
                                                              : !this.state.editable
                                                          }
                                                          style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            padding: 'unset',
                                                            border: 'unset',
                                                          }}
                                                          onClick={() =>
                                                            this.setState(prevState => ({
                                                              attachFile: prevState.attachFile.filter(
                                                                (itemPre, indexPre) =>
                                                                  indexPre !== index
                                                              ),
                                                            }))
                                                          }
                                                        >
                                                          <i
                                                            style={{ color: 'red' }}
                                                            class="fas fa-trash-alt"
                                                          ></i>
                                                        </Button>
                                                      }
                                                    </div>
                                                  </Col>
                                                )
                                            )}
                                          {
                                            //ผู้รับปัญหา
                                          }
                                          <Col md={6}>
                                            <Input
                                              disabled
                                              onChange={event =>
                                                this.setState({
                                                  caseOwner: event.target.value,
                                                  caseOwnerInvalid: false,
                                                })
                                              }
                                              value={this.state.caseOwner}
                                              inputLabel="ผู้รับปัญหา"
                                              style={{ marginTop: '10px' }}
                                              placeholder="ระบุ"
                                              required
                                              messageError={this.state.caseOwnerInvalid}
                                            />
                                          </Col>
                                          {
                                            //ช่องทางรับปัญหา
                                          }
                                          <Col md={6}>
                                            <SelectBox
                                              disabled={
                                                this.state.newCase ? false : !this.state.editable
                                              }
                                              onChange={event =>
                                                this.setState({
                                                  caseRecieveChanel: {
                                                    value: event.value,
                                                    label: event.label,
                                                  },
                                                  caseRecieveChanelInvalid: false,
                                                })
                                              }
                                              value={this.state.caseRecieveChanel}
                                              style={{ marginTop: '10px' }}
                                              options={selectOptionCaseChanel}
                                              inputLabel="ช่องทางรับปัญหา"
                                              placeholder="- เลือก -"
                                              required
                                              messageError={
                                                this.state.caseRecieveChanelInvalid &&
                                                'กรุณากรอกข้อมูล'
                                              }
                                            />
                                          </Col>
                                          {
                                            //เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)
                                          }
                                          <Col md={6}>
                                            <Input
                                              disabled={
                                                this.state.newCase ? false : !this.state.editable
                                              }
                                              onChange={event =>
                                                this.setState({
                                                  phoneInformer: event.target.value,
                                                  phoneInformerInvalid: false,
                                                })
                                              }
                                              value={this.state.phoneInformer}
                                              inputLabel="เบอร์โทรศัพท์ (ผู้แจ้งปัญหา)"
                                              style={{ marginTop: '10px' }}
                                              placeholder="ระบุ"
                                              messageError={
                                                this.state.phoneInformerInvalid &&
                                                'กรุณากรอกข้อมูลให้ถูกต้อง'
                                              }
                                            />
                                          </Col>
                                          {
                                            //อีเมล (ผู้แจ้งปัญหา)
                                          }
                                          <Col md={6}>
                                            <Input
                                              disabled={
                                                this.state.newCase ? false : !this.state.editable
                                              }
                                              onChange={event =>
                                                this.setState({
                                                  mailInformer: event.target.value,
                                                  mailInformerInvalid: false,
                                                })
                                              }
                                              value={this.state.mailInformer}
                                              inputLabel="อีเมล (ผู้แจ้งปัญหา)"
                                              style={{ marginTop: '10px' }}
                                              placeholder="ระบุ"
                                              messageError={
                                                this.state.mailInformerInvalid &&
                                                'กรุณากรอกข้อมูลให้ถูกต้อง'
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <hr style={{ margin: '10px' }} />
                                        {
                                          //Footer
                                        }
                                        <Row>
                                          <Col>
                                            {this.state.editable || this.state.newCase ? (
                                              <div style={{ float: 'right', marginRight: '15px' }}>
                                                <Button
                                                  style={{
                                                    backgroundColor: '#94DA57',
                                                    color: 'white',
                                                    opacity: '1',
                                                  }}
                                                  onClick={() => {
                                                    this.state.newCase
                                                      ? this.handlerCreateShipmentSla(
                                                          createShipment
                                                        )
                                                      : this.state.editable &&
                                                        this.handlerSaveFirstCase(
                                                          updateShipment,
                                                          this.state.caseId
                                                        );
                                                  }}
                                                >
                                                  บันทึกปัญหา
                                                </Button>
                                                <Button
                                                  style={{
                                                    marginLeft: '10px',
                                                    display: this.state.newCase ? 'none' : 'inline',
                                                  }}
                                                  onClick={() =>
                                                    this.getShipmentCaseDetailCancelFirst(
                                                      this.state.caseId,
                                                      admin
                                                    )
                                                  }
                                                >
                                                  ยกเลิก
                                                </Button>
                                              </div>
                                            ) : (
                                              <div style={{ float: 'right', marginRight: '15px' }}>
                                                <Button
                                                  onClick={() => {
                                                    this.refetchCaseLevelAndTitle();
                                                    this.setState({ editable: true });
                                                  }}
                                                  style={{ marginLeft: '10px' }}
                                                >
                                                  แก้ไข
                                                </Button>
                                              </div>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>
                                      {
                                        /////////////////////////
                                        //       CARD 2        //
                                        /////////////////////////
                                      }
                                      <div
                                        style={{
                                          backgroundColor: '#fff',
                                          padding: '10px',
                                          borderRadius: '5px',
                                          marginTop: '10px',
                                          display: this.state.newCase ? 'none' : 'block',
                                        }}
                                      >
                                        <Row>
                                          {
                                            //2. รายละเอียดการแก้ไขปัญหา
                                          }
                                          <Col md={8}>
                                            <TextArea
                                              disabled={
                                                this.state.newCase
                                                  ? true
                                                  : this.state.caseAnswer
                                                  ? !this.state.secondEditable
                                                  : false
                                              }
                                              onChange={event =>
                                                this.setState({
                                                  caseResolveDetail: event.target.value,
                                                  caseResolveDetailInvalid: '',
                                                })
                                              }
                                              style={{ marginTop: '5px' }}
                                              value={this.state.caseResolveDetail}
                                              inputLabel="รายละเอียดการแก้ไขปัญหา"
                                              placeholder="ระบุ"
                                              required={this.state.newCase ? false : true}
                                              messageError={
                                                this.state.caseResolveDetailInvalid &&
                                                'กรุณากรอกข้อมูล'
                                              }
                                            />
                                          </Col>
                                          {
                                            //วัน-เวลาตอบกลับลูกค้า
                                          }
                                          <Col md={4}>
                                            <div
                                              style={{
                                                marginTop: '5px',
                                                fontSize: '16px',
                                                fontWeight: 'bold',
                                                color:
                                                  this.state.customerReplyDateTimeInvalid &&
                                                  '#FF0000',
                                              }}
                                            >
                                              วัน-เวลาตอบกลับลูกค้า
                                              <span style={{ color: '#FF0000' }}>*</span>
                                            </div>
                                            <div
                                              style={{
                                                border:
                                                  this.state.customerReplyDateTimeInvalid &&
                                                  '1px solid #FF0000',
                                                borderRadius: '5px',
                                              }}
                                            >
                                              <Datetime
                                                value={this.state.customerReplyDateTimeShow}
                                                dateFormat="DD/MM/YYYY"
                                                timeFormat="HH:mm"
                                                inputProps={{
                                                  disabled: this.state.newCase
                                                    ? false
                                                    : this.state.caseAnswer
                                                    ? !this.state.secondEditable
                                                    : false,
                                                  placeholder: '00/00/00 - 00:00น',
                                                }}
                                                className="caseModal"
                                                onChange={event =>
                                                  this.handleDatePicker(event, 'answer')
                                                }
                                              />
                                            </div>
                                            {this.state.customerReplyDateTimeInvalid && (
                                              <div
                                                style={{
                                                  fontSize: '16px',
                                                  color: '#FF0000',
                                                  height: '22px',
                                                  textAlign: 'right',
                                                }}
                                              >
                                                {this.state.customerReplyDateTimeShow
                                                  ? 'เวลาต้องไม่เกินกว่าเวลาปัจจุบัน'
                                                  : 'กรุณากรอกข้อมูลให้ถูกต้อง'}
                                              </div>
                                            )}
                                          </Col>
                                          {
                                            //แนบไฟล์
                                          }
                                          <Col md={12}>
                                            <Mutation
                                              mutation={singleUploadMutation}
                                              update={(cache, { data }) => {
                                                // console.log(data.singleUpload.path)
                                                this._inputAttachFileSecondCase.current.value = '';
                                                this.setState({ animationUploadSecond: false });
                                                this.setState(prevState => ({
                                                  attachSecondFile: [
                                                    ...prevState.attachSecondFile,
                                                    data.singleUpload.path,
                                                  ],
                                                }));
                                              }}
                                              onError={error => {
                                                console.log(error);
                                              }}
                                            >
                                              {uploadFile => {
                                                return (
                                                  <input
                                                    accept="image/jpeg,image/png,application/pdf"
                                                    onChange={event => {
                                                      const files = event.target.files[0];
                                                      const checkFileTypeImage = this.checkFileTypeImage(
                                                        files
                                                      );
                                                      if (checkFileTypeImage === false) {
                                                        this.addNotification(
                                                          'error',
                                                          'ประเภทไฟล์ที่เลือกไม่ถูกต้อง รองรับเฉพาะไฟล์ประเภท jpg, jpeg, png, pdf เท่านั้น'
                                                        );
                                                        return;
                                                      }
                                                      files &&
                                                        this.setState({
                                                          animationUploadSecond: true,
                                                        });
                                                      this._inputAttachFileSecondCase.current
                                                        .files[0] &&
                                                        this.handleSingleUploadDocument(
                                                          uploadFile,
                                                          this._inputAttachFileSecondCase.current.files[0].name.split(
                                                            '.'
                                                          )[0],
                                                          event
                                                        );
                                                    }}
                                                    type="file"
                                                    id="file"
                                                    style={{ display: 'none' }}
                                                    ref={this._inputAttachFileSecondCase}
                                                  />
                                                );
                                              }}
                                            </Mutation>

                                            <div style={{ marginTop: '10px' }}>
                                              <Button
                                                disabled={
                                                  this.state.newCase
                                                    ? false
                                                    : this.state.caseAnswer
                                                    ? !this.state.secondEditable
                                                    : false
                                                }
                                                onClick={() =>
                                                  this._inputAttachFileSecondCase.current.click()
                                                }
                                                style={{
                                                  border: '1px dashed #0B9CFF',
                                                  padding: '2.5px unset',
                                                  color: '#0B9CFF',
                                                }}
                                              >
                                                + แนบไฟล์
                                              </Button>
                                              <span style={{ color: 'grey', marginLeft: '5px' }}>
                                                รูปแบบไฟล์แนบรับ .jpg, .jpeg, .png , .pdf
                                              </span>
                                            </div>
                                          </Col>
                                          {// this.state.attachFile.length > 0 && console.log(this.state.attachFile)
                                          this.state.animationUploadSecond ? (
                                            <Col md={12}>
                                              <div
                                                style={{
                                                  backgroundColor: 'rgba(10, 156, 255, 0.2)',
                                                  color: 'black',
                                                  borderRadius: '5px',
                                                  border: '0.1px solid #E7E7E7',
                                                  marginTop: '5px',
                                                  padding: '5px 5px',
                                                  position: 'relative',
                                                }}
                                              >
                                                <i class="fad fa-spinner-third fa-spin"></i>
                                              </div>
                                            </Col>
                                          ) : null}
                                          {this.state.attachSecondFile &&
                                            this.state.attachSecondFile.length > 0 &&
                                            this.state.attachSecondFile.map(
                                              (item, index) =>
                                                item && (
                                                  <Col md={12}>
                                                    <div
                                                      style={{
                                                        backgroundColor: 'rgba(10, 156, 255, 0.2)',
                                                        borderRadius: '5px',
                                                        border: '0.1px solid #E7E7E7',
                                                        marginTop: '5px',
                                                        padding: '5px 5px',
                                                        position: 'relative',
                                                      }}
                                                    >
                                                      {
                                                        <a
                                                          href={s3 + '/' + item}
                                                          target="_blank"
                                                          style={{ color: 'black' }}
                                                        >
                                                          {item.split('/').length > 2
                                                            ? item.split('/')[4]
                                                            : item}
                                                        </a>
                                                      }
                                                      <Button
                                                        disabled={
                                                          this.state.newCase
                                                            ? false
                                                            : this.state.caseAnswer
                                                            ? !this.state.secondEditable
                                                            : false
                                                        }
                                                        style={{
                                                          position: 'absolute',
                                                          right: '10px',
                                                          padding: 'unset',
                                                          border: 'unset',
                                                        }}
                                                        onClick={() =>
                                                          this.setState(prevState => ({
                                                            attachSecondFile: prevState.attachSecondFile.filter(
                                                              (preItem, preIndex) =>
                                                                preIndex !== index
                                                            ),
                                                          }))
                                                        }
                                                      >
                                                        <i
                                                          style={{ color: 'red' }}
                                                          class="fas fa-trash-alt"
                                                        ></i>
                                                      </Button>
                                                    </div>
                                                  </Col>
                                                )
                                            )}
                                          {
                                            //ผู้ตอบกลับปัญหา
                                          }
                                          <Col md={6}>
                                            <Input
                                              style={{ marginTop: '10px' }}
                                              disabled
                                              onChange={event =>
                                                this.setState({
                                                  caseAnswer: event.target.value,
                                                  caseAnswerInvalid: false,
                                                })
                                              }
                                              value={this.state.caseAnswer}
                                              inputLabel="ผู้ตอบกลับปัญหา"
                                              placeholder="ระบุ"
                                              messageError={this.state.caseAnswerInvalid}
                                            />
                                          </Col>
                                          {
                                            //ช่องทางตอบกลับปัญหา
                                          }
                                          <Col md={6}>
                                            <SelectBox
                                              style={{ marginTop: '10px' }}
                                              disabled={
                                                this.state.newCase
                                                  ? false
                                                  : this.state.caseAnswer
                                                  ? !this.state.secondEditable
                                                  : false
                                              }
                                              onChange={event =>
                                                this.setState({
                                                  caseAnswerChanel: {
                                                    value: event.value,
                                                    label: event.label,
                                                  },
                                                })
                                              }
                                              value={this.state.caseAnswerChanel}
                                              options={selectOptionCaseChanel}
                                              inputLabel="ช่องทางตอบกลับปัญหา"
                                              placeholder="- เลือก -"
                                              messageError={
                                                this.state.caseAnswerChanelInvalid &&
                                                'กรุณากรอกข้อมูล'
                                              }
                                            />
                                          </Col>
                                        </Row>
                                        <hr style={{ margin: '10px' }} />
                                        {
                                          //Footer
                                        }
                                        <Row>
                                          <Col>
                                            <div style={{ float: 'right', marginRight: '15px' }}>
                                              {this.state.secondEditable ||
                                              this.state.newCase ||
                                              !this.state.caseAnswer ? (
                                                <div>
                                                  <Button
                                                    disabled={this.state.newCase}
                                                    style={{
                                                      backgroundColor: '#94DA57',
                                                      color: 'white',
                                                      opacity: '1',
                                                    }}
                                                    onClick={() =>
                                                      this.handlerSaveSecondCase(
                                                        updateShipment,
                                                        this.state.caseId
                                                      )
                                                    }
                                                  >
                                                    บันทึก
                                                  </Button>
                                                  <Button
                                                    style={{
                                                      marginLeft: '10px',
                                                      display: this.state.newCase
                                                        ? 'none'
                                                        : 'inline',
                                                    }}
                                                    onClick={() =>
                                                      this.getShipmentCaseDetailCancelSecond(
                                                        this.state.caseId,
                                                        admin
                                                      )
                                                    }
                                                  >
                                                    ยกเลิก
                                                  </Button>
                                                </div>
                                              ) : (
                                                <Button
                                                  onClick={() =>
                                                    !this.state.caseResolveDetail ||
                                                    this.state.newCase
                                                      ? this.setState({
                                                          secondEditable: true,
                                                          caseAnswer: admin,
                                                          customerReplyDateTime: moment().format(
                                                            'YYYY-MM-DD HH:mm'
                                                          ),
                                                          customerReplyDateTimeShow: moment().format(
                                                            'DD/MM/YYYY HH:mm'
                                                          ),
                                                        })
                                                      : this.setState({
                                                          secondEditable: true,
                                                          caseAnswer: admin,
                                                        })
                                                  }
                                                >
                                                  แก้ไข
                                                </Button>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </ColRight>
                                  </div>
                                </Modal.Body>
                              </Modal>
                              <Modal
                                show={this.state.showConfirmModalCase}
                                style={{ marginTop: '30vh' }}
                                onHide={() => {
                                  this.setState({ showConfirmModalCase: false });
                                }}
                              >
                                <Modal.Body
                                  style={{
                                    height: 'calc(20vh)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <span style={{ fontSize: '20px' }}>
                                    <b>แก้ไขรายงานปัญหา Case Id : {this.state.caseIndex}</b>
                                  </span>
                                  <br />
                                  <span style={{ fontSize: '20px' }}>บันทึกการแก้ไขเรียบร้อย</span>
                                  <Button
                                    style={{
                                      position: 'absolute',
                                      right: '20px',
                                      bottom: '20px',
                                    }}
                                    onClick={() => this.setState({ showConfirmModalCase: false })}
                                  >
                                    ปิด
                                  </Button>
                                </Modal.Body>
                              </Modal>
                            </div>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  </Col>
                );
              }}
            </Query>
          </Row>
        </Grid>
      </Container>
    );
  }
}

export default compose(
  graphql(userTruckList, { name: 'userTruckList' }),
  graphql(driverUserList, { name: 'driverUserList' }),
  graphql(accountInfoList, {
    name: 'accountInfoList',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        account_info_type: 3,
      },
    }),
  }),
  graphql(adminName, { name: 'adminName' }),
  graphql(datamasterSlaTitlesList, {
    name: 'datamasterSlaTitlesList',
    options: props => ({
      fetchPolicy: 'network-only',
      variables: {
        params: {
          status_delete: false,
        },
      },
    }),
  }),
  graphql(datamasterSlaLevelsList, { name: 'datamasterSlaLevelsList' })
)(Shipment);
