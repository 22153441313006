import gql from 'graphql-tag';

export default gql`
    mutation setAdminUpdateMileage(
        $params: paramsAdminUpdateMileage
        $update: inputAdminUpdateMileage
    ) {
        adminUpdateMileage(
            params: $params
            update: $update
        ){
            _id
        }
    }
`;