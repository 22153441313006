import React, { Component } from 'react';
import Moment from 'react-moment';
import { Query, graphql, compose } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  DropdownButton,
  Dropdown,
  MenuItem,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import styled from 'styled-components';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import CustomButton from 'components/CustomButton/CustomButton.jsx';
import adminName from './graphql/query/adminName';

import accountInfoList from './graphql/query/accountInfoList';

import baseUrl from './../../config/baseUrl';
import reportUrl from './../../config/reportUrl';
import { AutoScaleAxis } from 'chartist';

const tooltip = <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const TableVertiacal = styled.div`
  overflow: auto;
  max-height: 70vh;
  width: 100%;
`;

const StatusFilter = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  padding: unset;
  padding-top: 5;
  margin-right: 4;
`;

class Shipper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin_id: 0,
      total_page: 0,
      page_number: 1,
      order_by: 'create_date',
      order_type: -1,
      account_info_status: null,
      account_info_email: '',
      account_info_phone_number: '',
      account_info_business_type_th: '',
      account_info_number: '',
      create_date: '',
      show_create_date: '',
      showReport: false,
      reportDate: '',
      show_reportDate: '',
      modeReport: '',
      account_info_customer_type: {
        value: 0,
        name: 'ทั้งหมด',
      },
      account_info_company_name: '',
      account_info_business_name: '',
      customer_service_type: '',
    };
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    // console.log(page+1);
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  handleDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ create_date: date, show_create_date: show }, () => {
      refetch();
    });
  }

  openReportShipper(mode, admin_id) {
    this.setState({
      showReport: true,
      modeReport: mode,
      reportDate: '',
      show_reportDate: '',
      admin_id,
    });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  exportReportShipper() {
    let uri = '';
    if (this.state.modeReport === 'list') {
      uri = `${reportUrl}/user_report?admin_id=${this.state.admin_id}&date=${this.state.reportDate}`;
    } else {
      uri = `${baseUrl}/accountReport?admin_id=${this.state.admin_id}&date=${this.state.reportDate}`;
    }

    window.location = uri;

    this.setState({ showReport: false, reportDate: '', show_reportDate: '', modeReport: '' });
  }

  renderUser(status) {
    switch (status) {
      case 1:
        return <Label bsStyle="default">ลูกค้าเก่า</Label>;
      default:
        return '';
    }
  }

  renderChannel(status) {
    switch (status) {
      case 2:
        return <Label bsStyle="primary">WEB</Label>;
      case 1:
        return <Label bsStyle="danger">APP</Label>;
      default:
        return '';
    }
  }

  renderCustomerType = status => {
    switch (status) {
      case 0:
        return <span>ทั้งหมด</span>;
      case 1:
        return (
          <span>
            <span className="label" style={{ backgroundColor: '#FED249' }}>
              <i className="fal fa-user" />
            </span>{' '}
            B2C{' '}
          </span>
        );
      case 2:
        return (
          <span>
            <span className="label label-warning">
              <i className="fal fa-users" />
            </span>{' '}
            B2B-N
          </span>
        );
      case 3:
        return (
          <span>
            <span className="label label-success">
              <i class="fal fa-donate" />
            </span>{' '}
            GTX BU
          </span>
        );
      case 4:
        return (
          <span>
            <span className="label label-primary">
              <i class="fal fa-gem" />
            </span>{' '}
            Key Account
          </span>
        );
      default:
        return '';
    }
  };

  renderCustomerTypeFilter = status => {
    switch (status) {
      case 0:
        return <span style={{ marginRight: '70px' }}>ทั้งหมด</span>;
      case 1:
        return (
          <span style={{ marginRight: '50px' }}>
            <StatusFilter className="label" style={{ backgroundColor: '#FED249' }}>
              <i style={{ fontSize: 9 }} className="fal fa-user" />
            </StatusFilter>{' '}
            B2C{' '}
          </span>
        );
      case 2:
        return (
          <span style={{ marginRight: '50px' }}>
            <StatusFilter className="label label-warning">
              <i style={{ fontSize: 9 }} className="fal fa-users" />
            </StatusFilter>{' '}
            B2B-N
          </span>
        );
      case 3:
        return (
          <span style={{ marginRight: '50px' }}>
            <StatusFilter className="label label-success">
              <i style={{ fontSize: 9 }} class="fal fa-donate" />
            </StatusFilter>{' '}
            GTX BU
          </span>
        );
      case 4:
        return (
          <span style={{ marginRight: '50px' }}>
            <StatusFilter className="label label-primary">
              <i style={{ fontSize: 9 }} class="fal fa-gem" />
            </StatusFilter>{' '}
            Key Account
          </span>
        );
      default:
        return '';
    }
  };

  render() {
    let admin_id = '';
    if (this.props.adminName.loading == false && this.props.adminName.adminList != undefined) {
      admin_id = this.props.adminName.adminList.admin[0]._id;
    }
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Query
                query={accountInfoList}
                variables={{
                  page_number: this.state.page_number,
                  order_by: this.state.order_by,
                  order_type: this.state.order_type,
                  account_info_status: this.state.account_info_status,
                  account_info_email: this.state.account_info_email,
                  account_info_phone_number: this.state.account_info_phone_number,
                  account_info_business_type_th: this.state.account_info_business_type_th,
                  create_date: this.state.create_date,
                  old_user: this.state.old_user,
                  account_info_register_channel: this.state.account_info_register_channel,
                  account_info_number: this.state.account_info_number,
                  account_info_customer_type: this.state.account_info_customer_type.value,
                  account_info_business_name: this.state.account_info_business_name,
                  customer_service_type: this.state.customer_service_type,
                }}
              >
                {({ loading, error, data, refetch, variables }) => {
                  {
                    console.log(variables);
                  }
                  {
                    console.log(data);
                  }
                  var itemLength = 'loading';
                  const itemIndicatorSize = (this.state.page_number - 1) * 30;

                  if (data && data.accountInfoList) {
                    if (data.accountInfoList.account_info.length > 0) {
                      itemLength = data.accountInfoList.account_info.length;
                    }
                  }
                  return (
                    <div>
                      <CardTable
                        title="ตารางรายการผู้ส่งสินค้า"
                        category="ข้อมูลผู้ส่งสินค้าจากระบบออนไลน์ Marketplace"
                        ctTableFullWidth
                        ctTableResponsive
                        rightButton={
                          <div style={{ float: 'right', marginLeft: '15px' }}>
                            <Button
                              bsStyle="primary"
                              onClick={() => this.openReportShipper('list', admin_id)}
                            >
                              {' '}
                              <i className="fal fa-clipboard-list"></i> รายงานรายชื่อลูกค้า{' '}
                            </Button>
                            <Button
                              style={{ marginLeft: '15px' }}
                              onClick={() => this.openReportShipper('total', admin_id)}
                            >
                              {' '}
                              <i className="fal fa-clipboard-user"></i> รายงานจำนวนลูกค้า{' '}
                            </Button>
                          </div>
                        }
                        content={
                          <div>
                            <PageWrapper style={{ textAlign: 'right' }}>
                              <div className="text">
                                {this.state.page_number === 1
                                  ? `1 - ${itemLength}`
                                  : `${itemIndicatorSize + 1} - ${itemLength +
                                      itemIndicatorSize}`}{' '}
                                หน้า :
                              </div>
                              <FormControl
                                type="number"
                                min={1}
                                value={this.state.page_number}
                                onChange={event =>
                                  setInterval(
                                    this.nextPage(
                                      Number(event.target.value) - 1,
                                      loading || error ? '0' : data.accountInfoList.total_page,
                                      refetch
                                    ),
                                    1000
                                  )
                                }
                                name="page_number"
                              />
                              <div className="text">
                                {' '}
                                {loading || error ? '' : `จาก ${data.accountInfoList.total_page}`}
                              </div>
                            </PageWrapper>
                            <div className="table-vertical">
                              <Table striped hover>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('account_info_number')}
                                      onClick={() => this.sort('account_info_number', refetch)}
                                    >
                                      รหัสลูกค้า
                                    </th>
                                    <th style={{ minWidth: '70px' }}>ชื่อบริษัท</th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('account_info_customer_type')}
                                      onClick={() =>
                                        this.sort('account_info_customer_type', refetch)
                                      }
                                    >
                                      ประเภทลูกค้า
                                    </th>
                                    <th
                                      style={{ minWidth: '140px' }}
                                      className={this.renderSorting('customer_service_type')}
                                      onClick={() => this.sort('customer_service_type', refetch)}
                                    >
                                      ประเภทบริการ
                                    </th>
                                    <th
                                      style={{ minWidth: '90px' }}
                                      className={this.renderSorting('account_info_email')}
                                      onClick={() => this.sort('account_info_email', refetch)}
                                    >
                                      อีเมล์
                                    </th>
                                    <th
                                      style={{ minWidth: '100px' }}
                                      className={this.renderSorting('account_info_phone_number')}
                                      onClick={() =>
                                        this.sort('account_info_phone_number', refetch)
                                      }
                                    >
                                      เบอร์โทรศัพท์
                                    </th>
                                    {/*<th style={{minWidth:"150px"}} className={this.renderSorting("account_info_business_type")} onClick={() => this.sort("account_info_business_type", refetch)}>ประเภทธุรกิจ</th>*/}
                                    <th
                                      style={{ minWidth: '120px' }}
                                      className={this.renderSorting('account_info_status')}
                                      onClick={() => this.sort('account_info_status', refetch)}
                                    >
                                      สถานะ
                                    </th>
                                    <th
                                      style={{ minWidth: '90px' }}
                                      className={this.renderSorting(
                                        'account_info_register_channel'
                                      )}
                                      onClick={() =>
                                        this.sort('account_info_register_channel', refetch)
                                      }
                                    >
                                      สมัครจาก
                                    </th>
                                    <th
                                      style={{ minWidth: '100px' }}
                                      className={this.renderSorting('create_date')}
                                      onClick={() => this.sort('create_date', refetch)}
                                    >
                                      วันที่สมัคร
                                    </th>
                                  </tr>
                                  <tr className="tableFilter">
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_number}
                                        placeholder="ค้นหารหัสลูกค้า"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_number: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_number"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_business_name}
                                        placeholder="ค้นหาชื่อบริษัท"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_business_name: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_business_name"
                                      />
                                    </th>
                                    <th>
                                      <DropdownButton
                                        style={{ minWidth: '120px', textAlign: 'left' }}
                                        title={this.renderCustomerTypeFilter(
                                          this.state.account_info_customer_type.value
                                        )}
                                        id="dropdown"
                                        onSelect={(eventKey, key) => {
                                          console.log(eventKey);
                                          this.setState({ account_info_customer_type: eventKey });
                                        }}
                                      >
                                        <MenuItem eventKey={{ value: 0, name: 'ทั้งหมด' }}>
                                          ทั้งหมด
                                        </MenuItem>
                                        <MenuItem eventKey={{ value: 1, name: 'B2C' }}>
                                          <span
                                            className="label"
                                            style={{ backgroundColor: '#FED249' }}
                                          >
                                            <i className="fal fa-user" />
                                          </span>{' '}
                                          B2C
                                        </MenuItem>
                                        <MenuItem eventKey={{ value: 2, name: 'B2B-N' }}>
                                          <span className="label label-warning">
                                            <i className="fal fa-users" />
                                          </span>{' '}
                                          B2B-N
                                        </MenuItem>
                                        <MenuItem eventKey={{ value: 3, name: 'GTX BU' }}>
                                          <span className="label label-success">
                                            <i class="fal fa-donate" />
                                          </span>{' '}
                                          GTX BU
                                        </MenuItem>
                                        <MenuItem eventKey={{ value: 4, name: 'Key Account' }}>
                                          <span className="label label-primary">
                                            <i class="fal fa-gem" />
                                          </span>{' '}
                                          Key Account
                                        </MenuItem>
                                      </DropdownButton>
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.customer_service_type}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              customer_service_type: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="customer_service_type"
                                      >
                                        <option value="">ทั้งหมด</option>
                                        <option value="FTL">FTL</option>
                                        <option value="PARCEL">PARCEL</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_email}
                                        placeholder="ค้นหาอีเมล"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_email: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_email"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_phone_number}
                                        placeholder="ค้นหาเบอร์โทรศัพท์"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_phone_number: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_phone_number"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.account_info_status}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_status: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_status"
                                      >
                                        <option value={0}>ทั้งหมด</option>
                                        <option value={1}>ไม่บล๊อค</option>
                                        <option value={2}>บล๊อค</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.account_info_register_channel}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_register_channel: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_register_channel"
                                      >
                                        <option value="0">ทั้งหมด</option>
                                        <option value="1">App</option>
                                        <option value="2">Web</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker(event, picker, refetch)
                                        }
                                        onCancel={() =>
                                          this.setState(
                                            { create_date: '', show_create_date: '' },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.show_create_date}
                                          placeholder="ค้นหาวันที่สมัคร"
                                          name="create_date"
                                        />
                                      </DateRangePicker>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading || error || !data ? (
                                    <tr>
                                      <td colSpan="9" className="textCenter">
                                        {loading ? 'Loading...' : error.message}
                                      </td>
                                    </tr>
                                  ) : data.accountInfoList.account_info.length > 0 ? (
                                    data.accountInfoList.account_info.map((prop, key) => (
                                      <tr key={key}>
                                        <td>
                                          <a
                                            style={{ marginRight: '10px', float: 'left' }}
                                            href={`/shipperdetail?id=${prop._id}`}
                                            className="btn btn-default"
                                          >
                                            <i className="fal fa-search"></i>
                                          </a>
                                        </td>
                                        <td>CU{prop.account_info_number}</td>
                                        <td>{prop.account_info_business_name}</td>
                                        <td>
                                          {this.renderCustomerType(prop.account_info_customer_type)}
                                        </td>
                                        <td>
                                          {prop?.customer_service_type?.length > 0
                                            ? prop.customer_service_type.join(', ')
                                            : '-'}
                                        </td>
                                        <td>{prop.account_info_email}</td>
                                        <td>
                                          {prop.account_info_phone_code
                                            ? `(${prop.account_info_phone_code})`
                                            : ''}{' '}
                                          {prop.account_info_phone_number}
                                        </td>
                                        {/* <td style={{ width: "130px" }}>{prop.account_info_business_type.th}</td>*/}
                                        <td style={{ width: '130px' }}>
                                          <OverlayTrigger placement="top" overlay={tooltip}>
                                            {prop.account_info_status == 1 ? (
                                              <Button bsStyle="link">
                                                {' '}
                                                ไม่บล๊อค{' '}
                                                <i
                                                  style={{ color: '#CCCCCC' }}
                                                  className="fas fa-check-circle"
                                                ></i>
                                              </Button>
                                            ) : (
                                              <Button bsStyle="link" style={{ color: '#ED1C24' }}>
                                                {' '}
                                                บล๊อค <i className="fas fa-times-circle"></i>
                                              </Button>
                                            )}
                                          </OverlayTrigger>
                                        </td>
                                        <td style={{ width: '130px' }}>
                                          {this.renderChannel(prop.account_info_register_channel)}
                                        </td>
                                        <td style={{ width: '130px' }}>
                                          <Moment format="DD/MM/YYYY HH:mm">
                                            {prop.create_date}
                                          </Moment>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="9" className="textCenter">
                                        No Data
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        }
                      />

                      <div style={{ textAlign: 'right' }}>
                        {loading || error || !data ? null : (
                          <ReactPaginate
                            pageCount={data.accountInfoList.total_page}
                            forcePage={this.state.page_number - 1}
                            containerClassName="pagination"
                            nextLabel=">"
                            previousLabel="<"
                            onPageChange={({ selected }) => {
                              this.nextPage(selected, data.accountInfoList.total_page, refetch);
                            }}
                          />
                        )}
                      </div>

                      <Modal
                        show={this.state.showReport}
                        onHide={() => this.setState({ showReport: false })}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>รายงานรายชื่อลูกค้า</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Row style={{ marginBottom: '10px' }}>
                            <Col md={12}> ช่วงวันที่</Col>
                          </Row>
                          <FormGroup controlId="formValidationError1">
                            <DateRangePicker
                              onApply={(event, picker) =>
                                this.handleDateRangePickerReport(event, picker)
                              }
                              onCancel={() =>
                                this.setState({ reportDate: '', show_reportDate: '' })
                              }
                              containerStyles={{ display: 'block' }}
                              opens="right"
                              format="DD/MM/YYYY"
                            >
                              <FormControl
                                type="text"
                                value={this.state.show_reportDate}
                                placeholder="ค้นหาวันที่สมัคร"
                                name="reportDate"
                              />
                            </DateRangePicker>
                          </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button bsStyle="primary" onClick={() => this.exportReportShipper()}>
                            ดาวน์โหลดรายงาน
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  );
                }}
              </Query>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default compose(graphql(adminName, { name: 'adminName' }))(Shipper);
