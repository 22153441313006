import gql from 'graphql-tag';

export default gql`
mutation update(
    $admin_username : String!
    $admin_password : String!
    $signin_from: Int!
){
    adminSignin(
      admin_username: $admin_username,
      admin_password:$admin_password,
      signin_from: $signin_from
    ){
      admin_token_key
    }
  }
`;