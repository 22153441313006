import styled from 'styled-components';
import CardTripStatus from './CardTripStatus';
import CardLargeNumber from './CardLargeNumber';
import CardTripCounter from './CardTripCounter';
import CardIcon from './CardIcon';
import { Card } from './components';
import QRCodeCheckin from './QRCheckin';

export const Container = styled.div`
  height: 100vh;
  background-color: #e0e0e0;
  white-space: nowrap;
`;

export const ContentContainer = styled.div`
  padding: 10px;
`;

export const TripStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  flex: 1;
`;

export const StyledCardTripStatus = styled(CardTripStatus)`
  border: 0.5px solid #000000;

  ${props =>
    props.count > 100 &&
    `
    height: 16.6%;
  `}

  ${props =>
    props.count > 120 &&
    `
    height: 14.4%;
  `}
`;

export const StyledCardTruckStatusWrapper = styled(Card)`
  height: calc(50vh - 10vh - 10px);
  padding: 16px;
  margin-bottom: 10px;
`;

export const CardIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 0.2vw solid #e5e5e5;
  border-radius: 8px;
  height: calc(35% - 20px);
  padding: 10px;
`;

export const StyledCardTripStatusWrapper = styled(Card)`
  height: calc(50vh - 20px);
  padding: 16px;
  background-color: #000000;
  display: flex;
  flex-direction: row;
`;

export const CardLargeNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 55%;
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledCardLargeNumber = styled(CardLargeNumber)`
  &:not(:first-child) {
    margin-left: 10px;
  }
`;

export const StyledCardIcon = styled(CardIcon)`
  height: 100%;

  &:not(:last-child) {
    border-right: 0.2vw solid #e5e5e5;
  }
`;

export const TripStatusCounterContainer = styled.div`
  width: calc(20% - 10px);
  margin-right: 10px;
`;

export const StyledCardTripCounter = styled(CardTripCounter)`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const TextCountDown = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
  font-size: 16px;
  width: 20px;
  text-align: center;
`;

export const StyledQRCode = styled(QRCodeCheckin)`
  margin-left: 10px;
`