import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;
const InputBox = styled.textarea`
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #e3e3e3;
  padding: 10px 12px 0;
  line-height: 1;
  height: 100px;
  background-color: #ffffff;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 10%;

  &:focus {
    outline: none;
  }

  &:disabled {
    ${props =>
      props.disabled &&
      `
      background-color: #F9F9F9;
    `}
  }

  ${props =>
    props.isBorderValidated &&
    `
    border: 1px solid #FF0000;
  `}

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b3b3b3;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b3b3b3;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b3b3b3;
  }
`;

const MessageError = styled.div`
  font-size: 16px;
  color: #ff0000;
  height: 22px;
  text-align: right;
`;

const Label = styled.div`
  font-size: 16px;
  font-weight: bold;

  ${props =>
    props.messageError &&
    `
    color: #FF0000;
  `}
`;

const Required = styled.span`
  color: #d90101;
`;

class Input extends React.Component {
  render() {
    return (
      <Container style={this.props.style}>
        {this.props.inputLabel ? (
          <Label messageError={this.props.messageError}>
            {this.props.inputLabel}
            {this.props.required && <Required>*</Required>}
          </Label>
        ) : null}
        <InputBox
          ref={this.props.inputRef}
          onBlur={this.props.onBlur}
          onKeyPress={this.props.onKeyPress}
          name={this.props.name}
          style={this.props.styleInput}
          onChange={this.props.onChange}
          value={this.props.value}
          isBorderValidated={this.props.messageError}
          placeholder={this.props.placeholder}
          type={this.props.type}
          disabled={this.props.disabled}
        />
        {this.props.messageError && <MessageError>{this.props.messageError}</MessageError>}
      </Container>
    );
  }
}

export default Input;
