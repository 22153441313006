import React from 'react';
import styled from 'styled-components';

const ListItemStyled = styled.div`
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  & > div:nth-child(1) {
    padding: 14px;
  }

  & > div:nth-child(2) {
    display: ${props => (props.isOpen ? 'block' : 'none')};
  }

  & > div:nth-child(1) {
    border: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    justify-content: space-between;
    cursor: pointer;

    ${props =>
      props.isOpen &&
      `
    background-color: #f2f2f2;
    border-bottom: 1px solid #e8e8e8;
    `}

    &:hover {
      background-color: #f2f2f2;
    }

    & > div:nth-child(2) {
      display: flex;
      align-items: center;

      & > i {
        margin-left: 10px;
        min-width: 10px;
        color: #b9b9b9;
        font-size: 16px;
      }
    }
  }
`;

const ListItem = props => {
  const { header, textRight, children, isOpen, onClick } = props;
  return (
    <ListItemStyled isOpen={isOpen}>
      <div onClick={onClick}>
        <div>{header}</div>
        <div>
          {textRight} <i className={`far fa-angle-${isOpen ? 'down' : 'right'}`}></i>
        </div>
      </div>

      <div>{children}</div>
    </ListItemStyled>
  );
};

export default ListItem;
