import gql from 'graphql-tag';

export default gql`
query accountInfoList(
  $_id: ID
  $account_info_customer_type: Int
){
  accountInfoList(
  _id: $_id
  account_info_customer_type: $account_info_customer_type
  ){
    account_info{
      _id
      account_info_status
      account_info_email
      account_info_customer_type
      account_info_business_name
      account_users{
        _id
        account_user_name
        account_user_status
      }
    }
  }
}

`;