import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { compose, Query, graphql } from 'react-apollo';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  Modal,
  FormGroup,
  FormControl,
  Label,
  NavDropdown,
  NavItem,
} from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import CardTable from 'components/CardTable/CardTable.jsx';
import { Layout, ModalConfirm } from '../../components';
import classesShopee from '../../Shopee.module.css';
import s3 from 'config/s3';
import reportUrl from 'config/reportUrl';
import { UploadFile } from './container';
import adminListQuery from './graphql/query/adminList';
import { client } from '../../../../index';
import lazadaImportOrderListQuery from './graphql/query/lazadaImportOrderList';
import lazadaImportOrderUpdateMutation from './graphql/mutation/lazadaImportOrderUpdate';
import updateTrackingBySummaryMutation from './graphql/mutation/updateTrackingBySummary';
import shopeeUpdataTrackingManual from './graphql/mutation/shopeeUpdataTracking';
import customerId from 'config/customerId';

const NavDropdownContainer = styled(NavDropdown)`
  list-style: none;
  float: left;
  margin-left: 5px;

  & a {
    & .caret {
      display: none;
    }

    & button.btn.btn-default {
      float: left;
      padding: 5px 8px;
    }
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const NavItemDropDownList = styled(NavItem)``;

const NavItemA = styled.li`
  & a {
    padding: 8px 16px;
    color: #333333;
    display: block;
    clear: both;
    font-weight: 400;
    line-height: 1;
    white-space: nowrap;
  }
`;

class Shipments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: '',
      fileStatus: 0,
      createDate: '',
      displayCreateDate: '',
      pickupDate: '',
      displayPickupDate: '',
      transferDate: '',
      displayTransferDate: '',
      pageNumber: 1,
      isOpenModalConfirmCancel: false,
      isOpenModalConfirmDownloadReport: false,
      isOpenModalConfirmDownloadIdReport: false,

      // select file cancel
      fileNameBookingFile: '',
      idBookingFile: null,
      isLoading: false,
      showImportModal: false,
      importFile: null,
      importDate: '',
      show_importDate: '',
    };
    this._inputImportFile = React.createRef();
    this._inputImportFile1 = React.createRef();
  }

  renderStatus = status => {
    switch (status) {
      case 4:
        return <Label bsStyle="primary">รอเริ่มงาน</Label>;
      case 5:
        return (
          <Label
            bsStyle="info"
            style={{
              backgroundColor: 'transparent',
              color: '#337ab7',
              border: '1px solid #337ab7',
            }}
          >
            กำลังดำเนินงาน...
          </Label>
        );
      case 6:
        return <Label bsStyle="success">เสร็จสิ้นแล้ว</Label>;
      case 7:
        return (
          <Label
            bsStyle="success"
            style={{
              backgroundColor: 'transparent',
              color: '#5cb85c',
              border: '1px solid #5cb85c',
            }}
          >
            ส่ง Report แล้ว
          </Label>
        );
      case 8:
        return <Label bsStyle="success">โอนเงิน Shopee แล้ว</Label>;
      case 9:
        return <Label bsStyle="danger">ยกเลิก</Label>;
      default:
        return <Label>{status}</Label>;
    }
  };

  handleDateRangePicker = (mode, picker) => {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;

    const display = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;

    if (mode === 'createDate') {
      this.setState({ createDate: date, displayCreateDate: display });
    } else if (mode === 'pickupDate') {
      this.setState({ pickupDate: date, displayPickupDate: display });
    } else if (mode === 'transferDate') {
      this.setState({ transferDate: date, displayTransferDate: display });
    }
  };
  uploadFileHandler = async file => {
    try {
      await this.props
        .updateTrackingBySummaryMutation({
          variables: {
            path: file.path,
          },
          refetchQueries: ['lazadaImportOrderListQuery'],
        })
        .then(({ data }) => {
          if (data.updateTrackingBySummary.succeed) {
            this.setState({
              showImportModal: false,
              importFile: null,
              importDate: '',
              show_importDate: '',
            });
          }
        });
    } catch (error) {
      this.setState({
        showImportModal: false,
        importFile: null,
        importDate: '',
        show_importDate: '',
      });

      alert(error);
    }
  };
  updataTracking = async () => {
    try {
      this.setState({ isLoading: true });
      const { idBookingFile } = this.state;

      await this.props
        .shopeeUpdataTrackingManual({
          variables: {
            order_id: idBookingFile,
          },
          refetchQueries: ['LazadaImportOrderListShipments'],
        })
        .then(({ data }) => {
          if (data.shopeeUpdataTrackingManual.succeed) {
            this.setState({
              isLoading: false,
              isOpenModalConfirmTracking: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmTracking: false,
      });

      alert(error);
    }
  };
  cancelShipmentLazada = async () => {
    try {
      this.setState({ isLoading: true });

      const { idBookingFile } = this.state;
      const { data } = await client.query({
        query: lazadaImportOrderListQuery,
        variables: {
          _id: idBookingFile,
        },
      });

      const { order } = data.lazadaImportOrderList;

      if (order[0].status !== 4) {
        alert('ไม่สามารถยกเลิกรายการได้');
        this.setState({ isLoading: false });
        return;
      }

      await this.props
        .lazadaImportOrderUpdateMutation({
          variables: {
            _id: idBookingFile,
            status: 9,
          },
          refetchQueries: ['LazadaImportOrderListShipments'],
        })
        .then(({ data }) => {
          if (data.lazadaImportOrderUpdate._id) {
            this.setState({
              isLoading: false,
              isOpenModalConfirmCancel: false,
            });
          }
        });
    } catch (error) {
      this.setState({
        isLoading: false,
        isOpenModalConfirmCancel: false,
      });

      alert(error);
    }
  };

  downloadReportHandler = async () => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const { idBookingFile } = this.state;
      const adminId = admin[0]._id;
      const url = `${reportUrl}/lazada-summary?pick_up_date=${this.state.reportDate}&admin_id=${adminId}&shipper=${customerId.shopee}`;
      window.open(url, '_blank');
      this.setState({
        isOpenModalConfirmDownloadReport: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  downloadReportByIdHandler = async () => {
    try {
      const { data } = await client.query({
        query: adminListQuery,
      });

      const { admin } = data.adminList;

      if (admin.length === 0) {
        alert('ไม่พบ Admin');
        return;
      }

      const { idBookingFile } = this.state;
      const adminId = admin[0]._id;
      const url = `${reportUrl}/lazada-summary?id=${idBookingFile}&admin_id=${adminId}`;
      window.open(url, '_blank');
      this.setState({
        isOpenModalConfirmDownloadIdReport: false,
      });
    } catch (error) {
      alert(error);
    }
  };

  formatMoney = number => {
    return `฿ ${number.toLocaleString('en-US', {
      maximumFractionDigits: 2,
    })}`;
  };

  openReportShipment(mode) {
    this.setState({
      isOpenModalConfirmDownloadReport: true,
      modeReport: mode,
      reportDate: '',
      show_reportDate: '',
    });
  }

  handleDateRangePickerReport(event, picker) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ reportDate: date, show_reportDate: show });
  }

  render() {
    let adminName = '';
    if (
      this.props.adminListQuery &&
      this.props.adminListQuery.loading == false &&
      this.props.adminListQuery.adminList != undefined
    ) {
      adminName = this.props.adminListQuery.adminList.admin[0].admin_username;
    }
    return (
      <Layout>
        <Row>
          <Col md={12}>
            <div>
              <Query
                query={lazadaImportOrderListQuery}
                variables={{
                  name: this.state.fileName,
                  status: this.state.fileStatus,
                  pick_up_date: this.state.pickupDate,
                  create_date: this.state.createDate,
                  transfer_date: this.state.transferDate,
                  create_shipment: 2,
                  page_number: this.state.pageNumber,
                  account_info_id: customerId.shopee,
                }}
                fetchPolicy="cache-and-network"
              >
                {({ data, loading, error, refetch: refetchLazadaImportOrderList }) => {
                  //console.log("lazadaImportOrderListQuery",data)
                  var itemLength = 'loading';
                  const itemIndicatorSize = (this.state.pageNumber - 1) * 30;
                  if (data.lazadaImportOrderList) {
                    if (data.lazadaImportOrderList.order.length > 0) {
                      itemLength = data.lazadaImportOrderList.order.length;
                    }
                  }
                  return (
                    <CardTable
                      title="ตาราง Booking file"
                      category="*ต้อง Import file booking ที่ แถบ Booking file ก่อน สร้าง Shipment ทุกครั้ง"
                      ctTableFullWidth
                      ctTableResponsive
                      rightButton={
                        adminName !== 'shopee' && (
                          <div style={{ float: 'right', marginLeft: '15px' }}>
                            <Button
                              style={{
                                background: '#87CB16',
                                color: '#ffffff',
                                marginRight: '15px',
                              }}
                              onClick={() => {
                                this.setState({
                                  showImportModal: true,
                                  importFile: null,
                                  importDate: '',
                                  show_importDate: '',
                                });
                              }}
                            >
                              <i className="fas fa-cloud-upload"></i> อัพโหลด Tracking
                            </Button>
                            <Button
                              style={{ marginLeft: '15px' }}
                              onClick={() => this.openReportShipment('booking')}
                            >
                              {' '}
                              <i className="fal fa-clipboard-list"></i> รายงานงานขนส่ง{' '}
                            </Button>
                          </div>
                        )
                      }
                      content={
                        <div>
                          <PageWrapper style={{ textAlign: 'right' }}>
                            <div className="text">
                              {this.state.pageNumber === 1
                                ? `1 - ${itemLength}`
                                : `${itemIndicatorSize + 1} - ${itemLength +
                                    itemIndicatorSize}`}{' '}
                              หน้า :
                            </div>
                            <FormControl
                              type="number"
                              min={1}
                              value={this.state.pageNumber}
                              onChange={event =>
                                Number(event.target.value) >= 0
                                  ? setInterval(
                                      this.setState({
                                        pageNumber: Number(event.target.value),
                                      }),
                                      1000
                                    )
                                  : setInterval(this.setState({ pageNumber: 1 }), 1000)
                              }
                              name="page_number"
                            />
                            <div className="text">
                              {' '}
                              {loading || error
                                ? ''
                                : `จาก ${data.lazadaImportOrderList.total_page}`}
                            </div>
                          </PageWrapper>
                          <div className="table-vertical">
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th style={{ minWidth: '140px' }}>เครื่องมือ</th>
                                  <th>mode</th>
                                  <th style={{ minWidth: '200px' }}>ชื่อไฟล์</th>
                                  <th style={{ minWidth: '100px' }}>วันที่รับสินค้า</th>
                                  <th style={{ minWidth: '140px' }}>สถานะ</th>
                                  <th style={{ minWidth: '120px' }}>จำนวน Trip</th>
                                  <th style={{ minWidth: '100px' }}>COD รับแล้ว</th>
                                  <th style={{ minWidth: '130px' }}>COD ยังไม่ได้รับ</th>
                                  <th style={{ minWidth: '100px' }}>COD ยกเลิก</th>
                                  <th style={{ minWidth: '100px' }}>COD ทั้งหมด</th>
                                  <th style={{ minWidth: '120px' }}>วันที่โอนเงิน</th>
                                  <th style={{ minWidth: '120px' }}>วันที่ Import file</th>
                                </tr>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th>
                                    <FormControl
                                      type="text"
                                      placeholder="ค้นหาชื่อไฟล์"
                                      name="file_name"
                                      value={this.state.fileName}
                                      onChange={event => {
                                        this.setState({
                                          fileName: event.target.value,
                                          pageNumber: 1,
                                        });
                                      }}
                                    />
                                  </th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker('pickupDate', picker)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          pickupDate: '',
                                          displayPickupDate: '',
                                          pageNumber: 1,
                                        })
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.displayPickupDate}
                                        placeholder="วันที่รับสินค้า"
                                      />
                                    </DateRangePicker>
                                  </th>
                                  <th>
                                    <FormControl
                                      componentClass="select"
                                      placeholder="select"
                                      defaultValue={this.state.fileStatus}
                                      onChange={event =>
                                        this.setState({
                                          fileStatus: event.target.value,
                                          pageNumber: 1,
                                        })
                                      }
                                      name="shipment_status"
                                    >
                                      <option value={0}>ทั้งหมด</option>
                                      <option value={4}>รอเริ่มงาน</option>
                                      <option value={5}>กำลังดำเนินงาน...</option>
                                      <option value={6}>ขนส่งเสร็จสิ้น</option>
                                      <option value={7}>ส่ง Report แล้ว</option>
                                      <option value={8}>โอนเงิน Shopee แล้ว</option>
                                      <option value={9}>ยกเลิก</option>
                                    </FormControl>
                                  </th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th></th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker('transferDate', picker)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          transferDate: '',
                                          displayTransferDate: '',
                                          pageNumber: 1,
                                        })
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.displayTransferDate}
                                        placeholder="วันที่โอนเงิน"
                                      />
                                    </DateRangePicker>
                                  </th>
                                  <th>
                                    <DateRangePicker
                                      onApply={(event, picker) =>
                                        this.handleDateRangePicker('createDate', picker)
                                      }
                                      onCancel={() =>
                                        this.setState({
                                          createDate: '',
                                          displayCreateDate: '',
                                          pageNumber: 1,
                                        })
                                      }
                                      containerStyles={{ display: 'block' }}
                                      opens="right"
                                      format="DD/MM/YYYY"
                                    >
                                      <FormControl
                                        type="text"
                                        value={this.state.displayCreateDate}
                                        placeholder="วันที่ Import file"
                                      />
                                    </DateRangePicker>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading && (
                                  <tr>
                                    <td colSpan="12">Loading...</td>
                                  </tr>
                                )}

                                {error && (
                                  <tr>
                                    <td colSpan="12">Error</td>
                                  </tr>
                                )}

                                {!loading &&
                                  !error &&
                                  data.lazadaImportOrderList.order.length === 0 && (
                                    <tr>
                                      <td colSpan="12">ไม่มีข้อมูล</td>
                                    </tr>
                                  )}

                                {!loading &&
                                  !error &&
                                  data.lazadaImportOrderList.order.length > 0 &&
                                  data.lazadaImportOrderList.order.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={<Tooltip id="tooltip">ดูข้อมูล</Tooltip>}
                                        >
                                          <Link
                                            to={`/shopee-shipment-list?id=${item._id}`}
                                            style={{ float: 'left' }}
                                            className="btn btn-default"
                                          >
                                            <i
                                              style={{ marginTop: '3px' }}
                                              className="fas fa-eye"
                                            ></i>
                                          </Link>
                                        </OverlayTrigger>
                                        {adminName !== 'shopee' && (
                                          <NavDropdownContainer
                                            title={
                                              <Button>
                                                เครื่องมือ <i className="fal fa-angle-down"></i>
                                              </Button>
                                            }
                                          >
                                            {/* <NavItemDropDownList disabled>
                                            <i className="fas fa-bell"></i> แจ้งโอนเงินให้ LAZADA
                                          </NavItemDropDownList> */}
                                            <NavItemDropDownList
                                              onClick={() => {
                                                this.setState({
                                                  isOpenModalConfirmDownloadIdReport: true,
                                                  fileNameBookingFile: item.name,
                                                  idBookingFile: item._id,
                                                });
                                              }}
                                            >
                                              <i className="fas fa-file-alt"></i> รายงานงานขนส่ง
                                            </NavItemDropDownList>

                                            <NavItemDropDownList
                                              onClick={() => {
                                                this.setState({
                                                  isOpenModalConfirmTracking: true,
                                                  fileNameBookingFile: item.name,
                                                  idBookingFile: item._id,
                                                });
                                              }}
                                            >
                                              <i class="fal fa-arrow-circle-up"></i>{' '}
                                              อัพเดตสถานะงานขนส่ง
                                            </NavItemDropDownList>

                                            {item.document_driver && (
                                              <NavItemA>
                                                <a
                                                  href={`${s3}/${item.document_driver}`}
                                                  download={`ใบงาน-${item.name}`}
                                                >
                                                  <i className="fal fa-file-download"></i>{' '}
                                                  ดาวน์โหลดใบงาน
                                                </a>
                                              </NavItemA>
                                            )}

                                            {item.file && (
                                              <NavItemA>
                                                <a href={`${s3}/${item.file}`} download={item.name}>
                                                  <i className="fal fa-file-archive"></i>{' '}
                                                  ดาวน์โหลดไฟล์ต้นฉบับ
                                                </a>
                                              </NavItemA>
                                            )}

                                            {item.status === 4 && ( //รอเริ่มงาน
                                              <NavItemDropDownList
                                                onClick={() => {
                                                  this.setState({
                                                    isOpenModalConfirmCancel: true,
                                                    fileNameBookingFile: item.name,
                                                    idBookingFile: item._id,
                                                  });
                                                }}
                                              >
                                                <i className="fas fa-times"></i> ยกเลิก
                                              </NavItemDropDownList>
                                            )}
                                          </NavDropdownContainer>
                                        )}
                                      </td>
                                      <td>
                                        {item.order_type == 1 ? (
                                          <span class="label label-success">NORMAL</span>
                                        ) : item.order_type == 2 ? (
                                          <span class="label label-danger">FAILED</span>
                                        ) : null}
                                      </td>
                                      <td>{item.name}</td>
                                      <td
                                        className={`${classesShopee.textDanger} ${classesShopee.textBold}`}
                                      >
                                        <Moment format="DD/MM/YYYY HH:mm:ss">
                                          {item.pick_up_date}
                                        </Moment>
                                      </td>
                                      <td>{this.renderStatus(item.status)}</td>
                                      <td>{item.total_shipment} Trip</td>
                                      <td>{this.formatMoney(item.total_accept_cod)}</td>
                                      <td>
                                        {this.formatMoney(
                                          item.total_price_cod -
                                            item.total_cancel_cod -
                                            item.total_accept_cod
                                        )}
                                      </td>
                                      <td>{this.formatMoney(item.total_cancel_cod)}</td>
                                      <td>{this.formatMoney(item.total_price_cod)}</td>
                                      <td
                                        className={`${classesShopee.textBold} ${classesShopee.textSuccess}`}
                                      >
                                        {item.transfer_date ? (
                                          <Moment format="DD/MM/YYYY">{item.transfer_date}</Moment>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        {item.create_date ? (
                                          <Moment format="DD/MM/YYYY">{item.create_date}</Moment>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          </div>
                          <div style={{ textAlign: 'right', marginTop: 20 }}>
                            {!loading && !error && (
                              <ReactPaginate
                                pageCount={data.lazadaImportOrderList.total_page}
                                forcePage={this.state.pageNumber - 1}
                                containerClassName="pagination"
                                nextLabel=">"
                                previousLabel="<"
                                onPageChange={({ selected }) => {
                                  this.setState({ pageNumber: selected + 1 });
                                }}
                              />
                            )}
                          </div>
                        </div>
                      }
                    />
                  );
                }}
              </Query>
            </div>

            <Modal
              show={this.state.showImportModal}
              onHide={() => this.setState({ showImportModal: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>อัพโหลด Tracking</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={12}>Tracking file :</Col>
                </Row>
                <UploadFile
                  completed={file => {
                    this._inputImportFile1.current.value = null;
                    this.setState({ importFile: file });
                  }}
                >
                  {selectFile => {
                    return (
                      <React.Fragment>
                        <input
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          onChange={event => {
                            selectFile(event, `upload/jdProject/document_${new Date().getTime()}`);
                          }}
                          type="file"
                          id="file"
                          ref={this._inputImportFile1}
                          style={{ display: 'none' }}
                        />
                        <Button
                          style={{ marginRight: '15px' }}
                          onClick={() => this._inputImportFile1.current.click()}
                        >
                          <i className="fas fa-cloud-upload"></i> อัพโหลด Tracking
                        </Button>
                        <span>{this.state.importFile ? this.state.importFile.fileName : ''}</span>
                      </React.Fragment>
                    );
                  }}
                </UploadFile>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={this.state.importFile == null}
                  style={{
                    background: '#87CB16',
                    color: '#ffffff',
                    marginRight: '15px',
                  }}
                  onClick={() =>
                    this.uploadFileHandler(this.state.importFile, lazadaImportOrderListQuery)
                  }
                >
                  อัพโหลด Tracking
                </Button>
              </Modal.Footer>
            </Modal>

            <ModalConfirm
              show={this.state.isOpenModalConfirmCancel}
              onPressButtonConfirm={this.cancelShipmentLazada}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'ยืนยัน...' : 'ยืนยัน'}
              labelButtonCancel={`ปิด`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmCancel: false })}
              titleHeader={`คุณต้องการยกเลิกงานขนส่ง(Shipment) นี้ ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmCancel: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            <ModalConfirm
              show={this.state.isOpenModalConfirmDownloadIdReport}
              onPressButtonConfirm={this.downloadReportByIdHandler}
              styleColorButtonConfirm="primary"
              labelButtonConfirm="ดาวน์โหลด"
              labelButtonCancel="ยกเลิก"
              onPressButtonCancel={() =>
                this.setState({ isOpenModalConfirmDownloadIdReport: false })
              }
              titleHeader={`Download Report ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmDownloadIdReport: false })}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            <ModalConfirm
              show={this.state.isOpenModalConfirmTracking}
              onPressButtonConfirm={this.updataTracking}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'ยืนยัน...' : 'ยืนยัน'}
              labelButtonCancel={`ปิด`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmTracking: false })}
              titleHeader={`คุณต้องการที่จะอัพเดตสถานะงานขนส่งนี้ ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmTracking: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                File Name: <strong>{this.state.fileNameBookingFile}</strong>
              </div>
            </ModalConfirm>

            <ModalConfirm
              show={this.state.isOpenModalConfirmDownloadReport}
              onPressButtonConfirm={this.downloadReportHandler}
              styleColorButtonConfirm="primary"
              labelButtonConfirm={this.state.isLoading ? 'ดาวน์โหลด...' : 'ดาวน์โหลด'}
              labelButtonCancel={`ยกเลิก`}
              onPressButtonCancel={() => this.setState({ isOpenModalConfirmDownloadReport: false })}
              titleHeader={`Download Report ใช่หรือไม่?`}
              onHide={() => this.setState({ isOpenModalConfirmDownloadReport: false })}
              disabledButtonConfirm={this.state.isLoading}
            >
              <div style={{ lineHeight: 1 }}>
                <Row style={{ marginBottom: '10px' }}>
                  <Col md={12}> ช่วงวันที่</Col>
                </Row>
                <FormGroup controlId="formValidationError1">
                  <DateRangePicker
                    onApply={(event, picker) => this.handleDateRangePickerReport(event, picker)}
                    onCancel={() => this.setState({ reportDate: '', show_reportDate: '' })}
                    containerStyles={{ display: 'block' }}
                    opens="right"
                    format="DD/MM/YYYY"
                  >
                    <FormControl
                      type="text"
                      value={this.state.show_reportDate}
                      placeholder="ค้นหาวันที่"
                      name="reportDate"
                    />
                  </DateRangePicker>
                </FormGroup>
              </div>
            </ModalConfirm>
          </Col>
        </Row>
      </Layout>
    );
  }
}

export default compose(
  graphql(lazadaImportOrderUpdateMutation, {
    name: 'lazadaImportOrderUpdateMutation',
  }),
  graphql(shopeeUpdataTrackingManual, {
    name: 'shopeeUpdataTrackingManual',
  }),
  graphql(updateTrackingBySummaryMutation, {
    name: 'updateTrackingBySummaryMutation',
  }),
  graphql(adminListQuery, { name: 'adminListQuery' })
)(Shipments);
