import React from "react";
import classes from "./ShipmentDetail.module.scss";
import cn from "../../ShipmentDetail.module.scss";
import Moment from "react-moment";
import { Row, Col } from "react-bootstrap";
import { compose, Query, Mutation, graphql } from "react-apollo";
import shipmentListQuery from "./graphql/query/shipmentList";
import accountInfoListQuery from "./graphql/query/accountInfoList";

class ShipmentDetail extends React.Component {
  getPickupCommodityAccept = (index, pickup) => {
    if (pickup.length === 0) {
      return '-';
    }

    const count = pickup[index].commodity.reduce((total, item) => {
      return item.accept === 1 ? 1 + total : total;
    }, 0);

    const total = pickup[index].commodity.length;
    return `${count} จาก ${total} รายการ`;
  };

  render() {
    return (
      <div className={classes.container}>
        <div className={cn.ShipmentHeader}>รายละเอียดค่าขนส่ง</div>
        <Query
          query={shipmentListQuery}
          variables={{
            _id: this.props.shipmentId
          }}
          fetchPolicy="cache-and-network"
        >
          {({ data, loading, error }) => {
            if (loading) return "loading...";
            if (error) return error;

            const { shipmentList } = data;
            const shipment = shipmentList.shipment[0];

            return (
              <Row>
                <Col md={12}>
                  <div className={cn.RowItem}>
                    <div className={cn.Left}>รหัสงานขนส่ง:</div>
                    <div className={cn.Right}>จองเมื่อ:</div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>
                      <span className={cn.textBold}>
                        {`${shipment.shipment_number}`}
                      </span>
                    </div>
                    <div className={cn.Right}>
                      <span className={cn.textBold}>
                        <Moment format="DD/MM/YYYY HH:mm">
                          {shipment.create_date}
                        </Moment>
                      </span>
                    </div>
                  </div>

                  <div className={`${cn.BoxWrapper} ${cn.mt10} ${cn.mb10}`}>
                    <div className={cn.InnerWrapper}>
                      <div className={cn.RowItem}>
                        <div className={cn.Left}>ผู้ให้บริการขนส่ง:</div>
                      </div>
                      <div className={cn.RowItem} style={{ paddingBottom: 0 }}>
                        <div className={cn.Left}>
                          <span className={cn.textBold}>
                            บริษัท จิซทิกซ์ จำกัด
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={cn.RowItem}>
                    <div className={cn.Left}>ลูกค้า:</div>
                    <div className={cn.Right}>
                      <span className={cn.textBold}>
                        {shipment.shipper_detail}
                      </span>
                    </div>
                  </div>

                  <Query
                    query={accountInfoListQuery}
                    variables={{
                      _id: shipment.shipper
                    }}
                  >
                    {({ loading, error, data, variables }) => {
                      if (loading || error) return null;
                      return (
                        <div className={cn.RowItem}>
                          <div className={cn.Left}>เบอร์โทรติดต่อลูกค้า:</div>
                          <div className={cn.Right}>
                            <span
                              className={cn.textBold}
                            >{`(${data.accountInfoList.account_info[0].account_info_phone_code}) ${data.accountInfoList.account_info[0].account_info_phone_number}`}</span>
                          </div>
                        </div>
                      );
                    }}
                  </Query>

                  <div className={`${cn.RowItem} ${cn.mb10}`}>
                    <div className={cn.Left}>ชื่อผู้จอง:</div>
                    <div className={cn.Right}>
                      <span className={cn.textBold}>
                        {shipment.account_user_display_name}
                      </span>
                    </div>
                  </div>

                  <div className={cn.ShipmentItem}>
                    <div className={cn.Icon}>
                      <i className="fal fa-truck"></i>
                    </div>
                    <div className={cn.Detail}>
                      <span>ประเภทรถขนส่ง</span>
                      <div className={cn.textBold}>
                        {`${shipment.setting_truck_name.th} - ${shipment.truck_type_name.th}`}
                      </div>
                    </div>
                  </div>

                  <div className={cn.ShipmentItem}>
                    <div className={cn.Icon}>
                      <i className="fal fa-clock"></i>
                    </div>
                    <div className={cn.Detail}>
                      <span>วันที่เริ่มงานขนส่ง</span>
                      <div className={cn.textBold}>
                        <Moment format="DD/MM/YYYY HH:mm">
                          {shipment.pick_up_date}
                        </Moment>
                      </div>
                    </div>
                  </div>

                  <div className={`${cn.TitleHeader} ${cn.mt20} ${cn.mb10}`}>
                    <div className={cn.Title}>จุดรับ - ส่งสินค้า</div>
                  </div>

                  <div className={cn.ShipmentAddress}>
                    {shipment.shipment_address.map((item, index) => (
                      <div key={index} className={cn.ShipmentList}>
                        {item.mode == "รับสินค้า" ? (
                          <div className={`${cn.ShipmentIcon} ${cn.textBlack}`}>
                            <i className="fas fa-dot-circle"></i>
                          </div>
                        ) : (
                          <div className={`${cn.ShipmentIcon} ${cn.textRed}`}>
                            <i className="fas fa-map-marker-alt"></i>
                          </div>
                        )}

                        <div className={cn.ShipmentDetail}>
                          <div className={`${cn.mb5}`}>
                            จุดที่ {index + 1} {item.mode} - {item.dictrict}
                          </div>
                          <div className={`${cn.textBold} ${cn.mb10}`}>
                            {item.address}
                          </div>
                          <div>รายละเอียดสินค้า</div>
                          <div className={`${cn.textBold} ${cn.mb10}`}>
                            {item.mode === "รับสินค้า" &&
                              this.getPickupCommodityAccept(
                                index,
                                shipment.tracking.tracking_detail_lazada.pick_up
                              )}

                            {item.mode !== "รับสินค้า" &&
                              item.commodity.map(item => item.name).join(", ")}
                          </div>
                          <div>ข้อมูลผู้ติดต่อ</div>
                          <div className={`${cn.textBold} ${cn.mb10}`}>
                            {`${item.contact_name}, ${item.contact_tel}`}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default ShipmentDetail;
