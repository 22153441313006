import React, { Component } from 'react';
import ModalConfirm from './components/ModalConfirm';
import NotificationSystem from 'react-notification-system';
import { Query, graphql, compose, Mutation } from 'react-apollo';
import updateContactShipmentKA from './graphql/mutation/updateContactShipmentKA';
import styled from 'styled-components';

const styleNotification = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },

    error: {
      color: '#FFFFFF',
      backgroundColor: '#FC727A',
      border: '1px solid #FC727A',
      boxShadow: 'none',
      borderRadius: '4px',
    },

    success: {
      color: '#FFFFFF',
      backgroundColor: '#318DE8',
      border: '1px solid #318DE8',
      boxShadow: 'none',
      borderRadius: '4px',
    },
  },
};

const InputBox = styled.input`
  padding: 4px;
  margin-top: 5px;
  width: 50%;
  font-size: 20px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
`;

class ConfirmStatusModal extends Component {
  constructor(props) {
    super(props);
    this.notificationSystem = React.createRef();
    this.state = {
      phoneNumber: this.props.phoneCus,
      phoneValid: false,
    };
  }

  componentDidMount() {
    this.phoneValidation(this.props.phoneCus);
  }

  handlerSubmit = shipmentsUpdate => {
    console.log(this.state.phoneNumber);
    console.log(this.props.shipmentAddressId);
    console.log(this.props.shipmentId);
    shipmentsUpdate({
      variables: {
        params: {
          shipment_id: this.props.shipmentId,
          shipment_address_id: this.props.shipmentAddressId,
        },
        update: {
          contact_tel: this.state.phoneNumber,
        },
      },
    });
  };

  phoneValidation = phone => {
    const regex = RegExp('^[0][0-9]{8,9}$');

    this.setState({
      phoneValid: regex.test(phone),
    });
  };

  addNotification = (typeStatus, message) => {
    const notification = this.notificationSystem.current;
    notification.addNotification({
      message: (
        <span>
          <strong>แจ้งเตือน</strong> {message}
        </span>
      ),
      level: typeStatus,
      position: 'tr',
    });
  };

  render() {
    return (
      <Mutation
        mutation={updateContactShipmentKA}
        update={(cache, { data }) => {
          this.props.closeModal();
        }}
        onError={error => {
          console.log(error);
          this.addNotification('error', error.message.replace('Network error: ', ''));
        }}
        refetchQueries={() => [
          'LazadaShipmentListDetail',
          'LazadaShipmentListDetailSubTracking',
          'LazadaShipmentDetailTracking',
          'LazadaShipmentDetailMain',
        ]}
      >
        {shipmentsUpdate => {
          return (
            <ModalConfirm
              show={this.props.isConfirmStatusModal}
              onPressButtonCancel={this.props.closeModal}
              onPressButtonConfirm={() => this.handlerSubmit(shipmentsUpdate)}
              labelButtonConfirm={`ยืนยัน`}
              labelButtonCancel={`ไม่ใช่`}
              disabledButtonConfirm={!this.state.phoneValid}
              styleColorButtonConfirm="green"
              titleHeader={`แจ้งเตือน`}
              onHide={this.props.closeModal}
            >
              <NotificationSystem ref={this.notificationSystem} style={styleNotification} />
              <div style={{ lineHeight: 1 }}>
                การเปลี่ยนเบอร์โทรติดต่อลูกค้า
                <br />
              </div>
              <InputBox
                value={this.state.phoneNumber}
                placeholder="เบอร์โทรติดต่อลูกค้า"
                onChange={e => {
                  this.phoneValidation(e.target.value);
                  this.setState({ phoneNumber: e.target.value });
                }}
              />
            </ModalConfirm>
          );
        }}
      </Mutation>
    );
  }
}

export default ConfirmStatusModal;
