import gql from 'graphql-tag';

export default gql`
query search(
    $account_info_id: ID
  ){
      waitingMakeSummaryInvoiceList(
         account_info_id 	: $account_info_id
      ){
        list{
          shipment_id
          shipment_number
          shipment_status
          pick_up_date
          total_price
          grand_total_price
          detail
        }
        total_price
        grand_total_price
        bill_date_start
        bill_date_end
        issue_date
        due_date
      }
    }
`;
