import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import {
  Grid,
  Row,
  Col,
  Table,
  Tooltip,
  OverlayTrigger,
  Button,
  FormControl,
  Modal,
} from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import Moment from 'react-moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import CardTable from 'components/CardTable/CardTable.jsx';
import CustomButton from 'components/CustomButton/CustomButton.jsx';
import { DriverArray } from 'variables/Variables.jsx';

import accountInfoListQuery from './graphql/query/accountInfoList';
import driverUserListQuery from './graphql/query/driverUserList';

const tooltip = <Tooltip id="tooltip">แก้ไข</Tooltip>;
const tooltip1 = <Tooltip id="tooltip">บล๊อค / ยกเลิกการบล็อค</Tooltip>;

const Container = styled(Row)`
  & .card_table {
    margin-bottom: 0 !important;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  float: right;

  & .text {
    padding: 5px;
  }
  & .form-control {
    padding: 6px 10px;
    height: 30px;
    width: 70px;
  }
`;

const TabHeaderContainer = styled.div`
  background-color: #ffffff;
  height: 42px;
  border-bottom: 1px solid #efefef;
  display: flex;
  flex-direction: row;
  padding: 10px 0;

  & .sub-header {
    &-left {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  & a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    color: #000000;
    font-size: 18px;

    & i {
      color: #d90101;
      font-size: 6px;
      position: absolute;
      top: 8px;
      right: 6px;
    }
  }

  & .line {
    height: 100%;
    background-color: #e3e3e3;
    width: 1px;
    margin-left: 20px;
    margin-right: 20px;
  }

  & .menu-wrapper {
    & ul {
      padding-left: 0;
      margin: 0;
      display: flex;

      & li {
        list-style: none;
        padding: 10px 15px;
        position: relative;
        margin-right: 10px;
        & a {
          font-size: 18px;
          cursor: pointer;
          color: #808080;
        }
      }
      & li.active {
        border-bottom: 2px solid #d90402;
        & a {
          color: #000000;
        }
      }
    }
  }
`;

const TabContentContainer = styled.div`
  margin-top: 15px;

  & .tab-item {
  }

  & table thead small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table thead span {
    font-size: 85%;
    font-weight: normal;
  }

  & table tbody td {
    font-weight: bold;
  }
  & table tbody small {
    width: 100%;
    float: left;
    font-weight: normal;
  }
  & table tbody span.new {
    font-size: 85%;
    font-weight: normal;
    color: #d90101;
  }
  & table tbody span.label {
    font-size: 85%;
    font-weight: normal;
    float: left;
  }
`;

class Agent3PL extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_page: 0,
      page_number: 1,
      order_by: null,
      order_type: null,
      page_number_agent: 1,
      order_by_agent: null,
      order_type_agent: null,
      showAdd: false,
      showEdit: false,
      driver_name: '',
      driver_phone_code: '66',
      driver_phone_number: '',
      _id: null,
      selectTap: 2,
      search_driver_number: '',
      search_driver_phonenumber: '',
      search_driver_name: '',
      search_driver_enable: 0,
      search_driver_block: 0,
      account_agent_number: '',
      account_info_business_name: '',
      account_user_status: null,
      account_info_phone_number: '',
      search_user_setting_truck_license_plate: '',
      last_update: '',
      account_contract: null,
      show_last_update: '',
      account_info_business_type_th: '',
    };
  }

  giztixMenu() {
    return localStorage.getItem('giztixmenu');
  }

  handleDateRangePicker(event, picker, refetch) {
    const date = `${picker.startDate.format('YYYY-MM-DD')}:${picker.endDate.format('YYYY-MM-DD')}`;
    const show = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format(
      'DD/MM/YYYY'
    )}`;
    this.setState({ last_update: date, show_last_update: show }, () => {
      refetch();
    });
  }

  sort(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type: this.state.order_type * -1 });
    } else {
      this.setState({ order_type: 1, order_by });
    }
    refetch();
  }

  renderSorting(order_by) {
    if (order_by == this.state.order_by) {
      if (this.state.order_type == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPage(page, total_page, refetch) {
    this.setState({ page_number: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  sort_agent(order_by, refetch) {
    if (order_by == this.state.order_by) {
      this.setState({ order_type_agent: this.state.order_type * -1 });
    } else {
      this.setState({ order_type_agent: 1, order_by_agent: order_by });
    }
    refetch();
  }

  renderSorting_agent(order_by) {
    if (order_by == this.state.order_by_agent) {
      if (this.state.order_type_agent == 1) {
        return 'sorting_asc';
      } else {
        return 'sorting_desc';
      }
    }

    return 'sorting';
  }

  nextPageAgent(page, total_page, refetch) {
    this.setState({ page_number_agent: page + 1, total_page });
    // console.log(this.state);
    refetch();
  }

  checkDisabled() {
    const validatePhoneNumber = /^[0][0-9].{8}$/;
    if (!validatePhoneNumber.test(this.state.driver_phone_number) || this.state.driver_name == '') {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="content">
        <Grid fluid>
          <Container>
            <Col md={12}>
              <CardTable
                title="ตารางรายการนายหน้า"
                category="ข้อมูลนายหน้าในระบบ"
                ctTableFullWidth
                ctTableResponsive
                rightButton={
                  <div style={{ float: 'right', marginLeft: '15px' }}>
                    {this.state.selectTap == 2 && (
                      <Button
                        bsStyle="primary"
                        onClick={() => (window.location = '/agentregister')}
                      >
                        <i className="fal fa-users-medical"></i> เพิ่มนายหน้า{' '}
                      </Button>
                    )}
                    {this.state.selectTap == 1 && (
                      <Button
                        bsStyle="primary"
                        onClick={() => (window.location = '/driverregister')}
                      >
                        <i className="fal fa-users-medical"></i> เพิ่มคนขับรถ{' '}
                      </Button>
                    )}
                  </div>
                }
                content={
                  <div>
                    <Query
                      query={accountInfoListQuery}
                      variables={{
                        account_agent_number: this.state.account_agent_number,
                        account_info_phone_number:
                          this.state.account_info_phone_number.charAt(0) == 0
                            ? this.state.account_info_phone_number.substring(1)
                            : this.state.account_info_phone_number,
                        account_info_business_name: this.state.account_info_business_name,
                        account_user_status: this.state.account_user_status,
                        agent_trip_month_rate: this.state.account_contract == 'true' ? true : null,
                        agent_trip_rate: this.state.account_contract == 'false' ? true : null,
                        page_number: this.state.page_number_agent,
                        order_by: this.state.order_by_agent,
                        order_type: this.state.order_type_agent,
                        last_update: this.state.last_update,
                        account_info_business_type_th: this.state.account_info_business_type_th,
                      }}
                    >
                      {({ loading, error, data, refetch }) => {
                        var itemLengthAgent = 'loading';
                        const itemIndicatorSizeAgent = (this.state.page_number_agent - 1) * 30;
                        if (data && data.accountInfoList) {
                          if (data.accountInfoList.account_info.length > 0) {
                            itemLengthAgent = data.accountInfoList.account_info.length;
                          }
                        }
                        return (
                          <div>
                            <PageWrapper style={{ textAlign: 'right' }}>
                              <div className="text">
                                {this.state.page_number_agent === 1
                                  ? `1 - ${itemLengthAgent}`
                                  : `${itemIndicatorSizeAgent + 1} - ${itemLengthAgent +
                                      itemIndicatorSizeAgent}`}{' '}
                                หน้า :
                              </div>
                              <FormControl
                                type="number"
                                min={1}
                                value={this.state.page_number_agent}
                                onChange={event =>
                                  setInterval(
                                    this.nextPage(
                                      Number(event.target.value) - 1,
                                      loading || error ? '0' : data.accountInfoList.total_page,
                                      refetch
                                    ),
                                    1000
                                  )
                                }
                                name="page_number_agent"
                              />
                              <div className="text">
                                {' '}
                                {loading || error ? '' : `จาก ${data.accountInfoList.total_page}`}
                              </div>
                            </PageWrapper>
                            <div className="table-vertical">
                              <Table striped hover responsive>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th
                                      style={{ width: '100px', minWidth: '100px' }}
                                      className={this.renderSorting_agent('account_agent_number')}
                                      onClick={() =>
                                        this.sort_agent('account_agent_number', refetch)
                                      }
                                    >
                                      Agent ID
                                    </th>
                                    <th style={{ width: '200px', minWidth: '200px' }}>
                                      ชื่อนายหน้า
                                    </th>
                                    <th style={{ minWidth: '130px' }}>Business Type</th>
                                    <th style={{ minWidth: '130px' }}>จำนวนประเภทรถ</th>
                                    <th style={{ minWidth: '130px' }}>เบอร์โทรศัพท์</th>
                                    <th>Line ID</th>
                                    <th style={{ minWidth: '130px' }}>รูปแบบการจ้างงาน</th>
                                    <th>สถานะ</th>
                                    <th
                                      style={{ width: '150px', minWidth: '150px' }}
                                      className={this.renderSorting('last_update')}
                                      onClick={() => this.sort('last_update', refetch)}
                                    >
                                      Last Update
                                    </th>
                                  </tr>
                                  <tr className="tableFilter">
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_agent_number}
                                        placeholder="ค้นหา"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_agent_number: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_agent_number"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_business_name}
                                        placeholder="ค้นหา"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_business_name: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_business_name"
                                      />
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.account_info_business_type_th}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_business_type_th: event.target.value,
                                              page_number: 1,
                                              page_number_agent: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_business_type_th"
                                      >
                                        <option value="">ทั้งหมด</option>
                                        <option value="นายหน้า">นายหน้า</option>
                                        <option value="พาร์ทเนอร์">พาร์ทเนอร์</option>
                                        <option value="RDC">RDC</option>
                                      </FormControl>
                                    </th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        type="text"
                                        value={this.state.account_info_phone_number}
                                        placeholder="ค้นหา"
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_info_phone_number: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_info_phone_number"
                                      />
                                    </th>
                                    <th></th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.account_contract}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_contract: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="agent_trip_rate"
                                      >
                                        <option value={''}>ทั้งหมด</option>
                                        <option value={true}>รายเที่ยว/รายเดือน</option>
                                        <option value={false}>รายเที่ยว</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <FormControl
                                        componentClass="select"
                                        placeholder="select"
                                        defaultValue={this.state.account_user_status}
                                        onChange={event =>
                                          this.setState(
                                            {
                                              account_user_status: event.target.value,
                                              page_number: 1,
                                            },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        name="account_user_status"
                                      >
                                        <option value={0}>ทั้งหมด</option>
                                        <option value={1}>กำลังใช้งาน</option>
                                        <option value={2}>ระงับการใช้งาน</option>
                                      </FormControl>
                                    </th>
                                    <th>
                                      <DateRangePicker
                                        onApply={(event, picker) =>
                                          this.handleDateRangePicker(event, picker, refetch)
                                        }
                                        onCancel={() =>
                                          this.setState(
                                            { last_update: '', show_last_update: '' },
                                            () => {
                                              refetch();
                                            }
                                          )
                                        }
                                        containerStyles={{ display: 'block' }}
                                        opens="right"
                                        format="DD/MM/YYYY"
                                      >
                                        <FormControl
                                          type="text"
                                          value={this.state.show_last_update}
                                          placeholder="ค้นหาวันที่"
                                          name="last_update"
                                        />
                                      </DateRangePicker>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {loading || error || !data ? (
                                    <tr>
                                      <td colSpan="10" className="textCenter">
                                        {loading ? 'Loading...' : error.message}
                                      </td>
                                    </tr>
                                  ) : (
                                    data.accountInfoList.account_info.map((prop, key) => (
                                      <tr key={key} data-id={prop._id}>
                                        <td style={{ width: '95px' }}>
                                          <OverlayTrigger placement="top" overlay={tooltip}>
                                            <Button
                                              onClick={() =>
                                                window.open(
                                                  `/agentdetail?agent=${prop._id}`,
                                                  '_blank'
                                                )
                                              }
                                            >
                                              <i
                                                style={{ color: '#6666666' }}
                                                className="fas fa-search"
                                              ></i>{' '}
                                              ดูข้อมูล
                                            </Button>
                                          </OverlayTrigger>
                                        </td>
                                        <td style={{ width: '100px' }}>
                                          {prop.account_agent_number
                                            ? `AG${prop.account_agent_number}`
                                            : ''}
                                        </td>
                                        <td>{prop.account_info_business_name}</td>
                                        <td>{prop.account_info_business_type.th}</td>
                                        <td>{prop.setting_truck_service.length}</td>
                                        <td>{`${prop.account_info_phone_code} ${prop.account_info_phone_number}`}</td>
                                        <td>{prop.line_id}</td>
                                        <td>
                                          {prop.agent_trip_rate ? (
                                            <>
                                              <i
                                                style={{ color: '#E13434' }}
                                                className="fas fa-truck"
                                              ></i>{' '}
                                              รายเที่ยว
                                            </>
                                          ) : (
                                            <>
                                              <i
                                                style={{ color: '#0088C4' }}
                                                className="far fa-calendar-alt"
                                              ></i>{' '}
                                              รายเที่ยว/รายเดือน
                                            </>
                                          )}
                                        </td>
                                        <td style={{ width: '130px' }}>
                                          {prop.account_users[0].account_user_status == 1 ? (
                                            <Button bsStyle="link" style={{ color: '#19AA19' }}>
                                              {' '}
                                              กำลังใช้งาน{' '}
                                            </Button>
                                          ) : (
                                            <Button bsStyle="link" style={{ color: '#ED1C24' }}>
                                              {' '}
                                              ระงับการใช้งาน <i className="fas fa-times-circle"></i>
                                            </Button>
                                          )}
                                        </td>
                                        <td>
                                          <Moment format="DD/MM/YYYY HH:mm">
                                            {prop.last_update}
                                          </Moment>
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </Table>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                              {loading || error || !data ? null : (
                                <ReactPaginate
                                  pageCount={data.accountInfoList.total_page}
                                  forcePage={this.state.page_number_agent - 1}
                                  containerClassName="pagination"
                                  nextLabel=">"
                                  previousLabel="<"
                                  onPageChange={({ selected }) => {
                                    this.nextPageAgent(
                                      selected,
                                      data.accountInfoList.total_page,
                                      refetch
                                    );
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        );
                      }}
                    </Query>
                  </div>
                }
              />
            </Col>
          </Container>
        </Grid>
      </div>
    );
  }
}

export default Agent3PL;
