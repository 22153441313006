import React from 'react';
import moment from 'moment';
import 'moment-duration-format';

class Countdown extends React.Component {
    state = {
        timeLeft: undefined,
    };

    componentDidMount() {
        // if (this.props.type == "once") {
        //     const { timeTillDate, timeFormat } = this.props;
        //     const then = moment(timeTillDate, timeFormat) ;
        //     const timeLeft = then.diff(moment(), 'second');
        //     this.setState({ timeLeft });
        // }else {
            this.interval = setInterval(() => {
                const { timeTillDate, timeFormat } = this.props;
                const then = moment(timeTillDate, timeFormat) ;
                const timeLeft = then.diff(moment(), 'second');
                this.setState({ timeLeft });
                
            }, 1000);
        // }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const { timeLeft } = this.state;
        
        if(timeLeft == undefined){
            return null
        }

        if(timeLeft == 0){
            this.props.exceed()
            return null
        }
        if(this.props.type == "once"){
            return (
                <div>
                    <i style={{ position: 'unset' }} class="fas fa-clock"></i> {moment.duration(timeLeft, 'second').format(`DD [วัน] HH[:]mm[:]ss`)}
                </div> 
            )
        }
        return ( moment.duration(timeLeft, 'second').format(`DD [วัน] HH [ชั่วโมง] mm [นาที] ss [วินาที]`));
    }
}

export default Countdown;
