import React from "react";
import { Grid } from "react-bootstrap";

class Layout extends React.Component {
  render() {
    return (
      <div className="content" style={{paddingTop:0}}>
        <Grid fluid>
          <div {...this.props}>
            <div>{this.props.children}</div>
          </div>
        </Grid>
      </div>
    );
  }
}

export default Layout;
