import gql from 'graphql-tag';

export default gql`
  query CustomerCreateBooking($customer_service_type: String, $account_info_customer_type: Int) {
    accountInfoList(
      customer_service_type: $customer_service_type
      account_info_customer_type: $account_info_customer_type
    ) {
      account_info {
        _id
        account_info_business_name
        account_info_customer_type
      }
    }
  }
`;
